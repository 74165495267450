import { SfFile } from '../../../core/models/file.model';

export interface SfMediaVideo {

  /** Name of the video */
  name: string;

  /** Video url */
  thumbnail: SfFile;

  /** Main image */
  video: string;
}

export function isSfMediaSfMediaVideo(object: Object): object is SfMediaVideo {
  return 'video' in object && 'thumbnail' in object;
}
