import { Component, OnInit, Input } from '@angular/core';
import { SfSectionVideo } from '../models/section-video.interface';
@Component({
    selector: 'sf-section-video',
    templateUrl: './section-video.component.html',
    styleUrls: ['./section-video.component.sass'],
    standalone: false
})
export class SfSectionVideoComponent implements OnInit {

  @Input() section: SfSectionVideo;

  @Input() seoTitle: 'H1'|'H2'|'H3'|'DIV' = 'DIV';

  constructor(
  ) { }

  ngOnInit() {
  }
}
