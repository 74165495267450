<div class="sf-form-image" *ngIf="data && formControl">
  <label class="sf-form-image-label sf-title" [for]="controlName" *ngIf="data.label">
    {{ data.label | translate }}
    <span *ngIf="data.required" [ngClass]="{'sf-error-color': !formControl.pristine && formControl.invalid}"> *</span>
  </label>

  <div #imageContainer class="sf-form-image-container">

    <!-- Selected image -->
    <img class="sf-form-image-thumbnail" *ngIf="formControl.value" [src]="formControl.value" loading="lazy">

    <!-- See button -->
    <button *ngIf="formControl.value" class="sf-form-image-detail sf-main-color" (click)="show(imageContainer, formControl.value)">
      <mat-icon class="sf-form-image-detail-icon" svgIcon="search_round"></mat-icon>
      <span>{{ 'SF.FORM.PHOTO.SEE' | translate }}</span>
    </button>

    <!-- Delete button -->
    <button *ngIf="formControl.value" class="sf-form-image-delete sf-main-color" (click)="delete()">
      <mat-icon class="sf-form-image-delete-icon" svgIcon="close_round"></mat-icon>
      <span>{{ 'SF.FORM.PHOTO.DELETE' | translate }}</span>
    </button>

    <!-- Add file button -->
    <div *ngIf="!formControl.value">
      <input #fileInput style="display: none;" accept="image/*" type="file" (change)="onFileChanged($event)">
      <app-button
        (buttonClick)="fileInput.click()"
        [label]="'SF.FORM.PHOTO.ADD' | translate">
      </app-button>
    </div>

  </div>

  <div class="mt-c-xs sf-error-color sf-title" *ngIf="!formControl.pristine && formControl.invalid">
    {{ data.error || 'SF.FORM.ERROR' | translate }}
  </div>
</div>
