import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'checkArray',
    standalone: false
})
export class CheckArrayPipe implements PipeTransform {
  transform(value: string | string[]): string[] {
    let newArray: string[] = [];
    if (value && Array.isArray(value) && value.length > 0) {
      newArray = value;
      return newArray;
    } else {
        newArray.push(value as string);
      return newArray;
    }
  }

}
