import { SfModel } from '../networking/models/model';

export class SfLang extends SfModel {

  /** Country code (ie: 'uk' or 'fr') */
  country: string;

  /** Language code (ie: 'uk' or 'fr') */
  code: string;

  /** Is language default language for the site */
  default?: boolean;
}
