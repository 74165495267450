import { SomArticleItem } from "@app/core/models/article-item.model";
import { PrediggoItem } from "../model/prediggo-item.model";
import { SfSimpleLink, SfSimpleMediaImage, SfTag } from "@app/sf-lib/public_api";
import { ARTICLE_CATEGORY } from "../enum/article-category.enum";
import { TranslateService } from "@ngx-translate/core";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class PredigoConverter {

    constructor(private translateService: TranslateService) {}

    public convertPrediggoItemToArticleItem(item: PrediggoItem): SomArticleItem {
        const articleItem = new SomArticleItem();
        articleItem.tag = new SfTag();
        articleItem.id = item.sku;
        articleItem.trackingCode = item.trackingCode;
        articleItem.title = this._getAttributeValue(item, 'shortDescription');
        articleItem.link = this._buildLinkFromPrediggoItem(item);
        articleItem.cover = this._buildImageFromPrediggoItem(item);
        articleItem.descriptionSeo = this._getAttributeValue(item, 'descriptionSEO');
        articleItem.thumbnailUrl = this._getAttributeValue(item, 'thumbnailUrl');

        return articleItem;
    }

    private _buildLinkFromPrediggoItem(item): SfSimpleLink {
        const link = new SfSimpleLink();
        const routeArticle = this.translateService.instant('ROUTES.article');
        const category = this._getAttributeValue(item, 'category');
        const url = this._getAttributeValue(item, 'itemUrl');
        const idPage = this._getAttributeValue(item, 'idPage');
        const duplicationId = this._getAttributeValue(item, 'duplicationId');

        link.url = '';
        
        switch (category) {
            case ARTICLE_CATEGORY.MAGAZINE:
                link.url += `${this.translateService.instant('ROUTES.magazine')}/${routeArticle}${url}/${idPage}/${duplicationId}`;
                break;
            case ARTICLE_CATEGORY.MENU_ARTICLE:
                link.url += `${this.translateService.instant('ROUTES.menu-page')}/${routeArticle}${url}/${idPage}/${duplicationId}`;
                break;
            case ARTICLE_CATEGORY.FAQ:
                link.url += `${this.translateService.instant('ROUTES.faq')}${url}`;
                break;
            default:
                link.url += `${url}/${idPage}/${duplicationId}`;

        }

        link.is_external = false;
        link.extras = { id: item.trackingCode };
        link.label = '';

        return link;
    }

    private _buildImageFromPrediggoItem(item): SfSimpleMediaImage {
        const media = new SfSimpleMediaImage();
        media.image.uri.original = this._getAttributeValue(item, 'thumbnailUrl');
        return media;
    }

    private _getAttributeValue(item: PrediggoItem, name: string): string | undefined {
        const attribute = item.attributeInfo.find(a => a.attributeName === name);

        if (attribute) {
            return attribute.vals[0].label;
        }

        return undefined;
    }
}