import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sfHighlight',
    standalone: false
})
export class SfHighlightPipe implements PipeTransform {

 transform(value: string, search: string): string {
   return value.replace(new RegExp('(?![^&;]+;)(?!<[^<>]*)(' + search + ')(?![^<>]*>)(?![^&;]+;)', 'gi'),
    '<span class="sf-highlighted">$1</span>');
 }

}
