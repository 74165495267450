import { SfModel } from '../networking/models/model';

export class SfFile extends SfModel {

  /** File name */
  filename: string;

  /** URIs */
  uri: {
    // No ratio
    original: string,

    // crop
    crop_thumbnail?: string

    // Ratio 3,2
    zone_maxi?: string,

    // Ratio 1,25
    ratio_770_616?: string,
    ratio_680_544?: string,
    ratio_500_400?: string,
    ratio_330_264?: string,
    ratio_245_196?: string,

    // Ratio 2,6667 (8:3)
    ratio_1024_384?: string,

    // Ratio 1,4286 (10:7)
    ratio_550_385?: string,

    // Ratio 0,8
    ratio_500_625?: string,
    ratio_328_410?: string,
    ratio_236_295?: string,
    ratio_416_520?: string
  };

  /** MIME type */
  filemime: string;

  /** Size (byte) */
  filesize: number;

  constructor() {
    super();
    this.uri = {
      original: undefined
    };
  }
}
