import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagManagerService } from './services/tag-manager.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [
    TagManagerService
  ]
})
export class TagManagerModule {
  static forRoot(): ModuleWithProviders<TagManagerModule> {
    return {
      ngModule: TagManagerModule
    };
  }
}
