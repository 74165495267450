import { SfMediaImage, SfLink, SfItem, SfTag } from '@app/sf-lib/public_api';

export class SomProductItem implements SfItem {
  // Product id from Prediggo
  id: string;

  /** Title of the item */
  title: string;

  /** Prediggo tracking code */
  trackingCode?: string;

  /** Main cover of the item */
  cover: SfMediaImage;

  /** SfItem link */
  link: SfLink;

  tag?: SfTag;

  constructor() {
  }
}
