import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


/**
 * ================= /!\ ====================
 * Please think security before using this pipe.
 * It may cause some security risks but it is necessary for components such as iframes.
 * https://stackoverflow.com/questions/38037760/how-to-set-iframe-src-in-angular-2-without-causing-unsafe-value-exception
 */
@Pipe({
    name: 'sanitizeResourceUrl',
    standalone: false
})
export class SfSanitizeResourceUrlPipe implements PipeTransform {

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  transform(v: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(v);
  }
}
