import { SfModel } from '../../../core/networking/models/model';
import { SfFile } from '../../../core/models/file.model';
import { SfMediaImage } from './media-image.interface';

export class SfSimpleMediaImage extends SfModel implements SfMediaImage {

  /** Image url */
  thumbnail: SfFile;

  /** alt attribute */
  alt_image?: string;

  /** Main image */
  image: SfFile;

  constructor() {
    super();
    this.thumbnail = new SfFile();
    this.image = new SfFile();
    this.sfType = 'SfMediaImage';
  }
}
