<div class="sf-slider" *ngIf="slider && slider.slides && slider.slides.length">
  <!-- Left arrow -->
  <div class="sf-slider-arrow sf-slider-arrow-left" [ngClass]="{'sf-slider-subjective': mode === 'subjective', 'sf-slider-objective': mode === 'objective'}" (click)="previous()" *ngIf="slider.slides.length > 1">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 50">
        <defs>
          <style>.cls-1 {fill: #FFF}</style>
          <clipPath>
            <path class="cls-1" d="M0 4l22 21L0 47l4 3 25-25L4 0z"/>
          </clipPath>
        </defs>
        <g transform="rotate(-180 14 25)">
          <g>
            <path class="cls-1" d="M0 4l22 21L0 47l4 3 25-25L4 0z"/>
          </g>
        </g>
      </svg>
    </div>

  <!-- Slider -->
  <ul class="sf-slider-slider">

    <!-- Slide -->
    <li [@sliderAnimations]="sliderService.getAnimationState(i)"
      (@sliderAnimations.start)="animationStart()"
      (@sliderAnimations.done)="animationDone()"
      *ngFor="let slide of slider.slides; let i = index"
      (swipeleft)="swipe(i,$event.type)"
      (swiperight)="swipe(i,$event.type)"
      class="sf-slider-slide">
      <ng-template
        [ngTemplateOutlet]="template"
        [ngTemplateOutletContext]="{$implicit: slide, state: sliderService.getAnimationState(i)}">
      </ng-template>
    </li>

  </ul>

  <!-- Right arrow -->
  <div class="sf-slider-arrow sf-slider-arrow-right" [ngClass]="{'sf-slider-subjective': mode === 'subjective', 'sf-slider-objective': mode === 'objective'}" (click)="next()" *ngIf="slider.slides.length > 1">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 50">
        <defs>
          <style>.cls-1 {fill: #FFF}</style>
          <clipPath>
            <path class="cls-1" d="M0 4l22 21L0 47l4 3 25-25L4 0z"/>
          </clipPath>
        </defs>
        <g>
          <g>
            <path class="cls-1" d="M0 4l22 21L0 47l4 3 25-25L4 0z"/>
          </g>
        </g>
      </svg>
    </div>

  <!-- Breadcrumb -->
  <div class="sf-slider-breadcrumb-container" [ngClass]="{'sf-slider-breadcrumb-center': mode === 'subjective'}">
    <sf-breadcrumb *ngIf="slider.slides.length > 1" class="sf-slider-breadcrumb" [elements]="slider.slides" [selectedIndex]="sliderService.index" (select)="sliderService.selectSlide($event)" [disabled]="animationLockCounter > 0"></sf-breadcrumb>
  </div>
</div>
