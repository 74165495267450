/* tslint:disable */export const no_no = {
  '404': {
    TITLE: 'Ops...',
    PAGENOTFOUND: 'Siden finnes ikke',
    BUTTON: 'Tilbake til startsiden',
    RECIPES: 'Se våre oppskrifter'
  },
  HOME: {
    SHOWCASE: 'Oppskrifter',
    CATCH_PHRASE: "Today's surprising vegetables, \nfor a better tomorrow",
    SHOWCASE_SEE_ALL: 'Se alle oppskrifter',
    MAGAZINE: { TITLE: 'Utvalgte artikler', BUTTON: 'Les våre nyheter' },
    OUR_VEGETABLES: 'Våre grønnsaker',
    SOCIAL_WALL: 'Del din lidenskap for grønnsaker sammen med oss',
    FAQ_TITLE: 'VANLIGE SPØRSMÅL',
    FAQ_SEE_MORE: 'Les mer',
    FLOWBOX: ''
  },
  SELECT: { DEFAULT_VALUE: 'Anbefalt' },
  MENU: {
    CONTACT_US: 'Kontakt oss',
    MY_ACCOUNT: 'Min konto',
    GOOD_DEALS: 'VANLIGE SPØRSMÅL',
    NEWSLETTER: 'Bonduelle Nyhetsbrev'
  },
  FOOTER: {
    LINK: {
      BONDUELLE: 'Bonduelle.no',
      CREDITS: 'Credits',
      LEGAL_NOTICE: 'Juridisk melding',
      TERMS_OF_SALES: 'Generelle salgsvilkår',
      COOKIES: 'Informasjonskapsler',
      SITEMAP: 'Nettstedskart',
      PRIVACY_POLICY: 'Ekstern personvernpolicy',
      ALL_RIGHTS_RESERVED: '2021 © Med enerett'
    },
    HISTORY: {
      TITLE: 'Bonduelle – siden 1853',
      TEXT: 'Bonduelle er en fransk familieeid virksomhet med røtter helt tilbake til 1853. Vi har lang erfaring med hvordan man høster og pakker grønnsaker slik at de bevarer den gode smaken og sprøheten. Hos Bonduelle ønsker vi at grønnsaker skal være en viktig del av det fremtidige kostholdet. Vi har også fokus på svinn, og derfor selges størstedelen av vårt sortiment i små forpakninger. På denne måten imøtekommer vi både de mindre husstandenes behov, og reduserer mengden av det som kastes.'
    },
    NEWSLETTER: {
      TITLE: 'Bonduelle Community',
      TEXT: 'Jeg vil gjerne motta nyhetsbrevet «Alt om grønnsaker» fra Bonduelle (nyheter, kampanjer, konkurranser, artikler osv.)',
      PLACEHOLDER: 'Din e-postadresse',
      GDPR_LINK: 'Mer om håndtering av dine data'
    }
  },
  PRODUCTS: 'Produkter',
  MAGAZINE: {
    HOME: 'Velkommen til våre nyheter',
    CATCH_PHRASE: '',
    MENU: { BONDUELLE_LINK: 'Gå tilbake' },
    SHOWCASE: 'Utvalgte artikler',
    PREVIOUS_ARTICLES: 'Tidligere artikler',
    PREVIOUS_ARTICLES_SEE_ALL: 'Se alle våre artikler',
    PREVIOUS_EDITIONS: 'Våre tidligere utgaver',
    PREVIOUS_EDITIONS_SEE_ALL: 'Se flere utgaver',
    PREVIOUS_ARTICLES_BY_CATEGORY: 'Tidligere artikler etter kategori',
    RUBRIC: {
      SEE_ALL: 'Se alle oppskrifter',
      SEE_ALL_ARTICLES: 'Se alle artikler',
      ON: 'artikler av'
    },
    RUBRIC_COOK: { ON: 'oppskrifter av' },
    ARTICLE: { JOIN_US: 'Følg oss', READ_ALSO: 'Les også:' },
    RECIPE: {
      INGREDIENTS: 'Ingredienser',
      INGREDIENTS_LABEL_DESC: 'til {{value}} personer',
      INGREDIENTS_LABEL_ONE_DESC: 'til {{value}} person',
      INGREDIENTS_LABEL: 'Ingredienser til {{value}} personer',
      INGREDIENTS_LABEL_ONE: 'Ingredienser til {{value}} person',
      PEOPLES: '{{value}} personer',
      PEOPLE: '{{value}} person',
      DIFFICULTY: { EASY: 'Lett', MEDIUM: 'Middels', HARD: 'Vanskelig' },
      COST: { CHEAP: 'Billig', EXPENSIVE: 'Dyrt' },
      TIMING: { MIN: 'min.', HOURS: 'timer' },
      STEP: 'Trinn',
      DISCOVER_ALSO: 'Se også:'
    }
  },
  ARTICLE: { DISCOVER_ALSO: 'Les også:', INGREDIENTS: 'Ingredienser' },
  SF: {
    SLIDE: { DISCOVER: 'Se' },
    SEARCH: { PLACEHOLDER: 'Søk', ALL: 'Alt' },
    NEWSLETTER: {
      BTN_LABEL: 'Registrer deg',
      PLACEHOLDER: 'Din e-postadresse',
      ERROR_MESSAGE: 'Ugyldig e-postadresse',
      ALREADY_SUBSCRIBED: 'Du er allerede registrert',
      GDPR: {
        TXT: 'Flere opplysninger om datahåndtering',
        LINK: '/ekstern-databeskyttelsespolitik'
      }
    },
    MONTHLY_FOLDER: { TITLE: 'Månedlige nyheter' },
    MINI_NEWSLETTER: {
      PLACEHOLDER: 'Din e-postadresse',
      GDPR: {
        TXT: 'Flere opplysninger om datahåndtering',
        LINK: '/ekstern-databeskyttelsespolitik'
      }
    },
    SOCIALITEM: { SHARE: 'del', COMMENT: 'kommenter', BONDUELLE: 'Bonduelle' },
    SEARCHRECIPE: { DISCOVER_ALL: 'Søk i alle oppskrifter!' },
    FORM: {
      ERROR: 'Ugyldig felt',
      PHOTO: { ADD: 'Legg til et bilde', DELETE: 'Slett bildet', SEE: 'Se' }
    }
  },
  CORPORATE: {
    COMPANY_STORY: 'Vår historie',
    CSR_COMMITMENT: 'Vårt engasjement',
    VEGETABLE_INNOVATIONS: 'Hermetikk og innovasjoner',
    NEWS: 'alle artikler',
    SEE_MORE: 'Se flere artikler',
    ON: 'artikler av'
  },
  NEWSLETTER: {
    TITLE: 'Abonner på nyhetsbrevet',
    SUBTITLE: 'Gode ideer og inspirasjon til matlagingen!'
  },
  ROUTES: {
    products: 'produkter',
    news: 'siste-nytt',
    'since-1853': 'siden-1853',
    'field-plate': 'jord-til-bord',
    'company-story': 'var-historie',
    'csr-commitments': 'vart-engasjement',
    'vegetal-innovations': 'hermetikk-innovasjoner',
    magazine: 'nyheter',
    all: 'se-alt',
    article: 'artikkel',
    cook: 'matlaging',
    cultivate: 'dyrke',
    explore: 'utforske',
    recipe: 'oppskriftsverden',
    recipes: 'oppskrifter',
    'recipes-home': 'oppskrift',
    r: 'r',
    credits: 'credits-no',
    'legal-notice': 'juridisk-melding',
    'terms-of-sales': 'generelle-salgsvilkar',
    cookies: 'ekstern-personvernpolicy',
    sitemap: 'nettstedskart',
    'privacy-policy': 'ekstern-personvernpolicy',
    'all-rights-reserved': 'med-enerett',
    search: 'sok',
    'articles-search': 'artikkel',
    vegetables: 'produkter',
    p: 'p',
    contact: 'kontakt-oss',
    'info-product': 'produktinformasjon',
    'note-product': 'produktreklamasjon',
    suggestion: 'forslag',
    'other-request': 'produktinformasjon',
    faq: 'vanlige-sporsmal',
    'all-themes': 'alle',
    'products-search': 'produkter-sok',
    recipesearch: 'oppskrifter-sok',
    'product-news': 'nyheter',
    'good-deals': 'gode-tilbud',
    'not-found': 'ikke-funnet',
    'menu-page': 'meny-side',
    'menu-one': 'meny-en',
    'menu-two': 'meny-to',
    'menu-three': 'meny-tre',
    'menu-four': 'meny-fire',
    'menu-external': '',
    'theme-pasta-rice': 'tema-pasta-ris',
    'theme-bowls': 'tema-skalmat',
    'theme-soup': 'tema-middag',
    'theme-snack': 'tema-snacks',
    'theme-sauce': 'tema-sauser',
    'theme-unique-plate': '',
    'theme-over': 'tema-andre-retter',
    'theme-legumin': 'tema-frukt',
    'theme-ingredients-recipes': 'tema-ingredienser-oppskrifter',
    'theme-cook-plate': ''
  },
  BREAD: {
    products: 'produkter',
    news: 'siste-nytt',
    'since-1853': 'siden-1853',
    'field-plate': 'jord-til-bord',
    'company-story': 'var historie',
    'csr-commitments': 'vart-engasjement',
    'vegetal-innovations': 'hermetikk innovasjoner',
    magazine: 'nyheter',
    all: 'se-alt',
    article: 'artikkel',
    cook: 'matlaging',
    cultivate: 'dyrke',
    explore: 'utforske',
    recipe: 'oppskriftsverden',
    recipes: 'oppskrifter',
    'recipes-home': 'oppskrift',
    'articles-search': 'artikkel',
    search: 'sok',
    vegetables: 'produkter',
    contact: 'kontakt-oss',
    'info-product': 'produktinformasjon',
    'note-product': 'produktreklamasjon',
    suggestion: 'forslag',
    'other-request': 'produktinformasjon',
    faq: 'vanlige spørsmal',
    'all-themes': 'alle',
    'products-search': 'produkter-sok',
    recipesearch: 'oppskrifter-søk',
    'product-news': 'nyheter-produkter',
    'good-deals': 'gode-tilbud',
    'not-found': 'ikke-funnet',
    'menu-page': 'meny-side',
    'menu-one': 'meny-en',
    'menu-two': 'meny-to',
    'menu-three': 'meny-tre',
    'menu-four': 'meny-fire'
  },
  MENU_PAGE: {
    TITLE1: 'TITTEL 1',
    TITLE2: 'TITTEL 2',
    TITLE3: 'TITTEL 3',
    TITLE4: 'TITTEL 4',
    EXTERNAL: ''
  },
  TECHNICAL: { MENU_PAGE: 'ledige-stillinger' },
  SEO: {
    TITLE: {
      MENU_ONE: 'menu one Title',
      MENU_TWO: 'menu two Title',
      MENU_THREE: 'menu three Title',
      MENU_FOUR: 'menu four Title',
      HOME: 'Bonduelle: finn inspirasjon i vårt grønnsaksunivers',
      SEARCH: {
        MAIN: '{{value}} | Bonduelle',
        RECIPES: '{{value}} sortere etter oppskrift | Bonduelle',
        VEGETABLES: '{{value}} sortere etter grønnsak | Bonduelle',
        ARTICLES: '{{value}} sortere etter artikkel | Bonduelle'
      },
      CONTACT: 'Kundeservice | Bonduelle',
      RECIPES: 'Oppskrifter: supper, salater, hovedretter ... | Bonduelle\n',
      RECIPE_HOME: 'Oppskriftsunivers | Bonduelle',
      RECIPE: 'Oppskrift {{value}} | Bonduelle',
      PRODUCTS: 'Hermetikk | Bonduelle',
      NEWS: 'Les mer om grønnsakenes potensial | Bonduelle',
      CORPORATE: {
        NEWS: 'Nye artikler om grønnsaker og matlaging | Bonduelle',
        COMPANY_STORY: 'Bli kjent med Bonduelle og dens historie | Bonduelle',
        CSR_COMMITMENTS: 'Les om våre verdier og prinsipper | Bonduelle',
        VEGETAL_INNOVATIONS: 'Les om våre innovasjoner | Bonduelle',
        FIELD_PLATE: 'Grønnsaker: de beste grønnsakene til de beste oppskriftene | Bonduelle',
        ARTICLE: '{{value}} | Bonduelle',
        VEGETABLE: 'Lær mer om {{value}} | Bonduelle'
      },
      PRODUCT: '{{value}}: Grønnsaker fra Bonduelle',
      MAGAZINE: {
        NEWS: 'Artikler om grønnsakenes fordeler, oppskrifter og nyheter | Bonduelle',
        ARTICLE: 'Bonduelle',
        COOK: 'Raske og enkle oppskrifter med grønnsaker | Bonduelle',
        GROW: 'Dyrk dine egne grønnsaker | Bonduelle',
        EXPLORE: 'Lær noe nytt om grønnsaker | Bonduelle',
        RECIPE: 'Oppskrift {{value}} | Bonduelle'
      },
      FAQ: 'FAQ: vanlige spørsmål | Bonduelle',
      NOTFOUND: '404-feil. Prøv igjen senere | Bonduelle',
      RECIPES_ALL: 'Våre oppskrifter',
      RECIPETYPE: 'Oppskrifter: {{value}}',
      RECIPESEASON: 'Årstid: {{value}}',
      RECIPEPREPARATION: 'Oppskrift: tilberedningstid {{value}}',
      RECIPEDIFFICULTY: 'Enkle oppskrifter med grønnsaker etter {{value}}.',
      RECIPEVEGETABLE: 'Enkle oppskrifter med {{value}}',
      RECIPECOST: 'Oppskrifter og grønnsaker {{value}}',
      RECIPEDISH: 'Oppskrifter med grønnsaker til {{value}}',
      RECIPEDESC: 'Oppskrifter og grønnsaker {{value}}',
      '2RECIPEFACETS': 'Oppskrift {{value}}',
      '4RECIPEFACETS': 'Oppskrift {{value}}',
      PRODUCTSEASON: 'Oppskrifter med grønnsaker som passer til: {{value}}',
      PRODUCTTECHNO: 'Enkle oppskrifter med grønnsaker {{value}}',
      PRODUCTVEGETABLE: 'Enkle oppskrifter med {{value}}',
      PRODUCTRANGE: 'Enkle oppskrifter med {{value}}',
      THEME_ALL: 'Grønnsakshermetikk',
      THEME_STARTER: 'Oppskriftsideer til forrettene',
      THEME_SALAD: 'Oppskriftsideer til salater',
      THEME_CAN: '',
      THEME_FROZEN: '',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: '',
      THEME_RECIPE: 'Oppskriftsideer til enkle og raske oppskrifter',
      THEME_PLATE: 'Oppskriftsideer til varme og kalde retter',
      THEME_ACC: 'Oppskriftsideer til tilbehør',
      THEME_SNACK: 'Oppskriftsideer til småretter og snacks',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': 'Oppskrifter med grønnsaker {{value}}'
    },
    DESCRIPTION: {
      MENU_ONE: 'menu one description',
      MENU_TWO: 'menu two Description',
      MENU_THREE: 'menu three Description',
      MENU_FOUR: 'menu four Description',
      HOME: 'Se våre produkter og få inspirasjon til matlagingen.',
      SEARCH: {
        MAIN: 'Se våre oppskrifter og produkter til {{value}}. Enkle og lekre oppskrifter med grønnsaker som er klare til bruk.',
        RECIPES: 'Alle våre {{value}} sortert etter oppskrift. Enkle og lekre oppskrifter med grønnsaker.',
        VEGETABLES: 'Alle våre {{value}} sortert etter grønnsak. Enkle og lekre oppskrifter med grønnsaker.',
        ARTICLES: 'Alle våre {{value}} sortert etter artikkel. Enkle og lekre oppskrifter med grønnsaker.'
      },
      CONTACT: 'Har du spørsmål om våre produkter? Du kan kontakte vår kundeservice.',
      RECIPES: 'Se våre lekre, grønnsaksbaserte oppskrifter. Gled dine nærmeste med lekre og raske oppskrifter tilberedt med grønnsaker klare til bruk.',
      RECIPE_HOME: 'Finn lekre grønnsaksbaserte oppskrifter til årets høytider. Gled dine nærmeste med lekre og raske oppskrifter tilberedt med grønnsaker klare til bruk.',
      RECIPE: '',
      PRODUCTS: 'Vi tilbyr et sortiment av grønnsaker du kan bruke til å tilberede lekre og enkle oppskrifter.',
      NEWS: 'Les mer om hvordan du tilbereder grønnsaker på lekre måter. Enkle oppskrifter med grønnsaker klare til bruk.',
      CORPORATE: {
        NEWS: 'De siste nyhetene fra vår grønnsaksverden.',
        COMPANY_STORY: 'Vil du vite mer om vårt grønnsaksunivers og vår historie? Les mer på vår blogg.',
        CSR_COMMITMENTS: 'Vil du vite mer om hvordan vi dyrker grønnsakene våre, hvor de kommer fra og hvordan vi respekterer miljøet? Finn svar på dine spørsmål her.',
        VEGETAL_INNOVATIONS: 'Vi tenker mye på plantebasert innovasjon. Blant annet hvordan man kan dyrke grønnsaker på miljøvennlige måter, og som produserer mindre avfall. Enkle og lekre oppskrifter. Grønnsaker klare til bruk.',
        VEGETABLE: '',
        FIELD_PLATE: 'Se våre lekre oppskrifter.',
        ARTICLE: ''
      },
      PRODUCT: '{{value}} til tilberedning av lekre oppskrifter du kan glede dine nærmeste med. Enkle og lekre oppskrifter med grønnsaker klare til bruk.',
      MAGAZINE: {
        NEWS: 'Alle de siste nyhetene om grønnsaker og grønnsaksmat. Enkle og lekre oppskrifter med grønnsaker klare til bruk.',
        ARTICLE: '',
        COOK: 'Våre lekre oppskrifter får gjestene til å føle seg som heldige poteter. Les mer på vårt nettsted. Enkle og lekre oppskrifter med grønnsaker klare til bruk.',
        GROW: 'Har du lyst til å dyrke dine egne grønnsaker? Les våre råd og tips om dyrking og stell av grønnsaker.',
        EXPLORE: 'Få inspirasjon til morsomme oppskrifter du kan bruke til å presentere barna for nye grønnsaker.',
        RECIPE: ''
      },
      FAQ: 'Har du spørsmål? Vi har samlet noen vanlige spørsmål fra våre kunder.',
      PAGE: 'side',
      RECIPETYPE: 'Se våre oppskrifter {{value}} og lær hvordan du tilbereder grønnsaker på best mulig måte.',
      RECIPESEASON: 'Se våre oppskrifter og lær hvordan du tilbereder grønnsaker som {{value}}.',
      RECIPEPREPARATION: 'Se våre oppskrifter og lær hvordan du tilbereder grønnsaker på {{value}}.',
      RECIPEDIFFICULTY: 'Se våre oppskrifter og lær hvordan du tilbereder grønnsaker {{value}}.',
      RECIPEVEGETABLE: 'Se våre oppskrifter med {{value}} og lær hvordan du tilbereder grønnsaker på best mulig måte.',
      RECIPECOST: 'Se våre oppskrifter og lær hvordan du tilbereder grønnsaker på best mulig måte til {{value}}.',
      RECIPEDISH: 'Se våre oppskrifter og lær hvordan du tilbereder grønnsaker på best mulig måte til {{value}} personer.',
      RECIPEDESC: 'Se våre oppskrifter og lær hvordan du tilbereder grønnsaker på best mulig måte som {{value}}.',
      '2RECIPEFACETS': 'Se våre oppskrifter og lær hvordan du tilbereder grønnsaker på best mulig måte som {{value}}.',
      '4RECIPEFACETS': 'Se våre oppskrifter og lær hvordan man tilbereder grønnsaker på best mulig måte {{value}}.',
      PRODUCTSEASON: 'Vi tilbyr et sortiment av grønnsaker du kan bruke til å tilberede lekre og enkle oppskrifter om {{value}}',
      PRODUCTTECHNO: 'Vi tilbyr et sortiment av grønnsaker du kan bruke til å tilberede lekre og enkle oppskrifter {{value}}.',
      PRODUCTVEGETABLE: 'Vi tilbyr et sortiment av grønnsaker du kan bruke til å tilberede lekre og enkle oppskrifter med {{value}}.',
      PRODUCTRANGE: 'Vi tilbyr et sortiment av grønnsaker du kan bruke til å tilberede lekre og enkle oppskrifter med {{value}}.',
      THEME_ALL: 'Vi tilbyr et sortiment av grønnsaker du kan bruke til å tilberede lekre og enkle oppskrifter.',
      THEME_STARTER: 'Leter du etter inspirasjon til en forrett? Hos oss finner du et stort utvalg av lekre oppskrifter og mye mer.',
      THEME_SALAD: 'Leter du etter inspirasjon til en lekker og frisk salat? Hos oss finner du et stort utvalg av lekre oppskrifter og mye mer.',
      THEME_CAN: '',
      THEME_FROZEN: '',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: '',
      THEME_RECIPE: 'Grønnsaker du kan bruke til å tilberede lekre og raske oppskrifter.',
      THEME_PLATE: 'Leter du etter inspirasjon til et balansert måltid? Hos oss finner du et stort utvalg av lekre oppskrifter til varme og kalde retter.',
      THEME_ACC: 'Grønnsaker du kan bruke til å tilberede lekkert og raskt tilbehør.',
      THEME_SNACK: 'Leter du etter inspirasjon til et balansert snacksmåltid? Hos oss finner du et stort utvalg av lekre oppskrifter til lekre småretter og snacksmåltider.',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': 'Vi tilbyr et sortiment av grønnsaker du kan bruke til å tilberede lekre og enkle oppskrifter for et balansert kosthold {{value}}.',
      NOTFOUND: 'Fant ikke siden. Søk på nytt eller prøv igjen senere.'
    },
    PIPE: { SOCIETY: '| Bonduelle' },
    THEME_ALL: 'Alle',
    THEME_STARTER: 'Forretter',
    THEME_SALAD: 'alt-til-mine-salater',
    THEME_CAN: '',
    THEME_FROZEN: '',
    THEME_READY_MEALS: '',
    THEME_BIO: '',
    THEME_VEGETABLES: '',
    THEME_RECIPE: 'oppskrifts-ingredienser',
    THEME_PLATE: 'Hovedretter',
    THEME_ACC: 'tilbehør',
    THEME_SNACK: 'snacks'
  },
  VEGETABLE: {
    DISCOVER_VEGETABLE: 'Les mer om våre grønnsaker',
    OURS_RECIPES: 'Våre oppskrifter',
    DISCOVER_ALSO: 'Se også:',
    SEE_ALL: 'Se alle våre grønnsaker',
    ON: 'grønnsaker av'
  },
  RECIPE: {
    'INGREDIENTS_LABEL ': 'Ingredienser til {{value}} personer',
    PEOPLES: '{{value}} personer',
    DIFFICULTY: { EASY: 'Lett', MEDIUM: 'Middels', HARD: 'Vanskelig' },
    COST: { CHEAP: 'Billig', EXPENSIVE: 'Dyrt' },
    TIMING: { MIN: 'min.', HOURS: 'timer' },
    STEP: 'Trinn',
    DISCOVER_ALSO: 'Se også:',
    HOME_SEE_MORE: 'Se flere oppskrifter',
    SHOWCASE: 'Topp',
    HOME_BOOK: 'Våre oppskrifter',
    MIN_MORE: 'min. og +',
    HOUR_MORE: 'timer og +',
    FILTER_BY_CRITERIA: 'Filtrere etter',
    SORT_BY: 'Sortere etter',
    BEST_RATED: 'Best vurderte',
    MOST_CONSULTED: 'Mest populære',
    MEMBERS_RECIPES: 'Medlemmenes oppskrifter',
    CRITERIA: 'Nyheter',
    DISCOVER: { RECIPES: 'Se våre oppskrifter' },
    DISCOVER_RECIPE: 'Se oppskriften',
    INSTRUCTIONS: 'Fremgangsmåte',
    DEFAULT_TAG: 'Oppskriftsidé',
    MOST_RECENT: 'Nyeste',
    RECIPE_VIDEOS: 'oppskriftsvideoer',
    PRODUCT_ASSOCIATE: 'Tilbered denne oppskriften med:',
    BUDGET_LOW: 'Billig',
    BUDGET_HIGH: 'Høy',
    BUDGET_MIDDLE: 'Middels',
    PRINT_RECIPE: 'skriv ut oppskrift',
    LET_YOURSELF_SURPRISED: 'Trenger du mer inspirasjon?',
    COOK_TIP_TITLE: '',
    ENJOY_MEAL: 'Vel bekomme!',
    FILTER: { LEGEND: 'Legende' }
  },
  PREDIGGO: {
    DIFFICULTY: { EASY: 'Lett', MEDIUM: 'Middels', HARD: 'Vanskelig' }
  },
  COOKIES: {
    TEXT: 'Hvis du fortsetter å bruke dette nettstedet, godtar du at vi bruker informasjonskapsler av tekniske grunner, til statistisk analyse og styring av trafikken på nettstedet, samt deling av informasjon med annonsører på andre nettsteder, for å gi deg relevant innhold som kan være interessant for deg.',
    CLOSE: 'Lukk',
    MORE: 'Les mer',
    AGREE_YOUTUBE: 'You must accept Youtube cookies to see this video',
    BUTTON_YOUTUBE: 'Accept cookies'
  },
  POPUP: { LAUNCH: 'POPUP-OUVERTURE-SITE' },
  FIELD_PLATE: { DISCOVER_VEGETABLE: 'Les mer om våre grønnsaker' },
  SEARCH: {
    OUR_RECIPES: 'Oppskrifter',
    OUR_PRODUCTS: 'Produkter',
    OUR_ARTICLES: 'Artikler',
    RECIPES: 'Oppskrifter',
    PRODUCTS: 'Produkter',
    ARTICLES: 'Artikler',
    ALL: 'Se alt',
    SORT_BY: 'Sortere etter',
    RESULTS: 'resultater',
    RESULT: 'resultat',
    PRODUCT_OUT: 'produkter av',
    APPLY: 'Bruk',
    FILTER: 'Filtrere',
    RECIPE_OUT: 'oppskrifter av',
    NO_RESULT: 'Ops... Vi finner ikke det du leter etter',
    SEE_MORE: 'Se mer',
    DISCOVER_RECIPES: 'Se våre oppskrifter',
    SEE_MORE_OF: 'Se flere',
    DELETE: 'slett'
  },
  RECIPE_LIST: {
    OUR_RECIPES: 'Oppskrifter',
    RESULTS: 'Resultater',
    SEE_MORE: 'Se flere oppskrifter'
  },
  PRODUCT: {
    BUTTON: {
      ACTU: 'produktnyheter',
      FOUND: 'Se hvor du kan finne dette produktet',
      DISCOVER: 'Se hele sortimentet'
    },
    NUTRITIONAL_VALUE: 'næringsinnhold',
    CONDITIONING: 'emballasje',
    PORTIONS: 'porsjoner',
    NET_WEIGHT: 'Nettovekt',
    DRY_WEIGHT: 'Drenert vekt',
    INGREDIENTS: 'Ingredienser',
    OUR_RECIPES: 'Se våre oppskrifter',
    FOR: 'per',
    PER_PORTION: 'og per porsjon av',
    ENERGY: 'Energi',
    FAT: 'Fett',
    SATURATE: 'Mettede fettsyrer',
    GLUCIDES: 'Karbohydrater',
    FIBER: 'Kostfiber',
    PROTEINS: 'Protein',
    SALT: 'Salt',
    DISCOVER_ALSO: 'Se også:',
    MORE_INFORMATION: 'mer informasjon',
    TRACES: 'Spor av',
    CONTAINS: 'Kan inneholde',
    SLIDER: { SHEET: 'se produkt', BUY: 'kjøp dette produktet' },
    NUTRISCORE: 'informasjon-nutriscore',
    TRIMAN: '',
    AGRICONFIANCE: '',
    BIO: 'informasjon-øko',
    PESTICIDE_FREE: '',
    LEAF: '',
    LOCK: '',
    SEE_ALL: 'Se alle produkter',
    LEGISLATION: 'i henhold til gjeldende lovgivning.',
    PORTION: { SINGULAR: 'porsjon', PLURAL: 'porsjoner' },
    DISCLAIMER: '',
    COOKING: 'Tilberedning',
    CONSERVATION: 'Oppbevaring'
  },
  FAQ: {
    ON: 'spørsmål om',
    ALL: 'Alle',
    SEE_MORE: 'se flere spørsmål',
    TITLE_1: 'FAQ – vanlige spørsmål',
    TITLE_2: 'Har du spørsmål om et bestemt produkt?',
    LINK_LABEL: 'Skriv til oss'
  },
  FORM: {
    GO_TO_STEP: 'Gå til trinn',
    STEP: 'Trinn',
    RECEIVE_EMAIL_COPY: 'Motta en kopi via e-post',
    SEND: 'Send',
    GDPR: 'Mer om håndtering av dine data',
    ERROR: 'Felt merket med * må fylles ut og være gyldige',
    SEND_ERROR: 'Det oppsto en feil. Prøv igjen senere.',
    NOTICE: 'For å kunne håndtere filen din på riktig måte kan vi, avhengig av saken, be deg om helsedata. Du kan selv velge om du vil laste dem opp.',
    SENDING: 'Skjemaet blir sendt...',
    LOADING: 'Laster inn...',
    PRODUCT_INFORMATION_TITLE: 'Spørsmål om et produkt',
    PRODUCT_INFORMATION: {
      SEO_TITLE: 'Spørsmål om et produkt | Bonduelle',
      SEO_DESC: 'Ta kontakt med vår kundeservice for spørsmål om våre produkter.'
    },
    INFORMATIONS: 'Dine opplysninger',
    MISTER: '',
    MISS: '',
    NAME: 'Etternavn',
    OUR_NAME: 'Ditt etternavn',
    FIRSTNAME: 'Fornavn',
    OUR_FIRSTNAME: 'Ditt fornavn',
    MAIL: 'Din e-postadresse',
    MAIL_PLH: '',
    MAIL_CONFIRM: 'Bekreft e-postadressen',
    MAIL_ERROR: 'Fyll ut en gyldig e-postadresse',
    MAIL_ERROR_CONFIRM: 'Bekreft e-postadressen',
    ADDRESS: 'Din adresse',
    STREET_PLH: '',
    STREET: 'Gate og gatenummer',
    ADDRESS_COMPLEMENT: 'Adresse 2',
    ADDRESS_COMP_PLH: 'Adresse 3',
    ZIPCODE: 'Postnummer',
    CITY: 'Sted',
    CITY_PLH: 'Sted',
    PHONE: 'Telefonnummer',
    PHONE_PLH: 'Telefonnummer',
    COUNTRY: 'Land',
    PRODUCT_INFORMATIONS: 'Produktinformasjon',
    PRODUCT_INFORMATION_DESC: 'For å kunne behandle henvendelsen din, ber vi deg fylle ut følgende opplysninger:',
    PRODUCT: {
      DETAIL: {
        DESC: 'Sammen med henvendelsen, kan du fylle ut relevante felter og legge til et bilde som eksempel. Bildene må vise produktets strekkode, batchnummer og utløpsdato.'
      },
      NOTE: {
        TITLE: 'Produktreklamasjon',
        SEO_TITLE: 'Send inn en produktreklamasjon | Bonduelle',
        SEO_DESC: 'Har du spørsmål om et produkt? Kontakt oss via e-post her.'
      }
    },
    PRODUCT_RANGE: 'Produktsortiment',
    PRODUCT_NAME: 'Produktnavn',
    PRODUCT_NAME_PLH: 'Skriv navnet på Bonduelle-produktet som henvendelsen din handler om.',
    PRODUCT_INFORMATIONS_DETAIL: 'Detaljert informasjon',
    PRODUCT_INFORMATIONS_LABEL: 'Produktets strekkode',
    PRODUCT_INFORMATIONS_PLH: 'Strekkoden består av 13 sifre – du finner den på emballasjen.',
    PRODUCT_LOT: 'Produktets batchnummer og utløpsdato',
    PRODUCT_LOT_INFO: 'Batchnummeret starter med en L <br> etterfulgt av en rekke tall. <br> Du finner det på baksiden av emballasjen <br> eller på bunnen av esken. <br> <br> For eksempel: L72450492107: 13.',
    PHOTO: 'Legg til et bilde',
    EXPIRED_DATE: 'Utløpsdato',
    EXPIRED_DATE_ERROR: 'Skriv inn datoen på følgende måte: dd/mm/åååå',
    EXPIRED_DATE_DESC: 'Utløpsdatoen finner du på emballasjen eller på bunnen av ​​esken.',
    PHOTO_MORE: 'Andre bilder',
    MESSAGE: 'Din melding*',
    OUR_MESSAGE: 'Skriv inn meldingen',
    SALES_OPERATIONS: { TITLE: '', SEO_TITLE: '', SEO_DESC: '' },
    PRESS_SERVICE: '',
    FOOD_SERVICE: 'Ta kontakt med vår Food Service avdeling',
    BUSINESS_INFORMATION: '',
    MODAL_INFORMATION: '',
    JOB_OFFER: { TITLE: '', SEO_TITLE: '', SEO_DESC: '' },
    WEB_SUGGESTION: {
      TITLE: 'Vil du foreslå forbedringer til nettstedet vårt?',
      SEO_TITLE: 'Forslag til nettstedet | Bonduelle',
      SEO_DESC: ''
    },
    ANOTHER: {
      TITLE: 'Spør om noe annet',
      SEO_TITLE: 'Kontakt oss hvis du har andre spørsmål eller kommentarer | Bonduelle',
      SEO_DESC: ''
    },
    AMBASSADOR: '',
    CATEGORY: {
      SALAD: 'salater',
      FROZEN: 'frossenmat',
      CAN: 'hermetikk',
      TAKEAWAY: 'takeaway',
      VEGETABLE: ''
    }
  },
  CONTACT: {
    TITLE: 'Hva kan vi hjelpe deg med?',
    SPECIAL_INFO: '',
    TAKE_CARE: '',
    BY_MAIL: 'Via post',
    BY_PHONE: 'På telefon',
    BY_SOURDLINE: '',
    MAIL_TEXT: 'BONDUELLE NORDIC A/S\nLyskær 3CD\nDK - 2730 Herlev',
    PHONE_TEXT: ' +45 70 70 29 75',
    SOURDLINE_TEXT: '',
    BY_DISTRIBUTOR: '',
    BY_DISTRIBUTOR_TEXT: '',
    SUBTITLE: 'Eller kontakt oss via e-post:',
    AGREEMENT: '',
    READ_MORE: '',
    READ_MORE_LABEL: '',
    SATISFACTION_TEXT: 'For å kontakte deg for å gi deg den forespurte informasjonen om\n' +
      'produktet og sende deg et tilfredshetsspørreskjema hvis du ønsker det, vil\n' +
      'vi behandle informasjonen som er oppgitt i vår egenskap av\n' +
      'behandlingsansvarlig. Det juridiske grunnlaget for å behandle\n' +
      'forespørselen din er samtykke på grunn av potensielle helserelaterte data\n' +
      'du kan oppgi i forespørselen. Denne informasjonen vil bli oppbevart så\n' +
      'lenge det er nødvendig for å behandle forespørselen din og i opptil 5 år for\n' +
      'å bevise at forespørselen din har blitt behandlet og for å overholde våre\n' +
      'juridiske forpliktelser. Det juridiske grunnlaget for å sende\n' +
      'tilfredshetsspørreskjemaet er også ditt samtykke. Denne informasjonen\n' +
      'vil bli oppbevart i den tiden som kreves for å sende\n' +
      'tilfredshetsspørreskjemaet og inntil maksimalt 1 år. For å finne ut mer om\n' +
      'behandlingen av dine personopplysninger, vennligst se vår\n' +
      '<a href="https://www.bonduelle.no/ekstern-personvernpolicy" target="_blank" class="underline">personvernerklæring</a>.',
    SATISFACTION_CHECKBOX: 'Ved å krysse av i denne boksen godtar du å motta et\n' +
      'tilfredshetsspørreskjema (bestående av ett enkelt spørsmål) når\n' +
      'forespørselen din er behandlet. Det er valgfritt.',
    CHECKBOX_TEXT: 'Ved å krysse av i denne boksen godtar du at Bonduelle kan behandle de\n' +
      'personlige helseopplysningene du måtte oppgi i meldingen din\n' +
      'utelukkende med det formål å svare på forespørselen din. <strong>Det er\n' +
      'obligatorisk for å behandle forespørselen din</strong>.',
    PHONE: 'Tlf.: +45 7070 2975\n',
    CALL_US: 'Ring oss',
    TO_WRITE: 'Skriv til oss',
    PHONE_NUMBER: '4570702975',
    SUCCESS_MESSAGE: 'Takk for din henvendelse. Meldingen din blir besvart innen 10 virkedager.',
    PHOTOERROR: { MESSAGE: 'bildefeilmelding' }
  },
  GLOBAL: { READ_MORE: '' },
  FLOWBOX: { GENERIC_KEY: '', PRODUCT_KEY: '', RECIPE_KEY: '', HOME_KEY: '' },
  TOKEN: {
    FACEBOOK: '',
    PAGE_FACEBOOK_ID: '',
    INSTAGRAM: '',
    INSTA_ID: '17841407279489116',
    YOUTUBE: '',
    TWITTER: '',
    MAGAZINE: ''
  },
  COOKIE: {
    BANNER: {
      MANDATORY: 'Nødvendig',
      ANALYTICS: 'Analyse',
      MARKETING: 'Nødvendig',
      SOCIAL_MEDIA: 'Markedsføring',
      ACCEPT: '\nGodkjenne alle',
      REJECT: 'Nekt alt',
      SET_UP: '\nAngi preferanser',
      MANDATORY_DETAILS: 'De påkrevde informasjonskapslene er nødvendige for driften av nettstedet, for eksempel for å lagre påloggingsinformasjon og gi deg en sikker tilkobling.\n' +
        'Informasjonskapslene som kreves for å bruke nettstedet bonduelle.fr er : \n' +
        '<ul>\n' +
        '<li>øktinformasjonskapsler: Informasjonskapsler generert av PHP-baserte programvarer. Dette er en generell identifikator som brukes til å administrere brukerøktvariabler. Det er vanligvis et tilfeldig generert tall, måten det brukes på kan være stedsspesifikk, men et godt eksempel er å opprettholde en påloggingsstatus for brukeren mellom sidene. </ li>\n' +
        '\n' +
        '</ul>',
      ANALYTICS_DETAILS: 'Funksjonelle informasjonskapsler hjelper oss med å optimalisere nettstedet, for eksempel for å samle inn statistikk, for å registrere preferansene dine for sosial interaksjon eller for å måle ytelsen og stabiliteten til applikasjonen vår.\n' +
        '<br>\n' +
        'De analytiske informasjonskapslene som brukes av nettstedet bonduelle.fr er : \n' +
        '<ul>\n' +
        '<li>DoubleClick: Denne informasjonskapselen innstilles av DoubleClick (eid av Google) for å opprette en profil over interessene til de besøkende på nettstedet og vise relevante annonser på andre nettsteder. Selskapets hovedaktivitet er utveksling av sanntids auksjonsannonser fra Google .</li>\n' +
        '<li>Google Analytics: Disse informasjonskapslene gjør det mulig å spore de mest besøkte sidene og trafikken på nettstedet.</li>\n' +
        '<li>UTMTracking: Denne informasjonskapselen gjør det mulig å administrere UTM-variabler for optimal oppfølging av de ulike annonsekampanjene på nettet</li>.\n' +
        '</ul>',
      MARKETING_DETAILS: 'Markedsføringsinformasjonskapsler lar oss spore preferansene dine for oppskrifter og produkter, slik at vi alltid kan tilby deg de produktene som passer ønskene dine best.\n' +
        '<br>\n' +
        'Markedsføringsinformasjonskapslene som brukes av nettstedet bonduelle.fr er : \n' +
        '<ul>\n' +
        '<li>Sosiale nettverk: Disse informasjonskapslene gjør det mulig å vise Bonduelle sin «SocialWall» på nettstedets hjemmeside.  «SocialWall»-veggen lar deg se alle de nyeste innleggene fra de store sosiale nettverkene som brukes av Bonduelle-teamene.<FlowBox: Denne informasjonskapselen gjør det mulig å bruke tjenestene til Flowbox-programvaren, som lar oss tilby de besøkende oppskrifter og opprettelser av andre Internett-brukere som bruker Bonduelle-produktene. </ li></ul> "',
      V2: 'ok'
    },
    MODAL: {
      TITLE: '\nInnstillinger for informasjonskapsler',
      TEXT: 'Bonduelle Group er forpliktet til å sikre beskyttelse av de personlige opplysningene til brukerne av sine nettsteder, og spesielt nettstedet Bonduelle.fr.  <br>For at du skal kunne dra nytte av tjenestene som tilbys på nettstedet, som f.eks. å vise produkter, oppdage nye gourmetoppskrifter, abonnere på visse tjenester som nyhetsbrevet vårt, og også optimalisere bruken av disse og tilpasse dem i henhold til profilen din, bruker nettstedet informasjonskapsler. Bonduelle Group er forpliktet til å sikre et høyt beskyttelsesnivå med hensyn til slike personopplysninger og til å overholde alle gjeldende personvernregler ved behandling av personopplysninger i forbindelse med bruk av nettstedet.\n' +
        'Derfor kan du når som helst aktivere/deaktivere informasjonskapsler.',
      CONFIG: '\nInnstillinger',
      SAVE: 'Lagre'
    }
  },
  LINK: { JOB_OFFERS: '' },
  CLIC2BUY: { INFOS: '' }
};
