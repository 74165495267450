<div class="video--with-background" [style.background-image]="section.background.uri ? 'url(' + options.apiUrl + section.background.uri.original + ')' : ''">
  <div *ngIf="section.title" class="--title">
    {{ section.title }}
  </div>
  <div *ngIf="section.text" class="--text" [innerHTML]="section.text | safePipe"></div>
  <div class="--video">
    <iframe
      *ngIf="section.video"
      class="sf-video-responsive"
      [src]="(section.video['value']) | checkYoutubeURL | sanitizeResourceUrl"
      allowfullscreen="allowfullscreen"
    ></iframe>

  </div>
</div>
