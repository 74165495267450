import { SomPage } from './page.model';
import { SfModel, SfSlide } from '@app/sf-lib/public_api';

export class SomHomePage extends SomPage {

  public slides: SfSlide[];

  public content: SfModel[];

  constructor() {
    super();
    this.slides = [];
    this.content = [];
  }
}
