<div class="sf-item-text-horizontal sf-main-border-bottom sf-main-color" *ngIf="item" [sfLink]="item.link" (mouseenter)="hover = true" (mouseleave)="hover = false">
  <div class="sf-item-text-horizontal-container">
    <div class="sf-item-text-horizontal-image">
      <sf-image [sfRollover]="hover" class="sf-item-text-horizontal-image-background" type="ratio_245_196" [image]="item.cover"></sf-image>
    </div>
    <div class="sf-item-text-horizontal-content">

      <!-- Tag -->
      <ng-container *ngIf="item.tag?.name" [ngSwitch]="seoTag">
        <h2 *ngSwitchCase="'H1'" class="sf-item-text-horizontal-content-tag sf-title">{{ (item.tag.name[0] && item.tag.name[0]['value'] ? item.tag.name[0]['value'] : item.tag?.name) }}</h2>
        <h2 *ngSwitchCase="'H2'" class="sf-item-text-horizontal-content-tag sf-title">{{ (item.tag.name[0] && item.tag.name[0]['value'] ? item.tag.name[0]['value'] : item.tag?.name) }}</h2>
        <h3 *ngSwitchCase="'H3'" class="sf-item-text-horizontal-content-tag sf-title">{{ (item.tag.name[0] && item.tag.name[0]['value'] ? item.tag.name[0]['value'] : item.tag?.name) }}</h3>
        <div *ngSwitchDefault class="sf-item-text-horizontal-content-tag sf-title">{{ (item.tag.name[0] && item.tag.name[0]['value'] ? item.tag.name[0]['value'] : item.tag?.name) }}</div>
      </ng-container>

      <!-- Title -->
      <ng-container *ngIf="item.title" [ngSwitch]="seoTitle">
        <h1 *ngSwitchCase="'H1'" class="sf-item-text-horizontal-content-title sf-title">{{ (item.title[0] && item.title[0]['value'] ? item.title[0]['value'] : item.title) | truncateString: 40 }}</h1>
        <h2 *ngSwitchCase="'H2'" class="sf-item-text-horizontal-content-title sf-title">{{ (item.title[0] && item.title[0]['value'] ? item.title[0]['value'] : item.title) | truncateString: 40 }}</h2>
        <h3 *ngSwitchCase="'H3'" class="sf-item-text-horizontal-content-title sf-title">{{ (item.title[0] && item.title[0]['value'] ? item.title[0]['value'] : item.title) | truncateString: 40 }}</h3>
        <div *ngSwitchDefault class="sf-item-text-horizontal-content-title sf-title">{{ (item.title[0] && item.title[0]['value'] ? item.title[0]['value'] : item.title) | truncateString: 40 }}</div>
      </ng-container>
    </div>
  </div>
</div>
