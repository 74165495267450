import { SfTypeParser } from '@app/sf-lib/public_api';

const types = {
  // SFLIB models
  'link': 'SfLink',
  'slide': 'SfSlide',
  'slide_1853': 'SfSlide',
  'item_stat': 'SfItemStat',
  'banner': 'SfBanner',
  'section_video': 'SfSectionVideo',
  'section_text': 'SfSectionText',
  'section_image': 'SfSectionImage',
  'section_slider': 'SfSectionSlider',
  'section_image_icon': 'SfSectionImageIcon',
  'section_script': 'SfSectionScript',
  'magazine_edito': 'SfEdito',
  'magazine_social_networks': 'SfSocialBanner',
  'social_networks': 'SfSocialNetwork',

  // Sombrero models
  'magazine_previous_editions': 'SomPreviousEdition',
  'magazine_prev_articles_category': 'SomPreviousArticlesByCategories',
  'magazine_showcase': 'SomMagazineShowcase',
  'magazine_monthly': 'SomMagazineMonthly',
  'magazine_previous_articles': 'SomPreviousArticles',
  'banner_link': 'SomHomeBanner',
  'highlights': 'SomHighlights',

  // Sombrero page models
  'accueil': 'SomHomePage',
  'magazine': 'SomMagazineHomePage',
  'magazine_article': 'SomMagazineArticlePage',
  'magazine_recipe': 'SomMagazineRecipePage',
  'section_jumbotron': 'SfSectionJumbotron',
  'section_reponsive_table': 'SfSectionResponsiveTable',
  'section_separator_maxi': 'SfSectionSeparatorMaxi',
  'section_separator_simple': 'SfSectionSeparatorSimple',
  'section_video_with_background': 'SfSectionVideoWithBackground',
  'item_ref_to_magazine_recipe': 'SomMagazineRecipePage',
  'generic_page': 'SomGenericPage',
  'corporate_page': 'SomCorporatePage',
  'corporate_article': 'SomCorporateArticlePage',
  'menu_page': 'SomCorporatePage',
  'menu_article': 'SomMenuPageArticlePage',
  'from_field_to_plate': 'SomFieldToPlatePage',
  'product_list': 'SomProductListPage',
  'recipe_list': 'SomRecipeListPage',
  'recipe_home': 'SomRecipeHomePage',
  'vegetable': 'SomVegetablePage',
  'faq_article': 'SomFaqArticlePage',
  'faq_section': 'SomFaqSection',
  'product_article_details': 'SomProductArticleDetails',
  'recipe_article_details': 'SomRecipeArticleDetails',
  'section_grid': 'SomSectionTiles',

  // Sombrero tiles models
  'tile_faq': 'SomTile',
  'tile_product': 'SomTile',
  'tile_corporate_article': 'SomTile',
  'tile_recipe': 'SomTile',
  'tile_generic_page': 'SomTile',
  'tile_magazine_article': 'SomTile',
  'tile_menu_article': 'SomTile',
  'banner_corporate_article': 'SomTile',
  'banner_generic_page': 'SomTile',
  'banner_product': 'SomTile',
  'banner_recipe': 'SomTile',
  'banner_faq': 'SomTile',
  'banner_menu_article': 'SomTile',
  'banner_magazine_article': 'SomTile',
};

export class SomTypeParser implements SfTypeParser {
  parseType(src: Object): Object {
    if (!src || !src['type']) {
      return src;
    }

    const sfType = types[src['type']];
    if (sfType) {
      src['sfType'] = sfType;
    } else {
      src['sfType'] = 'SfModel';
    }
  }
}
