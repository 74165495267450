<div class="sf-item-icon sf-accent-color" *ngIf="item" [sfLink]="item.link" (mouseenter)="hover = true" (mouseleave)="hover = false">
  <sf-image itemprop="image" class="u-photo" [sfRollover]="hover" class="sf-item-icon-background" [image]="item.cover"></sf-image>
  <sf-image itemprop="image" class="u-photo" *ngIf="item.icon" class="sf-item-icon-image" [image]="item.icon" align="right" fitting="contain"></sf-image>

  <!-- Title -->
  <ng-container *ngIf="item.title" [ngSwitch]="seoTitle">
    <h2 *ngSwitchCase="'H1'" class="sf-item-icon-title sf-title">{{ (item.title[0] && item.title[0]['value'] ? item.title[0]['value'] : item.title) }}</h2>
    <h2 *ngSwitchCase="'H2'" class="sf-item-icon-title sf-title">{{ (item.title[0] && item.title[0]['value'] ? item.title[0]['value'] : item.title) }}</h2>
    <h3 *ngSwitchCase="'H3'" class="sf-item-icon-title sf-title">{{ (item.title[0] && item.title[0]['value'] ? item.title[0]['value'] : item.title) }}</h3>
    <div *ngSwitchDefault class="sf-item-icon-title sf-title">{{ (item.title[0] && item.title[0]['value'] ? item.title[0]['value'] : item.title) }}</div>
  </ng-container>

</div>
