<div *ngIf="section" class="sf-section-slider">
  <div class="sf-section-slider-content">

    <!-- Title -->
    <ng-container *ngIf="section.text" [ngSwitch]="seoTitle">
      <h2 *ngSwitchCase="'H1'" class="sf-section-slider-content-title sf-title">{{section.title}}</h2>
      <h2 *ngSwitchCase="'H2'" class="sf-section-slider-content-title sf-title">{{section.title}}</h2>
      <h3 *ngSwitchCase="'H3'" class="sf-section-slider-content-title sf-title">{{section.title}}</h3>
      <div *ngSwitchDefault class="sf-section-slider-content-title sf-title">{{section.title}}</div>
    </ng-container>

    <!-- Text -->
    <div [innerHTML]="section.text ? (section.text | safePipe) : ''" class="sf-section-slider-content-text sf-text"></div>

  </div>

  <!-- Slider -->
  <sf-slider [slider]="section" [pace]="0" [template]="slideTemplate" mode="subjective">
    <ng-template #slideTemplate let-slide let-state="state">
      <sf-slide [isPinterestVisible]="isPinterestVisible" [slide]="slide" [state]="state" mode="subjective"></sf-slide>
    </ng-template>
  </sf-slider>
</div>
