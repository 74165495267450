import { SfModel } from '@app/sf-lib/public_api';

export class SomTheme extends SfModel {

  public name: string;

  public theme_id: string;

  constructor() {
    super();
  }
}
