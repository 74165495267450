import { SfMediaImage } from '../../image';
import { SfLink } from '../../link';
import { SfTag } from '../../../core/models/tag.model';

export interface SfItem {
  type?: any;
  display?: boolean;
  /** Title */
  title: string;
  /** Background Image */
  cover: SfMediaImage;
  /** Background Image for mobile*/
  smallcover?: SfMediaImage;
  /** Tag */
  tag?: SfTag;
  /** Link redirect to */
  link?: SfLink;

}

export function isSfItem(object: Object): object is SfItem {
  return 'title' in object && 'cover' in object;
}
