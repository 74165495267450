/* tslint:disable */export const be_nl = {
  '404': {
    TITLE: 'Oeps...',
    PAGENOTFOUND: 'Deze pagina bestaat niet',
    BUTTON: 'Terug naar de homepage',
    RECIPES: 'Ontdek onze recepten'
  },
  HOME: {
    SHOWCASE: 'Recepten',
    CATCH_PHRASE: 'De verrassende groente van vandaag... voor een beter morgen',
    SHOWCASE_SEE_ALL: 'Bekijk al onze recepten',
    MAGAZINE: { TITLE: 'TOP', BUTTON: 'Ontdek Légumiz' },
    OUR_VEGETABLES: 'Onze producten',
    SOCIAL_WALL: 'Laten we samen de passie van groenten delen',
    FAQ_TITLE: 'Veelgestelde vragen',
    FAQ_SEE_MORE: 'See more',
    FLOWBOX: ''
  },
  SELECT: { DEFAULT_VALUE: 'Bonduelle selectie' },
  MENU: {
    CONTACT_US: 'Neem contact met ons op',
    MY_ACCOUNT: 'Mijn account',
    GOOD_DEALS: 'Promoties',
    NEWSLETTER: 'Bonduelle Nieuwsbrief'
  },
  FOOTER: {
    LINK: {
      BONDUELLE: 'Bonduelle.nl',
      CREDITS: 'Credits',
      LEGAL_NOTICE: 'Gebruiksvoorwaarden',
      TERMS_OF_SALES: 'Algemene verkoopvoorwaarden',
      COOKIES: 'Cookies',
      SITEMAP: 'Sitemap',
      PRIVACY_POLICY: 'Privacybeleid',
      ALL_RIGHTS_RESERVED: '2019 © Alle rechten voorbehouden'
    },
    HISTORY: {
      TITLE: 'Over Bonduelle',
      TEXT: 'Hi. Wij zijn Bonduelle. De verrassende groente van vandaag, met onze blik op morgen. Want volgens ons ziet morgen er elke dag weer beter uit. Door alle kleine beetjes die we vandaag doen. Die stappen beginnen klein. Gewoon vanaf de keukentafel. Met ons dinerbord. Wij maken namelijk samen het verschil, simpelweg door lekker en beter te eten. Want, als we met z’n allen méér plantaardig eten, dan zorgen we niet alleen beter voor onszelf, maar ook voor de wereld. Op ons bord valt dus een wereld te winnen. Letterlijk! '
    },
    NEWSLETTER: {
      TITLE: 'Bonduelle Community',
      TEXT: 'Ik accepteer de nieuwsbrief «Alles over groenten van Bonduelle» (nieuws, promotie, prijsvragen, artikelen...)',
      PLACEHOLDER: 'Jouw email',
      GDPR_LINK: 'Meer over het beheren van je gegevens'
    }
  },
  PRODUCTS: 'Producten',
  MAGAZINE: {
    HOME: 'Welkom bij het magazine',
    CATCH_PHRASE: '',
    MENU: { BONDUELLE_LINK: 'Ga naar bonduelle.be' },
    SHOWCASE: 'Overzicht',
    PREVIOUS_ARTICLES: 'Vorige artikelen',
    PREVIOUS_ARTICLES_SEE_ALL: 'Bekijk al onze artikelen',
    PREVIOUS_EDITIONS: 'Onze vorige edities',
    PREVIOUS_EDITIONS_SEE_ALL: 'Bekijk alle edities',
    PREVIOUS_ARTICLES_BY_CATEGORY: 'Vorige artikelen per categorie',
    RUBRIC: {
      SEE_ALL: 'Bekijk alle recepten',
      SEE_ALL_ARTICLES: 'Bekijk alle artikelen',
      ON: 'Artikelen over'
    },
    RUBRIC_COOK: { ON: 'Recepten van' },
    ARTICLE: { JOIN_US: 'Doe met ons mee', READ_ALSO: 'Ook interessant' },
    RECIPE: {
      INGREDIENTS: 'Ingrediënten',
      INGREDIENTS_LABEL_DESC: 'voor {{value}} personen',
      INGREDIENTS_LABEL_ONE_DESC: 'voor {{value}} person',
      INGREDIENTS_LABEL: 'Ingrediënten voor {{value}} personen',
      INGREDIENTS_LABEL_ONE: 'Ingrediënten voor {{value}} personen',
      PEOPLES: '{{value}} personen',
      PEOPLE: '{{value}} person',
      DIFFICULTY: { EASY: 'Gemakkelijk', MEDIUM: 'Gemiddeld', HARD: 'Moeilijk' },
      COST: { CHEAP: 'Goedkoop', EXPENSIVE: 'Duur' },
      TIMING: { MIN: 'min', HOURS: 'uur' },
      STEP: 'Stap',
      DISCOVER_ALSO: 'Ontdek ook'
    }
  },
  ARTICLE: { DISCOVER_ALSO: 'Ontdek ook...', INGREDIENTS: 'Ingrediënten' },
  SF: {
    SLIDE: { DISCOVER: 'Lees verder' },
    SEARCH: { PLACEHOLDER: 'Zoeken', ALL: 'Alles' },
    NEWSLETTER: {
      BTN_LABEL: 'Ik registreer',
      PLACEHOLDER: 'Jouw email',
      ERROR_MESSAGE: 'Ongeldige e-mail',
      ALREADY_SUBSCRIBED: 'Je bent al ingeschreven',
      GDPR: {
        TXT: 'Meer informatie over het beheren van je gegevens',
        LINK: '/privacybeleid'
      }
    },
    MONTHLY_FOLDER: { TITLE: 'Nieuws' },
    MINI_NEWSLETTER: {
      PLACEHOLDER: 'Jouw email',
      GDPR: {
        TXT: 'Meer informatie over het beheren van je gegevens',
        LINK: '/privacybeleid'
      }
    },
    SOCIALITEM: { SHARE: 'Delen', COMMENT: 'Reageren', BONDUELLE: 'Bonduelle' },
    SEARCHRECIPE: { DISCOVER_ALL: 'Zoek in alle recepten' },
    FORM: {
      ERROR: 'Ongeldig veld',
      PHOTO: {
        ADD: 'Voeg een foto toe',
        DELETE: 'Verwijder een foto',
        SEE: 'Bekijken'
      }
    }
  },
  CORPORATE: {
    COMPANY_STORY: 'Geschiedenis',
    CSR_COMMITMENT: 'MVO',
    VEGETABLE_INNOVATIONS: 'Innovatie',
    NEWS: 'Alle activiteiten',
    SEE_MORE: 'Bekijk meer artikelen',
    ON: 'Artikelen over'
  },
  NEWSLETTER: { TITLE: '', SUBTITLE: '' },
  ROUTES: {
    products: 'producten',
    news: 'nieuws',
    'since-1853': 'over-ons',
    'field-plate': 'van-het-veld-tot-het-bord',
    'company-story': 'geschiedenis',
    'csr-commitments': 'mvo',
    'vegetal-innovations': 'innovatie',
    magazine: 'magazine',
    all: 'allemaal',
    article: 'artikel',
    cook: 'koken',
    cultivate: 'verbouwen',
    explore: 'onderzoeken',
    recipe: 'wait-recept',
    recipes: 'recept',
    'recipes-home': 'recepten',
    r: 'r',
    credits: 'credits',
    'legal-notice': 'gebruiksvoorwaarden',
    'terms-of-sales': 'verkoopvoorwaarden',
    cookies: 'cookies',
    sitemap: 'sitemap',
    'privacy-policy': 'privacybeleid',
    'all-rights-reserved': 'alle-rechten-voorbehouden',
    search: 'zoek',
    'articles-search': 'artikel',
    vegetables: 'producten',
    p: 'p',
    contact: 'neem-contact-met-ons-op',
    'info-product': 'vragen-product',
    'note-product': 'product-opmerking',
    suggestion: 'suggestie',
    'other-request': 'ander-verzoek',
    faq: 'faq',
    'all-themes': 'alle',
    'products-search': 'producten-search',
    recipesearch: 'recepten-search',
    'product-news': 'nieuwe-producten',
    'good-deals': 'promoties',
    'not-found': 'niet-gevonden',
    'menu-page': 'voor-een-beter-morgen',
    'menu-one': 'plantaardige-inspiratie',
    'menu-two': 'recyclebare-verpakkingen',
    'menu-three': 'biodiversiteit-en-bodem',
    'menu-four': 'voedselverspilling',
    'menu-external': '',
    'theme-pasta-rice': 'groentepasta-rijst',
    'theme-bowls': 'bowls',
    'theme-soup': 'soepen',
    'theme-snack': 'hapjes',
    'theme-sauce': 'spreads',
    'theme-unique-plate': 'eenpansgerechten',
    'theme-over': 'overige-gerechten',
    'theme-legumin': 'peulvruchten',
    'theme-ingredients-recipes': 'maaltijdverrijkers',
    'theme-cook-plate': 'bonengerechten'
  },
  BREAD: {
    products: 'producten',
    news: 'nieuws',
    'since-1853': 'over-ons',
    'field-plate': 'van-het-veld-tot-het-bord',
    'company-story': 'geschiedenis',
    'csr-commitments': 'mvo',
    'vegetal-innovations': 'innovatie',
    magazine: 'magazine',
    all: 'allemaal',
    article: 'artikel',
    cook: 'koken',
    cultivate: 'verbouwen',
    explore: 'onderzoeken',
    recipe: 'wait-recept',
    recipes: 'recept',
    'recipes-home': 'recepten',
    'articles-search': 'artikel',
    search: 'zoek',
    vegetables: 'producten',
    contact: 'neem-contact-met-ons-op',
    'info-product': 'vragen-product',
    'note-product': 'product-opmerking',
    suggestion: 'suggestie',
    'other-request': 'ander-verzoek',
    faq: 'faq',
    'all-themes': 'alle',
    'products-search': 'producten-search',
    recipesearch: 'recepten-search',
    'product-news': 'nieuwe-producten',
    'good-deals': 'promoties',
    'not-found': 'niet-gevonden',
    'menu-page': 'voor een beter morgen',
    'menu-one': 'plantaardige inspiratie',
    'menu-two': 'recyclebare verpakkingen',
    'menu-three': 'biodiversiteit en bodem',
    'menu-four': 'voedselverspilling'
  },
  MENU_PAGE: {
    TITLE1: 'Plantaardige inspiratie',
    TITLE2: 'Recyclebare verpakkingen',
    TITLE3: 'Biodiversiteit en bodem',
    TITLE4: 'Voedselverspilling',
    EXTERNAL: ''
  },
  TECHNICAL: { MENU_PAGE: '' },
  SEO: {
    TITLE: {
      MENU_ONE: 'Plantaardige inspiratie',
      MENU_TWO: 'Recyclebare verpakkingen',
      MENU_THREE: 'Biodiversiteit en bodem',
      MENU_FOUR: 'Voedselverspilling',
      HOME: 'Bonduelle: ontdek verrassende groenten en recepten',
      SEARCH: {
        MAIN: '{{value}} | Bonduelle',
        RECIPES: '{{value}} gesorteerd op recept | Bonduelle',
        VEGETABLES: '{{value}} gesorteerd op groente | Bonduelle',
        ARTICLES: '{{value}} gesorteerd op artikel | Bonduelle'
      },
      CONTACT: 'Consumentenservice | Bonduelle',
      RECIPES: 'Recepten: Soepen, Salades, Hoofdgerechten, ... | Bonduelle\n',
      RECIPE_HOME: 'De recepten van Bonduelle',
      RECIPE: 'Recept {{value}} | Bonduelle',
      PRODUCTS: 'Groenten, peulvruchten, granen & zaden | Bonduelle',
      NEWS: 'Ontdek de diversiteit van groenten | Bonduelle',
      CORPORATE: {
        NEWS: 'Recente artikelen en nieuws over groenten | Bonduelle',
        COMPANY_STORY: 'Ontdek Bonduelle, haar geschiedenis en betrokkenheid | Bonduelle',
        CSR_COMMITMENTS: 'Ontdek onze etische waarden, prinicpes en inzet | Bonduelle',
        VEGETAL_INNOVATIONS: 'Ontdek onze innovaties: nieuwe boerderijen, producten en agricultuur | Bonduelle',
        FIELD_PLATE: 'Groenten: het beste voor de beste recepten | Bonduelle',
        ARTICLE: '{{value}} | Bonduelle',
        VEGETABLE: 'Receptideëen {{value}} | Bonduelle'
      },
      PRODUCT: '{{value}}: Bonduelle groenten',
      MAGAZINE: {
        NEWS: 'Artikel over de variëteit aan groenten, recepten, teelt, nieuws | Bonduelle',
        ARTICLE: 'Bonduelle',
        COOK: 'Bonduelle',
        GROW: 'Bonduelle',
        EXPLORE: 'Bonduelle',
        RECIPE: 'Bonduelle'
      },
      FAQ: 'Veelgestelde vragen | Bonduelle',
      NOTFOUND: 'Foutcode 404, probeer het later opnieuw | Bonduelle',
      RECIPES_ALL: 'Bekijk al onze recepten',
      RECIPETYPE: 'Recept: {{value}} van groente',
      RECIPESEASON: 'Seizoensgroenten recept: {{value}}',
      RECIPEPREPARATION: 'Recepten: bereidingstijd {{value}}',
      RECIPEDIFFICULTY: 'Eenvoudig recept {{value}}',
      RECIPEVEGETABLE: 'Eenvoudig recept op basis van {{value}}',
      RECIPECOST: 'Recept & groente {{value}}',
      RECIPEDISH: 'Groente recepten voor {{value}}',
      RECIPEDESC: 'Recept & groente {{value}}',
      '2RECIPEFACETS': 'Recept {{value}}',
      '4RECIPEFACETS': 'Recept {{value}}',
      PRODUCTSEASON: 'Eenvoudig seizoensrecept: {{value}}',
      PRODUCTTECHNO: 'Gemakkelijk groente recept {{value}}',
      PRODUCTVEGETABLE: '{{value}}',
      PRODUCTRANGE: '{{value}}',
      THEME_ALL: 'Vers verwerkte en vriesverse groenten',
      THEME_STARTER: 'Groente idee voor je recept',
      THEME_SALAD: 'Groente ideeën voor salades',
      THEME_CAN: '',
      THEME_FROZEN: '',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: '',
      THEME_RECIPE: 'Groente idee voor het bereiden van snelle / eenvoudige recepten',
      THEME_PLATE: 'Groente ideeën voor het bereiden van warme en koude gerechten',
      THEME_ACC: 'Groenten passend bij vlees en vis',
      THEME_SNACK: 'Groente ideeën voor snelle recepten',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': 'Groenterecept {{value}}'
    },
    DESCRIPTION: {
      MENU_ONE: 'Ontdek hier plantaardige inspiratie van Bonduelle zodat jij lekkere plantaardige gerechten kan koken ',
      MENU_TWO: 'Leer hier over de recyclebare verpakkingen van Bonduelle. De blikjes en plastic zakken van Bonduelle zijn duurzaam voor een beter morgen. ',
      MENU_THREE: 'Ontdek hier wat Bonduelle doet om bioversiteit te boosten en de bodem te beschermen voor een beter morgen. ',
      MENU_FOUR: 'Lees hier wat Bonduelle doet om voedselverspilling tegen te gaan en hoe jij daarbij kan helpen. Dit is goed voor de wereld en jouw portemonnee.',
      HOME: 'Groenten, granen en zaden. Ontdek onze producten en receptinspiratie ',
      SEARCH: {
        MAIN: 'Ontdek onze receptideëen voor {{value}}. Lekere en gemakkelijke recepten.',
        RECIPES: 'Al onze {{value}} gesorteerd op recept. Lekkere en gemakkelijke recepten.',
        VEGETABLES: 'Al onze {{value}} gesorteerd op groenten. Lekkere en gemakkelijke recepten. ',
        ARTICLES: 'Al onze {{value}} gesorteerd op artikel. Lekkere en gemakkelijke recepten. '
      },
      CONTACT: 'Heeft u een vraag over een product? U kunt contact opnemen met onze consumentenservice. ',
      RECIPES: 'Ontdek heerlijke recepten met groenten. Snel, gemakkelijk en lekker.',
      RECIPE_HOME: 'Ontdek heerlijke recepten met groenten. Verras je geliefde op een speciale gelegenheid.',
      RECIPE: '',
      PRODUCTS: 'Met onze diverse groenten kun je heerlijke en eenvoudige recepten bereiden. ',
      NEWS: 'Ontdek hoe gemakkelijk het is om onze groenten te bereiden en snel heerlijke maaltijd op tafel te zetten.',
      CORPORATE: {
        NEWS: 'Het laatste nieuws uit de wereld over de landbouw, plantinnovatie, voedselverspiling en hoe je op een eenvoudige manier beter kunt eten.',
        COMPANY_STORY: 'Wil je meer weten over onze groenten, landbouw en innovaties? Ontdek dit en nog veel meer op onze blog.',
        CSR_COMMITMENTS: 'Vraag je jezelf af hoe onze groenten worden geteeld, waar onze groenten vandaan komen en hoe we omgaan met het milieu? Hier vind je meer informate.',
        VEGETAL_INNOVATIONS: 'Groenten en innovatie staan voor ons centraal. Nieuwe manieren om duurzamer te produceren, verspilling tegen te gaan. Lekkere en gemakkelijke recepten.',
        VEGETABLE: '',
        FIELD_PLATE: 'Ontdek onze heerlijke recepten. Eenvoudig en met lekker veel groenten!',
        ARTICLE: ''
      },
      PRODUCT: '{{value}} om een heerlijk recept te bereiden.',
      MAGAZINE: {
        NEWS: '',
        ARTICLE: '',
        COOK: '',
        GROW: '',
        EXPLORE: '',
        RECIPE: ''
      },
      FAQ: 'Heb je een vraag? Hier vind je de antwoorden op de meestgestelde vragen.',
      PAGE: 'pagina ',
      RECIPETYPE: 'Ontdek onze recepten {{value}}. Snel, eenvoudig en lekker.',
      RECIPESEASON: 'Ontdek onze recepten en hoe je je groenten bereid in {{value}}. Snel, eenvoudig en lekker!',
      RECIPEPREPARATION: 'Ontdek onze recepten en hoe je je groenten kan bereiden in {{value}}. Snel, eenvoudig en lekker.',
      RECIPEDIFFICULTY: 'Ontdek onze recepten en hoe je je groenten kan bereiden. Snel, eenvoudig en lekker.',
      RECIPEVEGETABLE: 'Ontdek onze recepten en hoe je je groenten kan bereiden. Snel, eenvoudig en lekker.',
      RECIPECOST: 'Ontdek onze recepten en hoe je je groenten kan bereiden voor {{value}}. Snel, eenvoudig en lekker.',
      RECIPEDISH: 'Ontdek onze recepten en hoe je je groenten kan bereiden voor {{value}} gasten. Snel, eenvoudig en lekker!',
      RECIPEDESC: 'Ontdek onze recepten en hoe je je groenten bereid {{value}}. Snel, eenvoudig en lekker.',
      '2RECIPEFACETS': 'Ontdek onze recepten en hoe je je groenten bereid {{value}}. Snel, eenvoudig en lekker.',
      '4RECIPEFACETS': 'Ontdek onze recepten en hoe je je groenten kan bereiden {{value}}.',
      PRODUCTSEASON: 'Met ons brede assortiment aan groenten kun je lekkere, gemakkelijke en uitgebalanceerde seizoensgebonden {{value}} recepten bereiden. Eenvoudige en lekkere recepten. Gekoote groenten.',
      PRODUCTTECHNO: 'Met ons brede assortiment aan groenten kun je lekkere, gemakkelijke en evenwichtige recepten bereiden {{value}}. Eenvoudige en lekkere recepten. Gekookte groenten.',
      PRODUCTVEGETABLE: 'Eenvoudig groente recept op basis van {{value}}',
      PRODUCTRANGE: 'Eenvoudig groente recept op basis van {{value}}',
      THEME_ALL: 'Met ons brede assortiment aan groenten kun je lekkere, gemakkelijke en evenwichtige recepten bereiden. Eenvoudige en lekkere recepten. Gekookte groenten.',
      THEME_STARTER: 'Wil je een uitgebalanceerde maaltijd bereiden? Grote keuze aan snelle, eenvoudige en lekkere groente recepten.',
      THEME_SALAD: 'Wil je een uitgebalanceerde maaltijd bereiden? Een brede selectie aan groenten voor het bereiden van salades. Snel, eenvoudig en lekker!',
      THEME_CAN: '',
      THEME_FROZEN: '',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: '',
      THEME_RECIPE: 'Groenten voor het bereiden van lekkere en evenwichtige recepten. Tips en trucs voor het bereiden van je maaltijden. Snelle en uitgebalanceerde recepten.',
      THEME_PLATE: 'Wil je een uitgebalanceerde maaltijd bereiden? Groenten voor het bereiden van warme of koude gerechten met eenvoudige, snelle en lekkere recepten.',
      THEME_ACC: 'Groenten voor eenvoudige en snelle groentegarnituren. ',
      THEME_SNACK: 'Wil je een uitgebalanceerde maaltijd bereiden? Groenten voor het bereiden van hapjes en snacks. Eenvoudig en snel. Tips en trucs.',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': 'Met ons brede assortiment aan groenten kun je lekkere, gemakkelijke en evenwichtige recepten bereiden {{value}}. Eenvoudige en lekkere recepten. Gekookte groenten.',
      NOTFOUND: 'De gevraagde pagina kon niet worden gevonden. Voer een nieuwe zoekopdracht in of probeer het later opnieuw.'
    },
    PIPE: { SOCIETY: ' | Bonduelle' },
    THEME_ALL: 'Alles',
    THEME_STARTER: 'Voorgerechten',
    THEME_SALAD: 'all-in-my-salad',
    THEME_CAN: '',
    THEME_FROZEN: '',
    THEME_READY_MEALS: '',
    THEME_BIO: '',
    THEME_VEGETABLES: '',
    THEME_RECIPE: 'recept-ingrediënten',
    THEME_PLATE: 'Hoofdgerecht',
    THEME_ACC: 'bijgerechten',
    THEME_SNACK: 'snacks'
  },
  VEGETABLE: {
    DISCOVER_VEGETABLE: 'Ontdekn onze groenten',
    OURS_RECIPES: 'Onze recepten',
    DISCOVER_ALSO: 'Ontdek ook...',
    SEE_ALL: 'Bekijk al onze groenten',
    ON: 'groenten van'
  },
  RECIPE: {
    'INGREDIENTS_LABEL ': 'Ingrediënten voor {{value}} personen',
    PEOPLES: '{{value}} personen',
    DIFFICULTY: { EASY: 'gemakkelijk', MEDIUM: 'gemiddeld', HARD: 'moeilijk' },
    COST: { CHEAP: 'goedkoop', EXPENSIVE: 'duur' },
    TIMING: { MIN: 'min ', HOURS: 'uur' },
    STEP: 'Stap',
    DISCOVER_ALSO: 'Ontdek ook...',
    HOME_SEE_MORE: 'Bekijk meer recepten',
    SHOWCASE: 'Nieuw',
    HOME_BOOK: 'Onze recepten',
    MIN_MORE: 'm and +',
    HOUR_MORE: 'h and +',
    FILTER_BY_CRITERIA: 'filter op criteria',
    SORT_BY: 'sorteer naar',
    BEST_RATED: 'best beroordeeld',
    MOST_CONSULTED: 'meest bekeken',
    MEMBERS_RECIPES: 'Recepten van members',
    CRITERIA: 'Nieuws',
    DISCOVER: { RECIPES: 'Ontdek onze recepten' },
    DISCOVER_RECIPE: 'Ontdek het recept',
    INSTRUCTIONS: 'Instructies',
    DEFAULT_TAG: 'Recept idee',
    MOST_RECENT: 'Meest recent',
    RECIPE_VIDEOS: 'recept video',
    PRODUCT_ASSOCIATE: 'recept gemaakt met',
    BUDGET_LOW: 'goedkoop',
    BUDGET_HIGH: 'hoog',
    BUDGET_MIDDLE: 'gemiddeld',
    PRINT_RECIPE: 'print recept',
    LET_YOURSELF_SURPRISED: 'Verras jezelf',
    COOK_TIP_TITLE: '',
    ENJOY_MEAL: '',
    FILTER: { LEGEND: 'Legend' }
  },
  PREDIGGO: {
    DIFFICULTY: { EASY: 'gemakkelijk', MEDIUM: 'gemiddeld', HARD: 'moeilijk' }
  },
  COOKIES: {
    TEXT: 'Door uw bezoek aan deze site voort te zetten, accepteert u het gebruik van cookies om technische redenen, ten behoeve van statistische analyse en webverkeerbeheer, het delen van informatie met adverteerders op andere websites, om u relevante promotionele communicatie te bieden die uw interesses weerspiegelen.',
    CLOSE: 'Sluiten',
    MORE: 'Kom meer te weten',
    AGREE_YOUTUBE: 'Je moet de Youtube cookies accepteren om deze video te bekijken.',
    BUTTON_YOUTUBE: 'Accept cookies'
  },
  POPUP: { LAUNCH: 'POPUP-OUVERTURE-SITE' },
  FIELD_PLATE: { DISCOVER_VEGETABLE: 'Ontdek onze groentebladen' },
  SEARCH: {
    OUR_RECIPES: 'Onze recepten',
    OUR_PRODUCTS: 'Onze producten',
    OUR_ARTICLES: 'Onze artikelen',
    RECIPES: 'Onze recepten',
    PRODUCTS: 'Onze producten',
    ARTICLES: 'Artikelen',
    ALL: 'Alles',
    SORT_BY: 'Sortér efter:',
    RESULTS: 'resultaten',
    RESULT: 'resultaat',
    PRODUCT_OUT: 'producten van',
    APPLY: 'toepassen',
    FILTER: 'Filter',
    RECIPE_OUT: 'recepten van',
    NO_RESULT: 'Oeps',
    SEE_MORE: 'Bekijk meer',
    DISCOVER_RECIPES: 'Ontdek onze recepten',
    SEE_MORE_OF: 'Bekijk meer van',
    DELETE: 'verwijder'
  },
  RECIPE_LIST: {
    OUR_RECIPES: 'Recepten',
    RESULTS: 'Resultaten',
    SEE_MORE: 'Bekijk meer recepten'
  },
  PRODUCT: {
    BUTTON: {
      ACTU: 'Product nieuws',
      FOUND: 'Waar kan ik dit product vinden',
      DISCOVER: 'Bekijk het hele assortiment'
    },
    NUTRITIONAL_VALUE: 'voedingswaarde',
    CONDITIONING: 'Inhoud',
    PORTIONS: 'porties',
    NET_WEIGHT: 'netto gewicht',
    DRY_WEIGHT: 'uitlekgewicht',
    INGREDIENTS: 'ingrediënten',
    OUR_RECIPES: 'Ontdek onze recepten',
    FOR: 'voor',
    PER_PORTION: 'en per portie van',
    ENERGY: 'energie',
    FAT: 'vet',
    SATURATE: 'verzadigd vet',
    GLUCIDES: 'koolhydraten',
    FIBER: 'vezels',
    PROTEINS: 'eiwitten',
    SALT: 'zout',
    DISCOVER_ALSO: 'Ontdek ook',
    MORE_INFORMATION: 'meer informatie',
    TRACES: 'Sporen van',
    CONTAINS: 'Bevat',
    SLIDER: { SHEET: 'bekijk het product', BUY: 'buy this product' },
    NUTRISCORE: 'informatie-nutriscore',
    TRIMAN: 'informatie-triman',
    AGRICONFIANCE: 'informatie-agriconfiance',
    BIO: 'informatie-bio',
    PESTICIDE_FREE: '',
    LEAF: '',
    LOCK: '',
    SEE_ALL: 'Bekijk alle producten',
    LEGISLATION: 'in overeenstemming met de geldende wetgeving.',
    PORTION: { SINGULAR: 'portion', PLURAL: 'portions' },
    DISCLAIMER: '',
    COOKING: 'Bereiding',
    CONSERVATION: 'Bewaren'
  },
  FAQ: {
    ON: 'vragen over',
    ALL: 'Alles',
    SEE_MORE: 'bekijk meer vragen',
    TITLE_1: 'Alle antwoorden op jouw vragen',
    TITLE_2: 'Heeft u een vraag over een bepaald product ?',
    LINK_LABEL: 'Schrijf ons'
  },
  FORM: {
    GO_TO_STEP: 'Ga naar stap',
    STEP: 'Stap',
    RECEIVE_EMAIL_COPY: 'Ontvang een kopie per e-mail',
    SEND: 'Verzenden',
    GDPR: 'Meer over het beheren van je gegevens',
    ERROR: 'Alle velden met een * zijn verplicht',
    SEND_ERROR: '\nEr is een fout opgetreden. Probeer het over een paar seconden opnieuw.',
    NOTICE: 'Voor de juiste afhandeling van je vraag of opmerking, kan Bonduelle je, afhankelijk van de kwestie, om gezondheidsgegevens vragen. Het is niet verplicht om dit te beantwoorden.',
    SENDING: 'Verzend het formulier',
    LOADING: 'Laden...',
    PRODUCT_INFORMATION_TITLE: 'Heb je een vraag over een product?',
    PRODUCT_INFORMATION: {
      SEO_TITLE: 'Vragen over een product? | Bonduelle',
      SEO_DESC: 'Neem contact op met onze consumentenservice voor informatie over een product.'
    },
    INFORMATIONS: 'Jouw gegevens',
    MISTER: 'Meneer',
    MISS: 'Mevrouw',
    NAME: 'Naam',
    OUR_NAME: 'Jouw naam',
    FIRSTNAME: 'Voornaam',
    OUR_FIRSTNAME: 'Jouw voornaam',
    MAIL: 'Jouw e-mailadres',
    MAIL_PLH: '',
    MAIL_CONFIRM: 'Bevestig jouw e-mailadres',
    MAIL_ERROR: 'Voer een geldig e-mailadres in',
    MAIL_ERROR_CONFIRM: 'Bevestig jouw e-mailadres',
    ADDRESS: 'Jouw adres',
    STREET_PLH: '',
    STREET: 'Straat',
    ADDRESS_COMPLEMENT: 'Toevoeging van adres',
    ADDRESS_COMP_PLH: 'Aanvullende informatie',
    ZIPCODE: 'Postcode',
    CITY: 'Woonplaats',
    CITY_PLH: 'Jouw woonplaats',
    PHONE: 'Telefoon',
    PHONE_PLH: 'Jouw telefoonnummer',
    COUNTRY: 'Land',
    PRODUCT_INFORMATIONS: 'Productinformatie',
    PRODUCT_INFORMATION_DESC: 'Voer de volgende informatie in voor onze consumentenservice om jouw verzoek te behandelen:',
    PRODUCT: {
      DETAIL: {
        DESC: "Voor alle gevraagde gegevens kun je het betreffende veld invullen en een foto toevoegen ter illustratie. Op de foto's moeten de barcode, het lotnummer en de houdbaarheidsdatum van het product duidelijk zichtbaar zijn.<br>\n" +
          "Note: Foto's via Pc of laptop - geen camera functie aanwezig."
      },
      NOTE: {
        TITLE: 'Heb je een opmerking over een product?',
        SEO_TITLE: 'Maak een opmerking over een product | Bonduelle',
        SEO_DESC: 'Een vraag of opmerking over een product? Neem contact op met onze consumentenservice om u te helpen. '
      }
    },
    PRODUCT_RANGE: 'Productgroep',
    PRODUCT_NAME: 'Naam van het product',
    PRODUCT_NAME_PLH: 'Noteer de naam van het Bonduelle product waarop je aanvraag betrekking heeft',
    PRODUCT_INFORMATIONS_DETAIL: '\nGedetailleerde informatie',
    PRODUCT_INFORMATIONS_LABEL: 'Barcode van het product',
    PRODUCT_INFORMATIONS_PLH: 'De barcode bestaat uit 13 cijfers op de verpakking van het product.',
    PRODUCT_LOT: 'Lotnummer van het product',
    PRODUCT_LOT_INFO: 'Het lotnummer begint met een L<br>gevolgd door een aantal cijfers.<br>Je kan deze vinden op de achterkant<br>van de diepvriesverpakking<br>of aan de onderkant van het blik.<br><br>Bijvoorbeeld: L72450492107:13.',
    PHOTO: 'Voeg een foto toe',
    EXPIRED_DATE: 'Houdbaarheidsdatum',
    EXPIRED_DATE_ERROR: 'Voer de datum als volgt in: dd/mm/jjjj',
    EXPIRED_DATE_DESC: 'De houdbaarheidsdatum staat weergegeven op de achterkant van de diepvriesverpakking of aan de onderkant van het blik.',
    PHOTO_MORE: "Extra foto's",
    MESSAGE: 'Uw bericht *',
    OUR_MESSAGE: 'schrijf je bericht',
    SALES_OPERATIONS: {
      TITLE: '\nInformatie over een commerciële transactie',
      SEO_TITLE: '',
      SEO_DESC: 'Neem contact op met onze consumentenservice voor informatie over een actuele commerciële transactie. '
    },
    PRESS_SERVICE: 'Een vraag voor de pers?',
    FOOD_SERVICE: 'Vragen of opmerkingen voor Bonduelle Foodservice?',
    BUSINESS_INFORMATION: '',
    MODAL_INFORMATION: '',
    JOB_OFFER: { TITLE: '', SEO_TITLE: '', SEO_DESC: '' },
    WEB_SUGGESTION: {
      TITLE: 'Suggesties over de website?',
      SEO_TITLE: 'Bericht voor verbetering, suggestie voor de website | Bonduelle',
      SEO_DESC: 'Neem contact op met onze consumentenservice om ons te helpen de website te verbeteren.'
    },
    ANOTHER: {
      TITLE: 'Een ander verzoek?',
      SEO_TITLE: 'Andere vragen, opmerkingen of suggesties? | Bonduelle',
      SEO_DESC: 'Neem contact op met onze consumentenservice voor vragen, opmerkingen of suggesties.'
    },
    AMBASSADOR: 'Wordt een ambassadeur van Bonduelle',
    CATEGORY: { SALAD: '', FROZEN: '', CAN: '', TAKEAWAY: '', VEGETABLE: '' }
  },
  CONTACT: {
    TITLE: 'Hoe kunnen we je helpen?',
    SPECIAL_INFO: '',
    TAKE_CARE: '',
    BY_MAIL: 'Per post',
    BY_PHONE: 'Per telefoon',
    BY_SOURDLINE: '',
    MAIL_TEXT: 'Bonduelle Benelux<br>\n' +
      't.a.v. Consumentenservice<br>\n' +
      'Postbus 28058<br>\n' +
      '5602 JB Eindhoven',
    PHONE_TEXT: 'Onze medewerkers zijn tot uw beschikking op telefoonnummer <a href="tel:0800 71262"><b>0800 71262</b></a> (gratis).',
    SOURDLINE_TEXT: '',
    BY_DISTRIBUTOR: '',
    BY_DISTRIBUTOR_TEXT: '',
    SUBTITLE: 'Of je kan een van onderstaande contactformulieren gebruiken:',
    AGREEMENT: '',
    READ_MORE: "Bovendien heeft u te allen tijde het recht om BONDUELLE te verzoeken uw recht op toegang, rectificatie, verwijdering en overdraagbaarheid van uw persoonsgegevens uit te oefenen, evenals uw recht om de verwerking van uw persoonsgegevens te beperken en er bezwaar tegen te maken. Om uw rechten met betrekking tot uw persoonlijke gegevens uit te oefenen, kunt u schrijven naar Dpo_france(at)bonduelle(dot)com of per post naar het volgende adres: ter attentie van de functionaris voor gegevensbescherming, Bonduelle, Rue Nicolas Appert, postbus 30173, F- 59653 Villeneuve D'Ascq. Voor meer informatie over de manier waarop BONDUELLE uw persoonsgegevens verzamelt en gebruikt, klikt u hier",
    READ_MORE_LABEL: 'Lees ook',
    SATISFACTION_TEXT: 'Om contact met u op te nemen om u de gevraagde informatie over het\n' +
      'product te verstrekken en om u desgewenst een tevredenheidsvragenlijst\n' +
      'te sturen, verwerken wij de verstrekte informatie in onze hoedanigheid\n' +
      'van gegevensbeheerder. De wettelijke basis voor het verwerken van uw\n' +
      'verzoek is toestemming vanwege de mogelijke gezondheidsgerelateerde\n' +
      'gegevens die u mogelijk in uw verzoek verstrekt. Deze gegevens worden\n' +
      'bewaard zo lang als nodig is om uw verzoek te verwerken en maximaal 5\n' +
      'jaar om het bewijs te leveren dat uw verzoek is verwerkt en om te voldoen\n' +
      'aan onze wettelijke verplichtingen. De wettelijke basis voor het verzenden\n' +
      'van de tevredenheidsenquête is tevens uw toestemming. Deze gegevens\n' +
      'worden bewaard gedurende de tijd die nodig is voor het verzenden van\n' +
      'de tevredenheidsenquête en maximaal 1 jaar. Voor meer informatie over\n' +
      'de verwerking van uw persoonsgegevens kunt u ons <a href="https://www.bonduelle.nl/privacybeleid" target="_blank" class="underline">Privacybeleid</a> raadplegen.',
    SATISFACTION_CHECKBOX: 'Door dit vakje aan te vinken, gaat u akkoord met het ontvangen van een\n' +
      'tevredenheidsvragenlijst (bestaande uit één enkele vraag) zodra uw\n' +
      'verzoek is verwerkt. Dat is optioneel.',
    CHECKBOX_TEXT: 'Door dit vakje aan te vinken, gaat u ermee akkoord dat Bonduelle de\n' +
      'persoonlijke gezondheidsgegevens die u in uw bericht verstrekt, mag\n' +
      'verwerken met als enige doel het beantwoorden van uw verzoek. <strong>Dat is\n' +
      'verplicht om uw verzoek te kunnen verwerken</strong>.',
    PHONE: 'Telefoonnummer: <a href="tel: 0800 7162 "><b>0800 7162 </b></a> (gratis)',
    CALL_US: 'Om ons te bellen',
    TO_WRITE: 'Om ons te schrijven',
    PHONE_NUMBER: '0800 7162',
    SUCCESS_MESSAGE: 'Bedankt! Je bericht is verzonden naar ons team. Zij nemen binnen 5 werkdagen contact met je op. <br> <br>\n' +
      'We zijn natuurlijk tot je beschikking op 0800 7162 (gratis). <br> <br>\n' +
      'Als onderdeel van het toezicht op onze kwaliteit van de dienstverlening, kunnen al onze uitwisselingen worden vastgelegd.',
    PHOTOERROR: {
      MESSAGE: "Momenteel werken wij aan een nieuwe website. In de praktijk is gebleken dat foto's uploaden via het contactformulier niet naar behoren functioneert. U kunt het formulier invullen en verzenden en de illustrerende foto's per mail versturen naar: consumerservices_blx_uk@bonduelle.com onder vermelding van uw voor- en achternaam. Excuses voor het ongemak."
    }
  },
  GLOBAL: { READ_MORE: '' },
  FLOWBOX: { GENERIC_KEY: '', PRODUCT_KEY: '', RECIPE_KEY: '', HOME_KEY: '' },
  TOKEN: {
    FACEBOOK: '',
    PAGE_FACEBOOK_ID: '',
    INSTAGRAM: '',
    INSTA_ID: '17841408408283787',
    YOUTUBE: '',
    TWITTER: '',
    MAGAZINE: ''
  },
  COOKIE: {
    BANNER: {
      MANDATORY: 'Noodzakelijke',
      ANALYTICS: 'Analyse',
      MARKETING: 'Marketing',
      SOCIAL_MEDIA: 'Social media',
      ACCEPT: 'Alles accepteren',
      REJECT: 'Alles afwijzen',
      SET_UP: 'Stel voorkeuren in',
      MANDATORY_DETAILS: 'De noodzakelijke cookies zijn verplicht voor de werking van de website om de basisfuncties van deze website mogelijk te maken. De cookies die nodig zijn om de Bonduelle.be website te gebruiken zijn:\n' +
        '<ul>\n' +
        "<li> Sessiecookies: dit zijn cookies die worden gegenereerd door PHP-gebaseerde applicaties. Dit is een algemene identificatie die wordt gebruikt om variabelen van gebruikerssessies te beheren. Het is meestal een willekeurig gegenereerd nummer, de manier waarop het wordt gebruikt kan locatiespecifiek zijn, maar een goed voorbeeld is het behouden van een inlogstatus voor een gebruiker tussen pagina's. </li>\n" +
        '</ul>\n',
      ANALYTICS_DETAILS: 'Analyse cookies helpen ons om de website te optimaliseren, bijvoorbeeld om statistieken te verzamelen, uw voorkeuren voor sociale interacties vast te leggen of de prestaties en stabiliteit van onze applicaties te meten.\n' +
        '<br>\n' +
        'De analytische cookies die door de Bonduelle.nl website worden gebruikt, zijn:\n' +
        '<ul>\n' +
        '<li> DoubleClick: deze cookie wordt door DoubleClick (eigendom van Google) geplaatst om een profiel van de interesses van de websitebezoeker te maken en om relevante advertenties op andere websites weer te geven. De belangrijkste activiteit van het bedrijf is het weergeven van Google-advertenties. </li>\n' +
        "<li> Google Analytics: deze cookies maken het mogelijk om de meest bezochte pagina's en websiteverkeer bij te houden. </li>\n" +
        '<li> UTMTracking: deze cookie laat het beheer toe van UTM-variabelen voor een optimale opvolging van de verschillende online advertentiecampagnes </li>.\n' +
        '</ul>\n',
      MARKETING_DETAILS: 'Met marketingcookies kunnen we uw voorkeuren voor recepten en producten volgen om u altijd de producten aan te bieden die het beste bij uw wensen passen.\n' +
        '<br>\n' +
        'De marketingcookies die door de site bonduelle.be worden gebruikt, zijn:\n' +
        '<ul>\n' +
        "<li> Sociale netwerken: deze cookies zorgen ervoor dat de Bonduelle 'Social Wall' op de homepage van de site wordt weergegeven. Deze 'Social Wall' stelt u in staat om in één oogopslag alle laatste berichten te zien van de belangrijkste sociale netwerken die gebruikt worden door de Bonduelle teams. </li>\n" +
        '\n',
      V2: 'ok'
    },
    MODAL: {
      TITLE: 'Cookie-instellingen',
      TEXT: 'De Bonduelle Group verbindt zich ertoe om de persoonsgegevens van de gebruikers van haar websites en in het bijzonder van de Bonduelle.be website te beschermen. Zodat u gebruik kunt maken van de diensten die door de website worden aangeboden, zoals het advies over producten, nieuwe recepten ontdekken en zich abonneren op bepaalde diensten zoals een nieuwsbrief. Om het gebruik van de website te optimaliseren en te personaliseren op basis van uw profiel, maakt de website gebruik van cookies. De Bonduelle Group verbindt zich ertoe om een ​​hoog niveau van bescherming te verzekeren met betrekking tot dergelijke persoonlijke gegevens en om alle toepasselijke gegevensbeschermingsregels na te leven bij het verwerken van persoonlijke gegevens in verband met het gebruik van haar website.',
      CONFIG: 'Configuratie',
      SAVE: 'Opslaan'
    }
  },
  LINK: { JOB_OFFERS: 'https://jobs.bonduelle.com/?locale=nl_NL' },
  CLIC2BUY: { INFOS: '' }
};
export const be_fr = {
  '404': {
    TITLE: 'Oups...',
    PAGENOTFOUND: "C'est râpé,<br> cette page n'existe pas!",
    BUTTON: "Retour à l'accueil",
    RECIPES: 'Découvrez nos recettes'
  },
  HOME: {
    SHOWCASE: 'Recettes de cuisine',
    CATCH_PHRASE: "Les légumes surprenants d'aujourd'hui... pour un avenir meilleur",
    SHOWCASE_SEE_ALL: 'Voir toutes nos recettes',
    MAGAZINE: { TITLE: 'À LA UNE', BUTTON: 'Découvrez légumiz' },
    OUR_VEGETABLES: 'Nos légumes',
    SOCIAL_WALL: 'Ensemble, partageons la passion du végétal',
    FAQ_TITLE: 'Vos questions',
    FAQ_SEE_MORE: 'See more',
    FLOWBOX: ''
  },
  SELECT: { DEFAULT_VALUE: 'Bonduelle présente' },
  MENU: {
    CONTACT_US: 'Nous contacter',
    MY_ACCOUNT: 'Mon compte',
    GOOD_DEALS: 'Bons plans',
    NEWSLETTER: 'Newsletter Bonduelle'
  },
  FOOTER: {
    LINK: {
      BONDUELLE: 'Bonduelle.be/fr',
      CREDITS: 'Crédits',
      LEGAL_NOTICE: "Conditions d'utilisation",
      TERMS_OF_SALES: '',
      COOKIES: 'Cookies',
      SITEMAP: 'Plan du site',
      PRIVACY_POLICY: 'Politique de confidentialité',
      ALL_RIGHTS_RESERVED: '2019 © Tous droits réservés'
    },
    HISTORY: {
      TITLE: 'Bonduelle, depuis 1853',
      TEXT: 'Bonduelle est une entreprise familiale qui travaille depuis 7 générations au développement d’une production agricole respectueuse des sols et des hommes. Nous défendons une agro-écologie efficiente, intelligente et solidaire, résolument tournée vers l’avenir et innovons chaque jour du champ à l’assiette pour créer un futur meilleur par l’alimentation végétale.'
    },
    NEWSLETTER: {
      TITLE: 'La communauté Bonduelle',
      TEXT: "J'accepte de recevoir la newsletter « Tout sur les légumes par Bonduelle » (actualités, promo, concours, articles…)",
      PLACEHOLDER: 'Votre email',
      GDPR_LINK: "Plus d'informations sur la gestion de vos données"
    }
  },
  PRODUCTS: 'Produits',
  MAGAZINE: {
    HOME: 'Bienvenue sur le magazine',
    CATCH_PHRASE: '',
    MENU: { BONDUELLE_LINK: 'Aller sur bonduelle.fr' },
    SHOWCASE: 'À la une',
    PREVIOUS_ARTICLES: 'Précédents articles',
    PREVIOUS_ARTICLES_SEE_ALL: 'Voir tous nos articles',
    PREVIOUS_EDITIONS: 'Nos précédents<br>numéros',
    PREVIOUS_EDITIONS_SEE_ALL: 'Voir plus de numéro',
    PREVIOUS_ARTICLES_BY_CATEGORY: 'Précédents articles<br>par catégorie',
    RUBRIC: {
      SEE_ALL: 'Voir toutes nos recettes',
      SEE_ALL_ARTICLES: 'Voir tous nos articles',
      ON: 'Articles sur'
    },
    RUBRIC_COOK: { ON: 'Recettes sur' },
    ARTICLE: { JOIN_US: 'Rejoignez-nous', READ_ALSO: 'À lire également' },
    RECIPE: {
      INGREDIENTS: 'Ingrédients',
      INGREDIENTS_LABEL_DESC: 'pour {{value}} personnes',
      INGREDIENTS_LABEL_ONE_DESC: 'pour {{value}} personne',
      INGREDIENTS_LABEL: 'Ingrédients pour {{value}} personnes',
      INGREDIENTS_LABEL_ONE: 'Ingrédients pour {{value}} personne',
      PEOPLES: '{{value}} personnes',
      PEOPLE: '{{value}} personne',
      DIFFICULTY: { EASY: 'Facile', MEDIUM: 'Moyen', HARD: 'Difficile' },
      COST: { CHEAP: 'Pas cher', EXPENSIVE: 'Cher' },
      TIMING: { MIN: 'min', HOURS: 'heures' },
      STEP: 'Étape',
      DISCOVER_ALSO: 'Découvrez<br>aussi'
    }
  },
  ARTICLE: { DISCOVER_ALSO: 'Découvrez également', INGREDIENTS: 'Ingrédients' },
  SF: {
    SLIDE: { DISCOVER: 'Découvrir' },
    SEARCH: { PLACEHOLDER: 'Rechercher', ALL: 'Voir tout' },
    NEWSLETTER: {
      BTN_LABEL: "Je m'inscris",
      PLACEHOLDER: 'Votre email',
      ERROR_MESSAGE: 'Email invalide',
      ALREADY_SUBSCRIBED: 'Vous êtes déjà inscrit',
      GDPR: {
        TXT: "Plus d'informations sur la gestion de vos données",
        LINK: '/politique-confidentialite'
      }
    },
    MONTHLY_FOLDER: { TITLE: 'Dossier du mois' },
    MINI_NEWSLETTER: {
      PLACEHOLDER: 'Votre email',
      GDPR: {
        TXT: "Plus d'informations sur la gestion de vos données",
        LINK: '/politique-confidentialite'
      }
    },
    SOCIALITEM: { SHARE: 'partager', COMMENT: 'commenter', BONDUELLE: 'Bonduelle' },
    SEARCHRECIPE: { DISCOVER_ALL: 'Recherchez parmi toutes nos recettes' },
    FORM: {
      ERROR: 'Champ invalide',
      PHOTO: {
        ADD: 'Ajoutez une photo',
        DELETE: 'Supprimer la photo',
        SEE: 'Voir'
      }
    }
  },
  CORPORATE: {
    COMPANY_STORY: 'Histoire',
    CSR_COMMITMENT: 'Nos engagements RSE',
    VEGETABLE_INNOVATIONS: 'Innovations',
    NEWS: 'Toutes nos actualites',
    SEE_MORE: "Voir plus d'articles",
    ON: 'articles sur'
  },
  NEWSLETTER: { TITLE: '', SUBTITLE: '' },
  ROUTES: {
    products: 'legumes',
    news: 'actualites',
    'since-1853': 'a-propos',
    'field-plate': 'du-champ-a-l-assiette',
    'company-story': 'notre-histoire',
    'csr-commitments': 'nos-engagements-rse',
    'vegetal-innovations': 'innovations-vegetales',
    magazine: 'magazine',
    all: 'voir-tout',
    article: 'article',
    cook: 'cuisiner',
    cultivate: 'cultiver',
    explore: 'explorer',
    recipe: 'wait-recette',
    recipes: 'recette',
    'recipes-home': 'recettes',
    r: 'r',
    credits: 'credits-fr',
    'legal-notice': 'mentions-legales',
    'terms-of-sales': 'cgv-fr',
    cookies: 'cookies',
    sitemap: 'sitemap-fr',
    'privacy-policy': 'politique-confidentialite',
    'all-rights-reserved': 'all-rights-reserved',
    search: 'recherche',
    'articles-search': 'articles',
    vegetables: 'legumes',
    p: 'p',
    contact: 'nous-contacter',
    'info-product': 'renseignement-produit',
    'note-product': 'note-product',
    suggestion: 'suggestion',
    'other-request': 'autre-demande',
    faq: 'faq',
    'all-themes': 'tous',
    'products-search': 'produits',
    recipesearch: 'recettes-recherche',
    'product-news': 'nouveautes',
    'good-deals': 'bons-plans',
    'not-found': 'erreur',
    'menu-page': 'pour-un-avenir-meilleur',
    'menu-one': 'inspiration-vegetale',
    'menu-two': 'emballage-recyclable',
    'menu-three': 'biodiversite-et-sol',
    'menu-four': 'gaspillage-alimentaire',
    'menu-external': '',
    'theme-pasta-rice': 'pates-riz-legumes',
    'theme-bowls': 'bols',
    'theme-soup': 'soupes',
    'theme-snack': 'collations',
    'theme-sauce': 'sauces',
    'theme-unique-plate': 'plats-uniques',
    'theme-over': 'autres-plats',
    'theme-legumin': 'legumineuses',
    'theme-ingredients-recipes': 'ingredients-recettes',
    'theme-cook-plate': 'plats-cuisines-legumes-secs'
  },
  BREAD: {
    products: 'legumes',
    news: 'actualites',
    'since-1853': 'a-propos',
    'field-plate': 'du-champ-a-l-assiette',
    'company-story': 'notre-histoire',
    'csr-commitments': 'nos-engagements-rse',
    'vegetal-innovations': 'innovations-vegetales',
    magazine: 'magazine',
    all: 'voir-tout',
    article: 'article',
    cook: 'cuisiner',
    cultivate: 'cultiver',
    explore: 'explorer',
    recipe: 'wait-recette',
    recipes: 'recette',
    'recipes-home': 'recettes',
    'articles-search': 'articles',
    search: 'recherche',
    vegetables: 'legumes',
    contact: 'nous-contacter',
    'info-product': 'renseignement-produit',
    'note-product': 'note-product',
    suggestion: 'suggestion',
    'other-request': 'autre-demande',
    faq: 'faq',
    'all-themes': 'tous',
    'products-search': 'produits',
    recipesearch: 'recettes-recherche',
    'product-news': 'nouveautes',
    'good-deals': 'bons-plans',
    'not-found': 'erreur',
    'menu-page': 'pour un avenir meilleur',
    'menu-one': 'inspiration végétale',
    'menu-two': 'emballage recyclable',
    'menu-three': 'biodiversité et sol',
    'menu-four': 'gaspillage-alimentaire'
  },
  MENU_PAGE: {
    TITLE1: 'Inspiration végétale',
    TITLE2: 'Emballage recyclable',
    TITLE3: 'Biodiversité et sol',
    TITLE4: 'Gaspillage alimentaire',
    EXTERNAL: ''
  },
  TECHNICAL: { MENU_PAGE: '' },
  SEO: {
    TITLE: {
      MENU_ONE: 'Inspiration végétale',
      MENU_TWO: 'Emballage recyclable',
      MENU_THREE: 'Biodiversité et sol',
      MENU_FOUR: 'Gaspillage alimentaire',
      HOME: "Bonduelle: source d'envie de legumes & recettes de cuisine",
      SEARCH: {
        MAIN: '{{value}}  | Bonduelle',
        RECIPES: '{{value}} triés par recette  | Bonduelle',
        VEGETABLES: '{{value}} triés par légume  | Bonduelle',
        ARTICLES: '{{value}} triés par article  | Bonduelle'
      },
      CONTACT: 'Contact service client, informations  | Bonduelle',
      RECIPES: 'Recette de cuisine : soupe, quiche, lasagne légume...  | Bonduelle',
      RECIPE_HOME: 'Recette de cuisine aux legumes noel, mariage, fete  | Bonduelle',
      RECIPE: 'Recette {{value}} | Bonduelle',
      PRODUCTS: 'Legumes conserves | Bonduelle',
      NEWS: 'Conseil, cuisiner, comment preparer des legumes  | Bonduelle',
      CORPORATE: {
        NEWS: 'Derniers articles, news de legume et de cuisine  | Bonduelle',
        COMPANY_STORY: "Présentation de l'entreprise et association  | Bonduelle",
        CSR_COMMITMENTS: 'Valeurs ethiques/principes et engagement du groupe  | Bonduelle',
        VEGETAL_INNOVATIONS: 'Innovation vegetale : nouvelles fermes, produits  | Bonduelle',
        FIELD_PLATE: 'Legumes : cuisiner les meilleures recettes | Bonduelle',
        ARTICLE: '{{value}}  | Bonduelle',
        VEGETABLE: 'Idée recette, bien cuisiner {{value}}  | Bonduelle'
      },
      PRODUCT: '{{value}} : légumes cuisinés Bonduelle ',
      MAGAZINE: {
        NEWS: 'Actualité des legumes, recettes adulte et enfant  | Bonduelle',
        ARTICLE: '{{value}} | Legumiz  | Bonduelle',
        COOK: 'Recettes de cuisine faciles & rapide aux legumes  | Bonduelle',
        GROW: 'Cultiver et entretenir les legumes dans son potager  | Bonduelle',
        EXPLORE: 'Decouvrir de nouveaux legumes & saveurs aux enfants  | Bonduelle',
        RECIPE: 'Recette {{value}} | Bonduelle'
      },
      FAQ: 'FAQ : foire aux questions des internautes  | Bonduelle',
      NOTFOUND: 'Erreur 404, veuillez reessayer plus tard | Bonduelle',
      RECIPES_ALL: 'Toutes nos recettes de cuisines de légumes',
      RECIPETYPE: 'Recette de cuisine: {{value}} de légume',
      RECIPESEASON: 'Recette de légume de saison: {{value}}',
      RECIPEPREPARATION: 'Recettes de cuisine : durée préparation {{value}}',
      RECIPEDIFFICULTY: 'Recette de cuisine & légume facile: {{value}} de légume',
      RECIPEVEGETABLE: 'Recette de cuisine facile à base de {{value}}',
      RECIPECOST: 'Recette de cuisine & légumes {{value}}',
      RECIPEDISH: 'Recettes de cuisine et légume pour {{value}} ',
      RECIPEDESC: 'Recette de cuisine & légumes {{value}}',
      '2RECIPEFACETS': 'Recette de cuisine {{value}}',
      '4RECIPEFACETS': 'Recette de cuisine {{value}}',
      PRODUCTSEASON: 'Recette de légume facile de saison : {{value}} ',
      PRODUCTTECHNO: 'Recette de légume facile {{value}}',
      PRODUCTVEGETABLE: 'Recette de légume facile à base de {{value}}',
      PRODUCTRANGE: 'Recette de légume facile à base de {{value}}',
      THEME_ALL: 'Légumes fraîchement préparés ',
      THEME_STARTER: "Idée légume pour cuisiner des recettes d'entrées",
      THEME_SALAD: 'Idée légumes pour cuisiner salade composée été',
      THEME_CAN: '',
      THEME_FROZEN: '',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: '',
      THEME_RECIPE: 'Idée Légume pour cuisiner recettes faciles/rapides',
      THEME_PLATE: 'Idées légumes pour cuisiner plat chaud & froid',
      THEME_ACC: 'Idées légumes en accompagnement viande & poisson',
      THEME_SNACK: 'Idée légume pour des recettes a manger rapidement',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': 'Recette de légume {{value}}'
    },
    DESCRIPTION: {
      MENU_ONE: "Découvrez ici l'inspiration végétale de Bonduelle, afin de pouvoir cuisiner de délicieux plats de légumes",
      MENU_TWO: 'Les boîtes et les sachets en plastique de Bonduelle sont durables pour un avenir meilleur.',
      MENU_THREE: 'Découvrez ce que Bonduelle fait pour stimuler la biodiversité et protéger les sols pour un avenir meilleur.',
      MENU_FOUR: 'Découvrez ce que Bonduelle fait pour réduire les déchets alimentaires et comment vous pouvez y contribuer. ',
      HOME: "Conserves, surgelés, salades, crudités, découvrez nos bons légumes, des idées recettes pour inspirer vos savoureux plats. Bonduelle : source d'envie de légumes & recettes de cuisine.",
      SEARCH: {
        MAIN: 'Découvrez nos idées recette & légume pour {{value}}. Recettes simples et savoureuses. Légumes cuisinés.',
        RECIPES: 'Tous nos {{value}} triés par recette. Recettes simples et savoureuses. Légumes cuisinés.',
        VEGETABLES: 'Tous nos {{value}} triés par légume. Recettes simples et savoureuses. Légumes cuisinés.',
        ARTICLES: 'Tous nos {{value}} triés par article. Recettes simples et savoureuses. Légumes cuisinés.'
      },
      CONTACT: "Une question ? Besoin d'une information sur un produit, service, opération en cours ? Vous pouvez contacter notre service client.",
      RECIPES: 'Découvrez des recettes de cuisine savoureuses à base de légumes. Faites plaisir à vos proches en leur préparant des recettes faciles. Recettes simples et savoureuses. Légumes cuisinés.',
      RECIPE_HOME: 'Découvrez des recettes de cuisine savoureuses à base de légumes pour les fêtes. Faites plaisir à vos proches en cette occasion spéciale. Recettes simples et savoureuses. Légumes cuisinés.',
      RECIPE: '',
      PRODUCTS: 'Notre large gamme de légumes vous permet de préparer des recettes savoureuses, faciles et équilibrées. Recettes simples et savoureuses. Légumes cuisinés.',
      NEWS: 'Découvrez comment bien préparer ses légumes, les cuisiner efficacement pour créer des plats savoureux. Recettes simples et savoureuses. Légumes cuisinés.',
      CORPORATE: {
        NEWS: "Dernières actualités sur l'univers de l'agriculture, l'innovation végétale, le gaspillage & comment mieux manger de manière simple. Recettes simples et savoureuses. Légumes cuisinés.",
        COMPANY_STORY: "Envie d'en savoir plus sur l'univers des légumes et du mon agricole & végétal ? Nous vous faisons découvrir tout cela sur notre blog. Recettes simples et savoureuses. Légumes cuisinés.",
        CSR_COMMITMENTS: 'Vous vous demandez comment sont cultivés nos légumes, d’où ils proviennent, comment nous respectons l’environnement ? Retrouvez ici les réponses aux questions sur les légumes.',
        VEGETAL_INNOVATIONS: "L'innovation végétale est au cœur de nos préoccupations. Nouvelles manières de cultiver plus écologique, moins gaspiller… Recettes simples et savoureuses. Légumes cuisinés.",
        VEGETABLE: '',
        FIELD_PLATE: 'Découvrez nos délicieuses recettes. Elles raviront tous les palais. Recettes simples et savoureuses. Légumes cuisinés.',
        ARTICLE: ''
      },
      PRODUCT: '{{value}} pour préparer des recettes de cuisines savoureuses et faire plaisir à vos proches. Recettes simples et savoureuses. Légumes cuisinés.',
      MAGAZINE: {
        NEWS: 'Toutes les actualités, news sur les légumes, recettes de cuisine équilibrées. Recettes simples et savoureuses. Légumes cuisinés.',
        ARTICLE: '',
        COOK: 'Nos délicieuses recettes raviront tous les palais, découvrez-les sur notre site. Recettes simples et savoureuses. Légumes cuisinés.',
        GROW: 'Envie de faire pousser vous-même vos légumes dans votre potager ? Découvrez nos conseils & astuce pour faire pousser et entretenir vos légumes. Légumes cuisinés.',
        EXPLORE: 'Faites Découvrir de nouvelles variétés de légumes à vos enfant à travers des recettes simples et efficaces. Recettes faciles et savoureuses. Légumes cuisinés.',
        RECIPE: ''
      },
      FAQ: 'Vous vous posez une question ? Nous avons regroupé toutes les questions les plus posées par les internautes pour répondre au mieux à vos attentes.',
      PAGE: 'page ',
      RECIPETYPE: 'Découvrez nos recettes de cuisine {{value}} et comment bien cuisiner ses légumes. Idée recette végétarienne, rapide, originale, facile.',
      RECIPESEASON: 'Découvrez nos recettes de cuisine et comment bien cuisiner ses légumes en {{value}}. Idée recette végétarienne, rapide, originale, facile.',
      RECIPEPREPARATION: 'Découvrez nos recettes de cuisine et comment bien cuisiner ses légumes en {{value}}. Idée recette végétarienne, rapide, originale, facile.',
      RECIPEDIFFICULTY: 'Découvrez nos recettes de cuisine et comment bien cuisiner ses légumes {{value}}. Idée recette végétarienne, rapide, originale, facile.',
      RECIPEVEGETABLE: 'Découvrez nos recettes de cuisine de {{value}} et comment bien cuisiner ses légumes. Idée recette végétarienne, rapide, originale, facile.',
      RECIPECOST: 'Découvrez nos recettes de cuisine et comment bien cuisiner ses légumes pour {{value}}. Idée recette végétarienne, rapide, originale, facile.',
      RECIPEDISH: 'Découvrez nos recettes de cuisine et comment bien cuisiner ses légumes pour {{value}} convives. Idée recette végétarienne, rapide, originale, facile.',
      RECIPEDESC: 'Découvrez nos recettes de cuisine et comment bien cuisiner ses légumes {{value}}. Idée recette végétarienne, rapide, originale, facile.',
      '2RECIPEFACETS': 'Découvrez nos recettes de cuisine et comment bien cuisiner ses légumes {{value}}. Idée recette végétarienne, rapide, originale, facile.',
      '4RECIPEFACETS': 'Découvrez nos recettes de cuisine et comment bien cuisiner ses légumes {{value}}.',
      PRODUCTSEASON: 'Notre large gamme de légumes vous permet de préparer des recettes de saison {{value}} savoureuses, faciles et équilibrées. Recettes simples et savoureuses. Légumes cuisinés.',
      PRODUCTTECHNO: 'Notre large gamme de légumes vous permet de préparer des recettes savoureuses, faciles et équilibrées {{value}}. Recettes simples et savoureuses. Légumes cuisinés.',
      PRODUCTVEGETABLE: 'Notre large gamme de légumes vous permet de préparer des recettes savoureuses, faciles et équilibrées à base de {{value}}. Recettes simples et savoureuses. Légumes cuisinés.',
      PRODUCTRANGE: 'Notre large gamme de légumes vous permet de préparer des recettes savoureuses, faciles et équilibrées à base de {{value}}. Recettes simples et savoureuses. Légumes cuisinés.',
      THEME_ALL: 'Notre large gamme de légumes vous permet de préparer des recettes savoureuses, faciles et équilibrées. Recettes simples et savoureuses. Légumes cuisinés.',
      THEME_STARTER: "Envie de cuisiner un repas équilibré ? Large gamme de légumes pour cuisiner des recette d'entrées à base de légumes faciles et rapides, des conseils et astuces pour sublimer vos repas.",
      THEME_SALAD: 'Envie de cuisiner un repas équilibré ? Large gamme de légumes pour cuisiner des salades à base de légumes faciles et rapides, des conseils et astuces pour sublimer vos repas.',
      THEME_CAN: '',
      THEME_FROZEN: '',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: '',
      THEME_RECIPE: 'légumes pour cuisiner des recette delicieuses et équilibrées à base de légumes faciles et rapides, des conseils et astuces pour sublimer vos repas. Recettes rapides & equilibrées',
      THEME_PLATE: 'Envie de cuisiner un repas équilibré ? légume pour cuisiner des plats chauds ou froid à base de légumes faciles et rapides, des conseils et astuces pour sublimer vos repas.',
      THEME_ACC: 'légumes pour cuisiner des accompagnements à base de légumes faciles et rapides, des conseils et astuces pour sublimer vos repas. Recettes de cuisine simples et équilibrées.',
      THEME_SNACK: 'Envie de cuisiner un repas équilibré ? légume pour cuisiner des recette à grignoter à base de légumes faciles et rapides, des conseils et astuces pour sublimer vos repas.',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': 'Notre large gamme de légumes vous permet de préparer des recettes savoureuses, faciles et équilibrées {{value}}. Recettes simples et savoureuses. Légumes cuisinés.',
      NOTFOUND: "La page demandée n'a pas pu être trouvée. Nous vous invitons à faire une nouvelle recherche ou à recommencer ultérieurement."
    },
    PIPE: { SOCIETY: ' | Bonduelle' },
    THEME_ALL: 'tous',
    THEME_STARTER: 'entrées',
    THEME_SALAD: 'tout-pour-mes-salades',
    THEME_CAN: '',
    THEME_FROZEN: '',
    THEME_READY_MEALS: '',
    THEME_BIO: '',
    THEME_VEGETABLES: '',
    THEME_RECIPE: 'ingredients-recettes',
    THEME_PLATE: 'plat-principal',
    THEME_ACC: 'accompagnements',
    THEME_SNACK: 'snacking'
  },
  VEGETABLE: {
    DISCOVER_VEGETABLE: 'Découvrez nos feuilles de légumes',
    OURS_RECIPES: 'Nos recettes de cuisine',
    DISCOVER_ALSO: 'Découvrez aussi...',
    SEE_ALL: 'Voir tous nos légumes',
    ON: 'légumes sur'
  },
  RECIPE: {
    'INGREDIENTS_LABEL ': 'Ingrédients pour {{value}} personnes',
    PEOPLES: '{{value}} personnes',
    DIFFICULTY: { EASY: 'facile', MEDIUM: 'moyen', HARD: 'difficile' },
    COST: { CHEAP: 'pas cher', EXPENSIVE: 'cher' },
    TIMING: { MIN: 'min', HOURS: 'heures' },
    STEP: 'Étape',
    DISCOVER_ALSO: 'Découvrez aussi...',
    HOME_SEE_MORE: 'voir plus de recettes',
    SHOWCASE: 'À la une',
    HOME_BOOK: 'Nos cahiers<br>de recettes',
    MIN_MORE: 'mn et +',
    HOUR_MORE: 'h et +',
    FILTER_BY_CRITERIA: 'filtrer par critères',
    SORT_BY: 'trier par :',
    BEST_RATED: 'Les mieux notés',
    MOST_CONSULTED: 'Les plus consultés',
    MEMBERS_RECIPES: 'Recettes de membres',
    CRITERIA: 'Nouveautés',
    DISCOVER: { RECIPES: 'découvrir nos recettes' },
    DISCOVER_RECIPE: 'découvrir la recette',
    INSTRUCTIONS: 'Instructions',
    DEFAULT_TAG: 'Idée recette',
    MOST_RECENT: 'Les plus récentes',
    RECIPE_VIDEOS: 'Recettes en vidéos',
    PRODUCT_ASSOCIATE: 'Cette recette a été préparée avec ',
    BUDGET_LOW: 'Bon marché',
    BUDGET_HIGH: 'Elevé',
    BUDGET_MIDDLE: 'Moyen',
    PRINT_RECIPE: 'imprimer la recette',
    LET_YOURSELF_SURPRISED: 'Laissez-vous<br>surprendre',
    COOK_TIP_TITLE: 'Mes astuces dégustations',
    ENJOY_MEAL: 'Bon appétit !',
    FILTER: { LEGEND: 'Légende' }
  },
  PREDIGGO: {
    DIFFICULTY: { EASY: 'facile', MEDIUM: 'accessible', HARD: 'difficile' }
  },
  COOKIES: {
    TEXT: "En poursuivant votre navigation sur ce site, vous acceptez l'utilisation de cookies pour des raisons techniques d'analyse statistique et de gestion du trafic Web, ainsi que pour le partage d'informations avec des annonceurs sur d'autres sites afin de vous proposer des publicités adaptées à vos intérêts.",
    CLOSE: 'Fermer',
    MORE: 'En savoir plus',
    AGREE_YOUTUBE: 'Vous devez accepter les cookies Youtube pour voir cette vidéo',
    BUTTON_YOUTUBE: 'Accept cookies'
  },
  POPUP: { LAUNCH: 'POPUP-OUVERTURE-SITE' },
  FIELD_PLATE: { DISCOVER_VEGETABLE: 'Découvrez<br>nos fiches légumes' },
  SEARCH: {
    OUR_RECIPES: 'Nos  recettes',
    OUR_PRODUCTS: 'Nos légumes',
    OUR_ARTICLES: 'Nos articles',
    RECIPES: 'Recettes',
    PRODUCTS: 'Légumes',
    ARTICLES: 'Articles',
    ALL: 'Voir tout',
    SORT_BY: 'Trier par',
    RESULTS: 'résultats',
    RESULT: 'résultat',
    PRODUCT_OUT: 'légumes sur',
    APPLY: 'Appliquer',
    FILTER: 'Filtrer',
    RECIPE_OUT: 'recettes sur',
    NO_RESULT: 'Chou blanc pour cette recherche... Vous nous avez posé une colle',
    SEE_MORE: 'Voir plus',
    DISCOVER_RECIPES: 'Laissez-vous<br>surprendre',
    SEE_MORE_OF: 'voir plus de ',
    DELETE: 'effacer'
  },
  RECIPE_LIST: {
    OUR_RECIPES: 'Recettes',
    RESULTS: 'Résultats',
    SEE_MORE: 'Voir plus de recettes'
  },
  PRODUCT: {
    BUTTON: {
      ACTU: 'actualité du produit',
      FOUND: 'où trouver ce produit',
      DISCOVER: 'découvrez toute la gamme'
    },
    NUTRITIONAL_VALUE: 'valeurs nutritionnelles',
    CONDITIONING: 'Conditionnement',
    PORTIONS: 'portions',
    NET_WEIGHT: 'poids net',
    DRY_WEIGHT: 'poids net égoutté',
    INGREDIENTS: 'ingrédients',
    OUR_RECIPES: 'Découvrez <br> nos recettes de cuisine',
    FOR: 'pour',
    PER_PORTION: 'et par portion de ',
    ENERGY: 'énergie',
    FAT: 'matières grasses',
    SATURATE: 'dont acides gras saturés',
    GLUCIDES: 'glucides',
    FIBER: 'fibres alimentaires',
    PROTEINS: 'protéines',
    SALT: 'sel',
    DISCOVER_ALSO: 'Découvrez<br>aussi...',
    MORE_INFORMATION: 'en savoir plus',
    TRACES: 'Traces de',
    CONTAINS: 'Contient',
    SLIDER: { SHEET: 'voir la fiche', BUY: 'buy this product' },
    NUTRISCORE: 'information-nutriscore',
    TRIMAN: 'information-triman',
    AGRICONFIANCE: 'information-agriconfiance',
    BIO: 'information-bio',
    PESTICIDE_FREE: '',
    LEAF: '',
    LOCK: '',
    SEE_ALL: 'Voir tous nos légumes',
    LEGISLATION: 'conformément à la législation en vigueur.',
    PORTION: { SINGULAR: 'portion', PLURAL: 'portions' },
    DISCLAIMER: '',
    COOKING: 'Cuisson',
    CONSERVATION: 'Conservation'
  },
  FAQ: {
    ON: 'questions sur',
    ALL: 'Tous',
    SEE_MORE: 'voir plus de questions',
    TITLE_1: 'Toutes les réponses à vos questions',
    TITLE_2: 'Vous avez une question sur un produit en particulier ?',
    LINK_LABEL: 'Ecrivez-nous'
  },
  FORM: {
    GO_TO_STEP: "Passer à l'étape",
    STEP: 'Étape',
    RECEIVE_EMAIL_COPY: 'Recevoir une copie par e-mail',
    SEND: 'Envoyer',
    GDPR: "Plus d'informations sur la gestion de vos données",
    ERROR: "Tous les champs marqués d'une * doivent êtres remplis et valides",
    SEND_ERROR: 'Une erreur est survenue, veuillez réessayer dans quelques instants',
    NOTICE: 'Pour le bon traitement de votre dossier, Bonduelle est susceptible, selon les cas, de vous demander des données de santé. Elles ne sont pas obligatoires et vous êtes libre de ne pas les transmettre.',
    SENDING: 'Envoi du formulaire',
    LOADING: 'Chargement...',
    PRODUCT_INFORMATION_TITLE: 'Un renseignement sur un produit',
    PRODUCT_INFORMATION: {
      SEO_TITLE: 'Renseignement et information sur un produit | Bonduelle',
      SEO_DESC: 'Contactez notre service consommateur pour une information sur un produit.'
    },
    INFORMATIONS: 'Vos informations',
    MISTER: 'Monsieur',
    MISS: 'Madame',
    NAME: 'Nom',
    OUR_NAME: 'Votre nom',
    FIRSTNAME: 'Prénom',
    OUR_FIRSTNAME: 'Votre prénom',
    MAIL: 'Votre email',
    MAIL_PLH: 'monadresse@email.com',
    MAIL_CONFIRM: 'Confirmez votre email',
    MAIL_ERROR: 'Veuillez saisir une adresse email valide',
    MAIL_ERROR_CONFIRM: 'Veuillez confirmer votre adresse email',
    ADDRESS: 'Votre adresse',
    STREET_PLH: '',
    STREET: 'N° et nom de la rue',
    ADDRESS_COMPLEMENT: "Complément d'adresse",
    ADDRESS_COMP_PLH: 'Indications supplémentaires',
    ZIPCODE: 'Code postal',
    CITY: 'Ville',
    CITY_PLH: 'Votre ville de résidence',
    PHONE: 'Téléphone',
    PHONE_PLH: 'Votre numéro de téléphone',
    COUNTRY: 'Pays',
    PRODUCT_INFORMATIONS: 'Informations produits',
    PRODUCT_INFORMATION_DESC: 'Afin que nos services puissent étudier au mieux votre demande, merci de renseigner les informations suivantes:',
    PRODUCT: {
      DETAIL: {
        DESC: 'Pour chaque critère demandé, vous pouvez remplir le champ du formulaire et ajouter photos d’illustration. Votre photos doivent permettre de lire clairement le code barre, le numéro de lot et la DLUO du produit.<br>\n' +
          'Note : Photos via PC ou ordinateur portable - fonction appareil photo non disponible.'
      },
      NOTE: {
        TITLE: 'Faire une remarque sur un produit',
        SEO_TITLE: 'Remarque et commentaire sur un produit | Bonduelle',
        SEO_DESC: 'Une remarque ou un commentaire sur un produit? Contactez notre service consommateur pour vous aider.'
      }
    },
    PRODUCT_RANGE: 'Gamme de produits concernée',
    PRODUCT_NAME: 'Nom du produit',
    PRODUCT_NAME_PLH: 'Ecrivez le nom du produit Bonduelle concerné par votre demande',
    PRODUCT_INFORMATIONS_DETAIL: 'Informations détaillées',
    PRODUCT_INFORMATIONS_LABEL: 'Code barre du produit',
    PRODUCT_INFORMATIONS_PLH: 'Le code barre est composé\\n de 13 chiffres présents sur\\n l’emballage de votre produit.',
    PRODUCT_LOT: 'N° de lot du produit',
    PRODUCT_LOT_INFO: 'Le numéro de lot commence\\n toujours pas la lettre L suivie\\n de plusieurs chiffres et varie\\n selon les produits.\\n\\nPar exemple L72450492107:13.',
    PHOTO: 'Ajouter une photo',
    EXPIRED_DATE: 'Date de la DLUO/DLC',
    EXPIRED_DATE_ERROR: 'Veuillez saisir une date au format jj/mm/aaaa',
    EXPIRED_DATE_DESC: "La DLUO (Date Limite d'Utilisation Optimale)<br>et la DLC (Date limite de Consommation)<br>sont présentées sous forme de dates.",
    PHOTO_MORE: 'Photos supplémentaires',
    MESSAGE: 'votre message *',
    OUR_MESSAGE: 'écrivez votre message',
    SALES_OPERATIONS: {
      TITLE: 'Des informations sur une opération commerciale',
      SEO_TITLE: 'Infos sur les opérations du moment: jeu concours… | Bonduelle',
      SEO_DESC: 'Contactez notre service consommateur pour une information sur une operation commerciale en cours. Réponse personnalisée dans les 48h'
    },
    PRESS_SERVICE: 'Contacter le service presse',
    FOOD_SERVICE: 'Contacter l’équipe Bonduelle Food Service',
    BUSINESS_INFORMATION: '',
    MODAL_INFORMATION: '',
    JOB_OFFER: { TITLE: '', SEO_TITLE: '', SEO_DESC: '' },
    WEB_SUGGESTION: {
      TITLE: 'Faire une suggestion à propos du site internet',
      SEO_TITLE: "Message d\\'amelioration, suggestion du site | Bonduelle",
      SEO_DESC: 'Veuillez contacter notre service consommateurs pour nous aider à améliorer le site.'
    },
    ANOTHER: {
      TITLE: 'Faire une autre demande',
      SEO_TITLE: 'Contact pour toute autre demande, service | Bonduelle',
      SEO_DESC: 'Contactez notre service consommateur pour une information, un service ou une suggestion. Réponse personnalisée dans les 48h'
    },
    AMBASSADOR: 'Devenir ambassadeur Bonduelle',
    CATEGORY: {
      SALAD: 'salades',
      FROZEN: 'surgelés',
      CAN: 'conserves',
      TAKEAWAY: 'traiteur',
      VEGETABLE: ''
    }
  },
  CONTACT: {
    TITLE: 'Comment pouvons-nous vous aider?',
    SPECIAL_INFO: '',
    TAKE_CARE: '',
    BY_MAIL: 'Par courrier',
    BY_PHONE: 'Par téléphone',
    BY_SOURDLINE: '',
    MAIL_TEXT: 'Bonduelle Benelux<br>\n' +
      'attn. Service consommateur<br>\n' +
      'Boite Postate 28058<br>\n' +
      '5602 JB Eindhoven<br>\n' +
      'Pays-Bas',
    PHONE_TEXT: 'Nos collaborateurs sont à votre disposition\n' +
      'au numéro de téléphone <br><a href="tel: 08 00 71 262" >Belgique : <b>0800 71262</b></a> (gratuit) <br/>Luxembourg : <a href="tel: 0800 27448"><b>0800 27448</b></a> (gratuit)\n' +
      ' <br/>Luxembourg :  <a href="tel: 00352-(0)27-861026 "><b>00352-(0)27-861026 </b></a> (seulement mobile).\n',
    SOURDLINE_TEXT: '',
    BY_DISTRIBUTOR: '',
    BY_DISTRIBUTOR_TEXT: '',
    SUBTITLE: "Ou vous pouvez utiliser l'un des formulaires de contact ci-dessous :",
    AGREEMENT: '',
    READ_MORE: 'Par ailleurs  vous disposez  d’un droit d’accès, de rectification, d’effacement et de portabilité à l’égard de vos Données Personnelles ainsi que du droit de restriction et d’opposition au traitement des Données Personnelles qui vous concernent, que vous pouvez exercer en écrivant à Dpo_france(at)bonduelle(dot)com ou par courrier : à l’attention de Monsieur le Délégué à la protection des données, Bonduelle, Rue Nicolas Appert, BP 30173, F-59653 Villeneuve D’Ascq. Pour en savoir plus sur la gestion de vos données et de vos droits cliquez ici',
    READ_MORE_LABEL: 'En savoir plus',
    SATISFACTION_TEXT: 'Afin de vous contacter à la suite d’une remarque sur nos produits, de nous\n' +
      'permettre de traiter votre réclamation, ainsi que de vous envoyer un\n' +
      'questionnaire de satisfaction si vous le souhaitez, nous traiterons les\n' +
      'informations renseignées en qualité de responsable de traitement. La\n' +
      'base légale du traitement de votre demande est le consentement en\n' +
      'raison des potentielles données relatives à votre santé que vous pourriez\n' +
      'renseigner dans votre demande. Ces informations seront conservées\n' +
      'pendant la durée nécessaire au traitement de votre demande et jusqu’à\n' +
      'une durée de 5 ans afin d’apporter la preuve du traitement de votre\n' +
      'demande et de nous conformer à nos obligations légales. Pour l’envoi du\n' +
      'questionnaire de satisfaction, la base légale est également votre\n' +
      'consentement. Ces informations seront conservées pendant la durée\n' +
      'nécessaire à l’expédition du questionnaire de satisfaction et jusqu’à 1 an\n' +
      'au maximum. Pour en savoir plus sur le traitement de vos données\n' +
      'personnelles, nous vous invitons à consulter notre <a href="https://www.bonduelle.be/fr/politique-confidentialite" target="_blank" class="underline">Politique de confidentialité</a>.',
    SATISFACTION_CHECKBOX: 'En cochant cette case, vous acceptez de recevoir un questionnaire de\n' +
      'satisfaction (composé d’une seule option) à l’issue du traitement de votre\n' +
      'demande. Cette dernière est facultative.',
    CHECKBOX_TEXT: 'En cochant cette case, vous acceptez que vos données à caractère\n' +
      'personnel de santé pouvant être indiquées dans votre message soient\n' +
      'traitées par Bonduelle dans l’unique but de répondre à votre demande.\n' +
      '<strong>Cette dernière est obligatoire pour traiter votre demande</strong>.',
    PHONE: 'Numéro de téléphone :\n' +
      '<br/><br/>Belgique :  <a href="tel: 0800 71262"><b>0800 71262</b></a> (gratuit)\n' +
      ' <br/><br/>Luxembourg : <a href="tel: 0800 27448"><b>0800 27448</b></a> (gratuit)\n' +
      ' <br/><br/>Luxembourg :  <a href="tel: 00352-(0)27-861026 "><b>00352-(0)27-861026 </b></a> (seulement mobile)',
    CALL_US: 'Nous appeler',
    TO_WRITE: 'Nous écrire',
    PHONE_NUMBER: 'Belgique : 0800 71262 (gratuit)\n' +
      'Luxembourg : 800 27448 (gratuit)\n' +
      'Luxembourg : 00352-(0)27-861026 (seulement mobile)',
    SUCCESS_MESSAGE: 'Merci! Votre​ ​message​ a ​​été​ ​transmis​ ​à notre équipe. Celui-ci​ ​sera​ traité​ ​dans​ ​un​ ​délai moyen​ ​\n' +
      'de​ 2​ à​ 3​​ jours,​ ​hors​ ​week-end​ ​et​ ​jours​ ​fériés.​ <br><br>\n' +
      'Nous restons naturellement​ à votre​ ​écoute​​​ au:\n' +
      'BE: 0800 71262 (gratuit)\n' +
      'L: 800 27448 (gratuit)\n' +
      'L: 00352-(0)27-861026 (seulement mobile)​​ ​<br><br>\n' +
      'Dans le cadre du suivi de notre qualité de service, l’ensemble de nos échanges peuvent faire l’objet d’un enregistrement.',
    PHOTOERROR: {
      MESSAGE: "Actuellement nous travaillons à la création d'un nouveau site. Malheureusement charger des photos via le formulaire, ne fonctionne pas correctement. Vous pouvez néanmoins remplir et envoyer le formulaire et ensuite envoyer les photos séparément par e-mail à: consumerservices_blx_@bonduelle.com et indiquer votre nom. Excusez-nous pour le désagrément."
    }
  },
  GLOBAL: { READ_MORE: '' },
  FLOWBOX: { GENERIC_KEY: '', PRODUCT_KEY: '', RECIPE_KEY: '', HOME_KEY: '' },
  TOKEN: {
    FACEBOOK: '',
    PAGE_FACEBOOK_ID: '',
    INSTAGRAM: '',
    INSTA_ID: '17841408408283787',
    YOUTUBE: '',
    TWITTER: '',
    MAGAZINE: ''
  },
  COOKIE: {
    BANNER: {
      MANDATORY: 'Obligatoire',
      ANALYTICS: 'Analyse',
      MARKETING: 'Marketing',
      SOCIAL_MEDIA: 'Réseaux sociaux',
      ACCEPT: 'Accepter Tout',
      REJECT: 'Refuser tout',
      SET_UP: 'Parametrer',
      MANDATORY_DETAILS: 'Les cookies requis sont nécessaires au fonctionnement du site, par exemple pour mémoriser vos informations de connexion et vous fournir une connexion sécurisée.\n' +
        'Les cookies requis pour utiliser le site bonduelle.be sont : \n' +
        '<ul>\n' +
        "<li>Cookies de sessions : Cookies générés par des applications basées sur le langage PHP. Il s'agit d'un identifiant à usage général utilisé pour gérer les variables de session utilisateur. Il s'agit normalement d'un nombre généré de manière aléatoire, la façon dont il est utilisé peut être spécifique au site, mais un bon exemple est le maintien d'un statut de connexion pour un utilisateur entre les pages.</li>\n" +
        '</ul>',
      ANALYTICS_DETAILS: 'Les cookies fonctionnels nous aident à optimiser le site, par exemple, à collecter des statistiques, à enregistrer vos préférences pour les interactions sociales ou à mesurer les performances et la stabilité de notre application.\n' +
        '<br>\n' +
        'Les cookies analytiques utilisés par le site bonduelle.be sont : \n' +
        '<ul>\n' +
        "<li>DoubleClick : Ce cookie est défini par DoubleClick (appartenant à Google) pour créer un profil des intérêts du visiteur du site Web et afficher des publicités pertinentes sur d'autres sites. L'activité principale de l'entreprise est l'échange publicitaire d'enchères en temps réel de Google.</li>\n" +
        '<li>Google Analytics : ces cookies permettre un suivi des pages les plus visitées et du trafic du site</li>\n' +
        '<li>UTMTracking : ce cookie permet la gestion des variables UTM pour un suivi optimal des différentes campagnes publicitaires en ligne</li>\n' +
        '</ul>\n',
      MARKETING_DETAILS: 'Les cookies marketing permettent un suivi de vos préférences de recherche de recettes et de produits afin de toujours vous proposer les produits les plus en adéquation avec vos envies.\n' +
        '<br>\n' +
        'Les cookies marketing utilisés par le site bonduelle.be sont : \n' +
        '<ul>\n' +
        `<li>RéseauxSociaux : ces cookies permettent l'affichage du 'SocialWall' Bonduelle présent sur la homepage du site.  Ce "mur" vous peremt de voir un un coup d'aoeil tous les derniers posts des réseaux sociaux majeurs utilisés par les équipes Bonduelle.</li>\n` +
        '</ul>',
      V2: 'ok'
    },
    MODAL: {
      TITLE: 'Gestion des cookies',
      TEXT: "Le Groupe Bonduelle se soucie d’assurer la Protection des Données Personnelles des utilisateurs de ses sites internet, et en particulier du site Bonduelle.be  <br> Pour vous permettre de bénéficier des services proposés par le site tels que la consultation de produits, la découverte de nouvelles recettes gourmandes, l'inscription à certains services tel que notre Newsletters, et également afin d’optimiser son utilisation et sa personnalisation en fonction de votre profil, le site utilise des cookies. Le Groupe Bonduelle s’engage pleinement à assurer un niveau de protection élevé à l’égard de ces Données Personnelles et à se conformer à toutes les règles applicables en matière de Protection des Données lors du Traitement des Données Personnelles réalisé dans le cadre de l'utilisation de son site Web.\n" +
        'Ainsi, vous pouvez à tout moment activer/désactiver les cookies.',
      CONFIG: 'Paramétrage',
      SAVE: 'Enregistrer'
    }
  },
  LINK: { JOB_OFFERS: 'https://jobs.bonduelle.com/?locale=fr_FR' },
  CLIC2BUY: { INFOS: '' }
};
