import {
  Component, Input,
  OnInit, OnDestroy,
  TemplateRef,
  AfterViewInit,
  AfterViewChecked
} from '@angular/core';
import { SfMessageService } from '../../../../core/message/services/message.service';

import { SliderService } from '../../services/slider.service';
import { SliderAnimations } from './slider.animations';
import { SfSlider } from '../../models/slider.interface';

@Component({
    selector: 'sf-slider',
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.sass'],
    animations: [
        SliderAnimations
    ],
    providers: [SliderService],
    standalone: false
})
export class SfSliderComponent implements OnInit, AfterViewChecked, OnDestroy {

  @Input() slider: SfSlider;

  /** 5 seconds between each slide transition */
  @Input() pace = 5000;

  @Input() template: TemplateRef<any>;

  @Input() mode: 'objective' | 'subjective' = 'objective';

  SWIPE_ACTION = { LEFT: 'swipeleft', RIGHT: 'swiperight' };

  public animationLockCounter = 0;

  constructor(
    public sliderService: SliderService,
    public eventService: SfMessageService
  ) { }

  ngOnInit() {
    this.sliderService.init(this.slider.slides, this.pace);
  }

  ngAfterViewChecked() {
    this.sliderService.start();
  }

  ngOnDestroy() {
    this.sliderService.stop();
  }

  public animationStart() {
    this.animationLockCounter = this.animationLockCounter + 1;
  }

  public animationDone() {
    this.animationLockCounter = this.animationLockCounter - 1;
  }

  public next() {
    this.eventService.setEvent('Slider', 'Suivant');
    if (this.animationLockCounter > 0) { return; }
    this.sliderService.next();
  }

  public previous() {
    this.eventService.setEvent('Slider', 'Précédent');
    if (this.animationLockCounter > 0) { return; }
    this.sliderService.previous();
  }

  public swipe(currentIndex: number, action = this.SWIPE_ACTION.RIGHT) {
    if (this.slider.slides.length === 1 || currentIndex < 0 ) return;

    // next
    if (action === this.SWIPE_ACTION.RIGHT) {
      this.previous();
    }

    // previous
    if (action === this.SWIPE_ACTION.LEFT) {
      this.next();
    }
  }

}
