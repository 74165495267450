import { SfModel, SfItem } from '@app/sf-lib/public_api';

export class SomBloc extends SfModel {

  public weight?: number;
  public icons?: string[];
  public items?: SfItem[];
  public label1?: string;
  public label2?: string;
  public id?: number;
  public contenttype?: [];

  constructor() {
    super();
    this.weight = 0;
  }
}
