import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'labelEmpty',
    standalone: false
})
export class SfLabelEmptyPipe implements PipeTransform {

  transform(value: string): boolean {
    if (value.trim() === '') {
      return false;
    }
    return true;
  }

}
