import {
  SfMediaImage, SfLink,
  SfLocalizeService,
  SfSection, SfTag, SfBanner, SfModel
} from '@app/sf-lib/public_api';
import { SomPage } from './page.model';
import { AppInjector } from '../app-injector';

export class SomFaqTag extends SfModel {
  name: string;
  total: number;
  path: string;
  link?: string
}

export class SomFaqArticlePage extends SomPage implements SfBanner {

  /** Unique ID from server */
  id: number;

  /** Type from server */
  type: string;

  /** Internal name of the article (debugging purposes) */
  name: string;

  path: {
    alias: string,
    langcode: string,
    pid: number
  }

  /** Question text (HTML) */
  question: string;

  /** Answer text (HTML) */
  answer: string;

  /** Background color of the item */
  color?: string;

  title?: string;

  /** Cover image */
  cover: SfMediaImage;

  /** Main image of the article for mobile */
  smallcover: SfMediaImage;

  /** Should we display cover or color in the item's background */
  is_cover: boolean;

  /** item's icon */
  icon: SfMediaImage;

  /** Sections of the article */
  sections: SfSection[];

  /** Tag (rubric of the article) */
  tag: SomFaqTag;

  /** Equivalent of path alias */
  url: string;
  

  get link(): SfLink {
    let alias = `${this.id}`;
    if (this.url) {
      alias = this.url.replace(/\//g, '');
    }
    let url = this.localize.instant(this.baseUrl);
    if (this.tag && this.tag.path) {
      const tagPath = this.tag.path && this.tag.path[0] && this.tag.path[0]['alias'] ? this.tag.path[0]['alias'] : this.tag.path;
      url += `${tagPath}/${alias}/${this.id}`;
    } else if (this.tag && this.tag.name) {
      const tagName = this.tag.name && this.tag.name[0] && this.tag.name[0]['value'] ? this.tag.name[0]['value'] : this.tag.name;
      url += `/${tagName.toLowerCase()}/${alias}/${this.id}`;
    }
    return {
      is_external: false,
      label: '',
      url: url
    };
  }
  set link(value: SfLink) {
    return;
  }

  /** SfBanner uppertitle */
  get uppertitle(): string {
    if (this.tag && this.tag.name) {
      return this.tag.name && this.tag.name[0] && this.tag.name[0]['value'] ? this.tag.name[0]['value'] : this.tag.name;
    } else {
      return null;
    }
  }

  /** SfBanner background */
  get background(): SfMediaImage {
    return this.cover;
  }

  /** SfBanner smallbackground */
  get smallbackground(): SfMediaImage {
    return this.smallcover;
  }

  private readonly baseUrl = '/faq';

  private localize: SfLocalizeService;

  constructor() {
    super();
    try {
      const injector = AppInjector.getInjector();
      this.localize = injector.get(SfLocalizeService);
    } catch (e) {
      console.error('Failed initializing model', e);
    }
  }

}
