import { SomPage } from './page.model';
import { Site } from './site.model';

export class SomProductArticleDetails extends SomPage {
    public id: string;
    public langcode: string;
    public type: string;
    public name: string;
    public status: Boolean;
    public gtin: string[];
    public randomize: Boolean;
    public show_video_icon_1: Boolean;
    public show_video_icon_2: Boolean;
    public site: Site;
    public content: [];
}
