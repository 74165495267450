<div class="sf-form-tf" *ngIf="data && formControl">
  <label class="sf-form-tf-label sf-title" [for]="controlName" *ngIf="data.label" [ngClass]="{'error-color': !formControl.pristine && formControl.invalid}">
    {{ data.label | translate }}
    <span *ngIf="data.required"> *</span>
  </label>
  <input #inputControl class="sf-form-tf-input sf-secondary-font-color-placeholder" [placeholder]="data.placeholder || '' | translate" [id]="controlName" [formControl]="formControl" type="{{type}}" [autocomplete]="data.autocomplete ? data.autocomplete : 'off'">
  <div class="sf-form-tf-error sf-error-color sf-title" *ngIf="!formControl.pristine && formControl.invalid">
    {{ data.error || 'SF.FORM.ERROR' | translate }}
  </div>
</div>
