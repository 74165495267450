import { SfModel } from '@app/sf-lib/public_api';
import { SomSectionTiles } from './section-tiles.interface';
import { SomTile } from './tile.model';

export class SomSimpleSectionTiles extends SfModel implements SomSectionTiles {

  /** Title */
  title: string;

  /** An array of Tiles */
  tiles: SomTile[];

  /** A boolean to know the display for this tiles */
  is_flip: boolean;
}
