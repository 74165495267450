<div *ngIf="section" class="som-section-image" [ngClass]="section.position === 'right' ? 'som-section-image-right' : section.position === 'center' ? 'som-section-image-center' : 'som-section-image-left'">

    <div [sfLink]='section.link' class="som-section-image-image">
      <sf-image [image]="section.image" class="som-global-display-deckstop" type="original"></sf-image>
      <sf-image *ngIf="section.smallimage" type="original" class="som-global-display-mobile" [image]="section.smallimage"></sf-image>
      <sf-image *ngIf="!section.smallimage" [type]="groupSEE ? 'original' : 'ratio_328_410'" class="som-global-display-mobile" [image]="section.image"></sf-image>
    </div>

    <!-- Content -->
    <div *ngIf="section.text || section.title" class="som-section-image-content">

      <!-- Title -->
      <ng-container *ngIf="section.title" [ngSwitch]="seoTitle">
        <h2 *ngSwitchCase="'H1'" class="som-section-image-content-title sf-title">{{section.title}}</h2>
        <h2 *ngSwitchCase="'H2'" class="som-section-image-content-title sf-title">{{section.title}}</h2>
        <h3 *ngSwitchCase="'H3'" class="som-section-image-content-title sf-title">{{section.title}}</h3>
        <div *ngSwitchDefault class="som-section-image-content-title sf-title">{{section.title}}</div>
      </ng-container>

      <!-- Text -->
      <div *ngIf="section.text" [innerHTML]="section.text" class="som-section-image-content-text sf-text"></div>

    </div>
</div>
