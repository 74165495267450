import { SfModel, SfLang } from '@app/sf-lib/public_api';
import { SomBloc } from './bloc.model';

export class SomPage extends SfModel {

  lang: SfLang;

  content: SomBloc[];

  meta_title: string;
  meta_description: string;
  meta_keywords: string;

  constructor() {
    super();
    this.content = [];
  }

}
