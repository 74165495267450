<div class="sf-main-color" [ngClass]="(size==='big')?'sf-item-recipe':'sf-item-recipe-small'" *ngIf="item" (mouseenter)="hover = true" (mouseleave)="hover = false">
  <div class="sf-item-recipe-image">
    <sf-image type="ratio_245_196" [sfLink]="item.link" [image]="item.cover"></sf-image>
    <div class="sf-item-recipe-icon">
      <mat-icon *ngIf="item.recipeDifficulty" class="sf-item-recipe-icon-img" svgIcon="chefs-hat"
        [ngStyle]="{'color': item.recipeDifficulty === ('PREDIGGO.DIFFICULTY.EASY' | translate) ? '#14B072' : item.recipeDifficulty === ('PREDIGGO.DIFFICULTY.MEDIUM' | translate) ? '#F39325' : '#E83D4C'}"></mat-icon>
      <mat-icon *ngIf="item.recipeTimingPreparation" class="sf-item-recipe-icon-img" svgIcon="sandglass"
        [ngStyle]="{'color': item.recipeTimingPreparation < 15 ? '#14B072' : item.recipeTimingPreparation < 45 ? '#F39325' : '#E83D4C'}"></mat-icon>
    </div>
  </div>
  <div class="sf-item-recipe-content">

    <!-- Title -->
    <ng-container *ngIf="item.title" [ngSwitch]="seoTitle">
      <h1 *ngSwitchCase="'H1'" class="sf-item-recipe-content-title sf-title">{{ (item.title[0] && item.title[0]['value'] ? item.title[0]['value'] : item.title) | truncateString: 40 }}</h1>
      <h2 *ngSwitchCase="'H2'" class="sf-item-recipe-content-title sf-title">{{ (item.title[0] && item.title[0]['value'] ? item.title[0]['value'] : item.title) | truncateString: 40 }}</h2>
      <h3 *ngSwitchCase="'H3'" class="sf-item-recipe-content-title sf-title">{{ (item.title[0] && item.title[0]['value'] ? item.title[0]['value'] : item.title) | truncateString: 40 }}</h3>
      <div *ngSwitchDefault class="sf-item-recipe-content-title sf-title">{{ (item.title[0] && item.title[0]['value'] ? item.title[0]['value'] : item.title) | truncateString: 40 }}</div>
    </ng-container>

    <!-- Text -->
    <p *ngIf="item.recipeEditorial && size === 'big'" class="sf-item-recipe-content-text sf-text">{{item.recipeEditorial | truncateString: 500 }}</p>
    <p *ngIf="item.recipeEditorial && size !== 'big'" class="sf-item-recipe-content-text sf-text">{{item.recipeEditorial | truncateString: 100 }}</p>

    <!-- CTA -->
    <button [sfLink]="item.link" [ngClass]="(size==='big')?'sf-item-recipe-button-small': 'sf-item-recipe-button-big'" class="sf-item-recipe-content-button som-font-bold">{{ 'RECIPE.DISCOVER_RECIPE' | translate }}</button>
  </div>
</div>
