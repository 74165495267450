import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sortArray',
    standalone: false
})
export class SortArrayPipe implements PipeTransform {

  transform(array: string[], key: string, sort = 'ASC'): string[] {
    return [...array].sort((a, b): number => {
      if(sort === 'ASC') {
        return (a[key] > b[key]) ? 1 : (a[key] < b[key]) ? -1 : 0;
      } else {
        return (a[key] < b[key]) ? 1 : (a[key] > b[key]) ? -1 : 0;
      }
    });
  }

}
