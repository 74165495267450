import { Component, Input, ViewEncapsulation } from '@angular/core';

import { ResponsiveTableModel } from './responsive-table.model';

@Component({
    selector: 'responsive-table',
    templateUrl: './responsive-table.component.html',
    styleUrls: ['./responsive-table.component.sass'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class ResponsiveTableComponent {

  @Input() section: ResponsiveTableModel;

}
