import { Subject } from 'rxjs';
import { OverlayRef } from '@angular/cdk/overlay';

export interface SfPopoverCloseEvent<T> {
  type: 'backdropClick' | 'close';
  data: T;
}

export class SfPopoverRef<T = any> {
  private afterClosed = new Subject<SfPopoverCloseEvent<T>>();
  afterClosed$ = this.afterClosed.asObservable();

  constructor(
    public overlay: OverlayRef,
    public content: any,
    public data: T
  ) {
    overlay.backdropClick().subscribe(() => this._close('backdropClick', data));
  }

  close(data?: T) {
    this._close('close', data);
  }

  private _close(type, data) {
    this.overlay.dispose();
    this.afterClosed.next({
      type,
      data
    });
    this.afterClosed.complete();
  }
}
