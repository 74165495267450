export class SfEvent {

  title: string;

  tag?: string;

  constructor() {
    this.title = '';
  }

}
