import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SfSanitizeResourceUrlPipe } from './pipes/sanitize-resource-url.pipe';
import { TruncateStringPipe } from './pipes/truncate-string.pipe';
import { SfSearchLinkPipe } from './pipes/search-link.pipe';
import { SfHighlightPipe } from './pipes/highlight.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [SfSanitizeResourceUrlPipe, TruncateStringPipe, SfSearchLinkPipe, SfHighlightPipe],
  exports: [SfSanitizeResourceUrlPipe, TruncateStringPipe, SfSearchLinkPipe, SfHighlightPipe]
})
export class SfUtilsModule { }
