import { SfModel } from '../networking/models/model';
import { SfMediaHoverImage } from '../../features/image/models/media-hover-image.model';
import { SfLink } from '../../features/link';

export class SfMenuItem extends SfModel {

  /** Displayed text */
  displayed_text?: string;

  /** Displayed image */
  displayed_image?: SfMediaHoverImage;

  /** ngxtranslate key used to display text */
  translation_key?: string;

  /** Link object */
  link?: SfLink;

  /** Nested menu items */
  children?: SfMenuItem[];

  /** Is this itemMenu active (should it be highlighted) */
  active: boolean;
}
