<div class="sf-search sf-secondary-font-color sf-main-color-mobile">

  <input [formControl]="searchCtrl"
    class="sf-search-bar sf-secondary-font-color sf-secondary-font-color-placeholder"
    [ngClass]="{'sf-search-bar-top': topBar}"
    [ngStyle]="{'text-align': siteInfo?.lang?.country === 'il' ? 'end' : 'left'}"
    type="text"
    [placeholder]="'SF.SEARCH.PLACEHOLDER' | translate"
    tabindex="1"
    [sfAutocomplete]="auto">

  <div [ngClass]="siteInfo?.lang?.country === 'il'? 'sf-search-il':'sf-search-bar-icon-container'">
    <!-- Search icon -->
    <mat-icon (click)="eventService.setEvent(searchCtrl.value, 'Click search')" *ngIf="!searchCtrl.dirty" class="sf-search-bar-icon sf-search-bar-icon-top" svgIcon="search"></mat-icon>

    <!-- Launch search icon -->
    <mat-icon *ngIf="searchCtrl.dirty && searchCtrl.untouched" class="sf-search-bar-icon" svgIcon="arrow-right" (click)="search()"></mat-icon>

    <!-- Cancel search -->
    <mat-icon *ngIf="searchCtrl.dirty && searchCtrl.touched" class="sf-search-bar-icon" svgIcon="close" (click)="reset()"></mat-icon>
  </div>

  <sf-autocomplete #auto (optionSelected)="search($event)">
    <sf-option [ngClass]="{'sf-option-search': !topBar}" class="sf-secondary-font-color" *ngFor="let option of keywordRecommandation" [value]="option" [innerHTML]="option | sfHighlight: searchCtrl.value"></sf-option>
  </sf-autocomplete>
</div>
