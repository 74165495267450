<div class="sf-slider-grid" [ngClass]="{'sf-slider-grid-item': content === 'item'} " *ngIf="sliderGrid">
  <!-- Left arrow -->
  <div class="sf-slider-grid-arrow sf-slider-grid-arrow-left" (click)="previous()" *ngIf="sliderGrid.gridSlides?.length > 1">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 50">
      <defs>
      <style>.cls-1 {fill: #FFF}</style>
      <clipPath>
        <path class="cls-1" d="M0 4l22 21L0 47l4 3 25-25L4 0z"/>
      </clipPath>
    </defs>
    <g transform="rotate(-180 14 25)">
      <g>
        <path class="cls-1" d="M0 4l22 21L0 47l4 3 25-25L4 0z"/>
      </g>
    </g>
  </svg>
</div>

  <!-- Slider -->
  <ul class="sf-slider-grid-slider">

    <!-- Slide -->
    <li [@sliderAnimations]="sliderGridService.getAnimationState(i)" 
        (@sliderAnimations.start)="animationStart()" 
        (@sliderAnimations.done)="animationDone()"
        *ngFor="let slideGrid of sliderGrid.gridSlides; let i = index" 
        class="sf-slider-grid-slide">
      <ng-template [ngTemplateOutlet]="template" 
      [ngTemplateOutletContext]="{$implicit: slideGrid, state: sliderGridService.getAnimationState(i)}">
      </ng-template>
    </li>
  </ul>
  <!-- Right Arrow -->
  <div class="sf-slider-grid-arrow sf-slider-grid-arrow-right" (click)="next()" *ngIf="sliderGrid.gridSlides?.length > 1">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 50">
        <defs>
          <style>.cls-1 {fill: #FFF}</style>
          <clipPath>
            <path class="cls-1" d="M0 4l22 21L0 47l4 3 25-25L4 0z"/>
          </clipPath>
        </defs>
        <g>
          <g>
            <path class="cls-1" d="M0 4l22 21L0 47l4 3 25-25L4 0z"/>
          </g>
        </g>
      </svg>
    </div>
  <!-- Breadcrumb -->
  <div class="sf-slider-grid-breadcrumb-container" [ngClass]="{'sf-slider-breadcrumb-center': mode === 'subjective'}">
    <sf-breadcrumb-grid *ngIf="sliderGrid.gridSlides?.length > 1" class="sf-slider-grid-breadcrumb" [elements]="sliderGrid.gridSlides"
      [selectedIndex]="sliderGridService.index" (select)="sliderGridService.selectSlide($event)" [disabled]="animationLockCounter > 0"></sf-breadcrumb-grid>
  </div>
</div>