import { Component, OnInit, OnDestroy, Input, TemplateRef, AfterViewInit } from '@angular/core';
import { SliderService } from '../../services/slider.service';
import { SliderAnimations } from '../slider/slider.animations';
import { SfSlider } from '../../models/slider.interface';
import { SfMessageService } from '../../../../core/message/services/message.service';

@Component({
    selector: 'sf-product-slider',
    templateUrl: './product-slider.component.html',
    styleUrls: ['./product-slider.component.sass'],
    animations: [SliderAnimations],
    providers: [SliderService],
    standalone: false
})
export class SfProductSliderComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() pSlider: SfSlider;

  @Input() pace = 0;

  @Input() pushTemplate: TemplateRef<any>;

  @Input() mode: 'main' | 'magazine' = 'main';

  @Input() buttons = false;

  public animationLockCounter = 0;

  constructor(
    public sliderService: SliderService,
    public eventService: SfMessageService
  ) { }

  ngOnInit() {
    this.sliderService.start();
  }

  ngAfterViewInit() {
    this.sliderService.init(this.pSlider.slides, this.pace);
  }

  ngOnDestroy() {
    this.sliderService.stop();
  }

  public animationStart() {
    this.animationLockCounter = this.animationLockCounter + 1;
  }

  public animationDone() {
    this.animationLockCounter = this.animationLockCounter - 1;
  }

  public next() {
    this.eventService.setEvent('recipe','Slider bonduelle');
    if (this.animationLockCounter > 0) { return; }
    this.sliderService.next();
  }

  public previous() {
    this.eventService.setEvent('recipe','Slider bonduelle');
    if (this.animationLockCounter > 0) { return; }
    this.sliderService.previous();
  }
}
