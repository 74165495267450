import { SfModel } from '../../../core/networking/models/model';
import { SfSliderGrid } from './slider-grid.interface';
import { SfSlideGrid } from './slide-grid.interface';

export class SfSimpleSliderGrid extends SfModel implements SfSliderGrid {

  gridSlides: SfSlideGrid[];

  constructor() {
    super();
    this.gridSlides = [];
  }
}
