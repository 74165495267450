import { SfModel } from '../../../core/networking/models/model';
import { SfItem } from '../../item';
import { SfSlideGrid } from './slide-grid.interface';

export class SfSimpleSlideGrid extends SfModel implements SfSlideGrid {

    public items: SfItem[];

    constructor() {
        super();
        this.items = [];
    }
}
