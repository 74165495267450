import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionImageComponent } from './components/section-image.component';
import { SfImageModule, SfLinkModule } from '@app/sf-lib/public_api';


@NgModule({
  declarations: [SectionImageComponent],
  imports: [
    CommonModule,
    SfImageModule,
    SfLinkModule
  ],
  exports: [SectionImageComponent]
})
export class SectionImageModule { }
