<div class="sf-item sf-accent-color" *ngIf="item"
[ngClass]="{'sf-item-packshot': packshot}"
(mouseenter)="hover = true" (mouseleave)="hover = false">

  <!-- Image -->
  <sf-image (click)="setEvent(item)" type="ratio_416_520" [sfRollover]="hover" [sfLink]="link" class="sf-global-display-deckstop sf-item-background" [image]="item.cover"></sf-image>
  <sf-image (click)="setEvent(item)" *ngIf="item.smallcover" type="ratio_416_520" [sfRollover]="hover" [sfLink]="link" class="sf-global-display-mobile sf-item-background" [image]="item.smallcover"></sf-image>
  <sf-image (click)="setEvent(item)" *ngIf="!item.smallcover" type="ratio_416_520" [sfRollover]="hover" [sfLink]="link" class="sf-global-display-mobile sf-item-background" [image]="item.cover"></sf-image>

  <!-- Text content -->
  <div (click)="setEvent(item)" class="sf-item-content" [sfLink]="item.link">

    <!-- Tag -->
    <!-- <ng-container *ngIf="item.tag?.name" [ngSwitch]="seoTag">
      <h1 *ngSwitchCase="'H1'" class="sf-title" [ngClass]="(item.title && item.tag)?'sf-item-content-title':'sf-item-title sf-title'">{{ (item.tag.name[0] && item.tag.name[0]['value'] ? item.tag.name[0]['value'] : item.tag?.name) | uppercase }}</h1>
      <h2 *ngSwitchCase="'H2'" class="sf-title" [ngClass]="(item.title && item.tag)?'sf-item-content-title':'sf-item-title sf-title'">{{ (item.tag.name[0] && item.tag.name[0]['value'] ? item.tag.name[0]['value'] : item.tag?.name) | uppercase }}</h2>
      <h3 *ngSwitchCase="'H3'" class="sf-title" [ngClass]="(item.title && item.tag)?'sf-item-content-title':'sf-item-title sf-title'">{{ (item.tag.name[0] && item.tag.name[0]['value'] ? item.tag.name[0]['value'] : item.tag?.name) | uppercase }}</h3>
      <div *ngSwitchDefault
            class="sf-title"
            [ngClass]="{'sf-item-content-title-shadow': !packshot,
            'sf-item-content-title': (item.title && item.tag),
            'sf-item-title sf-title': !(item.title && item.tag)
            }"

            >
        {{ (item.tag.name[0] && item.tag.name[0]['value'] ? item.tag.name[0]['value'] : item.tag?.name) | uppercase }}
      </div>
    </ng-container> -->

    <!-- Title -->
    <ng-container *ngIf="item.title" [ngSwitch]="seoTitle">
      <h2 *ngSwitchCase="'H1'" class="sf-item-content-subtitle sf-title">{{ (item.title[0] && item.title[0]['value'] ? item.title[0]['value'] : item.title) | truncateString: truncateNb }}</h2>
      <h2 *ngSwitchCase="'H2'" class="sf-item-content-subtitle sf-title">{{ (item.title[0] && item.title[0]['value'] ? item.title[0]['value'] : item.title) | truncateString: truncateNb }}</h2>
      <h3 *ngSwitchCase="'H3'" class="sf-item-content-subtitle sf-title">{{ (item.title[0] && item.title[0]['value'] ? item.title[0]['value'] : item.title) | truncateString: truncateNb }}</h3>
      <div *ngSwitchDefault
            class="sf-item-content-subtitle sf-title"
            [ngClass]="{'sf-item-content-subtitle-shadow': !packshot}">
          {{ (item.title[0] && item.title[0]['value'] ? item.title[0]['value'] : item.title) | truncateString: truncateNb }}
      </div>
    </ng-container>

  </div>
</div>
