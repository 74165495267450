/* tslint:disable */export const fi_fi = {
  '404': {
    TITLE: 'Hups...',
    PAGENOTFOUND: 'Sivua ei ole',
    BUTTON: 'Paluu aloitussivulle',
    RECIPES: 'Katso reseptimme'
  },
  HOME: {
    SHOWCASE: 'Reseptit',
    CATCH_PHRASE: "Today's surprising vegetables, \nfor a better tomorrow",
    SHOWCASE_SEE_ALL: 'Katso kaikki reseptit',
    MAGAZINE: { TITLE: 'Valitut artikkelit', BUTTON: 'Lue uutisiamme' },
    OUR_VEGETABLES: 'Tuotteemme',
    SOCIAL_WALL: 'Jaa rakkautesi vihenneksiin kanssamme',
    FAQ_TITLE: 'FAQ',
    FAQ_SEE_MORE: 'Lue lisää',
    FLOWBOX: ''
  },
  SELECT: { DEFAULT_VALUE: 'Suositellut' },
  MENU: {
    CONTACT_US: 'Ota yhteyttä',
    MY_ACCOUNT: 'Oma tili',
    GOOD_DEALS: 'FAQ',
    NEWSLETTER: 'Bonduelle-uutiskirje'
  },
  FOOTER: {
    LINK: {
      BONDUELLE: 'Bonduelle.fi',
      CREDITS: 'Credits',
      LEGAL_NOTICE: 'Juridinen ilmoitus',
      TERMS_OF_SALES: 'Yleiset myyntiehdot',
      COOKIES: 'Evästeet',
      SITEMAP: 'Sivustokartta',
      PRIVACY_POLICY: 'Ulkoinen tietosuojakäytäntö',
      ALL_RIGHTS_RESERVED: '2021 © Kaikki oikeudet pidätetään'
    },
    HISTORY: {
      TITLE: 'Bonduelle - vuodesta 1853',
      TEXT: 'Bonduelle on ranskalainen perheyritys, jonka juuret ulottuvat aina vuoteen 1853. Tiedämme siis varsin paljon siitä, miten kasviksia korjataan ja pakataan niin että niiden parhaat ominaisuudet säilyvät. Bonduelle haluaa kasvisten olevan merkittävä osa tulevaisuuden ruokavaliota. Olemme myös keskittyneet ruokahävikin minimointiin, joten suurin osa valikoimastamme on pienissä pakkauksissa. Näin uskomme täyttävämme sekä pienten kotitalouksien tarpeet että vähentävämme ruokahävikin määrää.'
    },
    NEWSLETTER: {
      TITLE: 'Bonduelle Community',
      TEXT: 'Haluan Bonduellen "Kaikki kasviksista" -uutiskirjeen (uutisia, kampanjoja, kilpailuja, artikkeleita, jne.).',
      PLACEHOLDER: 'Sähköpostiosoitteesi',
      GDPR_LINK: 'Lisätietoja tietojesi käsittelystä'
    }
  },
  PRODUCTS: 'Tuotteet',
  MAGAZINE: {
    HOME: 'Tule tutustumaan uutuuksiimme',
    CATCH_PHRASE: '',
    MENU: { BONDUELLE_LINK: 'Palaa' },
    SHOWCASE: 'Valitut artikkelit',
    PREVIOUS_ARTICLES: 'Aiemmat artikkelit',
    PREVIOUS_ARTICLES_SEE_ALL: 'Katso kaikki artikkelimme',
    PREVIOUS_EDITIONS: 'Aiemmat numerot',
    PREVIOUS_EDITIONS_SEE_ALL: 'Katso lisää numeroita',
    PREVIOUS_ARTICLES_BY_CATEGORY: 'Aiemmat artikkelit kategorian mukaan',
    RUBRIC: {
      SEE_ALL: 'Katso kaikki reseptit',
      SEE_ALL_ARTICLES: 'Katso kaikki artikkelit',
      ON: 'artikkelia/'
    },
    RUBRIC_COOK: { ON: 'reseptiä /' },
    ARTICLE: { JOIN_US: 'Seuraa meitä', READ_ALSO: 'Lue myös:' },
    RECIPE: {
      INGREDIENTS: 'Ainekset',
      INGREDIENTS_LABEL_DESC: '{{value}} henkilölle',
      INGREDIENTS_LABEL_ONE_DESC: '{{value}} henkilölle',
      INGREDIENTS_LABEL: 'Ainesosat {{value}} henkilölle',
      INGREDIENTS_LABEL_ONE: 'Ainesosat {{value}} henkilölle',
      PEOPLES: '{{value}} henkilöä',
      PEOPLE: '{{value}} henkilö',
      DIFFICULTY: { EASY: 'Helppo', MEDIUM: 'Keskitaso', HARD: 'Vaikea' },
      COST: { CHEAP: 'Halpa', EXPENSIVE: 'Kallis' },
      TIMING: { MIN: 'min', HOURS: 'h' },
      STEP: 'Vaihe',
      DISCOVER_ALSO: 'Katso myös:'
    }
  },
  ARTICLE: { DISCOVER_ALSO: 'Lue myös:', INGREDIENTS: 'Ainekset' },
  SF: {
    SLIDE: { DISCOVER: 'Tutustu' },
    SEARCH: { PLACEHOLDER: 'Haku', ALL: 'Kaikki' },
    NEWSLETTER: {
      BTN_LABEL: 'Rekisteröidy',
      PLACEHOLDER: 'Sähköpostiosoitteesi',
      ERROR_MESSAGE: 'Virheellinen sähköposti',
      ALREADY_SUBSCRIBED: 'Olet jo rekisteröitynyt',
      GDPR: {
        TXT: 'Lisätietoja tietojesi käsittelystä',
        LINK: '/ekstern-databeskyttelsespolitik'
      }
    },
    MONTHLY_FOLDER: { TITLE: 'Kuukausiuutiset' },
    MINI_NEWSLETTER: {
      PLACEHOLDER: 'Sähköpostiosoitteesi',
      GDPR: {
        TXT: 'Lisätietoja tietojesi käsittelystä',
        LINK: '/ekstern-databeskyttelsespolitik'
      }
    },
    SOCIALITEM: { SHARE: 'osa', COMMENT: 'kommentit', BONDUELLE: 'Bonduelle' },
    SEARCHRECIPE: { DISCOVER_ALL: 'Tutustu resepteihimme!' },
    FORM: {
      ERROR: 'Virheellinen kenttä',
      PHOTO: { ADD: 'Lisää kuva', DELETE: 'Poista kuva', SEE: 'Katso' }
    }
  },
  CORPORATE: {
    COMPANY_STORY: 'Historiamme',
    CSR_COMMITMENT: 'Sitoumuksemme',
    VEGETABLE_INNOVATIONS: 'Säilykkeet & innovaatiot',
    NEWS: 'kaikki artikkelit',
    SEE_MORE: 'Katso lisää artikkeleita',
    ON: 'artikkelia/'
  },
  NEWSLETTER: {
    TITLE: 'Tilaa uutiskirje',
    SUBTITLE: 'Hyviä ideoita ja inspiraatiota ruoanlaittoon!'
  },
  ROUTES: {
    products: 'tuotteet',
    news: 'viimeisimmat-uutiset',
    'since-1853': 'vuodesta-1853',
    'field-plate': 'pellolta-poytaan',
    'company-story': 'meidan-tarinamme',
    'csr-commitments': 'meidan-sitoumuksemme',
    'vegetal-innovations': 'sailontainnovaatiot',
    magazine: 'uutiset',
    all: 'katso kaikki',
    article: 'artikkeli',
    cook: 'ruoanlaitto',
    cultivate: 'viljella',
    explore: 'tutki',
    recipe: 'reseptimaailma',
    recipes: 'reseptit',
    'recipes-home': 'resepti',
    r: 'r',
    credits: 'credits-fi',
    'legal-notice': 'oikeudellinen ilmoitus',
    'terms-of-sales': 'yleiset myyntiehdot',
    cookies: 'ulkoinen-tietosuojakaytanto',
    sitemap: 'sivukartta',
    'privacy-policy': 'ulkoinen-tietosuojakaytanto',
    'all-rights-reserved': 'kaikki oikeudet pidatetaan',
    search: 'etsi',
    'articles-search': 'artikkeli',
    vegetables: 'tuotteet',
    p: 'p',
    contact: 'ota-yhteytta',
    'info-product': 'tuotetiedot',
    'note-product': 'tuotereklamaatio',
    suggestion: 'ehdotus',
    'other-request': 'tuotetiedot',
    faq: 'faq',
    'all-themes': 'kaikki',
    'products-search': 'tuotehaku',
    recipesearch: 'reseptihaku',
    'product-news': 'uutuudet',
    'good-deals': 'hyvia-tarjouksia',
    'not-found': 'ei-loytynyt',
    'menu-page': 'valikkosivu',
    'menu-one': 'menu-yksi',
    'menu-two': 'menu-kaksi',
    'menu-three': 'menu-kolme',
    'menu-four': 'menu-nelja',
    'menu-external': '',
    'theme-pasta-rice': 'teema-pasta-riisi',
    'theme-bowls': 'teema-kulhot',
    'theme-soup': 'teema-keitot',
    'theme-snack': 'teema-snacks',
    'theme-sauce': 'teema-kastikkeet',
    'theme-unique-plate': '',
    'theme-over': 'teema-paaruoat',
    'theme-legumin': 'teema-palkokasvit',
    'theme-ingredients-recipes': 'teema-ainekset-reseptit',
    'theme-cook-plate': ''
  },
  BREAD: {
    products: 'tuotteet',
    news: 'viimeisimmat-uutiset',
    'since-1853': 'vuodesta 1853',
    'field-plate': 'pellolta-poytaan',
    'company-story': 'historiamme',
    'csr-commitments': 'meidan sitoumuksemme',
    'vegetal-innovations': 'sailontainnovaatiot',
    magazine: 'uutiset',
    all: 'katso kaikki',
    article: 'artikkeli',
    cook: 'ruoanlaitto',
    cultivate: 'viljella',
    explore: 'tutki',
    recipe: 'reseptimaailma',
    recipes: 'reseptit',
    'recipes-home': 'resepti',
    'articles-search': 'artikkeli',
    search: 'etsi',
    vegetables: 'tuotteet',
    contact: 'ota yhteytta',
    'info-product': 'tuotetiedot',
    'note-product': 'tuotereklamaatio',
    suggestion: 'ehdotus',
    'other-request': 'tuotetiedot',
    faq: 'faq',
    'all-themes': 'kaikki',
    'products-search': 'tuotehaku',
    recipesearch: 'maaritteen haku',
    'product-news': 'uutuustuotteet',
    'good-deals': 'hyvia tarjouksia',
    'not-found': 'ei loytynyt',
    'menu-page': 'valikkosivu',
    'menu-one': 'menu-yksi',
    'menu-two': 'menu-kaksi',
    'menu-three': 'menu-kolme',
    'menu-four': 'menu-nelja'
  },
  MENU_PAGE: {
    TITLE1: 'OTSIKKO 1',
    TITLE2: 'OTSIKKO 2',
    TITLE3: 'OTSIKKO 3',
    TITLE4: 'OTSIKKO 4',
    EXTERNAL: ''
  },
  TECHNICAL: { MENU_PAGE: 'job_opslag' },
  SEO: {
    TITLE: {
      MENU_ONE: 'menu one Title',
      MENU_TWO: 'menu two Title',
      MENU_THREE: 'menu three Title',
      MENU_FOUR: 'menu four Title',
      HOME: 'Bonduelle: inspiroidu vihannesten maailmasta',
      SEARCH: {
        MAIN: '{{value}} | Bonduelle',
        RECIPES: '{{value}} lajittele reseptin mukaan | Bonduelle',
        VEGETABLES: '{{value}} Lajittele vihannesten mukaan | Bonduelle',
        ARTICLES: '{{value}} Lajittele artikkeli mukaan | Bonduelle'
      },
      CONTACT: 'Asiakaspalvelu | Bonduelle',
      RECIPES: 'Reseptit: keittoja, salaatteja, pääruokia ... | Bonduelle\n',
      RECIPE_HOME: 'Bonduellen reseptimaailma | Bonduelle',
      RECIPE: 'Resepti {{value}} | Bonduelle',
      PRODUCTS: 'Säilykkeet  | Bonduelle',
      NEWS: 'Tutustu kasvisten mahdollisuuksiin  | Bonduelle',
      CORPORATE: {
        NEWS: 'Uusia tuotteita kasviksista ja ruoanlaitosta  | Bonduelle',
        COMPANY_STORY: 'Tutustu Bonduelleen ja sen historiaan  | Bonduelle',
        CSR_COMMITMENTS: 'Lue arvoistamme ja periaatteistamme  | Bonduelle',
        VEGETAL_INNOVATIONS: 'Lue innovaatioistamme | Bonduelle',
        FIELD_PLATE: 'Vihannekset: parhaat vihannekset parhaille resepteille | Bonduelle',
        ARTICLE: '{{value}} | Bonduelle',
        VEGETABLE: 'Lisätietoja tuotteesta {{value}} | Bonduelle'
      },
      PRODUCT: '{{value}}: Bonduellen vihannekset',
      MAGAZINE: {
        NEWS: 'Artikkelit vihannesten eduista, resepteistä ja uutisista | Bonduelle',
        ARTICLE: 'Bonduelle',
        COOK: 'Nopeat ja helpot vihannesohjeet | Bonduelle',
        GROW: 'Kasvata vihanneksia | Bonduella',
        EXPLORE: 'Lue lisää kasviksista| Bonduelle',
        RECIPE: 'Resepti {{value}} | Bonduelle'
      },
      FAQ: 'Usein kysyttyä | Bonduelle',
      NOTFOUND: '404-virhe.. Yritä myöhemmin uudelleen | Bonduelle',
      RECIPES_ALL: 'Reseptimme',
      RECIPETYPE: 'Reseptit: {{value}}',
      RECIPESEASON: 'Kausi: {{value}}',
      RECIPEPREPARATION: 'Ohje: valmistusaika {{value}}',
      RECIPEDIFFICULTY: 'Helpot vihannesohjeet {{value}} mukaan.',
      RECIPEVEGETABLE: 'Helppoja reseptejä {{value}}',
      RECIPECOST: 'Reseptit ja vihannekset {{value}}',
      RECIPEDISH: 'Reseptejä kasviksilla haulle {{value}}',
      RECIPEDESC: 'Reseptit ja vihannekset {{value}}',
      '2RECIPEFACETS': 'Resepti {{value}}',
      '4RECIPEFACETS': 'Resepti {{value}}',
      PRODUCTSEASON: 'Reseptit vihanneksilla, jotka sopivat: {{value}}',
      PRODUCTTECHNO: 'Helpot reseptit kasviksista {{value}}',
      PRODUCTVEGETABLE: 'Helppoja reseptejä {{value}}',
      PRODUCTRANGE: 'Helppoja reseptejä {{value}}',
      THEME_ALL: 'Kasvissäilykkeet',
      THEME_STARTER: 'Reseptejä ruoanlaittoon',
      THEME_SALAD: 'Salaattireseptejä',
      THEME_CAN: '',
      THEME_FROZEN: '',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: '',
      THEME_RECIPE: 'Helppoja ja nopeita reseptejä',
      THEME_PLATE: 'Ohjeita lämpimille ja kylmille ruoille',
      THEME_ACC: 'Ruokaohjeita lisukkeille',
      THEME_SNACK: 'Reseptejä pikkuruokiin ja naposteltaviin',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': 'Resepti kasviksilla {{value}}'
    },
    DESCRIPTION: {
      MENU_ONE: 'menu one description',
      MENU_TWO: 'menu two Description',
      MENU_THREE: 'menu three Description',
      MENU_FOUR: 'menu four Description',
      HOME: 'Tutustu tuotteisiimme ja inspiroidu ruoanlaitosta.',
      SEARCH: {
        MAIN: 'Tutustu resepteihimme ja tuotteisiimme {{value}}. Helppoja ja herkullisia ruokaohjeita käyttövalmiista vihanneksista.',
        RECIPES: 'Kaikki {{value}} on lajiteltu reseptin mukaan. Helppoja ja herkullisia vihannesohjeita.',
        VEGETABLES: 'Kaikki {{value}} on lajiteltu vihannesten mukaan. Helppoja ja herkullisia vihannesohjeita.',
        ARTICLES: 'Kaikki {{value}} on lajiteltu artikkeli mukaan. Helppoja ja herkullisia vihannesohjeita.'
      },
      CONTACT: 'Onko sinulla kysyttävää tuotteistamme? Voit ottaa yhteyttä asiakaspalveluumme.',
      RECIPES: 'Tutustu herkullisiin kasvispohjaisiin resepteihimme. Nauti maukkaista ja nopeista ruoista, jotka on valmistettu käyttövalmiista vihanneksista.',
      RECIPE_HOME: 'Tutustu herkullisiin kasvispohjaisiin resepteihin ennen vuoden juhlapyhiä. Nauti maukkaista ja nopeista ruoista, jotka on valmistettu käyttövalmiista vihanneksista.',
      RECIPE: '',
      PRODUCTS: 'Tarjoamme vihannesvalikoiman, jonka avulla voit valmistaa herkullisia ja helppotekoisia ruokia.',
      NEWS: 'Lue lisää miten valmistat vihanneksia maukkailla tavoilla. Helpot reseptit, jotka on valmistettu valmiista kasviksista.',
      CORPORATE: {
        NEWS: 'Bonduellen vihannesmaailman uusimmat uutiset.',
        COMPANY_STORY: 'Haluatko tietää lisää vihannesmaailmastamme ja historiastamme? Lue lisää blogistamme.',
        CSR_COMMITMENTS: 'Haluatko tietää lisää siitä, miten kasviksia kasvatetaan niiden alkuperästä ja kuinka kunnioitamme ympäristöä? Täältä löydät vastaukset kysymyksiisi.',
        VEGETAL_INNOVATIONS: 'Kehitämme kasviksiin liittyviä innovaatioita, kuten vihennesten viljelyä ympäristöystävällisellä ja hävikkiä vähentävällä tavalla. Helppoja ja herkullisia ruokaohjeita. Käyttövalmiit vihannekset.',
        VEGETABLE: '',
        FIELD_PLATE: 'Tutustu herkullisiin resepteihimme.',
        ARTICLE: ''
      },
      PRODUCT: '{{value}} herkullisten reseptien valmistamiseen. Helpot ja herkulliset reseptit, jotka on valmistettu käyttövalmiista kasviksista.',
      MAGAZINE: {
        NEWS: 'Kaikki uusimmat uutiset kasviksista ja kasvisruoasta. Helpot ja herkulliset reseptit, jotka on valmistettu käyttövalmiista kasviksista.',
        ARTICLE: '',
        COOK: 'Maukkaat reseptimme ruokavieraidesi iloksi. Tutustu niihin kotisivullamme. Helpot ja herkulliset reseptit, jotka on valmistettu käyttövalmiista kasviksista.',
        GROW: 'Haluatko kasvattaa vihanneksia itse? Lue vihannesten kasvatukseen ja istuttamiseen liittyvät vinkkimme ja neuvomme.',
        EXPLORE: 'Inspiroidu hauskoista resepteistä, joiden avulla totutat lapsia uusiin vihanneksiin ja makuihin.',
        RECIPE: ''
      },
      FAQ: 'Onko sinulla kysyttävää? Olemme koonneet tähän kysymyksiä, joita asiakkaamme esittävät useimmin.',
      PAGE: 'sivu',
      RECIPETYPE: 'Tutustu resepteihimme {{value}} ja lue, miten valmistat vihannekset parhaiten.',
      RECIPESEASON: 'Tutustu resepteihimme ja vihannesten kuten {{value}} valmistamiseen.',
      RECIPEPREPARATION: 'Tutustu resepteihimme ja vihannesten valmistamiseen osoitteessa {{value}}.',
      RECIPEDIFFICULTY: 'Tutustu resepteihimme ja vihannesten valmistustapoihin {{value}}.',
      RECIPEVEGETABLE: 'Tutustu resepteihimme, joiden {{value}} auttaa sinua valmistamaan vihanneksia parhaalla mahdollisella tavalla.',
      RECIPECOST: 'Tutustu resepteihimme ja katso, miten kasvikset valmistetaan parhaiten - {{value}}.',
      RECIPEDISH: 'Tutustu resepteihimme ja vihannesten parhaisiin valmistustapoihin {{value}} henkilölle.',
      RECIPEDESC: 'Tutustu resepteihimme ja vihannesten valmistustapoihin parhaiten: {{value}}.',
      '2RECIPEFACETS': 'Tutustu resepteihimme ja vihannesten valmistustapoihin parhaiten: {{value}}.',
      '4RECIPEFACETS': 'Tutustu resepteihimme ja vihannesten parhaisiin valmistustapoihin {{value}}.',
      PRODUCTSEASON: 'Tarjoamme vihannesvalikoiman, jonka avulla voit valmistaa maistuvia ja helppotekoisia {{value}} -ruokia',
      PRODUCTTECHNO: 'Tarjoamme vihannesvalikoiman, jonka avulla voit valmistaa herkullisia ja helppotekoisia ruokia {{value}}.',
      PRODUCTVEGETABLE: 'Tarjoamme vihannesvalikoiman, jonka avulla voit valmistaa maistuvia ja helppotekoisia ruokia {value}}.',
      PRODUCTRANGE: 'Tarjoamme vihannesvalikoiman, jonka avulla voit valmistaa maistuvia ja helppotekoisia ruokia {value}}.',
      THEME_ALL: 'Tarjoamme vihannesvalikoiman, jonka avulla voit valmistaa herkullisia ja helppotekoisia ruokia.',
      THEME_STARTER: 'Etsitkö inspiraatiota alkuun? Meiltä löydät laajan valikoiman herkullisia reseptejä ja paljon muuta.',
      THEME_SALAD: 'Etsitkö inspiraatiota maistuvaan ja raikkaaseen salaattiin? Meiltä löydät laajan valikoiman herkullisia reseptejä ja paljon muuta.',
      THEME_CAN: '',
      THEME_FROZEN: '',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: '',
      THEME_RECIPE: 'Vihanneksia, joiden avulla voit valmistaa ruokaa helposti ja nopeasti.',
      THEME_PLATE: 'Etsitkö inspiraatiota tasapainotettuun ateriaan? Meiltä löydät runsaasti herkullisia reseptejä lämpimiin ja kylmiin ruokiin.',
      THEME_ACC: 'Näitä vihanneksia voi käyttää kätevien ja nopeiden lisukkeiden valmistukseen.',
      THEME_SNACK: 'Etsitkö inspiraatiota tasapainoiseen naposteluruokaan? Meiltä löydät runsaasti herkullisia reseptejä herkullisiin pikkuruokiin ja naposteltavaksi.',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': 'Tarjoamme vihannesvalikoiman herkullisten, helppojen ja tasapainoisten reseptien {{value}} valmistamiseen.',
      NOTFOUND: 'Pyydettyä sivua ei löytynyt. Tee uusi haku tai yritä myöhemmin uudelleen.'
    },
    PIPE: { SOCIETY: ' | Bonduelle' },
    THEME_ALL: 'Kaikki',
    THEME_STARTER: 'Alkuruoka',
    THEME_SALAD: 'kaikki-salaatteihin',
    THEME_CAN: '',
    THEME_FROZEN: '',
    THEME_READY_MEALS: '',
    THEME_BIO: '',
    THEME_VEGETABLES: '',
    THEME_RECIPE: 'resepti-ainekset',
    THEME_PLATE: 'Pääruokia',
    THEME_ACC: 'lisävarusteet',
    THEME_SNACK: 'snackseja'
  },
  VEGETABLE: {
    DISCOVER_VEGETABLE: 'Lue lisää vihanneksistamme',
    OURS_RECIPES: 'Reseptimme',
    DISCOVER_ALSO: 'Katso myös:',
    SEE_ALL: 'Katso kaikki vihanneksemme',
    ON: 'kasviksia'
  },
  RECIPE: {
    'INGREDIENTS_LABEL ': 'Ainesosat {{value}} henkilölle',
    PEOPLES: '{{value}} henkilöä',
    DIFFICULTY: { EASY: 'Helppo', MEDIUM: 'Keskitaso', HARD: 'Vaikea' },
    COST: { CHEAP: 'Halpa', EXPENSIVE: 'Kallis' },
    TIMING: { MIN: 'min', HOURS: 'h' },
    STEP: 'Vaihe',
    DISCOVER_ALSO: 'Katso myös:',
    HOME_SEE_MORE: 'Katso lisää reseptejä',
    SHOWCASE: 'Ylös',
    HOME_BOOK: 'Reseptimme',
    MIN_MORE: 'min ja +',
    HOUR_MORE: 'tuntia ja +',
    FILTER_BY_CRITERIA: 'Suodata',
    SORT_BY: 'Lajitteluperuste',
    BEST_RATED: 'Parhaiksi arvostellut',
    MOST_CONSULTED: 'Suosituimmat',
    MEMBERS_RECIPES: 'Jäsenten reseptit',
    CRITERIA: 'Uutuuksia',
    DISCOVER: { RECIPES: 'Katso reseptimme' },
    DISCOVER_RECIPE: 'Katso resepti',
    INSTRUCTIONS: 'Valmistusohje',
    DEFAULT_TAG: 'Reseptisivu',
    MOST_RECENT: 'Uusimmat',
    RECIPE_VIDEOS: 'reseptivideot',
    PRODUCT_ASSOCIATE: 'Valmista tämä resepti:',
    BUDGET_LOW: 'Halpa',
    BUDGET_HIGH: 'Korkea',
    BUDGET_MIDDLE: 'Keskitaso',
    PRINT_RECIPE: 'tulosta resepti',
    LET_YOURSELF_SURPRISED: 'Tarvitsetko lisää inspiraatiota?',
    COOK_TIP_TITLE: '',
    ENJOY_MEAL: 'Hyvää ruokahalua!',
    FILTER: { LEGEND: 'Leikkisä' }
  },
  PREDIGGO: {
    DIFFICULTY: { EASY: 'Helppo', MEDIUM: 'Keskitaso', HARD: 'Vaikea' }
  },
  COOKIES: {
    TEXT: 'Jatkamalla käyntiäsi tällä sivustolla suostut siihen, että käytämme evästeitä teknisistä syistä liittyen tilastolliseen analyysiin ja verkkosivuston liikenteen hallintaan sekä muiden kotisivujen mainostajien tiedonjakoon, jotta voimme tarjota sinulle kiinnostavaa sisältöä.',
    CLOSE: 'Sulje',
    MORE: 'Lue lisää',
    AGREE_YOUTUBE: 'You must accept Youtube cookies to see this video',
    BUTTON_YOUTUBE: 'Accept cookies'
  },
  POPUP: { LAUNCH: 'POPUP-OUVERTURE-SITE' },
  FIELD_PLATE: { DISCOVER_VEGETABLE: 'Lue lisää vihanneksistamme' },
  SEARCH: {
    OUR_RECIPES: 'Reseptit',
    OUR_PRODUCTS: 'Tuotteet',
    OUR_ARTICLES: 'Artikkelit',
    RECIPES: 'Reseptit',
    PRODUCTS: 'Tuotteet',
    ARTICLES: 'Artikkelit',
    ALL: 'Näytä kaikki',
    SORT_BY: 'Lajitteluperuste',
    RESULTS: 'tulokset',
    RESULT: 'tulos',
    PRODUCT_OUT: 'tuotetta /',
    APPLY: 'Käytä',
    FILTER: 'Suodata',
    RECIPE_OUT: 'reseptiä /',
    NO_RESULT: 'Hups... Emme löydä etsimääsi',
    SEE_MORE: 'Tutustu tarkemmin',
    DISCOVER_RECIPES: 'Tutustu resepteihimme',
    SEE_MORE_OF: 'Katso lisää',
    DELETE: 'poista'
  },
  RECIPE_LIST: {
    OUR_RECIPES: 'Reseptit',
    RESULTS: 'Tulokset',
    SEE_MORE: 'Katso lisää reseptejä'
  },
  PRODUCT: {
    BUTTON: {
      ACTU: 'tuoteuutuudet',
      FOUND: 'Katso mistä löydät tämän tuotteen',
      DISCOVER: 'Tutustu koko valikoimaamme'
    },
    NUTRITIONAL_VALUE: 'ravintosisältö',
    CONDITIONING: 'pakkaus',
    PORTIONS: 'annokset',
    NET_WEIGHT: 'Nettopaino',
    DRY_WEIGHT: 'Kuivapaino',
    INGREDIENTS: 'Ainekset:',
    OUR_RECIPES: 'Katso reseptimme',
    FOR: 'per',
    PER_PORTION: 'ja annosta kohti',
    ENERGY: 'Energia',
    FAT: 'Rasva',
    SATURATE: 'Tyydyttyneet rasvat',
    GLUCIDES: 'Hiilihydraatit',
    FIBER: 'Ravintokuitu',
    PROTEINS: 'Proteiini',
    SALT: 'Suola',
    DISCOVER_ALSO: 'Katso myös:',
    MORE_INFORMATION: 'lisätietoja',
    TRACES: 'Jäämiä',
    CONTAINS: 'Saattaa sisältää',
    SLIDER: { SHEET: 'katso tuote', BUY: 'osta tämä tuote' },
    NUTRISCORE: 'information-nutriscore',
    TRIMAN: '',
    AGRICONFIANCE: '',
    BIO: 'information-øko',
    PESTICIDE_FREE: '',
    LEAF: '',
    LOCK: '',
    SEE_ALL: 'Katso kaikki tuotteet',
    LEGISLATION: 'voimassa olevan lainsäädännön mukaisesti.',
    PORTION: { SINGULAR: 'annos', PLURAL: 'annosta' },
    DISCLAIMER: '',
    COOKING: 'Valmistus',
    CONSERVATION: 'Säilytys'
  },
  FAQ: {
    ON: 'kysymyksiä aiheesta',
    ALL: 'Kaikki',
    SEE_MORE: 'katso lisää kysymyksiä',
    TITLE_1: 'Usein kysyttyjä kysymyksiä',
    TITLE_2: 'Onko sinulla kysyttävää tietystä tuotteesta?',
    LINK_LABEL: 'Kirjoita meille'
  },
  FORM: {
    GO_TO_STEP: 'Siirry vaiheeseen',
    STEP: 'Vaihe',
    RECEIVE_EMAIL_COPY: 'Vastaanota kopio sähköpostitse',
    SEND: 'Lähetä',
    GDPR: 'Lisätietoja tietojesi käsittelystä',
    ERROR: 'Tähdellä (*) merkityt kentät ovat pakollisia ja voimassa',
    SEND_ERROR: 'Tapahtui virhe. Yritä myöhemmin uudelleen.',
    NOTICE: 'Jotta voimme käsitellä tiedostoasi oikein, saatamme tapauksesta riippuen pyytää sinulta tietoja terveydestäsi. Ne eivät ole pakollisia eikä sinun tarvitse lähettää niitä.',
    SENDING: 'Lomaketta lähetetään...',
    LOADING: 'Luetaan...',
    PRODUCT_INFORMATION_TITLE: 'Tuotteeseen liittyvät kysymykset',
    PRODUCT_INFORMATION: {
      SEO_TITLE: 'Tuotteeseen liittyvät kysymykset | Bonduelle',
      SEO_DESC: 'Jos sinulla on kysyttävää tuotteistamme, ota yhteyttä asiakaspalveluumme.'
    },
    INFORMATIONS: 'Tietosi',
    MISTER: '',
    MISS: '',
    NAME: 'Sukunimi',
    OUR_NAME: 'Sukunimi',
    FIRSTNAME: 'Etunimi',
    OUR_FIRSTNAME: 'Etunimi',
    MAIL: 'Sähköpostiosoitteesi',
    MAIL_PLH: '',
    MAIL_CONFIRM: 'Vahvista sähköpostiosoitteesi',
    MAIL_ERROR: 'Anna kelvollinen sähköpostiosoite',
    MAIL_ERROR_CONFIRM: 'Vahvista sähköpostiosoitteesi',
    ADDRESS: 'Osoitteesi',
    STREET_PLH: '',
    STREET: 'Katuosoite',
    ADDRESS_COMPLEMENT: 'Osoite 2',
    ADDRESS_COMP_PLH: 'Osoite 3',
    ZIPCODE: 'Postinumero',
    CITY: 'Postitoimipaikka',
    CITY_PLH: 'Postitoimipaikka',
    PHONE: 'Puhelinnumero',
    PHONE_PLH: 'Puhelinnumero',
    COUNTRY: 'Maa',
    PRODUCT_INFORMATIONS: 'Tuotetiedot',
    PRODUCT_INFORMATION_DESC: 'Anna seuraavat tiedot, jotta voimme käsitellä viestisi:',
    PRODUCT: {
      DETAIL: {
        DESC: 'Yhdessä viestisi kanssa voit täyttää asianmukaiset kentät ja lisätä esimerkkinä kuvan. Kuvissa on oltava tuotteen viivakoodi, eränumero ja viimeinen käyttöpäivä.'
      },
      NOTE: {
        TITLE: 'Tuotereklamaatio',
        SEO_TITLE: 'Lähetä tuotereklamaatio | Bonduelle',
        SEO_DESC: 'Onko sinulla kysyttävää tuotteesta? Ota meihin yhteyttä sähköpostilla.'
      }
    },
    PRODUCT_RANGE: 'Tuotevalikoima',
    PRODUCT_NAME: 'Tuotteen nimi',
    PRODUCT_NAME_PLH: 'Kirjoita sen Bondulle-tuotteen nimi, jota viestisi koskee.',
    PRODUCT_INFORMATIONS_DETAIL: 'Yksityiskohtaiset tiedot',
    PRODUCT_INFORMATIONS_LABEL: 'Tuotteen viivakoodi',
    PRODUCT_INFORMATIONS_PLH: 'Viivakoodissa on 13 numeroa - ne löytyvät pakkauksesta.',
    PRODUCT_LOT: 'Tuotteen eränumero ja viimeinen käyttöpäivä',
    PRODUCT_LOT_INFO: 'Eränumero alkaa kirjaimella L < br > ja sen jälkeen numeromäärällä. < br > Löydät sen pakkauksen takaa < br > tai purkin pohjasta. < br > < br > Esimerkiksi: L72450492107: 13.',
    PHOTO: 'Lisää kuva',
    EXPIRED_DATE: 'Viimeinen käyttöpäivä',
    EXPIRED_DATE_ERROR: 'Syötä päivämäärä muodossa pp/kk/vvvv',
    EXPIRED_DATE_DESC: 'Viimeinen käyttöpäivämäärä on merkitty pakkaukseen tai purkin pohjaan.',
    PHOTO_MORE: 'Muut kuvat',
    MESSAGE: 'Viestisi *',
    OUR_MESSAGE: 'Kirjoita viestisi',
    SALES_OPERATIONS: { TITLE: '', SEO_TITLE: '', SEO_DESC: '' },
    PRESS_SERVICE: '',
    FOOD_SERVICE: 'Ota yhteyttä Food Service -osastoomme',
    BUSINESS_INFORMATION: '',
    MODAL_INFORMATION: '',
    JOB_OFFER: { TITLE: '', SEO_TITLE: '', SEO_DESC: '' },
    WEB_SUGGESTION: {
      TITLE: 'Haluatko ehdottaa parannuksia kotisivullemme?',
      SEO_TITLE: 'Ehdotuksia verkkosivustolle | Bonduelle',
      SEO_DESC: ''
    },
    ANOTHER: {
      TITLE: 'Kysy jotain muuta',
      SEO_TITLE: 'Ota yhteyttä, jos sinulla on muita kysymyksiä tai kommentteja | Bonduelle',
      SEO_DESC: ''
    },
    AMBASSADOR: '',
    CATEGORY: {
      SALAD: 'salaatit',
      FROZEN: 'pakasteet',
      CAN: 'säilykkeet',
      TAKEAWAY: 'takeaway',
      VEGETABLE: ''
    }
  },
  CONTACT: {
    TITLE: 'Miten voimme auttaa?',
    SPECIAL_INFO: '',
    TAKE_CARE: '',
    BY_MAIL: 'Postitse',
    BY_PHONE: 'Puhelimitse',
    BY_SOURDLINE: '',
    MAIL_TEXT: 'BONDUELLE NORDIC A/S\nLyskær 3CD\nDK - 2730 Herlev',
    PHONE_TEXT: ' +45 70 70 29 75',
    SOURDLINE_TEXT: '',
    BY_DISTRIBUTOR: '',
    BY_DISTRIBUTOR_TEXT: '',
    SUBTITLE: 'Voit myös ottaa meihin yhteyttä sähköpostitse:',
    AGREEMENT: '',
    READ_MORE: '',
    READ_MORE_LABEL: '',
    SATISFACTION_TEXT: 'Käsittelemme antamiasi tietoja rekisterinpitäjänä, jotta voimme ottaa\n' +
      'sinuun yhteyttä toimittaaksemme sinulle tuotteesta pyydetyt tiedot ja\n' +
      'lähettääksemme sinulle halutessasi tyytyväisyyskyselyn. Pyyntösi\n' +
      'käsittelyn oikeusperusta on suostumus, joka johtuu mahdollisista\n' +
      'terveyteen liittyvistä tiedoista, joita saatat antaa pyynnössäsi. Näitä tietoja\n' +
      'säilytetään niin kauan kuin on tarpeen pyyntösi käsittelemiseksi ja\n' +
      'enintään 5 vuotta todisteeksi siitä, että pyyntösi on käsitelty, ja laillisten\n' +
      'velvoitteidemme noudattamiseksi. Tyytyväisyyskyselyn lähettämisen\n' +
      'oikeusperuste on myös suostumuksesi. Näitä tietoja säilytetään\n' +
      'tyytyväisyyskyselyn lähettämiseen tarvittavan ajan ja enintään vuoden. Jos\n' +
      'haluat lisätietoja henkilötietojesi käsittelystä, tutustu\n' +
      '<a href="https://www.bonduelle.fi/juridinen-ilmoitus" target="_blank" class="underline">tietosuojakäytäntöömme</a>.',
    SATISFACTION_CHECKBOX: 'Valitsemalla tämän ruudun hyväksyt tyytyväisyyskyselyn (joka koostuu\n' +
      'yhdestä kysymyksestä), kun pyyntösi on käsitelty. Se on valinnaista.',
    CHECKBOX_TEXT: 'Valitsemalla tämän ruudun hyväksyt, että Bonduelle voi käsitellä\n' +
      'viestissäsi antamiasi henkilökohtaisia terveystietoja ainoastaan\n' +
      'vastatakseen pyyntöösi. <strong>Tämä on pakollista pyyntösi käsittelemiseksi</strong>.',
    PHONE: 'Puh.nro +45 7070 2975\n',
    CALL_US: 'Soita meille',
    TO_WRITE: 'Kirjoita meille',
    PHONE_NUMBER: '4570702975',
    SUCCESS_MESSAGE: 'Kiitos yhteydenotostasi. Viestiisi vastataan 10 arkipäivän kuluessa.',
    PHOTOERROR: { MESSAGE: 'valokuvan virhe' }
  },
  GLOBAL: { READ_MORE: '' },
  FLOWBOX: { GENERIC_KEY: '', PRODUCT_KEY: '', RECIPE_KEY: '', HOME_KEY: '' },
  TOKEN: {
    FACEBOOK: '',
    PAGE_FACEBOOK_ID: '',
    INSTAGRAM: '',
    INSTA_ID: '17841407279489116',
    YOUTUBE: '',
    TWITTER: '',
    MAGAZINE: ''
  },
  COOKIE: {
    BANNER: {
      MANDATORY: 'Välttämättömät',
      ANALYTICS: 'Analyysi',
      MARKETING: 'Tarvittavat',
      SOCIAL_MEDIA: 'Markkinointi',
      ACCEPT: '\nHyväksy kaikki',
      REJECT: 'Kiellä kaikki',
      SET_UP: 'Määritä asetukset',
      MANDATORY_DETAILS: 'Vaaditut evästeet ovat välttämättömiä sivuston toiminnan kannalta, esimerkiksi kirjautumistietojen tallentamiseksi ja turvallisen yhteyden tarjoamiseksi.\n' +
        'Bonduelle.fi-sivuston käyttämiseen vaaditut evästeet: \n' +
        '<ul>\n' +
        '<li>Istuntoevästeet: PHP-pohjaisten sovellusten luomat evästeet. Tämä on yleinen tunniste, jota käytetään käyttäjäistunnon muuttujien hallintaan. Se on tavallisesti satunnaisesti luotu numero, ja sen käyttö voi erota eri sivustojen välillä, mutta hyvä esimerkki sen käytöstä on käyttäjän kirjautumistilan säilyttäminen sivujen välillä.</li>\n' +
        '</ul>',
      ANALYTICS_DETAILS: 'Toiminnalliset evästeet auttavat meitä optimoimaan sivustoa, esimerkiksi keräämään tilastotietoja, tallentamaan käyttäjän valinnat sosiaalisesta kanssakäymisestä tai mittaamaan sovelluksemme suorituskykyä ja vakautta.\n' +
        '<br>\n' +
        'Bonduelle.fi-sivuston käyttämät analyyttiset evästeet ovat: \n' +
        '<ul>\n' +
        '<li>DoubleClick: Tämän evästeen asettaa DoubleClick (Googlen omistama) luodakseen profiilin verkkosivuston käyttäjien kiinnostuksen kohteista ja näyttääkseen merkityksellisiä mainoksia muilla sivustoilla. Yrityksen pääasiallinen toiminta koostuu Googlen mainosten reaaliaikaisesta välittämisestä.</li>\n' +
        '<li>Google Analytics: näiden evästeiden avulla seurataan eniten käytettyjä sivuja ja sivustoliikennettä. </li>\n' +
        '<li>UTMTracking: tämä eväste mahdollistaa UTM-muuttujien hallinnan erilaisten verkkomainoskampanjoiden optimaalista seurantaa varten </li>.</ul>',
      MARKETING_DETAILS: 'Markkinointievästeiden avulla voimme seurata resepti- ja tuotemieltymyksiä sekä tarjota käyttäjän mieltymyksiä parhaiten vastaavia tuotteita.\n' +
        '<br>\n' +
        'Bonduelle.fi-sivuston käyttämät markkinointievästeet ovat: \n' +
        '<ul>\n' +
        '<li>Sosiaalinen media: näiden evästeiden avulla Bonduellen "SocialWall" voidaan näyttää kotisivulla.  SocialWall-seinällä näkyvät kaikki uusimmat viestit Bonduelle-tiimien käyttämistä suurista sosiaalisen median palveluista.</li></ul> "',
      V2: 'ok'
    },
    MODAL: {
      TITLE: '\nEvästeasetukset',
      TEXT: 'Bonduelle Group on sitoutunut suojaamaan verkkosivustojensa käyttäjien ja erityisesti Bonduelle.fi-sivuston käyttäjien henkilötietoja. <br>Sivustolla käytetään evästeitä tuotetietojen tarkastelun ja uusiin gourmet-resepteihin tutustumisen kaltaisten eri palveluiden tarjoamiseksi, uutiskirjeiden kaltaisten tiettyjen palveluiden tilaamisen mahdollistamiseksi sekä myös käytön optimoimiseksi ja profiilin mukaisesti yksilöllistämiseksi. Bonduelle-konserni on täysin sitoutunut varmistamaan tällaisten henkilötietojen korkean suojaustason ja noudattamaan kaikkia sovellettavia tietosuojasääntöjä käsitellessään henkilötietoja verkkosivustonsa käytön yhteydessä.\n' +
        'Voit ottaa evästeet käyttöön tai poistaa ne käytöstä milloin tahansa.',
      CONFIG: '\nAsetukset',
      SAVE: '\nTallenna'
    }
  },
  LINK: { JOB_OFFERS: '' },
  CLIC2BUY: { INFOS: '' }
};
