import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UtilsModule } from '@app/shared/utils/utils.module';
import { SfSectionImageComponent } from './components/section-image.component';
import { SfImageModule } from '../image/image.module';
import { SfLinkModule } from '../link/link.module';

@NgModule({
    imports: [
        CommonModule,
        SfImageModule,
        SfLinkModule,
        UtilsModule,
    ],
  declarations: [SfSectionImageComponent],
  exports: [SfSectionImageComponent]
})
export class SfSectionImageModule {}
