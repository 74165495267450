import { Component, Input } from '@angular/core';

@Component({
    selector: 'responsive-text',
    templateUrl: './responsive-text.component.html',
    styleUrls: ['./responsive-text.component.sass'],
    standalone: false
})
export class ResponsiveTextComponent {

  @Input() section;

}
