import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { SfItem } from '../../item';

@Component({
    selector: 'sf-search-grid',
    templateUrl: './search-grid.component.html',
    styleUrls: ['./search-grid.component.sass'],
    standalone: false
})
export class SfSearchGridComponent implements OnInit {
  /**
   * Template use in grid
   *
   * @memberof SfGridComponent
   */
  @Input() template: TemplateRef<any>;
  /**
   * Items display on grid
   *
   * @memberof SfGridComponent
   */
  @Input() items: SfItem[];
  /**
   * Number of columns
   *
   * @memberof SfGridComponent
   */
  @Input() cols: number;
  /**
   * Limit items display
   *
   * @memberof SfGridComponent
   */
  @Input() limit: number;
  /**
   * Start items display in array
   *
   * @memberof SfGridComponent
   */
  @Input() start: number;

  /**
   * Link to navigate
   *
   * @memberof SfGridComponent
   */
  @Input() mainUrl: string;

   /**
   * Link to navigate
   *
   * @memberof SfGridComponent
   */
  @Input() urlKeywords: string;

     /**
   * Link to navigate
   *
   * @memberof SfGridComponent
   */
  @Input() childUrl: string;

  constructor() {
    this.cols = 2;
    this.limit = 6;
    this.start = 0;
  }

  ngOnInit() {
  }

  colsUse() {
    let className = '';
    switch (this.cols) {
      case 1:
        className = 'sf-search-grid-100';
        break;
      case 2:
        className = 'sf-search-grid-50';
        break;
      case 3:
        className = 'sf-search-grid-33';
        break;
      case 4:
        className = 'sf-search-grid-25';
        break;
      default:
        className = 'sf-search-grid-33';
        break;
    }
    return className;
  }
}
