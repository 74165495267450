import { SfModel } from '@app/sf-lib/public_api';
import { SomFaqArticlePage, SomFaqTag } from './faq-article-page.model';

export class SomFaqList extends SfModel {

  tags: SomFaqTag[];

  total: number;

  items: SomFaqArticlePage[];

  constructor() {
      super();
    this.total = 0;
    this.items = [];
    this.tags = [];
  }
}
