import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.sass'],
    standalone: false
})
export class ButtonComponent {
  @Input() label: string = 'Button';
  @Input() link: string;
  @Input() disabled: boolean = false;
  @Input() type: 'button' | 'submit' | 'reset' = 'button'; 
  @Input() buttonClass: 'primary' | 'secondary' | 'danger' = 'primary';
  @Input() size: 'sm' | 'md' | 'lg' = 'lg'
  @Input('class') additionalClasses = '';
  
  @Output() buttonClick = new EventEmitter<void>();

  get classes(): string {
    return `${this.buttonClass} ${this.size} ${this.additionalClasses}`;
  }

  onClick() {
    if (!this.disabled) {
      this.buttonClick.emit();
    }
  }
}
