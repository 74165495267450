/* tslint:disable */export const smbi18n = {
  '404': {
    TITLE: '404/TITLE',
    PAGENOTFOUND: '404/PAGENOTFOUND',
    BUTTON: '404/BUTTON',
    RECIPES: '404/RECIPES'
  },
  HOME: {
    SHOWCASE: 'HOME/SHOWCASE',
    CATCH_PHRASE: 'HOME/CATCH_PHRASE',
    SHOWCASE_SEE_ALL: 'HOME/SHOWCASE_SEE_ALL',
    MAGAZINE: { TITLE: 'HOME/MAGAZINE/TITLE', BUTTON: 'HOME/MAGAZINE/BUTTON' },
    OUR_VEGETABLES: 'HOME/OUR_VEGETABLES',
    SOCIAL_WALL: 'HOME/SOCIAL_WALL',
    FAQ_TITLE: 'HOME/FAQ_TITLE',
    FAQ_SEE_MORE: 'HOME/FAQ_SEE_MORE',
    FLOWBOX: 'HOME/FLOWBOX'
  },
  SELECT: { DEFAULT_VALUE: 'SELECT/DEFAULT_VALUE' },
  MENU: {
    CONTACT_US: 'MENU/CONTACT_US',
    MY_ACCOUNT: 'MENU/MY_ACCOUNT',
    GOOD_DEALS: 'MENU/GOOD_DEALS',
    NEWSLETTER: 'MENU/NEWSLETTER'
  },
  FOOTER: {
    LINK: {
      BONDUELLE: 'FOOTER/LINK/BONDUELLE',
      CREDITS: 'FOOTER/LINK/CREDITS',
      LEGAL_NOTICE: 'FOOTER/LINK/LEGAL_NOTICE',
      TERMS_OF_SALES: 'FOOTER/LINK/TERMS_OF_SALES',
      COOKIES: 'FOOTER/LINK/COOKIES',
      SITEMAP: 'FOOTER/LINK/SITEMAP',
      PRIVACY_POLICY: 'FOOTER/LINK/PRIVACY_POLICY',
      ALL_RIGHTS_RESERVED: 'FOOTER/LINK/ALL_RIGHTS_RESERVED'
    },
    HISTORY: { TITLE: 'FOOTER/HISTORY/TITLE', TEXT: 'FOOTER/HISTORY/TEXT' },
    NEWSLETTER: {
      TITLE: 'FOOTER/NEWSLETTER/TITLE',
      TEXT: 'FOOTER/NEWSLETTER/TEXT',
      PLACEHOLDER: 'FOOTER/NEWSLETTER/PLACEHOLDER',
      GDPR_LINK: 'FOOTER/NEWSLETTER/GDPR_LINK'
    }
  },
  PRODUCTS: 'PRODUCTS',
  MAGAZINE: {
    HOME: 'MAGAZINE/HOME',
    CATCH_PHRASE: 'MAGAZINE/CATCH_PHRASE',
    MENU: { BONDUELLE_LINK: 'MAGAZINE/MENU/BONDUELLE_LINK' },
    SHOWCASE: 'MAGAZINE/SHOWCASE',
    PREVIOUS_ARTICLES: 'MAGAZINE/PREVIOUS_ARTICLES',
    PREVIOUS_ARTICLES_SEE_ALL: 'MAGAZINE/PREVIOUS_ARTICLES_SEE_ALL',
    PREVIOUS_EDITIONS: 'MAGAZINE/PREVIOUS_EDITIONS',
    PREVIOUS_EDITIONS_SEE_ALL: 'MAGAZINE/PREVIOUS_EDITIONS_SEE_ALL',
    PREVIOUS_ARTICLES_BY_CATEGORY: 'MAGAZINE/PREVIOUS_ARTICLES_BY_CATEGORY',
    RUBRIC: {
      SEE_ALL: 'MAGAZINE/RUBRIC/SEE_ALL',
      SEE_ALL_ARTICLES: 'MAGAZINE/RUBRIC/SEE_ALL_ARTICLES',
      ON: 'MAGAZINE/RUBRIC/ON'
    },
    RUBRIC_COOK: { ON: 'MAGAZINE/RUBRIC_COOK/ON' },
    ARTICLE: {
      JOIN_US: 'MAGAZINE/ARTICLE/JOIN_US',
      READ_ALSO: 'MAGAZINE/ARTICLE/READ_ALSO'
    },
    RECIPE: {
      INGREDIENTS: 'MAGAZINE/RECIPE/INGREDIENTS',
      INGREDIENTS_LABEL_DESC: 'MAGAZINE/RECIPE/INGREDIENTS_LABEL_DESC',
      INGREDIENTS_LABEL_ONE_DESC: 'MAGAZINE/RECIPE/INGREDIENTS_LABEL_ONE_DESC',
      INGREDIENTS_LABEL: 'MAGAZINE/RECIPE/INGREDIENTS_LABEL',
      INGREDIENTS_LABEL_ONE: 'MAGAZINE/RECIPE/INGREDIENTS_LABEL_ONE',
      PEOPLES: 'MAGAZINE/RECIPE/PEOPLES',
      PEOPLE: 'MAGAZINE/RECIPE/PEOPLE',
      DIFFICULTY: {
        EASY: 'MAGAZINE/RECIPE/DIFFICULTY/EASY',
        MEDIUM: 'MAGAZINE/RECIPE/DIFFICULTY/MEDIUM',
        HARD: 'MAGAZINE/RECIPE/DIFFICULTY/HARD'
      },
      COST: {
        CHEAP: 'MAGAZINE/RECIPE/COST/CHEAP',
        EXPENSIVE: 'MAGAZINE/RECIPE/COST/EXPENSIVE'
      },
      TIMING: {
        MIN: 'MAGAZINE/RECIPE/TIMING/MIN',
        HOURS: 'MAGAZINE/RECIPE/TIMING/HOURS'
      },
      STEP: 'MAGAZINE/RECIPE/STEP',
      DISCOVER_ALSO: 'MAGAZINE/RECIPE/DISCOVER_ALSO'
    }
  },
  ARTICLE: {
    DISCOVER_ALSO: 'ARTICLE/DISCOVER_ALSO',
    INGREDIENTS: 'ARTICLE/INGREDIENTS'
  },
  SF: {
    SLIDE: { DISCOVER: 'SF/SLIDE/DISCOVER' },
    SEARCH: { PLACEHOLDER: 'SF/SEARCH/PLACEHOLDER', ALL: 'SF/SEARCH/ALL' },
    NEWSLETTER: {
      BTN_LABEL: 'SF/NEWSLETTER/BTN_LABEL',
      PLACEHOLDER: 'SF/NEWSLETTER/PLACEHOLDER',
      ERROR_MESSAGE: 'SF/NEWSLETTER/ERROR_MESSAGE',
      ALREADY_SUBSCRIBED: 'SF/NEWSLETTER/ALREADY_SUBSCRIBED',
      GDPR: {
        TXT: 'SF/NEWSLETTER/GDPR/TXT',
        LINK: 'SF/NEWSLETTER/GDPR/LINK'
      }
    },
    MONTHLY_FOLDER: { TITLE: 'SF/MONTHLY_FOLDER/TITLE' },
    MINI_NEWSLETTER: {
      PLACEHOLDER: 'SF/MINI_NEWSLETTER/PLACEHOLDER',
      GDPR: {
        TXT: 'SF/MINI_NEWSLETTER/GDPR/TXT',
        LINK: 'SF/MINI_NEWSLETTER/GDPR/LINK'
      }
    },
    SOCIALITEM: {
      SHARE: 'SF/SOCIALITEM/SHARE',
      COMMENT: 'SF/SOCIALITEM/COMMENT',
      BONDUELLE: 'SF/SOCIALITEM/BONDUELLE'
    },
    SEARCHRECIPE: { DISCOVER_ALL: 'SF/SEARCHRECIPE/DISCOVER_ALL' },
    FORM: {
      ERROR: 'SF/FORM/ERROR',
      PHOTO: {
        ADD: 'SF/FORM/PHOTO/ADD',
        DELETE: 'SF/FORM/PHOTO/DELETE',
        SEE: 'SF/FORM/PHOTO/SEE'
      }
    }
  },
  CORPORATE: {
    COMPANY_STORY: 'CORPORATE/COMPANY_STORY',
    CSR_COMMITMENT: 'CORPORATE/CSR_COMMITMENT',
    VEGETABLE_INNOVATIONS: 'CORPORATE/VEGETABLE_INNOVATIONS',
    NEWS: 'CORPORATE/NEWS',
    SEE_MORE: 'CORPORATE/SEE_MORE',
    ON: 'CORPORATE/ON'
  },
  NEWSLETTER: { TITLE: 'NEWSLETTER/TITLE', SUBTITLE: 'NEWSLETTER/SUBTITLE' },
  ROUTES: {
    products: 'ROUTES/products',
    news: 'ROUTES/news',
    'since-1853': 'ROUTES/since-1853',
    'field-plate': 'ROUTES/field-plate',
    'company-story': 'ROUTES/company-story',
    'csr-commitments': 'ROUTES/csr-commitments',
    'vegetal-innovations': 'ROUTES/vegetal-innovations',
    magazine: 'ROUTES/magazine',
    all: 'ROUTES/all',
    article: 'ROUTES/article',
    cook: 'ROUTES/cook',
    cultivate: 'ROUTES/cultivate',
    explore: 'ROUTES/explore',
    recipe: 'ROUTES/recipe',
    recipes: 'ROUTES/recipes',
    'recipes-home': 'ROUTES/recipes-home',
    r: 'ROUTES/r',
    credits: 'ROUTES/credits',
    'legal-notice': 'ROUTES/legal-notice',
    'terms-of-sales': 'ROUTES/terms-of-sales',
    cookies: 'ROUTES/cookies',
    sitemap: 'ROUTES/sitemap',
    'privacy-policy': 'ROUTES/privacy-policy',
    'all-rights-reserved': 'ROUTES/all-rights-reserved',
    search: 'ROUTES/search',
    'articles-search': 'ROUTES/articles-search',
    vegetables: 'ROUTES/vegetables',
    p: 'ROUTES/p',
    contact: 'ROUTES/contact',
    'info-product': 'ROUTES/info-product',
    'note-product': 'ROUTES/note-product',
    suggestion: 'ROUTES/suggestion',
    'other-request': 'ROUTES/other-request',
    faq: 'ROUTES/faq',
    'all-themes': 'ROUTES/all-themes',
    'products-search': 'ROUTES/products-search',
    recipesearch: 'ROUTES/recipesearch',
    'product-news': 'ROUTES/product-news',
    'good-deals': 'ROUTES/good-deals',
    'not-found': 'ROUTES/not-found',
    'menu-page': 'ROUTES/menu-page',
    'menu-one': 'ROUTES/menu-one',
    'menu-two': 'ROUTES/menu-two',
    'menu-three': 'ROUTES/menu-three',
    'menu-four': 'ROUTES/menu-four',
    'menu-external': 'ROUTES/menu-external',
    'theme-pasta-rice': 'ROUTES/theme-pasta-rice',
    'theme-bowls': 'ROUTES/theme-bowls',
    'theme-soup': 'ROUTES/theme-soup',
    'theme-snack': 'ROUTES/theme-snack',
    'theme-sauce': 'ROUTES/theme-sauce',
    'theme-unique-plate': 'ROUTES/theme-unique-plate',
    'theme-over': 'ROUTES/theme-over',
    'theme-legumin': 'ROUTES/theme-legumin',
    'theme-ingredients-recipes': 'ROUTES/theme-ingredients-recipes',
    'theme-cook-plate': 'ROUTES/theme-cook-plate'
  },
  BREAD: {
    products: 'BREAD/products',
    news: 'BREAD/news',
    'since-1853': 'BREAD/since-1853',
    'field-plate': 'BREAD/field-plate',
    'company-story': 'BREAD/company-story',
    'csr-commitments': 'BREAD/csr-commitments',
    'vegetal-innovations': 'BREAD/vegetal-innovations',
    magazine: 'BREAD/magazine',
    all: 'BREAD/all',
    article: 'BREAD/article',
    cook: 'BREAD/cook',
    cultivate: 'BREAD/cultivate',
    explore: 'BREAD/explore',
    recipe: 'BREAD/recipe',
    recipes: 'BREAD/recipes',
    'recipes-home': 'BREAD/recipes-home',
    'articles-search': 'BREAD/articles-search',
    search: 'BREAD/search',
    vegetables: 'BREAD/vegetables',
    contact: 'BREAD/contact',
    'info-product': 'BREAD/info-product',
    'note-product': 'BREAD/note-product',
    suggestion: 'BREAD/suggestion',
    'other-request': 'BREAD/other-request',
    faq: 'BREAD/faq',
    'all-themes': 'BREAD/all-themes',
    'products-search': 'BREAD/products-search',
    recipesearch: 'BREAD/recipesearch',
    'product-news': 'BREAD/product-news',
    'good-deals': 'BREAD/good-deals',
    'not-found': 'BREAD/not-found',
    'menu-page': 'BREAD/menu-page',
    'menu-one': 'BREAD/menu-one',
    'menu-two': 'BREAD/menu-two',
    'menu-three': 'BREAD/menu-three',
    'menu-four': 'BREAD/menu-four'
  },
  MENU_PAGE: {
    TITLE1: 'MENU_PAGE/TITLE1',
    TITLE2: 'MENU_PAGE/TITLE2',
    TITLE3: 'MENU_PAGE/TITLE3',
    TITLE4: 'MENU_PAGE/TITLE4',
    EXTERNAL: 'MENU_PAGE/EXTERNAL'
  },
  TECHNICAL: { MENU_PAGE: 'TECHNICAL/MENU_PAGE' },
  SEO: {
    TITLE: {
      MENU_ONE: 'SEO/TITLE/MENU_ONE',
      MENU_TWO: 'SEO/TITLE/MENU_TWO',
      MENU_THREE: 'SEO/TITLE/MENU_THREE',
      MENU_FOUR: 'SEO/TITLE/MENU_FOUR',
      HOME: 'SEO/TITLE/HOME',
      SEARCH: {
        MAIN: 'SEO/TITLE/SEARCH/MAIN',
        RECIPES: 'SEO/TITLE/SEARCH/RECIPES',
        VEGETABLES: 'SEO/TITLE/SEARCH/VEGETABLES',
        ARTICLES: 'SEO/TITLE/SEARCH/ARTICLES'
      },
      CONTACT: 'SEO/TITLE/CONTACT',
      RECIPES: 'SEO/TITLE/RECIPES',
      RECIPE_HOME: 'SEO/TITLE/RECIPE_HOME',
      RECIPE: 'SEO/TITLE/RECIPE',
      PRODUCTS: 'SEO/TITLE/PRODUCTS',
      NEWS: 'SEO/TITLE/NEWS',
      CORPORATE: {
        NEWS: 'SEO/TITLE/CORPORATE/NEWS',
        COMPANY_STORY: 'SEO/TITLE/CORPORATE/COMPANY_STORY',
        CSR_COMMITMENTS: 'SEO/TITLE/CORPORATE/CSR_COMMITMENTS',
        VEGETAL_INNOVATIONS: 'SEO/TITLE/CORPORATE/VEGETAL_INNOVATIONS',
        FIELD_PLATE: 'SEO/TITLE/CORPORATE/FIELD_PLATE',
        ARTICLE: 'SEO/TITLE/CORPORATE/ARTICLE',
        VEGETABLE: 'SEO/TITLE/CORPORATE/VEGETABLE'
      },
      PRODUCT: 'SEO/TITLE/PRODUCT',
      MAGAZINE: {
        NEWS: 'SEO/TITLE/MAGAZINE/NEWS',
        ARTICLE: 'SEO/TITLE/MAGAZINE/ARTICLE',
        COOK: 'SEO/TITLE/MAGAZINE/COOK',
        GROW: 'SEO/TITLE/MAGAZINE/GROW',
        EXPLORE: 'SEO/TITLE/MAGAZINE/EXPLORE',
        RECIPE: 'SEO/TITLE/MAGAZINE/RECIPE'
      },
      FAQ: 'SEO/TITLE/FAQ',
      NOTFOUND: 'SEO/TITLE/NOTFOUND',
      RECIPES_ALL: 'SEO/TITLE/RECIPES_ALL',
      RECIPETYPE: 'SEO/TITLE/RECIPETYPE',
      RECIPESEASON: 'SEO/TITLE/RECIPESEASON',
      RECIPEPREPARATION: 'SEO/TITLE/RECIPEPREPARATION',
      RECIPEDIFFICULTY: 'SEO/TITLE/RECIPEDIFFICULTY',
      RECIPEVEGETABLE: 'SEO/TITLE/RECIPEVEGETABLE',
      RECIPECOST: 'SEO/TITLE/RECIPECOST',
      RECIPEDISH: 'SEO/TITLE/RECIPEDISH',
      RECIPEDESC: 'SEO/TITLE/RECIPEDESC',
      '2RECIPEFACETS': 'SEO/TITLE/2RECIPEFACETS',
      '4RECIPEFACETS': 'SEO/TITLE/4RECIPEFACETS',
      PRODUCTSEASON: 'SEO/TITLE/PRODUCTSEASON',
      PRODUCTTECHNO: 'SEO/TITLE/PRODUCTTECHNO',
      PRODUCTVEGETABLE: 'SEO/TITLE/PRODUCTVEGETABLE',
      PRODUCTRANGE: 'SEO/TITLE/PRODUCTRANGE',
      THEME_ALL: 'SEO/TITLE/THEME_ALL',
      THEME_STARTER: 'SEO/TITLE/THEME_STARTER',
      THEME_SALAD: 'SEO/TITLE/THEME_SALAD',
      THEME_CAN: 'SEO/TITLE/THEME_CAN',
      THEME_FROZEN: 'SEO/TITLE/THEME_FROZEN',
      THEME_READY_MEALS: 'SEO/TITLE/THEME_READY_MEALS',
      THEME_BIO: 'SEO/TITLE/THEME_BIO',
      THEME_VEGETABLES: 'SEO/TITLE/THEME_VEGETABLES',
      THEME_RECIPE: 'SEO/TITLE/THEME_RECIPE',
      THEME_PLATE: 'SEO/TITLE/THEME_PLATE',
      THEME_ACC: 'SEO/TITLE/THEME_ACC',
      THEME_SNACK: 'SEO/TITLE/THEME_SNACK',
      RECIPE_THEME1: 'SEO/TITLE/RECIPE_THEME1',
      '2PRODUCTSFACETS': 'SEO/TITLE/2PRODUCTSFACETS'
    },
    DESCRIPTION: {
      MENU_ONE: 'SEO/DESCRIPTION/MENU_ONE',
      MENU_TWO: 'SEO/DESCRIPTION/MENU_TWO',
      MENU_THREE: 'SEO/DESCRIPTION/MENU_THREE',
      MENU_FOUR: 'SEO/DESCRIPTION/MENU_FOUR',
      HOME: 'SEO/DESCRIPTION/HOME',
      SEARCH: {
        MAIN: 'SEO/DESCRIPTION/SEARCH/MAIN',
        RECIPES: 'SEO/DESCRIPTION/SEARCH/RECIPES',
        VEGETABLES: 'SEO/DESCRIPTION/SEARCH/VEGETABLES',
        ARTICLES: 'SEO/DESCRIPTION/SEARCH/ARTICLES'
      },
      CONTACT: 'SEO/DESCRIPTION/CONTACT',
      RECIPES: 'SEO/DESCRIPTION/RECIPES',
      RECIPE_HOME: 'SEO/DESCRIPTION/RECIPE_HOME',
      RECIPE: 'SEO/DESCRIPTION/RECIPE',
      PRODUCTS: 'SEO/DESCRIPTION/PRODUCTS',
      NEWS: 'SEO/DESCRIPTION/NEWS',
      CORPORATE: {
        NEWS: 'SEO/DESCRIPTION/CORPORATE/NEWS',
        COMPANY_STORY: 'SEO/DESCRIPTION/CORPORATE/COMPANY_STORY',
        CSR_COMMITMENTS: 'SEO/DESCRIPTION/CORPORATE/CSR_COMMITMENTS',
        VEGETAL_INNOVATIONS: 'SEO/DESCRIPTION/CORPORATE/VEGETAL_INNOVATIONS',
        VEGETABLE: 'SEO/DESCRIPTION/CORPORATE/VEGETABLE',
        FIELD_PLATE: 'SEO/DESCRIPTION/CORPORATE/FIELD_PLATE',
        ARTICLE: 'SEO/DESCRIPTION/CORPORATE/ARTICLE'
      },
      PRODUCT: 'SEO/DESCRIPTION/PRODUCT',
      MAGAZINE: {
        NEWS: 'SEO/DESCRIPTION/MAGAZINE/NEWS',
        ARTICLE: 'SEO/DESCRIPTION/MAGAZINE/ARTICLE',
        COOK: 'SEO/DESCRIPTION/MAGAZINE/COOK',
        GROW: 'SEO/DESCRIPTION/MAGAZINE/GROW',
        EXPLORE: 'SEO/DESCRIPTION/MAGAZINE/EXPLORE',
        RECIPE: 'SEO/DESCRIPTION/MAGAZINE/RECIPE'
      },
      FAQ: 'SEO/DESCRIPTION/FAQ',
      PAGE: 'SEO/DESCRIPTION/PAGE',
      RECIPETYPE: 'SEO/DESCRIPTION/RECIPETYPE',
      RECIPESEASON: 'SEO/DESCRIPTION/RECIPESEASON',
      RECIPEPREPARATION: 'SEO/DESCRIPTION/RECIPEPREPARATION',
      RECIPEDIFFICULTY: 'SEO/DESCRIPTION/RECIPEDIFFICULTY',
      RECIPEVEGETABLE: 'SEO/DESCRIPTION/RECIPEVEGETABLE',
      RECIPECOST: 'SEO/DESCRIPTION/RECIPECOST',
      RECIPEDISH: 'SEO/DESCRIPTION/RECIPEDISH',
      RECIPEDESC: 'SEO/DESCRIPTION/RECIPEDESC',
      '2RECIPEFACETS': 'SEO/DESCRIPTION/2RECIPEFACETS',
      '4RECIPEFACETS': 'SEO/DESCRIPTION/4RECIPEFACETS',
      PRODUCTSEASON: 'SEO/DESCRIPTION/PRODUCTSEASON',
      PRODUCTTECHNO: 'SEO/DESCRIPTION/PRODUCTTECHNO',
      PRODUCTVEGETABLE: 'SEO/DESCRIPTION/PRODUCTVEGETABLE',
      PRODUCTRANGE: 'SEO/DESCRIPTION/PRODUCTRANGE',
      THEME_ALL: 'SEO/DESCRIPTION/THEME_ALL',
      THEME_STARTER: 'SEO/DESCRIPTION/THEME_STARTER',
      THEME_SALAD: 'SEO/DESCRIPTION/THEME_SALAD',
      THEME_CAN: 'SEO/DESCRIPTION/THEME_CAN',
      THEME_FROZEN: 'SEO/DESCRIPTION/THEME_FROZEN',
      THEME_READY_MEALS: 'SEO/DESCRIPTION/THEME_READY_MEALS',
      THEME_BIO: 'SEO/DESCRIPTION/THEME_BIO',
      THEME_VEGETABLES: 'SEO/DESCRIPTION/THEME_VEGETABLES',
      THEME_RECIPE: 'SEO/DESCRIPTION/THEME_RECIPE',
      THEME_PLATE: 'SEO/DESCRIPTION/THEME_PLATE',
      THEME_ACC: 'SEO/DESCRIPTION/THEME_ACC',
      THEME_SNACK: 'SEO/DESCRIPTION/THEME_SNACK',
      RECIPE_THEME1: 'SEO/DESCRIPTION/RECIPE_THEME1',
      '2PRODUCTSFACETS': 'SEO/DESCRIPTION/2PRODUCTSFACETS',
      NOTFOUND: 'SEO/DESCRIPTION/NOTFOUND'
    },
    PIPE: { SOCIETY: 'SEO/PIPE/SOCIETY' },
    THEME_ALL: 'SEO/THEME_ALL',
    THEME_STARTER: 'SEO/THEME_STARTER',
    THEME_SALAD: 'SEO/THEME_SALAD',
    THEME_CAN: 'SEO/THEME_CAN',
    THEME_FROZEN: 'SEO/THEME_FROZEN',
    THEME_READY_MEALS: 'SEO/THEME_READY_MEALS',
    THEME_BIO: 'SEO/THEME_BIO',
    THEME_VEGETABLES: 'SEO/THEME_VEGETABLES',
    THEME_RECIPE: 'SEO/THEME_RECIPE',
    THEME_PLATE: 'SEO/THEME_PLATE',
    THEME_ACC: 'SEO/THEME_ACC',
    THEME_SNACK: 'SEO/THEME_SNACK'
  },
  VEGETABLE: {
    DISCOVER_VEGETABLE: 'VEGETABLE/DISCOVER_VEGETABLE',
    OURS_RECIPES: 'VEGETABLE/OURS_RECIPES',
    DISCOVER_ALSO: 'VEGETABLE/DISCOVER_ALSO',
    SEE_ALL: 'VEGETABLE/SEE_ALL',
    ON: 'VEGETABLE/ON'
  },
  RECIPE: {
    'INGREDIENTS_LABEL ': 'RECIPE/INGREDIENTS_LABEL ',
    PEOPLES: 'RECIPE/PEOPLES',
    DIFFICULTY: {
      EASY: 'RECIPE/DIFFICULTY/EASY',
      MEDIUM: 'RECIPE/DIFFICULTY/MEDIUM',
      HARD: 'RECIPE/DIFFICULTY/HARD'
    },
    COST: { CHEAP: 'RECIPE/COST/CHEAP', EXPENSIVE: 'RECIPE/COST/EXPENSIVE' },
    TIMING: { MIN: 'RECIPE/TIMING/MIN', HOURS: 'RECIPE/TIMING/HOURS' },
    STEP: 'RECIPE/STEP',
    DISCOVER_ALSO: 'RECIPE/DISCOVER_ALSO',
    HOME_SEE_MORE: 'RECIPE/HOME_SEE_MORE',
    SHOWCASE: 'RECIPE/SHOWCASE',
    HOME_BOOK: 'RECIPE/HOME_BOOK',
    MIN_MORE: 'RECIPE/MIN_MORE',
    HOUR_MORE: 'RECIPE/HOUR_MORE',
    FILTER_BY_CRITERIA: 'RECIPE/FILTER_BY_CRITERIA',
    SORT_BY: 'RECIPE/SORT_BY',
    BEST_RATED: 'RECIPE/BEST_RATED',
    MOST_CONSULTED: 'RECIPE/MOST_CONSULTED',
    MEMBERS_RECIPES: 'RECIPE/MEMBERS_RECIPES',
    CRITERIA: 'RECIPE/CRITERIA',
    DISCOVER: { RECIPES: 'RECIPE/DISCOVER/RECIPES' },
    DISCOVER_RECIPE: 'RECIPE/DISCOVER_RECIPE',
    INSTRUCTIONS: 'RECIPE/INSTRUCTIONS',
    DEFAULT_TAG: 'RECIPE/DEFAULT_TAG',
    MOST_RECENT: 'RECIPE/MOST_RECENT',
    RECIPE_VIDEOS: 'RECIPE/RECIPE_VIDEOS',
    PRODUCT_ASSOCIATE: 'RECIPE/PRODUCT_ASSOCIATE',
    BUDGET_LOW: 'RECIPE/BUDGET_LOW',
    BUDGET_HIGH: 'RECIPE/BUDGET_HIGH',
    BUDGET_MIDDLE: 'RECIPE/BUDGET_MIDDLE',
    PRINT_RECIPE: 'RECIPE/PRINT_RECIPE',
    LET_YOURSELF_SURPRISED: 'RECIPE/LET_YOURSELF_SURPRISED',
    COOK_TIP_TITLE: 'RECIPE/COOK_TIP_TITLE',
    ENJOY_MEAL: 'RECIPE/ENJOY_MEAL',
    FILTER: { LEGEND: 'RECIPE/FILTER/LEGEND' }
  },
  PREDIGGO: {
    DIFFICULTY: {
      EASY: 'PREDIGGO/DIFFICULTY/EASY',
      MEDIUM: 'PREDIGGO/DIFFICULTY/MEDIUM',
      HARD: 'PREDIGGO/DIFFICULTY/HARD'
    }
  },
  COOKIES: {
    TEXT: 'COOKIES/TEXT',
    CLOSE: 'COOKIES/CLOSE',
    MORE: 'COOKIES/MORE',
    AGREE_YOUTUBE: 'COOKIES/AGREE_YOUTUBE',
    BUTTON_YOUTUBE: 'COOKIES/BUTTON_YOUTUBE'
  },
  POPUP: { LAUNCH: 'POPUP/LAUNCH' },
  FIELD_PLATE: { DISCOVER_VEGETABLE: 'FIELD_PLATE/DISCOVER_VEGETABLE' },
  SEARCH: {
    OUR_RECIPES: 'SEARCH/OUR_RECIPES',
    OUR_PRODUCTS: 'SEARCH/OUR_PRODUCTS',
    OUR_ARTICLES: 'SEARCH/OUR_ARTICLES',
    RECIPES: 'SEARCH/RECIPES',
    PRODUCTS: 'SEARCH/PRODUCTS',
    ARTICLES: 'SEARCH/ARTICLES',
    ALL: 'SEARCH/ALL',
    SORT_BY: 'SEARCH/SORT_BY',
    RESULTS: 'SEARCH/RESULTS',
    RESULT: 'SEARCH/RESULT',
    PRODUCT_OUT: 'SEARCH/PRODUCT_OUT',
    APPLY: 'SEARCH/APPLY',
    FILTER: 'SEARCH/FILTER',
    RECIPE_OUT: 'SEARCH/RECIPE_OUT',
    NO_RESULT: 'SEARCH/NO_RESULT',
    SEE_MORE: 'SEARCH/SEE_MORE',
    DISCOVER_RECIPES: 'SEARCH/DISCOVER_RECIPES',
    SEE_MORE_OF: 'SEARCH/SEE_MORE_OF',
    DELETE: 'SEARCH/DELETE'
  },
  RECIPE_LIST: {
    OUR_RECIPES: 'RECIPE_LIST/OUR_RECIPES',
    RESULTS: 'RECIPE_LIST/RESULTS',
    SEE_MORE: 'RECIPE_LIST/SEE_MORE'
  },
  PRODUCT: {
    BUTTON: {
      ACTU: 'PRODUCT/BUTTON/ACTU',
      FOUND: 'PRODUCT/BUTTON/FOUND',
      DISCOVER: 'PRODUCT/BUTTON/DISCOVER'
    },
    NUTRITIONAL_VALUE: 'PRODUCT/NUTRITIONAL_VALUE',
    CONDITIONING: 'PRODUCT/CONDITIONING',
    PORTIONS: 'PRODUCT/PORTIONS',
    NET_WEIGHT: 'PRODUCT/NET_WEIGHT',
    DRY_WEIGHT: 'PRODUCT/DRY_WEIGHT',
    INGREDIENTS: 'PRODUCT/INGREDIENTS',
    OUR_RECIPES: 'PRODUCT/OUR_RECIPES',
    FOR: 'PRODUCT/FOR',
    PER_PORTION: 'PRODUCT/PER_PORTION',
    ENERGY: 'PRODUCT/ENERGY',
    FAT: 'PRODUCT/FAT',
    SATURATE: 'PRODUCT/SATURATE',
    GLUCIDES: 'PRODUCT/GLUCIDES',
    FIBER: 'PRODUCT/FIBER',
    PROTEINS: 'PRODUCT/PROTEINS',
    SALT: 'PRODUCT/SALT',
    DISCOVER_ALSO: 'PRODUCT/DISCOVER_ALSO',
    MORE_INFORMATION: 'PRODUCT/MORE_INFORMATION',
    TRACES: 'PRODUCT/TRACES',
    CONTAINS: 'PRODUCT/CONTAINS',
    SLIDER: { SHEET: 'PRODUCT/SLIDER/SHEET', BUY: 'PRODUCT/SLIDER/BUY' },
    NUTRISCORE: 'PRODUCT/NUTRISCORE',
    TRIMAN: 'PRODUCT/TRIMAN',
    AGRICONFIANCE: 'PRODUCT/AGRICONFIANCE',
    BIO: 'PRODUCT/BIO',
    PESTICIDE_FREE: 'PRODUCT/PESTICIDE_FREE',
    LEAF: 'PRODUCT/LEAF',
    LOCK: 'PRODUCT/LOCK',
    SEE_ALL: 'PRODUCT/SEE_ALL',
    LEGISLATION: 'PRODUCT/LEGISLATION',
    PORTION: {
      SINGULAR: 'PRODUCT/PORTION/SINGULAR',
      PLURAL: 'PRODUCT/PORTION/PLURAL'
    },
    DISCLAIMER: 'PRODUCT/DISCLAIMER',
    COOKING: 'PRODUCT/COOKING',
    CONSERVATION: 'PRODUCT/CONSERVATION'
  },
  FAQ: {
    ON: 'FAQ/ON',
    ALL: 'FAQ/ALL',
    SEE_MORE: 'FAQ/SEE_MORE',
    TITLE_1: 'FAQ/TITLE_1',
    TITLE_2: 'FAQ/TITLE_2',
    LINK_LABEL: 'FAQ/LINK_LABEL'
  },
  FORM: {
    GO_TO_STEP: 'FORM/GO_TO_STEP',
    STEP: 'FORM/STEP',
    RECEIVE_EMAIL_COPY: 'FORM/RECEIVE_EMAIL_COPY',
    SEND: 'FORM/SEND',
    GDPR: 'FORM/GDPR',
    ERROR: 'FORM/ERROR',
    SEND_ERROR: 'FORM/SEND_ERROR',
    NOTICE: 'FORM/NOTICE',
    SENDING: 'FORM/SENDING',
    LOADING: 'FORM/LOADING',
    PRODUCT_INFORMATION_TITLE: 'FORM/PRODUCT_INFORMATION_TITLE',
    PRODUCT_INFORMATION: {
      SEO_TITLE: 'FORM/PRODUCT_INFORMATION/SEO_TITLE',
      SEO_DESC: 'FORM/PRODUCT_INFORMATION/SEO_DESC'
    },
    INFORMATIONS: 'FORM/INFORMATIONS',
    MISTER: 'FORM/MISTER',
    MISS: 'FORM/MISS',
    NAME: 'FORM/NAME',
    OUR_NAME: 'FORM/OUR_NAME',
    FIRSTNAME: 'FORM/FIRSTNAME',
    OUR_FIRSTNAME: 'FORM/OUR_FIRSTNAME',
    MAIL: 'FORM/MAIL',
    MAIL_PLH: 'FORM/MAIL_PLH',
    MAIL_CONFIRM: 'FORM/MAIL_CONFIRM',
    MAIL_ERROR: 'FORM/MAIL_ERROR',
    MAIL_ERROR_CONFIRM: 'FORM/MAIL_ERROR_CONFIRM',
    ADDRESS: 'FORM/ADDRESS',
    STREET_PLH: 'FORM/STREET_PLH',
    STREET: 'FORM/STREET',
    ADDRESS_COMPLEMENT: 'FORM/ADDRESS_COMPLEMENT',
    ADDRESS_COMP_PLH: 'FORM/ADDRESS_COMP_PLH',
    ZIPCODE: 'FORM/ZIPCODE',
    CITY: 'FORM/CITY',
    CITY_PLH: 'FORM/CITY_PLH',
    PHONE: 'FORM/PHONE',
    PHONE_PLH: 'FORM/PHONE_PLH',
    COUNTRY: 'FORM/COUNTRY',
    PRODUCT_INFORMATIONS: 'FORM/PRODUCT_INFORMATIONS',
    PRODUCT_INFORMATION_DESC: 'FORM/PRODUCT_INFORMATION_DESC',
    PRODUCT: {
      DETAIL: { DESC: 'FORM/PRODUCT/DETAIL/DESC' },
      NOTE: {
        TITLE: 'FORM/PRODUCT/NOTE/TITLE',
        SEO_TITLE: 'FORM/PRODUCT/NOTE/SEO_TITLE',
        SEO_DESC: 'FORM/PRODUCT/NOTE/SEO_DESC'
      }
    },
    PRODUCT_RANGE: 'FORM/PRODUCT_RANGE',
    PRODUCT_NAME: 'FORM/PRODUCT_NAME',
    PRODUCT_NAME_PLH: 'FORM/PRODUCT_NAME_PLH',
    PRODUCT_INFORMATIONS_DETAIL: 'FORM/PRODUCT_INFORMATIONS_DETAIL',
    PRODUCT_INFORMATIONS_LABEL: 'FORM/PRODUCT_INFORMATIONS_LABEL',
    PRODUCT_INFORMATIONS_PLH: 'FORM/PRODUCT_INFORMATIONS_PLH',
    PRODUCT_LOT: 'FORM/PRODUCT_LOT',
    PRODUCT_LOT_INFO: 'FORM/PRODUCT_LOT_INFO',
    PHOTO: 'FORM/PHOTO',
    EXPIRED_DATE: 'FORM/EXPIRED_DATE',
    EXPIRED_DATE_ERROR: 'FORM/EXPIRED_DATE_ERROR',
    EXPIRED_DATE_DESC: 'FORM/EXPIRED_DATE_DESC',
    PHOTO_MORE: 'FORM/PHOTO_MORE',
    MESSAGE: 'FORM/MESSAGE',
    OUR_MESSAGE: 'FORM/OUR_MESSAGE',
    SALES_OPERATIONS: {
      TITLE: 'FORM/SALES_OPERATIONS/TITLE',
      SEO_TITLE: 'FORM/SALES_OPERATIONS/SEO_TITLE',
      SEO_DESC: 'FORM/SALES_OPERATIONS/SEO_DESC'
    },
    PRESS_SERVICE: 'FORM/PRESS_SERVICE',
    FOOD_SERVICE: 'FORM/FOOD_SERVICE',
    BUSINESS_INFORMATION: 'FORM/BUSINESS_INFORMATION',
    MODAL_INFORMATION: 'FORM/MODAL_INFORMATION',
    JOB_OFFER: {
      TITLE: 'FORM/JOB_OFFER/TITLE',
      SEO_TITLE: 'FORM/JOB_OFFER/SEO_TITLE',
      SEO_DESC: 'FORM/JOB_OFFER/SEO_DESC'
    },
    WEB_SUGGESTION: {
      TITLE: 'FORM/WEB_SUGGESTION/TITLE',
      SEO_TITLE: 'FORM/WEB_SUGGESTION/SEO_TITLE',
      SEO_DESC: 'FORM/WEB_SUGGESTION/SEO_DESC'
    },
    ANOTHER: {
      TITLE: 'FORM/ANOTHER/TITLE',
      SEO_TITLE: 'FORM/ANOTHER/SEO_TITLE',
      SEO_DESC: 'FORM/ANOTHER/SEO_DESC'
    },
    AMBASSADOR: 'FORM/AMBASSADOR',
    CATEGORY: {
      SALAD: 'FORM/CATEGORY/SALAD',
      FROZEN: 'FORM/CATEGORY/FROZEN',
      CAN: 'FORM/CATEGORY/CAN',
      TAKEAWAY: 'FORM/CATEGORY/TAKEAWAY',
      VEGETABLE: 'FORM/CATEGORY/VEGETABLE'
    }
  },
  CONTACT: {
    TITLE: 'CONTACT/TITLE',
    SPECIAL_INFO: 'CONTACT/SPECIAL_INFO',
    TAKE_CARE: 'CONTACT/TAKE_CARE',
    BY_MAIL: 'CONTACT/BY_MAIL',
    BY_PHONE: 'CONTACT/BY_PHONE',
    BY_SOURDLINE: 'CONTACT/BY_SOURDLINE',
    MAIL_TEXT: 'CONTACT/MAIL_TEXT',
    PHONE_TEXT: 'CONTACT/PHONE_TEXT',
    SOURDLINE_TEXT: 'CONTACT/SOURDLINE_TEXT',
    BY_DISTRIBUTOR: 'CONTACT/BY_DISTRIBUTOR',
    BY_DISTRIBUTOR_TEXT: 'CONTACT/BY_DISTRIBUTOR_TEXT',
    SUBTITLE: 'CONTACT/SUBTITLE',
    AGREEMENT: 'CONTACT/AGREEMENT',
    READ_MORE: 'CONTACT/READ_MORE',
    READ_MORE_LABEL: 'CONTACT/READ_MORE_LABEL',
    SATISFACTION_TEXT: 'CONTACT/SATISFACTION_TEXT',
    SATISFACTION_CHECKBOX: 'CONTACT/SATISFACTION_CHECKBOX',
    CHECKBOX_TEXT: 'CONTACT/CHECKBOX_TEXT',
    PHONE: 'CONTACT/PHONE',
    CALL_US: 'CONTACT/CALL_US',
    TO_WRITE: 'CONTACT/TO_WRITE',
    PHONE_NUMBER: 'CONTACT/PHONE_NUMBER',
    SUCCESS_MESSAGE: 'CONTACT/SUCCESS_MESSAGE',
    PHOTOERROR: { MESSAGE: 'CONTACT/PHOTOERROR/MESSAGE' }
  },
  GLOBAL: { READ_MORE: 'GLOBAL/READ_MORE' },
  FLOWBOX: {
    GENERIC_KEY: 'FLOWBOX/GENERIC_KEY',
    PRODUCT_KEY: 'FLOWBOX/PRODUCT_KEY',
    RECIPE_KEY: 'FLOWBOX/RECIPE_KEY',
    HOME_KEY: 'FLOWBOX/HOME_KEY'
  },
  TOKEN: {
    FACEBOOK: 'TOKEN/FACEBOOK',
    PAGE_FACEBOOK_ID: 'TOKEN/PAGE_FACEBOOK_ID',
    INSTAGRAM: 'TOKEN/INSTAGRAM',
    INSTA_ID: 'TOKEN/INSTA_ID',
    YOUTUBE: 'TOKEN/YOUTUBE',
    TWITTER: 'TOKEN/TWITTER',
    MAGAZINE: 'TOKEN/MAGAZINE'
  },
  COOKIE: {
    BANNER: {
      MANDATORY: 'COOKIE/BANNER/MANDATORY',
      ANALYTICS: 'COOKIE/BANNER/ANALYTICS',
      MARKETING: 'COOKIE/BANNER/MARKETING',
      SOCIAL_MEDIA: 'COOKIE/BANNER/SOCIAL_MEDIA',
      ACCEPT: 'COOKIE/BANNER/ACCEPT',
      REJECT: 'COOKIE/BANNER/REJECT',
      SET_UP: 'COOKIE/BANNER/SET_UP',
      MANDATORY_DETAILS: 'COOKIE/BANNER/MANDATORY_DETAILS',
      ANALYTICS_DETAILS: 'COOKIE/BANNER/ANALYTICS_DETAILS',
      MARKETING_DETAILS: 'COOKIE/BANNER/MARKETING_DETAILS',
      V2: 'COOKIE/BANNER/V2'
    },
    MODAL: {
      TITLE: 'COOKIE/MODAL/TITLE',
      TEXT: 'COOKIE/MODAL/TEXT',
      CONFIG: 'COOKIE/MODAL/CONFIG',
      SAVE: 'COOKIE/MODAL/SAVE'
    }
  },
  LINK: { JOB_OFFERS: 'LINK/JOB_OFFERS' },
  CLIC2BUY: { INFOS: 'CLIC2BUY/INFOS' }
};
