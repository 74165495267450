import { SomBloc } from './bloc.model';
import { SfItem } from '@app/sf-lib/public_api';
import { SomMagazineArticlePage } from './magazine-article-page.model';
import { SomMagazineRecipePage } from './magazine-recipe-page.model';

export class SomMagazineShowcase extends SomBloc {

  /** Items to display */
  public items: (SomMagazineArticlePage | SomMagazineRecipePage)[];


  constructor() {
    super();
    this.items = [];
  }
}
