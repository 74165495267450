import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SfSearchBarComponent } from './components/search-bar.component';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SfLinkModule } from '../link/link.module';
import { SfLocalizeModule } from '../../core/localize/localize.module';
import { SfUtilsModule } from '../../core/utils/utils.module';
import { AngularMaterialModule } from '@app/shared/angular-material/angular-material.module';
import { SfOptgroupComponent } from './components/optgroup';
import { SfOptionComponent } from './components/option';
import { SfAutocompleteComponent } from './components/autocomplete';
import { SF_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER, SfAutocompleteTriggerDirective } from './components/autocomplete-trigger';
import { OverlayModule } from '@angular/cdk/overlay';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    OverlayModule,

    SfLinkModule,
    SfLocalizeModule,
    SfUtilsModule,
    AngularMaterialModule,
  ],
  declarations: [
    SfSearchBarComponent,
    SfOptgroupComponent,
    SfOptionComponent,
    SfAutocompleteComponent,
    SfAutocompleteTriggerDirective
  ],
  exports: [
    SfSearchBarComponent,
  ],
  providers: [SF_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER]
})
export class SfSearchBarModule { }
