/* tslint:disable */export const hu_hu = {
  '404': {
    TITLE: 'Hoppá!',
    PAGENOTFOUND: 'Az oldal nem létezik',
    BUTTON: 'Vissza a nyitólapra',
    RECIPES: 'Fedezze fel receptjeinket'
  },
  HOME: {
    SHOWCASE: 'Receptek',
    CATCH_PHRASE: '',
    SHOWCASE_SEE_ALL: 'Minden recept',
    MAGAZINE: { TITLE: 'LEGFRISSEBB HÍREK', BUTTON: 'Ismerje meg a zöldségeket' },
    OUR_VEGETABLES: 'Zöldségeink',
    SOCIAL_WALL: 'Ossza meg másokkal is a zöldségek iránti szenvedélyét!',
    FAQ_TITLE: 'Kérdése van? ',
    FAQ_SEE_MORE: 'Olvasson tovább: ',
    FLOWBOX: ''
  },
  SELECT: { DEFAULT_VALUE: 'Bonduelle válogatás' },
  MENU: {
    CONTACT_US: 'Kapcsolat',
    MY_ACCOUNT: 'Felhasználói fiók',
    GOOD_DEALS: 'Promóciós ajánlatok',
    NEWSLETTER: 'Bonduelle Hírlevél'
  },
  FOOTER: {
    LINK: {
      BONDUELLE: 'Bonduelle.hu',
      CREDITS: 'Impresszum',
      LEGAL_NOTICE: 'Jogi nyilatkozat',
      TERMS_OF_SALES: 'Felhasználási feltételek',
      COOKIES: 'Cookie-k',
      SITEMAP: 'Oldaltérkép',
      PRIVACY_POLICY: 'Adatkezelési szabályzat',
      ALL_RIGHTS_RESERVED: '2018 © Minden jog fenntartva'
    },
    HISTORY: {
      TITLE: 'Bonduelle - 1853 óta',
      TEXT: 'A Bonduelle családi vállalkozás, amely hét generáció óta olyan mezőgazdasági termelésre törekszik, amely tiszteletben tartja mind a termőföldet, mind az embereket. Hitvallásunk szerint hatékony, intelligens és méltányos agroökológiát folytatunk, amely számára fontos a jövő. A természettől, a természetért, egy jobb jövőért.'
    },
    NEWSLETTER: {
      TITLE: 'Bonduelle Közösség',
      TEXT: 'Szeretnék feliratkozni a « Mindent a zöldségekről a Bonduelle támogatásával » hírlevélre (hírek, promóciók, cikkek…)',
      PLACEHOLDER: 'Az Ön e-mail címe',
      GDPR_LINK: 'Az adatkezeléssel kapcsolatos további információk'
    }
  },
  PRODUCTS: 'Termékek',
  MAGAZINE: {
    HOME: 'Ismerje meg magazinunkat',
    CATCH_PHRASE: '',
    MENU: { BONDUELLE_LINK: 'Vissza a bonduelle.hu-ra' },
    SHOWCASE: 'a kezdőlapon',
    PREVIOUS_ARTICLES: 'Előző cikk',
    PREVIOUS_ARTICLES_SEE_ALL: 'Minden cikk',
    PREVIOUS_EDITIONS: 'Korábbi megjelenések',
    PREVIOUS_EDITIONS_SEE_ALL: 'További megjelenések',
    PREVIOUS_ARTICLES_BY_CATEGORY: 'Korábbi cikkek kategóriák szerint',
    RUBRIC: {
      SEE_ALL: 'Minden recept',
      SEE_ALL_ARTICLES: 'Minden cikk',
      ON: 'folyóiratcikk a'
    },
    RUBRIC_COOK: { ON: 'receptek a' },
    ARTICLE: { JOIN_US: 'Kövessen minket', READ_ALSO: 'Olvasson tovább' },
    RECIPE: {
      INGREDIENTS: 'Hozzávalók',
      INGREDIENTS_LABEL_DESC: '{{value}} személyre',
      INGREDIENTS_LABEL_ONE_DESC: '{{value}} személyre',
      INGREDIENTS_LABEL: 'hozzávalók {{value}} személyre',
      INGREDIENTS_LABEL_ONE: 'hozzávalók {{value}} személyre',
      PEOPLES: '{{value}} személy ',
      PEOPLE: '{{value}} személy ',
      DIFFICULTY: { EASY: 'Egyszerű', MEDIUM: 'Közepes nehézségű', HARD: 'Nehéz' },
      COST: { CHEAP: 'Olcsó', EXPENSIVE: 'Drága' },
      TIMING: { MIN: 'perc', HOURS: 'óra' },
      STEP: 'lépés',
      DISCOVER_ALSO: 'Fedezze fel ezt is...'
    }
  },
  ARTICLE: { DISCOVER_ALSO: 'Fedezze fel ezt is...', INGREDIENTS: 'Hozzávalók' },
  SF: {
    SLIDE: { DISCOVER: 'Összes' },
    SEARCH: { PLACEHOLDER: 'Keresés', ALL: 'Minden' },
    NEWSLETTER: {
      BTN_LABEL: 'Regisztrálok',
      PLACEHOLDER: 'Az Ön e-mail címe',
      ERROR_MESSAGE: 'Érvénytelen e-mail cím',
      ALREADY_SUBSCRIBED: 'Ön már feliratkozott',
      GDPR: {
        TXT: 'Még több információ az adatkezelésről',
        LINK: '/adatkezelesi-szabalyzat'
      }
    },
    MONTHLY_FOLDER: { TITLE: 'Legfrissebb híreink' },
    MINI_NEWSLETTER: {
      PLACEHOLDER: 'Az Ön e-mail címe',
      GDPR: {
        TXT: 'Még több információ az adatkezelésről',
        LINK: '/adatkezelesi-szabalyzat'
      }
    },
    SOCIALITEM: {
      SHARE: 'megosztás',
      COMMENT: 'megjegyzés ',
      BONDUELLE: 'Bonduelle'
    },
    SEARCHRECIPE: { DISCOVER_ALL: 'keresés az összes recept között' },
    FORM: {
      ERROR: 'Érvénytelen mező',
      PHOTO: {
        ADD: 'Kép hozzáadása',
        DELETE: 'Kép törlése',
        SEE: 'Megtekintés'
      }
    }
  },
  CORPORATE: {
    COMPANY_STORY: 'Bonduelle Magyarországon',
    CSR_COMMITMENT: 'Kötelezettségvállalásaink',
    VEGETABLE_INNOVATIONS: 'Bonduelle a világban',
    NEWS: '',
    SEE_MORE: 'Még több cikk',
    ON: 'Cikkek az alábbi témában'
  },
  NEWSLETTER: {
    TITLE: 'Subscribe to the newsletter',
    SUBTITLE: 'Good deals, culinary inspiration and a little culture!'
  },
  ROUTES: {
    products: 'termekek',
    news: 'hirek',
    'since-1853': '1853-ota',
    'field-plate': 'termofoldrol-tanyerra',
    'company-story': 'cegtortenet',
    'csr-commitments': 'tarsadalmi-felelossegvallalas',
    'vegetal-innovations': 'legujabb-termekeink',
    magazine: 'magazin',
    all: 'minden',
    article: 'cikk',
    cook: 'fozes',
    cultivate: 'muveld',
    explore: 'felfedezes',
    recipe: 'receptvilag',
    recipes: 'receptek',
    'recipes-home': 'fozesi-receptek',
    r: 'r',
    credits: 'kredit',
    'legal-notice': 'jogi-nyilatkozat',
    'terms-of-sales': 'felhasznalasi-feltetelek',
    cookies: 'cookie-k',
    sitemap: 'oldalterkep',
    'privacy-policy': 'adatkezelesi-szabalyzat',
    'all-rights-reserved': 'minden-jog-fenntartva',
    search: 'kereses',
    'articles-search': 'cikk',
    vegetables: 'termekek',
    p: 'p',
    contact: 'kapcsolat',
    'info-product': 'termekinformaciok',
    'note-product': 'termekekkel-kapcsolatos-megjegyzesek',
    suggestion: 'javaslat',
    'other-request': 'termékinformációk',
    faq: 'gyik',
    'all-themes': 'osszes-kerdes',
    'products-search': 'termekkereso',
    recipesearch: 'receptkereso',
    'product-news': 'ujdonsagok',
    'good-deals': 'promocios-ajanlatok',
    'not-found': 'nincs-talalat',
    'menu-page': 'allasajanlatok',
    'menu-one': 'bekescsaba',
    'menu-two': 'nagykoros',
    'menu-three': 'nyirszolos',
    'menu-four': 'budapest',
    'menu-external': '',
    'theme-pasta-rice': '',
    'theme-bowls': '',
    'theme-soup': '',
    'theme-snack': '',
    'theme-sauce': '',
    'theme-unique-plate': '',
    'theme-over': '',
    'theme-legumin': '',
    'theme-ingredients-recipes': '',
    'theme-cook-plate': ''
  },
  BREAD: {
    products: 'Termékek',
    news: 'Hírek',
    'since-1853': '1853-óta',
    'field-plate': 'Termőföldről-a-tányérra',
    'company-story': 'Cégtörténet',
    'csr-commitments': 'Társadalmi-felelősségvállalás',
    'vegetal-innovations': 'Legújabb-termékeink',
    magazine: 'Magazin',
    all: 'Minden',
    article: 'Cikk',
    cook: 'Főzés ',
    cultivate: 'Műveld',
    explore: 'Felfedezés ',
    recipe: 'Receptvilág',
    recipes: 'Receptek',
    'recipes-home': 'Főzési receptek',
    'articles-search': 'Cikk',
    search: 'kereses',
    vegetables: 'Termékek',
    contact: 'Kapcsolat',
    'info-product': 'Termékinformációk',
    'note-product': 'Termékekkel-kapcsolatos-megjegyzések',
    suggestion: 'Javaslat',
    'other-request': 'Termékinformációk',
    faq: 'Gyik',
    'all-themes': 'Összes-kérdés',
    'products-search': 'Termékkereső',
    recipesearch: 'Receptkereső',
    'product-news': 'Újdonságok',
    'good-deals': 'Promociós-ajánlatok',
    'not-found': 'Nincs-találat',
    'menu-page': 'Állásajánlatok',
    'menu-one': 'Békéscsaba',
    'menu-two': 'Nagykőrős',
    'menu-three': 'Nyírszőlős',
    'menu-four': 'Budapest'
  },
  MENU_PAGE: {
    TITLE1: 'Békéscsaba',
    TITLE2: 'NAGYKŐRÖS',
    TITLE3: 'NYÍRSZŐLŐS',
    TITLE4: 'BUDAPEST',
    EXTERNAL: ''
  },
  TECHNICAL: { MENU_PAGE: 'job_offers' },
  SEO: {
    TITLE: {
      MENU_ONE: 'ÁLLÁSAJÁNLATOK - BÉKÉSCSABA | Bonduelle',
      MENU_TWO: 'ÁLLÁSAJÁNLATOK - NAGYKŐRÖS | Bonduelle',
      MENU_THREE: 'ÁLLÁSAJÁNLATOK - NYÍRSZŐLŐS | Bonduelle',
      MENU_FOUR: 'ÁLLÁSAJÁNLATOK - BUDAPEST | Bonduelle',
      HOME: 'Bonduelle: szenvedélyünk a zöldség ',
      SEARCH: {
        MAIN: '{{value}}  | Bonduelle',
        RECIPES: '{{value}} receptek szerint rendezve  | Bonduelle',
        VEGETABLES: '{{value}}zöldségek szerint rendezve   | Bonduelle',
        ARTICLES: '{{value}}zöldségek szerint cikk   | Bonduelle'
      },
      CONTACT: 'Bonduelle | Kapcsolat | Állásajánlattal kapcsolatos kérdések',
      RECIPES: 'Receptek: levesek, főételek, desszertek, ... | Bonduelle',
      RECIPE_HOME: 'Bonduelle receptek  | Bonduelle',
      RECIPE: 'Recept {{value}}  | Bonduelle',
      PRODUCTS: 'Konzerv és fagyasztott zöldségek | Bonduelle',
      NEWS: 'Fedezze fel a zöldségek sokszínűségét| Bonduelle',
      CORPORATE: {
        NEWS: 'Legfrissebb hírek a zöldségekről | Bonduelle',
        COMPANY_STORY: 'Cégünk bemutatása  | Bonduelle',
        CSR_COMMITMENTS: 'Fedezze fel vállalati etikai értékeinket, alapelveinket és elkötelezettségünket | Bonduelle',
        VEGETAL_INNOVATIONS: 'Fedezze fel újdonságainkat | Bonduelle',
        FIELD_PLATE: 'A legjobb zöldséges receptek| Bonduelle',
        ARTICLE: '{{value}}  | Bonduelle',
        VEGETABLE: 'Receptötletek{{value}}  | Bonduelle'
      },
      PRODUCT: '{{value}} : Bonduelle  zöldségek ',
      MAGAZINE: {
        NEWS: 'Fedezze fel a zöldségek sokféleségét receptekkel, tippekkel  | Bonduelle ',
        ARTICLE: 'Bonduelle',
        COOK: 'Egyszerű és gyors receptek zöldségekkel | Bonduelle',
        GROW: 'Zöldségek a saját zöldséges kertünkben  | Bonduelle',
        EXPLORE: 'Fedezze fel az új zöldségeket és ízeket | Bonduelle',
        RECIPE: 'Recept {{value}} | Bonduelle'
      },
      FAQ: 'GYIK: Gyakran ismételt kérdések| Bonduelle',
      NOTFOUND: '404-es hiba, próbálja később | Bonduelle | Bonduelle',
      RECIPES_ALL: 'Minden receptünk',
      RECIPETYPE: 'Recept: {{value}} zöldségből ',
      RECIPESEASON: 'Szezonális zöldség recept: {{value}}',
      RECIPEPREPARATION: 'Receptek: Előkészítési idő: {{value}}',
      RECIPEDIFFICULTY: 'Egyszerű  zöldséges receptek: zöldségek {{value}}',
      RECIPEVEGETABLE: 'Egyszerű recept a (z) {{value}} zöldséggel',
      RECIPECOST: 'Recept és zöldség {{value}}',
      RECIPEDISH: 'Zöldséges receptek  {{value}}',
      RECIPEDESC: 'Recept és zöldség {{value}}',
      '2RECIPEFACETS': 'Recept {{value}}',
      '4RECIPEFACETS': 'Recept {{value}}',
      PRODUCTSEASON: 'Szezonális zöldségeink: {{value}}',
      PRODUCTTECHNO: '',
      PRODUCTVEGETABLE: '',
      PRODUCTRANGE: '',
      THEME_ALL: 'Konzerv és fagyasztott zöldségek ',
      THEME_STARTER: 'Előétel receptek ',
      THEME_SALAD: 'Saláta receptek ',
      THEME_CAN: 'Bonduelle | Zöldségválaszték | Konzerv termékek ',
      THEME_FROZEN: 'Bonduelle | Zöldségválaszték | Fagyasztott termékek ',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: '',
      THEME_RECIPE: 'Egyszerű és gyors receptek ',
      THEME_PLATE: '',
      THEME_ACC: 'Köret receptek ',
      THEME_SNACK: '',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': '{{value}}'
    },
    DESCRIPTION: {
      MENU_ONE: 'ÁLLÁSAJÁNLATOK - BÉKÉSCSABA | Bonduelle',
      MENU_TWO: 'ÁLLÁSAJÁNLATOK - NAGYKŐRÖS | Bonduelle',
      MENU_THREE: 'ÁLLÁSAJÁNLATOK - NYÍRSZŐLŐS | Bonduelle',
      MENU_FOUR: 'ÁLLÁSAJÁNLATOK - BUDAPEST | Bonduelle',
      HOME: 'Konzerv és fagyasztott zöldségek széles választéka. Fedezze fel zöldségeinket és inspirálódjon receptötleteinkkel. Bonduelle a zöldségek legjava. ',
      SEARCH: {
        MAIN: 'Fedezze fel zöldségeinket és inspiráló recept ötleteinket a (z) {{value}} számára. Egyszerű és ízletes receptek a Bonduelle-lel. ',
        RECIPES: 'Az összes {{value}} recept rendezve. Egyszerű és ízletes receptek zöldségekkel.',
        VEGETABLES: 'Az összes {{value}} zöldség szerint. Egyszerű és ízletes receptek. ',
        ARTICLES: 'Az összes {{value}} cikk szerint. Egyszerű és ízletes receptek. '
      },
      CONTACT: 'Ha kérdése merülne fel termékünkkel kapcsolatban vagy információra lenne szüksége akkor vegye fel a kapcsolatot ügyfélszolgálatunkkal. ',
      RECIPES: 'Fedezze fel ízletes zöldséges receptjeinket, és szerezzen örömet  szeretteinek. Gyors, egyszerű és finom.',
      RECIPE_HOME: 'Fedezze fel az ízletes zöldséges receptjeinket az ünnepekre. Egyszerű és ízletes receptek. ',
      RECIPE: '',
      PRODUCTS: 'A széles zöldségválasztékunk lehetővé teszi a gyors, egyszerű  és ízletes receptek elkészítését.',
      NEWS: 'Fedezze fel, hogyan készítheti el a zöldségeket, hogy ízletes ételeket hozzon létre. Egyszerű és ízletes receptek.',
      CORPORATE: {
        NEWS: 'Legfrissebb hírek a mezőgazdaság világából, növényinnovációkró, élelmiszer-pazarlásról és arról, hogyan lehet egyszerűbben étkezni.',
        COMPANY_STORY: 'Szeretne többet megtudni a zöldségek világáról, valamint a mezőgazdaságról és zöldségfélékről? Fedezze fel mindezt a blogunkon. Egyszerű és inspiráló receptek.',
        CSR_COMMITMENTS: 'Kíváncsi arra, hogyan termesztik zöldségeinket, honnan származnak, hogyan óvjuk a környezetet? Itt találhat választ a zöldségekkel kapcsolatos kérdésekre.',
        VEGETAL_INNOVATIONS: 'Érdeklődésünk középpontjában a zöldségek állnak.',
        VEGETABLE: '',
        FIELD_PLATE: '\n' +
          'Fedezze fel inspiráló receptjeinket. Egyszerű és ízletes receptek zöldségekkel.',
        ARTICLE: ''
      },
      PRODUCT: '\n{{value}} inspiráló receptek elkészítéséhez',
      MAGAZINE: {
        NEWS: 'Aktualitások, hírek, újdonságok. ',
        ARTICLE: '',
        COOK: 'Zöldségmagazin: változatos, egészséges és kiegyensúlyozott receptek zöldségekkel konyhai alaptudástól függetlenül mindenkinek | Bonduelle',
        GROW: 'Kertészkedés mindenkinek: egy helyen minden tipp és információ, amit a kertművelésről és a háztáji kertészkedésről tudni kell | Bonduelle',
        EXPLORE: 'Fedezzen fel új és ötletes új zöldséges recepteket!',
        RECIPE: ''
      },
      FAQ: 'Gyakran ismételt kérdések: nézzen körül oldalunkon és keresse meg a választ termékekkel vagy egyéb információval kapcsolatos kérdéseire',
      PAGE: 'Oldal',
      RECIPETYPE: 'Fedezze fel  {{value}} receptjeinket és a zöldségek elkészítésének  inspiráló módját. Gyors, eredeti, egyszerű receptötletek. ',
      RECIPESEASON: 'Fedezze fel inspiráló receptötleteinket a (z) {{value}} számára.',
      RECIPEPREPARATION: 'Fedezze fel inspiráló receptjeinket és a {{value}} zöldségek elkészítésének módját. Gyors, eredeti, egyszerű receptötletek. ',
      RECIPEDIFFICULTY: 'Fedezze fel inspiráló receptjeinket és a {{value}} zöldségek elkészítésének módját. Gyors, eredeti, egyszerű receptötletek. ',
      RECIPEVEGETABLE: 'Fedezze fel inspiráló receptjeinket és a {{value}} zöldségek elkészítésének módját. Gyors, eredeti, egyszerű receptötletek. ',
      RECIPECOST: 'Fedezze fel inspiráló receptjeinket és a {{value}} zöldségek elkészítésének módját. Gyors, eredeti, egyszerű receptötletek.',
      RECIPEDISH: 'Fedezze fel inspiráló receptjeinket és a {{value}} zöldségek elkészítésének módját. Gyors, eredeti, egyszerű receptötletek. ',
      RECIPEDESC: 'Fedezze fel inspiráló receptjeinket és a {{value}} zöldségek elkészítésének módját. Gyors, eredeti, egyszerű receptötletek.',
      '2RECIPEFACETS': '',
      '4RECIPEFACETS': 'Fedezze fel inspiráló receptjeinket és a {{value}} zöldségek elkészítésének módját. Gyors, eredeti, egyszerű receptötletek.',
      PRODUCTSEASON: 'A zöldségeink széles választéka lehetővé teszi, hogy finom, egyszerű recepteket próbáljon ki minden évszakban. ',
      PRODUCTTECHNO: 'A zöldségeink széles választéka lehetővé teszi, hogy finom, egyszerű recepteket próbáljon ki minden évszakban. ',
      PRODUCTVEGETABLE: '{{value}}',
      PRODUCTRANGE: '{{value}}',
      THEME_ALL: 'A zöldségeink széles választéka lehetővé teszi, hogy finom, egyszerű recepteket próbáljon ki minden évszakban. ',
      THEME_STARTER: 'Új receptet keres? Nálunk minden alkalomra nagyszerű és inspiráló receptötleteket talál.',
      THEME_SALAD: 'Új saláta receptet keres? Nálunk minden alkalomra nagyszerű és inspiráló receptötleteket talál.',
      THEME_CAN: '',
      THEME_FROZEN: '',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: '',
      THEME_RECIPE: '',
      THEME_PLATE: '',
      THEME_ACC: '',
      THEME_SNACK: 'A zöldségeink széles választéka lehetővé teszi, hogy finom, egyszerű recepteket próbáljon ki minden évszakban. ',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': 'A zöldségeink széles választéka lehetővé teszi, hogy finom, egyszerű recepteket próbáljon ki minden évszakban. ',
      NOTFOUND: ''
    },
    PIPE: { SOCIETY: ' | Bonduelle' },
    THEME_ALL: 'minden ',
    THEME_STARTER: 'Előétel ',
    THEME_SALAD: '',
    THEME_CAN: '',
    THEME_FROZEN: '',
    THEME_READY_MEALS: '',
    THEME_BIO: '',
    THEME_VEGETABLES: '',
    THEME_RECIPE: 'Receptek ',
    THEME_PLATE: 'Főétel receptek ',
    THEME_ACC: 'Köretek ',
    THEME_SNACK: 'Snacking '
  },
  VEGETABLE: {
    DISCOVER_VEGETABLE: 'Fedezze fel zöldségválasztékunkat',
    OURS_RECIPES: 'Receptjeink ',
    DISCOVER_ALSO: 'Olvasson tovább',
    SEE_ALL: 'Mutassa az összes zöldséget',
    ON: 'Zöldségek kiválasztása'
  },
  RECIPE: {
    'INGREDIENTS_LABEL ': 'hozzávalók {{value}} személyre',
    PEOPLES: '{{value}} személy',
    DIFFICULTY: { EASY: 'Könnyű', MEDIUM: 'Közepes nehézségű', HARD: 'nehéz' },
    COST: { CHEAP: 'olcsó', EXPENSIVE: 'drága' },
    TIMING: { MIN: 'perc', HOURS: 'óra' },
    STEP: 'lépés',
    DISCOVER_ALSO: 'Olvasson tovább',
    HOME_SEE_MORE: 'Még több recept',
    SHOWCASE: 'Aktuális kedvencek ',
    HOME_BOOK: 'Receptjeink',
    MIN_MORE: 'perc +',
    HOUR_MORE: 'óra +',
    FILTER_BY_CRITERIA: 'kritériumok szerinti szűrés',
    SORT_BY: 'Az alábbiak szerint mutat:',
    BEST_RATED: 'Legjobb értékelést kapott',
    MOST_CONSULTED: 'Leggyakrabban megtekintett',
    MEMBERS_RECIPES: 'tagok receptjei',
    CRITERIA: 'Újdonságok',
    DISCOVER: { RECIPES: 'Fedezze fel receptjeinket' },
    DISCOVER_RECIPE: 'Fedezze fel a receptet',
    INSTRUCTIONS: 'Instrukciók',
    DEFAULT_TAG: 'Receptötlet',
    MOST_RECENT: 'Legújabb',
    RECIPE_VIDEOS: 'Receptvideók',
    PRODUCT_ASSOCIATE: 'Termékajánlatunk a recepthez',
    BUDGET_LOW: 'Olcsó',
    BUDGET_HIGH: 'Drága',
    BUDGET_MIDDLE: 'Megfizethető',
    PRINT_RECIPE: 'Recept nyomtatása',
    LET_YOURSELF_SURPRISED: 'Próbálja ki ezt a receptet is!',
    COOK_TIP_TITLE: '',
    ENJOY_MEAL: '',
    FILTER: { LEGEND: 'Legend' }
  },
  PREDIGGO: {
    DIFFICULTY: { EASY: 'Könnyű', MEDIUM: 'Közepes nehézségű', HARD: 'nehéz' }
  },
  COOKIES: {
    TEXT: 'Amennyiben továbblép weboldalunkra, hozzájárul a cookie-k használatához, melyeket technikai okokból, statisztikai analízishez, valamint az internetes forgalom kezelésének céljából használunk, továbbá az adatok megosztásához más webhelyek hirdetőivel, hogy az Ön érdeklődési körének megfelelő promóciós tartalmakat jelenítsenek meg.',
    CLOSE: 'Bezár',
    MORE: 'Tudjon meg többet',
    AGREE_YOUTUBE: 'A videó megtekintéséhez fogadja el a sütiket',
    BUTTON_YOUTUBE: 'Sütik engedélyezése'
  },
  POPUP: { LAUNCH: 'POPUP-OUVERTURE-SITE' },
  FIELD_PLATE: { DISCOVER_VEGETABLE: 'Fedezze fel zöldségválasztékunkat' },
  SEARCH: {
    OUR_RECIPES: 'Receptek',
    OUR_PRODUCTS: 'Termékek',
    OUR_ARTICLES: 'Cikk',
    RECIPES: 'Receptek',
    PRODUCTS: 'Termékek',
    ARTICLES: 'Cikk',
    ALL: 'Minden',
    SORT_BY: 'Az alábbiak szerint rendezve',
    RESULTS: 'eredmények',
    RESULT: 'eredmény',
    PRODUCT_OUT: 'zöldségek',
    APPLY: 'Alkalmaz',
    FILTER: 'Szűrő',
    RECIPE_OUT: 'Receptek az alábbi hozzávalóval:',
    NO_RESULT: '',
    SEE_MORE: 'Receptek',
    DISCOVER_RECIPES: 'Fedezze fel receptjeinket',
    SEE_MORE_OF: 'Összes',
    DELETE: 'töröl'
  },
  RECIPE_LIST: {
    OUR_RECIPES: 'receptek',
    RESULTS: 'eredmények',
    SEE_MORE: 'Még több recept'
  },
  PRODUCT: {
    BUTTON: {
      ACTU: 'Termékújdonságok',
      FOUND: 'Hol található meg ez a termék',
      DISCOVER: 'Fedezze fel a teljes választékot'
    },
    NUTRITIONAL_VALUE: 'tápérték',
    CONDITIONING: 'Kiszerelés ',
    PORTIONS: 'adagok',
    NET_WEIGHT: 'nettó tömeg',
    DRY_WEIGHT: 'töltőtömeg ',
    INGREDIENTS: 'összetevők',
    OUR_RECIPES: 'Fedezze fel receptjeinket',
    FOR: '',
    PER_PORTION: 'adagonként',
    ENERGY: 'kalória',
    FAT: 'zsír',
    SATURATE: 'telített zsír',
    GLUCIDES: 'szénhidrát',
    FIBER: 'rost',
    PROTEINS: 'fehérje',
    SALT: 'só',
    DISCOVER_ALSO: 'Fedezze fel ezt is...',
    MORE_INFORMATION: 'még több információ',
    TRACES: 'nyomokban tartalmazhat',
    CONTAINS: 'Tartalmaz',
    SLIDER: { SHEET: 'Termékleírás ', BUY: '' },
    NUTRISCORE: '',
    TRIMAN: '',
    AGRICONFIANCE: '',
    BIO: 'Bio ',
    PESTICIDE_FREE: '',
    LEAF: '',
    LOCK: '',
    SEE_ALL: 'Mutasson minden terméket',
    LEGISLATION: '',
    PORTION: { SINGULAR: 'adag', PLURAL: 'adagok ' },
    DISCLAIMER: '',
    COOKING: 'Elkészítés ',
    CONSERVATION: 'Hőkezelés '
  },
  FAQ: {
    ON: 'kérdések a',
    ALL: 'Minden',
    SEE_MORE: 'További kérdések',
    TITLE_1: 'Válaszaink kérdéseikre',
    TITLE_2: 'Kifejezetten egy termékkel kapcsolatban van kérdése? ',
    LINK_LABEL: 'Írjon nekünk! '
  },
  FORM: {
    GO_TO_STEP: 'Lépjen tovább',
    STEP: 'lépés',
    RECEIVE_EMAIL_COPY: 'Másolatot kérek emailben',
    SEND: 'Küldés',
    GDPR: 'Az adatkezeléssel kapcsolatos további információk',
    ERROR: 'Minden *-gal jelölt mező kitöltése kötelező',
    SEND_ERROR: 'Hiba lépett fel, kérjük, próbálja újra később',
    NOTICE: 'Az Ön által beküldött kérdés/üzenet kapcsán a Bonduelle, az esettől függően bizonyos egészségügyi adatokat bekérhet Öntől. Az adatok megadása nem kötelező érvényű, Önnek jogában van elállni a válaszadástól.',
    SENDING: 'Űrlap küldése...',
    LOADING: 'Töltés...',
    PRODUCT_INFORMATION_TITLE: 'Termékekkel kapcsolatos kérdések\r\n',
    PRODUCT_INFORMATION: {
      SEO_TITLE: '\nKérdés egy termékkel kapcsolatban  | Bonduelle',
      SEO_DESC: 'Vegye fel a kapcsolatot a Vevőszolgálatunkkal a termékeinkkel kapcsolatban.'
    },
    INFORMATIONS: 'Kérdés',
    MISTER: 'Úr',
    MISS: 'Hölgy',
    NAME: 'Név',
    OUR_NAME: 'Az Ön neve',
    FIRSTNAME: 'Keresztnév',
    OUR_FIRSTNAME: 'Az Ön keresztneve',
    MAIL: 'E-mail cím',
    MAIL_PLH: 'uzenet@gmail.com',
    MAIL_CONFIRM: 'E-mail cím megerősítése',
    MAIL_ERROR: 'Kérjük, érvényes e-mail címet írjon be.',
    MAIL_ERROR_CONFIRM: 'Kérjük, erősítse meg e-mail címét. ',
    ADDRESS: 'Cím',
    STREET_PLH: '',
    STREET: 'Utca',
    ADDRESS_COMPLEMENT: '',
    ADDRESS_COMP_PLH: 'Egyéb információ',
    ZIPCODE: 'Irányítószám',
    CITY: 'Város',
    CITY_PLH: 'Tartózkodási hely',
    PHONE: 'Telefonszám',
    PHONE_PLH: 'Az Ön telefonszáma',
    COUNTRY: 'Ország',
    PRODUCT_INFORMATIONS: 'Termékekkel kapcsolatos kérdések',
    PRODUCT_INFORMATION_DESC: 'Kérjük, adja meg a következő információkat, hogy munkatársaink feldolgozhassák kérését:',
    PRODUCT: {
      DETAIL: {
        DESC: 'Töltse ki a megfelelő mezőket és csatoljon egy fényképet. A fényképeknek tartalmazniuk kell a termék vonalkódját, tételszámát és lejárati dátumát.\n' +
          '\n' +
          'Tisztelt Vásárló!\r\n' +
          '\r\n' +
          'Sajnos jelenleg technikai okok miatt nem lehetséges a termékről készült fénykép(ek) feltöltése a weboldalon keresztül. \r\n' +
          '\r\n' +
          'Kérjük, hogy ezeket a fotókat mindenképpen őrizze meg. A termékkel kapcsolatos panasz elküldése után mihamarabb felvesszük Önnel a kapcsolatot e-mailben, melyre válaszolva csatolva küldje majd el részünkre a fent említett fotókat. \r\n' +
          '\r\n' +
          'Szíves elnézését kérjük, és köszönjük megértésüket!'
      },
      NOTE: {
        TITLE: 'Bonduelle | Kapcsolat | Termékekkel kapcsolatos reklamáció',
        SEO_TITLE: '',
        SEO_DESC: ''
      }
    },
    PRODUCT_RANGE: 'Termék technológia ',
    PRODUCT_NAME: 'Terméknév',
    PRODUCT_NAME_PLH: '\n' +
      'Írja be annak a Bonduelle terméknek a nevét, amelyre a kérdése vonatkozik',
    PRODUCT_INFORMATIONS_DETAIL: 'Részletes termékinformáció ',
    PRODUCT_INFORMATIONS_LABEL: 'Termék vonalkódja ',
    PRODUCT_INFORMATIONS_PLH: 'Az EAN-kód 13 számjegyből áll a termék csomagolásán.',
    PRODUCT_LOT: '\nA termék tételszáma',
    PRODUCT_LOT_INFO: 'A tételek száma L \\ n-vel kezdődik, amelyet egy sor számjegy követ. ',
    PHOTO: 'Fénykép csatolása ',
    EXPIRED_DATE: 'Szavatossági idő ',
    EXPIRED_DATE_ERROR: 'Írja be a dátumot az alábbiak szerint: NN / HH / ÉÉÉÉ',
    EXPIRED_DATE_DESC: 'A szavatossági idő  a csomagolás alján található.',
    PHOTO_MORE: 'Fotók feltöltése',
    MESSAGE: 'Az Ön üzenete *',
    OUR_MESSAGE: 'írja meg üzenetét',
    SALES_OPERATIONS: { TITLE: '', SEO_TITLE: '', SEO_DESC: '' },
    PRESS_SERVICE: '',
    FOOD_SERVICE: 'Bonduelle Food Service Kapcsolat',
    BUSINESS_INFORMATION: '',
    MODAL_INFORMATION: '',
    JOB_OFFER: {
      TITLE: 'Állásajánlattal kapcsolatos kérdések',
      SEO_TITLE: '',
      SEO_DESC: ''
    },
    WEB_SUGGESTION: { TITLE: '', SEO_TITLE: '', SEO_DESC: '' },
    ANOTHER: {
      TITLE: 'Egyéb kérdések',
      SEO_TITLE: 'Bonduelle | Kapcsolat | Egyéb kérdések',
      SEO_DESC: ''
    },
    AMBASSADOR: '',
    CATEGORY: {
      SALAD: '',
      FROZEN: 'Fagyasztott ',
      CAN: 'Konzerv ',
      TAKEAWAY: '',
      VEGETABLE: ''
    }
  },
  CONTACT: {
    TITLE: 'Lépjen kapcsolatba velünk',
    SPECIAL_INFO: '',
    TAKE_CARE: '',
    BY_MAIL: 'Postai úton ',
    BY_PHONE: 'Telefonon',
    BY_SOURDLINE: '',
    MAIL_TEXT: 'Bonduelle Central Europe Kft.<br>2750 Nagykőrös, Ceglédi út 25.<br>1139 Budapest, Fiastyúk u. 4-8.<br>Váci Greens F/2 épület, 5. emelet<br>Marketing osztály',
    PHONE_TEXT: 'Bonduelle Vevőszolgálat elérhetősége:<br>06 80 200 503, mely  hétköznapokon 9.00-17.00 óra között ingyenesen hívható.<br>Ezen kívül kérjük, hagyjon üzenetet, és visszahívjuk.                            ',
    SOURDLINE_TEXT: '',
    BY_DISTRIBUTOR: '',
    BY_DISTRIBUTOR_TEXT: '',
    SUBTITLE: 'Kapcsolatfelvétel oka:',
    AGREEMENT: '',
    READ_MORE: 'Ezenkívül Ön bármikor jogosult arra, hogy a BONDUELLE által kezelt személyes adatai tekintetében:\n' +
      '-a személyes adatokhoz hozzáférjen;\n' +
      '-a személyes adatok helyesbítését kérje;\n' +
      '-a személyes adatok törlését kérje;\n' +
      '-a személyes adatok kezelésének korlátozását kérje;\n' +
      '-gyakorolja adathordozhatósághoz való jogát\n' +
      '-valamint, hogy tiltakozzon a személyes adatai kezelése ellen\n' +
      '\n' +
      'Ön a fenti jogok gyakorlására vonatkozó kérelmét az alábbi elérhetőségekre küldhetje el: E-mail: Dpo_france@bonduelle.com vagy Postai úton a következő címre: \n' +
      'Az adatvédelmi tisztviselő részére\n' +
      "Bonduelle, Rue Nicolas Appert, PO Box 30173, F-59653, Villeneuve D'Ascq. \n" +
      '\n' +
      'Ha szeretne többet megtudni arról, hogy a BONDUELLE milyen módon gyűjti és használja fel az Ön személyes adatait, kattintson ide. \n',
    READ_MORE_LABEL: 'További információk',
    SATISFACTION_TEXT: 'Annak érdekében, hogy Önnel kapcsolatba léphessünk a terméken kért\n' +
      'információk megadása és igény esetén elégedettségi kérdőív küldése\n' +
      'érdekében, adatkezelői minőségünkben feldolgozzuk a megadott\n' +
      'információkat. Kérése feldolgozásának jogalapja a beleegyezés, tekintettel\n' +
      'a kérelmében esetlegesen megadott egészségügyi adatokra. Ezeket az\n' +
      'információkat addig őrizzük meg, ameddig az Ön kérésének\n' +
      'feldolgozásához szükséges, és legfeljebb 5 évig annak igazolására, hogy a\n' +
      'kérelmet feldolgozták, és hogy megfeleljünk jogi kötelezettségeinknek. Az\n' +
      'elégedettségi kérdőív kiküldésének jogalapja is az Ön hozzájárulása.\n' +
      'Ezeket az információkat az elégedettségi kérdőív elküldéséhez szükséges\n' +
      'ideig, de legfeljebb 1 évig megőrizzük. Ha többet szeretne megtudni\n' +
      'személyes adatainak kezeléséről, kérjük, olvassa el <a href="https://www.bonduelle.hu/adatkezelesi-szabalyzat" target="_blank" class="underline">Adatvédelmi\n' +
      'szabályzatunkat</a>.',
    SATISFACTION_CHECKBOX: 'A négyzet bejelölésével beleegyezik abba, hogy kérésének feldolgozása\n' +
      'után elégedettségi kérdőívet kap (egyetlen kérdésből áll). Ez nem\n' +
      'kötelező.',
    CHECKBOX_TEXT: 'A négyzet bejelölésével hozzájárul ahhoz, hogy a Bonduelle feldolgozza az\n' +
      'Ön által az üzenetben megadott személyes egészségügyi adatokat\n' +
      'kizárólag az Ön kérésének megválaszolása céljából. <strong>Ez kötelező a\n' +
      'kérésének feldolgozásához</strong>.',
    PHONE: '',
    CALL_US: 'Hívjon minket',
    TO_WRITE: 'Írjon nekünk',
    PHONE_NUMBER: '3680200503',
    SUCCESS_MESSAGE: 'Köszönjük! Üzenetét csapatunk megkapta, és hamarosan feldolgozza, ami átlagosan 2-3 munkanapot vesz igénybe (hétvégék és ünnepnapok nem értendők bele).<br><br>\n' +
      ' Vagy hívjon minket telefonon hétfőtől péntekig, reggel 9-től este 17 óráig a +3680200503-as, nem emelt díjas telefonszámon.<br><br>\n' +
      ' A szolgáltatatás minőségét ellenőrző monitoring részeként minden kapcsolatfelvétel rögzíthető.',
    PHOTOERROR: {
      MESSAGE: 'Tisztelt Vásárló!\n' +
        '\n' +
        'Sajnos jelenleg technikai okok miatt nem lehetséges a termékről készült fénykép(ek) feltöltése a weboldalon keresztül. \n' +
        '\n' +
        'Kérjük, hogy ezeket a fotókat mindenképpen őrizze meg. A termékkel kapcsolatos panasz elküldése után mihamarabb felvesszük Önnel a kapcsolatot e-mailben, melyre válaszolva csatolva küldje majd el részünkre a fent említett fotókat. \n' +
        '\n' +
        'Szíves elnézését kérjük, és köszönjük megértésüket!'
    }
  },
  GLOBAL: { READ_MORE: '' },
  FLOWBOX: { GENERIC_KEY: '', PRODUCT_KEY: '', RECIPE_KEY: '', HOME_KEY: '' },
  TOKEN: {
    FACEBOOK: '',
    PAGE_FACEBOOK_ID: '',
    INSTAGRAM: '',
    INSTA_ID: '17841407279489116',
    YOUTUBE: '',
    TWITTER: '',
    MAGAZINE: ''
  },
  COOKIE: {
    BANNER: {
      MANDATORY: 'Kötelező',
      ANALYTICS: 'Analitikus',
      MARKETING: 'Marketing',
      SOCIAL_MEDIA: 'Közösségi média',
      ACCEPT: 'Mindet elfogad',
      REJECT: 'Mindet elutasít',
      SET_UP: 'Konfigurálás',
      MANDATORY_DETAILS: 'A szükséges sütik elengedhetetlenek a webhely működéséhez, például a bejelentkezési adatai\n' +
        'tárolásához és a biztonságos kapcsolat fenntartásához. A bonduelle.hu webhely\n' +
        'használatához szükséges sütik a következők:  <ul> \n' +
        '<li> munkamenet-sütik: PHP-alapú alkalmazások által generált sütik. Ez egy általános célú\n' +
        'azonosító, melyek a felhasználói munkamenet változók kezelésére használnak. Általában\n' +
        'véletlenszerűen generált szám, felhasználásának módja webhely specifikus lehet jó példa ezek\n' +
        'felhasználására a felhasználó bejelentkezési állapotának fenntartása az oldalak váltogatása\n' +
        'mellett. </li> </ul>',
      ANALYTICS_DETAILS: 'A funkcionális sütik segítenek minket a webhely optimalizálásában, például statisztikai adatok\n' +
        'gyűjtésében, a közösségi interakciókra vonatkozó preferenciák rögzítésében vagy egy\n' +
        'alkalmazás teljesítményének és stabilitásának mérésében. <br>A bonduelle.hu webhely által\n' +
        'használt funkcionális sütik a következők:\n' +
        '<ul><li> DoubleClick: Ezt a sütit a DoubleClick állítja be (amely a Google tulajdonában van), hogy\n' +
        'létrehozzon egy profilt a látogató érdeklődéséről és releváns hirdetéseket jelenítsen meg más\n' +
        'webhelyeken. A vállalat fő tevékenysége a Google valós idejű, ajánlattételen alapuló\n' +
        'hirdetéseinek kezelése.</li>\n' +
        '<li> Google Analytics: ezek a sütik lehetővé teszik a webhely leglátogatottabb oldalainak és a\n' +
        'webhely forgalmának nyomon követését.</li> \n' +
        '<li>UMT Tracking: ez a süti hozzájárul az UTM-változók kezeléséhez a különböző online\n' +
        'hirdetési kampányok optimális nyomon követése érdekében.</li>. </ul>',
      MARKETING_DETAILS: 'A marketing cookie-k lehetővé teszik számunkra, hogy nyomon kövessük receptekkel és\n' +
        'termékekkel kapcsolatos preferenciáit, hogy mindig az Ön érdeklődésének leginkább\n' +
        'megfelelő termékeket kínálhassuk Önnek.<br> A bonduelle.hu webhely által használt marketing\n' +
        'sütik a következők: <ul> \n' +
        "<li> Közösségi hálózatok: ezek a sütik hozzájárulnak a Bonduelle 'SocialWall' megjelenítéséhez a\n" +
        'webhely nyitóoldalán. Ez lehetővé teszi a Bonduelle által használt főbb közösségi hálózatok\n' +
        'összes legfrissebb bejegyzésének megtekintését.</li>\n' +
        '</ul>',
      V2: 'ok'
    },
    MODAL: {
      TITLE: 'Sütik kezelése',
      TEXT: 'A Bonduelle Csoport elkötelezett amellett, hogy biztosítsa saját webhelyei, és kifejezetten a\n' +
        'Bonduelle.hu webhely felhasználói személyes adatainak védelmét. <br> Annak érdekében, hogy Ön\n' +
        'a lehető legjobb minőségben élvezhesse a webhely által kínált szolgáltatásokat, például\n' +
        'termékleírásokat, új recepteket, valamint annak az Ön preferenciáinak megfelelő\n' +
        'optimalizálását és személyre szabását, a webhely sütiket (cookie-kat) használ. A Bonduelle\n' +
        'Csoport teljes mértékben elkötelezett amellett, hogy a webhely használatakor az Ön\n' +
        'személyes adatainak kezelése során magas szintű védelmet biztosításon, valamint az összes\n' +
        'vonatkozó adatvédelmi szabályt betartsa. Ezért Ön bármikor aktiválhatja vagy kikapcsolhatja a\n' +
        'sütiket.',
      CONFIG: 'Konfigurálás',
      SAVE: 'Mentés '
    }
  },
  LINK: { JOB_OFFERS: 'https://jobs.bonduelle.com/?locale=hu_HU' },
  CLIC2BUY: { INFOS: '' }
};
