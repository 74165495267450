/* tslint:disable */export const se_sv = {
  '404': {
    TITLE: 'Hoppsan...',
    PAGENOTFOUND: 'Sidan finns inte',
    BUTTON: 'Tillbaka till hemsidan',
    RECIPES: 'Se våra recept'
  },
  HOME: {
    SHOWCASE: 'Recept',
    CATCH_PHRASE: "Today's surprising vegetables, \nfor a better tomorrow",
    SHOWCASE_SEE_ALL: 'Se alla recept',
    MAGAZINE: { TITLE: 'Utvalda artiklar', BUTTON: 'Läs våra nyheter' },
    OUR_VEGETABLES: 'Våra grönsaker',
    SOCIAL_WALL: 'Dela din passion för grönsaker med oss',
    FAQ_TITLE: 'VANLIGA FRÅGOR',
    FAQ_SEE_MORE: 'Läs mer',
    FLOWBOX: ''
  },
  SELECT: { DEFAULT_VALUE: 'Rekommenderade' },
  MENU: {
    CONTACT_US: 'Kontakta oss',
    MY_ACCOUNT: 'Mitt konto',
    GOOD_DEALS: 'VANLIGA FRÅGOR',
    NEWSLETTER: 'Bonduelle nyhetsbrev'
  },
  FOOTER: {
    LINK: {
      BONDUELLE: 'Bonduelle.se',
      CREDITS: 'Credits',
      LEGAL_NOTICE: 'Juridiskt meddelande',
      TERMS_OF_SALES: 'Allmänna försäljningsvillkor',
      COOKIES: 'Cookies',
      SITEMAP: 'Sitemap',
      PRIVACY_POLICY: 'Extern dataskyddspolicy',
      ALL_RIGHTS_RESERVED: '2021 © Med ensamrätt'
    },
    HISTORY: {
      TITLE: 'Bonduelle – sedan 1853',
      TEXT: 'Vi är ett franskt, familjeägt företag som har rötter ända tillbaka till 1853. Så vi vet en del om hur man skördar och packar grönsaker så att de bevarar alla sina nyttigheter. Vi på Bonduelle vill att grönsaker ska vara en viktig del av den framtida kosten. Vi har också fokus på matsvinn, varför den största delen av vårt sortiment säljs i små förpackninga. På så sätt tror vi att vi kan tillgodose både de mindre hushållens behov och minska mängden avfall.'
    },
    NEWSLETTER: {
      TITLE: 'Bonduelle Community',
      TEXT: 'Jag vill gärna få nyhetsbrevet "Allt om grönsaker" från Bonduelle (nyheter, kampanjer, tävlingar, artiklar osv.)',
      PLACEHOLDER: 'Din e-postadress',
      GDPR_LINK: 'Mer om hantering av dina data'
    }
  },
  PRODUCTS: 'Produkter',
  MAGAZINE: {
    HOME: 'Välkommen till våra nyheter',
    CATCH_PHRASE: '',
    MENU: { BONDUELLE_LINK: 'Gå tillbaka' },
    SHOWCASE: 'Utvalda artiklar',
    PREVIOUS_ARTICLES: 'Tidigare artiklar',
    PREVIOUS_ARTICLES_SEE_ALL: 'Se alla våra artiklar',
    PREVIOUS_EDITIONS: 'Våra tidigare utgåvor',
    PREVIOUS_EDITIONS_SEE_ALL: 'Se fler utgåvor',
    PREVIOUS_ARTICLES_BY_CATEGORY: 'Tidigare artiklar per kategori',
    RUBRIC: {
      SEE_ALL: 'Se alla recept',
      SEE_ALL_ARTICLES: 'Se alla artiklar',
      ON: 'artiklar av'
    },
    RUBRIC_COOK: { ON: 'recept av' },
    ARTICLE: { JOIN_US: 'Följ oss', READ_ALSO: 'Läs även:' },
    RECIPE: {
      INGREDIENTS: 'Ingredienser:',
      INGREDIENTS_LABEL_DESC: 'till {{value}} personer',
      INGREDIENTS_LABEL_ONE_DESC: 'för {{value}} person',
      INGREDIENTS_LABEL: 'Ingredienser för {{value}} personer',
      INGREDIENTS_LABEL_ONE: 'Ingredienser för {{value}} person',
      PEOPLES: '{{value}} personer',
      PEOPLE: '{{value}} person',
      DIFFICULTY: { EASY: 'Lätt', MEDIUM: 'Medium', HARD: 'Svårt' },
      COST: { CHEAP: 'Billigt', EXPENSIVE: 'Dyrt' },
      TIMING: { MIN: 'min.', HOURS: 'timmar' },
      STEP: 'Steg',
      DISCOVER_ALSO: 'Se även:'
    }
  },
  ARTICLE: { DISCOVER_ALSO: 'Läs även:', INGREDIENTS: 'Ingredienser' },
  SF: {
    SLIDE: { DISCOVER: 'Upptäck' },
    SEARCH: { PLACEHOLDER: 'Sökning', ALL: 'Allt' },
    NEWSLETTER: {
      BTN_LABEL: 'Registrera dig',
      PLACEHOLDER: 'Din e-postadress',
      ERROR_MESSAGE: 'Ogiltig e-postadress',
      ALREADY_SUBSCRIBED: 'Du är redan registrerad',
      GDPR: {
        TXT: 'Ytterligare information om hantering av dina data',
        LINK: '/ekstern-databeskyttelsespolitik'
      }
    },
    MONTHLY_FOLDER: { TITLE: 'Månatliga nyheter' },
    MINI_NEWSLETTER: {
      PLACEHOLDER: 'Din e-postadress',
      GDPR: {
        TXT: 'Ytterligare information om hantering av dina data',
        LINK: '/ekstern-databeskyttelsespolitik'
      }
    },
    SOCIALITEM: { SHARE: 'dela', COMMENT: 'kommentera', BONDUELLE: 'Bonduelle' },
    SEARCHRECIPE: { DISCOVER_ALL: 'Sök bland alla våra recept!' },
    FORM: {
      ERROR: 'Ogiltigt fält',
      PHOTO: { ADD: 'Bifoga bild', DELETE: 'Radera bilden', SEE: 'Visa' }
    }
  },
  CORPORATE: {
    COMPANY_STORY: 'Vår historia',
    CSR_COMMITMENT: 'Vårt engagemang',
    VEGETABLE_INNOVATIONS: 'Konserver & innovationer',
    NEWS: 'alla artiklar',
    SEE_MORE: 'Visa fler artiklar',
    ON: 'artiklar av'
  },
  NEWSLETTER: {
    TITLE: 'Anmäl dig till nyhetsbrevet',
    SUBTITLE: 'Bra idéer och inspiration till din matlagning!'
  },
  ROUTES: {
    products: 'produkter',
    news: 'senaste-nytt',
    'since-1853': 'sedan-1853',
    'field-plate': 'marken-bordet',
    'company-story': 'var-historia',
    'csr-commitments': 'vart-atagande',
    'vegetal-innovations': 'konserv-innovationer',
    magazine: 'nyheter',
    all: 'visa-allt',
    article: 'artikel',
    cook: 'matlagning',
    cultivate: 'odling',
    explore: 'utforska',
    recipe: 'receptvarlden',
    recipes: 'recept',
    'recipes-home': 'recept-hem',
    r: 'r',
    credits: 'credits-se',
    'legal-notice': 'juridiskt-meddelande',
    'terms-of-sales': 'allmanna-forsaljningsvillkor',
    cookies: 'extern-dataskyddspolicy',
    sitemap: 'sitemap',
    'privacy-policy': 'extern-dataskyddspolicy',
    'all-rights-reserved': 'alla-rattigheter-forbehalles',
    search: 'sok',
    'articles-search': 'artikel',
    vegetables: 'produkter',
    p: 'p',
    contact: 'kontakta-oss',
    'info-product': 'produktinformation',
    'note-product': 'produktreklamation',
    suggestion: 'forslag',
    'other-request': 'produktinformation',
    faq: 'vanliga-fragor',
    'all-themes': 'alla',
    'products-search': 'produkt-sokning',
    recipesearch: 'recept-sokning',
    'product-news': 'nyheter',
    'good-deals': 'bra-erbjudanden',
    'not-found': 'hittades-ej',
    'menu-page': 'meny-sida',
    'menu-one': 'meny-ett',
    'menu-two': 'menu-tva',
    'menu-three': 'meny-tre',
    'menu-four': 'meny-fyra',
    'menu-external': '',
    'theme-pasta-rice': 'tema-pasta-ris',
    'theme-bowls': 'tema-bowls',
    'theme-soup': 'tema-soppor',
    'theme-snack': 'tema-snacks',
    'theme-sauce': 'tema-saser',
    'theme-unique-plate': '',
    'theme-over': 'tema-ovriga-ratter',
    'theme-legumin': 'tema-baljvaxter',
    'theme-ingredients-recipes': 'tema-ingredienser-recept',
    'theme-cook-plate': ''
  },
  BREAD: {
    products: 'produkter',
    news: 'senaste nytt',
    'since-1853': 'sedan-1853',
    'field-plate': 'marken-bordet',
    'company-story': 'var historia',
    'csr-commitments': 'vart-atagande',
    'vegetal-innovations': 'konserv innovationer',
    magazine: 'nyheter',
    all: 'visa-allt',
    article: 'artikel',
    cook: 'matlagning',
    cultivate: 'odling',
    explore: 'utforska',
    recipe: 'receptvarlden',
    recipes: 'recept',
    'recipes-home': 'recept hem',
    'articles-search': 'artikel',
    search: 'sok',
    vegetables: 'produkter',
    contact: 'kontakta-oss',
    'info-product': 'produktinformation',
    'note-product': 'produktreklamation',
    suggestion: 'forslag',
    'other-request': 'produktinformation',
    faq: 'vanliga fragor',
    'all-themes': 'alla',
    'products-search': 'produkt-sokning',
    recipesearch: 'recept-sokning',
    'product-news': 'nyheter-produkter',
    'good-deals': 'bra-erbjudanden',
    'not-found': 'hittades-ej',
    'menu-page': 'meny-sida',
    'menu-one': 'meny-ett',
    'menu-two': 'menu-tva',
    'menu-three': 'meny-tre',
    'menu-four': 'meny-fyra'
  },
  MENU_PAGE: {
    TITLE1: 'TITEL 1',
    TITLE2: 'TITEL 2',
    TITLE3: 'TITEL 3',
    TITLE4: 'TITEL 4',
    EXTERNAL: ''
  },
  TECHNICAL: { MENU_PAGE: 'jobb_annonser' },
  SEO: {
    TITLE: {
      MENU_ONE: 'menu one Title',
      MENU_TWO: 'menu two Title',
      MENU_THREE: 'menu three Title',
      MENU_FOUR: 'menu four Title',
      HOME: 'Bonduelle: hitta inspiration i vårt grönsaksuniversum',
      SEARCH: {
        MAIN: '{{value}} | Bonduelle',
        RECIPES: '{{value}} sortera efter recept | Bonduelle',
        VEGETABLES: '{{value}} sortera efter grönsaker | Bonduelle',
        ARTICLES: '{{value}} sortera efter artikel | Bonduelle'
      },
      CONTACT: 'Kundservice | Bonduelle',
      RECIPES: 'Recept: soppor, sallader, huvudrätter … | Bonduelle\n',
      RECIPE_HOME: 'Receptuniversum | Bonduelle',
      RECIPE: 'Recept {{value}} | Bonduelle',
      PRODUCTS: 'Konserver  | Bonduelle',
      NEWS: 'Upptäck grönsakernas potential  | Bonduelle',
      CORPORATE: {
        NEWS: 'Nya artiklar om grönsaker och matlagning  | Bonduelle',
        COMPANY_STORY: 'Lär känna Bonduelle och dess historia | Bonduelle',
        CSR_COMMITMENTS: 'Läs om våra värderingar och principer  | Bonduelle',
        VEGETAL_INNOVATIONS: 'Läs om våra innovationer | Bonduelle',
        FIELD_PLATE: 'Grönsaker: de bästa grönsakerna till de bästa recepten | Bonduelle',
        ARTICLE: '{{value}} | Bonduelle',
        VEGETABLE: 'Lär dig mer om {{value}} | Bonduelle'
      },
      PRODUCT: '{{value}}: Grönsaker från Bonduelle',
      MAGAZINE: {
        NEWS: 'Artiklar om grönsakernas fördelar, recept och nyheter | Bonduelle',
        ARTICLE: 'Bonduelle',
        COOK: 'Snabba och enkla recept med grönsaker | Bonduelle',
        GROW: 'Odla dina egna grönsaker | Bonduelle',
        EXPLORE: 'Lär dig något nytt om grönsaker| Bonduelle',
        RECIPE: 'Recept {{value}} | Bonduelle'
      },
      FAQ: 'FAQ: vanliga frågor och svar | Bonduelle',
      NOTFOUND: '404-fel. Försök igen senare | Bonduelle',
      RECIPES_ALL: 'Våra recept',
      RECIPETYPE: 'Recept: {{value}}',
      RECIPESEASON: 'Årstid: {{value}}',
      RECIPEPREPARATION: 'Recept: tillagningstid {{value}}',
      RECIPEDIFFICULTY: 'Enkla recept med grönsaker efter {{value}}.',
      RECIPEVEGETABLE: 'Enkla recept med {{value}}',
      RECIPECOST: 'Recept och grönsaker {{value}}',
      RECIPEDISH: 'Recept med grönsaker till {{value}}',
      RECIPEDESC: 'Recept och grönsaker {{value}}',
      '2RECIPEFACETS': 'Recept {{value}}',
      '4RECIPEFACETS': 'Recept {{value}}',
      PRODUCTSEASON: 'Recept med grönsaker som passar till: {{value}}',
      PRODUCTTECHNO: 'Enkla recept med grönsaker {{value}}',
      PRODUCTVEGETABLE: 'Enkla recept med {{value}}',
      PRODUCTRANGE: 'Enkla recept med {{value}}',
      THEME_ALL: 'Grönsakskonserver',
      THEME_STARTER: 'Receptidéer till dina förrätter',
      THEME_SALAD: 'Receptidéer till dina sallader',
      THEME_CAN: 'test2',
      THEME_FROZEN: 'test5',
      THEME_READY_MEALS: 'test8',
      THEME_BIO: 'test11',
      THEME_VEGETABLES: 'test14',
      THEME_RECIPE: 'Receptidéer för enkla och snabba recept',
      THEME_PLATE: 'Receptidéer till varma och kalla rätter',
      THEME_ACC: 'Receptidéer till tillbehör',
      THEME_SNACK: 'Receptidéer till smårätter och snacks',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': 'Recept med grönsaker {{value}}'
    },
    DESCRIPTION: {
      MENU_ONE: 'menu one description',
      MENU_TWO: 'menu two Description',
      MENU_THREE: 'menu three Description',
      MENU_FOUR: 'menu four Description',
      HOME: 'Upptäck våra produkter och få inspiration till din matlagning.',
      SEARCH: {
        MAIN: 'Upptäck våra recept och produkter för {{value}}. Enkla och goda recept med färdiggrönsaker.',
        RECIPES: 'Alla våra {{value}} sorterade efter recept. Enkla och goda recept med grönsaker.',
        VEGETABLES: 'Alla våra {{value}} sorterade efter grönsak. Enkla och goda recept med grönsaker.',
        ARTICLES: 'Alla våra {{value}} sorterade efter artikel. Enkla och goda recept med grönsaker.'
      },
      CONTACT: 'Har du en fråga som rör någon av våra produkter? Välkommen att kontakta vår kundtjänst.',
      RECIPES: 'Upptäck våra goda grönsaksbaserade recept. Gläd dina nära och kära med läckra och snabba recept, tillagade med färdiggrönsaker.',
      RECIPE_HOME: 'Upptäck läckra grönsaksbaserade recept till årets högtider. Gläd dina nära och kära med läckra och snabba recept, tillagade med färdiggrönsaker.',
      RECIPE: '',
      PRODUCTS: 'Vi erbjuder ett sortiment av grönsaker som du kan använda för att laga goda och enkla recept.',
      NEWS: 'Läs mer om hur du lagar grönsaker på läckra sätt. Enkla recept med färdiggrönsaker.',
      CORPORATE: {
        NEWS: 'De senaste nyheterna från vår grönsaksvärld.',
        COMPANY_STORY: 'Vill du veta mer om vårt grönsaksuniversum och vår historia? Läs mer om det på vår blogg.',
        CSR_COMMITMENTS: 'Vill du veta mer om hur vi odlar våra grönsaker, var de kommer ifrån och hur vi respekterar miljön? Du hittar svar på dina frågor här.',
        VEGETAL_INNOVATIONS: 'Vi tänker mycket på växtbaserad innovation. T.ex. på hur man kan odla grönsaker på ett miljövänligt sätt och som resulterar i mindre spill. Enkla och goda recept. Färdiggrönsaker.',
        VEGETABLE: '',
        FIELD_PLATE: 'Upptäck våra goda recept.',
        ARTICLE: ''
      },
      PRODUCT: '{{value}} för tillagning av läckra recept som du kan glädja dina nära och kära med. Enkla och goda recept med färdiggrönsaker.',
      MAGAZINE: {
        NEWS: 'Alla de senaste nyheterna om grönsaker och grönsaksmat. Enkla och goda recept med färdiggrönsaker.',
        ARTICLE: '',
        COOK: 'Våra läckra recept får dina gäster att känna sig som lyckans potatisar. Se dem här på vår hemsida. Enkla och goda recept med färdiggrönsaker.',
        GROW: 'Vill du odla egna grönsaker? Läs våra råd och tips om odling och skötsel av grönsaker.',
        EXPLORE: 'Få inspiration till roliga recept som du kan använda för att introducera nya grönsaker för dina barn.',
        RECIPE: ''
      },
      FAQ: 'Har du en fråga? Här har vi samlat de vanligaste frågorna från våra kunder.',
      PAGE: 'sida',
      RECIPETYPE: 'Upptäck våra recept {{value}} och lär dig hur du tillagar grönsaker på bästa sätt.',
      RECIPESEASON: 'Upptäck våra recept och lär dig hur man tillagar grönsaker som {{value}}.',
      RECIPEPREPARATION: 'Upptäck våra recept och lär dig hur man tillagar grönsaker på {{value}}.',
      RECIPEDIFFICULTY: 'Upptäck våra recept och lär dig hur man tillagar grönsaker {{value}}.',
      RECIPEVEGETABLE: 'Upptäck våra recept med {{value}} och lär dig hur man tillagar grönsaker på bästa sätt.',
      RECIPECOST: 'Upptäck våra recept och lär dig hur man tillagar grönsaker på bäst sätt till {{value}}.',
      RECIPEDISH: 'Upptäck våra recept och lär dig hur man tillagar grönsaker på bästa sätt för {{value}} personer.',
      RECIPEDESC: 'Upptäck våra recept och lär dig hur man tillagar grönsaker på bästa sätt som {{value}}.',
      '2RECIPEFACETS': 'Upptäck våra recept och lär dig hur man tillagar grönsaker på bästa sätt som {{value}}.',
      '4RECIPEFACETS': 'Upptäck våra recept och lär dig hur man tillagar grönsaker på bästa sätt {{value}}.',
      PRODUCTSEASON: 'Vi erbjuder ett sortiment av grönsaker som du kan använda för att laga goda och enkla recept på {{value}}',
      PRODUCTTECHNO: 'Vi erbjuder ett sortiment av grönsaker som du kan använda för att laga goda och enkla recept {{value}}.',
      PRODUCTVEGETABLE: 'Vi erbjuder ett sortiment av grönsaker som du kan använda för att laga goda och enkla recept med {{value}}.',
      PRODUCTRANGE: 'Vi erbjuder ett sortiment av grönsaker som du kan använda för att laga goda och enkla recept med {{value}}.',
      THEME_ALL: 'Vi erbjuder ett sortiment av grönsaker som du kan använda för att laga goda och enkla recept.',
      THEME_STARTER: 'Söker du inspiration till en förrätt? Hos oss hittar du ett stort urval av goda recept och mycket mer.',
      THEME_SALAD: 'Söker du inspiration till en god och fräsch sallad? Hos oss hittar du ett stort urval av goda recept och mycket mer.',
      THEME_CAN: 'test3',
      THEME_FROZEN: 'test6',
      THEME_READY_MEALS: 'test9',
      THEME_BIO: 'test12',
      THEME_VEGETABLES: 'test15',
      THEME_RECIPE: 'Grönsaker som du kan använda för att laga enkla och snabba recept.',
      THEME_PLATE: 'Söker du inspiration till en balanserad måltid? Hos oss hittar du ett stort urval av goda recept på varma och kalla rätter.',
      THEME_ACC: 'Grönsaker som du kan använda för att tillaga enkla och snabba tillbehör.',
      THEME_SNACK: 'Söker du inspiration till balanserade snacks? Hos oss hittar du ett stort urval av goda recept på läckra smårätter som passar bra som snacks.',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': 'Vi erbjuder ett sortiment av grönsaker som du kan använda för att laga goda, enkla och balanserade recept. {{value}}',
      NOTFOUND: 'Den önskade sidan kunde inte hittas. Gör en ny sökning eller försök igen senare.'
    },
    PIPE: { SOCIETY: '| Bonduelle' },
    THEME_ALL: 'Alla',
    THEME_STARTER: 'Förrätter',
    THEME_SALAD: 'allt-till-mina-sallader',
    THEME_CAN: 'test1',
    THEME_FROZEN: 'test4',
    THEME_READY_MEALS: 'test7',
    THEME_BIO: 'test10',
    THEME_VEGETABLES: 'test13',
    THEME_RECIPE: 'recept-ingredienser',
    THEME_PLATE: 'Huvudrätter',
    THEME_ACC: 'tillbehör',
    THEME_SNACK: 'snacks'
  },
  VEGETABLE: {
    DISCOVER_VEGETABLE: 'Läs mer om våra grönsaker',
    OURS_RECIPES: 'Våra recept',
    DISCOVER_ALSO: 'Se även:',
    SEE_ALL: 'Se alla våra grönsaker',
    ON: 'grönsaker av'
  },
  RECIPE: {
    'INGREDIENTS_LABEL ': 'Ingredienser för {{value}} personer',
    PEOPLES: '{{value}} personer',
    DIFFICULTY: { EASY: 'Lätt', MEDIUM: 'Medium', HARD: 'Svårt' },
    COST: { CHEAP: 'Billigt', EXPENSIVE: 'Dyrt' },
    TIMING: { MIN: 'min:', HOURS: 'timmar' },
    STEP: 'Steg',
    DISCOVER_ALSO: 'Se även:',
    HOME_SEE_MORE: 'Se fler recept',
    SHOWCASE: 'Toppen',
    HOME_BOOK: 'Våra recept',
    MIN_MORE: 'min. och +',
    HOUR_MORE: 'timmar och +',
    FILTER_BY_CRITERIA: 'Filtrera efter',
    SORT_BY: 'Sortera efter',
    BEST_RATED: 'Bäst betyg',
    MOST_CONSULTED: 'Mest populära',
    MEMBERS_RECIPES: 'Medlemmarnas recept',
    CRITERIA: 'Nyheter',
    DISCOVER: { RECIPES: 'Se våra recept' },
    DISCOVER_RECIPE: 'Se receptet',
    INSTRUCTIONS: 'Så gör du',
    DEFAULT_TAG: 'Receptsida',
    MOST_RECENT: 'Senaste',
    RECIPE_VIDEOS: 'receptfilmer',
    PRODUCT_ASSOCIATE: 'Tillaga detta recept med:',
    BUDGET_LOW: 'Billigt',
    BUDGET_HIGH: 'Hög',
    BUDGET_MIDDLE: 'Medium',
    PRINT_RECIPE: 'skriv ut recept',
    LET_YOURSELF_SURPRISED: 'Behöver du mer inspiration?',
    COOK_TIP_TITLE: '',
    ENJOY_MEAL: 'Smaklig måltid!',
    FILTER: { LEGEND: 'Lekfullt' }
  },
  PREDIGGO: { DIFFICULTY: { EASY: 'Lätt', MEDIUM: 'Medium', HARD: 'Svårt' } },
  COOKIES: {
    TEXT: 'Genom att fortsätta ditt besök på denna webbplats godkänner du att vi använder cookies av tekniska skäl, med hänsyn till statistikanalys och styrning av trafiken på webbplatsen, samt informationsdelning med annonsörer på andra webbplatser, för att kunna erbjuda dig relevant innehåll som motsvarar dina intressen.',
    CLOSE: 'Stäng',
    MORE: 'Läs mer',
    AGREE_YOUTUBE: 'You must accept Youtube cookies to see this video',
    BUTTON_YOUTUBE: 'Accept cookies'
  },
  POPUP: { LAUNCH: 'POPUP-OUVERTURE SITE' },
  FIELD_PLATE: { DISCOVER_VEGETABLE: 'Läs mer om våra grönsaker' },
  SEARCH: {
    OUR_RECIPES: 'Recept',
    OUR_PRODUCTS: 'Produkter',
    OUR_ARTICLES: 'Artiklar',
    RECIPES: 'Recept',
    PRODUCTS: 'Produkter',
    ARTICLES: 'Artiklar',
    ALL: 'Visa alla',
    SORT_BY: 'Sortera efter',
    RESULTS: 'resultat',
    RESULT: 'resultat',
    PRODUCT_OUT: 'produkter av',
    APPLY: 'Använd',
    FILTER: 'Filtrera',
    RECIPE_OUT: 'recept av',
    NO_RESULT: 'Hoppsan... Vi kan inte hitta det du söker',
    SEE_MORE: 'Se mer',
    DISCOVER_RECIPES: 'Se våra recept',
    SEE_MORE_OF: 'Visa fler',
    DELETE: 'radera'
  },
  RECIPE_LIST: {
    OUR_RECIPES: 'Recept',
    RESULTS: 'Resultat',
    SEE_MORE: 'Se fler recept'
  },
  PRODUCT: {
    BUTTON: {
      ACTU: 'produktnyheter',
      FOUND: 'Se var du kan hitta den här produkten',
      DISCOVER: 'Se hela vårt sortiment'
    },
    NUTRITIONAL_VALUE: 'näringsinnehåll',
    CONDITIONING: 'förpackning',
    PORTIONS: 'portioner',
    NET_WEIGHT: 'Nettovikt',
    DRY_WEIGHT: 'Avrunnen vikt',
    INGREDIENTS: 'Ingredienser:',
    OUR_RECIPES: 'Se våra recept',
    FOR: 'per',
    PER_PORTION: 'och per portion av',
    ENERGY: 'Energi',
    FAT: 'Fett',
    SATURATE: 'Mättade fettsyror',
    GLUCIDES: 'Kolhydrat',
    FIBER: 'Kostfiber',
    PROTEINS: 'Protein',
    SALT: 'salt',
    DISCOVER_ALSO: 'Se även:',
    MORE_INFORMATION: 'ytterligare information',
    TRACES: 'Spår av',
    CONTAINS: 'Kan innehålla',
    SLIDER: { SHEET: 'se produkt', BUY: 'köp den här produkten' },
    NUTRISCORE: 'information-nutriscore',
    TRIMAN: '',
    AGRICONFIANCE: '',
    BIO: 'information-eko',
    PESTICIDE_FREE: '',
    LEAF: '',
    LOCK: '',
    SEE_ALL: 'Visa alla produkter',
    LEGISLATION: 'i enlighet med gällande lagstiftning.',
    PORTION: { SINGULAR: 'portion', PLURAL: 'portioner' },
    DISCLAIMER: '',
    COOKING: 'Tillagning',
    CONSERVATION: 'Förvaring'
  },
  FAQ: {
    ON: 'frågor om',
    ALL: 'Alla',
    SEE_MORE: 'se fler frågor',
    TITLE_1: 'FAQ – vanliga frågor',
    TITLE_2: 'Har du en fråga som rör en viss produkt?',
    LINK_LABEL: 'Skriv till oss'
  },
  FORM: {
    GO_TO_STEP: 'Gå till steg',
    STEP: 'Steg',
    RECEIVE_EMAIL_COPY: 'Få en kopia via e-post',
    SEND: 'Skicka',
    GDPR: 'Mer om hantering av dina data',
    ERROR: 'Fält markerade med * är obligatoriska och måste fyllas i',
    SEND_ERROR: 'Ett fel inträffade. Vänligen försök igen senare.',
    NOTICE: 'För att hantera din fil på ett korrekt sätt kan vi, beroende på ärendet, be om dina hälsodata. De är inte obligatoriska och du kan välja att inte föra över dem.',
    SENDING: 'Formuläret håller på att skickas...',
    LOADING: 'Laddar...',
    PRODUCT_INFORMATION_TITLE: 'Frågor om en produkt',
    PRODUCT_INFORMATION: {
      SEO_TITLE: 'Frågor om en produkt | Bonduelle',
      SEO_DESC: 'Kontakta vår kundtjänst för frågor som rör våra produkter.'
    },
    INFORMATIONS: 'Dina uppgifter',
    MISTER: '',
    MISS: '',
    NAME: 'Efternamn',
    OUR_NAME: 'Ditt efternamn',
    FIRSTNAME: 'Förnamn',
    OUR_FIRSTNAME: 'Ditt förnamn',
    MAIL: 'Din e-postadress',
    MAIL_PLH: '',
    MAIL_CONFIRM: 'Bekräfta din e-postadress',
    MAIL_ERROR: 'Ange en giltig e-postadress',
    MAIL_ERROR_CONFIRM: 'Vänligen bekräfta din e-postadress',
    ADDRESS: 'Din adress',
    STREET_PLH: '',
    STREET: 'Gatuadress',
    ADDRESS_COMPLEMENT: 'Adress 2',
    ADDRESS_COMP_PLH: 'Adress 3',
    ZIPCODE: 'Postnummer',
    CITY: 'Ort',
    CITY_PLH: 'Ort',
    PHONE: 'Telefonnummer',
    PHONE_PLH: 'Telefonnummer',
    COUNTRY: 'Land',
    PRODUCT_INFORMATIONS: 'Produktinformation',
    PRODUCT_INFORMATION_DESC: 'För att vi ska kunna behandla ditt ärende, vänligen ange följande uppgifter:',
    PRODUCT: {
      DETAIL: {
        DESC: 'I samband med att du upprättar ett ärende kan du fylla i relevanta fält och bifoga en exempelbild. Bilden/Bilderna ska visa produktens streckkod, batchnummer och utgångsdatum.'
      },
      NOTE: {
        TITLE: 'Produktreklamation',
        SEO_TITLE: 'Skicka in en produktreklamation | Bonduelle',
        SEO_DESC: 'Har du en fråga som rör en viss produkt? Kontakta oss via e-post här.'
      }
    },
    PRODUCT_RANGE: 'Produktsortiment',
    PRODUCT_NAME: 'Produktnamn',
    PRODUCT_NAME_PLH: 'Ange namnet på den Bonduelle-produkt som ditt ärende handlar om.',
    PRODUCT_INFORMATIONS_DETAIL: 'Detaljerad information',
    PRODUCT_INFORMATIONS_LABEL: 'Produktens streckkod',
    PRODUCT_INFORMATIONS_PLH: 'Streckkoden består av 13 siffror och du hittar den på förpackningen.',
    PRODUCT_LOT: 'Produktens batchnummer och utgångsdatum',
    PRODUCT_LOT_INFO: 'Batchnumret börjar med ett L < br > följt av ett antal siffror. < br > Du hittar numret på baksidan av förpackningen < br > eller på burkens undersida. < br > < br > Exempelvis: L72450492107: 13.',
    PHOTO: 'Bifoga bild',
    EXPIRED_DATE: 'Utgångsdatum',
    EXPIRED_DATE_ERROR: 'Ange datumet som: dd/mm/åååå',
    EXPIRED_DATE_DESC: 'Utgångsdatumet finns på förpackningen eller på burkens undersida.',
    PHOTO_MORE: 'Övriga bilder',
    MESSAGE: 'Ditt meddelande *',
    OUR_MESSAGE: 'Skriv in ditt meddelande',
    SALES_OPERATIONS: { TITLE: '', SEO_TITLE: '', SEO_DESC: '' },
    PRESS_SERVICE: '',
    FOOD_SERVICE: 'Ta kontakt med vår Food Service-avdelning',
    BUSINESS_INFORMATION: '',
    MODAL_INFORMATION: '',
    JOB_OFFER: { TITLE: '', SEO_TITLE: '', SEO_DESC: '' },
    WEB_SUGGESTION: {
      TITLE: 'Har du förbättringsförslag gällande vår hemsida?',
      SEO_TITLE: 'Förslag gällande hemsidan | Bonduelle',
      SEO_DESC: ''
    },
    ANOTHER: {
      TITLE: 'Fråga om något annat',
      SEO_TITLE: 'Kontakta oss om du har andra frågor eller kommentarer | Bonduelle',
      SEO_DESC: ''
    },
    AMBASSADOR: '',
    CATEGORY: {
      SALAD: 'sallader',
      FROZEN: 'frysvaror',
      CAN: 'konserver',
      TAKEAWAY: 'take away',
      VEGETABLE: ''
    }
  },
  CONTACT: {
    TITLE: 'Hur kan vi hjälpa dig?',
    SPECIAL_INFO: '',
    TAKE_CARE: '',
    BY_MAIL: 'Via post',
    BY_PHONE: 'Via telefon',
    BY_SOURDLINE: '',
    MAIL_TEXT: 'BONDUELLE NORDIC A/S\nLyskær 3CD\nDK - 2730 Herlev',
    PHONE_TEXT: ' +45 70 70 29 75',
    SOURDLINE_TEXT: '',
    BY_DISTRIBUTOR: '',
    BY_DISTRIBUTOR_TEXT: '',
    SUBTITLE: 'Eller kontakta oss via e-post:',
    AGREEMENT: '',
    READ_MORE: '',
    READ_MORE_LABEL: '',
    SATISFACTION_TEXT: 'För att kunna kontakta dig för att förse dig med den information som\n' +
      'efterfrågas om produkten och för att skicka ett tillfredsställelseformulär\n' +
      'om du så önskar, kommer vi att behandla den information som lämnas i\n' +
      'vår egenskap av personuppgiftsansvarig. Den rättsliga grunden för att\n' +
      'behandla din begäran är samtycke på grund av de potentiella\n' +
      'hälsorelaterade uppgifter du kan lämna i din begäran. Denna information\n' +
      'kommer att sparas så länge det är nödvändigt för att behandla din\n' +
      'begäran och i upp till 5 år för att bevisa att din begäran har behandlats\n' +
      'och för att uppfylla våra juridiska skyldigheter. Den rättsliga grunden för\n' +
      'att skicka tillfredsställelseformuläret är också ditt samtycke. Denna\n' +
      'information kommer att sparas under den tid som krävs för att skicka\n' +
      'nöjd-enkäten och upp till högst 1 år. För att ta reda på mer om\n' +
      'behandlingen av dina personuppgifter, se vår <a href="https://www.bonduelle.se/juridiskt-meddelande" target="_blank" class="underline">integritetspolicy</a>.',
    SATISFACTION_CHECKBOX: 'Genom att kryssa i denna ruta samtycker du till att få ett\n' +
      'tillfredsställelseformulär (som består av en enda fråga) när din förfrågan\n' +
      'har behandlats. Det är valfritt.',
    CHECKBOX_TEXT: 'Genom att kryssa i denna ruta samtycker du till att Bonduelle får\n' +
      'behandla de personliga hälsouppgifter du kan lämna i ditt meddelande i\n' +
      'det enda syftet att svara på din begäran. <strong>Det är obligatoriskt för att kunna\n' +
      'behandla din begäran</strong>.',
    PHONE: 'Tel. +45 7070 2975\n',
    CALL_US: 'Ring oss',
    TO_WRITE: 'Skriv till oss',
    PHONE_NUMBER: '4570702975',
    SUCCESS_MESSAGE: 'Tack för din förfrågan. Ditt meddelande kommer att besvaras inom 10 vardagar.',
    PHOTOERROR: { MESSAGE: 'photo error message' }
  },
  GLOBAL: { READ_MORE: '' },
  FLOWBOX: { GENERIC_KEY: '', PRODUCT_KEY: '', RECIPE_KEY: '', HOME_KEY: '' },
  TOKEN: {
    FACEBOOK: '',
    PAGE_FACEBOOK_ID: '',
    INSTAGRAM: '',
    INSTA_ID: '17841411087263218',
    YOUTUBE: '',
    TWITTER: '',
    MAGAZINE: ''
  },
  COOKIE: {
    BANNER: {
      MANDATORY: 'Nödvändig',
      ANALYTICS: 'Analys',
      MARKETING: 'Nödvändig',
      SOCIAL_MEDIA: 'Marknadsföring',
      ACCEPT: 'Godkänna alla',
      REJECT: 'Avvisa allt',
      SET_UP: '\nStäll in preferenser',
      MANDATORY_DETAILS: 'Det finns cookies som är nödvändiga för att webbplatsen ska fungera, till exempel cookies som lagrar dina inloggningsuppgifter och ger dig en säker anslutning.\n' +
        'De cookies som krävs för att använda webbplatsen Bonduelle.fr är: \n' +
        '<ul>\n' +
        '<li>sessionscookies: Cookies som genereras av PHP-baserade applikationer. Detta är en allmän identifierare som används för att hantera sessionsvariabler. Det är normalt ett slumpmässigt genererat nummer. Sättet på vilket det används kan variera beroende på webbplats, men ett exempel är att behålla inloggningsstatusen för en användare när användaren besöker olika sidor. </li>\n' +
        '</ul>',
      ANALYTICS_DETAILS: 'Funktionscookies hjälper oss att optimera webbplatsen, till exempel för att samla in statistik, registrera dina preferenser för sociala interaktioner eller för att mäta prestanda och stabilitet i vår applikation.\n' +
        '<br>\n' +
        'Analyscookies som används av Bonduelle.fr:s webbplats: \n' +
        '<ul>\n' +
        '<li>DoubleClick: Denna cookie ställs in av DoubleClick (ägs av Google) för att skapa en profil för besökarens intressen på webbplatsen och visa relevanta annonser på andra webbplatser. Företagets huvudsakliga verksamhet är utbyte av budgivningsannonser i realtid från Google. </li>\n' +
        '<li>Google Analytics: dessa cookies gör det möjligt att spåra mest besökta sidor och webbplatstrafik. </li>\n' +
        '< li>UTMTracking: denna cookie möjliggör hantering av UTM-variabler för optimal uppföljning av de olika reklamkampanjerna online </li>. </ul>',
      MARKETING_DETAILS: 'Marknadsföringscookies gör det möjligt för oss att spåra dina preferenser för recept och produkter för att alltid kunna erbjuda dig de produkter som bäst matchar dina önskemål.\n' +
        '<br>\n' +
        'De marknadsföringscookies som används av Bonduelle.fr: s webbplats är: \n' +
        '<ul>\n' +
        `<li>Sociala nätverk: dessa cookies gör att Bonduelle 'SocialWall' kan visas på webbplatsen.  Den här väggen gör att du kan se alla de senaste inläggen från de stora sociala nätverken som används av Bonduelle-teamen. </li>  </ul> "`,
      V2: 'ok'
    },
    MODAL: {
      TITLE: '\nCookie-inställningar',
      TEXT: 'Bonduelle-koncernen strävar efter att skydda Personuppgifterna för de som använder Bonduelles webbplatser och då särskilt webbplatsen Bonduelle.fr.  <br>För att du ska kunna använda tjänsterna som finns på webbplatsen, såsom konsultprodukter, nya gourmetrecept, prenumeration på vissa tjänster som våra nyhetsbrev och för att optimera användningen och anpassa innehållet efter din profil, använder webbplatsen cookies. Bonduelle-koncernen har förbundit sig att säkerställa en hög skyddsnivå vad gäller sådana personuppgifter och att följa alla tillämpliga dataskyddsbestämmelser vid behandling av personuppgifter i samband med användningen av Bonduelles webbplats.\n' +
        'Du när som helst aktivera/inaktivera cookies.',
      CONFIG: 'Inställningar',
      SAVE: 'Spara'
    }
  },
  LINK: { JOB_OFFERS: '' },
  CLIC2BUY: { INFOS: '' }
};
