import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionTextComponent } from './components/section-text.component';
import { UtilsModule } from '../utils/utils.module';

@NgModule({
  imports: [
    CommonModule,
    UtilsModule
  ],
  declarations: [SectionTextComponent],
  exports: [SectionTextComponent]
})
export class SectionTextModule { }
