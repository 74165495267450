import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SfUtilsModule } from '@app/sf-lib/lib/core/utils/utils.module';
import { SfSectionVideoModule } from '@app/sf-lib/lib/features/section-video/section-video.module';
import { SfVideoModule } from '@app/sf-lib/lib/features/video/video.module';
import { AgreeConsentModule } from './agree-consent/agree-consent.module';
import { JumbotronComponent } from './jumbotron/jumbotron.component';
import { ResponsiveImageComponent } from './responsive-table/responsive-image.component';
import { ResponsiveTableComponent } from './responsive-table/responsive-table.component';
import { ResponsiveTextComponent } from './responsive-table/responsive-text.component';
import { SectionImageModule } from './section-image/section-image.module';
import { SectionTextModule } from './section-text/section-text.module';
import { SeparatorSimpleComponent } from './separator-simple/separator-simple.component';
import { SeparatorMaxiComponent } from './separator-maxi/separator-maxi.component';
import { UtilsModule } from './utils/utils.module';
import { VideoWithBackgroundComponent } from './video-with-background/video-with-background.component';

const SHARED_DECLARATIONS = [
  JumbotronComponent,
  ResponsiveImageComponent,
  ResponsiveTableComponent,
  ResponsiveTextComponent,
  SeparatorSimpleComponent,
  SeparatorMaxiComponent,
  VideoWithBackgroundComponent,
];

@NgModule({
  declarations: [
    SHARED_DECLARATIONS,
  ],
  imports: [
    CommonModule,
    AgreeConsentModule,
    SectionImageModule,
    SectionTextModule,
    SfSectionVideoModule,
    SfUtilsModule,
    SfVideoModule,
    UtilsModule,
  ],
  providers: [
  ],
  exports: [
    SHARED_DECLARATIONS,
  ]
})
export class SharedModule {
}
