import { InjectionToken } from '@angular/core';
import { SfLang } from '../../models/lang.model';

export const SITE_INFO: InjectionToken<SfSiteInfo[]> = new InjectionToken<SfSiteInfo[]>('SITE_INFO');

export class SfSiteInfo {
  public lang: SfLang;
  public locales: SfLang[];
  public path: string;
  public translationObject: Object;
  public defaultLang: SfLang;
  public origin: string;
  public groupSEE? = false;

  constructor() {
    this.lang = new SfLang();
    this.locales = [];
    this.path = '';
    this.origin = '';
    this.translationObject = {};
    this.defaultLang = new SfLang();
    this.groupSEE = false;
  }
}
