import { Component, Input, Renderer2, ElementRef, ViewChild, HostListener, AfterViewInit, Inject, PLATFORM_ID } from '@angular/core';
import { SfSectionImageIcon } from '../models/section-image-icon.interface';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'sf-section-image-icon',
    templateUrl: './section-image-icon.component.html',
    styleUrls: ['./section-image-icon.component.sass'],
    standalone: false
})
export class SfSectionImageIconComponent implements AfterViewInit {

  @Input() section: SfSectionImageIcon;

  @Input() seoTitle: 'H1'|'H2'|'H3'|'DIV' = 'DIV';

  @ViewChild('content', {static: false}) content: ElementRef;

  displayContent = true;

  constructor(
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
  }

  ngAfterViewInit() {
    this.openCloseContent();
  }

  @HostListener('window:resize')
  onResize() {
    this.openCloseContent();
  }

  showHideContent(display: boolean) {
    this.displayContent = display;
    this.renderer.setStyle(this.content.nativeElement, 'display', display ? 'block' : 'none');
  }

  openCloseContent() {
    if (isPlatformBrowser(this.platformId) && window.innerWidth  < 550) {
      this.showHideContent(false);
    } else {
      this.showHideContent(true);
    }
  }

}
