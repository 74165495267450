import { AppTranslateLoader } from './translate-loader';

/**
 * Factory used for translate module. The loader returned is fetching data from local file in assets.
 * By default file are 'lang' + '.ts' and contain 'lang' constant
 * Exemple : 'fr.ts' or 'en.ts'
 *
 * @export
 * @returns The loader used by TranslateModule
 */
export function AppTranslateLoaderFactory() {
    return new AppTranslateLoader();
}
