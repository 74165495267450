import { SfLink } from '../../link';
import { SfMediaImage } from '../../image';
import { SfBanner } from './banner.interface';
import { SfModel } from '../../../core/networking';

export class SfSimpleBanner extends SfModel implements SfBanner {

  /** Background */
  background: SfMediaImage;

  /** Background for mobile */
  smallbackground: SfMediaImage;

  /** Tag */
  uppertitle?: string;

  /** Title */
  title?: string;

  /** Link */
  link?: SfLink;
}
