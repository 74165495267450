import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppTranslateModule } from './translate/translate.module';
import { LoggerModule } from './logger/logger.module';
import { NetworkingModule } from './networking/networking.module';
import { PrediggoModule } from './prediggo/prediggo.module';
import { BaseComponent } from './base.component';
import { RequestModule } from './request/request.module';
import { SomBreadcrumbModule } from './breadcrumb/breadcrumb.module';
import { TagManagerModule } from './tag-manager/tag-manager.module';
import { SeoService } from './seo.service';

@NgModule({
  imports: [
    CommonModule,
    AppTranslateModule,
    LoggerModule.forRoot(),
    NetworkingModule.forRoot(),
    PrediggoModule.forRoot(),
    RequestModule.forRoot(),
    SomBreadcrumbModule,
    TagManagerModule.forRoot(),
  ],
  declarations: [BaseComponent],
  providers: [
    SeoService,
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
