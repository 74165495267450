import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DidomiService } from '@app/core/didomi/didomi.service';


@Component({
    selector: 'som-agree-consent',
    templateUrl: './agree-consent.component.html',
    styleUrls: ['./agree-consent.component.sass'],
    standalone: false
})
export class AgreeConsentComponent implements OnInit {

  @Input() consentVendor: string;

  @Input() message = 'COOKIES.AGREE_YOUTUBE';

  @Output() valid = new EventEmitter<boolean>();

  public agreeVendor = false;

  constructor(private didomiService: DidomiService) {}

  ngOnInit(): void {
    this.didomiService.getCookieConsent(this.consentVendor).then(consentStatusForPurpose => this.agreeVendor = consentStatusForPurpose)
  }

  public validVendor(): void {
    this.didomiService.validVendor(this.consentVendor, () => this.valid.emit(true));
  }

}
