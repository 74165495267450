import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'sf-headline',
    templateUrl: './headline.component.html',
    styleUrls: ['./headline.component.sass'],
    standalone: false
})
export class SfHeadlineComponent implements OnInit {

  /**
   * Icons to display
   *
   * @memberof HeadlineComponent
   */
  @Input() icons: string[];
  /**
   * Headline
   *
   * @memberof HeadlineComponent
   */
  @Input() title: string;

  /**
   * Is this headline supposed to be the main headline of the page ?
   * (there should be only one main headline per page)
   * @memberof SfHeadlineComponent
   */
  @Input() mainTitle: boolean;

  @Input() seoTitle: 'H1'|'H2'|'H3'|'DIV' = 'DIV';

  constructor() { }

  ngOnInit() {
  }

}
