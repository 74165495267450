import { SfMediaImage } from '../../image';
import { SfItem, isSfItem } from './item.interface';

export interface SfItemIcon extends SfItem {
  /** Icon to display in template */
  icon: SfMediaImage;
}

export function isSfItemIcon(object: Object): object is SfItemIcon {
  return isSfItem(object) &&
  'icon' in object;
}
