import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { RequestPost } from '../models/request-post.model';
import { Observable, of} from 'rxjs';
import { EasiwareResponse, RequestPostResponse } from '../models/request-post-response.model';
import { RequestResponse } from '../models/request-response.model';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class RequestService {

  private readonly EASIWARE_SENDMAIL = '/send/mail-to-easiware';

  constructor(
    private http: HttpClient
  ) { }

  public getData({ table, fields, filters = '' }:
    {
      table: 'Contacts' | 'CustomerRequests' | 'Geography' | 'Salutations' | 'CS_Technologies' | 'CS_Products';
      fields: string;
      filters?: string;
    }):
    Observable<RequestResponse |RequestPostResponse> {

    fields = fields || 'Id';

    const query = {
      fct_name: 'jsGetData',
      params: {
        odSettings: {
          TableXmlTag: table,
          Fields: fields,
          Filter: filters
        }
      }
    };

    return this.http.post(`${environment.apiUrlMultisite}${this.EASIWARE_SENDMAIL}`, this.cleanValue(query)).pipe(
      map((response: EasiwareResponse) => {
        return response? response.data : null
      }),
      catchError(error => {
        console.error("getData EasiwareResponse error", error);
        return of(null);
      })
    );
  }

  public postData(table: 'Contacts' | 'CustomerRequests', body: any, token:string, wine_me?: string): Observable<RequestPostResponse> {
    const query: RequestPost = new RequestPost({
      params: {
        odSettings: {
          TableXmlTag: table,
          Action: 'add'
        },
        odData: body
      },
    }, token, wine_me);

    return this.http.post(`${environment.apiUrlMultisite}${this.EASIWARE_SENDMAIL}`, this.cleanValue(query)).pipe(
      map((response: EasiwareResponse) => {
        return response? response.data : null
      }),
      catchError(error => {
        console.error("postData EasiwareResponse error", error);
        return of(null);
      })
    );
  }

  public editData(table: 'Contacts' | 'CustomerRequests', body: any): Observable<RequestPostResponse> {
    const query: RequestPost = new RequestPost({
      params: {
        odSettings: {
          TableXmlTag: table,
          Action: 'edit'
        },
        odData: body
      }
    });

    return this.http.post(`${environment.apiUrlMultisite}${this.EASIWARE_SENDMAIL}`, this.cleanValue(query)).pipe(
      map((response: EasiwareResponse) => {
        return response? response.data : null
      }),
      catchError(error => {
        console.error("editData EasiwareResponse error", error);
        return of(null);
      })
    );
  }

  private cleanValue(obj: Object): Object {
    if (typeof obj !== 'object') {
      return obj;
    }
    const r = {};

    // Filter obj with known properties
    for (const prop of Object.keys(obj)) {
      if (obj.hasOwnProperty(prop) && obj[prop] !== undefined && obj[prop] !== null && obj[prop] !== '') {
        r[prop] = this.cleanValue(obj[prop]);
      }
    }

    return r;
  }
}
