<div class="sf-banner"
  *ngIf="banner"
  (mouseenter)="hover = true"
  (mouseleave)="hover = false"
  [sfLink]="(showCTA && !noLink && !!banner.link && (!banner.link.label || banner.link.label === '')) ?  banner.link : null"
  [ngClass]="{'sf-banner-clickable': (showCTA && !noLink && !!banner.link && (!banner.link.label || banner.link.label === '')) }">
  <sf-image type="zone_maxi" [sfRollover]="hover && !!banner.link && !noLink" class="sf-global-display-deckstop sf-banner-img" [image]="banner.background"></sf-image>
  <sf-image *ngIf="banner.smallbackground" type="original" [sfRollover]="hover && !!banner.link && !noLink" class="sf-global-display-mobile sf-banner-img" [image]="banner.smallbackground"></sf-image>
  <sf-image *ngIf="!banner.smallbackground" type="ratio_328_410" [sfRollover]="hover && !!banner.link && !noLink" class="sf-global-display-mobile sf-banner-img" [image]="banner.background"></sf-image>

  <div class="sf-banner-container">
    <!-- Content -->
    <div class="sf-banner-content" [ngClass]="{'sf-banner-content-cta': showCTA}">
      <div [ngClass]="{'sf-banner-content-no-label': !banner.uppertitle && ! banner.title && !banner.link?.label }">

        <!-- Icon -->
        <mat-icon *ngIf="banner.iconName" class="sf-banner-icon" [svgIcon]="banner.iconName" (click)="onSelect()"></mat-icon>

        <!-- Uppertitle -->
        <ng-container *ngIf="banner.uppertitle" [ngSwitch]="seoUpperTitle">
          <h1 *ngSwitchCase="'H1'" class="sf-banner-content-label sf-banner-content-label-tag sf-title">{{banner.uppertitle | translate }}</h1>
          <h2 *ngSwitchCase="'H2'" class="sf-banner-content-label sf-banner-content-label-tag sf-title">{{banner.uppertitle | translate }}</h2>
          <h3 *ngSwitchCase="'H3'" class="sf-banner-content-label sf-banner-content-label-tag sf-title">{{banner.uppertitle | translate }}</h3>
          <div *ngSwitchDefault class="sf-banner-content-label sf-banner-content-label-tag sf-title">{{banner.uppertitle | translate }}</div>
        </ng-container>

        <!-- Title -->
        <ng-container *ngIf="banner.title" [ngSwitch]="seoTitle">
          <h1 *ngSwitchCase="'H1'" class="sf-banner-content-label sf-banner-content-label-title sf-title">{{banner.title | translate }}</h1>
          <h2 *ngSwitchCase="'H2'" class="sf-banner-content-label sf-banner-content-label-title sf-title">{{banner.title | translate }}</h2>
          <h3 *ngSwitchCase="'H3'" class="sf-banner-content-label sf-banner-content-label-title sf-title">{{banner.title | translate }}</h3>
          <div *ngSwitchDefault class="sf-banner-content-label sf-banner-content-label-title sf-title">{{banner.title | translate }}</div>
        </ng-container>

        <!-- CTA -->
        <div *ngIf="showCTA && banner.link && banner.link.label"
          (click)="eventService.setEvent(banner.title, banner.link.label)"
          class="sf-banner-content-button"
          [sfLink]="banner.link">
          <button class="sf-button sf-accent-back sf-main-back-hover sf-main-color sf-accent-color-hover">
            {{ banner.link.label  | translate }}
          </button>
        </div>

      </div>
    </div>
  </div>
</div>

