import { Component, OnInit, Input } from '@angular/core';
import { SomSectionText } from '../models/section-text.model';


@Component({
    selector: 'som-section-text',
    templateUrl: './section-text.component.html',
    styleUrls: ['./section-text.component.sass'],
    standalone: false
})
export class SectionTextComponent implements OnInit {

  @Input() section: SomSectionText;

  @Input() seoTitle: 'H1'|'H2'|'H3'|'DIV' = 'DIV';

  constructor() { }

  ngOnInit() {
  }

}
