/**
 * Define here every case of custom local error encounterable in the app
 */
export enum SfErrorEnum {
    undefined = 0,
    no_network = 1,

    // HTTP Errors
    internal_error = 2,
    not_authenticated = 3,
    not_authorized = 4,
    not_found = 5,
    bad_request = 6,
    teapot = 7,

    malformed_json = 8
}
