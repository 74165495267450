import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { timer, Subscription } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { SfSlide } from '../models/slide.interface';

@Injectable()
export class SliderService {

  public slideIndex: number;
  get index(): number {
    return this.slideIndex;
  }
  set index(value: number) {
    if (!this.slides) { return; }
    if (this.slides.length <= 1 || value === 0) {
      this.slideIndex = 0;
    } else {
      let newIndex: number;
      if (value > 0) {
        newIndex = value % this.slides.length;
      } else {
        newIndex = this.slides.length - (Math.abs(value) % this.slides.length);
      }
      this.slideIndex = newIndex;
    }
  }

  public animationDirection: 'left' | 'right' = 'left';

  private slides: SfSlide[];
  private pace: number;

  private timerSubscription: Subscription;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.slides = [];
    this.index = 0;
  }

  public init(slides: SfSlide[], pace: number) {
    this.slides = slides;
    this.pace = pace;
  }

  public start() {
    this.iterate();
  }

  public stop() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  public selectSlide(index: number) {
    this.index = index;
    this.iterate();
  }

  public next() {
    this.animationDirection = 'left';
    this.index = this.index + 1;
    this.iterate();
  }

  public previous() {
    this.animationDirection = 'right';
    this.index = this.index - 1;
    this.iterate();
  }

  private iterate() {
    this.stop();
    if (this.pace > 0 && isPlatformBrowser(this.platformId)) {
      this.timerSubscription = timer(this.pace).subscribe(() => {
        this.next();
      });
    }
  }

  public getAnimationState(i: number): any {
    return {value: this.index === i ? 'show' : 'hide', params: { direction: this.animationDirection}};
  }
}
