<div class="sf-item-stat sf-accent-color" *ngIf="itemStat" [ngStyle]="{ 'background-color': itemStat.color }">
  <sf-image
    *ngIf="itemStat.background || itemStat.cover"
    (click)="eventService.setEvent(itemStat.text && itemStat.text[0] && itemStat.text[0]['value'] ? itemStat.text[0]['value'] : itemStat.text, '')"
    [sfRollover]="rollOver && hover"
    class="sf-item-stat-image"
    [image]="itemStat.cover ? itemStat.cover : itemStat.background"
    [type]="itemStat.crop"
  ></sf-image>
  <sf-image (click)="eventService.setEvent(itemStat.text, '')" *ngIf="itemStat.icon" class="sf-item-stat-icon" [image]="itemStat.icon" fitting="contain" align="left"></sf-image>
  <div class="sf-item-stat-content">
    <p
      *ngIf="itemStat.text"
      class="sf-item-stat-content-paragraph sf-text"
      [ngClass]="{'sf-item-stat-content-il' : siteInfo?.lang?.code ==='he'}"
      [innerHTML]="itemStat.text[0]['value'] ? itemStat.text[0]['value'] : itemStat.text"
    ></p>
  </div>
</div>
