import { SfLink } from '../../link';
import { SfMediaImage } from '../../image';
import { SfModel } from '../../../core/networking';

export interface SfBanner extends SfModel {

  /** Background */
  background: SfMediaImage;

  /** Background for mobile */
  smallbackground: SfMediaImage;

  /** Icon */
  iconName?: string;

  /** Tag */
  uppertitle?: string;

  /** Title */
  title?: string;

  /** Link */
  link?: SfLink;
}
