import { Component, OnInit } from '@angular/core';
import { SfFormHint } from '../../models/form-hint.interface';
import { SfPopoverRef } from '../../services/popover-ref';

@Component({
    selector: 'sf-form-hint',
    templateUrl: './hint.component.html',
    styleUrls: ['./hint.component.sass'],
    standalone: false
})
export class SfHintComponent implements OnInit {

  public hint: SfFormHint;

  constructor(
    private popoverRef: SfPopoverRef
  ) {
  }

  ngOnInit() {
    if (this.popoverRef && this.popoverRef.data) {
      this.hint = this.popoverRef.data.hint;
    }
  }

  close() {
    this.popoverRef.close(true);
  }

}
