import { SfMediaImage } from '../../image';
import { SfSection } from '../../../core/models/section.interface';
import { SfLink } from '../../link';

export interface SfSectionImage extends SfSection {

  /** Text of the section. May contain HTML tags */
  text: string;

  /** Image to display in the section */
  image: SfMediaImage;

  /** Position of the image in the section ('left'|'right') */
  position: 'left'|'right';

  /** link in section */
  link?: SfLink;

}
