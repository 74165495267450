/* tslint:disable */export const ba_bs = {
  '404': {
    TITLE: 'Ups...',
    PAGENOTFOUND: 'Stranica ne postoji',
    BUTTON: 'Nazad na početnu stranicu',
    RECIPES: 'Otkrijte naše recepte'
  },
  HOME: {
    SHOWCASE: 'Recepti za kuhanje',
    CATCH_PHRASE: '',
    SHOWCASE_SEE_ALL: 'Pogledajte sve recepte',
    MAGAZINE: { TITLE: 'VRH', BUTTON: 'Otkrijte legume' },
    OUR_VEGETABLES: 'Naše povrće',
    SOCIAL_WALL: 'Zajedno, podijelimo strast prema biljkama',
    FAQ_TITLE: 'Vaša pitanja',
    FAQ_SEE_MORE: 'Vidi još',
    FLOWBOX: 'Podijelite svoje gurmanske trenutke sa #mojbonduelle'
  },
  SELECT: { DEFAULT_VALUE: 'Bonduelle poklon' },
  MENU: {
    CONTACT_US: 'Kontaktirajte nas',
    MY_ACCOUNT: 'Moj nalog',
    GOOD_DEALS: 'Dobre ponude',
    NEWSLETTER: 'Bilten Bonduelle'
  },
  FOOTER: {
    LINK: {
      BONDUELLE: 'Bonduelle.com',
      CREDITS: 'Zasluge',
      LEGAL_NOTICE: 'Pravno obavještenje',
      TERMS_OF_SALES: 'Uslovi prodaje',
      COOKIES: 'Kolačići',
      SITEMAP: 'Mapa sajta',
      PRIVACY_POLICY: 'Politika privatnosti',
      ALL_RIGHTS_RESERVED: '2018 © Sva prava zadržana'
    },
    HISTORY: {
      TITLE: 'Bonduelle, od 1853.',
      TEXT: 'Bonduelle je porodična kompanija koja već 7 generacija radi na razvoju poljoprivredne proizvodnje poštujući zemlju i ljude. Mi podržavamo učinkovitu, inteligentnu i jedinstvenu agroekologiju, odlučno gledajući u budućnost i uvodeći inovacije svaki dan od polja do tanjura kako bismo stvorili bolju budućnost kroz biljnu hranu.'
    },
    NEWSLETTER: {
      TITLE: 'Zajednica Bonduelle',
      TEXT: 'Prihvatam primanje biltena « Sve o povrću od Bonduellea » (vijesti, promocije, nagradne igre, članci...)',
      PLACEHOLDER: 'Vaš e-mail',
      GDPR_LINK: 'Više o upravljanju vašim podacima'
    }
  },
  PRODUCTS: 'Proizvodi',
  MAGAZINE: {
    HOME: 'Dobrodošli u časopis',
    CATCH_PHRASE: 'ogulite vijesti o povrću s Bonduelleom !',
    MENU: { BONDUELLE_LINK: 'Idite na bonduelle.ba' },
    SHOWCASE: 'Izlog',
    PREVIOUS_ARTICLES: 'Prethodni članci',
    PREVIOUS_ARTICLES_SEE_ALL: 'Pogledajte sve naše članke',
    PREVIOUS_EDITIONS: 'Naša prethodna izdanja',
    PREVIOUS_EDITIONS_SEE_ALL: 'Vidi više izdanja',
    PREVIOUS_ARTICLES_BY_CATEGORY: 'Prethodni članci po kategorijama',
    RUBRIC: {
      SEE_ALL: 'Pogledajte sve recepte',
      SEE_ALL_ARTICLES: 'Pogledajte sve članke',
      ON: 'članci o'
    },
    RUBRIC_COOK: { ON: 'recepti za' },
    ARTICLE: { JOIN_US: 'Pridružite nam se', READ_ALSO: 'Čitati također' },
    RECIPE: {
      INGREDIENTS: 'Sastojci',
      INGREDIENTS_LABEL_DESC: 'za {{value}} osoba',
      INGREDIENTS_LABEL_ONE_DESC: 'za {{value}} osobu',
      INGREDIENTS_LABEL: 'Sastojci za {{value}} osoba',
      INGREDIENTS_LABEL_ONE: 'Sastojci za {{value}} osobu',
      PEOPLES: '{{value}} osoba',
      PEOPLE: '{{value}} ljudi',
      DIFFICULTY: { EASY: 'Lako', MEDIUM: 'Srednje', HARD: 'Teško' },
      COST: { CHEAP: 'Jeftino', EXPENSIVE: 'Skupo' },
      TIMING: { MIN: 'minuta', HOURS: 'sati' },
      STEP: 'Korak',
      DISCOVER_ALSO: 'Otkrijte takođe...'
    }
  },
  ARTICLE: { DISCOVER_ALSO: 'Otkrijte takođe...', INGREDIENTS: 'Sastojci' },
  SF: {
    SLIDE: { DISCOVER: 'Otkrijte' },
    SEARCH: { PLACEHOLDER: 'Pretraga', ALL: 'Sve' },
    NEWSLETTER: {
      BTN_LABEL: 'Registriram se',
      PLACEHOLDER: 'Vaš e-mail',
      ERROR_MESSAGE: 'Nevažeća e-mail adresa',
      ALREADY_SUBSCRIBED: 'Već ste pretplaćeni',
      GDPR: {
        TXT: 'Više informacija o upravljanju vašim podacima',
        LINK: '/ kolačići#gdpr'
      }
    },
    MONTHLY_FOLDER: { TITLE: 'Mjesečne vijesti' },
    MINI_NEWSLETTER: {
      PLACEHOLDER: 'Vaš e-mail',
      GDPR: {
        TXT: 'Više informacija o upravljanju vašim podacima',
        LINK: '/ kolačići#gdpr'
      }
    },
    SOCIALITEM: { SHARE: 'podijeli', COMMENT: 'komentar', BONDUELLE: 'Bonduelle' },
    SEARCHRECIPE: { DISCOVER_ALL: 'pretraži po svim receptima!' },
    FORM: {
      ERROR: 'Nevažeće polje',
      PHOTO: { ADD: 'Dodaj sliku', DELETE: 'Izbriši sliku', SEE: 'Vidi' }
    }
  },
  CORPORATE: {
    COMPANY_STORY: 'priča o kompaniji',
    CSR_COMMITMENT: 'dop obaveze',
    VEGETABLE_INNOVATIONS: 'biljne inovacije',
    NEWS: 'sve vijesti',
    SEE_MORE: 'Vidi još članaka',
    ON: 'članci o'
  },
  NEWSLETTER: {
    TITLE: 'Pretplatite se na bilten',
    SUBTITLE: 'Dobre ponude, kulinarska inspiracija i malo kulture!'
  },
  ROUTES: {
    products: 'proizvodi',
    news: 'vijesti',
    'since-1853': 'od-1853',
    'field-plate': 'polje-tanjur',
    'company-story': 'kompaniji-o-kompaniji',
    'csr-commitments': 'dop-obaveze',
    'vegetal-innovations': 'biljne-inovacije',
    magazine: 'casopis',
    all: 'sve',
    article: 'clanak',
    cook: 'kuvanje',
    cultivate: 'uzgajanje',
    explore: 'istrazivanje',
    recipe: 'cekaj-recept',
    recipes: 'recepti',
    'recipes-home': 'recepti-domacinstvo',
    r: 'r',
    credits: 'credits-com',
    'legal-notice': 'pravno-obavjestenje',
    'terms-of-sales': 'uslovi-prodaje',
    cookies: 'kolacici',
    sitemap: 'mapa sajta',
    'privacy-policy': 'politika-privatnosti',
    'all-rights-reserved': 'sva-prava-zadrzana',
    search: 'pretraga',
    'articles-search': 'clanak',
    vegetables: 'povrce',
    p: 'p',
    contact: 'kontakt',
    'info-product': 'informacije-o-proizvodu',
    'note-product': 'napomena-o-proizvodu',
    suggestion: 'sugestija',
    'other-request': 'informacije-o-proizvodu',
    faq: 'cesto-postavljana-pitanja',
    'all-themes': 'sve-teme',
    'products-search': 'proizvodi-pretraga',
    recipesearch: 'recept-pretraga',
    'product-news': 'proizvod-vijesti',
    'good-deals': 'dobre-ponude',
    'not-found': 'nije-pronadjeno',
    'menu-page': 'inspiracija',
    'menu-one': 'zivi-zdravo',
    'menu-two': 'ukusno-se-kuva',
    'menu-three': 'kuhaj-sa-stilom',
    'menu-four': 'budi-kreativan',
    'menu-external': 'jelovnik-eksterni',
    'theme-pasta-rice': '',
    'theme-bowls': '',
    'theme-soup': '',
    'theme-snack': '',
    'theme-sauce': '',
    'theme-unique-plate': '',
    'theme-over': 'tema-kraj',
    'theme-legumin': 'mahunarke',
    'theme-ingredients-recipes': '',
    'theme-cook-plate': ''
  },
  BREAD: {
    products: 'proizvodi',
    news: 'vijesti',
    'since-1853': 'od-1853',
    'field-plate': 'polje-tanjur',
    'company-story': 'kompaniji-o-kompaniji',
    'csr-commitments': 'dop-obaveze',
    'vegetal-innovations': 'biljne-inovacije',
    magazine: 'casopis',
    all: 'sve',
    article: 'clanak',
    cook: 'kuvanje',
    cultivate: 'uzgajanje',
    explore: 'istrazivanje',
    recipe: 'cekaj-recept',
    recipes: 'recepti',
    'recipes-home': 'recepti-domacinstvo',
    'articles-search': 'clanak',
    search: 'pretraga',
    vegetables: 'povrce',
    contact: 'kontakt',
    'info-product': 'informacije-o-proizvodu',
    'note-product': 'napomena-o-proizvodu',
    suggestion: 'sugestija',
    'other-request': 'informacije-o-proizvodu',
    faq: 'cesto-postavljana-pitanja',
    'all-themes': 'sve-teme',
    'products-search': 'proizvodi-pretraga',
    recipesearch: 'recept-pretraga',
    'product-news': 'proizvod-vijesti',
    'good-deals': 'dobre-ponude',
    'not-found': 'nije-pronadjeno',
    'menu-page': 'Inspiracija',
    'menu-one': 'Zivi Zdravo',
    'menu-two': 'Ukusno Se Kuva',
    'menu-three': 'Kuhaj Sa Stilom',
    'menu-four': 'Budi Kreativan'
  },
  MENU_PAGE: {
    TITLE1: 'ŽIVITE ZDRAVO',
    TITLE2: 'UKUSNO KUHANJE',
    TITLE3: 'PRIPREMITE SA STILOM',
    TITLE4: 'BUDI KREATIVAND',
    EXTERNAL: ''
  },
  TECHNICAL: { MENU_PAGE: 'posao-ponude' },
  SEO: {
    TITLE: {
      MENU_ONE: 'ŽIVITE ZDRAVO',
      MENU_TWO: 'KUHAJTE UKUSNO',
      MENU_THREE: 'PRIPREMITE SA STILOM',
      MENU_FOUR: 'BUDITE KREATIVNI',
      HOME: 'Bonduelle',
      SEARCH: {
        MAIN: '{{value}}',
        RECIPES: '{{value}}',
        VEGETABLES: '{{value}}',
        ARTICLES: '{{value}} Filtrirano po članku | Bonduelle'
      },
      CONTACT: 'Bonduelle',
      RECIPES: 'Bonduelle',
      RECIPE_HOME: 'Bonduelle',
      RECIPE: 'Bonduelle',
      PRODUCTS: 'Bonduelle',
      NEWS: 'Bonduelle',
      CORPORATE: {
        NEWS: 'Bonduelle',
        COMPANY_STORY: 'Bonduelle',
        CSR_COMMITMENTS: 'Bonduelle',
        VEGETAL_INNOVATIONS: 'Bonduelle',
        FIELD_PLATE: 'Bonduelle',
        ARTICLE: '{{value}}',
        VEGETABLE: '{{value}}'
      },
      PRODUCT: '{{value}}',
      MAGAZINE: {
        NEWS: 'Bonduelle',
        ARTICLE: 'Bonduelle',
        COOK: 'Bonduelle',
        GROW: 'Bonduelle',
        EXPLORE: 'Bonduelle',
        RECIPE: 'Bonduelle'
      },
      FAQ: 'Bonduelle',
      NOTFOUND: 'Bonduelle',
      RECIPES_ALL: '',
      RECIPETYPE: '{{value}}',
      RECIPESEASON: '{{value}}',
      RECIPEPREPARATION: '{{value}}',
      RECIPEDIFFICULTY: '{{value}}',
      RECIPEVEGETABLE: '{{value}}',
      RECIPECOST: '{{value}}',
      RECIPEDISH: '{{value}}',
      RECIPEDESC: '{{value}}',
      '2RECIPEFACETS': '',
      '4RECIPEFACETS': '{{value}}',
      PRODUCTSEASON: '{{value}}',
      PRODUCTTECHNO: '{{value}}',
      PRODUCTVEGETABLE: '{{value}}',
      PRODUCTRANGE: 'Proizvodi iz asortimana {{value}}',
      THEME_ALL: '',
      THEME_STARTER: '',
      THEME_SALAD: '',
      THEME_CAN: '',
      THEME_FROZEN: '',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: '',
      THEME_RECIPE: '',
      THEME_PLATE: '',
      THEME_ACC: '',
      THEME_SNACK: '',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': '{{value}}'
    },
    DESCRIPTION: {
      MENU_ONE: 'Pročitajte sve o zdravim načinima života, pravilima ishrane, metodama pripreme i konzumiranja povrća.',
      MENU_TWO: 'Otkrijte korisne kulinarske tajne za pripremu vaših omiljenih Bonduelle jela i recepata.',
      MENU_THREE: 'Sve o kulinarskim tajnama svjetskih kuhinja, razlikama između tradicionalnih jela i stilova hrane.',
      MENU_FOUR: 'Pročitajte savjete o hrani i članke o ishrani koje je pripremio Bonduelle. Saznajte više o prednostima i pripremi zdrave hrane.',
      HOME: '',
      SEARCH: {
        MAIN: '{{value}}',
        RECIPES: '{{value}}',
        VEGETABLES: '{{value}}',
        ARTICLES: 'Tema {{value}}: sortirano po članku. Jednostavni i ukusni recepti. Kuhano povrće'
      },
      CONTACT: '',
      RECIPES: '',
      RECIPE_HOME: '',
      RECIPE: '',
      PRODUCTS: '',
      NEWS: '',
      CORPORATE: {
        NEWS: '',
        COMPANY_STORY: '',
        CSR_COMMITMENTS: '',
        VEGETAL_INNOVATIONS: '',
        VEGETABLE: '',
        FIELD_PLATE: '',
        ARTICLE: ''
      },
      PRODUCT: '{{value}}',
      MAGAZINE: {
        NEWS: '',
        ARTICLE: '',
        COOK: '',
        GROW: '',
        EXPLORE: '',
        RECIPE: ''
      },
      FAQ: '',
      PAGE: 'Stranica',
      RECIPETYPE: '{{value}}',
      RECIPESEASON: '{{value}}',
      RECIPEPREPARATION: '{{value}}',
      RECIPEDIFFICULTY: '{{value}}',
      RECIPEVEGETABLE: '',
      RECIPECOST: '{{value}}',
      RECIPEDISH: '{{value}}',
      RECIPEDESC: '{{value}}',
      '2RECIPEFACETS': '{{value}}',
      '4RECIPEFACETS': '{{value}}',
      PRODUCTSEASON: '{{value}}',
      PRODUCTTECHNO: '{{value}}',
      PRODUCTVEGETABLE: '{{value}}',
      PRODUCTRANGE: 'Otkrijte sve proizvode iz asortimana {{value}}',
      THEME_ALL: '',
      THEME_STARTER: '',
      THEME_SALAD: '',
      THEME_CAN: '',
      THEME_FROZEN: '',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: '',
      THEME_RECIPE: '',
      THEME_PLATE: '',
      THEME_ACC: '',
      THEME_SNACK: '',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': '{{value}}',
      NOTFOUND: ''
    },
    PIPE: { SOCIETY: '' },
    THEME_ALL: '',
    THEME_STARTER: '',
    THEME_SALAD: '',
    THEME_CAN: '',
    THEME_FROZEN: '',
    THEME_READY_MEALS: '',
    THEME_BIO: '',
    THEME_VEGETABLES: '',
    THEME_RECIPE: '',
    THEME_PLATE: '',
    THEME_ACC: '',
    THEME_SNACK: ''
  },
  VEGETABLE: {
    DISCOVER_VEGETABLE: 'Otkrijte naše povrtne listove',
    OURS_RECIPES: 'Naši recepti',
    DISCOVER_ALSO: 'Otkrijte takođe...',
    SEE_ALL: 'Vidi sve povrće',
    ON: 'Povrće na'
  },
  RECIPE: {
    'INGREDIENTS_LABEL ': 'Sastojci za {{value}} osoba',
    PEOPLES: '{{value}} ljudi',
    DIFFICULTY: { EASY: 'Lako', MEDIUM: 'Srednje', HARD: 'Teško' },
    COST: { CHEAP: 'Jeftino', EXPENSIVE: 'Skupo' },
    TIMING: { MIN: 'Minuta', HOURS: 'sati' },
    STEP: 'Korak',
    DISCOVER_ALSO: 'Otkrijte takođe...',
    HOME_SEE_MORE: 'Vidi još recepata',
    SHOWCASE: 'Vrh',
    HOME_BOOK: 'Naši recepti',
    MIN_MORE: '45 m i +',
    HOUR_MORE: 'h i +',
    FILTER_BY_CRITERIA: 'Filtriraj prema kriterijima',
    SORT_BY: 'Sortiraj po :',
    BEST_RATED: 'Najbolje ocijenjeno',
    MOST_CONSULTED: 'Najčešće konsultirano',
    MEMBERS_RECIPES: 'Recepti članova',
    CRITERIA: 'Vijesti',
    DISCOVER: { RECIPES: 'Otkrijte naše recepte' },
    DISCOVER_RECIPE: 'Otkrijte recept',
    INSTRUCTIONS: 'Uputstva',
    DEFAULT_TAG: 'Ideja za recept',
    MOST_RECENT: 'Najnovije',
    RECIPE_VIDEOS: 'Video recepti',
    PRODUCT_ASSOCIATE: 'Recept napravljen sa',
    BUDGET_LOW: 'Jeftino',
    BUDGET_HIGH: 'Visoko',
    BUDGET_MIDDLE: 'Srednje',
    PRINT_RECIPE: 'štampaj recept',
    LET_YOURSELF_SURPRISED: 'Budite iznenađeni',
    COOK_TIP_TITLE: 'Naslov savjeta za kuhanje',
    ENJOY_MEAL: 'Uživajte u obroku!',
    FILTER: { LEGEND: 'Legenda' }
  },
  PREDIGGO: { DIFFICULTY: { EASY: 'Lako', MEDIUM: 'srednje', HARD: 'Teško' } },
  COOKIES: {
    TEXT: 'Nastavkom posjete ovoj stranici prihvatate korištenje kolačića iz tehničkih razloga, u svrhu statističke analize i upravljanja web-prometom, dijeljenja informacija s oglašivačima na drugim web-stranicama, te pružanja relevantnih promotivnih komunikacija koje odražavaju vaše interese.',
    CLOSE: 'Zatvori',
    MORE: 'Saznaj više',
    AGREE_YOUTUBE: 'Morate prihvatiti kolačiće na Youtubeu da biste vidjeli ovaj video',
    BUTTON_YOUTUBE: 'Prihvati kolačiće'
  },
  POPUP: { LAUNCH: 'ISKAČUĆE-OTVARANJE-STRANICE' },
  FIELD_PLATE: { DISCOVER_VEGETABLE: 'Otkrijte naše povrtne listove' },
  SEARCH: {
    OUR_RECIPES: 'Recepti',
    OUR_PRODUCTS: 'Proizvodi',
    OUR_ARTICLES: 'Naši članci',
    RECIPES: 'Recepti',
    PRODUCTS: 'Proizvodi',
    ARTICLES: 'Članci',
    ALL: 'Sve',
    SORT_BY: 'Sortiraj po',
    RESULTS: 'rezultati',
    RESULT: 'rezultat',
    PRODUCT_OUT: 'proizvoda od',
    APPLY: 'Primeni',
    FILTER: 'Filtriraj',
    RECIPE_OUT: 'recepata od',
    NO_RESULT: 'Bijeli kupus za ovu pretragu ... Pitate nas lukavo pitanje',
    SEE_MORE: 'Vidi još',
    DISCOVER_RECIPES: 'Otkrijte naše recepte',
    SEE_MORE_OF: 'vidi još od',
    DELETE: 'obriši'
  },
  RECIPE_LIST: {
    OUR_RECIPES: 'Recepti',
    RESULTS: 'Rezultati',
    SEE_MORE: 'Vidi još recepata'
  },
  PRODUCT: {
    BUTTON: {
      ACTU: 'vijesti o proizvodu',
      FOUND: 'Gdje pronaći ovaj proizvod',
      DISCOVER: 'otkrijte sve gama'
    },
    NUTRITIONAL_VALUE: 'nutritivna vrijednost',
    CONDITIONING: 'klimatizacija',
    PORTIONS: 'porcije',
    NET_WEIGHT: 'neto težina',
    DRY_WEIGHT: 'suva težina',
    INGREDIENTS: 'sastojci',
    OUR_RECIPES: 'Otkrijte naše recepte',
    FOR: 'za',
    PER_PORTION: 'i po porciji od',
    ENERGY: 'energija',
    FAT: 'masti',
    SATURATE: 'zasićene masti',
    GLUCIDES: 'ugljikohidrati',
    FIBER: 'vlakna',
    PROTEINS: 'proteini',
    SALT: 'so',
    DISCOVER_ALSO: 'otkrijte takođe...',
    MORE_INFORMATION: 'više informacija',
    TRACES: 'Tragove',
    CONTAINS: 'Sadrži',
    SLIDER: { SHEET: 'pogledajte listu', BUY: 'kupi ovaj proizvod' },
    NUTRISCORE: 'informacije-nutri-ocjena',
    TRIMAN: 'informacije--triman',
    AGRICONFIANCE: 'agriconfiance-informacije',
    BIO: 'bio-informacije',
    PESTICIDE_FREE: '',
    LEAF: '',
    LOCK: '',
    SEE_ALL: 'Pogledajte sve proizvode',
    LEGISLATION: 'u skladu s važećim zakonodavstvom.',
    PORTION: { SINGULAR: 'porcija', PLURAL: 'porcije' },
    DISCLAIMER: '',
    COOKING: 'kuvanje',
    CONSERVATION: 'zaštita prirode'
  },
  FAQ: {
    ON: 'pitanja o',
    ALL: 'Sve',
    SEE_MORE: 'vidite još pitanja',
    TITLE_1: 'Svi odgovore na vaša pitanja',
    TITLE_2: 'Imate li pitanje o određenom proizvodu ?',
    LINK_LABEL: 'Pišite nam'
  },
  FORM: {
    GO_TO_STEP: 'Idi na korak',
    STEP: 'Korak',
    RECEIVE_EMAIL_COPY: 'Primite kopiju e-maila',
    SEND: 'Pošalji',
    GDPR: 'Više o upravljanju vašim podacima',
    ERROR: 'Sva polja označena sa * moraju biti popunjena i validna',
    SEND_ERROR: 'Došlo je do greške, molimo pokušajte ponovo za nekoliko trenutaka',
    NOTICE: 'Za pravilno postupanje s vašim dosjeom, Bonduelle može, ovisno o slučaju, zatražiti vaše zdravstvene podatke. Oni nisu obavezni i slobodni ste da ih ne prenosite.',
    SENDING: 'Slanje obrasca...',
    LOADING: 'Učitavanje...',
    PRODUCT_INFORMATION_TITLE: 'Informacije o proizvodu',
    PRODUCT_INFORMATION: {
      SEO_TITLE: 'Informacije o proizvodu | Bonduelle',
      SEO_DESC: 'Kontaktirajte našu korisničku službu'
    },
    INFORMATIONS: 'vaše informacije',
    MISTER: 'Gospodin',
    MISS: 'Gospođica',
    NAME: 'Ime',
    OUR_NAME: 'Naše ime',
    FIRSTNAME: 'Lični ime',
    OUR_FIRSTNAME: 'Naše lično ime',
    MAIL: 'vaša pošta',
    MAIL_PLH: 'mail@address.com',
    MAIL_CONFIRM: 'potvrdite svoj e-mail',
    MAIL_ERROR: 'molimo, unesite važeći e-mail',
    MAIL_ERROR_CONFIRM: 'molimo, potvrdite vašu email adresu',
    ADDRESS: 'vaša adresa',
    STREET_PLH: '',
    STREET: 'Ulica i broj ulice',
    ADDRESS_COMPLEMENT: 'više informacija o vašoj adresi',
    ADDRESS_COMP_PLH: 'više informacija o vašoj adresi',
    ZIPCODE: 'poštanski brok',
    CITY: 'grad',
    CITY_PLH: 'vaš grad',
    PHONE: 'telefon',
    PHONE_PLH: 'broj telefona',
    COUNTRY: 'zemlja',
    PRODUCT_INFORMATIONS: 'informacije o proizvodima',
    PRODUCT_INFORMATION_DESC: 'Da bismo što bolje mogli proučiti vaš<br>zahtjev, molimo vas da unesete sljedeće informacije :',
    PRODUCT: {
      DETAIL: {
        DESC: 'Za svaki zahtijevani kriterij možete popuniti polje obrasca<br>i dodati ilustrativnu fotografiju. Informacija: vaša fotografija mora omogućiti jasno čitanje <br>barkoda, broja serije i roka trajanja proizvoda.'
      },
      NOTE: {
        TITLE: 'Molim, dajte primjedbu o proizvodu',
        SEO_TITLE: 'Komentar i recenzija proizvoda | Bonduelle',
        SEO_DESC: 'Imate li primjedbu ili komentar o proizvodu iz našeg asortimana? Kontaktirajte našu službu za korisnike kako bismo vam pomogli. Personalizirani odgovor u roku od 48 sati'
      }
    },
    PRODUCT_RANGE: 'Asortiman proizvoda',
    PRODUCT_NAME: 'Naziv proizvoda',
    PRODUCT_NAME_PLH: 'Molim vas da napišete naziv Bonduelle proizvoda o kojem je riječ u vašem zahtjevu',
    PRODUCT_INFORMATIONS_DETAIL: 'Detaljne informacije',
    PRODUCT_INFORMATIONS_LABEL: 'Šifra proizvoda na traci',
    PRODUCT_INFORMATIONS_PLH: 'Šifra proizvoda sastoji se od 13 brojeva koji se nalaze na ambalaži vašeg proizvoda.',
    PRODUCT_LOT: 'Broj lota proizvoda',
    PRODUCT_LOT_INFO: 'Broj serije se nalazi blizu<br>datuma isteka roka trajanja<br>i sastoji se od nekoliko brojeva i slova<br>raspoređenih u nekoliko redova./ Uvijek<br>počinje slovom L<br>za kojim slijedi nekoliko brojeva i varira<br>u zavisnosti od proizvoda.<br><br>Na primjer, L72450492107:13.',
    PHOTO: 'Dodajte fotografiju',
    EXPIRED_DATE: 'Datum do kog je najbolje upotrijebiti / rok trajanja',
    EXPIRED_DATE_ERROR: 'Molio, unesite valjan datum u formatu dd/mm/gggg',
    EXPIRED_DATE_DESC: 'DLUO (Datum do kog je najbolje upotrijebiti proizvod)<br>i DLC (Rok upotrebe)<br>su prikazani u obliku datuma.',
    PHOTO_MORE: 'Dodatne fotografije',
    MESSAGE: 'Vaša poruka*',
    OUR_MESSAGE: 'molimo, vas napišite svoju poruku',
    SALES_OPERATIONS: {
      TITLE: 'Informacije o poslovnim poduhvatima',
      SEO_TITLE: 'Informacije o trenutnim poslivnim poduhvatima : nagradne igre... | Bonduelle',
      SEO_DESC: 'Molimo, kontaktirajte našu službu za korisnike radi informacija o trenutnoj promotivnoj akciji. Personalizirani odgovor u roku od 48 sati'
    },
    PRESS_SERVICE: 'Kontaktirajte službu za odnose s javnošću',
    FOOD_SERVICE: 'Kontaktirajte tim Bonduelle Food Service',
    BUSINESS_INFORMATION: 'Dobijte uzorke/vaučere/poklon predmete',
    MODAL_INFORMATION: '',
    JOB_OFFER: { TITLE: '', SEO_TITLE: '', SEO_DESC: '' },
    WEB_SUGGESTION: {
      TITLE: 'Dajte prijedlog za web-stranicu',
      SEO_TITLE: 'Poruka za poboljšanje, prijedlog za web-stranicu | Bonduelle',
      SEO_DESC: 'Molimo, kontaktirajte našu službu za korisnike kako biste nam pomogli u poboljšanju web-stranice.'
    },
    ANOTHER: {
      TITLE: 'Napravite drugi zahtjev',
      SEO_TITLE: 'Kontakt za sve ostale zahtjeve, usluge | Bonduelle',
      SEO_DESC: 'Kontaktirajte našu službu za korisnike radi informacija, usluga ili prijedloga. Personalizirani odgovor u roku od 48 sati'
    },
    AMBASSADOR: 'Postanite ambasador Bonduellea',
    CATEGORY: {
      SALAD: 'Salata',
      FROZEN: 'zamrznuto',
      CAN: 'konzerva',
      TAKEAWAY: 'za ponjeti',
      VEGETABLE: ''
    }
  },
  CONTACT: {
    TITLE: 'Vama na usluzi',
    SPECIAL_INFO: '',
    TAKE_CARE: '',
    BY_MAIL: 'Distributer\r\n',
    BY_PHONE: '',
    BY_SOURDLINE: '',
    MAIL_TEXT: 'Planet doo        \n' +
      'Planet d.o.o. Posušje, Bage b.b., 88240 Posušje, BiH.        \n' +
      '+387 39 682 700        \n' +
      'planet@planetbih.com',
    PHONE_TEXT: '',
    SOURDLINE_TEXT: '',
    BY_DISTRIBUTOR: '',
    BY_DISTRIBUTOR_TEXT: '',
    SUBTITLE: 'Želite nas kontaktirati zbog:',
    AGREEMENT: '',
    READ_MORE: "Uz to, imate pravo u bilo koje vrijeme zatražiti od BONDUELLE da ostvarite svoja prava na pristup, ispravak, brisanje i prenosivost vaših ličnih podataka, kao i prava na ograničenje i prigovor na obradu vaših ličnih podataka. Za ostvarivanje prava koja se odnose na vaše lične podatke, molimo vas da pišete na Dpo_france(at)bonduelle(dot)com ili putem pošte na sljedeću adresu: za pažnju Službenika za zaštitu podataka, Bonduelle, Rue Nicolas Appert, PO Box 30173, F-59653 Villeneuve D'Ascq. Da biste saznali više o načinu na koji BONDUELLE prikuplja i koristi vaše lične podatke, kliknite ovdje",
    READ_MORE_LABEL: 'Pročitajte također',
    SATISFACTION_TEXT: 'Kako bismo vas kontaktirali nakon komentara o našim proizvodima, omogućili da se pozabavimo vašom pritužbom i poslali vam upitnik o zadovoljstvu ako to želite, obradit ćemo informacije koje ste dostavili u svojstvu voditelja obrade podataka. Pravna osnova za obradu vašeg zahtjeva je pristanak zbog potencijalnih podataka o zdravlju koje možete navesti u svom zahtjevu. Ove informacije bit će čuvane koliko je potrebno za obradu vašeg zahtjeva i do 5 godina kako bismo pružili dokaz da je vaš zahtjev obrađen i kako bismo se pridržavali naših zakonskih obveza. Pravna osnova za slanje upitnika o zadovoljstvu također je vaš pristanak. Ove informacije bit će čuvane za vrijeme potrebno za slanje upitnika o zadovoljstvu i do maksimalno 1 godine. Za više informacija o obradi vaših osobnih podataka, molimo vas da konzultirate našu <a href="https://www.bonduelle.ba/politika-privatnosti" target="_blank" class="underline">Politiku privatnosti</a>.',
    SATISFACTION_CHECKBOX: 'Stavljanjem oznake u ovo polje, pristajete primiti upitnik o zadovoljstvu (koji se sastoji od jednog pitanja) nakon što vaša zahtjev bude obrađen. To je opcionalno.',
    CHECKBOX_TEXT: 'Stavljanjem oznake u ovo polje, pristajete da Bonduelle može obraditi lične zdravstvene podatke koje možete navesti u svom poruci isključivo s ciljem odgovaranja na vaš zahtjev. <strong>To je obavezno za obradu vašeg zahtjeva</strong>.',
    PHONE: 'Od ponedjeljka do petka od 9 do 18 sati na broj 00.33.09.70.25.22.22 (pozivi se ne naplaćuju, osim vikendom i praznicima)',
    CALL_US: 'Da nas pozovete',
    TO_WRITE: 'Pišite nam',
    PHONE_NUMBER: '3.3097E+11',
    SUCCESS_MESSAGE: 'Hvala! Vaša poruka je poslana našem timu. Bit će obrađena u prosječnom periodu\n' +
      ' od 2 do 3 dana, isključujući vikende i praznike. <br><br>\n' +
      ' Naravno, ostajemo vam na usluzi od ponedjeljka do petka od 9 do 18 sati na broju 330970252222 (poziv se ne naplaćuje).<br><br>\n' +
      ' Kao dio praćenja kvalitete naše usluge, svi naši razgovori mogu biti snimljeni.',
    PHOTOERROR: { MESSAGE: '' }
  },
  GLOBAL: { READ_MORE: 'Pročitajte više' },
  FLOWBOX: {
    GENERIC_KEY: 'ba_GnGC3TKedKEe0-gKomQ',
    PRODUCT_KEY: '1hRXF7TUSt-fhhlJASv-TQ',
    RECIPE_KEY: 'Ulp6NW8-QFOoSa61nBIlaA',
    HOME_KEY: 'g7--tXG3QgiwJjjpsaMmrg'
  },
  TOKEN: {
    FACEBOOK: '',
    PAGE_FACEBOOK_ID: '',
    INSTAGRAM: '',
    INSTA_ID: '17841407279489116',
    YOUTUBE: '',
    TWITTER: '',
    MAGAZINE: '6318061952.9bbab10.8842be1c95904c9d80ff59765a3a2ccc'
  },
  COOKIE: {
    BANNER: {
      MANDATORY: 'Neophodno',
      ANALYTICS: 'ANALITIKA',
      MARKETING: 'MARKETING',
      SOCIAL_MEDIA: 'DRUŠTVENE MREŽE',
      ACCEPT: 'Prihvati sve',
      REJECT: 'Odbij sve',
      SET_UP: 'Konfiguracija',
      MANDATORY_DETAILS: 'Neophodni kolačići su potrebni za rad stranice, na primjer za čuvanje vaših podataka za prijavu i pružanje sigurne veze.\n' +
        ' Kolačići potrebni za korištenje stranice bonduelle.fr su : \n' +
        ' <ul>\n' +
        ' <li>Sesioni kolačići (SESSION_ID): Kolačić generisan od strane PHP baziranih aplikacija. Ovo je opći identifikator koji se koristi za upravljanje korisničkim sesijskim varijablama. To je nasumično generiran broj, način na koji se koristi može biti specifičan za web-stranicu, ali dobar primjer je održavanje statusa prijave korisnika između stranica.</li>\n' +
        ' </ul>',
      ANALYTICS_DETAILS: 'Funkcionalni kolačići pomažu nam da optimizujemo web stranicu, na primjer, za prikupljanje statistike, za bilježenje vaših preferencija za društvene interakcije ili za mjerenje performansi i stabilnosti naše aplikacije.\n' +
        ' <br>\n' +
        ' Analitički kolačići korišteni na bonduelle.com stranici su : \n' +
        ' <ul>\n' +
        ' <li>DoubleClick: Ovaj kolačić postavlja DoubleClick (vlasništvo Googlea) kako bi se napravio profil interesa posjetilaca web-stranice i prikazivali relevantni oglasi na drugim web-stranicama. Glavna aktivnost kompanije je razmjena oglasa u realnom vremenu putem Googlea.</li>\n' +
        ' <li>Google analitika: ovi kolačići omogućavaju praćenje najposjećenijih stranica i prometa na web-stranici.</li>\n' +
        ' <li>UTM praćenje: ovaj kolačić omogućava upravljanje UTM varijablama za optimalno praćenje različitih online oglašivačkih kampanja</li>.\n' +
        ' </ul>',
      MARKETING_DETAILS: 'Marketinški kolačići omogućuju nam praćenje vaših preferencija za recepte i proizvode kako bismo vam uvijek ponudili proizvode koji najbolje odgovaraju vašim željama.\n' +
        ' <br>\n' +
        ' Kolačići za marketing koji se koriste na bonduelle.fr stranici su: \n' +
        ' <ul>\n' +
        ' <li> Prediggo: ovaj alat koristi SESSION_ID kolačić kako bi vam ponudio proizvode i recepte koji najbolje odgovaraju vašem pregledavanju i pretragama. </li>\n' +
        ' <li>ClickToBuy: ovaj kolačić nam omogućava da zapamtimo vašu lokaciju kako bismo vam mogli ponuditi najbliže prodavnice koje imaju Bonduelle proizvode koje tražite.</li>\n' +
        ` <li>Društvene mreže: ovi kolačići omogućavaju prikazivanje Bonduelle 'SocialWall' na početnoj web-stranici. Ovaj "zid" vam omogućava da vidite sve najnovije objave s glavnih društvenih mreža koje koriste timovi Bonduellea.</li>\n` +
        ' <li>FlowBox: ovaj kolačić omogućava korištenje usluga FlowBox softvera, koji nam omogućava da ponudimo našim posjetiocima recepte i kreacije drugih internet korisnika koji koriste Bonduelle proizvode.</li>\n' +
        ' </ul> "',
      V2: 'ok'
    },
    MODAL: {
      TITLE: 'Upravljanje kolačićima',
      TEXT: 'Grupa Bonduelle se obavezuje da osigura zaštitu ličnih podataka korisnika svojih web-stranica, posebno web-stranice Bonduelle.fr. <br>Kako biste mogli koristiti usluge koje nudi web-stranica, kao što su pregled proizvoda, otkrivanje novih gurmanskih recepata, pretplata na određene usluge poput naših biltena, kao i optimizacija njenog korištenja i personalizacija prema vašem profilu, web-stranica koristi kolačiće. Bonduelle se u potpunosti obavezuje da osigura visok nivo zaštite u pogledu takvih ličnih podataka i da se pridržava svih primjenjivih pravila o zaštiti podataka prilikom obrade ličnih podataka u vjezi sa web-stranicom.\n' +
        ' Stoga, možete u bilo koje vrijeme aktivirati/deaktivirati kolačiće.',
      CONFIG: 'Konfiguracija',
      SAVE: 'Sačuvaj'
    }
  },
  LINK: { JOB_OFFERS: 'https://jobs.bonduelle.com/?locale=en_US' },
  CLIC2BUY: { INFOS: '' }
};
