// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// siteLang array contains all countries that have a different lang/country for default

export const environment = {
  production: false,
  mock: true,
  defaultSite: 'fr',
  url: 'https://api-retail.clddev.bonduelle.com',
  apiUrl: 'https://api-retail.clddev.bonduelle.com/{langcode}/api',
  apiUrlMultisite: 'https://api-retail.clddev.bonduelle.com/api',
  prediggoApiUrl: 'https://api-retail.clddev.bonduelle.com/prediggo',
  prediggoApiKey: 'afgiwvbn7zfgi8fz221opogf8gfz2afzdsjk0dks',
  recaptcha: {
    siteKey: '6Lf2Lt0qAAAAAK3nHI6rJR8RKEVM7lBLE6bUt1U9'
  },

  GtmCode: 'GTM-M42WQD',
  cookieExpirationHours: 72,
  siteLang: [
    { country: 'ba', code: 'bs'},
    { country: 'com', code: 'en' },
    { country: 'be', code: 'nl' },
    { country: 'at', code: 'de' },
    { country: 'pt', code: 'pt'},
    { country: 'dk', code: 'da'},
    { country: 'se', code: 'sv'},
    { country: 'cz', code: 'cs'},
    { country: 'ee', code: 'et'},
    { country: 'no', code: 'no'},
    { country: 'il', code: 'he'},
    { country: 'al', code: 'sq' },
    { country: 'rs', code: 'sr' },
    { country: 'gr', code: 'el' }
  ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
