/* tslint:disable */export const recipeLink = [
  { lang: 'smbi18n', link: 'ROUTES/recipes/r' },
  { lang: 'com_en', link: 'recipes/r' },
  { lang: 'fr_fr', link: 'recette/r' },
  { lang: 'de_de', link: 'rezept/r' },
  { lang: 'at_de', link: 'rezept/r' },
  { lang: 'be_nl', link: 'nl/recept/r' },
  { lang: 'be_fr', link: 'fr/recette/r' },
  { lang: 'nl_nl', link: 'recept/r' },
  { lang: 'dk_da', link: 'opskrifter/r' },
  { lang: 'hu_hu', link: 'receptek/r' },
  { lang: 'cz_cs', link: 'recepty/r' },
  { lang: 'sk_sk', link: 'recepty/r' },
  { lang: 'es_es', link: 'recetas/r' },
  { lang: 'pt_pt', link: 'receitas/r' },
  { lang: 'it_it', link: 'ricette/r' },
  { lang: 'ee_et', link: 'retseptid/r' },
  { lang: 'lt_lt', link: 'receptai/r' },
  { lang: 'lv_lv', link: 'receptes/r' },
  { lang: 'pl_pl', link: 'przepisy-kulinarne/r' },
  { lang: 'fi_fi', link: 'reseptit/r' },
  { lang: 'se_sv', link: 'recept/r' },
  { lang: 'no_no', link: 'oppskrifter/r' },
  { lang: 'il_he', link: 'recipes/r' },
  { lang: 'rs_sr', link: 'recepti/r' },
  { lang: 'al_sq', link: 'receta/r' },
  { lang: 'gr_el', link: 'syntages/r' },
  { lang: 'ro_ro', link: 'retete/r' },
  { lang: 'bg_bg', link: 'retsepti/r' },
  { lang: 'hr_hr', link: 'recepti/r' },
  { lang: 'ba_bs', link: 'recepti/r' }
];
