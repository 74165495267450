import { Component, OnInit, Input } from '@angular/core';
import { SfSectionText } from '../models/section-text.interface';

@Component({
    selector: 'sf-section-text',
    templateUrl: './section-text.component.html',
    styleUrls: ['./section-text.component.sass'],
    standalone: false
})
export class SfSectionTextComponent implements OnInit {

  @Input() section: SfSectionText;

  @Input() seoTitle: 'H1'|'H2'|'H3'|'DIV' = 'DIV';

  constructor() { }

  ngOnInit() {
  }

}
