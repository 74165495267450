import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SfSlide } from '../../models/slide.interface';

@Component({
    selector: 'sf-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.sass'],
    standalone: false
})
export class SfBreadcrumbComponent {

  @Input() elements: SfSlide[];

  @Input() selectedIndex: number;

  @Input() disabled = false;

  @Output() select: EventEmitter<any> = new EventEmitter();

  constructor() { }

  public selectIndex(index: number) {
    if (this.disabled) { return; }
    this.select.emit(index);
  }

}
