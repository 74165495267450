import { SfFormSection } from './form-section.interface';

export interface SfForm {

  /** Title */
  title: string;

  /** Icon name */
  iconName: string;

  /** Sections of the form */
  formSections: SfFormSection[];
}
