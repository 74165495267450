<div *ngIf="section" class="sf-section-image-icon" [ngClass]="section.position === 'right' ? 'sf-section-image-icon-right': 'sf-section-image-icon-left'">
  <sf-image [image]="section.image" class="sf-section-image-icon-image"></sf-image>
  <div class="sf-section-image-icon-content">

    <!-- Title -->
    <ng-container *ngIf="section.text" [ngSwitch]="seoTitle">
      <h2 *ngSwitchCase="'H1'" class="sf-section-image-icon-content-title sf-title">{{section.title}}</h2>
      <h2 *ngSwitchCase="'H2'" class="sf-section-image-icon-content-title sf-title">{{section.title}}</h2>
      <h3 *ngSwitchCase="'H3'" class="sf-section-image-icon-content-title sf-title">{{section.title}}</h3>
      <div *ngSwitchDefault class="sf-section-image-icon-content-title sf-title">{{section.title}}</div>
    </ng-container>


    <!-- Icon -->
    <div class="sf-section-image-icon-content-action">
      <svg *ngIf="!displayContent" (click)="showHideContent(true)" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M4 .755l14.374 11.245-14.374 11.219.619.781 15.381-12-15.391-12-.609.755z"/></svg>
      <svg *ngIf="displayContent" (click)="showHideContent(false)" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M23.245 4l-11.245 14.374-11.219-14.374-.781.619 12 15.381 12-15.391-.755-.609z"/></svg>
    </div>

    <!-- Content -->
    <div #content>
      <sf-image [image]="section.image" class="sf-section-image-icon-content-image"></sf-image>
      <div [innerHTML]="section.text ? (section.text | safePipe) : ''" class="sf-section-image-icon-content-text sf-text"></div>
    </div>
  </div>
  <sf-image *ngIf="section.icon" [image]="section.icon" class="sf-section-image-icon-icon"></sf-image>
</div>
