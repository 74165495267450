
import { InjectionToken } from '@angular/core';
import { Request, Response } from 'express';

export const REQUEST = new InjectionToken<Request>('REQUEST');
export const RESPONSE = new InjectionToken<Response>('RESPONSE');
export const HOSTNAME = new InjectionToken<string>('HOSTNAME');
export const ORIGIN = new InjectionToken<string>('ORIGIN');
export const PATH = new InjectionToken<string>('PATH');

