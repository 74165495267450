/* tslint:disable */export const al_sq = {
  '404': {
    TITLE: 'Ups...',
    PAGENOTFOUND: 'Faqja nuk ekziston',
    BUTTON: 'Kthehuni në faqen fillestare',
    RECIPES: 'Zbuloni recetat tona'
  },
  HOME: {
    SHOWCASE: 'Receta gatimi',
    CATCH_PHRASE: '',
    SHOWCASE_SEE_ALL: 'Shihni të gjitha recetat tona',
    MAGAZINE: { TITLE: 'risitë', BUTTON: 'Zbuloni Légumiz' },
    OUR_VEGETABLES: 'Perimet tona',
    SOCIAL_WALL: 'Le të shprehim pasionin për bimët së bashku',
    FAQ_TITLE: 'Pyetjet tuaja',
    FAQ_SEE_MORE: 'Shikoni me shume',
    FLOWBOX: 'Ndani momentet tuaja të shijimit me #mybonduelle'
  },
  SELECT: { DEFAULT_VALUE: 'Bonduelle prezente' },
  MENU: {
    CONTACT_US: 'Na kontaktoni',
    MY_ACCOUNT: 'Llogaria ime',
    GOOD_DEALS: 'Oferta të mira',
    NEWSLETTER: 'Buletini Bonduelle'
  },
  FOOTER: {
    LINK: {
      BONDUELLE: 'Bonduelle.com',
      CREDITS: 'Kreditë',
      LEGAL_NOTICE: 'Njoftim ligjor',
      TERMS_OF_SALES: 'Kushtet e shitjes',
      COOKIES: 'cookies',
      SITEMAP: 'Harta e faqes',
      PRIVACY_POLICY: 'Politika e privatësisë',
      ALL_RIGHTS_RESERVED: '2018 © të gjitha të drejtat rezervuara'
    },
    HISTORY: {
      TITLE: 'Bonduelle, që nga viti 1853',
      TEXT: '<b>Bonduelle</b> është një biznes familjar që punon prej 7 brezash për të zhvilluar prodhimin bujqësor që respekton tokën dhe njerëzit. Ne mbrojmë një agroekologji efikase, inteligjente dhe të bashkuar, të kthyer me vendosmëri drejt së ardhmes dhe inovojmë çdo ditë nga fusha në pjatë për të krijuar një të ardhme më të mirë përmes ushqimit me bazë bimore.'
    },
    NEWSLETTER: {
      TITLE: 'Komuniteti Bonduelle',
      TEXT: 'Pranoj të marr buletinin "Gjithçka rreth perimeve nga Bonduelle" (lajme, promovime, konkurse, artikuj ...)',
      PLACEHOLDER: 'E-mail-i juaj',
      GDPR_LINK: 'Më shumë rreth menaxhimit të të dhënave tuaja'
    }
  },
  PRODUCTS: 'Produktet',
  MAGAZINE: {
    HOME: 'Mirëseardhët në revistën',
    CATCH_PHRASE: '',
    MENU: { BONDUELLE_LINK: 'Shkoni në bonduelle.al' },
    SHOWCASE: 'Vitrinë',
    PREVIOUS_ARTICLES: 'Artikujt e mëparshëm',
    PREVIOUS_ARTICLES_SEE_ALL: 'Shihni gjithë artikujt tanë',
    PREVIOUS_EDITIONS: 'Botimet tona të mëparshme',
    PREVIOUS_EDITIONS_SEE_ALL: 'Shihni më shumë botime',
    PREVIOUS_ARTICLES_BY_CATEGORY: 'Artikujt e mëparshëm sipas kategorisë',
    RUBRIC: {
      SEE_ALL: 'Shihni të gjitha recetat',
      SEE_ALL_ARTICLES: 'Shihni gjithë recetat',
      ON: 'artikuj mbi'
    },
    RUBRIC_COOK: { ON: 'receta të' },
    ARTICLE: { JOIN_US: 'Bashkohuni me ne', READ_ALSO: 'Lexime të tjera' },
    RECIPE: {
      INGREDIENTS: 'Përbërësit',
      INGREDIENTS_LABEL_DESC: 'për {{value}} persona',
      INGREDIENTS_LABEL_ONE_DESC: 'për {{value}} person',
      INGREDIENTS_LABEL: 'Përbërësit për {{value}} persona',
      INGREDIENTS_LABEL_ONE: 'Përbërësit për {{value}} person',
      PEOPLES: '{{value}} persona',
      PEOPLE: '{{value}} person',
      DIFFICULTY: { EASY: 'E lehtë', MEDIUM: 'E mesme', HARD: 'E vështirë' },
      COST: { CHEAP: 'E lirë', EXPENSIVE: 'E shtrenjtë' },
      TIMING: { MIN: 'minuta', HOURS: 'orë' },
      STEP: 'Hapi',
      DISCOVER_ALSO: 'Zbuloni gjithashtu...'
    }
  },
  ARTICLE: {
    DISCOVER_ALSO: 'Zbuloni gjithashtu ...',
    INGREDIENTS: 'Përbërësit'
  },
  SF: {
    SLIDE: { DISCOVER: 'Zbuloni' },
    SEARCH: { PLACEHOLDER: 'Kërkoni', ALL: 'Të gjitha' },
    NEWSLETTER: {
      BTN_LABEL: 'Po regjistroj',
      PLACEHOLDER: 'E-mail-i juaj',
      ERROR_MESSAGE: 'E-mail I pavlefshëm',
      ALREADY_SUBSCRIBED: 'Jeni regjistruar tashmë',
      GDPR: {
        TXT: 'Më shumë informacion rreth menaxhimit të të dhënave tuaja',
        LINK: '/cookies#gdpr'
      }
    },
    MONTHLY_FOLDER: { TITLE: 'Të rejat mujore' },
    MINI_NEWSLETTER: {
      PLACEHOLDER: 'E-mail-I juaj',
      GDPR: {
        TXT: 'Më shumë informacion rreth menaxhimit të të dhënave tuaja',
        LINK: '/cookies#gdpr'
      }
    },
    SOCIALITEM: { SHARE: 'ndani', COMMENT: 'komentoni', BONDUELLE: 'Bonduelle' },
    SEARCHRECIPE: { DISCOVER_ALL: 'kërkoni gjithë recetat' },
    FORM: {
      ERROR: 'Fushë e pavlefshme',
      PHOTO: {
        ADD: 'Shtoni një imazh',
        DELETE: 'Fshini imazhin',
        SEE: 'Shihni'
      }
    }
  },
  CORPORATE: {
    COMPANY_STORY: 'historia e kompanisë',
    CSR_COMMITMENT: 'zotime CSR',
    VEGETABLE_INNOVATIONS: 'risi vegjetale',
    NEWS: 'gjithë të rejat',
    SEE_MORE: 'Shihni më shumë artikuj',
    ON: 'artikuj rreth'
  },
  NEWSLETTER: {
    TITLE: 'Regjistrohuni në buletin',
    SUBTITLE: 'Oferta të mira, frymëzim kulinar dhe pak kulturë!'
  },
  ROUTES: {
    products: 'produkte',
    news: 'lajme-te-reja',
    'since-1853': 'qe-nga-1853',
    'field-plate': 'fusha-pjata',
    'company-story': 'historia-e-kompanise',
    'csr-commitments': 'zotime-csr',
    'vegetal-innovations': 'risi-vegjetale',
    magazine: 'magazine',
    all: 'te-gjitha',
    article: 'artikull',
    cook: 'cook',
    cultivate: 'cultivate',
    explore: 'explore',
    recipe: 'wait-recipe',
    recipes: 'receta',
    'recipes-home': 'recipes-home',
    r: 'r',
    credits: 'credits-com',
    'legal-notice': 'legal-notice',
    'terms-of-sales': 'terms-of-sales',
    cookies: 'cookies',
    sitemap: 'sitemap',
    'privacy-policy': 'politikat-e-privatesise',
    'all-rights-reserved': 'all-rights-reserved',
    search: 'kerkoni',
    'articles-search': 'artikull',
    vegetables: 'produkte',
    p: 'p',
    contact: 'kontaktoni',
    'info-product': 'informacija-o-proizvodu',
    'note-product': 'komentarisite-proizvod',
    suggestion: 'suggestion',
    'other-request': 'napravite-novi-zahtev',
    faq: 'faq',
    'all-themes': 'all-themes',
    'products-search': 'products-search',
    recipesearch: 'recipe-search',
    'product-news': 'product-news',
    'good-deals': 'good-deals',
    'not-found': 'not-found',
    'menu-page': 'frymezimi',
    'menu-one': 'jeto-shendetshem',
    'menu-two': 'gatuaj-shijshem',
    'menu-three': 'pergatit-me-stil',
    'menu-four': 'behu-krijues',
    'menu-external': 'menu-external',
    'theme-pasta-rice': '',
    'theme-bowls': '',
    'theme-soup': '',
    'theme-snack': '',
    'theme-sauce': '',
    'theme-unique-plate': '',
    'theme-over': 'theme-over',
    'theme-legumin': 'legumin',
    'theme-ingredients-recipes': '',
    'theme-cook-plate': ''
  },
  BREAD: {
    products: 'produkte',
    news: 'lajme të reja',
    'since-1853': 'që nga 1853',
    'field-plate': 'fusha-pjata',
    'company-story': 'historia e kompanisë',
    'csr-commitments': 'zotime csr',
    'vegetal-innovations': 'risi vegjetale',
    magazine: 'revistë',
    all: 'të gjitha',
    article: 'artikull',
    cook: 'gatuani',
    cultivate: 'kultivoni',
    explore: 'eksploroni',
    recipe: 'prisni recetën',
    recipes: 'receta',
    'recipes-home': 'receta - shtëpi',
    'articles-search': 'artikull',
    search: 'kërkoni',
    vegetables: 'perime',
    contact: 'kontaktoni',
    'info-product': 'Informacija o proizvodu',
    'note-product': 'komentarišite proizvod',
    suggestion: 'sugjerim',
    'other-request': 'napravite novi zahtev',
    faq: 'pyetje te shpeshta',
    'all-themes': 'gjithë temat',
    'products-search': 'kërkoni produkte',
    recipesearch: 'kërkoni recetë',
    'product-news': 'të reja për produktet',
    'good-deals': 'oferta të mira',
    'not-found': 'nuk u gjet',
    'menu-page': 'FRYMËZIMI',
    'menu-one': 'JETO SHËNDETSHËM',
    'menu-two': 'GATUAJ SHIJSHËM',
    'menu-three': 'PËRGATIT ME STIL',
    'menu-four': 'BEHU KRIJUES'
  },
  MENU_PAGE: {
    TITLE1: 'JETO SHËNDETSHËM',
    TITLE2: 'GATUAJ SHIJSHËM',
    TITLE3: 'PËRGATIT ME STIL',
    TITLE4: 'BEHU KRIJUES',
    EXTERNAL: ''
  },
  TECHNICAL: { MENU_PAGE: 'oferta pune' },
  SEO: {
    TITLE: {
      MENU_ONE: 'JETO SHËNDETSHËM',
      MENU_TWO: 'GATUAJ SHIJSHËM',
      MENU_THREE: 'PËRGATIT ME STIL',
      MENU_FOUR: 'BEHU KRIJUES',
      HOME: 'Bonduelle',
      SEARCH: {
        MAIN: '{{value}}',
        RECIPES: '{{value}}',
        VEGETABLES: '{{value}}',
        ARTICLES: '{{value}} Filtruar sipas artikullit | Bonduelle'
      },
      CONTACT: 'Bonduelle',
      RECIPES: 'Bonduelle',
      RECIPE_HOME: 'Bonduelle',
      RECIPE: 'Bonduelle',
      PRODUCTS: 'Bonduelle',
      NEWS: 'Bonduelle',
      CORPORATE: {
        NEWS: 'Bonduelle',
        COMPANY_STORY: 'Bonduelle',
        CSR_COMMITMENTS: 'Bonduelle',
        VEGETAL_INNOVATIONS: 'Bonduelle',
        FIELD_PLATE: 'Bonduelle',
        ARTICLE: '{{value}}',
        VEGETABLE: '{{value}}'
      },
      PRODUCT: '{{value}}',
      MAGAZINE: {
        NEWS: 'Bonduelle',
        ARTICLE: 'Bonduelle',
        COOK: 'Bonduelle',
        GROW: 'Bonduelle',
        EXPLORE: 'Bonduelle',
        RECIPE: 'Bonduelle'
      },
      FAQ: 'Bonduelle',
      NOTFOUND: 'Bonduelle',
      RECIPES_ALL: '',
      RECIPETYPE: '{{value}}',
      RECIPESEASON: '{{value}}',
      RECIPEPREPARATION: '{{value}}',
      RECIPEDIFFICULTY: '{{value}}',
      RECIPEVEGETABLE: '{{value}}',
      RECIPECOST: '{{value}}',
      RECIPEDISH: '{{value}}',
      RECIPEDESC: '{{value}}',
      '2RECIPEFACETS': '',
      '4RECIPEFACETS': '{{value}}',
      PRODUCTSEASON: '{{value}}',
      PRODUCTTECHNO: '{{value}}',
      PRODUCTVEGETABLE: '{{value}}',
      PRODUCTRANGE: 'Produktet e gamës {{value}}',
      THEME_ALL: '',
      THEME_STARTER: '',
      THEME_SALAD: '',
      THEME_CAN: '',
      THEME_FROZEN: '',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: '',
      THEME_RECIPE: '',
      THEME_PLATE: '',
      THEME_ACC: '',
      THEME_SNACK: '',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': '{{value}}'
    },
    DESCRIPTION: {
      MENU_ONE: 'JETO SHËNDETSHËM',
      MENU_TWO: 'GATUAJ SHIJSHËM',
      MENU_THREE: 'PËRGATIT ME STIL',
      MENU_FOUR: 'BEHU KRIJUES',
      HOME: '',
      SEARCH: {
        MAIN: '{{value}}',
        RECIPES: '{{value}}',
        VEGETABLES: '{{value}}',
        ARTICLES: 'Të gjithë {{value}} tanë të radhitur sipas artikullit. Receta të thjeshta e të shijshme. Perime të gatuara.'
      },
      CONTACT: '',
      RECIPES: '',
      RECIPE_HOME: '',
      RECIPE: '',
      PRODUCTS: '',
      NEWS: '',
      CORPORATE: {
        NEWS: '',
        COMPANY_STORY: '',
        CSR_COMMITMENTS: '',
        VEGETAL_INNOVATIONS: '',
        VEGETABLE: '',
        FIELD_PLATE: '',
        ARTICLE: ''
      },
      PRODUCT: '{{value}}',
      MAGAZINE: {
        NEWS: '',
        ARTICLE: '',
        COOK: '',
        GROW: '',
        EXPLORE: '',
        RECIPE: ''
      },
      FAQ: '',
      PAGE: 'faqe ',
      RECIPETYPE: '{{value}}',
      RECIPESEASON: '{{value}}',
      RECIPEPREPARATION: '{{value}}',
      RECIPEDIFFICULTY: '{{value}}',
      RECIPEVEGETABLE: '',
      RECIPECOST: '{{value}}',
      RECIPEDISH: '{{value}}',
      RECIPEDESC: '{{value}}',
      '2RECIPEFACETS': '{{value}}',
      '4RECIPEFACETS': '{{value}}',
      PRODUCTSEASON: '{{value}}',
      PRODUCTTECHNO: '{{value}}',
      PRODUCTVEGETABLE: '{{value}}',
      PRODUCTRANGE: 'Zbuloni gjithë produktet e gamës {{value}}',
      THEME_ALL: '',
      THEME_STARTER: '',
      THEME_SALAD: '',
      THEME_CAN: '',
      THEME_FROZEN: '',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: '',
      THEME_RECIPE: '',
      THEME_PLATE: '',
      THEME_ACC: '',
      THEME_SNACK: '',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': '{{value}}',
      NOTFOUND: ''
    },
    PIPE: { SOCIETY: ' | Bonduelle' },
    THEME_ALL: '',
    THEME_STARTER: '',
    THEME_SALAD: '',
    THEME_CAN: '',
    THEME_FROZEN: '',
    THEME_READY_MEALS: '',
    THEME_BIO: '',
    THEME_VEGETABLES: '',
    THEME_RECIPE: '',
    THEME_PLATE: '',
    THEME_ACC: '',
    THEME_SNACK: ''
  },
  VEGETABLE: {
    DISCOVER_VEGETABLE: 'Zbuloni faqet tona të perimeve',
    OURS_RECIPES: 'Recetat Tona',
    DISCOVER_ALSO: 'Zbuloni gjithashtu…',
    SEE_ALL: 'Shihni gjithë perimet',
    ON: 'Perimet për'
  },
  RECIPE: {
    'INGREDIENTS_LABEL ': 'Përbërësit për {{value}} persona',
    PEOPLES: '{{value}} persona',
    DIFFICULTY: { EASY: 'E lehtë', MEDIUM: 'E mesme', HARD: 'E vështirë' },
    COST: { CHEAP: 'E lirë', EXPENSIVE: 'E shtrenjtë' },
    TIMING: { MIN: 'Minuta', HOURS: 'orë' },
    STEP: 'Hapi',
    DISCOVER_ALSO: 'Zbuloni gjithashtu...',
    HOME_SEE_MORE: 'Shihni më shumë receta',
    SHOWCASE: 'Në krye',
    HOME_BOOK: 'Recetat tona',
    MIN_MORE: 'm dhe +',
    HOUR_MORE: 'h dhe +',
    FILTER_BY_CRITERIA: 'Filtroni sipas kriterit',
    SORT_BY: 'Ndajini sipas:',
    BEST_RATED: 'Më të vlerësuarat',
    MOST_CONSULTED: 'Më të shikuarat',
    MEMBERS_RECIPES: 'Recetat e anëtarëve',
    CRITERIA: 'Të reja',
    DISCOVER: { RECIPES: 'Zbuloni recetat tona' },
    DISCOVER_RECIPE: 'Zbuloni recetën',
    INSTRUCTIONS: 'Udhëzime',
    DEFAULT_TAG: 'Ide recetash',
    MOST_RECENT: 'Më të hershmet',
    RECIPE_VIDEOS: 'Video recetash',
    PRODUCT_ASSOCIATE: 'Receta të bëra me',
    BUDGET_LOW: 'E lirë',
    BUDGET_HIGH: 'E lartë',
    BUDGET_MIDDLE: 'E mesme',
    PRINT_RECIPE: 'printo recetën',
    LET_YOURSELF_SURPRISED: 'Surprizë për ju!',
    COOK_TIP_TITLE: 'titull të dhënash për gatimin',
    ENJOY_MEAL: 'Shijoni vaktin tuaj!',
    FILTER: { LEGEND: 'Titujt' }
  },
  PREDIGGO: {
    DIFFICULTY: { EASY: 'E lehtë', MEDIUM: 'e mesme', HARD: 'E vështirë' }
  },
  COOKIES: {
    TEXT: "Duke vazhduar vizitën tuaj në këtë faqe, pranoni përdorimin e cookies për arsye teknike, për analiza statistikore dhe menaxhimin e trafikut të internetit, ndarjen e informacionit me reklamuesit në faqe të tjera interneti, për t'ju ofruar të dhëna promovuese që përputhen me interesat tuaja.",
    CLOSE: 'Mbyllni',
    MORE: 'Mësoni më shumë',
    AGREE_YOUTUBE: 'Duhet të pranoni cookies e Youtube-s për të parë këtë video',
    BUTTON_YOUTUBE: 'Pranoni cookies'
  },
  POPUP: { LAUNCH: 'FAQET QË HAPEN ME POPUP' },
  FIELD_PLATE: { DISCOVER_VEGETABLE: 'Zbuloni faqet tona të perimeve' },
  SEARCH: {
    OUR_RECIPES: 'Receta',
    OUR_PRODUCTS: 'Produkte',
    OUR_ARTICLES: 'Artikujt tanë',
    RECIPES: 'Receta',
    PRODUCTS: 'Produkte',
    ARTICLES: 'Artikuj',
    ALL: 'Të gjitha',
    SORT_BY: 'Ndajini sipas:',
    RESULTS: 'rezultate',
    RESULT: 'rezultat',
    PRODUCT_OUT: 'produkte nga',
    APPLY: 'Aplikoni',
    FILTER: 'Filtroni',
    RECIPE_OUT: 'receta nga',
    NO_RESULT: 'Ky kërkim nxorri vetëm lakra. Po na bëni një pyetje të vështireë',
    SEE_MORE: 'Shihni më shumë',
    DISCOVER_RECIPES: 'Discover our recipes',
    SEE_MORE_OF: 'shihni më shumë nga',
    DELETE: 'fshini'
  },
  RECIPE_LIST: {
    OUR_RECIPES: 'Receta',
    RESULTS: 'Rezultate',
    SEE_MORE: 'Shihni më shumë receta'
  },
  PRODUCT: {
    BUTTON: {
      ACTU: 'Të reja për produktet',
      FOUND: 'Ku gjendet ky produkt',
      DISCOVER: 'zbuloni gjithë gamën'
    },
    NUTRITIONAL_VALUE: 'vlera ushqyese',
    CONDITIONING: 'kushtëzim',
    PORTIONS: 'porcione',
    NET_WEIGHT: 'pesha neto',
    DRY_WEIGHT: 'pesha e thatë',
    INGREDIENTS: 'përbërësit',
    OUR_RECIPES: 'Zbuloni recetat tona',
    FOR: 'për',
    PER_PORTION: 'dhe sipas porcionit të',
    ENERGY: 'Energjisë',
    FAT: 'yndyrës',
    SATURATE: 'yndyrës së saturuar',
    GLUCIDES: 'glucideve',
    FIBER: 'fibrës',
    PROTEINS: 'proteinave',
    SALT: 'kripës',
    DISCOVER_ALSO: 'zbuloni gjithashtu',
    MORE_INFORMATION: 'më shumë informacion',
    TRACES: 'Gjurmë të',
    CONTAINS: 'Përmban',
    SLIDER: { SHEET: 'shihni faqen', BUY: 'blini këtë produkt' },
    NUTRISCORE: 'informacion-vlerat ushqyese',
    TRIMAN: 'informacion-triman',
    AGRICONFIANCE: 'informacion-agrobesimi',
    BIO: 'informacion-bio',
    PESTICIDE_FREE: '',
    LEAF: '',
    LOCK: '',
    SEE_ALL: 'Shihni gjithë produktet',
    LEGISLATION: 'sipas legjislacionit në fuqi',
    PORTION: { SINGULAR: 'porcion', PLURAL: 'porcione' },
    DISCLAIMER: '',
    COOKING: 'gatim',
    CONSERVATION: 'konservim'
  },
  FAQ: {
    ON: 'pyetje rreth',
    ALL: 'Të gjitha',
    SEE_MORE: 'shihni më shumë pyetje',
    TITLE_1: 'Gjithë përgjigjet për pyetjet tuaja',
    TITLE_2: 'Keni pyetje për një produkt të veçantë?',
    LINK_LABEL: 'Na shkruani'
  },
  FORM: {
    GO_TO_STEP: 'Shkoni tek hapi',
    STEP: 'Hapi',
    RECEIVE_EMAIL_COPY: 'Merrni një kopje të e-mail-it',
    SEND: 'Dërgoni',
    GDPR: 'Më shumë rreth menaxhimit të të dhënave tuaja',
    ERROR: 'Të gjitha fushat e shënuara me * duhen plotësuar dhe të jenë të vlefshme',
    SEND_ERROR: 'Ndodhi një gabim. Ju lutemi, provojeni sërish pas disa momentesh.',
    NOTICE: "Për trajtimin e duhur të dosjes suaj, Bonduelle mund t'ju kërkojë të dhënat tuaja shëndetësore, sipas rastit. Ato nuk janë të detyrueshme dhe jeni të lirë të mos i tregoni.",
    SENDING: 'Duke dërguar formën…',
    LOADING: 'Duke ngarkuar...',
    PRODUCT_INFORMATION_TITLE: 'Informacion për produktin',
    PRODUCT_INFORMATION: {
      SEO_TITLE: 'Informacion për produktin | Bonduelle',
      SEO_DESC: 'Kontaktoni shërbimin tonë për klientin'
    },
    INFORMATIONS: 'informacionet tuaja',
    MISTER: 'Zoti',
    MISS: 'Zonja',
    NAME: 'Emri',
    OUR_NAME: 'Emri ynë',
    FIRSTNAME: 'Emri',
    OUR_FIRSTNAME: 'Emri ynë',
    MAIL: 'posta juaja',
    MAIL_PLH: 'mail@address.com',
    MAIL_CONFIRM: 'konfirmoni postën tuaj',
    MAIL_ERROR: 'ju lutemi, shkruani një e-mail të vlefshëm',
    MAIL_ERROR_CONFIRM: 'ju lutemi, konfirmoni e-mail-in tuaj',
    ADDRESS: 'adresa juaj',
    STREET_PLH: '',
    STREET: 'Rruga dhe numri i rrugës',
    ADDRESS_COMPLEMENT: 'më shumë të dhëna rreth adresës suaj',
    ADDRESS_COMP_PLH: 'më shumë të dhëna rreth adresës suaj',
    ZIPCODE: 'zip',
    CITY: 'qyteti',
    CITY_PLH: 'qyteti juaj',
    PHONE: 'telefoni',
    PHONE_PLH: 'numri I telefonit',
    COUNTRY: 'shteti',
    PRODUCT_INFORMATIONS: 'informacionet për produktin',
    PRODUCT_INFORMATION_DESC: 'Në mënyrë që shërbimet tona ta studiojnë më mirë kërkesën tuaj<br>, ju lutemi të na jepni informacionin e mëposhtëm:',
    PRODUCT: {
      DETAIL: {
        DESC: 'Për çdo kërkesë, mund të plotësoni fushën e formularit<br>dhe të shtoni një foto shpjeguese. Informacion: fotografia juaj duhet të jetë e lexueshme lehtësisht<br>barkodi, numri i grupit dhe "këshillohet të konsumohet para datës…" e produktit.'
      },
      NOTE: {
        TITLE: 'Komentoni për një produkt',
        SEO_TITLE: 'Vini në dukje dhe komentoni për një produkt | Bonduelle',
        SEO_DESC: "Keni vërejtje apo komente për një produkt nga linja jonë? Kontaktoni shërbimin tonë për konsumatorin për t'ju ndihmuar. Merrni përgjigje të personalizuar brenda 48 orësh."
      }
    },
    PRODUCT_RANGE: 'Gama e produkteve në fjalë',
    PRODUCT_NAME: 'Emri i produktit',
    PRODUCT_NAME_PLH: 'Shkruani emrin e produktit Bonduelle për të cilin interesoheni',
    PRODUCT_INFORMATIONS_DETAIL: 'Informacion i detajuar',
    PRODUCT_INFORMATIONS_LABEL: 'Barkodi i produktit',
    PRODUCT_INFORMATIONS_PLH: 'Barkodi përbëhet nga 13 shifra që gjenden në<br>paketimin e produktit',
    PRODUCT_LOT: 'Numri i grupit të produktit',
    PRODUCT_LOT_INFO: 'Numri i grupit<br>fillon gjithmonë me shkronjën L të ndjekur<br> nga disa shifra dhe ndryshon<br> në varësi të produktit.<br><br>Për shembull L72450492107:13.',
    PHOTO: 'Shtoni një imazh',
    EXPIRED_DATE: 'Këshillohet të konsumohet para datës…',
    EXPIRED_DATE_ERROR: 'Ju lutemi, vendosni një datë të vlefshme në formatin dd/mm/vvvv',
    EXPIRED_DATE_DESC: 'BBD (Këshillohet të konsumohet para datës…)<br>dhe UBD (Mos përdor pas datës)<br>paraqiten si data.',
    PHOTO_MORE: 'Imazhe shtesë',
    MESSAGE: 'Mesazhi juaj *',
    OUR_MESSAGE: 'shkruani mesazhin tuaj',
    SALES_OPERATIONS: {
      TITLE: 'Informacion për një transaksion biznesi',
      SEO_TITLE: 'Informacion rreth veprimtarive tona aktuale: konkurs… | Bonduelle',
      SEO_DESC: 'Kontaktoni shërbimin tonë për konsumatorin për informacion rreth një operacioni tregtar në zhvillim e sipër. Merrni përgjigje të personalizuar brenda 48 orëve'
    },
    PRESS_SERVICE: 'Kontaktoni departamentin e shtypit',
    FOOD_SERVICE: 'Kontaktoni ekipin e Shërbimit të Ushqimit Bonduelle',
    BUSINESS_INFORMATION: 'Merrni mostra/kupona/artikuj promocionalë',
    MODAL_INFORMATION: '',
    JOB_OFFER: { TITLE: '', SEO_TITLE: '', SEO_DESC: '' },
    WEB_SUGGESTION: {
      TITLE: 'Sugjeroni lidhur me websaitin',
      SEO_TITLE: 'Si mund të përmirësohemi? Mesazhe dhe sugjerime për faqen | Bonduelle',
      SEO_DESC: 'Ju lutemi, kontaktoni shërbimin tonë për konsumatorin që të na ndihmoni të përmirësojmë faqen.'
    },
    ANOTHER: {
      TITLE: 'Bëni një kërkesë tjetër',
      SEO_TITLE: 'Kontaktoni për çdo kërkesë apo shërbim tjetër | Bonduelle',
      SEO_DESC: 'Kontaktoni shërbimin tonë për konsumatorin për informacione, shërbime ose sugjerime. Merrni përgjigje të personalizuar brenda 48 orëve'
    },
    AMBASSADOR: 'Bëhuni ambasador Bonduelle',
    CATEGORY: {
      SALAD: 'Sallatë',
      FROZEN: 'e ngrirë',
      CAN: 'konservë',
      TAKEAWAY: 'për të marrë me vete',
      VEGETABLE: ''
    }
  },
  CONTACT: {
    TITLE: 'Në shërbimin tuaj',
    SPECIAL_INFO: '',
    TAKE_CARE: '',
    BY_MAIL: 'Me postë',
    BY_PHONE: 'Me celular',
    BY_SOURDLINE: '',
    MAIL_TEXT: 'Service consommateurs Bonduelle\n' +
      ' — BP 30173\n' +
      ' 59653 Villeneuve d’Ascq Cedex',
    PHONE_TEXT: 'Këshilltarët tanë janë në dispozicionin tuaj <a href="tel:+33970252222"><b>09 70 25 22 22</b></a> (telefonatë jo premium)\n' +
      ' nga e hëna deri të premten 9:00 - 18:00.\n' +
      ' Megjithatë, mund të postoni një mesazh zanor 24 orë në ditë.',
    SOURDLINE_TEXT: '',
    BY_DISTRIBUTOR: 'Distributori',
    BY_DISTRIBUTOR_TEXT: 'Importues dhe distributor për Kosovë: Afri Grup sh.p.k., Miradi e Epërme, 12000 Fushë Kosovë, Kosovë.<br />\n' +
      'Tel: 038 600 622<br />\n' +
      '<a href="https://www.afrigroup.co" target="_blank">www.afrigroup.co</a><br />\n' +
      'Adresa e Shperndarësit: Devi20-Grup Shpk, Komuna Kashar, Katundi i Ri, Rruga Monun Nr: 18, Tirane-Albania, tel: +355 69 408 6970, info@devigrup.com',
    SUBTITLE: 'Dëshironi të na kontaktoni për:',
    AGREEMENT: '',
    READ_MORE: "Për më tepër, keni të drejtë, në çdo kohë, të kërkoni që BONDUELLE të ushtrojë të drejtat tuaja për të aksesuar, korrigjuar, fshirë dhe bartur të dhënat tuaja personale, si dhe të drejtat tuaja për të kufizuar dhe kundërshtuar Përpunimin e të Dhënave tuaja Personale. Për ushtrimin e të drejtave tuaja në lidhje me të dhënat tuaja personale, ju lutemi shkruajini Dpo_france(at)bonduelle(dot)com ose me postë në adresën e mëposhtme: for the attention of the Data Protection Officer, Bonduelle, Rue Nicolas Appert, PO Box 30173, F-59653 Villeneuve D'Ascq. (për vëmendjen e Oficerit të Mbrojtjes së të Dhënave, Bonduelle, Rue Nicolas Appert, PO Box 30173, F- 59653 Villeneuve D'Ascq). Për të mësuar më shumë rreth mënyrës se si BONDUELLE mbledh dhe përdor të dhënat tuaja personale, klikoni këtu",
    READ_MORE_LABEL: 'Lexoni gjithashtu',
    SATISFACTION_TEXT: "Për t'ju kontaktuar për t'ju dhënë informacionin e kërkuar për produktin\n" +
      "dhe për t'ju dërguar një pyetësor të kënaqësisë nëse dëshironi, ne do të\n" +
      'përpunojmë informacionin e dhënë në cilësinë tonë si kontrollues i të\n' +
      'dhënave. Baza ligjore për përpunimin e kërkesës suaj është pëlqimi për\n' +
      'shkak të të dhënave të mundshme lidhur me shëndetin që mund të jepni\n' +
      'në kërkesën tuaj. Ky informacion do të ruhet për aq kohë sa është e\n' +
      'nevojshme për të përpunuar kërkesën tuaj dhe deri në 5 vjet për të\n' +
      'siguruar prova që kërkesa juaj është përpunuar dhe për të përmbushur\n' +
      'detyrimet tona ligjore. Baza ligjore për dërgimin e pyetësorit të\n' +
      'kënaqësisë është edhe pëlqimi juaj. Ky informacion do të ruhet për kohën\n' +
      'e nevojshme për dërgimin e pyetësorit të kënaqësisë dhe deri në\n' +
      'maksimum 1 vit. Për të mësuar më shumë rreth përpunimit të të dhënave\n' +
      'tuaja personale, ju lutemi konsultohuni me <a href="https://www.bonduelle.al/politikat-e-privatesise" target="_blank" class="underline">Politikën tonë të Privatësisë</a>.',
    SATISFACTION_CHECKBOX: 'Duke shënuar këtë kuti, ju pranoni të merrni një pyetësor të kënaqësisë (i\n' +
      'përbërë nga një pyetje e vetme) pasi kërkesa juaj të jetë përpunuar. Kjo\n' +
      'është fakultative.',
    CHECKBOX_TEXT: 'Duke shënuar këtë kuti, ju pranoni që Bonduelle mund të përpunojë të\n' +
      'dhënat personale shëndetësore që mund të jepni në mesazhin tuaj me\n' +
      "qëllimin e vetëm për t'iu përgjigjur kërkesës suaj. <strong>Kjo është e\n" +
      'detyrueshme për të përpunuar kërkesën tuaj</strong>.',
    PHONE: 'Nga e hëna deri të premten, nga ora 09:00 deri në 18:00, në numrin 00.33.09.70.25.22.22 (telefonata nuk mbitarifohet, përjashto fundjavat dhe festat publike)',
    CALL_US: 'Për të na telefonuar',
    TO_WRITE: 'Na shkruani',
    PHONE_NUMBER: '3.3097E+11',
    SUCCESS_MESSAGE: 'Faleminderit! Mesazhi juaj iu dërgua ekipit tonë. Do të përpunohet për mesatarisht\n' +
      '  2 deri në 3 ditë, duke përjashtuar fundjavat dhe festat.<br><br>\n' +
      ' Jemi natyrisht në shërbimin tuaj nga e hëna deri të premten, nga ora 9 deri në 18, në 330970252222 (telefonata pa mbitarifim).<br><br>\n' +
      ' Si pjesë e monitorimit të cilësisë së shërbimit tonë, të gjitha komunikimet tona mund të regjistrohen.',
    PHOTOERROR: { MESSAGE: '' }
  },
  GLOBAL: { READ_MORE: 'Lexoni më shumë' },
  FLOWBOX: {
    GENERIC_KEY: 'ba_GnGC3TKedKEe0-gKomQ',
    PRODUCT_KEY: '1hRXF7TUSt-fhhlJASv-TQ',
    RECIPE_KEY: 'Ulp6NW8-QFOoSa61nBIlaA',
    HOME_KEY: 'g7--tXG3QgiwJjjpsaMmrg'
  },
  TOKEN: {
    FACEBOOK: '',
    PAGE_FACEBOOK_ID: '',
    INSTAGRAM: '',
    INSTA_ID: '17841407279489116',
    YOUTUBE: '',
    TWITTER: '',
    MAGAZINE: '6318061952.9bbab10.8842be1c95904c9d80ff59765a3a2ccc'
  },
  COOKIE: {
    BANNER: {
      MANDATORY: 'Të nevojshme',
      ANALYTICS: 'ANALITIKA',
      MARKETING: 'MARKETING',
      SOCIAL_MEDIA: 'MEDIA SOCIALE',
      ACCEPT: 'Pranoni të gjitha',
      REJECT: 'Refuzoni të gjitha',
      SET_UP: 'Config',
      MANDATORY_DETAILS: "Cookie-t e kërkuara janë të nevojshme për funksionimin e faqes, për shembull, për të ruajtur informacionin tuaj të hyrjes dhe për t'ju siguruar një lidhje të sigurt.\n" +
        ' Cookie-t që kërkohen për të përdorur faqen bonduelle.fr janë:\n' +
        ' <ul>\n' +
        ' <li>cookie-t e sesionit (SESSION_ID): Cookie e krijuar nga aplikacionet e bazuara në PHP. Ky është një identifikues me qëllim të përgjithshëm që përdoret për të menaxhuar variablat e sesionit të përdoruesit. Është një numër i gjeneruar rastësisht. Mënyra e përdorimit mund të jetë specifike për faqen, por një shembull i mirë është ruajtja e statusit të hyrjes për një përdorues midis faqeve.</li>\n' +
        ' </ul>',
      ANALYTICS_DETAILS: 'Cookie-t funksionale na ndihmojnë të optimizojmë faqen, për shembull, për të mbledhur statistika, për të regjistruar preferencat tuaja për ndërveprimet sociale, ose për të matur performancën dhe stabilitetin e aplikacionit tonë.\n' +
        ' <br>\n' +
        ' Cookie-t analitike të përdorura nga faqja bonduelle.com janë:\n' +
        ' <ul>\n' +
        ' <li>DoubleClick: Kjo cookie është caktuar nga DoubleClick (në pronësi të Google) për të krijuar një profil të interesave të vizitorëve të faqes së internetit dhe për të shfaqur reklamat përkatëse në faqe të tjera. Aktiviteti kryesor i kompanisë është shkëmbimi i reklamave të ofertave në kohë reale nga Google.</li>\n' +
        ' <li>Google Analytics: këto cookie lejojnë gjurmimin e faqeve më të vizituara dhe trafikut të faqes.</li>\n' +
        ' <li>UTMTracking: kjo cookie lejon menaxhimin e variablave UTM për një ndjekje optimale të fushatave të ndryshme reklamuese në internet</li>.\n' +
        ' </ul>',
      MARKETING_DETAILS: "Cookie-t e marketingut na lejojnë të gjurmojmë preferencat tuaja për recetat dhe produktet që t'ju ofrojmë gjithmonë produktet më të afërta me dëshirat tuaja.\n" +
        ' <br>\n' +
        ' Cookie-t e marketingut që përdor faqja bonduelle.fr janë:\n' +
        ' <ul>\n' +
        " <li> Prediggo: ky instrument përdor skedarin SESSION_ID për t'ju ofruar produktet dhe recetat që i përshtaten më mirë shfletimit dhe kërkimeve tuaja. </li>\n" +
        " <li>ClickToBuy: kjo cookie na mundëson të kujtojmë vendndodhjen tuaj në mënyrë që të mund t'ju njohim me dyqanet më të afërta me produktet Bonduelle që kërkoni.</li>\n" +
        ` <li>Social Networks: këto cookie lejojnë 'SocialWall' Bonduelle të shfaqet në faqen kryesore të faqes. Ky "mur" ju lejon të shihni të gjitha postimet më të fundit nga rrjetet kryesore sociale që përdoren nga ekipet e Bonduelle.</li>\n` +
        " <li>FlowBox: kjo cookie mundëson përdorimin e shërbimeve të softuerit flowbox, që na lejon t'u ofrojmë vizitorëve tanë recetat dhe krijimet e përdoruesve të tjerë të internetit që përdorin produktet Bonduelle.</li>\n" +
        ' </ul> "',
      V2: 'ok'
    },
    MODAL: {
      TITLE: 'Menaxhimi i Cookie-ve',
      TEXT: "Grupi Bonduelle përkushtohet të sigurojë mbrojtjen e të dhënave personale të përdoruesve të faqeve të tij të internetit, dhe në veçanti të faqes së internetit Bonduelle.fr. <br>Për t'ju mundësuar përfitimin e shërbimeve të faqes, të tilla si produktet e këshilluara, zbulimi i recetave të reja të shijshme; abonimi në shërbime të caktuara, si gazetat tona; si dhe për të optimizuar përdorimin dhe personalizimin e tij sipas profilit tuaj, faqja përdor cookies. Grupi Bonduelle përkushtohet plotësisht të sigurojë një nivel të lartë mbrojtjeje për këto të dhëna personale dhe për të respektuar të gjitha rregullat e zbatueshme të mbrojtjes së të dhënave kur përpunohen të dhëna personale në lidhje me përdorimin e faqes së tij të internetit.\n" +
        ' Mund të aktivizoni/çaktivizoni cookie-t në çdo kohë.',
      CONFIG: 'Konfigurimi',
      SAVE: 'Shpëtoni'
    }
  },
  LINK: { JOB_OFFERS: 'https://jobs.bonduelle.com/?locale=en_US' },
  CLIC2BUY: { INFOS: '' }
};
