<div class="sf-search-grid" *ngIf="items && items.length && template">
  <ul class="sf-search-grid-row">
    <li class="sf-search-grid-row-item" *ngFor="let item of items| slice:start:limit" [ngClass]="colsUse()" >
      <ng-template [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{$implicit: item}">
      </ng-template>
    </li>
    <li *ngIf="mainUrl && items.length > 8" class="sf-search-grid-mobile sf-main-color sf-main-border-top sf-main-border-bottom">
      <div class="sf-search-grid-link" [routerLink]="[ mainUrl | sfLocalize, urlKeywords, childUrl | sfLocalize]" routerLinkActive="sf-secondary-color">{{'SF.SEARCH.ALL' | translate}} ▶</div>
    </li>
  </ul>
</div>
