import { SfModel, SfForm, SfFormSection, SfLink } from '@app/sf-lib/public_api';

export class SomForm extends SfModel implements SfForm {

  title: string;

  iconName: string;

  formSections: SfFormSection[];

  link: SfLink;

  popup?: boolean;

  active: boolean;

  seoTitle: string;

  seoDescription: string;

  channelBellInfo: Object;

  channelFreshInfo: Object;

  constructor() {
    super();
    this.formSections = [];
  }
}
