import { SfSlide } from '..';
import { SfModel } from '../../../core/networking';
import { SfSectionSlider } from './section-slider.interface';

export class SfSimpleSectionSlider extends SfModel implements SfSectionSlider {

  /** Title */
  title: string;

  /** Text of the section. May contain HTML tags */
  text: string;

  /** Slides for this section */
  slides: SfSlide[];
}
