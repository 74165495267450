import { InjectionToken } from '@angular/core';

export interface SfTypeParser {

  /**
   * Takes a source object, usually coming from a HTTP request
   * and returns the modified object with a sfType property.
   * The newly added 'sfType' property should match a model existing in
   * the library or a type injected with SfNetworkingModule forRoot
   * @memberof SfTypeParser
   */
  parseType(src: Object): Object;
}

export const SF_TYPE_PARSER = new InjectionToken<() => string>('SF_TYPE_PARSER');
