<div [@slideAnimations]="state" class="sf-slide" *ngIf="slide">

  <!-- Printest icon link -->
  <div *ngIf="isPinterestVisible">
    <span *ngIf="slide && slide.recipeName && slide.recipeLink" class="sf-slide-pin" data-pin-do="buttonPin" data-pin-round="true"
    (click)="navigate('https://www.pinterest.com/pin/create/button/?url='+url+'&media='+urlImage+'&description='+ slide.recipeName)">
      <img src="//assets.pinterest.com/images/pidgets/pinit_fg_en_round_red_16.png" loading="lazy" />
    </span>
  </div>


  <sf-image
    *ngIf="mode === 'objective' && size == 'MD'"
    class="sf-slide-background"
    type="zone_maxi"
    [image]="slide.background"></sf-image>
  <sf-image
    *ngIf="mode === 'objective' && size == 'XS'"
    class="sf-slide-background"
    type="ratio_550_385"
    [image]="slide.smallbackground ? slide.smallbackground : slide.background"></sf-image>
  <sf-image
    *ngIf="mode === 'objective' && size == 'SM'"
    class="sf-slide-background"
    type="ratio_1024_384"
    [image]="slide.smallbackground ? slide.smallbackground : slide.background"></sf-image>
  <img
    *ngIf="mode === 'subjective' && size == 'MD'"
    class="sf-slide-subjective-image"
    [src]="options.apiUrl + (slide.background['uri'] && slide.background['uri']['original'] ? slide.background['uri']['original'] : slide.background.image.uri.original)"
    [alt]="slide.background.alt_image"
    [title]="slide.background.alt_image"
    loading="lazy"
  >
  <img
    *ngIf="mode === 'subjective' && size == 'SM'"
    class="sf-slide-subjective-image"
    [src]="options.apiUrl + (slide.background['uri'] && slide.background['uri']['ratio_1024_384'] ? slide.background['uri']['ratio_1024_384'] : slide.background.image.uri.ratio_1024_384)"
    [alt]="slide.background.alt_image"
    [title]="slide.background.alt_image"
    loading="lazy"
  >
  <img
    *ngIf="mode === 'subjective' && size == 'XS'"
    class="sf-slide-subjective-image"
    [src]="options.apiUrl + (slide.background['uri'] && slide.background['uri']['ratio_550_385'] ? slide.background['uri']['ratio_550_385'] : slide.background.image.uri.ratio_550_385)"
    [alt]="slide.background.alt_image"
    [title]="slide.background.alt_image"
    loading="lazy"
  >

  <div
    class="sf-slide-content"
    [sfLink]="(slide.link?.url && !slide.link?.label) ? slide.link : null"
    [ngClass]="{'sf-slide-content-clickable': (slide.link?.url && !slide.link?.label) }">

    <div
      [ngClass]="{
        'sf-slide-content-container-no-label': !slide.label1 && !slide.label2 && !slide.link?.label }"
        class="sf-slide-content-container">

      <!-- Uppertitle -->
      <ng-container *ngIf="slide.label1" [ngSwitch]="seoUpperTitle">
        <h2 *ngSwitchCase="'H1'" [@leftAnimation]="state" class="sf-slide-content-label sf-slide-content-label1 sf-title">{{slide.label1}}</h2>
        <h2 *ngSwitchCase="'H2'" [@leftAnimation]="state" class="sf-slide-content-label sf-slide-content-label1 sf-title">{{slide.label1}}</h2>
        <h3 *ngSwitchCase="'H3'" [@leftAnimation]="state" class="sf-slide-content-label sf-slide-content-label1 sf-title">{{slide.label1}}</h3>
        <div *ngSwitchDefault [@leftAnimation]="state" class="sf-slide-content-label sf-slide-content-label1 sf-title">{{slide.label1}}</div>
      </ng-container>

      <!-- Title -->
      <ng-container *ngIf="slide.label2" [ngSwitch]="seoTitle">
        <h1 *ngSwitchCase="'H1'" [@leftAnimation]="state" class="sf-slide-content-label sf-slide-content-label2 sf-title">{{slide.label2}}</h1>
        <h2 *ngSwitchCase="'H2'" [@leftAnimation]="state" class="sf-slide-content-label sf-slide-content-label2 sf-title">{{slide.label2}}</h2>
        <h3 *ngSwitchCase="'H3'" [@leftAnimation]="state" class="sf-slide-content-label sf-slide-content-label2 sf-title">{{slide.label2}}</h3>
        <div *ngSwitchDefault [@leftAnimation]="state" class="sf-slide-content-label sf-slide-content-label2 sf-title">{{slide.label2}}</div>
      </ng-container>

      <!-- CTA -->
      <div *ngIf="slide.link?.url && slide.link?.label" [sfLink]="slide.link" class="sf-slide-content-button">
        <button *ngIf="slide.link?.url" [@leftAnimation]="state"(click)="eventService.setEvent(slide.label1, 'Slider')"
          class="sf-button sf-accent-back sf-main-back-hover sf-main-color sf-accent-color-hover">
          {{ slide.link.label || 'SF.SLIDE.DISCOVER' | translate }}
        </button>
      </div>

    </div>

    <!-- Packshot -->
    <sf-image
      *ngIf="slide.packshot && slide.packshot.image && slide.packshot.image.uri"
      class="sf-slide-packshot"
      [@rightAnimation]="getAnimationState()"
      [sfLink]="productUrl"
      [image]="slide.packshot"
      align="bottom"
      fitting="contain"></sf-image>
  </div>
</div>
