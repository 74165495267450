// @ts-nocheck
import { SomPage } from './page.model';
import {
  SfMediaImage,
  SfLink,
  SfLocalizeService,
  SfItem,
  SfMediaVideo
} from '@app/sf-lib/public_api';
import { AppInjector } from '../app-injector';
import { transliterate as slugify } from 'transliteration';
import { productLink } from '@assets/link/product-link';
import { TranslateService } from '@ngx-translate/core';

const charsMapConvert = require('@app/core/charsMap');

export interface SomProductProperty {
  label: string;
  baseValue?: string;
  portionValue?: string;
}

export class SomProductPage extends SomPage implements SfItem {
  // Product id from Prediggo
  id: string;

  // Product id from back
  gtin: string;

  gtinParent: string

  /** Name of the product */
  productName: string;

  /** Name of the product */
  name: string;

  url: string
  urlParent: string

  /** Theme of the product */
  productTheme: string;

  /** Net weight */
  netweight: number;

  /** Dry weight */
  dryweight: number;

  /** Ingredients of the product (plain string) */
  productIngredients: string;

  /** Preparation tips */
  productInstructions?: string;

  /** Conservation directives */
  productConservation?: string;

  /** Warning information */
  productWarning?: string;

  /** Main picture of the product (packshot) */
  productMainPicture: SfMediaImage;

  imageUrl: SfMediaImage;

  /** Maxi zone */
  productZoomPicture: SfMediaImage;

  /** Catchphrase of the product */
  webCatchphrase: string;

  /** Description of the product */
  webDescription: string;

  /** Popup of the product */
  pictoUrl: string | string[];

  /** nutriscore of the product */
  nutriscore: string;

  /**Product Origin */
  generalStatement: string
  originInfo: {
    vegetable: string,
    countries: string[]
  }[] = []

  originInfoStatement: string = ''
  originInfoTitle: string = ''

  /**
   * Portion info
   */
  portionWeight: number;
  portionNumber: number;

  /**
   * List of claims of Product
   */
  claims: { [key: string]: string };
  get claimString(): string {
    return Object.values(this.claims)
      .filter(v => !!v)
      .join(', ');
  }

  claimsProduct: { [key: string]: string };
  get claimProductString(): string {
    return Object.values(this.claimsProduct)
      .filter(v => !!v)
      .join('. ');
  }

  /**
   * List of allergens
   */
  allergens: { [key: string]: string };
  get allergenString(): string {
    return Object.values(this.allergens)
      .filter(v => !!v)
      .join(', ');
  }

  /**
   * List of allergens (traces)
   */
  allergensTrace: { [key: string]: string };
  get allergenTraceString(): string {
    return Object.values(this.allergensTrace)
      .filter(v => !!v)
      .join(', ');
  }

  /**
   * list of nutritional value
   */
  nutritionalValues: { [key: string]: SomProductProperty };

  get nutritionalValuesIterable(): SomProductProperty[] {
    const newArray = [];
    const orderArray = [
      'energyKj',
      'energyKcal',
      'fatTotal',
      'fatSaturate',
      'carbohydratesTotal',
      'carbohydratesSugar',
      'carbohydratesAddedSugar',
      'fibre',
      'proteins',
      'salt',
      'vitProA',
      'vitA',
      'vitE',
      'vitK1',
      'vitC',
      'vitB2',
      'vitB9',
      'potassium',
      'calcium',
      'phosphorus',
      'magnesium',
      'iron',
      'zinc',
      'copper',
      'manganese',
      'selenium',
      'cholesterol',
    ];
    orderArray.forEach(ordervalues => {
      if (this.nutritionalValues[ordervalues]) {
        newArray.push(this.nutritionalValues[ordervalues]);
      }
    });
    return Object.values(newArray);
  }

  /** SEO title */
  productSeoTitle: string;
  get meta_title(): string {
    return this.productSeoTitle;
  }

  /** SEO description */
  productSeoDescription: string;
  get meta_description(): string {
    return this.productSeoDescription;
  }

  /** Prediggo tracking code */
  trackingCode?: string;

  /** Daily reference intake @todo unused */
  // public dailyVitProA: number;
  // public dailyVitA: number;
  // public dailyVitE: number;
  // public dailyVitC: number;
  // public dailyVitB2: number;
  // public dailyVitB9: number;
  // public dailyVitK1: number;
  // public dailyNatrium: number;
  // public dailyIron: number;

  /** @todo Unknown properties */
  // public pictoUrl: string;
  // public nutriScore: string;
  public webLandingPageButtonText: string;
  public webLandindPageUrl: string;

  public webNewsTitle: string;
  public webNewsDescription: string;
  public webNewsPicture: SfMediaImage;
  public webNewsVideo: SfMediaVideo;

  get landingUrl(): SfLink {
    return {
      is_external: true,
      label: '',
      url: this.webLandindPageUrl
    };
  }
  // public season: string;
  // public mainVegetable: string;
  // public technologie: string;
  // public productPublishedAt: string;
  // public productYoutubeCode: string;
  // public searchable?: boolean;
  // public recommandable?: boolean;

  get cover(): SfMediaImage {
      return this.imageUrl ? this.imageUrl : this.productMainPicture;
    }

  get title(): string {
    return this.productName;
  }

  /** SfItem link */
  get link(): SfLink {
    let alias = `${this.id}`;
    let slugProduct;
    let name = this.productName ? this.productName : this.name;
    if (name) {
      slugProduct = this.localize.currentLang.code === 'he' ? slugify(name) : this.name;
      alias = encodeURI(charsMapConvert(slugProduct));
    }
    const tradLink = productLink.find(l => l.lang === this.translate.currentLang)
    if (this.trackingCode) {
      return {
        is_external: false,
        label: '',
        url: `${tradLink.link}/${alias}/${this.id}`,
        extras: { id: this.trackingCode }
      };
    } else {
      return {
        is_external: false,
        label: '',
        url: `${tradLink.link}/${alias}/${this.id}`
      };
    }
  }
  set link(value: SfLink) {
    return;
  }

  private readonly baseUrl = '/vegetables/p';

  protected localize: SfLocalizeService;
  protected translate: TranslateService;

  constructor(product?: SomProductPage) {
    super();

    if (product) {
      this.id = product.id;
      this.gtin = product.gtin;
      this.gtinParent = product.gtinParent
      this.productName = product.name ? product.name : product.productName;
      this.name = product.name;
      this.productTheme = product.productTheme;
      this.netweight = product.netweight;
      this.dryweight = product.dryweight;
      this.url = product.url;
      this.urlParent = product.urlParent;
      this.productIngredients = product.productIngredients;
      this.productMainPicture = product.imageUrl;
      this.productZoomPicture = product.productZoomPicture;
      this.webCatchphrase = product.webCatchphrase;
      this.webDescription = product.webDescription;
      this.pictoUrl = product.pictoUrl;
      this.nutriscore = product.nutriscore;
      this.portionWeight = product.portionWeight;
      this.portionNumber = product.portionNumber;
      this.productInstructions = product.productInstructions;
      this.productWarning = product.productWarning;
      this.productConservation = product.productConservation;
      this.claims = product.claims;
      this.claimsProduct = product.claimsProduct;
      this.allergens = product.allergens;
      this.allergensTrace = product.allergensTrace;
      this.nutritionalValues = product.nutritionalValues;
      this.productSeoTitle = product.productSeoTitle;
      this.productSeoDescription = product.productSeoDescription;
      this.trackingCode = product.trackingCode;
      this.webLandingPageButtonText = product.webLandingPageButtonText;
      this.webLandindPageUrl = product.webLandindPageUrl;
      this.webNewsTitle = product.webNewsTitle;
      this.webNewsDescription = product.webNewsDescription;
      this.webNewsPicture = product.webNewsPicture;
      this.webNewsVideo = product.webNewsVideo;
      this.originInfo = product.originInfo
      this.originInfoStatement = product.originInfoStatement
    } else {
      this.allergens = {};
      this.allergensTrace = {};
      this.claims = {};
      this.claimsProduct = {};
      this.nutritionalValues = {};
      this.originInfo = []
    }

    try {
      const injector = AppInjector.getInjector();
      this.localize = injector.get(SfLocalizeService);
      this.translate = injector.get(TranslateService);
    } catch (e) {
      console.error('Failed initializing model', e);
    }
  }

  /**
   * Custom function used by JSON.stringify
   * This prevent JSON.stringify to throw an error on circular
   * references
   *
   * @param {string} nm Name of the property
   * @returns {string}
   * @memberof SomRecipePage
   */
  public toJSON(nm: string) {
    const newProduct = {
      id: this.id,
      gtin: this.gtin,
      gtinParent: this.gtinParent,
      url: this.url,
      urlParent: this.urlParent,
      productName: this.name ? this.name : this.productName,
      name: this.name,
      productTheme: this.productTheme,
      productIngredients: this.productIngredients,
      productMainPicture: this.imageUrl,
      productZoomPicture: this.productZoomPicture,
      webCatchphrase: this.webCatchphrase,
      webDescription: this.webDescription,
      portionWeight: this.portionWeight,
      portionNumber: this.portionNumber,
      claims: this.claims,
      claimString: this.claimString,
      claimsProduct: this.claimsProduct,
      claimProductString: this.claimProductString,
      allergens: this.allergens,
      allergenString: this.allergenString,
      allergensTrace: this.allergensTrace,
      allergenTraceString: this.allergenTraceString,
      nutritionalValues: this.nutritionalValues,
      nutritionalValuesIterable: this.nutritionalValuesIterable,
      productSeoTitle: this.productSeoTitle,
      meta_title: this.meta_title,
      productSeoDescription: this.productSeoDescription,
      meta_description: this.meta_description,
      trackingCode: this.trackingCode,
      webLandingPageButtonText: this.webLandingPageButtonText,
      webLandindPageUrl: this.webLandindPageUrl,
      webNewsTitle: this.webNewsTitle,
      webNewsDescription: this.webNewsDescription,
      webNewsPicture: this.webNewsPicture,
      webNewsVideo: this.webNewsVideo,
      landingUrl: this.landingUrl,
      cover: this.cover,
      title: this.title,
      link: this.link,
      nutriscore: this.nutriscore,
      pictoUrl: this.pictoUrl,
      netweight: this.netweight,
      dryweight: this.dryweight,

      lang: this.lang,
      content: this.content,
      meta_keywords: this.meta_keywords,
      productConservation: this.productConservation,
      productInstructions: this.productInstructions,
      generalStatement: this.generalStatement,
      originInfo: this.originInfo,
      originInfoStatement: this.originInfoStatement,
      originInfoTitle: this.originInfoTitle
    };
    return newProduct;
  }
}
