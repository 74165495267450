/* tslint:disable */export const hr_hr = {
  '404': {
    TITLE: 'Oprostite...',
    PAGENOTFOUND: 'Stranica ne postoji',
    BUTTON: 'Natrag na početnu stranicu',
    RECIPES: 'Otkrijte naše recepte'
  },
  HOME: {
    SHOWCASE: 'Recepti za kuhanje',
    CATCH_PHRASE: '',
    SHOWCASE_SEE_ALL: 'Vidi sve recepte',
    MAGAZINE: { TITLE: 'VRH', BUTTON: 'Otkrijte legume' },
    OUR_VEGETABLES: 'Naše povrće',
    SOCIAL_WALL: 'Zajedno, podijelimo strast prema biljkama',
    FAQ_TITLE: 'Vaša pitanja',
    FAQ_SEE_MORE: 'Vidi još',
    FLOWBOX: 'Podijelite svoje slasne trenutke s #mojbonduelle'
  },
  SELECT: { DEFAULT_VALUE: 'Bonduelle poklon' },
  MENU: {
    CONTACT_US: 'Kontaktirajte nas',
    MY_ACCOUNT: 'Moj nalog',
    GOOD_DEALS: 'Dobre ponude',
    NEWSLETTER: 'Bilten Bonduelle'
  },
  FOOTER: {
    LINK: {
      BONDUELLE: 'Bonduelle.com',
      CREDITS: 'Zahvala',
      LEGAL_NOTICE: 'Pravna obavijest',
      TERMS_OF_SALES: 'Uvjeti prodaje',
      COOKIES: 'Kolačići',
      SITEMAP: 'Mapa web-stranice',
      PRIVACY_POLICY: 'Politika privatnosti',
      ALL_RIGHTS_RESERVED: '2018 © Sva prava pridržana'
    },
    HISTORY: {
      TITLE: 'Bonduelle, od 1853.',
      TEXT: 'Bonduelle je obiteljska tvrtka koja već 7 generacija radi na razvoju poljoprivredne proizvodnje koja poštiva zemlju i ljude. Mi podržavamo učinkovitu, inteligentnu i jedinstvenu agroekologiju, odlučno gledajući u budućnost i uvodeći inovacije svaki dan od polja do tanjura kako bismo stvorili bolju budućnost kroz biljnu hranu.\n'
    },
    NEWSLETTER: {
      TITLE: 'Zajednica Bonduelle',
      TEXT: 'Prihvaćam primanje biltena « Sve o povrću od Bonduellea » (vijesti, promocije, natjecanja, članci...)',
      PLACEHOLDER: 'Vaša e-pošta',
      GDPR_LINK: 'Više o upravljanju vašim podacima'
    }
  },
  PRODUCTS: 'Proizvodi',
  MAGAZINE: {
    HOME: 'Dobrodošli u magazin',
    CATCH_PHRASE: 'ogulite vijesti o povrću s Bonduelleom !',
    MENU: { BONDUELLE_LINK: 'Idite na bonduelle.hr' },
    SHOWCASE: 'Izlog',
    PREVIOUS_ARTICLES: 'Prethodni članci',
    PREVIOUS_ARTICLES_SEE_ALL: 'Pogledajte sve naše članke',
    PREVIOUS_EDITIONS: 'Naša prethodni izdanja',
    PREVIOUS_EDITIONS_SEE_ALL: 'Vidi još izdanja',
    PREVIOUS_ARTICLES_BY_CATEGORY: 'Prethodni članci po kategoriji',
    RUBRIC: {
      SEE_ALL: 'Pogledaj sve recepte',
      SEE_ALL_ARTICLES: 'Pogledaj sve članke',
      ON: 'članci o'
    },
    RUBRIC_COOK: { ON: 'recepti za' },
    ARTICLE: { JOIN_US: 'Pridružite nam se', READ_ALSO: 'Čitati također' },
    RECIPE: {
      INGREDIENTS: 'Sastojci',
      INGREDIENTS_LABEL_DESC: 'za {{value}} osoba',
      INGREDIENTS_LABEL_ONE_DESC: 'za {{value}} osoba',
      INGREDIENTS_LABEL: 'Sastojci za {{value}} osoba',
      INGREDIENTS_LABEL_ONE: 'Sastojci za {{value}} osoba',
      PEOPLES: '{{value}} osoba',
      PEOPLE: '{{value}} ljudi',
      DIFFICULTY: { EASY: 'Lako', MEDIUM: 'Srednje', HARD: 'Teško' },
      COST: { CHEAP: 'Jeftino', EXPENSIVE: 'Skupo' },
      TIMING: { MIN: 'minuta', HOURS: 'sati' },
      STEP: 'Korak',
      DISCOVER_ALSO: 'Otkrijte također...'
    }
  },
  ARTICLE: { DISCOVER_ALSO: 'Otkrijte također...', INGREDIENTS: 'Sastojci' },
  SF: {
    SLIDE: { DISCOVER: 'Otkrijte' },
    SEARCH: { PLACEHOLDER: 'Pretraži', ALL: 'Sve' },
    NEWSLETTER: {
      BTN_LABEL: 'Registriram se',
      PLACEHOLDER: 'Vaša e-pošta',
      ERROR_MESSAGE: 'Nevažeća e-mail adresa',
      ALREADY_SUBSCRIBED: 'Već ste pretplaćeni',
      GDPR: {
        TXT: 'Više informacija o upravljanju vašim podacima',
        LINK: '/kolačići#gdpr'
      }
    },
    MONTHLY_FOLDER: { TITLE: 'Mjesečne vijesti' },
    MINI_NEWSLETTER: {
      PLACEHOLDER: 'Vaša e-pošta',
      GDPR: {
        TXT: 'Više informacija o upravljanju vašim podacima',
        LINK: '/kolačići#gdpr'
      }
    },
    SOCIALITEM: { SHARE: 'podijeli', COMMENT: 'komentar', BONDUELLE: 'Bonduelle' },
    SEARCHRECIPE: { DISCOVER_ALL: 'pretraži po svim receptima!' },
    FORM: {
      ERROR: 'Nevažeće polje',
      PHOTO: { ADD: 'Dodaj sliku', DELETE: 'Izbriši sliku', SEE: 'Vidi' }
    }
  },
  CORPORATE: {
    COMPANY_STORY: 'priča o tvrtki',
    CSR_COMMITMENT: 'dop obveze',
    VEGETABLE_INNOVATIONS: 'biljne inovacije',
    NEWS: 'sve vijesti',
    SEE_MORE: 'Pogledajte još članaka',
    ON: 'članci o'
  },
  NEWSLETTER: {
    TITLE: 'Pretplatite se na bilten',
    SUBTITLE: 'Dobre ponude, kulinarska inspiracija i malo kulture!'
  },
  ROUTES: {
    products: 'proizvodi',
    news: 'vijesti',
    'since-1853': 'od-1853',
    'field-plate': 'polje-tanjur',
    'company-story': 'prica-o-tvrtki',
    'csr-commitments': 'dop-obveze',
    'vegetal-innovations': 'biljne-inovacije',
    magazine: 'magazin',
    all: 'sve',
    article: 'clanak',
    cook: 'kuhati',
    cultivate: 'uzgajati',
    explore: 'istraziti',
    recipe: 'pricekajte-recept',
    recipes: 'recepti',
    'recipes-home': 'recepti-domacinstvo',
    r: 'r',
    credits: 'credits-com',
    'legal-notice': 'pravna-obavijest',
    'terms-of-sales': 'uvjeti-prodaje',
    cookies: 'kolacici',
    sitemap: 'mapa web-stranice',
    'privacy-policy': 'politika-privatnosti',
    'all-rights-reserved': 'sva-prava-pridrzana',
    search: 'pretrazi',
    'articles-search': 'clanak',
    vegetables: 'povrce',
    p: 'p',
    contact: 'kontakt',
    'info-product': 'informacije-proizvod',
    'note-product': 'primijedba-proizvod',
    suggestion: 'sugestija',
    'other-request': 'informacije-proizvod',
    faq: 'cesto-postavljana-pitanja',
    'all-themes': 'sve-teme',
    'products-search': 'proizvodi-pretraga',
    recipesearch: 'recept-pretraga',
    'product-news': 'proizvod-vijesti',
    'good-deals': 'dobre-ponude',
    'not-found': 'nije-pronadjeno',
    'menu-page': 'inspiracija',
    'menu-one': 'zivi-zdravo',
    'menu-two': 'ukusno-se-kuva',
    'menu-three': 'kuhaj-sa-stilom',
    'menu-four': 'budi-kreativan',
    'menu-external': 'jelovnik-eksterni',
    'theme-pasta-rice': '',
    'theme-bowls': '',
    'theme-soup': '',
    'theme-snack': '',
    'theme-sauce': '',
    'theme-unique-plate': '',
    'theme-over': 'tema-zavrsena',
    'theme-legumin': 'mahunarke',
    'theme-ingredients-recipes': '',
    'theme-cook-plate': ''
  },
  BREAD: {
    products: 'proizvodi',
    news: 'vijesti',
    'since-1853': 'od-1853',
    'field-plate': 'polje-tanjur',
    'company-story': 'prica-o-tvrtki',
    'csr-commitments': 'dop-obveze',
    'vegetal-innovations': 'biljne-inovacije',
    magazine: 'magazin',
    all: 'sve',
    article: 'clanak',
    cook: 'kuhati',
    cultivate: 'uzgajati',
    explore: 'istraziti',
    recipe: 'pricekajte-recept',
    recipes: 'recepti',
    'recipes-home': 'recepti-domacinstvo',
    'articles-search': 'clanak',
    search: 'pretrazi',
    vegetables: 'povrce',
    contact: 'kontakt',
    'info-product': 'informacije-proizvod',
    'note-product': 'primijedba-proizvod',
    suggestion: 'sugestija',
    'other-request': 'informacije-proizvod',
    faq: 'cesto-postavljana-pitanja',
    'all-themes': 'sve-teme',
    'products-search': 'proizvodi-pretraga',
    recipesearch: 'recept-pretraga',
    'product-news': 'proizvod-vijesti',
    'good-deals': 'dobre-ponude',
    'not-found': 'nije-pronadjeno',
    'menu-page': 'Inspiracija',
    'menu-one': 'Zivi Zdravo',
    'menu-two': 'Ukusno Se Kuva',
    'menu-three': 'Kuhaj Sa Stilom',
    'menu-four': 'Budi Kreativan'
  },
  MENU_PAGE: {
    TITLE1: 'ŽIVJETI ZDRAVO',
    TITLE2: 'KUHAJTE UKUSNO',
    TITLE3: 'PRIPREMITE SA STILOM',
    TITLE4: 'BUDITE KREATIVNI',
    EXTERNAL: ''
  },
  TECHNICAL: { MENU_PAGE: 'posao_ponude' },
  SEO: {
    TITLE: {
      MENU_ONE: 'ŽIVITE ZDRAVO',
      MENU_TWO: 'KUHAJTE UKUSNO',
      MENU_THREE: 'PRIPREMITE SA STILOM',
      MENU_FOUR: 'BUDITE KREATIVNI',
      HOME: 'Bonduelle',
      SEARCH: {
        MAIN: '{{value}}',
        RECIPES: '{{value}}',
        VEGETABLES: '{{value}}',
        ARTICLES: '{{value}} Filtrirano prema članku | Bonduelle'
      },
      CONTACT: 'Bonduelle',
      RECIPES: 'Bonduelle',
      RECIPE_HOME: 'Bonduelle',
      RECIPE: 'Bonduelle',
      PRODUCTS: 'Bonduelle',
      NEWS: 'Bonduelle',
      CORPORATE: {
        NEWS: 'Bonduelle',
        COMPANY_STORY: 'Bonduelle',
        CSR_COMMITMENTS: 'Bonduelle',
        VEGETAL_INNOVATIONS: 'Bonduelle',
        FIELD_PLATE: 'Bonduelle',
        ARTICLE: '{{value}}',
        VEGETABLE: '{{value}}'
      },
      PRODUCT: '{{value}}',
      MAGAZINE: {
        NEWS: 'Bonduelle',
        ARTICLE: 'Bonduelle',
        COOK: 'Bonduelle',
        GROW: 'Bonduelle',
        EXPLORE: 'Bonduelle',
        RECIPE: 'Bonduelle'
      },
      FAQ: 'Bonduelle',
      NOTFOUND: 'Bonduelle',
      RECIPES_ALL: '',
      RECIPETYPE: '{{value}}',
      RECIPESEASON: '{{value}}',
      RECIPEPREPARATION: '{{value}}',
      RECIPEDIFFICULTY: '{{value}}',
      RECIPEVEGETABLE: '{{value}}',
      RECIPECOST: '{{value}}',
      RECIPEDISH: '{{value}}',
      RECIPEDESC: '{{value}}',
      '2RECIPEFACETS': '',
      '4RECIPEFACETS': '{{value}}',
      PRODUCTSEASON: '{{value}}',
      PRODUCTTECHNO: '{{value}}',
      PRODUCTVEGETABLE: '{{value}}',
      PRODUCTRANGE: 'Proizvodi iz asortimana {{value}}',
      THEME_ALL: '',
      THEME_STARTER: '',
      THEME_SALAD: '',
      THEME_CAN: '',
      THEME_FROZEN: '',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: '',
      THEME_RECIPE: '',
      THEME_PLATE: '',
      THEME_ACC: '',
      THEME_SNACK: '',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': '{{value}}'
    },
    DESCRIPTION: {
      MENU_ONE: 'Pročitajte sve o zdravim načinima života, pravilima prehrane, metodama pripreme i konzumacije povrća.',
      MENU_TWO: 'Otkrijte korisne kulinarske tajne za pripremu vaših omiljenih Bonduelle jela i recepata.',
      MENU_THREE: 'Sve o kulinarskim tajnama svjetskih kuhinja, razlikama između tradicionalnih jela i stilova hrane.',
      MENU_FOUR: 'Pročitajte savjete o hrani i članke o prehrani koje je pripremio Bonduelle. Saznajte više o prednostima i pripremi zdrave hrane.',
      HOME: '',
      SEARCH: {
        MAIN: '{{value}}',
        RECIPES: '{{value}}',
        VEGETABLES: '{{value}}',
        ARTICLES: '{{value}} : sortirano po članku. Jednostavni i ukusni recepti. Kuhano povrće.'
      },
      CONTACT: '',
      RECIPES: '',
      RECIPE_HOME: '',
      RECIPE: '',
      PRODUCTS: '',
      NEWS: '',
      CORPORATE: {
        NEWS: '',
        COMPANY_STORY: '',
        CSR_COMMITMENTS: '',
        VEGETAL_INNOVATIONS: '',
        VEGETABLE: '',
        FIELD_PLATE: '',
        ARTICLE: ''
      },
      PRODUCT: '{{value}}',
      MAGAZINE: {
        NEWS: '',
        ARTICLE: '',
        COOK: '',
        GROW: '',
        EXPLORE: '',
        RECIPE: ''
      },
      FAQ: '',
      PAGE: 'Stranica',
      RECIPETYPE: '{{value}}',
      RECIPESEASON: '{{value}}',
      RECIPEPREPARATION: '{{value}}',
      RECIPEDIFFICULTY: '{{value}}',
      RECIPEVEGETABLE: '',
      RECIPECOST: '{{value}}',
      RECIPEDISH: '{{value}}',
      RECIPEDESC: '{{value}}',
      '2RECIPEFACETS': '{{value}}',
      '4RECIPEFACETS': '{{value}}',
      PRODUCTSEASON: '{{value}}',
      PRODUCTTECHNO: '{{value}}',
      PRODUCTVEGETABLE: '{{value}}',
      PRODUCTRANGE: 'Otkrijte sve proizvode iz asortimana {{value}}',
      THEME_ALL: '',
      THEME_STARTER: '',
      THEME_SALAD: '',
      THEME_CAN: '',
      THEME_FROZEN: '',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: '',
      THEME_RECIPE: '',
      THEME_PLATE: '',
      THEME_ACC: '',
      THEME_SNACK: '',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': '{{value}}',
      NOTFOUND: ''
    },
    PIPE: { SOCIETY: '' },
    THEME_ALL: '',
    THEME_STARTER: '',
    THEME_SALAD: '',
    THEME_CAN: '',
    THEME_FROZEN: '',
    THEME_READY_MEALS: '',
    THEME_BIO: '',
    THEME_VEGETABLES: '',
    THEME_RECIPE: '',
    THEME_PLATE: '',
    THEME_ACC: '',
    THEME_SNACK: ''
  },
  VEGETABLE: {
    DISCOVER_VEGETABLE: 'Otkrijte naše povrtne listove',
    OURS_RECIPES: 'Naše recepti',
    DISCOVER_ALSO: 'Otkrijte također...',
    SEE_ALL: 'Vidi sve povrće',
    ON: 'Povrće na'
  },
  RECIPE: {
    'INGREDIENTS_LABEL ': 'Sastojci za {{value}} ljudi',
    PEOPLES: '{{value}} ljudi',
    DIFFICULTY: { EASY: 'Lako', MEDIUM: 'Srednje', HARD: 'Teško' },
    COST: { CHEAP: 'Jeftino', EXPENSIVE: 'Skupo' },
    TIMING: { MIN: 'Minuta', HOURS: 'sati' },
    STEP: 'Korak',
    DISCOVER_ALSO: 'Otkrijte također...',
    HOME_SEE_MORE: 'Vidi još recepata',
    SHOWCASE: 'Vrh',
    HOME_BOOK: 'Naši recepti',
    MIN_MORE: ' m i +',
    HOUR_MORE: 's i +',
    FILTER_BY_CRITERIA: 'Filtriraj prema kriterijima',
    SORT_BY: 'Poredaj po :',
    BEST_RATED: 'Najbolje ocijenjeno',
    MOST_CONSULTED: 'Najčešće konzultirano',
    MEMBERS_RECIPES: 'Recepti članova',
    CRITERIA: 'Vijesti',
    DISCOVER: { RECIPES: 'Otkrijte naše recepte' },
    DISCOVER_RECIPE: 'Otkrijte recept',
    INSTRUCTIONS: 'Instrukcije',
    DEFAULT_TAG: 'Ideja za recept',
    MOST_RECENT: 'Najnovije',
    RECIPE_VIDEOS: 'Videi sa receptima',
    PRODUCT_ASSOCIATE: 'Recept napravljen sa',
    BUDGET_LOW: 'Jeftino',
    BUDGET_HIGH: 'Visoko',
    BUDGET_MIDDLE: 'Srednje',
    PRINT_RECIPE: 'štampaj recept',
    LET_YOURSELF_SURPRISED: 'Budite iznenađeni',
    COOK_TIP_TITLE: 'Naslov savjeta za kuhanje',
    ENJOY_MEAL: 'Uživajte u obroku !',
    FILTER: { LEGEND: 'Legenda' }
  },
  PREDIGGO: { DIFFICULTY: { EASY: 'Lako', MEDIUM: 'srednje', HARD: 'Teško' } },
  COOKIES: {
    TEXT: 'Nastavkom posjete ovoj stranici prihvaćate korištenje kolačića iz tehničkih razloga, u svrhu statističke analize i upravljanja web-prometom, dijeljenja informacija s oglašivačima na drugim web-stranicama, te pružanja relevantnih promotivnih komunikacija koje odražavaju vaše interese.',
    CLOSE: 'Zatvori',
    MORE: 'Saznaj više',
    AGREE_YOUTUBE: 'Morate prihvatiti kolačiće s Youtubea kako biste vidjeli ovaj video',
    BUTTON_YOUTUBE: 'Prihvati kolačiće'
  },
  POPUP: { LAUNCH: 'ISKAČUĆE-OTVARANJE-STRANICA' },
  FIELD_PLATE: { DISCOVER_VEGETABLE: 'Otkrijte naše povrtne listove' },
  SEARCH: {
    OUR_RECIPES: 'Recepti',
    OUR_PRODUCTS: 'Proizvodi',
    OUR_ARTICLES: 'Naši članci',
    RECIPES: 'Recepti',
    PRODUCTS: 'Proizvodi',
    ARTICLES: 'Članci',
    ALL: 'Sve',
    SORT_BY: 'Sortiraj po',
    RESULTS: 'rezultati',
    RESULT: 'rezultat',
    PRODUCT_OUT: 'proizvodi od',
    APPLY: 'Primjeni',
    FILTER: 'Filtriraj',
    RECIPE_OUT: 'recepata od',
    NO_RESULT: 'Bijeli kupus za ovu pretragu ... Postavljate nam škakljivo pitanje',
    SEE_MORE: 'Vidi još',
    DISCOVER_RECIPES: 'Otkrijte naše recepte',
    SEE_MORE_OF: 'vidi još',
    DELETE: 'obriši'
  },
  RECIPE_LIST: {
    OUR_RECIPES: 'Recepti',
    RESULTS: 'Rezultati',
    SEE_MORE: 'Vidi još recepata'
  },
  PRODUCT: {
    BUTTON: {
      ACTU: 'vijesti o proizvodu',
      FOUND: 'Gdje pronaći ovaj proizvod',
      DISCOVER: 'otkrijte sve gama'
    },
    NUTRITIONAL_VALUE: 'nutritivna vrijednost',
    CONDITIONING: 'uvjeti pohrana',
    PORTIONS: 'porcije',
    NET_WEIGHT: 'neto težina',
    DRY_WEIGHT: 'suha težina',
    INGREDIENTS: 'sastojci',
    OUR_RECIPES: 'Otkrijte naše recepte',
    FOR: 'za',
    PER_PORTION: 'i po porciji od',
    ENERGY: 'energija',
    FAT: 'masti',
    SATURATE: 'zasićene masti',
    GLUCIDES: 'ugljikohidrati',
    FIBER: 'vlakna',
    PROTEINS: 'proteini',
    SALT: 'sol',
    DISCOVER_ALSO: 'otkrijte također...',
    MORE_INFORMATION: 'još informacija',
    TRACES: 'Tragovi',
    CONTAINS: 'Sadrži',
    SLIDER: { SHEET: 'pogledaj listu', BUY: 'kupi ovaj proizvod' },
    NUTRISCORE: 'informacije-nutri-ocjena',
    TRIMAN: 'informacije-triman',
    AGRICONFIANCE: 'informacije-agriconfiance',
    BIO: 'informacije-bio',
    PESTICIDE_FREE: '',
    LEAF: '',
    LOCK: '',
    SEE_ALL: 'Pogledajte sve proizvode',
    LEGISLATION: 'sukladno važećem zakonodavstvu.',
    PORTION: { SINGULAR: 'procija', PLURAL: 'porcije' },
    DISCLAIMER: '',
    COOKING: 'kuhanje',
    CONSERVATION: 'čuvanje'
  },
  FAQ: {
    ON: 'pitanja o',
    ALL: 'Sve',
    SEE_MORE: 'vidi još pitanja',
    TITLE_1: 'Svi odgovori na vaša pitanja',
    TITLE_2: 'Imate li pitanje o određenom proizvodu ?',
    LINK_LABEL: 'Pišite nam'
  },
  FORM: {
    GO_TO_STEP: 'Idi na korak',
    STEP: 'Korak',
    RECEIVE_EMAIL_COPY: 'Primi kopiju e-maila',
    SEND: 'Pošalji',
    GDPR: 'Više o upravljanju vašim podacima',
    ERROR: 'Sva polja označena sa * moraju biti popunjena i ispravna',
    SEND_ERROR: 'Došlo je do pogreške, molimo pokušajte ponovno za nekoliko trenutaka',
    NOTICE: 'Za pravilno postupanje s vašom datotekom, Bonduelle može, ovisno o slučaju, zatražiti vaše zdravstvene podatke. Nisu obavezni i slobodni ste da ih ne prenosite.',
    SENDING: 'Slanje obrasca...',
    LOADING: 'Učitavanje...',
    PRODUCT_INFORMATION_TITLE: 'Informacije o proizvodu',
    PRODUCT_INFORMATION: {
      SEO_TITLE: 'Informacije o proizvodu | Bonduelle',
      SEO_DESC: 'Kontaktirajte našu službu za korisnike'
    },
    INFORMATIONS: 'vaše informacije',
    MISTER: 'Gospodin',
    MISS: 'Gospođica',
    NAME: 'Ime',
    OUR_NAME: 'Naše ime',
    FIRSTNAME: 'Osobno ime',
    OUR_FIRSTNAME: 'Naše osobno ime',
    MAIL: 'vaš e-mail',
    MAIL_PLH: 'mail@address.com',
    MAIL_CONFIRM: 'potvrdite svoj e-mail',
    MAIL_ERROR: 'molimo, napišite ispravan e-mail',
    MAIL_ERROR_CONFIRM: 'molimo, potvrdite svoj e-mail',
    ADDRESS: 'vaša adresa',
    STREET_PLH: '',
    STREET: 'Ulica i broj ulice',
    ADDRESS_COMPLEMENT: 'više informacija o vašoj adresi',
    ADDRESS_COMP_PLH: 'više informacija o vašoj adresi',
    ZIPCODE: 'poštanski broj',
    CITY: 'grad',
    CITY_PLH: 'vaš grad',
    PHONE: 'telefon',
    PHONE_PLH: 'broj telefona',
    COUNTRY: 'zemlja',
    PRODUCT_INFORMATIONS: 'informacije o proizvodu',
    PRODUCT_INFORMATION_DESC: 'Kako bi naše usluge mogle najbolje proučiti vaš<br>zahtjev, molimo vas da unesete sljedeće informacije:',
    PRODUCT: {
      DETAIL: {
        DESC: 'Za svaki traženi kriterij možete popuniti polje obrasca<br>i dodati ilustracijsku fotografiju. Informacija: Vaša fotografija mora omogućiti jasno čitanje<br>barkoda, broja serije i roka trajanja proizvoda.'
      },
      NOTE: {
        TITLE: 'Napravite primjedbu o proizvodu',
        SEO_TITLE: 'Molimo, podijelite svoje mišljenje o proizvodu | Bonduelle',
        SEO_DESC: 'Imate li primjedbu ili komentar o proizvodu iz naše ponude ? Kontaktirajte našu službu za korisnike kako bismo vam pomogli. Odgovorit ćemo vam u roku od 48 sati'
      }
    },
    PRODUCT_RANGE: 'Asortiman proizvoda koji su uključeni',
    PRODUCT_NAME: 'Ime proizvoda',
    PRODUCT_NAME_PLH: 'Molimo, napišite ime proizvoda tvrtke Bonduelle o kojem je riječ u vašem zahtjevu',
    PRODUCT_INFORMATIONS_DETAIL: 'Detaljne informacije',
    PRODUCT_INFORMATIONS_LABEL: 'Šifra proizvoda na traci',
    PRODUCT_INFORMATIONS_PLH: 'Kod trake sastoji se<br>od 13 brojeva koji se nalaze na<br>ambalaži vašeg proizvoda.',
    PRODUCT_LOT: 'Broj partije proizvoda',
    PRODUCT_LOT_INFO: 'Broj serije nalazi se blizu<br>roka trajanja<br>i sastoji se od nekoliko brojeva i slova<br>raspoređenih na nekoliko linija/ Uvijek počinje<br>slovom L<br> nakon kojeg slijedi nekoliko brojeva i varira<br> ovisno o proizvodu.<br><br>Na primjer, L72450492107:13.',
    PHOTO: 'Dodajte fotografiju',
    EXPIRED_DATE: 'Molimo, navedite datum do kojeg je najbolje upotrijebiti (DLUO) /datum isteka roka trajanja (DLC).',
    EXPIRED_DATE_ERROR: 'Molimo, unesite valjani datum u formatu dd/mm/gggg',
    EXPIRED_DATE_DESC: 'DLUO (Datum do kojeg je najbolje upotrijebiti)<br>i DLC (Datum isteka roka trajanja)<br>prikazani su u obliku datuma.',
    PHOTO_MORE: 'Dodatne fotografije',
    MESSAGE: 'Vaša poruka *',
    OUR_MESSAGE: 'molim, napišite svoju poruku',
    SALES_OPERATIONS: {
      TITLE: 'Informacije o poslovnom poduhvatu',
      SEO_TITLE: 'Podaci o trenutnom poslovanju : natjecanje... | Bonduelle',
      SEO_DESC: 'Molimo, kontaktirajte našu službu za korisnike radi informacija o trenutnim poslovnim operacijama. Odgovorit ćemo vam u roku od 48 sati'
    },
    PRESS_SERVICE: 'Kontaktirajte službu za odnose s javnošću',
    FOOD_SERVICE: 'Kontaktirajte tim Bonduelle Food Service',
    BUSINESS_INFORMATION: 'Primite uzorke/vaučere/promotivne artikle',
    MODAL_INFORMATION: '',
    JOB_OFFER: { TITLE: '', SEO_TITLE: '', SEO_DESC: '' },
    WEB_SUGGESTION: {
      TITLE: 'Molimo, napravite prijedlog u vezi s web-stranicom',
      SEO_TITLE: 'Poruka za poboljšanje, prijedlog za web-stranicu | Bonduelle',
      SEO_DESC: 'Molimo, kontaktirajte našu službu za korisnike kako biste nam pomogli poboljšati web-stranicu.'
    },
    ANOTHER: {
      TITLE: 'Molimo, postavite drugi zahtjev',
      SEO_TITLE: 'Kontakt za sve ostale upite, usluge | Bonduelle',
      SEO_DESC: 'Molimo, kontaktirajte našu službu za korisnike radi informacija, usluga ili prijedloga. Odgovorit ćemo vam u roku od 48 sati'
    },
    AMBASSADOR: 'Postanite Bonduelle ambasador',
    CATEGORY: {
      SALAD: 'Salata',
      FROZEN: 'zaleđeno',
      CAN: 'konzerva',
      TAKEAWAY: 'za ponijeti',
      VEGETABLE: ''
    }
  },
  CONTACT: {
    TITLE: 'Vama na usluzi',
    SPECIAL_INFO: '',
    TAKE_CARE: '',
    BY_MAIL: 'Distributer',
    BY_PHONE: '',
    BY_SOURDLINE: '',
    MAIL_TEXT: 'Orbico d.o.o.\r\n' +
      'Koturaška 69, 10000 Zagreb, Hrvatska\r\n' +
      '+385 1 3444 800\r\n' +
      'info.hr@orbico.com\r\n' +
      'https://www.orbico.com/hrv/hr/\r\n' +
      '\r\n' +
      '\r\n' +
      'Stanić d.o.o.\r\n' +
      'Kerestinečka cesta 57/A Kerestinec, 10431 Sveta Nedelja, Hrvatska\r\n' +
      '+385 1 2481 888\r\n' +
      'stanic@stanic.hr\r\n' +
      'https://stanic.hr/',
    PHONE_TEXT: '',
    SOURDLINE_TEXT: '',
    BY_DISTRIBUTOR: '',
    BY_DISTRIBUTOR_TEXT: '',
    SUBTITLE: 'Želite nas kontaktirati zbog :',
    AGREEMENT: '',
    READ_MORE: "Također, imate pravo u bilo kojem trenutku zatražiti od BONDUELLE da ostvarite svoja prava na pristup, ispravak, brisanje i prenosivost vaših osobnih podataka, kao i prava na ograničenje i prigovor na obradu vaših osobnih podataka. Za ostvarivanje prava koja se odnose na vaše osobne podatke, molimo pišite na Dpo_france(at)bonduelle(dot)com ili poštom na sljedeću adresu: za pozornost Službenika za zaštitu podataka, Bonduelle, Rue Nicolas Appert, poštanski broj 30173, F-59653 Villeneuve D'Ascq. Da biste saznali više o načinu na koji BONDUELLE prikuplja i koristi vaše lične podatke, kliknite ovdje.",
    READ_MORE_LABEL: 'Pročitajte također',
    SATISFACTION_TEXT: 'Kako bismo vas kontaktirali nakon komentara o našim proizvodima, omogućili da se pozabavimo vašom pritužbom i poslali vam upitnik o zadovoljstvu ako to želite, obradit ćemo informacije koje ste dali u svojstvu voditelja obrade podataka. Pravna osnova za obradu vašeg zahtjeva je pristanak zbog potencijalnih podataka o zdravlju koje možete navesti u svom zahtjevu. Ove informacije bit će pohranjene koliko je potrebno za obradu vašeg zahtjeva i do 5 godina kako bismo pružili dokaz da je vaš zahtjev obrađen i kako bismo se pridržavali naših zakonskih obveza. Pravna osnova za slanje upitnika o zadovoljstvu također je vaš pristanak. Ove informacije bit će pohranjene za vrijeme potrebno za slanje upitnika o zadovoljstvu i do maksimalno 1 godine. Za više informacija o obradi vaših osobnih podataka, molimo vas da konzultirate našu <a href="https://www.bonduelle.hr/politika-privatnosti" target="_blank" class="underline">Politiku privatnosti</a>.',
    SATISFACTION_CHECKBOX: 'Markiranjem ovog okvira, pristajete primiti upitnik o zadovoljstvu (koji se sastoji od jednog pitanja) nakon što vaša zahtjev bude obrađen. To je opcionalno.',
    CHECKBOX_TEXT: 'Markiranjem ovog okvira, pristajete da Bonduelle može obraditi osobne zdravstvene podatke koje možete navesti u svom poruci isključivo s ciljem odgovaranja na vaš zahtjev. <strong>To je obavezno za obradu vašeg zahtjeva</strong>.',
    PHONE: 'Od ponedjeljka do petka od 9 do 18 sati na broj 00.33.09.70.25.22.22 (pozivi se ne naplaćuju, osim vikendom i praznicima)',
    CALL_US: 'Da nas pozovete',
    TO_WRITE: 'Pišite nam',
    PHONE_NUMBER: '3.3097E+11',
    SUCCESS_MESSAGE: 'Hvala ! Vaša poruka je poslana našem timu. Bit će obrađena u prosječnom vremenu\n' +
      ' Od 2 do 3 dana, isključujući vikende i praznike. <br><br>\n' +
      ' Naravno, ostajemo vam na raspolaganju od ponedjeljka do petka od 9 do 18 sati na broju 330970252222 (poziv se ne naplaćuje dodatno).<br><br>\n' +
      ' Kao dio praćenja kvalitete naše usluge, svi naši razgovori mogu biti snimljeni.',
    PHOTOERROR: { MESSAGE: '' }
  },
  GLOBAL: { READ_MORE: 'Pročitaj više' },
  FLOWBOX: {
    GENERIC_KEY: 'ba_GnGC3TKedKEe0-gKomQ',
    PRODUCT_KEY: '1hRXF7TUSt-fhhlJASv-TQ',
    RECIPE_KEY: 'Ulp6NW8-QFOoSa61nBIlaA',
    HOME_KEY: 'g7--tXG3QgiwJjjpsaMmrg'
  },
  TOKEN: {
    FACEBOOK: '',
    PAGE_FACEBOOK_ID: '',
    INSTAGRAM: '',
    INSTA_ID: '17841407279489116',
    YOUTUBE: '',
    TWITTER: '',
    MAGAZINE: '6318061952.9bbab10.8842be1c95904c9d80ff59765a3a2ccc'
  },
  COOKIE: {
    BANNER: {
      MANDATORY: 'Potrebno',
      ANALYTICS: 'ANALITIKA',
      MARKETING: 'MARKETING',
      SOCIAL_MEDIA: 'DRUŠTVENE MREŽE',
      ACCEPT: 'Prihvati sve',
      REJECT: 'Odbij sve',
      SET_UP: 'Konfiguracija',
      MANDATORY_DETAILS: 'Potrebni kolačići su nužni za rad web-stranice, na primjer za pohranu vaših podataka za prijavu i osiguravanje sigurne veze.\n' +
        ' Kolačići potrebni za korištenje stranice bonduelle.fr su : \n' +
        ' <ul>\n' +
        ' <li>kolačići sesije (SESSION_ID): Kolačić generiran od strane PHP baziranih aplikacija. Ovo je opći identifikator koji se koristi za upravljanje korisničkim varijablama sesije. To je nasumično generirani broj, način na koji se koristi može biti specifičan za web-stranicu, ali dobar primjer je održavanje statusa prijave korisnika između stranica.\n' +
        ' </ul>',
      ANALYTICS_DETAILS: 'Funkcionalni kolačići pomažu nam da optimiziramo web-stranicu, na primjer, za prikupljanje statistike, za bilježenje vaših preferencija za društvene interakcije ili za mjerenje performansi i stabilnosti naše aplikacije.\n' +
        ' <br>\n' +
        ' Analitički kolačići korišteni na bonduelle.com stranici su : \n' +
        ' <ul>\n' +
        ' <li>DoubleClick: Ovaj kolačić postavlja DoubleClick (vlasništvo Googlea) kako bi se kreirao profil interesa posjetitelja web-stranice i prikazale relevantne reklame na drugim web-stranicama. Glavna djelatnost tvrtke je razmjena oglasa s Googleovim licitiranjem reklama u stvarnom vremenu.</li>\n' +
        ' <li>Google analitika: ovi kolačići omogućuju praćenje najposjećenijih stranica i prometa na web-stranici.</li>\n' +
        ' <li>UTMTracking: ovaj kolačić omogućuje upravljanje UTM varijablama za optimalno praćenje različitih online kampanja za oglašavanje</li>.\n' +
        ' </ul>',
      MARKETING_DETAILS: 'Marketinški kolačići omogućuju nam praćenje vaših preferencija za recepte i proizvode kako bismo vam uvijek ponudili proizvode koji najbolje odgovaraju vašim željama.\n' +
        ' <br>\n' +
        ' Kolačići za marketing koji se koriste na web-stranici bonduelle.fr su : \n' +
        ' <ul>\n' +
        ' <li> Prediggo: ovaj alat koristi SESSION_ID kolačić kako bi vam ponudio proizvode i recepte koji najbolje odgovaraju vašem pregledavanju i pretraživanju. </li>\n' +
        ' <li>ClickToBuy: ovaj kolačić nam omogućuje da zapamtimo vašu lokaciju kako bismo vam mogli ponuditi najbliže trgovine koje imaju Bonduelle proizvode koje tražite.</li>\n' +
        ` <li>Društvene mreže: ovi kolačići omogućuju prikazivanje Bonduelle 'SocialWall' na početnoj web-stranici. Ovaj "zid" omogućuje vam da vidite sve najnovije objave s glavnih društvenih mreža koje koriste timovi tvrtke Bonduelle.</li>\n` +
        ' <li>FlowBox: ovaj kolačić omogućuje korištenje usluga softvera FlowBox, koji nam omogućuje da ponudimo našim posjetiteljima recepte i kreacije drugih internetskih korisnika koji koriste Bonduelle proizvode.</li>\n' +
        ' </ul> "',
      V2: 'ok'
    },
    MODAL: {
      TITLE: 'Upravljanje kolačićima',
      TEXT: 'Grupa Bonduelle posvećena je osiguravanju zaštite osobnih podataka korisnika svojih web-stranica, posebno web-stranice Bonduelle.fr. Kako biste mogli koristiti usluge koje nudi web-stranica, poput pregledavanja proizvoda, otkrivanja novih gurmanskih recepata, pretplate na određene usluge poput naših biltena, te optimizacije njezine uporabe i personalizacije prema vašem profilu, web-stranica koristi kolačiće. Bonduelle grupa se u potpunosti obvezuje osigurati visoku razinu zaštite takvih osobnih podataka i pridržavati se svih primjenjivih pravila o zaštiti podataka prilikom obrade istih u okviru korištenja njezine web-stranice.\n' +
        ' Stoga, možete u bilo kojem trenutku aktivirati/deaktivirati kolačiće.',
      CONFIG: 'Konfiguracija',
      SAVE: 'Sačuvaj'
    }
  },
  LINK: { JOB_OFFERS: 'https://jobs.bonduelle.com/?locale=en_US' },
  CLIC2BUY: { INFOS: '' }
};
