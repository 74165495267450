import { Component, OnInit, Input } from '@angular/core';
import { SfSectionSlider } from '../../models/section-slider.interface';

@Component({
    selector: 'sf-section-slider',
    templateUrl: './section-slider.component.html',
    styleUrls: ['./section-slider.component.sass'],
    standalone: false
})
export class SfSectionSliderComponent implements OnInit {

  @Input() section: SfSectionSlider;
  @Input() isPinterestVisible = false;

  @Input() seoTitle: 'H1'|'H2'|'H3'|'DIV' = 'DIV';

  constructor() { }

  ngOnInit() {
    this.section.slides.forEach(s => {
      s.link = this.section.link
    })
  }

}
