import { Component, Input, ViewEncapsulation, OnInit } from '@angular/core';
import { SfItem } from '../models/item.interface';
import { SfMessageService } from '../../../core/message/services/message.service';
import { SfLink } from '../../link';

@Component({
    selector: 'sf-item',
    templateUrl: './item.component.html',
    styleUrls: ['./item.component.sass'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SfItemComponent implements OnInit {

  /**
   * Data item
   *
   * @memberof SfItemComponent
   */
  @Input() item: SfItem;
  @Input() packshot = false;

  @Input() seoTag: 'H1'|'H2'|'H3'|'DIV' = 'DIV';
  @Input() seoTitle: 'H1'|'H2'|'H3'|'DIV' = 'DIV';

  public hover: boolean;

  public truncateNb = 50;

  public smallDevice = false;

  constructor(
    public eventService: SfMessageService
  ) { }

  ngOnInit() {
    if (this.packshot) {
      this.truncateNb = 40;
    }
  }

  setEvent(item) {
    if (item && item.tag) {
      this.eventService.setEvent(item.title, item.tag.name)
    }
  }

  get link() {
    let link = this.item.link || {} as SfLink;

    // Drupal 9: link is in item.type
    if (!this.item.link && this.item['type'] && this.item['type'][0]) {
      link = this.item['type'][0]['link'];
    }

    // Drupal 9: replace undefined value from URL, due to field missing on Drupal update
    if (this.item['path'] && this.item['path'][0] && this.item['nid']) {
      // First "undefined" is item alias
      link['url'] = link['url'].replace('undefined', this.item['path'][0]['alias'].replace('/', ''));
      // The 2 others are item ID
      link['url'] = link['url'].replace(/undefined/g, this.item['nid'][0]['value']);
    }

    // No item.link, build all
    if (!this.item.link && this.item['path'] && this.item['path']['alias'] && this.item['nid']) {
      link['url'] = this.item['path']['alias'] + `/${this.item['nid']}/${this.item['nid']}`;
    }

    // SEO: All links to lower case
    link['url'] = link['url'].toLowerCase();

    return link;
  }


}
