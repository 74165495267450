export function replaceSpecialChars(text: string, lang: string): string {
  const translationMap = translations[lang]; // Sélectionne la table de traduction en fonction de la langue donnée
  const translationPunctuation = translations.punctuation
  if (!translationMap) {
    console.error(`Table de traduction pour la langue '${lang}' non trouvée`);
    return text;
  }
  
  let processedText = text
    .split("")
    .map((char) => translationMap[char] || char)
    .join("");

  // Étape 2 : Remplacement de la ponctuation
  if (translationPunctuation) {
    processedText = processedText
      .split("")
      .map((char) => translationPunctuation[char] || char)
      .join("");
  }
  processedText = processedText
  .replaceAll(/\u200B/g, '') // Zero-width space
  .replaceAll(/\u00AD/g, '') // Soft Hyphen
  .replaceAll(/\u00A0/g, '-') // Non-breaking space
  // Special chars
  .replaceAll(/ /g, '-')
  .replaceAll(/---/g, '-')
  .replaceAll(/--/g, '-')
  .replaceAll(/[_'«»₣!"#()*,`´:;@^}{+|~‚„…†‡‹‘’“”•–—›¡%¦©ª¬®°µ¶º¿℅§¨―‣‼]/g, '')
  .replaceAll(/&/g, '%26')
  .replaceAll(/²/g, '2')
  .replaceAll(/³/g, '3')



  return processedText;
}

export const translations: { [key: string]: { [key: string]: string } } = {
  at: {
    ä: "ae",
    É: "e",
    é: "e",
    Ö: "oe",
    ö: "oe",
    Ü: "ue",
    ü: "ue",
    ß: "ss",
    "€": "euro",
    "£": "pfund",
  },
  bg: {
    А: "a",
    а: "a",
    ь: "y",
    Б: "b",
    б: "b",
    В: "v",
    в: "v",
    Г: "g",
    г: "g",
    Д: "d",
    д: "d",
    Е: "e",
    е: "e",
    Ж: "zh",
    ж: "zh",
    З: "z",
    з: "z",
    И: "i",
    и: "i",
    Й: "y",
    й: "y",
    К: "k",
    к: "k",
    Л: "l",
    л: "l",
    М: "m",
    м: "m",
    Н: "n",
    н: "n",
    О: "o",
    о: "o",
    ö: "o",
    П: "p",
    п: "p",
    Р: "r",
    р: "r",
    С: "s",
    с: "s",
    Т: "t",
    т: "t",
    У: "u",
    у: "u",
    Ф: "f",
    ф: "f",
    Х: "h",
    х: "h",
    Ц: "ts",
    ц: "ts",
    Ч: "ch",
    ч: "ch",
    Ш: "sh",
    ш: "sh",
    Щ: "sht",
    щ: "sht",
    Ъ: "b",
    ъ: "a",
    Ю: "yu",
    ю: "yu",
    Я: "ya",
    я: "ya",
  },
  bs: {
    А: "a",
    а: "a",
    B: "b",
    b: "b",
    C: "c",
    c: "c",
    Č: "c",
    č: "c",
    Ć: "c",
    ć: "c",
    D: "d",
    d: "d",
    Dž: "dz",
    dž: "dz",
    Đ: "dj",
    đ: "dj",
    E: "e",
    e: "e",
    F: "f",
    f: "f",
    G: "g",
    g: "g",
    H: "h",
    h: "h",
    I: "i",
    i: "i",
    J: "j",
    j: "j",
    K: "k",
    k: "k",
    L: "l",
    l: "l",
    Lj: "lj",
    lj: "lj",
    M: "m",
    m: "m",
    N: "n",
    n: "n",
    Nj: "nj",
    nj: "nj",
    O: "o",
    o: "o",
    P: "p",
    p: "p",
    R: "r",
    r: "r",
    S: "s",
    s: "s",
    Š: "s",
    š: "s",
    T: "t",
    t: "t",
    U: "u",
    u: "u",
    V: "v",
    v: "v",
    Z: "z",
    z: "z",
    Ž: "z",
    ž: "z",
    Y: "Y",
    y: "y",
  },
  cs: {
    á: "a",
    Ď: "d",
    ď: "d",
    Č: "c",
    č: "c",
    É: "e",
    é: "e",
    Ě: "e",
    ě: "e",
    Í: "i",
    í: "i",
    Ň: "n",
    ň: "n",
    Ó: "o",
    ó: "o",
    Ř: "r",
    ř: "r",
    Ú: "u",
    ú: "u",
    Ů: "u",
    ů: "u",
    Ý: "y",
    ý: "y",
    Ž: "z",
    ž: "z",
  },
  da: {
    æ: "ae",
    Ø: "oe",
    ø: "oe",
    Å: "aa",
    å: "aa",
  },
  de: {
    ä: "ae",
    É: "e",
    é: "e",
    Ö: "oe",
    ö: "oe",
    Ü: "ue",
    ü: "ue",
    ß: "ss",
    "«": "",
    "»": "",
    "„": "",
    "“": "",
    "”": "",
    "°": "",
    "€": "euro",
    "£": "pfund",
    "©": "",
    "@": "",
    "®": "",
  },
  el: {
    ά: "a",
    α: "a",
    Α: 'a',
    β: "b",
    Β: 'b',
    B: 'b',
    Δ: 'd',
    δ: "d",
    ε: "e",
    έ: 'e',
    ή: 'e',
    Ε: 'e',
    φ: "f",
    Φ: 'f',
    Γ: 'g',
    γ: "g",
    η: "h",
    Η: 'h',
    θ: 'th',
    Θ: 'th',
    ι: "i",
    ΐ: "i",
    Ι: 'i',
    ί: "i",
    Ϊ: 'i',
    ϊ: 'i',
    ξ: "j",
    Ξ: 'j',
    κ: "k",
    Κ: 'k',
    λ: "l",
    Λ: 'l',
    μ: "m",
    Μ: 'm',
    ν: "n",
    Ν: 'n',
    ο: "o",
    ό: 'o',
    Ο: 'o',
    π: "p",
    Π: 'p',
    Ρ: 'r',
    ρ: "r",
    σ: "s",
    V: 's',
    ψ: 'ps',
    Ψ: 'ps',
    ω: 'o',
    ώ: 'o',
    ς: 's',
    Σ: 's',
    τ: "t",
    Τ: 't',
    ύ: 'y',
    υ: "y", 
    χ: "x",
    Χ: 'x',
    ζ: 'z',
    Ζ: 'z',
    
  },
  es: {
    á: "a",
    É: "e",
    é: "e",
    Í: "i",
    í: "i",
    Ó: "o",
    ó: "o",
    Ú: "u",
    ú: "u",
    Ü: "u",
    ü: "u",
    "&": "y",
    "€": "euros",
  },
  et: {
    ä: "a",
    õ: "o",
    ü: "u",
    ž: "z",
    š: "s",
    Õ: "O",
    Š: "S",
    Ö: "O",
  },
  fi: {
    ä: "ae",
    Ö: "oe",
    ö: "oe",
  },
  fr: {
    "´": "",
    à: "a",
    Â: "a",
    â: "a",
    ä: 'a',
    Ä: 'a',
    Æ: "ae",
    æ: "ae",
    Ç: "c",
    ç: "c",
    È: "e",
    è: "e",
    É: "e",
    é: "e",
    Ê: "e",
    ê: "e",
    Ë: "e",
    ë: "e",
    Î: "i",
    î: "i",
    Ï: "i",
    ï: "i",
    Ô: "o",
    ô: "o",
    Œ: "oe",
    œ: "oe",
    Ù: "u",
    ù: "u",
    Û: "u",
    û: "u",
    Ü: "u",
    ü: "u",
    "«": "",
    "»": "",
    "€": "euros",
    "₣": "euros",
  },
  hr: {
    А: "a",
    а: "a",
    B: "b",
    b: "b",
    C: "c",
    c: "c",
    Č: "c",
    č: "c",
    Ć: "c",
    ć: "c",
    D: "d",
    d: "d",
    Dž: "dz",
    dž: "dz",
    Đ: "dj",
    đ: "dj",
    E: "e",
    e: "e",
    F: "f",
    f: "f",
    G: "g",
    g: "g",
    H: "h",
    h: "h",
    I: "i",
    i: "i",
    J: "j",
    j: "j",
    K: "k",
    k: "k",
    L: "l",
    l: "l",
    Lj: "lj",
    lj: "lj",
    M: "m",
    m: "m",
    N: "n",
    n: "n",
    Nj: "nj",
    nj: "nj",
    O: "o",
    o: "o",
    P: "p",
    p: "p",
    R: "r",
    r: "r",
    S: "s",
    s: "s",
    Š: "s",
    š: "s",
    T: "t",
    t: "t",
    U: "u",
    u: "u",
    V: "v",
    v: "v",
    Z: "z",
    z: "z",
    Ž: "z",
    ž: "z",
    Y: "Y",
    y: "y",
  },
  hu: {
    á: "a",
    É: "e",
    é: "e",
    Í: "i",
    í: "i",
    Ö: "o",
    ö: "o",
    Ó: "o",
    ó: "o",
    Ő: "o",
    ő: "o",
    Ú: "u",
    ú: "u",
    Ű: "u",
    ű: "u",
  },
  it: {
    á: "a",
    À: "a",
    à: "a",
    É: "e",
    é: "e",
    È: "e",
    è: "e",
    Í: "i",
    í: "i",
    Ì: "i",
    ì: "i",
    Ó: "o",
    ó: "o",
    Ò: "o",
    ò: "o",
    Ú: "u",
    ú: "u",
    Ù: "u",
    ù: "u",
    "«": ".",
    "»": ".",
    "–": ".",
    '"': ".",
    "“": ".",
    "”": ".",
    "‘": ".",
    "’": ".",
    "&": "e",
    ">": ".",
    "<": ".",
    "€": "e",
  },
  lt: {
    č: "c",
    ę: "e",
    ė: "e",
    į: "i",
    š: "s",
    ų: "u",
    ū: "u",
    "&": "ir",
    Ą: "A",
    Č: "C",
    Ę: "E",
    Ė: "E",
    Į: "I",
    Š: "S",
    Ū: "U",
    Ų: "U",
  },
  lv: {
    š: "s",
    Š: "S",
    ļ: "l",
    ē: "e",
    ģ: "g",
    ī: "i",
    ķ: "k",
    ž: "z",
    Ķ: "K",
    ņ: "n",
    ū: "u",
    Ā: "A",
  },
  nl: {
    ï: "i",
  },
  no: {
    æ: "ae",
    Ø: "oe",
    ø: "oe",
    Å: "aa",
    å: "aa",
  },
  pl: {
    ą: "a",
    Ę: "e",
    ę: "e",
    Ó: "o",
    ó: "o",
    Ć: "c",
    ć: "c",
    Ł: "l",
    ł: "l",
    Ń: "n",
    ń: "n",
    Ś: "s",
    ś: "s",
    Ź: "z",
    ź: "z",
    Ż: "z",
    ż: "z",
  },
  pt: {
    á: "a",
    À: "a",
    à: "a",
    â: "a",
    Â: "a",
    Ã: "a",
    ã: "a",
    Ç: "c",
    ç: "c",
    È: "e",
    è: "e",
    É: "e",
    é: "e",
    Ê: "e",
    ê: "e",
    Î: "I",
    î: "i",
    Ô: "o",
    ô: "o",
    Ó: "o",
    ó: "o",
    Ù: "u",
    ù: "u",
    Û: "u",
    û: "u",
    Ú: "u",
    ú: "u",
    Ü: "u",
    ü: "u",
    "&": "e",
    "€": "euros",
  },
  ro: {
    Ă: 'a',
    ă: 'a',
    Â: 'a',
    â: 'a',
    Î: 'i',
    î: 'i',
    Ș: 's',
    ș: 's',
    Ț: 't',
    ț: 't'
  },
  sk: {
    ä: "a",
    Á: "a",
    á: "a",
    Č: "c",
    č: "c",
    Ď: "d",
    ď: "d",
    dž: "dz",
    É: "e",
    é: "e",
    Í: "i",
    í: "i",
    Ľ: "l",
    ľ: "l",
    Ň: "n",
    ň: "n",
    Ó: "o",
    ó: "o",
    Ô: "o",
    ô: "o",
    Ř: "r",
    ř: "r",
    Š: "s",
    š: "s",
    Ť: "t",
    ť: "t",
    Ý: "y",
    ý: "y",
    Ž: "z",
    ž: "z",
  },
  sl: {
    á: "a",
    Ą: "a",
    ą: "a",
    Ä: "ae",
    ä: "a",
    É: "e",
    é: "e",
    Ę: "e",
    ę: "e",
    Ě: "e",
    ě: "e",
    Í: "I",
    í: "i",
    Ó: "o",
    ó: "o",
    Ô: "o",
    ô: "o",
    Ú: "u",
    ú: "u",
    Ů: "u",
    ů: "u",
    Ý: "y",
    ý: "y",
    Č: "c",
    č: "c",
    ď: "d",
    ť: "t",
    Ĺ: "l",
    ĺ: "l",
    Ň: "n",
    ň: "n",
    Ŕ: "r",
    ŕ: "r",
    Ř: "r",
    ř: "r",
    s: "s",
    z: "z",
  },
  sq: {
    ç: "c",
    dh: "d",
    ë: "e",
    gj: "g",
    nj: "n",
    rr: "r",
    sh: "s",
    th: "t",
    xh: "x",
    zh: "z",
  },
  sr: {
    B: "b",
    b: "b",
    C: "c",
    c: "c",
    Č: "c",
    č: "c",
    Ć: "c",
    ć: "c",
    D: "d",
    d: "d",
    Dž: "dz",
    dž: "dz",
    Đ: "dj",
    đ: "dj",
    E: "e",
    e: "e",
    F: "f",
    f: "f",
    G: "g",
    g: "g",
    H: "h",
    h: "h",
    I: "i",
    i: "i",
    J: "j",
    j: "j",
    K: "k",
    k: "k",
    L: "l",
    l: "l",
    Lj: "lj",
    lj: "lj",
    M: "m",
    m: "m",
    N: "n",
    n: "n",
    Nj: "nj",
    nj: "nj",
    O: "o",
    o: "o",
    P: "p",
    p: "p",
    R: "r",
    r: "r",
    S: "s",
    s: "s",
    Š: "s",
    š: "s",
    T: "t",
    t: "t",
    U: "u",
    u: "u",
    V: "v",
    v: "v",
    Z: "z",
    z: "z",
    Ž: "z",
    ž: "z",
  },
  sv: {
    ä: "ae",
    Ö: "oe",
    ö: "oe",
    Å: "aa",
    å: "aa",
  },
  punctuation: {
    '"': "",
    "#": "",
    "&": "et",
    "(": "",
    ")": "",
    "*": "",
    ",": "",
    "-": "-",
    ".": ".",
    "/": "",
    ":": "",
    ";": "",
    "?": "",
    "@": "",
    "[": "",
    "\\": "",
    "]": "",
    "^": "",
    "_": "",
    "{": "",
    "|": "",
    "}": "",
    "~": "",
    "‚": "",
    "„": "",
    "…": "",
    "†": "",
    "‡": "",
    "‹": "",
    "‘": "",
    "’": "",
    "“": "",
    "”": "",
    "•": "",
    "–": "-",
    "—": "-",
    "™": "tm",
    "›": "",
    "¡": "",
    "¦": "",
    "©": "",
    ª: "",
    "«": ".",
    "¬": "",
    "®": "",
    "°": "",
    "²": "2",
    "³": "3",
    µ: "",
    "¶": "",
    "·": ".",
    º: "",
    "»": ".",
    "¿": "",
    "℅": "",
    ⁿ: "n",
    "§": "",
    "¨": "",
    "―": "-",
    "‣": "",
    "‾": "-",
    "‼": "",
    "№": "n",
  },
  currencies: {
    "₳": "a",
    "₵": "b",
    "¢": "b",
    "₡": "e",
    "₢": "e",
    "💲": "s",
    "$": "s",
    "₫": "d",
    "֏": "o",
    "€": "e",
    "₠": "e",
    "₣": "f",
    "ƒ": "f",
    "₲": "g",
    "₴": "h",
    "₭": "k",
    "£": "p",
    "₺": "t",
    "₦": "n",
    "₻": "c",
    "₼": "m",
    "₧": "p",
    "₱": "p",
    "₹": "r",
    "₽": "r",
    "៛": "r",
    "₪": "s",
    "₸": "t",
    "₮": "t",
    "₩": "w",
    "¥": "y"
  }
};
