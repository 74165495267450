import { NgModule, ModuleWithProviders  } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SITE_INFO } from './models/site-info.model';
import { SfLocalizeConfig } from './models/localize-config.model';
import { dummySiteInfo } from './localize.module';

@NgModule({
  imports: [
    CommonModule
  ]
})
export class SfLocalizeSSRModule {
  static forRoot(config: SfLocalizeConfig): ModuleWithProviders<SfLocalizeSSRModule> {
    return {
      ngModule: SfLocalizeSSRModule,
      providers: [
        config.siteInfo ? config.siteInfo :
        {
          provide: SITE_INFO,
          useFactory: dummySiteInfo
        }
      ],
    };
  }
}
