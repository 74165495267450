import { Component, Input, EventEmitter, Output, Inject, PLATFORM_ID } from '@angular/core';
import { SfBanner } from '../models/banner.interface';
import { OPTIONS, SfOptions } from '../../../core/interfaces/options.interface';
import { SfMessageService } from '../../../core/message/services/message.service';

@Component({
    selector: 'sf-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.sass'],
    standalone: false
})
export class SfBannerComponent {

  public hover: boolean;

  public smallDevice = false;

  @Input() banner: SfBanner;

  @Input() showCTA: boolean;

  @Input() noLink: boolean;

  @Output() show = new EventEmitter<boolean>();

  @Input() seoUpperTitle: 'H1'|'H2'|'H3'|'DIV' = 'DIV';
  @Input() seoTitle: 'H1'|'H2'|'H3'|'DIV' = 'DIV';


  constructor(
    public eventService: SfMessageService,
    @Inject(OPTIONS) public options: SfOptions,
  ) {
  }

  public onSelect() {
    this.show.emit(true);
  }
}
