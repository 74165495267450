/* tslint:disable */export const fr_fr = {
  '404': {
    TITLE: 'Oups...',
    PAGENOTFOUND: "C'est râpé,<br> cette page n'existe pas!",
    BUTTON: "Retour à l'accueil",
    RECIPES: 'Découvrez nos recettes'
  },
  HOME: {
    SHOWCASE: 'Recettes de cuisine',
    CATCH_PHRASE: "Les légumes qui facilitent l'envie",
    SHOWCASE_SEE_ALL: 'Voir toutes nos recettes',
    MAGAZINE: { TITLE: 'À LA UNE', BUTTON: 'Découvrez légumiz' },
    OUR_VEGETABLES: 'Nos légumes',
    SOCIAL_WALL: 'Ensemble, partageons la passion du végétal',
    FAQ_TITLE: 'Vos questions',
    FAQ_SEE_MORE: 'Voir plus',
    FLOWBOX: 'Partagez vos instants gourmands avec #mybonduelle'
  },
  SELECT: { DEFAULT_VALUE: 'Bonduelle présente' },
  MENU: {
    CONTACT_US: 'Nous contacter',
    MY_ACCOUNT: 'Mon compte',
    GOOD_DEALS: 'Bons plans',
    NEWSLETTER: 'Newsletter Bonduelle'
  },
  FOOTER: {
    LINK: {
      BONDUELLE: 'Bonduelle.fr',
      CREDITS: 'Crédits',
      LEGAL_NOTICE: 'Mentions légales',
      TERMS_OF_SALES: '',
      COOKIES: 'Cookies',
      SITEMAP: 'Plan du site',
      PRIVACY_POLICY: 'Politique de confidentialité',
      ALL_RIGHTS_RESERVED: '2019 © Tous droits réservés'
    },
    HISTORY: {
      TITLE: 'Bonduelle, depuis 1853',
      TEXT: 'Bonduelle est une entreprise familiale qui travaille depuis 7 générations au développement d’une production agricole respectueuse des sols et des hommes. Nous défendons une agro-écologie efficiente, intelligente et solidaire, résolument tournée vers l’avenir et innovons chaque jour du champ à l’assiette pour créer un futur meilleur par l’alimentation végétale.'
    },
    NEWSLETTER: {
      TITLE: 'La communauté Bonduelle',
      TEXT: "J'accepte de recevoir la newsletter « Tout sur les légumes par Bonduelle » (actualités, promo, concours, articles…)",
      PLACEHOLDER: 'Votre email',
      GDPR_LINK: "Plus d'informations sur la gestion de vos données"
    }
  },
  PRODUCTS: 'Produits',
  MAGAZINE: {
    HOME: 'Bienvenue sur le magazine',
    CATCH_PHRASE: "Épluchez l'actu des légumes avec Bonduelle !",
    MENU: { BONDUELLE_LINK: 'Aller sur bonduelle.fr' },
    SHOWCASE: 'À la une',
    PREVIOUS_ARTICLES: 'Précédents articles',
    PREVIOUS_ARTICLES_SEE_ALL: 'Voir tous nos articles',
    PREVIOUS_EDITIONS: 'Nos précédents<br>numéros',
    PREVIOUS_EDITIONS_SEE_ALL: 'Voir plus de numéros',
    PREVIOUS_ARTICLES_BY_CATEGORY: 'Précédents articles<br>par catégorie',
    RUBRIC: {
      SEE_ALL: 'Voir toutes nos recettes',
      SEE_ALL_ARTICLES: 'Voir tous nos articles',
      ON: 'Articles sur'
    },
    RUBRIC_COOK: { ON: 'Recettes sur' },
    ARTICLE: { JOIN_US: 'Rejoignez-nous', READ_ALSO: 'À lire également' },
    RECIPE: {
      INGREDIENTS: 'Ingrédients',
      INGREDIENTS_LABEL_DESC: 'pour {{value}} personnes',
      INGREDIENTS_LABEL_ONE_DESC: 'pour {{value}} personne',
      INGREDIENTS_LABEL: 'Ingrédients pour {{value}} personnes',
      INGREDIENTS_LABEL_ONE: 'Ingrédients pour {{value}} personne',
      PEOPLES: '{{value}} personnes',
      PEOPLE: '{{value}} personne',
      DIFFICULTY: { EASY: 'Facile', MEDIUM: 'Moyen', HARD: 'Difficile' },
      COST: { CHEAP: 'Pas cher', EXPENSIVE: 'Cher' },
      TIMING: { MIN: 'min', HOURS: 'heures' },
      STEP: 'Étape',
      DISCOVER_ALSO: 'Découvrez<br>aussi...'
    }
  },
  ARTICLE: { DISCOVER_ALSO: 'Découvrez également', INGREDIENTS: 'Ingrédients' },
  SF: {
    SLIDE: { DISCOVER: 'Découvrir' },
    SEARCH: { PLACEHOLDER: 'Rechercher', ALL: 'Tout voir' },
    NEWSLETTER: {
      BTN_LABEL: "Je m'inscris",
      PLACEHOLDER: 'Votre email',
      ERROR_MESSAGE: 'Email invalide',
      ALREADY_SUBSCRIBED: 'Vous êtes déjà inscrit',
      GDPR: {
        TXT: "Plus d'informations sur la gestion de vos données",
        LINK: '/politique-confidentialite'
      }
    },
    MONTHLY_FOLDER: { TITLE: 'Dossier du mois' },
    MINI_NEWSLETTER: {
      PLACEHOLDER: 'Votre email',
      GDPR: {
        TXT: "Plus d'informations sur la gestion de vos données",
        LINK: '/politique-confidentialite'
      }
    },
    SOCIALITEM: { SHARE: 'partager', COMMENT: 'commenter', BONDUELLE: 'Bonduelle' },
    SEARCHRECIPE: { DISCOVER_ALL: 'Recherchez parmi toutes nos recettes !' },
    FORM: {
      ERROR: 'Champ invalide',
      PHOTO: {
        ADD: 'Ajoutez une photo',
        DELETE: 'Supprimer la photo',
        SEE: 'Voir'
      }
    }
  },
  CORPORATE: {
    COMPANY_STORY: 'Notre histoire',
    CSR_COMMITMENT: 'nos engagements',
    VEGETABLE_INNOVATIONS: 'innovation végétale',
    NEWS: 'toutes nos actus',
    SEE_MORE: "Voir plus d'articles",
    ON: 'articles sur'
  },
  NEWSLETTER: {
    TITLE: 'Inscrivez-vous à la newsletter',
    SUBTITLE: 'Bons plans, inspiration culinaire et un peu de culture !'
  },
  ROUTES: {
    products: 'legumes',
    news: 'actualites',
    'since-1853': 'depuis-1853',
    'field-plate': 'du-champ-a-l-assiette',
    'company-story': 'notre-histoire',
    'csr-commitments': 'nos-engagements',
    'vegetal-innovations': 'innovation-vegetale',
    magazine: 'legumiz',
    all: 'voir-tout',
    article: 'article',
    cook: 'cuisiner',
    cultivate: 'cultiver',
    explore: 'explorer',
    recipe: 'wait-recette',
    recipes: 'recette',
    'recipes-home': 'recettes',
    r: 'r',
    credits: 'credits-fr',
    'legal-notice': 'mentions-legales',
    'terms-of-sales': 'cgv-fr',
    cookies: 'cookies',
    sitemap: 'sitemap-fr',
    'privacy-policy': 'politique-confidentialite',
    'all-rights-reserved': 'all-rights-reserved',
    search: 'recherche',
    'articles-search': 'articles',
    vegetables: 'legumes',
    p: 'p',
    contact: 'nous-contacter',
    'info-product': 'renseignement-produit',
    'note-product': 'note-product',
    suggestion: 'suggestion',
    'other-request': 'autre-demande',
    faq: 'faq',
    'all-themes': 'tous',
    'products-search': 'produits',
    recipesearch: 'recettes-recherche',
    'product-news': 'nouveautes',
    'good-deals': 'bons-plans',
    'not-found': 'erreur',
    'menu-page': 'menu-page',
    'menu-one': 'menu-one',
    'menu-two': 'menu-two',
    'menu-three': 'menu-three',
    'menu-four': 'menu-four',
    'menu-external': 'menu-external',
    'theme-pasta-rice': '',
    'theme-bowls': '',
    'theme-soup': '',
    'theme-snack': '',
    'theme-sauce': '',
    'theme-unique-plate': '',
    'theme-over': '',
    'theme-legumin': 'legumin',
    'theme-ingredients-recipes': '',
    'theme-cook-plate': ''
  },
  BREAD: {
    products: 'légumes',
    news: 'actualités',
    'since-1853': 'depuis 1853',
    'field-plate': "du champ à l'assiette",
    'company-story': 'notre histoire',
    'csr-commitments': 'nos-engagements',
    'vegetal-innovations': 'innovation végétale',
    magazine: 'légumiz',
    all: 'voir-tout',
    article: 'article',
    cook: 'cuisiner',
    cultivate: 'cultiver',
    explore: 'explorer',
    recipe: 'wait-recette',
    recipes: 'recette',
    'recipes-home': 'recettes',
    'articles-search': 'articles',
    search: 'recherche',
    vegetables: 'légumes',
    contact: 'nous-contacter',
    'info-product': 'renseignement-produit',
    'note-product': 'note-product',
    suggestion: 'suggestion',
    'other-request': 'autre-demande',
    faq: 'faq',
    'all-themes': 'tous',
    'products-search': 'produits',
    recipesearch: 'recettes-recherche',
    'product-news': 'nouveautes',
    'good-deals': 'bons-plans',
    'not-found': 'erreur',
    'menu-page': 'menu-page',
    'menu-one': 'menu-one',
    'menu-two': 'menu-two',
    'menu-three': 'menu-three',
    'menu-four': 'menu-four'
  },
  MENU_PAGE: { TITLE1: '', TITLE2: '', TITLE3: '', TITLE4: '', EXTERNAL: '' },
  TECHNICAL: { MENU_PAGE: '' },
  SEO: {
    TITLE: {
      MENU_ONE: '',
      MENU_TWO: '',
      MENU_THREE: '',
      MENU_FOUR: '',
      HOME: "Bonduelle: source d'envie de legumes & recettes de cuisine",
      SEARCH: {
        MAIN: '{{value}}  | Bonduelle',
        RECIPES: '{{value}} triés par recette  | Bonduelle',
        VEGETABLES: '{{value}} triés par légume  | Bonduelle',
        ARTICLES: '{{value}} triés par article | Bonduelle'
      },
      CONTACT: 'Contact service client, informations  | Bonduelle',
      RECIPES: 'Recette de cuisine : soupe, quiche, lasagne légume...  | Bonduelle',
      RECIPE_HOME: 'Recette de cuisine aux legumes noel, mariage, fete  | Bonduelle',
      RECIPE: 'Recette {{value}}  | Bonduelle',
      PRODUCTS: 'Legumes cuisinés, conserves, surgeles à cuisiner  | Bonduelle',
      NEWS: 'Conseil, cuisiner, comment preparer des legumes  | Bonduelle',
      CORPORATE: {
        NEWS: 'Derniers articles, news de legume et de cuisine  | Bonduelle',
        COMPANY_STORY: "Présentation de l'entreprise et association  | Bonduelle",
        CSR_COMMITMENTS: 'Valeurs ethiques/principes et engagement du groupe  | Bonduelle',
        VEGETAL_INNOVATIONS: 'Innovation vegetale : nouvelles fermes, produits  | Bonduelle',
        FIELD_PLATE: 'Legumes : cuisiner les meilleures recettes | Bonduelle',
        ARTICLE: '{{value}}  | Bonduelle',
        VEGETABLE: 'Idée recette, bien cuisiner {{value}}  | Bonduelle'
      },
      PRODUCT: '{{value}} : légumes cuisinés Bonduelle ',
      MAGAZINE: {
        NEWS: 'Actualité des legumes, recettes adulte et enfant  | Bonduelle',
        ARTICLE: '{{value}} | Legumiz  | Bonduelle',
        COOK: 'Recettes de cuisine faciles & rapide aux legumes  | Bonduelle',
        GROW: 'Cultiver et entretenir les legumes dans son potager  | Bonduelle',
        EXPLORE: 'Decouvrir de nouveaux legumes & saveurs aux enfants  | Bonduelle',
        RECIPE: 'Recette {{value}} | Bonduelle'
      },
      FAQ: 'FAQ : foire aux questions des internautes  | Bonduelle',
      NOTFOUND: 'Erreur 404, veuillez reessayer plus tard | Bonduelle',
      RECIPES_ALL: 'Toutes nos recettes de cuisines de légumes',
      RECIPETYPE: 'Recette de cuisine: {{value}} de légume',
      RECIPESEASON: 'Recette de légume de saison: {{value}}',
      RECIPEPREPARATION: 'Recettes de cuisine : durée préparation {{value}}',
      RECIPEDIFFICULTY: 'Recette de cuisine & légume facile: {{value}} de légume',
      RECIPEVEGETABLE: 'Recette de cuisine facile à base de {{value}}',
      RECIPECOST: 'Recette de cuisine & légumes {{value}}',
      RECIPEDISH: 'Recettes de cuisine et légume pour {{value}} ',
      RECIPEDESC: 'Recette de cuisine & légumes {{value}}',
      '2RECIPEFACETS': 'Recette de cuisine {{value}}',
      '4RECIPEFACETS': 'Recette de cuisine {{value}}',
      PRODUCTSEASON: 'Recette de légume facile de saison : {{value}} ',
      PRODUCTTECHNO: 'Recette de légume facile {{value}}',
      PRODUCTVEGETABLE: 'Recette de légume facile à base de {{value}}',
      PRODUCTRANGE: 'Recette de légume facile de gamme {{value}}',
      THEME_ALL: 'Légumes cuisinés, conserves, surgeles à cuisiner',
      THEME_STARTER: "Idée légume pour cuisiner des recettes d'entrées",
      THEME_SALAD: 'Idée légumes pour cuisiner salade composée été',
      THEME_CAN: '',
      THEME_FROZEN: '',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: '',
      THEME_RECIPE: 'Idée Légume pour cuisiner recettes faciles/rapides',
      THEME_PLATE: 'Idées légumes pour cuisiner plat chaud & froid',
      THEME_ACC: 'Idées légumes en accompagnement viande & poisson',
      THEME_SNACK: 'Idée légume pour des recettes a manger rapidement',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': 'Recette de légume {{value}}'
    },
    DESCRIPTION: {
      MENU_ONE: '',
      MENU_TWO: '',
      MENU_THREE: '',
      MENU_FOUR: '',
      HOME: "Conserves, surgelés, salades, crudités, découvrez nos bons légumes, des idées recettes pour inspirer vos savoureux plats. Bonduelle : source d'envie de légumes & recettes de cuisine.",
      SEARCH: {
        MAIN: 'Découvrez nos idées recette & légume pour {{value}}. Recettes simples et savoureuses. Légumes cuisinés.',
        RECIPES: 'Tous nos {{value}} triés par recette. Recettes simples et savoureuses. Légumes cuisinés.',
        VEGETABLES: 'Tous nos {{value}} triés par légume. Recettes simples et savoureuses. Légumes cuisinés.',
        ARTICLES: 'Tous nos {{value}} triés par article. Recettes simples et savoureuses. Légumes cuisinés.'
      },
      CONTACT: "Une question ? Besoin d'une information sur un produit, service, opération en cours ? Vous pouvez contacter notre service client. Réponse garantie dans les 48h.",
      RECIPES: 'Découvrez des recettes de cuisine savoureuses à base de légumes. Faites plaisir à vos proches en leur préparant des recettes faciles. Recettes simples et savoureuses. Légumes cuisinés.',
      RECIPE_HOME: 'Découvrez des recettes de cuisine savoureuses à base de légumes pour les fêtes. Faites plaisir à vos proches en cette occasion spéciale. Recettes simples et savoureuses. Légumes cuisinés.',
      RECIPE: '',
      PRODUCTS: 'Notre large gamme de légumes vous permet de préparer des recettes savoureuses, faciles et équilibrées. Recettes simples et savoureuses. Légumes cuisinés.',
      NEWS: 'Découvrez comment bien préparer ses légumes, les cuisiner efficacement pour créer des plats savoureux. Recettes simples et savoureuses. Légumes cuisinés.',
      CORPORATE: {
        NEWS: "Dernières actualités sur l'univers de l'agriculture, l'innovation végétale, le gaspillage & comment mieux manger de manière simple. Recettes simples et savoureuses. Légumes cuisinés.",
        COMPANY_STORY: "Envie d'en savoir plus sur l'univers des légumes et du mon agricole & végétal ? Nous vous faisons découvrir tout cela sur notre blog. Recettes simples et savoureuses. Légumes cuisinés.",
        CSR_COMMITMENTS: 'Vous vous demandez comment sont cultivés nos légumes, d’où ils proviennent, comment nous respectons l’environnement ? Retrouvez ici les réponses aux questions sur les légumes.',
        VEGETAL_INNOVATIONS: "L'innovation végétale est au cœur de nos préoccupations. Nouvelles manières de cultiver plus écologique, moins gaspiller… Recettes simples et savoureuses. Légumes cuisinés.",
        VEGETABLE: '',
        FIELD_PLATE: 'Découvrez nos délicieuses recettes. Elles raviront tous les palais. Recettes simples et savoureuses. Légumes cuisinés.',
        ARTICLE: ''
      },
      PRODUCT: '{{value}} pour préparer des recettes de cuisines savoureuses et faire plaisir à vos proches. Recettes simples et savoureuses. Légumes cuisinés.',
      MAGAZINE: {
        NEWS: 'Toutes les actualités, news sur les légumes, recettes de cuisine équilibrées. Recettes simples et savoureuses. Légumes cuisinés.',
        ARTICLE: '',
        COOK: 'Nos délicieuses recettes raviront tous les palais, découvrez-les sur notre site. Recettes simples et savoureuses. Légumes cuisinés.',
        GROW: 'Envie de faire pousser vous-même vos légumes dans votre potager ? Découvrez nos conseils & astuce pour faire pousser et entretenir vos légumes. Légumes cuisinés.',
        EXPLORE: 'Faites Découvrir de nouvelles variétés de légumes à vos enfant à travers des recettes simples et efficaces. Recettes faciles et savoureuses. Légumes cuisinés.',
        RECIPE: ''
      },
      FAQ: 'Vous vous posez une question ? Nous avons regroupé toutes les questions les plus posées par les internautes pour répondre au mieux à vos attentes.',
      PAGE: 'page ',
      RECIPETYPE: 'Découvrez nos recettes de cuisine {{value}} et comment bien cuisiner ses légumes. Idée recette végétarienne, rapide, originale, facile.',
      RECIPESEASON: 'Découvrez nos recettes de cuisine et comment bien cuisiner ses légumes en {{value}}. Idée recette végétarienne, rapide, originale, facile.',
      RECIPEPREPARATION: 'Découvrez nos recettes de cuisine et comment bien cuisiner ses légumes en {{value}}. Idée recette végétarienne, rapide, originale, facile.',
      RECIPEDIFFICULTY: 'Découvrez nos recettes de cuisine et comment bien cuisiner ses légumes {{value}}. Idée recette végétarienne, rapide, originale, facile.',
      RECIPEVEGETABLE: 'Découvrez nos recettes de cuisine de {{value}} et comment bien cuisiner ses légumes. Idée recette végétarienne, rapide, originale, facile.',
      RECIPECOST: 'Découvrez nos recettes de cuisine et comment bien cuisiner ses légumes pour {{value}}. Idée recette végétarienne, rapide, originale, facile.',
      RECIPEDISH: 'Découvrez nos recettes de cuisine et comment bien cuisiner ses légumes pour {{value}} convives. Idée recette végétarienne, rapide, originale, facile.',
      RECIPEDESC: 'Découvrez nos recettes de cuisine et comment bien cuisiner ses légumes {{value}}. Idée recette végétarienne, rapide, originale, facile.',
      '2RECIPEFACETS': 'Découvrez nos recettes de cuisine et comment bien cuisiner ses légumes {{value}}. Idée recette végétarienne, rapide, originale, facile.',
      '4RECIPEFACETS': 'Découvrez nos recettes de cuisine et comment bien cuisiner ses légumes {{value}}.',
      PRODUCTSEASON: 'Notre large gamme de légumes vous permet de préparer des recettes de saison {{value}} savoureuses, faciles et équilibrées. Recettes simples et savoureuses. Légumes cuisinés.',
      PRODUCTTECHNO: 'Notre large gamme de légumes vous permet de préparer des recettes savoureuses, faciles et équilibrées {{value}}. Recettes simples et savoureuses. Légumes cuisinés.',
      PRODUCTVEGETABLE: 'Notre large gamme de légumes vous permet de préparer des recettes savoureuses, faciles et équilibrées à base de {{value}}. Recettes simples et savoureuses. Légumes cuisinés.',
      PRODUCTRANGE: 'Notre large gamme de légumes vous permet de préparer des recettes savoureuses, faciles et équilibrées à base de {{value}}. Recettes simples et savoureuses. Légumes cuisinés.',
      THEME_ALL: 'Notre large gamme de légumes vous permet de préparer des recettes savoureuses, faciles et équilibrées. Recettes simples et savoureuses. Légumes cuisinés.',
      THEME_STARTER: "Envie de cuisiner un repas équilibré ? Large gamme de légumes pour cuisiner des recettes d'entrées à base de légumes faciles et rapides, des conseils et astuces pour sublimer vos repas.",
      THEME_SALAD: 'Envie de cuisiner un repas équilibré ? Large gamme de légumes pour cuisiner des salades à base de légumes faciles et rapides, des conseils et astuces pour sublimer vos repas.',
      THEME_CAN: '',
      THEME_FROZEN: '',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: '',
      THEME_RECIPE: 'légumes pour cuisiner des recette delicieuses et équilibrées à base de légumes faciles et rapides, des conseils et astuces pour sublimer vos repas. Recettes rapides & equilibrées',
      THEME_PLATE: 'Envie de cuisiner un repas équilibré ? légume pour cuisiner des plats chauds ou froid à base de légumes faciles et rapides, des conseils et astuces pour sublimer vos repas.',
      THEME_ACC: 'légumes pour cuisiner des accompagnements à base de légumes faciles et rapides, des conseils et astuces pour sublimer vos repas. Recettes de cuisine simples et équilibrées.',
      THEME_SNACK: 'Envie de cuisiner un repas équilibré ? légume pour cuisiner des recette à grignoter à base de légumes faciles et rapides, des conseils et astuces pour sublimer vos repas.',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': 'Notre large gamme de légumes vous permet de préparer des recettes savoureuses, faciles et équilibrées {{value}}. Recettes simples et savoureuses. Légumes cuisinés.',
      NOTFOUND: "La page demandée n'a pas pu être trouvée. Nous vous invitons à faire une nouvelle recherche ou à recommencer ultérieurement."
    },
    PIPE: { SOCIETY: ' | Bonduelle' },
    THEME_ALL: 'tous',
    THEME_STARTER: 'entrées',
    THEME_SALAD: 'tout-pour-mes-salades',
    THEME_CAN: '',
    THEME_FROZEN: '',
    THEME_READY_MEALS: '',
    THEME_BIO: '',
    THEME_VEGETABLES: '',
    THEME_RECIPE: 'ingredients-recettes',
    THEME_PLATE: 'plat-principal',
    THEME_ACC: 'accompagnements',
    THEME_SNACK: 'snacking'
  },
  VEGETABLE: {
    DISCOVER_VEGETABLE: 'Découvrez nos feuilles de légumes',
    OURS_RECIPES: 'Nos recettes de cuisine',
    DISCOVER_ALSO: 'Découvrez aussi...',
    SEE_ALL: 'Voir tous nos légumes',
    ON: 'légumes sur'
  },
  RECIPE: {
    'INGREDIENTS_LABEL ': 'Ingrédients pour {{value}} personnes',
    PEOPLES: '{{value}} personnes',
    DIFFICULTY: { EASY: 'facile', MEDIUM: 'moyen', HARD: 'difficile' },
    COST: { CHEAP: 'pas cher', EXPENSIVE: 'cher' },
    TIMING: { MIN: 'min', HOURS: 'heures' },
    STEP: 'Étape',
    DISCOVER_ALSO: 'Découvrez aussi...',
    HOME_SEE_MORE: 'voir plus de recettes',
    SHOWCASE: 'À la une',
    HOME_BOOK: 'Nos cahiers<br>de recettes',
    MIN_MORE: 'mn et +',
    HOUR_MORE: 'h et +',
    FILTER_BY_CRITERIA: 'filtrer par critères',
    SORT_BY: 'trier par :',
    BEST_RATED: 'Les mieux notés',
    MOST_CONSULTED: 'Les plus consultés',
    MEMBERS_RECIPES: 'Recettes de membres',
    CRITERIA: 'Nouveautés',
    DISCOVER: { RECIPES: 'découvrir nos recettes' },
    DISCOVER_RECIPE: 'découvrir la recette',
    INSTRUCTIONS: 'Instructions',
    DEFAULT_TAG: 'Idée recette',
    MOST_RECENT: 'Les plus récentes',
    RECIPE_VIDEOS: 'Recettes en vidéos',
    PRODUCT_ASSOCIATE: 'Cette recette a été préparée avec ',
    BUDGET_LOW: 'Bon marché',
    BUDGET_HIGH: 'Elevé',
    BUDGET_MIDDLE: 'Moyen',
    PRINT_RECIPE: 'imprimer la recette',
    LET_YOURSELF_SURPRISED: 'Laissez-vous<br>surprendre',
    COOK_TIP_TITLE: 'Mes astuces dégustations',
    ENJOY_MEAL: 'Bon appétit !',
    FILTER: { LEGEND: 'Légende' }
  },
  PREDIGGO: {
    DIFFICULTY: { EASY: 'facile', MEDIUM: 'accessible', HARD: 'difficile' }
  },
  COOKIES: {
    TEXT: "En poursuivant votre navigation sur ce site, vous acceptez l'utilisation de cookies pour des raisons techniques, d'analyse statistique et de gestion du trafic Web, ainsi que pour le partage d'informations avec des annonceurs sur d'autres sites afin de vous proposer des publicités adaptées à vos intérêts.  ",
    CLOSE: 'Fermer',
    MORE: 'En savoir plus',
    AGREE_YOUTUBE: 'Vous devez accepter les cookies Youtube pour voir cette vidéo',
    BUTTON_YOUTUBE: 'Accepter les cookies'
  },
  POPUP: { LAUNCH: 'POPUP-OUVERTURE-SITE' },
  FIELD_PLATE: { DISCOVER_VEGETABLE: 'Découvrez<br>nos fiches légumes' },
  SEARCH: {
    OUR_RECIPES: 'Nos recettes',
    OUR_PRODUCTS: 'Nos légumes',
    OUR_ARTICLES: 'Nos articles',
    RECIPES: 'Recettes',
    PRODUCTS: 'Légumes',
    ARTICLES: 'Articles',
    ALL: 'Voir tout',
    SORT_BY: 'Trier par',
    RESULTS: 'résultats',
    RESULT: 'résultat',
    PRODUCT_OUT: 'légumes sur',
    APPLY: 'Appliquer',
    FILTER: 'Filtrer',
    RECIPE_OUT: 'recettes sur',
    NO_RESULT: 'Chou blanc pour cette recherche... Vous nous avez posé une colle',
    SEE_MORE: 'Voir plus',
    DISCOVER_RECIPES: 'Laissez-vous<br>surprendre',
    SEE_MORE_OF: 'voir plus de ',
    DELETE: 'effacer'
  },
  RECIPE_LIST: {
    OUR_RECIPES: 'Recettes',
    RESULTS: 'Résultats',
    SEE_MORE: 'Voir plus de recettes'
  },
  PRODUCT: {
    BUTTON: {
      ACTU: 'actualité du produit',
      FOUND: 'où trouver ce produit',
      DISCOVER: 'découvrez toute la gamme'
    },
    NUTRITIONAL_VALUE: 'valeurs nutritionnelles',
    CONDITIONING: 'conditionnement',
    PORTIONS: 'portions',
    NET_WEIGHT: 'poids net',
    DRY_WEIGHT: 'poids net égoutté',
    INGREDIENTS: 'ingrédients',
    OUR_RECIPES: 'Découvrez <br> nos recettes de cuisine',
    FOR: 'pour',
    PER_PORTION: 'et par portion de ',
    ENERGY: 'énergie',
    FAT: 'matières grasses',
    SATURATE: 'dont acides gras saturés',
    GLUCIDES: 'glucides',
    FIBER: 'fibres alimentaires',
    PROTEINS: 'protéines',
    SALT: 'sel',
    DISCOVER_ALSO: 'Découvrez<br>aussi...',
    MORE_INFORMATION: 'en savoir plus',
    TRACES: 'Traces de',
    CONTAINS: 'Contient',
    SLIDER: { SHEET: 'voir la fiche', BUY: "Où l'acheter ?" },
    NUTRISCORE: 'information-nutriscore',
    TRIMAN: 'information-triman\r\n',
    AGRICONFIANCE: 'information-agriconfiance\r\n',
    BIO: 'information-bio\r\n',
    PESTICIDE_FREE: '',
    LEAF: '',
    LOCK: '',
    SEE_ALL: 'Voir tous nos légumes',
    LEGISLATION: 'conformément à la législation en vigueur.',
    PORTION: { SINGULAR: 'portion', PLURAL: 'portions' },
    DISCLAIMER: '',
    COOKING: 'cuisson',
    CONSERVATION: 'conservation'
  },
  FAQ: {
    ON: 'questions sur',
    ALL: 'Tous',
    SEE_MORE: 'voir plus de questions',
    TITLE_1: 'Toutes les réponses à vos questions',
    TITLE_2: 'Vous avez une question sur un produit en particulier ?',
    LINK_LABEL: 'Ecrivez-nous'
  },
  FORM: {
    GO_TO_STEP: "Passer à l'étape",
    STEP: 'Étape',
    RECEIVE_EMAIL_COPY: 'Recevoir une copie par e-mail',
    SEND: 'Envoyer',
    GDPR: "Plus d'informations sur la gestion de vos données",
    ERROR: "Tous les champs marqués d'une * doivent êtres remplis et valides",
    SEND_ERROR: 'Une erreur est survenue, veuillez réessayer dans quelques instants',
    NOTICE: 'Pour le bon traitement de votre dossier, Bonduelle est susceptible, selon les cas, de vous demander des données de santé. Elles ne sont pas obligatoires et vous êtes libre de ne pas les transmettre.',
    SENDING: 'Envoi du formulaire...',
    LOADING: 'Chargement...',
    PRODUCT_INFORMATION_TITLE: 'Un renseignement sur un produit',
    PRODUCT_INFORMATION: {
      SEO_TITLE: 'Renseignement et information sur un produit | Bonduelle',
      SEO_DESC: 'Contactez notre service consommateur pour une information sur un produit de nos gammes. Réponse personnalisée dans les 48h'
    },
    INFORMATIONS: 'Vos informations',
    MISTER: 'Monsieur',
    MISS: 'Madame',
    NAME: 'Nom',
    OUR_NAME: 'Votre nom',
    FIRSTNAME: 'Prénom',
    OUR_FIRSTNAME: 'Votre prénom',
    MAIL: 'Votre email',
    MAIL_PLH: 'monadresse@email.com',
    MAIL_CONFIRM: 'Confirmez votre email',
    MAIL_ERROR: 'Veuillez saisir une adresse email valide',
    MAIL_ERROR_CONFIRM: 'Veuillez confirmer votre adresse email',
    ADDRESS: 'Votre adresse',
    STREET_PLH: '',
    STREET: 'N° et nom de la rue',
    ADDRESS_COMPLEMENT: "Complément d'adresse",
    ADDRESS_COMP_PLH: 'Indications supplémentaires',
    ZIPCODE: 'Code postal',
    CITY: 'Ville',
    CITY_PLH: 'Votre ville de résidence',
    PHONE: 'Téléphone',
    PHONE_PLH: 'Votre numéro de téléphone',
    COUNTRY: 'Pays',
    PRODUCT_INFORMATIONS: 'Informations produits',
    PRODUCT_INFORMATION_DESC: 'Afin que nos services puissent étudier au mieux votre<br>demande, merci de renseigner les informations suivantes :',
    PRODUCT: {
      DETAIL: {
        DESC: 'Pour chaque critère demandé, vous pouvez remplir le champ du formulaire<br>et ajouter une photo d’illustration. Information : votre photo doit permettre de lire clairement<br>le code barre, le numéro de lot et la DLUO du produit.'
      },
      NOTE: {
        TITLE: 'Faire une remarque sur un produit',
        SEO_TITLE: 'Remarque et commentaire sur un produit | Bonduelle',
        SEO_DESC: 'Une remarque ou un commentaire sur un produit de nos gammes ? Contactez notre service consommateur pour vous aider. Réponse personnalisée dans les 48h'
      }
    },
    PRODUCT_RANGE: 'Gamme de produits concernée',
    PRODUCT_NAME: 'Nom du produit',
    PRODUCT_NAME_PLH: 'Écrivez le nom du produit Bonduelle concerné par votre demande',
    PRODUCT_INFORMATIONS_DETAIL: 'Informations détaillées',
    PRODUCT_INFORMATIONS_LABEL: 'Code barre du produit',
    PRODUCT_INFORMATIONS_PLH: 'Le code barre est composé<br>de 13 chiffres présents sur<br>l’emballage de votre produit.',
    PRODUCT_LOT: 'N° de lot du produit',
    PRODUCT_LOT_INFO: 'Le n° de lot se trouve à proximité<br>de la date limite de consommation<br>et comporte plusieurs chiffres et lettres<br>sur plusieurs lignes.<br><br>Par exemple : 07/2024 L42277448 3028 01 62 05:14.',
    PHOTO: 'Ajouter une photo',
    EXPIRED_DATE: 'Date de la DLUO /DLC',
    EXPIRED_DATE_ERROR: 'Veuillez saisir une date valide au format jj/mm/aaaa',
    EXPIRED_DATE_DESC: "La DLUO (Date Limite d'Utilisation Optimale)<br>et la DLC (Date limite de Consommation)<br>sont présentées sous forme de dates.",
    PHOTO_MORE: 'Photos supplémentaires - Pensez à bien conserver vos photos',
    MESSAGE: 'Votre message *',
    OUR_MESSAGE: 'écrivez votre message',
    SALES_OPERATIONS: {
      TITLE: 'Des informations sur une opération commerciale',
      SEO_TITLE: 'Infos sur les opérations du moment : jeu concours… | Bonduelle',
      SEO_DESC: 'Contactez notre service consommateur pour une information sur une operation commerciale en cours. Réponse personnalisée dans les 48h'
    },
    PRESS_SERVICE: 'Contacter le service presse',
    FOOD_SERVICE: 'Contacter l’équipe Bonduelle Food Service',
    BUSINESS_INFORMATION: "Recevoir des échantillons/bons d'achat/objets publicitaires",
    MODAL_INFORMATION: "Merci de l'intérêt que vous portez à la marque.\n" +
      "Nous ne disposons pas de bons de réduction, ou d'échantillons à vous envoyer. Cependant, nous vous invitons à nous suivre sur Facebook et Instagram. Vous pourrez ainsi être informé des jeux concours et opérations d'échantillonnages proposés ponctuellement et pourquoi pas, rejoindre la liste de nos heureux gagnants !\n" +
      'Bonne chance!',
    JOB_OFFER: { TITLE: '', SEO_TITLE: '', SEO_DESC: '' },
    WEB_SUGGESTION: {
      TITLE: '',
      SEO_TITLE: '',
      SEO_DESC: 'Veuillez contacter notre service consommateurs pour nous aider à améliorer le site.'
    },
    ANOTHER: {
      TITLE: 'Adresser une demande de partenariat/ sponsoring',
      SEO_TITLE: 'Contact pour toute autre demande, service | Bonduelle',
      SEO_DESC: 'Contactez notre service consommateur pour une information, un service ou une suggestion. Réponse personnalisée dans les 48h'
    },
    AMBASSADOR: 'Devenir ambassadeur Bonduelle',
    CATEGORY: {
      SALAD: 'salades',
      FROZEN: 'surgelés',
      CAN: 'conserves',
      TAKEAWAY: 'traiteur',
      VEGETABLE: ''
    }
  },
  CONTACT: {
    TITLE: 'À votre écoute',
    SPECIAL_INFO: '',
    TAKE_CARE: '',
    BY_MAIL: 'Par courrier',
    BY_PHONE: 'Par téléphone',
    BY_SOURDLINE: 'Par Sourdline',
    MAIL_TEXT: 'Service consommateurs Bonduelle\nBP 30173\n\n59653 Villeneuve d’Ascq Cedex',
    PHONE_TEXT: 'Nos conseillers sont à votre disposition\n' +
      'au  <a href="tel:+33970252222"><b>09 70 25 22 22</b></a> (appel non surtaxé)\n' +
      'du lundi au vendredi de 9h à 18h.\n' +
      'Il vous est néanmoins possible de déposer un message vocal 24H sur 24H.',
    SOURDLINE_TEXT: 'Pour les sourds/malentendants, nous sommes à votre disposition par webcam ou par tchat  <b>du lundi au vendredi de 9h à 18h.</b><br>Cliquez sur le logo ci-dessous pour accéder au service.',
    BY_DISTRIBUTOR: '',
    BY_DISTRIBUTOR_TEXT: '',
    SUBTITLE: 'Vous souhaitez nous contacter pour : ',
    AGREEMENT: '',
    READ_MORE: 'Par ailleurs  vous disposez  d’un droit d’accès, de rectification, d’effacement et de portabilité à l’égard de vos Données Personnelles ainsi que du droit de restriction et d’opposition au traitement des Données Personnelles qui vous concernent, que vous pouvez exercer en écrivant à Dpo_france(at)bonduelle(dot)com ou par courrier : à l’attention de Monsieur le Délégué à la protection des données, Bonduelle, Rue Nicolas Appert, BP 30173, F-59653 Villeneuve D’Ascq. Pour en savoir plus sur la gestion de vos données et de vos droits cliquez ici',
    READ_MORE_LABEL: 'En savoir plus',
    SATISFACTION_TEXT: 'Afin de vous contacter à la suite d’une remarque sur nos produits, de nous\n' +
      'permettre de traiter votre réclamation, ainsi que de vous envoyer un\n' +
      'questionnaire de satisfaction si vous le souhaitez, nous traiterons les\n' +
      'informations renseignées en qualité de responsable de traitement. La\n' +
      'base légale du traitement de votre demande est le consentement en\n' +
      'raison des potentielles données relatives à votre santé que vous pourriez\n' +
      'renseigner dans votre demande. Ces informations seront conservées\n' +
      'pendant la durée nécessaire au traitement de votre demande et jusqu’à\n' +
      'une durée de 5 ans afin d’apporter la preuve du traitement de votre\n' +
      'demande et de nous conformer à nos obligations légales. Pour l’envoi du\n' +
      'questionnaire de satisfaction, la base légale est également votre\n' +
      'consentement. Ces informations seront conservées pendant la durée\n' +
      'nécessaire à l’expédition du questionnaire de satisfaction et jusqu’à 1 an\n' +
      'au maximum. Pour en savoir plus sur le traitement de vos données\n' +
      'personnelles, nous vous invitons à consulter notre <a href="https://www.bonduelle.fr/politique-confidentialite" target="_blank" class="underline">Politique de confidentialité</a>.',
    SATISFACTION_CHECKBOX: 'En cochant cette case, vous acceptez de recevoir un questionnaire de\n' +
      'satisfaction (composé d’une seule option) à l’issue du traitement de votre\n' +
      'demande. Cette dernière est facultative.',
    CHECKBOX_TEXT: 'En cochant cette case, vous acceptez que vos données à caractère\n' +
      'personnel de santé pouvant être indiquées dans votre message soient\n' +
      'traitées par Bonduelle dans l’unique but de répondre à votre demande.\n' +
      '<strong>Cette dernière est obligatoire pour traiter votre demande</strong>.',
    PHONE: '',
    CALL_US: 'Nous appeler',
    TO_WRITE: 'Nous écrire',
    PHONE_NUMBER: '0970252222',
    SUCCESS_MESSAGE: "Merci ! Notre service consommateurs va prendre contact avec vous (éventuellement par mail. Attention, parfois notre message peut arriver dans vos spams 😉), d'ici 2 à 3 jours ouvrés. <br><br>\n" +
      'Nous restons naturellement​ à votre​ ​écoute​ du​ ​lundi​ au​ ​vendredi​ ​de​ ​9h​ ​à 18h​​ au​​ 09​ ​70​ ​25​ ​22 22 (appel​ ​non​ ​surtaxé).​<br><br>\n' +
      'Dans le cadre du suivi de notre qualité de service, l’ensemble de nos échanges peuvent faire l’objet d’un enregistrement.',
    PHOTOERROR: { MESSAGE: '' }
  },
  GLOBAL: { READ_MORE: 'Voir plus' },
  FLOWBOX: {
    GENERIC_KEY: 'ba_GnGC3TKedKEe0-gKomQ',
    PRODUCT_KEY: '1hRXF7TUSt-fhhlJASv-TQ',
    RECIPE_KEY: 'Ulp6NW8-QFOoSa61nBIlaA',
    HOME_KEY: 'g7--tXG3QgiwJjjpsaMmrg'
  },
  TOKEN: {
    FACEBOOK: '',
    PAGE_FACEBOOK_ID: '',
    INSTAGRAM: '',
    INSTA_ID: '17841406418569757',
    YOUTUBE: '',
    TWITTER: '',
    MAGAZINE: '6318061952.9bbab10.8842be1c95904c9d80ff59765a3a2ccc'
  },
  COOKIE: {
    BANNER: {
      MANDATORY: 'Requis',
      ANALYTICS: 'Analyse',
      MARKETING: 'Marketing',
      SOCIAL_MEDIA: 'Réseaux sociaux',
      ACCEPT: 'Tout accepter',
      REJECT: 'Tout refuser',
      SET_UP: 'Paramétrer',
      MANDATORY_DETAILS: "Les cookies requis sont nécessaires au fonctionnement du site, par exemple pour mémoriser vos informations de connexion et vous fournir une connexion sécurisée.Les cookies requis pour utiliser le site bonduelle.fr sont : <ul><li>Cookie de session (SESSION_ID) : Cookie généré par des applications basées sur le langage PHP. Il s'agit d'un identifiant à usage général utilisé pour gérer les variables de session utilisateur. Il s'agit d'un nombre généré de manière aléatoire, la façon dont il est utilisé peut être spécifique au site, mais un bon exemple est le maintien d'un statut de connexion pour un utilisateur entre les pages.</li></ul> ",
      ANALYTICS_DETAILS: "Les cookies fonctionnels nous aident à optimiser le site, par exemple, à collecter des statistiques, à enregistrer vos préférences pour les interactions sociales ou à mesurer les performances et la stabilité de notre application.<br>Les cookies analytiques utilisés par le site bonduelle.fr sont : <ul><li>DoubleClick : Ce cookie est défini par DoubleClick (appartenant à Google) pour créer un profil des intérêts du visiteur du site Web et afficher des publicités pertinentes sur d'autres sites. L'activité principale de l'entreprise est l'échange publicitaire d'enchères en temps réel de Google.</li><li>Google Analytics : ces cookies permettent de faire des analyses sur la fréquentation des pages du site.</li>\n" +
        '<li>UTMTracking : ce cookie permet la gestion des variables UTM pour un suivi optimal des différentes campagnes publicitaires en ligne</li></ul>',
      MARKETING_DETAILS: 'Les cookies marketing permettent un suivi de vos préférences de recherche de recettes et de produits afin de toujours vous proposer les produits les plus en adéquation avec vos envies.<br>Les cookies marketing utilisés par le site bonduelle.fr sont : <ul>\n' +
        '<li> Prediggo: cet outil utilise le cookie SESSION_ID pour vous proposer les produits et recettes les mieux adaptés à votre navigation et à vos recherches.</li>\n' +
        `<li>ClickToBuy : ce cookie permet de mémoriser votre localisation afin de vous proposer les magasins les plus proches de chez vous proposant les produits Bonduelle recherchés.</li><li>RéseauxSociaux : ces cookies permettent l'affichage du 'SocialWall' Bonduelle présent sur la homepage du site intitulé "Ensemble, partageons la passion du végétal". Ce "mur" vous permet de voir en un coup d'oeil tous les derniers posts des réseaux sociaux majeurs utilisés par la marque Bonduelle.</li><li>FlowBox : ce cookie permet l'utilisation des services de la solution Flowbox nous permettant de proposer à nos visiteurs les recettes et réalisations d'internautes ayant posté la photo d'un produit ou une recette Bonduelle sur Instagram. La solution Flowbox se trouve sur différentes pages du site dont sur <a href="https://www.bonduelle.fr/mybonduelle-galerie">la page My Bonduelle</a></li> </ul> \n`,
      V2: 'ok'
    },
    MODAL: {
      TITLE: 'Gestion des cookies',
      TEXT: "Le Groupe Bonduelle se soucie d’assurer la Protection des Données Personnelles des utilisateurs de ses sites internet, et en particulier du site Bonduelle.fr. <br> Pour vous permettre de bénéficier des services proposés par le site tels que la consultation de produits, la découverte de nouvelles recettes gourmandes, et également afin d’optimiser son utilisation et sa personnalisation en fonction de votre profil, le site utilise des cookies. Le Groupe Bonduelle s’engage pleinement à assurer un niveau de protection élevé à l’égard de ces données personnelles et à se conformer à toutes les règles applicables en matière de protection des données lors du traitement des données personnelles réalisé dans le cadre de l'utilisation de son site Web.Ainsi, vous pouvez à tout moment activer/désactiver les cookies.",
      CONFIG: 'Paramétrer',
      SAVE: 'Enregistrer'
    }
  },
  LINK: { JOB_OFFERS: 'https://jobs.bonduelle.com/?locale=fr_FR' },
  CLIC2BUY: {
    INFOS: `Pour lister les points de vente de nos produits, nous devons utiliser un cookie spécifique permettant de fournir le code produit à notre partenaire. Veuillez valider l'enregistrement des cookies en cliquant sur le bouton "Valider les cookies".<br><br>\n` +
      'Merci de votre compréhension.'
  }
};
