import { SomBloc } from './bloc.model';
import { SomMagazineArticlePage } from './magazine-article-page.model';
import { SomMagazineRecipePage } from './magazine-recipe-page.model';

export class SomMagazineMonthly extends SomBloc {

  title: string;

  /** Items to display */
  items: (SomMagazineArticlePage | SomMagazineRecipePage)[];

  constructor() {
    super();
    this.items = [];
  }
}
