import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SfSlideGrid } from '../../models/slide-grid.interface';

@Component({
    selector: 'sf-breadcrumb-grid',
    templateUrl: './breadcrumb-grid.component.html',
    styleUrls: ['./breadcrumb-grid.component.sass'],
    standalone: false
})
export class BreadcrumbGridComponent {

  @Input() elements: SfSlideGrid[];

  @Input() selectedIndex: number;

  @Input() disabled = false;

  @Output() select: EventEmitter<any> = new EventEmitter();

  constructor() { }

  public selectIndex(index: number) {
    if (this.disabled) {return; }
    this.select.emit(index);
  }

}
