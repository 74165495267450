import { SomPage } from './page.model';
import { SomForm } from './form.model';

export class SomContactPage extends SomPage {

  forms: SomForm[];

  constructor() {
    super();
    this.forms = [];
  }
}
