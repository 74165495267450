<div class="sf-gallery" *ngIf="images && images.length">
  <div class="sf-gallery-big">
    <sf-image [image]="bigImage" class="sf-gallery-image-large"></sf-image>
  </div>
  <div class="sf-gallery-small">
    <div *ngFor="let image of images; index as i" class="sf-gallery-images">
      <sf-image [image]="image" class="sf-gallery-images-items" (click)="changeImage(i)"></sf-image>
    </div>
  </div>
</div>
