import { SfMediaVideo } from '../../video';
import { SfSection } from '../../../core/models/section.interface';

export interface SfSectionVideo extends SfSection {

  /** Text of the section. May contain HTML tags */
  text: string;

  /** Video to display in the section */
  video: SfMediaVideo;
}
