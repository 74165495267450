/* tslint:disable */export const rs_sr = {
  '404': {
    TITLE: 'Ups',
    PAGENOTFOUND: 'Stranica ne postoji',
    BUTTON: 'Povratak na početnu stranicu',
    RECIPES: 'Otkrijte naše recepte'
  },
  HOME: {
    SHOWCASE: 'Recepti za kuvanje',
    CATCH_PHRASE: '#POSVECENIPOVRCU',
    SHOWCASE_SEE_ALL: 'Pogledajte sve naše recepte',
    MAGAZINE: { TITLE: '1.Aktuelnosti 2 Vrh', BUTTON: 'Otkrijte mahunarke' },
    OUR_VEGETABLES: 'Naše povrće',
    SOCIAL_WALL: 'Hajde da zajedno podelimo strast ka biljkama',
    FAQ_TITLE: 'Vaša pitanja',
    FAQ_SEE_MORE: 'Pogledajte više',
    FLOWBOX: 'Podelite svoje gurmanske trenutke sa #mybonduelle'
  },
  SELECT: { DEFAULT_VALUE: 'Bonduelle poklon' },
  MENU: {
    CONTACT_US: 'Kontaktirajte nas',
    MY_ACCOUNT: 'Moj račun',
    GOOD_DEALS: 'Dobre ponude',
    NEWSLETTER: 'Bonduel bilten'
  },
  FOOTER: {
    LINK: {
      BONDUELLE: 'Bonduelle.com',
      CREDITS: 'Impresum',
      LEGAL_NOTICE: 'Pravno obaveštenje',
      TERMS_OF_SALES: 'Uslovi prodaje',
      COOKIES: 'Kolačići',
      SITEMAP: 'Mapa sajta',
      PRIVACY_POLICY: 'Politika privatnosti',
      ALL_RIGHTS_RESERVED: '2018 © Sva prava zadržana'
    },
    HISTORY: {
      TITLE: 'Bonduelle, оd 1853. godine',
      TEXT: 'Bonduelle je porodična firma koja već 7 generacija radi na razvoju poljoprivredne proizvodnje koja poštuje zemlju i ljude. Mi branimo efikasnu, inteligentnu i ujedinjenu agroekologiju, odlučno okrenuti budućnosti i svaki dan inoviramo od polja do tanjira kako bismo stvorili bolju budućnost kroz biljnu hranu'
    },
    NEWSLETTER: {
      TITLE: 'Bonduelle zajednica',
      TEXT: 'Prihvatam da primam bilten «Sve o povrću od Bonduel-a» (vesti, promocije, konkursi, članci...)',
      PLACEHOLDER: 'Vaš imejl',
      GDPR_LINK: 'Saznajte više o upravljanju vašim podacima'
    }
  },
  PRODUCTS: 'Proizvodi',
  MAGAZINE: {
    HOME: 'Dobrodošli u magazin',
    CATCH_PHRASE: 'prelistajte vesti o povrću sa Bonduelle',
    MENU: { BONDUELLE_LINK: 'Idi na bonduelle.com' },
    SHOWCASE: '1.Vitrina ; 2. Prikazati',
    PREVIOUS_ARTICLES: 'Prethodni članci',
    PREVIOUS_ARTICLES_SEE_ALL: 'Pogledajte sve naše članke',
    PREVIOUS_EDITIONS: 'Naša prethodna izdanja',
    PREVIOUS_EDITIONS_SEE_ALL: 'Vidite više izdanja',
    PREVIOUS_ARTICLES_BY_CATEGORY: 'Prethodni članci po kategorijama',
    RUBRIC: {
      SEE_ALL: 'Vidite sve recepte',
      SEE_ALL_ARTICLES: 'Vidite sve članke',
      ON: 'članci o'
    },
    RUBRIC_COOK: { ON: 'recepti za' },
    ARTICLE: { JOIN_US: 'Pridružite nam se', READ_ALSO: 'Pročitajte takođe' },
    RECIPE: {
      INGREDIENTS: 'Sastojci',
      INGREDIENTS_LABEL_DESC: 'za {{value}} osobe / osoba',
      INGREDIENTS_LABEL_ONE_DESC: 'za {{value}} osobu',
      INGREDIENTS_LABEL: 'Sastojci za {{value}} osobe/osoba',
      INGREDIENTS_LABEL_ONE: 'Sastojci za {{value}}osobu',
      PEOPLES: '{{value}} osobe/osoba',
      PEOPLE: '{{value}} ljudi',
      DIFFICULTY: { EASY: 'Lako', MEDIUM: 'Srednje', HARD: 'Teško' },
      COST: { CHEAP: 'Jeftino', EXPENSIVE: 'Skupo' },
      TIMING: { MIN: 'minut', HOURS: 'sati' },
      STEP: 'korak',
      DISCOVER_ALSO: 'Otkrijte takođe'
    }
  },
  ARTICLE: { DISCOVER_ALSO: 'Otkrijte takođe', INGREDIENTS: 'Sastojci' },
  SF: {
    SLIDE: { DISCOVER: 'Otkrijte' },
    SEARCH: { PLACEHOLDER: 'Pretraživati', ALL: 'Sve' },
    NEWSLETTER: {
      BTN_LABEL: 'Ja se registrujem',
      PLACEHOLDER: 'Vaš imejl',
      ERROR_MESSAGE: 'Nevažeći imejl',
      ALREADY_SUBSCRIBED: 'Vi ste već pretplaćeni',
      GDPR: {
        TXT: 'Više informacija o upravljanju vašim podacima',
        LINK: '/kolačići#gdpr'
      }
    },
    MONTHLY_FOLDER: { TITLE: 'Mesečne vesti' },
    MINI_NEWSLETTER: {
      PLACEHOLDER: 'Vaš imejl',
      GDPR: {
        TXT: 'Više informacija o upravljanju vašim podacima',
        LINK: '/kolačići#gdpr'
      }
    },
    SOCIALITEM: { SHARE: 'udeo', COMMENT: 'komentar', BONDUELLE: 'Bonduel' },
    SEARCHRECIPE: { DISCOVER_ALL: 'pretražujte po svim receptima' },
    FORM: {
      ERROR: 'Nevažeće polje',
      PHOTO: {
        ADD: 'Dodajte fotografiju',
        DELETE: 'Obrišite fotografiju',
        SEE: 'Vidite'
      }
    }
  },
  CORPORATE: {
    COMPANY_STORY: 'priča o kompaniji',
    CSR_COMMITMENT: 'društvena odgovornost',
    VEGETABLE_INNOVATIONS: 'inovacije u svetu biljaka',
    NEWS: 'sve vesti',
    SEE_MORE: 'Vidite više članaka',
    ON: 'članci o...'
  },
  NEWSLETTER: {
    TITLE: 'Pretplatite se na bilten',
    SUBTITLE: 'Dobre ponude, kulinarska inspiracija i malo kulture!'
  },
  ROUTES: {
    products: 'proizvodi',
    news: 'vesti',
    'since-1853': 'od-1853',
    'field-plate': 'od-polja-do-tanjira',
    'company-story': 'prica-o-kompaniji',
    'csr-commitments': 'drustvena-odgovornost',
    'vegetal-innovations': 'inovacije-u-svetu-povrca',
    magazine: 'magazine',
    all: 'sve',
    article: 'clanak',
    cook: 'cook',
    cultivate: 'cultivate',
    explore: 'explore',
    recipe: 'wait-recipe',
    recipes: 'recepti',
    'recipes-home': 'recipes-home',
    r: 'r',
    credits: 'credits-com',
    'legal-notice': 'legal-notice',
    'terms-of-sales': 'terms-of-sales',
    cookies: 'cookies',
    sitemap: 'sitemap',
    'privacy-policy': 'politika-privatnosti',
    'all-rights-reserved': 'all-rights-reserved',
    search: 'pretraga',
    'articles-search': 'clanak',
    vegetables: 'proizvodi',
    p: 'p',
    contact: 'kontakt',
    'info-product': 'informacion-per-produktin',
    'note-product': 'komentoni-per-nje-produkt',
    suggestion: 'suggestion',
    'other-request': 'beni-nje-kerkese-tjeter',
    faq: 'faq',
    'all-themes': 'all-themes',
    'products-search': 'products-search',
    recipesearch: 'recipe-search',
    'product-news': 'product-news',
    'good-deals': 'good-deals',
    'not-found': 'not-found',
    'menu-page': 'inspiracija',
    'menu-one': 'zdrav-zivot',
    'menu-two': 'kuvanje-ukusnih-jela',
    'menu-three': 'priprema-jela-sa-stilom',
    'menu-four': 'budite-kreativni',
    'menu-external': 'menu-external',
    'theme-pasta-rice': '',
    'theme-bowls': '',
    'theme-soup': '',
    'theme-snack': '',
    'theme-sauce': '',
    'theme-unique-plate': '',
    'theme-over': 'theme-over',
    'theme-legumin': 'legumin',
    'theme-ingredients-recipes': '',
    'theme-cook-plate': ''
  },
  BREAD: {
    products: 'proizvodi',
    news: 'vesti',
    'since-1853': 'od - 1853',
    'field-plate': 'od polja do tanjira',
    'company-story': 'priča o kompaniji',
    'csr-commitments': 'društvena odgovornost',
    'vegetal-innovations': 'inovacije u svetu povrća',
    magazine: 'magazin',
    all: 'sve',
    article: 'članak',
    cook: 'kuvar',
    cultivate: 'uzgajanje',
    explore: 'istraživanje',
    recipe: 'sačekaj recept',
    recipes: 'recepti',
    'recipes-home': 'recepti kod kuće',
    'articles-search': 'članak',
    search: 'pretraga',
    vegetables: 'povrće',
    contact: 'kontakt',
    'info-product': 'informacion për produktin',
    'note-product': 'komentoni për një produkt',
    suggestion: 'predlog',
    'other-request': 'bëni një kërkesë tjetër',
    faq: 'faq',
    'all-themes': 'sve teme',
    'products-search': 'pretraga po proizvodima',
    recipesearch: 'pretraga po receptima',
    'product-news': 'vesti o proizvodima',
    'good-deals': 'dobre ponude',
    'not-found': 'nije pronađen',
    'menu-page': 'INSPIRACIJA',
    'menu-one': 'ZDRAV ŽIVOT',
    'menu-two': 'KUVANJE UKUSNIH JELA',
    'menu-three': 'PRIPREMA JELA SA STILOM',
    'menu-four': 'BUDITE KREATIVNI'
  },
  MENU_PAGE: {
    TITLE1: 'ZDRAV ŽIVOT',
    TITLE2: 'KUVANJE UKUSNIH JELA',
    TITLE3: 'PRIPREMA JELA SA STILOM',
    TITLE4: 'BUDITE KREATIVNI',
    EXTERNAL: ''
  },
  TECHNICAL: { MENU_PAGE: 'ponude_posla' },
  SEO: {
    TITLE: {
      MENU_ONE: 'ZDRAV ŽIVOT',
      MENU_TWO: 'KUVANJE UKUSNIH JELA',
      MENU_THREE: 'PRIPREMA JELA SA STILOM',
      MENU_FOUR: 'BUDITE KREATIVNI',
      HOME: 'Bonduelle: izvor žudnje za povrćem i recepti za kuvanje',
      SEARCH: {
        MAIN: '{{value}}',
        RECIPES: '{{value}}sortirano po receptima | Bonduelle',
        VEGETABLES: '{{value}}sortirano po povrću | Bonduelle',
        ARTICLES: '{{value}}sortirano po clanak | Bonduelle'
      },
      CONTACT: 'Kontaktirajte službu za potrošače, informacije | Bonduelle',
      RECIPES: 'Recepti: supe, kiš, lazanje od povrća... | Bonduelle',
      RECIPE_HOME: 'Bonduelle',
      RECIPE: 'Bonduelle',
      PRODUCTS: 'Bonduelle',
      NEWS: 'Bonduelle',
      CORPORATE: {
        NEWS: 'Bonduelle',
        COMPANY_STORY: 'Bonduelle',
        CSR_COMMITMENTS: 'Bonduelle',
        VEGETAL_INNOVATIONS: 'Bonduelle',
        FIELD_PLATE: 'Bonduelle',
        ARTICLE: '{{value}}',
        VEGETABLE: '{{value}}'
      },
      PRODUCT: '{{value}}',
      MAGAZINE: {
        NEWS: 'Bonduelle',
        ARTICLE: 'Bonduelle',
        COOK: 'Bonduelle',
        GROW: 'Bonduelle',
        EXPLORE: 'Bonduelle',
        RECIPE: 'Bonduelle'
      },
      FAQ: 'Bonduelle',
      NOTFOUND: 'Bonduelle',
      RECIPES_ALL: '',
      RECIPETYPE: '',
      RECIPESEASON: '{{value}}',
      RECIPEPREPARATION: '{{value}}',
      RECIPEDIFFICULTY: '{{value}}',
      RECIPEVEGETABLE: '{{value}}',
      RECIPECOST: '{{value}}',
      RECIPEDISH: '{{value}}',
      RECIPEDESC: '{{value}}',
      '2RECIPEFACETS': '',
      '4RECIPEFACETS': '{{value}}',
      PRODUCTSEASON: '{{value}}',
      PRODUCTTECHNO: '{{value}}',
      PRODUCTVEGETABLE: '{{value}}',
      PRODUCTRANGE: 'Proizvodi iz asortimana {{value}}',
      THEME_ALL: '',
      THEME_STARTER: '',
      THEME_SALAD: '',
      THEME_CAN: '',
      THEME_FROZEN: '',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: '',
      THEME_RECIPE: '',
      THEME_PLATE: '',
      THEME_ACC: '',
      THEME_SNACK: '',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': '{{value}}'
    },
    DESCRIPTION: {
      MENU_ONE: 'ZDRAV ŽIVOT',
      MENU_TWO: 'KUVANJE UKUSNIH JELA',
      MENU_THREE: 'PRIPREMA JELA SA STILOM',
      MENU_FOUR: 'BUDITE KREATIVNI',
      HOME: '',
      SEARCH: {
        MAIN: '{{value}}',
        RECIPES: '{{value}}',
        VEGETABLES: '{{value}}',
        ARTICLES: 'Tema {{value}}: sortirano po članku. Jednostavni i ukusni recepti. Kuvano povrće'
      },
      CONTACT: '',
      RECIPES: '',
      RECIPE_HOME: '',
      RECIPE: '',
      PRODUCTS: '',
      NEWS: '',
      CORPORATE: {
        NEWS: '',
        COMPANY_STORY: '',
        CSR_COMMITMENTS: '',
        VEGETAL_INNOVATIONS: '',
        VEGETABLE: '',
        FIELD_PLATE: '',
        ARTICLE: ''
      },
      PRODUCT: '{{value}}',
      MAGAZINE: {
        NEWS: '',
        ARTICLE: '',
        COOK: '',
        GROW: '',
        EXPLORE: '',
        RECIPE: ''
      },
      FAQ: '',
      PAGE: 'страна ',
      RECIPETYPE: '{{value}}',
      RECIPESEASON: '{{value}}',
      RECIPEPREPARATION: '{{value}}',
      RECIPEDIFFICULTY: '{{value}}',
      RECIPEVEGETABLE: '',
      RECIPECOST: '{{value}}',
      RECIPEDISH: '{{value}}',
      RECIPEDESC: '{{value}}',
      '2RECIPEFACETS': '{{value}}',
      '4RECIPEFACETS': '{{value}}',
      PRODUCTSEASON: '{{value}}',
      PRODUCTTECHNO: '{{value}}',
      PRODUCTVEGETABLE: '{{value}}',
      PRODUCTRANGE: 'Pretraži sve proizvode iz asortimana {{value}}',
      THEME_ALL: '',
      THEME_STARTER: '',
      THEME_SALAD: '',
      THEME_CAN: '',
      THEME_FROZEN: '',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: '',
      THEME_RECIPE: '',
      THEME_PLATE: '',
      THEME_ACC: '',
      THEME_SNACK: '',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': '{{value}}',
      NOTFOUND: ''
    },
    PIPE: { SOCIETY: ' | Bonduelle' },
    THEME_ALL: '',
    THEME_STARTER: '',
    THEME_SALAD: '',
    THEME_CAN: '',
    THEME_FROZEN: '',
    THEME_READY_MEALS: '',
    THEME_BIO: '',
    THEME_VEGETABLES: '',
    THEME_RECIPE: '',
    THEME_PLATE: '',
    THEME_ACC: '',
    THEME_SNACK: ''
  },
  VEGETABLE: {
    DISCOVER_VEGETABLE: 'Saznajte više o našem povrću',
    OURS_RECIPES: 'Naši Recepti',
    DISCOVER_ALSO: 'Saznajte takođe',
    SEE_ALL: 'Vidite svo povrće',
    ON: 'Povrće na'
  },
  RECIPE: {
    'INGREDIENTS_LABEL ': 'Sastojci za {{value}} ljudi',
    PEOPLES: '{{value}} ljudi',
    DIFFICULTY: { EASY: 'Lako', MEDIUM: 'Srednje', HARD: 'Teško' },
    COST: { CHEAP: 'Jeftino', EXPENSIVE: 'Skupo' },
    TIMING: { MIN: 'Minut', HOURS: 'sati' },
    STEP: 'Korak',
    DISCOVER_ALSO: 'Otkrijte takođe',
    HOME_SEE_MORE: 'Vidite više recepata',
    SHOWCASE: 'Vrh',
    HOME_BOOK: 'Naši recepti',
    MIN_MORE: 'm i +',
    HOUR_MORE: 'h i +',
    FILTER_BY_CRITERIA: 'Filtiraj po kriterijumu',
    SORT_BY: 'Sortiraj po:',
    BEST_RATED: 'Najbolje ocenjeni',
    MOST_CONSULTED: 'Najpregledaniji / Najposećeniji',
    MEMBERS_RECIPES: 'Recepti korisnika',
    CRITERIA: 'Vesti',
    DISCOVER: { RECIPES: 'Otkrijte naše recepte' },
    DISCOVER_RECIPE: 'Otkrijte recept',
    INSTRUCTIONS: 'Instrukcije',
    DEFAULT_TAG: 'Ideja za recept',
    MOST_RECENT: 'Najnovije',
    RECIPE_VIDEOS: 'Video snimci recepata',
    PRODUCT_ASSOCIATE: 'Recept napravljen od',
    BUDGET_LOW: 'Jeftin',
    BUDGET_HIGH: 'Visok',
    BUDGET_MIDDLE: 'Srednji',
    PRINT_RECIPE: 'otštampaj recept',
    LET_YOURSELF_SURPRISED: 'Iznenadite sebe',
    COOK_TIP_TITLE: 'Naziv saveta za kuvanje',
    ENJOY_MEAL: 'Uživajte u svom obroku!',
    FILTER: { LEGEND: 'Legenda' }
  },
  PREDIGGO: { DIFFICULTY: { EASY: 'Lako', MEDIUM: 'srednje', HARD: 'Teško' } },
  COOKIES: {
    TEXT: 'Nastavkom posete ovom sajtu prihvatate upotrebu kolačića iz tehničkih razloga, u svrhu statističke analize i upravljanja veb saobraćajem, deljenja informacija sa oglašivačima na drugim veb lokacijama, kako bi vam pružili relevantne promotivne komunikacije koje odražavaju vaša interesovanja.',
    CLOSE: 'Zatvorite',
    MORE: 'Saznajte više',
    AGREE_YOUTUBE: 'Morate prihvatiti Jutjub kolačiće da biste videli ovaj snimak',
    BUTTON_YOUTUBE: 'Dozvolite kolačiće'
  },
  POPUP: { LAUNCH: 'POPUP-OPEN-SITE' },
  FIELD_PLATE: { DISCOVER_VEGETABLE: 'Pogledajte našu ponudu povrća' },
  SEARCH: {
    OUR_RECIPES: 'Recepti',
    OUR_PRODUCTS: 'Proizvodi',
    OUR_ARTICLES: 'Naši članci',
    RECIPES: 'Recepti',
    PRODUCTS: 'Proizvodi',
    ARTICLES: 'Članci',
    ALL: 'Svi',
    SORT_BY: 'Sortrirajte po',
    RESULTS: 'rezultati',
    RESULT: 'rezultat',
    PRODUCT_OUT: 'proizvodi van',
    APPLY: 'Primenite',
    FILTER: 'Pretražite po filteru',
    RECIPE_OUT: 'recepti van',
    NO_RESULT: 'Beli kupus za ovu pretragu ... Postavljate nam nezgodno pitanje',
    SEE_MORE: 'Saznajte više',
    DISCOVER_RECIPES: 'Otkrijte naše recepte',
    SEE_MORE_OF: 'Pogledajte više od',
    DELETE: 'Obrisati'
  },
  RECIPE_LIST: {
    OUR_RECIPES: 'Recepti',
    RESULTS: 'Rezultati',
    SEE_MORE: 'Vidite više recepata'
  },
  PRODUCT: {
    BUTTON: {
      ACTU: 'Vesti o proizvodima',
      FOUND: 'Gde naći ovaj proizvod',
      DISCOVER: 'Otkrijte ceo asortiman'
    },
    NUTRITIONAL_VALUE: 'nutritivna vrednost',
    CONDITIONING: 'tip proizvoda',
    PORTIONS: 'porcije',
    NET_WEIGHT: 'neto težina',
    DRY_WEIGHT: 'oceđena težina',
    INGREDIENTS: 'sastojci',
    OUR_RECIPES: 'Otkrijte naše recepte',
    FOR: 'Za',
    PER_PORTION: 'i po porciji',
    ENERGY: 'energija',
    FAT: 'mast',
    SATURATE: 'zasićena mast',
    GLUCIDES: 'ugljeni hidrati',
    FIBER: 'vlakna',
    PROTEINS: 'proteini',
    SALT: 'so',
    DISCOVER_ALSO: 'Saznajte takođe',
    MORE_INFORMATION: 'više informacija',
    TRACES: 'tragovi od',
    CONTAINS: 'Sadrži',
    SLIDER: { SHEET: 'Prikaži listu proizvoda', BUY: 'kupite ovaj proizvod' },
    NUTRISCORE: 'informacija o nutritivnim vrednostima',
    TRIMAN: 'information-triman',
    AGRICONFIANCE: 'informaciono-poljoprivredno poverenje',
    BIO: 'bio-informacija',
    PESTICIDE_FREE: '',
    LEAF: '',
    LOCK: '',
    SEE_ALL: 'Vidite sve proizvode',
    LEGISLATION: 'u skladu sa važećim zakonodavstvom',
    PORTION: { SINGULAR: 'porcija', PLURAL: 'porcije' },
    DISCLAIMER: '',
    COOKING: 'kuvanje',
    CONSERVATION: 'konzervacija'
  },
  FAQ: {
    ON: 'pitanja u vezi',
    ALL: 'Svi',
    SEE_MORE: 'pogledajte više pitanja',
    TITLE_1: 'Svi odgovori na vaša pitanja',
    TITLE_2: 'Da li imate pitanja u vezi nekog proizvoda?',
    LINK_LABEL: 'Pišite nam'
  },
  FORM: {
    GO_TO_STEP: 'Pređite na sledeće korak',
    STEP: 'Korak',
    RECEIVE_EMAIL_COPY: 'Primite kopiju imejla',
    SEND: 'Pošaljite',
    GDPR: 'Saznajte više o načinu na koji koristimo i štitimo vaše podatke',
    ERROR: 'Sva polja označena sa* moraju biti popunjena kao nevažeća',
    SEND_ERROR: 'Došlo je do greške, molim vas pokušajte ponovo uskoro',
    NOTICE: 'Za pravilno rukovanje vašom arhivom, Bonduelle može, u zavisnosti od slučaja, od vas tražiti zdravstvene podatke. Niste u obavezi da ih podelite',
    SENDING: 'Slanje formulara...',
    LOADING: 'Očitavanje...',
    PRODUCT_INFORMATION_TITLE: 'Informacija o proizvodu',
    PRODUCT_INFORMATION: {
      SEO_TITLE: 'Informacija o proizvodu | Bonduelle',
      SEO_DESC: 'Kontaktirajte našu korisničku službu'
    },
    INFORMATIONS: 'Vaše informacije',
    MISTER: 'Gospodin',
    MISS: 'Gospođa',
    NAME: 'Ime',
    OUR_NAME: 'Naše ime',
    FIRSTNAME: 'Vlastito ime',
    OUR_FIRSTNAME: 'Naše vlastito ime',
    MAIL: 'Vaš imejl',
    MAIL_PLH: 'mail@address.com',
    MAIL_CONFIRM: 'Potvrdite vaš imejl',
    MAIL_ERROR: 'molimo vas upišite ispravnu imejl adresu',
    MAIL_ERROR_CONFIRM: 'molimo vas potvrdite vaš imejl',
    ADDRESS: 'vaša adresa',
    STREET_PLH: '',
    STREET: 'Ulica i broj ulice',
    ADDRESS_COMPLEMENT: 'Više informacija o vašoj adresi',
    ADDRESS_COMP_PLH: 'Više informacija o vašoj adresi',
    ZIPCODE: 'poštanski kod',
    CITY: 'grad',
    CITY_PLH: 'vaš grad',
    PHONE: 'telefon',
    PHONE_PLH: 'broj telefona',
    COUNTRY: 'država',
    PRODUCT_INFORMATIONS: 'Informacija o proizvodu',
    PRODUCT_INFORMATION_DESC: 'Molimo da nam dostavite sledeće informacije kako bi naš tim mogao da obradi vaš <br> zahtev.',
    PRODUCT: {
      DETAIL: {
        DESC: 'Za svaki upit možete popuniti polje obrasca<br>i dodati fotografiju sa objašnjenjem. Informacije: vaša fotografija mora biti lako čitljiva<br>bar-kod, broj serije i rok upotrebe proizvoda.'
      },
      NOTE: {
        TITLE: 'Komentarišite proizvod',
        SEO_TITLE: 'Napomena i komentar na proizvod | Bonduelle',
        SEO_DESC: 'Imate primedbu ili komentar na proizvod iz našeg asortimana? Kontaktirajte našu korisničku službu da vam pomogne. Dobićete personalizovani odgovor u roku od 48 sati'
      }
    },
    PRODUCT_RANGE: 'Asortiman dotičnih proizvoda',
    PRODUCT_NAME: 'Ime proizvoda',
    PRODUCT_NAME_PLH: 'Napišite ime Bonduelle proizvoda koji vas zanima',
    PRODUCT_INFORMATIONS_DETAIL: 'Detaljna informacija',
    PRODUCT_INFORMATIONS_LABEL: 'Bar- kod poizvoda',
    PRODUCT_INFORMATIONS_PLH: 'Bar kod je sastavljen<br>od 13 cifara koji se nalaze na<br>pakovanju proizvoda',
    PRODUCT_LOT: 'Broj serije proizvoda',
    PRODUCT_LOT_INFO: 'Broj serije<br>uvek počinje slovom L praćen<br> sa nekoliko cifara i varira<br> u zavisnosti od proizvoda.<br><br>Na primer L72450492107:13.',
    PHOTO: 'Dodajte fotografiju',
    EXPIRED_DATE: 'Najbolje upotrebiti do',
    EXPIRED_DATE_ERROR: 'Unesite važeći datum u formatu dd/mm/gggg',
    EXPIRED_DATE_DESC: '„NUD (Najbolje upotrebite do)<br>i UDD (Upotrebi do datuma)<br>su predstavljeni kao datumi.“',
    PHOTO_MORE: 'Dodatne fotografije',
    MESSAGE: 'Vaša poruka',
    OUR_MESSAGE: 'Napišite vašu porukiu',
    SALES_OPERATIONS: {
      TITLE: '"Informacije o poslovnoj transakciji"',
      SEO_TITLE: '"Informacije o tekućem poslovanju: konkurs… | Bonduelle"',
      SEO_DESC: 'Kontaktirajte našu službu za korisnike za informacije kupovini koja je u toku. Dobićete personalizovani odgovor u roku od 48 sati'
    },
    PRESS_SERVICE: 'Kontaktirajte odeljenje za štampu',
    FOOD_SERVICE: 'Kontaktirajte tim Bonduelle Food Service',
    BUSINESS_INFORMATION: '"Primite uzorke/vaučere/promotivne artikle"',
    MODAL_INFORMATION: '',
    JOB_OFFER: { TITLE: '', SEO_TITLE: '', SEO_DESC: '' },
    WEB_SUGGESTION: {
      TITLE: 'Dajte predlog o veb sajtu',
      SEO_TITLE: 'Kako se možemo poboljšati? poruka, predlog izgleda sajta | Bonduelle',
      SEO_DESC: 'Molimo kontaktirajte našu korisničku službu da nam pomognete da poboljšamo sajt.'
    },
    ANOTHER: {
      TITLE: 'Napravite novi zahtev',
      SEO_TITLE: 'Kontakt za svaki drugi zahtev, uslugu | Bonduelle',
      SEO_DESC: 'Kontaktirajte našu službu za potrošače za informacije, uslugu ili sugestiju. Dobićete personalizovani odgovor u roku od 48 sati'
    },
    AMBASSADOR: 'Postanite ambasador Bonduelle',
    CATEGORY: {
      SALAD: 'Salata',
      FROZEN: 'Zamrznuto',
      CAN: 'konzerva',
      TAKEAWAY: 'za poneti',
      VEGETABLE: ''
    }
  },
  CONTACT: {
    TITLE: 'Na vašoj usluzi',
    SPECIAL_INFO: '',
    TAKE_CARE: '',
    BY_MAIL: 'Mejlom',
    BY_PHONE: 'Telefonom',
    BY_SOURDLINE: 'By Sourdline',
    MAIL_TEXT: 'Bonduelle usluga potrošača',
    PHONE_TEXT: 'Naši savetnici su na vašoj usluzi <a href="tel:+33970252222"><b>09 70 25 22 22</b></a> (non-premium call)\n' +
      'od ponedeljka do petka 9:00 - 18:00.\n' +
      'Ipak, moguće je da objavite glasovnu poruku 24 časa dnevno.',
    SOURDLINE_TEXT: 'Za gluve/osobe sa oštećenim sluhom, na vašoj smo usluzi putem kamere ili četa <b> od ponedeljka do petka od 9 h do 18 h.</b>',
    BY_DISTRIBUTOR: 'Distributeri',
    BY_DISTRIBUTOR_TEXT: 'Mercata VT<br />\n' +
      'Megarska 9, Novi Beograd, Serbia<br />\n' +
      '+381 21 310 3300<br />\n' +
      'office@mercatavt.rs<br />\n' +
      '<a href="https://mercatavt.rs" target="_blank">https://mercatavt.rs</a><br />',
    SUBTITLE: 'Želite da nas kontaktirajte zbog:',
    AGREEMENT: '',
    READ_MORE: "Pored toga, imate pravo, u bilo kom trenutku, da zahtevate od BONDUELLE da ostvari vaša prava na pristup, ispravku, brisanje i prenosivost vaših ličnih podataka, kao i svoja prava da ograničite i prigovorite na obradu vaših ličnih podataka. Za ostvarivanje vaših prava u vezi sa vašim ličnim podacima, pišite na Dpo_france(at)bonduelle(dot)com ili poštom na sledeću adresu: za pažnju službenika za zaštitu podataka, Bonduelle, Rue Nicolas Appert, poštanski fah 30173, F- 59653 Villeneuve D'Asck. Da biste saznali više o načinu na koji BONDUELLE prikuplja i koristi vaše lične podatke kliknite ovde",
    READ_MORE_LABEL: 'Pročitajte takođe',
    SATISFACTION_TEXT: 'Да бисмо вас контактирали да бисмо вам пружили тражене\n' +
      'информације о производу и да бисмо вам послали упитник о\n' +
      'задовољству ако то желите, ми ћемо обрадити информације које сте\n' +
      'дали у својству контролора података. Правни основ за обраду вашег\n' +
      'захтева је сагласност због потенцијалних здравствених података које\n' +
      'можете навести у свом захтеву. Ове информације ће се чувати\n' +
      'онолико дуго колико је потребно за обраду вашег захтева и до 5\n' +
      'година да бисмо пружили доказ да је ваш захтев обрађен и да бисмо\n' +
      'испунили наше законске обавезе. Правни основ за слање упитника о\n' +
      'задовољству је и ваша сагласност. Ове информације ће се чувати\n' +
      'онолико времена колико је потребно за слање упитника о\n' +
      'задовољству и највише 1 годину. Да бисте сазнали више о обради\n' +
      'ваших личних података, погледајте нашу <a href="https://www.bonduelle.rs/politika-privatnosti" target="_blank" class="underline">Политику приватности</a>.',
    SATISFACTION_CHECKBOX: 'Означавањем овог поља прихватате да добијете упитник о\n' +
      'задовољству (који се састоји од једног питања) након што ваш захтев\n' +
      'буде обрађен. То је опционо.',
    CHECKBOX_TEXT: 'Означавањем овог поља сагласни сте да Бондуелле може да обрађује\n' +
      'личне здравствене податке које наведете у својој поруци искључиво\n' +
      'у сврху одговора на ваш захтев. <strong>То је обавезно за обраду вашег\n' +
      'захтева</strong>.',
    PHONE: 'Od ponedeljka do petka od 9 do 18 časova 00.33.09.70.25.22.22. (poziv se ne naplaćuje, ne računajući vikende i državne praznike)',
    CALL_US: 'Pozovite nas',
    TO_WRITE: 'Pišite nam',
    PHONE_NUMBER: '3.3097E+11',
    SUCCESS_MESSAGE: 'Hvala ! Vaša poruka je poslata našem timu. Biće obrađena za 2 do 3 dana, izuzimajući vikend i praznike',
    PHOTOERROR: { MESSAGE: '' }
  },
  GLOBAL: { READ_MORE: 'Pročitajte više' },
  FLOWBOX: {
    GENERIC_KEY: 'ba_GnGC3TKedKEe0-gKomQ',
    PRODUCT_KEY: '1hRXF7TUSt-fhhlJASv-TQ',
    RECIPE_KEY: 'Ulp6NW8-QFOoSa61nBIlaA',
    HOME_KEY: 'g7--tXG3QgiwJjjpsaMmrg'
  },
  TOKEN: {
    FACEBOOK: '',
    PAGE_FACEBOOK_ID: '',
    INSTAGRAM: '',
    INSTA_ID: '17841407279489116',
    YOUTUBE: '',
    TWITTER: '',
    MAGAZINE: '6318061952.9bbab10.8842be1c95904c9d80ff59765a3a2ccc'
  },
  COOKIE: {
    BANNER: {
      MANDATORY: 'Neophodno',
      ANALYTICS: 'ANALITIKA',
      MARKETING: 'MARKETING',
      SOCIAL_MEDIA: 'SOCIAL MEDIA / DRUŠTVENE MREŽE',
      ACCEPT: 'Prihvati sve',
      REJECT: 'Odbaci sve',
      SET_UP: 'Konfiguracija',
      MANDATORY_DETAILS: 'Potrebni kolačići su neophodni za funkcionisanje sajta, na primer za čuvanje vaših podataka za prijavu i obezbeđivanje bezbedne veze.',
      ANALYTICS_DETAILS: 'Funkcionalni kolačići nam pomažu da optimizujemo sajt, na primer, da prikupimo statistiku, da zabeležimo vaše preferencije za društvene interakcije ili da merimo performanse i stabilnost naše aplikacije',
      MARKETING_DETAILS: 'Marketinški kolačići nam omogućavaju da pratimo vaše preferencije za recepte i proizvode kako bismo vam uvek ponudili proizvode koji najbolje odgovaraju vašim željama.',
      V2: 'ok'
    },
    MODAL: {
      TITLE: 'Upravljanje kolačićima',
      TEXT: 'Bonduelle Grupa je posvećena obezbeđivanju zaštite ličnih podataka korisnika svojih veb lokacija, a posebno veb stranice Bonduelle.fr. <br>Da bismo vam omogućili da iskoristite usluge koje nudi sajt, kao što su savetovanje o proizvodima, otkrivanje novih gurmanskih recepata, pretplata na određene usluge kao što su naši bilteni, kao i da optimizujete njegovu upotrebu i personalizaciju u skladu sa vašim profilom, sajt koristi kolačiće. Bonduelle grupa je u potpunosti posvećena obezbeđivanju visokog nivoa zaštite u vezi sa takvim ličnim podacima i poštovanju svih važećih pravila o zaštiti podataka prilikom obrade ličnih podataka u vezi sa korišćenjem svoje veb stranice.',
      CONFIG: 'Konfiguracija',
      SAVE: 'Sačuvati'
    }
  },
  LINK: { JOB_OFFERS: 'https://jobs.bonduelle.com/?locale=en_US' },
  CLIC2BUY: { INFOS: '' }
};
