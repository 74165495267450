import { Component, OnInit, TemplateRef } from '@angular/core';
import { SfPopoverContent } from '../../services/popover.service';
import { SfPopoverRef } from '../../services/popover-ref';

@Component({
    templateUrl: './popover.component.html',
    styleUrls: ['./popover.component.sass'],
    standalone: false
})
export class SfPopoverComponent implements OnInit {
 renderMethod: 'template' | 'component' | 'text' = 'component';
 content: SfPopoverContent;
 context;

 constructor(private popoverRef: SfPopoverRef) {
 }

 ngOnInit() {
   this.content = this.popoverRef.content;

   if (typeof this.content === 'string') {
     this.renderMethod = 'text';
   } else if (this.content instanceof TemplateRef) {
     this.renderMethod = 'template';
     this.context = {
       close: this.popoverRef.close.bind(this.popoverRef)
     };
   }
 }
}
