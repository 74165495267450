import { Component, OnInit } from '@angular/core';
import { SfPopoverRef } from '../../services/popover-ref';

@Component({
    selector: 'sf-form-image-popover',
    templateUrl: './image-popover.component.html',
    styleUrls: ['./image-popover.component.sass'],
    standalone: false
})
export class SfImagePopoverComponent implements OnInit {

  public imgSrc: string;

  constructor(
    private popoverRef: SfPopoverRef
  ) {
  }

  ngOnInit() {
    if (this.popoverRef && this.popoverRef.data) {
      this.imgSrc = this.popoverRef.data.imgSrc;
    }
  }

  close() {
    this.popoverRef.close(true);
  }

}
