<div class="sf-form-radio" *ngIf="data && formControl">
  <label class="sf-form-radio-label sf-title" [for]="controlName" *ngIf="data.label">
    {{ data.label | translate }}
    <span *ngIf="data.required" [ngClass]="{'sf-error-color': !formControl.pristine && formControl.invalid}"> *</span>
  </label>


  <div class="sf-form-radio-container">
    <div class="sf-form-radio-option" *ngFor="let rd of data.values;index as i">

      <input class="sf-form-radio-input" [value]="rd.value" [name]="controlName" [id]="controlName + i" [formControl]="formControl" type="radio">
      <label [for]="controlName + i" class="sf-form-radio-input-label sf-title">
        {{ rd.label | translate }}
      </label>

    </div>
  </div>

  
  <div class="sf-form-radio-error sf-error-color sf-title" *ngIf="!formControl.pristine && formControl.invalid">
    {{ data.error || 'SF.FORM.ERROR' | translate }}
  </div>
</div>
