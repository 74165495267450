import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'removeDash',
    standalone: false
})
export class RemoveDashPipe implements PipeTransform {
    transform(value: string): string {
        const val = value ? value.replace(/-/g, ' ') : '';
        return val;
    }
}
