import { TranslateModuleConfig, TranslateLoader } from '@ngx-translate/core';
import { AppTranslateLoaderFactory } from './translate-loader-factory';

/**
 * Default config for translate module
 */
export const translateConfig: TranslateModuleConfig = {
    loader: {
        provide: TranslateLoader,
        useFactory: AppTranslateLoaderFactory
    }
};
