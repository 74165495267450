import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UtilsModule } from '@app/shared/utils/utils.module';
import { SfSectionImageIconComponent } from './components/section-image-icon.component';
import { SfImageModule } from '../image/image.module';

@NgModule({
    imports: [
        CommonModule,
        SfImageModule,
        UtilsModule,
    ],
  declarations: [SfSectionImageIconComponent],
  exports: [SfSectionImageIconComponent]
})
export class SfSectionImageIconModule { }
