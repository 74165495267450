import { Injectable, Inject } from '@angular/core';
import { environment } from '@env/environment';
import { SfLocalizeService, SITE_INFO, SfSiteInfo } from '@app/sf-lib/public_api';
import { SomPage } from '@app/core/models/page.model';
import { SomBloc } from '@app/core/models/bloc.model';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  get apiUrl(): string {
    // 'en' is the default language for drupal. It doesn't need a langcode in url
    if (this.langcode !== 'en') {
      return environment.apiUrl.replace('{langcode}', this.langcode);
    } else {
      return environment.apiUrl.replace('/{langcode}', '');
    }
  }

  // Returns the right langcode as expected by drupal backend
  // Drupal expects two letters langcode when the site has only one language
  // And 4 letters langcode (i.e: 'nl-be') when site has more than 1 language
  // Also we have country with diffentiate language (i.e: 'pt-pt' or 'pt-br' for Portugal)
  get langcode(): string {
    if (this.siteInfo.locales.length > 1
      || this.localize.currentLang.country === 'pt'
      || this.localize.currentLang.country === 'at'
    ) {
      return `${this.localize.currentLang.code}-${this.localize.currentLang.country}`;
    } else {
      if (this.localize.currentLang.country === this.localize.currentLang.code) {
      return this.localize.currentLang.country;
      } else {
        return this.localize.currentLang.code;
      }
    }
  }

  constructor(
    private localize: SfLocalizeService,
    @Inject(SITE_INFO) private siteInfo: SfSiteInfo
  ) { }

  public mergePage(newPage: SomPage, oldPage: SomPage): SomPage | undefined {
    if (!newPage && !oldPage) {
      return void 0;
    } else if (!newPage && oldPage) {
      return oldPage;
    } else if (newPage && oldPage && !newPage.content) {
      newPage.content = oldPage.content;
    } else if (newPage && newPage.content && oldPage && oldPage.content) {
      // Remove duplicate from locale mock
      newPage.content.forEach(
        (content: SomBloc) => {
          if (content.sfType === 'SomHighlights') {
            content.weight = -2;
          }
          let duplicateIndex = -1;
          do {
            duplicateIndex = oldPage.content.findIndex(c => content.sfType === c.sfType);
            if (duplicateIndex >= 0) {
              oldPage.content.splice(duplicateIndex, 1);
            }
          } while (duplicateIndex >= 0);
        }
      );
      newPage.content = newPage.content.concat(oldPage.content);
      newPage.content.sort((a, b) => a.weight > b.weight ? 1 : a.weight === b.weight ? 0 : -1 );
    }
    return newPage;
  }
}
