import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SITE_INFO, SfSiteInfo } from './models/site-info.model';
import { SfLocalizeService } from './services/localize.service';

import { SfLocalizeConfig } from './models/localize-config.model';
import { SfLocalizePipe } from './pipes/localize.pipe';
import { SfLang } from '../models/lang.model';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [SfLocalizePipe],
  exports: [SfLocalizePipe]
})
export class SfLocalizeModule {
  static forRoot(config: SfLocalizeConfig): ModuleWithProviders<SfLocalizeModule> {
    return {
      ngModule: SfLocalizeModule,
      providers: [
        config.siteInfo ? config.siteInfo :
        {
          provide: SITE_INFO,
          useFactory: dummySiteInfo
        },
        SfLocalizeService,
      ],
    };
  }
}

export function dummySiteInfo(): SfSiteInfo {
  const lang: SfLang = {
    sfType: 'SfLang',
    country: 'com',
    code: 'en',
    default: true
  };
  const si: SfSiteInfo = {
    lang: lang,
    locales: [lang],
    defaultLang: lang,
    path: '',
    translationObject: {},
    origin: ''
  };
  return si;
}
