/* tslint:disable */export const dk_da = {
  '404': {
    TITLE: 'Ups...',
    PAGENOTFOUND: 'Siden eksisterer ikke',
    BUTTON: 'Tilbage til homepage',
    RECIPES: 'Se vores opskrifter'
  },
  HOME: {
    SHOWCASE: 'Opskrifter',
    CATCH_PHRASE: "Today's surprising vegetables, \nfor a better tomorrow",
    SHOWCASE_SEE_ALL: 'Se alle opskrifter',
    MAGAZINE: { TITLE: 'Udvalgte artikler', BUTTON: 'Læs vores nyheder' },
    OUR_VEGETABLES: 'Vores grøntsager',
    SOCIAL_WALL: 'Del din passion for grøntsager sammen med os',
    FAQ_TITLE: 'FAQ',
    FAQ_SEE_MORE: 'Læs mere',
    FLOWBOX: ''
  },
  SELECT: { DEFAULT_VALUE: 'Anbefalede' },
  MENU: {
    CONTACT_US: 'Kontakt os',
    MY_ACCOUNT: 'Min konto',
    GOOD_DEALS: 'FAQ',
    NEWSLETTER: 'Bonduelle Nyhedsbrev'
  },
  FOOTER: {
    LINK: {
      BONDUELLE: 'Bonduelle.dk',
      CREDITS: 'Credits',
      LEGAL_NOTICE: 'Juridisk meddelelse',
      TERMS_OF_SALES: 'Generelle salgsbetingelser',
      COOKIES: 'Cookies',
      SITEMAP: 'Sitemap',
      PRIVACY_POLICY: 'Ekstern databeskyttelsespolitik',
      ALL_RIGHTS_RESERVED: '2021 © Alle rettigheder forbeholdes'
    },
    HISTORY: {
      TITLE: 'Bonduelle - siden 1853',
      TEXT: 'Vi er en fransk familieejet virksomhed med rødder helt tilbage til 1853. Så vi ved en del om, hvordan man høster og pakker grønsager, så de bevarer alt det gode. Hos Bonduelle ønsker vi at grøntsager skal være en vigtig del af den fremtidige kost. Vi har også fokus på madspild, hvorfor størstedelen af vores sortiment sælges i små emballager, således mener vi, at imødekomme både de mindre husstandes behov, samt mindske mængden af det der smides ud.'
    },
    NEWSLETTER: {
      TITLE: 'Bonduelle Community',
      TEXT: 'Jeg vil gerne modtage nyhedsbrevet "Alt om grøntsager" af Bonduelle (nyheder, kampagner, konkurrencer, artikler etc.) ',
      PLACEHOLDER: 'Din email',
      GDPR_LINK: 'Mere om håndtering af dine data'
    }
  },
  PRODUCTS: 'Produkter',
  MAGAZINE: {
    HOME: 'Velkommen til vores nyheder',
    CATCH_PHRASE: '',
    MENU: { BONDUELLE_LINK: 'Gå tilbage' },
    SHOWCASE: 'Udvalgte artikler',
    PREVIOUS_ARTICLES: 'Tidligere artikler',
    PREVIOUS_ARTICLES_SEE_ALL: 'Se alle vores artikler',
    PREVIOUS_EDITIONS: 'Vores tidligere udgaver',
    PREVIOUS_EDITIONS_SEE_ALL: 'Se flere udgaver',
    PREVIOUS_ARTICLES_BY_CATEGORY: 'Tidligere artikler efter kategori',
    RUBRIC: {
      SEE_ALL: 'Se alle opskrifter',
      SEE_ALL_ARTICLES: 'Se alle artikler',
      ON: 'artikler ud af '
    },
    RUBRIC_COOK: { ON: 'opskrifter ud af ' },
    ARTICLE: { JOIN_US: 'Følg os', READ_ALSO: 'Læs også:' },
    RECIPE: {
      INGREDIENTS: 'Ingredienser',
      INGREDIENTS_LABEL_DESC: 'til {{value}} personer',
      INGREDIENTS_LABEL_ONE_DESC: 'til {{value}} person',
      INGREDIENTS_LABEL: 'Ingredienser til {{value}} personer',
      INGREDIENTS_LABEL_ONE: 'Ingredienser til {{value}} person',
      PEOPLES: '{{value}} personer',
      PEOPLE: '{{value}} person',
      DIFFICULTY: { EASY: 'Let', MEDIUM: 'Medium', HARD: 'Svær' },
      COST: { CHEAP: 'Billig', EXPENSIVE: 'Dyrt' },
      TIMING: { MIN: 'min.', HOURS: 'timer' },
      STEP: 'Trin',
      DISCOVER_ALSO: 'Se også:'
    }
  },
  ARTICLE: { DISCOVER_ALSO: 'Læs også:', INGREDIENTS: 'Ingredienser' },
  SF: {
    SLIDE: { DISCOVER: 'Opdage' },
    SEARCH: { PLACEHOLDER: 'Søgning', ALL: 'Alt' },
    NEWSLETTER: {
      BTN_LABEL: 'Tilmeld',
      PLACEHOLDER: 'Din email',
      ERROR_MESSAGE: 'Ugyldig email',
      ALREADY_SUBSCRIBED: 'Du er allerede tilmeldt',
      GDPR: {
        TXT: 'Flere oplysninger om håndtering af dine data',
        LINK: '/ekstern-databeskyttelsespolitik'
      }
    },
    MONTHLY_FOLDER: { TITLE: 'Månedlige nyheder' },
    MINI_NEWSLETTER: {
      PLACEHOLDER: 'Din email',
      GDPR: {
        TXT: 'Flere oplysninger om håndtering af dine data',
        LINK: '/ekstern-databeskyttelsespolitik'
      }
    },
    SOCIALITEM: { SHARE: 'del', COMMENT: 'kommenter', BONDUELLE: 'Bonduelle' },
    SEARCHRECIPE: { DISCOVER_ALL: 'Søg blandt alle vores opskrifter!' },
    FORM: {
      ERROR: 'Ugyldigt felt ',
      PHOTO: { ADD: 'Tilføj et billede', DELETE: 'Slet billedet', SEE: 'Se' }
    }
  },
  CORPORATE: {
    COMPANY_STORY: 'Vores historie',
    CSR_COMMITMENT: 'Vores engagement',
    VEGETABLE_INNOVATIONS: 'Konserves & innovationer',
    NEWS: 'alle artikler',
    SEE_MORE: 'Se flere artikler',
    ON: 'artikler ud af'
  },
  NEWSLETTER: {
    TITLE: 'Tilmeld dig nyhedsbrevet',
    SUBTITLE: 'Gode ideer og inspiration til din madlavning! '
  },
  ROUTES: {
    products: 'produkter',
    news: 'sidste-nyt',
    'since-1853': 'siden-1853',
    'field-plate': 'marken-bordet',
    'company-story': 'vores-historie',
    'csr-commitments': 'vores-engagement',
    'vegetal-innovations': 'konserves-og-innovationer',
    magazine: 'nyheder',
    all: 'se-alt',
    article: 'artikel',
    cook: 'madlavning',
    cultivate: 'dyrke',
    explore: 'udforske',
    recipe: 'opskriftsverden',
    recipes: 'opskrifter',
    'recipes-home': 'opskrifter-home',
    r: 'r',
    credits: 'credits-dk',
    'legal-notice': 'juridisk-meddelelse',
    'terms-of-sales': 'generelle-salgsbetingelser',
    cookies: 'ekstern-databeskyttelsespolitik',
    sitemap: 'sitemap',
    'privacy-policy': 'ekstern-databeskyttelsespolitik',
    'all-rights-reserved': 'alle-rettigheder-forbeholdt',
    search: 'sog',
    'articles-search': 'artikel',
    vegetables: 'produkter',
    p: 'p',
    contact: 'kontakt-os',
    'info-product': 'produktinformationer',
    'note-product': 'produktreklamation',
    suggestion: 'forslag',
    'other-request': 'produktinformationer',
    faq: 'faq',
    'all-themes': 'alle',
    'products-search': 'produkter-sogning',
    recipesearch: 'opskrifter-sogning',
    'product-news': 'nyheder',
    'good-deals': 'gode-tilbud',
    'not-found': 'ikke-fundet',
    'menu-page': 'menu-side',
    'menu-one': 'menu-en',
    'menu-two': 'menu-to',
    'menu-three': 'menu-tre',
    'menu-four': 'menu-fire',
    'menu-external': '',
    'theme-pasta-rice': 'tema-pasta-ris',
    'theme-bowls': 'tema-bowls',
    'theme-soup': 'tema-supper',
    'theme-snack': 'tema-snacks',
    'theme-sauce': 'tema-sovser',
    'theme-unique-plate': '',
    'theme-over': 'tema-andre-retter',
    'theme-legumin': 'tema-bælgfrugter',
    'theme-ingredients-recipes': 'tema-ingredienser-opskrifter',
    'theme-cook-plate': ''
  },
  BREAD: {
    products: 'produkter',
    news: 'sidste nyt',
    'since-1853': 'siden 1853',
    'field-plate': 'marken bordet',
    'company-story': 'vores historie',
    'csr-commitments': 'vores engagement',
    'vegetal-innovations': 'konserves-og-innovationer',
    magazine: 'nyheder',
    all: 'se alt',
    article: 'artikel',
    cook: 'madlavning',
    cultivate: 'dyrke',
    explore: 'udforske',
    recipe: 'opskriftsverden',
    recipes: 'opskrifter',
    'recipes-home': 'opskrifter home',
    'articles-search': 'artikel',
    search: 'Søg',
    vegetables: 'produkter',
    contact: 'kontakt os',
    'info-product': 'produktinformationer',
    'note-product': 'produktreklamation',
    suggestion: 'forslag',
    'other-request': 'produktinformationer',
    faq: 'faq',
    'all-themes': 'alle',
    'products-search': 'produkter søgning',
    recipesearch: 'opskrifter søgning',
    'product-news': 'nyheder produkter',
    'good-deals': 'gode tilbud',
    'not-found': 'ikke fundet',
    'menu-page': 'menu side',
    'menu-one': 'menu en',
    'menu-two': 'menu to',
    'menu-three': 'menu tre',
    'menu-four': 'menu fire'
  },
  MENU_PAGE: {
    TITLE1: 'TITEL 1',
    TITLE2: 'TITEL 2',
    TITLE3: 'TITEL 3',
    TITLE4: 'TITEL 4',
    EXTERNAL: ''
  },
  TECHNICAL: { MENU_PAGE: 'job_opslag' },
  SEO: {
    TITLE: {
      MENU_ONE: 'menu one Title',
      MENU_TWO: 'menu two Title',
      MENU_THREE: 'menu three Title',
      MENU_FOUR: 'menu four Title',
      HOME: 'Bonduelle: find inspiration i vores grøntsagsunivers',
      SEARCH: {
        MAIN: '{{value}} | Bonduelle',
        RECIPES: '{{value}} sortér efter opskrift | Bonduelle',
        VEGETABLES: '{{value}} sortér efter grøntsag | Bonduelle',
        ARTICLES: '{{value}} sortér efter artikel | Bonduelle'
      },
      CONTACT: 'Kundeservice | Bonduelle',
      RECIPES: 'Opskrifter: supper, salater, hovedretter ... | Bonduelle\n',
      RECIPE_HOME: 'Vores opskriftsunivers | Bonduelle',
      RECIPE: 'Opskrift {{value}} | Bonduelle',
      PRODUCTS: 'Konserves  | Bonduelle',
      NEWS: 'Opdag grøntsagernes potentiale  | Bonduelle',
      CORPORATE: {
        NEWS: 'Nye artikler om grøntsager og madlavning  | Bonduelle',
        COMPANY_STORY: 'Lær Bonduelle og dens historie at kende  | Bonduelle',
        CSR_COMMITMENTS: 'Læs om vores værdier og principper  | Bonduelle',
        VEGETAL_INNOVATIONS: 'Læs om vores innovationer | Bonduelle',
        FIELD_PLATE: 'Grøntsager: de bedste grøntsager til de bedste opskrifter | Bonduelle',
        ARTICLE: '{{value}} | Bonduelle',
        VEGETABLE: 'Lær mere om {{value}} | Bonduelle'
      },
      PRODUCT: '{{value}}: Grøntsager fra Bonduelle',
      MAGAZINE: {
        NEWS: 'Artikler om grøntsagernes fordele, opskrifter, og nyheder | Bonduelle',
        ARTICLE: 'Bonduelle',
        COOK: 'Hurtige og nemme opskrifter med grøntsager | Bonduelle',
        GROW: 'Dyrk dine egne grøntsager | Bonduelle',
        EXPLORE: 'Lær noget nyt om grøntsager| Bonduelle',
        RECIPE: 'Opskrift {{value}} | Bonduelle'
      },
      FAQ: 'FAQ: ofte stillede spørgsmål | Bonduelle',
      NOTFOUND: '404-fejl. Venligst prøv igen senere | Bonduelle',
      RECIPES_ALL: 'Vores opskrifter',
      RECIPETYPE: 'Opskrifter: {{value}} ',
      RECIPESEASON: 'Årstid: {{value}}',
      RECIPEPREPARATION: 'Opskrift: tilberedningstid {{value}}',
      RECIPEDIFFICULTY: 'Nemme opskrifter med grøntsager efter {{value}}.',
      RECIPEVEGETABLE: 'Nemme opskrifter med {{value}}',
      RECIPECOST: 'Opskrifter og grøntsager {{value}}',
      RECIPEDISH: 'Opskrifter med grøntsager til {{value}}',
      RECIPEDESC: 'Opskrifter og grøntsager {{value}}',
      '2RECIPEFACETS': 'Opskrift {{value}}',
      '4RECIPEFACETS': 'Opskrift {{value}}',
      PRODUCTSEASON: 'Opskrifter med grøntsager som passer til: {{value}}',
      PRODUCTTECHNO: 'Nemme opskrifter med grøntsager {{value}}',
      PRODUCTVEGETABLE: 'Nemme opskrifter med {{value}}',
      PRODUCTRANGE: 'Nemme opskrifter med {{value}}',
      THEME_ALL: 'Grøntsagskonserves',
      THEME_STARTER: 'Opskriftsideer til dine forretter',
      THEME_SALAD: 'Opskriftsideer til dine salater',
      THEME_CAN: '',
      THEME_FROZEN: '',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: '',
      THEME_RECIPE: 'Opskriftsideer til nemme og hurtige opskrifter ',
      THEME_PLATE: 'Opskriftsideer til varme og kolde retter',
      THEME_ACC: 'Opskriftsideer til tilbehør',
      THEME_SNACK: 'Opskriftsideer til småretter og snacks',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': 'Opskrift med grøntsager {{value}}'
    },
    DESCRIPTION: {
      MENU_ONE: 'menu one description',
      MENU_TWO: 'menu two Description',
      MENU_THREE: 'menu three Description',
      MENU_FOUR: 'menu four Description',
      HOME: 'Opdag vores produkter og få inspiration til din madlavning.',
      SEARCH: {
        MAIN: 'Opdag vores opskrifter og produkter til {{value}}. Nemme og lækre opskrifter med grøntsager som er klar til brug.',
        RECIPES: 'Alle vores {{value}} sorteret efter opskrift. Nemme og lækre opskrifter med grøntsager.',
        VEGETABLES: 'Alle vores {{value}} sorteret efter grøntsag. Nemme og lækre opskrifter med grøntsager.',
        ARTICLES: 'Alle vores {{value}} sorteret efter artikel. Nemme og lækre opskrifter med grøntsager.'
      },
      CONTACT: 'Har du et spørgsmål omkring et af vores produkter? Du kan kontakte vores kundeservice.',
      RECIPES: 'Opdag vores lækre, grøntsagsbaserede opskrifter. Glæd dine nærmeste med lækre og hurtige opskrifter, tilberedt med klar-til-brug grøntsager. ',
      RECIPE_HOME: 'Opdag lækre grøntsagsbaserede opskrifter til årets højtider. Glæd dine nærmeste med lækre og hurtige opskrifter, tilberedt med klar-til-brug grøntsager. ',
      RECIPE: '',
      PRODUCTS: 'Vi tilbyder et sortiment af grøntsager som du kan bruge til at tilberede lækre og nemme opskrifter. ',
      NEWS: 'Læs mere omkring hvordan du tilbereder grøntsager på lækre måder. Nemme opskrifter med klar-til-brug grøntsager.',
      CORPORATE: {
        NEWS: 'De sidste nyheder fra vores grøntsagsverden. ',
        COMPANY_STORY: 'Vil du gerne vide mere omkring vores grøntsagsunivers og vores historie? Læs mere om det på vores blog.',
        CSR_COMMITMENTS: 'Vil du gerne vide mere omkring hvordan vi dyrker vores grøntsager, hvor de kommer fra, og hvordan vi respekterer miljøet? Find alle svar på dine spørgsmal her. ',
        VEGETAL_INNOVATIONS: 'Vi tænker meget på plantebaseret innovation. Blandt andet på hvordan man kan dykre grøntsager på miljøvenlige måder, som producerer mindre spild. Nemme og lækre opskrifter. Klar-til-brug grøntsager. ',
        VEGETABLE: '',
        FIELD_PLATE: 'Opdag vores lækre opskrifter.   ',
        ARTICLE: ''
      },
      PRODUCT: '{{value}} til tilberedning af lækre opskrifter som du kan glæde dine nærmeste med. Nemme og lækre opskrifter med klar-til-brug grøntsager.',
      MAGAZINE: {
        NEWS: 'Alle de sidste nyheder omkring grøntsager og grøntsagsmad. Nemme og lækre opskrifter med klar-til-brug grøntsager. ',
        ARTICLE: '',
        COOK: 'Vores lækre opskrifter får dine gæster til at føle sig som heldige kartofler. Se dem her på vores hjemmeside. Nemme og lækre opskrifter med klar-til-brug grøntsager.   ',
        GROW: 'Vil du gerne dyrke dine egne grøntsager? Læs vores råd og tips til dyrkning og plejning af grøntsager.',
        EXPLORE: 'Få inspiration til sjove opskrifter du kan bruge for at introducere dine børn for nye grøntsager. ',
        RECIPE: ''
      },
      FAQ: 'Har du et spørgsmål? Vi har samlet her de spørgsmål, som vores kunder stiller os oftest. ',
      PAGE: 'side',
      RECIPETYPE: 'Opdag vores opskrifter {{value}} og lær hvordan du tilbereder grøntsager bedst.',
      RECIPESEASON: 'Opdag vores opskrifter og lær hvordan man tilbereder grøntsager som {{value}}.  ',
      RECIPEPREPARATION: 'Opdag vores opskrifter og lær hvordan man tilbereder grøntsager på {{value}}.  ',
      RECIPEDIFFICULTY: 'Opdag vores opskrifter og lær hvordan man tilbereder grøntsager {{value}}.  ',
      RECIPEVEGETABLE: 'Opdag vores opskrifter med {{value}} og lær hvordan man tilbereder grøntsager bedst.  ',
      RECIPECOST: 'Opdag vores opskrifter og lær hvordan man tilbereder grøntsager bedst til {{value}}.  ',
      RECIPEDISH: 'Opdag vores opskrifter og lær hvordan man tilbereder grøntsager bedst til {{value}} personer.  ',
      RECIPEDESC: 'Opdag vores opskrifter og lær hvordan man tilbereder grøntsager bedst som {{value}}.  ',
      '2RECIPEFACETS': 'Opdag vores opskrifter og lær hvordan man tilbereder grøntsager bedst som {{value}}.  ',
      '4RECIPEFACETS': 'Opdag vores opskrifter og lær hvordan man tilbereder grøntsager bedst {{value}}.  ',
      PRODUCTSEASON: 'Vi tilbyder et sortiment af grøntsager som du kan bruge til at tilberede lækre og nemme opskrifter om {{value}}   ',
      PRODUCTTECHNO: 'Vi tilbyder et sortiment af grøntsager som du kan bruge til at tilberede lækre og nemme opskrifter {{value}}.   ',
      PRODUCTVEGETABLE: 'Vi tilbyder et sortiment af grøntsager som du kan bruge til at tilberede lækre og nemme opskrifter med {{value}}.   ',
      PRODUCTRANGE: 'Vi tilbyder et sortiment af grøntsager som du kan bruge til at tilberede lækre og nemme opskrifter med {{value}}.   ',
      THEME_ALL: 'Vi tilbyder et sortiment af grøntsager som du kan bruge til at tilberede lækre og nemme opskrifter.   ',
      THEME_STARTER: 'Søger du efter inspiration til en forret? Hos os finder du et stort udvalg af lækre opskrifter, og meget mere.',
      THEME_SALAD: 'Søger du efter inspiration til en lækker og frisk salat? Hos os finder du et stort udvalg af lækre opskrifter, og meget mere.',
      THEME_CAN: '',
      THEME_FROZEN: '',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: '',
      THEME_RECIPE: 'Grøntsager som du kan bruge til at tilberede nemme og hurtige opskrifter. ',
      THEME_PLATE: 'Søger du efter inspiration til en balanceret måltid? Hos os finder du et stort udvalg af lækre opskrifter til varme og kolde retter.',
      THEME_ACC: 'Grøntsager som du kan bruge til at tilberede nemme og hurtige tilbehør. ',
      THEME_SNACK: 'Søger du efter inspiration til en balanceret snack? Hos os finder du et stort udvalg af lækre opskrifter til lækre småretter, som er lige til at snacke på.',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': 'Vi tilbyder et sortiment af grøntsager som du kan bruge til at tilberede lækre, nemme og balancerede opskrifter {{value}}.   ',
      NOTFOUND: 'Den ønskede side kunne ikke findes. Venligst lav en ny søgning eller prøv igen senere.'
    },
    PIPE: { SOCIETY: ' | Bonduelle' },
    THEME_ALL: 'Alle',
    THEME_STARTER: 'Forretter',
    THEME_SALAD: 'alt-til-mine-salater',
    THEME_CAN: '',
    THEME_FROZEN: '',
    THEME_READY_MEALS: '',
    THEME_BIO: '',
    THEME_VEGETABLES: '',
    THEME_RECIPE: 'opskrifts-ingredienser',
    THEME_PLATE: 'Hovedretter',
    THEME_ACC: 'tilbehør',
    THEME_SNACK: 'snacks'
  },
  VEGETABLE: {
    DISCOVER_VEGETABLE: 'Læs mere om vores grøntsager',
    OURS_RECIPES: 'Vores opskrifter',
    DISCOVER_ALSO: 'Se også:',
    SEE_ALL: 'Se alle vores grøntsager',
    ON: 'grøntsager ud af'
  },
  RECIPE: {
    'INGREDIENTS_LABEL ': 'Ingredienser til {{value}} personer',
    PEOPLES: '{{value}} personer',
    DIFFICULTY: { EASY: 'Let', MEDIUM: 'Medium', HARD: 'Svær' },
    COST: { CHEAP: 'Billig', EXPENSIVE: 'Dyrt' },
    TIMING: { MIN: 'min.', HOURS: 'timer' },
    STEP: 'Trin',
    DISCOVER_ALSO: 'Se også:',
    HOME_SEE_MORE: 'Se flere opskrifter',
    SHOWCASE: 'Top',
    HOME_BOOK: 'Vores opskrifter',
    MIN_MORE: '> 45 min.',
    HOUR_MORE: 'timer og +',
    FILTER_BY_CRITERIA: 'Filtrér efter',
    SORT_BY: 'Sortér efter',
    BEST_RATED: 'Bedst vurderede',
    MOST_CONSULTED: 'Mest populære',
    MEMBERS_RECIPES: 'Medlemmernes opskrifter',
    CRITERIA: 'Nyheder',
    DISCOVER: { RECIPES: 'Se vores opskrifter' },
    DISCOVER_RECIPE: 'Se opskriften',
    INSTRUCTIONS: 'Fremgangsmåde',
    DEFAULT_TAG: 'Opskriftside',
    MOST_RECENT: 'Nyeste',
    RECIPE_VIDEOS: 'opskriftsvideoer',
    PRODUCT_ASSOCIATE: 'Tilbered denne opskrift med:',
    BUDGET_LOW: 'Billig',
    BUDGET_HIGH: 'Høj',
    BUDGET_MIDDLE: 'Medium',
    PRINT_RECIPE: 'udskriv opskrift',
    LET_YOURSELF_SURPRISED: 'Brug for mere inspiration?',
    COOK_TIP_TITLE: '',
    ENJOY_MEAL: 'Velbekomme!',
    FILTER: { LEGEND: 'Legende' }
  },
  PREDIGGO: { DIFFICULTY: { EASY: 'Let', MEDIUM: 'Medium', HARD: 'Svær' } },
  COOKIES: {
    TEXT: 'Ved at fortsætte dit besøg på denne hjemmeside, accepterer du, at vi bruger cookies af tekniske årsager, af hensyn til statistikanalyse og styring af trafikken på hjemmesiden, samt informationsdeling med annoncører på andre hjemmesider, for at kunne tilbyde dig relevant indhold, som svarer til dine interesser. ',
    CLOSE: 'Luk',
    MORE: 'Læs mere',
    AGREE_YOUTUBE: 'You must accept Youtube cookies to see this video',
    BUTTON_YOUTUBE: 'Accept cookies'
  },
  POPUP: { LAUNCH: 'POPUP-OUVERTURE-SITE' },
  FIELD_PLATE: { DISCOVER_VEGETABLE: 'Læs mere om vores grøntsager' },
  SEARCH: {
    OUR_RECIPES: 'Opskrifter',
    OUR_PRODUCTS: 'Produkter',
    OUR_ARTICLES: 'Artikler',
    RECIPES: 'Opskrifter',
    PRODUCTS: 'Produkter',
    ARTICLES: 'Artikler',
    ALL: 'Se alt',
    SORT_BY: 'Sortér efter',
    RESULTS: 'resultater',
    RESULT: 'resultat',
    PRODUCT_OUT: 'produkter ud af',
    APPLY: 'Anvend',
    FILTER: 'Filtrér',
    RECIPE_OUT: 'opskrifter ud af',
    NO_RESULT: 'Ups... Vi kan ikke finde det du søger',
    SEE_MORE: 'Se mere',
    DISCOVER_RECIPES: 'Se vores opskrifter ',
    SEE_MORE_OF: 'Se flere',
    DELETE: 'slet'
  },
  RECIPE_LIST: {
    OUR_RECIPES: 'Opskrifter',
    RESULTS: 'Resultater',
    SEE_MORE: 'Se flere opskrifter'
  },
  PRODUCT: {
    BUTTON: {
      ACTU: 'produktnyheder',
      FOUND: 'Se hvor du kan finde den her produkt',
      DISCOVER: 'Se hele vores sortiment'
    },
    NUTRITIONAL_VALUE: 'næringsindhold',
    CONDITIONING: 'indpakning',
    PORTIONS: 'portioner',
    NET_WEIGHT: 'Nettovægt',
    DRY_WEIGHT: 'Drænet vægt',
    INGREDIENTS: 'Ingredienser',
    OUR_RECIPES: 'Se vores opskrifter',
    FOR: 'per',
    PER_PORTION: 'og per portion af',
    ENERGY: 'Energi',
    FAT: 'Fedt',
    SATURATE: 'Mættede fedtsyrer',
    GLUCIDES: 'Kulhydrat',
    FIBER: 'Kostfibre',
    PROTEINS: 'Protein',
    SALT: 'Salt',
    DISCOVER_ALSO: 'Se også:',
    MORE_INFORMATION: 'flere oplysninger',
    TRACES: 'Spor af',
    CONTAINS: 'Kan indeholde',
    SLIDER: { SHEET: 'se produkt', BUY: 'køb dette produkt' },
    NUTRISCORE: 'information-nutriscore',
    TRIMAN: '',
    AGRICONFIANCE: '',
    BIO: 'information-øko',
    PESTICIDE_FREE: 'ingen-pesticidrester-logo',
    LEAF: 'eu-oekologi-maerke',
    LOCK: 'noeglehul-maerket',
    SEE_ALL: 'Se alle produkter',
    LEGISLATION: 'i overensstemmelse med den gældende lovgivning.',
    PORTION: { SINGULAR: 'portion', PLURAL: 'portioner' },
    DISCLAIMER: '',
    COOKING: 'Tilberedning',
    CONSERVATION: 'Opbevaring'
  },
  FAQ: {
    ON: 'spørgsmål omkring',
    ALL: 'Alle',
    SEE_MORE: 'se flere spørgsmål',
    TITLE_1: 'FAQ - ofte stillede spørgsmål',
    TITLE_2: 'Har du et spørgsmål angående et bestemt produkt? ',
    LINK_LABEL: 'Skriv til os'
  },
  FORM: {
    GO_TO_STEP: 'Gå til trin',
    STEP: 'Trin',
    RECEIVE_EMAIL_COPY: 'Modtag en kopi via e-mail',
    SEND: 'Send',
    GDPR: 'Mere om håndtering af dine data',
    ERROR: 'Felter, der er mærket med en *, skal udfyldes og være gyldige',
    SEND_ERROR: 'Der opstod en fejl. Prøv venligst igen senere.',
    NOTICE: 'For at håndtere din fil på korrekt vis kan vi, afhængigt af sagen, bede dig om sundhedsdata. De er ikke obligatoriske, og du er fri til ikke at overføre dem.',
    SENDING: 'Formularen er ved at blive sendt...',
    LOADING: 'Indlæser...',
    PRODUCT_INFORMATION_TITLE: 'Spørgsmål omkring et produkt',
    PRODUCT_INFORMATION: {
      SEO_TITLE: 'Spørgsmål omkring et produkt | Bonduelle',
      SEO_DESC: 'Kontakt vores kundeservice for spørgsmål omkring et af vores produkter.'
    },
    INFORMATIONS: 'Dine oplysninger',
    MISTER: '',
    MISS: '',
    NAME: 'Efternavn',
    OUR_NAME: 'Dit efternavn',
    FIRSTNAME: 'Fornavn',
    OUR_FIRSTNAME: 'Dit fornavn',
    MAIL: 'Din e-mailadresse',
    MAIL_PLH: '',
    MAIL_CONFIRM: 'Bekræft din e-mailadresse',
    MAIL_ERROR: 'Venligst indtast en gyldig e-mailadresse',
    MAIL_ERROR_CONFIRM: 'Venligst bekræft din e-mailadresse',
    ADDRESS: 'Din adresse',
    STREET_PLH: '',
    STREET: 'Gade og gadenummer',
    ADDRESS_COMPLEMENT: 'Adresse 2',
    ADDRESS_COMP_PLH: 'Adresse 3',
    ZIPCODE: 'Postnummer',
    CITY: 'By',
    CITY_PLH: 'By',
    PHONE: 'Telefonnummer',
    PHONE_PLH: 'Telefonnummer',
    COUNTRY: 'Land',
    PRODUCT_INFORMATIONS: 'Produktoplysninger',
    PRODUCT_INFORMATION_DESC: 'For at vi kan behandle din henvendelse, indtast da venligst de følgende oplysninger:',
    PRODUCT: {
      DETAIL: {
        DESC: 'Sammen med henvendelsen kan du udfylde de relevante felter, og tilføje et billede som eksempel. Billederne skal vise produktets stregkode, batchnummer og udløbsdato.'
      },
      NOTE: {
        TITLE: 'Produktreklamation',
        SEO_TITLE: 'Insend en produktreklamation | Bonduelle',
        SEO_DESC: 'Har du et spørgsmål angående et produkt? Kontakt os via e-mail her. '
      }
    },
    PRODUCT_RANGE: 'Produktsortiment',
    PRODUCT_NAME: 'Produktnavn',
    PRODUCT_NAME_PLH: 'Skriv venligst navnet på det Bonduelle produkt som din henvendelse handler om.  ',
    PRODUCT_INFORMATIONS_DETAIL: 'Detaljerede oplysninger',
    PRODUCT_INFORMATIONS_LABEL: 'Produktets stregkode',
    PRODUCT_INFORMATIONS_PLH: 'Stregkoden består af 13 cifre - du kan finde den på emballagen. ',
    PRODUCT_LOT: 'Produktets batchnummer',
    PRODUCT_LOT_INFO: 'Batchnummeret starter med en L <br> efterfulgt af et antal cifre. <br> Du kan finde det på bagsiden af ​​emballagen <br> eller på dåsens bund. <br> <br> For eksempel: L72450492107: 13.',
    PHOTO: 'Tilføj et billede',
    EXPIRED_DATE: 'Udløbsdato',
    EXPIRED_DATE_ERROR: 'Indtast venligst datoen som: dd/mm/åååå',
    EXPIRED_DATE_DESC: 'Udløbsdatoen ses på ​​emballagen eller på bunden af ​​dåsen.',
    PHOTO_MORE: 'Øvrige billeder',
    MESSAGE: 'Din besked *',
    OUR_MESSAGE: 'Indtast din besked',
    SALES_OPERATIONS: { TITLE: '', SEO_TITLE: '', SEO_DESC: '' },
    PRESS_SERVICE: '',
    FOOD_SERVICE: 'Tag kontakt til vores Food Service afdeling',
    BUSINESS_INFORMATION: '',
    MODAL_INFORMATION: '',
    JOB_OFFER: { TITLE: '', SEO_TITLE: '', SEO_DESC: '' },
    WEB_SUGGESTION: {
      TITLE: 'Vil du gerne foreslå nogle forbedringer til vores hjemmeside?',
      SEO_TITLE: 'Forslag til hjemmesiden | Bonduelle',
      SEO_DESC: ''
    },
    ANOTHER: {
      TITLE: 'Spørg om noget andet',
      SEO_TITLE: 'Kontakt os hvis du har andre andre spørgsmål eller kommentarer | Bonduelle ',
      SEO_DESC: ''
    },
    AMBASSADOR: '',
    CATEGORY: {
      SALAD: 'salater',
      FROZEN: 'frost',
      CAN: 'konserves',
      TAKEAWAY: 'takeaway',
      VEGETABLE: ''
    }
  },
  CONTACT: {
    TITLE: 'Hvordan kan vi hjælpe dig?',
    SPECIAL_INFO: '',
    TAKE_CARE: '',
    BY_MAIL: 'Via post',
    BY_PHONE: 'Via telefon',
    BY_SOURDLINE: '',
    MAIL_TEXT: 'BONDUELLE NORDIC A/S\r\nLyskær 3CD\r\nDK - 2730 Herlev',
    PHONE_TEXT: ' +45 70 70 29 75',
    SOURDLINE_TEXT: '',
    BY_DISTRIBUTOR: '',
    BY_DISTRIBUTOR_TEXT: '',
    SUBTITLE: 'Eller kontakt os via e-mail:',
    AGREEMENT: '',
    READ_MORE: '',
    READ_MORE_LABEL: '',
    SATISFACTION_TEXT: 'For at kunne kontakte dig for at give dig de ønskede oplysninger om\n' +
      'produktet og sende dig et tilfredshedsspørgeskema, hvis du ønsker det,\n' +
      'behandler vi de oplysninger, vi har givet i vores egenskab af dataansvarlig.\n' +
      'Det juridiske grundlag for at behandle din anmodning er samtykke på\n' +
      'grund af de potentielle helbredsrelaterede data, du måtte angive i din\n' +
      'anmodning. Disse oplysninger vil blive opbevaret så længe det er\n' +
      'nødvendigt for at behandle din anmodning og i op til 5 år for at bevise, at\n' +
      'din anmodning er blevet behandlet og for at overholde vores juridiske\n' +
      'forpligtelser. Retsgrundlaget for at sende tilfredshedsspørgeskemaet er\n' +
      'også dit samtykke. Disse oplysninger opbevares i den tid, det tager at\n' +
      'sende tilfredshedsspørgeskemaet og op til maksimalt 1 år. For at finde ud\n' +
      'af mere om behandlingen af dine personoplysninger, se venligst vores\n' +
      '<a href="https://www.bonduelle.dk/juridisk-meddelelse" target="_blank" class="underline">privatlivspolitik</a>.',
    SATISFACTION_CHECKBOX: 'Ved at afkrydse dette felt accepterer du at modtage et\n' +
      'tilfredshedsspørgeskema (bestående af et enkelt spørgsmål), når din\n' +
      'anmodning er blevet behandlet. Det er valgfrit.',
    CHECKBOX_TEXT: 'Ved at afkrydse dette felt accepterer du, at Bonduelle må behandle de\n' +
      'personlige helbredsdata, du måtte angive i din besked, udelukkende med\n' +
      'det formål at besvare din anmodning. <strong>Det er obligatorisk for at kunne\n' +
      'behandle din anmodning</strong>.',
    PHONE: 'Tlf.nr. +45 7070 2975\n',
    CALL_US: 'Ring til os',
    TO_WRITE: 'Skriv til os',
    PHONE_NUMBER: '4570702975',
    SUCCESS_MESSAGE: 'Tak for din henvendelse. Din besked vil blive besvaret indenfor 10 hverdage. ',
    PHOTOERROR: { MESSAGE: 'photo error message' }
  },
  GLOBAL: { READ_MORE: '' },
  FLOWBOX: { GENERIC_KEY: '', PRODUCT_KEY: '', RECIPE_KEY: '', HOME_KEY: '' },
  TOKEN: {
    FACEBOOK: '',
    PAGE_FACEBOOK_ID: '',
    INSTAGRAM: '',
    INSTA_ID: '17841407279489116',
    YOUTUBE: '',
    TWITTER: '',
    MAGAZINE: ''
  },
  COOKIE: {
    BANNER: {
      MANDATORY: 'Nødvendige',
      ANALYTICS: 'Analytics',
      MARKETING: 'Markedsføring',
      SOCIAL_MEDIA: 'Sociale Medier',
      ACCEPT: 'Godkend alle',
      REJECT: 'Afvis alle',
      SET_UP: 'Indstil præferencer',
      MANDATORY_DETAILS: 'Nødvendige cookies er påkrævet til driften af websitet, for eksempel til at gemme dine loginoplysninger og give dig en sikker forbindelse.\n' +
        'De cookies, der kræves for at bruge bonduelle.dk-webstedet, er: \n' +
        '<ul>\n' +
        '<li>Sessionscookies: Cookies, der genereres af PHP-baserede applikationer. Dette er en generel identifikator, der bruges til at administrere brugersessionsvariabler. Det er normalt et tilfældigt genereret nummer. Den måde, den bruges på, kan være sitespecifik, men et godt eksempel er, at den opretholder en brugers loginstatus mellem sider.</li>\n' +
        '</ul>\n',
      ANALYTICS_DETAILS: 'Funktionelle cookies hjælper os med at optimere websitet, f.eks. til at indsamle statistikker, registrere dine præferencer for sociale interaktioner eller til at måle vores applikations ydeevne og stabilitet.\n' +
        '<br>\n' +
        'De analysecookies, der anvendes på bonduelle.dk-websitet, er: \n' +
        '<ul>\n' +
        '<li>DoubleClick: Denne cookie anbringes af DoubleClick (ejet af Google) for at oprette en profil over den besøgendes interesser og vise relevante annoncer på andre websites. Virksomhedens hovedaktivitet er udveksling af annoncer i realtid fra Google.</li>\n' +
        '<li>Google Analytics: Disse cookies gør det muligt at registrere de mest besøgte sider samt trafikken på websitet.</li></ul>\n',
      MARKETING_DETAILS: 'Markedsføringscookies giver os mulighed for at registrere dine præferencer for opskrifter og produkter, så vi altid kan tilbyde dig de produkter, der passer bedst til dine ønsker.\n' +
        '<br>\n' +
        'De markedsføringscookies, der anvendes af bonduelle.dk-websitet, er: \n' +
        '<ul>\n' +
        '<li>Sociale Medier: Disse cookies gør det muligt at vise Bonduelles ""SocialWall"" på sitets hjemmeside.  Denne væg giver dig mulighed for at se alle de seneste opslag fra de største sociale netværk, der bruges af Bonduelle-teamene.<FlowBox: Denne cookie gør det muligt at bruge serviceydelserne i FlowBox-softwaren, hvilket giver os mulighed for at tilbyde vores besøgende opskrifter og værker kreeret af andre internetbrugere, der bruger Bonduelle-produkter.</li><li> UTMTracking: Denne cookie giver mulighed for at administrere UTM-variabler for optimal opfølgning af de forskellige onlineannoncekampagner</ li>.\n',
      V2: 'ok'
    },
    MODAL: {
      TITLE: 'Indstillinger for cookies',
      TEXT: 'Bonduelle Gruppen forpligter sig til at beskytte personoplysningere hos de personer, der bruger vores websites, især websitet Bonduelle.dk.<br> For at du kan nyde godt af de tjenester, som websitet tilbyder, såsom at se vores produkter, finde nye gourmetopskrifter, abonnere på visse tjenester, bl.a. vores nyhedsbreve, samt for at optimere brugen og personaliseringen i henhold til din profil, bruger websitet cookies. Bonduelle Gruppen forpligter sig fuldt ud til at sikre et højt beskyttelsesniveau med hensyn til sådanne personoplysninger og til at overholde alle gældende databeskyttelsesregler ved behandling af personoplysninger i forbindelse med brugen af deres website.\n' +
        'Du kan således når som helst aktivere/deaktivere cookies.\n',
      CONFIG: 'Indstillinger',
      SAVE: 'Gem'
    }
  },
  LINK: { JOB_OFFERS: '' },
  CLIC2BUY: { INFOS: '' }
};
