/* tslint:disable */export const com_en = {
  '404': {
    TITLE: 'Oups...',
    PAGENOTFOUND: "Page doesn't exist",
    BUTTON: 'Back to home',
    RECIPES: 'Discover our recipes'
  },
  HOME: {
    SHOWCASE: 'Cook recipes',
    CATCH_PHRASE: '#allisgood',
    SHOWCASE_SEE_ALL: 'See all recipes',
    MAGAZINE: { TITLE: 'TOP', BUTTON: 'Discover légumiz' },
    OUR_VEGETABLES: 'Our vegetables',
    SOCIAL_WALL: "Together, let's share the passion of the plant",
    FAQ_TITLE: 'Your questions',
    FAQ_SEE_MORE: 'See more',
    FLOWBOX: 'Partagez vos instants gourmands avec #mybonduelle'
  },
  SELECT: { DEFAULT_VALUE: 'Bonduelle present' },
  MENU: {
    CONTACT_US: 'Contact us',
    MY_ACCOUNT: 'My account',
    GOOD_DEALS: 'Good deals',
    NEWSLETTER: 'Newsletter Bonduelle'
  },
  FOOTER: {
    LINK: {
      BONDUELLE: 'Bonduelle.com',
      CREDITS: 'Credits',
      LEGAL_NOTICE: 'Legal notice',
      TERMS_OF_SALES: 'Termes of sales',
      COOKIES: 'Cookies',
      SITEMAP: 'Sitemap',
      PRIVACY_POLICY: 'Privacy policy',
      ALL_RIGHTS_RESERVED: '2018 © All rights reserved'
    },
    HISTORY: {
      TITLE: 'Bonduelle, since 1853',
      TEXT: 'Hnam, <b>quibusc</b> idiandis rempore iusani del ma dit ma nis antissint hilitiorror arum sequis accatur? Quiam quam, aruptiis et dia venis susae minum quidus et dolorep restrum etust, velesti dolor ad es nimporem evelique nonsequo odist, quiant molupie. Sit eostint est fugitis quis quo tem laboribus sa consed quisqui re alibus es evenimil minveliqui quuntis nossum fuga.'
    },
    NEWSLETTER: {
      TITLE: 'The Bonduelle community',
      TEXT: 'I accept to receive the newsletter « All about vegetables by Bonduelle » (news, promotion, contests, articles ...)',
      PLACEHOLDER: 'Your email',
      GDPR_LINK: 'More about managing your data'
    }
  },
  PRODUCTS: 'Products',
  MAGAZINE: {
    HOME: 'Welcome to the magazine',
    CATCH_PHRASE: 'peel the news of vegetables with Bonduelle !',
    MENU: { BONDUELLE_LINK: 'Go to bonduelle.com' },
    SHOWCASE: 'Showcase',
    PREVIOUS_ARTICLES: 'Previous articles',
    PREVIOUS_ARTICLES_SEE_ALL: 'See all our articles',
    PREVIOUS_EDITIONS: 'Our previous editions',
    PREVIOUS_EDITIONS_SEE_ALL: 'See more editions',
    PREVIOUS_ARTICLES_BY_CATEGORY: 'Previous articles by category',
    RUBRIC: {
      SEE_ALL: 'See all the recipes',
      SEE_ALL_ARTICLES: 'See all articles',
      ON: 'articles on'
    },
    RUBRIC_COOK: { ON: 'recipes of' },
    ARTICLE: { JOIN_US: 'Join us', READ_ALSO: 'To read also' },
    RECIPE: {
      INGREDIENTS: 'Ingredients',
      INGREDIENTS_LABEL_DESC: 'for {{value}} persons',
      INGREDIENTS_LABEL_ONE_DESC: 'for {{value}} person',
      INGREDIENTS_LABEL: 'Ingredients for {{value}} persons',
      INGREDIENTS_LABEL_ONE: 'Ingredients for {{value}} person',
      PEOPLES: '{{value}} persons',
      PEOPLE: '{{value}} people',
      DIFFICULTY: { EASY: 'Easy', MEDIUM: 'Medium', HARD: 'Hard' },
      COST: { CHEAP: 'Cheap', EXPENSIVE: 'Expensive' },
      TIMING: { MIN: 'min', HOURS: 'hours' },
      STEP: 'Step',
      DISCOVER_ALSO: 'Discover also...'
    }
  },
  ARTICLE: { DISCOVER_ALSO: 'Discover also ...', INGREDIENTS: 'Ingredients' },
  SF: {
    SLIDE: { DISCOVER: 'Discover' },
    SEARCH: { PLACEHOLDER: 'Search', ALL: 'All' },
    NEWSLETTER: {
      BTN_LABEL: "I'm registering",
      PLACEHOLDER: 'Your email',
      ERROR_MESSAGE: 'Invalid email',
      ALREADY_SUBSCRIBED: 'You are already subscribed',
      GDPR: {
        TXT: 'More information on managing your data',
        LINK: '/cookies#gdpr'
      }
    },
    MONTHLY_FOLDER: { TITLE: 'Monthly news' },
    MINI_NEWSLETTER: {
      PLACEHOLDER: 'Your email',
      GDPR: {
        TXT: 'More information on managing your data',
        LINK: '/cookies#gdpr'
      }
    },
    SOCIALITEM: { SHARE: 'share', COMMENT: 'comment', BONDUELLE: 'Bonduelle' },
    SEARCHRECIPE: { DISCOVER_ALL: 'search by all recipes!' },
    FORM: {
      ERROR: 'Invalid field',
      PHOTO: {
        ADD: 'Add a picture',
        DELETE: 'Delete the picture',
        SEE: 'See'
      }
    }
  },
  CORPORATE: {
    COMPANY_STORY: 'company story',
    CSR_COMMITMENT: 'csr commitment',
    VEGETABLE_INNOVATIONS: 'vegetal innovations',
    NEWS: 'all the news',
    SEE_MORE: 'See more articles',
    ON: 'articles on'
  },
  NEWSLETTER: {
    TITLE: 'Subscribe to the newsletter',
    SUBTITLE: 'Good deals, culinary inspiration and a little culture!'
  },
  ROUTES: {
    products: 'products',
    news: 'news',
    'since-1853': 'since-1853',
    'field-plate': 'field-plate',
    'company-story': 'company-story',
    'csr-commitments': 'csr-commitments',
    'vegetal-innovations': 'vegetal-innovations',
    magazine: 'magazine',
    all: 'all',
    article: 'article',
    cook: 'cook',
    cultivate: 'cultivate',
    explore: 'explore',
    recipe: 'wait-recipe',
    recipes: 'recipes',
    'recipes-home': 'recipes-home',
    r: 'r',
    credits: 'credits-com',
    'legal-notice': 'legal-notice',
    'terms-of-sales': 'terms-of-sales',
    cookies: 'cookies',
    sitemap: 'sitemap',
    'privacy-policy': 'privacy-policy',
    'all-rights-reserved': 'all-rights-reserved',
    search: 'search',
    'articles-search': 'articles-search',
    vegetables: 'vegetables',
    p: 'p',
    contact: 'contact',
    'info-product': 'renseignement-produit',
    'note-product': 'remarque-produit',
    suggestion: 'suggestion',
    'other-request': 'renseignement-produit',
    faq: 'faq',
    'all-themes': 'all-themes',
    'products-search': 'products-search',
    recipesearch: 'recipe-search',
    'product-news': 'product-news',
    'good-deals': 'good-deals',
    'not-found': 'not-found',
    'menu-page': 'menu-page',
    'menu-one': 'menu-one',
    'menu-two': 'menu-two',
    'menu-three': 'menu-three',
    'menu-four': 'menu-four',
    'menu-external': 'menu-external',
    'theme-pasta-rice': '',
    'theme-bowls': '',
    'theme-soup': '',
    'theme-snack': '',
    'theme-sauce': '',
    'theme-unique-plate': '',
    'theme-over': 'theme-over',
    'theme-legumin': 'legumin',
    'theme-ingredients-recipes': '',
    'theme-cook-plate': ''
  },
  BREAD: {
    products: 'products',
    news: 'news',
    'since-1853': 'since-1853',
    'field-plate': 'field-plate',
    'company-story': 'company-story',
    'csr-commitments': 'csr-commitments',
    'vegetal-innovations': 'vegetal-innovations',
    magazine: 'magazine',
    all: 'all',
    article: 'article',
    cook: 'cook',
    cultivate: 'cultivate',
    explore: 'explore',
    recipe: 'wait-recipe',
    recipes: 'recipes',
    'recipes-home': 'recipes-home',
    'articles-search': 'articles-search',
    search: 'search',
    vegetables: 'vegetables',
    contact: 'contact',
    'info-product': 'renseignement-produit',
    'note-product': 'remarque-produit',
    suggestion: 'suggestion',
    'other-request': 'renseignement-produit',
    faq: 'faq',
    'all-themes': 'all-themes',
    'products-search': 'products-search',
    recipesearch: 'recipe-search',
    'product-news': 'product-news',
    'good-deals': 'good-deals',
    'not-found': 'not-found',
    'menu-page': 'menu-page',
    'menu-one': 'menu-one',
    'menu-two': 'menu-two',
    'menu-three': 'menu-three',
    'menu-four': 'menu-four'
  },
  MENU_PAGE: {
    TITLE1: 'TITLE 1',
    TITLE2: 'TITLE 2',
    TITLE3: 'TITLE 3',
    TITLE4: 'TITLE 4',
    EXTERNAL: ''
  },
  TECHNICAL: { MENU_PAGE: 'job_offers' },
  SEO: {
    TITLE: {
      MENU_ONE: 'menu one Title',
      MENU_TWO: 'menu two Title',
      MENU_THREE: 'menu three Title',
      MENU_FOUR: 'menu four Title',
      HOME: 'Bonduelle',
      SEARCH: {
        MAIN: '{{value}}',
        RECIPES: '{{value}}',
        VEGETABLES: '{{value}}',
        ARTICLES: '{{value}}'
      },
      CONTACT: 'Bonduelle',
      RECIPES: 'Bonduelle',
      RECIPE_HOME: 'Bonduelle',
      RECIPE: 'Bonduelle',
      PRODUCTS: 'Bonduelle',
      NEWS: 'Bonduelle',
      CORPORATE: {
        NEWS: 'Bonduelle',
        COMPANY_STORY: 'Bonduelle',
        CSR_COMMITMENTS: 'Bonduelle',
        VEGETAL_INNOVATIONS: 'Bonduelle',
        FIELD_PLATE: 'Bonduelle',
        ARTICLE: '{{value}}',
        VEGETABLE: '{{value}}'
      },
      PRODUCT: '{{value}}',
      MAGAZINE: {
        NEWS: 'Bonduelle',
        ARTICLE: 'Bonduelle',
        COOK: 'Bonduelle',
        GROW: 'Bonduelle',
        EXPLORE: 'Bonduelle',
        RECIPE: 'Bonduelle'
      },
      FAQ: 'Bonduelle',
      NOTFOUND: 'Bonduelle',
      RECIPES_ALL: '',
      RECIPETYPE: '{{value}}',
      RECIPESEASON: '{{value}}',
      RECIPEPREPARATION: '{{value}}',
      RECIPEDIFFICULTY: '{{value}}',
      RECIPEVEGETABLE: '{{value}}',
      RECIPECOST: '{{value}}',
      RECIPEDISH: '{{value}}',
      RECIPEDESC: '{{value}}',
      '2RECIPEFACETS': '',
      '4RECIPEFACETS': '{{value}}',
      PRODUCTSEASON: '{{value}}',
      PRODUCTTECHNO: '{{value}}',
      PRODUCTVEGETABLE: '{{value}}',
      PRODUCTRANGE: 'Products from range {{value}}',
      THEME_ALL: '',
      THEME_STARTER: '',
      THEME_SALAD: '',
      THEME_CAN: '',
      THEME_FROZEN: '',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: '',
      THEME_RECIPE: '',
      THEME_PLATE: '',
      THEME_ACC: '',
      THEME_SNACK: '',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': '{{value}}'
    },
    DESCRIPTION: {
      MENU_ONE: 'menu one description',
      MENU_TWO: 'menu two Description',
      MENU_THREE: 'menu three Description',
      MENU_FOUR: 'menu four Description',
      HOME: '',
      SEARCH: {
        MAIN: '{{value}}',
        RECIPES: '{{value}}',
        VEGETABLES: '{{value}}',
        ARTICLES: '{{value}}'
      },
      CONTACT: '',
      RECIPES: '',
      RECIPE_HOME: '',
      RECIPE: '',
      PRODUCTS: '',
      NEWS: '',
      CORPORATE: {
        NEWS: '',
        COMPANY_STORY: '',
        CSR_COMMITMENTS: '',
        VEGETAL_INNOVATIONS: '',
        VEGETABLE: '',
        FIELD_PLATE: '',
        ARTICLE: ''
      },
      PRODUCT: '{{value}}',
      MAGAZINE: {
        NEWS: '',
        ARTICLE: '',
        COOK: '',
        GROW: '',
        EXPLORE: '',
        RECIPE: ''
      },
      FAQ: '',
      PAGE: '',
      RECIPETYPE: '{{value}}',
      RECIPESEASON: '{{value}}',
      RECIPEPREPARATION: '{{value}}',
      RECIPEDIFFICULTY: '{{value}}',
      RECIPEVEGETABLE: '',
      RECIPECOST: '{{value}}',
      RECIPEDISH: '{{value}}',
      RECIPEDESC: '{{value}}',
      '2RECIPEFACETS': '{{value}}',
      '4RECIPEFACETS': '{{value}}',
      PRODUCTSEASON: '{{value}}',
      PRODUCTTECHNO: '{{value}}',
      PRODUCTVEGETABLE: '{{value}}',
      PRODUCTRANGE: 'Discover all products from range {{value}}',
      THEME_ALL: '',
      THEME_STARTER: '',
      THEME_SALAD: '',
      THEME_CAN: '',
      THEME_FROZEN: '',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: '',
      THEME_RECIPE: '',
      THEME_PLATE: '',
      THEME_ACC: '',
      THEME_SNACK: '',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': '{{value}}',
      NOTFOUND: ''
    },
    PIPE: { SOCIETY: '' },
    THEME_ALL: '',
    THEME_STARTER: '',
    THEME_SALAD: '',
    THEME_CAN: '',
    THEME_FROZEN: '',
    THEME_READY_MEALS: '',
    THEME_BIO: '',
    THEME_VEGETABLES: '',
    THEME_RECIPE: '',
    THEME_PLATE: '',
    THEME_ACC: '',
    THEME_SNACK: ''
  },
  VEGETABLE: {
    DISCOVER_VEGETABLE: 'Discover our vegetable sheets',
    OURS_RECIPES: 'Ours Recipes',
    DISCOVER_ALSO: 'Discover also...',
    SEE_ALL: 'See all the vegetables',
    ON: 'Vegetables on'
  },
  RECIPE: {
    'INGREDIENTS_LABEL ': 'Ingredients for {{value}} peoples',
    PEOPLES: '{{value}} peoples',
    DIFFICULTY: { EASY: 'Easy', MEDIUM: 'Medium', HARD: 'Hard' },
    COST: { CHEAP: 'Cheap', EXPENSIVE: 'Expensive' },
    TIMING: { MIN: 'Min', HOURS: 'hours' },
    STEP: 'Step',
    DISCOVER_ALSO: 'Discover also...',
    HOME_SEE_MORE: 'See more recipes',
    SHOWCASE: 'Top',
    HOME_BOOK: 'Our recipes',
    MIN_MORE: 'm and +',
    HOUR_MORE: 'h and +',
    FILTER_BY_CRITERIA: 'Filter by criteria',
    SORT_BY: 'Sort by :',
    BEST_RATED: 'Best rated',
    MOST_CONSULTED: 'Most consulted',
    MEMBERS_RECIPES: "Member's recipes",
    CRITERIA: 'News',
    DISCOVER: { RECIPES: 'Discover our recipes' },
    DISCOVER_RECIPE: 'Discover the recipe',
    INSTRUCTIONS: 'Instructions',
    DEFAULT_TAG: 'Recipe idea',
    MOST_RECENT: 'Most recents',
    RECIPE_VIDEOS: 'Recipe videos',
    PRODUCT_ASSOCIATE: 'Recipe made with',
    BUDGET_LOW: 'Cheap',
    BUDGET_HIGH: 'High',
    BUDGET_MIDDLE: 'Medium',
    PRINT_RECIPE: 'print recipe',
    LET_YOURSELF_SURPRISED: 'Let yourself be surprised',
    COOK_TIP_TITLE: 'Cook tip title',
    ENJOY_MEAL: 'Enjoy your meal ! ',
    FILTER: { LEGEND: 'Legend' }
  },
  PREDIGGO: { DIFFICULTY: { EASY: 'Easy', MEDIUM: 'medium', HARD: 'Hard' } },
  COOKIES: {
    TEXT: 'By continuing your visit to this site, you accept the use of cookies for technical reasons, for the purposes of statistical analysis and web traffic management, information sharing with advertisers on other websites, to provide you with relevant promotional communications that reflect your interests.',
    CLOSE: 'Close',
    MORE: 'Learn more',
    AGREE_YOUTUBE: 'You must accept Youtube cookies to see this video',
    BUTTON_YOUTUBE: 'Accept cookies'
  },
  POPUP: { LAUNCH: 'POPUP-OUVERTURE-SITE' },
  FIELD_PLATE: { DISCOVER_VEGETABLE: 'Discover our vegetable sheets' },
  SEARCH: {
    OUR_RECIPES: 'Recipes',
    OUR_PRODUCTS: 'Products',
    OUR_ARTICLES: 'Articles',
    RECIPES: 'Recipes',
    PRODUCTS: 'Products',
    ARTICLES: 'Articles',
    ALL: 'All',
    SORT_BY: 'Sort by',
    RESULTS: 'results',
    RESULT: 'result',
    PRODUCT_OUT: 'products out of',
    APPLY: 'Apply',
    FILTER: 'Filter',
    RECIPE_OUT: 'recipes out of',
    NO_RESULT: 'White cabbage for this search ... You ask us a tricky question',
    SEE_MORE: 'See more',
    DISCOVER_RECIPES: 'Discover our recipes',
    SEE_MORE_OF: 'see more of',
    DELETE: 'delete'
  },
  RECIPE_LIST: {
    OUR_RECIPES: 'Recipes',
    RESULTS: 'Results',
    SEE_MORE: 'See more recipes'
  },
  PRODUCT: {
    BUTTON: {
      ACTU: 'product news',
      FOUND: 'Where to find this product',
      DISCOVER: 'discover all the gamma'
    },
    NUTRITIONAL_VALUE: 'nutritional value',
    CONDITIONING: 'conditioning',
    PORTIONS: 'portions',
    NET_WEIGHT: 'net weight',
    DRY_WEIGHT: 'dry weight',
    INGREDIENTS: 'ingredients',
    OUR_RECIPES: 'Discover our recipes',
    FOR: 'for',
    PER_PORTION: 'and by portion of',
    ENERGY: 'energy',
    FAT: 'fat',
    SATURATE: 'saturate fat',
    GLUCIDES: 'glucides',
    FIBER: 'fiber',
    PROTEINS: 'proteins',
    SALT: 'salt',
    DISCOVER_ALSO: 'discover also ...',
    MORE_INFORMATION: 'more information',
    TRACES: 'Traces of',
    CONTAINS: 'Contains',
    SLIDER: { SHEET: 'view the sheet', BUY: 'buy this product' },
    NUTRISCORE: 'information-nutriscore',
    TRIMAN: 'information-triman',
    AGRICONFIANCE: 'information-agriconfiance',
    BIO: 'information-bio',
    PESTICIDE_FREE: '',
    LEAF: '',
    LOCK: '',
    SEE_ALL: 'See all products',
    LEGISLATION: 'conformément à la législation en vigueur.',
    PORTION: { SINGULAR: 'portion', PLURAL: 'portions' },
    DISCLAIMER: '',
    COOKING: 'cooking',
    CONSERVATION: 'conservation'
  },
  FAQ: {
    ON: 'questions on',
    ALL: 'All',
    SEE_MORE: 'see more questions',
    TITLE_1: 'All the answers to your questions',
    TITLE_2: 'Do you have a question on a product in particular ?',
    LINK_LABEL: 'Write us'
  },
  FORM: {
    GO_TO_STEP: 'Go to step',
    STEP: 'Step',
    RECEIVE_EMAIL_COPY: 'Receive an email copy',
    SEND: 'Send',
    GDPR: 'More about managing your data',
    ERROR: 'All fields marked with * must be filled and valid',
    SEND_ERROR: 'An error occured, please try again in a few moments',
    NOTICE: 'For the proper handling of your file, Bonduelle may, depending on the case, ask you for health data. They are not obligatory and you are free not to transmit them.',
    SENDING: 'Sending the form ...',
    LOADING: 'Loading...',
    PRODUCT_INFORMATION_TITLE: 'Information about product',
    PRODUCT_INFORMATION: {
      SEO_TITLE: 'Information about product | Bonduelle',
      SEO_DESC: 'Contact our customer service'
    },
    INFORMATIONS: 'your informations',
    MISTER: 'Mister',
    MISS: 'Miss',
    NAME: 'Name',
    OUR_NAME: 'Our name',
    FIRSTNAME: 'Firstname',
    OUR_FIRSTNAME: 'Our firstname',
    MAIL: 'your mail',
    MAIL_PLH: 'mail@address.com',
    MAIL_CONFIRM: 'confirm your mail',
    MAIL_ERROR: 'please write a valid email',
    MAIL_ERROR_CONFIRM: 'please confirm your email',
    ADDRESS: 'your address',
    STREET_PLH: '',
    STREET: 'Street and street number',
    ADDRESS_COMPLEMENT: 'more infos about your address',
    ADDRESS_COMP_PLH: 'more infos about your address',
    ZIPCODE: 'zip',
    CITY: 'city',
    CITY_PLH: 'your city',
    PHONE: 'phone',
    PHONE_PLH: 'phone number',
    COUNTRY: 'country',
    PRODUCT_INFORMATIONS: 'product informations',
    PRODUCT_INFORMATION_DESC: 'Afin que nos services puissent étudier au mieux votre<br>demande, merci de renseigner les informations suivantes :',
    PRODUCT: {
      DETAIL: {
        DESC: 'For each criterion requested, you can fill in the form field<br>and add an illustration photo. Information: your photo must make it possible to clearly read<br>the barcode, the batch number and the BBD of the product.'
      },
      NOTE: {
        TITLE: 'Faire une remarque sur un produit',
        SEO_TITLE: 'Remarque et commentaire sur un produit | Bonduelle',
        SEO_DESC: 'Une remarque ou un commentaire sur un produit de nos gammes ? Contactez notre service consommateur pour vous aider. Réponse personnalisée dans les 48h'
      }
    },
    PRODUCT_RANGE: 'Gamme de produits concernée',
    PRODUCT_NAME: 'Nom du produit',
    PRODUCT_NAME_PLH: 'Ecrivez le nom du produit Bonduelle concerné par votre demande',
    PRODUCT_INFORMATIONS_DETAIL: 'Detailed informations ',
    PRODUCT_INFORMATIONS_LABEL: 'Code barre du produit',
    PRODUCT_INFORMATIONS_PLH: 'Le code barre est composé<br>de 13 chiffres présents sur<br>l’emballage de votre produit.',
    PRODUCT_LOT: 'N° de lot du produit',
    PRODUCT_LOT_INFO: 'Le n° de lot se trouve à proximité<br>de la date limite de consommation<br>et comporte plusieurs chiffres et lettres<br>sur plusieurs lignes/  Il commence<br>toujours pas la lettre L suivie<br> de plusieurs chiffres et varie<br> selon les produits.<br><br>Par exemple L72450492107:13.',
    PHOTO: 'Ajouter une photo',
    EXPIRED_DATE: 'Date de la DLUO /DLC',
    EXPIRED_DATE_ERROR: 'Veuillez saisir une date valide au format jj/mm/aaaa',
    EXPIRED_DATE_DESC: "La DLUO (Date Limite d'Utilisation Optimale)<br>et la DLC (Date limite de Consommation)<br>sont présentées sous forme de dates.",
    PHOTO_MORE: 'Photos supplémentaires',
    MESSAGE: 'Votre message *',
    OUR_MESSAGE: 'écrivez votre message',
    SALES_OPERATIONS: {
      TITLE: 'Des informations sur une opération commerciale',
      SEO_TITLE: 'Infos sur les opérations du moment : jeu concours… | Bonduelle',
      SEO_DESC: 'Contactez notre service consommateur pour une information sur une operation commerciale en cours. Réponse personnalisée dans les 48h'
    },
    PRESS_SERVICE: 'Contacter le service presse',
    FOOD_SERVICE: 'Contacter l’équipe Bonduelle Food Service',
    BUSINESS_INFORMATION: "Recevoir des échantillons/bons d'achat/objets publicitaires",
    MODAL_INFORMATION: '',
    JOB_OFFER: { TITLE: '', SEO_TITLE: '', SEO_DESC: '' },
    WEB_SUGGESTION: {
      TITLE: 'Faire une suggestion à propos du site internet',
      SEO_TITLE: "Message d\\'amelioration, suggestion du site | Bonduelle",
      SEO_DESC: 'Veuillez contacter notre service consommateurs pour nous aider à améliorer le site.'
    },
    ANOTHER: {
      TITLE: 'Faire une autre demande',
      SEO_TITLE: 'Contact pour toute autre demande, service | Bonduelle',
      SEO_DESC: 'Contactez notre service consommateur pour une information, un service ou une suggestion. Réponse personnalisée dans les 48h'
    },
    AMBASSADOR: 'Devenir ambassadeur Bonduelle',
    CATEGORY: {
      SALAD: 'Salad',
      FROZEN: 'frozen',
      CAN: 'can',
      TAKEAWAY: 'takeaway',
      VEGETABLE: ''
    }
  },
  CONTACT: {
    TITLE: 'At your service',
    SPECIAL_INFO: '',
    TAKE_CARE: '',
    BY_MAIL: 'By mail',
    BY_PHONE: 'By phone',
    BY_SOURDLINE: 'By Sourdline',
    MAIL_TEXT: 'Service consommateurs Bonduelle\n— BP 30173\n59653 Villeneuve d’Ascq Cedex',
    PHONE_TEXT: 'Our advisors are at your disposal <a href="tel:+33970252222"><b>09 70 25 22 22</b></a> (non-premium call)\n' +
      'from Monday to Friday 9AM - 6PM.\n' +
      'It is nevertheless possible for you to post a voice message 24 hours a day.',
    SOURDLINE_TEXT: 'For the deaf/hearing impaired, we are at your disposal by webcam or chat <b> from Monday to Friday from 9am to 6pm.</b>',
    BY_DISTRIBUTOR: '',
    BY_DISTRIBUTOR_TEXT: '',
    SUBTITLE: 'You wish to contact us for : ',
    AGREEMENT: "In accordance with Act n°78-17 of 6 January 1978 on information technology, data files and civil liberties amended and General Data Protection Regulation (EU) 2016/679 , the information collected on this form is intended to process your request / and / or claim and are subject to computer processing by Bonduelle, acting as controller. This personal data is collected to satisfy Bonduelle's legitimate interest and is intended for Bonduelle's consumer, quality, marketing and digital services teams and its processor acting on its behalf, some of whom are outside the European Union. We keep your Personal Data for the period that is necessary to process your request and then, if necessary, archive it for a duration allowing us to comply with any legal obligation or for a duration equivalent to the statutory limitation periods (namely 5 years). ",
    READ_MORE: "In addition, you are entitled, at any time, to request BONDUELLE exercise your rights to access, rectify, delete and the portability of your Personal Data as well as your rights to restrict and object to the Processing of your Personal Data. For exercising your rights related to your Personal Data, please write Dpo_france(at)bonduelle(dot)com or by mail to the following address: for the attention of the Data Protection Officer, Bonduelle, Rue Nicolas Appert, PO Box 30173, F-59653 Villeneuve D'Ascq. To learn more about the manner in which BONDUELLE collects and uses your Personal Data click here",
    READ_MORE_LABEL: 'Read also',
    SATISFACTION_TEXT: 'In order to contact you following a comment about our products, to\n' +
      'enable us to deal with your complaint and to send you a satisfaction\n' +
      'questionnaire if you so wish, we will process the information provided in\n' +
      'our capacity as data controller. The legal basis for processing your request\n' +
      'is consent due to the potential health-related data you may provide in\n' +
      'your request. This information will be kept for as long as necessary to\n' +
      'process your request and for up to 5 years to provide proof that your\n' +
      'request has been processed and to comply with our legal obligations. The\n' +
      'legal basis for sending the satisfaction questionnaire is also your consent.\n' +
      'This information will be kept for the time required to send the satisfaction\n' +
      'questionnaire and up to a maximum of 1 year. To find out more about the\n' +
      'processing of your personal data, please consult our <a href="https://www.bonduelle.fr/politique-confidentialite" target="_blank" class="underline">Privacy Policy</a>.',
    SATISFACTION_CHECKBOX: 'By ticking this box, you agree to receive a satisfaction questionnaire\n' +
      '(consisting of a single question) once your request has been processed.\n' +
      'That is optional.',
    CHECKBOX_TEXT: 'By ticking this box, you agree that Bonduelle may process the personal\n' +
      'health data you may provide in your message for the sole purpose of\n' +
      'responding to your request. <strong>That is mandatory in order to process your\n' +
      'request</strong>.',
    PHONE: 'Monday to Friday from 9am to 6pm on 00.33.09.70.25.22.22 (call not surcharged, excluding weekends and public holidays)',
    CALL_US: 'To call us',
    TO_WRITE: 'Write to us',
    PHONE_NUMBER: '330970252222',
    SUCCESS_MESSAGE: 'Thanks ! Your message has been sent to our team. It will be processed in an average time\n' +
      'from 2 to 3 days, excluding weekends and holidays.<br><br>\n' +
      'We remain naturally at your service Monday to Friday from 9h to 18h at 330970252222 (call not surcharged).<br><br>\n' +
      'As part of the monitoring of our quality of service, all our exchanges can be recorded.',
    PHOTOERROR: { MESSAGE: '' }
  },
  GLOBAL: { READ_MORE: 'Read more' },
  FLOWBOX: {
    GENERIC_KEY: 'ba_GnGC3TKedKEe0-gKomQ',
    PRODUCT_KEY: '1hRXF7TUSt-fhhlJASv-TQ',
    RECIPE_KEY: 'Ulp6NW8-QFOoSa61nBIlaA',
    HOME_KEY: 'g7--tXG3QgiwJjjpsaMmrg'
  },
  TOKEN: {
    FACEBOOK: '',
    PAGE_FACEBOOK_ID: '',
    INSTAGRAM: '',
    INSTA_ID: '17841407279489116',
    YOUTUBE: '',
    TWITTER: '',
    MAGAZINE: '6318061952.9bbab10.8842be1c95904c9d80ff59765a3a2ccc'
  },
  COOKIE: {
    BANNER: {
      MANDATORY: 'Required',
      ANALYTICS: 'ANALYTICS',
      MARKETING: 'MARKETING',
      SOCIAL_MEDIA: 'SOCIAL MEDIA',
      ACCEPT: 'Accept all',
      REJECT: 'Reject All',
      SET_UP: 'Config',
      MANDATORY_DETAILS: 'The required cookies are necessary for the operation of the site, for example to store your login information and provide you with a secure connection.\n' +
        'The cookies required to use the bonduelle.fr site are : \n' +
        '<ul>\n' +
        '<li>session cookies (SESSION_ID): Cookie generated by PHP-based applications. This is a general purpose identifier used to manage user session variables. It is a randomly generated number, the way it is used can be site specific, but a good example is maintaining a login status for a user between pages.</li>\n' +
        '</ul>',
      ANALYTICS_DETAILS: 'Functional cookies help us to optimize the site, for example, to collect statistics, to record your preferences for social interactions or to measure the performance and stability of our application.\n' +
        '<br>\n' +
        'The analytical cookies used by the bonduelle.com site are : \n' +
        '<ul>\n' +
        "<li>DoubleClick: This cookie is set by DoubleClick (owned by Google) to create a profile of the website visitor's interests and display relevant advertisements on other sites. The company's main activity is the exchange of real-time bidding ads from Google.</li>\n" +
        '<li>Google Analytics: these cookies allow tracking the most visited pages and site traffic.</li>\n' +
        '<li>UTMTracking: this cookie allows the management of UTM variables for an optimal follow-up of the different online advertising campaigns</li>.\n' +
        '</ul>',
      MARKETING_DETAILS: 'Marketing cookies allow us to track your preferences for recipes and products in order to always offer you the products that best match your desires.\n' +
        '<br>\n' +
        'The marketing cookies used by the bonduelle.fr site are : \n' +
        '<ul>\n' +
        '<li> Prediggo: this tool uses the SESSION_ID cookie to offer you the products and recipes that best suit your browsing and your searches. </li>\n' +
        '<li>ClickToBuy: this cookie enables us to remember your location so that we can offer you the nearest stores that carry the Bonduelle products you are looking for.</li>\n' +
        `<li>Social Networks: these cookies allow the Bonduelle 'SocialWall' to be displayed on the site's homepage.  This wall" allows you to see all the latest posts from the major social networks used by the Bonduelle teams.</li>\n` +
        '<li>FlowBox: this cookie enables the use of the services of the flowbox software, which allows us to offer our visitors the recipes and creations of other Internet users who use Bonduelle products.</li>\n' +
        '</ul> "',
      V2: 'ok'
    },
    MODAL: {
      TITLE: 'Cookie management',
      TEXT: 'The Bonduelle Group is committed to ensuring the protection of the Personal Data of users of its Web sites, and in particular the Bonduelle.fr Web site.  <br>To enable you to benefit from the services offered by the site, such as consulting products, discovering new gourmet recipes, subscribing to certain services such as our Newsletters, and also to optimize its use and personalization according to your profile, the site uses cookies. The Bonduelle Group is fully committed to ensuring a high level of protection with respect to such Personal Data and to complying with all applicable Data Protection rules when processing Personal Data in connection with the use of its Web site.\n' +
        'Thus, you can activate/deactivate cookies at any time.',
      CONFIG: 'Configuration',
      SAVE: 'Save'
    }
  },
  LINK: { JOB_OFFERS: 'https://jobs.bonduelle.com/?locale=en_US' },
  CLIC2BUY: { INFOS: '' }
};
