import { Component, Input, ViewEncapsulation } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
    selector: 'sf-item-flip',
    templateUrl: './item-flip.component.html',
    styleUrls: ['./item-flip.component.sass'],
    animations: [
        trigger('flip', [
            state('flipped', style({ transform: 'rotateY(180deg)' })),
            state('unflipped', style({ transform: 'rotateY(0)' })),
            transition('* => *', animate('400ms ease-in-out'))
        ])
    ],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SfItemFlipComponent {

  @Input() set flip(value: boolean) {
    this.state = value ? 'flipped' : 'unflipped';
  }

  public state: 'flipped' | 'unflipped' = 'unflipped';

  constructor() { }

}
