import { Component, Inject, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { TagManagerService } from '@app/core/tag-manager/services/tag-manager.service';
import { SomBreadcrumbService } from './breadcrumb.service';
import { BreadCrumb } from './breadcrumb.interface';
import { DOCUMENT, PlatformLocation } from '@angular/common';

@Component({
    selector: 'som-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.sass'],
    standalone: false
})
export class SomBreadcrumbComponent implements OnInit {

  breadcrumb: BreadCrumb[] = [];
  private scriptId = 'json-ld-breadcrumb';
  private script: any;

  constructor(
    private tagManager: TagManagerService,
    public breadcrumbService: SomBreadcrumbService,
    private platformLocation: PlatformLocation,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private somDocument: Document,
    @Inject(PLATFORM_ID) private platformId
  ) {}

  ngOnInit() {
    this.breadcrumbService.breadcrumb$.subscribe(breadcrumb => {
      this.breadcrumb = breadcrumb
      this._generateJsonLD();
    });
  }

  private _removeJsonLD() {
    if (this.script) {
      this.renderer.removeChild(this.somDocument.head, this.script);
      this.script = undefined
    }
  }

  private _generateJsonLD() {
    this._removeJsonLD();

    if (this.script || this.breadcrumb.length === 0) {
      return
    }
    const domainUrl = `${this.platformLocation.protocol}//${this.platformLocation.hostname}${this.platformLocation.port ? ':' + this.platformLocation.port : ''}`;
    const itemListElement = this.breadcrumb.map((bc, index) => {
      const url = this.breadcrumb[index]?.url;
      return {
        "@type": "ListItem",
        "position": index + 1,
        "name": bc.label,
        ...(index < this.breadcrumb.length - 1 && url ? { "item": `${domainUrl}/${url}` } : {})
      }
    })
    const jsonLdObject  = {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": itemListElement
    };
    const jsonLdScript = this.renderer.createElement('script');
    this.renderer.setAttribute(jsonLdScript, 'type', 'application/ld+json');
    this.renderer.setAttribute(jsonLdScript, 'id', this.scriptId);
    this.renderer.appendChild(jsonLdScript, this.renderer.createText(JSON.stringify(jsonLdObject)));
    this.renderer.appendChild(this.somDocument.head, jsonLdScript);
    this.script = jsonLdScript
  }

  public setTagBreadCrumb(label: string) {
    const origin = label === 'légumiz' ? 'Legumiz' : 'Bonduelle';
    this.tagManager.setNewEvent('Breadcrumb', origin , label , 'bondEventLab');
  }

}
