import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, Injector, PLATFORM_ID, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, withComponentInputBinding, withDebugTracing } from '@angular/router';

import { provideClientHydration, withNoHttpTransferCache } from '@angular/platform-browser';
import { routes } from '@app/app-routing.module';
import { PAGE_INFO, PageInfo } from './core/models/page-info.model';
import { GlobalErrorHandlerService } from './core/error-handler/services/error-handler.service';
import { SITE_INFO, SfLib, SfLocalizeModule, SfLocalizeService, SfSiteInfo, extractExtension, extractLang } from './sf-lib/public_api';
import { isPlatformServer } from '@angular/common';
import { site } from '@assets/mock/site.mock';
import { environment } from '@env/environment';
import { translationObject } from '@assets/translationObject';
import { HOSTNAME, ORIGIN, PATH } from './../express.tokens';
import { CoreModule } from './core/core.module';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { AngularMaterialModule } from './shared/angular-material/angular-material.module';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppInjector } from './core/app-injector';
import { withPreloading } from '@angular/router';
import { PreloadAllModules } from '@angular/router';
import { ErrorInterceptor } from './core/request/interceptors/error.interceptor';

export const appConfig: ApplicationConfig = {

  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }), 
    provideRouter(routes,
      withPreloading(PreloadAllModules),
      withComponentInputBinding(),
    ), 
    provideClientHydration(),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    provideAnimations(),
    importProvidersFrom(CoreModule),
    importProvidersFrom(SharedModule),
    importProvidersFrom(SfLib.forRoot({apiUrl: environment.url})),
    importProvidersFrom(SfLocalizeModule.forRoot({
      siteInfo: {
          provide: SITE_INFO,
          useFactory: localizeInit,
          deps: [Injector, PLATFORM_ID]
      }
    })),
    importProvidersFrom(AngularMaterialModule),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: SITE_INFO,
      useFactory: localizeInit,
      deps: [Injector, PLATFORM_ID]
    },
    {
        provide: APP_INITIALIZER,
        useFactory: localizeServiceInit,
        deps: [Injector],
        multi: true
    },
    {
        provide: PAGE_INFO,
        useClass: PageInfo,
    },
    
    // Error handling
    {
        provide: ErrorHandler,
        useClass: GlobalErrorHandlerService
    },
  ]
};

export function storeInjector(injector: Injector) {
  return () => {
    AppInjector.setInjector(injector);  // Appeler votre méthode pour stocker l'injecteur
  };
}

export function localizeServiceInit(injector: Injector) {
  AppInjector.setInjector(injector)
  return () => {
    injector.get(SfLocalizeService);
  };
}

export function localizeInit(injector: Injector, platformId: string) {
  const siteInfo: SfSiteInfo = new SfSiteInfo();
  let hostExtension: string;
  let lang: string;
  if (isPlatformServer(platformId)) {
    // Fetch HOSTNAME value token defined in server.ts (when run on server)
    const hostname = injector.get(HOSTNAME);
    hostExtension = extractExtension(hostname);
    siteInfo.lang.country = site.find((s) => s.lang.country === hostExtension) ? hostExtension : 'com';

    siteInfo.locales = site
    .filter((s) => s.lang.country === siteInfo.lang.country)
    .map((s) => s.lang);

    siteInfo.path = injector.get(PATH);

    (siteInfo as any).origin = injector.get(ORIGIN).replace(':443', '');

    lang = extractLang(siteInfo.path, siteInfo.locales);
    if (!lang) {
      const foundSiteLang: {country: string, code: string} = environment.siteLang.find((sl) => sl.country === siteInfo.lang.country);
      lang = foundSiteLang ? foundSiteLang.code : siteInfo.lang.country;
      siteInfo.lang.default = true;
    } else if (lang && lang === siteInfo.lang.country) {
      siteInfo.lang.default = true;
    } else {
      siteInfo.lang.default = false;
    }
    siteInfo.lang.code = lang;
  } else {
    hostExtension = extractExtension((<any>window).location.hostname);
    siteInfo.lang.country = site.find((s) => s.lang.country === hostExtension) ? hostExtension : 'com';

    siteInfo.locales = site
    .filter((s) => s.lang.country === siteInfo.lang.country)
    .map((s) => s.lang);

    siteInfo.path = (<any>window).location.pathname;
    (siteInfo as any).origin = (<any>window).location.origin;

    lang = extractLang(siteInfo.path, siteInfo.locales);

    if (!lang) {
      const foundSiteLang: {country: string, code: string} = environment.siteLang.find((sl) => sl.country === siteInfo.lang.country);
      lang = foundSiteLang ? foundSiteLang.code : siteInfo.lang.country;
      siteInfo.lang.default = true;
    } else if (lang && lang === siteInfo.lang.country) {
      siteInfo.lang.default = true;
    } else {
      siteInfo.lang.default = false;
    }
    siteInfo.lang.code = lang;

  }
  siteInfo.defaultLang = {
    sfType: 'SfLang',
    country: 'com',
    code: 'en',
    default: true
  };
  siteInfo.translationObject = translationObject;
  return siteInfo;
}