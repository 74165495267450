import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  Inject
} from '@angular/core';


import { OPTIONS, SfOptions } from '../../../core/interfaces/options.interface';
import { SfMediaHoverImage } from '../models/media-hover-image.model';
import { SfMediaImage } from '../models/media-image.interface';
import { SfMediaImagev2 } from '../models/media-imagev2.interface';
import { SfLink } from '../../link';

@Component({
    selector: 'sf-image',
    templateUrl: './image.component.html',
    styleUrls: ['./image.component.sass'],
    standalone: false
})
export class SfImageComponent implements OnChanges {

  @Input() image: SfMediaImage | SfMediaHoverImage | SfMediaImagev2;

  @Input() link: SfLink;

  @Input() align: 'center'| 'left' | 'right' | 'bottom' | 'top' = 'center';

  @Input() fitting: 'fill' | 'contain' | 'cover' | 'scale-down' | 'none' = 'cover';

  @Input() type: 'original'|
                 'zone_maxi' |
                 'crop_thumbnail' |
                 'ratio_770_616' |
                 'ratio_680_544' |
                 'ratio_500_400' |
                 'ratio_330_264' |
                 'ratio_245_196' |
                 'ratio_500_625' |
                 'ratio_328_410' |
                 'ratio_236_295' |
                 'ratio_416_520' = 'original';

  public hover = false;
  public imageAlt: string;
  public imageSrc: string;
  public imageSrcHover: string;
  public showImage = false;

  constructor(
    @Inject(OPTIONS) private options: SfOptions
  ) { }

  ngOnChanges(simpleChanges: SimpleChanges) {
    for (const propName of Object.keys(simpleChanges)) {
      if (propName === 'image') {
        const chargedImage: SfMediaImage | SfMediaHoverImage = simpleChanges[propName].currentValue;
        this.chargeImage(chargedImage && chargedImage[0] ? chargedImage[0] : chargedImage);
      }
    }
  }

  private chargeImage(chargedImage: SfMediaImage | SfMediaHoverImage | SfMediaImagev2) {
    if (!chargedImage) { return; }

    const isMediaV2 = !(chargedImage && chargedImage['image']);
    const imageUri = isMediaV2 ?
      (chargedImage as SfMediaImagev2).uri :
      (chargedImage as SfMediaImage).image.uri;
    const imageAlt = isMediaV2 ?
      '' :
      (chargedImage as SfMediaImage).alt_image;

    if (imageUri) {

      // uri contains at least 'original'
      let uri = imageUri.original;
      if (imageUri.hasOwnProperty(this.type) && typeof imageUri[this.type] === 'string') {
        uri = imageUri[this.type];
      }

      // Make the difference between relative and absolute uri
      if (uri && (uri.startsWith('http') || uri.startsWith('/assets'))) {
        this.imageSrc = uri;
      } else if (uri) {
        // If uri is relative, add the known api url
        this.imageSrc = `${this.options.apiUrl}${uri}`;
      } else {
        this.imageSrc = null;
      }
    } else {
      this.imageSrc = null;
    }
    this.imageAlt = imageAlt;
    this.imageSrcHover = null;

    // Load hover image
    const hover: SfMediaHoverImage = chargedImage as SfMediaHoverImage;
    if (hover.hover && hover.image) {
      if (hover.image.image.uri && hover.image.image.uri.original) {
        this.imageSrc = `${this.options.apiUrl}${hover.image.image.uri.original}`;
      } else {
        this.imageSrc = null;
      }
      this.imageAlt = hover.image.alt_image;
      if (hover.hover.image.uri && hover.hover.image.uri.original) {
        this.imageSrcHover = `${this.options.apiUrl}${hover.hover.image.uri.original}`;
      } else {
        this.imageSrcHover = null;
      }
    }
  }

}
