import { Component, Input, Inject, ViewEncapsulation } from '@angular/core';
import { SfItemRecipe } from '../models/item-recipe.interface';

@Component({
    selector: 'sf-item-recipe',
    templateUrl: './item-recipe.component.html',
    styleUrls: ['./item-recipe.component.sass'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SfItemRecipeComponent {

  /**
   * Display item data
   *
   * @memberof SfItemRecipeComponent
   */
  @Input() item: SfItemRecipe;
  /**
   * Size template
   *
   * @memberof SfItemRecipeComponent
   */
  @Input() size: 'big'| 'small' = 'big';

  @Input() seoTitle: 'H1'|'H2'|'H3'|'DIV' = 'DIV';

  public hover: boolean;

  constructor() {
  }
}
