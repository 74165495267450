import {Component, Inject, Input} from '@angular/core';

import { OPTIONS, SfOptions } from '@app/sf-lib/lib/core/interfaces/options.interface';
import { VideoWithBackgroundModel } from './video-with-background.model';

@Component({
    selector: 'video-with-background',
    templateUrl: './video-with-background.component.html',
    styleUrls: ['./video-with-background.component.sass'],
    standalone: false
})
export class VideoWithBackgroundComponent {

  @Input() section: VideoWithBackgroundModel;

  constructor(
    @Inject(OPTIONS) public options: SfOptions,
  ) {
  }

}
