import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SfMessageService } from './services/message.service';

@NgModule({
  imports: [
    CommonModule
  ]
})
export class SfMessageModule {
  static forRoot(): ModuleWithProviders<SfMessageModule> {
    return {
      ngModule: SfMessageModule,
      providers: [
        SfMessageService
      ],
    };
  }
}
