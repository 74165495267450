import * as moment from 'moment';
import { prediggoDateFormat } from '../utils/utils';
import { SfModel } from '@app/sf-lib/public_api';

export class PrediggoAdvanced extends SfModel {

  /**
   * ID
   * @memberof PrediggoAdvanced
   */
  public customerId: string;

  /**
   * COMPUTER - this is the default device (value used if device is left empty) and is for the traditionnel computer web browser
   * MOBILE - this is for the mobile app or responsive design
   * TABLET - this is for the tablet app
   * EMAIL - this is for the email specific call
   * STORE - this is for the instore app.
   * @memberof PrediggoAdvanced
   */
  public device: 'COMPUTER' | 'MOBILE' | 'TABLET' | 'EMAIL' | 'STORE';

  /**
   * The reference date to use for the computation. Date format: “yyyy-MM-dd'T'HH:mm:ss” (ISO-8601)
   * @memberof PrediggoAdvanced
   */
  public referenceDate: string;
  get referenceDateAsDate(): Date {
    return moment(this.referenceDate, prediggoDateFormat).toDate();
  }
  set referenceDateAsDate(date: Date) {
    this.referenceDate = moment(date).format(prediggoDateFormat);
  }

  /**
   * The identifier of a referer (url)
   * @memberof PrediggoAdvanced
   */
  public referer: string;

  /**
   * The name of the zone. i.e: 'en-us'
   * @memberof PrediggoAdvanced
   */
  public zone: string;

  constructor() {
    super();
    this.customerId = '';
    this.device = 'COMPUTER';
    this.referer = '';
    this.zone = '';
  }
}
