import { SomPage } from './page.model';
import { SfSlide, SfSlider } from '@app/sf-lib/public_api';
import { SomTheme } from './theme.model';

export class SomProductListPage extends SomPage implements SfSlider {

  public slides: SfSlide[];

  /** HTML description */
  public description: string;

  public theme: SomTheme;

  public theme_h1?: string;

  constructor() {
    super();
    this.slides = [];
  }
}
