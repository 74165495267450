import { SfModel } from '../../../core/networking/models/model';
import { SfFile } from '../../../core/models/file.model';
import { SfMediaVideo } from './media-video.interface';

export class SfSimpleMediaVideo extends SfModel implements SfMediaVideo {

  /** Name of the video */
  name: string;

  /** Main image */
  thumbnail: SfFile;

  /** Video url */
  video: string;

  constructor() {
    super();
    this.thumbnail = new SfFile();
  }
}
