import { SfModel } from '../../../core/networking/models/model';
import { SfMediaImage } from './media-image.interface';
import { SfSimpleMediaImage } from './media-image.model';

export class SfMediaHoverImage extends SfModel {

  /** Base image */
  public image: SfMediaImage;

  /** Hovered image */
  public hover: SfMediaImage;

  constructor() {
    super();
    this.image = new SfSimpleMediaImage();
    this.hover = new SfSimpleMediaImage();
  }
}
