<div ngSkipHydration *ngIf="section" class="sf-section-image" [ngClass]="section.position === 'right' ? 'sf-section-image-right': 'sf-section-image-left'">

  <div [sfLink]='section.link' class="sf-section-image-image">
    <sf-image [image]="section.image" type="ratio_328_410"></sf-image>
  </div>

  <!-- Content -->
  <div class="sf-section-image-content">

    <!-- Title -->
    <ng-container *ngIf="section.title" [ngSwitch]="seoTitle">
      <h2 *ngSwitchCase="'H1'" class="sf-section-image-content-title sf-title">{{section.title}}</h2>
      <h2 *ngSwitchCase="'H2'" class="sf-section-image-content-title sf-title">{{section.title}}</h2>
      <h3 *ngSwitchCase="'H3'" class="sf-section-image-content-title sf-title">{{section.title}}</h3>
      <div *ngSwitchDefault class="sf-section-image-content-title sf-title">{{section.title}}</div>
    </ng-container>

    <!-- Text -->
    <div *ngIf="section.text" [innerHTML]="section.text ? (section.text | safePipe) : ''" class="sf-section-image-content-text sf-text"></div>

  </div>
</div>
