import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import {
  SfNetworkingModule, SF_TYPE_PARSER, SfModelBundle,
  SfSimpleLink, SfSimpleSlide, SfSimpleBanner,
  SfSimpleSectionText,
  SfSimpleSectionImage,
  SfSimpleSectionImageIcon,
  SfSimpleItemStat
} from '@app/sf-lib/public_api';
import { SomTypeParser } from './class/type-parser';

// Sombrero Models
import { SomPage } from '../models/page.model';
import { SomBloc } from '../models/bloc.model';
import { SomCorporatePage } from '../models/corporate-page.model';
import { SomGenericPage } from '../models/generic-page.model';
import { SomItemList } from '../models/item-list.model';
import { SomPreviousArticles } from '../models/previous-articles.model';
import { SomPreviousEdition } from '../models/previous-editions.model';
import { SomProductListPage } from '../models/product-list-page.model';
import { SomProductPage } from '../models/product-page.model';
import { SomRecipeListPage } from '../models/recipe-list-page.model';
import { SomRecipeHomePage } from '../models/recipe-home-page.model';
import { SomRecipePage } from '../models/recipe-page.model';
import { SomTheme } from '../models/theme.model';
import { SomMagazineHomePage } from '../models/magazine-home-page.model';
import { SomMagazineArticlePage } from '../models/magazine-article-page.model';
import { SomPreviousArticlesByCategories } from '../models/previous-articles-by-categories.model';
import { SomMagazineShowcase } from '../models/magazine-showcase.model';
import { SomMagazineRecipePage } from '../models/magazine-recipe-page.model';
import { SomFieldToPlatePage } from '../models/field-to-plate-page.model';
import { SomCorporateArticlePage } from '../models/corporate-article-page.model';
import { SomHomePage } from '../models/home-page.model';
import { SomHomeBanner } from '../models/home-banner.model';
import { SomVegetablePage } from '../models/vegetable-page.model';
import { SomMagazineMonthly } from '../models/magazine-monthly.model';
import { SomFaqArticlePage } from '../models/faq-article-page.model';
import { SomFaqPage } from '../models/faq-page.model';
import { SomFaqList } from '../models/faq-list.model';
import { SomProductArticleDetails } from '../models/product-article-details.models';
import { SomContactPage } from '../models/contact-page.model';
import { SomForm } from '../models/form.model';
import { SomMenuPageArticlePage } from '../models/menu-page-article.model';
import { SomFaqSection } from '../models/faq-section.model';
import { SomSimpleSectionTiles } from '@app/shared/grid-tiles/models/section-tiles.model';
import { SomTile } from '@app/shared/grid-tiles/models/tile.model';
import { SomHighlight } from '../models/highlight.model';
import { SomSectionVideo } from '../models/video-section.model';
import { SfSimpleSectionSlider } from '@app/sf-lib/lib/features/slider/models/section-slider.model';

const bundle: SfModelBundle = {
  'SomPage': SomPage,
  'SomBloc': SomBloc,
  'SomHomePage': SomHomePage,
  'SomHomeBanner': SomHomeBanner,
  'SomHighlights': SomHighlight,
  'SomMagazineHomePage': SomMagazineHomePage,
  'SomMagazineArticlePage': SomMagazineArticlePage,
  'SomPreviousEdition': SomPreviousEdition,
  'SomPreviousArticlesByCategories': SomPreviousArticlesByCategories,
  'SomRecipePage': SomRecipePage,
  'SomMagazineRecipePage': SomMagazineRecipePage,
  'SomMagazineShowcase': SomMagazineShowcase,
  'SomMagazineMonthly': SomMagazineMonthly,
  'SomItemList': SomItemList, // @todo make sure one class of this type is enough
  'SomGenericPage': SomGenericPage,
  'SomCorporatePage': SomCorporatePage,
  'SomCorporateArticlePage': SomCorporateArticlePage,
  'SomFieldToPlatePage': SomFieldToPlatePage,
  'SomMenuPageArticlePage': SomMenuPageArticlePage,
  'SomPreviousArticles': SomPreviousArticles,
  'SomProductListPage': SomProductListPage,
  'SomProductPage': SomProductPage,
  'SomRecipeHomePage': SomRecipeHomePage,
  'SomRecipeListPage': SomRecipeListPage,
  'SomVegetablePage': SomVegetablePage,
  'SomTheme': SomTheme,
  'SomFaqArticlePage': SomFaqArticlePage,
  'SomFaqList': SomFaqList,
  'SomFaqPage': SomFaqPage,
  'SomProductArticleDetails': SomProductArticleDetails,
  'SomContactPage': SomContactPage,
  'SomForm': SomForm,
  'SomFaqSection': SomFaqSection,
  'SomSectionTiles': SomSimpleSectionTiles,
  'SomTile': SomTile,

  // For unknown reasons we need to redefine models that come from sflib and
  // that we are using in Sombrero
  'SfLink': SfSimpleLink,
  'SfSlide': SfSimpleSlide,
  'SfItemStat': SfSimpleItemStat,
  'SfBanner': SfSimpleBanner,
  'SfSectionVideo': SomSectionVideo,
  'SfSectionText': SfSimpleSectionText,
  'SfSectionImage': SfSimpleSectionImage,
  'SfSectionSlider': SfSimpleSectionSlider,
  'SfSectionImageIcon': SfSimpleSectionImageIcon,
};

@NgModule({
  imports: [
    CommonModule,

    SfNetworkingModule.forRoot(
      bundle,
      {
        // Type parser provider
        provide: SF_TYPE_PARSER,
        useClass: SomTypeParser
      }
    )
  ],
  declarations: []
})
export class NetworkingModule {
  static forRoot(): ModuleWithProviders<NetworkingModule> {
    return {
      ngModule: NetworkingModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true,
        },

      ],
    };
  }
}
