import {
    trigger,
    state,
    style,
    transition,
    query,
    animateChild,
    stagger
  } from '@angular/animations';

  export function hideLeft(from, to, params) {
    return from === 'show' && to === 'hide' && params.direction === 'left';
  }

  export function hideRight(from, to, params) {
    return from === 'show' && to === 'hide' && params.direction === 'right';
  }

  export function showLeft(from, to, params) {
    return from === 'hide' && to === 'show' && params.direction === 'left';
  }

  export function showRight(from, to, params) {
    return from === 'hide' && to === 'show' && params.direction === 'right';
  }

  export const SlideGridAnimations = {
    parent: trigger('slideAnimations', [
      state('show', style('*')),
      state('hide', style('*')),
      transition('show => hide', [
        query('@leftAnimation, @rightAnimation', [
          stagger(100, [
            animateChild()
          ])
        ], { optional: true })
      ]),
      transition('hide => show', [
        query('@leftAnimation, @rightAnimation', [
          stagger(100, [
            animateChild()
          ])
        ], { optional: true })
      ])
    ]),


    left: trigger('leftAnimation', [
      state('show', style('*')),
      state('hide', style('*')),
    ]),


    right: trigger('rightAnimation', [
      state('show', style('*')),
      state('hide', style('*')),
    ])
  };
