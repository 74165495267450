import { Component, Input, ViewEncapsulation } from '@angular/core';

import { SfLink } from '@app/sf-lib/lib/features/link';
import { SfItem } from '../models/item.interface';
import { SITE_INFO, SfSiteInfo } from '../../../core/localize/models/site-info.model';
import { OPTIONS, SfOptions } from '../../../core/interfaces/options.interface';
import { isPlatformBrowser } from '@angular/common';
import { SfMessageService } from '../../../core/message/services/message.service';

@Component({
    selector: 'sf-item-text',
    templateUrl: './item-text.component.html',
    styleUrls: ['./item-text.component.sass'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SfItemTextComponent  {

  /**
   * Display item data
   *
   * @memberof SfItemTextComponent
   */
  @Input() item: SfItem;

  @Input() seoTag: 'H1'|'H2'|'H3'|'DIV' = 'DIV';
  @Input() seoTitle: 'H1'|'H2'|'H3'|'DIV' = 'DIV';

  public hover: boolean;

  public smallDevice = false;
  constructor(
    public eventService: SfMessageService
  ) { }

  get link() {
    let link = this.item.link || {} as SfLink;

    // Drupal 9: link is in item.type
    if (this.item['type'] && this.item['type'][0] && this.item['type'][0]['link'] && this.item['type'][0]['link'] && this.item['type'][0]['link']['url']) {
      link = this.item['type'][0]['link'];
    }

    // Drupal 9: replace undefined value from URL, due to field missing on Drupal update
    if (this.item['path'] && this.item['path'][0] && this.item['path'][0]['alias'] && this.item['nid']) {
      // console.log('this.item[\'path\'][0][\'alias\']', this.item['path'][0]['alias']);
      // First "undefined" is item alias
      link['url'] = link['url'].replace('undefined', this.item['path'][0]['alias'].replace('/', ''));
      // The 2 others are item ID
      link['url'] = link['url'].replace(/undefined/g, this.item['nid'][0]['value']);
    }

    // SEO: All links to lower case
    link['url'] = link['url'].toLowerCase();

    return link;
  }
}
