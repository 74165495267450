import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SomBreadcrumbComponent } from './breadcrumb.component';
import { RouterModule } from '@angular/router';
import { UtilsModule } from '@app/shared/utils/utils.module';
import { AngularMaterialModule } from '@app/shared/angular-material/angular-material.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    UtilsModule,
    AngularMaterialModule,
  ],
  declarations: [
    SomBreadcrumbComponent
  ],
  exports: [
    SomBreadcrumbComponent
  ]
})
export class SomBreadcrumbModule {
  
}
