import { SfModel } from '../../../core/networking/models/model';
import { Validator, Validators, ValidationErrors, ValidatorFn } from '@angular/forms';
import { SfFormHint } from './form-hint.interface';

export interface SfFormField extends SfModel {

  /** Label */
  label: string;

  /** Description of the field */
  description: string;

  /** Value */
  value?: any;

  /** Technical name used in requests */
  technicalName: string;

  /** True if the field should show an error when missing */
  required?: boolean;

  /** Other validators names (other than required) */
  validators?: string[];

  /** Error message */
  error?: string;

  /** Hint */
  hint?: SfFormHint;

  /** Width of the field on its line */
  width: number;

  /** For HTML autocomplete attribute. Generally helps navigator autofill contact infos */
  autocomplete: string;
}

export function parseValidators(validatorNames: string[]): ValidatorFn[] {

  if (!Array.isArray(validatorNames) && typeof validatorNames === 'string') {
    validatorNames = [validatorNames];
  }

  const validators = validatorNames.reduce((prev: ValidatorFn[], curr: string) => {
    switch (curr) {
      case 'email':
        prev.push(Validators.email);
        break;
      case 'date':
        prev.push(Validators.pattern(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i));
        break;
      case 'max(13)':
        prev.push(Validators.maxLength(13));
        break;
      case 'min(13), max(13)':
        prev.push(Validators.maxLength(13));
        prev.push(Validators.minLength(13));
        break;
      case 'phone':
        prev.push(Validators.pattern(/^[0-9+]*$/i));
        break;
      default:
      break;
    }
    return prev;
  }, []);

  return validators;
}
