<ng-container *ngIf="link; else noLink">
  <a [href]="link" target="_blank">
    <button 
      [attr.type]="type"
      [disabled]="disabled"
      (click)="onClick()"
      [ngClass]="classes">
      {{ label }}
    </button>
  </a>
</ng-container>

<ng-template #noLink>
  <button 
    [attr.type]="type"
    [disabled]="disabled"
    (click)="onClick()"
    [ngClass]="classes">
    {{ label }}
  </button>
</ng-template>