import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SfLinkDirective } from './directives/link.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [SfLinkDirective],
  exports: [SfLinkDirective]
})
export class SfLinkModule { }
