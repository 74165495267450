<div class="sf-form-image-multi" *ngIf="data && formControl">
  <label class="sf-form-image-multi-label sf-title" [for]="controlName" *ngIf="data.label">
    {{ data.label | translate }}
    <span *ngIf="data.required" [ngClass]="{'sf-error-color': !formControl.pristine && formControl.invalid}"> *</span>
  </label>

  <div #imageMultiContainer class="sf-form-image-multi-container" *ngFor="let formValue of formControl.value; index as i">
    <!-- Selected image -->
    <img class="sf-form-image-multi-thumbnail" [src]="formValue" loading="lazy">

    <!-- See button -->
    <button class="sf-form-image-multi-detail sf-main-color" (click)="show(imageMultiContainer, i)">
      <mat-icon class="sf-form-image-multi-detail-icon" svgIcon="search_round"></mat-icon>
      <span>{{ 'SF.FORM.PHOTO.SEE' | translate }}</span>
    </button>

    <!-- Delete button -->
    <button class="sf-form-image-multi-delete sf-main-color" (click)="delete(i)">
      <mat-icon class="sf-form-image-multi-delete-icon" svgIcon="close_round"></mat-icon>
      <span>{{ 'SF.FORM.PHOTO.DELETE' | translate }}</span>
    </button>
  </div>

  <!-- Add file button -->
  <div>
    <input #fileInput style="display: none;" multiple="multiple" accept="image/*" type="file" (change)="onFileChanged($event)">
    <app-button
      class="mt-c-md"
      (buttonClick)="fileInput.click()"
      [label]="'SF.FORM.PHOTO.ADD' | translate">
    </app-button>
  </div>


  <div class="mt-c-xs sf-error-color sf-title" *ngIf="!formControl.pristine && formControl.invalid">
    {{ data.error || 'SF.FORM.ERROR' | translate }}
  </div>
</div>
