/* tslint:disable */export const lt_lt = {
  '404': {
    TITLE: 'Ups...',
    PAGENOTFOUND: 'Puslapis neegzistuoja',
    BUTTON: 'Atgal į pagrindinį',
    RECIPES: 'Atrask mūsų receptus'
  },
  HOME: {
    SHOWCASE: 'Receptai',
    CATCH_PHRASE: 'Maži dideli atradimai',
    SHOWCASE_SEE_ALL: 'Visi receptai',
    MAGAZINE: { TITLE: 'Populiariausi', BUTTON: "BLOG'AS" },
    OUR_VEGETABLES: 'Mūsų daržovės',
    SOCIAL_WALL: 'Kartu visada smagiau!',
    FAQ_TITLE: 'Jūsų klausimai',
    FAQ_SEE_MORE: 'Daugiau',
    FLOWBOX: ''
  },
  SELECT: { DEFAULT_VALUE: 'Bonduelle siūlomi' },
  MENU: {
    CONTACT_US: 'Kontaktai',
    MY_ACCOUNT: 'Mano Bonduelle',
    GOOD_DEALS: 'Geri pasiūlymai',
    NEWSLETTER: 'Bonduelle naujienlaiškis'
  },
  FOOTER: {
    LINK: {
      BONDUELLE: 'Bonduelle.lt',
      CREDITS: 'Autorinės teisės',
      LEGAL_NOTICE: 'Teisinė informacija',
      TERMS_OF_SALES: 'Pardavimo sąlygos',
      COOKIES: 'Slapukai',
      SITEMAP: 'Svetainės žemėlapis',
      PRIVACY_POLICY: 'Privatumo politika',
      ALL_RIGHTS_RESERVED: '2024© Visos teisės saugomos'
    },
    HISTORY: {
      TITLE: 'Bonduelle nuo 1853',
      TEXT: 'Bonduelle – tai šeima. Jau 7 karta dirba, kurdama žemės ūkio produkciją, gerbiančią gamtą ir žmones. Mes palaikome ekologinę žemdirbystę, orientuojamės į ateitį ir kasdien stengiamės, kad daržovės taptų vis didesnė kiekvieno žmogaus gyvenimo dalis.'
    },
    NEWSLETTER: {
      TITLE: 'Žmonės ir Bonduelle',
      TEXT: 'Sutinku gauti Bonduelle naujienas ir pasiūlymus "Viskas apie daržovės pagal Bonduelle" (naujienos, reklama, konkursai, straipsniai...)',
      PLACEHOLDER: 'Jūsų el. paštas',
      GDPR_LINK: 'Daugiau apie jūsų duomenų tvarkymą'
    }
  },
  PRODUCTS: 'Produktai',
  MAGAZINE: {
    HOME: 'Sveiki atvykę į MAŽUS BEI DIDELIUS ATRADIMUS',
    CATCH_PHRASE: '',
    MENU: { BONDUELLE_LINK: 'Eiti į bonduelle.lt' },
    SHOWCASE: 'Straipsniai/įrašai',
    PREVIOUS_ARTICLES: 'Ankstesni įrašai',
    PREVIOUS_ARTICLES_SEE_ALL: 'Matyti visus mūsų įrašus',
    PREVIOUS_EDITIONS: 'Ankstesni mūsų leidiniai',
    PREVIOUS_EDITIONS_SEE_ALL: 'Matyti daugiau leidinių',
    PREVIOUS_ARTICLES_BY_CATEGORY: 'Ankstesni įrašai pagal kategorijas',
    RUBRIC: {
      SEE_ALL: 'Matyti visus receptus',
      SEE_ALL_ARTICLES: 'Matyti visus įrašus',
      ON: 'įrašai apie'
    },
    RUBRIC_COOK: { ON: 'receptai' },
    ARTICLE: { JOIN_US: 'Prisijunk', READ_ALSO: 'Taip pat skaitykite' },
    RECIPE: {
      INGREDIENTS: 'Ingredientai',
      INGREDIENTS_LABEL_DESC: '{{value}} žmonėms',
      INGREDIENTS_LABEL_ONE_DESC: '{{value}} žmogui',
      INGREDIENTS_LABEL: 'Ingredientai {{value}} porcijoms',
      INGREDIENTS_LABEL_ONE: 'Ingredientai {{value}} porcijai',
      PEOPLES: '{{value}} asmenims',
      PEOPLE: '{{value}} žmonių',
      DIFFICULTY: {
        EASY: 'Lengvas',
        MEDIUM: 'Vidutinio sunkumo',
        HARD: 'Sudėtingas'
      },
      COST: { CHEAP: 'Pigus', EXPENSIVE: 'Brangus' },
      TIMING: { MIN: 'minučių', HOURS: 'valandos' },
      STEP: 'Žingsnis',
      DISCOVER_ALSO: 'Taip pat atrask...'
    }
  },
  ARTICLE: { DISCOVER_ALSO: 'Taip pat atrask...', INGREDIENTS: 'Ingredientai' },
  SF: {
    SLIDE: { DISCOVER: 'Atrask' },
    SEARCH: { PLACEHOLDER: 'Paieška', ALL: 'Visi' },
    NEWSLETTER: {
      BTN_LABEL: 'Prenumeruoti',
      PLACEHOLDER: 'Jūsų el. paštas',
      ERROR_MESSAGE: 'Neteisingas el. paštas',
      ALREADY_SUBSCRIBED: 'Jūs jau esate užsiregistravęs',
      GDPR: {
        TXT: 'Daugiau informacijos apie jūsų duomenų tvarkymą',
        LINK: '/slapukai#gdpr'
      }
    },
    MONTHLY_FOLDER: { TITLE: 'Mėnesio naujienos' },
    MINI_NEWSLETTER: {
      PLACEHOLDER: 'Jūsų el. paštas',
      GDPR: {
        TXT: 'Daugiau informacijos apie jūsų duomenų tvarkymą',
        LINK: '/slapukai#gdpr'
      }
    },
    SOCIALITEM: {
      SHARE: 'dalintis',
      COMMENT: 'komentuoti',
      BONDUELLE: 'Bonduelle'
    },
    SEARCHRECIPE: { DISCOVER_ALL: 'ieškoti visų receptų!' },
    FORM: {
      ERROR: 'Neteisingai užpildyta',
      PHOTO: {
        ADD: 'Pridėti nuotrauką',
        DELETE: 'Ištrinti nuotrauką',
        SEE: 'Matyti'
      }
    }
  },
  CORPORATE: {
    COMPANY_STORY: 'kompanijos istorija',
    CSR_COMMITMENT: 'tvarumas',
    VEGETABLE_INNOVATIONS: '',
    NEWS: 'apie bonduelle',
    SEE_MORE: 'Daugiau įrašų',
    ON: 'Įrašai'
  },
  NEWSLETTER: {
    TITLE: 'Prenumeruoti naujienlaiškį',
    SUBTITLE: 'Geri pasiūlymai, kulinariniai įkvėpimai ir kultūra!'
  },
  ROUTES: {
    products: 'produktai',
    news: 'naujienos',
    'since-1853': 'nuo-1853',
    'field-plate': 'darzoviu-enciklopediia',
    'company-story': 'bonduelle-lietuvoie',
    'csr-commitments': 'mums-rupi',
    'vegetal-innovations': 'isa-jsipareigoiimas',
    magazine: 'blogas',
    all: 'visi',
    article: 'irasas',
    cook: 'kepti',
    cultivate: 'kultivuoti',
    explore: 'atrasti',
    recipe: '',
    recipes: 'receptai',
    'recipes-home': 'receptai-pagrindinis',
    r: 'r',
    credits: 'kreditai-lt',
    'legal-notice': 'teisine-informacija',
    'terms-of-sales': 'pardavimo-salygos',
    cookies: 'slapukai',
    sitemap: 'svetaines-zemelapis',
    'privacy-policy': 'privatumo-politika',
    'all-rights-reserved': 'visos-teises-saugomos',
    search: 'paieska',
    'articles-search': 'irasas',
    vegetables: 'produktai',
    p: 'p',
    contact: 'kontaktai',
    'info-product': 'produkto-informacija',
    'note-product': 'produkto-pastaba',
    suggestion: 'pasiulymas',
    'other-request': 'kiti-pasiulymai',
    faq: 'duk',
    'all-themes': 'visos-temos',
    'products-search': 'produkto-paieska',
    recipesearch: 'recepto-paieska',
    'product-news': 'naujienos',
    'good-deals': 'geri-pasiulymai',
    'not-found': 'nerasta',
    'menu-page': 'blog-as',
    'menu-one': 'pasigaminkime',
    'menu-two': 'jdomybes',
    'menu-three': '',
    'menu-four': '',
    'menu-external': '',
    'theme-pasta-rice': '',
    'theme-bowls': '',
    'theme-soup': '',
    'theme-snack': '',
    'theme-sauce': '',
    'theme-unique-plate': '',
    'theme-over': '',
    'theme-legumin': '',
    'theme-ingredients-recipes': '',
    'theme-cook-plate': ''
  },
  BREAD: {
    products: 'produktai',
    news: 'naujienos',
    'since-1853': 'nuo 1853',
    'field-plate': 'Daržovių enciklopedija',
    'company-story': 'bonduelle lietuvoje',
    'csr-commitments': 'Mums rūpi',
    'vegetal-innovations': 'ĮSA įsipareigojimas',
    magazine: 'blogas',
    all: 'visi',
    article: 'įrasas',
    cook: 'kepti',
    cultivate: 'kultivuoti',
    explore: 'atrasti',
    recipe: '',
    recipes: 'receptai',
    'recipes-home': 'receptai pagrindinis',
    'articles-search': 'įrasas',
    search: 'paieska',
    vegetables: 'produktai',
    contact: 'kontaktai',
    'info-product': 'produkto informacija',
    'note-product': 'produkto-pastaba',
    suggestion: 'pasiūlymas',
    'other-request': 'kiti pasiūlymai',
    faq: 'duk',
    'all-themes': 'visos temos',
    'products-search': 'produkto paieška',
    recipesearch: 'recepto paieška',
    'product-news': 'produkto naujienos',
    'good-deals': 'geri pasiūlymai',
    'not-found': 'nerasta',
    'menu-page': "blog'as",
    'menu-one': 'Pasigaminkime',
    'menu-two': 'Įdomybės',
    'menu-three': '',
    'menu-four': ''
  },
  MENU_PAGE: {
    TITLE1: 'pasigaminkime',
    TITLE2: 'Įdomybės',
    TITLE3: '',
    TITLE4: '',
    EXTERNAL: ''
  },
  TECHNICAL: { MENU_PAGE: 'vegetarian_passions' },
  SEO: {
    TITLE: {
      MENU_ONE: 'Pasigaminkime',
      MENU_TWO: 'Įdomybės',
      MENU_THREE: 'pagrindinis trečias Pavadinimas',
      MENU_FOUR: 'pagrindinis ketvirtas Pavadinimas',
      HOME: 'Bonduelle: pasaulis, kuris mums patinka',
      SEARCH: {
        MAIN: '{{value}} | Bonduelle',
        RECIPES: 'Paieškos rezultatai receptuose: {{value}} | Bonduelle',
        VEGETABLES: 'Paieškos rezultatai produktuose: {{value}} | Bonduelle',
        ARTICLES: '{{value}} - paieškos rezultatai Bonduelle'
      },
      CONTACT: 'Bonduelle kontaktai',
      RECIPES: 'Bonduelle receptai',
      RECIPE_HOME: 'Visi mūsų receptai | Bonduelle',
      RECIPE: 'Bonduelle receptas',
      PRODUCTS: 'Bonduelle produktai',
      NEWS: 'Bonduelle naujienos',
      CORPORATE: {
        NEWS: 'Bonduelle nuo 1853m.: apie bonduelle',
        COMPANY_STORY: 'Bonduelle nuo 1853m.: kompanijos istorija',
        CSR_COMMITMENTS: 'Bonduelle tvarumas',
        VEGETAL_INNOVATIONS: 'Bonduelle nuo 1853m.: daržovių naujovės',
        FIELD_PLATE: 'Daržovių enciklopedija | Bonduelle',
        ARTICLE: '{{value}} | Bonduelle',
        VEGETABLE: 'Viskas apie {{value}} | Bonduelle'
      },
      PRODUCT: '{{value}} | Bonduelle daržovės',
      MAGAZINE: {
        NEWS: 'Naujienos apie daržoves, kūrybingi receptai ir dar daugiau | Bonduelle',
        ARTICLE: 'Sužinokite su Bonduelle ',
        COOK: 'Pagaminkite su Bonduelle',
        GROW: 'Daržovių auginimas su Bonduelle',
        EXPLORE: 'Atradimai su Bonduelle',
        RECIPE: 'Pasigaminkime su Bonduelle'
      },
      FAQ: 'Dažniausiai užduodami klausimai | Bonduelle',
      NOTFOUND: '404 klaida, bandykite dar kartą vėliau | Bonduelle',
      RECIPES_ALL: 'visi mūsų receptai',
      RECIPETYPE: '{{value}} - pasigaminkime sveikai, greitai ir skaniai',
      RECIPESEASON: '{{value}} - receptai, skirti tinkamiausiu metų laiku',
      RECIPEPREPARATION: '{{value}} - pasigaminkime sveikai, greitai ir skaniai',
      RECIPEDIFFICULTY: '{{value}} receptas - kiekvienas gali gaminti.',
      RECIPEVEGETABLE: '{{value}} - receptai su šia daržove',
      RECIPECOST: '{{value}}',
      RECIPEDISH: '{{value}} - sveika, greita ir paprasta.',
      RECIPEDESC: '{{value}} - patiekalai visiems.',
      '2RECIPEFACETS': '{{value}}',
      '4RECIPEFACETS': '{{value}} receptų idėjos',
      PRODUCTSEASON: 'Produktai, kuriuos galite gaminti {{value}}',
      PRODUCTTECHNO: '{{value}} produktai',
      PRODUCTVEGETABLE: '{{value}} - Bonduelle produktai',
      PRODUCTRANGE: 'Produktai, kuriuose vyrauja {{value}}',
      THEME_ALL: 'Konservuoti bei šaldyti produktai',
      THEME_STARTER: 'Užkandžiai | Bonduelle',
      THEME_SALAD: 'Salotos | Bonduelle',
      THEME_CAN: 'Konservuoti produktai | Bonduelle',
      THEME_FROZEN: 'Šaldyti produktai | Bonduelle',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: 'Daržovės | Bonduelle',
      THEME_RECIPE: 'Idėjos greitai ir lengvai paruošiamiems daržovių receptams',
      THEME_PLATE: 'Pagrindiniai patiekalai | Bonduelle',
      THEME_ACC: 'Garnyras | Bonduelle',
      THEME_SNACK: 'Užkandžiai kiekvienam | Bonduelle',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': '{{value}} - atraskite mūsų produktus'
    },
    DESCRIPTION: {
      MENU_ONE: 'Maisto atradimai, kurie ragaujant leidžia mums keliauti pasauliu. Atraskite naujus receptus.',
      MENU_TWO: 'Įdomybės',
      MENU_THREE: 'pagrindinis trečias apibūdinimas',
      MENU_FOUR: 'pagrindinis ketvirtas apibūdinimas',
      HOME: 'Viskas apie daržoves, mūsų produkciją bei naujoves, įdėjos pietums ir įvairūs atradimai. Šaldyti ir konservuoti produktai.',
      SEARCH: {
        MAIN: '{{value}}. Atraskite mūsų receptus bei daržoves. Įsikvėpkite gaminti ir kurti. Lengvai paruošiami ir skanūs receptai. Konervuotos ir šaldytos daržovės.',
        RECIPES: '{{value}}. Įsikvėpkite gaminti kartu su Bonduelle. Lengvi ir skanūs receptai visoms progoms. Konservuotos ir šaldytos daržovės.',
        VEGETABLES: '{{value}}. Atraskite įvairias daržoves ir mūsų produkciją. Kiekvienas ras savo mėgstamiausią. Šaldyti ir konservuoti produktai.',
        ARTICLES: '{{value}} paieškos rezultatai. Lengvai paruošiami receptai. Šaldyti ir konservuoti produktai.'
      },
      CONTACT: 'Ar turite klausimų? O gal turite kažkokių pastebėjimų ar pasiūlymų? Susisiekite su mumis. Šaldyti ir konservuoti produktai.',
      RECIPES: 'Atraskite įdomius receptus ir nustebinkite savo draugus ar giminaičius. Lengvi ir paprastai paruošiami receptai, tinkantys kiekvienai progai.',
      RECIPE_HOME: 'Lengvai paruošiami ir greitai pagaminami receptai Jūsų šventėms ar kasdieniams pietums. Šaldyti ir konservuoti produktai.',
      RECIPE: 'Atraskite įdomų receptą ir nustebinkite savo draugus ar giminaičius. Skanus ir paprastai paruošiamas receptas, skirtas visoms progoms.',
      PRODUCTS: 'Mūsų plačiame daržovių asortimente savo mėgstamą produktą atras kiekvienas. Atraskite visas daržovių galimybes. Šaldyti ir konservuoti produktai.',
      NEWS: 'Sužinokite, kaip auginti, laikyti bei paruošti daržoves. Visos naujienos ir atradimai. Šaldyti ir konservuoti produktai.',
      CORPORATE: {
        NEWS: 'Visos naujienos apie daržoves, jų auginimą bei paruošimą. Sužinokite mūsų įsipareigojimus, kompanijos istoriją ir dar daugiau.',
        COMPANY_STORY: 'Sužinokite mūsų istoriją. Mes palaikome ekologinę žemdirbystę ir stengiamės, kad daržovės taptų vis didesnė žmogaus gyvenimo dalis.',
        CSR_COMMITMENTS: 'Tvarumas - labai didelis Bonduelle prioritetas! Mes orientuojamės į ekologišką žemdirbystę bei įsipareigojame skatinti atsakingus valgymo įpročius.',
        VEGETAL_INNOVATIONS: 'Naujovės apie daržoves, kaip jas auginti, prižiūrėti, laikyti bei paruošti. Atraskite ir sužinokite. Šaldyti ir konservuoti produktai. ',
        VEGETABLE: '',
        FIELD_PLATE: 'Viskas apie daržoves - nuo žemės paruošimo, daržovių pasodinimo, priežiūros iki tinkamo paruošimo gaminant. Šaldyti ir konservuoti produktai.',
        ARTICLE: ''
      },
      PRODUCT: 'Sužinokite viską apie {{value}}. Bonduelle produktų asortimente kiekvienas atras savo mėgstamiausią. Šaldyti ir konservuoti produktai.',
      MAGAZINE: {
        NEWS: '',
        ARTICLE: '',
        COOK: '',
        GROW: '',
        EXPLORE: '',
        RECIPE: ''
      },
      FAQ: 'Visi atsakymai į dažniausiai užduodamus klausimus. Nerandi atsakymo į savo klausimą? Nesidrovėk ir parašyk mums! Šaldyti ir konservuoti produktai.',
      PAGE: 'puslapis',
      RECIPETYPE: '{{value}}. Atraskite receptus bei naujoviškus daržovių pagaminimo metodus. Rasite receptą kiekvienai progai. Šaldyti ir konservuoti produktai.',
      RECIPESEASON: '{{value}} - įsikvėpkite gaminti visais metų laikais. Atraskite naujus receptus ir daržovių paruošimo metodus. Šaldyti ir konservuoti produktai.',
      RECIPEPREPARATION: '{{value}} - atraskite receptus, įsikvėpkite gaminti. Daugiau nebesukite galvos ką pasigaminti pusryčiams ar vakarienei su draugais.',
      RECIPEDIFFICULTY: '{{value}} nebijokite eksperimentuoti ir išbandyti naujus receptus. Kiekvienas gali gaminti. Šaldyti ir konservuoti produktai.',
      RECIPEVEGETABLE: 'Pasigaminkite sveikai, greitai ir paprastai. Įsikvėpkite ir išmokite tinkamai paruošti daržoves. Šaldyti ir konservuoti produktai.',
      RECIPECOST: '{{value}}',
      RECIPEDISH: '{{value}} patiekalai kiekvienam ir visomis progomis. Įsikvėpkite gaminti ir išmokite tinkamai paruošti daržoves. Šaldyti ir konservuoti produktai.',
      RECIPEDESC: '{{value}} įsikvėpkite gaminti ir atraskite naujus skonius. Lengvi ir skanūs receptai, tinkantys kiekvienai progai. Šaldyti ir konservuoti produktai.',
      '2RECIPEFACETS': '{{value}} lengvi ir paprasti receptai kiekvienam ir visomis progomis. Šaldyti ir konservuoti produktai. ',
      '4RECIPEFACETS': '{{value}} receptai, kurie gali nustebinti visus. Paprastai pagaminami ir skanūs receptai visoms progoms. Šaldytos ir konservuotos daržovės.',
      PRODUCTSEASON: 'Platus mūsų daržovių asortimentas leidžia paruošti skanius, lengvai pagaminamus ir subalansuotus patiekalus {{value}}. Šaldyti ir konservuoti produktai.',
      PRODUCTTECHNO: '{{value}} - produktai, išskirti pagal jų paruošimo technologijas. Platus produktų asortimentas leidžia paruošti skanius, lengvai pagaminamus ir subalansuotus patiekalus.',
      PRODUCTVEGETABLE: '{{value}} - lengvai paruošiamos daržovės, skirtos valgyti kaip garnyrą ar įkomponuoti į ruošiamos patiekalus.',
      PRODUCTRANGE: '{{value}} - su šiomis daržovėmis paruošti patiekalą dar nebuvo taip paprasta! Platus produktų asortimentas leidžia paruošti skanius, lengvai pagaminamus ir subalansuotus patiekalus.',
      THEME_ALL: 'Mūsų platus daržovių asortimentas leidžia paruošti skanius ir subalansuotus receptus. Kiekvienas atras savo mėgstamą produktą. Konservuoti ir šaldyti produktai.',
      THEME_STARTER: 'Užkandžiai - lengvai pagaminami, skanūs ir tinkantys visoms progoms. Lengvi ir skanūs receptai. Šaldyti ir konservuoti produktai.',
      THEME_SALAD: 'Pagaminti salotas dar nebuvo taip paprasta su mūsų Bonduelle daržovių asortimentu. Lengvi ir skanūs receptai. Šaldyti ir konservuoti produktai.',
      THEME_CAN: 'Daržovės pasiekiamos visais metų laikais. Su šiomis daržovėmis gaminti dar nebuvo taip paprasta. Šaldyti ir konservuoti produktai.',
      THEME_FROZEN: 'Produktai, su kuriais gaminti dar nebuvo taip paprasta. Daržovės, pasiekiamos visais metų laikais. Šaldyti ir konservuoti produktai.',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: 'Mūsų platus daržovių asortimentas leidžia paruošti skanius, lengvus ir subalansuotus receptus. Šaldyti ir konservuoti produktai.',
      THEME_RECIPE: 'Sužinokite kaip greitai, skaniai ir lengvai paruošti daržoves. Įsikvėpkite gaminti ir kurti. Šaldyti ir konservuoti produktai. ',
      THEME_PLATE: 'Skanūs ir lengvai paruošiami pagrindiniai patiekalai visomis progomis. Įsikvėpkite gaminti ir kurti. Šaldyti ir konservuoti produktai.',
      THEME_ACC: 'Receptai garnyrų kūrybai. Lengvi ir paprastai paruošiami receptai kiekvienam. Įsikvėpkite gaminti ir kurti. Šaldyti ir konservuoti produktai.',
      THEME_SNACK: 'Greitai ir lengvai paruošiami užkandžiai visoms progoms. Įsikvėpkite gaminti ir kurti. Šaldyti ir konservuoti produktai.',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': 'Platus mūsų daržovių asortimentas leidžia paruošti skanius, lengvai paruošiamus ir subalansuotus receptus. {{value}} Įsikvėpkite gaminti ir kurti.',
      NOTFOUND: 'Deja, tačiau puslapis nerastas. Pabandykite naują paiešką arba bandykite dar kartą vėliau. Šaldyti ir konservuoti produktai.'
    },
    PIPE: { SOCIETY: ' | Bonduelle' },
    THEME_ALL: 'Visi',
    THEME_STARTER: 'Bonduelle užkandžiai',
    THEME_SALAD: 'Bonduelle salotos',
    THEME_CAN: 'Bonduelle konservuoti produktai',
    THEME_FROZEN: 'Bonduelle šaldyti produktai',
    THEME_READY_MEALS: '',
    THEME_BIO: '',
    THEME_VEGETABLES: 'Bonduelle daržovės',
    THEME_RECIPE: 'Ingredientai - receptai',
    THEME_PLATE: 'Bonduelle pagrindiniai patiekalai',
    THEME_ACC: 'Bonduelle garnyras',
    THEME_SNACK: 'Bonduelle užkandžiai kiekvienam'
  },
  VEGETABLE: {
    DISCOVER_VEGETABLE: 'Atrask mūsų daržoves',
    OURS_RECIPES: 'Mūsų receptai',
    DISCOVER_ALSO: 'Taip pat atrask...',
    SEE_ALL: 'Visos daržovės',
    ON: 'Daržovė'
  },
  RECIPE: {
    'INGREDIENTS_LABEL ': 'Ingredientai {{value}} porcijoms',
    PEOPLES: '{{value}} žmonėms',
    DIFFICULTY: {
      EASY: 'Lengvas',
      MEDIUM: 'Vidutinio sunkumo',
      HARD: 'Sudėtingas'
    },
    COST: { CHEAP: 'Pigus', EXPENSIVE: 'Brangus' },
    TIMING: { MIN: 'Minučių', HOURS: 'valandos' },
    STEP: 'Žingsnis',
    DISCOVER_ALSO: 'Taip pat atrask...',
    HOME_SEE_MORE: 'Daugiau receptų',
    SHOWCASE: 'Top',
    HOME_BOOK: 'Mūsų receptai',
    MIN_MORE: 'm ir +',
    HOUR_MORE: 'h ir +',
    FILTER_BY_CRITERIA: 'Filtruoti pagal',
    SORT_BY: 'Rūšiuoti pagal',
    BEST_RATED: 'Geriausiai įvertinti',
    MOST_CONSULTED: 'Daugiausiai žiūrėta',
    MEMBERS_RECIPES: 'Jūsų receptai',
    CRITERIA: 'Naujienos',
    DISCOVER: { RECIPES: 'Atrask visus mūsų receptus' },
    DISCOVER_RECIPE: 'Atrask receptą',
    INSTRUCTIONS: 'Instrukcijos',
    DEFAULT_TAG: 'Recepto idėja',
    MOST_RECENT: 'Naujausios',
    RECIPE_VIDEOS: 'Recepto video',
    PRODUCT_ASSOCIATE: 'Receptas pagamintas su',
    BUDGET_LOW: 'Pigus',
    BUDGET_HIGH: 'Brangus',
    BUDGET_MIDDLE: 'Vidutinis',
    PRINT_RECIPE: 'Atspausdinti',
    LET_YOURSELF_SURPRISED: 'Leisk save nustebinti',
    COOK_TIP_TITLE: 'Gaminimo patarimas',
    ENJOY_MEAL: 'Skanaus!',
    FILTER: { LEGEND: 'Apibūdinimas' }
  },
  PREDIGGO: {
    DIFFICULTY: {
      EASY: 'Lengvas',
      MEDIUM: 'Vidutinio sunkumo',
      HARD: 'Sudėtingas'
    }
  },
  COOKIES: {
    TEXT: 'Sklandžiam svetainės veikimui, jūsų naršymo patirties gerinimui bei rinkodarai naudojame slapukus. Naršydami toliau sutinkate su slapukų įrašymu.',
    CLOSE: 'Uždaryti',
    MORE: 'Sužinoti daugiau',
    AGREE_YOUTUBE: 'Norint pamatyti filmuką, turite sutikti su Youtube slapukais',
    BUTTON_YOUTUBE: 'Priimti slapukus'
  },
  POPUP: { LAUNCH: 'POPUP-OUVERTURE-SITE' },
  FIELD_PLATE: { DISCOVER_VEGETABLE: 'Daržovių enciklopedija' },
  SEARCH: {
    OUR_RECIPES: 'Receptai',
    OUR_PRODUCTS: 'Produktai',
    OUR_ARTICLES: 'Įrašai',
    RECIPES: 'Receptai',
    PRODUCTS: 'Produktai',
    ARTICLES: 'Įrašai',
    ALL: 'Visi',
    SORT_BY: 'Rūšiuoti pagal',
    RESULTS: 'Rezultatai',
    RESULT: 'Rezultatas',
    PRODUCT_OUT: 'Produktai iš',
    APPLY: 'Taikyti',
    FILTER: 'Filtras',
    RECIPE_OUT: 'Receptai iš',
    NO_RESULT: 'Ups.. Klausimas labai sudėtingas!',
    SEE_MORE: 'Žiūrėti daugiau',
    DISCOVER_RECIPES: 'Atrask mūsų receptus',
    SEE_MORE_OF: 'Žiūrėti daugiau',
    DELETE: 'Ištrinti'
  },
  RECIPE_LIST: {
    OUR_RECIPES: 'Receptai',
    RESULTS: 'Rezultatai',
    SEE_MORE: 'Žiūrėti daugiau receptų'
  },
  PRODUCT: {
    BUTTON: {
      ACTU: 'Produktų naujienos',
      FOUND: 'Kur rasti šį produktą',
      DISCOVER: 'atrask visą asortimentą'
    },
    NUTRITIONAL_VALUE: 'maistinė vertė',
    CONDITIONING: 'Kiekis',
    PORTIONS: 'porcijos',
    NET_WEIGHT: 'grynasis kiekis',
    DRY_WEIGHT: 'grynoji masė be skystos terpės',
    INGREDIENTS: 'sudedamosios medžiagos',
    OUR_RECIPES: 'Atrask mūsų receptus',
    FOR: '',
    PER_PORTION: 'ir porcijos iš',
    ENERGY: 'energinė vertė',
    FAT: 'riebalai',
    SATURATE: 'sočiosios riebalų rūgštys',
    GLUCIDES: 'angliavandeniai',
    FIBER: 'skaidulinės medžiagos',
    PROTEINS: 'baltymai',
    SALT: 'druska',
    DISCOVER_ALSO: 'taip pat atrask...',
    MORE_INFORMATION: 'daugiau informacijos',
    TRACES: 'Sudėtyje gali būti',
    CONTAINS: 'Sudėtyje yra',
    SLIDER: { SHEET: 'produkto aprašymas', BUY: 'pirkti šį produktą' },
    NUTRISCORE: 'maistingumo-informacija',
    TRIMAN: '',
    AGRICONFIANCE: '',
    BIO: 'informacija-bio',
    PESTICIDE_FREE: '',
    LEAF: '',
    LOCK: '',
    SEE_ALL: 'Žiūrėti daugiau produktų',
    LEGISLATION: 'pagal galiojančius teisės aktus',
    PORTION: { SINGULAR: 'porcija', PLURAL: 'porcijos' },
    DISCLAIMER: '',
    COOKING: 'paruošimo būdas',
    CONSERVATION: 'laikymo sąlygos'
  },
  FAQ: {
    ON: 'klausimai',
    ALL: 'Visi',
    SEE_MORE: 'žiūrėti daugiau klausimų',
    TITLE_1: 'Visi randami atsakymai į klausimus',
    TITLE_2: 'Ar turite klausimą apie produktą?',
    LINK_LABEL: 'Klausti'
  },
  FORM: {
    GO_TO_STEP: 'Eiti į',
    STEP: 'Žingsnis',
    RECEIVE_EMAIL_COPY: 'Gauti kopiją į el. paštą',
    SEND: 'Siųsti',
    GDPR: 'Daugiau apie jūsų duomenų tvarkymą',
    ERROR: 'Visi laukai pažymėti * turi būti užpildyti',
    SEND_ERROR: 'Klaida, pabandykite už kelių minučių',
    NOTICE: 'Susisiekdami su mumis jūs suteikiate savo asmens duomenis. Jūsų asmens duomenų valdytojas yra „Bonduelle Polska S.A.“, kurios būstinė registruota Varšuvoje, Lenkijoje. Daugiau informacijos apie savo asmens duomenų tvarkymą ir apsaugą rasite mūsų privatumo politikoje.',
    SENDING: '',
    LOADING: 'Kraunama...',
    PRODUCT_INFORMATION_TITLE: 'Informacija apie produktą',
    PRODUCT_INFORMATION: {
      SEO_TITLE: 'Informacija apie produktą | Bonduelle',
      SEO_DESC: 'Susisiekite su mumis'
    },
    INFORMATIONS: 'JŪSŲ INFORMACIJA',
    MISTER: 'Ponas',
    MISS: 'Ponia',
    NAME: 'Pavardė',
    OUR_NAME: 'Jūsų pavardė',
    FIRSTNAME: 'Vardas',
    OUR_FIRSTNAME: 'Jūsų vardas',
    MAIL: 'Jūsų el. paštas',
    MAIL_PLH: 'paštas@adresas.com',
    MAIL_CONFIRM: 'pakartokite el. pašto adresą',
    MAIL_ERROR: 'įrašykite galiojantį el. paštą',
    MAIL_ERROR_CONFIRM: 'patvirtinkite el. pašto adresą',
    ADDRESS: 'jūsų adresas',
    STREET_PLH: '',
    STREET: 'Gatvė ir namo numeris',
    ADDRESS_COMPLEMENT: 'Jūsų adresas',
    ADDRESS_COMP_PLH: 'Jūsų adresas',
    ZIPCODE: 'Pašto kodas',
    CITY: 'Miestas',
    CITY_PLH: 'Jūsų miestas',
    PHONE: 'Telefono numeris',
    PHONE_PLH: 'Telefono numeris',
    COUNTRY: 'šalis',
    PRODUCT_INFORMATIONS: 'PRODUKTO INFORMACIJA',
    PRODUCT_INFORMATION_DESC: 'Norėdami, kad išnagrinėtume jūsų komentarą, užpildykite šią informaciją:',
    PRODUCT: {
      DETAIL: {
        DESC: 'Pateikdami užklausą užpildykite atitinkamus laukus ir kaip pavyzdį pateikite nuotrauką. Nuotraukoje turi matytis EAN kodas, partijos numeris ir galiojimo laikas.'
      },
      NOTE: {
        TITLE: 'Komentaras apie produktą',
        SEO_TITLE: 'Produkto apžvalga | Bonduelle',
        SEO_DESC: 'Norite palikti pastabą ar komentarą apie mūsų produktą? Susisiekite su mūsų klientų aptarnavimo centru.'
      }
    },
    PRODUCT_RANGE: 'Nagrinėjamų produktų asortimentas',
    PRODUCT_NAME: 'Produkto pavadinimas',
    PRODUCT_NAME_PLH: 'Parašykite Bonduelle produkto, dėl kurio pateiktas prašymas, pavadinimą',
    PRODUCT_INFORMATIONS_DETAIL: 'IŠSAMI INFORMACIJA',
    PRODUCT_INFORMATIONS_LABEL: 'Produkto brūkšninis kodas',
    PRODUCT_INFORMATIONS_PLH: 'Brūkšninis kodas sudarytas iš 13 skaitmenų, randamų ant gaminio pakuotės.',
    PRODUCT_LOT: 'Produkto partijos numeris',
    PRODUCT_LOT_INFO: 'Partijos numeris prasideda raide L, po kurios eina skaitmenys, nurodyti ant pakuotės. Pavyzdžiui, L72450492107:13',
    PHOTO: 'Pridėkite nuotrauką',
    EXPIRED_DATE: 'Galiojimo laikas',
    EXPIRED_DATE_ERROR: 'Įveskite datą taip: DD/MM/MMMM',
    EXPIRED_DATE_DESC: 'Galiojimo laikas nurodytas ant pakuotės',
    PHOTO_MORE: 'Daugiau nuotraukų',
    MESSAGE: 'JŪSŲ ŽINUTĖ *',
    OUR_MESSAGE: 'Parašykite žinutę',
    SALES_OPERATIONS: {
      TITLE: 'Informacija apie pirkimą',
      SEO_TITLE: 'Informacija apie vykdomas akcijas | Bonduelle',
      SEO_DESC: 'Norėdami gauti informaciją apie pirkimą, susisiekite su mūsų klientų aptvarnavimo centru.'
    },
    PRESS_SERVICE: '',
    FOOD_SERVICE: 'Susisiekite su Bonduelle restoranų komanda',
    BUSINESS_INFORMATION: '',
    MODAL_INFORMATION: '',
    JOB_OFFER: { TITLE: '', SEO_TITLE: '', SEO_DESC: '' },
    WEB_SUGGESTION: {
      TITLE: 'Pateikite pasiūlymą apie internetinę svetainę',
      SEO_TITLE: 'Pasiūlymai tobulėti ar patarimai dėl svetainės | Bonduelle',
      SEO_DESC: 'Kreipkitės į mūsų klientų aptarnavimo centrą, kad padėtumėte mums tobulėti.'
    },
    ANOTHER: {
      TITLE: 'Pateikite pasiūlymą',
      SEO_TITLE: 'Kreipkitės dėl kitų pasiūlymų ar patarimų | Bonduelle',
      SEO_DESC: 'Norėdami gauti papildomą informaciją, patarimus ar pasiūlymus, susisiekite su mūsų klientų aptarnavimo centru.'
    },
    AMBASSADOR: '',
    CATEGORY: {
      SALAD: 'Salotos',
      FROZEN: 'Šaldyti',
      CAN: 'Konservuoti',
      TAKEAWAY: '',
      VEGETABLE: ''
    }
  },
  CONTACT: {
    TITLE: 'Jūsų paslaugoms',
    SPECIAL_INFO: '',
    TAKE_CARE: '',
    BY_MAIL: 'El. paštu',
    BY_PHONE: 'Telefonu',
    BY_SOURDLINE: '',
    MAIL_TEXT: 'Galite su mumis susisiekti el. paštu: baltics@bonduelle.com\n' +
      '\n' +
      'Susisiekdami su mumis jūs suteikiate savo asmens duomenis. Jūsų asmens duomenų valdytojas yra „Bonduelle Polska S.A.“, kurios būstinė registruota Varšuvoje, Lenkijoje. Daugiau informacijos apie savo asmens duomenų tvarkymą ir apsaugą rasite mūsų privatumo politikoje.',
    PHONE_TEXT: 'Informacija telefonu: +370 61170170\n' +
      'Mes atsiliepsime pirmadieniais-penktadieniais nuo 9 iki 16 valandos.',
    SOURDLINE_TEXT: '',
    BY_DISTRIBUTOR: '',
    BY_DISTRIBUTOR_TEXT: '',
    SUBTITLE: 'Galite su mumis susisiekti dėl:',
    AGREEMENT: '',
    READ_MORE: 'Be to, turite teisę bet kuriuo metu prašyti, kad BONDUELLE įgyvendintų jūsų teises susipažinti su savo Asmens\r\n' +
      'duomenimis, juos ištaisyti, ištrinti ir perkelti, taip pat teises apriboti savo Asmens duomenų tvarkymą ir\r\n' +
      'nesutikti su jų tvarkymu. Norėdami pasinaudoti savo teisėmis, susijusiomis su jūsų Asmens duomenimis,\r\n' +
      'rašykite Dpo_france(at)bonduelle(dot)com arba kreipkitės paštu šiuo adresu: Duomenų apsaugos pareigūnas,\r\n' +
      'Bonduelle, Rue Nicolas Appert, pašto dėžutės kodas 30173, F-59653 Villeneuve D&#39;Ascq. Norėdami sužinoti\r\n' +
      'daugiau apie tai, kaip BONDUELLE renka ir naudoja jūsų Asmens duomenis, spustelėkite čia\\',
    READ_MORE_LABEL: 'Taip pat skaitykite',
    SATISFACTION_TEXT: 'Siekdami susisiekti su jumis ir suteikti jums reikalingą informaciją apie\n' +
      'gaminį ir, jei to pageidaujate, atsiųsime pasitenkinimo klausimyną, mes,\n' +
      'kaip duomenų valdytojas, tvarkysime pateiktą informaciją. Jūsų prašymo\n' +
      'apdorojimo teisinis pagrindas yra sutikimas dėl galimų su sveikata\n' +
      'susijusių duomenų, kuriuos galite pateikti savo prašyme. Ši informacija\n' +
      'bus saugoma tiek, kiek reikia norint apdoroti jūsų užklausą, ir iki 5 metų,\n' +
      'kad būtų pateikti įrodymai, kad jūsų užklausa buvo apdorota, ir laikytis\n' +
      'mūsų teisinių įsipareigojimų. Teisinis pasitenkinimo klausimyno siuntimo\n' +
      'pagrindas taip pat yra jūsų sutikimas. Ši informacija bus saugoma tiek,\n' +
      'kiek reikia pasitenkinimo klausimynui išsiųsti, ir ne ilgiau kaip 1 metus.\n' +
      'Norėdami sužinoti daugiau apie savo asmens duomenų tvarkymą,\n' +
      'skaitykite mūsų <a href="https://www.bonduelle.lt/privatumo-politika" target="_blank" class="underline">Privatumo politiką</a>.',
    SATISFACTION_CHECKBOX: 'Pažymėdami šį langelį, sutinkate gauti pasitenkinimo klausimyną\n' +
      '(sudarytą iš vieno klausimo), kai jūsų užklausa bus apdorota. Tai\n' +
      'neprivaloma.',
    CHECKBOX_TEXT: 'Pažymėdami šį langelį, sutinkate, kad Bonduelle gali tvarkyti asmens\n' +
      'sveikatos duomenis, kuriuos galite pateikti savo žinutėje, vieninteliu tikslu\n' +
      'atsakyti į jūsų užklausą. <strong>Tai būtina norint apdoroti jūsų užklausą</strong>.',
    PHONE: 'Nuo pirmadienio iki penktadienio 9-16 valandomis atsiliepsime! Skambutis papildomai nekainuoja.',
    CALL_US: 'Paskambinti mums',
    TO_WRITE: 'Parašyti mums',
    PHONE_NUMBER: '37061170170',
    SUCCESS_MESSAGE: 'Ačiū! Jūsų žinutė išsiųsta mūsų komandai.',
    PHOTOERROR: { MESSAGE: '' }
  },
  GLOBAL: { READ_MORE: '' },
  FLOWBOX: { GENERIC_KEY: '', PRODUCT_KEY: '', RECIPE_KEY: '', HOME_KEY: '' },
  TOKEN: {
    FACEBOOK: '',
    PAGE_FACEBOOK_ID: '',
    INSTAGRAM: '',
    INSTA_ID: '17841405863491030',
    YOUTUBE: '',
    TWITTER: '',
    MAGAZINE: ''
  },
  COOKIE: {
    BANNER: {
      MANDATORY: 'Būtinieji',
      ANALYTICS: 'Funkciniai',
      MARKETING: 'Rinkodaros',
      SOCIAL_MEDIA: 'Socialiniai',
      ACCEPT: 'Patvirtinti visus slapukus',
      REJECT: 'Nesutikti',
      SET_UP: 'Keisti slapukų nustatymus',
      MANDATORY_DETAILS: 'Būtinieji slapukai yra būtini svetainės tinkamai veiklai. Šie slapukai naudojami statistinės analizės ir svetainės lankytojų srauto valdymo tikslais, pavyzdžiui, siekiant didinti interneto svetainės naudojimo patogumą ir savo paslaugų aktualumą, analizuoti informaciją apie tai, kaip lankytojai naudoja svetainę, pagal lankomus puslapius, lankytojų skaičių ir taip toliau. Taip pat, „Google Analytics“ naudoja šiuos slapukus, kurie leidžia stebėti lankomiausius puslapius ir svetainės srautą.',
      ANALYTICS_DETAILS: 'Funkciniai slapukai naudojami dėl techninių priežasčių, pavyzdžiui, siekiant identifikuoti seansus, įsiminti nuostatas, pomėgius, sekti Jūsų įvestis, kai pildote internetines formas ir panašiai. ',
      MARKETING_DETAILS: 'Rinkodaros slapukai leidžia mums stebėti Jūsų receptų ir produktų pageidavimus, kad galėtume pasiūlyti Jums geriausiai Jūsų norus atitinkančius produktus. Taip pat jie naudojami siekiant dalytis informacija su reklamdaviais kitose internetinėse svetainėse, kad būtų galima teikti Jums aktualius reklaminius pranešimus, kurie atitinka Jūsų interesus.\r\n',
      V2: 'ok'
    },
    MODAL: {
      TITLE: 'Slapukų nustatymai',
      TEXT: 'BONDUELLE grupė yra įsipareigojusi užtikrinti savo interneto svetainių, ypač „Bonduelle.lt“ naudotojų asmens duomenų apsaugą. Kad galėtumėte pasinaudoti svetainės siūlomomis paslaugomis, tokiomis kaip receptų bei produktų paieška, svetainėje naudojami slapukai. BONDUELLE taiko tinkamas technines ir organizacines priemones, padedančias efektyviai įgyvendinti asmens duomenų apsaugos principus, laikytis BDAR reikalavimų ir apsaugoti duomenų subjektų teises ir laisves nuo pat duomenų rinkimo pradžios ir duomenų tvarkymo metu. Turite teisę nesutikti su Jūsų asmens duomenų tvarkymu naudojant slapukus.',
      CONFIG: 'Keisti',
      SAVE: 'Išsaugoti'
    }
  },
  LINK: { JOB_OFFERS: '' },
  CLIC2BUY: { INFOS: '' }
};
