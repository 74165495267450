import { Component, OnDestroy, PLATFORM_ID, Injector } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { SfMessage } from '@app/sf-lib/public_api';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AppInjector } from './app-injector';
import { filter, distinctUntilChanged } from 'rxjs/operators';
import { TagManagerService } from './tag-manager/services/tag-manager.service';
import { SomPage } from './models/page.model';

/**
 * Defines all available display types.
 *
 * @export
 * @enum {number}
 */
export enum DisplayType {
  /**
   * Displays a popup
   */
  popup = 0,

  /**
   * Displays a toast
   */
  toast = 1
}

/**
 * Base component extended by other components of the app.
 * Provides methods to interact and handle common needs of an app's component.
 * @export
 * @class BaseComponent
 */
@Component({
    template: '',
    standalone: false
})
export class BaseComponent implements OnDestroy {
  /**
   * Title service
   * @protected
   * @memberof BaseComponent
   */
  protected title: Title;

  protected libMessage : BehaviorSubject<any> = new BehaviorSubject<any>(null);

  /**
   * Meta service
   * @protected
   * @memberof BaseComponent
   */
  protected meta: Meta;

  /**
   * Translate service
   * @protected
   * @memberof BaseComponent
   */
  protected translate: TranslateService;

  /**
   * Router service
   * @protected
   * @memberof BaseComponent
   */
  protected router: Router;

  protected route: ActivatedRoute;


  protected tagManager: TagManagerService;

  protected disableNewsletter = [
    'al',
    'at',
    'ba',
    'be',
    'bg',
    'cz',
    'de',
    'dk',
    'ee',
    'fi',
    'fr',
    'gr',
    'hr',
    'hu',
    'il',
    'it',
    'lt',
    'lv',
    'nl',
    'no',
    'pl',
    'ro',
    'rs',
    'se',
    'sk'
  ];

  /**
   * PlatformId
   * @protected
   * @memberof BaseComponent
   */
  protected platformId: Object;

  /**
   * Dispose bag. It's purpose is to contain all RxJs Subscription object
   * generated when subscribing to an Observable. When destroying the component
   * all Subscriptions are unsubscribed to prevent memory leak.
   * @example
   * ngOnInit() {
   *                  this.disposeBag.push(
   *                    this.service.observableProperty.subscribe(
   *                      () => { // Do stuff }
   *                    );
   *                  )
   * }
   * @protected
   * @type {Subscription[]}
   * @memberof BaseComponent
   */
  protected disposeBag: Subscription[];

  /** @ignore */
  constructor(componentInjector?: Injector) {
    // Fetch the main injector.
    try {
      const injector = componentInjector || AppInjector.getInjector();

      // injector is undefined at this point
      this.title = injector.get(Title);
      this.meta = injector.get(Meta);
      this.translate = injector.get(TranslateService);
      this.tagManager = injector.get(TagManagerService);
      this.router = injector.get(Router);
      this.route = injector.get(ActivatedRoute);
      this.platformId = injector.get(PLATFORM_ID);
    } catch (e) {
      console.error('Failed initializing dependencies', e);
    }
    this.disposeBag = [];

    this.disposeBag.push(
      this.router.events
        .pipe(
          filter(event => {
            return event instanceof NavigationEnd;
          }),
          distinctUntilChanged()
        )
        .subscribe(() => {
          // this.breadcrumbService.buildBreadCrumb(this.route.root);
        })
    );
  }

  /**
   * Set the page meta(s)
   * @protected
   * @param {SfPage} page the title of the page to set
   * @memberof BaseComponent
   */
  protected setPageMetas(page: SomPage, altTitle?: string, altDesc?: string) {
    if (page) {
      this.title.setTitle(page.meta_title || altTitle || 'Bonduelle');
    } else {
      this.title.setTitle(altTitle || 'Bonduelle');
    }
    if (page) {
      this.meta.updateTag({
        name: 'description',
        content: page.meta_description || altDesc || ''
      });
    } else {
      this.meta.updateTag({
        name: 'description',
        content: altDesc || ''
      });
    }
  }

  /**
   * Handle the message provided and displays it to the user.<br/>
   * If no DisplayType if provided a popup is showed by default.<br/>
   * See {@link DisplayType} for display types
   * @example
   * ngOnInit() {
   *                  this.service.getSomething.subscribe(
   *                    () =\> { },
   *                    (error: Error) =\> {
   *                      error.mRetryAction = () =\> { this.doStuff(); }
   *                      super.handleMessage(error, DisplayType.toast);
   *                    }
   *                  );
   *
   * }
   * @protected
   * @param {Message} message
   * @param {DisplayType} [sType]
   * @memberof BaseComponent
   */
  protected handleMessage(message: SfMessage, sType?: DisplayType) {
    switch (sType) {
      case DisplayType.toast:
        this.showToast(message);
        break;
      default:
        this.showPopup(message);
        break;
    }
  }

  /**
   * Shows a modal popup describing error.<br/>
   * Provides one or two actions to the user<br/>
   * @todo display popup
   * @private
   * @param {Message} message
   * @memberof BaseComponent
   */
  private showPopup(message: SfMessage) {
    // this.dialogService.open(ErrorDialogComponent, {
    //   data: {error: sError, header: this.translate.instant('DIALOG.ERROR')}
    // })
    // .afterClosed().subscribe(
    //   result => {
    //     if (result) {
    //       if (sError.retryAction) {
    //         sError.retryAction();
    //       } else if (sError.okAction) {
    //         sError.okAction();
    //       }
    //     } else {
    //       if (sError.okAction) {
    //         sError.okAction();
    //       }
    //     }
    //   }
    // );
  }

  /**
   * Shows a non-blocking toast at the bottom of the screen.<br/>
   * May provide retry action to the user.
   * @todo display toast
   * @private
   * @param {Message} message
   * @memberof BaseComponent
   */
  private showToast(message: SfMessage) {
    // if (message instanceof Error && message.shouldRetryOnFailure) {
    //   this.snackBar.open(
    //     sError.message,
    //     sError.okLabel || this.translate.instant('DIALOG.RETRY'),
    //     this.TOAST_CONFIG).onAction().subscribe(
    //     () => {
    //       sError.retryAction();
    //     }
    //   );
    // } else {
    //   this.snackBar.open(sError.message, null, this.TOAST_CONFIG);
    // }
  }

  // LIFECYCLE HOOKS

  /**
   * Lifecycle Hook responsible for unsubscribing from every component
   * filling the {@link this.disposeBag}.
   * @memberof BaseComponent
   */
  ngOnDestroy() {
    this.disposeBag.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

}
