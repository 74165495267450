/* tslint:disable:max-line-length */

export const site: any[] = [
  {
    lang: {
      country: 'ba',
      code: 'bs',
      default: true,
    },
    has_account: false,
    pictos_product: [],
    menu_items: [{
      displayed_text: 'RECEPTI',
      link: {
        url: '/recepti',
      },
      children: [
        {
          displayed_text: 'SVI RECEPTI',
          link: {
            url: '/recepti/svi-recepti',
          },
        },
        {
          displayed_text: 'PREDJELA I GRICKALICE',
          link: {
            url: '/recepti/predjela-i-grickalice',
          },
        },
        {
          displayed_text: 'SUPE I BORŠT',
          link: {
            url: '/recepti/supe-i-borst',
          },
        },
        {
          displayed_text: 'GLAVNO JELO',
          link: {
            url: '/recepti/glavno-jelo',
          },
        },
        {
          displayed_text: 'SALATE',
          link: {
            url: '/recepti/salate',
          },
        },
        {
          displayed_text: 'DESERTI',
          link: {
            url: '/recepti/deserti',
          },
        },
      ],
    },
      {
        displayed_text: 'PROIZVODI',
        link: {
          url: '/povrce',
        },
        children: [
          {
            displayed_text: 'SVI PROIZVODI',
            link: {
              url: '/povrce/svi-proizvodi',
            },
          },
          {
            displayed_text: 'NOVI PROIZVODI',
            link: {
              url: '/povrce/proizvod-vijesti',
            },
          },
          {
            displayed_text: 'KONZERVIRANI PROIZVODI',
            link: {
              url: '/povrce/konzervirani-proizvodi',
            },
          },
          {
            displayed_text: 'ZAMRZNUTI PROIZVODI',
            link: {
              url: '/povrce/zamrznuti-proizvodi',
            },
          },
        ],
      },
      {
        displayed_text: 'O NAMA',
        link: {
          url: '/od-1853/vijesti',
        },
        children: [
          {
            displayed_text: 'NAŠA PRIČA',
            link: {
              url: '/od-1853/kompaniji-o-kompaniji',
            },
          },
          {
            displayed_text: 'NAŠE OBAVEZE',
            link: {
              url: '/od-1853/dop-obaveze',
            },
          },
          {
            displayed_text: 'VIJESTI & DOGAĐAJI',
            link: {
              url: '/od-1853/biljne-inovacije',
            },
          },
          {
            displayed_text: 'OD POLJA DO TANJIRA',
            link: {
              url: '/inspiracija/clanak/od-polja-do-ploca/5583/5583',
            },
          },
          {
            displayed_text: 'PROMOCIJE I KONKURSI',
            link: {
              url: '/oferti',
            },
          },
        ],
      },
      {
        displayed_text: 'INSPIRACIJA',
        link: {
          url: '/inspiracija',
        },
        children: [
          {
            displayed_text: 'ŽIVITE ZDRAVO',
            link: {
              url: '/inspiracija/zivi-zdravo',
            },
          },
          {
            displayed_text: 'UKUSNO KUHANJE',
            link: {
              url: '/inspiracija/ukusno-se-kuva',
            },
          },
          {
            displayed_text: 'PRIPREMITE SE SA STILOM',
            link: {
              url: '/inspiracija/kuhaj-sa-stilom',
            },
          },
          {
            displayed_text: 'BUDI KREATIVAN',
            link: {
              url: '/inspiracija/budi-kreativan',
            },
          },
          {
            displayed_text: 'ENCIKLOPEDIJA POVRĆA',
            link: {
              url: '/od-1853/polje-tanjur',
            },
          },
        ],
      },
    ],
    footer_items: [
      {
        displayed_text: 'O NAMA',
        children: [
          {
            displayed_text: 'Grupa Bonduelle',
            link: {
              url: 'https://www.bonduelle.com/en/',
              is_external: true,
            },
          },
          {
            displayed_text: 'Fondacija Louis Bonduelle',
            link: {
              url: 'http://www.fondation-louisbonduelle.org/',
              is_external: true,
            },
          },
        ],
      },
      {
        displayed_text: 'Služba za korisnike',
        children: [
          {
            displayed_text: 'Kontaktirajte nas',
            link: {
              url: '/kontakt',
            },
          },
          {
            displayed_text: 'FAQ',
            link: {
              url: '/cesto-postavljana-pitanja',
            },
          },
        ],
      },
    ],
    sub_footer_items: [
      {
        translation_key: 'FOOTER.LINK.LEGAL_NOTICE',
        link: {
          url: '/Pravna-obavijest',
        },
      },
      {
        translation_key: 'FOOTER.LINK.PRIVACY_POLICY',
        link: {
          url: '/Politika-privatnosti',
        },
      },
      {
        translation_key: 'FOOTER.LINK.ALL_RIGHTS_RESERVED',
      },
    ],
    share_buttons: [
      {
        name: 'youtube',
        url: 'https://www.youtube.com/BonduelleBosnaiHerzegovina',
      },
    ],
    default_videos: [],
    theme: 'default-theme',
    catch_phrase: 'HOME.CATCH_PHRASE',
    published: true,
    packshot: false,
  },
  {
    lang: {
      country: 'bg',
      code: 'bg',
      default: true,
    },
    has_account: false,
    pictos_product: [],
    menu_items: [{
      displayed_text: 'РЕЦЕПТИ',
      link: {
        url: '/retsepti',
      },
      children: [
        {
          displayed_text: 'Всички рецепти',
          link: {
            url: '/retsepti/vsiciki-reterti', 
          },
        },
        {
          displayed_text: 'предястия и закуски',
          link: {
            url: '/retsepti/preziatiia-i-zakuski',
          },
        },
        {
          displayed_text: 'СУПИ',
          link: {
            url: '/retsepti/supi',
          },
        },
        {
          displayed_text: 'основни ястия',
          link: {
            url: '/retsepti/osnovni-vidove',
          },
        },
        {
          displayed_text: 'САЛАТИ',
          link: {
            url: '/retsepti/salati',
          },
        },
        {
          displayed_text: 'ДЕСЕРТИ',
          link: {
            url: '/retsepti/deserti',
          },
        },
      ],
    },
      {
        displayed_text: 'ПРОДУКТИ',
        link: {
          url: '/zelenchutsi',
        },
        children: [
          {
            displayed_text: 'Всички продукти',
            link: {
              url: '/zelenchutsi/vsichki-produkti',
            },
          },
          {
            displayed_text: 'НОВИ-ПРОДУКТИ',
            link: {
              url: '/zelenchutsi/novini-za-produkti',
            },
          },
          {
            displayed_text: 'КОНСЕРВИРАНИ ПРОДУКТИ',
            link: {
              url: '/zelenchutsi/konservirani-produkti',
            },
          },
          {
            displayed_text: 'ЗАМРАЗЕНИ ПРОДУКТИ',
            link: {
              url: '/zelenchutsi/zamrazeni-produkti',
            },
          },
        ],
      },
      {
        displayed_text: 'ЗА НАС',
        link: {
          url: '/ot-1853-nasam/novini',
        },
        children: [
          {
            displayed_text: 'НАШАТА ИСТОРИЯ',
            link: {
              url: '/ot-1853-nasam/istoriia-na-kompaniiata',
            },
          },
          {
            displayed_text: 'НАШИТЕ АНГАЖИМЕНТИ',
            link: {
              url: '/ot-1853-nasam/korporativna-sotsialna-otgovornost',
            },
          },
          {
            displayed_text: 'ОТ ПОЛЕТО ДО ПЛОЩАТА',
            link: {
              url: '/vdiekhnovenie/statiia/ot-poleto-do-chiniyata/5547/5547',
            },
          },
          {
            displayed_text: 'НОВИНИ И СЪБИТИЯ',
            link: {
              url: '/ot-1853-nasam/zelenchukovi-inovatsii',
            }, 
          },
          {
            displayed_text: 'ПРОМОЦИИ И СЪСТЕЗАНИЯ',
            link: {
              url: '/promotsii',
            },
          },
        ],
      },
      {
        displayed_text: 'ВДЪХНОВЕНИЯ',
        link: {
          url: '/vdiekhnovenie',
        },
        children: [
          {
            displayed_text: 'ЖИВЕЙТЕ ЗДРАВОСЛОВНО',
            link: {
              url: '/vdiekhnovenie/zhiveite-zdravi',
            },
          },
          {
            displayed_text: 'ГОТВЕТЕ ВКУСНО',
            link: {
              url: '/vdiekhnovenie/gotvi-se-vkusno',
            },
          },
          {
            displayed_text: 'ПРИГОТВЯЙТЕ СЪС СТИЛ',
            link: {
              url: '/vdiekhnovenie/gotvi-sies-stil',
            },
          },
          {
            displayed_text: 'БЪДЕТЕ КРЕАТИВНИ',
            link: {
              url: '/vdiekhnovenie/biedi-kreativen',
            },
          },
          {
            displayed_text: 'ЗЕЛЕНЧУКОВА ЕНЦИКЛОПЕДИЯ',
            link: {
              url: '/ot-1853-nasam/ot-poleto-do-chiniiata',
            },
          },
        ],
      },
    ],
    footer_items: [
      {
        displayed_text: 'ЗА НАС',
        children: [
          {
            displayed_text: 'БОНДЮЕЛ ГРУП',
            link: {
              url: 'https://www.bonduelle.com/en/',
              is_external: true,
            },
          },
          {
            displayed_text: 'ФОНДАЦИЯ LOUIS BONDUELLE',
            link: {
              url: 'http://www.fondation-louisbonduelle.org/',
              is_external: true,
            },
          },
        ],
      },
      {
        displayed_text: 'Поддръжка на клиенти',
        children: [
          {
            displayed_text: 'Свържете се с нас',
            link: {
              url: '/kontakti',
            },
          },
          {
            displayed_text: 'FAQ',
            link: {
              url: '/chesto-zadavani-vieprosi',
            },
          },
        ],
      },
    ],
    sub_footer_items: [
      {
        translation_key: 'FOOTER.LINK.LEGAL_NOTICE',
        link: {
          url: '/pravna-informaciya',
        },
      },
      {
        translation_key: 'FOOTER.LINK.PRIVACY_POLICY',
        link: {
          url: '/politika-za-poveritelnost',
        },
      },
      {
        translation_key: 'FOOTER.LINK.ALL_RIGHTS_RESERVED',
      },
    ],

    share_buttons: [
      {
        name: 'facebook',
        url: 'https://www.facebook.com/bonduelle.bg',
      },
      {
        name: 'instagram',
        url: 'https://www.instagram.com/bonduelle.bg/',
      },
      {
        name: 'youtube',
        url: 'https://www.youtube.com/user/BonduelleBG',
      }
    ],
    default_videos: [],
    theme: 'gr-theme',
    catch_phrase: 'HOME.CATCH_PHRASE',
    published: true,
    packshot: false,
  },
  {
    lang: {
      country: 'fr',
      code: 'fr',
      default: true,
    },
    has_account: false,
    pictos_product: [],
    menu_items: [{
        displayed_text: 'Recettes',
        link: {
          url: '/recettes',
        },
        children: [{
            displayed_text: 'Recettes à base de chou et chou-fleur',
            link: {
              url: '/recette/base-de-chou-fleur',
            },
          },
          {
            displayed_text: 'Recettes à base de pomme de terre',
            link: {
              url: '/recette/base-de-pommes-de-terre',
            },
          },
          {
            displayed_text: 'Recettes à base de carottes',
            link: {
              url: '/recette/base-de-carottes',
            },
          },
          {
            displayed_text: 'Recettes à base d\'épinards',
            link: {
              url: '/recette/base-d-epinards',
            },
          },
          {
            displayed_text: 'Recettes à base de poivrons',
            link: {
              url: '/recette/base-de-poivrons',
            },
          },
          {
            displayed_text: 'Recettes à base de champignons',
            link: {
              url: '/recette/base-de-champignons',
            },
          },
          {
            displayed_text: 'Recettes à base d\'haricots verts',
            link: {
              url: '/recette/base-de-haricots-verts-plats',
            },
          },
          {
            displayed_text: 'Recettes à base de mais',
            link: {
              url: '/recette/base-de-mais',
            },
          },
          {
            displayed_text: 'Toutes nos recettes',
            link: {
              url: '/recette',
            },
          },
        ],
      },
      {
        displayed_text: 'Nos légumes',
        link: {
          url: '/legumes',
        },
        children: [{
            displayed_text: 'Nouveautés',
            link: {
              url: '/legumes/nouveautes',
            },
          },
          {
            displayed_text: 'Entrées',
            link: {
              url: '/legumes/entrees',
            },
          },
          {
            displayed_text: 'Tout pour mes salades',
            link: {
              url: '/legumes/tout-pour-mes-salades',
            },
          },
          {
            displayed_text: 'Plat principal',
            link: {
              url: '/legumes/plat-principal',
            },
          },
          {
            displayed_text: 'Ingrédients pour mes recettes',
            link: {
              url: '/legumes/ingredients-recettes',
            },
          },
          {
            displayed_text: 'Accompagnements',
            link: {
              url: '/legumes/accompagnements',
            },
          },
          {
            displayed_text: 'Snacking',
            link: {
              url: '/legumes/snacking',
            },
          },
          {
            displayed_text: 'Origine des légumes',
            link: {
              url: '/faq/nos-legumes/origine-info/467',
            },
          }
        ],
      },
      
      {
        displayed_text: 'Depuis 1853',
        link: {
          url: '/depuis-1853/actualites',
        },
        children: [{
            displayed_text: 'Toutes nos actus',
            link: {
              url: '/depuis-1853/actualites',
            },
          },
          {
            displayed_text: 'Notre histoire',
            link: {
              url: '/depuis-1853/notre-histoire',
            },
          },
          {
            displayed_text: 'Nos engagements',
            link: {
              url: '/depuis-1853/nos-engagements',
            },
          },
          {
            displayed_text: 'Innovation végétale',
            link: {
              url: '/depuis-1853/innovation-vegetale',
            },
          },
          {
            displayed_text: 'Du champ à l\'assiette',
            link: {
              url: '/depuis-1853/du-champ-a-l-assiette',
            },
          },
        ],
      },

      {
        displayed_text: 'Questions \n / réponses',
        link: {
          url: '/faq',
        },
        children: [],
      },

      // {
      //    displayed_text: 'Magazine',
      //   link: {
      //     url: '/legumiz/actualites',
      //   },
      // },
    ],
    footer_items: [{
        displayed_text: 'À propos',
        children: [{
            displayed_text: 'Le groupe',
            link: {
              url: 'https://www.bonduelle.com/fr/',
              is_external: true,
            },
          },
          {
            displayed_text: 'Nos engagements',
            link: {
              url: 'https://engagements.bonduelle.fr',
              is_external: true,
            },
          },
          {
            displayed_text: 'Origine des légumes',
            link: {
              url: '/faq/nos-legumes/origine-info/467',
            },
          },
          {
            displayed_text: 'Fondation Bonduelle',
            link: {
              url: 'http://www.fondation-louisbonduelle.org/',
              is_external: true,
            },
          },
          {
            displayed_text: 'Bonduelle Food Service',
            link: {
              url: 'https://www.bonduelle-foodservice.fr/',
              is_external: true,
            },
          },
          {
            displayed_text: 'Bonduelle la boutique',
            link: {
              url: 'https://www.bonduelle.boutique/',
              is_external: true,
            },
          },
          {
            displayed_text: 'Nous rejoindre',
            link: {
              url: 'https://jobs.bonduelle.com/?locale=fr_FR',
              is_external: true,
            },
          },
        ],
      },
      {
        displayed_text: 'Service consommateurs',
        children: [{
            displayed_text: 'Nous contacter',
            link: {
              url: '/nous-contacter',
            },
          },
          {
            displayed_text: 'Questions/Réponses',
            link: {
              url: '/faq',
            },
          },
          {
            displayed_text: 'Sourds et Malentendants',
            link: {
              url: 'https://bonduelle.sourdline.com/',
              is_external: true,
            },
          },
        ],
      },
    ],
    sub_footer_items: [{
        translation_key: 'FOOTER.LINK.LEGAL_NOTICE',
        link: {
          url: '/mentions-legales',
        },
      },
      {
        translation_key: 'FOOTER.LINK.CREDITS',
        link: {
          url: '/credits',
        },
      },
      {
        translation_key: 'FOOTER.LINK.SITEMAP',
        link: {
          url: '/sitemap-fr',
        },
      },
      {
        translation_key: 'FOOTER.LINK.PRIVACY_POLICY',
        link: {
          url: '/politique-confidentialite',
        },
      },
      {
        translation_key: 'FOOTER.LINK.ALL_RIGHTS_RESERVED',
      },
      {
        displayed_text: 'Fiche produit relative aux qualites et caracteristiques environnementales',
        link: {
          url: '/fiche-produit-relative-aux-qualites-et-caracteristiques-environnementales',
        },
      },
    ],
    share_buttons: [{
        name: 'facebook',
        url: 'https://www.facebook.com/bonduelle/',
      },
      {
        name: 'instagram',
        url: 'https://www.instagram.com/bonduelle_fr/?hl=fr',
      },
      {
        name: 'youtube',
        url: 'https://www.youtube.com/user/LaMarqueBonduelle',
      },
      {
        name: 'pinterest',
        url: 'https://www.pinterest.fr/bonduellefr/',
      },
    ],
    default_videos: [],
    theme: 'default-theme',
    catch_phrase: 'HOME.CATCH_PHRASE',
    published: true,
    packshot: false,
  },
  {
    lang: {
      country: 'hr',
      code: 'hr',
      default: true,
    },
    has_account: false,
    pictos_product: [],
    menu_items: [{
      displayed_text: 'RECEPTI',
      link: {
        url: '/recepti',
      },
      children: [
        {
          displayed_text: 'SVI RECEPTI',
          link: {
            url: '/recepti/svi-recepti',
          },
        },
        {
          displayed_text: 'PREDJELA I GRICKALICE',
          link: {
            url: '/recepti/predjela-i-grickalice',
          },
        },
        {
          displayed_text: 'JUHE I BORŠČ',
          link: {
            url: '/recepti/juhe-i-borsc',
          },
        },
        {
          displayed_text: 'GLAVNI TEČAJ',
          link: {
            url: '/recepti/glavni-tecaj',
          },
        },
        {
          displayed_text: 'SALATE',
          link: {
            url: '/recepti/salate',
          },
        },
        {
          displayed_text: 'DESERTI',
          link: {
            url: '/recepti/deserti',
          },
        },
      ],
    },
      {
        displayed_text: 'PROIZVODI',
        link: {
          url: '/povrce',
        },
        children: [
          {
            displayed_text: 'SVI PROIZVODI',
            link: {
              url: '/povrce/svi-proizvodi',
            },
          },
          {
            displayed_text: 'NOVI PROIZVODI',
            link: {
              url: '/povrce/proizvod-vijesti',
            },
          },
          {
            displayed_text: 'KONZERVIRANI PROIZVODI',
            link: {
              url: '/povrce/konzervirani-proizvodi',
            },
          },
          {
            displayed_text: 'ZAMRZNUTI PROIZVODI',
            link: {
              url: '/povrce/zamrznuti-proizvodi',
            },
          },
        ],
      },
      {
        displayed_text: 'O NAMA',
        link: {
          url: '/od-1853/vijesti',
        },
        children: [
          {
            displayed_text: 'NAŠA PRIČA',
            link: {
              url: '/od-1853/prica-o-tvrtki',
            },
          },
          {
            displayed_text: 'NAŠE OBVEZE',
            link: {
              url: '/od-1853/dop-obveze',
            },
          },
          {
            displayed_text: 'VIJESTI & DOGAĐAJI',
            link: {
              url: '/od-1853/biljne-inovacije',
            },
          },
          {
            displayed_text: 'OD POLJA DO PLOČA',
            link: {
              url: '/inspiracija/clanak//od-polja-do-ploca/5576/5576',
            },
          },
          {
            displayed_text: 'PROMOCIJE I NATJECANJA',
            link: {
              url: '/dobre-ponude',
            },
          },
        ],
      },
      {
        displayed_text: 'INSPIRACIJA',
        link: {
          url: '/inspiracija',
        },
        children: [
          {
            displayed_text: 'ŽIVJETI ZDRAVO',
            link: {
              url: '/inspiracija/zivi-zdravo',
            },
          },
          {
            displayed_text: 'KUHAJTE UKUSNO',
            link: {
              url: '/inspiracija/ukusno-se-kuva',
            },
          },
          {
            displayed_text: 'PRIPREMITE SE SA STILOM',
            link: {
              url: '/inspiracija/kuhaj-sa-stilom',
            },
          },
          {
            displayed_text: 'BUDITE KREATIVNI',
            link: {
              url: '/inspiracija/budi-kreativan',
            },
          },
          {
            displayed_text: 'ENCIKLOPEDIJA POVRĆA',
            link: {
              url: '/od-1853/polje-tanjur',
            },
          },
        ],
      },
    ],
    footer_items: [
      {
        displayed_text: 'O NAMA',
        children: [
          {
            displayed_text: 'Grupa Bonduelle',
            link: {
              url: 'https://www.bonduelle.com/en/',
              is_external: true,
            },
          },
          {
            displayed_text: 'Fondacija Louis Bonduelle',
            link: {
              url: 'http://www.fondation-louisbonduelle.org/',
              is_external: true,
            },
          },
        ],
      },
      {
        displayed_text: 'Služba za korisnike',
        children: [
          {
            displayed_text: 'Kontaktirajte nas',
            link: {
              url: '/kontakt',
            },
          },
          {
            displayed_text: 'FAQ',
            link: {
              url: '/cesto-postavljana-pitanja',
            },
          },
        ],
      },
    ],
    sub_footer_items: [
      {
        translation_key: 'FOOTER.LINK.LEGAL_NOTICE',
        link: {
          url: '/pravna-obavijest',
        },
      },
      {
        translation_key: 'FOOTER.LINK.PRIVACY_POLICY',
        link: {
          url: '/politika-privatnosti',
        },
      },
      {
        translation_key: 'FOOTER.LINK.ALL_RIGHTS_RESERVED',
      },
    ],
    share_buttons: [
      {
        name: 'facebook',
        url: 'https://www.facebook.com/bonduelle.hr',
      },
      {
        name: 'instagram',
        url: 'https://www.instagram.com/bonduelle.hr/',
      },
      {
        name: 'youtube',
        url: 'https://www.youtube.com/c/BonduelleHrvatska',
      }
    ],
    default_videos: [],
    theme: 'default-theme',
    catch_phrase: 'HOME.CATCH_PHRASE',
    published: true,
    packshot: false,
  },  {
    lang: {
      country: 'be',
      code: 'nl',
      default: false,
    },
    has_account: false,
    pictos_product: [],
    menu_items: [{
        displayed_text: 'Recepten',
        link: {
          url: '/nl/recepten',
        },
        children: [
          {
            displayed_text: 'Salades',
            link: {
              url: '/nl/recept/salades',
            },
          },
          {
            displayed_text: 'Mexicaans',
            link: {
              url: '/nl/recept/mexicaans',
            },
          },
          {
            displayed_text: 'De wereld rond',
            link: {
              url: '/nl/recept/de-wereld-rond',
            },
          },
          {
            displayed_text: 'Dagje zonder vlees',
            link: {
              url: '/nl/recept/dagje-zonder-vlees',
            },
          },
          {
            displayed_text: 'Soepen',
            link: {
              url: '/nl/recept/soepen',
            },
          },
          {
            displayed_text: 'Bowls',
            link: {
              url: '/nl/recept/bowls',
            },
          }
        ],
      },
      {
        displayed_text: 'Producten',
        link: {
          url: '/nl/producten',
        },
        children: [
          {
            displayed_text: 'Mais',
            link: {
              url: '/nl/producten/mais',
            },
          },
          {
            displayed_text: 'Peulvruchten',
            link: {
              url: '/nl/producten/peulvruchten',
            },
          },
          {
            displayed_text: 'Groenten',
            link: {
              url: '/nl/producten/groenten',
            },
          }
        ],
      },
      {
        displayed_text: 'Over Bonduelle',
        link: {
          url: '/nl/over-ons/nieuws',
        },
        children: [{
            displayed_text: 'Alle activiteiten',
            link: {
              url: '/nl/over-ons/nieuws',
            },
          },
          {
            displayed_text: 'Geschiedenis',
            link: {
              url: '/nl/over-ons/geschiedenis',
            },
          },
          {
            displayed_text: 'MVO',
            link: {
              url: '/nl/over-ons/mvo',
            },
          },
          {
            displayed_text: 'Innovatie',
            link: {
              url: '/nl/over-ons/innovatie',
            },
          },
          {
            displayed_text: 'FAQ',
            link: {
              url: '/nl/faq',
            },
          }
        ],
      },
      {
        displayed_text: 'Voor een beter morgen',
        link: {
          url: '/nl/voor-een-beter-morgen',
        },
        children: [
          {
            displayed_text: 'Plantaardige inspiratie',
            link: {
              url: '/nl/voor-een-beter-morgen/plantaardige-inspiratie',
            },
          },
          {
            displayed_text: 'Recyclebare verpakkingen',
            link: {
              url: '/nl/voor-een-beter-morgen/recyclebare-verpakkingen',
            },
          },
          {
            displayed_text: 'Biodiversiteit en bodem',
            link: {
              url: '/nl/voor-een-beter-morgen/biodiversiteit-en-bodem',
            },
          },
          {
            displayed_text: 'Voedselverspilling',
            link: {
              url: '/nl/voor-een-beter-morgen/voedselverspilling',
            },
          }
        ],
      },
    ],
    footer_items: [{
        displayed_text: 'Over ons',
        children: [{
            displayed_text: 'De Bonduelle groep',
            link: {
              url: 'https://www.bonduelle.com/en',
              is_external: true,
            },
          },
          {
            displayed_text: 'Werken bij',
            link: {
              url: 'https://jobs.bonduelle.com/?locale=nl_NL',
              is_external: true,
            },
          },
          {
            displayed_text: 'Bonduelle Food Service',
            link: {
              url: 'https://www.bonduelle-foodservice.nl',
              is_external: true,
            },
          },
        ],
      },
      {
        displayed_text: 'Klantenservice',
        children: [{
          displayed_text: 'Neem contact met ons op',
          link: {
            url: '/nl/neem-contact-met-ons-op',
          },
        },
        {
          displayed_text: 'Veelgestelde vragen',
          link: {
            url: '/nl/faq',
          },
        }
       ],
      },
    ],
    sub_footer_items: [{
        translation_key: 'FOOTER.LINK.LEGAL_NOTICE',
        link: {
          url: '/nl/gebruiksvoorwaarden',
        },
      },
      {
        translation_key: 'FOOTER.LINK.PRIVACY_POLICY',
        link: {
          url: '/nl/privacybeleid',
        },
      },
      {
        translation_key: 'FOOTER.LINK.ALL_RIGHTS_RESERVED',
      },
    ],
    share_buttons: [{
        name: 'facebook',
        url: 'https://www.facebook.com/BonduelleBelgie',
      },
      {
        name: 'instagram',
        url: 'https://www.instagram.com/bonduelle_be',
      },
    ],
    default_videos: [],
    theme: 'be-theme',
    catch_phrase: 'HOME.CATCH_PHRASE',
    published: true,
    packshot: true,
  },
  {
    lang: {
      country: 'be',
      code: 'fr',
      default: false,
    },
    has_account: false,
    pictos_product: [],
    menu_items: [{
        displayed_text: 'Recettes',
        link: {
          url: '/fr/recettes',
        },
        children: [
          {
            displayed_text: 'Salades',
            link: {
              url: '/fr/recette/salades',
            },
          },
          {
            displayed_text: 'Mexicain',
            link: {
              url: '/fr/recette/mexicain',
            },
          },
          {
            displayed_text: 'Le tour du monde',
            link: {
              url: '/fr/recette/le-tour-du-monde',
            },
          },
          {
            displayed_text: 'Une journée sans viande',
            link: {
              url: '/fr/recette/une-journee-sans-viande',
            },
          },
          {
            displayed_text: 'Soupes',
            link: {
              url: '/fr/recette/soupes',
            },
          },
          {
            displayed_text: 'Bowls',
            link: {
              url: '/fr/recette/bols',
            },
          }
        ],
      },
      {
        displayed_text: 'Nos légumes',
        link: {
          url: '/fr/legumes',
        },
        children: [
          {
            displayed_text: 'Le maïs',
            link: {
              url: '/fr/legumes/mais',
            },
          },
          {
            displayed_text: 'Les légumineuses',
            link: {
              url: '/fr/legumes/legumineuses',
            },
          },
          {
            displayed_text: 'Légumes',
            link: {
              url: '/fr/legumes/legumes',
            },
          }
        ],
      },
      {
        displayed_text: 'À propos',
        link: {
          url: '/fr/a-propos/actualites',
        },
        children: [{
            displayed_text: 'Toutes nos activités',
            link: {
              url: '/fr/a-propos/actualites',
            },
          },
          {
            displayed_text: 'Notre histoire',
            link: {
              url: '/fr/a-propos/notre-histoire',
            },
          },
          {
            displayed_text: 'Nos engagements RSE',
            link: {
              url: '/fr/a-propos/nos-engagements-rse',
            },
          },
          {
            displayed_text: 'Innovations végétales',
            link: {
              url: '/fr/a-propos/innovations-vegetales',
            },
          },
          {
            displayed_text: 'FAQ',
            link: {
              url: '/fr/faq',
            },
          }
        ],
      },
      {
        displayed_text: 'Pour un avenir meilleur',
        link: {
          url: '/fr/pour-un-avenir-meilleur',
        },
        children: [
          {
            displayed_text: 'Inspiration végétale',
            link: {
              url: '/fr/pour-un-avenir-meilleur/inspiration-vegetale',
            },
          },
          {
            displayed_text: 'Emballage recyclable',
            link: {
              url: '/fr/pour-un-avenir-meilleur/emballage-recyclable',
            },
          },
          {
            displayed_text: 'Biodiversité et sol',
            link: {
              url: '/fr/pour-un-avenir-meilleur/biodiversite-et-sol',
            },
          },
          {
            displayed_text: 'Gaspillage alimentaire',
            link: {
              url: '/fr/pour-un-avenir-meilleur/gaspillage-alimentaire',
            },
          }
        ],
      },
    ],
    footer_items: [{
        displayed_text: 'À propos',
        children: [{
            displayed_text: 'Le groupe Bonduelle',
            link: {
              url: 'https://www.bonduelle.com/fr',
              is_external: true,
            },
          },
          {
            displayed_text: 'Nous rejoindre',
            link: {
              url: 'https://jobs.bonduelle.com/?locale=fr_FR',
              is_external: true,
            },
          },
          {
            displayed_text: 'Bonduelle Food Service',
            link: {
              url: 'https://www.bonduelle-foodservice.be',
              is_external: true,
            },
          },
        ],
      },
      {
        displayed_text: 'Service consommateurs',
        children: [{
          displayed_text: 'Nous contacter',
          link: {
            url: '/fr/nous-contacter',
          },
        },
        {
          displayed_text: 'Questions/Réponses',
          link: {
            url: '/fr/faq',
          },
        }
      ],
      },
    ],
    sub_footer_items: [{
        translation_key: 'FOOTER.LINK.LEGAL_NOTICE',
        link: {
          url: '/fr/mentions-legales',
        },
      },
      {
        translation_key: 'FOOTER.LINK.PRIVACY_POLICY',
        link: {
          url: '/fr/politique-confidentialite',
        },
      },
      {
        translation_key: 'FOOTER.LINK.ALL_RIGHTS_RESERVED',
      },
    ],
    share_buttons: [{
        name: 'facebook',
        url: 'https://www.facebook.com/BonduelleBelgique/',
      },
      {
        name: 'instagram',
        url: 'https://www.instagram.com/bonduelle_be',
      },
    ],
    default_videos: [],
    theme: 'be-theme',
    catch_phrase: 'HOME.CATCH_PHRASE',
    published: true,
    packshot: true,
  },
  {
    lang: {
      country: 'dk',
      code: 'da',
      default: true,
    },
    has_account: false,
    pictos_product: [],
    menu_items: [{
        displayed_text: 'Opskrifter',
        link: {
          url: '/opskrifter',
        },
        children: [
          {
            displayed_text: 'Hovedretter',
            link: {
              url: '/opskrifter/hovedretter',
            },
          },
          {
            displayed_text: 'Småretter & snacks',
            link: {
              url: '/opskrifter/smaretter-snacks',
            },
          },
          {
            displayed_text: 'Salater',
            link: {
              url: '/opskrifter/salater',
            },
          },
          {
            displayed_text: 'Sødt & bagværk',
            link: {
              url: '/opskrifter/sodt-bagvaerk',
            },
          },
        ],
      },
      {
        displayed_text: 'Produkter',
        link: {
          url: '/produkter',
        },
        children: [
          /*{
            displayed_text: 'Nyheder',
            link: {
              url: '/produkter/nyheder'
            }
          },*/
          {
            displayed_text: 'Lunch bowls',
            link: {
              url: '/produkter/lunch-bowl',
            },
          },
          {
            displayed_text: 'Majs',
            link: {
              url: '/produkter/majs',
            },
          },
          {
            displayed_text: 'Bælgfrugter',
            link: {
              url: '/produkter/baelgfrugter',
            },
          },
          {
            displayed_text: 'Bønner i sauce',
            link: {
              url: '/produkter/bonner-sauce',
            },
          },
          /*{
            displayed_text: 'Champignoner',
            link: {
              url: '/produkter/champignoner'
            }
          },
          {
            displayed_text: 'Tex Mex',
            link: {
              url: '/produkter/tex-mex'
            }
          },*/
          {
            displayed_text: 'Grønne grøntsager',
            link: {
              url: '/produkter/gronne-grontsager',
            },
          },
          {
            displayed_text: 'Mini packs',
            link: {
              url: '/produkter/mini-packs',
            },
          },
        ],
      },
      {
        displayed_text: 'Siden 1853',
        link: {
          url: '/siden-1853/sidste-nyt',
        },
        children: [{
            displayed_text: 'Alle artikler',
            link: {
              url: '/siden-1853/sidste-nyt',
            },
          },
          {
            displayed_text: 'Vores historie',
            link: {
              url: '/siden-1853/vores-historie',
            },
          },
          {
            displayed_text: 'Vores engagement',
            link: {
              url: '/siden-1853/vores-engagement',
            },
          },
          {
            displayed_text: 'Konserves & innovationer',
            link: {
              url: '/siden-1853/konserves-og-innovationer',
            },
          },
          {
            displayed_text: 'Fra marken til bordet',
            link: {
              url: '/siden-1853/marken-bordet',
            },
          },
        ],
      },
      {
        displayed_text: 'Nyheder',
        link: {
          url: '/nyheder/sidste-nyt',
        },
      },
    ],
    footer_items: [{
        displayed_text: 'Om os',
        children: [{
            displayed_text: 'Bonduelle Gruppen',
            link: {
              url: 'https://www.bonduelle.com/en/',
              is_external: true,
            },
          },
          {
            displayed_text: 'Vores engagement',
            link: {
              url: 'siden-1853/vores-engagement',
            },
          },
          {
            displayed_text: 'Louis Bonduelle Fonden',
            link: {
              url: 'http://www.fondation-louisbonduelle.org/en/',
              is_external: true,
            },
          },
          {
            displayed_text: 'Bonduelle Food Service',
            link: {
              url: 'https://www.bonduelle-foodservice.dk/',
              is_external: true,
            },
          },
          {
            displayed_text: 'Job hos Bonduelle',
            link: {
              url: 'https://workingatbonduellenortherneurope.com/',
              is_external: true,
            },
          },
          {
            displayed_text: 'Kontrolrapport Fødevarestyrelsen',
            link: {
              url: 'https://www.findsmiley.dk/503655',
              is_external: true,
            },
          },
        ],
      },
      {
        displayed_text: 'Kundeservice',
        children: [{
            displayed_text: 'Kontakt os',
            link: {
              url: '/kontakt-os',
            },
          },
          {
            displayed_text: 'FAQ',
            link: {
              url: '/faq',
            },
          },
        ],
      },
    ],
    sub_footer_items: [{
        translation_key: 'FOOTER.LINK.LEGAL_NOTICE',
        link: {
          url: '/juridisk-meddelelse',
        },
      },
      {
        translation_key: 'FOOTER.LINK.PRIVACY_POLICY',
        link: {
          url: '/ekstern-databeskyttelsespolitik',
        },
      },
      {
        translation_key: 'FOOTER.LINK.ALL_RIGHTS_RESERVED',
      },
    ],
    share_buttons: [{
        name: 'facebook',
        url: 'https://www.facebook.com/BonduelleDk',
      },
      {
        name: 'youtube',
        url: 'https://www.youtube.com/channel/UCb4v9IQEFCV_T1aWzm6V_pg/videos',
      },
      {
        name: 'instagram',
        url: 'https://www.instagram.com/bonduelle_dk/?hl=en',
      }
    ],
    default_videos: [],
    theme: 'default-theme',
    catch_phrase: 'HOME.CATCH_PHRASE',
    published: false,
    packshot: true,
  },
  {
    lang: {
      country: 'de',
      code: 'de',
      default: true,
    },
    has_account: false,
    pictos_product: [],
    menu_items: [{
        displayed_text: 'Rezepte',
        link: {
          url: '/rezepte',
        },
        children: [{
            displayed_text: 'alle rezepte',
            link: {
              url: '/rezept',
            },
          },
          {
            displayed_text: 'lieblingsrezepte',
            link: {
              url: '/rezepte',
            },
          },
          {
            displayed_text: 'Vorspeisen & Suppen',
            link: {
              url: '/rezept/vorspeisen-und-suppen',
            },
          },
          {
            displayed_text: 'Hauptgang',
            link: {
              url: '/rezept/hauptgang',
            },
          },
          {
            displayed_text: 'Nachtisch & Backwaren',
            link: {
              url: '/rezept/nachtisch-und-backwaren',
            },
          },
          {
            displayed_text: 'Snacks & Fingerfood',
            link: {
              url: '/rezept/snacks-und-fingerfood',
            },
          },
          {
            displayed_text: 'Salate & Grillen',
            link: {
              url: '/rezept/salate-und-grillen',
            },
          },
        ],
      },
      {
        displayed_text: 'UNSER GEMÜSE',
        link: {
          url: 'unser-gemuese',
        },
        children: [{
            displayed_text: 'Alle Produkte',
            link: {
              url: '/unser-gemuese',
            },
          },
          {
            displayed_text: 'Neue Produkte',
            link: {
              url: '/unser-gemuese/neue-produkte',
            },
          },
          {
            displayed_text: 'Gemüsekonserven',
            link: {
              url: '/unser-gemuese/gemuesekonserven',
            },
          },
          {
            displayed_text: 'Lunch Bowl',
            link: {
              url: '/unser-gemuese/lunch-bowl',
            },
          },
          {
            displayed_text: 'World Kitchen',
            link: {
              url: '/unser-gemuese/world-kitchen',
            },
          },
          {
            displayed_text: 'Dampfgegart',
            link: {
              url: '/unser-gemuese/dampfgegart',
            },
          },
          {
            displayed_text: 'Bio Produkte',
            link: {
              url: '/unser-gemuese/bio-produkte',
            },
          },
          {
            displayed_text: 'Frische Salate',
            link: {
              url: '/unser-gemuese/frische-salate',
            },
          }
        ],
      },
      {
        displayed_text: 'ÜBER UNS',
        link: {
          url: '/ueber-uns/aktuelles',
        },
        children: [{
            displayed_text: 'Karriere',
            link: {
              url: '/karriere',
            },
          },
          {
            displayed_text: 'Neueste Artikel',
            link: {
              url: '/ueber-uns/aktuelles',
            },
          },
          {
            displayed_text: 'Unser Nachhaltigkeitsprogramm',
            link: {
              url: '/ueber-uns/artikel/wir-verpflichten-uns-zu-nachhaltigem-anbau/2272/2272',
            },
          },
          {
            displayed_text: 'Unsere Geschichte',
            link: {
              url: '/ueber-uns/unsere-geschichte',
            },
          },
          {
            displayed_text: 'Unser Engagement',
            link: {
              url: '/ueber-uns/unsere-engagement',
            },
          },
          {
            displayed_text: 'Unsere Innovationen',
            link: {
              url: '/ueber-uns/unsere-innovationen',
            },
          },
          {
            displayed_text: 'Vom Feld auf den Teller',
            link: {
              url: '/ueber-uns/vom-feld-auf-den-teller',
            },
          },
        ],
      },
      {
        displayed_image: 'gemuseMag',
        link: {
          url: '/gemuese4me/aktuelles',
        },
      },
    ],
    footer_items: [{
        displayed_text: 'über uns',
        children: [{
            displayed_text: 'Karriere',
            link: {
              url: '/karriere',
            },
          },
          {
            displayed_text: 'Unsere Geschichte',
            link: {
              url: '/ueber-uns/unsere-geschichte',
            },
          },
          {
            displayed_text: 'Unser Engagement',
            link: {
              url: '/ueber-uns/unsere-engagement',
            },
          },
          {
            displayed_text: 'Unsere Innovationen',
            link: {
              url: '/ueber-uns/unsere-innovationen',
            },
          },
        ],
      },
      {
        displayed_text: 'Service',
        children: [{
            displayed_text: 'FAQ',
            link: {
              url: '/faq',
            },
          },
          {
            displayed_text: 'Kontakt',
            link: {
              url: '/kontakt',
            },
          },
          {
            displayed_text: 'Presse',
            link: {
              url: '/presse',
            },
          },
          {
            displayed_text: 'Influencer Kooperation',
            link: {
              url: 'https://platform.influenceme.de/widget/bonduelle_de',
              is_external: true
            },
          },
        ],
      },
    ],
    sub_footer_items: [{
        displayed_text: 'Impressum',
        link: {
          url: '/impressum',
        },
      },
      {
        displayed_text: 'Datenschutz',
        link: {
          url: '/datenschutz',
        },
      },
      {
        displayed_text: 'AVB',
        link: {
          url: '/avb',
        },
      },
      {
        displayed_text: 'Food service',
        link: {
          url: 'http://www.bonduelle-foodservice.de/',
          is_external: true,
        },
      },
      {
        displayed_text: 'Bonduelle.com',
        link: {
          url: 'https://www.bonduelle.com/',
          is_external: true,
        },
      },
      {
        translation_key: 'FOOTER.LINK.ALL_RIGHTS_RESERVED',
      },
    ],
    share_buttons: [
      {
        name: 'pinterest',
        url: 'https://www.pinterest.de/bonduelle_de/',
      },
      {
        name: 'facebook',
        url: 'https://www.facebook.com/BonduelleDE',
      },
      {
        name: 'instagram',
        url: 'https://www.instagram.com/bonduelle_de/',
      },
    ],
    default_videos: [],
    theme: 'default-theme',
    catch_phrase: 'HOME.CATCH_PHRASE',
    published: true,
    packshot: true,
  },
  {
    lang: {
      country: 'at',
      code: 'de',
      default: true,
    },
    has_account: false,
    pictos_product: [],
    menu_items: [{
        displayed_text: 'Rezepte',
        link: {
          url: '/rezept',
        },
        children: [{
            displayed_text: 'alle rezepte',
            link: {
              url: '/rezept',
            },
          },
          {
            displayed_text: 'lieblingsrezepte',
            link: {
              url: '/rezepte',
            },
          },
          {
            displayed_text: 'Vorspeisen & Suppen',
            link: {
              url: '/rezept/vorspeisen-und-suppen',
            },
          },
          {
            displayed_text: 'Hauptgang',
            link: {
              url: '/rezept/hauptgang',
            },
          },
          {
            displayed_text: 'Nachtisch & Backwaren',
            link: {
              url: '/rezept/nachtisch-und-backwaren',
            },
          },
          {
            displayed_text: 'Snacks & Fingerfood',
            link: {
              url: '/rezept/snacks-und-fingerfood',
            },
          },
          {
            displayed_text: 'Salate & Grillen',
            link: {
              url: '/rezept/salate-und-grillen',
            },
          },
        ],
      },
      {
        displayed_text: 'UNSER GEMÜSE',
        link: {
          url: 'unser-gemuese',
        },
        children: [{
            displayed_text: 'Neue Produkte',
            link: {
              url: '/unser-gemuese/neue-produkte',
            },
          },
          {
            displayed_text: 'Bio',
            link: {
              url: '/unser-gemuese/bio',
            },
          },
          {
            displayed_text: 'Mais',
            link: {
              url: '/unser-gemuese/mais',
            },
          },
          {
            displayed_text: 'Hülsenfrüchte',
            link: {
              url: '/unser-gemuese/hulsenfruchte'
            }
          },
          {
            displayed_text: 'Grüngemüse',
            link: {
              url: '/unser-gemuese/grungemuse',
            },
          },
          {
            displayed_text: 'Pilze',
            link: {
              url: '/unser-gemuese/pilze',
            },
          },
          {
            displayed_text: 'Lunch bowl',
            link: {
              url: '/unser-gemuese/lunch-bowl',
            },
          }
          /*{
            displayed_text: 'A la Reis',
            link: {
              url: '/unser-gemuese/reis'
            }
          }*/
        ],
      },
      {
        displayed_text: 'ÜBER UNS',
        link: {
          url: '/ueber-uns/aktuelles',
        },
        children: [{
            displayed_text: 'Neueste Artikel',
            link: {
              url: '/ueber-uns/aktuelles',
            },
          },
          {
            displayed_text: 'Unsere Geschichte',
            link: {
              url: '/ueber-uns/unsere-geschichte',
            },
          },
          {
            displayed_text: 'Unser Engagement',
            link: {
              url: '/ueber-uns/unsere-engagement',
            },
          },
          {
            displayed_text: 'Unsere Innovationen',
            link: {
              url: '/ueber-uns/unsere-innovationen',
            },
          },
          {
            displayed_text: 'Vom Feld auf den Teller',
            link: {
              url: '/ueber-uns/vom-feld-auf-den-teller',
            },
          },
        ],
      },
      {
        displayed_image: 'gemuseMag',
        link: {
          url: '/gemuese4me/aktuelles',
        },
      },
    ],
    footer_items: [{
        displayed_text: 'über',
        children: [{
            displayed_text: 'Die Gruppe',
            link: {
              url: 'https://www.bonduelle.com/en/',
              is_external: true,
            },
          },
          {
            displayed_text: 'Unsere Verpflichtungen',
            link: {
              url: '/ueber-uns/unsere-engagement',
            },
          },
          {
            displayed_text: 'Bonduelle-Stiftung',
            link: {
              url: 'http://www.fondation-louisbonduelle.org/de/',
              is_external: true,
            },
          },
        ],
      },
      {
        displayed_text: 'Verbraucherservice',
        children: [{
            displayed_text: 'FAQ',
            link: {
              url: '/faq',
            },
          },
          {
            displayed_text: 'Kontakt',
            link: {
              url: '/kontakt',
            },
          },
        ],
      },
    ],
    sub_footer_items: [{
        displayed_text: 'Impressum',
        link: {
          url: '/impressum',
        },
      },
      {
        displayed_text: 'Datenschutz',
        link: {
          url: '/datenschutz',
        },
      },
      {
        displayed_text: 'AVB',
        link: {
          url: '/avb',
        },
      },
    ],
    share_buttons: [{
        name: 'facebook',
        url: 'https://www.facebook.com/BonduelleDE',
      },
      {
        name: 'instagram',
        url: 'https://www.instagram.com/bonduelle_de/',
      },
    ],
    default_videos: [],
    theme: 'default-theme',
    catch_phrase: 'HOME.CATCH_PHRASE',
    published: true,
    packshot: true,
  },
  {
    lang: {
      country: 'com',
      code: 'en',
      default: true,
    },
    has_account: true,
    pictos_product: [],
    menu_items: [{
        displayed_text: 'Recipe',
        link: {
          url: '/menu-page',
        },
        children: [{
            displayed_text: 'Party Plate',
            link: {
              url: '/recipes/party-plate',
            },
          },
          {
            displayed_text: 'Aperitif',
            link: {
              url: '/recipes/aperitif',
            },
          },
          {
            displayed_text: 'Accompaniment',
            link: {
              url: '/recipes/accompaniment',
            },
          },
        ],
      },
      {
        displayed_text: 'Products',
        link: {
          url: '/vegetables/all-themes',
        },
        children: [{
            displayed_text: 'News',
            link: {
              url: '/vegetables/news',
            },
          },
          {
            displayed_text: 'Starter',
            link: {
              url: '/vegetables/starter',
            },
          },
          {
            displayed_text: 'Salad',
            link: {
              url: '/vegetables/salad',
            },
          },
          {
            displayed_text: 'Main course',
            link: {
              url: '/vegetables/main-course',
            },
          },
          {
            displayed_text: 'Ingrédients for my recipes',
            link: {
              url: '/vegetables/ingredients-recipes',
            },
          },
          {
            displayed_text: 'Accompaniment',
            link: {
              url: '/vegetables/accompaniment',
            },
          },
          {
            displayed_text: 'Snacking',
            link: {
              url: '/vegetables/snacking',
            },
          },
        ],
      },
      {
        displayed_text: 'Since 1853',
        link: {
          url: '/since-1853/news',
        },
        children: [{
            displayed_text: 'All our actualities',
            link: {
              url: '/since-1853/news',
            },
          },
          {
            displayed_text: 'Company story',
            link: {
              url: '/since-1853/company-story',
            },
          },
          {
            displayed_text: 'CSR Commitments',
            link: {
              url: '/since-1853/csr-commitments',
            },
          },
          {
            displayed_text: 'Vegetal innovations',
            link: {
              url: '/since-1853/vegetal-innovations',
            },
          },
          {
            displayed_text: 'From the field to the plate',
            link: {
              url: '/since-1853/field-plate',
            },
          },
          {
            displayed_text: 'Join us',
            link: {
              url: '/menu-page',
            },
          },
        ],
      },
      {
        displayed_text: 'Magazine',
        link: {
          url: '/magazine/news',
        },
      },
    ],
    footer_items: [{
        displayed_text: 'About',
        children: [{
            displayed_text: 'The group',
            link: {
              url: '#',
            },
          },
          {
            displayed_text: 'Our commitments',
            link: {
              url: '#',
            },
          },
          {
            displayed_text: 'Join us',
            link: {
              url: '/menu-page',
            },
          },
          {
            displayed_text: 'Bonduelle Food Service',
            link: {
              url: '#',
            },
          },
          {
            displayed_text: 'Fondation Bonduelle',
            link: {
              url: '#',
            },
          },
          {
            displayed_text: 'Bonduelle Bienvenue',
            link: {
              url: '#',
            },
          },
        ],
      },
      {
        displayed_text: 'Consumer service',
        children: [{
            displayed_text: 'Contact us',
            link: {
              url: '#',
            },
          },
          {
            displayed_text: 'Questions/Answers',
            link: {
              url: '#',
            },
          },
        ],
      },
    ],
    sub_footer_items: [{
        translation_key: 'FOOTER.LINK.PRIVACY_POLICY',
        link: {
          url: '/privacy-policy',
        },
      },
      {
        translation_key: 'FOOTER.LINK.SITEMAP',
        link: {
          url: '/sitemap',
        },
      },
      {
        translation_key: 'FOOTER.LINK.CREDITS',
        link: {
          url: '/credits',
        },
      },
      {
        translation_key: 'FOOTER.LINK.LEGAL_NOTICE',
        link: {
          url: '/legal-notice',
        },
      },
      {
        translation_key: 'FOOTER.LINK.COOKIES',
        link: {
          url: '/cookies',
        },
      },
      {
        translation_key: 'FOOTER.LINK.ALL_RIGHTS_RESERVED',
      },
    ],
    share_buttons: [],
    default_videos: [],
    theme: 'default-theme',
    catch_phrase: 'HOME.CATCH_PHRASE',
    published: false,
    packshot: false,
  },
  {
    lang: {
      country: 'nl',
      code: 'nl',
      default: true,
    },
    has_account: false,
    pictos_product: [],
    menu_items: [{
        displayed_text: 'Recepten',
        link: {
          url: '/recepten',
        },
        children: [{
            displayed_text: 'Salades',
            link: {
              url: '/recept/salades',
            },
          },
          {
            displayed_text: 'Mexicaans',
            link: {
              url: '/recept/mexicaans',
            },
          },
          {
            displayed_text: 'De wereld rond',
            link: {
              url: '/recept/de-wereld-rond',
            },
          },
          {
            displayed_text: 'Dagje zonder vlees',
            link: {
              url: '/recept/dagje-zonder-vlees',
            },
          },
          {
            displayed_text: 'Laag in koolhydraten',
            link: {
              url: '/recept/laag-in-koolhydraten',
            },
          },
          {
            displayed_text: 'Soepen',
            link: {
              url: '/recept/soepen',
            },
          },
          {
            displayed_text: 'Bowls',
            link: {
              url: '/recept/bowls',
            },
          }
        ],
      },
      {
        displayed_text: 'Producten',
        link: {
          url: '/producten',
        },
        children: [
          {
            displayed_text: 'Maïs',
            link: {
              url: '/producten/mais',
            },
          },
          {
            displayed_text: 'Peulvruchten',
            link: {
              url: '/producten/peulvruchten',
            },
          },
          {
            displayed_text: 'Groenten',
            link: {
              url: '/producten/groenten',
            },
          },
          {
            displayed_text: 'Groenterijst',
            link: {
              url: '/producten/groenterijst',
            },
          },
          {
            displayed_text: 'Groenten voor Soep',
            link: {
              url: '/producten/groenten-voor-soep',
            },
          },
          {
            displayed_text: 'Groente Rondo\'s',
            link: {
              url: '/producten/groente-rondo-s',
            },
          },
          {
            displayed_text: 'Lunch Bowl',
            link: {
              url: '/producten/lunch-bowl',
            },
          }
        ],
      },
      {
        displayed_text: 'Over Bonduelle',
        link: {
          url: '/over-ons/nieuws',
        },
        children: [{
            displayed_text: 'Alle activiteiten',
            link: {
              url: '/over-ons/nieuws',
            },
          },
          {
            displayed_text: 'Geschiedenis',
            link: {
              url: '/over-ons/geschiedenis',
            },
          },
          {
            displayed_text: 'MVO',
            link: {
              url: '/over-ons/mvo',
            },
          },
          {
            displayed_text: 'Innovatie',
            link: {
              url: '/over-ons/innovatie',
            },
          },
          {
            displayed_text: 'FAQ',
            link: {
              url: '/faq',
            },
          }
        ],
      },
      {
        displayed_text: 'Voor een beter morgen',
        link: {
          url: '/voor-een-beter-morgen',
        },
        children: [
          {
            displayed_text: 'Plantaardige inspiratie',
            link: {
              url: '/voor-een-beter-morgen/plantaardige-inspiratie',
            },
          },
          {
            displayed_text: 'Recyclebare verpakkingen',
            link: {
              url: '/voor-een-beter-morgen/recyclebare-verpakkingen',
            },
          },
          {
            displayed_text: 'Biodiversiteit en bodem',
            link: {
              url: '/voor-een-beter-morgen/biodiversiteit-en-bodem',
            },
          },
          {
            displayed_text: 'Voedselverspilling',
            link: {
              url: '/voor-een-beter-morgen/voedselverspilling',
            },
          }
        ],
      },
    ],
    footer_items: [{
        displayed_text: 'Over ons',
        children: [{
            displayed_text: 'De Bonduelle groep',
            link: {
              url: 'https://www.bonduelle.com/en',
              is_external: true,
            },
          },
          {
            displayed_text: 'Werken bij',
            link: {
              url: 'https://jobs.bonduelle.com/?locale=nl_NL',
              is_external: true,
            },
          },
          {
            displayed_text: 'Bonduelle Food Service',
            link: {
              url: 'https://www.bonduelle-foodservice.nl',
              is_external: true,
            },
          },
        ],
      },
      {
        displayed_text: 'Klantenservice',
        children: [{
            displayed_text: 'Neem contact met ons op',
            link: {
              url: '/neem-contact-met-ons-op',
            },
          },
          {
            displayed_text: 'Veelgestelde vragen',
            link: {
            url: '/faq'
            }
          }
        ],
      },
    ],
    sub_footer_items: [{
        translation_key: 'FOOTER.LINK.LEGAL_NOTICE',
        link: {
          url: '/gebruiksvoorwaarden',
        },
      },
      // {
      //   translation_key: 'FOOTER.LINK.CREDITS',
      //   link: {
      //     url: '/credits'
      //   }
      // },
      // {
      //   translation_key: 'FOOTER.LINK.SITEMAP',
      //   link: {
      //     url: '/sitemap'
      //   }
      // },
      {
        translation_key: 'FOOTER.LINK.PRIVACY_POLICY',
        link: {
          url: '/privacybeleid',
        },
      },
      {
        translation_key: 'FOOTER.LINK.ALL_RIGHTS_RESERVED',
      },
    ],
    share_buttons: [{
        name: 'facebook',
        url: 'https://www.facebook.com/Bonduelle.nl/',
      },
      {
        name: 'instagram',
        url: 'https://www.instagram.com/bonduelle_nl',
      },
    ],
    default_videos: [],
    theme: 'be-theme',
    catch_phrase: 'HOME.CATCH_PHRASE',
    published: true,
    packshot: true,
  },
  {
    lang: {
      country: 'hu',
      code: 'hu',
      default: true,
    },
    has_account: false,
    pictos_product: [],
    menu_items: [{
        displayed_text: 'Receptek',
        link: {
          url: '/fozesi-receptek',
        },
        children: [{
            displayed_text: 'Receptek kukoricával',
            link: {
              url: '/receptek/kukoricaval',
            },
          },
          {
            displayed_text: 'Receptek borsóval',
            link: {
              url: '/receptek/borsoval',
            },
          },
          {
            displayed_text: 'Receptek vörösbabbal',
            link: {
              url: '/receptek/vorosbabbal',
            },
          },
          {
            displayed_text: 'Receptek fehérbabbal',
            link: {
              url: '/receptek/babbal',
            },
          },
          {
            displayed_text: 'Receptek lencsével',
            link: {
              url: '/receptek/lencsevel',
            },
          },
          {
            displayed_text: 'Receptek csicseriborsóval',
            link: {
              url: '/receptek/csicseriborsoval',
            },
          },
          {
            displayed_text: 'Receptek zöldbabbal',
            link: {
              url: '/receptek/zoldbabbal',
            },
          },
          {
            displayed_text: 'Receptek brokkolival',
            link: {
              url: '/receptek/brokkolival',
            },
          },
          {
            displayed_text: 'Receptek karfiollal',
            link: {
              url: '/receptek/karfiollal',
            },
          },
          {
            displayed_text: 'Minden Recept',
            link: {
              url: '/receptek',
            },
          }
        ],
      },
      {
        displayed_text: 'Termékek',
        link: {
          url: '/termekek',
        },
        children: [{
            displayed_text: 'Újdonságok ',
            link: {
              url: '/termekek/ujdonsagok',
            },
          },
          {
            displayed_text: 'Good Lunch',
            link: {
              url: '/magazin/cikk/uj-good-lunch-finom-izletes-kiegyensulyozott-etkezes/3066/3066',
            },
          },
          {
            displayed_text: 'Menő klasszikusok',
            link: {
              url: '/termekek/meno-klasszikusok',
            },
          },
          {
            displayed_text: 'Salátákhoz',
            link: {
              url: '/termekek/salatakhoz',
            },
          },
          {
            displayed_text: 'Készételek',
            link: {
              url: '/termekek/keszetelek',
            },
          },
          {
            displayed_text: 'Köretek',
            link: {
              url: '/termekek/koretek',
            },
          },
        ],
      },
      {
        displayed_text: 'Rólunk',
        link: {
          url: '/1853-ota/cegtortenet',
        },
        children: [{
            displayed_text: 'Bonduelle  Magyarországon',
            link: {
              url: '/1853-ota/cegtortenet',
            },
          },
          {
            displayed_text: 'Bonduelle a világban',
            link: {
              url: '/1853-ota/legujabb-termekeink',
            },
          },
          {
            displayed_text: 'FENNTARTHATÓSÁG',
            link: {
              url: '/1853-ota/cikk/kornyezeti-celjaink/6740/642',
            },
          },
          {
            displayed_text: 'Kötelezettségvállalásaink',
            link: {
              url: '/1853-ota/tarsadalmi-felelossegvallalas',
            },
          },
          {
            displayed_text: 'A földtől az asztalig',
            link: {
              url: '/1853-ota/termofoldrol-tanyerra',
            },
          },
          {
            displayed_text: 'Állásajánlatok',
            link: {
              url: '/allasajanlatok',
            },
          },
        ],
      },
      {
        displayed_image: 'zoldsemagazin',
        link: {
          url: '/magazin/hirek',
        },
      },
    ],
    footer_items: [{
        displayed_text: 'Rólunk',
        children: [{
            displayed_text: 'Adatvédelmi szabályzatunk',
            link: {
              url: '/adatkezelesi-szabalyzat',
            },
          },
          {
            displayed_text: 'Bonduelle Food Service',
            link: {
              url: 'http://bonduelle-foodservice.hu/',
              is_external: true,
            },
          },
          {
            displayed_text: 'Csatlakozz hozzánk',
            link: {
              url: 'https://jobs.bonduelle.com/?locale=hu_HU',
              is_external: true,
            },
          },
        ],
      },
      {
        displayed_text: 'Vevőszolgálat',
        children: [{
            displayed_text: 'Kapcsolat',
            link: {
              url: '/kapcsolat',
            },
          },
          {
            displayed_text: 'Gyakori kérdések',
            link: {
              url: '/gyik',
            },
          },
        ],
      },
    ],
    sub_footer_items: [
      {
        translation_key: 'FOOTER.LINK.PRIVACY_POLICY',
        link: {
          url: '/adatkezelesi-szabalyzat',
        },
      },
      {
        displayed_text: 'bonduelle.com',
        link: {
          url: 'https://www.bonduelle.com/en/',
          is_external: true,
        },
      },
      // {
      //   translation_key: 'FOOTER.LINK.SITEMAP',
      //   link: {
      //     url: '/oldalterkep'
      //   }
      // },
      // {
      //   translation_key: 'FOOTER.LINK.CREDITS',
      //   link: {
      //     url: '/kredit'
      //   }
      // },
      // {
      //   translation_key: 'FOOTER.LINK.LEGAL_NOTICE',
      //   link: {
      //     url: '/jogi-nyilatkozat'
      //   }
      // },
      // {
      //   translation_key: 'FOOTER.LINK.COOKIES',
      //   link: {
      //     url: '/cookie-k'
      //   }
      // },
      {
        translation_key: 'FOOTER.LINK.ALL_RIGHTS_RESERVED',
      },
    ],
    share_buttons: [{
        name: 'facebook',
        url: 'https://www.facebook.com/bonduelle.hu',
      },
      {
        name: 'instagram',
        url: 'https://www.instagram.com/bonduelle_hu/',
      },
      {
        name: 'youtube',
        url: 'https://www.youtube.com/channel/UCQHTV_bcd8ulywfLFyq46Ng',
      },
      {
        name: 'pinterest',
        url: 'https://hu.pinterest.com/bonduellehu/_created/',
      },
    ],
    default_videos: [],
    theme: 'default-theme',
    catch_phrase: 'HOME.CATCH_PHRASE',
    published: true,
    packshot: true,
  },
  {
    lang: {
      country: 'cz',
      code: 'cs',
      default: true,
    },
    has_account: false,
    pictos_product: [],
    menu_items: [{
        displayed_text: 'Recepty',
        link: {
          url: '/recepty-domu',
        },
        children: [{
            displayed_text: 'Všechny recepty',
            link: {
              url: '/recepty/vse',
            },
          },
          {
            displayed_text: 'Recepty na saláty a předkrmy',
            link: {
              url: '/recepty/salaty-predkrmy',
            },
          },
          {
            displayed_text: 'Recepty na polévky',
            link: {
              url: '/recepty/polevky',
            },
          },
          {
            displayed_text: 'Recepty na hlavní jídla',
            link: {
              url: '/recepty/hlavni-jidla',
            },
          },
          {
            displayed_text: 'Recepty na dezerty',
            link: {
              url: '/recepty/dezerty',
            },
          },
        ],
      },
      {
        displayed_text: 'Produkty',
        link: {
          url: '/zelenina/vse',
        },
        children: [
          {
            displayed_text: 'Aktuality',
            link: {
              url: '/aktuality',
            },
          },
          {
            displayed_text: 'vše',
            link: {
              url: '/zelenina/vse',
            },
          },
          {
            displayed_text: 'Sterilovaná zelenina',
            link: {
              url: '/zelenina/sterilovana-zelenina',
            },
          },
          {
            displayed_text: 'Mražená zelenina',
            link: {
              url: '/zelenina/mrazena-zelenina',
            },
          },
          {
            displayed_text: 'Hotová jídla',
            link: {
              url: '/zelenina/hotova-jidla',
            },
          },
          {
            displayed_text: 'Creátif',
            link: {
              url: '/zelenina/creatif',
            },
          },
          {
            displayed_text: 'Bon Menu',
            link: {
              url: '/zelenina/bon-menu',
            },
          },
          {
            displayed_text: 'Vapeur',
            link: {
              url: '/zelenina/vapeur',
            },
          },
        ],
      },
      {
        displayed_text: 'Tipy a triky',
        link: {
          url: '/zajimavosti',
        },
      },
      {
        displayed_text: 'O Bonduelle',
        link: {
          url: '/od-roku-1853/novinky',
        },
      },
    ],
    footer_items: [{
        displayed_text: 'Důležité odkazy',
        children: [{
            displayed_text: 'Ochrana osobních údajů',
            link: {
              url: '/ochrana-osobni-udaju',
            },
          },
          {
            displayed_text: 'Bonduelle Food Service',
            link: {
              url: 'https://www.bonduelle-foodservice.cz/',
              is_external: true,
            },
          },
          {
            displayed_text: 'Bonduelle.com',
            link: {
              url: 'https://www.bonduelle.com/en/',
              is_external: true,
            },
          },
          {
            displayed_text: 'Volné pozice',
            link: {
              url: 'https://jobs.bonduelle.com/?locale=en_US',
              is_external: true,
            },
          },
        ],
      },
      {
        displayed_text: 'Ostatní',
        children: [{
          displayed_text: 'Kontakty',
          link: {
            url: '/kontakt',
          },
        }, ],
      },
    ],
    sub_footer_items: [{
        translation_key: 'FOOTER.LINK.PRIVACY_POLICY',
        link: {
          url: '/zasady-ochrany-osobnich-udaju',
        },
      },
      {
        translation_key: 'FOOTER.LINK.ALL_RIGHTS_RESERVED',
      },
    ],
    share_buttons: [{
        name: 'facebook',
        url: 'https://www.facebook.com/bonduelle.cz',
      },
      {
        name: 'instagram',
        url: 'https://www.instagram.com/bonduelle.cz/?hl=en',
      },
      {
        name: 'youtube',
        url: 'https://www.youtube.com/channel/UCkfv1NICOqgj2mJ4lMWp43Q',
      },
    ],
    default_videos: [],
    theme: 'default-theme',
    catch_phrase: 'HOME.CATCH_PHRASE',
    published: false,
    packshot: false,
  },
  {
    lang: {
      country: 'sk',
      code: 'sk',
      default: true,
    },
    has_account: false,
    pictos_product: [],
    menu_items: [{
        displayed_text: 'Recepty',
        link: {
          url: '/recepty-domov',
        },
        children: [{
            displayed_text: 'Všetky recepty',
            link: {
              url: '/recepty/vsetko',
            },
          },
          {
            displayed_text: 'Recepty na šaláty a predkrmy',
            link: {
              url: '/recepty/salaty-predkrmy',
            },
          },
          {
            displayed_text: 'Recepty na polievky',
            link: {
              url: '/recepty/polievky',
            },
          },
          {
            displayed_text: 'Recepty na hlavné jedlá',
            link: {
              url: '/recepty/hlavne-jedla',
            },
          },
          {
            displayed_text: 'Recepty na dezerty',
            link: {
              url: '/recepty/dezerty',
            },
          },
        ],
      },
      {
        displayed_text: 'Produkty',
        link: {
          url: '/zelenina/vsetko',
        },
        children: [{
            displayed_text: 'všetko',
            link: {
              url: '/zelenina/vsetko',
            },
          },
          {
            displayed_text: 'Sterilizovaná zelenina',
            link: {
              url: '/zelenina/sterilizovana-zelenina',
            },
          },
          {
            displayed_text: 'Mrazená zelenina',
            link: {
              url: '/zelenina//mrazena-zelenina',
            },
          },
          {
            displayed_text: 'Hotové jedlá',
            link: {
              url: '/zelenina/hotove-jedla',
            },
          },
          {
            displayed_text: 'Creátif',
            link: {
              url: '/zelenina/creatif',
            },
          },
          {
            displayed_text: 'Bon menu',
            link: {
              url: '/zelenina/bon-menu',
            },
          },
          {
            displayed_text: 'Vapeur',
            link: {
              url: '/zelenina/vapeur',
            },
          },
          {
            displayed_text: 'Aktuality',
            link: {
              url: '/aktuality',
            },
          },
          
        ],
      },
      {
        displayed_text: 'Tipy a triky',
        link: {
          url: '/zaujimavosti',
        },
        children: [

        ],
      },
      {
        displayed_text: 'O Bonduelle',
        link: {
          url: '/od-roku-1853/novinky',
        },
        children: [
        ],
      },
      
    ],
    footer_items: [{
        displayed_text: 'Dôležité odkazy',
        children: [{
            displayed_text: 'Ochrana osobných údajov',
            link: {
              url: '/zasady-ochrany-osobnych-udajov',
            },
          },
          {
            displayed_text: 'Bonduelle Food Service',
            link: {
              url: 'https://www.bonduelle-foodservice.cz',
              is_external: true,
            },
          },
          {
            displayed_text: 'Bonduelle.com',
            link: {
              url: 'https://www.bonduelle.com/',
              is_external: true,
            },
          },
          {
            displayed_text: 'Voľné pozície',
            link: {
              url: 'https://jobs.bonduelle.com/?locale=en_US',
              is_external: true,
            },
          },
        ],
      },
      {
        displayed_text: 'Ostatné',
        children: [{
          displayed_text: 'Kontakty',
          link: {
            url: '/kontakt',
          },
        }, ],
      },
    ],
    sub_footer_items: [{
        translation_key: 'FOOTER.LINK.PRIVACY_POLICY',
        link: {
          url: '/zasady-ochrany-osobnych-udajov',
        },
      },
      {
        translation_key: 'FOOTER.LINK.ALL_RIGHTS_RESERVED',
      },
    ],
    share_buttons: [{
        name: 'facebook',
        url: 'https://www.facebook.com/bonduelle.cz',
      },
      {
        name: 'instagram',
        url: 'https://www.instagram.com/bonduelle.cz/?hl=en',
      },
      {
        name: 'youtube',
        url: 'https://www.youtube.com/channel/UCRmHDz68HXxbEI40EuEe8bg',
      },
    ],
    default_videos: [],
    theme: 'default-theme',
    catch_phrase: 'HOME.CATCH_PHRASE',
    published: false,
    packshot: true,
  },
  {
    lang: {
      country: 'pl',
      code: 'pl',
      default: true,
    },
    has_account: false,
    pictos_product: [],
    menu_items: [{
        displayed_text: 'Przepisy',
        link: {
          url: 'https://warzywneinspiracje.pl/',
          is_external: true,
        },
      },
      {
        displayed_text: 'Produkty',
        link: {
          url: '/warzywa',
        },
        children: [
          {
          displayed_text: 'Nowości',
          link: {
            url: '/warzywa/nowosci',
            },
          },
          {
            displayed_text: 'Warzywa w puszkach',
            link: {
              url: '/warzywa/warzywa-w-puszkach',
            },
          },
          {
            displayed_text: 'Mrożonki',
            link: {
              url: '/warzywa/mrozonki',
            },
          },
          {
            displayed_text: 'Pieczarki marynowane',
            link: {
              url: '/warzywa/pieczarki',
            },
          },
          {
            displayed_text: 'Kaszetka',
            link: {
              url: '/warzywa/kaszetka-do-gotowania',
            },
          },
          {
            displayed_text: 'Good Lunch',
            link: {
              url: '/warzywa/good-lunch',
            },
          },
          {
            displayed_text: 'Dania na ciepło',
            link: {
              url: '/warzywa/dania-na-cieplo',
            },
          }
        ],
      },
      {
        displayed_text: 'O nas',
        link: {
          url: '/o-nas/aktualnosci',
        },
        children: [{
            displayed_text: 'aktualności',
            link: {
              url: '/o-nas/aktualnosci',
            },
          },
          {
            displayed_text: 'Historia firmy',
            link: {
              url: '/o-nas/historia-firmy',
            },
          },
          {
            displayed_text: 'Nasze zobowiązania',
            link: {
              url: '/o-nas/zobowiazania-csr',
            },
          },
          {
            displayed_text: 'Innowacje roślinne',
            link: {
              url: '/o-nas/roslinne-innowacje',
            },
          },
          {
            displayed_text: 'Z pola na talerz',
            link: {
              url: '/o-nas/z-pola-na-talerz',
            },
          },
          {
            displayed_text: 'Dołącz do nas',
            link: {
              url: 'www.bonduelle.com',
              is_external: true,
            },
          },
        ],
      },
    ],
    footer_items: [{
        displayed_text: 'Sprawdź',
        children: [{
            displayed_text: 'Bonduelle Food Service',
            link: {
              url: 'https://www.bonduelle-foodservice.pl/',
              is_external: true,
            },
          },
          {
            displayed_text: 'Grupa Bonduelle',
            link: {
              url: 'https://www.bonduelle.com/',
              is_external: true,
            },
          },
        ],
      },
      {
        displayed_text: 'Obsługa klienta',
        children: [{
            displayed_text: 'Częste pytania',
            link: {
              url: '/faq',
            },
          },
          {
            displayed_text: 'Kontakt',
            link: {
              url: '/kontakt',
            },
          },
          {
            displayed_text: 'Dołącz do nas',
            link: {
              url: 'https://jobs.bonduelle.com/?locale=pl_PL',
              is_external: true,
            },
          },
          {
            displayed_text: 'Dla akcjonariuszy',
            link: {
              url: '/wezwanie_dla_akcjonariuszy',
            },
          },
          {
            displayed_text: 'Strategia podatkowa',
            link: {
              url: '/strategia-podatkowa',
            },
          },
          {
            displayed_text: 'Polityka środowiskowa',
            link: {
              url: '/polityka-srodowiskowa',
            },
          },
          {
            displayed_text: 'Zobowiązania na rzecz środowiska',
            link: {
              url: '/zobowiazania-na-rzecz-srodowiska',
            },
          },
        ],
      },
    ],
    sub_footer_items: [
      /* {
        translation_key: 'FOOTER.LINK.LEGAL_NOTICE',
        link: {
          url: '/legal-notice'
        }
      },
      {
        translation_key: 'FOOTER.LINK.CREDITS',
        link: {
          url: '/credits-com'
        }
      },
      {
        translation_key: 'FOOTER.LINK.SITEMAP',
        link: {
          url: '/sitemap-com'
        }
      },*/
      {
        translation_key: 'FOOTER.LINK.PRIVACY_POLICY',
        link: {
          url: '/polityka-prywatnosci'
        }
      },
      {
        translation_key: 'FOOTER.LINK.ALL_RIGHTS_RESERVED',
      },
    ],
    share_buttons: [{
        name: 'facebook',
        url: 'https://pl-pl.facebook.com/BonduellePolska/',
      },
      {
        name: 'youtube',
        url: 'https://www.youtube.com/channel/UC2Y-4qBgHJ_CerJ_3rm51WQ',
      },
    ],
    default_videos: [],
    theme: 'default-theme',
    catch_phrase: 'HOME.CATCH_PHRASE',
    published: false,
    packshot: true,
  },
  {
    lang: {
      country: 'es',
      code: 'es',
      default: true,
    },
    has_account: false,
    pictos_product: [],
    menu_items: [{
        displayed_text: 'Recetas',
        link: {
          url: '/recetas-home',
        },
        children: [{
            displayed_text: 'Aperitivo/Tapa',
            link: {
              url: '/recetas/aperitivo-tapa',
            },
          },
          {
            displayed_text: 'entrante',
            link: {
              url: '/recetas/entrante',
            },
          },
          {
            displayed_text: 'Sopa/Crema',
            link: {
              url: '/recetas/sopa-crema',
            },
          },
          {
            displayed_text: 'ensalada',
            link: {
              url: '/recetas/ensalada',
            },
          },
          {
            displayed_text: 'Plato principal',
            link: {
              url: '/recetas/plato-principal',
            },
          },
          {
            displayed_text: 'postre',
            link: {
              url: '/recetas/postre',
            },
          },
        ],
      },
      {
        displayed_text: 'Productos',
        link: {
          url: '/productos',
        },
        children: [{
            displayed_text: 'Novedades',
            link: {
              url: '/productos/novedades',
            },
          },
          {
            displayed_text: 'Bio',
            link: {
              url: '/productos/bio',
            },
          },
          {
            displayed_text: 'Para tus ensaladas',
            link: {
              url: '/productos/para-tus-ensaladas',
            },
          },
          {
            displayed_text: 'Para tus recetas',
            link: {
              url: '/productos/para-tus-recetas',
            },
          },
          {
            displayed_text: 'Calentar y listo',
            link: {
              url: '/productos/calentar-y-listo',
            },
          },
          {
            displayed_text: 'Listo para comer',
            link: {
              url: '/productos/listo-para-comer',
            },
          },
          {
            displayed_text: 'Preguntas frecuentes',
            link: {
              url: '/preguntas-frecuentes',
            },
          },
        ],
      },
      {
        displayed_text: 'Desde 1853',
        link: {
          url: '/desde-1853/novedades',
        },
        children: [{
            displayed_text: 'Nuestra historia',
            link: {
              url: '/desde-1853/nuestra-historia',
            },
          },
          {
            displayed_text: 'El Grupo Bonduelle',
            link: {
              url: '/desde-1853/innovacion-vegetal',
            },
          },
          {
            displayed_text: 'Responsabilidad Social Corporativa',
            link: {
              url: '/desde-1853/compromiso-rsc',
            },
          },
          {
            displayed_text: 'Fundación Louis Bonduelle',
            link: {
              url: 'http://www.fondation-louisbonduelle.org/es/',
              is_external: true,
            },
          },
          {
            displayed_text: 'Del campo a la mesa',
            link: {
              url: '/desde-1853/campo-hasta-plato',
            },
          },
        ],
      },
      {
        displayed_image: 'vegetaliciosobis',
        link: {
          url: '/blog/novedades',
        },
      },
    ],
    footer_items: [{
        displayed_text: 'Sobre bonduelle',
        children: [{
            displayed_text: 'Nuestra historia',
            link: {
              url: '/desde-1853/nuestra-historia',
            },
          },
          {
            displayed_text: 'El grupo Bonduelle',
            link: {
              url: 'https://www.bonduelle.com',
              is_external: true,
            },
          },
          {
            displayed_text: 'Responsabilidad Social Corporativa',
            link: {
              url: '/desde-1853/compromiso-rsc',
            },
          },
          {
            displayed_text: 'Fundación Louis Bonduelle',
            link: {
              url: 'http://www.fondation-louisbonduelle.org/es/',
              is_external: true,
            },
          },
          {
            displayed_text: 'Bonduelle Food Service',
            link: {
              url: 'https://www.bonduelle-foodservice.es/',
              is_external: true,
            },
          },
          {
            displayed_text: 'Del campo a la mesa',
            link: {
              url: '/desde-1853/campo-hasta-plato',
            },
          },
          {
            displayed_text: 'Únete a nosotros',
            link: {
              url: 'https://jobs.bonduelle.com/?locale=es_ES',
              is_external: true,
            },
          },
        ],
      },
      {
        displayed_text: 'Servicio al consumidor',
        children: [{
            displayed_text: 'Contáctanos',
            link: {
              url: '/contacto',
            },
          },
          {
            displayed_text: 'Preguntas frecuentes',
            link: {
              url: '/preguntas-frecuentes',
            },
          },
        ],
      },
    ],
    sub_footer_items: [{
        translation_key: 'FOOTER.LINK.LEGAL_NOTICE',
        link: {
          url: '/menciones-legales',
        },
      },
      {
        translation_key: 'FOOTER.LINK.PRIVACY_POLICY',
        link: {
          url: '/politica-de-privacidad',
        },
      },
      {
        translation_key: 'FOOTER.LINK.COOKIES',
        link: {
          url: '/cookies',
        },
      },
      {
        translation_key: 'FOOTER.LINK.SITEMAP',
        link: {
          url: '/mapa-do-portal',
        },
      },
      {
        translation_key: 'FOOTER.LINK.ALL_RIGHTS_RESERVED',
      },
    ],
    share_buttons: [{
        name: 'facebook',
        url: 'https://www.facebook.com/BonduelleEsp/',
      },
      {
        name: 'instagram',
        url: 'https://www.instagram.com/bonduelle_es/',
      },
      {
        name: 'youtube',
        url: 'https://www.youtube.com/user/bonduelleesp',
      },
      {
        name: 'pinterest',
        url: 'https://www.pinterest.es/bonduelleesp',
      },
    ],
    default_videos: [],
    theme: 'default-theme',
    catch_phrase: 'HOME.CATCH_PHRASE',
    published: false,
    packshot: false,
  },
  {
    lang: {
      country: 'pt',
      code: 'pt',
      default: true,
    },
    has_account: false,
    pictos_product: [],
    menu_items: [{
        displayed_text: 'Receitas',
        link: {
          url: '/receitas-inicio',
        },
        children: [{
            displayed_text: 'Aperitivo',
            link: {
              url: '/receitas/aperitivo',
            },
          },
          {
            displayed_text: 'Entrada',
            link: {
              url: '/receitas/entrada',
            },
          },
          {
            displayed_text: 'Sopa',
            link: {
              url: '/receitas/sopa',
            },
          },
          {
            displayed_text: 'Salada',
            link: {
              url: '/receitas/salada',
            },
          },
          {
            displayed_text: 'Prato principal',
            link: {
              url: '/receitas/prato-principal',
            },
          },
          {
            displayed_text: 'Sobremesa',
            link: {
              url: '/receitas/sobremesa',
            },
          },
        ],
      },
      {
        displayed_text: 'nossos legumes',
        link: {
          url: '/produtos',
        },
        children: [{
            displayed_text: 'Novidades',
            link: {
              url: '/produtos/novos-produtos',
            },
          },
          {
            displayed_text: 'BIO',
            link: {
              url: '/produtos/bio',
            },
          },
          {
            displayed_text: 'PARA AS SUAS SALADAS',
            link: {
              url: '/produtos/para-as-suas-saladas',
            },
          },
          {
            displayed_text: 'PRONTO PARA COZINHAR',
            link: {
              url: '/produtos/pronto-para-cozinhar',
            },
          },
          {
            displayed_text: 'PARA AS SUAS RECEITAS',
            link: {
              url: '/produtos/para-as-suas-receitas',
            },
          },
          {
            displayed_text: 'Perguntas frequentes',
            link: {
              url: '/perguntas-frequentes',
            },
          },
        ],
      },
      {
        displayed_text: 'SOBRE NÓS',
        link: {
          url: '/sobre-nos/novidades',
        },
        children: [
          /*{
            displayed_text: 'Todas as novidades',
            link: {
              url: '/sobre-nos/novidades'
            }
          },*/
          {
            displayed_text: 'A nossa história',
            link: {
              url: '/sobre-nos/a-nossa-historia',
            },
          },
          {
            displayed_text: 'O grupo Bonduelle',
            link: {
              url: '/sobre-nos/inovacao-vegetal',
            },
          },
          {
            displayed_text: 'RESPONSABILIDADE SOCIAL CORPORATIVA',
            link: {
              url: '/sobre-nos/compromisso-rsc',
            },
          },
          {
            displayed_text: 'FONDATION LOUIS BONDUELLE',
            link: {
              url: 'http://www.fondation-louisbonduelle.org/pt-pt/',
              is_external: true,
            },
          },
          {
            displayed_text: 'DO CAMPO À MESA',
            link: {
              url: '/sobre-nos/do-campo-a-mesa',
            },
          },
        ],
      },
      {
        displayed_image: 'vegetaliciosobis',
        link: {
          url: '/blogue/novidades',
        },
      },
    ],
    footer_items: [{
        displayed_text: 'Sobre Bonduelle',
        children: [{
            displayed_text: 'O Grupo Bonduelle',
            link: {
              url: 'https://www.bonduelle.com/en',
              is_external: true,
            },
          },
          {
            displayed_text: 'Responsabilidade Social Corporativa',
            link: {
              url: '/sobre-nos/compromisso-rsc',
            },
          },
          {
            displayed_text: 'Do campo à mesa',
            link: {
              url: '/sobre-nos/do-campo-a-mesa',
            },
          },
          {
            displayed_text: 'Bonduelle Food Service',
            link: {
              url: 'https://www.bonduelle-foodservice.pt/',
              is_external: true,
            },
          },
          {
            displayed_text: 'Junte-se a nós',
            link: {
              url: 'https://jobs.bonduelle.com/?locale=pt_PT',
              is_external: true,
            },
          },
        ],
      },
      {
        displayed_text: 'Serviço ao Consumidor',
        children: [{
            displayed_text: 'Contacte-nos',
            link: {
              url: '/contacto',
            },
          },
          {
            displayed_text: 'Perguntas frequentes',
            link: {
              url: '/perguntas-frequentes',
            },
          },
        ],
      },
    ],
    sub_footer_items: [{
        translation_key: 'FOOTER.LINK.LEGAL_NOTICE',
        link: {
          url: '/mencoes-legais',
        },
      },
      {
        translation_key: 'FOOTER.LINK.PRIVACY_POLICY',
        link: {
          url: '/politica-de-privacidade',
        },
      },
      {
        translation_key: 'FOOTER.LINK.COOKIES',
        link: {
          url: '/politica-de-cookies',
        },
      },
      {
        translation_key: 'FOOTER.LINK.SITEMAP',
        link: {
          url: '/mapa-do-portal',
        },
      },
      {
        translation_key: 'FOOTER.LINK.ALL_RIGHTS_RESERVED',
      },
    ],
    share_buttons: [{
        name: 'facebook',
        url: 'https://www.facebook.com/BonduellePT/',
      },
      {
        name: 'instagram',
        url: 'https://www.instagram.com/bonduelle_pt/',
      },
    ],
    default_videos: [],
    theme: 'default-theme',
    catch_phrase: 'HOME.CATCH_PHRASE',
    published: false,
    packshot: false,
  },
  {
    lang: {
      country: 'it',
      code: 'it',
      default: true,
    },
    has_account: false,
    pictos_product: [],
    menu_items: [{
        displayed_text: 'I NOSTRI PRODOTTI',
        link: {
          url: '/prodotti',
        },
        children: [{
            displayed_text: 'Prodotti in evidenza',
            link: {
              url: '/prodotti/prodotti-in-evidenza',
            },
          },
          {
            displayed_text: 'Insalate',
            link: {
              url: '/prodotti/insalate',
            },
          },
          {
            displayed_text: 'Mais, verdure e legumi in conserva',
            link: {
              url: '/prodotti/conserve',
            },
          },
          {
            displayed_text: 'Surgelati',
            link: {
              url: '/prodotti/surgelati',
            },
          },
          {
            displayed_text: 'Piatti pronti',
            link: {
              url: '/prodotti/piatti-pronti',
            },
          },
          {
            displayed_text: 'Pronti per l\'aperitivo',
            link: {
              url: '/prodotti/pronti-per-l-aperitivo',
            },
          },
          {
            displayed_text: 'bio',
            link: {
              url: '/prodotti/bio',
            },
          },
        ],
      },
      {
        displayed_text: 'Ricette',
        link: {
          url: '/ricette-di-cucina',
        },
        children: [{
            displayed_text: 'Tutte le ricette',
            link: {
              url: '/ricette',
            },
          },
          {
            displayed_text: 'Antipasti e aperitivi',
            link: {
              url: '/ricette/antipasti-aperitivi',
            },
          },
          {
            displayed_text: 'Insalate e piatti unici',
            link: {
              url: '/ricette/insalate-piatti-unici',
            },
          },
          {
            displayed_text: 'Primi piatti',
            link: {
              url: '/ricette/primi-piatti',
            },
          },
          {
            displayed_text: 'Secondi piatti',
            link: {
              url: '/ricette/secondi-piatti',
            },
          },
          {
            displayed_text: 'Contorni',
            link: {
              url: '/ricette/contorni',
            },
          },
          {
            displayed_text: 'Dolci',
            link: {
              url: '/ricette/dolci',
            },
          },
        ],
      },
      {
        displayed_text: 'Dal 1853',
        link: {
          url: '/azienda/novita',
        },
        children: [{
            displayed_text: 'Il Gruppo',
            link: {
              url: '/azienda/il-gruppo',
            },
          },
          {
            displayed_text: 'I nostri impegni',
            link: {
              url: '/azienda/bonduelle-si-impegna',
            },
          },
          {
            displayed_text: 'La nostra filiera',
            link: {
              url: '/azienda/la-nostra-filiera',
            },
          },
          {
            displayed_text: 'Fondazione L.B.',
            link: {
              url: 'https://www.fondation-louisbonduelle.org/it',
              is_external: true,
            },
          },
          {
            displayed_text: 'Lavora con noi',
            link: {
              url: '/lavora-con-noi',
            },
          },
        ],
      },
      {
        displayed_text: 'VEGGIE PASSION',
        link: {
          url: '/veggie-passion/veggie-passion',
        },
        children: [{
            displayed_text: 'l\'ABC delle verdure',
            link: {
              url: '/azienda/abc-verdure',
            },
          },
          {
            displayed_text: '#veggiepassion',
            link: {
              url: '/veggie-passion/veggie-passion',
            },
          },
          {
            displayed_text: 'Alimentazione e curiosità',
            link: {
              url: '/veggie-passion/alimentazione-curiosita',
            },
          },
          {
            displayed_text: 'InOrto',
            link: {
              url: 'https://www.inorto.org/',
              is_external: true,
            },
          },
          {
            displayed_text: 'Riciblog',
            link: {
              url: 'https://www.riciblog.it/',
              is_external: true,
            },
          },
        ],
      },
    ],
    footer_items: [{
        displayed_text: 'Dal 1853',
        link: {
          url: '/azienda/novita',
        },
        children: [{
            displayed_text: 'Il Gruppo',
            link: {
              url: '/azienda/il-gruppo',
            },
          },
          {
            displayed_text: 'Bonduelle S\'impegna',
            link: {
              url: '/azienda/bonduelle-si-impegna',
            },
          },
          {
            displayed_text: 'La nostra filiera',
            link: {
              url: '/azienda/la-nostra-filiera',
            },
          },
          {
            displayed_text: 'Lavora con noi',
            link: {
              url: '/lavora-con-noi',
            },
          },
        ],
      },
      {
        displayed_text: 'Veggie Passion',
        link: {
          url: '/veggie-passion/veggie-passion',
        },
        children: [{
            displayed_text: 'l\'ABC delle verdure',
            link: {
              url: '/azienda/abc-verdure',
            },
          },
          {
            displayed_text: '#veggiepassion',
            link: {
              url: '/veggie-passion/veggie-passion',
            },
          },
          {
            displayed_text: 'Alimentazione e curiosità',
            link: {
              url: '/veggie-passion/alimentazione-curiosita',
            },
          },
          {
            displayed_text: 'InOrto',
            link: {
              url: 'https://www.inorto.org/',
              is_external: true,
            },
          },
          {
            displayed_text: 'Riciblog',
            link: {
              url: 'https://www.riciblog.it/',
              is_external: true,
            },
          },
        ],
      },
    ],
    sub_footer_items: [{
        translation_key: 'FOOTER.LINK.LEGAL_NOTICE',
        link: {
          url: '/note-legali',
        },
      },
      {
        translation_key: 'FOOTER.LINK.SITEMAP',
        link: {
          url: '/mappa-del-sito',
        },
      },
      {
        translation_key: 'FOOTER.LINK.PRIVACY_POLICY',
        link: {
          url: '/privacy-policy',
        },
      },
      {
        displayed_text: 'Il gruppo',
        link: {
          url: 'https://www.bonduelle.com/en/',
          is_external: true,
        },
      },
      {
        displayed_text: 'Bonduelle Food Service',
        link: {
          url: 'https://www.bonduelle-foodservice.it/',
          is_external: true,
        },
      },
      {
        translation_key: 'FOOTER.LINK.ALL_RIGHTS_RESERVED',
      },
    ],
    share_buttons: [{
        name: 'facebook',
        url: 'https://www.facebook.com/Bonduelle.Italia/',
      },
      {
        name: 'instagram',
        url: 'https://www.instagram.com/bonduelle_it/',
      },
      {
        name: 'youtube',
        url: 'https://www.youtube.com/user/BonduelleItalia',
      },
    ],
    additional_footer: [{
      displayed_text: 'Servizio clienti',
      children: [{
          displayed_text: 'Contattaci',
          link: {
            url: '/contatto',
          },
        },
        {
          displayed_text: 'FAQ',
          link: {
            url: '/faq',
          },
        },
      ],
    }, ],
    default_videos: [],
    theme: 'default-theme',
    catch_phrase: 'HOME.CATCH_PHRASE',
    published: false,
    packshot: true,
  },
  {
    lang: {
      country: 'se',
      code: 'sv',
      default: true,
    },
    has_account: false,
    pictos_product: [],
    menu_items: [{
        displayed_text: 'Recept',
        link: {
          url: '/recept',
        },
        children: [
          {
            displayed_text: 'Huvudrätter',
            link: {
              url: '/recept/huvudratter',
            },
          },
          {
            displayed_text: 'Små rätter & snacks',
            link: {
              url: '/recept/smaretter-snacks',
            },
          },
          {
            displayed_text: 'Sallader',
            link: {
              url: '/recept/sallader',
            },
          },
          {
            displayed_text: 'Söta & bakade varor',
            link: {
              url: '/recept/sota-bakadevaror',
            },
          },
        ],
      },
      {
        displayed_text: 'Produkter',
        link: {
          url: '/produkter',
        },
        children: [{
            displayed_text: 'Lunch Bowl',
            link: {
              url: '/produkter/lunch-bowl',
            },
          }
          ,{
            displayed_text: 'Majs',
            link: {
              url: '/produkter/majs',
            },
          }
        ],
      },
      {
        displayed_text: 'Sedan 1853',
        link: {
          url: '/sedan-1853/senaste-nytt',
        },
        children: [{
            displayed_text: 'Alla artiklar',
            link: {
              url: '/sedan-1853/senaste-nytt',
            },
          },
          {
            displayed_text: 'Vår historia',
            link: {
              url: '/sedan-1853/var-historia',
            },
          },
          {
            displayed_text: 'Våra engagemang',
            link: {
              url: '/sedan-1853/vart-atagande',
            },
          },
          {
            displayed_text: 'Konserver & innovationer',
            link: {
              url: '/sedan-1853/konserv-innovationer',
            },
          },
          {
            displayed_text: 'Från jord till bord',
            link: {
              url: '/sedan-1853/marken-bordet',
            },
          },
        ],
      },
      {
        displayed_text: 'Nyheter',
        link: {
          url: '/nyheter/senaste-nytt',
        },
      },
    ],
    footer_items: [{
        displayed_text: 'Om oss',
        children: [{
            displayed_text: 'Bonduelle-gruppen',
            link: {
              url: 'https://www.bonduelle.com/en/',
              is_external: true,
            },
          },
          {
            displayed_text: 'Våra engagemang',
            link: {
              url: 'sedan-1853/vart-atagande',
            },
          },
          {
            displayed_text: 'Louis Bonduelle Fonden',
            link: {
              url: 'http://www.fondation-louisbonduelle.org/en/',
              is_external: true,
            },
          },
          {
            displayed_text: 'Bonduelle Food Service',
            link: {
              url: 'https://www.bonduelle-foodservice.se/',
              is_external: true,
            },
          },
          {
            displayed_text: 'Jobba hos Bonduelle',
            link: {
              url: 'https://workingatbonduellenortherneurope.com/',
              is_external: true,
            },
          },
          {
            displayed_text: 'Kontrollrapport',
            link: {
              url: 'https://www.findsmiley.dk/503655',
              is_external: true,
            },
          },
        ],
      },
      {
        displayed_text: 'Kundeservice',
        children: [{
            displayed_text: 'Kontakta oss',
            link: {
              url: '/kontakta-oss',
            },
          },
          {
            displayed_text: 'FAQ',
            link: {
              url: '/vanliga-fragor',
            },
          },
        ],
      },
    ],
    sub_footer_items: [{
        translation_key: 'FOOTER.LINK.LEGAL_NOTICE',
        link: {
          url: '/juridiskt-meddelande',
        },
      },
      {
        translation_key: 'FOOTER.LINK.PRIVACY_POLICY',
        link: {
          url: '/extern-dataskyddspolicy',
        },
      },
      {
        translation_key: 'FOOTER.LINK.ALL_RIGHTS_RESERVED',
      },
    ],
    share_buttons: [{
        name: 'facebook',
        url: 'https://www.facebook.com/bonduellese',
      },
      {
        name: 'instagram',
        url: 'https://www.instagram.com/bonduelle_se',
      },
      {
        name: 'youtube',
        url: 'https://www.youtube.com/channel/UCb4v9IQEFCV_T1aWzm6V_pg/videos',
      },
    ],
    default_videos: [],
    theme: 'default-theme',
    catch_phrase: 'HOME.CATCH_PHRASE',
    published: false,
    packshot: true,
  },
  {
    lang: {
      country: 'no',
      code: 'no',
      default: true,
    },
    has_account: false,
    pictos_product: [],
    menu_items: [{
        displayed_text: 'Oppskrifter',
        link: {
          url: '/oppskrifter',
        },
        children: [
          {
            displayed_text: 'Hovedretter',
            link: {
              url: '/oppskrifter/hovedretter',
            },
          },
          {
            displayed_text: 'Småretter & snacks',
            link: {
              url: '/oppskrifter/smaretter-snacks',
            },
          },
          {
            displayed_text: 'Salater',
            link: {
              url: '/oppskrifter/salater',
            },
          },
          {
            displayed_text: 'Søte retter & bakst',
            link: {
              url: '/oppskrifter/soteretter-bakst',
            },
          },
        ],
      },
      {
        displayed_text: 'Produkter',
        link: {
          url: '/produkter',
        },
        children: [
          /*{
            displayed_text: 'Nyheder',
            link: {
              url: '/produkter/nyheder'
            }
          },*/
          {
            displayed_text: 'Mais',
            link: {
              url: '/produkter/mais',
            },
          },
          {
            displayed_text: 'Belgfrukter',
            link: {
              url: '/produkter/belgfrukter',
            },
          },
          {
            displayed_text: 'BØNNER I SAUS',
            link: {
              url: '/produkter/bonner-sauce',
            },
          },
          {
            displayed_text: 'Tex Mex',
            link: {
              url: '/produkter/tex-mex',
            },
          },
          {
            displayed_text: 'GRØNNE GRØNNSAKER',
            link: {
              url: '/produkter/gronne-gronnsaker',
            },
          },
          {
            displayed_text: 'Mini packs',
            link: {
              url: '/produkter/mini-packs',
            },
          },
        ],
      },
      {
        displayed_text: 'Siden 1853',
        link: {
          url: '/siden-1853/siste-nytt',
        },
        children: [{
            displayed_text: 'Alle artikler',
            link: {
              url: '/siden-1853/siste-nytt',
            },
          },
          {
            displayed_text: 'Vår historie',
            link: {
              url: '/siden-1853/var-historie',
            },
          },
          {
            displayed_text: 'Vårt engasjement',
            link: {
              url: '/siden-1853/vart-engasjement',
            },
          },
          {
            displayed_text: 'Hermetikk & innovasjoner',
            link: {
              url: '/siden-1853/hermetikk-innovasjoner',
            },
          },
          {
            displayed_text: 'Fra marken til bordet',
            link: {
              url: '/siden-1853/jord-til-bord',
            },
          },
        ],
      },
      {
        displayed_text: 'Nyheter',
        link: {
          url: '/nyheter/siste-nytt',
        },
      },
    ],
    footer_items: [{
        displayed_text: 'Om oss',
        children: [{
            displayed_text: 'Bonduelle Gruppen',
            link: {
              url: 'https://www.bonduelle.com/en/',
              is_external: true,
            },
          },
          {
            displayed_text: 'Vårt engasjement',
            link: {
              url: 'siden-1853/vart-engasjement',
            },
          },
          {
            displayed_text: 'Louis Bonduelle Fonden',
            link: {
              url: 'http://www.fondation-louisbonduelle.org/en/',
              is_external: true,
            },
          },
          {
            displayed_text: 'Bonduelle Food Service',
            link: {
              url: 'https://www.bonduelle-foodservice.dk/',
              is_external: true,
            },
          },
          {
            displayed_text: 'Jobbe hos Bonduelle',
            link: {
              url: 'https://workingatbonduellenortherneurope.com/',
              is_external: true,
            },
          },
          {
            displayed_text: 'Kontrolrapport Fødevarestyrelsen',
            link: {
              url: 'https://www.findsmiley.dk/503655',
              is_external: true,
            },
          },
        ],
      },
      {
        displayed_text: 'Kundeservice',
        children: [{
            displayed_text: 'Kontakt oss',
            link: {
              url: '/kontakt-oss',
            },
          },
          {
            displayed_text: 'FAQ',
            link: {
              url: '/vanlige-sporsmal',
            },
          },
        ],
      },
    ],
    sub_footer_items: [{
        translation_key: 'FOOTER.LINK.LEGAL_NOTICE',
        link: {
          url: '/juridisk-melding',
        },
      },
      {
        translation_key: 'FOOTER.LINK.PRIVACY_POLICY',
        link: {
          url: '/ekstern-personvernpolicy',
        },
      },
      {
        translation_key: 'FOOTER.LINK.ALL_RIGHTS_RESERVED',
      },
    ],
    share_buttons: [{
        name: 'facebook',
        url: 'https://www.facebook.com/BonduelleNo',
      },
      {
        name: 'youtube',
        url: 'https://www.youtube.com/channel/UCb4v9IQEFCV_T1aWzm6V_pg/videos',
      },
    ],
    default_videos: [],
    theme: 'default-theme',
    catch_phrase: 'HOME.CATCH_PHRASE',
    published: false,
    packshot: true,
  },
  {
    lang: {
      country: 'fi',
      code: 'fi',
      default: true,
    },
    has_account: false,
    pictos_product: [],
    menu_items: [{
        displayed_text: 'Reseptit',
        link: {
          url: '/reseptit',
        },
        children: [
          {
            displayed_text: 'Pääruoat',
            link: {
              url: '/reseptit/paaruoat',
            },
          },
          {
            displayed_text: 'Välipalat ja naposteltavat',
            link: {
              url: '/reseptit/valipalat-ja-naposteltavat',
            },
          },
          {
            displayed_text: 'Salaatit',
            link: {
              url: '/reseptit/salaatit',
            },
          },
          {
            displayed_text: 'Makeat & leivonnaiset',
            link: {
              url: '/reseptit/makeat-leivonnaiset',
            },
          },
        ],
      },
      {
        displayed_text: 'Tuotteet',
        link: {
          url: '/tuotteet',
        },
        children: [
          {
            displayed_text: 'Lunch Bowls',
            link: {
              url: '/tuotteet/lunch-bowl',
            },
          },
          {
            displayed_text: 'Maissi',
            link: {
              url: '/tuotteet/maissi',
            },
          },
          {
            displayed_text: 'Palkokasvit',
            link: {
              url: '/tuotteet/palkokasvit',
            },
          },
          {
            displayed_text: 'Pavut kastikkeessa',
            link: {
              url: '/tuotteet/pavut-kastikkeessa',
            },
          },
          {
            displayed_text: 'Vihreät vihannekset',
            link: {
              url: '/tuotteet/vihreat-vihannekset',
            },
          },
          {
            displayed_text: 'Mini packs',
            link: {
              url: '/tuotteet/mini-packs',
            },
          },
        ],
      },
      {
        displayed_text: 'Vuodesta 1853',
        link: {
          url: '/vuodesta-1853/viimeisimmat-uutiset',
        },
        children: [{
            displayed_text: 'Kaikki artikkelit',
            link: {
              url: '/vuodesta-1853/viimeisimmat-uutiset',
            },
          },
          {
            displayed_text: 'Historiamme',
            link: {
              url: '/vuodesta-1853/meidan-tarinamme',
            },
          },
          {
            displayed_text: 'Sitoumuksemme',
            link: {
              url: '/vuodesta-1853/meidan-sitoumuksemme',
            },
          },
          {
            displayed_text: 'Innovaatiomme',
            link: {
              url: '/vuodesta-1853/sailontainnovaatiot',
            },
          },
          {
            displayed_text: 'Pellolta pöytään',
            link: {
              url: '/vuodesta-1853/pellolta-poytaan',
            },
          },
        ],
      },
      {
        displayed_text: 'Uutiset',
        link: {
          url: '/uutiset/viimeisimmat-uutiset',
        },
      },
    ],
    footer_items: [{
        displayed_text: 'Tietoa meistä',
        children: [{
            displayed_text: 'Bonduelle-konserni',
            link: {
              url: 'https://www.bonduelle.com/en/',
              is_external: true,
            },
          },
          {
            displayed_text: 'Sitoumuksemme',
            link: {
              url: '/vuodesta-1853/meidan-sitoumuksemme',
            },
          },
          {
            displayed_text: 'Louis Bonduelle -säätiö',
            link: {
              url: 'http://www.fondation-louisbonduelle.org/en/',
              is_external: true,
            },
          },
          {
            displayed_text: 'Bonduelle Food Service',
            link: {
              url: 'https://www.bonduelle-foodservice.fi/',
              is_external: true,
            },
          },
          {
            displayed_text: 'Bonduelle työpaikkana',
            link: {
              url: 'https://workingatbonduellenortherneurope.com/',
              is_external: true,
            },
          },
        ],
      },
      {
        displayed_text: 'Kuluttajapalvelu',
        children: [{
            displayed_text: 'Ota yhteyttä',
            link: {
              url: '/ota-yhteytta',
            },
          },
          {
            displayed_text: 'FAQ',
            link: {
              url: '/faq',
            },
          },
        ],
      },
    ],
    sub_footer_items: [{
        translation_key: 'FOOTER.LINK.LEGAL_NOTICE',
        link: {
          url: '/juridinen-ilmoitus',
        },
      },
      {
        translation_key: 'FOOTER.LINK.PRIVACY_POLICY',
        link: {
          url: '/ulkoinen-tietosuojakaytanto',
        },
      },
      {
        translation_key: 'FOOTER.LINK.ALL_RIGHTS_RESERVED',
      },
    ],
    share_buttons: [],
    default_videos: [],
    theme: 'default-theme',
    catch_phrase: 'HOME.CATCH_PHRASE',
    published: false,
    packshot: true,
  },
  {
    lang: {
      country: 'ee',
      code: 'et',
      default: true,
    },
    has_account: false,
    pictos_product: [],
    menu_items: [{
        displayed_text: 'Retseptid',
        link: {
          url: '/retseptid',
        },
        children: [{
            displayed_text: 'Suupisted',
            link: {
              url: '/retseptid/suupisted',
            },
          },
          {
            displayed_text: 'Salatid',
            link: {
              url: '/retseptid/salatid',
            },
          },
          {
            displayed_text: 'Supid',
            link: {
              url: '/retseptid/supid',
            },
          },
          {
            displayed_text: 'Põhiroad',
            link: {
              url: '/retseptid/pohiroad',
            },
          },
          {
            displayed_text: 'Desserdid',
            link: {
              url: '/retseptid/desserdid',
            },
          },
        ],
      },
      {
        displayed_text: 'Tooted',
        link: {
          url: '/tooted/uudistooted',
        },
        children: [{
            displayed_text: 'Uudistooted',
            link: {
              url: '/tooted/uudistooted',
            },
          },
          {
            displayed_text: 'Moodne klassika',
            link: {
              url: '/tooted/moodne-klassika',
            },
          },
          {
            displayed_text: 'Salatitele',
            link: {
              url: '/tooted/salatitele',
            },
          },
          {
            displayed_text: 'Põhiroogadele',
            link: {
              url: '/tooted/pohiroogadele',
            },
          },
          {
            displayed_text: 'Suupistetele',
            link: {
              url: '/tooted/suupistetele',
            },
          },
        ],
      },
      {
        displayed_text: 'Aastast 1853',
        link: {
          url: '/alates-1853/bonduelle-st',
        },
        children: [{
            displayed_text: 'BONDUELLE’ST',
            link: {
              url: '/alates-1853/bonduelle-st',
            },
          },
          {
            displayed_text: 'Ettevõtte ajalugu',
            link: {
              url: '/alates-1853/bonduelle-leedus',
            },
          },
          {
            displayed_text: 'JÄTKUSUUTLIKKUS',
            link: {
              url: '/alates-1853/jatkusuutlikkus',
            },
          },
          {
            displayed_text: 'Köögiviljaentsüklopeedia',
            link: {
              url: '/alates-1853/koogiviljaentsuklopeedia',
            },
          },
          {
            displayed_text: 'Kuidas tagame kvaliteedi',
            link: {
              url: '/kuidas-tagame-kvaliteedi',
            },
          },
          {
            displayed_text: 'KKK',
            link: {
              url: '/kkk',
            },
          },
        ],
      },
      {
        displayed_text: 'Blog',
        link: {
          url: '/blog',
        },
        children: [{
          displayed_text: 'Teeme süüa',
          link: {
            url: '/blog/teeme',
          },
        }, ],
      },
    ],
    footer_items: [
      {
        displayed_text: 'Tarbijate teenindamine',
        children: [          {
            displayed_text: 'Bonduelle Food Service',
            link: {
              url: 'https://greenology.ee/',
              is_external: true
            }
          }
        ]
      },
      {
        displayed_text: 'Kasutajatugi',
        children: [{
            displayed_text: 'Kontaktid',
            link: {
              url: '/kontakt',
            },
          },
          {
            displayed_text: 'KKK',
            link: {
              url: '/kkk',
            },
          },
        ],
      },
    ],
    sub_footer_items: [{
        translation_key: 'FOOTER.LINK.COOKIES',
        link: {
          url: '/kupsised',
        },
      },
      {
        translation_key: 'FOOTER.LINK.PRIVACY_POLICY',
        link: {
          url: '/privaatsuspoliitika',
        },
      },
      {
        translation_key: 'FOOTER.LINK.ALL_RIGHTS_RESERVED',
      },
    ],
    share_buttons: [{
        name: 'facebook',
        url: 'https://www.facebook.com/BonduelleEesti',
      },
      {
        name: 'instagram',
        url: 'https://www.instagram.com/bonduelle_baltics/?hl=en',
      },
      {
        name: 'youtube',
        url: 'https://www.youtube.com/channel/UCefSxNMQBZlUWFLzE5qKEgg',
      },
    ],
    default_videos: [],
    theme: 'default-theme',
    catch_phrase: 'HOME.CATCH_PHRASE',
    published: false,
    packshot: false,
  },
  {
    lang: {
      country: 'lt',
      code: 'lt',
      default: true,
    },
    has_account: false,
    pictos_product: [],
    menu_items: [{
        displayed_text: 'Receptai',
        link: {
          url: '/receptai',
        },
        children: [{
            displayed_text: 'UŽKANDŽIAI',
            link: {
              url: '/receptai/uzkandziai',
            },
          },
          {
            displayed_text: 'Salotos',
            link: {
              url: '/receptai/salotos',
            },
          },
          {
            displayed_text: 'Sriubos',
            link: {
              url: '/receptai/sriubos',
            },
          },
          {
            displayed_text: 'Pagrindiniai patiekalai',
            link: {
              url: '/receptai/pagrindiniai-patiekalai',
            },
          },
          {
            displayed_text: 'Desertai',
            link: {
              url: '/receptai/desertai',
            },
          },
        ],
      },
      {
        displayed_text: 'Produktai',
        link: {
          url: '/produktai',
        },
        children: [{
            displayed_text: 'Visi produktai',
            link: {
              url: '/produktai/visi-produktai',
            },
          },
          {
            displayed_text: 'Naujienos',
            link: {
              url: '/produktai/naujienos',
            },
          },
          {
            displayed_text: 'Madinga klasika',
            link: {
              url: '/produktai/klasikinis',
            },
          },
          {
            displayed_text: 'Salotoms',
            link: {
              url: '/produktai/salotu-misinys',
            },
          },
          {
            displayed_text: 'Pagrindiniams patiekalams',
            link: {
              url: '/produktai/pagrindiniams-patiekalams',
            },
          },
          {
            displayed_text: 'Užkandžiams',
            link: {
              url: '/produktai/uzkandziai',
            },
          },
        ],
      },
      {
        displayed_text: 'nuo 1853',
        link: {
          url: '/nuo-1853/naujienos',
        },
        children: [{
            displayed_text: 'Apie Bonduelle',
            link: {
              url: '/nuo-1853/naujienos',
            },
          },
          {
            displayed_text: 'Kompanijos istorija',
            link: {
              url: '/nuo-1853/bonduelle-lietuvoie',
            },
          },
          {
            displayed_text: 'Tvarumas',
            link: {
              url: '/nuo-1853/mums-rupi',
            },
          },
          {
            displayed_text: 'Daržovių enciklopedija',
            link: {
              url: '/nuo-1853/darzoviu-enciklopediia',
            },
          },
          {
            displayed_text: 'Kaip mes užtikriname kokybę',
            link: {
              url: '/kaip-mes-uztikriname-kokybe',
            },
          },
          {
            displayed_text: 'duk',
            link: {
              url: '/duk',
            },
          },
        ],
      },
      {
        displayed_text: 'Blog\'as',
        link: {
          url: '/blog-as/pasigaminkime',
        },
        children: [{
            displayed_text: 'Pasigaminkime',
            link: {
              url: '/blog-as/pasigaminkime',
            },
          },
          {
            displayed_text: 'ĮDOMYBĖS',
            link: {
              url: '/blog-as/jdomybes',
            },
          },
        ],
      },
    ],
    footer_items: [
      {
        displayed_text: 'Vartotojų aptarnavimas',
        children: [{
            displayed_text: 'Kontaktai',
            link: {
              url: '/kontaktai',
            },
          },
          {
            displayed_text: 'DUK',
            link: {
              url: '/duk',
            },
          },
          {
            displayed_text: 'Bonduelle Food Service',
            link: {
              url: 'https://www.bonduelle-foodservice.lt/',
              is_external: true,
            },
          }
        ],
      },
    ],
    sub_footer_items: [{
        translation_key: 'FOOTER.LINK.COOKIES',
        link: {
          url: '/slapukai',
        },
      },
      {
        translation_key: 'FOOTER.LINK.PRIVACY_POLICY',
        link: {
          url: '/privatumo-politika',
        },
      },
      {
        translation_key: 'FOOTER.LINK.ALL_RIGHTS_RESERVED',
      },
    ],
    share_buttons: [{
        name: 'facebook',
        url: 'https://www.facebook.com/BonduelleLietuva/',
      },
      {
        name: 'instagram',
        url: 'https://www.instagram.com/bonduelle_baltics/?hl=en',
      },
      {
        name: 'youtube',
        url: 'https://www.youtube.com/channel/UCBiS_2VDxIp-u9ZPHPdG0Nw',
      },
    ],
    default_videos: [],
    theme: 'default-theme',
    catch_phrase: 'HOME.CATCH_PHRASE',
    published: false,
    packshot: false,
  },
  {
    lang: {
      country: 'lv',
      code: 'lv',
      default: true,
    },
    has_account: false,
    pictos_product: [],
    menu_items: [{
        displayed_text: 'Receptes',
        link: {
          url: '/receptes',
        },
        children: [{
            displayed_text: 'Uzkodas',
            link: {
              url: '/receptes/uzkodas',
            },
          },
          {
            displayed_text: 'Salāti',
            link: {
              url: '/receptes/salati',
            },
          },
          {
            displayed_text: 'Zupas',
            link: {
              url: '/receptes/zupas',
            },
          },
          {
            displayed_text: 'Pamatēdieni',
            link: {
              url: '/receptes/pamatedieni',
            },
          },
          {
            displayed_text: 'Deserti',
            link: {
              url: '/receptes/deserti',
            },
          },
        ],
      },
      {
        displayed_text: 'Produkti',
        link: {
          url: '/produkti',
        },
        children: [{
            displayed_text: 'Jaunie produkti',
            link: {
              url: '/produkti/jaunie-produkti',
            },
          },
          {
            displayed_text: 'Moderna klasika',
            link: {
              url: '/produkti/moderna-klasika',
            },
          },
          {
            displayed_text: 'Salātiem',
            link: {
              url: '/produkti/salatiem',
            },
          },
          {
            displayed_text: 'Pamatēdieniem',
            link: {
              url: '/produkti/pamatedieniem',
            },
          },
          {
            displayed_text: 'Uzkodām',
            link: {
              url: '/produkti/uzkodam',
            },
          },
        ],
      },
      {
        displayed_text: 'No 1853',
        link: {
          url: '/no-1853/par-bonduelle',
        },
        children: [{
            displayed_text: 'PAR BONDUELLE',
            link: {
              url: '/no-1853/par-bonduelle',
            },
          },
          {
            displayed_text: 'Uzņēmuma vēsture',
            link: {
              url: '/no-1853/bonduelle-latvija',
            },
          },
          {
            displayed_text: 'ILGTSPĒJĪBA',
            link: {
              url: '/no-1853/ilgtspejiba',
            },
          },
          {
            displayed_text: 'Dārzeņu enciklopēdija',
            link: {
              url: '/no-1853/darzenu-enciklopedija',
            },
          },
          {
            displayed_text: 'Kā mēs nodrošinām kvalitāti',
            link: {
              url: '/ka-mes-nodrosinam-kvalitati',
            },
          },
          {
            displayed_text: 'BUJ',
            link: {
              url: '/buj',
            },
          },
        ],
      },
      {
        displayed_text: 'Blog',
        link: {
          url: '/blog/pagatavosim',
        },
        children: [{
            displayed_text: 'pagatavosim',
            link: {
              url: '/blog/pagatavosim',
            },
          },
          {
            displayed_text: 'interesanti',
            link: {
              url: '/blog/interesanti',
            },
          },
        ],
      },
    ],
    footer_items: [
      {
        displayed_text: 'Lietotāju apkalpošana arba Patērētāju apkalpošana',
        children: [
          {
            displayed_text: 'Bonduelle Food Service',
            link: {
              url: 'https://greenology.lv/',
              is_external: true
            }
          },
        ]
      },
      {
        displayed_text: 'Patērētāju apkalpošana',
        children: [{
            displayed_text: 'Kontakti',
            link: {
              url: '/kontakti',
            },
          },
          {
            displayed_text: 'BUJ',
            link: {
              url: '/buj',
            },
          },
        ],
      },
    ],
    sub_footer_items: [{
        translation_key: 'FOOTER.LINK.COOKIES',
        link: {
          url: '/sikdatnes',
        },
      },
      {
        translation_key: 'FOOTER.LINK.PRIVACY_POLICY',
        link: {
          url: '/privatuma-politika',
        },
      },
      {
        translation_key: 'FOOTER.LINK.ALL_RIGHTS_RESERVED',
      },
    ],
    share_buttons: [{
        name: 'facebook',
        url: 'https://www.facebook.com/BonduelleLatvia',
      },
      {
        name: 'instagram',
        url: 'https://www.instagram.com/bonduelle_baltics/?hl=en',
      },
      {
        name: 'youtube',
        url: 'https://www.youtube.com/channel/UCtYPsJ4Yu4mVfXuDLsToqkw',
      },
    ],
    default_videos: [],
    theme: 'default-theme',
    catch_phrase: 'HOME.CATCH_PHRASE',
    published: false,
    packshot: false,
  },
  {
    lang: {
      country: 'il',
      code: 'he',
      default: true,
    },
    has_account: false,
    pictos_product: [],
    menu_items: [{
        displayed_text: 'מתכונים',
        link: {
          url: '/recipes',
        },
        children: [
        ],
      },
      {
        displayed_text: 'מוצרים',
        link: {
          url: '/vegetables/all-themes',
        },
        children: [],
      },
      {
        displayed_text: 'מאז 1853',
        link: {
          url: '/since-1853/news',
        },
        children: [{
            displayed_text: 'כל החדשות',
            link: {
              url: '/since-1853/news',
            },
          },
          {
            displayed_text: 'הסיפור שלנו',
            link: {
              url: '/since-1853/company-story',
            },
          },
          {
            displayed_text: 'מחויבות חברתית',
            link: {
              url: '/since-1853/csr-commitments',
            },
          },
        ],
      },
    ],
    footer_items: [{
        displayed_text: 'אודות',
        children: [{
            displayed_text: 'הקבוצה',
            link: {
              url: 'https://www.bonduelle.com/en',
              is_external: true,
            },
          }
        ],
      },
      {
        displayed_text: 'שירות לקוחות',
        children: [{
            displayed_text: 'צור קשר',
            link: {
              url: '/contact-israel',
            },
          },
          {
            displayed_text: 'שאלות ותשובות',
            link: {
              url: '/faq',
            },
          },
        ],
      },
    ],
    sub_footer_items: [{
        translation_key: 'FOOTER.LINK.PRIVACY_POLICY',
        link: {
          url: '/privacy-policy',
        },
      },
      {
        translation_key: 'FOOTER.LINK.ALL_RIGHTS_RESERVED',
      },
    ],
    share_buttons: [{
      name: 'facebook',
      url: 'https://www.facebook.com/BonduelleIL',
    }, ],
    default_videos: [],
    theme: 'il-theme',
    catch_phrase: 'HOME.CATCH_PHRASE',
    published: false,
    packshot: false,
  },
  {
    lang: {
      country: 'al',
      code: 'sq',
      default: true,
    },
    has_account: false,
    pictos_product: [],
    menu_items: [{
        displayed_text: 'Recetat',
        link: {
          url: '/receta',
        },
        children: [{
            displayed_text: 'Të gjitha recetat',
            link: {
              url: '/receta/te-gjitha-recetat',
            },
          },
          {
            displayed_text: 'Meze dhe snacks',
            link: {
              url: '/receta/meze-dhe-snacks',
            },
          },
          {
            displayed_text: 'Supat',
            link: {
              url: '/receta/supat',
            },
          },
          {
            displayed_text: 'Pjata kryesore',
            link: {
              url: '/receta/pjata-kryesore',
            },
          },
          {
            displayed_text: 'Sallatat',
            link: {
              url: '/receta/sallatat',
            },
          },
          {
            displayed_text: 'Ëmbëlsirat',
            link: {
              url: '/receta/embelsirat',
            },
          },
        ],
      },
      {
        displayed_text: 'Produktet tona',
        link: {
          url: '/produkte',
        },
        children: [{
            displayed_text: 'Të gjitha produktet',
            link: {
              url: '/produkte/te-gjitha-produktet',
            },
          },
          {
            displayed_text: 'Perimet e konservuara',
            link: {
              url: '/produkte/perimet-e-konservuara',
            },
          },
          {
            displayed_text: 'Perimet e ngrira',
            link: {
              url: '/produkte/perimet-e-ngrira',
            },
          },
        ],
      },
      {
        displayed_text: 'Rreth nesh',
        link: {
          url: '/qe-nga-1853/lajme-te-reja',
        },
        children: [{
            displayed_text: 'Historia jonë',
            link: {
              url: '/qe-nga-1853/historia-e-kompanise',
            },
          },
          {
            displayed_text: 'Zotimet tona',
            link: {
              url: '/qe-nga-1853/zotime-csr',
            },
          },
          {
            displayed_text: 'Nga fusha në pjatë',
            link: {
              url: '/frymezimi/artikull/nga-fusha-ne-pjate/3638/3638',
            },
          },
          //{
          //  displayed_text: 'Promocionet dhe konkurset',
          //  link: {
          //    url: '/promocionet-dhe-konkurset',
          //  },
          //},
          {
            displayed_text: 'Lajme dhe ngjarje',
            link: {
              url: '/lajme-dhe-ngjarje',
            },
          }
        ],
      },
      {
        displayed_text: 'Frymëzimi',
        link: {
          url: '/frymezimi',
        },
        children: [
          //{
          //  displayed_text: 'Përmbajtja e perimeve',
          //  link: {
          //    url: '/permbajtja-e-perimeve',
          //  },
          //},
          {
            displayed_text: 'Jeto shëndetshëm',
            link: {
              url: '/frymezimi/jeto-shendetshem',
            },
          },
          {
            displayed_text: 'Gatuaj shijshëm',
            link: {
              url: '/frymezimi/gatuaj-shijshem',
            },
          },
          {
            displayed_text: 'Përgatit me stil',
            link: {
              url: '/frymezimi/pergatit-me-stil',
            },
          },
          {
            displayed_text: 'Behu krijues',
            link: {
              url: '/frymezimi/behu-krijues',
            },
          },
          {
            displayed_text: 'Vegetables encyclopedia',
            link: {
              url: '/qe-nga-1853/fusha-pjata',
            },
          }
        ],
      },
    ],
    footer_items: [
      {
        displayed_text: 'Rhet',
        children: [
          {
            displayed_text: 'Grupi Bonduelle',
            link: {
              url: 'https://www.bonduelle.com/en/',
              is_external: true,
            },
        },
        {
          displayed_text: 'Fondacioni Louis Bonduelle',
          link: {
            url: 'https://www.fondation-louisbonduelle.org/en/',
            is_external: true,
          },
      }
      ],
      },
      {
        displayed_text: 'Shërbimi ndaj klientit ',
        children: [
          {
          displayed_text: 'Na kontaktoni',
          link: {
            url: '/kontaktoni',
          }
        },
         {displayed_text: 'FAQ',
          link: {
            url: '/faq'
          }
         }
      ]
      }
    ],
    sub_footer_items: [
      {
        translation_key: 'FOOTER.LINK.LEGAL_NOTICE',
        link: {
          url: '/njoftim-ligjor',
        },
      },
      {
        translation_key: 'FOOTER.LINK.PRIVACY_POLICY',
        link: {
          url: '/politikat-e-privatesise',
        },
      },
      {
        translation_key: 'FOOTER.LINK.ALL_RIGHTS_RESERVED',
      },
    ],
    share_buttons: [
      {
        name: 'youtube',
        url: 'https://www.youtube.com/channel/UC-okCimuBrlrZopaBfXr4DA',
      }
    ],
    default_videos: [],
    theme: 'default-theme',
    catch_phrase: 'HOME.CATCH_PHRASE',
    published: false,
    packshot: false,
  },
  {
    lang: {
      country: 'rs',
      code: 'sr',
      default: true,
    },
    has_account: false,
    pictos_product: [],
    menu_items: [{
        displayed_text: 'Recepti',
        link: {
          url: '/recepti',
        },
        children: [{
            displayed_text: 'Svi recepti',
            link: {
              url: '/recepti/svi-recepti',
            },
          },
          {
            displayed_text: 'Predjela i užine',
            link: {
              url: '/recepti/predjela-i-uzine',
            },
          },
          {
            displayed_text: 'Supe i čorbe',
            link: {
              url: '/recepti/supe-i-corbe',
            },
          },
          {
            displayed_text: 'Glavna jela',
            link: {
              url: '/recepti/glavna-jela',
            },
          },
          {
            displayed_text: 'Salate',
            link: {
              url: '/recepti/salate',
            },
          },
          {
            displayed_text: 'Deserti',
            link: {
              url: '/recepti/deserti',
            },
          },
        ],
      },
      {
        displayed_text: 'Naši proizvodi',
        link: {
          url: '/proizvodi',
        },
        children: [{
            displayed_text: 'Svi proizvodi',
            link: {
              url: '/proizvodi/svi-proizvodi',
            },
          },
          {
            displayed_text: 'Konzervirano povrće',
            link: {
              url: '/proizvodi/konzervirano-povrce',
            },
          }
        ],
      },
      {
        displayed_text: 'O nama',
        link: {
          url: '/od-1853/vesti',
        },
        children: [{
            displayed_text: 'Naša priča',
            link: {
              url: '/od-1853/prica-o-kompaniji',
            },
          },
          {
            displayed_text: 'Naša misija',
            link: {
              url: '/od-1853/drustvena-odgovornost',
            },
          },
          {
            displayed_text: 'Od polja do stola',
            link: {
              url: '/inspiracija/clanak/od-polja-do-stola/3639/3638',
            },
          },
          //{
          //  displayed_text: 'Promocije i takmičenja',
          //  link: {
          //    url: '/since-1853/good-deals',
          //  },
          //},
          {
            displayed_text: 'Novosti i događaji',
            link: {
              url: 'novosti-i-dogadaji',
            },
          }
        ],
      },
      {
        displayed_text: 'Inspiracija',
        link: {
          url: '/inspiracija',
        },
        children: [
          //{
          //  displayed_text: 'Sadržaj povrća',
          //  link: {
          //    url: '/menu-one',
          //  },
          //},
          {
            displayed_text: 'Zdrav život',
            link: {
              url: '/inspiracija/zdrav-zivot',
            },
          },
          {
            displayed_text: 'Kuvanje ukusnih jela',
            link: {
              url: '/inspiracija/kuvanje-ukusnih-jela',
            },
          },
          {
            displayed_text: 'Priprema jela sa stilom',
            link: {
              url: '/inspiracija/priprema-jela-sa-stilom',
            },
          },
          {
            displayed_text: 'Budite kreativni',
            link: {
              url: '/inspiracija/budite-kreativni',
            },
          },
          {
            displayed_text: 'Enciklopedija povrća',
            link: {
              url: '/od-1853/od-polja-do-tanjira',
            },
          },
        ],
      },
    ],
    footer_items: [{
      displayed_text: 'O',
      children: [{
        displayed_text: 'Bonduelle grupa',
        link: {
          url: 'https://www.bonduelle.com/en/',
          is_external: true,
        }
      },
      {
        displayed_text: 'Louis Bonduelle Fondacija ',
        link: {
          url: 'https://www.fondation-louisbonduelle.org/en/',
          is_external: true,
        }
      }
      ]
      },
      {
        displayed_text: 'Korisnički servis',
        children: [{
          displayed_text: 'Kontaktirajte nas',
          link: {
            url: '/kontakt'
          }
        },
        {
          displayed_text: 'FAQ',
          link: {
            url: '/faq'
          }
        }
        ]
      }
    ],
    sub_footer_items: [
      {
        translation_key: 'FOOTER.LINK.LEGAL_NOTICE',
        link: {
          url: '/pravno-obavestenje',
        },
      },
      {
        translation_key: 'FOOTER.LINK.PRIVACY_POLICY',
        link: {
          url: '/politika-privatnosti',
        },
      },
      {
        translation_key: 'FOOTER.LINK.ALL_RIGHTS_RESERVED',
      },
    ],
    share_buttons: [
      {
        name: 'youtube',
        url: 'https://www.youtube.com/channel/UCaG1Scc7i4LonK3LZ78TqVw',
      }
    ],
    default_videos: [],
    theme: 'default-theme',
    catch_phrase: 'HOME.CATCH_PHRASE',
    published: false,
    packshot: false,
  },
  {
    lang: {
      country: 'gr',
      code: 'el',
      default: true,
    },
    has_account: false,
    pictos_product: [],
    menu_items: [{
        displayed_text: 'ΣΥΝΤΑΓΕΣ',
        link: {
          url: '/syntages',
        },
        children: [{
            displayed_text: 'ΟΛΕΣ ΟΙ ΣΥΝΤΑΓΕΣ',
            link: {
              url: '/syntages/ola-ta-themata',
            },
          },
          {
            displayed_text: 'ΟΡΕΚΤΙΚΑ ΚΑΙ ΣΝΑΚ',
            link: {
              url: '/syntages/Σνακ-φίνγκερφουντ',
            },
          },
          {
            displayed_text: 'ΣΟΥΠΕΣ',
            link: {
              url: '/syntages/Σούπες-και-γκασπάτσο',
            },
          },
          {
            displayed_text: 'ΚΥΡΙΩΣ ΠΙΑΤΑ',
            link: {
              url: '/syntages/Κυρίως-πιάτα',
            },
          },
          {
            displayed_text: 'ΣΑΛΑΤΕΣ',
            link: {
              url: '/syntages/σαλάτες',
            },
          },
          {
            displayed_text: 'ΣΥΝΟΔΕΥΤΙΚΑ',
            link: {
              url: '/syntages/συνοδευτικά',
            },
          },
        ],
      },
      {
        displayed_text: 'ΤΑ ΠΡΟΪΟΝΤΑ ΜΑΣ',
        link: {
          url: '/proionta',
        },
        children: [{
            displayed_text: 'ΟΛΑ ΤΑ ΠΡΟΪΟΝΤΑ',
            link: {
              url: '/proionta/ola-ta-themata',
            },
          },
          {
            displayed_text: 'ΚΟΝΣΕΡΒΟΠΟΙΗΜΕΝΑ ΛΑΧΑΝΙΚΑ',
            link: {
              url: '/proionta/κονσέρβα',
            },
          },
          {
            displayed_text: 'ΚΑΤΕΨΥΓΜΕΝΑ ΛΑΧΑΝΙΚΑ',
            link: {
              url: '/proionta/κατεψυγμένο',
            },
          }
        ],
      },
      {
        displayed_text: 'ΣΧΕΤΙΚΑ ΜΕ ΕΜΑΣ',
        link: {
          url: '/apo-1853/nea',
        },
        children: [
          {
            displayed_text: 'Η ΙΣΤΟΡΙΑ ΜΑΣ',
            link: {
              url: '/apo-1853/e-istoria-tes-bonduelle',
            },
          },
          {
            displayed_text: 'Η ΔΕΣΜΕΥΣΗ ΜΑΣ',
            link: {
              url: '/apo-1853/desmefseis-csr',
            },
          },
          {
            displayed_text: 'ΝΕΑ ΚΑΙ ΔΡΑΣΕΙΣ',
            link: {
              url: '/apo-1853/fytikes-kainotomies',
            },
          },
          {
            displayed_text: 'ΑΠΟ ΤΟΝ ΑΓΡΟ ΣΤΟ ΠΙΑΤΟ ΣΑΣ',
            link: {
              url: '/selida-menu/arthro/apo-ton-agro-sto-piato-sas/4349/4349',
            },
          },
          {
            displayed_text: 'ΠΡΟΩΘΗΣΕΙΣ ΚΑΙ ΔΙΑΓΩΝΙΣΜΟΙ',
            link: {
              url: '/promotion',
            },
          },
        ],
      },
      {
        displayed_text: 'ΕΜΠΝΕΥΣΟΥ',
        link: {
          url: '/selida-menu',
        },
        children: [
          {
            displayed_text: 'ΖΗΣΕ ΥΓΙΕΙΝΑ',
            link: {
              url: '/selida-menu/zese-ygieina',
            },
          },
          {
            displayed_text: 'ΜΑΓΕΙΡΕΨΕ ΝΟΣΤΙΜΑ',
            link: {
              url: '/selida-menu/mageirepse-nostima',
            },
          },
          {
            displayed_text: 'ΠΡΟΣΘΕΣΕ ΣΤΥΛ',
            link: {
              url: '/selida-menu/morpho',
            },
          },
          {
            displayed_text: 'ΔΗΜΙΟΥΡΓΗΣΕ',
            link: {
              url: '/selida-menu/gine-demioyrgike',
            },
          },
          {
            displayed_text: 'ΛΑΧΑΝΟΛΕΞΙΚΟ',
            link: {
              url: '/apo-to-1853/pedio',
            },
          },
        ]
      },
    ],
    footer_items: [{
        displayed_text: 'ΣΧΕΤΙΚΑ',
        children: [{
            displayed_text: 'BONDUELLE GROUP',
            link: {
              url: 'https://www.bonduelle.com/en/',
              is_external: true,
            },
          },
          {
            displayed_text: 'ΚΑΙ ΤΟ ΙΔΡΥΜΑ LOUIS BONDUELLE',
            link: {
              url: 'http://www.fondation-louisbonduelle.org/en/',
              is_external: true,
            },
          }
        ],
      },
      {
        displayed_text: 'ΕΞΥΠΗΡΕΤΗΣΗ ΠΕΛΑΤΩΝ ',
        children: [{
            displayed_text: 'ΕΠΙΚΟΙΝΩΝΗΣΤΕ ΜΑΖΙ ΜΑΣ',
            link: {
              url: '/epikoinonia',
            },
          },
          {
            displayed_text: 'FAQ',
            link: {
              url: '/syhnes-erotiseis',
            },
          },
        ],
      },
    ],
    sub_footer_items: [{
        translation_key: 'FOOTER.LINK.PRIVACY_POLICY',
        link: {
          url: '/politiki-idiotikotitas-cookies',
        },
      },
     {
        translation_key: 'FOOTER.LINK.LEGAL_NOTICE',
        link: {
          url: '/nomiki-anakoinosi',
        },
      },
      {
        translation_key: 'FOOTER.LINK.ALL_RIGHTS_RESERVED',
      },
    ],
    share_buttons: [{
      name: 'facebook',
      url: 'https://www.facebook.com/BonduelleGr',
      },
      {
        name: 'instagram',
        url: 'https://www.instagram.com/bonduelle_gr/',
      },
      {
        name: 'youtube',
        url: 'https://www.youtube.com/c/BonduelleGreece',
      },
    ],
    default_videos: [],
    theme: 'gr-theme',
    catch_phrase: 'HOME.CATCH_PHRASE',
    published: false,
    packshot: false,
  },
  {
    lang: {
      country: 'ro',
      code: 'ro',
      default: true,
    },
    has_account: false,
    pictos_product: [],
    menu_items: [{
        displayed_text: 'REȚETE',
        link: {
          url: '/retete',
        },
        children: [
          {
            displayed_text: 'APERITIVE ȘI GUSTĂRI',
            link: {
              url: '/retete/aperitive-si-gustari',
            },
          },
          {
            displayed_text: 'SUPE ȘI CIORBE',
            link: {
              url: '/retete/supe-si-ciorbe',
            },
          },
          {
            displayed_text: 'FELURI PRINCIPALE',
            link: {
              url: '/retete/feluri-principale',
            },
          },
          {
            displayed_text: 'SALATE',
            link: {
              url: '/retete/salate',
            },
          },
          {
            displayed_text: 'DESERTURI',
            link: {
              url: '/retete/deserturi',
            },
          },
          // {
          //   displayed_text: 'GARNITURI',
          //   link: {
          //     url: '/retete/garnituri',
          //   },
          // },
        ],
      },
      {
        displayed_text: 'PRODUSELE NOASTRE',
        link: {
          url: '/produse',
        },
        children: [
          {
            displayed_text: 'PRODUSE NOI',
            link: {
              url: '/produse/produs-stiri',
            },
          },
          {
            displayed_text: 'LEGUME ÎN CONSERVĂ',
            link: {
              url: '/produse/legume-in-conserva',
            },
          },
          {
            displayed_text: 'LEGUME CONGELATE',
            link: {
              url: '/produse/legume-congelate',
            },
          }
        ],
      },
      {
        displayed_text: 'DESPRE NOI',
        link: {
          url: '/despre-noi/stiri',
        },
        children: [
          {
            displayed_text: 'POVESTEA NOASTRĂ',
            link: {
              url: '/despre-noi/povestea-noastra',
            },
          },
          {
            displayed_text: 'ANGAJAMENTELE NOASTRE',
            link: {
              url: '/despre-noi/responsabilitate-sociala',
            },
          },
          {
            displayed_text: 'ȘTIRI ȘI EVENIMENTE',
            link: {
              url: '/despre-noi/stiri-si-evenimente',
            },
          },
          {
            displayed_text: 'DE PE CAMP ÎN FARFURIE',
            link: {
              url: '/inspiratie/articol/de-pe-camp-farfurie/4560/3638',
            },
          },
          {
            displayed_text: 'PROMOȚII ȘI CONCURSURI',
            link: {
              url: '/promotii',
            },
          }
        ],
      },
      {
        displayed_text: 'INSPIRAȚIE',
        link: {
          url: '/inspiratie',
        },
        children: [
          {
            displayed_text: 'TRĂIEȘTE SĂNĂTOS',
            link: {
              url: '/inspiratie/traieste-sanatos',
            },
          },
          {
            displayed_text: 'GĂTEȘTE DELICIOS',
            link: {
              url: '/inspiratie/gateste-delicios',
            },
          },
          {
            displayed_text: 'PREPARĂ CU STIL',
            link: {
              url: '/inspiratie/prepara-cu-stil',
            },
          },
          {
            displayed_text: 'FII CREATIV',
            link: {
              url: '/inspiratie/fii-creativ',
            },
          },
          {
            displayed_text: 'ENCICLOPEDIA LEGUMELOR',
            link: {
              url: '/despre-noi/enciclopedia-legumelor',
            },
          }
        ],
      },
    ],
    footer_items: [{
        displayed_text: 'Despre noi',
        children: [{
            displayed_text: 'Grupul Bonduelle',
            link: {
              url: 'https://www.bonduelle.com/en/',
              is_external: true,
            },
          },
          {
            displayed_text: 'Fundatia Louis Bonduelle',
            link: {
              url: 'http://www.fondation-louisbonduelle.org/en/',
              is_external: true,
            },
          },
          {
            displayed_text: 'Bonduelle Food Service',
            link: {
              url: 'https://www.bonduelle-foodservice.ro/',
              is_external: true,
            },
          }
        ],
      },
      {
        displayed_text: 'Suport clienți',
        children: [{
            displayed_text: 'Contactează-ne',
            link: {
              url: '/contact',
            },
          },
          {
            displayed_text: 'FAQ',
            link: {
              url: '/intrebari-frecvente',
            },
          },
        ],
      },
    ],
    sub_footer_items: [
      {
      translation_key: 'FOOTER.LINK.LEGAL_NOTICE',
      link: {
        url: '/aviz-juridic',
      },
      },
      {
        translation_key: 'FOOTER.LINK.PRIVACY_POLICY',
        link: {
          url: '/politica-de-confidentialitate',
        },
      },
      {
        displayed_text: 'A.N.P.C.',
        link: {
          url: 'http://www.anpc.gov.ro/',
          is_external: true,
        },
      },
      {
        translation_key: 'FOOTER.LINK.ALL_RIGHTS_RESERVED',
      },
    ],
    share_buttons: [{
      name: 'facebook',
      url: 'https://www.facebook.com/BonduelleRo',
      },
      {
        name: 'youtube',
        url: 'https://www.youtube.com/c/BonduelleRomania',
      },
      {
        name: 'instagram',
        url: 'https://www.instagram.com/bonduelle.ro',
      },
      {
        name: 'tiktok',
        url: 'https://www.tiktok.com/@bonduelle_ro',
      }
    ],
    default_videos: [],
    theme: 'default-theme',
    catch_phrase: 'HOME.CATCH_PHRASE',
    published: false,
    packshot: false,
  }
];
