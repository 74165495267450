import { InjectionToken, Injectable } from '@angular/core';

import { SfMediaImage } from '@app/sf-lib/lib/features/image';

export const PAGE_INFO: InjectionToken<PageInfo[]> = new InjectionToken<PageInfo[]>('PAGE_INFO');

@Injectable()
export class PageInfo {

  // Type
  public isLandingPage: boolean;

  // Configuration
  public displayHeader: boolean;
  public displayFooter: boolean;
  public displayFullWidth: boolean;
  public backgroundCover: SfMediaImage;

  constructor() {
    this.isLandingPage = false;
    this.displayHeader = true;
    this.displayFooter = true;
    this.displayFullWidth = false;
    this.backgroundCover = null;
  }

}
