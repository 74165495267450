import { Component, Inject, Input, OnInit } from '@angular/core';

import { SfSiteInfo, SITE_INFO } from "@app/sf-lib/lib/core/localize";
import { SomSectionImage } from '../models/section-image.model';

@Component({
    selector: 'som-section-image',
    templateUrl: './section-image.component.html',
    styleUrls: ['./section-image.component.sass'],
    standalone: false
})
export class SectionImageComponent implements OnInit {

  @Input() section: SomSectionImage;

  @Input() seoTitle: 'H1'|'H2'|'H3'|'DIV' = 'DIV';

  public groupSEE = this.siteInfo.groupSEE;

  constructor(
    @Inject(SITE_INFO) private siteInfo: SfSiteInfo,
  ) {}

  ngOnInit(): void {
  }

}
