import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { Observable } from 'rxjs';

declare let Didomi;
const CLICK2BUY_ID = 'c:click2buy-q6MchTXT';

@Injectable({
    providedIn: 'root'
})
export class DidomiService {

    private renderer: Renderer2
    private listConsent = [
        "cookies", "select_basic_ads", "create_ads_profile", 
        "select_personalized_ads", "create_content_profile", 
        "select_personalized_content", "measure_ad_performance", 
        "measure_content_performance", "market_research", "improve_products"
    ];

    constructor(
        @Inject(DOCUMENT) private documentElement: Document,
        @Inject(PLATFORM_ID) protected platformId: Object,
        private rendererFactory: RendererFactory2,

    ) {
        this.renderer = this.rendererFactory.createRenderer(null, null);
    }


    public generateClick2BuyScript() {
        this._generateDidomiScript(CLICK2BUY_ID, this._generateClickToBuyScript.bind(this))
    }

    public removeScriptsFromDocument(): void {
        const click2buyScript = this.documentElement.querySelector(`script[data-vendor="${CLICK2BUY_ID}"]`);

        if (click2buyScript) { this.renderer.removeChild(this.documentElement.body, click2buyScript); }

    }
    
    public getCookieConsent(vendor: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this._generateDidomiScript(vendor, () => {
                const consentStatusForPurpose = Didomi.getUserConsentStatusForPurpose('cookies');
                resolve(consentStatusForPurpose)
            })
        })
    }

    public validVendor(vendor: string, callback?: () => void): void {
        if (isPlatformBrowser(this.platformId)) {
            this._waitForDidomi().then(() => {
                const consent = Didomi.getUserStatus();
                const isVendorEnabled = consent.vendors.consent.enabled.includes(vendor);
                const isVendorDisabled = consent.vendors.consent.disabled.includes(vendor);

                if (!isVendorEnabled) {
                    consent.vendors.consent.enabled.push(vendor);
                }
                if (isVendorDisabled) {
                    consent.vendors.consent.disabled.splice(consent.vendors.consent.disabled.indexOf(vendor), 1);
                }

                // Activer les cookies si désactivés
                const isCookiesDisabled = consent.purposes.consent.disabled.includes('cookies');
                
                if (isCookiesDisabled) {
                    consent.purposes.consent.disabled.splice(consent.purposes.consent.disabled.indexOf('cookies'), 1);
                    consent.purposes.consent.enabled.push('cookies');
                }
            
                if (vendor === 'CLICK2BUY_ID') {
                    consent.purposes.consent.disabled = [];
                    consent.purposes.consent.enabled = this.listConsent;
                }

                Didomi.setUserStatus({
                    purposes: consent.purposes,
                    vendors: consent.vendors
                });

                setTimeout(() => {
                    const transaction = Didomi.openTransaction();
                    transaction.commit();
                    callback()
                }, 500);
            });
        }         
        
      }


    private _generateDidomiScript(vendorId: string, callback: (vendorId: string) => void): void {
        if (isPlatformBrowser(this.platformId)) {
            this._waitForDidomi().then(() => {
                this._handleVendorConsent(vendorId, callback.bind(this));
            });
        }
    }


    private _waitForDidomi(timeout = 5000, intervalTime = 100): Promise<void> {
        return new Promise((resolve, reject) => {
            let timeElapsed = 0;
            const interval = setInterval(() => {
                timeElapsed += intervalTime;
                if (typeof Didomi !== 'undefined') {
                    clearInterval(interval);
                    resolve();
                } else if (timeElapsed >= timeout) {
                    clearInterval(interval);
                    console.error("Didomi n'a pas pu être chargé dans le temps imparti (5 secondes)")
                    reject('Didomi n\'a pas pu être chargé dans le temps imparti.');
                }
            }, intervalTime);
        });
    }

    private _getUserConsentStatusForVendor(id: string): Observable<boolean> {
        if (typeof Didomi.getObservableOnUserConsentStatusForVendor === 'function') {
            return Didomi.getObservableOnUserConsentStatusForVendor(id)
        }
    }

    private _generateClickToBuyScript(id: string): void {
        const clic2buyElement = this.renderer.createElement('script');
        clic2buyElement.async = true;
        clic2buyElement.type = 'text/javascript';
        clic2buyElement.setAttribute('data-vendor', id);
        clic2buyElement.src = 'https://widget.clic2buy.com/c2b.js?locale=fr-FR';
        this.renderer.appendChild(this.documentElement.body, clic2buyElement);
    }


    private _handleVendorConsent(vendorId: string, callback: (vendorId: string) => void): void {
        this._getUserConsentStatusForVendor(vendorId).subscribe((consent: boolean) => {
            if (consent) {
                callback(vendorId);
            }
        })
    }

    
  

}
