import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SfSliderComponent } from './components/slider/slider.component';
import { SfSlideComponent } from './components/slide/slide.component';
import { SfBreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { SfImageModule } from '../image/image.module';
import { SfLinkModule } from '../link/link.module';
import { SfVideoModule } from '../video/video.module';
import { SfVideoSlideComponent } from './components/video-slide/video-slide.component';
import { SfProductSlideComponent } from './components/product-slide/product-slide.component';
import { SfProductSliderComponent } from './components/product-slider/product-slider.component';
import { SfUtilsModule } from '../../core/utils/utils.module';
import { SfSectionSliderComponent } from './components/section-slider/section-slider.component';
import { UtilsModule } from '@app/shared/utils/utils.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    UtilsModule,
    SfUtilsModule,
    SfImageModule,
    SfLinkModule,
    SfVideoModule,
  ],
  providers: [],
  declarations: [
    SfSliderComponent,
    SfSlideComponent,
    SfVideoSlideComponent,
    SfBreadcrumbComponent,
    SfProductSlideComponent,
    SfProductSliderComponent,
    SfSectionSliderComponent,
  ],
  exports: [
    SfSliderComponent,
    SfSlideComponent,
    SfVideoSlideComponent,
    SfProductSlideComponent,
    SfProductSliderComponent,
    SfSectionSliderComponent
  ]
})
export class SfSliderModule { }
