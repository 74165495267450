/* tslint:disable */export const nl_nl = {
  '404': {
    TITLE: 'Oeps...',
    PAGENOTFOUND: 'Deze pagina bestaat niet',
    BUTTON: 'Terug naar de homepage',
    RECIPES: 'Ontdek onze recepten'
  },
  HOME: {
    SHOWCASE: 'Recepten',
    CATCH_PHRASE: 'De verrassende groente van vandaag... voor een beter morgen',
    SHOWCASE_SEE_ALL: 'Bekijk al onze recepten',
    MAGAZINE: { TITLE: 'TOP', BUTTON: 'Ontdek Légumiz' },
    OUR_VEGETABLES: 'Onze producten',
    SOCIAL_WALL: 'Laten we samen de passie van groenten delen',
    FAQ_TITLE: 'Veelgestelde vragen',
    FAQ_SEE_MORE: 'See more',
    FLOWBOX: ''
  },
  SELECT: { DEFAULT_VALUE: 'Bonduelle selectie' },
  MENU: {
    CONTACT_US: 'Neem contact met ons op',
    MY_ACCOUNT: 'Mijn account',
    GOOD_DEALS: 'Promoties ',
    NEWSLETTER: 'Bonduelle Nieuwsbrief'
  },
  FOOTER: {
    LINK: {
      BONDUELLE: 'Bonduelle.nl',
      CREDITS: 'Credits',
      LEGAL_NOTICE: 'Gebruiksvoorwaarden',
      TERMS_OF_SALES: 'Algemene verkoopvoorwaarden',
      COOKIES: 'Cookies',
      SITEMAP: 'Sitemap',
      PRIVACY_POLICY: 'Privacybeleid',
      ALL_RIGHTS_RESERVED: '2019 © Alle rechten voorbehouden'
    },
    HISTORY: {
      TITLE: 'Over Bonduelle',
      TEXT: 'Hi. Wij zijn Bonduelle. De verrassende groente van vandaag, met onze blik op morgen. Want volgens ons ziet morgen er elke dag weer beter uit. Door alle kleine beetjes die we vandaag doen. Die stappen beginnen klein. Gewoon vanaf de keukentafel. Met ons dinerbord. Wij maken namelijk samen het verschil, simpelweg door lekker en beter te eten. Want, als we met z’n allen méér plantaardig eten, dan zorgen we niet alleen beter voor onszelf, maar ook voor de wereld. Op ons bord valt dus een wereld te winnen. Letterlijk! '
    },
    NEWSLETTER: {
      TITLE: 'Bonduelle Community',
      TEXT: 'Ik accepteer de nieuwsbrief «Alles over groenten van Bonduelle» (nieuws, promotie, prijsvragen, artikelen...)',
      PLACEHOLDER: 'Jouw email',
      GDPR_LINK: 'Meer over het beheren van je gegevens'
    }
  },
  PRODUCTS: 'Producten',
  MAGAZINE: {
    HOME: 'Welkom bij het magazine',
    CATCH_PHRASE: '',
    MENU: { BONDUELLE_LINK: 'Ga naar bonduelle.be' },
    SHOWCASE: 'Overzicht',
    PREVIOUS_ARTICLES: 'Vorige artikelen',
    PREVIOUS_ARTICLES_SEE_ALL: 'Bekijk al onze artikelen',
    PREVIOUS_EDITIONS: 'Onze vorige edities',
    PREVIOUS_EDITIONS_SEE_ALL: 'Bekijk alle edities',
    PREVIOUS_ARTICLES_BY_CATEGORY: 'Vorige artikelen per categorie',
    RUBRIC: {
      SEE_ALL: 'Bekijk alle recepten',
      SEE_ALL_ARTICLES: 'Bekijk alle artikelen',
      ON: 'Artikelen over'
    },
    RUBRIC_COOK: { ON: 'Recepten van' },
    ARTICLE: { JOIN_US: 'Doe met ons mee', READ_ALSO: 'Ook interessant' },
    RECIPE: {
      INGREDIENTS: 'Ingrediënten',
      INGREDIENTS_LABEL_DESC: 'voor {{value}} personen',
      INGREDIENTS_LABEL_ONE_DESC: 'voor {{value}} person',
      INGREDIENTS_LABEL: 'Ingrediënten voor {{value}} personen',
      INGREDIENTS_LABEL_ONE: 'Ingrediënten voor {{value}} personen',
      PEOPLES: '{{value}} personen',
      PEOPLE: '{{value}} person',
      DIFFICULTY: { EASY: 'Gemakkelijk', MEDIUM: 'Gemiddeld', HARD: 'Moeilijk' },
      COST: { CHEAP: 'Goedkoop', EXPENSIVE: 'Duur' },
      TIMING: { MIN: 'min', HOURS: 'uur' },
      STEP: 'Stap',
      DISCOVER_ALSO: 'Ontdek ook'
    }
  },
  ARTICLE: { DISCOVER_ALSO: 'Ontdek ook...', INGREDIENTS: 'Ingrediënten' },
  SF: {
    SLIDE: { DISCOVER: 'Lees verder' },
    SEARCH: { PLACEHOLDER: 'Zoeken', ALL: 'Alles' },
    NEWSLETTER: {
      BTN_LABEL: 'Ik registreer',
      PLACEHOLDER: 'Jouw email',
      ERROR_MESSAGE: 'Ongeldige e-mail',
      ALREADY_SUBSCRIBED: 'Je bent al ingeschreven',
      GDPR: {
        TXT: 'Meer informatie over het beheren van je gegevens',
        LINK: '/privacybeleid'
      }
    },
    MONTHLY_FOLDER: { TITLE: 'Nieuws' },
    MINI_NEWSLETTER: {
      PLACEHOLDER: 'Jouw email',
      GDPR: {
        TXT: 'Meer informatie over het beheren van je gegevens',
        LINK: '/privacybeleid'
      }
    },
    SOCIALITEM: { SHARE: 'Delen', COMMENT: 'Reageren', BONDUELLE: 'Bonduelle' },
    SEARCHRECIPE: { DISCOVER_ALL: 'Zoek in alle recepten' },
    FORM: {
      ERROR: 'Ongeldig veld',
      PHOTO: {
        ADD: 'Voeg een foto toe',
        DELETE: 'Verwijder een foto',
        SEE: 'Bekijken'
      }
    }
  },
  CORPORATE: {
    COMPANY_STORY: 'Geschiedenis',
    CSR_COMMITMENT: 'MVO',
    VEGETABLE_INNOVATIONS: 'Innovatie',
    NEWS: 'Alle activiteiten',
    SEE_MORE: 'Bekijk meer artikelen',
    ON: 'Artikelen over'
  },
  NEWSLETTER: { TITLE: '', SUBTITLE: '' },
  ROUTES: {
    products: 'producten',
    news: 'nieuws',
    'since-1853': 'over-ons',
    'field-plate': 'van-het-veld-tot-het-bord',
    'company-story': 'geschiedenis',
    'csr-commitments': 'mvo',
    'vegetal-innovations': 'innovatie',
    magazine: 'magazine',
    all: 'allemaal',
    article: 'artikel',
    cook: 'koken',
    cultivate: 'verbouwen',
    explore: 'onderzoeken',
    recipe: 'wait-recept',
    recipes: 'recept',
    'recipes-home': 'recepten',
    r: 'r',
    credits: 'credits',
    'legal-notice': 'gebruiksvoorwaarden',
    'terms-of-sales': 'verkoopvoorwaarden',
    cookies: 'cookies',
    sitemap: 'sitemap',
    'privacy-policy': 'privacybeleid',
    'all-rights-reserved': 'alle-rechten-voorbehouden',
    search: 'zoek',
    'articles-search': 'artikel',
    vegetables: 'producten',
    p: 'p',
    contact: 'neem-contact-met-ons-op',
    'info-product': 'vragen-product',
    'note-product': 'product-opmerking',
    suggestion: 'suggestie',
    'other-request': 'ander-verzoek',
    faq: 'faq',
    'all-themes': 'alle',
    'products-search': 'producten-search',
    recipesearch: 'recepten-search',
    'product-news': 'nieuwe-producten',
    'good-deals': 'promoties',
    'not-found': 'niet-gevonden',
    'menu-page': 'voor-een-beter-morgen',
    'menu-one': 'plantaardige-inspiratie',
    'menu-two': 'recyclebare-verpakkingen',
    'menu-three': 'biodiversiteit-en-bodem',
    'menu-four': 'voedselverspilling',
    'menu-external': '',
    'theme-pasta-rice': '',
    'theme-bowls': '',
    'theme-soup': '',
    'theme-snack': '',
    'theme-sauce': '',
    'theme-unique-plate': '',
    'theme-over': '',
    'theme-legumin': 'peulvruchten',
    'theme-ingredients-recipes': '',
    'theme-cook-plate': ''
  },
  BREAD: {
    products: 'producten',
    news: 'nieuws',
    'since-1853': 'over-ons',
    'field-plate': 'van-het-veld-tot-het-bord',
    'company-story': 'geschiedenis',
    'csr-commitments': 'mvo',
    'vegetal-innovations': 'innovatie',
    magazine: 'magazine',
    all: 'allemaal',
    article: 'artikel',
    cook: 'koken',
    cultivate: 'verbouwen',
    explore: 'onderzoeken',
    recipe: 'wait-recept',
    recipes: 'recept',
    'recipes-home': 'recepten',
    'articles-search': 'artikel',
    search: 'zoek',
    vegetables: 'producten',
    contact: 'neem-contact-met-ons-op',
    'info-product': 'vragen-product',
    'note-product': 'product-opmerking',
    suggestion: 'suggestie',
    'other-request': 'ander-verzoek',
    faq: 'faq',
    'all-themes': 'alle',
    'products-search': 'producten-search',
    recipesearch: 'recepten-search',
    'product-news': 'nieuwe-producten',
    'good-deals': 'promoties',
    'not-found': 'niet-gevonden',
    'menu-page': 'voor een beter morgen',
    'menu-one': 'plantaardige inspiratie',
    'menu-two': 'recyclebare verpakkingen',
    'menu-three': 'biodiversiteit en bodem',
    'menu-four': 'voedselverspilling'
  },
  MENU_PAGE: {
    TITLE1: 'Plantaardige inspiratie',
    TITLE2: 'Recyclebare verpakkingen',
    TITLE3: 'Biodiversiteit en bodem',
    TITLE4: 'Voedselverspilling',
    EXTERNAL: ''
  },
  TECHNICAL: { MENU_PAGE: '' },
  SEO: {
    TITLE: {
      MENU_ONE: 'Plantaardige inspiratie',
      MENU_TWO: 'Recyclebare verpakkingen',
      MENU_THREE: 'Biodiversiteit en bodem',
      MENU_FOUR: 'Voedselverspilling',
      HOME: 'Bonduelle: ontdek verrassende groenten en recepten',
      SEARCH: {
        MAIN: '{{value}} | Bonduelle',
        RECIPES: '{{value}} gesorteerd op recept | Bonduelle',
        VEGETABLES: '{{value}} gesorteerd op groente | Bonduelle',
        ARTICLES: '{{value}} gesorteerd op artikel | Bonduelle'
      },
      CONTACT: 'Consumentenservice | Bonduelle',
      RECIPES: 'Recepten: Soepen, Salades, Hoofdgerechten, ... | Bonduelle\n',
      RECIPE_HOME: 'De recepten van Bonduelle',
      RECIPE: 'Recept {{value}} | Bonduelle',
      PRODUCTS: 'Groenten, peulvruchten, granen & zaden | Bonduelle',
      NEWS: 'Ontdek de diversiteit van groenten | Bonduelle',
      CORPORATE: {
        NEWS: 'Recente artikelen en nieuws over groenten | Bonduelle',
        COMPANY_STORY: 'Ontdek Bonduelle, haar geschiedenis en betrokkenheid | Bonduelle',
        CSR_COMMITMENTS: 'Ontdek onze etische waarden, prinicpes en inzet | Bonduelle',
        VEGETAL_INNOVATIONS: 'Ontdek onze innovaties: nieuwe boerderijen, producten en agricultuur | Bonduelle',
        FIELD_PLATE: 'Groenten: het beste voor de beste recepten | Bonduelle',
        ARTICLE: '{{value}} | Bonduelle',
        VEGETABLE: 'Receptideëen {{value}} | Bonduelle'
      },
      PRODUCT: '{{value}}: Bonduelle groenten',
      MAGAZINE: {
        NEWS: 'Artikel over de variëteit aan groenten, recepten, teelt, nieuws | Bonduelle',
        ARTICLE: 'Bonduelle',
        COOK: 'Bonduelle',
        GROW: 'Bonduelle',
        EXPLORE: 'Bonduelle',
        RECIPE: 'Bonduelle'
      },
      FAQ: 'Veelgestelde vragen | Bonduelle',
      NOTFOUND: 'Foutcode 404, probeer het later opnieuw | Bonduelle',
      RECIPES_ALL: 'Bekijk al onze recepten',
      RECIPETYPE: 'Recept: {{value}} van groente',
      RECIPESEASON: 'Seizoensgroenten recept: {{value}}',
      RECIPEPREPARATION: 'Recepten: bereidingstijd {{value}}',
      RECIPEDIFFICULTY: 'Eenvoudig recept {{value}}',
      RECIPEVEGETABLE: 'Eenvoudig recept op basis van {{value}}',
      RECIPECOST: 'Recept & groente {{value}}',
      RECIPEDISH: 'Groente recepten voor {{value}}',
      RECIPEDESC: 'Recept & groente {{value}}',
      '2RECIPEFACETS': 'Recept {{value}}',
      '4RECIPEFACETS': 'Recept {{value}}',
      PRODUCTSEASON: 'Eenvoudig seizoensrecept: {{value}}',
      PRODUCTTECHNO: 'Gemakkelijk groente recept {{value}}',
      PRODUCTVEGETABLE: '{{value}}',
      PRODUCTRANGE: '{{value}}',
      THEME_ALL: 'Vers verwerkte en vriesverse groenten',
      THEME_STARTER: 'Groente idee voor je recept',
      THEME_SALAD: 'Groente ideeën voor salades',
      THEME_CAN: '',
      THEME_FROZEN: '',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: '',
      THEME_RECIPE: 'Groente idee voor het bereiden van snelle / eenvoudige recepten',
      THEME_PLATE: 'Groente ideeën voor het bereiden van warme en koude gerechten',
      THEME_ACC: 'Groenten passend bij vlees en vis',
      THEME_SNACK: 'Groente ideeën voor snelle recepten',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': 'Groenterecept {{value}}'
    },
    DESCRIPTION: {
      MENU_ONE: 'Ontdek hier plantaardige inspiratie van Bonduelle zodat jij lekkere plantaardige gerechten kan koken ',
      MENU_TWO: 'Leer hier over de recyclebare verpakkingen van Bonduelle. De blikjes en plastic zakken van Bonduelle zijn duurzaam voor een beter morgen. ',
      MENU_THREE: 'Ontdek hier wat Bonduelle doet om bioversiteit te boosten en de bodem te beschermen voor een beter morgen. ',
      MENU_FOUR: 'Lees hier wat Bonduelle doet om voedselverspilling tegen te gaan en hoe jij daarbij kan helpen. Dit is goed voor de wereld en jouw portemonnee.',
      HOME: 'Groenten, granen en zaden. Ontdek onze producten en receptinspiratie ',
      SEARCH: {
        MAIN: 'Ontdek onze receptideëen voor {{value}}. Lekere en gemakkelijke recepten.',
        RECIPES: 'Al onze {{value}} gesorteerd op recept. Lekkere en gemakkelijke recepten.',
        VEGETABLES: 'Al onze {{value}} gesorteerd op groenten. Lekkere en gemakkelijke recepten. ',
        ARTICLES: 'Al onze {{value}} gesorteerd op artikel. Lekkere en gemakkelijke recepten. '
      },
      CONTACT: 'Heeft u een vraag over een product? U kunt contact opnemen met onze consumentenservice. ',
      RECIPES: 'Ontdek heerlijke recepten met groenten. Snel, gemakkelijk en lekker.',
      RECIPE_HOME: 'Ontdek heerlijke recepten met groenten. Verras je geliefde op een speciale gelegenheid.',
      RECIPE: '',
      PRODUCTS: 'Met onze diverse groenten kun je heerlijke en eenvoudige recepten bereiden. ',
      NEWS: 'Ontdek hoe gemakkelijk het is om onze groenten te bereiden en snel heerlijke maaltijd op tafel te zetten.',
      CORPORATE: {
        NEWS: 'Het laatste nieuws uit de wereld over de landbouw, plantinnovatie, voedselverspiling en hoe je op een eenvoudige manier beter kunt eten.',
        COMPANY_STORY: 'Wil je meer weten over onze groenten, landbouw en innovaties? Ontdek dit en nog veel meer op onze blog.',
        CSR_COMMITMENTS: 'Vraag je jezelf af hoe onze groenten worden geteeld, waar onze groenten vandaan komen en hoe we omgaan met het milieu? Hier vind je meer informate.',
        VEGETAL_INNOVATIONS: 'Groenten en innovatie staan voor ons centraal. Nieuwe manieren om duurzamer te produceren, verspilling tegen te gaan. Lekkere en gemakkelijke recepten.',
        VEGETABLE: '',
        FIELD_PLATE: 'Ontdek onze heerlijke recepten. Eenvoudig en met lekker veel groenten!',
        ARTICLE: ''
      },
      PRODUCT: '{{value}} om een heerlijk recept te bereiden.',
      MAGAZINE: {
        NEWS: '',
        ARTICLE: '',
        COOK: '',
        GROW: '',
        EXPLORE: '',
        RECIPE: ''
      },
      FAQ: 'Heb je een vraag? Hier vind je de antwoorden op de meestgestelde vragen.',
      PAGE: 'pagina ',
      RECIPETYPE: 'Ontdek onze recepten {{value}}. Snel, eenvoudig en lekker.',
      RECIPESEASON: 'Ontdek onze recepten en hoe je je groenten bereid in {{value}}. Snel, eenvoudig en lekker!',
      RECIPEPREPARATION: 'Ontdek onze recepten en hoe je je groenten kan bereiden in {{value}}. Snel, eenvoudig en lekker.',
      RECIPEDIFFICULTY: 'Ontdek onze recepten en hoe je je groenten kan bereiden. Snel, eenvoudig en lekker.',
      RECIPEVEGETABLE: 'Ontdek onze recepten en hoe je je groenten kan bereiden. Snel, eenvoudig en lekker.',
      RECIPECOST: 'Ontdek onze recepten en hoe je je groenten kan bereiden voor {{value}}. Snel, eenvoudig en lekker.',
      RECIPEDISH: 'Ontdek onze recepten en hoe je je groenten kan bereiden voor {{value}} gasten. Snel, eenvoudig en lekker!',
      RECIPEDESC: 'Ontdek onze recepten en hoe je je groenten bereid {{value}}. Snel, eenvoudig en lekker.',
      '2RECIPEFACETS': 'Ontdek onze recepten en hoe je je groenten bereid {{value}}. Snel, eenvoudig en lekker.',
      '4RECIPEFACETS': 'Ontdek onze recepten en hoe je je groenten kan bereiden {{value}}.',
      PRODUCTSEASON: 'Met ons brede assortiment aan groenten kun je lekkere, gemakkelijke en uitgebalanceerde seizoensgebonden {{value}} recepten bereiden. Eenvoudige en lekkere recepten. Gekoote groenten.',
      PRODUCTTECHNO: 'Met ons brede assortiment aan groenten kun je lekkere, gemakkelijke en evenwichtige recepten bereiden {{value}}. Eenvoudige en lekkere recepten. Gekookte groenten.',
      PRODUCTVEGETABLE: 'Eenvoudig groente recept op basis van {{value}}',
      PRODUCTRANGE: 'Eenvoudig groente recept op basis van {{value}}',
      THEME_ALL: 'Met ons brede assortiment aan groenten kun je lekkere, gemakkelijke en evenwichtige recepten bereiden. Eenvoudige en lekkere recepten. Gekookte groenten.',
      THEME_STARTER: 'Wil je een uitgebalanceerde maaltijd bereiden? Grote keuze aan snelle, eenvoudige en lekkere groente recepten.',
      THEME_SALAD: 'Wil je een uitgebalanceerde maaltijd bereiden? Een brede selectie aan groenten voor het bereiden van salades. Snel, eenvoudig en lekker!',
      THEME_CAN: '',
      THEME_FROZEN: '',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: '',
      THEME_RECIPE: 'Groenten voor het bereiden van lekkere en evenwichtige recepten. Tips en trucs voor het bereiden van je maaltijden. Snelle en uitgebalanceerde recepten.',
      THEME_PLATE: 'Wil je een uitgebalanceerde maaltijd bereiden? Groenten voor het bereiden van warme of koude gerechten met eenvoudige, snelle en lekkere recepten.',
      THEME_ACC: 'Groenten voor eenvoudige en snelle groentegarnituren. ',
      THEME_SNACK: 'Wil je een uitgebalanceerde maaltijd bereiden? Groenten voor het bereiden van hapjes en snacks. Eenvoudig en snel. Tips en trucs.',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': 'Met ons brede assortiment aan groenten kun je lekkere, gemakkelijke en evenwichtige recepten bereiden {{value}}. Eenvoudige en lekkere recepten. Gekookte groenten.',
      NOTFOUND: 'De gevraagde pagina kon niet worden gevonden. Voer een nieuwe zoekopdracht in of probeer het later opnieuw.'
    },
    PIPE: { SOCIETY: ' | Bonduelle' },
    THEME_ALL: 'Alles',
    THEME_STARTER: 'Voorgerechten',
    THEME_SALAD: 'all-in-my-salad',
    THEME_CAN: '',
    THEME_FROZEN: '',
    THEME_READY_MEALS: '',
    THEME_BIO: '',
    THEME_VEGETABLES: '',
    THEME_RECIPE: 'recept-ingrediënten',
    THEME_PLATE: 'Hoofdgerecht',
    THEME_ACC: 'bijgerechten',
    THEME_SNACK: 'snacks'
  },
  VEGETABLE: {
    DISCOVER_VEGETABLE: 'Ontdekn onze groenten',
    OURS_RECIPES: 'Onze recepten',
    DISCOVER_ALSO: 'Ontdek ook...',
    SEE_ALL: 'Bekijk al onze groenten',
    ON: 'groenten van'
  },
  RECIPE: {
    'INGREDIENTS_LABEL ': 'Ingrediënten voor {{value}} personen',
    PEOPLES: '{{value}} personen',
    DIFFICULTY: { EASY: 'Makkelijk', MEDIUM: 'gemiddeld', HARD: 'moeilijk' },
    COST: { CHEAP: 'goedkoop', EXPENSIVE: 'duur' },
    TIMING: { MIN: 'min ', HOURS: 'uur' },
    STEP: 'Stap',
    DISCOVER_ALSO: 'Ontdek ook...',
    HOME_SEE_MORE: 'Bekijk meer recepten',
    SHOWCASE: 'Nieuw',
    HOME_BOOK: 'Onze recepten',
    MIN_MORE: 'm and +',
    HOUR_MORE: 'h and +',
    FILTER_BY_CRITERIA: 'filter op criteria',
    SORT_BY: 'sorteer naar',
    BEST_RATED: 'best beroordeeld',
    MOST_CONSULTED: 'meest bekeken',
    MEMBERS_RECIPES: 'Recepten van members',
    CRITERIA: 'Nieuws',
    DISCOVER: { RECIPES: 'Ontdek onze recepten' },
    DISCOVER_RECIPE: 'Ontdek het recept',
    INSTRUCTIONS: 'Instructies',
    DEFAULT_TAG: 'Recept idee',
    MOST_RECENT: 'Meest recent',
    RECIPE_VIDEOS: 'recept video',
    PRODUCT_ASSOCIATE: 'recept gemaakt met',
    BUDGET_LOW: 'goedkoop',
    BUDGET_HIGH: 'hoog',
    BUDGET_MIDDLE: 'gemiddeld',
    PRINT_RECIPE: 'print recept',
    LET_YOURSELF_SURPRISED: 'Verras jezelf',
    COOK_TIP_TITLE: '',
    ENJOY_MEAL: '',
    FILTER: { LEGEND: 'Legend' }
  },
  PREDIGGO: {
    DIFFICULTY: { EASY: 'Makkelijk', MEDIUM: 'gemiddeld', HARD: 'moeilijk' }
  },
  COOKIES: {
    TEXT: 'Door uw bezoek aan deze site voort te zetten, accepteert u het gebruik van cookies om technische redenen, ten behoeve van statistische analyse en webverkeerbeheer, het delen van informatie met adverteerders op andere websites, om u relevante promotionele communicatie te bieden die uw interesses weerspiegelen.',
    CLOSE: 'Sluiten',
    MORE: 'Kom meer te weten',
    AGREE_YOUTUBE: 'Je moet de Youtube cookies accepteren om deze video te bekijken.',
    BUTTON_YOUTUBE: 'Accept cookies'
  },
  POPUP: { LAUNCH: 'POPUP-OUVERTURE-SITE' },
  FIELD_PLATE: { DISCOVER_VEGETABLE: 'Ontdek onze groentebladen' },
  SEARCH: {
    OUR_RECIPES: 'Onze recepten',
    OUR_PRODUCTS: 'Onze producten',
    OUR_ARTICLES: 'Onze artikelen',
    RECIPES: 'Onze recepten',
    PRODUCTS: 'Onze producten',
    ARTICLES: 'Artikelen',
    ALL: 'Alles',
    SORT_BY: 'Sorteren naar',
    RESULTS: 'resultaten',
    RESULT: 'resultaat',
    PRODUCT_OUT: 'producten van',
    APPLY: 'toepassen',
    FILTER: 'Filter',
    RECIPE_OUT: 'recepten van',
    NO_RESULT: 'Oeps',
    SEE_MORE: 'Bekijk meer',
    DISCOVER_RECIPES: 'Ontdek onze recepten',
    SEE_MORE_OF: 'Bekijk meer van',
    DELETE: 'verwijder'
  },
  RECIPE_LIST: {
    OUR_RECIPES: 'Recepten',
    RESULTS: 'Resultaten',
    SEE_MORE: 'Bekijk meer recepten'
  },
  PRODUCT: {
    BUTTON: {
      ACTU: 'Product nieuws',
      FOUND: 'Waar kan ik dit product vinden',
      DISCOVER: 'Bekijk het hele assortiment'
    },
    NUTRITIONAL_VALUE: 'voedingswaarde',
    CONDITIONING: 'Inhoud',
    PORTIONS: 'porties',
    NET_WEIGHT: 'netto gewicht',
    DRY_WEIGHT: 'uitlekgewicht',
    INGREDIENTS: 'ingrediënten',
    OUR_RECIPES: 'Ontdek onze recepten',
    FOR: 'voor',
    PER_PORTION: 'en per portie van',
    ENERGY: 'energie',
    FAT: 'vet',
    SATURATE: 'verzadigd vet',
    GLUCIDES: 'koolhydraten',
    FIBER: 'vezels',
    PROTEINS: 'eiwitten',
    SALT: 'zout',
    DISCOVER_ALSO: 'Ontdek ook',
    MORE_INFORMATION: 'meer informatie',
    TRACES: 'Sporen van',
    CONTAINS: 'Bevat',
    SLIDER: { SHEET: 'bekijk het product', BUY: 'buy this product' },
    NUTRISCORE: 'informatie-nutriscore',
    TRIMAN: 'informatie-triman',
    AGRICONFIANCE: 'informatie-agriconfiance',
    BIO: 'informatie-bio',
    PESTICIDE_FREE: '',
    LEAF: '',
    LOCK: '',
    SEE_ALL: 'Bekijk alle producten',
    LEGISLATION: 'in overeenstemming met de geldende wetgeving.',
    PORTION: { SINGULAR: 'portion', PLURAL: 'portions' },
    DISCLAIMER: '',
    COOKING: 'Bereiding',
    CONSERVATION: 'Bewaren'
  },
  FAQ: {
    ON: 'vragen over',
    ALL: 'Alles',
    SEE_MORE: 'bekijk meer vragen',
    TITLE_1: 'Alle antwoorden op jouw vragen',
    TITLE_2: 'Heeft u een vraag over een bepaald product ?',
    LINK_LABEL: 'Schrijf ons'
  },
  FORM: {
    GO_TO_STEP: 'Ga naar stap',
    STEP: 'Stap',
    RECEIVE_EMAIL_COPY: 'Ontvang een kopie per e-mail',
    SEND: 'Verzenden',
    GDPR: 'Meer over het beheren van je gegevens',
    ERROR: 'Alle velden met een * zijn verplicht',
    SEND_ERROR: '\nEr is een fout opgetreden. Probeer het over een paar seconden opnieuw.',
    NOTICE: 'Voor de juiste afhandeling van je vraag of opmerking, kan Bonduelle je, afhankelijk van de kwestie, om gezondheidsgegevens vragen. Het is niet verplicht om dit te beantwoorden.',
    SENDING: 'Verzend het formulier',
    LOADING: 'Laden...',
    PRODUCT_INFORMATION_TITLE: 'Vragen over een product?',
    PRODUCT_INFORMATION: {
      SEO_TITLE: 'Vragen over een product? | Bonduelle',
      SEO_DESC: 'Neem contact op met onze consumentenservice voor informatie over een product.'
    },
    INFORMATIONS: 'Jouw gegevens',
    MISTER: 'Meneer',
    MISS: 'Mevrouw',
    NAME: 'Naam',
    OUR_NAME: 'Jouw naam',
    FIRSTNAME: 'Voornaam',
    OUR_FIRSTNAME: 'Jouw voornaam',
    MAIL: 'Jouw e-mailadres',
    MAIL_PLH: '',
    MAIL_CONFIRM: 'Bevestig jouw e-mailadres',
    MAIL_ERROR: 'Voer een geldig e-mailadres in',
    MAIL_ERROR_CONFIRM: 'Bevestig jouw e-mailadres',
    ADDRESS: 'Jouw adres',
    STREET_PLH: '',
    STREET: 'Straat',
    ADDRESS_COMPLEMENT: 'Toevoeging van adres',
    ADDRESS_COMP_PLH: 'Aanvullende informatie',
    ZIPCODE: 'Postcode',
    CITY: 'Woonplaats',
    CITY_PLH: 'Jouw woonplaats',
    PHONE: 'Telefoon',
    PHONE_PLH: 'Jouw telefoonnummer',
    COUNTRY: 'Land',
    PRODUCT_INFORMATIONS: 'Productinformatie',
    PRODUCT_INFORMATION_DESC: 'Voer de volgende informatie in voor onze consumentenservice om jouw verzoek te behandelen:',
    PRODUCT: {
      DETAIL: {
        DESC: "Voor alle gevraagde gegevens kun je het betreffende veld invullen en een foto toevoegen ter illustratie. Op de foto's moeten de barcode, het lotnummer en de houdbaarheidsdatum van het product duidelijk zichtbaar zijn.<br>\n" +
          "Note: Foto's via Pc of laptop - geen camera functie aanwezig."
      },
      NOTE: {
        TITLE: 'Een opmerking over een product?',
        SEO_TITLE: 'Maak een opmerking over een product | Bonduelle',
        SEO_DESC: 'Een vraag of opmerking over een product? Neem contact op met onze consumentenservice om u te helpen. '
      }
    },
    PRODUCT_RANGE: 'Productgroep',
    PRODUCT_NAME: 'Naam van het product',
    PRODUCT_NAME_PLH: 'Noteer de naam van het Bonduelle product waarop je aanvraag betrekking heeft',
    PRODUCT_INFORMATIONS_DETAIL: '\nGedetailleerde informatie',
    PRODUCT_INFORMATIONS_LABEL: 'Barcode van het product',
    PRODUCT_INFORMATIONS_PLH: 'De barcode bestaat uit 13 cijfers op de verpakking van het product.',
    PRODUCT_LOT: 'Lotnummer van het product',
    PRODUCT_LOT_INFO: 'Het lotnummer begint met een L<br>gevolgd door een aantal cijfers.<br>Je kan deze vinden op de achterkant<br>van de diepvriesverpakking<br>of aan de onderkant van het blik.<br><br>Bijvoorbeeld: L72450492107:13.',
    PHOTO: 'Voeg een foto toe',
    EXPIRED_DATE: 'Houdbaarheidsdatum',
    EXPIRED_DATE_ERROR: 'Voer de datum als volgt in: dd/mm/jjjj',
    EXPIRED_DATE_DESC: 'De houdbaarheidsdatum staat weergegeven op de achterkant van de diepvriesverpakking of aan de onderkant van het blik.',
    PHOTO_MORE: "Extra foto's",
    MESSAGE: 'Uw bericht *',
    OUR_MESSAGE: 'schrijf je bericht',
    SALES_OPERATIONS: {
      TITLE: '\nInformatie over een commerciële transactie',
      SEO_TITLE: '',
      SEO_DESC: 'Neem contact op met onze consumentenservice voor informatie over een actuele commerciële transactie. '
    },
    PRESS_SERVICE: 'Een vraag voor de pers?',
    FOOD_SERVICE: 'Vragen of opmerkingen voor Bonduelle Foodservice?',
    BUSINESS_INFORMATION: '',
    MODAL_INFORMATION: '',
    JOB_OFFER: { TITLE: '', SEO_TITLE: '', SEO_DESC: '' },
    WEB_SUGGESTION: {
      TITLE: 'Suggesties over de website?',
      SEO_TITLE: 'Bericht voor verbetering, suggestie voor de website | Bonduelle',
      SEO_DESC: 'Neem contact op met onze consumentenservice om ons te helpen de website te verbeteren.'
    },
    ANOTHER: {
      TITLE: 'Een ander verzoek?',
      SEO_TITLE: 'Andere vragen, opmerkingen of suggesties? | Bonduelle',
      SEO_DESC: 'Neem contact op met onze consumentenservice voor vragen, opmerkingen of suggesties.'
    },
    AMBASSADOR: 'Wordt een ambassadeur van Bonduelle',
    CATEGORY: { SALAD: '', FROZEN: '', CAN: '', TAKEAWAY: '', VEGETABLE: '' }
  },
  CONTACT: {
    TITLE: 'Hoe kunnen we je helpen?',
    SPECIAL_INFO: '',
    TAKE_CARE: '',
    BY_MAIL: 'Per post',
    BY_PHONE: 'Per telefoon',
    BY_SOURDLINE: '',
    MAIL_TEXT: 'Bonduelle Benelux<br>\n' +
      't.a.v. Consumentenservice<br>\n' +
      'Postbus 28058<br>\n' +
      '5602 JB Eindhoven',
    PHONE_TEXT: 'Onze medewerkers zijn tot uw beschikking op telefoonnummer <a href="tel:0800 266 38 355"><b>0800 266 38 355</b></a> (gratis).',
    SOURDLINE_TEXT: '',
    BY_DISTRIBUTOR: '',
    BY_DISTRIBUTOR_TEXT: '',
    SUBTITLE: 'Of je kan een van onderstaande contactformulieren gebruiken:',
    AGREEMENT: '',
    READ_MORE: "Bovendien heeft u te allen tijde het recht om BONDUELLE te verzoeken uw recht op toegang, rectificatie, verwijdering en overdraagbaarheid van uw persoonsgegevens uit te oefenen, evenals uw recht om de verwerking van uw persoonsgegevens te beperken en er bezwaar tegen te maken. Om uw rechten met betrekking tot uw persoonlijke gegevens uit te oefenen, kunt u schrijven naar Dpo_france(at)bonduelle(dot)com of per post naar het volgende adres: ter attentie van de functionaris voor gegevensbescherming, Bonduelle, Rue Nicolas Appert, postbus 30173, F- 59653 Villeneuve D'Ascq. Voor meer informatie over de manier waarop BONDUELLE uw persoonsgegevens verzamelt en gebruikt, klikt u hier",
    READ_MORE_LABEL: 'Lees ook',
    SATISFACTION_TEXT: 'Om contact met u op te nemen om u de gevraagde informatie over het\n' +
      'product te verstrekken en om u desgewenst een tevredenheidsvragenlijst\n' +
      'te sturen, verwerken wij de verstrekte informatie in onze hoedanigheid\n' +
      'van gegevensbeheerder. De wettelijke basis voor het verwerken van uw\n' +
      'verzoek is toestemming vanwege de mogelijke gezondheidsgerelateerde\n' +
      'gegevens die u mogelijk in uw verzoek verstrekt. Deze gegevens worden\n' +
      'bewaard zo lang als nodig is om uw verzoek te verwerken en maximaal 5\n' +
      'jaar om het bewijs te leveren dat uw verzoek is verwerkt en om te voldoen\n' +
      'aan onze wettelijke verplichtingen. De wettelijke basis voor het verzenden\n' +
      'van de tevredenheidsenquête is tevens uw toestemming. Deze gegevens\n' +
      'worden bewaard gedurende de tijd die nodig is voor het verzenden van\n' +
      'de tevredenheidsenquête en maximaal 1 jaar. Voor meer informatie over\n' +
      'de verwerking van uw persoonsgegevens kunt u ons <a href="https://www.bonduelle.nl/privacybeleid" target="_blank" class="underline">Privacybeleid</a> raadplegen.',
    SATISFACTION_CHECKBOX: 'Door dit vakje aan te vinken, gaat u akkoord met het ontvangen van een\n' +
      'tevredenheidsvragenlijst (bestaande uit één enkele vraag) zodra uw\n' +
      'verzoek is verwerkt. Dat is optioneel.',
    CHECKBOX_TEXT: 'Door dit vakje aan te vinken, gaat u ermee akkoord dat Bonduelle de\n' +
      'persoonlijke gezondheidsgegevens die u in uw bericht verstrekt, mag\n' +
      'verwerken met als enige doel het beantwoorden van uw verzoek. <strong>Dat is\n' +
      'verplicht om uw verzoek te kunnen verwerken</strong>.',
    PHONE: 'Telefoonnummer: <a href="tel: 0800 266 38 255 "><b>0800 266 38 355 </b></a> (gratis)',
    CALL_US: 'Om ons te bellen',
    TO_WRITE: 'Om ons te schrijven',
    PHONE_NUMBER: '0800 266 38 355',
    SUCCESS_MESSAGE: 'Bedankt! Je bericht is verzonden naar ons team. Zij nemen binnen 5 werkdagen contact met je op. <br> <br>\n' +
      'We zijn natuurlijk tot je beschikking op 0800 266 38 355 (gratis). <br> <br>\n' +
      'Als onderdeel van het toezicht op onze kwaliteit van de dienstverlening, kunnen al onze uitwisselingen worden vastgelegd.',
    PHOTOERROR: {
      MESSAGE: "Momenteel werken wij aan een nieuwe website. In de praktijk is gebleken dat foto's uploaden via het contactformulier niet naar behoren functioneert. U kunt het formulier invullen en verzenden en de illustrerende foto's per mail versturen naar: consumerservices_blx_uk@bonduelle.com onder vermelding van uw voor- en achternaam. Excuses voor het ongemak."
    }
  },
  GLOBAL: { READ_MORE: '' },
  FLOWBOX: { GENERIC_KEY: '', PRODUCT_KEY: '', RECIPE_KEY: '', HOME_KEY: '' },
  TOKEN: {
    FACEBOOK: '',
    PAGE_FACEBOOK_ID: '',
    INSTAGRAM: '',
    INSTA_ID: '17841408596443785',
    YOUTUBE: '',
    TWITTER: '',
    MAGAZINE: ''
  },
  COOKIE: {
    BANNER: {
      MANDATORY: 'Noodzakelijke',
      ANALYTICS: 'Analyse',
      MARKETING: 'Marketing',
      SOCIAL_MEDIA: 'Social media',
      ACCEPT: 'Alles accepteren',
      REJECT: 'Alles afwijzen',
      SET_UP: 'Stel voorkeuren in',
      MANDATORY_DETAILS: 'De noodzakelijke cookies zijn verplicht voor de werking van de website om de basisfuncties van deze website mogelijk te maken. De cookies die nodig zijn om de Bonduelle.nl website te gebruiken zijn:\n' +
        '<ul>\n' +
        "<li> Sessiecookies: dit zijn cookies die worden gegenereerd door PHP-gebaseerde applicaties. Dit is een algemene identificatie die wordt gebruikt om variabelen van gebruikerssessies te beheren. Het is meestal een willekeurig gegenereerd nummer, de manier waarop het wordt gebruikt kan locatiespecifiek zijn, maar een goed voorbeeld is het behouden van een inlogstatus voor een gebruiker tussen pagina's. </li>\n" +
        '</ul>\n',
      ANALYTICS_DETAILS: 'Analyse cookies helpen ons om de website te optimaliseren, bijvoorbeeld om statistieken te verzamelen, uw voorkeuren voor sociale interacties vast te leggen of de prestaties en stabiliteit van onze applicaties te meten.\n' +
        '<br>\n' +
        'De analytische cookies die door de Bonduelle.nl website worden gebruikt, zijn:\n' +
        '<ul>\n' +
        '<li> DoubleClick: deze cookie wordt door DoubleClick (eigendom van Google) geplaatst om een profiel van de interesses van de websitebezoeker te maken en om relevante advertenties op andere websites weer te geven. De belangrijkste activiteit van het bedrijf is het weergeven van Google-advertenties. </li>\n' +
        "<li> Google Analytics: deze cookies maken het mogelijk om de meest bezochte pagina's en websiteverkeer bij te houden. </li>\n" +
        '</ul>\n',
      MARKETING_DETAILS: 'Met marketingcookies kunnen we uw voorkeuren voor recepten en producten volgen om u altijd de producten aan te bieden die het beste bij uw wensen passen.\n' +
        '<br>\n' +
        'De marketingcookies die door de site bonduelle.nl worden gebruikt, zijn:\n' +
        '<ul>\n' +
        "<li> Sociale netwerken: deze cookies zorgen ervoor dat de Bonduelle 'Social Wall' op de homepage van de site wordt weergegeven. Deze 'Social Wall' stelt u in staat om in één oogopslag alle laatste berichten te zien van de belangrijkste sociale netwerken die gebruikt worden door de Bonduelle teams. </li>\n" +
        '\n',
      V2: 'ok'
    },
    MODAL: {
      TITLE: 'Cookie-instellingen',
      TEXT: 'De Bonduelle Group verbindt zich ertoe om de persoonsgegevens van de gebruikers van haar websites en in het bijzonder van de Bonduelle.nl website te beschermen. Zodat u gebruik kunt maken van de diensten die door de website worden aangeboden, zoals het advies over producten, nieuwe recepten ontdekken en zich abonneren op bepaalde diensten zoals een nieuwsbrief. Om het gebruik van de website te optimaliseren en te personaliseren op basis van uw profiel, maakt de website gebruik van cookies. De Bonduelle Group verbindt zich ertoe om een ​​hoog niveau van bescherming te verzekeren met betrekking tot dergelijke persoonlijke gegevens en om alle toepasselijke gegevensbeschermingsregels na te leven bij het verwerken van persoonlijke gegevens in verband met het gebruik van haar website.',
      CONFIG: 'Configuratie',
      SAVE: 'Opslaan'
    }
  },
  LINK: { JOB_OFFERS: 'https://jobs.bonduelle.com/?locale=nl_NL' },
  CLIC2BUY: { INFOS: '' }
};
