import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation, HostBinding} from '@angular/core';

// Counter for unique group ids.
let _uniqueOptgroupIdCounter = 0;

@Component({
    selector: 'sf-optgroup',
    templateUrl: 'optgroup.html',
    styleUrls: ['optgroup.sass'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SfOptgroupComponent {
  /** Label for the option group. */
  @Input() label: string;

  @HostBinding('attr.role') role = 'group';

  /** Unique id for the underlying label. */
  labelId = `sf-optgroup-label-${_uniqueOptgroupIdCounter++}`;
}
