// @ts-nocheck
import { SomBloc } from './bloc.model';
import { SfMediaImage, SfLink, SfBanner } from '@app/sf-lib/public_api';

export class SomHomeBanner extends SomBloc implements SfBanner {

  private _label1: string;

  set label1(value: string) {
    this._label1 = value;
    this.uppertitle = value;
  }
  get label1(): string {
    return this._label1;
  }
  uppertitle: string;

  private _label2: string;
  set label2(value: string) {
    this._label2 = value;
    this.title = value;
  }
  get label2(): string {
    return this._label2;
  }
  title: string;

  background: SfMediaImage;

  smallbackground: SfMediaImage;

  link: SfLink;

  weight: number;

  sfType: string;

  /**
   * Custom function used by JSON.stringify
   *
   * @param {string} nm Name of the property
   * @returns {string}
   * @memberof SomFieldToPlatePage
   */
  public toJSON(nm: string) {
    const newArticle = {
      label1: this.label1,
      uppertitle: this.uppertitle,
      label2: this.label2,
      title: this.title,
      background: this.background,
      smallbackground: this.smallbackground,
      link: this.link,
      weight: this.weight,
      sfType: 'SomHomeBanner'
    };
    return newArticle;
  }

}
