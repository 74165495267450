import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, flatMap } from 'rxjs/operators';

@Component({
    selector: 'sf-search-recipe',
    templateUrl: './search-recipe.component.html',
    styleUrls: ['./search-recipe.component.sass'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SfSearchRecipeComponent implements OnInit {

  public searchRecipe: UntypedFormControl = new UntypedFormControl('');

  public keywordRecommandation: string[] | Observable<string[]>;

  @Input() prediggoTerms: ((terms: string) => string[] | Observable<string[]>);

  @Output() keyWord = new EventEmitter();

  constructor(
    private formBuilder: UntypedFormBuilder,
  ) {
    this.searchRecipe = this.formBuilder.control('', [Validators.required]);
    this.searchRecipe.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        filter(data => {
          if (data.length <= 2) {
            this.keywordRecommandation = null;
            return false;
          } else {
            return true;
          }
        }),
        flatMap((data: string) => {
          if (this.prediggoTerms) {
            const call: string[] | Observable<string[]> = this.prediggoTerms(data);
            if (Array.isArray(call)) {
              return of(call as string[]);
            } else {
              return call as Observable<string[]>;
            }
          } else {
            return of(null);
          }
        })
      )
      .subscribe(
        (data: string[]) => {
          this.keywordRecommandation = data;
        }
      );
  }

  ngOnInit() {

  }

  public onkeydown(search: string) {
    this.keyWord.emit(search);
  }

  public blurFunction() {
    setTimeout( () => {
    return this.keywordRecommandation = null;
    }, (200) );
   }

}
