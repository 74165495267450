/**
 * Defines a message object.
 */
export class SfMessage {

  /**
   * The informative message
   */
  message: string;

  /**
   * Ok action label of the button
   */
  okLabel: string;

  /**
   * The action (function) to call on 'OK' action (default action)
   */
  okAction: () => void;

  /**
   * Creates an instance of SfMessage.
   */
  constructor(sMessage: string) {
    this.message = sMessage;
  }

}
