import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { SfItemIcon } from '../models/item-icon.interface';

@Component({
    selector: 'sf-item-icon',
    templateUrl: './item-icon.component.html',
    styleUrls: ['./item-icon.component.sass'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SfItemIconComponent implements OnInit {

  /**
   * Data item with icon
   *
   * @memberof SfItemIconComponent
   */
  @Input() item: SfItemIcon;

  @Input() seoTitle: 'H1'|'H2'|'H3'|'DIV' = 'DIV';

  public hover: boolean;

  constructor() { }

  ngOnInit() {
  }

}
