import { NgModule, ModuleWithProviders, Provider } from '@angular/core';
import { CommonModule } from '@angular/common';

// Models
import { SfModel } from './models/model';
import { SfSimpleBanner } from '../../features/banner';
import { SfFile } from '../models/file.model';
import { SfSimpleMediaImage, SfMediaHoverImage } from '../../features/image';
import { SfSimpleLink } from '../../features/link';
import { SfLang } from '../models/lang.model';
import { SfSimpleMediaVideo } from '../../features/video';
import { SfMenuItem } from '../models/menu-item.model';
import { SfRubric } from '../models/rubric.model';
import { SfSimpleSectionImage } from '../../features/section-image';
import { SfSimpleSectionImageIcon } from '../../features/section-image-icon';
import { SfSimpleSectionText } from '../../features/section-text';
import { SfSimpleSectionVideo } from '../../features/section-video/models/section-video.model';
import { SfShareButton } from '../models/share-button.model';
import { SfSimpleSlide, SfSimpleSlider, SfSimpleVideoSlide } from '../../features/slider';
import { SfSimpleSlideGrid, SfSimpleSliderGrid } from '../../features/slider-grid';


import { SfNetworkingService, SF_MODEL_LIST } from './services/networking.service';
import { SF_TYPE_PARSER } from './class/sf-type-parser.interface';
import { SfModelBundle } from './models/model-bundle.interface';
import { DummyTypeParser } from './class/dummy-type-parser';
import { SfSocialNetwork } from '../models/social-network.model';
import { SfSimpleSectionSlider } from '../../features/slider/models/section-slider.model';

const sfBundle: SfModelBundle = {
  // Non interface models
  'SfModel': SfModel,
  'SfFile': SfFile,
  'SfLang': SfLang,
  'SfMediaHoverImage': SfMediaHoverImage,
  'SfMenuItem': SfMenuItem,
  'SfRubric': SfRubric,
  'SfShareButton': SfShareButton,
  'SfSocialNetwork': SfSocialNetwork,

  // Interface models
  'SfBanner': SfSimpleBanner,
  'SfMediaImage': SfSimpleMediaImage,
  'SfLink': SfSimpleLink,
  'SfMediaVideo': SfSimpleMediaVideo,
  'SfSectionImage': SfSimpleSectionImage,
  'SfSectionImageIcon': SfSimpleSectionImageIcon,
  'SfSectionSlider': SfSimpleSectionSlider,
  'SfSectionText': SfSimpleSectionText,
  'SfSectionVideo': SfSimpleSectionVideo,
  'SfSlide': SfSimpleSlide,
  'SfSlider': SfSimpleSlider,
  'SfSlideGrid': SfSimpleSlideGrid,
  'SfSliderGrid': SfSimpleSliderGrid,
  'SfVideoSlide': SfSimpleVideoSlide
};

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: []
})
export class SfNetworkingModule {
  static forRoot(bundle: SfModelBundle, parserProvider?: Provider): ModuleWithProviders<SfNetworkingModule> {
    return {
      ngModule: SfNetworkingModule,
      providers: [
        parserProvider ? parserProvider : {
          provide: SF_TYPE_PARSER,
          useClass: DummyTypeParser
        },
        {
          provide: SF_MODEL_LIST,
          useValue: sfBundle,
          multi: true
        },
        {
          provide: SF_MODEL_LIST,
          useValue: bundle,
          multi: true
        },
        SfNetworkingService
      ],
    };
  }

  /**
   * @experimental
   * Don't use !
   * Only works for non lazy loaded modules...
   */
  static forChild(models: SfModelBundle ): ModuleWithProviders<SfNetworkingModule> {
    return {
      ngModule: SfNetworkingModule,
      providers: [
        {
          provide: SF_MODEL_LIST,
          useValue: models,
          multi: true
        }
      ],
    };
  }
}
