import { Injectable, OnDestroy, Inject, PLATFORM_ID, InjectionToken } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';

import { Observable, BehaviorSubject, Subscription, fromEvent, merge, of, Subject, throwError } from 'rxjs';
import { mapTo, map, tap, catchError } from 'rxjs/operators';

import { SfMessageService } from '../../message/services/message.service';
import { SfError } from '../../message/models/error.model';
import { SfErrorEnum } from '../../message/models/error.enum';
import { SfModel } from '../models/model';

import { SF_TYPE_PARSER, SfTypeParser } from '../class/sf-type-parser.interface';
import { SfModelBundle } from '../models/model-bundle.interface';

export const SF_MODEL_LIST = new InjectionToken<SfModelBundle>('SF_MODEL_LIST');

/**
 * Class responsible to provide the online status of the application
 */
@Injectable()
export class SfNetworkingService implements OnDestroy {

  /** @ignore */
  private connectionSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  /** @ignore */
  private connectionDisposable: Subscription;

  /**
   * Get an Observable of the application network status.<br/>
   * <b>true</b> => online <br/>
   * <b>false</b> => offline
   */
  public get connection(): Observable<boolean> {
    return this.connectionSubject.asObservable();
  }

  /**
   * Get the current status of the network.<br/>
   * <b>true</b> => online <br/>
   * <b>false</b> => offline
   */
  public get connected(): boolean {
    return this.connectionSubject.getValue();
  }

  constructor(
    private http: HttpClient,
    private errorService: SfMessageService,
    @Inject(SF_MODEL_LIST) private sfModelList: SfModelBundle[],
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(SF_TYPE_PARSER) private typeParser: SfTypeParser
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.connectionDisposable = merge(
        of(navigator.onLine),
        fromEvent(window, 'online').pipe(mapTo(true)),
        fromEvent(window, 'offline').pipe(mapTo(false))
      ).subscribe((cConnected: boolean) => {
        this.connectionSubject.next(cConnected);
      });
    } else {
      this.connectionSubject.next(true);
    }
  }

  get modelBundle(): SfModelBundle {
    const rr = this.sfModelList.reduce((prev, curr) => {
      const r = {...prev, ...curr};
      return r;
    }, {});
    return rr;
  }

  /**
   * Use this class to make an API request.
   * Returns class extending BaseModel or throws an error
   * if an error occured.
   */
  get(url: string,
  options?: { headers?: HttpHeaders, params?: HttpParams } ): Observable<SfModel | SfModel[]> {
    if (!this.connected) {
      const tError: SfError = this.errorService.getErrorForLocaleCode(SfErrorEnum.no_network);
      return throwError(() => tError);
    }
  
    return this.http.get(url, options).pipe(
      catchError(err => {
        return throwError(() => err);
      }),
      map((response: HttpResponse<any>) => this.parse(response)) // Transformation des données
    )
  }

  /**
   * Use this class to make an API POST request.
   */
  post(
    url: string,
    body: any,
    options?: {headers?: HttpHeaders}
  ): Observable<SfModel | SfModel[]> {
    const subject: Subject<SfModel | SfModel[]> = new Subject();

    if (!this.connected) {
      // If we're not connected throw an error
      const tError: SfError = this.errorService.getErrorForLocaleCode(SfErrorEnum.no_network);
      subject.error(tError);
    } else {
      // send the request
      this.http.post(url, JSON.stringify(body), options)
      .pipe(
        map(
          (response: HttpResponse<any>) => {
            return this.parse(response);
          }
        )
      )
      .subscribe(
        (value: SfModel | SfModel[]) => {
          subject.next(value);
          subject.complete();
        },
        (error: SfError) => {
          error.origin = url;
          subject.error(error);
        }
      );
    }

    return subject.asObservable();
  }

  /**
   * Use this class to make an API PUT request.
   */
  put(
    url: string,
    body: any,
    options?: {headers: HttpHeaders}
  ): Observable<SfModel | SfModel[]> {
    const subject: Subject<SfModel | SfModel[]> = new Subject();

    if (!this.connected) {
      // If we're not connected throw an error
      const tError: SfError = this.errorService.getErrorForLocaleCode(SfErrorEnum.no_network);
      subject.error(tError);
    } else {
      // send the request
      this.http.put(url, JSON.stringify(body), options)
      .pipe(
        map(
          (response: HttpResponse<any>) => {
            return this.parse(response);
          }
        )
      )
      .subscribe(
        (value: SfModel | SfModel[]) => {
          subject.next(value);
          subject.complete();
        },
        (error: SfError) => {
          error.origin = url;
          subject.error(error);
        }
      );
    }

    return subject.asObservable();
  }

  public parse(src: any): SfModel | SfModel[] {
    if (!src) {
      return new SfModel();
    }
    try {
      const newModel: SfModel | SfModel[] = this.deserialize(src);
      return newModel;
    } catch (e) {
      console.error(e);
    }
    return new SfModel();
  }

  /**
   * Takes an any and try to cast it as a SfModel.</br>
   * Throws an Error if cast is not possible.
   */
  private deserialize(src: any | any[]): SfModel | SfModel[] | any {

    let dest: any;

    // Sanity check
    // API may send us empty array to express a null value (weird but true)
    if (this.isNullOrUndefined(src) || (Array.isArray(src) && !src.length)) {
      dest = void 0;
      return dest;
    }

    // Custom type parser. If none is provided in SfNetworkingModule.forRoot()
    // a default one is used, not parsing anything. This parser class can be defined
    // from outside the library to add a custom sfType property
    this.typeParser.parseType(src);

    // In case we deserialize a primitive type (or function). Or if the object doesn't have a type
    if (typeof src !== 'object') {
      dest = src;
      return dest ;
    }

    // In case the src is an array
    if (Array.isArray(src)) {
      dest = [];
      for (let i = 0; i < src.length; i++) {
        dest.push(this.deserialize(src[i]));
      }
      return dest;
    }

    if (src.sfType) {
      // Create the model from modelList
      try {
        dest = new (<any>this.modelBundle[src.sfType])();
      } catch (error) {
        dest = new SfModel();
        console.warn(`Unknown SfType ${src.sfType}. Will be considered as SfModel`);
      }
    } else {
      dest = new SfModel();
    }

    // Parse the source object in the new model
    for (const key of Object.keys(src)) {
      /** @toimprove type checking disabled for now. Don't handle empty arrays and or undefined */
      // if (dest.hasOwnProperty(key) && typeof dest[key] !== typeof src[key]) {
      //   throw Error(`Malformed input value for ${dest.sfType}, expected '${key}' to be '${typeof dest[key]}'`);
      // }
      dest[key] = this.deserialize(src[key]);
    }

    return dest;
  }

  isNullOrUndefined(obj: any): boolean {
    return typeof obj === 'undefined' || obj === null;
  }

  /**
   * When the service is destroyed we unsubscribe from the observable to prevent memory leak
   */
  ngOnDestroy() {
    if (this.connectionDisposable) {
      this.connectionDisposable.unsubscribe();
    }
  }
}
