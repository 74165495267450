import { StringToHTMLPipe } from './pipes/string-to-html.pipe';
import { NgModule } from '@angular/core';
import { CapitalizeFirstLetterPipe } from './pipes/capitalize-first-letter.pipe';
import { LevelsCostPipe } from './pipes/levels-cost.pipe';
import { RemoveDashPipe } from '@app/shared/utils/pipes/remove-dash.pipe';
import { NutriScorePipe } from '@app/shared/utils/pipes/nutriscore.pipe';
import { CheckArrayPipe } from '@app/shared/utils/pipes/check-array.pipe';
import { DisplayNonePipe } from './pipes/display-none.pipe';
import { SortArrayPipe } from './pipes/sort-array.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { RemoveSlashesPipe } from './pipes/remove-slashes.pipe';

@NgModule({
  imports: [],
  declarations: [
    StringToHTMLPipe,
    CapitalizeFirstLetterPipe,
    CheckArrayPipe,
    LevelsCostPipe,
    SafePipe,
    RemoveDashPipe,
    NutriScorePipe,
    DisplayNonePipe,
    SortArrayPipe,
    RemoveSlashesPipe,
  ],
  exports: [
    StringToHTMLPipe,
    CapitalizeFirstLetterPipe,
    CheckArrayPipe,
    LevelsCostPipe,
    SafePipe,
    RemoveDashPipe,
    NutriScorePipe,
    DisplayNonePipe,
    SortArrayPipe,
    RemoveSlashesPipe
  ]
})
export class UtilsModule { }
