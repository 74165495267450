import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SfVideoComponent } from './components/video.component';
import { SfUtilsModule } from '../../core/utils/utils.module';
import { SfCheckYoutubeUrlPipe } from './pipes/check-youtube-url.pipe';

@NgModule({
  imports: [
    CommonModule,
    SfUtilsModule
  ],
  declarations: [
    SfVideoComponent,
    SfCheckYoutubeUrlPipe
  ],
  exports: [
    SfVideoComponent,
    SfCheckYoutubeUrlPipe
  ],
})
export class SfVideoModule { }
