import { Component, Input, TemplateRef } from '@angular/core';
import { SlideGridAnimations } from './slide-grid.animations';
import { SfSlideGrid } from '../../models/slide-grid.interface';

@Component({
    selector: 'sf-slide-grid',
    templateUrl: './slide-grid.component.html',
    styleUrls: ['./slide-grid.component.sass'],
    animations: [
        SlideGridAnimations.parent,
        SlideGridAnimations.left,
        SlideGridAnimations.right
    ],
    standalone: false
})
export class SfSlideGridComponent {

  @Input() slideGrid: SfSlideGrid;

  @Input() template: TemplateRef<any>;

  @Input() state: any;

  public elementWidth: number;

  constructor(
  ) {}


  public getAnimationState(): any {
    if (!this.state) { return this.state; }
    if (!this.state.params) {
      this.state.params = {};
     }
    return this.state;
  }
}
