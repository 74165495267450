import {environment} from '@env/environment';
import { SfLang } from '../../models/lang.model';

/**
 * Extract domain extension (ie: 'fr' from 'https://www.bonduelle.fr')
 */
export function extractExtension(host: string): string {
  const hostSegments = host.split('.');
  return hostSegments.length > 1 ? hostSegments.pop() : environment.defaultSite;
}

/**
 * Extract language from URL's path (ie: 'fr' from '/fr/path/to/products').
 * If there is no lang extension in path, returns default languages
 * extracted from domain extension.
 */
export function extractLang(path: string, locales: SfLang[]): string | undefined {
  const pathSegments: string[] = path.split('/');
  let lang: string;
  if (pathSegments.length > 1) {
    const langlang = locales.find(locale => locale.code === pathSegments[1]);
    lang = langlang ? langlang.code : void 0;
  }
  return lang;
}

export function fullCode(lang: SfLang): string {
  return `${lang.country}_${lang.code}`;
}

export function localesContains(key: string, locales: SfLang[]): boolean {
  return locales.map(s => s.code).indexOf(key) !== -1;
}

// Translate

export function anonymize(path: string, lang: SfLang, translationObject: Object): string {
  const queryParts = path.split('?');
  const pathSegments = queryParts[0].split('/');
  const queryString = (queryParts.length > 1 ? `?${queryParts[1]}` : '');
  const uuidSegments = [];

  pathSegments.forEach(
    (segment: string) => {
      if (segment.length === 0 || segment.startsWith(':')) {
        uuidSegments.push(segment);
      } else {
        const uuid = findUUID(segment, fullCode(lang), translationObject);
        if (uuid !== undefined) {
          uuidSegments.push(uuid);
        } else {
          uuidSegments.push(segment);
        }
      }
    }
  );

  const newPath = `${uuidSegments.join('/')}${queryString}`;
  return newPath;
}

export function unanonymize(path: string, lang: SfLang, translationObject: Object): string {
  const queryParts = path.split('?');
  const uuidSegments = queryParts[0].split('/');
  const queryString = (queryParts.length > 1 ? `?${queryParts[1]}` : '');
  const pathSegments = [];

  uuidSegments.forEach(
    (uuid: string) => {
      if (uuid.length === 0 && uuid.startsWith(':')) {
        uuidSegments.push(uuid);
      } else {
        const segment = findTranslation(uuid, fullCode(lang), translationObject);
        pathSegments.push(segment);
      }
    }
  );

  const newPath = `${pathSegments.join('/')}${queryString}`;
  return newPath;
}

/**
 * Find the uuid corresponding to the term in the translationObject and for the lang
 * Returns undefined if term is empty or translationObject doesn't contains it
 */
function findUUID(term: string, lang: string, translationObject: Object): string|undefined {
  if (!translationObject || !translationObject.hasOwnProperty(lang) || !term) {
    return void 0;
  } else {
    const entries = Object.entries(translationObject[lang]);
    const result = entries.find((entry) => entry[1] === term);
    return result ? result[0] : void 0;
  }
}

/**
 * Find the term corresponding to the uuid in the translationObject and for the lang
 * Returns uuid if uuid is empty or translationObject doesn't contains it
 */
function findTranslation(uuid: string, lang: string, translationObject: Object): string {
  if (!translationObject || !translationObject.hasOwnProperty(lang) || !uuid) {
    return uuid;
  } else {
    const entries = translationObject[lang];
    const result = entries ? entries[uuid] : uuid;
    return result || uuid;
  }
}
