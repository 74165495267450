import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

import { SfMessage } from '../models/message.model';
import { SfErrorEnum } from '../models/error.enum';
import { SfError } from '../models/error.model';
import { BehaviorSubject } from 'rxjs';
import { SfEvent } from '../models/event.model';

/**
 * Class responsible for generating local SfMessage objects
 */
@Injectable({providedIn: 'root'})
export class SfMessageService {

  public tEventSubject: BehaviorSubject<SfEvent> = new BehaviorSubject(null);
  getCurrentLabel() {
    return this.tEventSubject.getValue();
  }
  getLabel() {
    return this.tEventSubject.asObservable();
  }

  public tCategorySubject: BehaviorSubject<string> = new BehaviorSubject('');
  getCurrentCategory() {
    return this.tCategorySubject.getValue();
  }
  getCategory() {
    return this.tCategorySubject.asObservable();
  }

  constructor(
    private translate: TranslateService,
    private router: Router
  ) { }

  /**
   * Returns the Error object associated to a local error code
   */
  public getErrorForLocaleCode(code: SfErrorEnum): SfError {
    switch (code) {
      case SfErrorEnum.no_network:
        return new SfError(code.valueOf(), this.translate.instant('ERROR.NO_NETWORK'), true, true);
      case SfErrorEnum.internal_error:
        return new SfError(code.valueOf(), this.translate.instant('ERROR.INTERNAL'), true, false);
      case SfErrorEnum.bad_request:
        return new SfError(code.valueOf(), this.translate.instant('ERROR.BAD_REQUEST'), true, false);
      case SfErrorEnum.not_authenticated:
        // By default an authentication error should allow user to reconnect
        const tError = new SfError(code.valueOf(), this.translate.instant('ERROR.NOT_AUTHENTICATED'), true, true);
        tError.retryLabel = this.translate.instant('DIALOG.RECONNECT');
        tError.retryAction = () => { this.router.navigate(['/login']); };
        return tError;
      case SfErrorEnum.not_authorized:
        return new SfError(code.valueOf(), this.translate.instant('ERROR.NOT_AUTHORIZED'), true, false);
      case SfErrorEnum.not_found:
        return new SfError(code.valueOf(), this.translate.instant('ERROR.NOT_FOUND'), true, true);
      case SfErrorEnum.teapot:
        return new SfError(code.valueOf(), this.translate.instant('ERROR.TEAPOT'), true, true);
      case SfErrorEnum.malformed_json:
        return new SfError(code.valueOf(), this.translate.instant('ERROR.MALFORMED_JSON'), true, true);
      default:
        return new SfError(code.valueOf(), this.translate.instant('ERROR.UNDEFINED'), true);
    }
  }

  /**
   * Get an SfMessage object that is actually not a error but an information.
   */
  public getMessage(msg: string): SfMessage {
    return new SfMessage(msg);
  }

  public setEvent(label: string, tag?:string): void {
    if (label && label !== '') {
      const message: SfEvent = {
        title : label,
        tag: tag? tag: ''
      }
      this.tEventSubject.next(message);
    }
  }

  public setCategory(title: string): void {
    if (title && title !== '') {
      this.tCategorySubject.next(title);
    }
  }
}
