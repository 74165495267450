import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'capitalizeFirst',
    standalone: false
})
export class CapitalizeFirstLetterPipe implements PipeTransform {

  constructor() {}

  transform(value: string): string {
    if (value) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  }
}
