/* tslint:disable */export const pt_pt = {
  '404': {
    TITLE: 'Ups...',
    PAGENOTFOUND: 'Esta página não existe',
    BUTTON: 'Voltar ao Início',
    RECIPES: 'Descubra as nossas receitas'
  },
  HOME: {
    SHOWCASE: 'Receitas',
    CATCH_PHRASE: '',
    SHOWCASE_SEE_ALL: 'Ver todas as receitas',
    MAGAZINE: { TITLE: 'DESTAQUES', BUTTON: 'Descubra o nosso blogue' },
    OUR_VEGETABLES: 'Os nossos Legumes',
    SOCIAL_WALL: 'Juntos, partilhamos a paixão pelos legumes',
    FAQ_TITLE: 'Perguntas frequentes',
    FAQ_SEE_MORE: 'Veja mais',
    FLOWBOX: ''
  },
  SELECT: { DEFAULT_VALUE: 'Ver todos' },
  MENU: {
    CONTACT_US: 'Contacte-nos',
    MY_ACCOUNT: 'A minha conta',
    GOOD_DEALS: 'Promoções',
    NEWSLETTER: 'Newsletter Bonduelle'
  },
  FOOTER: {
    LINK: {
      BONDUELLE: 'Bonduelle.pt',
      CREDITS: 'Créditos',
      LEGAL_NOTICE: 'TERMOS LEGAIS',
      TERMS_OF_SALES: 'Condições de venda',
      COOKIES: 'POLITICA DE COOKIES',
      SITEMAP: '',
      PRIVACY_POLICY: 'Política de privacidade',
      ALL_RIGHTS_RESERVED: '2020© Todos os direitos reservados'
    },
    HISTORY: {
      TITLE: 'Bonduelle, desde 1853',
      TEXT: 'A Bonduelle é uma empresa familiar que trabalha há 7 gerações no desenvolvimento de uma produção agrícola que respeita a terra e as pessoas. Defendemos uma agro-ecologia eficiente, inteligente e solidária, orientada para o futuro, inovando todos os dias, do campo ao prato, para criar um amanhã melhor, através da alimentação vegetal.'
    },
    NEWSLETTER: {
      TITLE: 'A comunidade Bonduelle',
      TEXT: 'Aceito receber a newsletter «Tudo sobre os vegetais Bonduelle» (novidades, promoções, concursos, artigos…)',
      PLACEHOLDER: 'O seu e-mail',
      GDPR_LINK: 'Mais informação sobre a gestão dos seus dados'
    }
  },
  PRODUCTS: 'Produtos',
  MAGAZINE: {
    HOME: 'Bem-vindo ao nosso blogue',
    CATCH_PHRASE: '',
    MENU: { BONDUELLE_LINK: 'Ir para Bonduelle.pt' },
    SHOWCASE: 'Para ver',
    PREVIOUS_ARTICLES: 'Artigos anteriores',
    PREVIOUS_ARTICLES_SEE_ALL: 'Ver todos os artigos',
    PREVIOUS_EDITIONS: 'Edições anteriores',
    PREVIOUS_EDITIONS_SEE_ALL: 'Ver mais edições',
    PREVIOUS_ARTICLES_BY_CATEGORY: 'Artigos anteriores por categoria',
    RUBRIC: {
      SEE_ALL: 'Ver todas as receitas',
      SEE_ALL_ARTICLES: 'Ver todos os artigos',
      ON: 'Artigos sobre'
    },
    RUBRIC_COOK: { ON: 'Receitas de' },
    ARTICLE: { JOIN_US: 'Siga-nos', READ_ALSO: 'Ler também' },
    RECIPE: {
      INGREDIENTS: 'Ingredientes',
      INGREDIENTS_LABEL_DESC: 'para {{value}} pessoas',
      INGREDIENTS_LABEL_ONE_DESC: 'para {{value}} pessoa',
      INGREDIENTS_LABEL: 'Ingredientes para {{value}} pessoas',
      INGREDIENTS_LABEL_ONE: 'Ingredientes para {{value}} pessoa',
      PEOPLES: '{{value}} pessoas',
      PEOPLE: '{{value}} pessoa',
      DIFFICULTY: { EASY: 'Fácil', MEDIUM: 'Médio', HARD: 'Difícil' },
      COST: { CHEAP: 'Barato', EXPENSIVE: 'Caro' },
      TIMING: { MIN: 'min', HOURS: 'Horas' },
      STEP: 'Etapa',
      DISCOVER_ALSO: 'Descubra também'
    }
  },
  ARTICLE: { DISCOVER_ALSO: 'Descubra também', INGREDIENTS: 'Ingredientes' },
  SF: {
    SLIDE: { DISCOVER: 'Descubra' },
    SEARCH: { PLACEHOLDER: 'Pesquisar', ALL: 'Tudo sobre' },
    NEWSLETTER: {
      BTN_LABEL: 'Estou a registar-me',
      PLACEHOLDER: 'O seu e-mail',
      ERROR_MESSAGE: 'E-mail inválido',
      ALREADY_SUBSCRIBED: 'Já está registado',
      GDPR: {
        TXT: 'Mais informação sobre a gestão dos seus dados',
        LINK: '/politica-de-privacidade#gdpr'
      }
    },
    MONTHLY_FOLDER: { TITLE: 'Novidades do mês' },
    MINI_NEWSLETTER: {
      PLACEHOLDER: 'O seu e-mail',
      GDPR: {
        TXT: 'Mais informação sobre a gestão dos seus dados',
        LINK: '/politica-de-privacidade#gdpr'
      }
    },
    SOCIALITEM: { SHARE: 'Partilhar', COMMENT: 'Comentar', BONDUELLE: 'Bonduelle' },
    SEARCHRECIPE: { DISCOVER_ALL: 'Pesquisar todas as receitas!' },
    FORM: {
      ERROR: 'Campo obrigatório',
      PHOTO: { ADD: 'Acrescentar foto', DELETE: 'Eliminar foto', SEE: 'Ver' }
    }
  },
  CORPORATE: {
    COMPANY_STORY: 'A nossa história',
    CSR_COMMITMENT: 'Compromisso RSC',
    VEGETABLE_INNOVATIONS: 'O grupo Bonduelle',
    NEWS: 'Todas as novidades',
    SEE_MORE: 'Ver mais artigos',
    ON: 'Artigos sobre'
  },
  NEWSLETTER: {
    TITLE: 'Subscreva a newsletter',
    SUBTITLE: 'Promoções, inspiração culinária e um pouco de cultura!'
  },
  ROUTES: {
    products: 'produtos',
    news: 'novidades',
    'since-1853': 'sobre-nos',
    'field-plate': 'do-campo-a-mesa',
    'company-story': 'a-nossa-historia',
    'csr-commitments': 'compromisso-rsc',
    'vegetal-innovations': 'inovacao-vegetal',
    magazine: 'blogue',
    all: 'ver-tudo',
    article: 'artigo',
    cook: 'alimentacao',
    cultivate: 'tendencias',
    explore: 'sustentabilidade',
    recipe: 'preparacao-receita',
    recipes: 'receitas',
    'recipes-home': 'receitas-inicio',
    r: 'r',
    credits: 'creditos-pt',
    'legal-notice': 'mencoes-legais',
    'terms-of-sales': 'condicoes-de-venda',
    cookies: 'politica-de-cookies',
    sitemap: 'sitemap',
    'privacy-policy': 'politica-de-privacidade',
    'all-rights-reserved': 'todos-os-direitos-reservados',
    search: 'pesquisar',
    'articles-search': 'artigo',
    vegetables: 'produtos',
    p: 'p',
    contact: 'contacto',
    'info-product': 'informacao-sobre-produto',
    'note-product': 'notas-sobre-produto',
    suggestion: 'sugestoes',
    'other-request': 'nova-pesquisa',
    faq: 'perguntas-frequentes',
    'all-themes': 'todos-os-temas',
    'products-search': 'pesquisar-produtos',
    recipesearch: 'pesquisar-receitas',
    'product-news': 'novos-produtos',
    'good-deals': 'promocoes',
    'not-found': 'erro',
    'menu-page': 'menu-page',
    'menu-one': 'menu-one',
    'menu-two': 'menu-two',
    'menu-three': 'menu-three',
    'menu-four': 'menu-four',
    'menu-external': 'menu-external',
    'theme-pasta-rice': '',
    'theme-bowls': '',
    'theme-soup': '',
    'theme-snack': '',
    'theme-sauce': '',
    'theme-unique-plate': '',
    'theme-over': '',
    'theme-legumin': 'legumin',
    'theme-ingredients-recipes': '',
    'theme-cook-plate': ''
  },
  BREAD: {
    products: 'produtos',
    news: 'novidades',
    'since-1853': 'sobre-nos',
    'field-plate': 'do-campo-a-mesa',
    'company-story': 'a-nossa-historia',
    'csr-commitments': 'compromisso-rsc',
    'vegetal-innovations': 'inovacao-vegetal',
    magazine: 'blogue',
    all: 'ver-tudo',
    article: 'artigo',
    cook: 'alimentaçao',
    cultivate: 'tendencias',
    explore: 'sustentabilidade',
    recipe: 'esperar-receita',
    recipes: 'receitas',
    'recipes-home': 'receitas inicio',
    'articles-search': 'artigo',
    search: 'pesquisar',
    vegetables: 'Produtos',
    contact: 'contacto',
    'info-product': 'informacao-sobre-produto',
    'note-product': 'notas-sobre-produto',
    suggestion: 'sugestoes',
    'other-request': 'nova-pesquisa',
    faq: 'perguntas frequentes',
    'all-themes': 'todos-os-temas',
    'products-search': 'pesquisar-produtos',
    recipesearch: 'pesquisar-receitas',
    'product-news': 'novos-produtos',
    'good-deals': 'Promoções',
    'not-found': 'erro',
    'menu-page': 'menu-page',
    'menu-one': 'menu-one',
    'menu-two': 'menu-two',
    'menu-three': 'menu-three',
    'menu-four': 'menu-four'
  },
  MENU_PAGE: { TITLE1: '', TITLE2: '', TITLE3: '', TITLE4: '', EXTERNAL: '' },
  TECHNICAL: { MENU_PAGE: '' },
  SEO: {
    TITLE: {
      MENU_ONE: '',
      MENU_TWO: '',
      MENU_THREE: '',
      MENU_FOUR: '',
      HOME: 'Bonduelle',
      SEARCH: {
        MAIN: '{{value}} | Bonduelle',
        RECIPES: '{{value}} ordenado por receita | Bonduelle',
        VEGETABLES: '{{value}} ordenado por produto | Bonduelle',
        ARTICLES: '{{value}} ordenado por artigo | Bonduelle'
      },
      CONTACT: 'Contacto do Serviço de Atenção ao Consumidor | Bonduelle',
      RECIPES: 'Receita culinária: sopa, quiche, lasanha de legumes...  | Bonduelle',
      RECIPE_HOME: 'Receita para cozinhar com vegetais no Natal, casamentos, festas | Bonduelle',
      RECIPE: 'Receita {{value}} | Bonduelle',
      PRODUCTS: 'Vegetais cozidos, conservas, congelados para cozinhar | Bonduelle',
      NEWS: 'Conselhos, cozinha, como preparar os vegetais | Bonduelle',
      CORPORATE: {
        NEWS: 'Últimos artigos, notícias sobre vegetais e cozinha | Bonduelle',
        COMPANY_STORY: 'Apresentação da empresa | Bonduelle',
        CSR_COMMITMENTS: 'Valores/princípios éticos e compromisso do grupo | Bonduelle',
        VEGETAL_INNOVATIONS: 'Inovação vegetal: novas explorações, produtos | Bonduelle',
        FIELD_PLATE: 'Os nossos vegetais saem da terra | Bonduelle',
        ARTICLE: '{{value}} | Bonduelle',
        VEGETABLE: '{{value}}, descubra mais sobre este vegetal | Bonduelle'
      },
      PRODUCT: '{{value}}  Vegetais cozidos Bonduelle',
      MAGAZINE: {
        NEWS: 'Notícias sobre vegetais, receitas para adultos e crianças | Bonduelle',
        ARTICLE: '{{value}} Bonduelle',
        COOK: 'Receitas culinárias com vegetais, fáceis e rápidos  | Bonduelle',
        GROW: 'Cultivar e manter os vegetais na sua horta | Bonduelle',
        EXPLORE: 'Descubra novos vegetais e sabores para as crianças | Bonduelle',
        RECIPE: 'Receita {{value}} | Bonduelle'
      },
      FAQ: 'Q&As: Perguntas frequentes | Bonduelle',
      NOTFOUND: 'Erro 404, por favor, tente novamente mais tarde | Bonduelle',
      RECIPES_ALL: 'Todas as nossas receitas de vegetais ',
      RECIPETYPE: 'Receitas culinárias: {{value}} de vegetais',
      RECIPESEASON: 'Receita de vegetais da época: {{value}}.',
      RECIPEPREPARATION: 'Receitas culinárias: tempo de preparação {{value}}.',
      RECIPEDIFFICULTY: 'Receitas fáceis de vgetais: {{value}} de vegetais',
      RECIPEVEGETABLE: 'Receita culinária fácil à base de {{value}}',
      RECIPECOST: 'Receita de vegetais{{value}}.',
      RECIPEDISH: 'Receitas culinárias e vegetais para {{value}}',
      RECIPEDESC: 'Receita culinária {{value}}.',
      '2RECIPEFACETS': 'Receita culinária {{value}}.',
      '4RECIPEFACETS': 'Receitas culinárias {{value}}',
      PRODUCTSEASON: 'Receitas de vegetais fáceis de fazer: {{value}}',
      PRODUCTTECHNO: 'Receita fácil de vegetais {{value}}',
      PRODUCTVEGETABLE: 'Receita fácil de vegetais à base de {{value}}',
      PRODUCTRANGE: 'Receita fácil de vegetais à base de {{value}}',
      THEME_ALL: 'Vegetais em conserva, congelados para cozinhar.',
      THEME_STARTER: 'Ideia para cozinhar receitas de entradas com vegetais',
      THEME_SALAD: 'Ideias para cozinhar uma salada mista no verão',
      THEME_CAN: '',
      THEME_FROZEN: '',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: '',
      THEME_RECIPE: 'Ideias para cozinhar receitas fáceis e rápidas com vegetais',
      THEME_PLATE: 'Ideias para cozinhar pratos frios e quentes com vegetais',
      THEME_ACC: 'Ideias de legumes para acompanhar carne e peixe.',
      THEME_SNACK: 'Ideias de vegetais para receitas rápidas',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': 'Receitas de vegetais {{value}}'
    },
    DESCRIPTION: {
      MENU_ONE: '',
      MENU_TWO: '',
      MENU_THREE: '',
      MENU_FOUR: '',
      HOME: 'Vegetais em conserva e congelados. Descubra os nossos deliciosos vegetais, ideias de receitas para inspirar os seus pratos. Bonduelle: uma fonte de desejo de vegetais e receitas.',
      SEARCH: {
        MAIN: 'Descubra as nossas ideias de receitas e vegetais para {{value}}. Receitas simples e saborosas. ',
        RECIPES: 'Todos os nossos {{value}} ordenados por receita. Receitas simples e saborosas. ',
        VEGETABLES: 'Todos os nossos {{value}} ordenados por vegetal. Receitas simples e saborosas. ',
        ARTICLES: 'Todos os nossos {{value}} ordenados por artigo. Receitas simples e saborosas. '
      },
      CONTACT: 'Tem alguma pergunta para nos fazer? Precisa de informação sobre um produto, serviço ou acção em curso? Pode contactar-nos através do nosso Serviço de Atenção ao Consumidor. Resposta garantida em 48 horas.',
      RECIPES: 'Descubra saborosas receitas culinárias à base de vegetais. Faça os seus felizes, preparando receitas fáceis. Receitas simples e saborosas. ',
      RECIPE_HOME: 'Descubra saborosas receitas à base de vegetais para as festas. Mime os seus entes queridos com algo especial, nesta ocasião especial. Receitas simples e saborosas. ',
      RECIPE: '',
      PRODUCTS: 'A nossa ampla gama de produtos dá-lhe a possibilidade de preparar receitas saborosas, fáceis e equilibradas. Receitas simples e saborosas. ',
      NEWS: 'Descubra como preparar os seus legumes, cozinhá-los de forma eficiente e criar pratos saborosos. Receitas simples e saborosas. ',
      CORPORATE: {
        NEWS: 'Últimas notícias sobre o mundo agrícola, a inovação vegetal, os resíduos e como comer melhor de uma forma simples. Receitas simples e saborosas. ',
        COMPANY_STORY: 'Quer saber mais sobre o mundo dos vegetais, do mundo agrícola e das hortaliças? Descubra tudo isto no nosso blogue. Receitas simples e saborosas. ',
        CSR_COMMITMENTS: 'Quer saber como são cultivados os nossos vegetais, de onde vêm, como respeitamos o meio ambiente? Encontre as respostas às perguntas sobre os nossos vegetais, aqui.',
        VEGETAL_INNOVATIONS: 'A inovação vegetal está no centro das nossas preocupações. Novas formas de cultivar e mais ecológicas, com menos resíduos... Receitas simples e saborosas. ',
        VEGETABLE: '',
        FIELD_PLATE: 'Descubra as nossas deliciosas receitas. Serão um deleite para todos os paladares. Receitas simples e saborosas.',
        ARTICLE: ''
      },
      PRODUCT: '{{value}} para preparar saborosas receitas culinárias e satisfazer os seus entes queridos. Receitas simples e saborosas.',
      MAGAZINE: {
        NEWS: 'Todas as novidades, notícias sobre vegetais, receitas equilibradas. Receitas simples e saborosas.',
        ARTICLE: '',
        COOK: 'As nossas deliciosas receitas serão um deleite para todos os paladares, descubra-as na nossa página. Receitas simples e saborosas. ',
        GROW: 'Quer cultivar os seus próprios vegetais na sua horta? Descubra os nossos conselhos e truques para cultivar e manter os seus vegetais.',
        EXPLORE: 'Deixe que os seus filhos descubram novas variedades de vegetais, através de receitas simples e eficazes. Receitas fáceis e saborosas.',
        RECIPE: ''
      },
      FAQ: 'Tem alguma pergunta? Agrupámos todas as perguntas mais frequentes para responder melhor às suas sugestões.',
      PAGE: 'página',
      RECIPETYPE: 'Descubra as nossas receitas {{value}} e como cozinhar correctamente os vegetais. Receita vegetariana, rápida, original, fácil.',
      RECIPESEASON: 'Descubra as nossas receitas culinárias e como cozinhar bem os vegetais por {{value}}. Receita vegetariana, rápida, original, fácil.',
      RECIPEPREPARATION: 'Descubra as nossas receitas culinárias e como cozinhar bem os vegetais por {{value}}. Receita vegetariana, rápida, original, fácil.',
      RECIPEDIFFICULTY: 'Descubra as nossas receitas culinárias e como cozinhar vgetais {{value}}. Receita vegetariana, rápida, original, fácil.',
      RECIPEVEGETABLE: 'Descubra as nossas receitas para cozinhar {{value}} e como cozinhar correctamente os vegetais. Receita vegetariana, rápida, original, fácil.',
      RECIPECOST: 'Descubra as nossas receitas culinárias e como cozinhar os seus vegetais correctamente para {{value}}. Receita vegetariana, rápida, original, fácil.',
      RECIPEDISH: 'Descubra as nossas receitas e como cozinhar bem os vegetais para {{value}} os convidados. Receita vegetariana, rápida, original, fácil.',
      RECIPEDESC: 'Descubra as nossas receitas culinárias e como cozinhar vegetais {{value}}. Receita vegetariana, rápida, original, fácil.',
      '2RECIPEFACETS': 'Descubra as nossas receitas culinárias e como cozinhar vegetais {{value}}. Receita vegetariana, rápida, original, fácil.',
      '4RECIPEFACETS': 'Descubra as nossas receitas culinárias e como cozinhar bem os vegetais {{value}}.',
      PRODUCTSEASON: 'A nossa ampla gama de produtos permite-lhe preparar receitas da época {{value}} saborosas, fáceis, e equilibradas. Receitas simples e saborosas. ',
      PRODUCTTECHNO: 'A nossa ampla gama de produtos permite-lhe preparar receitas da época {{value}} saborosas, fáceis, e equilibradas. Receitas simples e saborosas. ',
      PRODUCTVEGETABLE: 'A nossa ampla gama de produtos permite-lhe preparar receitas saborosas, fáceis e equilibradas, baseadas em {{{value}}. Receitas simples e saborosas. Legumes cozidos.',
      PRODUCTRANGE: 'A nossa ampla gama de produtos permite-lhe preparar receitas saborosas, fáceis e equilibradas, baseadas em {{{value}}. Receitas simples e saborosas. Legumes cozidos.',
      THEME_ALL: 'A nossa ampla gama de produtos permite-lhe preparar receitas saborosas, fáceis e equilibradas. Receitas simples e saborosas. ',
      THEME_STARTER: 'Quer preparar uma refeição equilibrada? Uma ampla gama de produtos para preparar, de forma rápida e simples, receitas de entradas à base de vegetais, conselhos e truques para melhorar as suas refeições.',
      THEME_SALAD: 'Quer preparar uma refeição equilibrada? Uma ampla gama de produtos para preparar saladas rápidas e fáceis, conselhos e truques para melhorar as suas refeições.',
      THEME_CAN: '',
      THEME_FROZEN: '',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: '',
      THEME_RECIPE: 'Produtos para cozinhar de forma fácil e rápida deliciosas e equilibradas receitas à base de vegetais, conselhos e truques para melhorar as suas refeições. Receitas rápidas e equilibradas.',
      THEME_PLATE: 'Quer preparar uma refeição equilibrada? Produtos para cozinhar pratos quentes ou frios, à base de vegetais, fáceis e rápidos, conselhos e truques para melhorar as suas refeições.',
      THEME_ACC: 'Produtos para cozinhar guarnições rápidas e fáceis à base de vegetais, conselhos e truques para melhorar as suas refeições. Receitas simples e equilibradas.',
      THEME_SNACK: 'Quer preparar uma refeição equilibrada? Produtos para preparar receitas de aperitivos à base de vegetais, de forma fácil e rápida, conselhos e truques para melhorar as suas refeições.',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': 'A nossa ampla gama de produtos permite-lhe preparar receitas saborosas, fáceis e equilibradas {{value}}. Receitas simples e saborosas. ',
      NOTFOUND: 'Não foi encontrada a página solicitada. Sugerimos que faça uma nova pesquisa, ou tente novamente mais tarde.'
    },
    PIPE: { SOCIETY: ' | Bonduelle' },
    THEME_ALL: 'Todos',
    THEME_STARTER: 'Entradas',
    THEME_SALAD: 'Tudo para as minhas saladas',
    THEME_CAN: '',
    THEME_FROZEN: '',
    THEME_READY_MEALS: '',
    THEME_BIO: '',
    THEME_VEGETABLES: '',
    THEME_RECIPE: 'Ingredientes-receitas',
    THEME_PLATE: 'prato principal',
    THEME_ACC: 'Acompanhamento',
    THEME_SNACK: 'Snacks'
  },
  VEGETABLE: {
    DISCOVER_VEGETABLE: 'Descubra os nossos vegetais',
    OURS_RECIPES: 'As nossas receitas',
    DISCOVER_ALSO: 'Descubra também...',
    SEE_ALL: 'Ver todos os vegetais',
    ON: 'Vegetais sobre'
  },
  RECIPE: {
    'INGREDIENTS_LABEL ': 'Ingredientes para {{value}} pessoas',
    PEOPLES: '{{value}} pessoas',
    DIFFICULTY: { EASY: 'Fácil', MEDIUM: 'Médio', HARD: 'Difícil' },
    COST: { CHEAP: 'Barato', EXPENSIVE: 'Caro' },
    TIMING: { MIN: 'min', HOURS: 'horas' },
    STEP: 'etapa',
    DISCOVER_ALSO: 'Descubra também...',
    HOME_SEE_MORE: 'Descubra mais receitas',
    SHOWCASE: 'Destaques',
    HOME_BOOK: 'As nossas receitas',
    MIN_MORE: 'm e +',
    HOUR_MORE: 'h e +',
    FILTER_BY_CRITERIA: 'Filtrar por critérios',
    SORT_BY: 'Ordenar por',
    BEST_RATED: 'Os mais pontuados',
    MOST_CONSULTED: 'Os mais consultados',
    MEMBERS_RECIPES: 'Receitas dos membros',
    CRITERIA: 'Novidades',
    DISCOVER: { RECIPES: 'Descubra as nossas receitas' },
    DISCOVER_RECIPE: 'Descubra a receita',
    INSTRUCTIONS: 'Instruções',
    DEFAULT_TAG: 'Ideia de receita',
    MOST_RECENT: 'Mais recentes',
    RECIPE_VIDEOS: 'Vídeo-receitas',
    PRODUCT_ASSOCIATE: 'Receita com',
    BUDGET_LOW: 'Barato',
    BUDGET_HIGH: 'Alto',
    BUDGET_MIDDLE: 'Médio',
    PRINT_RECIPE: 'Imprimir receita',
    LET_YOURSELF_SURPRISED: 'Deixe-se surpreender',
    COOK_TIP_TITLE: '',
    ENJOY_MEAL: '',
    FILTER: { LEGEND: 'Legenda' }
  },
  PREDIGGO: { DIFFICULTY: { EASY: 'Fácil', MEDIUM: 'Médio', HARD: 'Difícil' } },
  COOKIES: {
    TEXT: 'Ao continuar a sua visita neste site, está a aceitar a utilização de cookies por razões técnicas, com a finalidade de análise estatística e de gestão do tráfego da rede, para partilhar informação com anunciantes doutros sites da rede, no intuito de proporcionar comunicações promocionais relevantes e que possam reflectir os seus interesses.',
    CLOSE: 'Fechar',
    MORE: 'Ler mais',
    AGREE_YOUTUBE: 'Deve aceitar cookies do Youtube para ver este vídeo',
    BUTTON_YOUTUBE: 'Aceitar cookies'
  },
  POPUP: { LAUNCH: 'POPUP-OUVERTURE-SITE' },
  FIELD_PLATE: { DISCOVER_VEGETABLE: 'Descubra as nossas fichas de produtos' },
  SEARCH: {
    OUR_RECIPES: 'Receitas',
    OUR_PRODUCTS: 'Produtos',
    OUR_ARTICLES: 'Artigos',
    RECIPES: 'Receitas',
    PRODUCTS: 'Produtos',
    ARTICLES: 'Artigos',
    ALL: 'Tudo',
    SORT_BY: 'Ordenar por',
    RESULTS: 'Resultados',
    RESULT: 'Resultado',
    PRODUCT_OUT: 'Produtos de',
    APPLY: 'Aplicar',
    FILTER: 'Filtro',
    RECIPE_OUT: 'Receita de',
    NO_RESULT: 'Ups... Fez uma pergunta difícil',
    SEE_MORE: 'Ver mais',
    DISCOVER_RECIPES: 'Descubra as nossas receitas',
    SEE_MORE_OF: 'Ver mais sobre',
    DELETE: 'Eliminar'
  },
  RECIPE_LIST: {
    OUR_RECIPES: 'Receitas',
    RESULTS: 'Resultados',
    SEE_MORE: 'Ver mais receitas'
  },
  PRODUCT: {
    BUTTON: {
      ACTU: 'Novidades dos produtos',
      FOUND: 'Onde encontrar este produto',
      DISCOVER: 'Descubra toda a gama'
    },
    NUTRITIONAL_VALUE: 'Valores nutricionais',
    CONDITIONING: 'Embalagem',
    PORTIONS: 'Porções',
    NET_WEIGHT: 'Peso líquido',
    DRY_WEIGHT: 'Peso líquido escorrido',
    INGREDIENTS: 'Ingredientes',
    OUR_RECIPES: 'Descubra as nossas receitas',
    FOR: 'Para',
    PER_PORTION: 'e por porção de',
    ENERGY: 'Energia',
    FAT: 'Lípidos',
    SATURATE: 'Dos quais saturados',
    GLUCIDES: 'Hidratos de carbono',
    FIBER: 'Fibra',
    PROTEINS: 'Proteínas',
    SALT: 'Sal',
    DISCOVER_ALSO: 'Descubra também...',
    MORE_INFORMATION: 'Mais informação',
    TRACES: 'Vestígios de',
    CONTAINS: 'Contém',
    SLIDER: { SHEET: 'Ver ficha', BUY: 'Onde comprar este produto ?' },
    NUTRISCORE: 'informação-nutriscore',
    TRIMAN: 'informação-triman',
    AGRICONFIANCE: 'informação-agriconfiance',
    BIO: 'informação-bio',
    PESTICIDE_FREE: '',
    LEAF: '',
    LOCK: '',
    SEE_ALL: 'Ver todos os produtos',
    LEGISLATION: 'Conforme a legislação em vigor.',
    PORTION: { SINGULAR: 'Porção', PLURAL: 'Porções' },
    DISCLAIMER: '',
    COOKING: 'Preparação',
    CONSERVATION: 'conservação'
  },
  FAQ: {
    ON: 'Perguntas sobre',
    ALL: 'Tudo',
    SEE_MORE: 'Ver mais perguntas',
    TITLE_1: 'Todas as respostas às suas perguntas',
    TITLE_2: 'Tem alguma pergunta sobre um produto em particular?',
    LINK_LABEL: 'Escreva-nos'
  },
  FORM: {
    GO_TO_STEP: 'Ir para passo seguinte',
    STEP: 'Passo',
    RECEIVE_EMAIL_COPY: 'Receber cópia por e-mail',
    SEND: 'Enviar',
    GDPR: 'Mais informação sobre a gestão dos seus dados\n' +
      '\n' +
      'Na BONDUELLE PORTUGAL, AGRO-INDÚSTRIA,S.A., somos responsáveis pelo tratamento dos dados pessoais que nos fornece, os quais serão tratados com a finalidade de responder aos seus pedidos ou consultas realizadas, e se for o caso, proceder ao registo da sua conta no site.\n' +
      '\n' +
      'A legitimidade para o tratamento é o consentimento prestado para remeter as suas questões, proceder ao registo como utilizador do Site e a aceitação das condições gerais de uso da conta.Tem os seguintes direitos sobre os seus dados: Aceder, retificar, eliminar e opor-se, limitar e transferir os seus dados pessoais, perante a Bonduelle Portugal Agro-Indústria S.A. Poderá obter informação adicional sobre proteção de dados na nossa Política de Privacidade.\n' +
      '\n' +
      'Ao registar-se consente expressamente o tratamento dos seus dados pessoais com as finalidades indicadas.',
    ERROR: 'Todos os campos com * são de preenchimento obrigatório.',
    SEND_ERROR: 'Ocorreu um erro, por favor, tente novamente mais tarde.',
    NOTICE: 'Para o correcto tratamento do seu registo, a Bonduelle poderá, conforme o caso, solicitar dados sobre saúde. São facultativos, é livre de não os transmitir, se assim achar mais conveniente.',
    SENDING: 'A enviar formulário...',
    LOADING: 'A carregar...',
    PRODUCT_INFORMATION_TITLE: 'Informação sobre o produto',
    PRODUCT_INFORMATION: {
      SEO_TITLE: 'Informação e dados do produto Bonduelle',
      SEO_DESC: 'Contacte o nosso Serviço de Atenção ao Consumidor para obter informação sobre os produtos das nossas gamas. Resposta personalizada em 48 horas.'
    },
    INFORMATIONS: 'A sua informação',
    MISTER: 'Estimado Sr.',
    MISS: 'Estimada Sr.ª',
    NAME: 'Apelido',
    OUR_NAME: 'O seu apelido',
    FIRSTNAME: 'Nome próprio',
    OUR_FIRSTNAME: 'O seu nome próprio',
    MAIL: 'O seu endereço electrónico',
    MAIL_PLH: 'email@',
    MAIL_CONFIRM: 'Confirme o seu endereço electrónico',
    MAIL_ERROR: 'Por favor, introduza um endereço electrónico válido',
    MAIL_ERROR_CONFIRM: 'Por favor, confirme o seu endereço electrónico',
    ADDRESS: 'A sua morada',
    STREET_PLH: '',
    STREET: 'Número e nome da rua',
    ADDRESS_COMPLEMENT: 'Informação adicional sobre a morada',
    ADDRESS_COMP_PLH: 'Informação adicional',
    ZIPCODE: 'Código Postal',
    CITY: 'Localidade',
    CITY_PLH: 'Distrito',
    PHONE: 'Telefone',
    PHONE_PLH: 'O seu número de telefone',
    COUNTRY: 'País',
    PRODUCT_INFORMATIONS: 'Informação sobre o produto',
    PRODUCT_INFORMATION_DESC: 'Para que os nossos serviços possam analisar o seu pedido da melhor forma possível, é favor facultar a seguinte informação:',
    PRODUCT: {
      DETAIL: {
        DESC: 'Por cada critério solicitado, pode preencher o campo do formulário e anexar uma fotografia. Informação: a sua fotografia deve mostrar claramente o código de barras, o número de lote e a data de validade (DLUO) do produto.'
      },
      NOTE: {
        TITLE: 'Fazer comentário sobre produto',
        SEO_TITLE: 'Comentário sobre produto | Bonduelle',
        SEO_DESC: 'Deseja deixar uma observação ou comentário sobre um produto da nossa gama? Contacte o nosso Serviço de Atenção ao Consumidor para que o/a possamos ajudar. Resposta personalizada em 48 horas.'
      }
    },
    PRODUCT_RANGE: 'Gama de produtos afectada',
    PRODUCT_NAME: 'Nome do produto',
    PRODUCT_NAME_PLH: 'Escreva o nome do produto Bonduelle a que se refere no seu pedido',
    PRODUCT_INFORMATIONS_DETAIL: 'Informação detalhada',
    PRODUCT_INFORMATIONS_LABEL: 'Código de barras do produto',
    PRODUCT_INFORMATIONS_PLH: 'O código de barras inscrito na embalagem do produto consiste em 13 dígitos.',
    PRODUCT_LOT: 'Número de lote do produto',
    PRODUCT_LOT_INFO: 'O número de lote começa com a letra L, seguido de vários dígitos, além de variar conforme o produto, como por exemplo: L72450492107:13.',
    PHOTO: 'Juntar fotografia',
    EXPIRED_DATE: 'Data da DLUO/DLC',
    EXPIRED_DATE_ERROR: 'Por favor, introduza a data no formato dd/mm/aaaa',
    EXPIRED_DATE_DESC: 'A DLUO (data limite de utilização óptima) e a DLC (data limite de consumo) apresentam-se em forma de datas.',
    PHOTO_MORE: 'Fotografias adicionais',
    MESSAGE: 'Sua mensagem *',
    OUR_MESSAGE: 'escreva sua mensagem',
    SALES_OPERATIONS: {
      TITLE: 'Informação sobre uma acção comercial',
      SEO_TITLE: 'Informação sobre as acções actuais: jogos, concursos... | Bonduelle',
      SEO_DESC: 'Contacte o nosso Serviço de Atenção ao Consumidor para obter informação sobre uma acção comercial em curso. Resposta personalizada em 48 horas.'
    },
    PRESS_SERVICE: 'Contacte o nosso serviço de imprensa',
    FOOD_SERVICE: 'Contacte a nossa equipa de Bonduelle Food Service',
    BUSINESS_INFORMATION: '',
    MODAL_INFORMATION: '',
    JOB_OFFER: { TITLE: '', SEO_TITLE: '', SEO_DESC: '' },
    WEB_SUGGESTION: {
      TITLE: 'Fazer uma sugestão sobre a web ',
      SEO_TITLE: 'Mensagem de melhoria, sugestão da web| Bonduelle',
      SEO_DESC: 'Contacte o nosso Serviço de Atenção ao Consumidor para ajudar-nos a melhorar a web.'
    },
    ANOTHER: {
      TITLE: 'Realizar outro pedido',
      SEO_TITLE: 'Contacto para qualquer outro pedido, serviço | Bonduelle',
      SEO_DESC: 'Contacte o nosso Serviço de Atenção ao Consumidor para obter informação, serviço ou sugestões. Resposta personalizada em 48 horas.'
    },
    AMBASSADOR: 'Convirta-se num embaixador Bonduelle',
    CATEGORY: {
      SALAD: '',
      FROZEN: 'Congelado',
      CAN: 'Conserva',
      TAKEAWAY: '',
      VEGETABLE: ''
    }
  },
  CONTACT: {
    TITLE: 'À sua disposição',
    SPECIAL_INFO: '',
    TAKE_CARE: '',
    BY_MAIL: 'Por correio',
    BY_PHONE: 'Por telefone',
    BY_SOURDLINE: '',
    MAIL_TEXT: 'Serviço de Atenção ao Consumidor\n' +
      ' Zona Industrial de Santarém\n' +
      ' 2005-002 Várzea Str',
    PHONE_TEXT: 'O nosso serviço de Atenção ao Consumidor está à sua disposição de segunda a sexta-feira, das 09h00 às 18h00. Não obstante, pode deixar uma mensagem de voz, 24 horas por dia no número +351 243 359 070 (chamada para a rede fixa nacional)',
    SOURDLINE_TEXT: '',
    BY_DISTRIBUTOR: '',
    BY_DISTRIBUTOR_TEXT: '',
    SUBTITLE: 'Deseja contactar-nos para:',
    AGREEMENT: '',
    READ_MORE: "Além disso, tem o direito de acesso, retificação, eliminação e portabilidade dos seus Dados Pessoais, bem como o direito de limitação e oposição ao tratamento dos Dados Pessoais que lhe digam respeito, que pode exercer escrevendo para Dpo_france(at)bonduelle(ponto)com ou por correio postal: ao cuidado do Encarregado de Proteção de Dados, Bonduelle, Rue Nicolas Appert, BP 30173, F-59653 Villeneuve D'Ascq. Para saber mais sobre a gestão dos seus dados e dos seus direitos clique aqui",
    READ_MORE_LABEL: 'Saber mais',
    SATISFACTION_TEXT: 'Para entrar em contacto consigo para lhe fornecer as informações\n' +
      'solicitadas sobre o produto e para lhe enviar um questionário de\n' +
      'satisfação se assim o desejar, processaremos as informações fornecidas na\n' +
      'nossa qualidade de responsável pelo tratamento dos dados. A base legal\n' +
      'para processar o seu pedido é o consentimento devido aos potenciais\n' +
      'dados relacionados com a saúde que você pode fornecer no seu pedido.\n' +
      'Esta informação será conservada durante o tempo necessário para\n' +
      'processar o seu pedido e até 5 anos para comprovar que o seu pedido foi\n' +
      'processado e para cumprir as nossas obrigações legais. A base legal para\n' +
      'o envio do questionário de satisfação é também o seu consentimento.\n' +
      'Esta informação será conservada durante o tempo necessário para o\n' +
      'envio do questionário de satisfação e até um máximo de 1 ano. Para saber\n' +
      'mais sobre o tratamento dos seus dados pessoais, consulte a nossa <a href="https://www.bonduelle.pt/politica-de-privacidade" target="_blank" class="underline">Política de Privacidade</a>.',
    SATISFACTION_CHECKBOX: 'Ao marcar esta caixa, você concorda em receber um questionário de\n' +
      'satisfação (composto por uma única pergunta) assim que sua solicitação\n' +
      'for processada. Isso é opcional.',
    CHECKBOX_TEXT: 'Ao marcar esta caixa, você concorda que Bonduelle possa processar os\n' +
      'dados pessoais de saúde que você fornecer em sua mensagem com o\n' +
      'único propósito de responder à sua solicitação. <strong>Isso é obrigatório para\n' +
      'processar sua solicitação</strong>.',
    PHONE: 'De segunda a sexta-feira, das 08h00 às 18h00, à excepção de feriados nacionais, pelo telefone número +351 243 359 070 (chamada para a rede fixa nacional)',
    CALL_US: 'Contacte-nos',
    TO_WRITE: 'Escreva-nos',
    PHONE_NUMBER: '00351 243 359 070',
    SUCCESS_MESSAGE: 'Obrigado! A sua mensagem foi recebida pela nossa equipa. Será processada num período estimado de 2 a 3 dias, excluindo fins-de-semana e feriados.\n' +
      'Permanecemos ao seu serviço, de segunda a sexta-feira, das 09h00 às 18h00, pelo telefone número +351 243 359 070 (chamada para a rede fixa nacional).\n' +
      'Como parte da supervisão da nossa qualidade de serviço, as chamadas poderão ser gravadas.',
    PHOTOERROR: { MESSAGE: '' }
  },
  GLOBAL: { READ_MORE: '' },
  FLOWBOX: { GENERIC_KEY: '', PRODUCT_KEY: '', RECIPE_KEY: '', HOME_KEY: '' },
  TOKEN: {
    FACEBOOK: '',
    PAGE_FACEBOOK_ID: '',
    INSTAGRAM: '',
    INSTA_ID: '17841408019332251',
    YOUTUBE: '',
    TWITTER: '',
    MAGAZINE: ''
  },
  COOKIE: {
    BANNER: {
      MANDATORY: 'Requeridos',
      ANALYTICS: 'Análise',
      MARKETING: 'Marketing',
      SOCIAL_MEDIA: 'As redes sociais',
      ACCEPT: 'Aceitar tudo',
      REJECT: 'Rejeitar tudo',
      SET_UP: 'Configuração',
      MANDATORY_DETAILS: 'Os cookies necessários são necessários para o funcionamento do site, por exemplo, para armazenar suas informações de login e fornecer uma conexão segura.\n' +
        'Os cookies necessários para usar o site bonduelle.es são:\n' +
        '<ul>\n' +
        '<li> cookies de sessão: cookies gerados por aplicativos baseados em PHP. Este é um identificador de propósito geral usado para gerenciar variáveis ​​de sessão do usuário. Normalmente é um número gerado aleatoriamente, a forma como é usado pode ser específica do site, mas um bom exemplo é manter um estado de login para um usuário entre as páginas. </li>\n' +
        '</ul>',
      ANALYTICS_DETAILS: 'Os cookies funcionais nos ajudam a otimizar o site, por exemplo, para coletar estatísticas, registrar suas preferências para interações sociais ou medir o desempenho e a estabilidade de nosso aplicativo.\n' +
        'para\n' +
        'Os cookies analíticos usados ​​pelo site bonduelle.pt são:\n' +
        '<ul>\n' +
        '<li> DoubleClick: este cookie é definido por um DoubleClick (propriedade do Google) para criar um perfil com os interesses do visitante do site e mostrar anúncios relevantes em outros sites. A principal atividade da empresa é a troca de anúncios pelas ofertas do Google em tempo real. </li>\n' +
        '<li> Google Analytics: esses cookies permitem que você rastreie as páginas mais visitadas e o tráfego do site. </li>\n' +
        '<li> UTMTracking: este cookie permite a gestão de variáveis ​​UTM para um acompanhamento ideal das diferentes campanhas de publicidade online </li>\n' +
        ' </ul>',
      MARKETING_DETAILS: '"Os cookies de marketing nos permitem rastrear sua receita e preferências de produto para que possamos sempre lhe oferecer os produtos que melhor se adaptam à sua pesquisa.\n' +
        'para\n' +
        'Os cookies de marketing usados ​​pelo site bonduelle.pt são:\n' +
        '<ul>\n' +
        `li> Redes sociais: esses cookies permitem que Bonduelle 'SocialWall' seja exibido na página inicial do site. Esta parede "" permite-lhe ver as publicações mais recentes das principais redes sociais utilizadas pelo Grupo Bonduelle. </li>\n` +
        '</ul>',
      V2: 'ok'
    },
    MODAL: {
      TITLE: 'Gerenciar cookies',
      TEXT: 'O Grupo Bonduelle se compromete a garantir a proteção dos dados pessoais dos usuários de seus sites e, em particular, do site Bonduelle.pt. Para lhe permitir usufruir dos serviços que a web oferece, como a consultoria de produtos, a descoberta de novas receitas gourmet, a subscrição de determinados serviços como as nossas Newsletters e também a optimização da sua utilização e personalização de acordo com o seu perfil, o nosso site utiliza cookies. O Grupo Bonduelle está totalmente empenhado em garantir um alto nível de proteção com relação a esses Dados Pessoais e em cumprir todas as regulamentações de proteção de dados aplicáveis ​​ao processar Dados Pessoais em conexão com o uso de nosso website. Portanto, você pode habilitar / desabilitar os cookies a qualquer momento.',
      CONFIG: 'Configuração',
      SAVE: 'Salve'
    }
  },
  LINK: { JOB_OFFERS: 'https://jobs.bonduelle.com/?locale=pt_PT' },
  CLIC2BUY: { INFOS: '' }
};
