<div class="sf-form-ta" *ngIf="data && formControl">
  <label class="sf-form-ta-label sf-title" [for]="controlName" *ngIf="data.label" [ngClass]="{'error-color': !formControl.pristine && formControl.invalid}">
    {{ data.label | translate }}
    <span *ngIf="data.required"> *</span>
  </label>

  <textarea class="sf-form-ta-input sf-secondary-font-color-placeholder sf-text" rows="6" [placeholder]="data.placeholder || '' | translate" [id]="controlName" [formControl]="formControl" type="text"></textarea>

  <div class="sf-form-ta-error error-color sf-title" *ngIf="!formControl.pristine && formControl.invalid">
    {{ data.error || 'SF.FORM.ERROR' | translate }}
  </div>
</div>
