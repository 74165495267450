import { SfModel } from '@app/sf-lib/public_api';
import { RequestAuthentication } from './request-authentication.model';
import { RequestPostParams } from './request-post-params.model';


export class RequestPost extends SfModel {

  /**
   * Post function
   * @memberof RequestPost
   */
  private fct_name = 'jsPostData';


  public captcha: string
  public wine_me: string
   /**
   * Params
   * @memberof RequestPost
   */
  public params: RequestPostParams;

  constructor(data?: {params: RequestPostParams}, captcha?: string, wine_me?: string) {
    super();
    if (data) {
      this.params = new RequestPostParams(data.params);
      this.captcha = captcha
      this.wine_me = wine_me
    } else {
      this.params = new RequestPostParams();
    }
  }

  public static create(): RequestPost {
    const newEntity: RequestPost = new RequestPost();
    newEntity.params = RequestPostParams.create();
    return newEntity;
  }
}
