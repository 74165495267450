import {
  SfMediaImage, SfItem, SfLink,
  SfLocalizeService,
  SfRubric, SfSection, SfTag, SfBanner
} from '@app/sf-lib/public_api';
import { SomPage } from './page.model';
import { AppInjector } from '../app-injector';

const charsMapConvert = require('@app/core/charsMap');

export class SomMagazineArticlePage extends SomPage implements SfItem, SfBanner {

  /** Unique ID from server */
  nid: number;

  /** Duplicate ID. When a node is duplicated we should show this ID in url  */
  duplication_id: number;

  /** Type from server */
  type: string;

  /** Title */
  title: any;

  /** Path alias information */
  path: {
    alias: string;
  };

  /** Main image of the article */
  cover: any;

  /** Main image of the article for mobile */
  smallcover: SfMediaImage;

  /** Should item be displayed big or small */
  display: boolean;

  rubric: SfRubric;

  sections: SfSection[];

  tag: any;

  get link(): SfLink {
    let alias = `${this.nid}`;
    if (this.path && this.path.alias) {
      alias = charsMapConvert(this.path.alias);
    }
    return {
      is_external: false,
      label: '',
      url: `${this.localize.instant(this.baseUrl)}/${alias}/${this.nid}/${this.duplication_id}`
    };
  }
  set link(value: SfLink) {
    return;
  }

  /** SfBanner uppertitle */
  get uppertitle(): string {
    if (this.tag && this.tag.name) {
      return this.tag.name && this.tag.name[0] && this.tag.name[0]['value'] ? this.tag.name[0]['value'] : this.tag.name;
    } else {
      return null;
    }
  }

  /** SfBanner background */
  get background(): SfMediaImage {
    return this.cover;
  }

  /** SfBanner smallbackground */
  get smallbackground(): SfMediaImage {
    return this.smallcover;
  }


  private readonly baseUrl = '/magazine/article';

  private localize: SfLocalizeService;

  constructor() {
    super();
    try {
      const injector = AppInjector.getInjector();
      this.localize = injector.get(SfLocalizeService);
    } catch (e) {
      console.error('Failed initializing model', e);
    }
  }

  /**
   * Custom function used by JSON.stringify
   * This prevent JSON.stringify to throw an error on circular
   * references
   *
   * @param {string} nm Name of the property
   * @returns {string}
   * @memberof SomRecipePage
   */
  public toJSON(nm: string) {
    const newArticle = {
      nid: this.nid,
      duplication_id: this.duplication_id,
      type: this.type,
      title: this.title,
      cover: this.cover,
      display: this.display,
      rubric: this.rubric,
      sections: this.sections,
      tag: this.tag,
      path: this.path,
      link: this.link,
      uppertitle: this.uppertitle,
      background: this.background,

      lang: this.lang,
      content: this.content,
      meta_title: this.meta_title,
      meta_description: this.meta_description,
      meta_keywords: this.meta_keywords,
    };
    return newArticle;
  }
}
