/* tslint:disable */export const sk_sk = {
  '404': {
    TITLE: 'Jéj…',
    PAGENOTFOUND: 'Stránka neexistuje',
    BUTTON: 'Späť domov',
    RECIPES: 'Objavte naše recepty'
  },
  HOME: {
    SHOWCASE: 'Recepty',
    CATCH_PHRASE: '',
    SHOWCASE_SEE_ALL: 'Zobraziť všetky recepty',
    MAGAZINE: { TITLE: 'Hore', BUTTON: 'Vedeli ste, že..' },
    OUR_VEGETABLES: 'Výrobky',
    SOCIAL_WALL: 'Zdieľajme spoločne vášeň pre rastliny',
    FAQ_TITLE: 'Your questions',
    FAQ_SEE_MORE: 'See more',
    FLOWBOX: ''
  },
  SELECT: { DEFAULT_VALUE: 'Aktuálnych' },
  MENU: {
    CONTACT_US: 'Kontakty',
    MY_ACCOUNT: 'Môj účet',
    GOOD_DEALS: 'Špeciálne ponuky',
    NEWSLETTER: 'Bonduelle Newsletter'
  },
  FOOTER: {
    LINK: {
      BONDUELLE: 'Bonduelle.sk',
      CREDITS: 'Credits',
      LEGAL_NOTICE: 'Právne dohody',
      TERMS_OF_SALES: 'Podmienky obchodu',
      COOKIES: 'Cookies',
      SITEMAP: 'Mapa stránky',
      PRIVACY_POLICY: 'Zásady ochrany osobných údajov',
      ALL_RIGHTS_RESERVED: '2020 © Všetky práva vyhradené'
    },
    HISTORY: {
      TITLE: 'Bonduelle, už od roku 1853',
      TEXT: 'Bonduelle je rodinný podnik, ktorý pracuje už sedem generácií v oblasti poľnohospodárskej produkcie, ktorá dbá o šetrný prístup k pôde a jej pracovníkom. Bonduelle dbá o efektívnu, inteligentnú a podpornú agroekológiu, ktorá myslí na budúcnosť. Heslo spoločnosti - Príroda je naša budúcnosť.'
    },
    NEWSLETTER: {
      TITLE: 'Komunita Bonduelle',
      TEXT: 'Súhlasím so zasielaním newsletteru «Všetko o zelenine od Bonduelle» (novinky, propagácia, súťaže, články ...)',
      PLACEHOLDER: 'Váš email',
      GDPR_LINK: 'Ďalšie informácie o správe dát'
    }
  },
  PRODUCTS: 'Výrobky',
  MAGAZINE: {
    HOME: 'Vítajte v časopise',
    CATCH_PHRASE: '',
    MENU: { BONDUELLE_LINK: 'Choď na bonduelle.sk' },
    SHOWCASE: 'Náhľad',
    PREVIOUS_ARTICLES: 'Predchádzajúci článok',
    PREVIOUS_ARTICLES_SEE_ALL: 'Pozrite sa na všetky naše články',
    PREVIOUS_EDITIONS: 'Naše predchádzajúce vydania',
    PREVIOUS_EDITIONS_SEE_ALL: 'Zobraziť ďalšie vydanie',
    PREVIOUS_ARTICLES_BY_CATEGORY: 'Predchádzajúce články podľa kategórie',
    RUBRIC: {
      SEE_ALL: 'Pozrite sa na všetky recepty',
      SEE_ALL_ARTICLES: 'Pozrite sa na všetky články',
      ON: 'články na'
    },
    RUBRIC_COOK: { ON: 'recepty na' },
    ARTICLE: { JOIN_US: 'Pripoj sa k nám', READ_ALSO: 'Prečítaj si tiež' },
    RECIPE: {
      INGREDIENTS: 'Ingrediencie',
      INGREDIENTS_LABEL_DESC: 'pre {{value}} osôby',
      INGREDIENTS_LABEL_ONE_DESC: 'pre {{value}} osobu',
      INGREDIENTS_LABEL: 'ingrediencie pre {{value}} osôb',
      INGREDIENTS_LABEL_ONE: 'ingrediencie pre {{value}} osobu',
      PEOPLES: '{{value}} osôb',
      PEOPLE: '{{value}} človek',
      DIFFICULTY: { EASY: 'Jednoduché', MEDIUM: 'Stredné', HARD: 'Obťažné' },
      COST: { CHEAP: 'Lacné', EXPENSIVE: 'Drahé' },
      TIMING: { MIN: 'min', HOURS: 'hodín' },
      STEP: 'krok',
      DISCOVER_ALSO: 'Objavte tiež…'
    }
  },
  ARTICLE: { DISCOVER_ALSO: 'Objavte tiež…', INGREDIENTS: 'Ingrediencie' },
  SF: {
    SLIDE: { DISCOVER: 'Objavte' },
    SEARCH: { PLACEHOLDER: 'Hľadať', ALL: 'Všetko' },
    NEWSLETTER: {
      BTN_LABEL: 'Registrujem sa',
      PLACEHOLDER: 'Váš e-mail',
      ERROR_MESSAGE: 'Neplatný e-mail',
      ALREADY_SUBSCRIBED: 'Už máte predplatné',
      GDPR: {
        TXT: 'Viac informácií o spracovaní Vašich údajov',
        LINK: '/zasady-ochrany-osobnych-udajov'
      }
    },
    MONTHLY_FOLDER: { TITLE: 'Mesačné novinky' },
    MINI_NEWSLETTER: {
      PLACEHOLDER: 'Váš e-mail',
      GDPR: {
        TXT: 'Viac informácií o spracovaní Vašich údajov',
        LINK: '/zasady-ochrany-osobnych-udajov'
      }
    },
    SOCIALITEM: { SHARE: 'Zobraziť', COMMENT: 'komentár', BONDUELLE: 'Bonduelle' },
    SEARCHRECIPE: { DISCOVER_ALL: 'hľadajte vo všetkých receptoch!' },
    FORM: {
      ERROR: 'Neplatné pole',
      PHOTO: {
        ADD: 'Pridať obrázok',
        DELETE: 'Vymazať obrázok',
        SEE: 'Prezerať'
      }
    }
  },
  CORPORATE: {
    COMPANY_STORY: 'história spoločnosti',
    CSR_COMMITMENT: 'závazky csr',
    VEGETABLE_INNOVATIONS: 'Bonduelle záväzky',
    NEWS: 'všetky novinky',
    SEE_MORE: 'Prehliadnuť viac článkov',
    ON: 'článkov z'
  },
  NEWSLETTER: {
    TITLE: 'Prihláste sa na odber newsletteru',
    SUBTITLE: 'Dobré nápady, kuchárske inšpirácie a trocha kultúry!'
  },
  ROUTES: {
    products: 'vyrobky',
    news: 'novinky',
    'since-1853': 'od-roku-1853',
    'field-plate': 'z-pola-na-tanier',
    'company-story': 'historia-spolocnosti',
    'csr-commitments': 'zavazky-csr',
    'vegetal-innovations': 'zeleninove-novinky',
    magazine: 'magazin',
    all: 'vsetko',
    article: 'clanok',
    cook: 'varit',
    cultivate: 'kultivovat',
    explore: 'objavovat',
    recipe: 'recept',
    recipes: 'recepty',
    'recipes-home': 'recepty-domov',
    r: 'r',
    credits: 'kredity',
    'legal-notice': 'pravne-dohody',
    'terms-of-sales': 'podmienky-obchodu',
    cookies: 'cookies',
    sitemap: 'mapa-stranky',
    'privacy-policy': 'zasady-ochrany-osobnych-udajov',
    'all-rights-reserved': 'vsetky-prava-vyhradene',
    search: 'hladat',
    'articles-search': 'clanok',
    vegetables: 'zelenina',
    p: 'p',
    contact: 'kontakt',
    'info-product': 'informacie-o-vyrobkoch',
    'note-product': 'poznamky-k-vyrobkom',
    suggestion: 'navrh',
    'other-request': 'ina-poziadavka',
    faq: 'casto-kladene-otazky',
    'all-themes': 'vsetky-temy',
    'products-search': 'hladanie-vyrobkov',
    recipesearch: 'hladanie-receptu',
    'product-news': 'novinky-o-vyrobkoch',
    'good-deals': 'akcie',
    'not-found': 'nenajdene',
    'menu-page': 'zaujimavosti.',
    'menu-one': 'menu-jedna',
    'menu-two': 'menu-dva',
    'menu-three': 'menu-tri',
    'menu-four': 'menu-styri',
    'menu-external': '',
    'theme-pasta-rice': 'cestoviny-a-ryza',
    'theme-bowls': 'misy',
    'theme-soup': 'polievka',
    'theme-snack': 'desiata',
    'theme-sauce': 'omacka',
    'theme-unique-plate': 'lahodkovy-tanier',
    'theme-over': 'dalsi-jedla',
    'theme-legumin': 'strukoviny',
    'theme-ingredients-recipes': 'ingrediencie - recepty',
    'theme-cook-plate': ''
  },
  BREAD: {
    products: 'vyrobky',
    news: 'novinky',
    'since-1853': 'od-roku-1853',
    'field-plate': 'z-pola-na-tanier',
    'company-story': 'historia-spolocnosti',
    'csr-commitments': 'zavazky csr',
    'vegetal-innovations': 'zeleninove-novinky',
    magazine: 'magazin',
    all: 'všetko',
    article: 'clanok',
    cook: 'varit',
    cultivate: 'kultivovat',
    explore: 'objavovat',
    recipe: 'recept',
    recipes: 'recepty',
    'recipes-home': 'recepty-domov',
    'articles-search': 'clanok',
    search: 'hladat',
    vegetables: 'zelenina',
    contact: 'kontakt',
    'info-product': 'informacie-o-vyrobkoch',
    'note-product': 'poznamky-k-vyrobkom',
    suggestion: 'navrh',
    'other-request': 'ina-poziadavka',
    faq: 'casto-kladene-otazky',
    'all-themes': 'vsetky-temy',
    'products-search': 'hladanie-vyrobkov',
    recipesearch: 'hladanie-receptu',
    'product-news': 'novinky-o-vyrobkoch',
    'good-deals': 'akcie',
    'not-found': 'nenajdene',
    'menu-page': 'zaujímavosti',
    'menu-one': 'menu-jedna',
    'menu-two': 'menu-dva',
    'menu-three': 'menu-tri',
    'menu-four': 'menu-styri'
  },
  MENU_PAGE: { TITLE1: '', TITLE2: '', TITLE3: '', TITLE4: '', EXTERNAL: '' },
  TECHNICAL: { MENU_PAGE: '' },
  SEO: {
    TITLE: {
      MENU_ONE: '',
      MENU_TWO: '',
      MENU_THREE: '',
      MENU_FOUR: '',
      HOME: 'Bonduelle',
      SEARCH: {
        MAIN: '{{value}}',
        RECIPES: '{{value}}',
        VEGETABLES: '{{value}}',
        ARTICLES: '{{value}}'
      },
      CONTACT: 'Bonduelle',
      RECIPES: 'Bonduelle',
      RECIPE_HOME: 'Bonduelle',
      RECIPE: 'Recept {{value}} | Bonduelle',
      PRODUCTS: 'Bonduelle',
      NEWS: 'Bonduelle',
      CORPORATE: {
        NEWS: 'Bonduelle',
        COMPANY_STORY: 'Bonduelle',
        CSR_COMMITMENTS: 'Bonduelle',
        VEGETAL_INNOVATIONS: 'Bonduelle',
        FIELD_PLATE: 'Bonduelle',
        ARTICLE: '{{value}} | Bonduelle',
        VEGETABLE: '{{value}}'
      },
      PRODUCT: '{{value}}',
      MAGAZINE: {
        NEWS: 'Bonduelle',
        ARTICLE: 'Bonduelle',
        COOK: 'Bonduelle',
        GROW: 'Bonduelle',
        EXPLORE: 'Bonduelle',
        RECIPE: 'Bonduelle'
      },
      FAQ: 'Bonduelle',
      NOTFOUND: 'Bonduelle',
      RECIPES_ALL: 'Všetky recepty',
      RECIPETYPE: '{{value}}',
      RECIPESEASON: '{{value}}',
      RECIPEPREPARATION: '{{value}}',
      RECIPEDIFFICULTY: '{{value}}',
      RECIPEVEGETABLE: '{{value}}',
      RECIPECOST: '{{value}}',
      RECIPEDISH: '{{value}}',
      RECIPEDESC: '{{value}}',
      '2RECIPEFACETS': '',
      '4RECIPEFACETS': '',
      PRODUCTSEASON: '',
      PRODUCTTECHNO: '',
      PRODUCTVEGETABLE: '',
      PRODUCTRANGE: '',
      THEME_ALL: '',
      THEME_STARTER: '',
      THEME_SALAD: '',
      THEME_CAN: '',
      THEME_FROZEN: '',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: '',
      THEME_RECIPE: '',
      THEME_PLATE: '',
      THEME_ACC: '',
      THEME_SNACK: '',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': ''
    },
    DESCRIPTION: {
      MENU_ONE: '',
      MENU_TWO: '',
      MENU_THREE: '',
      MENU_FOUR: '',
      HOME: '{{value}}',
      SEARCH: {
        MAIN: '{{value}}',
        RECIPES: '{{value}}',
        VEGETABLES: '',
        ARTICLES: ''
      },
      CONTACT: '',
      RECIPES: '',
      RECIPE_HOME: '',
      RECIPE: '',
      PRODUCTS: '',
      NEWS: '',
      CORPORATE: {
        NEWS: '',
        COMPANY_STORY: '',
        CSR_COMMITMENTS: '',
        VEGETAL_INNOVATIONS: '',
        VEGETABLE: '',
        FIELD_PLATE: '',
        ARTICLE: ''
      },
      PRODUCT: '{{value}}',
      MAGAZINE: {
        NEWS: '',
        ARTICLE: '',
        COOK: '',
        GROW: '',
        EXPLORE: '',
        RECIPE: ''
      },
      FAQ: '',
      PAGE: '',
      RECIPETYPE: '{{value}}',
      RECIPESEASON: '{{value}}',
      RECIPEPREPARATION: '{{value}}',
      RECIPEDIFFICULTY: '{{value}}',
      RECIPEVEGETABLE: '{{value}}',
      RECIPECOST: '{{value}}',
      RECIPEDISH: '{{value}}',
      RECIPEDESC: '{{value}}',
      '2RECIPEFACETS': '',
      '4RECIPEFACETS': '',
      PRODUCTSEASON: '',
      PRODUCTTECHNO: '',
      PRODUCTVEGETABLE: '',
      PRODUCTRANGE: '',
      THEME_ALL: '',
      THEME_STARTER: '',
      THEME_SALAD: '',
      THEME_CAN: '',
      THEME_FROZEN: '',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: '',
      THEME_RECIPE: '',
      THEME_PLATE: '',
      THEME_ACC: '',
      THEME_SNACK: '',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': '',
      NOTFOUND: ''
    },
    PIPE: { SOCIETY: ' | Bonduelle' },
    THEME_ALL: '',
    THEME_STARTER: '',
    THEME_SALAD: '',
    THEME_CAN: '',
    THEME_FROZEN: '',
    THEME_READY_MEALS: '',
    THEME_BIO: '',
    THEME_VEGETABLES: '',
    THEME_RECIPE: '',
    THEME_PLATE: '',
    THEME_ACC: '',
    THEME_SNACK: ''
  },
  VEGETABLE: {
    DISCOVER_VEGETABLE: '',
    OURS_RECIPES: 'Naše recepty',
    DISCOVER_ALSO: 'Objavte tiež…',
    SEE_ALL: 'Pozrite si všetku zeleninu',
    ON: 'zelenina na'
  },
  RECIPE: {
    'INGREDIENTS_LABEL ': 'Ingrediencie pre {{value}} ľudí',
    PEOPLES: '{{value}} ľudí',
    DIFFICULTY: { EASY: 'Jednoduché', MEDIUM: 'Stredné', HARD: 'Obťažné' },
    COST: { CHEAP: 'Lacné', EXPENSIVE: 'Drahé' },
    TIMING: { MIN: 'Min', HOURS: 'hodiny' },
    STEP: 'Krok',
    DISCOVER_ALSO: 'Objavte tiež…',
    HOME_SEE_MORE: 'Pozrieť ďalšie recepty',
    SHOWCASE: 'Všetky recepty',
    HOME_BOOK: 'Naše recepty',
    MIN_MORE: 'm a +',
    HOUR_MORE: 'h a +',
    FILTER_BY_CRITERIA: 'filter podľa kritérií',
    SORT_BY: 'triediť podľa',
    BEST_RATED: 'najlepšie hodnotené',
    MOST_CONSULTED: 'najčastejšie prezerané',
    MEMBERS_RECIPES: 'Recepty členov',
    CRITERIA: 'Novinky',
    DISCOVER: { RECIPES: 'Objavte naše recepty' },
    DISCOVER_RECIPE: 'Objavte recept',
    INSTRUCTIONS: 'Pokyny',
    DEFAULT_TAG: 'Nápad na recept',
    MOST_RECENT: 'Najnovšie',
    RECIPE_VIDEOS: 'Video recepty',
    PRODUCT_ASSOCIATE: 'Recepty s',
    BUDGET_LOW: 'Lacné',
    BUDGET_HIGH: 'Drahé',
    BUDGET_MIDDLE: 'Stredné',
    PRINT_RECIPE: 'Vytlačiť recept',
    LET_YOURSELF_SURPRISED: 'Nechajte sa prekvapiť',
    COOK_TIP_TITLE: '',
    ENJOY_MEAL: '',
    FILTER: { LEGEND: 'Legenda' }
  },
  PREDIGGO: {
    DIFFICULTY: { EASY: 'Jednoduché', MEDIUM: 'stredné', HARD: 'Obťažné' }
  },
  COOKIES: {
    TEXT: 'Ďalším prezeraním týchto stránok vyjadrujete súhlas s používaním cookies pre technické účely na štatistické účely a správu prevádzky stránok, zdieľanie informácií s poskytovateľmi reklám na iných stránkach, aby sme vás informovali o novinkách, ktoré vás zaujímajú.',
    CLOSE: 'Zatvoriť',
    MORE: 'Zistite viac',
    AGREE_YOUTUBE: 'Ak chcete vidieť toto video, musíte akceptovať YouTube cookies ',
    BUTTON_YOUTUBE: 'Akceptovať cookies'
  },
  POPUP: { LAUNCH: 'POPUP-OUVERTURE-SITE' },
  FIELD_PLATE: { DISCOVER_VEGETABLE: '' },
  SEARCH: {
    OUR_RECIPES: 'Recepty',
    OUR_PRODUCTS: 'Výrobky',
    OUR_ARTICLES: 'Články',
    RECIPES: 'Recepty',
    PRODUCTS: 'Výrobky',
    ARTICLES: 'Články',
    ALL: 'Všetko',
    SORT_BY: 'Radiť podľa',
    RESULTS: 'Výsledky',
    RESULT: 'výsledok',
    PRODUCT_OUT: 'výrobky z',
    APPLY: 'Použiť',
    FILTER: 'Filter',
    RECIPE_OUT: 'recepty z',
    NO_RESULT: '\nPožadovaný výraz sa nepodarilo nájsť...',
    SEE_MORE: 'Prehliadnuť ďalšie',
    DISCOVER_RECIPES: 'Objavte naše recepty',
    SEE_MORE_OF: 'Dalšia',
    DELETE: 'vymazať'
  },
  RECIPE_LIST: {
    OUR_RECIPES: 'Recepty',
    RESULTS: 'Výsledkov',
    SEE_MORE: 'Prezerať ďalšie recepty'
  },
  PRODUCT: {
    BUTTON: {
      ACTU: 'novinky o výrobkoch',
      FOUND: 'Kde tento výrobok nájdete',
      DISCOVER: 'nájdite kompletný sortiment'
    },
    NUTRITIONAL_VALUE: 'nutričná hodnota',
    CONDITIONING: 'Velikosť',
    PORTIONS: 'porcia',
    NET_WEIGHT: 'čistá váha',
    DRY_WEIGHT: 'Hmotnosť pevného podielu',
    INGREDIENTS: 'zloženie',
    OUR_RECIPES: 'Objavte naše recepty',
    FOR: 'pre',
    PER_PORTION: 'Na porcii',
    ENERGY: 'energia',
    FAT: 'tuk',
    SATURATE: 'nasýtené tuky',
    GLUCIDES: 'sacharidy',
    FIBER: 'vláknina',
    PROTEINS: 'bielkoviny',
    SALT: 'soľ',
    DISCOVER_ALSO: 'objavte tiež…',
    MORE_INFORMATION: 'viac informácií',
    TRACES: '',
    CONTAINS: '',
    SLIDER: { SHEET: 'zistite viac\r\n', BUY: 'kúpiť tento produkt' },
    NUTRISCORE: 'informácie o nutriskóre',
    TRIMAN: 'informácie-triman',
    AGRICONFIANCE: 'informácie-agrofiance',
    BIO: 'informácie-bio',
    PESTICIDE_FREE: '',
    LEAF: '',
    LOCK: '',
    SEE_ALL: 'Prezerať všetky produkty',
    LEGISLATION: 'v súlade s platnými právnymi predpismi.',
    PORTION: { SINGULAR: 'porcie', PLURAL: 'porcie' },
    DISCLAIMER: '',
    COOKING: 'Príprava',
    CONSERVATION: 'Skladovanie'
  },
  FAQ: {
    ON: 'dotazy na',
    ALL: 'Všetko',
    SEE_MORE: 'prezerať ďalšie dotazy',
    TITLE_1: 'translate line 319',
    TITLE_2: 'translate line 320',
    LINK_LABEL: 'translate line 321'
  },
  FORM: {
    GO_TO_STEP: 'Prejsť na',
    STEP: 'Krok',
    RECEIVE_EMAIL_COPY: 'Dostať kópiu e-mailu',
    SEND: 'Odoslať',
    GDPR: 'Viac informácií o spracovaní Vašich údajov',
    ERROR: 'Všetky polia označené * sú povinné a informácie musia byť aktuálne',
    SEND_ERROR: 'Došlo k chybe, vyskúšajte to, prosím, neskôr',
    NOTICE: 'Aby sme mohli riadne spracovať Váš požadavok, spoločnost Bonduelle potrebuje niektoré vaše osobné údaje. Nejde o povinné údaje a ich poskytnutie môžete odmietnuť.',
    SENDING: 'Odosielam formulár…',
    LOADING: 'Nahrávam…',
    PRODUCT_INFORMATION_TITLE: 'Informácie o výrobku',
    PRODUCT_INFORMATION: {
      SEO_TITLE: 'Informácie o výrobku',
      SEO_DESC: 'Kontaktujte naše zákaznícke oddelenie\r'
    },
    INFORMATIONS: 'osobné informácie\n',
    MISTER: 'Pán',
    MISS: 'Pani',
    NAME: 'Priezvisko\r\n',
    OUR_NAME: 'Moje priezvisko\r\n',
    FIRSTNAME: 'Meno',
    OUR_FIRSTNAME: 'Moje meno\r\n',
    MAIL: 'váš email\r',
    MAIL_PLH: 'email@adresa.com',
    MAIL_CONFIRM: 'potvrďte váš email\r',
    MAIL_ERROR: 'prosím uveďte platný email',
    MAIL_ERROR_CONFIRM: 'prosím uveďte platný email',
    ADDRESS: 'vaša adresa',
    STREET_PLH: '',
    STREET: 'Ulica a č.p.',
    ADDRESS_COMPLEMENT: 'viac informácií o vašej adrese',
    ADDRESS_COMP_PLH: 'viac informácií o vašej adrese\r',
    ZIPCODE: 'PSČ',
    CITY: 'Mesto',
    CITY_PLH: 'Vaše mesto',
    PHONE: 'telefón',
    PHONE_PLH: 'telefónne číslo\r',
    COUNTRY: 'Krajina',
    PRODUCT_INFORMATIONS: 'informácie o produktoch\r',
    PRODUCT_INFORMATION_DESC: 'Uveďte, prosím nasledujúce informácie, aby naši zamestnanci mohli vašu žiadosť spracovať:\r',
    PRODUCT: {
      DETAIL: {
        DESC: 'Vyplňte príslušné polia a pripojte fotografiu. Fotografie musia obsahovať čiarový kód produktu, číslo šarže a dátum expirácie.\r'
      },
      NOTE: {
        TITLE: 'Skúsenosť s výrobkom',
        SEO_TITLE: 'Komentár k produktu Bonduelle\r',
        SEO_DESC: 'Máte nejaké otázky k produktu z našej ponuky? Obráťte sa na náš zákaznícky servis.'
      }
    },
    PRODUCT_RANGE: 'Technológia produktu\r\n',
    PRODUCT_NAME: 'Názov produktu\r',
    PRODUCT_NAME_PLH: 'Zadajte názov produktu Bonduelle, na ktorý sa vzťahuje vaša otázka\r',
    PRODUCT_INFORMATIONS_DETAIL: 'Detailné informácie',
    PRODUCT_INFORMATIONS_LABEL: 'Čiarový kód produktu',
    PRODUCT_INFORMATIONS_PLH: 'Kód EAN, ktorý sa skladá z 13 číslic na obale produktu.\r',
    PRODUCT_LOT: 'výrobné číslo',
    PRODUCT_LOT_INFO: 'Kód začínajúci písmenom L, nasledovaný radom číslic.\n',
    PHOTO: 'Pripojte fotografiu\r',
    EXPIRED_DATE: 'Dátum expirácie\r\n',
    EXPIRED_DATE_ERROR: 'Zadajte dátum nasledujúcim spôsobom: DD / MM / RRRR\r',
    EXPIRED_DATE_DESC: 'Doby expirácie nájdete na spodnej strane balenia.\r',
    PHOTO_MORE: 'Nahrajte fotografie\r',
    MESSAGE: 'Vaša správa *',
    OUR_MESSAGE: 'napíš svoju správu',
    SALES_OPERATIONS: {
      TITLE: 'Informácie obchodného charakteru\r',
      SEO_TITLE: 'Informácie o aktuálnych akciách: hry, súťaže ...\r',
      SEO_DESC: 'Informácie obchodného charakteru získate od nášho zákazníckeho oddelenia.\r'
    },
    PRESS_SERVICE: 'Kontaktujte tlačové oddelenie',
    FOOD_SERVICE: 'Spojte sa s tímom Bonduelle Food Service',
    BUSINESS_INFORMATION: '',
    MODAL_INFORMATION: '',
    JOB_OFFER: {
      TITLE: 'Informacie o voľných pracovných pozíciách\n',
      SEO_TITLE: '',
      SEO_DESC: ''
    },
    WEB_SUGGESTION: {
      TITLE: 'Chceli by ste navrhnúť niektoré vylepšenia nášho webu?\r',
      SEO_TITLE: 'Správa ohľadom vylepšenia webe Bonduelle\r',
      SEO_DESC: 'Spojte sa s naším zákazníckym servisom a pomôžte nám vylepšiť web.\r'
    },
    ANOTHER: {
      TITLE: '\r\nIné žiadosti',
      SEO_TITLE: 'Kontakt pre akýkoľvek ďalšiu požiadavku, servis\r',
      SEO_DESC: 'Informácie vám poskytne naše oddelenie služieb zákazníkom.'
    },
    AMBASSADOR: '',
    CATEGORY: {
      SALAD: 'Sterilizovaná zelenina',
      FROZEN: 'Mrazená zelenina',
      CAN: 'Légumio',
      TAKEAWAY: '',
      VEGETABLE: ''
    }
  },
  CONTACT: {
    TITLE: 'K vašim službám',
    SPECIAL_INFO: 'Náš zákaznícky servis je vám k dispozícii.\r\n' +
      'Vaše správy kontrolujeme každý deň.',
    TAKE_CARE: '',
    BY_MAIL: 'Na adrese',
    BY_PHONE: 'Telefonicky',
    BY_SOURDLINE: '',
    MAIL_TEXT: 'BONDUELLE spol. s r. o. Hybernská 1009 / 24 Praha 110 00 ',
    PHONE_TEXT: 'Tel.: +420 778 407 408 \n',
    SOURDLINE_TEXT: '',
    BY_DISTRIBUTOR: '',
    BY_DISTRIBUTOR_TEXT: '',
    SUBTITLE: 'Chcete nás kontaktovať ohľadom:',
    AGREEMENT: '',
    READ_MORE: "Okrem toho máte právo kedykoľvek požiadať BONDUELLE o uplatnenie vašich práv na prístup, opravu, vymazanie a prenosnosť vašich osobných údajov, ako aj vaše práva na obmedzenie a namietanie proti spracovaniu vašich osobných údajov. Ak chcete uplatniť svoje práva súvisiace s vašimi osobnými údajmi, napíšte Dpo_france(at)bonduelle(bodka)com alebo poštou na nasledujúcu adresu: do pozornosti úradníkovi pre ochranu údajov, Bonduelle, Rue Nicolas Appert, PO Box 30173, F- 59653 Villeneuve D'Ascq. Ak sa chcete dozvedieť viac o spôsobe, akým BONDUELLE zhromažďuje a používa vaše osobné údaje, kliknite sem",
    READ_MORE_LABEL: 'Čítajte tiež',
    SATISFACTION_TEXT: 'Aby sme vás mohli kontaktovať, aby sme vám poskytli informácie\n' +
      'požadované o produkte, a ak si to želáte, aby sme vám zaslali dotazník\n' +
      'spokojnosti, spracujeme poskytnuté informácie ako správca údajov.\n' +
      'Právnym základom spracovania vašej žiadosti je súhlas vzhľadom na\n' +
      'potenciálne zdravotné údaje, ktoré môžete poskytnúť vo svojej žiadosti.\n' +
      'Tieto informácie budú uchovávané tak dlho, ako to bude potrebné na\n' +
      'vybavenie vašej žiadosti, a až po dobu 5 rokov, aby sme poskytli dôkaz o\n' +
      'tom, že vaša žiadosť bola spracovaná a aby sme splnili naše zákonné\n' +
      'povinnosti. Právnym základom zaslania dotazníka spokojnosti je aj Váš\n' +
      'súhlas. Tieto informácie budú uchovávané po dobu potrebnú na odoslanie\n' +
      'dotazníka spokojnosti a maximálne 1 rok. Ak sa chcete dozvedieť viac o\n' +
      'spracovaní vašich osobných údajov, prečítajte si naše <a href="https://www.bonduelle.sk/zasady-ochrany-osobnych-udajov" target="_blank" class="underline">Zásady ochrany\n' +
      'osobných údajov</a>.',
    SATISFACTION_CHECKBOX: 'Zaškrtnutím tohto políčka súhlasíte so zaslaním dotazníka spokojnosti\n' +
      '(pozostávajúci z jedinej otázky) po spracovaní vašej žiadosti. To je voliteľné.',
    CHECKBOX_TEXT: 'Zaškrtnutím tohto políčka súhlasíte s tým, že spoločnosť Bonduelle môže\n' +
      'spracovať osobné údaje o zdravotnom stave, ktoré uvediete vo svojej\n' +
      'správe, výlučne za účelom odpovede na vašu žiadosť. <strong>Je to nevyhnutné na\n' +
      'spracovanie vašej žiadosti</strong>.',
    PHONE: 'Pondelok až piatok od 9:00 do 18:00 na +420 222 811 011 (hovor zdarma, okrem víkendov a štátnych sviatkov)',
    CALL_US: 'Zavolajte nám',
    TO_WRITE: 'Napíšte nám',
    PHONE_NUMBER: '',
    SUCCESS_MESSAGE: 'Ďakujeme! Vaša správa bola odoslaná nášmu tímu. Budeme ju spracovávať štandardným spôsobom.',
    PHOTOERROR: { MESSAGE: '' }
  },
  GLOBAL: { READ_MORE: '' },
  FLOWBOX: { GENERIC_KEY: '', PRODUCT_KEY: '', RECIPE_KEY: '', HOME_KEY: '' },
  TOKEN: {
    FACEBOOK: '',
    PAGE_FACEBOOK_ID: '',
    INSTAGRAM: '',
    INSTA_ID: '17841404637610582',
    YOUTUBE: '',
    TWITTER: '',
    MAGAZINE: ''
  },
  COOKIE: {
    BANNER: {
      MANDATORY: 'Povinné',
      ANALYTICS: 'Analýza',
      MARKETING: 'Marketing',
      SOCIAL_MEDIA: 'Sociálne siete',
      ACCEPT: 'Prijať všetko',
      REJECT: 'Odmienuť všetko',
      SET_UP: 'Nastavenia',
      MANDATORY_DETAILS: 'Požadované cookies sú nevyhnutné pre prevádzku stránok, napríklad pre uloženie vašich prihlasovacích údajov a pre zaistenie bezpečného pripojenia.\n' +
        '\n' +
        'Súbory cookie potrebné k používaniu webu bonduelle.sk sú:\n' +
        '\n' +
        '<Ul>\n' +
        '\n' +
        '<Li> Relačné cookies: Cookies generované aplikáciami založenými na PHP. Toto je univerzálny identifikátor používaný na správu premenných relácií užívateľov. Zvyčajne sa jedná o náhodne generované číslo. Spôsob jeho použitia môže byť špecifický pre daný web, ale dobrým príkladom je udržanie stavu prihlásenia pre užívateľov medzi stránkami. </ Li>\n' +
        '</ Ul>',
      ANALYTICS_DETAILS: 'Požadované cookies sú nevyhnutné pre prevádzku stránok, napríklad pre uloženie vašich prihlasovacích údajov a pre zaistenie bezpečného pripojenia.\n' +
        '\n' +
        'Súbory cookie potrebné k používaniu webu bonduelle.sk sú:\n' +
        '\n' +
        '<Ul>\n' +
        '\n' +
        '<Li> relačné cookies: Cookies generované aplikáciami založenými na PHP. Toto je univerzálny identifikátor používaný na správu premenných relácií užívateľov. Zvyčajne sa jedná o náhodne generované číslo. Spôsob jeho použitia môže byť špecifický pre daný web, ale dobrým príkladom je udržanie stavu prihlásenia pre užívateľov medzi stránkami. </ Li>\n' +
        '<Li> Google Analytics: tieto súbory cookie umožňujú sledovať najnavštevovanejšie stránky a prevádzku na webe. </ Li>\n' +
        '<li> UTMTracking: tento súbor cookie umožňuje správu premenných UTM pre optimálne sledovanie rôznych online reklamných kampaní. </ li> </ Ul>',
      MARKETING_DETAILS: 'Marketingové cookies nám umožňujú sledovať vaše preferencie receptov a produktov, aby sme vám vždy mohli ponúknuť produkty, ktoré najlepšie zodpovedajú vašim prianiam.\n' +
        '\n' +
        '<br>\n' +
        '\n' +
        'Marketingové cookies používané stránkou bonduelle.sk sú:\n' +
        '\n' +
        '<Ul>\n' +
        '\n' +
        '<Li> Sociálne siete: tieto súbory cookie umožňujú zobrazenie Bonduelle "SocialWall" na domovskej stránke lokality. Social Media Wall umožňuje zobraziť všetky najnovšie príspevky z hlavných sociálnych sietí používaných tímami Bonduelle. </li>   </Ul>',
      V2: 'ok'
    },
    MODAL: {
      TITLE: 'Správa cookies ',
      TEXT: 'Skupina Bonduelle sa zaväzuje na zabezpečenie ochrany osobných údajov užívateľov svojich webových stránok, najmä webových stránok Bonduelle.sk. <br> Aby sme vám umožnili využívanie všetkých služieb ponúkaných webom, ako je ponuka produktov, objavovanie nových labužníckych receptov, tipy a rady z našich tematických článkov a tiež optimalizovanie pri využívaní webu s prispôsobením podľa vášho profilu, používame preto cookies. Skupina Bonduelle je plne odhodlaná zabezpečiť vysokú úroveň ochrany týchto osobných údajov a dodržiavať všetky príslušné pravidlá ochrany údajov pri spracovaní osobných údajov v súvislosti s používaním svojich webových stránok.\n' +
        '\n' +
        'Súbory cookie môžete kedykoľvek aktivovať / deaktivovať',
      CONFIG: '',
      SAVE: 'Uložiť'
    }
  },
  LINK: { JOB_OFFERS: '' },
  CLIC2BUY: { INFOS: '' }
};
