import {
  Component,
  Input
} from '@angular/core';
import { SfMediaVideo } from '../models/media-video.interface';

@Component({
    selector: 'sf-video',
    templateUrl: './video.component.html',
    styleUrls: ['./video.component.sass'],
    standalone: false
})
export class SfVideoComponent {

  @Input() video: SfMediaVideo;

  @Input() content: 'slide' | 'original' = 'original';

  constructor() { }

}
