import { Pipe, PipeTransform } from '@angular/core';
import { SfLocalizeService } from '../../../core/localize/services/localize.service';
import { SfLink, SfSimpleLink } from '../../../features/link';

@Pipe({
    name: 'sfSearchLink',
    standalone: false
})
export class SfSearchLinkPipe implements PipeTransform {

  public link: SfLink;

  constructor(
    private localize: SfLocalizeService
  ) {
    this.link = new SfSimpleLink();
    this.link.is_external = false;
  }

  transform(v: string): SfLink {
    v = v.trim().toLowerCase();
    this.link.label = v;
    this.link.url = this.localize.instant(v);
    return this.link;
  }
}
