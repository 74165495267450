<div *ngIf="section">
  <a #link [href]="linkUrl" [target]="isExternal ? '_blank' : '_self'">

  <div *ngIf="section.image && section.image.uri" class="__img">
    <img [src]="options.apiUrl + section.image.uri.original" alt="" loading="lazy"/>
  </div>

  <!-- Content -->
  <div *ngIf="section.text || section.title" class="__content">

    <!-- Title -->
    <div class="__title">{{section.title}}</div>

    <!-- Text -->
    <div *ngIf="section.text" [innerHTML]="section.text | safePipe" class="__text"></div>

  </div>

  </a>
</div>
