/* tslint:disable */export const cz_cs = {
  '404': {
    TITLE: 'Jejda…',
    PAGENOTFOUND: 'Stránka neexistuje',
    BUTTON: 'Zpět domů',
    RECIPES: 'Objevte naše recepty'
  },
  HOME: {
    SHOWCASE: 'Recepty',
    CATCH_PHRASE: '',
    SHOWCASE_SEE_ALL: 'Zobrazit všechny recepty',
    MAGAZINE: { TITLE: 'Nahoru', BUTTON: 'Věděli jste, že..' },
    OUR_VEGETABLES: 'Produkty',
    SOCIAL_WALL: 'Sdílejme společně vášeň pro rostliny',
    FAQ_TITLE: 'Your questions',
    FAQ_SEE_MORE: 'See more',
    FLOWBOX: ''
  },
  SELECT: { DEFAULT_VALUE: 'Aktuálních' },
  MENU: {
    CONTACT_US: 'Kontakty',
    MY_ACCOUNT: 'Můj účet',
    GOOD_DEALS: 'Speciální nabídky',
    NEWSLETTER: 'Bonduelle Newsletter'
  },
  FOOTER: {
    LINK: {
      BONDUELLE: 'Bonduelle.cz',
      CREDITS: 'Credits',
      LEGAL_NOTICE: 'Právní ujednání',
      TERMS_OF_SALES: 'Podmínky obchodu',
      COOKIES: 'Cookies',
      SITEMAP: 'Mapa stránky',
      PRIVACY_POLICY: 'Zásady ochrany osobních údajů',
      ALL_RIGHTS_RESERVED: '2020 © Všechna práva vyhrazena'
    },
    HISTORY: {
      TITLE: 'Bonduelle, již od roku 1853',
      TEXT: 'Bonduelle je rodinná společnost, která pracuje již sedm generací v oblasti zemědělské produkce, která dbá o šetrný přístup k půdě a jejím pracovníkům. Bonduelle dbá o efektivní, inteligentní a podpůrnou agroekologii, která myslí na budoucnost. Heslo společnosti – Příroda je naše budoucnost'
    },
    NEWSLETTER: {
      TITLE: 'Komunita Bonduelle',
      TEXT: 'Souhlasím se zasíláním newsletteru «Vše o zelenině od Bonduelle» (novinky, propagace, soutěže, články ...)',
      PLACEHOLDER: 'Váš email',
      GDPR_LINK: 'Další informace o správě dat'
    }
  },
  PRODUCTS: 'Produkty',
  MAGAZINE: {
    HOME: 'Vítejte v časopisu',
    CATCH_PHRASE: '',
    MENU: { BONDUELLE_LINK: 'Jdi na bonduelle.cz' },
    SHOWCASE: 'Náhled',
    PREVIOUS_ARTICLES: 'Předchozí článek',
    PREVIOUS_ARTICLES_SEE_ALL: 'Podívejte se na všechny naše články',
    PREVIOUS_EDITIONS: 'Naše předchozí vydání',
    PREVIOUS_EDITIONS_SEE_ALL: 'Zobrazit další vydání',
    PREVIOUS_ARTICLES_BY_CATEGORY: 'Předchozí články podle kategorie',
    RUBRIC: {
      SEE_ALL: 'Podívejte se na všechny recepty',
      SEE_ALL_ARTICLES: 'Podívejte se na všechny články',
      ON: 'články na'
    },
    RUBRIC_COOK: { ON: 'recepty na' },
    ARTICLE: { JOIN_US: 'Připoj se k nám', READ_ALSO: 'Přečti si také' },
    RECIPE: {
      INGREDIENTS: 'Ingredience',
      INGREDIENTS_LABEL_DESC: 'pro {{value}} osoby',
      INGREDIENTS_LABEL_ONE_DESC: 'pro {{value}} osobu',
      INGREDIENTS_LABEL: 'ingredience pro {{value}} osob',
      INGREDIENTS_LABEL_ONE: 'ingredience pro {{value}} osobu',
      PEOPLES: '{{value}} osob',
      PEOPLE: '{{value}} člověka',
      DIFFICULTY: { EASY: 'Snadné', MEDIUM: 'Střední', HARD: 'Obtížné' },
      COST: { CHEAP: 'Levné', EXPENSIVE: 'Drahé' },
      TIMING: { MIN: 'min', HOURS: 'hodin' },
      STEP: 'krok',
      DISCOVER_ALSO: 'Objevte také…'
    }
  },
  ARTICLE: { DISCOVER_ALSO: 'Objevte také…', INGREDIENTS: 'Ingredience' },
  SF: {
    SLIDE: { DISCOVER: 'Objevte' },
    SEARCH: { PLACEHOLDER: 'Hledat', ALL: 'Vše' },
    NEWSLETTER: {
      BTN_LABEL: 'Registruji se',
      PLACEHOLDER: 'Váš e-mail',
      ERROR_MESSAGE: 'Neplatný e-mail',
      ALREADY_SUBSCRIBED: 'Už máte předplatné',
      GDPR: {
        TXT: 'Více informací o zpracování Vašich údajů',
        LINK: '/zasady-ochrany-osobnich-udaju'
      }
    },
    MONTHLY_FOLDER: { TITLE: 'Měsíční novinky' },
    MINI_NEWSLETTER: {
      PLACEHOLDER: 'Váš e-mail',
      GDPR: {
        TXT: 'Více informací o zpracování Vašich údajů',
        LINK: '/zasady-ochrany-osobnich-udaju'
      }
    },
    SOCIALITEM: { SHARE: 'Zobrazit', COMMENT: 'komentář', BONDUELLE: 'Bonduelle' },
    SEARCHRECIPE: { DISCOVER_ALL: 'hledejte ve všech receptech!' },
    FORM: {
      ERROR: 'Neplatné pole',
      PHOTO: {
        ADD: 'Přidat obrázek',
        DELETE: 'Smazat obrázek',
        SEE: 'Prohlížet'
      }
    }
  },
  CORPORATE: {
    COMPANY_STORY: 'Naše historie',
    CSR_COMMITMENT: 'závazky csr',
    VEGETABLE_INNOVATIONS: '',
    NEWS: 'všechny novinky',
    SEE_MORE: 'Prohlédnout více článků',
    ON: 'článků z'
  },
  NEWSLETTER: {
    TITLE: 'Přihlašte se k odběru newsletteru',
    SUBTITLE: 'Dobré obchody, kuchařská inspirace a trocha kultury!'
  },
  ROUTES: {
    products: 'produkty',
    news: 'novinky',
    'since-1853': 'od-roku-1853',
    'field-plate': 'z-pole-na-talir',
    'company-story': 'historie-spolecnosti',
    'csr-commitments': 'zavazky-csr',
    'vegetal-innovations': '',
    magazine: 'magazin',
    all: 'vse',
    article: 'clanek',
    cook: 'varit',
    cultivate: 'kultivovat',
    explore: 'objevovat',
    recipe: 'cekat-recept',
    recipes: 'recepty',
    'recipes-home': 'recepty-domu',
    r: 'r',
    credits: 'kredity',
    'legal-notice': 'pravni-ujednani',
    'terms-of-sales': 'podminky-obchodu',
    cookies: 'cookies',
    sitemap: 'mapa-stranky',
    'privacy-policy': 'zasady-ochrany-osobnich-udaju',
    'all-rights-reserved': 'vsechna-prava-vyhrazena',
    search: 'hledat',
    'articles-search': 'clanek',
    vegetables: 'zelenina',
    p: 'p',
    contact: 'kontakt',
    'info-product': 'informace-o-produktech',
    'note-product': 'poznamky-k-produktum',
    suggestion: 'navrh',
    'other-request': 'jiny-pozadavek',
    faq: 'casto-kladene-otazky',
    'all-themes': 'vsechna-temata',
    'products-search': 'hledani-produktu',
    recipesearch: 'hledani-receptu',
    'product-news': 'produktove-novinky',
    'good-deals': 'akce',
    'not-found': 'nenalezeno',
    'menu-page': 'zajimavosti.',
    'menu-one': 'menu-jedna',
    'menu-two': 'menu-dva',
    'menu-three': 'menu-tri',
    'menu-four': 'menu-styri',
    'menu-external': '',
    'theme-pasta-rice': 'testoviny-a-ryze',
    'theme-bowls': 'misy',
    'theme-soup': 'polevka',
    'theme-snack': 'presnidavka',
    'theme-sauce': 'omacka',
    'theme-unique-plate': 'jedinecny-talir',
    'theme-over': 'dalsi-jidla',
    'theme-legumin': 'lusteniny',
    'theme-ingredients-recipes': 'ingredience - recepty',
    'theme-cook-plate': ''
  },
  BREAD: {
    products: 'produkty',
    news: 'novinky',
    'since-1853': 'od-roku-1853',
    'field-plate': 'z-pole-na-talir',
    'company-story': 'historie-spolecnosti',
    'csr-commitments': 'zavazky csr',
    'vegetal-innovations': '',
    magazine: 'magazin',
    all: 'vše',
    article: 'clanek',
    cook: 'varit',
    cultivate: 'kultivovat',
    explore: 'objevovat',
    recipe: 'cekat-recept',
    recipes: 'recepty',
    'recipes-home': 'recepty-domu',
    'articles-search': 'clanek',
    search: 'hledat',
    vegetables: 'zelenina',
    contact: 'kontakt',
    'info-product': 'informace-o-produktech',
    'note-product': 'poznamky-k-produktum',
    suggestion: 'navrh',
    'other-request': 'jiny-pozadavek',
    faq: 'casto-kladene-otazky',
    'all-themes': 'vsechna-temata',
    'products-search': 'hledani-produktu',
    recipesearch: 'hledani-receptu',
    'product-news': 'produktove-novinky',
    'good-deals': 'akce',
    'not-found': 'nenalezeno',
    'menu-page': 'zajímavosti',
    'menu-one': 'menu-jedna',
    'menu-two': 'menu-dva',
    'menu-three': 'menu-tri',
    'menu-four': 'menu-styri'
  },
  MENU_PAGE: { TITLE1: '', TITLE2: '', TITLE3: '', TITLE4: '', EXTERNAL: '' },
  TECHNICAL: { MENU_PAGE: 'vegetarian_passions' },
  SEO: {
    TITLE: {
      MENU_ONE: '',
      MENU_TWO: '',
      MENU_THREE: 'menu three Title',
      MENU_FOUR: 'menu four Title',
      HOME: 'Bonduelle',
      SEARCH: {
        MAIN: '{{value}}',
        RECIPES: '{{value}}',
        VEGETABLES: '{{value}}',
        ARTICLES: '{{value}}'
      },
      CONTACT: 'Bonduelle',
      RECIPES: 'Bonduelle',
      RECIPE_HOME: 'Bonduelle',
      RECIPE: 'Recept {{value}} | Bonduelle',
      PRODUCTS: 'Bonduelle',
      NEWS: 'Bonduelle',
      CORPORATE: {
        NEWS: 'Bonduelle',
        COMPANY_STORY: 'Bonduelle',
        CSR_COMMITMENTS: 'Bonduelle',
        VEGETAL_INNOVATIONS: 'Bonduelle',
        FIELD_PLATE: 'Bonduelle',
        ARTICLE: '{{value}} | Bonduelle',
        VEGETABLE: '{{value}}'
      },
      PRODUCT: '{{value}}',
      MAGAZINE: {
        NEWS: 'Bonduelle',
        ARTICLE: 'Bonduelle',
        COOK: 'Bonduelle',
        GROW: 'Bonduelle',
        EXPLORE: 'Bonduelle',
        RECIPE: 'Bonduelle'
      },
      FAQ: 'Bonduelle',
      NOTFOUND: 'Bonduelle',
      RECIPES_ALL: 'Všechny recepty',
      RECIPETYPE: '{{value}}',
      RECIPESEASON: '{{value}}',
      RECIPEPREPARATION: 'Vaření recepty: doba přípravy {{value}}',
      RECIPEDIFFICULTY: '{{value}}',
      RECIPEVEGETABLE: '{{value}}',
      RECIPECOST: '{{value}}',
      RECIPEDISH: '{{value}}',
      RECIPEDESC: '{{value}}',
      '2RECIPEFACETS': '',
      '4RECIPEFACETS': '',
      PRODUCTSEASON: '',
      PRODUCTTECHNO: '',
      PRODUCTVEGETABLE: '',
      PRODUCTRANGE: '',
      THEME_ALL: 'Vše',
      THEME_STARTER: '',
      THEME_SALAD: '',
      THEME_CAN: '',
      THEME_FROZEN: '',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: '',
      THEME_RECIPE: '',
      THEME_PLATE: '',
      THEME_ACC: '',
      THEME_SNACK: '',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': ''
    },
    DESCRIPTION: {
      MENU_ONE: '',
      MENU_TWO: '',
      MENU_THREE: 'menu three Description',
      MENU_FOUR: 'menu four Description',
      HOME: '{{value}}',
      SEARCH: {
        MAIN: '{{value}}',
        RECIPES: '{{value}}',
        VEGETABLES: '',
        ARTICLES: ''
      },
      CONTACT: '',
      RECIPES: '',
      RECIPE_HOME: '',
      RECIPE: '',
      PRODUCTS: '',
      NEWS: '',
      CORPORATE: {
        NEWS: '',
        COMPANY_STORY: '',
        CSR_COMMITMENTS: '',
        VEGETAL_INNOVATIONS: '',
        VEGETABLE: '',
        FIELD_PLATE: '',
        ARTICLE: ''
      },
      PRODUCT: '{{value}}',
      MAGAZINE: {
        NEWS: '',
        ARTICLE: '',
        COOK: '',
        GROW: '',
        EXPLORE: '',
        RECIPE: ''
      },
      FAQ: '',
      PAGE: '',
      RECIPETYPE: '{{value}}',
      RECIPESEASON: '{{value}}',
      RECIPEPREPARATION: '{{value}}',
      RECIPEDIFFICULTY: '{{value}}',
      RECIPEVEGETABLE: '{{value}}',
      RECIPECOST: '{{value}}',
      RECIPEDISH: '{{value}}',
      RECIPEDESC: '{{value}}',
      '2RECIPEFACETS': '',
      '4RECIPEFACETS': '',
      PRODUCTSEASON: '',
      PRODUCTTECHNO: '',
      PRODUCTVEGETABLE: '',
      PRODUCTRANGE: '',
      THEME_ALL: '',
      THEME_STARTER: '',
      THEME_SALAD: '',
      THEME_CAN: '',
      THEME_FROZEN: '',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: '',
      THEME_RECIPE: '',
      THEME_PLATE: '',
      THEME_ACC: '',
      THEME_SNACK: '',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': '',
      NOTFOUND: ''
    },
    PIPE: { SOCIETY: ' | Bonduelle' },
    THEME_ALL: 'Vše',
    THEME_STARTER: '',
    THEME_SALAD: '',
    THEME_CAN: '',
    THEME_FROZEN: '',
    THEME_READY_MEALS: '',
    THEME_BIO: '',
    THEME_VEGETABLES: '',
    THEME_RECIPE: '',
    THEME_PLATE: '',
    THEME_ACC: '',
    THEME_SNACK: ''
  },
  VEGETABLE: {
    DISCOVER_VEGETABLE: '',
    OURS_RECIPES: 'Naše recepty',
    DISCOVER_ALSO: 'Objevte také…',
    SEE_ALL: 'Prohlédněte si všechnu zeleninu',
    ON: 'zelenina na'
  },
  RECIPE: {
    'INGREDIENTS_LABEL ': 'Ingredience pro {{value}} lidí',
    PEOPLES: '{{value}} lidí',
    DIFFICULTY: { EASY: 'Snadné', MEDIUM: 'Střední', HARD: 'Obtížné' },
    COST: { CHEAP: 'Levné', EXPENSIVE: 'Drahé' },
    TIMING: { MIN: 'Min', HOURS: 'hodiny' },
    STEP: 'Krok',
    DISCOVER_ALSO: 'Objevte také…',
    HOME_SEE_MORE: 'Prohlédnout další recepty',
    SHOWCASE: 'Nahoru',
    HOME_BOOK: 'Naše recepty',
    MIN_MORE: 'm a +',
    HOUR_MORE: 'h a +',
    FILTER_BY_CRITERIA: 'filtr podle kritérií',
    SORT_BY: 'třídit podle',
    BEST_RATED: 'nejlépe hodnocené',
    MOST_CONSULTED: 'nejčastěji prohlížené',
    MEMBERS_RECIPES: 'Recepty členů',
    CRITERIA: 'Novinky',
    DISCOVER: { RECIPES: 'Objevujte naše recepty' },
    DISCOVER_RECIPE: 'Objevte recept',
    INSTRUCTIONS: 'Pokyny',
    DEFAULT_TAG: 'Nápad na recept',
    MOST_RECENT: 'Nejnovější',
    RECIPE_VIDEOS: 'Video recepty',
    PRODUCT_ASSOCIATE: 'Recepty s',
    BUDGET_LOW: 'Levné',
    BUDGET_HIGH: 'Drahé',
    BUDGET_MIDDLE: 'Střední',
    PRINT_RECIPE: 'Vytisknout recept',
    LET_YOURSELF_SURPRISED: 'Nechte se překvapit',
    COOK_TIP_TITLE: '',
    ENJOY_MEAL: '',
    FILTER: { LEGEND: 'Legenda' }
  },
  PREDIGGO: {
    DIFFICULTY: { EASY: 'Jednoduché', MEDIUM: 'střední', HARD: 'Náročné' }
  },
  COOKIES: {
    TEXT: 'Dalším prohlížením těchto stránek vyjadřujete souhlas s používáním cookies pro technické účely, za účelem statistické analýzy a řízení provozu stránek, sdílení informací s poskytovateli reklam na jiných stránkách, abychom vám poskytli relevantní reklamní sdělení, které odpovídají vašemu zájmu.',
    CLOSE: 'Zavřít',
    MORE: 'Zjistěte více',
    AGREE_YOUTUBE: 'Pokud chcete vidět toto video, musíte akceptovat YouTube cookies',
    BUTTON_YOUTUBE: 'Akceptovat cookies'
  },
  POPUP: { LAUNCH: 'POPUP-OUVERTURE-SITE' },
  FIELD_PLATE: { DISCOVER_VEGETABLE: '' },
  SEARCH: {
    OUR_RECIPES: 'Recepty',
    OUR_PRODUCTS: 'Produkty',
    OUR_ARTICLES: 'Články',
    RECIPES: 'Recepty',
    PRODUCTS: 'Produkty',
    ARTICLES: 'Články',
    ALL: 'Vše',
    SORT_BY: 'Řadit podle',
    RESULTS: 'Výsledky',
    RESULT: 'výsledek',
    PRODUCT_OUT: 'produkty z',
    APPLY: 'Použít',
    FILTER: 'Filtr',
    RECIPE_OUT: 'recepty z',
    NO_RESULT: 'Požadovaný výraz se nepodařilo nalézt..',
    SEE_MORE: 'Prohlédnout další',
    DISCOVER_RECIPES: 'Objevte naše recepty',
    SEE_MORE_OF: 'Další',
    DELETE: 'smazat'
  },
  RECIPE_LIST: {
    OUR_RECIPES: 'Recepty',
    RESULTS: 'Výsledků',
    SEE_MORE: 'Prohlížet další recepty'
  },
  PRODUCT: {
    BUTTON: {
      ACTU: 'novinky o produktech',
      FOUND: 'Kde tento produkt naleznete',
      DISCOVER: 'objevte kompletní sortiment'
    },
    NUTRITIONAL_VALUE: 'nutriční hodnota',
    CONDITIONING: 'Velikost',
    PORTIONS: 'porce',
    NET_WEIGHT: 'čistá váha',
    DRY_WEIGHT: 'Hmotnost pevného podílu',
    INGREDIENTS: 'složení',
    OUR_RECIPES: 'Objevte naše recepty',
    FOR: 'pro',
    PER_PORTION: 'Na porci',
    ENERGY: 'energie',
    FAT: 'tuk',
    SATURATE: 'nasycené tuky',
    GLUCIDES: 'sacharidy',
    FIBER: 'vláknina',
    PROTEINS: 'bílkoviny',
    SALT: 'sůl',
    DISCOVER_ALSO: 'objevte také…',
    MORE_INFORMATION: 'více informací',
    TRACES: '',
    CONTAINS: '',
    SLIDER: { SHEET: 'zjistěte více', BUY: 'koupit tento produkt' },
    NUTRISCORE: 'informace o nutriskóre',
    TRIMAN: 'informace-triman',
    AGRICONFIANCE: 'informace-agrofiance',
    BIO: 'informace-bio',
    PESTICIDE_FREE: '',
    LEAF: '',
    LOCK: '',
    SEE_ALL: 'Prohlížet všechny produkty',
    LEGISLATION: 'v souladu s platnými právními předpisy.\r',
    PORTION: { SINGULAR: 'porce', PLURAL: 'porce' },
    DISCLAIMER: '',
    COOKING: 'Příprava',
    CONSERVATION: 'Skladování'
  },
  FAQ: {
    ON: 'dotazy na',
    ALL: 'Vše',
    SEE_MORE: 'prohlížet další dotazy',
    TITLE_1: 'translate line 319',
    TITLE_2: 'translate line 320',
    LINK_LABEL: 'translate line 321'
  },
  FORM: {
    GO_TO_STEP: 'Přejít na',
    STEP: 'Krok',
    RECEIVE_EMAIL_COPY: 'Dostat kopii e-mailu',
    SEND: 'Odeslat',
    GDPR: 'Více informací o zpracování Vašich údajů',
    ERROR: 'Všechna pole označená * jsou povinná a informace musí být platné',
    SEND_ERROR: 'Došlo k chybě, zkuste to prosím později',
    NOTICE: 'Abychom mohli řádně zpracovat Váš požadavek, potřebuje společnost Bonduelle některé vaše osobní údaje. Nejedná se o povinné údaje a můžete jejich poskytnutí odmítnout.',
    SENDING: 'Odesílám formulář…',
    LOADING: 'Nahrávám…',
    PRODUCT_INFORMATION_TITLE: 'Informace o výrobku',
    PRODUCT_INFORMATION: {
      SEO_TITLE: 'Informace o výrobku ',
      SEO_DESC: 'Kontaktujte náše zákaznické oddělení'
    },
    INFORMATIONS: 'osobní informace',
    MISTER: 'Pan',
    MISS: 'Paní',
    NAME: 'Příjmení',
    OUR_NAME: 'Mé příjmení',
    FIRSTNAME: 'Jméno',
    OUR_FIRSTNAME: 'Mé jméno',
    MAIL: 'váš email',
    MAIL_PLH: 'email@adresa.com',
    MAIL_CONFIRM: 'potvrďte váš email',
    MAIL_ERROR: 'prosím uveďte platný email',
    MAIL_ERROR_CONFIRM: 'prosím potvďte váš email',
    ADDRESS: 'vaše adresa',
    STREET_PLH: '',
    STREET: 'Ulice a č.p.',
    ADDRESS_COMPLEMENT: 'více informací o vaší adrese',
    ADDRESS_COMP_PLH: 'více informací o vaší adrese',
    ZIPCODE: 'PSČ',
    CITY: 'Město',
    CITY_PLH: 'Vaše město',
    PHONE: 'telefon',
    PHONE_PLH: 'telefonní číslo',
    COUNTRY: 'Země',
    PRODUCT_INFORMATIONS: 'informace o produktech',
    PRODUCT_INFORMATION_DESC: 'Uveďte prosím následující informace, aby naši zaměstnanci mohli vaši žádost zpracovat:\n',
    PRODUCT: {
      DETAIL: {
        DESC: 'Vyplňte příslušná pole a připojte fotografii. Fotografie musí obsahovat čárový kód produktu, číslo šarže a datum expirace.\n'
      },
      NOTE: {
        TITLE: 'Zkušenost s výrobkem',
        SEO_TITLE: 'Komentář k produktu Bonduelle\n',
        SEO_DESC: 'Máte nějaké otázky k produktu z naší nabídce? Obraťte se na náš zákaznický servis.'
      }
    },
    PRODUCT_RANGE: 'Technologie produktu',
    PRODUCT_NAME: 'Název produktu',
    PRODUCT_NAME_PLH: 'Zadejte název produktu Bonduelle, na který se vztahuje váš dotaz',
    PRODUCT_INFORMATIONS_DETAIL: 'Detailní informace ',
    PRODUCT_INFORMATIONS_LABEL: 'Čárový kód produktu\n',
    PRODUCT_INFORMATIONS_PLH: 'Kód EAN, který se skládá z 13 číslic na obalu produktu.\n',
    PRODUCT_LOT: 'výrobní číslo',
    PRODUCT_LOT_INFO: 'Kód začínající písmenem L, následovaný řadou číslic. ',
    PHOTO: 'Připojte fotografii\n',
    EXPIRED_DATE: 'Datum expirace',
    EXPIRED_DATE_ERROR: 'Zadejte datum následujícím způsobem: DD / MM / RRRR',
    EXPIRED_DATE_DESC: 'Doby expirace najdete na spodní straně balení.',
    PHOTO_MORE: 'Nahrajte fotografie\n',
    MESSAGE: 'Vaše zpráva *',
    OUR_MESSAGE: 'napište zprávu',
    SALES_OPERATIONS: {
      TITLE: 'Informace obchodního charakteru',
      SEO_TITLE: '\nInformace o aktuálních akcích: hry, soutěže...',
      SEO_DESC: 'Informace obchodního charakteru získáte od našeho zákaznického oddělení. '
    },
    PRESS_SERVICE: 'Kontaktujte tiskové oddělení\n',
    FOOD_SERVICE: 'Spojte se s týmem Bonduelle Food Service\n',
    BUSINESS_INFORMATION: '',
    MODAL_INFORMATION: '',
    JOB_OFFER: {
      TITLE: 'Informace o volných pracovních pozicích',
      SEO_TITLE: '',
      SEO_DESC: ''
    },
    WEB_SUGGESTION: {
      TITLE: 'Chtěli byste navrhnout některá vylepšení našeho webu?\n',
      SEO_TITLE: 'Zpráva ohledně vylepšení webu Bonduelle\n',
      SEO_DESC: 'Spojte se s naším zákaznickým servisem a pomozte nám vylepšit web.\n'
    },
    ANOTHER: {
      TITLE: 'Jiné žádosti',
      SEO_TITLE: '\nKontakt pro jakýkoli další požadavek, servis',
      SEO_DESC: 'Informace vám poskytne naše oddělení služeb zákazníkům.'
    },
    AMBASSADOR: '',
    CATEGORY: {
      SALAD: 'Sterilovaná zelenina',
      FROZEN: 'Mražená zelenina',
      CAN: 'Légumio',
      TAKEAWAY: '',
      VEGETABLE: ''
    }
  },
  CONTACT: {
    TITLE: 'K vašim službám',
    SPECIAL_INFO: 'Náš zákaznický servis je vám k dispozici.\n' +
      'Vaše zprávy kontrolujeme každý den.',
    TAKE_CARE: '',
    BY_MAIL: 'Na adrese',
    BY_PHONE: 'Telefonicky',
    BY_SOURDLINE: '',
    MAIL_TEXT: 'BONDUELLE spol. s r. o.\n\nHybernská 1009 / 24\nPraha 110 00 \n',
    PHONE_TEXT: 'Tel.: +420 778 407 408 \n',
    SOURDLINE_TEXT: '',
    BY_DISTRIBUTOR: '',
    BY_DISTRIBUTOR_TEXT: '',
    SUBTITLE: 'Chcete nás kontaktovat ohledně:',
    AGREEMENT: '',
    READ_MORE: "Kromě toho máte právo kdykoli požádat společnost BONDUELLE o uplatnění vašich práv na přístup, opravu, výmaz či přenos vašich osobních údajů. Stejně tak můžete využít i vaše práva na omezení a vznesení námitky proti zpracování vašich osobních údajů. Pro uplatnění vašich práv souvisejících s vašimi osobními údaji napište Dpo_france(at)bonduelle(tečka)com nebo poštou na následující adresu: k rukám pověřence pro ochranu osobních údajů, Bonduelle, Rue Nicolas Appert, PO Box 30173, F- 59653 Villeneuve D'Ascq. Chcete-li se dozvědět více o způsobu, jakým BONDUELLE shromažďuje a používá vaše osobní údaje, klikněte sem.",
    READ_MORE_LABEL: 'Přečtěte si také',
    SATISFACTION_TEXT: 'Abychom vás mohli kontaktovat, abychom vám poskytli informace\n' +
      'požadované o produktu a zaslali vám dotazník spokojenosti, pokud si to\n' +
      'přejete, zpracujeme poskytnuté informace jako správce údajů. Právním\n' +
      'základem pro zpracování vaší žádosti je souhlas vzhledem k potenciálním\n' +
      'údajům týkajícím se zdraví, které můžete ve své žádosti uvést. Tyto\n' +
      'informace budou uchovávány po dobu nezbytně nutnou ke zpracování\n' +
      'vaší žádosti a po dobu až 5 let, abychom prokázali, že vaše žádost byla\n' +
      'vyřízena, a abychom splnili naše zákonné povinnosti. Právním základem\n' +
      'pro zaslání dotazníku spokojenosti je rovněž Váš souhlas. Tyto informace\n' +
      'budou uchovávány po dobu nezbytnou k odeslání dotazníku spokojenosti\n' +
      'a maximálně po dobu 1 roku. Chcete-li se dozvědět více o zpracování\n' +
      'vašich osobních údajů, přečtěte si prosím naše <a href="https://www.bonduelle.cz/zasady-ochrany-osobnich-udaju" target="_blank" class="underline">Zásady ochrany osobních\n' +
      'údajů</a>.',
    SATISFACTION_CHECKBOX: 'Zaškrtnutím tohoto políčka souhlasíte se zasíláním dotazníku\n' +
      'spokojenosti (skládajícího se z jediné otázky), jakmile bude vaše žádost\n' +
      'zpracována. To je nepovinné.',
    CHECKBOX_TEXT: 'Zaškrtnutím tohoto políčka souhlasíte s tím, že společnost Bonduelle\n' +
      'může zpracovávat osobní zdravotní údaje, které poskytnete ve své zprávě,\n' +
      'výhradně za účelem odpovědi na vaši žádost. <strong>To je povinné pro zpracování\n' +
      'vaší žádosti</strong>.',
    PHONE: 'Pondělí až pátek od 9:00 do 18:00 na +420 222 811 011 (hovor bez příplatku, mimo víkendy a státní svátky)',
    CALL_US: 'Zavolejte nám',
    TO_WRITE: 'Napište nám',
    PHONE_NUMBER: '',
    SUCCESS_MESSAGE: 'Děkujeme! Vaše zpráva byla odeslána našemu týmu. Budeme ji zpracovávat standardním způsobem.',
    PHOTOERROR: { MESSAGE: 'Chyba v nahrávání fotografie' }
  },
  GLOBAL: { READ_MORE: '' },
  FLOWBOX: { GENERIC_KEY: '', PRODUCT_KEY: '', RECIPE_KEY: '', HOME_KEY: '' },
  TOKEN: {
    FACEBOOK: '',
    PAGE_FACEBOOK_ID: '',
    INSTAGRAM: '',
    INSTA_ID: '17841404637610582',
    YOUTUBE: '',
    TWITTER: '',
    MAGAZINE: ''
  },
  COOKIE: {
    BANNER: {
      MANDATORY: 'Povinné',
      ANALYTICS: 'Analýza',
      MARKETING: 'Marketing',
      SOCIAL_MEDIA: 'Sociální sítě',
      ACCEPT: 'Přijmout vše',
      REJECT: 'Odmítnout vše',
      SET_UP: 'Nastavení',
      MANDATORY_DETAILS: 'Požadované cookies jsou nezbytné pro provoz stránek, například pro uložení vašich přihlašovacích údajů a pro zajištění bezpečného připojení.\n' +
        '\n' +
        'Soubory cookie potřebné k používání webu bonduelle.cz jsou:\n' +
        '\n' +
        '<ul>\n' +
        '\n' +
        '<li> Relační cookies: Cookies generované aplikacemi založenými na PHP. Toto je univerzální identifikátor používaný ke správě proměnných relací uživatelů. Obvykle se jedná o náhodně generované číslo. Způsob jeho použití může být specifický pro daný web, ale dobrým příkladem je udržení stavu přihlášení pro uživatele mezi stránkami.</li>\n' +
        '\n' +
        '\n' +
        '</ul>\n' +
        '\n',
      ANALYTICS_DETAILS: 'Funkční soubory cookie nám pomáhají optimalizovat web, například shromažďovat statistiky, zaznamenávat vaše preference pro sociální interakce nebo měřit výkon a stabilitu naší aplikace.\n' +
        '\n' +
        '<br>\n' +
        '\n' +
        'Analytické cookies používané stránkou bonduelle.cz jsou:\n' +
        '\n' +
        '<ul>\n' +
        '\n' +
        '<li> DoubleClick: Tento soubor cookie nastavuje společnost DoubleClick (vlastněná společností Google) a umožňuje vytvářet profil zájmů návštěvníka webu a zobrazovat relevantní reklamy na jiných webech. Hlavní činností společnosti je výměna nabídek reklam v reálném čase. </li>\n' +
        '<li> Google Analytics: tyto soubory cookie umožňují sledovat nejnavštěvovanější stránky a provoz na webu. </li> \n' +
        '<li> UTMTracking: tento soubor cookie umožňuje správu proměnných UTM pro optimální sledování různých online reklamních kampaní. </li> </ul>\n' +
        '\n',
      MARKETING_DETAILS: 'Marketingové cookies nám umožňují sledovat vaše preference receptů a produktů, abychom vám vždy mohli nabídnout produkty, které nejlépe odpovídají vašim přáním.\n' +
        '\n' +
        '<br>\n' +
        '\n' +
        'Marketingové cookies používané stránkou bonduelle.cz jsou:\n' +
        '\n' +
        '<ul>\n' +
        '\n' +
        '\n' +
        '<li> Sociální sítě: tyto soubory cookie umožňují zobrazení Bonduelle „SocialWall“ na domovské stránce webu. Social Media Wall umožňuje zobrazit všechny nejnovější příspěvky z hlavních sociálních sítí používaných týmy Bonduelle. </li>  </ul> \n' +
        '\n' +
        '\n' +
        '\n',
      V2: 'ok'
    },
    MODAL: {
      TITLE: 'Správa cookies ',
      TEXT: 'Skupina Bonduelle se zavazuje k zajištění ochrany osobních údajů uživatelů svých webových stránek, zejména webových stránek Bonduelle.cz. <br> Abychom vám umožnili využívání všech služeb nabízených webem, jako je nabídka produktů, objevování nových labužnických receptů, tipy a rady z našich tematických článků a také optimalizování při využívání webu s přizpůsobením podle vašeho profilu, používáme proto cookies. Skupina Bonduelle je plně odhodlána zajistit vysokou úroveň ochrany těchto osobních údajů a dodržovat všechna příslušná pravidla ochrany údajů při zpracování osobních údajů v souvislosti s používáním svých webových stránek.\n' +
        '\n' +
        'Soubory cookie můžete kdykoli aktivovat / deaktivovat.',
      CONFIG: '',
      SAVE: 'Uložit'
    }
  },
  LINK: { JOB_OFFERS: '' },
  CLIC2BUY: { INFOS: '' }
};
