import {NgModule} from '@angular/core';
import { MatCommonModule } from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';

const MODULES = [
  MatCommonModule,
  MatIconModule,
];

@NgModule({
  imports: MODULES,
  exports: MODULES,
})
export class AngularMaterialModule {
}
