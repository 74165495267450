import { trigger, state, style, transition, animate, animateChild, query, group } from '@angular/animations';

export function hideLeft(from, to, element, params) {
  return from === 'show' && to === 'hide' && params.direction === 'left';
}

export function hideRight(from, to, element, params) {
  return from === 'show' && to === 'hide' && params.direction === 'right';
}

export function showLeft(from, to, element, params) {
  return from === 'hide' && to === 'show' && params.direction === 'left';
}

export function showRight(from, to, element, params) {
  return from === 'hide' && to === 'show' && params.direction === 'right';
}

export const SliderAnimations =
trigger('sliderAnimations', [
  state('show', style({
    left: '0%',
    'z-index': '1'
  })),
  state('hide', style({
    left: '100%',
    'z-index': '0'
  })),

  // HIDE LEFT
  transition(hideLeft, [
    style({
      left: '0%',
      'z-index': '2'
    }),
    group([
      animate('1s ease-out', style({
        left: '-100%'
      })),
      query('@slideAnimations', [
        animateChild()
      ])
    ])
  ], {params: {direction: ''}}),

  // HIDE RIGHT
  transition(hideRight, [
    style({
      left: '0%',
      'z-index': '2'
    }),
    group([
      animate('1s ease-out', style({
        left: '100%'
      })),
      query('@slideAnimations', [
        animateChild()
      ])
    ])
  ], {params: {direction: ''}}),

  // SHOW LEFT
  transition(showLeft, [
    style({
      left: '100%'
    }),
    group([
      animate('0.8s ease-out', style({
        left: '0%'
      })),
      query('@slideAnimations', [
        animateChild()
      ])
    ])
  ], {params: {direction: ''}}),

  // SHOW RIGHT
  transition(showRight, [
    style({
      left: '-100%'
    }),
    group([
      animate('0.8s ease-out', style({
        left: '0%'
      })),
      query('@slideAnimations', [
        animateChild()
      ])
    ])
  ], {params: {direction: ''}})
]);
