<!-- Title -->
<ng-container *ngIf="title" [ngSwitch]="seoTitle">
  <h1 *ngSwitchCase="'H1'" [innerHTML]="title" class="sf-headline-title sf-title sf-main-color"></h1>
  <h2 *ngSwitchCase="'H2'" [innerHTML]="title" class="sf-headline-title sf-title sf-main-color"></h2>
  <h3 *ngSwitchCase="'H3'" [innerHTML]="title" class="sf-headline-title sf-title sf-main-color"></h3>
  <div *ngSwitchDefault  [innerHTML]="title" class="sf-headline-title sf-title sf-main-color"></div>
</ng-container>

<!-- Icons -->
<ul class="sf-headline-list" *ngIf="icons && icons.length">
  <ng-container *ngFor="let icon of icons">
    <li [ngClass]="{'sf-headline-list-item-large': icon === 'container'}" class="sf-headline-list-item">
      <mat-icon class="sf-headline-list-item-icon" [svgIcon]="icon"></mat-icon>
    </li>
  </ng-container>
</ul>
