import { TranslatableRoute } from "@app/core/interfaces/translatable-route";
import { TranslateService } from "@ngx-translate/core";

export function removeHTMLTags(str: string): string {
  if (typeof str === 'string') {
    return str.replace(/<[^>]+>/gm, '').replace(/&nbsp;/g, ' ').trim();
  } else {
    return '';
  }
}

export function truncateMetaDescription(str: string, length: number = 150): string {
  if (typeof str === 'string') {
    const sanitizedStr = removeHTMLTags(str);
    let truncatedStr = sanitizedStr.slice(0, length);
    if (sanitizedStr.length >= length) {
      truncatedStr += '.';
    }
    return truncatedStr;
  } else {
    return '';
  }
}

export function translateRoutes(routes: TranslatableRoute[], translateService: TranslateService) {
  routes.forEach(route => {
    if (route.path.length && route.translateKey) {
      route.path = translateService.instant(route.translateKey) + 
        (route.params ? route.params.map(p => `/:${p}`).join('') : '');
    }

    if (route.redirectTo && route.translateKey) {
      route.redirectTo =  translateService.instant(route.translateKey);
    }

    if (route.children) {
      translateRoutes(route.children, translateService);
    }
  });
}

export function toUpperCaseFirstLetter(word: string) {
  if (!word) return word;
  return word[0].toUpperCase() + word.substr(1);
}
