import { SfModel } from '../networking/models/model';

export class SfShareButton extends SfModel {

  /** Name of the social network */
  name: string;

  /** Url of the social network */
  url: string;
}
