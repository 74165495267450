import {
  SfLink
} from '@app/sf-lib/public_api';
import { SomRecipePage } from './recipe-page.model';

const charsMapConvert = require('@app/core/charsMap');

export class SomMagazineRecipePage extends SomRecipePage {

  get link(): SfLink {
    let alias = `${this.id}`;
    const title = this.name ? this.name : this.recipeName;
    if (this.recipeName || this.name) {
      alias = encodeURI(charsMapConvert(title));
    }
    // for seo friendly url we need to downcase the recipe id, for France, the url are already index with uppercase id and we not touch it.
    /*if (this.localize.currentLang.country !== 'fr') {
      this.id = this.id.toLocaleLowerCase();
    }*/
    if(typeof this.id === "string") this.id = this.id.toLocaleLowerCase();
    if (this.trackingCode) {
      return {
        is_external: false,
        label: '',
        url: `${this.localize.instant(this.baseMagazineUrl)}/${alias}/${this.id}`,
        extras: {id: this.trackingCode}
      };
    } else {
    return {
      is_external: false,
      label: '',
      url: `${this.localize.instant(this.baseMagazineUrl)}/${alias}/${this.id}`
    };
  }

  }
  set link(value: SfLink) {
    return;
  }

  private readonly baseMagazineUrl = '/magazine/recipes';

  public toJSON(nm: string) {
    const newArticle = super.toJSON(nm);
    newArticle.link = this.link;
    return newArticle;
  }
}
