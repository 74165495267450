import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'sf-icon-button',
    templateUrl: './icon-button.component.html',
    styleUrls: ['./icon-button.component.sass'],
    standalone: false
})
export class SfIconButtonComponent implements OnInit {

  @Input() icon: string;

  @Input() label: string;

  public hover: boolean;

  constructor() { }

  ngOnInit() {
  }

}
