import { SfLink } from '../../link';
import { SfMediaImage } from '../../image';

export interface SfSlide {

  /** Main image background */
  background: SfMediaImage;

  /** Image background specifically for mobile device */
  smallbackground?: SfMediaImage;

  /** 3D pack */
  packshot?: SfMediaImage;

  /** GTIN code of product */
  gtin?: string;

  /** Label 1 (title) */
  label1?: string;

  /** Label 2 */
  label2?: string;

  /** Link */
  link?: SfLink;

  recipeName?: string;

  recipeLink?: SfLink;

}

export function isSfSlide(object: Object): object is SfSlide {
  return 'background' in object;
}
