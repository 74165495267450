import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SfSearchGridComponent } from './components/search-grid.component';
import { SfLinkModule } from '../link/link.module';
import { TranslateModule } from '@ngx-translate/core';
import { SfLocalizeModule } from '../../core/localize/localize.module';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    SfLinkModule,
    TranslateModule,
    SfLocalizeModule,
    RouterModule
  ],
  declarations: [SfSearchGridComponent],
  exports: [SfSearchGridComponent]
})
export class SfSearchGridModule { }
