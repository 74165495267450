<div class="sf-slider-product" 
[ngClass]="{'sf-slider-product-main': mode === 'main',
'sf-slider-product-magazine': mode === 'magazine',
'sf-slider-product-buttons': buttons}" *ngIf="pSlider && pSlider.slides && pSlider.slides.length">
  <!-- Left arrow -->
  <div class="sf-slider-product-arrow sf-slider-product-arrow-left"
    (click)="previous()" *ngIf="pSlider.slides.length > 1">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 50"><defs>
        <style>.cls-1 {fill: #FFF}</style>
        <clipPath>
          <path class="cls-1" d="M0 4l22 21L0 47l4 3 25-25L4 0z"/>
        </clipPath>
      </defs>
      <g transform="rotate(-180 14 25)">
        <g>
          <path class="cls-1" d="M0 4l22 21L0 47l4 3 25-25L4 0z"/>
        </g>
      </g>
    </svg>
  </div>

  <!-- Slider -->
  <ul class="sf-slider-product-slider">

    <div class="sf-slider-product-slider-title" 
    [ngClass]="{'sf-slider-product-slider-title-main': mode === 'main',
    'sf-slider-product-slider-title-magazine': mode === 'magazine',
    'sf-slider-product-slider-title-large': ('RECIPE.PRODUCT_ASSOCIATE' | translate).length > 28 }">
      {{'RECIPE.PRODUCT_ASSOCIATE' | translate }}
    </div>

    <!-- Slide -->
    <li [@sliderAnimations]="sliderService.getAnimationState(i)" (@sliderAnimations.start)="animationStart()" (@sliderAnimations.done)="animationDone()"
      *ngFor="let slide of pSlider.slides; let i = index" class="sf-slider-product-slide">
      <ng-template [ngTemplateOutlet]="pushTemplate" [ngTemplateOutletContext]="{$implicit: slide, state: sliderService.getAnimationState(i)}">
      </ng-template>
    </li>

  </ul>

  <!-- Right arrow -->
  <div class="sf-slider-product-arrow sf-slider-product-arrow-right"
      (click)="next()" *ngIf="pSlider.slides.length > 1">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 50">
        <defs>
          <style>.cls-1 {fill: #FFF}</style>
          <clipPath>
            <path class="cls-1" d="M0 4l22 21L0 47l4 3 25-25L4 0z"/>
          </clipPath>
        </defs>
        <g>
          <g>
            <path class="cls-1" d="M0 4l22 21L0 47l4 3 25-25L4 0z"/>
          </g>
        </g>
      </svg>
  </div>
</div>