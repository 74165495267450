import { SfModel } from '../../../core/networking/models/model';
import { SfMediaImage } from '../../image';
import { SfLink } from '../../link';
import { SfSlide } from './slide.interface';

export class SfSimpleSlide extends SfModel implements SfSlide {

  /** Main image background */
  background: SfMediaImage;

  /** 3D pack */
  packshot?: SfMediaImage;

  /** GTIN code of product */
  gtin?: string;

  /** Label 1 (title) */
  label1?: string;

  /** Label 2 */
  label2?: string;

  /** Link */
  link?: SfLink;

  recipeName?: string;

  recipeLink?: SfLink;
}
