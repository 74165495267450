import { Component, OnInit, Input, ViewEncapsulation, Inject } from '@angular/core';
import { SfItemStat } from '../models/item-stat.interface';
import { SfMessageService } from '../../../core/message/services/message.service';
import { SfSiteInfo, SITE_INFO } from '../../../core/localize/models/site-info.model';

@Component({
    selector: 'sf-item-stat',
    templateUrl: './item-stat.component.html',
    styleUrls: ['./item-stat.component.sass'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SfItemStatComponent implements OnInit {

  constructor(
    public eventService: SfMessageService, @Inject(SITE_INFO) public siteInfo: SfSiteInfo
  ){}

  @Input() itemStat: SfItemStat;
  @Input() rollOver = false;

  public hover: boolean;

  ngOnInit() {
  }

}
