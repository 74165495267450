import { SfMediaImage, SfSection } from '@app/sf-lib/public_api';
import { SomPage } from './page.model';

export class SomGenericPage extends SomPage {

    public url: string;

    public sections: SfSection[];

    public nid?: string;

    public title?: string;

    public type?: string;

    public afficher_header?: boolean;

    public afficher_footer?: boolean;

    public afficher_pleine_page?: boolean;

    public image_de_fond?: SfMediaImage;

    constructor() {
        super();
        this.sections = [];
        this.meta_title = '';
        this.meta_description = '';
        this.meta_keywords = '';
        this.type = '';
        this.afficher_header = true;
        this.afficher_footer = true;
        this.afficher_pleine_page = false;
        this.image_de_fond = null;
    }
}
