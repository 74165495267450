/* tslint:disable */export const pl_pl = {
  '404': {
    TITLE: 'Ups....',
    PAGENOTFOUND: 'Strona nie istnieje',
    BUTTON: 'Powrót do strony startowej',
    RECIPES: 'Odkryj nasze przepisy'
  },
  HOME: {
    SHOWCASE: 'Przepisy',
    CATCH_PHRASE: '#tojestpyszne',
    SHOWCASE_SEE_ALL: 'Zobacz wszystkie przepisy',
    MAGAZINE: { TITLE: 'TOP', BUTTON: 'Nasz blog (without name yet)' },
    OUR_VEGETABLES: 'Nasze warzywa',
    SOCIAL_WALL: 'Podzielmy się pasją',
    FAQ_TITLE: 'Pytania',
    FAQ_SEE_MORE: 'Wybierz temat',
    FLOWBOX: ''
  },
  SELECT: { DEFAULT_VALUE: 'Bonduelle przedstawia' },
  MENU: {
    CONTACT_US: 'Kontakt',
    MY_ACCOUNT: 'Mój profil',
    GOOD_DEALS: 'Promocje',
    NEWSLETTER: 'Newsletter'
  },
  FOOTER: {
    LINK: {
      BONDUELLE: 'Bonduelle.pl',
      CREDITS: 'Prawa autorskie',
      LEGAL_NOTICE: 'Nota prawna',
      TERMS_OF_SALES: 'Warunki sprzedaży',
      COOKIES: 'Ciasteczka',
      SITEMAP: 'Mapa strony',
      PRIVACY_POLICY: 'Polityka prywatności',
      ALL_RIGHTS_RESERVED: '2020© Wszelkie prawa zastrzeżone'
    },
    HISTORY: {
      TITLE: 'Bonduelle od 1853 r.',
      TEXT: 'Bonduelle to firma rodzinna, która od 7 pokoleń pracuje nad rozwojem produkcji rolnej, która szanuje glebę i ludzi. Bronimy wydajnej, inteligentnej i zjednoczonej agroekologii, zdecydowanie zwróciliśmy się w przyszłość i codziennie wprowadzamy innowacje od pola do talerza, aby stworzyć lepszą przyszłość dzięki żywności roślinnej'
    },
    NEWSLETTER: {
      TITLE: 'Społeczność Bonduelle',
      TEXT: 'Wyrażam zgodę na otrzymywanie newslettera "Wszystko o warzywach według Bonduelle" (wiadomości, promocje, konkursy, artykuły ...)',
      PLACEHOLDER: 'Twój adres e-mail',
      GDPR_LINK: 'Polityka prywatności'
    }
  },
  PRODUCTS: 'Produkty',
  MAGAZINE: {
    HOME: 'Witamy na naszym blogu',
    CATCH_PHRASE: '',
    MENU: { BONDUELLE_LINK: 'Odwiedź bonduelle.com' },
    SHOWCASE: 'Zobacz',
    PREVIOUS_ARTICLES: 'Poprzednie artykuły',
    PREVIOUS_ARTICLES_SEE_ALL: 'Wszystkie artykuły',
    PREVIOUS_EDITIONS: 'Poprzednie edycje',
    PREVIOUS_EDITIONS_SEE_ALL: 'Zobacz inne wydania',
    PREVIOUS_ARTICLES_BY_CATEGORY: 'Poprzednie artykuły według kategorii',
    RUBRIC: {
      SEE_ALL: 'Zobacz wszystkie przepisy',
      SEE_ALL_ARTICLES: 'Zobacz wszystkie artykuły',
      ON: 'artykuły na temat'
    },
    RUBRIC_COOK: { ON: 'przepisy z' },
    ARTICLE: { JOIN_US: 'Dołącz do nas', READ_ALSO: 'Czytaj więcej' },
    RECIPE: {
      INGREDIENTS: 'Składniki',
      INGREDIENTS_LABEL_DESC: 'dla {{value}} osób',
      INGREDIENTS_LABEL_ONE_DESC: 'dla {{value}} osób',
      INGREDIENTS_LABEL: 'Składniki dla {{value}} osób',
      INGREDIENTS_LABEL_ONE: 'Składniki dla {{value}} osoby',
      PEOPLES: '{{value}} osoby',
      PEOPLE: '{{value}} osoby',
      DIFFICULTY: { EASY: 'Łatwe', MEDIUM: 'Średnie', HARD: 'Trudne' },
      COST: { CHEAP: 'Tanie', EXPENSIVE: 'Drogie' },
      TIMING: { MIN: 'min', HOURS: 'godz.' },
      STEP: 'Krok',
      DISCOVER_ALSO: 'Odkryj również...'
    }
  },
  ARTICLE: { DISCOVER_ALSO: 'Odkryj również ...', INGREDIENTS: 'Składniki' },
  SF: {
    SLIDE: { DISCOVER: 'Odkryj' },
    SEARCH: { PLACEHOLDER: 'Szukaj', ALL: 'Wszystkie' },
    NEWSLETTER: {
      BTN_LABEL: 'Rejestruję',
      PLACEHOLDER: 'Twój adres email',
      ERROR_MESSAGE: 'Nieprawidłowy adres email',
      ALREADY_SUBSCRIBED: 'Już zamówiłeś',
      GDPR: {
        TXT: 'Więcej informacji o przetwarzaniu Twoich danych',
        LINK: '/cookies#gdpr'
      }
    },
    MONTHLY_FOLDER: { TITLE: 'Wiadomości z ostatniego miesiąca' },
    MINI_NEWSLETTER: {
      PLACEHOLDER: 'Twój adres email',
      GDPR: {
        TXT: 'Więcej informacji o przetwarzaniu Twoich danych',
        LINK: '/cookies#gdpr'
      }
    },
    SOCIALITEM: {
      SHARE: 'udostępnij',
      COMMENT: 'skomentuj',
      BONDUELLE: 'Bonduelle'
    },
    SEARCHRECIPE: { DISCOVER_ALL: 'szukaj wszystkich przepisów!' },
    FORM: {
      ERROR: 'Nieprawidłowe pole',
      PHOTO: { ADD: 'Dodaj zdjęcie', DELETE: 'Usuń zdjęcie', SEE: 'Zobacz' }
    }
  },
  CORPORATE: {
    COMPANY_STORY: 'Historia firmy',
    CSR_COMMITMENT: 'Nasze zobowiązania',
    VEGETABLE_INNOVATIONS: 'Innowacje roślinne',
    NEWS: 'Aktualności',
    SEE_MORE: 'Zobacz więcej artykułów',
    ON: 'artykuły na temat'
  },
  NEWSLETTER: {
    TITLE: 'Zamów newsletter',
    SUBTITLE: 'Promocje, inspiracje kulinarne i odrobina kultury'
  },
  ROUTES: {
    products: 'produkty',
    news: 'aktualnosci',
    'since-1853': 'o-nas',
    'field-plate': 'z-pola-na-talerz',
    'company-story': 'historia-firmy',
    'csr-commitments': 'zobowiazania-csr',
    'vegetal-innovations': 'roslinne-innowacje',
    magazine: 'blog',
    all: 'zobacz-wszystko',
    article: 'artykul',
    cook: 'gotowac',
    cultivate: 'uprawiac',
    explore: 'odkrywac',
    recipe: 'przepis-na-czekanie',
    recipes: 'przepisy-kulinarne',
    'recipes-home': 'przepisy-kulinarne',
    r: 'r',
    credits: 'credits-com',
    'legal-notice': 'nota-prawna',
    'terms-of-sales': 'warunki-sprzedaży',
    cookies: 'cookies',
    sitemap: 'mapa-strony',
    'privacy-policy': 'polityka-prywatnosci',
    'all-rights-reserved': 'wszystkie-prawa-zastrzeżone',
    search: 'szukaj',
    'articles-search': 'artykul',
    vegetables: 'warzywa',
    p: 'p',
    contact: 'kontakt',
    'info-product': 'informacje-o-produkcie',
    'note-product': 'uwaga-produktowa',
    suggestion: 'propozycja',
    'other-request': 'informacje-o-produkcie',
    faq: 'faq',
    'all-themes': 'wszystkie-tematy',
    'products-search': 'wyszukiwanie-produktow',
    recipesearch: 'wyszukiwanie-przepisow',
    'product-news': 'nowosci-o-produkcie',
    'good-deals': 'dobre-oferty',
    'not-found': 'nie-znaleziono',
    'menu-page': 'strona-menu',
    'menu-one': 'menu-jeden',
    'menu-two': 'menu-dwa',
    'menu-three': 'menu-trzy',
    'menu-four': 'menu-cztery',
    'menu-external': '',
    'theme-pasta-rice': '',
    'theme-bowls': '',
    'theme-soup': '',
    'theme-snack': '',
    'theme-sauce': '',
    'theme-unique-plate': '',
    'theme-over': '',
    'theme-legumin': 'legumin',
    'theme-ingredients-recipes': '',
    'theme-cook-plate': ''
  },
  BREAD: {
    products: 'Produkty',
    news: 'aktualności',
    'since-1853': 'O nas',
    'field-plate': 'z pola na talerz',
    'company-story': 'Historia firmy',
    'csr-commitments': 'Zobowiązania CSR',
    'vegetal-innovations': 'Roślinne innowacje',
    magazine: 'Blog',
    all: 'Zobacz wszystko',
    article: 'artykuł',
    cook: 'gotować',
    cultivate: 'uprawiać',
    explore: 'Odkrywać',
    recipe: 'Przepis na czekanie',
    recipes: 'Przepisy kulinarne',
    'recipes-home': 'Przepisy kulinarne',
    'articles-search': 'artykuł',
    search: 'szukaj',
    vegetables: 'warzywa',
    contact: 'Kontakt',
    'info-product': 'informacje o produkcie',
    'note-product': 'uwaga-produktowa',
    suggestion: 'propozycja',
    'other-request': 'informacje o produkcie',
    faq: 'faq',
    'all-themes': 'wszystkie tematy',
    'products-search': 'wyszukiwanie produktów',
    recipesearch: 'wyszukiwanie przepisów',
    'product-news': 'nowości o produkcie',
    'good-deals': 'dobre oferty',
    'not-found': 'nie znaleziono',
    'menu-page': 'strona menu',
    'menu-one': 'menu jeden',
    'menu-two': 'menu dwa',
    'menu-three': 'menu trzy',
    'menu-four': 'menu cztery'
  },
  MENU_PAGE: {
    TITLE1: 'TYTUŁ 1',
    TITLE2: 'TYTUŁ 2',
    TITLE3: 'TYTUŁ 3',
    TITLE4: 'TYTUŁ 4',
    EXTERNAL: ''
  },
  TECHNICAL: { MENU_PAGE: 'Oferty pracy' },
  SEO: {
    TITLE: {
      MENU_ONE: 'Menu jeden Tytuł',
      MENU_TWO: 'Menu jeden Tytuł',
      MENU_THREE: 'Menu jeden Tytuł',
      MENU_FOUR: 'Menu jeden Tytuł',
      HOME: 'Bonduelle: źródło inspiracji na  warzywa i przepisów kulinarnych',
      SEARCH: {
        MAIN: '{{value}} Bonduelle',
        RECIPES: '{{value}} posortowane według przepisu | Bonduelle',
        VEGETABLES: '{{value}} posortowane według warzywa | Bonduelle',
        ARTICLES: '{{value}} posortowane według ieraksts | Bonduelle'
      },
      CONTACT: 'Skontaktuj się z obsługą klienta, informacje | Bonduelle',
      RECIPES: 'Przepis kulinarny: zupa, quiche, lasagne warzywna ... | Bonduelle',
      RECIPE_HOME: 'Przepis warzywny na świeta, wesele, przyjęcie | Bonduelle',
      RECIPE: 'Przepis {{value}}  | Bonduelle',
      PRODUCTS: 'Świat żywności roślinnej',
      NEWS: 'Porady, gotowanie, jak przygotować warzywa | Bonduelle',
      CORPORATE: {
        NEWS: 'Najnowsze artykuły, aktualności dotyczące warzyw i gotowania | Bonduelle',
        COMPANY_STORY: 'Prezentacja firmy Bonduelle',
        CSR_COMMITMENTS: 'Wartości etyczne / zasady i zaangażowanie grupy  | Bonduelle',
        VEGETAL_INNOVATIONS: 'Innowacje roślinne: nowe gospodarstwa, produkty | Bonduelle',
        FIELD_PLATE: 'Warzywa: gotuj najlepsze przepisy | Bonduelle',
        ARTICLE: '{{value}}  | Bonduelle',
        VEGETABLE: 'Pomysł na przepis, gotuj dobrze {{value}} | Bonduelle'
      },
      PRODUCT: '{{value}} gotowane warzywa Bonduelle',
      MAGAZINE: {
        NEWS: 'Nowości warzywne, przepisy dla dorosłych i dzieci | Bonduelle',
        ARTICLE: '{{value}} | Legumiz  | Bonduelle',
        COOK: 'Proste i szybkie przepisy na gotowanie warzyw | Bonduelle',
        GROW: 'Uprawa i pielęgnacja warzyw w ogrodzie warzywnym  |  Bonduelle',
        EXPLORE: 'Odkryj nowe warzywa i smaki dla dzieci | Bonduelle',
        RECIPE: 'Przepis {{value}} | Bonduelle'
      },
      FAQ: 'FAQ: często zadawane pytania internautów | Bonduelle',
      NOTFOUND: 'Błąd 404, spróbuj ponownie później | Bonduelle',
      RECIPES_ALL: 'Wszystkie nasze przepisy na gotowanie warzyw',
      RECIPETYPE: 'Przepis na przygotowanie: {{value}} warzywnego',
      RECIPESEASON: 'Przepis na warzywa sezonowe {{value}}',
      RECIPEPREPARATION: 'Przepis kulinarny: czas przygotowania{{value}}',
      RECIPEDIFFICULTY: 'Łatwy przepis na gotowanie warzyw: {{value}} z warzyw',
      RECIPEVEGETABLE: 'Łatwe przepisy na bazie{{value}}',
      RECIPECOST: 'Przepisy kulinrane i warzywa {{value}}',
      RECIPEDISH: 'Przepisy kulinarne i warzywa na{{value}}',
      RECIPEDESC: 'Przepisy kulinrane i warzywa {{value}}',
      '2RECIPEFACETS': 'Przepis kulinarny {{value}}',
      '4RECIPEFACETS': 'Przepis kulinarny {{value}}',
      PRODUCTSEASON: 'Przepis na łatwe potrawy z warzyw sezonowych{{value}}',
      PRODUCTTECHNO: 'Łatwe przepisy kulinarne z warzyw {{value}}',
      PRODUCTVEGETABLE: 'Łatwe przepisy kulinarne z warzyw na bazie {{value}}',
      PRODUCTRANGE: 'Łatwe przepisy kulinarne z warzyw na bazie {{value}}',
      THEME_ALL: 'Świat żywności roślinnej',
      THEME_STARTER: 'Pomysł na warzywne przystawki',
      THEME_SALAD: 'Warzywny pomysł na przygotowanie letniej sałatki',
      THEME_CAN: '',
      THEME_FROZEN: '',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: '',
      THEME_RECIPE: 'Pomysł na przygotowanie z warzyw łatwych / szybkich przepisów',
      THEME_PLATE: 'Pomysły warzyne na przygotowanie dań ciepłych i zimnych',
      THEME_ACC: 'Warzywne pomysły na dodatki do mięsa i ryby',
      THEME_SNACK: 'Pomysł na warzywa do szybkiego zjedzenia',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': 'Przepis z warzyw{{value}}'
    },
    DESCRIPTION: {
      MENU_ONE: 'menu jeden opis',
      MENU_TWO: 'menu jeden opis',
      MENU_THREE: 'menu jeden opis',
      MENU_FOUR: 'menu jeden opis',
      HOME: 'Warzywa w puszkach, mrożone,  odkryj nasze smaczne warzywa, pomysły na przepisy, które zainspirują Twoje dania. Bonduelle: źródło pomysłów na przygotowanie warzyw i przepisów kulinarnych.',
      SEARCH: {
        MAIN: 'Odkryj nasze pomysły na przygotowanie warzyw na {{value}}. Proste i smaczne przepisy kulinarne. Gotowane Warzywa.',
        RECIPES: 'Wszystkie nasze {{value}} posortowane według przepisu. Proste i smaczne przepisy kulinarne. Gotowane Warzywa.',
        VEGETABLES: 'Wszystkie nasze {{value}} posortowane według warzyw. Proste i smaczne przepisy kulinarne. Gotowane Warzywa.',
        ARTICLES: 'Wszystkie nasze {{value}} posortowane według artykul. Proste i smaczne przepisy kulinarne. Gotowane Warzywa.'
      },
      CONTACT: 'Pytanie ? Potrzebujesz informacji na temat produktu, usługi lub sprawy w toku? Możesz skontaktować się z naszą obsługą klienta. Odpowiedź w 2-3 dni robocze.',
      RECIPES: 'Odkryj smaczne przepisy kulinarne na bazie warzyw.  Spraw przujemność swoim bliskim, przygotowując proste przepisy. Proste i smaczne przepisy kulinarne. Gotowane Warzywa.',
      RECIPE_HOME: 'Odkryj smaczne przepisy na warzywa na święta. Spraw przyjemność swoim bliskim na tę specjalną okazję. Proste i smaczne przepisy kulinarne. Gotowane Warzywa.',
      RECIPE: '',
      PRODUCTS: 'Szeroka gama naszych warzyw pozwala przygotować smaczne, łatwe i zbilansowane przepisy. Proste i smaczne przepisy kulinarne. Gotowane Warzywa.',
      NEWS: 'Dowiedz się, jak prawidłowo przygotować warzywa, ugotuj je skutecznie, aby stworzyć smaczne dania. Proste i smaczne przepisy kulinarne. Gotowane Warzywa.',
      CORPORATE: {
        NEWS: 'Najnowsze wiadomości na temat rolnictwa, innowacji roślinnych, odpadów i sposobów lepszego jedzenia w prosty sposób. Proste i smaczne przepisy kulinarne. Gotowane Warzywa.',
        COMPANY_STORY: 'Chcesz wiedzieć więcej o świecie warzyw i moim życiu rolniczym i roślinnym? Wszystko to pokażemy na naszym blogu. Proste i smaczne przepisy kulinarne. Gotowane Warzywa.',
        CSR_COMMITMENTS: 'Zastanawiasz się, jak uprawia się nasze warzywa, skąd pochodzą, jak szanujemy środowisko? Oto odpowiedzi na pytania dotyczące warzyw.',
        VEGETAL_INNOVATIONS: 'Innowacje roślinne są w centrum naszych zainteresowań. Nowe, bardziej ekologicznej sposoby uprawy , mniej odpadów… Proste i smaczne przepisy kulinarne. Gotowane Warzywa.',
        VEGETABLE: '',
        FIELD_PLATE: 'Odkryj nasze pyszne przepisy, które zachwycą wszystkie podniebienia. Proste i smaczne przepisy kulinarne. Gotowane Warzywa.',
        ARTICLE: ''
      },
      PRODUCT: '{{value}} aby przygotować smaczne przepisy kulinarne i zadowolić swoich bliskich. Proste i smaczne przepisy kulinarne. Gotowane Warzywa.',
      MAGAZINE: {
        NEWS: 'Wszystkie aktualności, nowości na temat warzyw, zrównoważone przepisy kulinarne. Proste i smaczne przepisy kulinarne. Gotowane Warzywa.',
        ARTICLE: '',
        COOK: 'Nasze pyszne przepisy zachwycą wszystkie podniebienia, odkryj je na naszej stronie. Proste i smaczne przepisy kulinarne. Gotowane Warzywa.',
        GROW: 'Czy sam chcesz uprawiać warzywa w ogrodzie warzywnym? Odkryj nasze wskazówki i porady dotyczące uprawy i przetworów z warzyw. Gotowane Warzywa.',
        EXPLORE: 'Pozwól swoim dzieciom odkryć nowe dania z warzyw dzięki prostym i skutecznym przepisom. Proste i smaczne przepisy kulinarne. Gotowane Warzywa.',
        RECIPE: ''
      },
      FAQ: 'Masz pytanie? Pogrupowaliśmy wszystkie najczęściej zadawane pytania internautów, aby jak najlepiej spełnić Twoje oczekiwania.',
      PAGE: 'strona',
      RECIPETYPE: 'Odkryj nasze  przepisy kulinarne {{value}}i jak prawidłowo gotować warzywa. Pomysł na wegetariański przepis, szybki, oryginalny, łatwy.',
      RECIPESEASON: 'Odkryj nasze przepisy kulinarne i porady jak prawidłowo gotować warzywa w {{value}}. Pomysł na wegetariański przepis, szybki, oryginalny, łatwy.',
      RECIPEPREPARATION: 'Odkryj nasze przepisy kulinarne i porady jak prawidłowo gotować warzywa w {{value}}. Pomysł na wegetariański przepis, szybki, oryginalny, łatwy.',
      RECIPEDIFFICULTY: 'Odkryj nasze przepisy kulinarne i porady jak prawidłowo gotować warzywa w {{value}}. Pomysł na wegetariański przepis, szybki, oryginalny, łatwy.',
      RECIPEVEGETABLE: 'Odkryj nasze  przepisy kulinarne {{value}}i jak prawidłowo gotować warzywa. Pomysł na wegetariański przepis, szybki, oryginalny, łatwy.',
      RECIPECOST: 'Odkryj nasze przepisy kulinarne i porady jak prawidłowo gotować warzywa na {{value}}. Pomysł na wegetariański przepis, szybki, oryginalny, łatwy.',
      RECIPEDISH: 'Odkryj nasze przepisy kulinarne i porady jak prawidłowo gotować warzywa na {{value}}. Pomysł na wegetariański przepis, szybki, oryginalny, łatwy.',
      RECIPEDESC: 'Odkryj nasze przepisy kulinarne i porady jak prawidłowo gotować warzywa {{value}}. Pomysł na wegetariański przepis, szybki, oryginalny, łatwy.',
      '2RECIPEFACETS': 'Odkryj nasze przepisy kulinarne i porady jak prawidłowo gotować warzywa {{value}}. Pomysł na wegetariański przepis, szybki, oryginalny, łatwy.',
      '4RECIPEFACETS': 'Odkryj nasze przepisy kulinarne i porady jak prawidłowo gotować warzywa {{value}}.',
      PRODUCTSEASON: 'Szeroka gama naszych warzyw pozwala przygotować smaczne, łatwe i zrównoważone przepisy sezonowe {{value}}. Proste i smaczne przepisy kulinarne. Gotowane Warzywa.',
      PRODUCTTECHNO: 'Szeroka gama naszych warzyw pozwala przygotować smaczne, łatwe i zrównoważone przepisy {{value}}. Proste i smaczne przepisy kulinarne. Gotowane Warzywa.',
      PRODUCTVEGETABLE: 'Szeroka gama naszych warzyw pozwala przygotować smaczne, łatwe i zrównoważone przepisy na bazie  {{value}}. Proste i smaczne przepisy kulinarne. Gotowane Warzywa.',
      PRODUCTRANGE: 'Szeroka gama naszych warzyw pozwala przygotować smaczne, łatwe i zrównoważone przepisy na bazie  {{value}}. Proste i smaczne przepisy kulinarne. Gotowane Warzywa.',
      THEME_ALL: 'Szeroka gama naszych warzyw pozwala przygotować smaczne, łatwe i zbilansowane przepisy. Proste i smaczne przepisy kulinarne. Gotowane Warzywa.',
      THEME_STARTER: 'Chcesz ugotować zbilansowany posiłek? Szeroka gama warzyw do przygotowywania łatwych i szybkich przystawek warzywnych, porady i wskazówki, które udoskonalą Twoje posiłki.',
      THEME_SALAD: 'Chcesz ugotować zbilansowany posiłek? Szeroka gama warzyw do przygotowywania łatwych i szybkich przystawek warzywnych, porady i wskazówki, które udoskonalą Twoje posiłki.',
      THEME_CAN: '',
      THEME_FROZEN: '',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: '',
      THEME_RECIPE: 'warzywa, aby ugotować pyszne i zbilansowane przepisy oparte na łatwych i szybkich warzywach, wskazówki i sztuczki, które urozmaicą Twoje posiłki. Szybkie i zbilansowane przepisy kulinarne',
      THEME_PLATE: 'Chcesz ugotować zbilansowany posiłek? Warzywa do przygotowania gorących lub zimnych potraw na bazie szybkich i łatwych warzyw, wskazówki i sztuczki, które wzbogacą Twoje posiłki.',
      THEME_ACC: 'warzywa do gotowania łatwych i szybkich dodatków do dań na bazie warzyw, wskazówki i sztuczki, które wzbogacą Twoje posiłki. Proste i zrównoważone przepisy kulinarne.',
      THEME_SNACK: 'Chcesz ugotować zbilansowany posiłek? warzywa do gotowania przepisów na przekąski oparte na łatwych i szybkich warzywach, wskazówki i sztuczkki, aby wzbogacić swoje posiłki.',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': 'Szeroka gama naszych warzyw pozwala przygotować smaczne, łatwe i zbilansowane przepisy kulinarne {{value}}. Proste i smaczne przepisy kulinarne. Gotowane Warzywa.',
      NOTFOUND: 'Żądana strona nie została znaleziona. Zapraszamy do nowego wyszukiwania lub spróbuj ponownie później.'
    },
    PIPE: { SOCIETY: ' | Bonduelle' },
    THEME_ALL: 'wszyscy',
    THEME_STARTER: 'Przystawki',
    THEME_SALAD: 'Wszystko do mojej sałatki',
    THEME_CAN: '',
    THEME_FROZEN: '',
    THEME_READY_MEALS: '',
    THEME_BIO: '',
    THEME_VEGETABLES: '',
    THEME_RECIPE: 'składniki -  przepisy',
    THEME_PLATE: 'danie główne',
    THEME_ACC: 'dodatki',
    THEME_SNACK: 'przekąsko'
  },
  VEGETABLE: {
    DISCOVER_VEGETABLE: 'Wszystko o naszych warzywach',
    OURS_RECIPES: 'Nasze Przepisy',
    DISCOVER_ALSO: 'Odkryj także...',
    SEE_ALL: 'Zobacz wszystkie warzywa',
    ON: 'Warzywa na'
  },
  RECIPE: {
    'INGREDIENTS_LABEL ': 'Składniki dla {{value}} osób',
    PEOPLES: '{{value}} osoby',
    DIFFICULTY: { EASY: 'Łatwy', MEDIUM: 'Średni', HARD: 'Trudny' },
    COST: { CHEAP: 'Niedrogi', EXPENSIVE: 'Drogi' },
    TIMING: { MIN: 'Min', HOURS: 'godziny' },
    STEP: 'Krok',
    DISCOVER_ALSO: 'Odkryj także...',
    HOME_SEE_MORE: 'Zobacz więcej przepisów',
    SHOWCASE: 'Top',
    HOME_BOOK: 'Nasze przepisy',
    MIN_MORE: 'm i +',
    HOUR_MORE: 'h i +',
    FILTER_BY_CRITERIA: 'Filtruj według kryteriów',
    SORT_BY: 'Sortuj przes',
    BEST_RATED: 'Najlepiej oceniane',
    MOST_CONSULTED: 'Najczęściej odwiedzane',
    MEMBERS_RECIPES: 'Przepisy członków',
    CRITERIA: 'Nowości',
    DISCOVER: { RECIPES: 'Odkryj nasze przepisy' },
    DISCOVER_RECIPE: 'Odkryj przepis',
    INSTRUCTIONS: 'Wskazówki',
    DEFAULT_TAG: 'Pomysł na przepis',
    MOST_RECENT: 'Najnowszy',
    RECIPE_VIDEOS: 'Przepis wideo',
    PRODUCT_ASSOCIATE: 'Przepis wykonany z',
    BUDGET_LOW: 'Tani',
    BUDGET_HIGH: 'Wysoki',
    BUDGET_MIDDLE: 'Średni',
    PRINT_RECIPE: 'drukuj przepis',
    LET_YOURSELF_SURPRISED: 'Daj się zaskoczyć',
    COOK_TIP_TITLE: 'Moje wskazówki',
    ENJOY_MEAL: 'Smacznego!',
    FILTER: { LEGEND: 'Legenda' }
  },
  PREDIGGO: { DIFFICULTY: { EASY: 'Łatwy', MEDIUM: 'Średni', HARD: 'Trudny' } },
  COOKIES: {
    TEXT: 'Kontynuując wizytę na tej stronie, akceptujesz użycie plików cookies do celów statystycznych i reklamowych oraz w celu zarządzania ruchem w sieci, udostępniania informacji reklamodawcom na innych stronach internetowych, aby zapewnić Ci odpowiednią komunikację promocyjną, która odzwierciedla Twoje zainteresowania.',
    CLOSE: 'Zamknij',
    MORE: 'Dowiedz się więcej',
    AGREE_YOUTUBE: 'Aby zobaczyć ten film, musisz zaakceptować pliki cookie YouTubeo',
    BUTTON_YOUTUBE: 'Zaakceptuj cookies'
  },
  POPUP: { LAUNCH: 'POPUP-OUVERTURE-SITE' },
  FIELD_PLATE: { DISCOVER_VEGETABLE: 'Wszystko o naszych warzywach' },
  SEARCH: {
    OUR_RECIPES: 'Przepisy',
    OUR_PRODUCTS: 'Produkty',
    OUR_ARTICLES: 'Artykuły',
    RECIPES: 'Przepisy',
    PRODUCTS: 'Produkty',
    ARTICLES: 'Artykuły',
    ALL: 'Zobacz wszystko',
    SORT_BY: 'Sortuj przez',
    RESULTS: 'wyniki',
    RESULT: 'wyniki',
    PRODUCT_OUT: 'produkty z',
    APPLY: 'Zastosuj',
    FILTER: 'Filtruj',
    RECIPE_OUT: 'przepis z',
    NO_RESULT: 'Biała kapusta do tego wyszukiwania... Zadajesz nam trudne pytanie',
    SEE_MORE: 'Zobacz więcej',
    DISCOVER_RECIPES: 'Odkryj nasze przepisy',
    SEE_MORE_OF: 'zobacz więcej z ',
    DELETE: 'skasuj'
  },
  RECIPE_LIST: {
    OUR_RECIPES: 'Przepisy',
    RESULTS: 'Wyniki',
    SEE_MORE: 'Zobacz więcej przepisów'
  },
  PRODUCT: {
    BUTTON: {
      ACTU: 'Nowości o produktach',
      FOUND: 'Gdzie można znaleźć ten product',
      DISCOVER: 'Odkryj całą gamę'
    },
    NUTRITIONAL_VALUE: 'wartość odżywcza',
    CONDITIONING: 'opakowanie',
    PORTIONS: 'porcje',
    NET_WEIGHT: 'waga netto',
    DRY_WEIGHT: 'waga po odsączeniu',
    INGREDIENTS: 'składniki',
    OUR_RECIPES: 'Odkryj nasze przepisy',
    FOR: 'dla',
    PER_PORTION: 'i porcją',
    ENERGY: 'energia',
    FAT: 'tłuszcz',
    SATURATE: 'w tym kwasy tłuszczowe nasycone',
    GLUCIDES: 'węglowodany',
    FIBER: 'błonnik ',
    PROTEINS: 'białko',
    SALT: 'sól',
    DISCOVER_ALSO: 'odkryt także',
    MORE_INFORMATION: 'więcej informacji',
    TRACES: 'śladowa ilość',
    CONTAINS: 'zawiera',
    SLIDER: { SHEET: 'zobacz arkusz', BUY: 'kup ten produkt' },
    NUTRISCORE: 'informacja-nutriscore',
    TRIMAN: 'information-triman',
    AGRICONFIANCE: 'information-agriconfiance',
    BIO: 'informacja-bio',
    PESTICIDE_FREE: '',
    LEAF: '',
    LOCK: '',
    SEE_ALL: 'Zobacz wszystkie produkty',
    LEGISLATION: 'zgodnie z obowiązującymi przepisami',
    PORTION: { SINGULAR: 'porcja', PLURAL: 'porcje' },
    DISCLAIMER: '',
    COOKING: 'sposób przygotowania',
    CONSERVATION: 'przechowywanie'
  },
  FAQ: {
    ON: 'pytania o',
    ALL: 'Wszystko',
    SEE_MORE: 'Zobacz więcej pytań',
    TITLE_1: 'Odpowiedzi na twoje pytania',
    TITLE_2: 'Czy masz pytanie dotyczące konkretnego produktu?',
    LINK_LABEL: 'Napisz do nas'
  },
  FORM: {
    GO_TO_STEP: 'Przejdź do kroku',
    STEP: 'Krok',
    RECEIVE_EMAIL_COPY: 'Chcę otrzymać kopię maila',
    SEND: 'Wyślij',
    GDPR: 'Więcej informacji o zarządzaniu  Twoimi danymi',
    ERROR: 'Wszystkie pola oznaczone * muszą być wypełnione i prawidłowe',
    SEND_ERROR: 'Wystąpił błąd. Spróbuj ponownie za chwilę',
    NOTICE: 'W celu prawidłowej obsługi Twojego pliku Bonduelle może, w zależności od przypadku, poprosić Cię o dane dotyczące zdrowia. Nie są one obowiązkowe i możesz ich nie przekazywać.',
    SENDING: 'Wyślij formularz',
    LOADING: 'Ładowanie...',
    PRODUCT_INFORMATION_TITLE: 'Informacja o produkcie',
    PRODUCT_INFORMATION: {
      SEO_TITLE: 'Informacja o produkcie | Bonduelle',
      SEO_DESC: 'Skontaktuj się z naszym działem obsługi klienta'
    },
    INFORMATIONS: 'Twoje informacje',
    MISTER: 'Pan',
    MISS: 'Pani',
    NAME: 'Nazwisko',
    OUR_NAME: 'Nazwisko',
    FIRSTNAME: 'Imię',
    OUR_FIRSTNAME: 'Imię',
    MAIL: 'Twój e-mail',
    MAIL_PLH: 'moj_mail@mail.com',
    MAIL_CONFIRM: 'Potwierdź swój e-mail',
    MAIL_ERROR: 'Proszę wpisać aktualny adres e-mail',
    MAIL_ERROR_CONFIRM: 'Proszę potwierdzić swój adres e-mail',
    ADDRESS: 'Twój adres',
    STREET_PLH: '',
    STREET: 'ulica i numer domu',
    ADDRESS_COMPLEMENT: 'dodatkowe informacje o adresie',
    ADDRESS_COMP_PLH: 'dodatkowe wskazówki',
    ZIPCODE: 'Kod pocztowy',
    CITY: 'Miasto',
    CITY_PLH: 'Twoje miasto',
    PHONE: 'Telefon',
    PHONE_PLH: 'Twój numer telefonu',
    COUNTRY: 'Kraj',
    PRODUCT_INFORMATIONS: 'Informacje o produkcie',
    PRODUCT_INFORMATION_DESC: 'Abyśmy mogli jak najlepiej zbadać Twoją prośbę, wypełnij następujące informacje:',
    PRODUCT: {
      DETAIL: {
        DESC: 'Dla każdego wymaganego kryterium możesz wypełnić formularz <br> formularz i dodać zdjęcie. Informacja: twoje zdjęcie musi pozwolić odczytać <br>kod kreskowy, numer partii i datę przydatności do spożycia produktu.'
      },
      NOTE: {
        TITLE: 'Reklamacja produktu',
        SEO_TITLE: 'Uwaga i komentarz na temat produktu  | Bonduelle',
        SEO_DESC: 'Uwaga lub komentarz na temat produktu z naszego asortymentu? Aby uzyskać pomoc skontaktuj się z naszym działem obsługi klienta. Spersonalizowana odpowiedź w ciągu 48 godzin'
      }
    },
    PRODUCT_RANGE: 'Jakiej gamy produktów dotyczy zapytanie?',
    PRODUCT_NAME: 'Nazwa produktu',
    PRODUCT_NAME_PLH: 'Proszę wpisać nazwę produktu Bonduelle, którego dotyczy zapytanie',
    PRODUCT_INFORMATIONS_DETAIL: 'Informacje szczegółowe',
    PRODUCT_INFORMATIONS_LABEL: 'Kod kreskowy produktu',
    PRODUCT_INFORMATIONS_PLH: 'Kod kreskowy składa się <br> z 13 cyfr widocznych na <br> opakowaniu twojego produktu',
    PRODUCT_LOT: 'Numer serii produktu',
    PRODUCT_LOT_INFO: 'Numer serii produktu zaczyna się <br> zawsze literą L, po której następuje kilka cyfr, i różni się w zależności od produktów. <br> <br> Na przykład L72450492107: 13.',
    PHOTO: 'Dodaj zdjęcie',
    EXPIRED_DATE: 'Data przydatności do spożycia',
    EXPIRED_DATE_ERROR: 'Proszę wpisać datę w formacie dd/mm/rrrr',
    EXPIRED_DATE_DESC: 'Termin przydatności do spożycia <br> jest przedstawiony w formie daty.',
    PHOTO_MORE: 'Dodatkowe zdjęcia',
    MESSAGE: 'Twoja wiadomość',
    OUR_MESSAGE: 'Proszę wpisać swoją wiadomość',
    SALES_OPERATIONS: {
      TITLE: 'Informacje na temat akcji handlowej',
      SEO_TITLE: 'Informacje o bieżących akcjach: konkurs… | Bonduelle',
      SEO_DESC: 'Skontaktuj się z naszym działem obsługi klienta, aby uzyskać informacje na temat bieżącej akcji handlowej. Spersonalizowana odpowiedź w ciągu 48 godzin'
    },
    PRESS_SERVICE: 'Skontaktuj się z działem prasowym',
    FOOD_SERVICE: 'Skontaktuj się z działem Bonduelle Food Service',
    BUSINESS_INFORMATION: '',
    MODAL_INFORMATION: '',
    JOB_OFFER: { TITLE: '', SEO_TITLE: '', SEO_DESC: '' },
    WEB_SUGGESTION: {
      TITLE: 'Uwaga dotycząca strony internetowej',
      SEO_TITLE: 'Komunikat o poprawie, sugestia dotycząca strony | Bonduelle',
      SEO_DESC: 'Skontaktuj się z naszym działem obsługi klienta, aby pomóc nam ulepszyć witrynę.'
    },
    ANOTHER: {
      TITLE: 'Złóż kolejne zapytanie',
      SEO_TITLE: 'Kontakt w sprawie wszelkich innych wniosków, usług | Bonduelle',
      SEO_DESC: 'Skontaktuj się z naszym działem obsługi klienta, aby uzyskać informacje, usługi lub sugestie. Spersonalizowana odpowiedź w ciągu 48 godzin'
    },
    AMBASSADOR: 'Zostań ambasadorem Bonduelle',
    CATEGORY: {
      SALAD: 'Sałatka',
      FROZEN: 'Mrożonki',
      CAN: 'Konserwa',
      TAKEAWAY: 'Jedzenie na wynos',
      VEGETABLE: ''
    }
  },
  CONTACT: {
    TITLE: 'KONTAKT',
    SPECIAL_INFO: 'Bonduelle Polska S.A.\n' +
      '<br>ul. Szturmowa 2, 02-678 Warszawa\n' +
      '<br>\n' +
      '<br>NIP: 5260215243\n' +
      '<br>REGON: 011555353\n' +
      '<br>KRS: 0000227543',
    TAKE_CARE: '',
    BY_MAIL: 'Recepcja ',
    BY_PHONE: 'Obsługa Klienta ',
    BY_SOURDLINE: '',
    MAIL_TEXT: 'recepcja@bonduelle.com \n' +
      '<br>\n' +
      'Tel. +48 22 232 34 00\n' +
      'Pon.- pt, w godz. 8.00-16.00',
    PHONE_TEXT: 'Inflolinia dostępna od poniedziałku do piątku, w godz. 8.00-16.00\n' +
      ' 0 801 372 032',
    SOURDLINE_TEXT: '',
    BY_DISTRIBUTOR: '',
    BY_DISTRIBUTOR_TEXT: '',
    SUBTITLE: 'W jakim celu chcesz się z nami skontaktować:',
    AGREEMENT: '',
    READ_MORE: '',
    READ_MORE_LABEL: '',
    SATISFACTION_TEXT: 'W celu skontaktowania się z Tobą w celu przekazania wymaganych\n' +
      'informacji na temat produktu i przesłania kwestionariusza satysfakcji, jeśli\n' +
      'sobie tego życzysz, będziemy przetwarzać przekazane informacje w\n' +
      'ramach naszej roli administratora danych. Podstawą prawną rozpatrzenia\n' +
      'Twojego żądania jest zgoda ze względu na potencjalne dane dotyczące\n' +
      'stanu zdrowia, które możesz podać w swoim żądaniu. Informacje te będą\n' +
      'przechowywane tak długo, jak będzie to konieczne do przetworzenia\n' +
      'Twojej prośby i przez okres do 5 lat, aby przedstawić dowód, że Twoja\n' +
      'prośba została rozpatrzona i aby spełnić nasze zobowiązania prawne.\n' +
      'Podstawą prawną przesłania ankiety satysfakcji jest także Twoja zgoda.\n' +
      'Informacje te będą przechowywane przez czas niezbędny do przesłania\n' +
      'ankiety satysfakcji i maksymalnie przez 1 rok. Aby dowiedzieć się więcej na\n' +
      'temat przetwarzania Twoich danych osobowych, zapoznaj się z naszą\n' +
      '<a href="https://www.bonduelle.pl/polityka-srodowiskowa" target="_blank" class="underline">Polityką Prywatności</a>.',
    SATISFACTION_CHECKBOX: 'Zaznaczając to pole, wyrażasz zgodę na otrzymanie kwestionariusza\n' +
      'satysfakcji (składającego się z jednego pytania) po przetworzeniu Twojego\n' +
      'żądania. To jest opcjonalne.',
    CHECKBOX_TEXT: 'Zaznaczając to pole, wyrażasz zgodę na przetwarzanie przez Bonduelle\n' +
      'danych osobowych dotyczących zdrowia, które możesz podać w\n' +
      'wiadomości, wyłącznie w celu udzielenia odpowiedzi na Twoją prośbę.\n' +
      '<strong>Jest to obowiązkowe, aby móc przetworzyć Twoją prośbę</strong>.',
    PHONE: 'Od poniedziałku do piątku od 8:00 do 16:00 na numer 0 801 372 032 ( z wyłączeniem weekendów i świąt)',
    CALL_US: 'Aby do nas zadzwonić',
    TO_WRITE: 'Napisz do nas',
    PHONE_NUMBER: '801372032',
    SUCCESS_MESSAGE: 'Dziękuję! Twoja wiadomość została wysłana do naszego zespołu. Zostanie przetworzona w czasie\n' +
      '2 - 3 dni roboczych. <br> <br>\n' +
      'Jesteśmy naturalnie do Państwa dyspozycji od poniedziałku do piątku w godzinach od 8.00 do 16.00 pod numerem 0801372032(połączenie bez opłat). <br> <br>\n' +
      'W celu kontroli jakości naszych usług, połączenie może być rejestrowane.',
    PHOTOERROR: { MESSAGE: '' }
  },
  GLOBAL: { READ_MORE: '' },
  FLOWBOX: { GENERIC_KEY: '', PRODUCT_KEY: '', RECIPE_KEY: '', HOME_KEY: '' },
  TOKEN: {
    FACEBOOK: '',
    PAGE_FACEBOOK_ID: '140531319366310',
    INSTAGRAM: '',
    INSTA_ID: '',
    YOUTUBE: '',
    TWITTER: '',
    MAGAZINE: ''
  },
  COOKIE: {
    BANNER: {
      MANDATORY: 'Wymagane',
      ANALYTICS: 'Analiza',
      MARKETING: 'Marketing',
      SOCIAL_MEDIA: 'Media społecznościowe',
      ACCEPT: 'Akceptuj wszystkie',
      REJECT: 'Odrzuć wszystkie',
      SET_UP: 'Konfiguracja',
      MANDATORY_DETAILS: '\n' +
        '„Wymagane pliki cookie są niezbędne do działania witryny, na przykład do przechowywania danych logowania i zapewniania bezpiecznego połączenia.\n' +
        'Pliki cookie wymagane do korzystania ze strony bonduelle.pl to:\n' +
        '<ul>\n' +
        '<li> pliki cookie sesji (SESSION_ID): plik cookie generowany przez aplikacje PHP. Jest to identyfikator ogólnego przeznaczenia używany do zarządzania zmiennymi sesji użytkownika. Jest to liczba generowana losowo, sposób jej użycia może zależeć od witryny, ale dobrym przykładem jest utrzymywanie stanu logowania użytkownika między stronami. </li>\n' +
        '</ul> ” ',
      ANALYTICS_DETAILS: '„Funkcjonalne pliki cookie pomagają nam optymalizować witrynę, na przykład gromadzić statystyki, rejestrować preferencje dotyczące interakcji społecznościowych lub mierzyć wydajność i stabilność naszej aplikacji.\n' +
        '<br>\n' +
        'Analityczne pliki cookies wykorzystywane przez serwis bonduelle.com to:\n' +
        '<ul>\n' +
        '<li> DoubleClick: ten plik cookie jest ustawiany przez firmę DoubleClick (będącą własnością Google) w celu tworzenia profilu zainteresowań odwiedzających witrynę i wyświetlania odpowiednich reklam w innych witrynach. Główną działalnością firmy jest wymiana reklam ofertowych w czasie rzeczywistym od Google. </li>\n' +
        '<li> Google Analytics: te pliki cookie umożliwiają śledzenie najczęściej odwiedzanych stron i ruchu w witrynie. </li>\n' +
        '<li> UTMTracking: ten plik cookie umożliwia zarządzanie zmiennymi UTM w celu optymalnego śledzenia różnych kampanii reklamowych online </li>.\n' +
        '</ul> ”',
      MARKETING_DETAILS: '„Marketingowe pliki cookie pozwalają nam śledzić Twoje preferencje dotyczące przepisów i produktów, aby zawsze oferować Ci produkty, które najlepiej odpowiadają Twoim potrzebom.\n' +
        '<br>\n' +
        'Marketingowe pliki cookie używane przez stronę bonduelle.pl to:\n' +
        '<ul>\n' +
        '<li> Prediggo: to narzędzie wykorzystuje plik cookie SESSION_ID, aby oferować produkty i przepisy, które najlepiej pasują do sposobu przeglądania i wyszukiwań. </li>\n' +
        '<li> ClickToBuy: ten plik cookie umożliwia nam zapamiętanie Twojej lokalizacji, abyśmy mogli zaoferować Ci najbliższe sklepy, które oferują produkty Bonduelle, których szukasz. </li>\n' +
        '<li> Social Network: te pliki cookie umożliwiają wyświetlanie Bonduelle „SocialWall” na stronie głównej witryny. Ten widok pozwala zobaczyć wszystkie najnowsze posty z głównych sieci społecznościowych używanych przez zespoły Bonduelle. </li>\n' +
        '<li> FlowBox: ten plik cookie umożliwia korzystanie z usług oprogramowania flowbox, które pozwala nam oferować naszym odwiedzającym przepisy i kreacje innych użytkowników Internetu, którzy używają produktów Bonduelle. </li>\n' +
        '</ul> „” ”',
      V2: 'ok'
    },
    MODAL: {
      TITLE: 'Zarządzanie plikami cookie',
      TEXT: 'Grupa Bonduelle zobowiązuje się do zapewnienia ochrony danych osobowych użytkowników jej witryn internetowych, w szczególności serwisu internetowego Bonduelle.pl. <br> Aby umożliwić Ci korzystanie z usług oferowanych przez tę witrynę, takich jak prezentownie produktów, odkrywanie nowych przepisów kulinarnych, subskrybowanie niektórych usług, takich jak nasze biuletyny, a także w celu optymalizacji ich wykorzystania i personalizacji zgodnie z Twoim profilem, strona korzysta z plików cookie.Grupa Bonduelle jest w pełni zaangażowana w zapewnienie wysokiego poziomu ochrony w odniesieniu do takich Danych Osobowych i do przestrzegania wszystkich obowiązujących zasad ochrony danych podczas przetwarzania Danych Osobowych w związku z korzystaniem z jej strony internetowej.\n' +
        'W ten sposób możesz w dowolnym momencie aktywować / dezaktywować pliki cookie',
      CONFIG: 'Konfiguracja',
      SAVE: 'Zapisz'
    }
  },
  LINK: { JOB_OFFERS: 'https://jobs.bonduelle.com/?locale=pl_PL' },
  CLIC2BUY: { INFOS: '' }
};
