import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncateString',
    standalone: false
})
export class TruncateStringPipe implements PipeTransform {

  transform(value: any, length?: any): any {
    length = length ? length : 80;
    if (length && value.length > length) {
      value = value.substr(0, length) + '...';
    }
    return value;
  }

}
