import { Component, OnInit, Input } from '@angular/core';
import { SfSlide } from '../../models/slide.interface';
import { SlideAnimations } from './slide.animations';
import { SfMessageService } from '../../../../core/message/services/message.service';

@Component({
    selector: 'sf-product-slide',
    templateUrl: './product-slide.component.html',
    styleUrls: ['./product-slide.component.sass'],
    animations: [
        SlideAnimations.parent,
        SlideAnimations.left,
        SlideAnimations.right
    ],
    standalone: false
})
export class SfProductSlideComponent implements OnInit {

  @Input() pSlide: SfSlide;

  @Input() state: any;

  @Input() seoTitle: 'H1'|'H2'|'H3'|'DIV' = 'DIV';

  @Input() mode: 'main' | 'magazine' = 'main';

  @Input() buttons = false;

  constructor(
    public eventService: SfMessageService
  ) {}

  ngOnInit() {
  }

  public getAnimationState(): any {
    if (!this.state) { return this.state; }
    if (!this.state.params) {
      this.state.params = {};
    }
    return this.state;
  }

}
