import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SfSearchRecipeComponent } from './components/search-recipe.component';
import { SfSearchBarModule } from '../search-bar/search-bar.module';
import { SfIconModule } from '../icon/icon.module';
import { AngularMaterialModule } from '@app/shared/angular-material/angular-material.module';
import { TranslateModule } from '@ngx-translate/core';
import { SfLinkModule } from '../link/link.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SfLocalizeModule } from '../../core/localize/localize.module';
import { SfUtilsModule } from '../../core/utils/utils.module';

@NgModule({
  imports: [
    CommonModule,
    SfSearchBarModule,
    SfIconModule,
    SfLocalizeModule,
    AngularMaterialModule,
    TranslateModule,
    SfLinkModule,
    ReactiveFormsModule,
    SfUtilsModule
  ],
  declarations: [SfSearchRecipeComponent],
  exports: [SfSearchRecipeComponent]
})
export class SfSearchRecipeModule { }
