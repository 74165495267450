import { SfModel } from '../../../core/networking';
import { SfSectionText } from './section-text.interface';

export class SfSimpleSectionText extends SfModel implements SfSectionText {

  /** Title */
  title: string;

  /** Text of the section. May contain HTML tags */
  text: string;

}
