import { Injectable, Inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable} from 'rxjs';
import { SfLocalizeService, SITE_INFO, SfSiteInfo } from '@app/sf-lib/public_api';
import { NetworkService } from '../services/network.service';

/**
 * Interceptor responsible for passing headers/params in all requests
 * @export
 * @class AuthInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private readonly PREVIEW_STR = 'retail-pre';

  /** @ignore */
  constructor(
    private localize: SfLocalizeService,
    private networkService: NetworkService,
    @Inject(SITE_INFO) private siteInfo: SfSiteInfo
  ) { }

  /**
   * Append necessary headers and/or params to all requests to the API
   * @param {HttpRequest<any>} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   * @memberof AuthInterceptor
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let headers: HttpHeaders = request.headers;
    if (request.url.indexOf('/upload') >= 0) {
      return next.handle(request);
    }
    headers = headers.append('Content-type', 'application/json');
    headers = headers.append('site', `ret_${this.localize.currentLang.country}`);
    headers = headers.append('langcode', this.networkService.langcode);

    const origin: string = (this.siteInfo as any).origin || '';

    if (origin.indexOf(this.PREVIEW_STR) < 0) {
      // If current site is NOT the preview, append the 'status' property to headers
      // Status is :
      // 0 => only unpublished content
      // 1 => only published
      // null => all contents
      headers = headers.append('status', '1');
    }

    request = request.clone({
      headers: headers
    });

    return next.handle(request);

  }
}
