import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { PrediggoInterceptor } from './interceptors/prediggo.interceptor';
import { PrediggoItemsPipe } from './pipes/prediggo-items.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    PrediggoItemsPipe
  ],
  exports: [
    PrediggoItemsPipe
  ]
})
export class PrediggoModule {
  static forRoot(): ModuleWithProviders<PrediggoModule> {
    return {
      ngModule: PrediggoModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: PrediggoInterceptor,
          multi: true,
        }
      ],
    };
  }
}
