import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SfIconButtonComponent } from './components/icon-button.component';
import { AngularMaterialModule } from '@app/shared/angular-material/angular-material.module';

@NgModule({
  imports: [
    CommonModule,
    AngularMaterialModule,
  ],
  declarations: [SfIconButtonComponent],
  exports: [SfIconButtonComponent]
})
export class SfIconButtonModule { }
