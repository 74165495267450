import { Pipe, PipeTransform, ChangeDetectorRef, OnDestroy, Inject } from '@angular/core';
import { SfLocalizeService } from '../services/localize.service';
import { Subscription } from 'rxjs';
import { SITE_INFO, SfSiteInfo } from '../models/site-info.model';
import * as utils from '../classes/localize-utils';
import { SfLang } from '../../models/lang.model';

const VIEW_DESTROYED_STATE = 128;

@Pipe({
    name: 'sfLocalize',
    pure: false // required to update the value when the promise is resolved
    ,
    standalone: false
})
export class SfLocalizePipe implements PipeTransform, OnDestroy {
  private value = '';
  private lastKey: string;
  private lastLanguage: SfLang;
  private subscription: Subscription;

  constructor(
    private localize: SfLocalizeService,
    @Inject(SITE_INFO) private siteInfo: SfSiteInfo,
    private _ref: ChangeDetectorRef
  ) {
    this.subscription = this.localize.lang.subscribe(() => {
      this.transform(this.lastKey);
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  transform(query: string): string {
    if (!this.localize.currentLang.code) {
      return query;
    }
    if (!query || query.length === 0) {
      if (!this.localize.currentLang.default) {
        return `/${this.localize.currentLang.code}`;
      } else {
        return query;
      }
    }
    if (equals(query, this.lastKey) && equals(this.lastLanguage.code, this.localize.currentLang.code)) {
      return this.value;
    }

    this.lastKey = query;
    this.lastLanguage = this.localize.currentLang;

    this.value = this.localize.instant(query);

    this.lastKey = query;

    // if view is already destroyed, ignore firing change detection
    // tslint:disable-next-line:no-bitwise
    if ((<any> this._ref)._view && (<any> this._ref)._view.state & VIEW_DESTROYED_STATE) {
      return this.value;
    }
    this._ref.detectChanges();
    return this.value;
  }
}

/**
 * Compare if two objects are same
 */
export function equals(o1: any, o2: any): boolean {
  if (o1 === o2) {
    return true;
  }
  if (o1 === null || o2 === null) {
    return false;
  }
  if (o1 !== o1 && o2 !== o2) {
    return true; // NaN === NaN
  }
  const t1 = typeof o1,
    t2 = typeof o2;
  let length: number,
    key: any,
    keySet: any;

  if (t1 === t2 && t1 === 'object') {
    if (Array.isArray(o1)) {
      if (!Array.isArray(o2)) {
        return false;
      }
      if ((length = o1.length) === o2.length) {
        for (key = 0; key < length; key++) {
          if (!equals(o1[key], o2[key])) {
            return false;
          }
        }
        return true;
      }
    } else {
      if (Array.isArray(o2)) {
        return false;
      }
      keySet = Object.create(null);
      for (key in o1) {
        if (o1.hasOwnProperty(key)) {
          if (!equals(o1[key], o2[key])) {
            return false;
          }
          keySet[key] = true;
        }
      }
      for (key in o2) {
        if (o2.hasOwnProperty(key)) {
          if (!(key in keySet) && typeof o2[key] !== 'undefined') {
            return false;
          }
        }
      }
      return true;
    }
  }
  return false;
}
