import { SfModel } from '../../../core/networking';
import { SfSlider } from './slider.interface';
import { SfSlide } from './slide.interface';

export class SfSimpleSlider extends SfModel implements SfSlider {

  /** Main image background */
  slides: SfSlide[];

  constructor() {
    super();
    this.slides = [];
  }
}
