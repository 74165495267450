<nav aria-label="breadcrumb">
  <ol class="som-breadcrumb som-main-color som-font-regular" [ngClass]="{'white': breadcrumbService.isTextInsideImage}" *ngIf="breadcrumb.length">
    <li class="som-breadcrumb-item">
      <a [routerLink]="'/'">
        <mat-icon class="som-breadcrumb-item-icon" [svgIcon]="'home'"></mat-icon>
        <span (click)="setTagBreadCrumb('/')"></span>
      </a>
    </li>
    <li *ngFor="let segment of breadcrumb; index as i; first as isFirst; last as isLast" class="som-breadcrumb-item">
      <a *ngIf="!isLast" [routerLink]="segment.url">
        <!-- <mat-icon class="som-breadcrumb-item-icon" *ngIf="isFirst" [svgIcon]="'home'"></mat-icon> -->
        <span class="som-breadcrumb-item-arrow">&gt;</span>
        <span (click)="setTagBreadCrumb(segment)">{{ segment.label | capitalizeFirst }}</span>
      </a>
  
      <!-- Don't use a link for last element -->
      <a *ngIf="isLast">
        <!-- <mat-icon class="som-breadcrumb-item-icon" *ngIf="isFirst" [svgIcon]="'home'"></mat-icon> -->
        <span class="som-breadcrumb-item-arrow">&gt;</span>
        <span (click)="setTagBreadCrumb(segment)">{{ segment.label | capitalizeFirst }}</span>
      </a>
    </li>
  </ol>
</nav>

