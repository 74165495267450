import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { SfItemIconBkg } from '../../models/item-icon-bkg.interface';

@Component({
    selector: 'sf-item-icon-bkg',
    templateUrl: './item-icon-bkg.component.html',
    styleUrls: ['./item-icon-bkg.component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SfItemIconBkgComponent implements OnInit {

  /**
   * Data item
   *
   * @memberof SfItemIconBkgComponent
   */

  @Input() item: SfItemIconBkg;

  @Input() seoTitle: 'H1'|'H2'|'H3'|'DIV' = 'DIV';

  public hover: boolean;

  constructor() { }

  ngOnInit() {
  }

}
