/* tslint:disable */export const lv_lv = {
  '404': {
    TITLE: 'Ups...',
    PAGENOTFOUND: 'Lapa neeksistē',
    BUTTON: 'Atpakaļ uz galveno',
    RECIPES: 'Atklāj mūsu receptes'
  },
  HOME: {
    SHOWCASE: 'Receptes',
    CATCH_PHRASE: '',
    SHOWCASE_SEE_ALL: 'Visas receptes',
    MAGAZINE: { TITLE: 'Populārākās', BUTTON: 'Mazie lielie atklājumi' },
    OUR_VEGETABLES: 'Mūsu dārzeņi',
    SOCIAL_WALL: 'Kopā vienmēr jautrāk!',
    FAQ_TITLE: 'Jūsu jautājumi',
    FAQ_SEE_MORE: 'Vairāk',
    FLOWBOX: ''
  },
  SELECT: { DEFAULT_VALUE: 'Bonduelle piedāvā' },
  MENU: {
    CONTACT_US: 'Sazinieties',
    MY_ACCOUNT: 'Mans Bonduelle',
    GOOD_DEALS: 'Labi piedāvājumi',
    NEWSLETTER: 'Bonduelle žurnāls'
  },
  FOOTER: {
    LINK: {
      BONDUELLE: 'Bonduelle.lv',
      CREDITS: 'Autortiesības',
      LEGAL_NOTICE: 'Juridiskā informācija',
      TERMS_OF_SALES: 'Pārdošanas nosacījumi',
      COOKIES: 'Sīkdatnes',
      SITEMAP: 'Lapas karte',
      PRIVACY_POLICY: 'Privātuma politika',
      ALL_RIGHTS_RESERVED: '2024© Visas tiesības aizsargātas'
    },
    HISTORY: {
      TITLE: 'Bonduelle kopš 1853.',
      TEXT: 'Bonduelle – tā ir ģimene. Jau 7. paaudze strādā, radot lauksaimniecības produkciju, cienot dabu un cilvēkus. Mēs atbalstām bioloģisko lauksaimniecību, orientējamies uz nākotni un katru dienu cenšamies, lai dārzeņi kļūtu par aizvien lielāku katra cilvēka dzīves daļu.'
    },
    NEWSLETTER: {
      TITLE: 'Cilvēki un Bonduelle',
      TEXT: 'Piekrītu saņemt Bonduelle jaunumus un piedāvājumus "Viss par dārzeņiem ar Bonduelle" (jaunumi, reklāma, konkursi, raksti...)',
      PLACEHOLDER: 'Jūsu e-pasts',
      GDPR_LINK: 'Vairāk par Jūsu datu apstrādi'
    }
  },
  PRODUCTS: 'Produkti',
  MAGAZINE: {
    HOME: 'Laipni lūgti MAZAJOS LIELAJOS ATKLĀJUMOS',
    CATCH_PHRASE: '',
    MENU: { BONDUELLE_LINK: 'Iet uz bonduelle.lv' },
    SHOWCASE: 'Raksti/ieraksti',
    PREVIOUS_ARTICLES: 'Iepriekšējais ieraksts',
    PREVIOUS_ARTICLES_SEE_ALL: 'Apskatīt visus mūsu ierakstus',
    PREVIOUS_EDITIONS: 'Iepriekšējos ierakstos',
    PREVIOUS_EDITIONS_SEE_ALL: 'Apskatīt vairāk ierakstu',
    PREVIOUS_ARTICLES_BY_CATEGORY: 'Iepriekšējie ieraksti pa kategorijām',
    RUBRIC: {
      SEE_ALL: 'Apskatīt visas receptes',
      SEE_ALL_ARTICLES: 'Apskatīt visus ierakstus',
      ON: 'ieraksti '
    },
    RUBRIC_COOK: { ON: 'receptes no' },
    ARTICLE: { JOIN_US: 'Pievienojies', READ_ALSO: 'Lasīt arī' },
    RECIPE: {
      INGREDIENTS: 'Sastāvdaļas',
      INGREDIENTS_LABEL_DESC: '{{value}} porcijām',
      INGREDIENTS_LABEL_ONE_DESC: '{{value}} porcijai',
      INGREDIENTS_LABEL: 'Sastāvdaļas {{value}} porcijām',
      INGREDIENTS_LABEL_ONE: 'Sastāvdaļas {{value}} porcijai',
      PEOPLES: '{{value}} cilvēkiem',
      PEOPLE: '{{value}} cilvēkiem',
      DIFFICULTY: { EASY: 'Viegla', MEDIUM: 'Vidēji sarežģīta', HARD: 'Sarežģīta' },
      COST: { CHEAP: 'Lēta', EXPENSIVE: 'Dārga' },
      TIMING: { MIN: 'minūtes', HOURS: 'stundas' },
      STEP: 'Solis',
      DISCOVER_ALSO: 'Atklāj arī...'
    }
  },
  ARTICLE: { DISCOVER_ALSO: 'Atklāj arī...', INGREDIENTS: 'Sastāvdaļas' },
  SF: {
    SLIDE: { DISCOVER: 'Atklāj' },
    SEARCH: { PLACEHOLDER: 'Meklēšana', ALL: 'Visas' },
    NEWSLETTER: {
      BTN_LABEL: 'Abonēt',
      PLACEHOLDER: 'Jūsu e-pasts',
      ERROR_MESSAGE: 'Nepareizs e-pasts',
      ALREADY_SUBSCRIBED: 'Jūs jau esat reģistrējušies',
      GDPR: {
        TXT: 'Vairāk informācijas par Jūsu datu apstrādi',
        LINK: '/sīkdatnes#gdpr'
      }
    },
    MONTHLY_FOLDER: { TITLE: 'Mēneša jaunumi' },
    MINI_NEWSLETTER: {
      PLACEHOLDER: 'Jūsu e-pasts',
      GDPR: {
        TXT: 'Vairāk informācijas par Jūsu datu apstrādi',
        LINK: '/sīkdatnes#gdpr'
      }
    },
    SOCIALITEM: { SHARE: 'dalīties', COMMENT: 'komentēt', BONDUELLE: 'Bonduelle' },
    SEARCHRECIPE: { DISCOVER_ALL: 'meklēt visas receptes!' },
    FORM: {
      ERROR: 'Nepareizi aizpildīts',
      PHOTO: {
        ADD: 'Pievienot attēlu',
        DELETE: 'Dzēst attēlu',
        SEE: 'Apskatīt'
      }
    }
  },
  CORPORATE: {
    COMPANY_STORY: 'uzņēmuma vēsture',
    CSR_COMMITMENT: 'Ilgtspējība',
    VEGETABLE_INNOVATIONS: '',
    NEWS: 'Par Bonduelle',
    SEE_MORE: 'Vairāk ierakstu',
    ON: 'Ieraksti no'
  },
  NEWSLETTER: {
    TITLE: 'Abonēt jaunumus',
    SUBTITLE: 'Labi piedāvājumi, kulinārijas iedvesma un kultūra!'
  },
  ROUTES: {
    products: 'produkti',
    news: 'par-bonduelle',
    'since-1853': 'no-1853',
    'field-plate': 'darzenu-enciklopedija',
    'company-story': 'bonduelle-latvija',
    'csr-commitments': 'ilgtspejiba',
    'vegetal-innovations': 'mums-rup',
    magazine: 'mazie-lielie-atklaiumi',
    all: 'visi',
    article: 'ieraksts',
    cook: 'cepti',
    cultivate: 'kultiveti',
    explore: 'atklāti',
    recipe: '',
    recipes: 'receptes',
    'recipes-home': 'receptes-galvena',
    r: 'r',
    credits: 'atsauces-lv',
    'legal-notice': 'juridiska-informacija',
    'terms-of-sales': 'pardosanas-nosacijumi',
    cookies: 'sikdatnes',
    sitemap: 'lapas-karte',
    'privacy-policy': 'privatuma-politika',
    'all-rights-reserved': 'visas-tiesibas-aizsargatas',
    search: 'meklesana',
    'articles-search': 'ieraksts',
    vegetables: 'produkti',
    p: 'p',
    contact: 'kontakti',
    'info-product': 'produkta-informacija',
    'note-product': '',
    suggestion: 'piedavajums',
    'other-request': 'citi-piedavajumi',
    faq: 'buj',
    'all-themes': 'visas-temas',
    'products-search': 'produkta-meklesana',
    recipesearch: 'receptes-meklesana',
    'product-news': 'produkta-jaunumi',
    'good-deals': 'labi-piedavajumi',
    'not-found': 'nav-atrasts',
    'menu-page': 'blog',
    'menu-one': 'pagatavosim',
    'menu-two': 'interesanti',
    'menu-three': '',
    'menu-four': '',
    'menu-external': '',
    'theme-pasta-rice': '',
    'theme-bowls': '',
    'theme-soup': '',
    'theme-snack': '',
    'theme-sauce': '',
    'theme-unique-plate': '',
    'theme-over': '',
    'theme-legumin': '',
    'theme-ingredients-recipes': '',
    'theme-cook-plate': ''
  },
  BREAD: {
    products: 'produkti',
    news: 'par bonduelle',
    'since-1853': 'kopš 1853',
    'field-plate': 'DĀRZEŅU ENCIKLOPĒDIJA',
    'company-story': 'bonduelle Latvijā',
    'csr-commitments': 'ilgtspējība',
    'vegetal-innovations': 'Mums rūp',
    magazine: 'MAZIE LIELIE ATKLĀJUMI',
    all: 'visi',
    article: 'ieraksts',
    cook: 'cepti',
    cultivate: 'kultivēti',
    explore: 'atklāti',
    recipe: '',
    recipes: 'receptes',
    'recipes-home': 'receptes-galvena',
    'articles-search': 'ieraksts',
    search: 'meklesana',
    vegetables: 'produkti',
    contact: 'kontakti',
    'info-product': 'produkta-informācija',
    'note-product': '',
    suggestion: 'piedāvājums',
    'other-request': 'citi-piedāvājumi',
    faq: 'buj',
    'all-themes': 'visas-tēmas',
    'products-search': 'produkta-meklēšana',
    recipesearch: 'receptes-meklēšana',
    'product-news': 'produkta-jaunumi',
    'good-deals': 'labi-piedāvājumi',
    'not-found': 'nav atrasts',
    'menu-page': 'blog',
    'menu-one': 'Pagatavosim',
    'menu-two': 'Interesanti',
    'menu-three': '',
    'menu-four': ''
  },
  MENU_PAGE: {
    TITLE1: 'Pagatavosim',
    TITLE2: 'Interesanti',
    TITLE3: '',
    TITLE4: '',
    EXTERNAL: ''
  },
  TECHNICAL: { MENU_PAGE: 'vegetarian_passions' },
  SEO: {
    TITLE: {
      MENU_ONE: 'BLOG pagatavosim',
      MENU_TWO: 'BLOG interesanti',
      MENU_THREE: 'galvenā trešā Nosaukums',
      MENU_FOUR: 'galvenā ceturtā Nosaukums',
      HOME: 'Bonduelle: pasaule, kas mums patīk',
      SEARCH: {
        MAIN: '{{value}} | Bonduelle',
        RECIPES: 'Meklēšanas rezultāti receptēs: {{value}} | Bonduelle',
        VEGETABLES: 'Meklēšanas rezultāti produktos: {{value}} | Bonduelle',
        ARTICLES: '{{value}} sakārtoti pēc preces | Bonduelle'
      },
      CONTACT: 'Bonduelle kontakti',
      RECIPES: 'Bonduelle receptes',
      RECIPE_HOME: 'Visas mūsu receptes | Bonduelle',
      RECIPE: 'Bonduelle recepte',
      PRODUCTS: 'Bonduelle produkti',
      NEWS: 'Bonduelle jaunumi',
      CORPORATE: {
        NEWS: 'Bonduelle no 1853. g.: visi jaunumi',
        COMPANY_STORY: 'Bonduelle no 1853. g.: uzņēmuma vēsture',
        CSR_COMMITMENTS: 'Bonduelle no 1853. g.: mums rūp',
        VEGETAL_INNOVATIONS: 'Bonduelle no 1853. g.: dārzeņu jaunumi',
        FIELD_PLATE: 'Dārzeņu enciklopēdija | Bonduelle',
        ARTICLE: '{{value}} | Bonduelle',
        VEGETABLE: 'Viss par {{value}} | Bonduelle'
      },
      PRODUCT: '{{value}} | Bonduelle dārzeņi',
      MAGAZINE: {
        NEWS: 'Jaunumi par dārzeņiem, radošas receptes un vēl vairāk | Bonduelle',
        ARTICLE: 'Uzziniet ar Bonduelle',
        COOK: 'Pagatavojiet ar Bonduelle',
        GROW: 'Dārzeņu audzēšana ar Bonduelle',
        EXPLORE: 'Atklājumi ar Bonduelle',
        RECIPE: 'Pagatavojiet ar Bonduelle'
      },
      FAQ: 'Biežāk uzdotie jautājumi | Bonduelle',
      NOTFOUND: '404. kļūda, mēģiniet vēlreiz vēlāk | Bonduelle',
      RECIPES_ALL: 'visas mūsu receptes',
      RECIPETYPE: '{{value}} – gatavosim veselīgi, ātri un gardi',
      RECIPESEASON: '{{value}} – receptes, kas paredzētas piemērotākajam gadalaikam',
      RECIPEPREPARATION: '{{value}} – gatavosim veselīgi, ātri un gardi',
      RECIPEDIFFICULTY: '{{value}} recepte – gatavot var ikviens.',
      RECIPEVEGETABLE: '{{value}} – receptes ar šo dārzeni',
      RECIPECOST: '{{value}}',
      RECIPEDISH: '{{value}} – veselīgi, ātri un vienkārši.',
      RECIPEDESC: '{{value}} – ēdieni visiem.',
      '2RECIPEFACETS': '{{value}}',
      '4RECIPEFACETS': '{{value}} recepšu idejas',
      PRODUCTSEASON: 'Produkti, ko varat gatavot {{value}}',
      PRODUCTTECHNO: '{{value}} produkti',
      PRODUCTVEGETABLE: 'Produkti, kuros dominē {{value}}',
      PRODUCTRANGE: 'Produkti, kuros dominē {{value}}',
      THEME_ALL: 'Konservēti un saldēti produkti',
      THEME_STARTER: 'Uzkodas | Bonduelle',
      THEME_SALAD: 'Salāti | Bonduelle',
      THEME_CAN: 'Konservēti produkti | Bonduelle',
      THEME_FROZEN: 'Saldēti produkti | Bonduelle',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: 'Dārzeņi | Bonduelle',
      THEME_RECIPE: 'Idejas ātri un viegli pagatavojamām dārzeņu receptēm',
      THEME_PLATE: 'Pamatēdieni | Bonduelle',
      THEME_ACC: 'Piedevas | Bonduelle',
      THEME_SNACK: 'Uzkodas ikvienam | Bonduelle',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': '{{value}} – atklājiet mūsu produktus'
    },
    DESCRIPTION: {
      MENU_ONE: '',
      MENU_TWO: 'galvenā otrā apraksts',
      MENU_THREE: 'galvenā trešā apraksts',
      MENU_FOUR: 'galvenā ceturtā apraksts',
      HOME: 'Viss par dārzeņiem, mūsu produkciju un jaunumiem, idejas pusdienām un dažādi atklājumi. Saldēti un konservēti produkti.',
      SEARCH: {
        MAIN: '{{value}}. Atklājiet mūsu receptes un dārzeņus. Iedvesmojieties gatavot un darīt. Viegli pagatavojamas un gardas receptes. Konservēti un saldēti dārzeņi.',
        RECIPES: '{{value}}. Iedvesmojieties gatavot kopā ar Bonduelle. Vieglas un gardas receptes visiem gadījumiem. Konservēti un saldēti dārzeņi.',
        VEGETABLES: '{{value}}. Atklājiet dažādus dārzeņus un mūsu produkciju. Katrs atradīs savu iemīļoto. Saldēti un konservēti produkti.',
        ARTICLES: 'Visi mūsu {{value}} sakārtoti pēc preces. Vienkāršas un gardas receptes. Jau gatavi dārzeņi.'
      },
      CONTACT: 'Vai Jums ir jautājumi? Bet varbūt piezīmes vai ierosinājumi? Sazinieties ar mums. Saldēti un konservēti produkti.',
      RECIPES: 'Atklājiet interesantas receptes un pārsteidziet draugus un radus. Viegli un vienkārši pagatavojamas receptes, kas der ikvienam gadījumam.',
      RECIPE_HOME: 'Viegli un ātri pagatavojamas receptes Jūsu svētku vai ikdienas pusdienām. Saldēti un konservēti produkti.',
      RECIPE: 'Atklājiet interesantu recepti un pārsteidziet draugus vai radus. Garda un vienkārši pagatavojama recepte, paredzēta visiem gadījumiem.',
      PRODUCTS: 'Mūsu plašajā dārzeņu sortimentā savu iecienīto produktu atradīs ikviens. Atklājiet visas dārzeņu iespējas. Saldēti un konservēti produkti.',
      NEWS: 'Uzziniet, kā ir audzēti, uzglabāti un gatavoti dārzeņi. Visi jaunumi un atklājumi. Saldēti un konservēti produkti.',
      CORPORATE: {
        NEWS: 'Visi jaunumi par dārzeņiem, to audzēšanu un sagatavošanu. Uzziniet par mūsu apņemšanos, uzņēmuma vēsturi un vēl vairāk.',
        COMPANY_STORY: 'Uzziniet mūsu vēsturi. Mes atbalstām bioloģisko lauksaimniecību un cenšamies, lai dārzeņi kļūtu par aizvien lielāku cilvēka dzīves daļu.',
        CSR_COMMITMENTS: 'Mes atbalstām bioloģisko lauksaimniecību, orientējamies uz nākotni un katru dienu cenšamies, lai dārzeņi kļūtu par aizvien lielāku katra cilvēka dzīves daļu.',
        VEGETAL_INNOVATIONS: 'Jaunumi par dārzeņiem, kā tie ir audzēti, kopti, uzglabātu un sagatavoti. Atklājiet un uzziniet. Saldēti un konservēti produkti.',
        VEGETABLE: '',
        FIELD_PLATE: 'Viss par dārzeņiem – no zemes sagatavošanas, dārzeņu iestādīšanas, kopšanas, līdz pienācīgai pagatavošanai. Saldēti un konservēti produkti.',
        ARTICLE: ''
      },
      PRODUCT: 'Uzzini visu par {{value}}. Bonduelle produktu sortimentā katrs atradīs savu iecienītāko. Saldēti un konservēti produkti.',
      MAGAZINE: {
        NEWS: '',
        ARTICLE: '',
        COOK: '',
        GROW: '',
        EXPLORE: '',
        RECIPE: ''
      },
      FAQ: 'Visas atbildes uz biežāk uzdotajiem jautājumiem. Neatrodi atbildi uz savu jautājumu? Neskumsti un uzraksti mums! Saldēti un konservēti produkti.',
      PAGE: 'lappuse',
      RECIPETYPE: '{{value}}. Atrodiet receptes un jaunas dārzeņu pagatavošanas metodes. Atrodi recepti jebkuram gadījumam. Saldēti un konservēti produkti.',
      RECIPESEASON: '{{value}} – iedvesmojieties gatavot visos gadalaikos. Atklājiet jaunas receptes un dārzeņu gatavošanas metode. Saldēti un konservēti produkti.',
      RECIPEPREPARATION: '{{value}} – atklājiet receptes, iedvesmojieties gatavot. Vairs nelauziet galvu, ko pagatavot brokastīm vai vakariņām ar draugiem.',
      RECIPEDIFFICULTY: '{{value}} nebaidieties eksperimentēt un izmēģināt jaunas receptes. Gatavot var ikviens. Saldēti un konservēti produkti.',
      RECIPEVEGETABLE: 'Gatavojiet veselīgi, ātri un vienkārši. Iedvesmojieties un iemācieties pareizi pagatavot dārzeņus. Saldēti un konservēti produkti.',
      RECIPECOST: '{{value}}',
      RECIPEDISH: '{{value}} ēdieni jebkuram un visiem gadījumiem. Iedvesmojieties gatavot un iemācieties pareizi pagatavot dārzeņus. Saldēti un konservēti produkti.',
      RECIPEDESC: '{{value}} iedvesmojieties gatavot un atklājiet jaunas garšas. Vieglas un gardas receptes, kas der ikvienam gadījumam. Saldēti un konservēti produkti.',
      '2RECIPEFACETS': '{{value}} vieglas un vienkāršas receptes ikvienam un visiem gadījumiem. Saldēti un konservēti produkti.',
      '4RECIPEFACETS': '{{value}} receptes, kas var pārsteigt visus. Viegli pagatavojamas un gardas receptes visiem gadījumiem. Saldēti un konservēti dārzeņi.',
      PRODUCTSEASON: 'Mūsu plašais dārzeņu sortiments ļauj pagatavot gardus, viegli pagatavojamus un sabalansētus ēdienus {{value}}. Saldēti un konservēti produkti.',
      PRODUCTTECHNO: '{{value}} – produkti, kas atlasīti pēc pagatavošanas tehnoloģijas. Mūsu plašais produktu sortiments ļauj pagatavot gardus, viegli pagatavojamus un sabalansētus ēdienus.',
      PRODUCTVEGETABLE: '{{value}} – pagatavot ēdienus ar šiem dārzeņiem vēl nekad nav bijis tik vienkārši! Mūsu plašais produktu sortiments ļauj pagatavot gardus, viegli pagatavojamus un sabalansētus ēdienus.',
      PRODUCTRANGE: '{{value}} – pagatavot ēdienus ar šiem dārzeņiem vēl nekad nav bijis tik vienkārši! Mūsu plašais produktu sortiments ļauj pagatavot gardus, viegli pagatavojamus un sabalansētus ēdienus.',
      THEME_ALL: 'Mūsu plašais dārzeņu sortiments ļauj pagatavot gardas un sabalansētas receptes. Katrs atklās savu iecienīto produktu. Konservēti un saldēti produkti.',
      THEME_STARTER: 'Uzkodas – viegli pagatavojamas, gards un piemērotas ikvienam gadījumam. Vieglas un gardas receptes. Saldēti un konservēti produkti.',
      THEME_SALAD: 'Pagatavot salātus ar mūsu Bonduelle dārzeņu sortimentu vēl nekad nav bijis tik vienkārši. Vieglas un gardas receptes. Saldēti un konservēti produkti.',
      THEME_CAN: 'Dārzeņi ir pieejami visos gadalaikos. Gatavot ar šiem dārzeņiem vēl nekad nav bijis tik vienkārši. Saldēti un konservēti produkti.',
      THEME_FROZEN: 'Produkti, ar kuriem gatavot vēl nekad nav bijis tik vienkārši. Dārzeņi, kas pieejami visos gadalaikos. Saldēti un konservēti produkti.',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: 'Mūsu plašais dārzeņu sortiments ļauj pagatavot gardas, vieglas un sabalansētas receptes. Saldēti un konservēti produkti.',
      THEME_RECIPE: 'Uzziniet, cik ātri, gardi un viegli ir pagatavot dārzeņus. Iedvesmojieties gatavot un darīt. Saldēti un konservēti produkti.',
      THEME_PLATE: 'Gardi un viegli pagatavojami pamatēdieni visiem gadījumiem. Iedvesmojieties gatavot un darīt. Saldēti un konservēti produkti.',
      THEME_ACC: 'Receptes piedevu radīšanai. Viegli un vienkārši pagatavojamas receptes ikvienam. Iedvesmojieties gatavot un darīt. Saldēti un konservēti produkti.',
      THEME_SNACK: 'Ātri un viegli pagatavojamas uzkodas visiem gadījumiem. Iedvesmojieties gatavot un darīt. Saldēti un konservēti produkti.',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': 'Mūsu plašais dārzeņu sortiments ļauj pagatavot gardus, viegli pagatavojamas un sabalansētas receptes. {{value}} Iedvesmojieties gatavot un radīt.',
      NOTFOUND: 'Diemžēl lapa nav atrasta. Izmēģiniet jaunu meklēšanu vai mēģiniet vēlreiz vēlāk. Saldēti un konservēti produkti.'
    },
    PIPE: { SOCIETY: ' | Bonduelle' },
    THEME_ALL: 'Visi',
    THEME_STARTER: 'Bonduelle uzkodas',
    THEME_SALAD: 'Bonduelle salāti',
    THEME_CAN: 'Bonduelle konservēti produkti',
    THEME_FROZEN: 'Bonduelle saldētie produkti',
    THEME_READY_MEALS: '',
    THEME_BIO: '',
    THEME_VEGETABLES: 'Bonduelle dārzeņi',
    THEME_RECIPE: 'Sastāvdaļas – receptes',
    THEME_PLATE: 'Bonduelle pamatēdieni',
    THEME_ACC: 'Bonduelle piedevas',
    THEME_SNACK: 'Bonduelle uzkodas ikvienam.'
  },
  VEGETABLE: {
    DISCOVER_VEGETABLE: 'Atklāj mūsu dārzeņus',
    OURS_RECIPES: 'Mūsu receptes',
    DISCOVER_ALSO: 'Atklāj arī...',
    SEE_ALL: 'Visi dārzeņi',
    ON: 'Dārzeņi no'
  },
  RECIPE: {
    'INGREDIENTS_LABEL ': 'Sastāvdaļas {{value}} porcijām',
    PEOPLES: '{{value}} cilvēkiem',
    DIFFICULTY: { EASY: 'Viegla', MEDIUM: 'vidēji sarežģīta', HARD: 'Sarežģīta' },
    COST: { CHEAP: 'Lēta', EXPENSIVE: 'Dārga' },
    TIMING: { MIN: 'minūtes', HOURS: 'stundas' },
    STEP: 'Solis',
    DISCOVER_ALSO: 'Atklāj arī...',
    HOME_SEE_MORE: 'Vairāk recepšu',
    SHOWCASE: 'Top',
    HOME_BOOK: 'Mūsu receptes',
    MIN_MORE: 'm un +',
    HOUR_MORE: 'h un +',
    FILTER_BY_CRITERIA: 'Filtrēt pēc',
    SORT_BY: 'Šķirot pēc',
    BEST_RATED: 'Visaugstāk novērtētās',
    MOST_CONSULTED: 'Visvairāk skatītās',
    MEMBERS_RECIPES: 'Jūsu receptes',
    CRITERIA: 'Jaunumi',
    DISCOVER: { RECIPES: 'Atklāj visas mūsu receptes' },
    DISCOVER_RECIPE: 'Atklāj recepti',
    INSTRUCTIONS: 'Instrukcijas',
    DEFAULT_TAG: 'Receptes ideja',
    MOST_RECENT: 'Jaunākās',
    RECIPE_VIDEOS: 'Receptes video',
    PRODUCT_ASSOCIATE: 'Recepte pagatavota ar',
    BUDGET_LOW: 'Lēta',
    BUDGET_HIGH: 'Dārga',
    BUDGET_MIDDLE: 'Vidēja',
    PRINT_RECIPE: 'Drukāt',
    LET_YOURSELF_SURPRISED: 'Ļauj sevi pārsteigt',
    COOK_TIP_TITLE: 'Gatavošanas ieteikums',
    ENJOY_MEAL: 'Labu apetīti!',
    FILTER: { LEGEND: 'Paraksts' }
  },
  PREDIGGO: {
    DIFFICULTY: { EASY: 'Viegla', MEDIUM: 'vidēji sarežģīta', HARD: 'Sarežģīta' }
  },
  COOKIES: {
    TEXT: 'Lietojam sīkdatnes, lai nodrošinātu nevainojamu vietnes darbību, uzlabotu pārlūkošanas pieredzi, kā arī marketinga vajadzībām. Turpinot pārlūkošanu, Jūs piekrītat sīkdatņu lietošanai.',
    CLOSE: 'Aizvērt',
    MORE: 'Uzzināt vairāk',
    AGREE_YOUTUBE: 'Ja vēlaties noskatīties videoierakstu, jums ir jāpiekrīt Youtube sīkdatņu izmantošanai.',
    BUTTON_YOUTUBE: 'Piekrist sīkdatņu izmantošanai '
  },
  POPUP: { LAUNCH: 'POPUP-OUVERTURE-SITE' },
  FIELD_PLATE: { DISCOVER_VEGETABLE: 'Atklāj mūsu dārzeņus' },
  SEARCH: {
    OUR_RECIPES: 'Receptes',
    OUR_PRODUCTS: 'Produkti',
    OUR_ARTICLES: 'Raksti',
    RECIPES: 'Receptes',
    PRODUCTS: 'Produkti',
    ARTICLES: 'Raksti',
    ALL: 'Visas',
    SORT_BY: 'Šķirot pēc',
    RESULTS: 'rezultāts',
    RESULT: 'rezultāts',
    PRODUCT_OUT: 'produkti no',
    APPLY: 'Izmantot',
    FILTER: 'Filtrs',
    RECIPE_OUT: 'receptes no',
    NO_RESULT: 'Ups... Jautājums ir ļoti sarežģīts!',
    SEE_MORE: 'Apskatīt vairāk',
    DISCOVER_RECIPES: 'Atklāj mūsu receptes',
    SEE_MORE_OF: 'apskatīt vairāk',
    DELETE: 'dzēst'
  },
  RECIPE_LIST: {
    OUR_RECIPES: 'Receptes',
    RESULTS: 'Rezultāti',
    SEE_MORE: 'Apskatīt vairāk recepšu'
  },
  PRODUCT: {
    BUTTON: {
      ACTU: 'produkta jaunumi',
      FOUND: 'Kur atrodams šis produkts',
      DISCOVER: 'atklāj visu klāstu'
    },
    NUTRITIONAL_VALUE: 'uzturvērtība',
    CONDITIONING: 'Izmērs',
    PORTIONS: 'porcijas',
    NET_WEIGHT: 'neto svars',
    DRY_WEIGHT: 'neto svars bez šķidruma',
    INGREDIENTS: 'sastāvdaļas',
    OUR_RECIPES: 'Atklāj mūsu receptes',
    FOR: '',
    PER_PORTION: 'un porcijas no',
    ENERGY: 'enerģētiskā vērtība',
    FAT: 'tauki',
    SATURATE: 'piesātinātās taukskābes',
    GLUCIDES: 'ogļhidrāti',
    FIBER: 'šķiedrvielas',
    PROTEINS: 'olbaltumvielas',
    SALT: 'sāls',
    DISCOVER_ALSO: 'atklāj arī...',
    MORE_INFORMATION: 'vairāk informācijas',
    TRACES: 'Var saturēt',
    CONTAINS: 'Sastāv',
    SLIDER: { SHEET: 'produkta apraksts', BUY: 'pirkt šo produktu' },
    NUTRISCORE: 'informācija-par-uzturvērtību',
    TRIMAN: '',
    AGRICONFIANCE: '',
    BIO: 'informācija-bio',
    PESTICIDE_FREE: '',
    LEAF: '',
    LOCK: '',
    SEE_ALL: 'Apskatīt visus produktus',
    LEGISLATION: 'atbilstoši spēkā esošajiem tiesību aktiem',
    PORTION: { SINGULAR: 'porcija', PLURAL: 'porcijas' },
    DISCLAIMER: '',
    COOKING: 'pagatavošanas veids',
    CONSERVATION: 'uzglabāšanas nosacījumi'
  },
  FAQ: {
    ON: 'jautājumi',
    ALL: 'Visas',
    SEE_MORE: 'apskatīt vairāk jautājumu',
    TITLE_1: 'Visas atbildes uz jautājumiem',
    TITLE_2: 'Vai Jums ir jautājums par produktu?',
    LINK_LABEL: 'Rakstiet mums'
  },
  FORM: {
    GO_TO_STEP: 'Iet uz',
    STEP: 'Solis',
    RECEIVE_EMAIL_COPY: 'Saņemt kopiju e-pastā',
    SEND: 'Sūtīt',
    GDPR: 'Vairāk par Jūsu datu apstrādi',
    ERROR: 'Visiem ar * apzīmētajiem laukiem jābūt aizpildītiem',
    SEND_ERROR: 'Kļūda, mēģiniet pēc dažām minūtēm',
    NOTICE: 'Sazinoties ar mums, jūs dalāties ar saviem personas datiem. Jūsu personas datu pārzinis ir Bonduelle Polska S.A. ar juridisko adresi Varšavā (Warsaw), Polijā. Plašāku informāciju par jūsu personas datu apstrādi un aizsardzību varat atrast mūsu privātuma politikā.',
    SENDING: '',
    LOADING: 'Ielādē...',
    PRODUCT_INFORMATION_TITLE: 'Informācija par produktu',
    PRODUCT_INFORMATION: {
      SEO_TITLE: 'Informācija par produktu | Bonduelle',
      SEO_DESC: 'Sazinieties ar mums'
    },
    INFORMATIONS: 'JŪSU INFORMĀCIJA',
    MISTER: 'Kungs',
    MISS: 'Kundze',
    NAME: 'Uzvārds',
    OUR_NAME: 'jūsu uzvārds',
    FIRSTNAME: 'Vārds',
    OUR_FIRSTNAME: 'jūsu vārds',
    MAIL: 'jūsu e-pasts',
    MAIL_PLH: 'vards@adrese.com',
    MAIL_CONFIRM: 'apstipriniet e-pasta adresi',
    MAIL_ERROR: 'ierakstiet derīgu e-pastu',
    MAIL_ERROR_CONFIRM: 'lūdzu, apstipriniet e-pasta adresi',
    ADDRESS: 'jūsu adrese',
    STREET_PLH: '',
    STREET: 'Iela un mājas numurs',
    ADDRESS_COMPLEMENT: 'vairāk informācijas par Jūsu adresi',
    ADDRESS_COMP_PLH: 'vairāk informācijas par Jūsu adresi',
    ZIPCODE: 'pasta indekss',
    CITY: 'pilsēta',
    CITY_PLH: 'jūsu pilsēta',
    PHONE: 'tālrunis',
    PHONE_PLH: 'tālruņa numurs',
    COUNTRY: 'valsts',
    PRODUCT_INFORMATIONS: 'PRODUKTA INFORMĀCIJA',
    PRODUCT_INFORMATION_DESC: 'Lai labi izskatītu Jūsu jautājumu, aizpildiet šādu informāciju:',
    PRODUCT: {
      DETAIL: {
        DESC: 'Iesniedzot pieprasījumu, aizpildiet attiecīgos laukus un kā piemēru iesniedziet fotogrāfiju. Fotogrāfijā jābūt redzamam EAN kodam, partijas numuram un derīguma termiņam.'
      },
      NOTE: {
        TITLE: 'Komentārs par produktu',
        SEO_TITLE: 'Produkta apskats | Bonduelle',
        SEO_DESC: 'Vai vēlaties atstāt piezīmi vai komentāru par mūsu produktu? Sazinieties ar mūsu klientu informācijas dienestu.'
      }
    },
    PRODUCT_RANGE: 'Analizējamo produktu sortiments',
    PRODUCT_NAME: 'Produkta nosaukums',
    PRODUCT_NAME_PLH: 'Uzrakstiet Bonduelle produkta nosaukumu, par kuru iesniegts pieprasījums',
    PRODUCT_INFORMATIONS_DETAIL: 'VAIRĀK INFORMĀCIJAS',
    PRODUCT_INFORMATIONS_LABEL: 'Produkta svītrkods',
    PRODUCT_INFORMATIONS_PLH: 'Svītrkods sastāv no 13 cipariem, kas atrodami uz produkta iepakojuma.',
    PRODUCT_LOT: 'Produkta partijas numurs',
    PRODUCT_LOT_INFO: 'Partijas numurs sākas ar burtu L, pēc kura ir cipari, tas norādīts uz iepakojuma. Piemēram, L72450492107:13',
    PHOTO: 'Pievienojiet fotogrāfiju',
    EXPIRED_DATE: 'Derīguma termiņš',
    EXPIRED_DATE_ERROR: 'Datumu ievadiet šādi: DD/MM/GGGG',
    EXPIRED_DATE_DESC: 'Derīguma termiņš ir norādīts uz iepakojuma',
    PHOTO_MORE: 'Papildu fotogrāfijas',
    MESSAGE: 'JŪSU ZIŅOJUMS *',
    OUR_MESSAGE: 'Uzrakstiet ziņojumu',
    SALES_OPERATIONS: {
      TITLE: 'Informācija par pirkumu',
      SEO_TITLE: 'Informācija par esošajām akcijām | Bonduelle',
      SEO_DESC: 'Lai saņemtu informāciju par pirkumu, sazinieties ar mūsu klientu apkalpošanas dienestu.'
    },
    PRESS_SERVICE: '',
    FOOD_SERVICE: 'Sazinieties ar Bonduelle pārtikas komandu',
    BUSINESS_INFORMATION: '',
    MODAL_INFORMATION: '',
    JOB_OFFER: { TITLE: '', SEO_TITLE: '', SEO_DESC: '' },
    WEB_SUGGESTION: {
      TITLE: 'Iesniedziet ierosinājumu par tīmekļa vietni',
      SEO_TITLE: 'Pilnveidošanas ierosinājumi vai ieteikumi par vietni | Bonduelle',
      SEO_DESC: 'Lai palīdzētu mums pilnveidoties, sazinieties ar mūsu klientu apkalpošanas dienestu.'
    },
    ANOTHER: {
      TITLE: 'Iesniedziet ierosinājumu',
      SEO_TITLE: 'Sazinieties par citiem piedāvājumiem | Bonduelle',
      SEO_DESC: 'Lai saņemtu informāciju, ieteikumu vai piedāvājumu, sazinieties ar mūsu klientu apkalpošanas dienestu.'
    },
    AMBASSADOR: '',
    CATEGORY: {
      SALAD: 'Salāti',
      FROZEN: 'Saldēti',
      CAN: 'Konservēti',
      TAKEAWAY: '',
      VEGETABLE: ''
    }
  },
  CONTACT: {
    TITLE: 'Jūsu pakalpojumiem',
    SPECIAL_INFO: '',
    TAKE_CARE: '',
    BY_MAIL: 'Pa pastu',
    BY_PHONE: 'Pa tālruni',
    BY_SOURDLINE: '',
    MAIL_TEXT: 'baltics@bonduelle.com\n' +
      '\n' +
      'Sazinoties ar mums, jūs dalāties ar saviem personas datiem. Jūsu personas datu pārzinis ir Bonduelle Polska S.A. ar juridisko adresi Varšavā (Warsaw), Polijā. Plašāku informāciju par jūsu personas datu apstrādi un aizsardzību varat atrast mūsu privātuma politikā.',
    PHONE_TEXT: 'Informācija pa tālruni: +370 61170170\n' +
      'Mēs atbildēsim no pirmdienas līdz piektdienai no 9.00 līdz 16.00.',
    SOURDLINE_TEXT: '',
    BY_DISTRIBUTOR: '',
    BY_DISTRIBUTOR_TEXT: '',
    SUBTITLE: 'Jūs vēlaties ar mums sazināties par:',
    AGREEMENT: '',
    READ_MORE: 'Turklāt jums ir tiesības jebkurā laikā pieprasīt, lai BONDUELLE īstenotu jūsu tiesības piekļūt jūsu personas\r\n' +
      'datiem, labot tos, dzēst, īstenotu tiesības uz datu pārnesamību, kā arī īstenotu jūsu tiesības ierobežot jūsu\r\n' +
      'personas datu apstrādi un iebilst pret to. Lai īstenotu savas tiesības saistībā ar jūsu personas datiem, lūdzu,\r\n' +
      'rakstiet uz e-pastu: Dpo_france(at)bonduelle(dot)com, vai sazinieties pa pastu, rakstot uz šo adresi: datu\r\n' +
      'aizsardzības speciālistam, Bonduelle, Rue Nicolas Appert, PO Box 30173, F-59653 Villeneuve D&#39;Ascq. Lai uzzinātu\r\n' +
      'vairāk par veidu, kādā BONDUELLE vāc un izmanto jūsu personas datus, noklikšķiniet šeit\\',
    READ_MORE_LABEL: 'Lasiet arī',
    SATISFACTION_TEXT: 'Lai sazinātos ar jums, lai sniegtu jums par produktu pieprasīto informāciju\n' +
      'un, ja vēlaties, nosūtītu jums apmierinātības anketu, mēs apstrādāsim\n' +
      'sniegto informāciju kā datu pārzinis. Jūsu pieprasījuma apstrādes\n' +
      'juridiskais pamats ir piekrišana saistībā ar iespējamiem ar veselību\n' +
      'saistītiem datiem, kurus varat sniegt savā pieprasījumā. Šī informācija tiks\n' +
      'glabāta tik ilgi, cik nepieciešams, lai apstrādātu jūsu pieprasījumu, un līdz\n' +
      '5 gadiem, lai nodrošinātu pierādījumu, ka jūsu pieprasījums ir apstrādāts,\n' +
      'un lai izpildītu mūsu juridiskās saistības. Apmierinātības anketas\n' +
      'nosūtīšanas juridiskais pamats ir arī jūsu piekrišana. Šī informācija tiks\n' +
      'glabāta tik ilgi, cik nepieciešams apmierinātības anketas nosūtīšanai, bet\n' +
      'ne ilgāk kā 1 gadu. Lai uzzinātu vairāk par savu personas datu apstrādi,\n' +
      'lūdzu, iepazīstieties ar mūsu <a href="https://www.bonduelle.lv/privatuma-politika" target="_blank" class="underline">Privātuma politiku</a>.',
    SATISFACTION_CHECKBOX: 'Atzīmējot šo izvēles rūtiņu, jūs piekrītat saņemt apmierinātības anketu\n' +
      '(kas sastāv no viena jautājuma), kad jūsu pieprasījums ir apstrādāts. Tas\n' +
      'nav obligāti.',
    CHECKBOX_TEXT: 'Atzīmējot šo izvēles rūtiņu, jūs piekrītat, ka Bonduelle var apstrādāt\n' +
      'personas veselības datus, ko varat sniegt savā ziņojumā, tikai un vienīgi, lai\n' +
      'atbildētu uz jūsu pieprasījumu. <strong>Tas ir obligāti, lai apstrādātu jūsu\n' +
      'pieprasījumu</strong>.',
    PHONE: 'Atbildēsim no pirmdienas līdz piektdienai no 9.00 līdz 16.00. Zvanam netiek piemērota papildu maksa.',
    CALL_US: 'Zvanīt mums',
    TO_WRITE: 'Rakstīt mums',
    PHONE_NUMBER: '37061170170',
    SUCCESS_MESSAGE: 'Paldies! Jūsu ziņa ir izsūtīta mums.',
    PHOTOERROR: { MESSAGE: '' }
  },
  GLOBAL: { READ_MORE: '' },
  FLOWBOX: { GENERIC_KEY: '', PRODUCT_KEY: '', RECIPE_KEY: '', HOME_KEY: '' },
  TOKEN: {
    FACEBOOK: '',
    PAGE_FACEBOOK_ID: '',
    INSTAGRAM: '',
    INSTA_ID: '17841405863491030',
    YOUTUBE: '',
    TWITTER: '',
    MAGAZINE: ''
  },
  COOKIE: {
    BANNER: {
      MANDATORY: 'Obligātās ',
      ANALYTICS: 'Funkcionālās',
      MARKETING: 'Tirgvedības',
      SOCIAL_MEDIA: 'Sociālās',
      ACCEPT: 'Apstiprināt visas sīkdatnes',
      REJECT: 'Nepiekrist',
      SET_UP: 'Mainīt sīkdatņu iestatījumus',
      MANDATORY_DETAILS: 'Obligātās sīkdatnes ir nepieciešamas pienācīgai vietnes darbībai. Šīs sīkdatnes tiek izmantotas statistiskās analīzes un vietnes apmeklētāju plūsmas pārvaldības nolūkiem, piemēram, lai palielinātu interneta vietnes lietošanas ērtumu un pakalpojumu aktualitāti, analizētu informāciju par to, kā apmeklētāji izmanto vietni – pēc apmeklētajām lapām, apmeklētāju skaita un tā tālāk. Arī „Google Analytics” lieto šīs sīkdatnes, kas ļauj novērot apmeklētākās lapas un vietnes plūsmu.',
      ANALYTICS_DETAILS: 'Funkcionālās sīkdatnes tiek izmantotas tehnisku iemeslu dēļ, piemēram, lai identificētu seansus, atcerētos iestatījumus, paradumus, sekotu līdzi Jūsu ievadītajai informācijai, kad pildāt interneta veidlapas u.tml. ',
      MARKETING_DETAILS: 'Tirgvedības sīkdatnes ļauj mums novērt Jūsu recepšu un produktu vēlmes, lai varētu piedāvāt Jums produktus, kas vislabāk atbilst Jūsu vēlmēm. Tās tiek izmantotas arī, lai sniegtu informāciju reklāmdevējiem citās interneta vietnēs, lai varētu piedāvāt Jums aktuālus reklāmas ziņojumus, kas atbilst Jūsu interesēm.\r\n',
      V2: 'ok'
    },
    MODAL: {
      TITLE: 'Sīkdatņu iestatījumi',
      TEXT: 'BONDUELLE grupa apņemas nodrošināt savu interneta vietņu, īpaši Bonduelle.lv, lietotāju personas datu aizsardzību. Lai varētu izmantot vietnes piedāvātos pakalpojumus, tādus kā recepšu un produktu meklēšana, vietnē tiek izmantotas sīkdatnes. BONDUELLE veic pienācīgus tehniskos un organizatoriskos pasākumus, kas palīdz efektīvi īstenot personas datu aizsardzības principus, ievērot VDAR prasības un aizsargāt datu subjektu tiesības un brīvības no paša datu vākšanas sākuma un datu apstrādes laikā. Jums ir tiesības nepiekrist Jūsu personas datu apstrādei, izmantojot sīkdatnes. ',
      CONFIG: 'Mainīt',
      SAVE: 'Saglabāt'
    }
  },
  LINK: { JOB_OFFERS: '' },
  CLIC2BUY: { INFOS: '' }
};
