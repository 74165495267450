import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SfFormTextFieldComponent } from './components/text-field/text-field.component';
import { SfFormSectionComponent } from './components/form-section.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SfFormTextFieldConfirmComponent } from './components/text-field-confirm/text-field-confirm.component';
import { SfHintComponent } from './components/hint/hint.component';
import { AngularMaterialModule } from '@app/shared/angular-material/angular-material.module';
import { SfPopoverComponent } from './components/hint/popover.component';
import { SfPopoverService } from './services/popover.service';
import { OverlayModule } from '@angular/cdk/overlay';
import { SfFormRadioComponent } from './components/radio/radio.component';
import { SfFormSelectComponent } from './components/select/select.component';
import { SfFormImageComponent } from './components/image/image.component';
import { SfImagePopoverComponent } from './components/image/image-popover.component';
import { SfFormTextAreaComponent } from './components/text-area/text-area.component';
import { SfFormImageMultiComponent } from './components/image-multi/image-multi.component';
import { SfLabelEmptyPipe } from './pipes/label-empty.pipe';
import { WidgetModule } from '@app/shared/widget/widget.module';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        WidgetModule,
        ReactiveFormsModule,
        AngularMaterialModule,
        OverlayModule
    ],
    exports: [
        SfFormSectionComponent,
        SfFormTextFieldComponent,
        SfFormTextFieldConfirmComponent,
        SfHintComponent,
        SfPopoverComponent,
        SfFormRadioComponent,
        SfFormSelectComponent,
        SfFormImageComponent
    ],
    declarations: [
        SfFormSectionComponent,
        SfFormTextFieldComponent,
        SfFormTextFieldConfirmComponent,
        SfHintComponent,
        SfPopoverComponent,
        SfFormRadioComponent,
        SfFormSelectComponent,
        SfFormImageComponent,
        SfFormImageMultiComponent,
        SfImagePopoverComponent,
        SfFormTextAreaComponent,
        SfLabelEmptyPipe
    ],
    providers: [
        SfPopoverService
    ]
})
export class SfFormModule {}
