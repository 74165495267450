
<div class="sf-form-tfc-container" *ngIf="data && formGroup" [formGroup]="formGroup">
  <!-- TextField -->
  <div class="sf-form-tfc-container-field">
    <label class="sf-form-tfc-label sf-title" [for]="controlName" *ngIf="data.label" [ngClass]="{'error-color': !formGroup.pristine && formGroup.invalid}">
      {{ data.label | translate }}
      <span *ngIf="data.required"> *</span>
    </label>
    <input class="sf-form-tfc-input sf-secondary-font-color-placeholder" [placeholder]="data.placeholder || '' | translate" [id]="controlName" [formControlName]="controlName" type="text" [autocomplete]="data.autocomplete ? data.autocomplete : 'off'">

    <!-- Error -->
    <div class="sf-form-tfc-error sf-error-color sf-title" *ngIf="!formGroup.get(controlName).pristine && formGroup.get(controlName).invalid">
      {{ data.error || 'SF.FORM.ERROR' | translate }}
    </div>
  </div>

  <!-- Confirm -->
  <div class="sf-form-tfc-container-confirm">
    <label class="sf-form-tfc-label sf-title" [for]="controlName + 'C'" *ngIf="data.label" [ngClass]="{'error-color': !formGroup.pristine && formGroup.invalid}">
      {{ data.confirm || data.label | translate }}
      <span *ngIf="data.required"> *</span>
    </label>
    <input class="sf-form-tfc-input sf-secondary-font-color-placeholder" [placeholder]="data.placeholder || '' | translate" [id]="controlName + 'C'" [formControlName]="controlName + 'C'" type="text" [autocomplete]="data.autocomplete ? data.autocomplete : 'off'">

    <!-- Confirm Error -->
    <div class="sf-form-tfc-error sf-error-color sf-title" *ngIf="!formGroup.get(controlName + 'C').pristine && formGroup.invalid">
      {{ data.errorConfirm || 'SF.FORM.ERROR' | translate }}
    </div>
  </div>

</div>

