/**
 * Abstract base model.
 * All models fetched with NetworkService's methods should
 * extend this class.
 */
export class SfModel {

  /**
   * Defines the type of model
   * @memberof SfModel
   */
  sfType?: string;

}
