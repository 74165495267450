import { SfModel } from '../networking/models/model';

export class SfSocialNetwork extends SfModel {

  /** type */
  type: string;

  like?: number;

  comment?: number;

  share?: number;

  description?: string;

  link?: string;
}
