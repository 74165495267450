import { Injectable, ErrorHandler } from '@angular/core';
import { LoggerService } from '@app/core/logger/services/logger.service';
import { SfError } from '@app/sf-lib/public_api';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService extends ErrorHandler {
  constructor(private logger: LoggerService) {
    super()
  }
  handleError(error) {
    super.handleError(error)
    if (typeof error === 'string' && error.indexOf('icon') === -1) {
      this.logger.error('ERROR', error)
    } else if (typeof error === 'object' && error.message && error.message.indexOf('icon') === -1) {
      this.logger.error('ERROR', error.message)
    } else if (error instanceof SfError && error.message.indexOf('icon') === -1) {
      this.logger.error('ERROR', origin.slice(45), error.message )
    } else if (error.message.indexOf('icon') === -1) {
      this.logger.error('ERROR', JSON.stringify(error))
    }
  }
}
