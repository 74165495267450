import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RequestInterceptor } from './interceptors/request.interceptor';
import { RequestService } from './services/request.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: []
})
export class RequestModule {
  static forRoot(): ModuleWithProviders<RequestModule> {
    return {
      ngModule: RequestModule,
      providers: [
        RequestService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: RequestInterceptor,
          multi: true,
        }
      ],
    };
  }
}
