import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SfHeadlineComponent } from './components/headline.component';
import { AngularMaterialModule } from '@app/shared/angular-material/angular-material.module';

@NgModule({
  imports: [
    CommonModule,
    AngularMaterialModule,
  ],
  declarations: [
    SfHeadlineComponent
  ],
  exports: [
    SfHeadlineComponent
  ]
})
export class SfHeadlineModule { }
