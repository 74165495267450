import {
  SfMediaImage, SfItem, SfLink,
  SfLocalizeService,
  SfSection, SfBanner
} from '@app/sf-lib/public_api';
import { SomPage } from './page.model';
import { AppInjector } from '../app-injector';

export class SomVegetablePage extends SomPage implements SfItem, SfBanner {

  /** Unique ID from server */
  nid: number;

  /** Duplicate ID. When a node is duplicated we should show this ID in url  */
  duplication_id: number;

  /** Type from server */
  type: string;

  /** Title */
  title: string;

  /** Path alias information */
  path: {
    alias: string;
  };

  /** Main image of the article */
  cover: SfMediaImage;

  /** Main image of the article for mobile */
  smallcover: SfMediaImage;

  name: string;

  sections: SfSection[];

  get link(): SfLink {
    let alias = this.path.alias;
    return {
      is_external: false,
      label: '',
      url: `${alias}/${this.nid}/${this.duplication_id}`
    };
  }
  set link(value: SfLink) {
    return;
  }

  /** SfBanner uppertitle */
  get uppertitle(): string {
    return this.name;
  }

  /** SfBanner background */
  get background(): SfMediaImage {
    return this.cover;
  }

  /** SfBanner smallbackground */
  get smallbackground(): SfMediaImage {
    return this.smallcover;
  }

  private readonly baseUrl = '/since-1853/field-plate';

  private localize: SfLocalizeService;

  constructor() {
    super();
    try {
      const injector = AppInjector.getInjector();
      this.localize = injector.get(SfLocalizeService);
    } catch (e) {
      console.error('Failed initializing model', e);
    }
  }

  /**
   * Custom function used by JSON.stringify
   * This prevent JSON.stringify to throw an error on circular
   * references
   *
   * @param {string} nm Name of the property
   * @returns {string}
   * @memberof SomRecipePage
   */
  public toJSON(nm: string) {
    const newVegetable = {
      nid: this.nid,
      duplication_id: this.duplication_id,
      type: this.type,
      name: this.name,
      title: this.title,
      cover: this.cover,
      sections: this.sections,
      path: this.path,
      link: this.link,
      uppertitle: this.uppertitle,
      background: this.background,

      lang: this.lang,
      content: this.content,
      meta_title: this.meta_title,
      meta_description: this.meta_description,
      meta_keywords: this.meta_keywords
    };
    return newVegetable;
  }
}
