import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter, ChangeDetectionStrategy, Inject } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, flatMap, tap } from 'rxjs/operators';
import { SfAutocompleteSelectedEvent } from './autocomplete';
import { SfMessageService } from '../../../core/message/services/message.service';
import { SITE_INFO, SfSiteInfo } from '../../../core/localize/models/site-info.model';

@Component({
    selector: 'sf-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.sass'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SfSearchBarComponent implements OnInit {

  @Output() keyWord = new EventEmitter();

  @Input() searchQuery?: Observable<string>;

  @Input() prediggoTerms: ((terms: string) => string[] | Observable<string[]>);

  @Input() topBar: Boolean = false;

  public searchCtrl: UntypedFormControl = new UntypedFormControl('');

  public keywordRecommandation: string[];

  public previousValue: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public eventService: SfMessageService,
    @Inject(SITE_INFO) public siteInfo: SfSiteInfo,
  ) {
    this.searchCtrl = this.formBuilder.control('', [Validators.required]);
    this.searchCtrl.valueChanges.pipe(
      tap(() => {
        this.searchCtrl.markAsUntouched();
      }),
      debounceTime(200),
      distinctUntilChanged(),
      filter(term => {
        if (this.previousValue) {
          this.previousValue = null;
          return false;
        }
        if (term && term.length <= 2) {
          this.keywordRecommandation = null;
          return false;
        } else {
          return true;
        }
      }),
      flatMap((term: string) => {
        if (this.prediggoTerms) {
          const call: string[] | Observable<string[]> = this.prediggoTerms(term);
          if (call instanceof Observable) {
            return call;
          } else {
            return of(call);
          }
        } else {
          return of(null);
        }
      })
    )
    .subscribe(
      (reco: string[]) => {
        if (reco) {
          this.keywordRecommandation = reco;
        } else {
          this.keywordRecommandation = null;
        }
      }
    );
  }

  ngOnInit() {
    if (this.searchQuery) {
      this.searchQuery.subscribe(
        (query) => {
          this.previousValue = query;
          this.searchCtrl.setValue(query);
        }
      );
    }
  }

  // when the user validate his search
  public search(term?: SfAutocompleteSelectedEvent) {
    const s = term && term.option ? term.option.value : this.searchCtrl.value;
    if (s) {
      this.keyWord.emit(s);
      if (!this.topBar) {
      this.searchCtrl.markAsTouched();
      } else {
        this.reset();
      }
    }
  }

  public reset() {
    this.searchCtrl.reset('');
  }
}
