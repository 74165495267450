import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'checkYoutubeURL',
    standalone: false
})
export class SfCheckYoutubeUrlPipe implements PipeTransform {

  /**
   * Transform
   */
  transform(url: string): string {
    let checkedURL = '';
    if (url) {
      let id = '';
      url = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/) as any;
      if (url[2] !== undefined) {
        id = url[2].split(/[^0-9a-z_\-]/i)[0];
      } else {
        id = url;
      }
      if (!id) {
        checkedURL = null;
      } else {
        checkedURL = `https://www.youtube.com/embed/${id}?rel=0`;
      }
    }

    return checkedURL;
  }

}
