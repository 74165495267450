import { Component, Input, ElementRef, AfterViewChecked, HostListener, PLATFORM_ID, Inject, ChangeDetectorRef } from '@angular/core';
import { SlideAnimations } from './slide.animations';
import { isPlatformBrowser } from '@angular/common';
import { OPTIONS, SfOptions } from '../../../../core/interfaces/options.interface';
import { SITE_INFO, SfSiteInfo } from '../../../../core/localize/models/site-info.model';
import { SfSlide } from '../../models/slide.interface';
import { SfLink } from '../../../link/models/link.interface';
import { SfLocalizeService } from '../../../../core/localize/services/localize.service';
import { SfMessageService } from '../../../../core/message/services/message.service';
import { AccessCheckerService } from '@app/core/services/access-checker.service';

@Component({
    selector: 'sf-slide',
    templateUrl: './slide.component.html',
    styleUrls: ['./slide.component.sass'],
    animations: [
        SlideAnimations.parent,
        SlideAnimations.left,
        SlideAnimations.right
    ],
    standalone: false
})
export class SfSlideComponent implements AfterViewChecked {

  @Input() slide: SfSlide;

  @Input() state: any;

  @Input() mode: 'objective' | 'subjective' = 'objective';

  @Input() seoUpperTitle: 'H1' | 'H2' | 'H3' | 'DIV' = 'DIV';
  @Input() seoTitle: 'H1' | 'H2' | 'H3' | 'DIV' = 'DIV';

  @Input() isPinterestVisible = false

  public elementWidth: number;
  //public smallDevice = false;
  public size: string;

  constructor(
    private el: ElementRef,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(OPTIONS) public options: SfOptions,
    @Inject(SITE_INFO) private siteInfo: SfSiteInfo,
    public eventService: SfMessageService,
    private localize: SfLocalizeService,
    private cdRef: ChangeDetectorRef,
  ) {}


  ngAfterViewChecked() {
    this.updateWidth();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateWidth();
  }

  private updateWidth() {
    /*if (isPlatformBrowser(this.platformId)) {
      this.elementWidth = window.innerWidth;
      if (this.elementWidth >= 550 && this.smallDevice) {
        setTimeout(() => { this.smallDevice = false; });
      } else if (this.elementWidth < 550 && !this.smallDevice) {
        setTimeout(() => { this.smallDevice = true; });
      }
    }*/
    try {
      const width = window.innerWidth;
      this.size = width > 1024 ? 'MD' : width > 550 ? 'SM' : 'XS';
      this.cdRef.detectChanges();
    } catch (e) {}
  }

  public getAnimationState(): any {
    if (!this.state) { return this.state; }
    if (!this.state.params) {
      this.state.params = {};
    }
    if (this.elementWidth >= 1024) {
      this.state.params.min = -130;
      this.state.params.moy = -30;
      this.state.params.max = 70;
    } else if (this.elementWidth >= 768) {
      this.state.params.min = -115;
      this.state.params.moy = -15;
      this.state.params.max = 85;
    } else {
      this.state.params.min = -120;
      this.state.params.moy = 0;
      this.state.params.max = 120;
    }

    return this.state;
  }

  get url(): string {
    if (this.slide && this.slide.recipeLink) {
      return this.siteInfo.origin + this.slide.recipeLink.url;
    }
  }

  get productUrl(): SfLink {
    if (this.slide && this.slide.packshot && this.slide.gtin) {
      if (this.slide.packshot.alt_image) {
        const newLink: SfLink = {
          // TODO: the back will return the name of the product and add it for the url link
          url: `${this.localize.instant('vegetables/p')}/${this.slide.gtin}/${this.slide.gtin}`,
          is_external: false
        };
        return newLink;
      } else {
        const newLink: SfLink = {
          url: `${this.localize.instant('vegetables/p')}/${this.slide.gtin}/${this.slide.gtin}`,
          is_external: false
        };
        return newLink;
      }
    }
  }

  get urlImage(): string {
    if (this.slide && this.slide.background) {
      if (this.slide.background.image) {
        if (this.slide.background.image.uri && typeof this.slide.background.image.uri.original === 'string') {
          if (this.slide.background.image.uri.original.startsWith('http')) {
            return this.slide.background.image.uri.original;
          } else {
            return `${this.options.apiUrl}${this.slide.background.image.uri.original}`;
          }
        } else {
          return '';
        }
      }
      return '';
    }
  }

  public navigate(url: string) {
    if (isPlatformBrowser(this.platformId)) {
      window.open(url, '_blank');
    }
  }
}
