import { TranslatableRoute } from './core/interfaces/translatable-route';

export const routes: TranslatableRoute[] = [
  {
    path: 'magazine',
    translateKey: 'ROUTES.magazine',
    loadChildren: () => import('./sites/magazine/magazine.app.module').then(m => m.MagazinAppModule),
  },
  {
    path: '',
    loadChildren: () => import('./sites/main/main.app.module').then(m => m.MainAppModule),
  },
  { path: '**', redirectTo: '' }

];


