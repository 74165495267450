import { SfModel } from '@app/sf-lib/public_api';


export class RequestPostSettings extends SfModel {

  /**
   * Action to perform
   * @memberof RequestPostSettings
   */
  public Action: 'add' | 'edit' | 'delete';

  /**
   * Table to perform action on
   * @memberof RequestPostSettings
   */
  public TableXmlTag: string;

  /**
   * Filter data to update (doesn't apply on 'add' actions)
   * @memberof RequestPostSettings
   */
  public Filter?: string;

  constructor(data?: any) {
    super();
    if (data) {
      this.Action = data.Action || 'add';
      this.TableXmlTag = data.TableXmlTag || '';
      this.Filter = data.Filter || '';
    } else {
      this.Action = 'add';
      this.TableXmlTag = '';
      this.Filter = '';
    }
  }
}
