<div *ngIf="section" [class]="'responsive-table --length-' + (section.nb_columns ? section.nb_columns : 3)">
  <ng-container *ngFor="let section of section.sections; index as i" [ngSwitch]="section.sfType">

    <!-- Section Text -->
    <div *ngSwitchCase="'SfSectionText'">
      <responsive-text [section]="section"></responsive-text>
    </div>

    <!-- Section Image -->
    <div *ngSwitchCase="'SfSectionImage'">
      <responsive-image [section]="section"></responsive-image>
    </div>

    <!-- Section Video -->
    <div *ngSwitchCase="'SfSectionVideo'">
      <som-agree-consent [consentVendor]="'c:youtube-r6L8RmVK'">
        <sf-section-video view [section]="section"></sf-section-video>
      </som-agree-consent>
    </div>

  </ng-container>
</div>
