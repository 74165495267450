import { Component, Input } from '@angular/core';
import { VideoSlideAnimations } from './video-slide.animations';
import { SfVideoSlide } from '../../models/video-slide.interface';

@Component({
    selector: 'sf-video-slide',
    templateUrl: './video-slide.component.html',
    styleUrls: ['./video-slide.component.sass'],
    animations: [
        VideoSlideAnimations.parent
    ],
    standalone: false
})
export class SfVideoSlideComponent {

  @Input() slide: SfVideoSlide;

  @Input() state: any;

  @Input() ratio: 'square' | 'wide' = 'wide';

  constructor(
  ) { }
}
