<ng-container *ngIf="section">

  <!-- Title -->
  <ng-container *ngIf="section.title" [ngSwitch]="seoTitle">
    <h2 *ngSwitchCase="'H1'" class="som-section-text-title sf-title">{{section.title}}</h2>
    <h2 *ngSwitchCase="'H2'" class="som-section-text-title sf-title">{{section.title}}</h2>
    <h3 *ngSwitchCase="'H3'" class="som-section-text-title sf-title">{{section.title}}</h3>
    <div *ngSwitchDefault class="som-section-text-title sf-title">{{section.title}}</div>
  </ng-container>

  <!-- Text -->
  <div [innerHTML]="section.text ? (section.text | safePipe) : ''" class="som-section-text-text sf-text"></div>

</ng-container>
