import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SfSliderGridComponent } from './components/slider-grid/slider-grid.component';
import { SfSlideGridComponent } from './components/slide-grid/slide-grid.component';
import { SfItemModule } from '../item/item.module';
import { BreadcrumbGridComponent } from './components/breadcrumb-grid/breadcrumb-grid.component';
import { SliderGridService } from './services/slider-grid.service';

@NgModule({
  imports: [
    CommonModule,
    SfItemModule
  ],
  providers: [
    SliderGridService
  ],
  declarations: [SfSliderGridComponent, SfSlideGridComponent, BreadcrumbGridComponent],
  exports: [SfSliderGridComponent, SfSlideGridComponent]
})
export class SfSliderGridModule { }
