import {Component, Inject, Input} from '@angular/core';

import { OPTIONS, SfOptions } from '@app/sf-lib/lib/core/interfaces/options.interface';
import { SeparatorMaxiModel } from './separator-maxi.model';

@Component({
    selector: 'separator-maxi',
    templateUrl: './separator-maxi.component.html',
    styleUrls: ['./separator-maxi.component.sass'],
    standalone: false
})
export class SeparatorMaxiComponent {

  @Input() section: SeparatorMaxiModel;

  constructor(
    @Inject(OPTIONS) public options: SfOptions,
  ) {
  }

}
