import { SfMessage } from './message.model';

/**
 * Defines an SfError object. <br/>
 * <p> An SfError has in all cases a code and a message. <p/>
 * <p> An SfError may be retryable.
 *  If it is then it must have a retry action which is a function </p>
 */
export class SfError extends SfMessage {
    /**
     * Code defined in @link SfErrorEnum
     */
    code: number;

    /**
     * The error origin, if any. A url for exemple
     * @memberof SfError
     */
    origin?: string;

    /**
     * Additional information about the error
     */
    additionalInfo: string;

    /**
     * Whether the SfError should permit to retry the failed action
     */
    shouldRetryOnFailure: boolean;

    /**
     * Retry action label of the button
     */
    retryLabel: string;

    /**
     * The action (function) to replay
     */
    retryAction: () => void;

    /**
     * Creates an instance of SfError.
     */
    constructor(sCode: number,
                sMessage: string,
                sIsFailure: boolean = false,
                sShouldRetryOnFailure: boolean = false) {
      super(sMessage);
      this.code = sCode;
      this.additionalInfo = '';
      this.shouldRetryOnFailure = sShouldRetryOnFailure;
    }

}
