<ng-container *ngIf="!agreeVendor">
  <div class='som-agree-consent-agree'>
    <div class='som-agree-consent-text'>
      <h4 class='som-agree-consent-link'[innerHTML]="message | translate"></h4>
      <button (click)="validVendor()" class="sf-button">
        {{ 'COOKIES.BUTTON_YOUTUBE' | translate }}
      </button>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="agreeVendor">
  <ng-content select="[view]"></ng-content>
</ng-container>
