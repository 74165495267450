/* tslint:disable */export const it_it = {
  '404': {
    TITLE: 'Ops...',
    PAGENOTFOUND: 'Questa pagina non esiste!',
    BUTTON: 'Torna alla pagina iniziale',
    RECIPES: 'Scopri le nostre ricette'
  },
  HOME: {
    SHOWCASE: 'Ricette',
    CATCH_PHRASE: 'IL MONDO CHE CI PIACE.',
    SHOWCASE_SEE_ALL: 'Scopri tutte le ricette ',
    MAGAZINE: { TITLE: 'In prima pagina ', BUTTON: '' },
    OUR_VEGETABLES: 'Le nostre verdure ',
    SOCIAL_WALL: 'Seguici anche su Instagram!',
    FAQ_TITLE: 'Domande frequenti',
    FAQ_SEE_MORE: 'Vedi di più',
    FLOWBOX: ''
  },
  SELECT: { DEFAULT_VALUE: 'Selezione Bonduelle' },
  MENU: {
    CONTACT_US: 'Contattaci',
    MY_ACCOUNT: 'Il mio profilo ',
    GOOD_DEALS: 'Promozioni ',
    NEWSLETTER: 'Newsletter Bonduelle'
  },
  FOOTER: {
    LINK: {
      BONDUELLE: 'Bonduelle.it',
      CREDITS: 'Crediti',
      LEGAL_NOTICE: 'Note legali ',
      TERMS_OF_SALES: 'Condizioni di vendita ',
      COOKIES: 'Cookies ',
      SITEMAP: 'Mappa del sito',
      PRIVACY_POLICY: 'Informativa privacy',
      ALL_RIGHTS_RESERVED: '2020© TUTTI I DIRITTI RISERVATI'
    },
    HISTORY: {
      TITLE: 'Bonduelle, dal 1853',
      TEXT: "Bonduelle è un'azienda a conduzione familiare che lavora da 7 generazioni per sviluppare una produzione agricola che rispetti il territorio e le persone. Sosteniamo una agricoltura ecologica efficiente, intelligente e solidale, fortemente orientata al futuro e innoviamo ogni giorno dal campo al piatto per creare un futuro migliore attraverso le verdure."
    },
    NEWSLETTER: {
      TITLE: 'Bonduelle Community',
      TEXT: 'Accetto di ricevere la newsletter " Tutto sulle verdure di Bonduelle " (novità, promozioni, concorsi, articoli....)',
      PLACEHOLDER: 'La tua email',
      GDPR_LINK: 'Per saperne di più sulla gestione dei dati'
    }
  },
  PRODUCTS: 'Prodotti',
  MAGAZINE: {
    HOME: 'Benvenuti sul magazine  (nome magazine?)',
    CATCH_PHRASE: '',
    MENU: { BONDUELLE_LINK: 'Vai su bonduelle.it' },
    SHOWCASE: 'In vetrina',
    PREVIOUS_ARTICLES: 'Articoli precedenti ',
    PREVIOUS_ARTICLES_SEE_ALL: 'Guarda tutti i nostri articoli ',
    PREVIOUS_EDITIONS: 'Le nostre edizioni precedenti',
    PREVIOUS_EDITIONS_SEE_ALL: 'Consulta altre edizioni ',
    PREVIOUS_ARTICLES_BY_CATEGORY: 'Articoli precedenti per categoria ',
    RUBRIC: {
      SEE_ALL: 'Scopri tutte le ricette ',
      SEE_ALL_ARTICLES: 'Guarda tutti gli articoli ',
      ON: 'Articoli su'
    },
    RUBRIC_COOK: { ON: 'Ricette di ' },
    ARTICLE: { JOIN_US: 'Unisciti a noi', READ_ALSO: 'Leggi anche ' },
    RECIPE: {
      INGREDIENTS: 'Ingredienti ',
      INGREDIENTS_LABEL_DESC: 'per {{value}} persone',
      INGREDIENTS_LABEL_ONE_DESC: 'per {{value}} persona',
      INGREDIENTS_LABEL: 'Ingredienti per {{value}} persone',
      INGREDIENTS_LABEL_ONE: 'Ingredienti per {{value}} persona',
      PEOPLES: '{{value}} persone',
      PEOPLE: '{{value}} persona',
      DIFFICULTY: {
        EASY: 'facili',
        MEDIUM: 'di media difficoltà',
        HARD: 'difficili'
      },
      COST: { CHEAP: 'Economico', EXPENSIVE: 'Costoso' },
      TIMING: { MIN: 'min', HOURS: 'ore' },
      STEP: 'Step',
      DISCOVER_ALSO: 'Scopri anche...'
    }
  },
  ARTICLE: { DISCOVER_ALSO: 'Scopri anche...', INGREDIENTS: 'Ingredienti' },
  SF: {
    SLIDE: { DISCOVER: 'Scoprire' },
    SEARCH: { PLACEHOLDER: 'Ricercare', ALL: 'Tutto' },
    NEWSLETTER: {
      BTN_LABEL: 'Mi sto iscrivendo',
      PLACEHOLDER: 'La tua email',
      ERROR_MESSAGE: 'Email non valida',
      ALREADY_SUBSCRIBED: 'Sei già iscritto',
      GDPR: {
        TXT: 'Per saperne di più sulla gestione dei dati',
        LINK: '/privacy-policy'
      }
    },
    MONTHLY_FOLDER: { TITLE: 'Notizie del mese' },
    MINI_NEWSLETTER: {
      PLACEHOLDER: 'La tua email',
      GDPR: {
        TXT: 'Per saperne di più sulla gestione dei dati',
        LINK: '/privacy-policy'
      }
    },
    SOCIALITEM: { SHARE: 'condividi', COMMENT: 'commenta', BONDUELLE: 'Bonduelle' },
    SEARCHRECIPE: { DISCOVER_ALL: 'Cerca tutte le ricette!' },
    FORM: {
      ERROR: 'Campo non valido',
      PHOTO: {
        ADD: "Aggiungere un'immagine",
        DELETE: "Cancellare l'immagine",
        SEE: 'Vedi'
      }
    }
  },
  CORPORATE: {
    COMPANY_STORY: 'Il Gruppo',
    CSR_COMMITMENT: 'I nostri impegni',
    VEGETABLE_INNOVATIONS: 'LA NOSTRA FILIERA',
    NEWS: 'tutte le notizie',
    SEE_MORE: 'Vedi altri articoli',
    ON: 'articoli su'
  },
  NEWSLETTER: {
    TITLE: 'Iscriviti alla newsletter',
    SUBTITLE: 'Promozioni, ispirazioni culinarie e tante curiosità!'
  },
  ROUTES: {
    products: 'prodotti',
    news: 'novita',
    'since-1853': 'azienda',
    'field-plate': 'abc-verdure',
    'company-story': 'il-gruppo',
    'csr-commitments': 'bonduelle-si-impegna',
    'vegetal-innovations': 'la-nostra-filiera',
    magazine: '',
    all: 'tutto',
    article: 'articolo',
    cook: 'cucinare',
    cultivate: 'coltivare',
    explore: 'esplorare',
    recipe: 'attendere-ricetta',
    recipes: 'ricette',
    'recipes-home': 'ricette-di-cucina',
    r: 'r',
    credits: 'crediti',
    'legal-notice': 'note-legali',
    'terms-of-sales': 'condizioni-di-vendita',
    cookies: 'privacy-policy',
    sitemap: 'mappa-del-sito',
    'privacy-policy': 'privacy-policy',
    'all-rights-reserved': 'tutti-i-diritti-riservati',
    search: 'ricerca',
    'articles-search': 'articolo',
    vegetables: 'prodotti',
    p: 'p',
    contact: 'contatto',
    'info-product': 'Informazioni-prodotto',
    'note-product': 'nota-prodotto',
    suggestion: 'suggerimento',
    'other-request': 'altre-richieste',
    faq: 'faq',
    'all-themes': 'tutti-temi',
    'products-search': 'ricerca-prodotti',
    recipesearch: 'ricerca-ricetta',
    'product-news': 'prodotti-in-evidenza',
    'good-deals': 'promozioni',
    'not-found': 'non-trovato',
    'menu-page': 'veggie-passion',
    'menu-one': 'veggie-passion',
    'menu-two': 'alimentazione-curiosita',
    'menu-three': 'l-abc-delle-verdure',
    'menu-four': '',
    'menu-external': 'azienda/abc-verdure',
    'theme-pasta-rice': '',
    'theme-bowls': '',
    'theme-soup': '',
    'theme-snack': '',
    'theme-sauce': '',
    'theme-unique-plate': '',
    'theme-over': '',
    'theme-legumin': 'legumin',
    'theme-ingredients-recipes': '',
    'theme-cook-plate': ''
  },
  BREAD: {
    products: 'prodotti',
    news: 'novita',
    'since-1853': 'azienda',
    'field-plate': "l'abc delle verdure",
    'company-story': 'il-gruppo',
    'csr-commitments': 'bonduelle-si-impegna',
    'vegetal-innovations': 'la-nostra-filiera',
    magazine: '',
    all: 'tutto',
    article: 'articolo',
    cook: 'cucinare',
    cultivate: 'coltivare',
    explore: 'esplorare',
    recipe: 'attendere-ricetta',
    recipes: 'ricette',
    'recipes-home': 'ricette-di-cucina',
    'articles-search': 'articolo',
    search: 'ricerca',
    vegetables: 'prodotti',
    contact: 'contatto',
    'info-product': 'Informazioni-prodotto',
    'note-product': 'nota-prodotto',
    suggestion: 'suggerimento',
    'other-request': 'altre-richieste',
    faq: 'faq',
    'all-themes': 'tutti-temi',
    'products-search': 'ricerca-prodotti',
    recipesearch: 'ricerca-ricetta',
    'product-news': 'prodotti-in-evidenza',
    'good-deals': 'promozioni',
    'not-found': 'non-trovato',
    'menu-page': 'veggie passion',
    'menu-one': '#veggiepassion',
    'menu-two': 'alimentazione e curiosità',
    'menu-three': 'l-abc-delle-verdure',
    'menu-four': ''
  },
  MENU_PAGE: {
    TITLE1: '#veggiepassion',
    TITLE2: 'Alimentazione e curiosità',
    TITLE3: '',
    TITLE4: '',
    EXTERNAL: "L'ABC delle verdure"
  },
  TECHNICAL: { MENU_PAGE: 'vegetarian_passions' },
  SEO: {
    TITLE: {
      MENU_ONE: 'Veggie passion: uno sguardo al mondo delle verdure | Bonduelle',
      MENU_TWO: 'Alimentazione e curiosità: tutte le curiosità sulle verdure | Bonduelle',
      MENU_THREE: '',
      MENU_FOUR: '',
      HOME: 'Bonduelle, il mondo che ci piace | Bonduelle',
      SEARCH: {
        MAIN: '{{value}}  | Bonduelle',
        RECIPES: 'Ricette di {{value}} | Bonduelle',
        VEGETABLES: 'Prodotti con {{value}} | Bonduelle',
        ARTICLES: '{{value}} ordinato per articolo | Bonduelle'
      },
      CONTACT: 'Contatto del servizio consumatori, informazioni | Bonduelle ',
      RECIPES: 'Le nostre ricette | Bonduelle',
      RECIPE_HOME: 'Ricette di cucina: tutte le nostre ricette | Bonduelle',
      RECIPE: 'Ricetta {{value}} | Bonduelle',
      PRODUCTS: 'Prodotti Bonduelle, la gamma dei nostri prodotti | Bonduelle',
      NEWS: 'Bonduelle',
      CORPORATE: {
        NEWS: 'Bonduelle, dal 1853: tutte le notizie | Bonduelle',
        COMPANY_STORY: 'La nostra storia | Bonduelle',
        CSR_COMMITMENTS: 'I nostri impegni | Bonduelle',
        VEGETAL_INNOVATIONS: 'La nostra filiera produttiva | Bonduelle',
        FIELD_PLATE: 'Bonduelle',
        ARTICLE: '{{value}} | Bonduelle',
        VEGETABLE: '{{value}}'
      },
      PRODUCT: '{{value}}',
      MAGAZINE: {
        NEWS: 'Veggie passion: uno sguardo al mondo delle verdure | Bonduelle',
        ARTICLE: '{{value}} | Veggie Passion | Bonduelle',
        COOK: '',
        GROW: '',
        EXPLORE: '',
        RECIPE: ''
      },
      FAQ: 'FAQ: Domande frequenti | Bonduelle',
      NOTFOUND: 'Errore 404, per favore prova più tardi | Bonduelle',
      RECIPES_ALL: 'Le nostre ricette',
      RECIPETYPE: 'Ricette di cucina {{value}}',
      RECIPESEASON: '',
      RECIPEPREPARATION: 'Ricette di cucina in {{value}}',
      RECIPEDIFFICULTY: 'Ricette di cucina {{value}}',
      RECIPEVEGETABLE: 'Ricette con {{value}}',
      RECIPECOST: 'Ricette di verdure a {{value}}',
      RECIPEDISH: 'Ricette di cucina per {{value}}',
      RECIPEDESC: 'Ricette di cucina {{value}}',
      '2RECIPEFACETS': 'Ricette {{value}}',
      '4RECIPEFACETS': 'Ricette di cucina {{value}}',
      PRODUCTSEASON: 'Ricette {{value}}',
      PRODUCTTECHNO: 'Ricetta facile di verdure {{value}}',
      PRODUCTVEGETABLE: 'Prodotti con {{value}}',
      PRODUCTRANGE: 'Prodotti della gamma {{value}}',
      THEME_ALL: 'Insalate, conserve, surgelati e piatti pronti',
      THEME_STARTER: 'Ricette per antipasti e aperitivi | Bonduelle',
      THEME_SALAD: 'Scopri la nostra gamma di insalate in busta | Bonduelle',
      THEME_CAN: 'Conserve di mais, verdure e legumi | Bonduelle',
      THEME_FROZEN: 'Surgelati, verdure surgelate | Bonduelle',
      THEME_READY_MEALS: "Insalate e piatti pronti per l'uso | Bonduelle",
      THEME_BIO: 'Prodotti Bio, la nostra gamma di verdure bio | Bonduelle',
      THEME_VEGETABLES: 'Pasta di Legumi e Verdure | Bonduelle ',
      THEME_RECIPE: '',
      THEME_PLATE: 'Idee per cucinare piatti freddi e caldi con le verdure',
      THEME_ACC: 'Ricette per contorni | Bonduelle',
      THEME_SNACK: '',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': 'Prodotti con {{value}}'
    },
    DESCRIPTION: {
      MENU_ONE: 'Scopri il  mondo delle verdure. Caratteristiche delle verdure, proprietà e benefici, consigli nutrizionali, news, e tante idee di ricette per cucinare le verdure.',
      MENU_TWO: 'Scopri tante informazioni utili e curiosità sul mondo delle verdure e altri alimenti. Consigli nutrizionali, proprietà e benefici, utilizzi e preparazioni in cucina.',
      MENU_THREE: '',
      MENU_FOUR: '',
      HOME: "Scopri la nostra storia e i nostri prodotti, l'amore per le verdure coltivate nel rispetto dell'ambiente. Ricette, consigli e curiosità legate al mondo delle verdure.",
      SEARCH: {
        MAIN: '',
        RECIPES: '',
        VEGETABLES: 'Tutti i nostri {{value}} ordinati per verdura. Ricette semplici e saporite.',
        ARTICLES: 'Tutti i nostri {{value}} ordinati per articolo. Ricette semplici e saporite.'
      },
      CONTACT: 'Hai una domanda? Hai bisogno di informazioni su un prodotto, servizio o operazione in corso? Puoi contattare il nostro servizio clienti. Ti risponderemo entro 48 ore.',
      RECIPES: 'Scopri le ricette Bonduelle, la migliore selezione di ricette dall’antipasto al dolce. Tanti consigli e suggerimenti per piatti gustosi e originali. ',
      RECIPE_HOME: 'Ricette di cucina: una raccolta di ricette suddivise per categoria per soddisfare tutti i gusti, dai tradizionali ai più ricercati, o su musura per bambini.',
      RECIPE: '',
      PRODUCTS: 'Prodotti Bonduelle: scopri tutta la gamma dei nostri prodotti. Scegli tra insalate e piatti pronti, surgelati, prodotti in conserva e tanto altro su www.bonduelle.it',
      NEWS: 'Scopri il  mondo delle verdure. Caratteristiche delle verdure, proprietà e benefici, consigli nutrizionali, news, e tante idee di ricette per cucinare le verdure.',
      CORPORATE: {
        NEWS: "L'azienda, la storia, la nostra filiera produttiva e gli standard di qualità. Scopri tutto quello che c'è da sapere sul gruppo Bonduelle e come opera dal 1853 per garantirti prodotti di qualità.",
        COMPANY_STORY: 'Scopri la storia di Bonduelle. Come nasce la nostra azienda e la nostra idea di agricoltura responsabile, orientata a creare un mondo migliore attraverso le verdure.',
        CSR_COMMITMENTS: "Scopri l'impegno che Bonduelle dedica ogni giorno a sostegno dell'ambiente e della coltivazione delle proprie verdure per garantire sempre prodotti di qualità.",
        VEGETAL_INNOVATIONS: 'Scopri la nostra filiera produttiva: surgelati, prodotti freschi e insalate degli agricoltori, conserve ottenuti preservando la naturalità delle nostre verdure.',
        VEGETABLE: '',
        FIELD_PLATE: 'Scopri la nostra filiera produttiva: surgelati, prodotti freschi e insalate degli agricoltori, conserve ottenuti preservando la naturalità delle nostre verdure.',
        ARTICLE: ''
      },
      PRODUCT: '{{value}}',
      MAGAZINE: {
        NEWS: 'Scopri il  mondo delle verdure. Caratteristiche delle verdure, proprietà e benefici, consigli nutrizionali, news, e tante idee di ricette per cucinare le verdure.',
        ARTICLE: '',
        COOK: '',
        GROW: '',
        EXPLORE: '',
        RECIPE: ''
      },
      FAQ: 'Hai una domanda? Abbiamo raccolto tutte le domande più frequenti per rispondere al meglio ai tuoi suggerimenti.',
      PAGE: 'pagina ',
      RECIPETYPE: 'Scopri le nostre ricette di {{value}} e come cucinare correttamente le verdure. Ricette di verdure veloci, originali e facili.',
      RECIPESEASON: '',
      RECIPEPREPARATION: 'Scopri le nostre ricette di cucina e come cucinare bene le verdure in {{value}}. Ricette con le verdure, originali e gustose.',
      RECIPEDIFFICULTY: 'Scopri le nostre ricette di cucina {{value}} e come cucinare bene le verdure. Idee di ricette di verdure originali e gustose.',
      RECIPEVEGETABLE: 'Scopri la nostra selezione di ricette a base di {{value}} per sperimentare in cucina piatti sempre nuovi e originali.',
      RECIPECOST: 'Scopri le nostre ricette e come cucinare le verdure correttamente. Ricette di verdure a {{value}}, originali e gustose.',
      RECIPEDISH: 'Scopri le nostre ricette e come cucinare bene le verdure per {{value}}. Ricette di verdure, veloci, originali, facili.',
      RECIPEDESC: 'Scopri le nostre ricette di cucina e come cucinare le verdure {{value}}. Ricette di verdura, veloci, originali, facili.',
      '2RECIPEFACETS': 'Scopri le nostre ricette di cucina e come cucinare le verdure {{value}}. Ricette di verdura, veloci, originali, facili.',
      '4RECIPEFACETS': 'Scopri le nostre ricette di cucina e come cucinare bene le verdure {{value}}.',
      PRODUCTSEASON: 'Ricette {{value}}',
      PRODUCTTECHNO: 'La nostra ampia gamma di prodotti ti permette di preparare ricette gustose, facili ed equilibrate di {{value}}.',
      PRODUCTVEGETABLE: 'La nostra ampia gamma di prodotti ti permette di preparare ricette gustose ed equilibrate a base di {{value}}.',
      PRODUCTRANGE: 'La nostra ampia gamma di prodotti ti permette di preparare ricette gustose ed equilibrate a base di {{value}}.',
      THEME_ALL: 'La nostra ampia gamma di verdure ti permette di preparare ricette gustose, facili ed equilibrate. Ricette semplici e gustose. Verdure cucinate.',
      THEME_STARTER: 'Scopri le nostre ricette per antipasti, aperitivi e finger food facili e veloci da preparare. Consigli e suggerimenti per antipasti e aperitivi a regola d’arte.  ',
      THEME_SALAD: "Scopri la gamma dedicata alle insalate in busta Bonduelle. La qualità dei  prodotti freschi confezionati, già pronti all'uso, da portare in tavola con gusto e comodità.",
      THEME_CAN: 'Conserve di verdure: scopri tutti i prodotti Bonduelle della gamma Conserve, tutta la bontà delle verdure raccolte e lavorate in giornata per offrirti la massima qualità.',
      THEME_FROZEN: 'Scopri tutti i prodotti di verdure surgelate Bonduelle, una selezione di verdure facili e veloci da preparare, ideali per portare colore e gusto sulla tua tavola. ',
      THEME_READY_MEALS: "Scopri tutti i prodotti di verdure Bonduelle della gamma dedicata alle insalate e piatti pronti per l'uso, semplici da prepare e pronti in pochi minuti.  ",
      THEME_BIO: 'Scopri tutti i prodotti dedicati alla gamma di verdure bio Bonduelle, coltivate con cura e rispetto per garantirti sempre la massima qualità. ',
      THEME_VEGETABLES: 'Scopri la pasta di legumi Bonduelle, ideale per mangiare i legumi e le verdure in un modo del tutto nuovo, divertente e gustoso. ',
      THEME_RECIPE: 'Scopri le nostre ricette per antipasti, aperitivi e finger food facili e veloci da preparare. Consigli e suggerimenti per antipasti e aperitivi a regola d’arte.',
      THEME_PLATE: 'Vorresti cucinare un piatto equilibrato? Prodotti per cucinare piatti caldi o freddi a base di verdure facili e veloci, consigli e trucchi per migliorare i tuoi piatti.',
      THEME_ACC: 'Scopri le nostre ricette per contorni. Consigli e suggerimenti per preparare contorni indimenticabili al pari della tua portata principale.',
      THEME_SNACK: '',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': 'La nostra ampia gamma di prodotti ti permette di preparare ricette gustose, facili ed equilibrate {{value}}. Ricette semplici e gustose.',
      NOTFOUND: 'Ricette di cucina: una raccolta di ricette suddivise per categoria per soddisfare tutti i gusti, dai tradizionali ai più ricercati, o su musura per bambini.'
    },
    PIPE: { SOCIETY: ' | Bonduelle' },
    THEME_ALL: 'Tutti',
    THEME_STARTER: 'Antipasti e aperitivi',
    THEME_SALAD: 'Insalate',
    THEME_CAN: 'Conserve',
    THEME_FROZEN: 'Surgelati',
    THEME_READY_MEALS: 'Piatti pronti',
    THEME_BIO: 'bio',
    THEME_VEGETABLES: 'Pasta di legumi e verdure',
    THEME_RECIPE: '',
    THEME_PLATE: 'Piatto principale',
    THEME_ACC: '',
    THEME_SNACK: ''
  },
  VEGETABLE: {
    DISCOVER_VEGETABLE: 'Scopri tutte le altre verdure',
    OURS_RECIPES: 'Le nostre ricette',
    DISCOVER_ALSO: 'Scopri anche......',
    SEE_ALL: 'Vedi tutte le verdure',
    ON: 'Verdure su'
  },
  RECIPE: {
    'INGREDIENTS_LABEL ': 'Ingredienti per {{value}} persone',
    PEOPLES: '{{value}} persone',
    DIFFICULTY: {
      EASY: 'facili',
      MEDIUM: 'di media difficoltà',
      HARD: 'difficili'
    },
    COST: { CHEAP: 'Economico', EXPENSIVE: 'Costoso' },
    TIMING: { MIN: 'Min ', HOURS: 'ore' },
    STEP: 'Step',
    DISCOVER_ALSO: 'Scopri anche......',
    HOME_SEE_MORE: 'Guarda altre ricette ',
    SHOWCASE: 'Ricette in vetrina',
    HOME_BOOK: 'Le nostre ricette ',
    MIN_MORE: '> Min',
    HOUR_MORE: 'o e +',
    FILTER_BY_CRITERIA: 'Filtra per criteri ',
    SORT_BY: 'Ordina per :',
    BEST_RATED: 'I più votati',
    MOST_CONSULTED: 'I più consultati',
    MEMBERS_RECIPES: 'Ricette dei membri',
    CRITERIA: 'Notizie',
    DISCOVER: { RECIPES: 'Scopri le nostre ricette' },
    DISCOVER_RECIPE: 'Scopri la ricetta',
    INSTRUCTIONS: "Istruzioni per l'uso",
    DEFAULT_TAG: 'Idea ricetta',
    MOST_RECENT: 'I più recenti',
    RECIPE_VIDEOS: 'Video delle ricette',
    PRODUCT_ASSOCIATE: 'Ricetta realizzata con',
    BUDGET_LOW: 'costo basso',
    BUDGET_HIGH: 'costo elevato',
    BUDGET_MIDDLE: 'costo medio',
    PRINT_RECIPE: 'stampa ricetta',
    LET_YOURSELF_SURPRISED: 'Lasciatevi sorprendere',
    COOK_TIP_TITLE: '',
    ENJOY_MEAL: '',
    FILTER: { LEGEND: 'Legenda' }
  },
  PREDIGGO: {
    DIFFICULTY: {
      EASY: 'facili',
      MEDIUM: 'di media difficoltà',
      HARD: 'difficili'
    }
  },
  COOKIES: {
    TEXT: 'Il Sito www.bonduelle.it utilizza cookie al fine di migliorarne la fruizione, le performance e la sicurezza; nonché per raccogliere informazioni statistiche circa i dati di navigazione e di fruizione del sito web da parte degli utenti.\n' +
      'Inoltre, il sito utilizza cookie che permettono di individuare le preferenze dell’utente al fine di mostrargli contenuti e di inviargli comunicazioni promozionali personalizzati. Questi cookie per poter essere utilizzati necessitano del consenso dell’utente del sito web.\n' +
      'Per saperne di più clicca su <a href="https://www.bonduelle.it/privacy-policy"><b>Informativa Privacy</b></a> , mentre puoi cliccare in qualsiasi momento su gestione dei cookies, per impostare le tue preferenze di navigazione.',
    CLOSE: 'Chiudi',
    MORE: 'Per saperne di più',
    AGREE_YOUTUBE: 'È necessario accettare i cookie di Youtube per visualizzare il video',
    BUTTON_YOUTUBE: 'Accetta i cookie'
  },
  POPUP: { LAUNCH: 'POPUP-OUVERTURE-SITE' },
  FIELD_PLATE: {
    DISCOVER_VEGETABLE: 'Scopri le caratteristiche di tutte le verdure'
  },
  SEARCH: {
    OUR_RECIPES: 'Le nostre ricette',
    OUR_PRODUCTS: 'Prodotti',
    OUR_ARTICLES: 'I nostri articoli',
    RECIPES: 'Ricette',
    PRODUCTS: 'Prodotti',
    ARTICLES: 'Articoli',
    ALL: 'Visualizza tutti',
    SORT_BY: 'Ordina per',
    RESULTS: 'risultati',
    RESULT: 'risultato',
    PRODUCT_OUT: 'Prodotti su',
    APPLY: 'Applicare',
    FILTER: 'Filtro',
    RECIPE_OUT: 'ricette con ',
    NO_RESULT: '',
    SEE_MORE: 'Vedere di più',
    DISCOVER_RECIPES: 'Scopri le nostre ricette',
    SEE_MORE_OF: 'vedi di più',
    DELETE: 'cancellare'
  },
  RECIPE_LIST: {
    OUR_RECIPES: 'Ricette',
    RESULTS: 'Risultati',
    SEE_MORE: 'Guarda più ricette'
  },
  PRODUCT: {
    BUTTON: {
      ACTU: 'notizie sui prodotti',
      FOUND: 'Dove trovare questo prodotto',
      DISCOVER: 'Scopri tutta la gamma'
    },
    NUTRITIONAL_VALUE: 'valore nutrizionale',
    CONDITIONING: 'confezionamento',
    PORTIONS: 'porzioni',
    NET_WEIGHT: 'peso netto',
    DRY_WEIGHT: 'peso a secco',
    INGREDIENTS: 'ingredienti',
    OUR_RECIPES: 'Scopri le nostre ricette',
    FOR: 'per',
    PER_PORTION: 'e per porzione di',
    ENERGY: 'energetico',
    FAT: 'grasso',
    SATURATE: 'grasso saturo',
    GLUCIDES: '',
    FIBER: 'fibra',
    PROTEINS: 'proteine',
    SALT: 'sale',
    DISCOVER_ALSO: 'Scopri anche...',
    MORE_INFORMATION: 'più informazioni',
    TRACES: 'Tracce di',
    CONTAINS: 'Contiene',
    SLIDER: {
      SHEET: 'visualizza il prodotto',
      BUY: 'acquista questo prodotto'
    },
    NUTRISCORE: 'informazioni-valore-nutrizionale',
    TRIMAN: 'informazione-triman',
    AGRICONFIANCE: 'informazione - agriconfiance',
    BIO: 'informazione-bio',
    PESTICIDE_FREE: '',
    LEAF: '',
    LOCK: '',
    SEE_ALL: 'Vedi tutti i prodotti',
    LEGISLATION: 'in conformità alla legislazione vigente.',
    PORTION: { SINGULAR: 'porzione', PLURAL: 'porzioni' },
    DISCLAIMER: '',
    COOKING: 'modalità di preparazione',
    CONSERVATION: 'conservazione'
  },
  FAQ: {
    ON: 'domande su',
    ALL: 'Tutte',
    SEE_MORE: 'vedi altre domande',
    TITLE_1: 'Tutte le risposte alle tue domande',
    TITLE_2: 'Hai una domanda che non trovi? ',
    LINK_LABEL: 'Scrivici'
  },
  FORM: {
    GO_TO_STEP: 'Vai allo step',
    STEP: 'Step',
    RECEIVE_EMAIL_COPY: 'Ricevi una copia via e-mail',
    SEND: 'Inviare',
    GDPR: 'Per saperne di più sulla gestione dei dati',
    ERROR: 'Tutti i campi contrassegnati con * devono essere compilati e validi.',
    SEND_ERROR: 'Si è verificato un errore, riprovare tra qualche istante',
    NOTICE: 'Per la corretta gestione del vostro file, Bonduelle può, a seconda dei casi, richiedervi i dati relativi alla vostra salute. Non sono obbligatori e si è liberi di non trasmetterli.',
    SENDING: 'Invio del modulo ...',
    LOADING: 'Caricamento...',
    PRODUCT_INFORMATION_TITLE: 'Informazioni su un prodotto',
    PRODUCT_INFORMATION: {
      SEO_TITLE: 'Informazioni e dati di prodotto | Bonduelle',
      SEO_DESC: 'Contattate il nostro servizio clienti per informazioni su un prodotto della nostra gamma. Risposta personalizzata entro 48 ore'
    },
    INFORMATIONS: 'Le vostre informazioni',
    MISTER: 'Caro Signor',
    MISS: 'Gentile Signora',
    NAME: 'Cognome',
    OUR_NAME: 'Il suo cognome',
    FIRSTNAME: 'Nome',
    OUR_FIRSTNAME: 'Il suo nome',
    MAIL: 'Indirizzo e-mail',
    MAIL_PLH: '',
    MAIL_CONFIRM: 'Conferma indirizzo e-mail',
    MAIL_ERROR: 'Inserisca un indirizzo e-mail valido',
    MAIL_ERROR_CONFIRM: "Conferma l'indirizzo e-mail",
    ADDRESS: 'Indirizzo',
    STREET_PLH: '',
    STREET: 'Via e numero civico',
    ADDRESS_COMPLEMENT: "Ulteriori informazioni sull'indirizzo",
    ADDRESS_COMP_PLH: 'Ulteriori informazioni',
    ZIPCODE: 'Codice postale',
    CITY: 'Città',
    CITY_PLH: 'La sua città di residenza',
    PHONE: 'Numero di telefono',
    PHONE_PLH: 'Il suo numero di telefono',
    COUNTRY: 'Paese',
    PRODUCT_INFORMATIONS: 'Informazioni sul prodotto',
    PRODUCT_INFORMATION_DESC: 'Per essere in grado di esaminare al meglio la sua richiesta, le chiediamo di fornire le seguenti informazioni:',
    PRODUCT: {
      DETAIL: {
        DESC: 'Per la richiesta è possibile compilare gli appositi campi e aggiungere una foto a titolo di esempio. Le foto devono includere il codice EAN, il numero di lotto e la data di scadenza del prodotto.'
      },
      NOTE: {
        TITLE: 'Reclamo su un prodotto',
        SEO_TITLE: 'Osservazione e commento su un prodotto | Bonduelle',
        SEO_DESC: "Un'osservazione o un commento su un prodotto della nostra gamma? Contatta il nostro servizio clienti per aiutarti. Risposta personalizzata entro 48 ore"
      }
    },
    PRODUCT_RANGE: 'Gamma del prodotto',
    PRODUCT_NAME: 'Nome del prodotto',
    PRODUCT_NAME_PLH: 'Specificare il nome del prodotto Bonduelle a cui si riferisce la sua domanda.',
    PRODUCT_INFORMATIONS_DETAIL: 'Informazioni dettagliate',
    PRODUCT_INFORMATIONS_LABEL: 'Codice a barre del prodotto',
    PRODUCT_INFORMATIONS_PLH: 'Il codice a barre è composto da 13 cifre sulla confezione del prodotto.',
    PRODUCT_LOT: 'Numero di lotto del prodotto',
    PRODUCT_LOT_INFO: 'Il numero di lotto inizia con una lettera seguita da una serie di cifre. Lo puoi trovare nelle buste sul retro della confezione vicino alla data di scadenza, sul fondo della lattina, sul coperchio di ciotole e barattoli.\n',
    PHOTO: 'Aggiungere una foto',
    EXPIRED_DATE: 'Data di scadenza ',
    EXPIRED_DATE_ERROR: 'Inserire la data come segue: GG/MM/AAAAAA',
    EXPIRED_DATE_DESC: 'La data di scadenza è indicata nelle buste sul retro della confezione, sul fondo della lattina, sul coperchio di ciotole e barattoli.',
    PHOTO_MORE: 'Ulteriori foto',
    MESSAGE: 'Il tuo messaggio *',
    OUR_MESSAGE: 'scrivi il tuo messaggio',
    SALES_OPERATIONS: {
      TITLE: 'Dove acquistare i prodotti Bonduelle',
      SEO_TITLE: 'Dove acquistare i prodotti Bonduelle.... | Bonduelle',
      SEO_DESC: "Contattate il nostro servizio clienti per informazioni su un'operazione commerciale in corso. Risposta personalizzata entro 48 ore"
    },
    PRESS_SERVICE: 'Vendere i prodotti Bonduelle',
    FOOD_SERVICE: 'Informazioni dal team Food Service',
    BUSINESS_INFORMATION: '',
    MODAL_INFORMATION: '',
    JOB_OFFER: { TITLE: '', SEO_TITLE: '', SEO_DESC: '' },
    WEB_SUGGESTION: {
      TITLE: 'Informazioni su buoni sconto, promozioni o iniziative concorsuali',
      SEO_TITLE: 'Informazioni su buoni sconto, promozioni o iniziative concorsuali  | Bonduelle',
      SEO_DESC: 'Si prega di contattare il nostro servizio clienti per aiutarci a migliorare il sito.'
    },
    ANOTHER: {
      TITLE: 'Altra richiesta',
      SEO_TITLE: 'Contatto per qualsiasi altra richiesta, servizio | Bonduelle',
      SEO_DESC: 'Contattate il nostro servizio clienti per informazioni, assistenza o suggerimenti. Risposta personalizzata entro 48 ore'
    },
    AMBASSADOR: 'Lavorare in Bonduelle',
    CATEGORY: {
      SALAD: 'INSALATE',
      FROZEN: 'SURGELATI',
      CAN: 'MAIS, VERDURE E LEGUMI IN CONSERVA',
      TAKEAWAY: 'PIATTI PRONTI',
      VEGETABLE: 'Pasta di legumi e verdure'
    }
  },
  CONTACT: {
    TITLE: 'Contatti e Indirizzi',
    SPECIAL_INFO: '',
    TAKE_CARE: '',
    BY_MAIL: 'Per posta',
    BY_PHONE: 'Per telefono',
    BY_SOURDLINE: '',
    MAIL_TEXT: "Bonduelle Italia \r\nVia Trento, 1\r\n24060 San Paolo d'Argon (BG)",
    PHONE_TEXT: 'Contattaci al Numero Verde <b>800 903 160</b>. Ti risponderemo tra le 9.00 e le 14.00 dal lunedì al venerdì (festivi esclusi). Oppure lasciaci un messaggio quando vuoi tu alla nostra segreteria telefonica.',
    SOURDLINE_TEXT: '',
    BY_DISTRIBUTOR: '',
    BY_DISTRIBUTOR_TEXT: '',
    SUBTITLE: 'Desiderate contattarci per :',
    AGREEMENT: '',
    READ_MORE: "Inoltre, hai il diritto, in qualsiasi momento, di chiedere a Bonduelle di esercitare i tuoi diritti di accesso, rettifica, cancellazione e portabilità dei tuoi Dati Personali, nonché i tuoi diritti di limitazione e opposizione al trattamento dei tuoi Dati Personali. Per esercitare i tuoi diritti, ti preghiamo di scrivere a Dpo_france@bonduelle.com o via posta al seguente indirizzo: all'attenzione del responsabile della protezione dei dati, Bonduelle, Rue Nicolas Appert, PO Box 30173, F- 59653 Villeneuve D'Ascq. Per saperne di più sul modo in cui Bonduelle raccoglie e utilizza i tuoi Dati Personali clicca qui",
    READ_MORE_LABEL: 'Scopri di più',
    SATISFACTION_TEXT: 'Al fine di contattarvi per fornirvi le informazioni richieste sul prodotto e\n' +
      'per inviarvi un questionario di soddisfazione se lo desiderate, tratteremo le\n' +
      'informazioni fornite in qualità di titolare del trattamento. La base giuridica\n' +
      'per il trattamento della vostra richiesta è il consenso, a causa dei\n' +
      'potenziali dati relativi alla salute che potreste fornire nella vostra richiesta.\n' +
      'Queste informazioni saranno conservate per il tempo necessario a\n' +
      'elaborare la richiesta e per un massimo di 5 anni, al fine di fornire la prova\n' +
      'che la richiesta è stata elaborata e di adempiere ai nostri obblighi legali. La\n' +
      "base giuridica per l'invio del questionario di soddisfazione è anche il vostro\n" +
      'consenso. Queste informazioni saranno conservate per il tempo\n' +
      "necessario all'invio del questionario di gradimento e fino a un massimo di\n" +
      '1 anno. Per saperne di più sul trattamento dei vostri dati personali,\n' +
      'consultate la nostra <a href="https://www.bonduelle.it/privacy-policy" target="_blank" class="underline">Informativa sulla privacy</a>.',
    SATISFACTION_CHECKBOX: 'Selezionando questa casella, acconsentite a ricevere un questionario di\n' +
      "soddisfazione (composto da un'unica opzione) una volta evasa la vostra\n" +
      'richiesta. Questa opzione è facoltativa.',
    CHECKBOX_TEXT: 'Selezionando questa casella, acconsentite al trattamento dei vostri dati\n' +
      'sanitari personali, eventualmente indicati nel vostro messaggio, da parte\n' +
      'di Bonduelle al solo scopo di rispondere alla vostra richiesta. <strong>Questo è\n' +
      'obbligatorio per poter elaborare la vostra richiesta</strong>.',
    PHONE: 'Contattaci al Numero Verde <b>800 903 160</b>. Ti risponderemo dalle ore 09:00 alle 14:00 dal lunedì al venerdì (festivi esclusi). Oppure lasciaci un messaggio quando vuoi tu alla nostra segreteria telefonica.',
    CALL_US: 'Per chiamarci',
    TO_WRITE: 'Scrivici',
    PHONE_NUMBER: '800 903 160',
    SUCCESS_MESSAGE: 'Grazie! Il tuo messaggio è stato inviato al nostro team. \n' +
      'Rimaniamo sempre a disposizione al Numero Verde 800 903 160. Ti risponderemo tra le 9.30 e le 16.00 dal lunedì al venerdì (festivi esclusi). Oppure lasciaci un messaggio quando vuoi tu alla nostra segreteria telefonica.',
    PHOTOERROR: { MESSAGE: '' }
  },
  GLOBAL: { READ_MORE: '' },
  FLOWBOX: { GENERIC_KEY: '', PRODUCT_KEY: '', RECIPE_KEY: '', HOME_KEY: '' },
  TOKEN: {
    FACEBOOK: '',
    PAGE_FACEBOOK_ID: '',
    INSTAGRAM: '',
    INSTA_ID: '17841407279489116',
    YOUTUBE: '',
    TWITTER: '',
    MAGAZINE: ''
  },
  COOKIE: {
    BANNER: {
      MANDATORY: 'Tecnici',
      ANALYTICS: 'Di prestazione (analytics)',
      MARKETING: 'Di profilazione (marketing)',
      SOCIAL_MEDIA: 'Social Media',
      ACCEPT: 'Accetta tutti',
      REJECT: 'Disattiva tutti',
      SET_UP: 'Personalizza le preferenze sui cookie',
      MANDATORY_DETAILS: 'Si tratta di cookie necessari al corretto funzionamento del sito web. Questi cookie non raccolgono dati personali dell’utente e sono necessari a consentire la corretta fruizione del sito web. Pertanto, non possono essere rifiutati.\n',
      ANALYTICS_DETAILS: 'Si tratta di cookie che permettono l’analisi dell’attività dell’utente sul sito web per finalità statistiche. Questi cookie possono essere installati sia da Bonduelle che da soggetti terzi.',
      MARKETING_DETAILS: 'Si tratta di cookie che permettono di analizzare le preferenze dell’utente per mostrargli contenuti e di inviargli comunicazioni promozionali personalizzate.',
      V2: 'ok'
    },
    MODAL: {
      TITLE: 'Gestione dei cookie',
      TEXT: 'Nella seguente schermata potrai scegliere quali tipologie di cookie accettare, a seconda della loro specifica funzione.\n' +
        '\n',
      CONFIG: 'Configurazione',
      SAVE: 'Salva'
    }
  },
  LINK: { JOB_OFFERS: '' },
  CLIC2BUY: { INFOS: '' }
};
