import { SfModel, SfItem } from '@app/sf-lib/public_api';

export class SomItemList extends SfModel {

    public total: number;

    public nbArticles: number;

    public items: SfItem[];

    constructor() {
        super();
        this.nbArticles = 0;
        this.total = 0;
        this.items = [];
    }
}
