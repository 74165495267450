import { SfSlide } from '@app/sf-lib/public_api';
import { SomPage } from './page.model';
import { SomItemList } from './item-list.model';

export class SomCorporatePage extends SomPage {

public corporate_articles: SomItemList;

public slides: SfSlide[];

constructor() {
    super();
    this.slides = [];
  }
}
