import { Component, Input, ViewEncapsulation } from '@angular/core';
import { SfItem } from '../models/item.interface';

@Component({
    selector: 'sf-item-text-horizontal',
    templateUrl: './item-text-horizontal.component.html',
    styleUrls: ['./item-text-horizontal.component.sass'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SfItemTextHorizontalComponent {

  /**
   * Display item data
   *
   * @memberof SfItemTextComponent
   */
  @Input() item: SfItem;

  @Input() seoTag: 'H1'|'H2'|'H3'|'DIV' = 'DIV';
  @Input() seoTitle: 'H1'|'H2'|'H3'|'DIV' = 'DIV';

  public hover: boolean;

  constructor() {
  }

}
