import { SfItem } from '@app/sf-lib/public_api';
import { SomBloc } from './bloc.model';

export class SomPreviousEdition extends SomBloc {

    /** Items to display    */
    public items: SfItem[];

    constructor() {
        super();
        this.items = [];
    }
}
