<div class="sf-section-video" *ngIf="section">

  <!-- Title -->
  <ng-container *ngIf="section.title" [ngSwitch]="seoTitle">
    <h2 *ngSwitchCase="'H1'" class="sf-section-video-title sf-title">{{section.title}}</h2>
    <h2 *ngSwitchCase="'H2'" class="sf-section-video-title sf-title">{{section.title}}</h2>
    <h3 *ngSwitchCase="'H3'" class="sf-section-video-title sf-title">{{section.title}}</h3>
    <div *ngSwitchDefault class="sf-section-video-title sf-title">{{section.title}}</div>
  </ng-container>

  <!-- Text -->
  <div *ngIf="section.text" [innerHTML]="section.text ? (section.text | safePipe) : ''" class="sf-section-video-text sf-text"></div>

  <!-- Video -->
  <div class="sf-section-video-iframe">
    <sf-video *ngIf="section.video" [video]="section.video" class="sf-section-video-iframe-responsive"></sf-video>
  </div>
</div>
