import { SfFormField } from './form-field.interface';

export interface SfFormTextField extends SfFormField {

  /** Value */
  value: string;

  /** Placeholder */
  placeholder: string;

  /** Style of the field */
  style: 'outline-text' | 'underline-text';

  type: 'text' | 'number' | 'date';

}
