import { SfMediaVideo } from '../../video';
import { SfModel } from '../../../core/networking';
import { SfSectionVideo } from './section-video.interface';

export class SfSimpleSectionVideo extends SfModel implements SfSectionVideo {

  /** Title of the video */
  title: string;

  /** Text of the section. May contain HTML tags */
  text: string;

  /** Video to display in the section */
  video: SfMediaVideo;
}
