import { Directive, ElementRef, Renderer2, HostListener, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[sfRollover]',
    standalone: false
})
export class SfRolloverDirective implements OnInit, OnChanges {

  @Input() sfRollover: boolean;

  private el: HTMLElement;
  private get hoverEl(): HTMLElement {
    if (!this.el) {
      this.el = this.renderer.createElement('div');
    }
    return this.el;
  }

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    this.renderer.addClass(this.hoverEl, 'sf-rollover');
    this.renderer.setStyle(this.hoverEl, 'position', 'absolute');
    this.renderer.setStyle(this.hoverEl, 'top', '0');
    this.renderer.setStyle(this.hoverEl, 'bottom', '0');
    this.renderer.setStyle(this.hoverEl, 'left', '0');
    this.renderer.setStyle(this.hoverEl, 'right', '0');
    this.renderer.setStyle(this.hoverEl, 'pointer-events', 'none');
    this.renderer.appendChild(this.elementRef.nativeElement, this.hoverEl);
    this.renderer.setStyle(this.elementRef.nativeElement, 'position', 'relative');
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName of Object.keys(changes)) {
      if (propName === 'sfRollover') {
        if (changes[propName].currentValue === true) {
          this.applyRollover();
        } else {
          this.removeRollover();
        }
      }
    }
  }

  /**
   * Triggered on mouse enter in container component
   * @memberof SfRolloverDirective
   */
  @HostListener('mouseenter', ['$event.target'])
  public onEnter(targetElement) {
    if (typeof this.sfRollover !== 'boolean') {
      this.applyRollover();
    }
  }

  /**
   * Triggered on mouse leave in container component
   * @memberof SfRolloverDirective
   */
  @HostListener('mouseleave', ['$event.target'])
  public onLeave(targetElement) {
    if (typeof this.sfRollover !== 'boolean') {
      this.removeRollover();
    }
  }

  private applyRollover() {
    this.renderer.setStyle(
      this.hoverEl,
      'background',
      `linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.50) 100%)`
    );
  }

  private removeRollover() {
    this.renderer.removeStyle(this.hoverEl, 'background');
    this.renderer.removeStyle(this.hoverEl, 'filter');
  }

}
