import { SfModel } from '../../../core/networking';
import { SfItemStat } from './item-stat.interface';
import { SfMediaImage } from '../../image';

export class SfSimpleItemStat extends SfModel implements SfItemStat {

  /** Icon display in template */
  icon: SfMediaImage;

  /** Text to be displayed */
  text: string;

  /** Color of the background as hexadecimal string. i.e: #FF39A0  */
  color?: string;

  background?: SfMediaImage;
}
