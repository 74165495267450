/* tslint:disable */export const productLink = [
  { lang: 'smbi18n', link: 'ROUTES/vegetables/p' },
  { lang: 'com_en', link: 'vegetables/p' },
  { lang: 'fr_fr', link: 'legumes/p' },
  { lang: 'de_de', link: 'unser-gemuese/p' },
  { lang: 'at_de', link: 'unser-gemuese/p' },
  { lang: 'be_nl', link: 'nl/producten/p' },
  { lang: 'be_fr', link: 'fr/legumes/p' },
  { lang: 'nl_nl', link: 'producten/p' },
  { lang: 'dk_da', link: 'produkter/p' },
  { lang: 'hu_hu', link: 'termekek/p' },
  { lang: 'cz_cs', link: 'zelenina/p' },
  { lang: 'sk_sk', link: 'zelenina/p' },
  { lang: 'es_es', link: 'productos/p' },
  { lang: 'pt_pt', link: 'produtos/p' },
  { lang: 'it_it', link: 'prodotti/p' },
  { lang: 'ee_et', link: 'tooted/p' },
  { lang: 'lt_lt', link: 'produktai/p' },
  { lang: 'lv_lv', link: 'produkti/p' },
  { lang: 'pl_pl', link: 'warzywa/p' },
  { lang: 'fi_fi', link: 'tuotteet/p' },
  { lang: 'se_sv', link: 'produkter/p' },
  { lang: 'no_no', link: 'produkter/p' },
  { lang: 'il_he', link: 'vegetables/p' },
  { lang: 'rs_sr', link: 'proizvodi/p' },
  { lang: 'al_sq', link: 'produkte/p' },
  { lang: 'gr_el', link: 'proionta/p' },
  { lang: 'ro_ro', link: 'produse/p' },
  { lang: 'bg_bg', link: 'zelenchutsi/p' },
  { lang: 'hr_hr', link: 'povrce/p' },
  { lang: 'ba_bs', link: 'povrce/p' }
];
