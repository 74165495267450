import { Component, OnInit, Input } from '@angular/core';
import { SfSectionImage } from '../models/section-image.interface';

@Component({
    selector: 'sf-section-image',
    templateUrl: './section-image.component.html',
    styleUrls: ['./section-image.component.sass'],
    standalone: false
})
export class SfSectionImageComponent implements OnInit {

  @Input() section: SfSectionImage;

  @Input() seoTitle: 'H1'|'H2'|'H3'|'DIV' = 'DIV';

  constructor() { }

  ngOnInit() {
  }

}
