import { SfModel } from '@app/sf-lib/public_api';

export class PrediggoParameters extends SfModel {

  /**
   * Optional. A list of skus. The content of the list depends on the current page.
   * If you are on an item page or the add to basket pop-in, then skus should contain just the current item id.
   * However, if you are on a basket page, then skus should contain all the skus in the basket.
   * @memberof PrediggoParameters
   */
  public skus?: string[];

  /**
   * Optional. The search query if you are on a search page.
   * The search query should also be sent when using the refiningid parameter.
   * @memberof PrediggoParameters
   */
  public query?: string;

  /**
   * Optional. Map of filter properties
   * exemple:
   *  {
   *    'brand' : ['ibm', 'sony'],
   *    'category' : '121'
   *  }
   * @memberof PrediggoParameters
   */
  public filters?: { [key: string]: string[]|string };

  /**
   * Optional. Contains the refining id code indicating the action of the user
   * on the search/navigation results (such as click on a facet, or changing the sorting).
   * @memberof PrediggoParameters
   */
  public refiningId?: string;

  /**
   * Optional. the starting page number.
   * @memberof PrediggoParameters
   */
  public page?: number;

  /**
   * Optional. The maximum number of items to return per page.
   * @memberof PrediggoParameters
   */
  public resultsPerPage?: number;

  /**
   * Optional. The initial sorting order (as a string constant).
   * @memberof PrediggoParameters
   */
  public sortingCode?: string;

  constructor() {
    super();
    this.skus = [];
    this.query = '';
    this.filters = {};
    this.refiningId = '';
    this.page = 0;
    this.resultsPerPage = 0;
    this.sortingCode = '';
  }

  public static create(): PrediggoParameters {
    const newEntity = new PrediggoParameters();
    newEntity.filters = void 0;
    newEntity.page = void 0;
    newEntity.query = void 0;
    newEntity.refiningId = void 0;
    newEntity.resultsPerPage = void 0;
    newEntity.skus = void 0;
    newEntity.sortingCode = void 0;
    return newEntity;
  }
}
