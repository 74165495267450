import { SfItem } from '@app/sf-lib/public_api';
import { SomBloc } from './bloc.model';

export class SomPreviousArticlesByCategories extends SomBloc {

  /** Number of articles to be displayed */
  public nbarticle: number;

  /** Items to display */
  public categories: {name: string, items: SfItem[]}[];

  public readonly baseUrl = '/magazine';


  constructor() {
    super();
    this.nbarticle = 0;
    this.categories = [];
  }
}
