import { SfSlide } from './slide.interface';
import { SfMediaVideo } from '../../video';

export interface SfVideoSlide extends SfSlide {

  video: SfMediaVideo;
}

export function isSfVideoSlide(object: Object): object is SfVideoSlide {
  return 'video' in object;
}
