import { SfModel } from '@app/sf-lib/public_api';
import { SomFaqArticlePage } from './faq-article-page.model';

export class SomFaqSection extends SfModel {

  items: SomFaqArticlePage[];

  constructor() {
      super();
    this.items = [];
  }
}
