<div class="sf-form-section" *ngIf="section">

  <ng-container *ngIf="!isMobile">
    <!-- Section Title -->
    <ng-container *ngIf="section.title" [ngSwitch]="seoTitle">
      <h2 *ngSwitchCase="'H1'" class="sf-form-section-title sf-title">{{ section.title | translate }}</h2>
      <h2 *ngSwitchCase="'H2'" class="sf-form-section-title sf-title">{{ section.title | translate }}</h2>
      <h3 *ngSwitchCase="'H3'" class="sf-form-section-title sf-title">{{ section.title | translate }}</h3>
      <div *ngSwitchDefault class="sf-form-section-title sf-title">{{ section.title | translate }}</div>
    </ng-container>

    <!-- Description -->
    <div class="sf-form-section-description sf-text"
    *ngIf="section.description"
      [innerHTML]="section.description | translate">
    </div>
  </ng-container>

  <div *ngIf="isMobile" class="sf-form-section-header" (click)="toggle()">
    <!-- Section Title -->
    <ng-container *ngIf="section.title" [ngSwitch]="seoTitle">
      <h2 *ngSwitchCase="'H1'" class="sf-form-section-title sf-title"><span>{{index}}. </span>{{ section.title | translate }}</h2>
      <h2 *ngSwitchCase="'H2'" class="sf-form-section-title sf-title"><span>{{index}}. </span>{{ section.title | translate }}</h2>
      <h3 *ngSwitchCase="'H3'" class="sf-form-section-title sf-title"><span>{{index}}. </span>{{ section.title | translate }}</h3>
      <div *ngSwitchDefault class="sf-form-section-title sf-title"><span>{{index}}. </span>{{ section.title | translate }}</div>
    </ng-container>

    <div class="sf-form-section-title-expand" [@toggleAnimations]="state">▶</div>

    <div [@expansionAnimations]="state" class="sf-form-section-breadcrumb">
      <ng-content select="[breadcrumb]"></ng-content>
    </div>

    <!-- Description -->
    <div class="sf-form-section-description sf-text" *ngIf="section.description">
      {{ section.description | translate }}
    </div>
  </div>

  <div class="sf-form-section-divider"></div>

  <!-- Fields -->
  <div class="sf-form-section-field-container">

    <div #section class="sf-form-section-field" *ngFor="let formField of section.formFields; index as i" [ngSwitch]="formField.sfType" [ngStyle]="{'flex':'0 0 calc(' + formField.width / flexBasis + '% - 1rem)'}">

      <sf-form-text-field #field *ngSwitchCase="'SfFormTextField'" [data]="formField" [parentFormGroup]="formGroup" [discriminator]="discriminator + '_' + i"></sf-form-text-field>

      <sf-form-text-field-confirm #field *ngSwitchCase="'SfFormTextFieldConfirm'" [data]="formField" [parentFormGroup]="formGroup" [discriminator]="discriminator + '_' + i"></sf-form-text-field-confirm>

      <sf-form-radio #field *ngSwitchCase="'SfFormRadio'" [data]="formField" [parentFormGroup]="formGroup" [discriminator]="discriminator + '_' + i"></sf-form-radio>

      <sf-form-select #field *ngSwitchCase="'SfFormSelect'" [data]="formField" [parentFormGroup]="formGroup" [discriminator]="discriminator + '_' + i"></sf-form-select>

      <sf-form-image #field *ngSwitchCase="'SfFormImage'" [data]="formField" [parentFormGroup]="formGroup" [discriminator]="discriminator + '_' + i"></sf-form-image>

      <sf-form-image-multi #field *ngSwitchCase="'SfFormImageMulti'" [data]="formField" [parentFormGroup]="formGroup" [discriminator]="discriminator + '_' + i"></sf-form-image-multi>

      <sf-form-text-area #field *ngSwitchCase="'SfFormTextArea'" [data]="formField" [parentFormGroup]="formGroup" [discriminator]="discriminator + '_' + i"></sf-form-text-area>

      <div #field *ngSwitchDefault>
        Default Field
      </div>

      <div class="sf-form-section-field-hint" *ngIf="formField.hint">
        <mat-icon (click)="showHint(section, formField.hint)"  [svgIcon]="'info'"></mat-icon>
      </div>

    </div>

  </div>

</div>
