<div [@slideAnimations]="state" class="sf-product-slide" *ngIf="pSlide">
  <div class="sf-product-slide-content">

    <!-- Packshot -->
    <div class="sf-product-slide-content-image">
      <sf-image *ngIf="pSlide.packshot && pSlide.packshot.image && pSlide.packshot.image.uri" class="sf-product-slide-content-image-packshot"
        [@rightAnimation]="state" [image]="pSlide.packshot" fitting="contain"></sf-image>
    </div>

    <!-- Title -->
    <ng-container *ngIf="pSlide.label1" [ngSwitch]="seoTitle">
      <h2 *ngSwitchCase="'H1'" class="sf-product-slide-content-title">{{ pSlide.label1 | truncateString:45 }}</h2>
      <h2 *ngSwitchCase="'H2'" class="sf-product-slide-content-title">{{ pSlide.label1 | truncateString:45 }}</h2>
      <h3 *ngSwitchCase="'H3'" class="sf-product-slide-content-title">{{ pSlide.label1 | truncateString:45 }}</h3>
      <div *ngSwitchDefault class="sf-product-slide-content-title">{{ pSlide.label1 | truncateString:45 }}</div>
    </ng-container>

    <!-- Button -->
    <div [@leftAnimation]="state">
      <button [sfLink]="pSlide.link" (click)="eventService.setEvent(pSlide.label1, 'See product Page')" [@leftAnimation]="state" class="sf-product-slide-content-button"
      [ngClass]="{'sf-product-slide-content-button-main': mode === 'main',
       'sf-product-slide-content-button-magazine': mode === 'magazine',
       'sf-product-slide-content-button-alone': !buttons}">
        {{ 'PRODUCT.SLIDER.SHEET' | translate }}
      </button>
    </div>
    <div *ngIf="buttons" [@leftAnimation]="state">
        <button data-widget-element [attr.data-widget-ean]="pSlide.gtin" [@leftAnimation]="state" (click)="eventService.setEvent(pSlide.label1, 'Product store')" class="sf-product-slide-content-button-cta"
        [ngClass]="{'sf-product-slide-content-button-main': mode === 'main', 'sf-product-slide-content-button-magazine': mode === 'magazine'}">
          {{ 'PRODUCT.SLIDER.BUY' | translate }}
        </button>
      </div>
  </div>
</div>
