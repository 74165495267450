/* tslint:disable */export const ee_et = {
  '404': {
    TITLE: 'Oih ...',
    PAGENOTFOUND: 'Lehekülge ei eksisteeri',
    BUTTON: 'Tagasi esilehele',
    RECIPES: 'Avasta meie retsepte'
  },
  HOME: {
    SHOWCASE: 'Retseptid',
    CATCH_PHRASE: '',
    SHOWCASE_SEE_ALL: 'Kõik retseptid',
    MAGAZINE: {
      TITLE: 'Kõige populaarsemad',
      BUTTON: 'Väikesed suured avastused'
    },
    OUR_VEGETABLES: 'Meie aedviljad',
    SOCIAL_WALL: 'Koos on alati toredam!',
    FAQ_TITLE: 'Teie küsimused',
    FAQ_SEE_MORE: 'Rohkem',
    FLOWBOX: ''
  },
  SELECT: { DEFAULT_VALUE: 'Bonduelle pakub' },
  MENU: {
    CONTACT_US: 'Võta ühendust',
    MY_ACCOUNT: 'Minu Bonduelle',
    GOOD_DEALS: 'Head pakkumised',
    NEWSLETTER: 'Bonduelle՚i ajakiri'
  },
  FOOTER: {
    LINK: {
      BONDUELLE: 'Bonduelle.ee',
      CREDITS: 'Autoriõigused',
      LEGAL_NOTICE: 'Juriidiline teave',
      TERMS_OF_SALES: 'Müügitingimused',
      COOKIES: 'Küpsised',
      SITEMAP: 'Kodulehe sisupuu',
      PRIVACY_POLICY: 'Privaatsuspoliitika',
      ALL_RIGHTS_RESERVED: '2024© Kõik õigused kaitstud'
    },
    HISTORY: {
      TITLE: 'Bonduelle alates 1853',
      TEXT: 'Bonduelle on perekond. Siin töötab juba 7. põlvkond, kelle loodus- ja inimsõbraliku käe all valmib põllumajandustoodang. Me toetame ökopõllumajandust, orienteerume tulevikule ja püüdleme iga päev selle poole, et aedviljade osa kõikide inimeste elus muutuks aina suuremaks.'
    },
    NEWSLETTER: {
      TITLE: 'Inimesed ja Bonduelle',
      TEXT: "Olen nõus saama Bonduelle'i uudiseid ja pakkumisi „Kõik aedviljade kohta Bonduelle'i sulest“ (uudised, reklaam, konkursid, artiklid ...)",
      PLACEHOLDER: 'Sinu e-post',
      GDPR_LINK: 'Lähemalt sinu andmete töötluse kohta'
    }
  },
  PRODUCTS: 'Tooted',
  MAGAZINE: {
    HOME: 'Tere tulemast VÄIKESTESSE SUURTESSE AVASTUSTESSE',
    CATCH_PHRASE: '',
    MENU: { BONDUELLE_LINK: 'Sisene bonduelle.ee' },
    SHOWCASE: 'Artiklid/postitused',
    PREVIOUS_ARTICLES: 'Varasem postitus',
    PREVIOUS_ARTICLES_SEE_ALL: 'Vaata kõiki meie postitusi',
    PREVIOUS_EDITIONS: 'Varasemates postitustes',
    PREVIOUS_EDITIONS_SEE_ALL: 'Vaata rohkem postitusi',
    PREVIOUS_ARTICLES_BY_CATEGORY: 'Varasemad postitused kategooria järgi',
    RUBRIC: {
      SEE_ALL: 'Vaata kõiki retsepte',
      SEE_ALL_ARTICLES: 'Vaata kõiki postitusi',
      ON: 'postitused'
    },
    RUBRIC_COOK: { ON: 'retseptid' },
    ARTICLE: { JOIN_US: 'Liitu', READ_ALSO: 'Loe lisaks' },
    RECIPE: {
      INGREDIENTS: 'Koostisained',
      INGREDIENTS_LABEL_DESC: '{{value}} portsjoni jaoks',
      INGREDIENTS_LABEL_ONE_DESC: '{{value}} portsjoni jaoks',
      INGREDIENTS_LABEL: 'Koostisained {{value}} portsjoni jaoks',
      INGREDIENTS_LABEL_ONE: 'Koostisained {{value}} portsjoni jaoks',
      PEOPLES: '{{value}} inimest',
      PEOPLE: '{{value}} inimest',
      DIFFICULTY: { EASY: 'Kerge', MEDIUM: 'Keskmine', HARD: 'Keerukas' },
      COST: { CHEAP: 'Odav', EXPENSIVE: 'Kallis' },
      TIMING: { MIN: 'minutit', HOURS: 'tundi' },
      STEP: 'Samm',
      DISCOVER_ALSO: 'Avasta lisaks ...'
    }
  },
  ARTICLE: { DISCOVER_ALSO: 'Avasta lisaks ...', INGREDIENTS: 'Koostisained' },
  SF: {
    SLIDE: { DISCOVER: 'Avasta' },
    SEARCH: { PLACEHOLDER: 'Otsing', ALL: 'Kõik' },
    NEWSLETTER: {
      BTN_LABEL: 'Telli',
      PLACEHOLDER: 'Sinu e-post',
      ERROR_MESSAGE: 'E-post ei ole õige',
      ALREADY_SUBSCRIBED: 'Sa oled juba registreerunud',
      GDPR: {
        TXT: 'Rohkem infot sinu andmete töötluse kohta',
        LINK: '/küpsised#gdpr'
      }
    },
    MONTHLY_FOLDER: { TITLE: 'Kuu uudised' },
    MINI_NEWSLETTER: {
      PLACEHOLDER: 'Sinu e-post',
      GDPR: {
        TXT: 'Rohkem infot sinu andmete töötluse kohta',
        LINK: '/küpsised#gdpr'
      }
    },
    SOCIALITEM: { SHARE: 'jaga', COMMENT: 'kommenteeri', BONDUELLE: 'Bonduelle' },
    SEARCHRECIPE: { DISCOVER_ALL: 'otsi kõiki retsepte!' },
    FORM: {
      ERROR: 'Valesti täidetud',
      PHOTO: { ADD: 'Lisa foto', DELETE: 'Kustuta foto', SEE: 'Vaata' }
    }
  },
  CORPORATE: {
    COMPANY_STORY: 'ettevõtte ajalugu',
    CSR_COMMITMENT: 'Jätkusuutlikkus',
    VEGETABLE_INNOVATIONS: '',
    NEWS: 'Bonduelle’st',
    SEE_MORE: 'Rohkem postitusi',
    ON: 'Postitused'
  },
  NEWSLETTER: {
    TITLE: 'Telli uudiskiri',
    SUBTITLE: 'Head pakkumised, kulinaarne inspiratsioon ja kultuur!'
  },
  ROUTES: {
    products: 'tooted',
    news: 'bonduelle-st',
    'since-1853': 'alates-1853',
    'field-plate': 'koogiviljaentsuklopeedia',
    'company-story': 'bonduelle-leedus',
    'csr-commitments': 'jatkusuutlikkus',
    'vegetal-innovations': 'me-hoolime',
    magazine: 'vaikesed-suured-avastused',
    all: 'koik',
    article: 'postitus',
    cook: 'kupsetada',
    cultivate: 'kultiveerida',
    explore: 'avastada',
    recipe: '',
    recipes: 'retseptid',
    'recipes-home': 'retseptid-esileht',
    r: 'r',
    credits: 'kreedit-lt',
    'legal-notice': 'juriidiline-teave',
    'terms-of-sales': 'muugitingimused',
    cookies: 'kupsised',
    sitemap: 'kodulehe-sisupuu',
    'privacy-policy': 'privaatsuspoliitika',
    'all-rights-reserved': 'koik-oigused-kaitstud',
    search: 'otsing',
    'articles-search': 'postitus',
    vegetables: 'tooted',
    p: 'p',
    contact: 'kontakt',
    'info-product': 'tooteinfo',
    'note-product': '',
    suggestion: 'pakkumine',
    'other-request': 'muud-pakkumised',
    faq: 'kkk',
    'all-themes': 'koik-teemad',
    'products-search': 'tooteotsing',
    recipesearch: 'retseptiotsing',
    'product-news': 'tooteuudised',
    'good-deals': 'head-pakkumised',
    'not-found': 'ei-leitud',
    'menu-page': 'blog',
    'menu-one': 'teeme',
    'menu-two': '',
    'menu-three': '',
    'menu-four': '',
    'menu-external': '',
    'theme-pasta-rice': '',
    'theme-bowls': '',
    'theme-soup': '',
    'theme-snack': '',
    'theme-sauce': '',
    'theme-unique-plate': '',
    'theme-over': '',
    'theme-legumin': '',
    'theme-ingredients-recipes': '',
    'theme-cook-plate': ''
  },
  BREAD: {
    products: 'tooted',
    news: "bonduelle'st",
    'since-1853': 'alates 1853',
    'field-plate': 'KÖÖGIVILJAENTSÜKLOPEEDIA',
    'company-story': 'bonduelle leedus',
    'csr-commitments': 'jätkusuutlikkus',
    'vegetal-innovations': 'Me hoolime',
    magazine: 'VÄIKESED-SUURED-AVASTUSED',
    all: 'kõik',
    article: 'postitus',
    cook: 'küpsetada',
    cultivate: 'kultiveerida',
    explore: 'avastada',
    recipe: '',
    recipes: 'retseptid',
    'recipes-home': 'retseptid-esileht',
    'articles-search': 'postitus',
    search: 'otsing',
    vegetables: 'tooted',
    contact: 'kontakt',
    'info-product': 'tooteinfo',
    'note-product': '',
    suggestion: 'pakkumine',
    'other-request': 'muud-pakkumised',
    faq: 'kkk',
    'all-themes': 'kõik-teemad',
    'products-search': 'tooteotsing',
    recipesearch: 'retseptiotsing',
    'product-news': 'tooteuudised',
    'good-deals': 'head-pakkumised',
    'not-found': 'ei leitud',
    'menu-page': 'blog',
    'menu-one': 'Teeme süüa',
    'menu-two': '',
    'menu-three': '',
    'menu-four': ''
  },
  MENU_PAGE: {
    TITLE1: 'Teeme süüa',
    TITLE2: '',
    TITLE3: '',
    TITLE4: '',
    EXTERNAL: ''
  },
  TECHNICAL: { MENU_PAGE: 'vegetarian_passions' },
  SEO: {
    TITLE: {
      MENU_ONE: 'esileht esimene Nimetus',
      MENU_TWO: 'esileht teine Nimetus',
      MENU_THREE: 'esileht kolmas Nimetus',
      MENU_FOUR: 'esileht neljas Nimetus',
      HOME: 'Bonduelle: maailm, mis meile meeldib',
      SEARCH: {
        MAIN: 'Retseptid: {{value}} | Bonduelle',
        RECIPES: 'Otsingutulemused retseptides: {{value}} | Bonduelle',
        VEGETABLES: 'Otsingutulemused toodetes: {{value}} | Bonduelle',
        ARTICLES: '{{value}} - otsingutulemused toote kohta | Bonduelle'
      },
      CONTACT: "Bonduelle'i kontaktid",
      RECIPES: "Bonduelle'i retseptid",
      RECIPE_HOME: 'Kõik meie retseptid | Bonduelle',
      RECIPE: "Bonduelle'i retsept",
      PRODUCTS: "Bonduelle'i tooted",
      NEWS: "Bonduelle'i uudised",
      CORPORATE: {
        NEWS: 'Aastast 1853: kõik uudised',
        COMPANY_STORY: 'Aastast 1853: ettevõtte ajalugu',
        CSR_COMMITMENTS: 'Aastast 1853: me hoolime',
        VEGETAL_INNOVATIONS: 'Aastast 1853: köögiviljauudised',
        FIELD_PLATE: 'Köögiviljaentsüklopeedia | Bonduelle',
        ARTICLE: '{{value}} | Bonduelle',
        VEGETABLE: 'Kõik {{value}} kohta | Bonduelle'
      },
      PRODUCT: "{{value}} | Bonduelle'i köögiviljad",
      MAGAZINE: {
        NEWS: 'Köögiviljauudised, loomingulised retseptid ja veel palju muud | Bonduelle',
        ARTICLE: "Saage teada koos Bonduelle'ga",
        COOK: "Kokkama koos Bonduelle'ga",
        GROW: "Köögiviljakasvatus Bonduelle'ga",
        EXPLORE: "Avastused koos Bonduelle'ga",
        RECIPE: "Valmistagem koos Bonduelle'ga"
      },
      FAQ: 'Korduma kippuvad küsimused | Bonduelle',
      NOTFOUND: 'Viga 404, proovige hiljem uuesti | Bonduelle',
      RECIPES_ALL: 'kõik meie retseptid',
      RECIPETYPE: '{{value}} – valmistagem tervislikult, kiiresti ja maitsvalt',
      RECIPESEASON: '{{value}} – sobivaimad retseptid vastavalt aastaajale',
      RECIPEPREPARATION: '{{value}} – valmistagem tervislikult, kiiresti ja maitsvalt',
      RECIPEDIFFICULTY: '{{value}} retsept – igaüks võib valmistada.',
      RECIPEVEGETABLE: '{{value}} – selle köögiviljaga retseptid.',
      RECIPECOST: '{{value}}',
      RECIPEDISH: '{{value}} – tervislik, kiire ja lihtne.',
      RECIPEDESC: '{{value}} – road kõigile.',
      '2RECIPEFACETS': '{{value}}',
      '4RECIPEFACETS': '{{value}} retseptiideed',
      PRODUCTSEASON: 'Tooted, mida saate valmistada {{value}}',
      PRODUCTTECHNO: '{{value}} tooted',
      PRODUCTVEGETABLE: 'Tooted, mis sisaldavad enim {{value}}',
      PRODUCTRANGE: 'Tooted, mis sisaldavad enim {{value}}',
      THEME_ALL: 'Konserveeritud ja külmutatud tooted',
      THEME_STARTER: 'Suupisted | Bonduelle',
      THEME_SALAD: 'SALATID | Bonduelle',
      THEME_CAN: 'Konservtooted | Bonduelle',
      THEME_FROZEN: 'Külmutatud tooted | Bonduelle',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: 'Köögiviljad | Bonduelle',
      THEME_RECIPE: 'Kiirete ja lihtsate köögiviljaretseptide ideed',
      THEME_PLATE: 'Põhiroad | Bonduelle',
      THEME_ACC: 'Garneering | Bonduelle',
      THEME_SNACK: 'Suupisteid igaühele | Bonduelle',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': '{{value}} – avastage meie tooted'
    },
    DESCRIPTION: {
      MENU_ONE: 'esileht esimene kirjeldus',
      MENU_TWO: 'esileht teine kirjeldus',
      MENU_THREE: 'esileht kolmas kirjeldus',
      MENU_FOUR: 'esileht neljas kirjeldus',
      HOME: 'Kõik köögiviljadest, meie toodetest ja uuendustest, lõunasöögiideed ja erinevad avastused. Külmutatud ja konservtooted.',
      SEARCH: {
        MAIN: '{{value}}. Avastage meie retseptid ja köögiviljad. Innustuge kokkamisest ja loomingust. Lihtsad ja maitsvad retseptid. Konserveeritud ja külmutatud köögiviljad.',
        RECIPES: "{{value}}. Innustuge kokkamisest koos Bonduelle'ga. Lihtsad ja maitsvad retseptid erinevateks puhkudeks. Konserveeritud ja külmutatud köögiviljad.",
        VEGETABLES: '{{value}}. Avastage erinevad köögiviljad ja meie tooted. Igaüks leiab omale lemmiku. Külmutatud ja konservtooted.',
        ARTICLES: 'Kõik toote otsingutulemused: {{value}}. Lihtsad ja maitsvad retseptid. Konservköögiviljad.'
      },
      CONTACT: 'Kas on küsimusi? Või on teil märkusi või ettepanekuid? Võtke meiega ühendust. Külmutatud ja konservtooted.',
      RECIPES: 'Avastage huvitavaid retsepte ja üllatage oma sõpru või lähedasi. Kerged ja lihtsalt valmivad retseptid iga erineva sündmuse puhuks.',
      RECIPE_HOME: 'Kergesti ettevalmistatavad ja kiiresti valmivad retseptid teie peolauale ja lõunasöögiks. Külmutatud ja konservtooted.',
      RECIPE: 'Avastage huvitavaid retsepte ja üllatage oma sõpru või lähedasi. Maitsev ja lihtne retsept, mis sobib erinevateks puhkudeks.',
      PRODUCTS: 'Meie laiast köögiviljavalikust leiab igaüks omale lemmiktoote. Avastage kõik köögiviljade kasutusvõimalused. Külmutatud ja konservtooted.',
      NEWS: 'Lugege, kuidas köögivilju kasvatada, hoida ja valmistada. Kõik uudised ja avastused. Külmutatud ja konservtooted.',
      CORPORATE: {
        NEWS: 'Kõik uudised köögiviljadest, nende kasvatamisest ja töötlemisest. Lugege meie kohustustest, ettevõtte ajaloost ja veel muustki.',
        COMPANY_STORY: 'Tutvuge meie ajalooga. Me pooldame mahepõllumajandust ja töötame selle nimel, et köögiviljad muutuksid inimeste elus üha olulisemaks.',
        CSR_COMMITMENTS: 'Me pooldame mahepõllumajandust, vaatame tulevikku ja püüame iga päev, et köögiviljad muutuksid iga inimese elus üha olulisemaks.',
        VEGETAL_INNOVATIONS: 'Köögiviljauuendused: kuidas neid kasvatada, hooldada, hoida ja töödelda. Avastage ja tutvuge lähemalt. Külmutatud ja konservtooted.',
        VEGETABLE: '',
        FIELD_PLATE: 'Kõik köögiviljade kohta – alates mulla harimisest, taimede istutamisest ja hooldusest kuni õige ettevalmistuseni toidu tegemisel.',
        ARTICLE: ''
      },
      PRODUCT: "Saage {{value}} kohta kõik teada. Igaüks leiab Bonduelle'i tootevalikust oma lemmiku. Külmutatud ja konservtooted.",
      MAGAZINE: {
        NEWS: '',
        ARTICLE: '',
        COOK: '',
        GROW: '',
        EXPLORE: '',
        RECIPE: ''
      },
      FAQ: 'Kõik vastused korduma kippuvatele küsimustele. Sa ei leia oma küsimusele vastust? Ära ole häbelik ja kirjuta meile!',
      PAGE: 'leht',
      RECIPETYPE: '{{value}}. Avastage retseptid ja uuenduslikud köögivilja valmistamise meetodid. Leiate retsepti igaks puhuks. Külmutatud ja konservtooted.',
      RECIPESEASON: '{{value}} – innustuge toiduvalmistamisest igal aastaajal. Avastage uued retseptid ja köögivilja valmistamise meetodid. Külmutatud ja konservtooted.',
      RECIPEPREPARATION: '{{value}} – avastage retseptid, innustuge kokkamisest. Ära murra rohkem pead, mida teha hommikueineks või õhtusöögiks koos sõpradega.',
      RECIPEDIFFICULTY: '{{value}} ärge kartke katsetada ja proovida uusi retsepte. Igaüks võib valmistada. Külmutatud ja konservtooted.',
      RECIPEVEGETABLE: 'Valmistage tervislikult, kiiresti ja lihtsalt. Innustuge ja õppige köögivilju õigesti ette valmistama. Külmutatud ja konservtooted.',
      RECIPECOST: '{{value}}',
      RECIPEDISH: '{{value}} road igaühele ja igaks puhuks. Innustuge kokkamisest ja õppige köögivilju õigesti ette valmistama. Külmutatud ja konservtooted.',
      RECIPEDESC: '{{value}} innustuge kokkamisest ja avastage uued maitsed. Kerged ja maitsvad retseptid iga sündmuse puhuks. Külmutatud ja konservtooted.',
      '2RECIPEFACETS': '{{value}} kerged ja lihtsad retseptid igaühele ja igaks puhuks. Külmutatud ja konservtooted.',
      '4RECIPEFACETS': '{{value}} retseptid, mis võivad kõiki üllatada. Lihtsalt valmivad ja maitsvad retseptid igaks puhuks. Külmutatud ja konservköögiviljad.',
      PRODUCTSEASON: 'Meie lai köögiviljavalik annab teile võimaluse valmistada maitsvaid, vähese vaevaga valmivaid ja tasakaalustatud roogi {{value}}. Külmutatud ja konservtooted.',
      PRODUCTTECHNO: '{{value}} – tooted, mis on liigitatud vastavalt valmistamistehnoloogiale. Lai köögiviljavalik annab võimaluse valmistada maitsvaid, vähese vaevaga valmivaid ja tasakaalustatud roogi.',
      PRODUCTVEGETABLE: '{{value}} – nende köögiviljadega roogi ei ole veel kunagi olnud nii lihtne valmistada! Lai köögiviljavalik annab võimaluse valmistada maitsvaid, vähese vaevaga valmivaid ja tasakaalustatud roogi.',
      PRODUCTRANGE: '{{value}} – nende köögiviljadega roogi ei ole veel kunagi olnud nii lihtne valmistada! Lai köögiviljavalik annab võimaluse valmistada maitsvaid, vähese vaevaga valmivaid ja tasakaalustatud roogi.',
      THEME_ALL: 'Meie lai köögiviljavalik annab võimaluse koostada maitsvaid ja tasakaalustatud retsepte. Igaüks avastab oma lemmiktoote.',
      THEME_STARTER: 'Suupisted: lihtne valmistada, maitsvad ja sobivad igal puhul kasutada. Lihtsad ja maitsvad retseptid. Külmutatud ja konservtooted.',
      THEME_SALAD: "Bonduelle'i köögiviljavalikuga on salatite valmistamine lihtsam kui kunagi varem! Lihtsad ja maitsvad retseptid. Külmutatud ja konservtooted.",
      THEME_CAN: 'Köögiviljad on saadaval aastaringselt. Nende köögiviljadega ei ole toiduvalmistamine veel kunagi nii lihtne olnud. Külmutatud ja konservtooted.',
      THEME_FROZEN: 'Tooted, millest toitu valmistada ei ole kunagi nii lihtne olnud. Köögiviljad, mis on saadaval aastaringselt. Külmutatud ja konservtooted.',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: 'Meie lai köögiviljavalik annab võimaluse koostada maitsvaid, lihtsaid ja tasakaalustatud retsepte. Külmutatud ja konservtooted.',
      THEME_RECIPE: 'Lugege siit, kuidas köögivilju kiiresti, maitsvalt ja lihtsalt valmistada. Innustuge kokkamisest ja loomingust. Külmutatud ja konservtooted.',
      THEME_PLATE: 'Maitsvad ja kergesti valmivad põhiroad igaks puhuks. Innustuge kokkamisest ja loomingust. Külmutatud ja konservtooted.',
      THEME_ACC: 'Loomingulised garneeringuretseptid Kergeid ja vähese vaevaga valmivaid retsepte igaühele. Innustuge kokkamisest ja loomingust. Külmutatud ja konservtooted.',
      THEME_SNACK: 'Kiired ja lihtsad suupisted igaks puhuks. Innustuge kokkamisest ja loomingust. Külmutatud ja konservtooted.',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': 'Meie lai köögiviljavalik annab võimaluse koostada maitsvaid, vähese vaevaga valmivaid ja tasakaalustatud retsepte. {{value}} Innustuge kokkamisest ja loomingust.',
      NOTFOUND: 'Kahjuks lehte ei leitud. Proovige uut otsingut või proovige hiljem uuesti. Külmutatud ja konservtooted.'
    },
    PIPE: { SOCIETY: ' | Bonduelle' },
    THEME_ALL: 'Kõik',
    THEME_STARTER: "Bonduelle'i suupisted",
    THEME_SALAD: "Bonduelle'i salatid",
    THEME_CAN: "Bonduelle'i konservtooted",
    THEME_FROZEN: "Bonduelle'i külmutatud tooted",
    THEME_READY_MEALS: '',
    THEME_BIO: '',
    THEME_VEGETABLES: "Bonduelle'i köögiviljad",
    THEME_RECIPE: 'Koostisosad – retseptid',
    THEME_PLATE: "Bonduelle'i põhiroad",
    THEME_ACC: "Bonduelle'i garneering",
    THEME_SNACK: "Bonduelle'i suupisteid igaühele"
  },
  VEGETABLE: {
    DISCOVER_VEGETABLE: 'Avasta meie aedviljad',
    OURS_RECIPES: 'Meie retseptid',
    DISCOVER_ALSO: 'Avasta lisaks ...',
    SEE_ALL: 'Kõik aedviljad',
    ON: 'Aedviljad koos'
  },
  RECIPE: {
    'INGREDIENTS_LABEL ': 'Koostisained {{value}} portsjoni jaoks',
    PEOPLES: '{{value}} inimesele',
    DIFFICULTY: { EASY: 'Kerge', MEDIUM: 'Keskmine', HARD: 'Keerukas' },
    COST: { CHEAP: 'Odav', EXPENSIVE: 'Kallis' },
    TIMING: { MIN: 'Minutit', HOURS: 'Tundi' },
    STEP: 'Samm',
    DISCOVER_ALSO: 'Avasta lisaks ...',
    HOME_SEE_MORE: 'Rohkem retsepte',
    SHOWCASE: 'Top',
    HOME_BOOK: 'Meie retseptid',
    MIN_MORE: 'm ja +',
    HOUR_MORE: 'h ja +',
    FILTER_BY_CRITERIA: 'Filtreerimisalus',
    SORT_BY: 'Sorteeri',
    BEST_RATED: 'Parima hinde saanud',
    MOST_CONSULTED: 'Enim vaadatud',
    MEMBERS_RECIPES: 'Teie retseptid',
    CRITERIA: 'Uudised',
    DISCOVER: { RECIPES: 'Avasta kõik meie retseptid' },
    DISCOVER_RECIPE: 'Avasta retsept',
    INSTRUCTIONS: 'Juhendid',
    DEFAULT_TAG: 'Retseptiidee',
    MOST_RECENT: 'Kõige uuem',
    RECIPE_VIDEOS: 'Retsepti video',
    PRODUCT_ASSOCIATE: 'Retsept, milles kasutatakse valmistamisel',
    BUDGET_LOW: 'Odav',
    BUDGET_HIGH: 'Kallis',
    BUDGET_MIDDLE: 'Keskmine',
    PRINT_RECIPE: 'Prindi',
    LET_YOURSELF_SURPRISED: 'Lase end üllatada',
    COOK_TIP_TITLE: 'Valmistussoovitus',
    ENJOY_MEAL: 'Head isu!',
    FILTER: { LEGEND: 'Legend' }
  },
  PREDIGGO: {
    DIFFICULTY: { EASY: 'Kerge', MEDIUM: 'Keskmine', HARD: 'Keerukas' }
  },
  COOKIES: {
    TEXT: 'Kodulehe sujuvaks toimimiseks, sinu paremaks lehitsemiskogemuseks ja turunduse eesmärgil kasutame küpsiseid. Lehitsemist jätkates nõustud küpsiste salvestamisega.',
    CLOSE: 'Sule',
    MORE: 'Uuri lähemalt',
    AGREE_YOUTUBE: "Video vaatamiseks tuleb nõustuda YouTube'i küpsistega.",
    BUTTON_YOUTUBE: 'Nõustun küpsistega'
  },
  POPUP: { LAUNCH: 'POPUP-OUVERTURE-SITE' },
  FIELD_PLATE: { DISCOVER_VEGETABLE: 'Avasta meie aedviljad' },
  SEARCH: {
    OUR_RECIPES: 'Retseptid',
    OUR_PRODUCTS: 'Tooted',
    OUR_ARTICLES: 'Postitused',
    RECIPES: 'Retseptid',
    PRODUCTS: 'Tooted',
    ARTICLES: 'Postitused',
    ALL: 'Kõik',
    SORT_BY: 'Sorteeri',
    RESULTS: 'tulemust',
    RESULT: 'tulemus',
    PRODUCT_OUT: 'toodet',
    APPLY: 'Kohalda',
    FILTER: 'Filter',
    RECIPE_OUT: 'retseptid',
    NO_RESULT: 'Oih ... Väga keerukas küsimus!',
    SEE_MORE: 'Näita rohkem',
    DISCOVER_RECIPES: 'Avasta meie retsepte',
    SEE_MORE_OF: 'näita rohkem',
    DELETE: 'kustuta'
  },
  RECIPE_LIST: {
    OUR_RECIPES: 'Retseptid',
    RESULTS: 'Tulemust',
    SEE_MORE: 'Näita rohkem retsepte'
  },
  PRODUCT: {
    BUTTON: {
      ACTU: 'tooteuudised',
      FOUND: 'Kust seda toodet leida',
      DISCOVER: 'avasta kogu tootevalik'
    },
    NUTRITIONAL_VALUE: 'toiteväärtus',
    CONDITIONING: 'Kogus',
    PORTIONS: 'portsjoneid',
    NET_WEIGHT: 'netokogus',
    DRY_WEIGHT: 'netomass ilma vedelikuta',
    INGREDIENTS: 'koostisosad',
    OUR_RECIPES: 'Avasta meie retsepte',
    FOR: '',
    PER_PORTION: 'ja portsjonit',
    ENERGY: 'energiasisaldus',
    FAT: 'rasvad',
    SATURATE: 'küllastunud rasvhapped',
    GLUCIDES: 'süsivesikud',
    FIBER: 'kiudained',
    PROTEINS: 'valgud',
    SALT: 'sool',
    DISCOVER_ALSO: 'avasta lisaks ...',
    MORE_INFORMATION: 'rohkem teavet',
    TRACES: 'Võib sisaldada',
    CONTAINS: 'Koosneb',
    SLIDER: { SHEET: 'Toote kirjeldus', BUY: 'osta see toode' },
    NUTRISCORE: 'toiteväärtuse info',
    TRIMAN: '',
    AGRICONFIANCE: '',
    BIO: 'info-bio',
    PESTICIDE_FREE: '',
    LEAF: '',
    LOCK: '',
    SEE_ALL: 'Vaata kõiki tooteid',
    LEGISLATION: 'kehtivate õigusaktide järgi',
    PORTION: { SINGULAR: 'portsjon', PLURAL: 'portsjonit' },
    DISCLAIMER: '',
    COOKING: 'valmistamisviis',
    CONSERVATION: 'säilitustingimused'
  },
  FAQ: {
    ON: 'küsimused',
    ALL: 'Kõik',
    SEE_MORE: 'vaata rohkem küsimusi',
    TITLE_1: 'Kõik vastused küsimustele',
    TITLE_2: 'Kas toote kohta on küsimusi?',
    LINK_LABEL: 'Küsi'
  },
  FORM: {
    GO_TO_STEP: 'Minema',
    STEP: 'Samm',
    RECEIVE_EMAIL_COPY: 'Saada koopia e-posti',
    SEND: 'Saada',
    GDPR: 'Lähemalt sinu andmete töötluse kohta',
    ERROR: 'Kõik tärniga tähistatud väljad tuleb täita',
    SEND_ERROR: 'Viga, proovi mõne minuti pärast uuesti',
    NOTICE: 'Meiega kontakteerudes esitate meile oma isikuandmeid. Teie isikuandmete vastutav töötleja on Bonduelle Polska S.A., kelle kontor on registreeritud Poolas Varssavis. Rohkem teavet teie isikuandmete töötlemise ja kaitse kohta leiate meie isikuandmete puutumatust käsitlevast poliitikast.',
    SENDING: '',
    LOADING: 'Laadin ...',
    PRODUCT_INFORMATION_TITLE: 'Info toote kohta',
    PRODUCT_INFORMATION: {
      SEO_TITLE: 'Info toote kohta | Bonduelle',
      SEO_DESC: 'Võta meiega ühendust'
    },
    INFORMATIONS: 'SINU TEAVE',
    MISTER: 'Härra',
    MISS: 'Proua',
    NAME: 'Perekonnanimi',
    OUR_NAME: 'Teie perekonnanimi',
    FIRSTNAME: 'Nimi',
    OUR_FIRSTNAME: 'Teie nimi',
    MAIL: 'Sinu e-post',
    MAIL_PLH: 'post@aadress.com',
    MAIL_CONFIRM: 'kinnita e-posti aadress',
    MAIL_ERROR: 'märgi siia kehtiv e-posti aadress',
    MAIL_ERROR_CONFIRM: 'palun kinnita e-posti aadress',
    ADDRESS: 'sinu aadress',
    STREET_PLH: '',
    STREET: 'Tänav ja maja number',
    ADDRESS_COMPLEMENT: 'rohkem infot sinu aadressi kohta',
    ADDRESS_COMP_PLH: 'rohkem infot sinu aadressi kohta',
    ZIPCODE: 'sihtnumber',
    CITY: 'linn',
    CITY_PLH: 'sinu linn',
    PHONE: 'telefon',
    PHONE_PLH: 'telefoninumber',
    COUNTRY: 'riik',
    PRODUCT_INFORMATIONS: 'TOOTEINFO',
    PRODUCT_INFORMATION_DESC: 'Et saaksime sinu taotlust korralikult menetleda, märgi järgmine info:',
    PRODUCT: {
      DETAIL: {
        DESC: 'Täida päringu esitamisel vastavad väljad ja esita näitena foto. Fotol peavad olema näha EAN-kood, partii number ja kõlblikkusaeg.'
      },
      NOTE: {
        TITLE: 'Toote kommentaar',
        SEO_TITLE: 'Toote ülevaade | Bonduelle',
        SEO_DESC: 'Kas soovid jätta meie toote kohta märkuse või kommentaari? Võta ühendust meie kliendiinfoga.'
      }
    },
    PRODUCT_RANGE: 'Uuritavate toodete valik',
    PRODUCT_NAME: 'Toote nimetus',
    PRODUCT_NAME_PLH: "Kirjuta Bonduelle'ile selle toote nimetus, mille kohta taotlus on esitatud",
    PRODUCT_INFORMATIONS_DETAIL: 'ÜKSIKASJALIK INFORMATSIOON',
    PRODUCT_INFORMATIONS_LABEL: 'Toote vöötkood',
    PRODUCT_INFORMATIONS_PLH: 'Vöötkood koosneb 13 numbrist, mis asuvad toote pakendil.',
    PRODUCT_LOT: 'Toote partiinumber',
    PRODUCT_LOT_INFO: 'Partii number algab tähega L, millele järgnevad pakendil märgitud numbrid. Näiteks: L72450492107:13',
    PHOTO: 'Lisa foto',
    EXPIRED_DATE: 'Kõlblikkusaeg',
    EXPIRED_DATE_ERROR: 'Sisesta kuupäev nii: PP/KK/AAAA',
    EXPIRED_DATE_DESC: 'Kõlblikkusaeg on märgitud pakendil',
    PHOTO_MORE: 'Rohkem fotosid',
    MESSAGE: 'SINU SÕNUM *',
    OUR_MESSAGE: 'Kirjuta sõnum',
    SALES_OPERATIONS: {
      TITLE: 'Ostuinfo',
      SEO_TITLE: 'Ostuinfo | Bonduelle',
      SEO_DESC: "Ostuinfo saamiseks võta ühendust meie klienditeenindusega. Võta ühendust Bonduelle'i restoranimeeskonnaga"
    },
    PRESS_SERVICE: '',
    FOOD_SERVICE: "Võta ühendust Bonduelle'i restoranimeeskonnaga",
    BUSINESS_INFORMATION: '',
    MODAL_INFORMATION: '',
    JOB_OFFER: { TITLE: '', SEO_TITLE: '', SEO_DESC: '' },
    WEB_SUGGESTION: {
      TITLE: 'Esita ettepanek veebilehe kohta',
      SEO_TITLE: 'Arengusoovitused või ettepanekud kodulehe kohta | Bonduelle',
      SEO_DESC: 'Võta ühendust meie klienditeenindusega, et aidata meil paremaks saada.'
    },
    ANOTHER: {
      TITLE: 'Esita ettepanek',
      SEO_TITLE: 'Võta ühendust muude ettepanekute | Bonduelle',
      SEO_DESC: 'Informatsiooni, nõuannete või pakkumiste saamiseks võta ühendust meie klienditeenindusega.'
    },
    AMBASSADOR: '',
    CATEGORY: {
      SALAD: 'Salat',
      FROZEN: 'Külmutatud',
      CAN: 'Konserveeritud',
      TAKEAWAY: '',
      VEGETABLE: ''
    }
  },
  CONTACT: {
    TITLE: 'Teie teenistuses',
    SPECIAL_INFO: '',
    TAKE_CARE: '',
    BY_MAIL: 'Posti teel',
    BY_PHONE: 'Telefoni teel',
    BY_SOURDLINE: '',
    MAIL_TEXT: 'baltics@bonduelle.com\n' +
      '\n' +
      'Meiega kontakteerudes esitate meile oma isikuandmeid. Teie isikuandmete vastutav töötleja on Bonduelle Polska S.A., kelle kontor on registreeritud Poolas Varssavis. Rohkem teavet teie isikuandmete töötlemise ja kaitse kohta leiate meie isikuandmete puutumatust käsitlevast poliitikast.',
    PHONE_TEXT: 'Info telefonil +370 6117 0170\nVastame esmaspäevast reedeni kell 9–16.',
    SOURDLINE_TEXT: '',
    BY_DISTRIBUTOR: '',
    BY_DISTRIBUTOR_TEXT: '',
    SUBTITLE: 'Sa soovid meiega ühendust võtta järgmisel teemal:',
    AGREEMENT: '',
    READ_MORE: 'Lisaks on teil õigus igal ajal nõuda, et BONDUELLE võimaldaks teil teie isikuandmetele juurde pääseda, neid\r\n' +
      'parandada, kustutada ja teisaldada, samuti piirata ja vaidlustada isikuandmete töötlemist. Isikuandmetega\r\n' +
      'seotud õiguste kasutamiseks kirjutage palun Dpo_france(ätt)bonduelle(punkt)com või posti teel järgmisele\r\n' +
      'aadressile: andmekaitseametnikule, Bonduelle, Rue Nicolas Appert, PO Box 30173, F-59653 Villeneuve D&#39;Ascq.\r\n' +
      'Lisainfot selle kohta, kuidas BONDUELLE teie isikuandmeid kogub ja kasutab, klõpsake siin\\',
    READ_MORE_LABEL: 'Loe lisaks',
    SATISFACTION_TEXT: 'Selleks, et teiega ühendust võtta, et edastada teile toote kohta nõutud\n' +
      'teave ja saata teile soovi korral rahuloluküsimustik, töötleme esitatud\n' +
      'teavet vastutava töötlejana. Teie taotluse töötlemise õiguslik alus on\n' +
      'nõusolek seoses võimalike tervisega seotud andmetega, mida võite oma\n' +
      'taotluses esitada. Seda teavet säilitatakse nii kaua, kui see on vajalik teie\n' +
      'taotluse töötlemiseks ja kuni 5 aastat, et tõendada, et teie taotlus on\n' +
      'töödeldud, ja täita meie juriidilisi kohustusi. Rahuloluküsimustiku\n' +
      'saatmise õiguslikuks aluseks on ka teie nõusolek. Seda teavet säilitatakse\n' +
      'rahuloluküsimustiku saatmiseks vajaliku aja ja maksimaalselt 1 aasta.\n' +
      'Isikuandmete töötlemise kohta lisateabe saamiseks tutvuge meie\n' +
      '<a href="https://www.bonduelle.ee/privaatsuspoliitika" target="_blank" class="underline">privaatsuspoliitikaga</a>.',
    SATISFACTION_CHECKBOX: 'Märkides selle kasti, nõustute saama rahuloluküsimustiku (koosneb ühest\n' +
      'küsimusest), kui teie päring on töödeldud. See on valikuline.',
    CHECKBOX_TEXT: 'Märkides selle kasti, nõustute, et Bonduelle võib töödelda isikuandmeid,\n' +
      'mille võite oma sõnumis esitada, ainult teie taotlusele vastamise\n' +
      'eesmärgil. <strong>See on teie taotluse töötlemiseks kohustuslik</strong>.',
    PHONE: 'Vastame esmaspäevast reedeni kell 9–16! Kõnele ei lisandu täiendavat tasu.',
    CALL_US: 'Helista meile',
    TO_WRITE: 'Kirjuta meile',
    PHONE_NUMBER: '37061170170',
    SUCCESS_MESSAGE: 'Aitäh! Sinu sõnum on saadetud.',
    PHOTOERROR: { MESSAGE: '' }
  },
  GLOBAL: { READ_MORE: '' },
  FLOWBOX: { GENERIC_KEY: '', PRODUCT_KEY: '', RECIPE_KEY: '', HOME_KEY: '' },
  TOKEN: {
    FACEBOOK: '',
    PAGE_FACEBOOK_ID: '',
    INSTAGRAM: '',
    INSTA_ID: '17841405863491030',
    YOUTUBE: '',
    TWITTER: '',
    MAGAZINE: ''
  },
  COOKIE: {
    BANNER: {
      MANDATORY: 'Kohustuslikud',
      ANALYTICS: 'Funktsionaalsed',
      MARKETING: 'Turunduslikud',
      SOCIAL_MEDIA: 'Sotsiaalsed',
      ACCEPT: 'Kinnita kõik küpsised',
      REJECT: 'Mitte nõustuda',
      SET_UP: 'Muuta küpsiste seadeid',
      MANDATORY_DETAILS: 'Kohustuslikud küpsised on vajalikud veebilehe nõuetekohase toimimise tagamiseks. Neid küpsiseid kasutatakse statistilise analüüsi ja veebilehe liikluse haldamise eesmärkidel, näiteks selleks, et suurendada veebilehe kasutamise mugavust ja oma teenuste aktuaalsust, analüüsida teavet selle kohta, kuidas külastajad kasutavad veebilehte, vastavalt külastatavatele lehtedele, külastajate arvule jne. Lisaks kasutab Google Analytics neid küpsiseid, mis võimaldavad jälgida enimkülastatavaid veebilehti ja veebilehe voogusid.',
      ANALYTICS_DETAILS: 'Funktsionaalseid küpsiseid kasutatakse tehnilistel põhjustel, näiteks selleks, et identifitseerida seansse, jätta meelde seadeid, huvisid, jälgida Teie sisendeid, kui täidate internetis vorme jne. ',
      MARKETING_DETAILS: 'Turunduse küpsised võimaldavad meil jälgida teie retseptide ja toodete eelistusi, et saaksime pakkuda teile kõige enam sobivaid tooteid. Lisaks kasutatakse neid ka selleks, et jagada infot reklaamipakkujatega teistes veebilehtedes, et oleks võimalik pakkuda teile aktuaalseid reklaamteateid, mis vastavad Teie huvidele.',
      V2: 'ok'
    },
    MODAL: {
      TITLE: 'Küpsiste seaded',
      TEXT: 'BONDUELLE Group kohustub tagama oma veebilehtede, eriti Bonduelle.ee kasutajate isikuandmete kaitse. Et saaksite kasutada veebilehe pakutavaid teenuseid, nagu retseptide ja toodete otsing, kasutatakse veebilehel küpsiseid. BONDUELLE kasutab nõuetekohaseid tehnilisi ja organisatoorseid vahendeid, mis aitavad efektiivselt viia ellu isikuandmete kaitsmise põhimõtteid, järgida GDPR-i nõudeid ja kaitsta andmesubjektide õigusi ja vabadusi alates andmete kogumise algusest kogu andmete töötlemise aja. Teil on ka õigus mitte nõustuda Teie isikuandmete töötlemisega,  kasutades küpsiseid. ',
      CONFIG: 'Muuda',
      SAVE: 'Salvesta'
    }
  },
  LINK: { JOB_OFFERS: '' },
  CLIC2BUY: { INFOS: '' }
};
