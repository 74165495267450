import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
    selector: 'sf-expansion-panel',
    templateUrl: './expansion-panel.component.html',
    styleUrls: ['./expansion-panel.component.sass'],
    animations: [
        trigger('expansionAnimations', [
            state('open', style({
                height: '*'
            })),
            state('close', style({
                height: '0px'
            })),
            transition('* => *', animate('0.3s ease-out'))
        ]),
        trigger('toggleAnimations', [
            state('open', style({
                transform: 'rotate(90deg)'
            })),
            state('close', style({
                transform: 'rotate(0deg)'
            })),
            transition('* => *', animate('0.15s'))
        ])
    ],
    standalone: false
})
export class SfExpansionPanelComponent implements OnChanges {

  @Input() title: string;

  @Input() alwaysOpen: boolean;

  @Input() seoTitle: 'H1'|'H2'|'H3'|'DIV' = 'DIV';

  public state: 'open' | 'close' = 'close';

  constructor() { }

  public toggle() {
    if (!this.alwaysOpen) {
      this.state = this.state === 'close' ? 'open' : 'close';
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName of Object.keys(changes)) {
      if (propName === 'alwaysOpen') {
        const value: boolean = !!changes[propName].currentValue;
        this.state = value ? 'open' : 'close';
      }
    }
  }

}





