import { SfSection } from '@app/sf-lib/public_api';
import { SomBloc } from "./bloc.model";

export class SomHighlight extends SomBloc {

  public title : string;

  public sections : SfSection[];

  public sfType: string;

  constructor() {
    super();
    this.sections = [];
    this.title = '';
    this.sfType = 'SomHighlights';
  }
}
