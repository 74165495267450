<div class="sf-ep">
  <div class="sf-ep-header" [ngClass]="{'sf-ep-header-clickable':!alwaysOpen}" (click)="toggle()">

    <!-- Title -->
    <ng-container *ngIf="title" [ngSwitch]="seoTitle">
      <h2 *ngSwitchCase="'H1'" class="sf-ep-header-title">{{title}}</h2>
      <h2 *ngSwitchCase="'H2'" class="sf-ep-header-title">{{title}}</h2>
      <h3 *ngSwitchCase="'H3'" class="sf-ep-header-title">{{title}}</h3>
      <div *ngSwitchDefault class="sf-ep-header-title">{{title}}</div>
    </ng-container>

    <!-- Title content -->
    <ng-content *ngIf="!title" class="sf-ep-header-title" select="[title]"></ng-content>
    <span class="sf-ep-header-toggle" *ngIf="!alwaysOpen">
      <mat-icon class="sf-ep-header-toggle-icon" svgIcon="arrow-right" [@toggleAnimations]="state"></mat-icon>
    </span>
  </div>

  <!-- Panel content -->
  <div class="sf-ep-container" [@expansionAnimations]="state">
    <ng-content class="sf-ep-content" select="[content]"></ng-content>
  </div>
</div>
