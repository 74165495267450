import { SfSlide, SfSlider } from '@app/sf-lib/public_api';
import { SomPage } from './page.model';

export class SomRecipeHomePage extends SomPage implements SfSlider {

  public slides: SfSlide[];

  constructor() {
    super();
    this.slides = [];
  }
}
