/* tslint:disable */export const es_es = {
  '404': {
    TITLE: 'Ups..',
    PAGENOTFOUND: 'Esta página no existe',
    BUTTON: 'Volver a la home',
    RECIPES: 'Descubre nuestras recetas'
  },
  HOME: {
    SHOWCASE: 'Recetas',
    CATCH_PHRASE: '',
    SHOWCASE_SEE_ALL: 'Ver todas nuestras recetas',
    MAGAZINE: { TITLE: 'DESTACADO', BUTTON: 'Descubre nuestro blog' },
    OUR_VEGETABLES: 'Nuestros Vegetales',
    SOCIAL_WALL: 'Juntos, compartimos la pasión por los vegetales',
    FAQ_TITLE: 'PREGUNTAS FRECUENTES',
    FAQ_SEE_MORE: 'VER MÁS',
    FLOWBOX: ''
  },
  SELECT: { DEFAULT_VALUE: 'Ver todos' },
  MENU: {
    CONTACT_US: 'Contacto',
    MY_ACCOUNT: 'Mi cuenta',
    GOOD_DEALS: 'Promociones',
    NEWSLETTER: 'Newsletter Bonduelle'
  },
  FOOTER: {
    LINK: {
      BONDUELLE: 'Bonduelle.es',
      CREDITS: 'Créditos',
      LEGAL_NOTICE: 'TÉRMINOS LEGALES',
      TERMS_OF_SALES: 'Condiciones de venta',
      COOKIES: 'Politica de Cookies',
      SITEMAP: '',
      PRIVACY_POLICY: 'Política de Privacidad',
      ALL_RIGHTS_RESERVED: '2020©  Todos los derechos reservados'
    },
    HISTORY: {
      TITLE: 'Bonduelle, desde 1853',
      TEXT: 'Bonduelle es una empresa familiar que trabaja desde hace 7 generaciones para desarrollar una producción agrícola respetuosa con la tierra y las personas. Defendemos una agroecología eficiente, inteligente y solidaria,  orientada al futuro e innovando día a día desde el campo hasta el plato para crear un futuro mejor a través de la alimentación vegetal.'
    },
    NEWSLETTER: {
      TITLE: 'La comunidad Bonduelle',
      TEXT: 'Acepto recibir la Newsletter " Todo sobre los vegetales de Bonduelle " (noticias, promoción, concursos, artículos...)',
      PLACEHOLDER: 'Tu email',
      GDPR_LINK: 'Más información sobre la gestión de datos'
    }
  },
  PRODUCTS: 'Productos',
  MAGAZINE: {
    HOME: 'Bienvenido a nuesto blog',
    CATCH_PHRASE: '',
    MENU: { BONDUELLE_LINK: 'Ir a Bonduelle.es' },
    SHOWCASE: 'En portada',
    PREVIOUS_ARTICLES: 'Artículos anteriores',
    PREVIOUS_ARTICLES_SEE_ALL: 'Ver todos nuestros artículos',
    PREVIOUS_EDITIONS: 'Ediciones anteriores',
    PREVIOUS_EDITIONS_SEE_ALL: 'Ver más ediciones',
    PREVIOUS_ARTICLES_BY_CATEGORY: 'Artículos anteriores por categoría',
    RUBRIC: {
      SEE_ALL: 'Ver todas nuestras recetas',
      SEE_ALL_ARTICLES: 'Ver todos nuestros artículos',
      ON: 'Articulos sobre '
    },
    RUBRIC_COOK: { ON: 'Recetas sobre' },
    ARTICLE: { JOIN_US: 'Síguenos', READ_ALSO: 'Lee también' },
    RECIPE: {
      INGREDIENTS: 'Ingredientes',
      INGREDIENTS_LABEL_DESC: ' {{value}} personas',
      INGREDIENTS_LABEL_ONE_DESC: ' {{value}} persona',
      INGREDIENTS_LABEL: 'Ingredientes  {{value}} personas',
      INGREDIENTS_LABEL_ONE: 'Ingredientes  {{value}} persona',
      PEOPLES: '{{value}} personas',
      PEOPLE: '{{value}} persona',
      DIFFICULTY: { EASY: 'Fácil', MEDIUM: 'Medio', HARD: 'Difícil' },
      COST: { CHEAP: 'Barato', EXPENSIVE: 'Caro' },
      TIMING: { MIN: 'min', HOURS: 'Horas' },
      STEP: 'Paso',
      DISCOVER_ALSO: 'Descubre también'
    }
  },
  ARTICLE: { DISCOVER_ALSO: 'Descubre además', INGREDIENTS: 'Ingredientes' },
  SF: {
    SLIDE: { DISCOVER: 'Descubre' },
    SEARCH: { PLACEHOLDER: 'Busca', ALL: 'Todo sobre' },
    NEWSLETTER: {
      BTN_LABEL: 'Suscribirse',
      PLACEHOLDER: 'Tu email',
      ERROR_MESSAGE: 'Email incorrecto',
      ALREADY_SUBSCRIBED: 'Ya estás registrado',
      GDPR: {
        TXT: 'Más información sobre la gestión de  los datos',
        LINK: '/politica-de-privacidad#gdpr'
      }
    },
    MONTHLY_FOLDER: { TITLE: 'Noticias del mes' },
    MINI_NEWSLETTER: {
      PLACEHOLDER: 'Tu email',
      GDPR: {
        TXT: 'Más información sobre cómo gestionar los datos',
        LINK: '/politica-de-privacidad#gdpr'
      }
    },
    SOCIALITEM: { SHARE: 'Compartir', COMMENT: 'Comenta', BONDUELLE: 'Bonduelle' },
    SEARCHRECIPE: { DISCOVER_ALL: '¡Busca entre todas las recetas!' },
    FORM: {
      ERROR: 'Campo obligatorio',
      PHOTO: { ADD: 'Añade una foto', DELETE: 'Eliminar la foto', SEE: 'Ver' }
    }
  },
  CORPORATE: {
    COMPANY_STORY: 'Nuestra historia',
    CSR_COMMITMENT: 'Compromiso RSC ',
    VEGETABLE_INNOVATIONS: 'El Grupo Bonduelle',
    NEWS: 'Todas nuestras noticias',
    SEE_MORE: 'Leer más artículos',
    ON: 'Articulos sobre'
  },
  NEWSLETTER: {
    TITLE: 'Subscríbete a la newsletter',
    SUBTITLE: 'Promociones, inspiración culinaria y un poco de cultura'
  },
  ROUTES: {
    products: 'productos',
    news: 'novedades',
    'since-1853': 'desde-1853',
    'field-plate': 'campo-hasta-plato',
    'company-story': 'nuestra-historia',
    'csr-commitments': 'compromiso-rsc',
    'vegetal-innovations': 'innovacion-vegetal',
    magazine: 'blog',
    all: 'ver-todo',
    article: 'articulo',
    cook: 'alimentacion',
    cultivate: 'tendencias',
    explore: 'sostenibilidad',
    recipe: 'preparacion-de-receta',
    recipes: 'recetas',
    'recipes-home': 'recetas-home',
    r: 'r',
    credits: 'creditos-es',
    'legal-notice': 'menciones-legales',
    'terms-of-sales': 'condiciones-de-venta',
    cookies: 'cookies',
    sitemap: 'sitemap-es',
    'privacy-policy': 'politica-de-privacidad',
    'all-rights-reserved': 'todos-derechos-reservados',
    search: 'buscar',
    'articles-search': 'articulo',
    vegetables: 'productos',
    p: 'p',
    contact: 'contacto',
    'info-product': 'informacion-producto',
    'note-product': 'nota-de-producto',
    suggestion: 'sugerencias',
    'other-request': 'otra-peticion',
    faq: 'preguntas-frecuentes',
    'all-themes': 'todos-remas',
    'products-search': 'buscar-productos',
    recipesearch: 'buscar-recetas',
    'product-news': 'novedades',
    'good-deals': 'promociones',
    'not-found': 'error',
    'menu-page': 'menu-page',
    'menu-one': 'menu-one',
    'menu-two': 'menu-two',
    'menu-three': 'menu-three',
    'menu-four': 'menu-four',
    'menu-external': 'menu-external',
    'theme-pasta-rice': '',
    'theme-bowls': '',
    'theme-soup': '',
    'theme-snack': '',
    'theme-sauce': '',
    'theme-unique-plate': '',
    'theme-over': '',
    'theme-legumin': 'legumin',
    'theme-ingredients-recipes': '',
    'theme-cook-plate': ''
  },
  BREAD: {
    products: 'productos',
    news: 'novedades',
    'since-1853': 'desde-1853',
    'field-plate': 'campo-hasta-plato',
    'company-story': 'nuestra-historia',
    'csr-commitments': 'compromiso-rsc',
    'vegetal-innovations': 'innovacion-vegetal',
    magazine: 'blog',
    all: 'ver-todo',
    article: 'articulo',
    cook: 'ALIMENTACIÓN',
    cultivate: 'tendencias',
    explore: 'sostenibilidad',
    recipe: 'preparacion-de-receta',
    recipes: 'recetas',
    'recipes-home': 'recetas home',
    'articles-search': 'articulo',
    search: 'buscar',
    vegetables: 'productos',
    contact: 'contacto',
    'info-product': 'informacion-producto',
    'note-product': 'nota-de-producto',
    suggestion: 'sugerencias',
    'other-request': 'otra-peticion',
    faq: 'preguntas-frecuentes',
    'all-themes': 'todos-remas',
    'products-search': 'buscar-productos',
    recipesearch: 'buscar-recetas',
    'product-news': 'novedades',
    'good-deals': 'promociones',
    'not-found': 'error',
    'menu-page': 'menu-page',
    'menu-one': 'menu-one',
    'menu-two': 'menu-two',
    'menu-three': 'menu-three',
    'menu-four': 'menu-four'
  },
  MENU_PAGE: { TITLE1: '', TITLE2: '', TITLE3: '', TITLE4: '', EXTERNAL: '' },
  TECHNICAL: { MENU_PAGE: '' },
  SEO: {
    TITLE: {
      MENU_ONE: '',
      MENU_TWO: '',
      MENU_THREE: '',
      MENU_FOUR: '',
      HOME: 'Bonduelle',
      SEARCH: {
        MAIN: '{{value}} | Bonduelle',
        RECIPES: '{{value}} ordenado por receta | Bonduelle',
        VEGETABLES: '{{value}} ordenado por producto | Bonduelle',
        ARTICLES: '{{value}} ordenado por articulo | Bonduelle'
      },
      CONTACT: 'Contacto de atención al cliente, información | Bonduelle',
      RECIPES: 'Receta de cocina: sopa, quiche, lasaña de verduras....  | Bonduelle',
      RECIPE_HOME: 'Receta para cocinar con vegetales para Navidad, boda, fiesta | Bonduelle',
      RECIPE: 'Receta {{value}} | Bonduelle',
      PRODUCTS: 'Vegetales en conserva y congelados para cocinar | Bonduelle',
      NEWS: 'Consejos, cocina, cómo preparar los vegetales | Bonduelle',
      CORPORATE: {
        NEWS: 'Últimos artículos, noticias sobre vegetales y cocina | Bonduelle',
        COMPANY_STORY: 'Presentación de la empresa | Bonduelle',
        CSR_COMMITMENTS: 'Valores/principios éticos y compromiso del grupo | Bonduelle',
        VEGETAL_INNOVATIONS: 'Innovación vegetal: nuevas explotaciones, productos | Bonduelle',
        FIELD_PLATE: 'Nuestros vegetales salen de la tierra | Bonduelle',
        ARTICLE: '{{value}} | Bonduelle',
        VEGETABLE: '{{value}}, descubre más sobre este vegetal | Bonduelle'
      },
      PRODUCT: '{{value}}  Vegetales cocidos Bonduelle',
      MAGAZINE: {
        NEWS: 'Noticias sobre vegetales, recetas para adultos y niños | Bonduelle',
        ARTICLE: '{{value}} Bonduelle',
        COOK: 'Recetas de cocina con vegetales fáciles y rápidas  | Bonduelle',
        GROW: 'Cultivar y mantener los vegetales en su huerto | Bonduelle',
        EXPLORE: 'Descubre nuevos vegetales y sabores para niños | Bonduelle',
        RECIPE: 'Receta {{value}} | Bonduelle'
      },
      FAQ: 'Q&As: Preguntas frecuentes | Bonduelle',
      NOTFOUND: 'Error 404, por favor inténtelo de nuevo más tarde | Bonduelle',
      RECIPES_ALL: 'Todas nuestras recetas de vegetales',
      RECIPETYPE: 'Recetas de cocina: {{value}} de vegetales',
      RECIPESEASON: 'Receta de vegetales de temporada: {{value}}.',
      RECIPEPREPARATION: 'Recetas de cocina: tiempo de preparación {{value}}.',
      RECIPEDIFFICULTY: 'Recetas fáciles de vegetales: {{value}} de vegetales',
      RECIPEVEGETABLE: 'Receta de cocina fácil a base de {{value}}',
      RECIPECOST: 'Receta de vegetales{{value}}.',
      RECIPEDISH: 'Recetas de cocina y vegetales para {{value}}',
      RECIPEDESC: 'Receta de cocina {{value}}.',
      '2RECIPEFACETS': 'Receta de cocina {{value}}.',
      '4RECIPEFACETS': 'Recetas de cocina {{value}}',
      PRODUCTSEASON: 'Recetas de vegetales fáciles de hacer: {{value}}',
      PRODUCTTECHNO: 'Receta de vegetales fáciles {{value}}',
      PRODUCTVEGETABLE: 'Receta fácil de vegetales a base de {{value}}',
      PRODUCTRANGE: 'Receta fácil de vegetales a base de {{value}}',
      THEME_ALL: 'Vegetales en conserva y congelados para cocinar',
      THEME_STARTER: 'Idea para cocinar recetas de entrantes con vegetales',
      THEME_SALAD: 'Ideas para cocinar una ensalada mixta en verano',
      THEME_CAN: '',
      THEME_FROZEN: '',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: '',
      THEME_RECIPE: 'Ideas para cocinar recetas fáciles y rápidas con vegetales',
      THEME_PLATE: 'Ideas  para cocinar platos fríos y calientes con vegetales',
      THEME_ACC: 'Ideas de vegetales para acompañar carnes y pescados',
      THEME_SNACK: 'Ideas de vegetales para recetas rápidas',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': 'Recetas de vegetales {{value}}'
    },
    DESCRIPTION: {
      MENU_ONE: '',
      MENU_TWO: '',
      MENU_THREE: '',
      MENU_FOUR: '',
      HOME: 'Vegetales en conservas y congelados. Descubre nuestros deliciosos vegetales, ideas de recetas para inspirar tus platos. Bonduelle: una fuente de deseo de vegetales y recetas.',
      SEARCH: {
        MAIN: 'Descubre nuestras ideas de recetas y vegetales para {{value}}. Recetas simples y sabrosas. ',
        RECIPES: 'Todos nuestros {{value}} ordenados por receta. Recetas simples y sabrosas. ',
        VEGETABLES: 'Todos nuestros {{value}} ordenados por vegetal. Recetas simples y sabrosas. Vegetales cocidos.',
        ARTICLES: 'Todos nuestros {{value}} ordenados por articulo. Recetas simples y sabrosas.'
      },
      CONTACT: '¿Tienes alguna pregunta? ¿Necesitas información sobre un producto, servicio u operación en curso? Puedes ponerte en contacto con nuestro servicio de atención al cliente. Te responderemos de forma personalizada en 48 horas',
      RECIPES: 'Descubre sabrosas recetas de cocina a base de vegetales. Haz feliz a los tuyos preparando recetas fáciles. Recetas simples y sabrosas. ',
      RECIPE_HOME: 'Descubre sabrosas recetas a base de vegetales para las fiestas. Sorprende a tus seres queridos con algo especial. Recetas simples y sabrosas. ',
      RECIPE: '',
      PRODUCTS: 'Nuestra amplia gama de productos te permite preparar recetas sabrosas, fáciles y equilibradas. Recetas simples y sabrosas. ',
      NEWS: 'Descubre cómo preparar los vegetaless, cocínalos eficientemente para crear platos sabrosos. Recetas simples y sabrosas. ',
      CORPORATE: {
        NEWS: 'Últimas noticias sobre el mundo de la agricultura, la innovación vegetal, los residuos y cómo comer mejor de una manera simple. Recetas simples y sabrosas. ',
        COMPANY_STORY: '¿Quieres saber más sobre el mundo de los vegetales y del mundo de la agricultura y hortalizas? Decúbre todo esto en nuestro blog. Recetas simples y sabrosas. ',
        CSR_COMMITMENTS: '¿Te preguntas cómo se cultivan nuestros vegetales, de dónde vienen, cómo respetamos el medio ambiente? Encuentra las respuestas a preguntas sobre nuestros vegetales aquí.',
        VEGETAL_INNOVATIONS: 'La innovación vegetal está en el centro de nuestras preocupaciones. Nuevas formas de cultivo más sostenibles, menos residuos ... Recetas simples y sabrosas. ',
        VEGETABLE: '',
        FIELD_PLATE: 'Descubre nuestras deliciosas recetas. Deleitarán a todos los paladares. Recetas simples y sabrosas.',
        ARTICLE: ''
      },
      PRODUCT: '{{value}} para preparar sabrosas recetas de cocina y disfrutar con tus seres queridos. Recetas simples y sabrosas. ',
      MAGAZINE: {
        NEWS: 'Todas las novedades, noticias sobre vegetales, recetas equilibradas. Recetas simples y sabrosas. ',
        ARTICLE: '',
        COOK: 'Nuestras deliciosas recetas deleitarán a todos los paladares. Descúbrelas en nuestra página web. Recetas simples y sabrosas.',
        GROW: '¿Quieres cultivar tus propio vegetales en tu huerto? Descubre nuestros consejos y trucos para cultivar y mantener tus vegetales. ',
        EXPLORE: 'Deja que tus hijos descubran nuevas variedades de vegetales  a través de recetas sencillas y eficaces. Recetas fáciles y sabrosas con vegetales.',
        RECIPE: ''
      },
      FAQ: '¿Tienes alguna pregunta? Hemos agrupado todas las preguntas más frecuentes para responder mejor a tus sugerencias.',
      PAGE: 'página',
      RECIPETYPE: 'Descubre nuestras recetas {{value}} y cómo cocinar correctamente los vegetales. Receta vegetariana, rápida, original, fácil.',
      RECIPESEASON: 'Descubre nuestras recetas de cocina y cómo cocinar bien los vegetales por {{value}}. Receta vegetariana, rápida, original, fácil.',
      RECIPEPREPARATION: 'Descubre nuestras recetas de cocina y cómo cocinar bien los vegetales por {{value}}. Receta vegetariana, rápida, original, fácil.',
      RECIPEDIFFICULTY: 'Descubre nuestras recetas de cocina y cómo cocinar vegetales {{value}}. Receta vegetariana, rápida, original, fácil.',
      RECIPEVEGETABLE: 'Descubre nuestras recetas para cocinar {{value}} y cómo cocinar correctamente los vegetales. Receta vegetariana, rápida, original, fácil.',
      RECIPECOST: 'Descubre nuestras recetas de cocina y cómo cocinar los vegetales correctamente para {{value}}. Receta vegetariana, rápida, original, fácil.',
      RECIPEDISH: 'Descubre nuestras recetas  y cómo cocinar bien los vegetales para {{value}} los invitados. Receta vegetariana, rápida, original, fácil.',
      RECIPEDESC: 'Descubre nuestras recetas de cocina y cómo cocinar vegetales {{value}}. Receta vegetariana, rápida, original, fácil.',
      '2RECIPEFACETS': 'Descubre nuestras recetas de cocina y cómo cocinar vegetales {{value}}. Receta vegetariana, rápida, original, fácil.',
      '4RECIPEFACETS': 'Descubre nuestras recetas de cocina y cómo cocinar bien los vegetales {{value}}',
      PRODUCTSEASON: 'Nuestra amplia gama de productos te permiten preparar recetas de temporada {{value}} sabrosas, fáciles, y equilibradas. Recetas simples y sabrosas. ',
      PRODUCTTECHNO: 'Nuestra amplia gama de productos te permite preparar recetas de temporada {{value}} sabrosas, fáciles, y equilibradas. Recetas simples y sabrosas. ',
      PRODUCTVEGETABLE: 'Nuestra amplia gama de productos te permite preparar recetas sabrosas, fáciles y equilibradas basadas en {{{value}}. Recetas simples y sabrosas. ',
      PRODUCTRANGE: 'Nuestra amplia gama de productos te permite preparar recetas sabrosas, fáciles y equilibradas basadas en {{{value}}. Recetas simples y sabrosas. ',
      THEME_ALL: 'Nuestra amplia gama de productos te permite preparar recetas sabrosas, fáciles y equilibradas. Recetas simples y sabrosas. ',
      THEME_STARTER: '¿Quieres cocinar una comida equilibrada? Una amplia gama de productos para cocinar de forma rápida y sencilla recetas de entrantes a base de vegetales, consejos y trucos para mejorar tus comidas.',
      THEME_SALAD: '¿Quieres cocinar una comida equilibrada? Una amplia gama de  productos para cocinar ensaladas rápidas y fáciles, consejos y trucos para mejorar sus comidas.',
      THEME_CAN: '',
      THEME_FROZEN: '',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: '',
      THEME_RECIPE: 'Productos para cocinar de forma fácil y rápida deliciosas y equilibradas recetas basadas en vegetales , consejos y trucos para mejorar sus comidas. Recetas rápidas y equilibradas',
      THEME_PLATE: '¿Quieres cocinar una comida equilibrada? Productos para cocinar platos calientes o fríos a base de vegetales fáciles y rápidos, consejos y trucos para mejorar sus comidas.',
      THEME_ACC: 'Productos para cocinar guarniciones rápidas y fáciles a base de vegetales, consejos y trucos para mejorar sus comidas. Recetas simples y equilibradas.',
      THEME_SNACK: '¿Quieres cocinar una comida equilibrada? Productos para cocinar recetas de aperitivos a base de vegetales de forma fácil y rápida, consejos y trucos para mejorar sus comidas.',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': 'Nuestra amplia gama de productos te permite preparar recetas sabrosas, fáciles y equilibradas {{value}}. Recetas simples y sabrosas. ',
      NOTFOUND: 'No se ha encontrado la página solicitada. Te invitamos a que hagas una nueva búsqueda o a que empieces de nuevo más tarde.'
    },
    PIPE: { SOCIETY: ' | Bonduelle' },
    THEME_ALL: 'Todos',
    THEME_STARTER: 'Entrantes',
    THEME_SALAD: 'Todo para mis ensaladas',
    THEME_CAN: '',
    THEME_FROZEN: '',
    THEME_READY_MEALS: '',
    THEME_BIO: '',
    THEME_VEGETABLES: '',
    THEME_RECIPE: 'Ingredientes-recetas',
    THEME_PLATE: 'plato principal',
    THEME_ACC: 'Acompañamiento',
    THEME_SNACK: 'Snacks'
  },
  VEGETABLE: {
    DISCOVER_VEGETABLE: 'Descubre nuestros vegetales',
    OURS_RECIPES: 'Nuestras recetas de cocina',
    DISCOVER_ALSO: 'Descubre también...',
    SEE_ALL: 'Ver todos nuestros vegetales',
    ON: 'Vegetales sobre'
  },
  RECIPE: {
    'INGREDIENTS_LABEL ': 'Ingredientes para {{value}} personas',
    PEOPLES: '{{value}} personas',
    DIFFICULTY: { EASY: 'Fácil', MEDIUM: 'Media', HARD: 'Díficil' },
    COST: { CHEAP: 'Barato', EXPENSIVE: 'Caro' },
    TIMING: { MIN: 'min', HOURS: 'horas' },
    STEP: 'etapa',
    DISCOVER_ALSO: 'Descubre también',
    HOME_SEE_MORE: 'Descubre más recetas',
    SHOWCASE: 'Top',
    HOME_BOOK: 'Nuestras recetas',
    MIN_MORE: 'm y +',
    HOUR_MORE: 'h y +',
    FILTER_BY_CRITERIA: 'Filtrar por criterios',
    SORT_BY: 'Ordenar por',
    BEST_RATED: 'Mejor valorados',
    MOST_CONSULTED: 'Los más leidos',
    MEMBERS_RECIPES: 'Recetas de los socios',
    CRITERIA: 'Noticias',
    DISCOVER: { RECIPES: 'Descubre nuestras recetas' },
    DISCOVER_RECIPE: 'Descubre la receta',
    INSTRUCTIONS: 'Instrucciones',
    DEFAULT_TAG: 'Idea de receta',
    MOST_RECENT: 'Las más recientes',
    RECIPE_VIDEOS: 'Video recetas',
    PRODUCT_ASSOCIATE: 'Receta hecha con',
    BUDGET_LOW: 'Barato',
    BUDGET_HIGH: 'Alto',
    BUDGET_MIDDLE: 'Medio',
    PRINT_RECIPE: 'Imprimir la receta',
    LET_YOURSELF_SURPRISED: 'Déjate sorprender',
    COOK_TIP_TITLE: '',
    ENJOY_MEAL: '',
    FILTER: { LEGEND: 'Leyenda' }
  },
  PREDIGGO: { DIFFICULTY: { EASY: 'Fácil', MEDIUM: 'Media', HARD: 'Díficil' } },
  COOKIES: {
    TEXT: 'Al continuar su visita a este sitio, usted acepta el uso de cookies por razones técnicas, con fines de análisis estadístico y de gestión del tráfico web, para compartir información con anunciantes en otros sitios web, con el fin de proporcionarle comunicaciones promocionales relevantes que reflejen sus intereses.',
    CLOSE: 'Cerrar',
    MORE: 'Leer más',
    AGREE_YOUTUBE: 'Debe aceptar las cookies de Youtube para ver este video',
    BUTTON_YOUTUBE: 'Aceptar cookies'
  },
  POPUP: { LAUNCH: 'POPUP-OUVERTURE-SITE' },
  FIELD_PLATE: { DISCOVER_VEGETABLE: 'Descubre nuestras fichas de productos' },
  SEARCH: {
    OUR_RECIPES: 'Nuestras recetas',
    OUR_PRODUCTS: 'Nuestros vegetales',
    OUR_ARTICLES: 'Nuestros artículos',
    RECIPES: 'Recetas',
    PRODUCTS: 'Productos',
    ARTICLES: 'Artículos',
    ALL: 'Ver todo',
    SORT_BY: 'Ordenar por',
    RESULTS: 'Resultados',
    RESULT: 'Resultado',
    PRODUCT_OUT: 'Productos con',
    APPLY: 'Aplicar',
    FILTER: 'Filtro',
    RECIPE_OUT: 'Receta con',
    NO_RESULT: 'Ups, no hemos encontrado nada ¡Vaya pregunta tan difícil!',
    SEE_MORE: 'Ver más',
    DISCOVER_RECIPES: 'Descubre nuestras recetas',
    SEE_MORE_OF: 'Ver más sobre',
    DELETE: 'Eliminar'
  },
  RECIPE_LIST: {
    OUR_RECIPES: 'Recetas',
    RESULTS: 'Resultados',
    SEE_MORE: 'Ver más recetas'
  },
  PRODUCT: {
    BUTTON: {
      ACTU: 'Novedades de productos',
      FOUND: 'Dónde enconrtrar el producto',
      DISCOVER: 'Descubre toda la gama'
    },
    NUTRITIONAL_VALUE: 'Valores nutricionales',
    CONDITIONING: 'Envase',
    PORTIONS: 'Porciones',
    NET_WEIGHT: 'Peso neto',
    DRY_WEIGHT: 'Peso neto escurrido',
    INGREDIENTS: 'ingredientes',
    OUR_RECIPES: 'Descubre nuestras recetas de cocina',
    FOR: 'Para',
    PER_PORTION: 'y por porción de',
    ENERGY: 'Energía',
    FAT: 'Grasas',
    SATURATE: 'De las cuales saturadas',
    GLUCIDES: 'Hidratos de carbono; De los cuales azúcares',
    FIBER: 'Fibra alimentaria',
    PROTEINS: 'Proteinas',
    SALT: 'sal',
    DISCOVER_ALSO: 'Descubre también',
    MORE_INFORMATION: 'Más información',
    TRACES: 'Trazas de',
    CONTAINS: 'Contiene',
    SLIDER: { SHEET: 'Ver la ficha', BUY: '¿Dónde comprarlo?' },
    NUTRISCORE: 'Información de nutriscore',
    TRIMAN: 'información-triman',
    AGRICONFIANCE: 'Información-agrofiance',
    BIO: 'Información-bio',
    PESTICIDE_FREE: '',
    LEAF: '',
    LOCK: '',
    SEE_ALL: 'Ver todos nuestros vegetales',
    LEGISLATION: 'Conforme a la legislación vigente',
    PORTION: { SINGULAR: 'Porción', PLURAL: 'Porciones' },
    DISCLAIMER: '',
    COOKING: 'cocinado',
    CONSERVATION: 'conservación'
  },
  FAQ: {
    ON: 'Preguntas sobre',
    ALL: 'Todo',
    SEE_MORE: 'ver más preguntas',
    TITLE_1: 'Todas las respuestas a tus preguntas',
    TITLE_2: '¿Tienes alguna pregunta sobre un producto en particular?',
    LINK_LABEL: 'Escríbenos'
  },
  FORM: {
    GO_TO_STEP: 'ir al siguiente paso',
    STEP: 'paso',
    RECEIVE_EMAIL_COPY: 'Recibir una copia por email',
    SEND: 'Enviar',
    GDPR: 'Más información sobre la gestión de datos:\n' +
      '\n' +
      'En BONDUELLE IBERICA, S.A.U. somos responsables del tratamiento de los datos personales que nos facilitas. Los mismos los trataremos con la finalidad de atender tus solicitudes o consultas realizadas, y en su caso, proceder al registro de tu cuenta en la web.\n' +
      '\n' +
      'La legitimación para el tratamiento es el consentimiento manifestado para remitir tus consultas, proceder al registro como usuario de la Web y la aceptación de las condiciones generales de uso de la cuenta.\n' +
      '\n' +
      'Tienes los siguientes derechos sobre tus datos: Acceder, rectificar, suprimir y oponerte, limitar y portar tus datos personales, ante el Bonduelle Ibérica, S.A.U.Podrás obtener información adicional sobre protección de datos en nuestra Política de Privacidad.\n' +
      '\n' +
      'Al registrarte consientes expresamente el tratamiento de tus datos personales con las finalidades indicadas.',
    ERROR: 'Todos los campos marcados con un * son obligatorios.',
    SEND_ERROR: 'Ha ocurrido un error, por favor prueba de nuevo en unos minutos',
    NOTICE: 'Para el correcto tratamiento de algunas de sus solicitudes, Bonduelle podrá, según el caso, solicitarle datos sanitarios. No son obligatorios y usted es libre de no transmitirlos.',
    SENDING: 'Enviando el formulario',
    LOADING: 'Cargando',
    PRODUCT_INFORMATION_TITLE: 'Información sobre un producto',
    PRODUCT_INFORMATION: {
      SEO_TITLE: 'Información y datos de producto | Bonduelle\r\n',
      SEO_DESC: 'Ponte en contacto con nuestro departamento de atención al cliente para obtener información sobre un producto de nuestras gamas. Respuesta personalizada en 48 horas'
    },
    INFORMATIONS: 'Tu información',
    MISTER: 'Estimado Sr.',
    MISS: 'Estimada señora',
    NAME: 'Apellidos',
    OUR_NAME: 'Apellidos',
    FIRSTNAME: 'Nombre',
    OUR_FIRSTNAME: 'Nombre ',
    MAIL: 'Dirección de correo electrónico',
    MAIL_PLH: 'email@',
    MAIL_CONFIRM: 'Confirma tu dirección de correo electrónico',
    MAIL_ERROR: 'Por favor, introduce una dirección de correo electrónico válida',
    MAIL_ERROR_CONFIRM: 'Por favor, confirma tu dirección de correo electrónico',
    ADDRESS: 'Dirección ',
    STREET_PLH: '',
    STREET: 'Número y nombre de la calle',
    ADDRESS_COMPLEMENT: 'Información adicional sobre la dirección',
    ADDRESS_COMP_PLH: 'Información adicional',
    ZIPCODE: 'Código postal',
    CITY: 'Ciudad',
    CITY_PLH: 'Ciudad de residencia',
    PHONE: 'Teléfono',
    PHONE_PLH: 'Número de teléfono',
    COUNTRY: 'País',
    PRODUCT_INFORMATIONS: 'Información sobre el producto',
    PRODUCT_INFORMATION_DESC: 'Para que podamos  estudiar tu solicitud lo mejor posible, por favor, proporcionenos la siguiente información:',
    PRODUCT: {
      DETAIL: {
        DESC: 'Para cada criterio solicitado, puedes rellenar el campo del formulario y añadir una foto de ilustración. Información: La foto debe mostrar claramente el código de barras, el número de lote y la fecha de consumo preferente del producto.'
      },
      NOTE: {
        TITLE: 'Hacer un comentario sobre un producto',
        SEO_TITLE: 'Comentario sobre un producto | Bonduelle',
        SEO_DESC: '¿Tienes algún  comentario sobre un producto de nuestra gama? Ponte en contacto con nuestro servicio de atención al cliente para que te ayudemos. Te responderemos de forma personalizada en 48 horas'
      }
    },
    PRODUCT_RANGE: 'Gama del producto',
    PRODUCT_NAME: 'Nombre del producto',
    PRODUCT_NAME_PLH: 'Escriba el nombre del producto Bonduelle al que se refiere tu solicitud',
    PRODUCT_INFORMATIONS_DETAIL: 'Información detallada',
    PRODUCT_INFORMATIONS_LABEL: 'Código de barras del producto',
    PRODUCT_INFORMATIONS_PLH: 'El código de barras consta de 13 dígitos en el embalaje de su producto.',
    PRODUCT_LOT: 'Número de lote del producto',
    PRODUCT_LOT_INFO: 'El número de lote comienza con la letra L seguida de varios dígitos y varía según el producto, por ejemplo, L72450492107:13.',
    PHOTO: 'Añadir una foto',
    EXPIRED_DATE: 'Fecha de consumo preferente',
    EXPIRED_DATE_ERROR: 'Por favor, introduce una fecha en formato dd/mm/aaaaaa',
    EXPIRED_DATE_DESC: 'La DLUO (fecha de consumo preferente) y la DLC (fecha límite para el consumo) se presentan en forma de fechas.',
    PHOTO_MORE: 'Fotos adicionales',
    MESSAGE: 'Tu mensaje *',
    OUR_MESSAGE: 'escribe tu mensaje',
    SALES_OPERATIONS: {
      TITLE: 'Información sobre una operación comercial',
      SEO_TITLE: 'Información sobre las acciones actuales: juegos, concursos... | Bonduelle',
      SEO_DESC: 'Ponte en contacto con nuestro departamento de atención al cliente para obtener información sobre una operación comercial en curso. Te responderemos de forma personalizada en 48 horas.'
    },
    PRESS_SERVICE: 'Contactar con el departamento de prensa',
    FOOD_SERVICE: 'Ponte en contacto con el equipo de Bonduelle Food Service',
    BUSINESS_INFORMATION: '',
    MODAL_INFORMATION: '',
    JOB_OFFER: { TITLE: '', SEO_TITLE: '', SEO_DESC: '' },
    WEB_SUGGESTION: {
      TITLE: 'Hacer una sugerencia respecto a la web ',
      SEO_TITLE: 'Mensaje de mejora, sugerencia de la web| Bonduelle',
      SEO_DESC: 'Ponte  en contacto con nuestro servicio de atención al cliente para ayudarnos a mejorar la web.'
    },
    ANOTHER: {
      TITLE: 'Realizar otra solicitud',
      SEO_TITLE: 'Contacto para cualquier otra solicitud, servicio | Bonduelle',
      SEO_DESC: 'Ponte en contacto con nuestro departamento de atención al cliente para obtener información, servicio o sugerencias. Te responderemos de forma personalizada en 48 horas'
    },
    AMBASSADOR: 'Conviértete en un embajador de Bonduelle',
    CATEGORY: {
      SALAD: '',
      FROZEN: 'Congelado',
      CAN: 'Conserva',
      TAKEAWAY: '',
      VEGETABLE: ''
    }
  },
  CONTACT: {
    TITLE: 'A tu disposición',
    SPECIAL_INFO: '',
    TAKE_CARE: '',
    BY_MAIL: 'Por correo',
    BY_PHONE: 'Por teléfono',
    BY_SOURDLINE: '',
    MAIL_TEXT: 'Servicio de atención al cliente Bonduelle. <br>\n' +
      'Av. Isla Graciosa, 1 - 2ª planta\n' +
      ' <br> 28703 San Sebastián de los Reyes - Madrid',
    PHONE_TEXT: 'Nuestro horario de atención al cliente es de lunes a viernes de 9:00 a 18:00. No obstante, puedes dejar un mensaje de voz las 24 horas del día en el teléfono +34 91 658 60 51.',
    SOURDLINE_TEXT: '',
    BY_DISTRIBUTOR: '',
    BY_DISTRIBUTOR_TEXT: '',
    SUBTITLE: 'Puedes ponerte en contacto con nosotros para: ',
    AGREEMENT: '',
    READ_MORE: "Además, tiene derecho de acceso, rectificación, supresión y portabilidad de sus Datos Personales, así como derecho de limitación y oposición al tratamiento de los Datos Personales que le conciernen, que puede ejercer escribiendo a Dpo_france(at)bonduelle(punto)com o por correo postal: a la atención del Delegado de Protección de Datos, Bonduelle, Rue Nicolas Appert, BP 30173, F-59653 Villeneuve D'Ascq. Para saber más sobre la gestión de sus datos y sus derechos haga clic aquí",
    READ_MORE_LABEL: 'Saber más',
    SATISFACTION_TEXT: 'Con el fin de contactar con usted para facilitarle la información solicitada\n' +
      'sobre el producto y enviarle un cuestionario de satisfacción si así lo desea,\n' +
      'trataremos la información facilitada en nuestra calidad de responsable del\n' +
      'tratamiento. La base legal para procesar su solicitud es el consentimiento\n' +
      'debido a los posibles datos relacionados con la salud que pueda\n' +
      'proporcionar en su solicitud. Esta información se conservará durante el\n' +
      'tiempo necesario para procesar su solicitud y hasta por 5 años para\n' +
      'proporcionar prueba de que su solicitud ha sido procesada y para cumplir\n' +
      'con nuestras obligaciones legales. La base legal para el envío del\n' +
      'cuestionario de satisfacción es también su consentimiento. Esta\n' +
      'información se conservará durante el tiempo necesario para enviar el\n' +
      'cuestionario de satisfacción y hasta un máximo de 1 año. Para saber más\n' +
      'sobre el tratamiento de sus datos personales, consulte nuestra <a href="https://www.bonduelle.es/politica-de-privacidad" target="_blank" class="underline">Política de Privacidad</a>.',
    SATISFACTION_CHECKBOX: 'Al marcar esta casilla, acepta recibir un cuestionario de satisfacción\n' +
      '(compuesto por una única pregunta) una vez procesada su solicitud. Eso\n' +
      'es opcional.',
    CHECKBOX_TEXT: 'Al marcar esta casilla, acepta que Bonduelle pueda procesar los datos\n' +
      'personales de salud que pueda proporcionar en su mensaje con el único\n' +
      'fin de responder a su solicitud. <strong>Esto es obligatorio para poder procesar su\n' +
      'solicitud</strong>.',
    PHONE: 'De lunes a viernes de 9am a 6pm al número +34 91 658 60 51 (llamada sin recargo)',
    CALL_US: 'Si quieres llamarnos',
    TO_WRITE: 'Escríbenos',
    PHONE_NUMBER: '0034 916586051',
    SUCCESS_MESSAGE: '¡Gracias! Tu mensaje ha sido enviado a nuestro equipo. Se procesará en un tiempo medio de 2 a 3 días, excluyendo fines de semana y festivos.\n' +
      'Permanecemos a tu servicio de lunes a viernes de 9h a 18h en el +34916586051 (llamada sin recargo).\n' +
      'Como parte de la supervisión de nuestra calidad de servicio, todas las llamadas pueden ser grabadas.',
    PHOTOERROR: { MESSAGE: '' }
  },
  GLOBAL: { READ_MORE: '' },
  FLOWBOX: { GENERIC_KEY: '', PRODUCT_KEY: '', RECIPE_KEY: '', HOME_KEY: '' },
  TOKEN: {
    FACEBOOK: '',
    PAGE_FACEBOOK_ID: '',
    INSTAGRAM: '',
    INSTA_ID: '17841407667910349',
    YOUTUBE: '',
    TWITTER: '',
    MAGAZINE: ''
  },
  COOKIE: {
    BANNER: {
      MANDATORY: 'Obligatorio',
      ANALYTICS: 'Análisis',
      MARKETING: 'Marketing',
      SOCIAL_MEDIA: 'Las redes sociales',
      ACCEPT: 'Aceptar todo',
      REJECT: 'Rechazar todo',
      SET_UP: 'Configurar',
      MANDATORY_DETAILS: 'Las cookies requeridas son necesarias para el funcionamiento del sitio web, por ejemplo, para almacenar su información de inicio de sesión y proporcionarle una conexión segura.\n' +
        'Las cookies necesarias para utilizar el sitio bonduelle.es son:\n' +
        '<ul>\n' +
        '<li> cookies de sesión: cookies generadas por aplicaciones basadas en PHP. Este es un identificador de propósito general que se utiliza para administrar las variables de sesión del usuario. Normalmente es un número generado aleatoriamente, la forma en que se usa puede ser específica del sitio, pero un buen ejemplo es mantener un estado de inicio de sesión para un usuario entre páginas. </li>\n' +
        '</ul>',
      ANALYTICS_DETAILS: 'Las cookies funcionales nos ayudan a optimizar el sitio web, por ejemplo, para recopilar estadísticas, registrar sus preferencias para interacciones sociales o medir el rendimiento y la estabilidad de nuestra aplicación.\n' +
        '<br>\n' +
        'Las cookies analíticas utilizadas por el sitio bonduelle.es son:\n' +
        '<ul>\n' +
        '<li> DoubleClick: esta cookie la establece por un DoubleClick (propiedad de Google) para crear un perfil con los intereses del visitante del sitio web y mostrar anuncios relevantes en otros sitios webs. La actividad principal de la empresa es el intercambio de anuncios de ofertas de Google en tiempo real.  </li> \n' +
        '<li> Google Analytics: estas cookies permiten rastrear las páginas más visitadas y el tráfico del sitio. </li>\n' +
        '<li> UTMTracking: esta cookie permite la gestión de variables UTM para un seguimiento óptimo de las diferentes campañas publicitarias online </li></ul>',
      MARKETING_DETAILS: 'Las cookies de marketing nos permiten rastrear sus preferencias de recetas y productos para poder ofrecerle siempre los productos que mejor se adapten a su búsqueda.\n' +
        '<br>\n' +
        'Las cookies de marketing utilizadas por el sitio bonduelle.es son:\n' +
        '<ul>\n' +
        `<li> Redes sociales: estas cookies permiten que Bonduelle 'SocialWall' se muestre en la página de inicio del sitio. Este muro "te permite ver las últimas publicaciones de las principales redes sociales utilizadas por el Grupo Bonduelle. </li>\n` +
        '</ul>',
      V2: 'ok'
    },
    MODAL: {
      TITLE: 'Gestionar las cookies ',
      TEXT: 'El Grupo Bonduelle se compromete a garantizar la protección de los datos personales de los usuarios de sus sitios web y, en particular, del sitio web Bonduelle.es. Para permitirle beneficiarse de los servicios ofrecidos por la web, como consultar productos, descubrir nuevas recetas gourmet, suscribirse a ciertos servicios como nuestros Newsletters, y también optimizar su uso y personalización de acuerdo con su perfil, nuestra web utiliza cookies. El Grupo Bonduelle se compromete plenamente a garantizar un alto nivel de protección con respecto a dichos Datos personales y a cumplir con todas las normas de protección de datos aplicables al procesar Datos personales en relación con el uso de nuestro sitio web. Por lo tanto, usted puede activar / desactivar las cookies en cualquier momento. ',
      CONFIG: 'Configuración',
      SAVE: 'Guardar'
    }
  },
  LINK: { JOB_OFFERS: 'https://jobs.bonduelle.com/?locale=es_ES' },
  CLIC2BUY: { INFOS: '' }
};
