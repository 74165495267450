import { SfMediaImage } from '../../image';

export interface SfItemStat {

  /** Icon display in template */
  icon: SfMediaImage;

  /** Text to be displayed */
  text: string;

  /** Color of the background as hexadecimal string. i.e: #FF39A0  */
  color?: string;

  background?: SfMediaImage;

  cover?: SfMediaImage;

  crop?: string;

}

export function isSfItemStat(object: Object): object is SfItemStat {
  if(object) {
  return 'icon' in object &&
  'text' in object;
  }
}
