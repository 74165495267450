import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UtilsModule } from '@app/shared/utils/utils.module';
import { SfSectionTextComponent } from './components/section-text.component';

@NgModule({
    imports: [
        CommonModule,
        UtilsModule,
    ],
  declarations: [SfSectionTextComponent],
  exports: [SfSectionTextComponent]
})
export class SfSectionTextModule { }
