import {ActiveDescendantKeyManager} from '@angular/cdk/a11y';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
  HostBinding,
} from '@angular/core';
import { SfOptionComponent } from './option';
import { SfOptgroupComponent } from './optgroup';


/**
 * Autocomplete IDs need to be unique across components, so this counter exists outside of
 * the component definition.
 */
let _uniqueAutocompleteIdCounter = 0;

/** Event object that is emitted when an autocomplete option is selected. */
export class SfAutocompleteSelectedEvent {
  constructor(
    /** Reference to the autocomplete panel that emitted the event. */
    public source: SfAutocompleteComponent,
    /** Option that was selected. */
    public option: SfOptionComponent
  ) { }
}

@Component({
    selector: 'sf-autocomplete',
    templateUrl: 'autocomplete.html',
    styleUrls: ['autocomplete.sass'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SfAutocompleteComponent implements AfterContentInit {

  /** Function that maps an option's control value to its display value in the trigger. */
  @Input() displayWith: ((value: any) => string) | null = null;

  /** Manages active item in option list based on key events. */
  public _keyManager: ActiveDescendantKeyManager<SfOptionComponent>;

  /** Whether the autocomplete panel should be visible, depending on option length. */
  public showPanel = false;

  /** Whether the autocomplete panel is open. */
  get isOpen(): boolean { return this._isOpen && this.showPanel; }
  _isOpen = false;

  /** @docs-private */
  @ViewChild(TemplateRef, {static: false}) template: TemplateRef<any>;

  /** Element for the panel containing the autocomplete options. */
  @ViewChild('panel', {static: false}) panel: ElementRef;

  /** @docs-private */
  @ContentChildren(SfOptionComponent, { descendants: true }) options: QueryList<SfOptionComponent>;

  /** @docs-private */
  @ContentChildren(SfOptgroupComponent) optionGroups: QueryList<SfOptgroupComponent>;

  /**
   * Whether the first option should be highlighted when the autocomplete panel is opened.
   */
  @Input()
  get autoActiveFirstOption(): boolean { return this._autoActiveFirstOption; }
  set autoActiveFirstOption(value: boolean) {
    this._autoActiveFirstOption = coerceBooleanProperty(value);
  }
  private _autoActiveFirstOption: boolean;

  /**
   * Specify the width of the autocomplete panel.  Can be any CSS sizing value, otherwise it will
   * match the width of its host.
   */
  @Input() panelWidth: string | number;

  /** Event that is emitted whenever an option from the list is selected. */
  @Output() readonly optionSelected: EventEmitter<SfAutocompleteSelectedEvent> =
      new EventEmitter<SfAutocompleteSelectedEvent>();

  /** Event that is emitted when the autocomplete panel is opened. */
  @Output() readonly opened: EventEmitter<void> = new EventEmitter<void>();

  /** Event that is emitted when the autocomplete panel is closed. */
  @Output() readonly closed: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Takes classes set on the host sf-autocomplete element and applies them to the panel
   * inside the overlay container to allow for easy styling.
   */
  @Input('class')
  set classList(value: string) {
    if (value && value.length) {
      value.split(' ').forEach(className => this._classList[className.trim()] = true);
      this._elementRef.nativeElement.className = '';
    }
  }
  _classList: {[key: string]: boolean} = {};

  /** Unique ID to be used by autocomplete trigger's "aria-owns" property. */
  id = `sf-autocomplete-${_uniqueAutocompleteIdCounter++}`;

  // Use sf-option class on host element
  @HostBinding('class.sf-autocomplete') autocompleteClass = true;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _elementRef: ElementRef<HTMLElement>
  ) {
    // False by default
    this._autoActiveFirstOption = false;
  }

  ngAfterContentInit() {
    this._keyManager = new ActiveDescendantKeyManager<SfOptionComponent>(this.options).withWrap();
    // Set the initial visibility state.
    this._setVisibility();
  }

  /**
   * Sets the panel scrollTop. This allows us to manually scroll to display options
   * above or below the fold, as they are not actually being focused when active.
   */
  _setScrollTop(scrollTop: number): void {
    if (this.panel) {
      this.panel.nativeElement.scrollTop = scrollTop;
    }
  }

  /** Returns the panel's scrollTop. */
  _getScrollTop(): number {
    return this.panel ? this.panel.nativeElement.scrollTop : 0;
  }

  /** Panel should hide itself when the option list is empty. */
  _setVisibility() {
    this.showPanel = !!this.options.length;
    this._classList['sf-autocomplete-visible'] = this.showPanel;
    this._classList['sf-autocomplete-hidden'] = !this.showPanel;
    this._changeDetectorRef.markForCheck();
  }

  /** Emits the `select` event. */
  _emitSelectEvent(option: SfOptionComponent | null): void {
    if (option === null) {
      this.optionSelected.emit(null);
    } else {
      const event = new SfAutocompleteSelectedEvent(this, option);
      this.optionSelected.emit(event);
    }
  }
}

