import { SfItem } from '@app/sf-lib/public_api';
import { SomBloc } from './bloc.model';

export class SomPreviousArticles extends SomBloc {

  /** Number of articles to be displayed */
  public nbarticle: number;

  /** Items to display */
  public items: SfItem[];

  constructor() {
    super();
    this.nbarticle = 0;
    this.items = [];
  }
}
