import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SfBannerComponent } from './components/banner.component';
import { SfLinkModule } from '../link/link.module';
import { SfImageModule } from '../image/image.module';
import { SfRolloverModule } from '../rollover/rollover.module';
import { AngularMaterialModule } from '@app/shared/angular-material/angular-material.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    SfLinkModule,
    SfImageModule,
    SfRolloverModule,
    TranslateModule,
    AngularMaterialModule,
  ],
  declarations: [
    SfBannerComponent
  ],
  exports: [
    SfBannerComponent
  ]
})
export class SfBannerModule { }
