import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable} from 'rxjs';
import { environment } from '@env/environment';

/**
 * Interceptor responsible for passing headers/params in all requests
 * @export
 * @class RequestInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  /** @ignore */
  constructor(
  ) { }

  /**
   * Request has no header and can't accept any
   * @param {HttpRequest<any>} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   * @memberof AuthInterceptor
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.url.indexOf('send/mail-to-easiware') >= 0) {
      // Remove headers if url is request api url
      const headers: HttpHeaders = new HttpHeaders();
      request = request.clone({
        headers: headers
      });
    }

    return next.handle(request);

  }
}
