/* tslint:disable */export const ro_ro = {
  '404': {
    TITLE: 'Ups...',
    PAGENOTFOUND: 'Pagina nu există',
    BUTTON: 'Back to home',
    RECIPES: 'Descoperă rețetele noastre'
  },
  HOME: {
    SHOWCASE: 'Rețete',
    CATCH_PHRASE: '',
    SHOWCASE_SEE_ALL: 'Vezi toate rețetele',
    MAGAZINE: { TITLE: 'Mai sus', BUTTON: 'Descoperă Legumis' },
    OUR_VEGETABLES: 'Legumele noastre',
    SOCIAL_WALL: 'Împreună împărtășim pasiunea pentru legume',
    FAQ_TITLE: 'Întrebările voastre',
    FAQ_SEE_MORE: 'Vezi mai mult',
    FLOWBOX: 'Împărtășește-ți momentele gourmet cu #mybonduelle'
  },
  SELECT: { DEFAULT_VALUE: 'Cele mai populare' },
  MENU: {
    CONTACT_US: 'Contactează-ne',
    MY_ACCOUNT: 'Contul meu',
    GOOD_DEALS: 'Promoții',
    NEWSLETTER: 'Newsletter Bonduelle'
  },
  FOOTER: {
    LINK: {
      BONDUELLE: 'Bonduelle.com',
      CREDITS: 'Credits',
      LEGAL_NOTICE: 'Aviz juridic',
      TERMS_OF_SALES: 'Termenii de vânzare',
      COOKIES: 'Cookies',
      SITEMAP: 'Harta site-ului',
      PRIVACY_POLICY: 'Politica de confidențialitate',
      ALL_RIGHTS_RESERVED: '2023 © Toate drepturile rezervate'
    },
    HISTORY: {
      TITLE: 'Bonduelle, din 1853',
      TEXT: 'Bonduelle este o companie de familie care a lucrat timp de 7 generații la dezvoltarea producției agricole care respectă pământul și oamenii. Susținem o agroecologie eficientă, inteligentă și unificată, orientată spre viitor și inovatoare în fiecare zi, de pe câmp în farfurie, pentru a crea un viitor mai bun prin alimente pe bază de legume.'
    },
    NEWSLETTER: {
      TITLE: 'Comunitatea Bonduelle',
      TEXT: 'Accept să primesc buletinul informativ « Totul despre legume de la Bonduelle » (știri, promoții, concursuri, articole...)',
      PLACEHOLDER: 'Adresa ta de email',
      GDPR_LINK: 'Mai multe despre gestionarea datelor dvs'
    }
  },
  PRODUCTS: 'Produse',
  MAGAZINE: {
    HOME: 'Bun venit la revista Bonduelle',
    CATCH_PHRASE: '',
    MENU: { BONDUELLE_LINK: 'Mergi la bonduelle.ro' },
    SHOWCASE: 'Vitrina',
    PREVIOUS_ARTICLES: 'Articole anterioare',
    PREVIOUS_ARTICLES_SEE_ALL: 'Vezi toate articolele',
    PREVIOUS_EDITIONS: 'Edițiile anterioare',
    PREVIOUS_EDITIONS_SEE_ALL: 'Vezi mai multe ediții',
    PREVIOUS_ARTICLES_BY_CATEGORY: 'Articole anterioare pe categorie',
    RUBRIC: {
      SEE_ALL: 'Vezi toate rețetele',
      SEE_ALL_ARTICLES: 'Vezi toate articolele',
      ON: 'articole despre'
    },
    RUBRIC_COOK: { ON: 'rețete despre' },
    ARTICLE: { JOIN_US: 'Alăturaţi-ne', READ_ALSO: 'De citit' },
    RECIPE: {
      INGREDIENTS: 'Ingrediente',
      INGREDIENTS_LABEL_DESC: 'pentru {{value}} persoane',
      INGREDIENTS_LABEL_ONE_DESC: 'pentru {{value}} persoană',
      INGREDIENTS_LABEL: 'Ingrediente pentru {{value}} persoane',
      INGREDIENTS_LABEL_ONE: 'Ingrediente pentru {{value}} persoană',
      PEOPLES: '{{value}} persoane',
      PEOPLE: '{{value}} persoană',
      DIFFICULTY: { EASY: 'Ușor', MEDIUM: 'Mediu', HARD: 'Greu' },
      COST: { CHEAP: 'Ieftin', EXPENSIVE: 'Scump' },
      TIMING: { MIN: 'Minute', HOURS: 'Ore' },
      STEP: 'Pași',
      DISCOVER_ALSO: 'Descoperă și...'
    }
  },
  ARTICLE: { DISCOVER_ALSO: 'Descoperă și...', INGREDIENTS: 'Ingrediente' },
  SF: {
    SLIDE: { DISCOVER: 'Descoperă' },
    SEARCH: { PLACEHOLDER: 'Căutare', ALL: 'Toate' },
    NEWSLETTER: {
      BTN_LABEL: 'Înregistrare',
      PLACEHOLDER: 'Adresa ta de email',
      ERROR_MESSAGE: 'Adresă de email invalidă',
      ALREADY_SUBSCRIBED: 'Contul tău este deja creat',
      GDPR: {
        TXT: 'Mai multe informații despre gestionarea datelor dvs',
        LINK: '/cookies#gdpr'
      }
    },
    MONTHLY_FOLDER: { TITLE: 'Știri lunare' },
    MINI_NEWSLETTER: {
      PLACEHOLDER: 'Adresa ta de email',
      GDPR: {
        TXT: 'Mai multe informații despre gestionarea datelor dvs',
        LINK: '/cookies#gdpr'
      }
    },
    SOCIALITEM: {
      SHARE: 'distribuie',
      COMMENT: 'comentează',
      BONDUELLE: 'Bonduelle'
    },
    SEARCHRECIPE: { DISCOVER_ALL: 'caută după toate rețetele!' },
    FORM: {
      ERROR: 'Câmp nevalid',
      PHOTO: {
        ADD: 'Adaugă o fotografie',
        DELETE: 'Șterge fotografia',
        SEE: 'Vezi'
      }
    }
  },
  CORPORATE: {
    COMPANY_STORY: 'Povestea Noastră',
    CSR_COMMITMENT: 'Responsabilitate Socială',
    VEGETABLE_INNOVATIONS: 'Stiri si evenimente',
    NEWS: 'toate știrile',
    SEE_MORE: 'Vezi mai multe articole',
    ON: 'articole despre'
  },
  NEWSLETTER: {
    TITLE: 'Abonați-vă la newsletter',
    SUBTITLE: 'Oferte bune, inspirație culinară și puțină cultură!'
  },
  ROUTES: {
    products: 'produse',
    news: 'stiri',
    'since-1853': 'despre-noi',
    'field-plate': 'enciclopedia-legumelor',
    'company-story': 'povestea-noastra',
    'csr-commitments': 'responsabilitate-sociala',
    'vegetal-innovations': 'stiri-si-evenimente',
    magazine: 'legumis',
    all: 'toate',
    article: 'articol',
    cook: 'gateste',
    cultivate: 'toate-articolele',
    explore: 'exploreaza',
    recipe: 'asteapta-reteta',
    recipes: 'retete',
    'recipes-home': 'retete-acasa',
    r: 'r',
    credits: 'credite-com',
    'legal-notice': 'aviz-juridic',
    'terms-of-sales': 'termenii-de-vanzare',
    cookies: 'cookie-uri',
    sitemap: 'harta-site-ului',
    'privacy-policy': 'politica-de-confidentialitate',
    'all-rights-reserved': 'toate-drepturile-rezervate',
    search: 'cautare',
    'articles-search': 'articol',
    vegetables: 'produse',
    p: 'p',
    contact: 'contact',
    'info-product': 'informatii-despre-produs',
    'note-product': 'opinia-ta-despre-produs',
    suggestion: 'sugestie',
    'other-request': 'informatii-despre-produs',
    faq: 'intrebari-frecvente',
    'all-themes': 'toate-temele',
    'products-search': 'produse-cautare',
    recipesearch: 'cautare-retete',
    'product-news': 'produs-stiri',
    'good-deals': 'promotii',
    'not-found': 'nu-a-fost-gasit',
    'menu-page': 'inspiratie',
    'menu-one': 'traieste-sanatos',
    'menu-two': 'gateste-delicios',
    'menu-three': 'prepara-cu-stil',
    'menu-four': 'fii-creativ',
    'menu-external': 'meniu-extern',
    'theme-pasta-rice': '',
    'theme-bowls': '',
    'theme-soup': '',
    'theme-snack': '',
    'theme-sauce': '',
    'theme-unique-plate': '',
    'theme-over': '',
    'theme-legumin': '',
    'theme-ingredients-recipes': '',
    'theme-cook-plate': ''
  },
  BREAD: {
    products: 'produse',
    news: 'știri',
    'since-1853': 'Despre noi',
    'field-plate': 'Enciclopedia legumelor',
    'company-story': 'Povestea Noastră',
    'csr-commitments': 'Responsabilitate socială',
    'vegetal-innovations': 'Știri și evenimente',
    magazine: 'legumis',
    all: 'toate',
    article: 'articol',
    cook: 'gătește',
    cultivate: 'Toate articolele',
    explore: 'explorează',
    recipe: 'așteaptă-rețetă',
    recipes: 'rețete',
    'recipes-home': 'rețete-acasă',
    'articles-search': 'articol',
    search: 'căutare',
    vegetables: 'produse',
    contact: 'contact',
    'info-product': 'Informații despre produs',
    'note-product': 'Opinia ta despre produs',
    suggestion: 'sugestie',
    'other-request': 'Informații despre produs',
    faq: 'întrebări frecvente',
    'all-themes': 'toate temele',
    'products-search': 'produse-căutare',
    recipesearch: 'cautare retete',
    'product-news': 'produs-știri',
    'good-deals': 'promoții',
    'not-found': 'nu a fost găsit',
    'menu-page': 'inspiratie',
    'menu-one': 'trăiește sănătos',
    'menu-two': 'gătește delicios',
    'menu-three': 'prepară cu stil',
    'menu-four': 'fii creativ'
  },
  MENU_PAGE: {
    TITLE1: 'TRĂIEȘTE SĂNĂTOS',
    TITLE2: 'GĂTEȘTE DELICIOS',
    TITLE3: 'PREPARĂ CU STIL',
    TITLE4: 'FII CREATIV',
    EXTERNAL: ''
  },
  TECHNICAL: { MENU_PAGE: 'oferte de muncă' },
  SEO: {
    TITLE: {
      MENU_ONE: 'TRĂIEȘTE SĂNĂTOS',
      MENU_TWO: 'GĂTEȘTE DELICIOS',
      MENU_THREE: 'PREPARĂ CU STIL',
      MENU_FOUR: 'FII CREATIV',
      HOME: 'Bonduelle',
      SEARCH: {
        MAIN: '{{value}}',
        RECIPES: '{{value}}',
        VEGETABLES: '{{value}}',
        ARTICLES: '{{value}} afișate în funcție de articol | Bonduelle'
      },
      CONTACT: 'Bonduelle',
      RECIPES: 'Bonduelle',
      RECIPE_HOME: 'Bonduelle',
      RECIPE: 'Bonduelle',
      PRODUCTS: 'Bonduelle',
      NEWS: 'Bonduelle',
      CORPORATE: {
        NEWS: 'Bonduelle',
        COMPANY_STORY: 'Bonduelle',
        CSR_COMMITMENTS: 'Bonduelle',
        VEGETAL_INNOVATIONS: 'Bonduelle',
        FIELD_PLATE: 'Bonduelle',
        ARTICLE: '{{value}}',
        VEGETABLE: '{{value}}'
      },
      PRODUCT: '{{value}}',
      MAGAZINE: {
        NEWS: 'Bonduelle',
        ARTICLE: 'Bonduelle',
        COOK: 'Bonduelle',
        GROW: 'Bonduelle',
        EXPLORE: 'Bonduelle',
        RECIPE: 'Bonduelle'
      },
      FAQ: 'Bonduelle',
      NOTFOUND: 'Bonduelle',
      RECIPES_ALL: 'Toate rețetele',
      RECIPETYPE: '{{value}}',
      RECIPESEASON: '{{value}}',
      RECIPEPREPARATION: '{{value}}',
      RECIPEDIFFICULTY: '{{value}}',
      RECIPEVEGETABLE: '{{value}}',
      RECIPECOST: '{{value}}',
      RECIPEDISH: '{{value}}',
      RECIPEDESC: '{{value}}',
      '2RECIPEFACETS': '',
      '4RECIPEFACETS': '{{value}}',
      PRODUCTSEASON: '{{value}}',
      PRODUCTTECHNO: '{{value}}',
      PRODUCTVEGETABLE: '{{value}}',
      PRODUCTRANGE: 'Produsele din gama de {{value}}',
      THEME_ALL: '',
      THEME_STARTER: '',
      THEME_SALAD: '',
      THEME_CAN: '',
      THEME_FROZEN: '',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: '',
      THEME_RECIPE: '',
      THEME_PLATE: '',
      THEME_ACC: '',
      THEME_SNACK: '',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': '{{value}}'
    },
    DESCRIPTION: {
      MENU_ONE: 'Citește totul despre stilul sănătos de viață, reguli de nutriție, metode de pregătire și de consumare a legumelor.',
      MENU_TWO: 'Descoperă secretele culinare utile pentru pregătirea mâncărurilor și rețetelor preferate de la Bonduelle.',
      MENU_THREE: 'Totul despre secretele culinare din bucătăriile lumii, diferențele dintre mâncărurile tradiționale și stilurile alimentare.',
      MENU_FOUR: 'Citește sfaturi culinare și articole despre nutriție de la Bonduelle. Află mai multe despre beneficiile și prepararea mâncării sănătoase.',
      HOME: '',
      SEARCH: {
        MAIN: '{{value}}',
        RECIPES: '{{value}}',
        VEGETABLES: '{{value}}',
        ARTICLES: 'Toate {{value}} noastre afișate în funcție de articol. Rețete simple și gustoase. Legume gătite.'
      },
      CONTACT: '',
      RECIPES: '',
      RECIPE_HOME: '',
      RECIPE: '',
      PRODUCTS: '',
      NEWS: '',
      CORPORATE: {
        NEWS: '',
        COMPANY_STORY: '',
        CSR_COMMITMENTS: '',
        VEGETAL_INNOVATIONS: '',
        VEGETABLE: '',
        FIELD_PLATE: '',
        ARTICLE: ''
      },
      PRODUCT: '{{value}}',
      MAGAZINE: {
        NEWS: '',
        ARTICLE: '',
        COOK: '',
        GROW: '',
        EXPLORE: '',
        RECIPE: ''
      },
      FAQ: '',
      PAGE: 'pagina ',
      RECIPETYPE: '{{value}}',
      RECIPESEASON: '{{value}}',
      RECIPEPREPARATION: '{{value}}',
      RECIPEDIFFICULTY: '{{value}}',
      RECIPEVEGETABLE: '',
      RECIPECOST: '{{value}}',
      RECIPEDISH: '{{value}}',
      RECIPEDESC: '{{value}}',
      '2RECIPEFACETS': '{{value}}',
      '4RECIPEFACETS': '{{value}}',
      PRODUCTSEASON: '{{value}}',
      PRODUCTTECHNO: '{{value}}',
      PRODUCTVEGETABLE: '{{value}}',
      PRODUCTRANGE: 'Descoperiți toate produsele din gama {{value}}',
      THEME_ALL: '',
      THEME_STARTER: '',
      THEME_SALAD: '',
      THEME_CAN: '',
      THEME_FROZEN: '',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: '',
      THEME_RECIPE: '',
      THEME_PLATE: '',
      THEME_ACC: '',
      THEME_SNACK: '',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': '{{value}}',
      NOTFOUND: ''
    },
    PIPE: { SOCIETY: ' | Bonduelle' },
    THEME_ALL: '',
    THEME_STARTER: '',
    THEME_SALAD: '',
    THEME_CAN: '',
    THEME_FROZEN: '',
    THEME_READY_MEALS: '',
    THEME_BIO: '',
    THEME_VEGETABLES: '',
    THEME_RECIPE: '',
    THEME_PLATE: '',
    THEME_ACC: '',
    THEME_SNACK: ''
  },
  VEGETABLE: {
    DISCOVER_VEGETABLE: 'Descoperă legumele noastre',
    OURS_RECIPES: 'Rețetele noastre',
    DISCOVER_ALSO: 'Descoperă și',
    SEE_ALL: 'Vezi toate legumele',
    ON: 'Legume'
  },
  RECIPE: {
    'INGREDIENTS_LABEL ': 'Ingredients for {{value}} peoples',
    PEOPLES: '{{value}} peoples',
    DIFFICULTY: { EASY: 'Ușor', MEDIUM: 'Mediu', HARD: 'Greu' },
    COST: { CHEAP: 'Ieftin', EXPENSIVE: 'Scump' },
    TIMING: { MIN: 'Minute', HOURS: 'Ore' },
    STEP: 'Pași',
    DISCOVER_ALSO: 'Descoperă și',
    HOME_SEE_MORE: 'Vezi mai multe rețete',
    SHOWCASE: 'Mai sus',
    HOME_BOOK: 'Rețetele noastre',
    MIN_MORE: '> 45 minute ',
    HOUR_MORE: 'h and +',
    FILTER_BY_CRITERIA: 'Filtrați după criterii',
    SORT_BY: 'Sortează după:',
    BEST_RATED: 'Cele mai populare',
    MOST_CONSULTED: 'Cele mai căutate',
    MEMBERS_RECIPES: 'Rețetele membrilor',
    CRITERIA: 'Știri',
    DISCOVER: { RECIPES: 'Descoperă rețetele noastre' },
    DISCOVER_RECIPE: 'Descoperă rețeta',
    INSTRUCTIONS: 'Instrucțiuni',
    DEFAULT_TAG: 'Idei de rețete',
    MOST_RECENT: 'Cele mai recente',
    RECIPE_VIDEOS: 'Rțete video',
    PRODUCT_ASSOCIATE: 'Rețete făcute cu',
    BUDGET_LOW: 'Ieftin',
    BUDGET_HIGH: 'Scump',
    BUDGET_MIDDLE: 'Mediu',
    PRINT_RECIPE: 'printează rețeta',
    LET_YOURSELF_SURPRISED: 'Lasă-te surprins',
    COOK_TIP_TITLE: 'Sfaturi în bucătărie',
    ENJOY_MEAL: 'Bucura-te de masa ta !',
    FILTER: { LEGEND: 'Legendă' }
  },
  PREDIGGO: { DIFFICULTY: { EASY: 'Ușor', MEDIUM: 'Medium', HARD: 'greu' } },
  COOKIES: {
    TEXT: 'Continuând vizita dvs. pe acest site, acceptați utilizarea cookie-urilor din motive tehnice, în scopuri de analiză statistică și de gestionare a traficului web, partajare de informații cu agenții de publicitate de pe alte site-uri web, pentru a vă oferi comunicări promoționale relevante care reflectă interesele dumneavoastră.',
    CLOSE: 'Închide',
    MORE: 'Află mai multe',
    AGREE_YOUTUBE: 'Trebuie să acceptați cookie-urile Youtube pentru a vedea acest videoclip',
    BUTTON_YOUTUBE: 'Acceptă cookies'
  },
  POPUP: { LAUNCH: 'DESCHIDERE POPUP-SITE' },
  FIELD_PLATE: { DISCOVER_VEGETABLE: 'Descoperă legumele noastre' },
  SEARCH: {
    OUR_RECIPES: 'Rețete',
    OUR_PRODUCTS: 'Produse',
    OUR_ARTICLES: 'Articolele noastre',
    RECIPES: 'Rețete',
    PRODUCTS: 'Produse',
    ARTICLES: 'Articole',
    ALL: 'Toate',
    SORT_BY: 'Sortează după',
    RESULTS: 'rezultate',
    RESULT: 'rezultate',
    PRODUCT_OUT: 'produse indisponibile',
    APPLY: 'Aplică',
    FILTER: 'Filtru',
    RECIPE_OUT: 'rețete indisponibile',
    NO_RESULT: 'Ne-ați pus o întrebare dificilă',
    SEE_MORE: 'Vezi mai mult',
    DISCOVER_RECIPES: 'Descoperă rețetele noastre',
    SEE_MORE_OF: 'Vezi mai multe',
    DELETE: 'șterge'
  },
  RECIPE_LIST: {
    OUR_RECIPES: 'Rețete',
    RESULTS: 'Rezultate',
    SEE_MORE: 'Vezi mai multe rețete'
  },
  PRODUCT: {
    BUTTON: {
      ACTU: 'știri despre rețete',
      FOUND: 'Unde pot găsi acest produs',
      DISCOVER: 'descoperă toată gama'
    },
    NUTRITIONAL_VALUE: 'valori nutriționale',
    CONDITIONING: 'condiționare',
    PORTIONS: 'porții',
    NET_WEIGHT: 'greutate netă',
    DRY_WEIGHT: 'greutate după scurgere',
    INGREDIENTS: 'ingrediente',
    OUR_RECIPES: 'Descoperă rețetele',
    FOR: 'pentru',
    PER_PORTION: 'o porție de ',
    ENERGY: 'energie',
    FAT: 'grăsimi',
    SATURATE: 'grăsimi saturare',
    GLUCIDES: 'glucide',
    FIBER: 'fibre',
    PROTEINS: 'proteine',
    SALT: 'sare',
    DISCOVER_ALSO: 'descoperă și...',
    MORE_INFORMATION: 'mai multe informații',
    TRACES: 'Urme de',
    CONTAINS: 'Conține',
    SLIDER: { SHEET: 'descoperă produsul', BUY: 'cumpără acest produs' },
    NUTRISCORE: '-',
    TRIMAN: 'information-triman',
    AGRICONFIANCE: 'information-agriconfiance',
    BIO: 'information-bio',
    PESTICIDE_FREE: '',
    LEAF: '',
    LOCK: '',
    SEE_ALL: 'Vezi toate produsele',
    LEGISLATION: 'în conformitate cu legislația în vigoare.',
    PORTION: { SINGULAR: 'porție', PLURAL: 'porții' },
    DISCLAIMER: '',
    COOKING: 'gătire',
    CONSERVATION: 'păstrare'
  },
  FAQ: {
    ON: 'întrebări despre',
    ALL: 'Toate',
    SEE_MORE: 'vezi mai multe întrebări',
    TITLE_1: 'Toate răspunsurile întrebărilor tale',
    TITLE_2: 'Aveți o întrebare despre un produs în special?',
    LINK_LABEL: 'Scrie-ne'
  },
  FORM: {
    GO_TO_STEP: 'Mergi la pași',
    STEP: 'Pas',
    RECEIVE_EMAIL_COPY: 'Primește o copie pe email',
    SEND: 'Trimite',
    GDPR: 'Mai multe despre gestionarea datelor dvs',
    ERROR: 'Toate câmpurile marcate cu * trebuie să fie completate și valide',
    SEND_ERROR: 'A apărut o eroare, vă rugăm să încercați din nou peste câteva momente',
    NOTICE: 'Pentru gestionarea corectă a dosarului dumneavoastră, Bonduelle vă poate solicita, în funcție de caz, date de sănătate. Ele nu sunt obligatorii și sunteți liber să nu le transmiteți.',
    SENDING: 'Se trimite formularul...',
    LOADING: 'Încărcare...',
    PRODUCT_INFORMATION_TITLE: 'Informații despre produs',
    PRODUCT_INFORMATION: {
      SEO_TITLE: 'Informații despre produs | Bonduelle',
      SEO_DESC: 'Contactați serviciul nostru pentru clienți'
    },
    INFORMATIONS: 'informațiile dvs',
    MISTER: 'Domnul',
    MISS: 'Doamna',
    NAME: 'Nume',
    OUR_NAME: 'Numele meu',
    FIRSTNAME: 'Prenume',
    OUR_FIRSTNAME: 'Prenumele meu',
    MAIL: 'adresa de email',
    MAIL_PLH: 'mail@address.com',
    MAIL_CONFIRM: 'confirmă emailul',
    MAIL_ERROR: 'va rugăm să scrieți o adresă de email validă',
    MAIL_ERROR_CONFIRM: 'vă rugăm să vă confirmați adresa de e-mail',
    ADDRESS: 'Adresa  ',
    STREET_PLH: '',
    STREET: 'Stradă și număr',
    ADDRESS_COMPLEMENT: 'mai multe informații despre adresă',
    ADDRESS_COMP_PLH: 'mai multe informații despre adresă',
    ZIPCODE: 'Cod poștal',
    CITY: 'Oraș',
    CITY_PLH: 'Oraș',
    PHONE: 'Telefon',
    PHONE_PLH: 'Număr de telefon',
    COUNTRY: 'Țară',
    PRODUCT_INFORMATIONS: 'informații despre produs',
    PRODUCT_INFORMATION_DESC: 'Pentru ca serviciile noastre să studieze mai bine<br>solicitarea dvs., vă rugăm să furnizați următoarele informații:',
    PRODUCT: {
      DETAIL: {
        DESC: 'Pentru fiecare criteriu solicitat, puteți completa câmpul formular<br>și adăuga o fotografie ilustrativă. Informații: fotografia dvs. trebuie să permită citirea clară a<br>codul de bare, numărul lotului și BBD-ul produsului.'
      },
      NOTE: {
        TITLE: 'Opinia ta despre un produs',
        SEO_TITLE: 'Opinia ta despre un produs| Bonduelle',
        SEO_DESC: 'O observație sau un comentariu asupra unui produs din gamele noastre? Contactați serviciul nostru pentru consumatori pentru a vă ajuta. Răspuns personalizat în 48 de ore'
      }
    },
    PRODUCT_RANGE: 'Gama de produse în cauză',
    PRODUCT_NAME: 'Numele produsului',
    PRODUCT_NAME_PLH: 'Scrieți numele produsului Bonduelle vizat de solicitarea dumneavoastră',
    PRODUCT_INFORMATIONS_DETAIL: 'Informatii suplimentare',
    PRODUCT_INFORMATIONS_LABEL: 'Cod de bare produs',
    PRODUCT_INFORMATIONS_PLH: 'Codul de bare este format<br>din 13 cifre care se găsesc pe<br>ambalajul produsului dumneavoastră.',
    PRODUCT_LOT: 'Numărul lotului produsului',
    PRODUCT_LOT_INFO: 'Numărul lotului<br>începe întotdeauna cu litera L urmată<br> de mai multe cifre și variază<br> între produse.<br><br>De exemplu, L72450492107:13.',
    PHOTO: 'Adăugați o imagine',
    EXPIRED_DATE: 'Data DLUO / DLC',
    EXPIRED_DATE_ERROR: 'Vă rugăm să introduceți o dată validă în format zz/ll/aaaa',
    EXPIRED_DATE_DESC: 'BBD (Data ca cel mai bun înainte)<br>și BBD (Data de utilizare)<br>sunt prezentate ca date.',
    PHOTO_MORE: 'Imagini suplimentare',
    MESSAGE: 'Mesajul dvs',
    OUR_MESSAGE: 'scrie-ți mesajul',
    SALES_OPERATIONS: {
      TITLE: 'Informații despre o tranzacție comercială',
      SEO_TITLE: 'Informații despre operațiunile curente: concurs... | Bonduelle',
      SEO_DESC: 'Contactați serviciul nostru pentru consumatori pentru informații despre o operațiune comercială în curs. Răspuns personalizat în 48 de ore'
    },
    PRESS_SERVICE: 'Contactați departamentul de presă',
    FOOD_SERVICE: 'Contactați echipa Bonduelle Food Service',
    BUSINESS_INFORMATION: 'Primiți mostre/vouchere/articole promoționale',
    MODAL_INFORMATION: '',
    JOB_OFFER: { TITLE: '', SEO_TITLE: '', SEO_DESC: '' },
    WEB_SUGGESTION: {
      TITLE: 'Faceți o sugestie despre site',
      SEO_TITLE: 'Mesaj de îmbunătățire, sugestie de site | Bonduelle',
      SEO_DESC: 'Vă rugăm să contactați serviciul nostru pentru consumatori pentru a ne ajuta să îmbunătățim site-ul.'
    },
    ANOTHER: {
      TITLE: 'Faceți o nouă cerere',
      SEO_TITLE: 'Contact pentru orice altă solicitare, service | Bonduelle',
      SEO_DESC: 'Contactați serviciul nostru pentru consumatori pentru informații, un serviciu sau o sugestie. Răspuns personalizat în 48 de ore'
    },
    AMBASSADOR: 'Deveniți ambasador Bonduelle',
    CATEGORY: {
      SALAD: 'Salată',
      FROZEN: 'congelat',
      CAN: 'conservă',
      TAKEAWAY: 'la pachet',
      VEGETABLE: ''
    }
  },
  CONTACT: {
    TITLE: 'La dispoziția ta',
    SPECIAL_INFO: '',
    TAKE_CARE: '',
    BY_MAIL: '',
    BY_PHONE: 'Prin telefon',
    BY_SOURDLINE: '',
    MAIL_TEXT: '',
    PHONE_TEXT: 'Consilierii noștri vă stau la dispoziție <a href=""tel:+4021 207 00 10""><b>021 207 00 10</b></a> (apel non-premium)\n' +
      'de luni până vineri între orele 9:00 - 18:00.\n' +
      'Cu toate acestea, este posibil să postezi un mesaj vocal 24 de ore pe zi.',
    SOURDLINE_TEXT: '',
    BY_DISTRIBUTOR: 'Distribuitori',
    BY_DISTRIBUTOR_TEXT: '<strong>Aquila</strong><br />\n' +
      'Str. Steagului nr. 1, 310262, Arad<br />\n' +
      'Telefon: +40 244 594 790<br />\n' +
      'Email: main@aquila.ro<br />\n' +
      '<a href="https://www.aquila.ro" target="_blank">https://www.aquila.ro</a><br />\n' +
      '<br />\n' +
      '<strong>Marathon</strong><br />\n' +
      'Șos. București-Urziceni nr. 53A, com. Afumați, jud. Ilfov<br />\n' +
      'Telefon: +40 213 513 193<br />\n' +
      'Email: office@marathon.com.ro<br />\n' +
      '<a href="https://marathon.com.ro/ro" target="_blank">https://marathon.com.ro/ro</a><br />',
    SUBTITLE: 'Doriți să ne contactați pentru :',
    AGREEMENT: '',
    READ_MORE: "În plus, aveți dreptul, în orice moment, să solicitați BONDUELLE să vă exercite drepturile de acces, rectificare, ștergere și portabilitate a Datelor dumneavoastră cu caracter personal, precum și drepturile dumneavoastră de a restricționa și de a vă opune Prelucrării Datelor dumneavoastră cu caracter personal. Pentru exercitarea drepturilor dumneavoastră legate de datele dumneavoastră cu caracter personal, vă rugăm să scrieți Dpo_france(at)bonduelle(dot)com sau prin poștă la următoarea adresă: în atenția responsabilului cu protecția datelor, Bonduelle, Rue Nicolas Appert, PO Box 30173, F- 59653 Villeneuve D'Ascq. Pentru a afla mai multe despre modul în care BONDUELLE colectează și utilizează datele dumneavoastră cu caracter personal, faceți clic aici",
    READ_MORE_LABEL: 'Citește și',
    SATISFACTION_TEXT: 'Pentru a vă contacta pentru a vă oferi informațiile solicitate asupra\n' +
      'produsului și pentru a vă trimite un chestionar de satisfacție dacă doriți,\n' +
      'vom prelucra informațiile furnizate în calitatea noastră de operator de\n' +
      'date. Temeiul legal pentru procesarea cererii dumneavoastră este\n' +
      'consimțământul datorită potențialelor date legate de sănătate pe care le\n' +
      'puteți furniza în cererea dumneavoastră. Aceste informații vor fi păstrate\n' +
      'atât timp cât este necesar pentru procesarea cererii dumneavoastră și\n' +
      'până la 5 ani pentru a oferi dovada că cererea dumneavoastră a fost\n' +
      'procesată și pentru a ne conforma obligațiilor legale. Baza legală pentru\n' +
      'trimiterea chestionarului de satisfacție este și consimțământul\n' +
      'dumneavoastră. Aceste informații vor fi păstrate pentru timpul necesar\n' +
      'trimiterii chestionarului de satisfacție și până la maximum 1 an. Pentru a\n' +
      'afla mai multe despre prelucrarea datelor dumneavoastră cu caracter\n' +
      'personal, vă rugăm să consultați <a href="https://www.bonduelle.ro/politica-de-confidentialitate" target="_blank" class="underline">Politica noastră de confidențialitate</a>.',
    SATISFACTION_CHECKBOX: 'Bifând această casetă, sunteți de acord să primiți un chestionar de\n' +
      'satisfacție (constând dintr-o singură întrebare) odată ce solicitarea dvs. a\n' +
      'fost procesată. Asta este opțional.',
    CHECKBOX_TEXT: 'Bifând această casetă, sunteți de acord ca Bonduelle să prelucreze datele\n' +
      'personale de sănătate pe care le puteți furniza în mesajul dvs. în scopul\n' +
      'unic de a răspunde solicitării dumneavoastră. <strong>Acest lucru este obligatoriu\n' +
      'pentru a vă procesa cererea</strong>.',
    PHONE: 'De luni până vineri de la 9:00 la 18:00 la 021 207 00 10  (apel fără taxă, excluzând weekend-urile și sărbătorile legale)',
    CALL_US: 'Să ne suni',
    TO_WRITE: 'Scrie-ne',
    PHONE_NUMBER: '021 207 00 10',
    SUCCESS_MESSAGE: 'Mulțumim! Mesajul dumneavoastră a fost înregistrat și va fi procesat în cel mai scurt timp posibil.<br><br>\r\n' +
      'Dacă aveți nevoie de ajutor suplimentar, vă stăm la dispoziție de luni până vineri, între orele 9 și 18, la numărul de telefon 021 207 00 10 (apel fără taxă).<br><br>\r\n' +
      'Ca parte a monitorizării calității serviciilor noastre, toate apelurile telefonice pot fi înregistrate.',
    PHOTOERROR: { MESSAGE: '' }
  },
  GLOBAL: { READ_MORE: 'Citeşte mai mult' },
  FLOWBOX: {
    GENERIC_KEY: 'ba_GnGC3TKedKEe0-gKomQ',
    PRODUCT_KEY: '1hRXF7TUSt-fhhlJASv-TQ',
    RECIPE_KEY: 'Ulp6NW8-QFOoSa61nBIlaA',
    HOME_KEY: 'g7--tXG3QgiwJjjpsaMmrg'
  },
  TOKEN: {
    FACEBOOK: '',
    PAGE_FACEBOOK_ID: '',
    INSTAGRAM: '',
    INSTA_ID: '17841407279489116',
    YOUTUBE: '',
    TWITTER: '',
    MAGAZINE: '6318061952.9bbab10.8842be1c95904c9d80ff59765a3a2ccc'
  },
  COOKIE: {
    BANNER: {
      MANDATORY: 'Necesar',
      ANALYTICS: 'ANALIZĂ',
      MARKETING: 'MARKETING',
      SOCIAL_MEDIA: 'SOCIAL MEDIA',
      ACCEPT: 'Acceptă toate',
      REJECT: 'Respinge toate',
      SET_UP: 'Configurare',
      MANDATORY_DETAILS: 'Cookie-urile sunt necesare pentru funcționarea site-ului, de exemplu pentru a stoca informațiile de autentificare și pentru a vă oferi o conexiune sigură.\n' +
        'Cookie-urile necesare pentru a utiliza site-ul bonduelle.fr sunt:\n' +
        '<ul>\n' +
        '<li>cookie-uri de sesiune (SESSION_ID): cookie generat de aplicații bazate pe PHP. Acesta este un identificator de uz general utilizat pentru a gestiona variabilele sesiunii utilizator. Este un număr generat aleatoriu, modul în care este utilizat poate fi specific site-ului, dar un exemplu bun este menținerea unei stări de conectare pentru un utilizator între pagini.</li>\n' +
        '</ul>',
      ANALYTICS_DETAILS: 'Cookie-urile funcționale ne ajută să optimizăm site-ul, de exemplu, pentru a colecta statistici, pentru a vă înregistra preferințele pentru interacțiunile sociale sau pentru a măsura performanța și stabilitatea aplicației noastre.\n' +
        '<br>\n' +
        'Cookie-urile analitice utilizate de site-ul bonduelle.com sunt:\n' +
        '<ul>\n' +
        '<li>DoubleClick: Acest cookie este setat de DoubleClick (deținut de Google) pentru a crea un profil al intereselor vizitatorilor site-ului web și pentru a afișa reclame relevante pe alte site-uri. Activitatea principală a companiei este schimbul de anunțuri de licitare în timp real de la Google.</li>\n' +
        '<li>Google Analytics: aceste module cookie permit urmărirea celor mai vizitate pagini și a traficului pe site.</li>\n' +
        '<li>UTMTracking: acest cookie permite gestionarea variabilelor UTM pentru o urmărire optimă a diferitelor campanii de publicitate online</li>.\n' +
        '</ul>',
      MARKETING_DETAILS: 'Cookie-urile de marketing ne permit să urmărim preferințele dumneavoastră pentru rețete și produse pentru a vă oferi întotdeauna produsele care se potrivesc cel mai bine dorințelor dumneavoastră.\n' +
        '<br>\n' +
        'Cookie-urile de marketing utilizate de site-ul bonduelle.fr sunt:\n' +
        '<ul>\n' +
        '<li> Prediggo: acest instrument folosește cookie-ul SESSION_ID pentru a vă oferi produsele și rețetele care se potrivesc cel mai bine navigării și căutărilor dvs. </li>\n' +
        '<li>ClickToBuy: acest cookie ne permite să vă amintim locația, astfel încât să vă putem oferi cele mai apropiate magazine care dețin produsele Bonduelle pe care le căutați.</li>\n' +
        '<li>Rețele sociale: aceste module cookie permit ca „SocialWall” Bonduelle să fie afișat pe pagina de pornire a site-ului. Acest perete"" vă permite să vedeți toate cele mai recente postări de pe rețelele sociale majore utilizate de echipele Bonduelle.</li>\n' +
        '<li>FlowBox: acest cookie permite utilizarea serviciilor software-ului flowbox, care ne permite să oferim vizitatorilor noștri rețetele și creațiile altor utilizatori de internet care folosesc produsele Bonduelle.</li>\n' +
        '</ul>',
      V2: 'ok'
    },
    MODAL: {
      TITLE: 'Gestionarea cookie-urilor',
      TEXT: 'Grupul Bonduelle se angajează să asigure protecția datelor cu caracter personal ale utilizatorilor site-urilor sale Web și, în special, a site-ului Web Bonduelle.fr. <br>Pentru a vă permite să beneficiați de serviciile oferite de site, cum ar fi consultanța produse, descoperirea de noi rețete gourmet, abonarea la anumite servicii precum buletinele noastre informative și, de asemenea, pentru a optimiza utilizarea și personalizarea acestuia în funcție de profilul dvs., site-ul folosește cookie-uri.Grupul Bonduelle este pe deplin angajat să asigure un nivel ridicat de protecție în ceea ce privește astfel de Date cu caracter personal și pentru a respecta toate regulile aplicabile de protecție a datelor atunci când procesează Date cu caracter personal în legătură cu utilizarea site-ului său web.\n' +
        'Astfel, puteți activa/dezactiva în orice moment cookie-urile.',
      CONFIG: 'Configurare',
      SAVE: 'Salvați'
    }
  },
  LINK: { JOB_OFFERS: 'https://jobs.bonduelle.com/?locale=en_US' },
  CLIC2BUY: { INFOS: '' }
};
