import { Injectable, makeStateKey, TransferState } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { BreadCrumb } from './breadcrumb.interface';

const BREADCRUMB_KEY = makeStateKey<BreadCrumb[]>('breadcrumbs');

@Injectable({
  providedIn: 'root'
})
export class SomBreadcrumbService {

  private breadcrumbSubject: BehaviorSubject<BreadCrumb[]> = new BehaviorSubject([]);
  public breadcrumb$: Observable<BreadCrumb[]> = this.breadcrumbSubject.asObservable();
  public isTextInsideImage = true;

  constructor(private transferState: TransferState) {}

  setBreadcrumb(breadcrumb: BreadCrumb[]): void {
    this.breadcrumbSubject.next(breadcrumb);
    this.transferState.set(BREADCRUMB_KEY, breadcrumb);
  }

  getBreadcrumb(): BreadCrumb[] {
    const breadcrumb = this.transferState.get(BREADCRUMB_KEY, []);

    if (breadcrumb.length) {
      return breadcrumb
    }
    
    return this.breadcrumbSubject.getValue();
  }

  setTextInsideImage(value: boolean) {
    this.isTextInsideImage = value;
  }

}
