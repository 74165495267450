<div class="sf-item-flip-relative">
  <div class="sf-item-flip" (touchstart)="state = 'flipped'" (mouseenter)="state = 'flipped'" (mouseleave)="state = 'unflipped'">

    <div class="sf-item-flip-flipper" [@flip]="state">
      
      <div class="sf-item-flip-sides">
        <ng-content select="[flip-front]"></ng-content>
      </div>
      <div class="sf-item-flip-sides sf-item-flip-sides-back">
        <ng-content select="[flip-back]"></ng-content>
      </div>
    </div>

  </div>
  <mat-icon *ngIf="state ==='flipped'" class="sf-item-flip-icon" svgIcon="close" (click)="state = 'unflipped'"></mat-icon>
</div>