<div (sfImgLazyLoad)="showImage = true" class="sf-image" (mouseenter)="hover = true" (mouseleave)="hover = false">
  <ng-container>
      <img
        *ngIf="showImage"
        [hidden]="imageSrcHover && hover"
        [src]="imageSrc" [alt]="imageAlt"
        onerror="this.src='/assets/images/placeholder.png';this.onerror='';"
        [ngClass]="{'sf-image-fill': fitting === 'fill', 'sf-image-contain': fitting === 'contain', 'sf-image-cover': fitting === 'cover', 'sf-image-scale-down': fitting === 'scale-down', 'sf-image-none': fitting === 'none', 'sf-image-left': align === 'left', 'sf-image-right': align === 'right', 'sf-image-center': align === 'center', 'sf-image-top': align === 'top', 'sf-image-bottom': align === 'bottom'}"
        loading="lazy"
      >
      <img *ngIf="showImage && imageSrcHover"
        [hidden]="imageSrcHover && !hover"
        [src]="imageSrcHover" [alt]="imageAlt"
        [ngClass]="{'sf-image-fill': fitting === 'fill', 'sf-image-contain': fitting === 'contain', 'sf-image-cover': fitting === 'cover', 'sf-image-scale-down': fitting === 'scale-down', 'sf-image-none': fitting === 'none', 'sf-image-left': align === 'left', 'sf-image-right': align === 'right', 'sf-image-center': align === 'center', 'sf-image-top': align === 'top', 'sf-image-bottom': align === 'bottom'}"
        loading="lazy"
      >
    </ng-container>
</div>

