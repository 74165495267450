/* tslint:disable */export const de_de = {
  '404': {
    TITLE: 'Ups...',
    PAGENOTFOUND: "Da brat mir doch einer 'nen Mais ... die Seite existiert nicht!",
    BUTTON: 'Zurück zur Startseite',
    RECIPES: 'Entdecke unsere Rezepte'
  },
  HOME: {
    SHOWCASE: 'Rezepte',
    CATCH_PHRASE: '',
    SHOWCASE_SEE_ALL: 'Alle Rezepte anzeigen',
    MAGAZINE: { TITLE: 'TOP', BUTTON: 'Entdecke Gemüse4Me' },
    OUR_VEGETABLES: 'Unser Gemüse',
    SOCIAL_WALL: 'Teile die Leidenschaft für Gemüse',
    FAQ_TITLE: 'Häufig gestellte Fragen',
    FAQ_SEE_MORE: 'Mehr anzeigen',
    FLOWBOX: ''
  },
  SELECT: { DEFAULT_VALUE: 'Bonduelle Auswahl' },
  MENU: {
    CONTACT_US: 'Kontakt',
    MY_ACCOUNT: 'Mein Konto',
    GOOD_DEALS: 'Promotion',
    NEWSLETTER: 'Newsletter'
  },
  FOOTER: {
    LINK: {
      BONDUELLE: 'Bonduelle.de',
      CREDITS: 'Impressum',
      LEGAL_NOTICE: '',
      TERMS_OF_SALES: 'AVB',
      COOKIES: 'Cookies',
      SITEMAP: 'Sitemap',
      PRIVACY_POLICY: 'Datenschutz',
      ALL_RIGHTS_RESERVED: '© 2021 BDG GmbH'
    },
    HISTORY: {
      TITLE: 'Unsere Verpflichtung',
      TEXT: 'Bonduelle ist ein Familienunternehmen, das seit sieben Generationen daran arbeitet, eine landwirtschaftliche Produktion zu entwickeln, die den Boden und die Menschen respektiert. Wir stehen für eine effiziente, intelligente und unterstützende Agrarökologie, die entschlossen in die Zukunft blickt. Aus der Natur, für die Natur, für eine bessere Zukunft.'
    },
    NEWSLETTER: {
      TITLE: 'Bonduelle Community',
      TEXT: 'Ich möchte den Bonduelle Newsletter abonnieren und per E-Mail über aktuelle Angebote und Aktionen informiert werden. Die Hinweise zum Datenschutz habe ich gelesen.',
      PLACEHOLDER: 'Deine E-Mail',
      GDPR_LINK: 'Hinweise zum Datenschutz'
    }
  },
  PRODUCTS: 'Produkte',
  MAGAZINE: {
    HOME: 'Willkommen bei Gemüse4Me',
    CATCH_PHRASE: '',
    MENU: { BONDUELLE_LINK: 'zurück zu Bonduelle.de' },
    SHOWCASE: 'Übersicht',
    PREVIOUS_ARTICLES: 'Vorherige Artikel',
    PREVIOUS_ARTICLES_SEE_ALL: 'Alle Artikel anzeigen',
    PREVIOUS_EDITIONS: 'Unsere vorherigen Artikel',
    PREVIOUS_EDITIONS_SEE_ALL: 'Alle Zahlen sehen',
    PREVIOUS_ARTICLES_BY_CATEGORY: 'Vorherige Artikel nach Kategorien',
    RUBRIC: {
      SEE_ALL: 'Alle Rezepte anzeigen',
      SEE_ALL_ARTICLES: 'Alle Artikel anzeigen',
      ON: 'Artikel über'
    },
    RUBRIC_COOK: { ON: 'Rezepte zu' },
    ARTICLE: { JOIN_US: 'Folge uns', READ_ALSO: 'Auch interessant' },
    RECIPE: {
      INGREDIENTS: 'Zutaten',
      INGREDIENTS_LABEL_DESC: 'für {{value}} Personen',
      INGREDIENTS_LABEL_ONE_DESC: 'für {{value}} Person',
      INGREDIENTS_LABEL: 'Zutaten für {{value}} Personen',
      INGREDIENTS_LABEL_ONE: 'Zutaten für {{value}} Person',
      PEOPLES: '{{value}} Personen',
      PEOPLE: '{{value}} Person',
      DIFFICULTY: { EASY: 'Einfach', MEDIUM: 'Mittel', HARD: 'Schwer' },
      COST: { CHEAP: 'Günstig', EXPENSIVE: 'Teuer' },
      TIMING: { MIN: 'Min', HOURS: 'Std' },
      STEP: 'Schritt',
      DISCOVER_ALSO: 'Entdecke auch ...'
    }
  },
  ARTICLE: { DISCOVER_ALSO: 'Entdecke auch ...', INGREDIENTS: 'Zutaten' },
  SF: {
    SLIDE: { DISCOVER: 'Entdecken' },
    SEARCH: { PLACEHOLDER: 'Suchen', ALL: 'Alles' },
    NEWSLETTER: {
      BTN_LABEL: 'Registrieren',
      PLACEHOLDER: 'Deine E-Mail',
      ERROR_MESSAGE: 'Ungültige E-Mail',
      ALREADY_SUBSCRIBED: 'Du bist bereits registriert',
      GDPR: {
        TXT: 'Weitere Informationen zum Datenschutz',
        LINK: '/datenschutz'
      }
    },
    MONTHLY_FOLDER: { TITLE: 'Nachrichten' },
    MINI_NEWSLETTER: {
      PLACEHOLDER: 'Deine E-Mail',
      GDPR: {
        TXT: 'Weitere Informationen zum Datenschutz',
        LINK: '/datenschutz'
      }
    },
    SOCIALITEM: {
      SHARE: 'Teilen',
      COMMENT: 'Kommentieren',
      BONDUELLE: 'Bonduelle'
    },
    SEARCHRECIPE: { DISCOVER_ALL: 'Alle Rezepte' },
    FORM: {
      ERROR: 'Falsche Eingabe',
      PHOTO: {
        ADD: 'Foto hinzufügen',
        DELETE: 'Lösche ein Foto',
        SEE: 'Ansicht'
      }
    }
  },
  CORPORATE: {
    COMPANY_STORY: 'Unsere Geschichte',
    CSR_COMMITMENT: 'Unser Engagement',
    VEGETABLE_INNOVATIONS: 'Unsere Innovationen',
    NEWS: 'Neueste Artikel',
    SEE_MORE: 'Weitere Artikel anzeigen',
    ON: 'Artikel über'
  },
  NEWSLETTER: {
    TITLE: 'Abonnieren Sie den Newsletter',
    SUBTITLE: 'Gute Ideen, kulinarische Inspiration und ein bisschen Kultur !'
  },
  ROUTES: {
    products: 'produkte',
    news: 'aktuelles',
    'since-1853': 'ueber-uns',
    'field-plate': 'vom-feld-auf-den-teller',
    'company-story': 'unsere-geschichte',
    'csr-commitments': 'unsere-engagement',
    'vegetal-innovations': 'unsere-innovationen',
    magazine: 'gemuese4me',
    all: 'alle',
    article: 'artikel',
    cook: 'kochen',
    cultivate: 'anbauen',
    explore: 'entdecken',
    recipe: 'rezeptwelt',
    recipes: 'rezept',
    'recipes-home': 'rezepte',
    r: 'r',
    credits: 'credits',
    'legal-notice': 'legal-notice',
    'terms-of-sales': 'avb',
    cookies: 'datenschutz',
    sitemap: 'sitemap',
    'privacy-policy': 'datenschutz',
    'all-rights-reserved': 'alle-rechte-vorbehalten',
    search: 'suche',
    'articles-search': 'artikel',
    vegetables: 'unser-gemuese',
    p: 'p',
    contact: 'kontakt',
    'info-product': 'produktinformationen',
    'note-product': 'produktnotizen',
    suggestion: 'empfehlung',
    'other-request': 'andere-anfrage',
    faq: 'faq',
    'all-themes': 'alle',
    'products-search': 'produkt-suche',
    recipesearch: 'rezept-suche',
    'product-news': 'neue-produkte',
    'good-deals': 'promotionen',
    'not-found': 'nicht-gefunden',
    'menu-page': 'pressemitteilungen',
    'menu-one': 'pressemitteilungen',
    'menu-two': 'logos',
    'menu-three': 'produktbilder',
    'menu-four': '',
    'menu-external': '',
    'theme-pasta-rice': '',
    'theme-bowls': '',
    'theme-soup': '',
    'theme-snack': '',
    'theme-sauce': '',
    'theme-unique-plate': '',
    'theme-over': '',
    'theme-legumin': 'legumin',
    'theme-ingredients-recipes': '',
    'theme-cook-plate': ''
  },
  BREAD: {
    products: 'produkte',
    news: 'aktuelles',
    'since-1853': 'ueber-uns',
    'field-plate': 'vom-feld-auf-den-teller',
    'company-story': 'unsere-geschichte',
    'csr-commitments': 'unsere-engagement',
    'vegetal-innovations': 'unsere-innovationen',
    magazine: 'gemuese4me',
    all: 'alle',
    article: 'artikel',
    cook: 'kochen',
    cultivate: 'anbauen',
    explore: 'entdecken',
    recipe: 'rezeptwelt',
    recipes: 'rezept',
    'recipes-home': 'rezepte',
    'articles-search': 'artikel',
    search: 'suche',
    vegetables: 'unser-gemuese',
    contact: 'kontakt',
    'info-product': 'produktinformationen',
    'note-product': 'produktnotizen',
    suggestion: 'empfehlung',
    'other-request': 'andere-anfrage',
    faq: 'faq',
    'all-themes': 'alle',
    'products-search': 'produkt-suche',
    recipesearch: 'rezept-suche',
    'product-news': 'neue-produkte',
    'good-deals': 'promotionen',
    'not-found': 'nicht-gefunden',
    'menu-page': 'pressemitteilungen',
    'menu-one': 'pressemitteilungen',
    'menu-two': 'logos',
    'menu-three': 'produktbilder',
    'menu-four': ''
  },
  MENU_PAGE: {
    TITLE1: 'Pressemitteilungen',
    TITLE2: 'Logos ',
    TITLE3: 'Produktbilder',
    TITLE4: '',
    EXTERNAL: ''
  },
  TECHNICAL: { MENU_PAGE: 'vegetarian_passions' },
  SEO: {
    TITLE: {
      MENU_ONE: 'Pressemitteilungen',
      MENU_TWO: 'Logos ',
      MENU_THREE: 'Produktbilder',
      MENU_FOUR: '',
      HOME: 'Bonduelle: 100 Jahre Leidenschaft für Gemüse',
      SEARCH: {
        MAIN: '{{value}} | Bonduelle',
        RECIPES: '{{value}} sortiert nach Rezept | Bonduelle',
        VEGETABLES: '{{value}} sortiert nach Gemüse | Bonduelle',
        ARTICLES: '{{value}} sortiert nach Artikel | Bonduelle'
      },
      CONTACT: 'Verbraucherservice Kontakt & Informationen | Bonduelle',
      RECIPES: 'Rezepte: Suppen, Hauptspeisen, Nachtische, ... | Bonduelle',
      RECIPE_HOME: 'Die Rezeptwelt von Bonduelle',
      RECIPE: 'Rezept {{value}} | Bonduelle',
      PRODUCTS: 'Gemüsekonserven & frischer Salat | Bonduelle',
      NEWS: 'Entdecke die Vielfalt von Gemüse | Bonduelle',
      CORPORATE: {
        NEWS: 'Aktuelle Artikel und Neuigkeiten rund um das Thema Gemüse | Bonduelle',
        COMPANY_STORY: 'Entdecke das Unternehmen, sein Engagement und seine Geschichte',
        CSR_COMMITMENTS: 'Entdecke unsere ethischen Werte, Prinzipien und Verpflichtung | Bonduelle',
        VEGETAL_INNOVATIONS: 'Entdecke unsere Innovationen: Betriebe, Produkte, Agrarkultur | Bonduelle',
        FIELD_PLATE: 'Gemüse: das Beste für die besten Rezepte | Bonduelle',
        ARTICLE: '{{value}} | Bonduelle',
        VEGETABLE: 'Rezeptideen, gut kochen {{value}} | Bonduelle'
      },
      PRODUCT: '{{value}}: Bonduelle verzehrfertiges Gemüse',
      MAGAZINE: {
        NEWS: 'Entdecke die Vielfallt von Gemüse mit Rezepten, Tipps und News | Bonduelle | Gemüse4Me',
        ARTICLE: '{{value}} | Gemüse4Me  | Bonduelle',
        COOK: 'Einfache & schnelle Rezepte mit Gemüse | Bonduelle',
        GROW: 'Gemüse im Gemüsegarten selber anbauen und pflegen | Bonduelle',
        EXPLORE: 'Entdecke Gemüse neu | Bonduelle',
        RECIPE: 'Rezept {{value}} | Bonduelle'
      },
      FAQ: 'FAQ: Häufig gestellte Fragen | Bonduelle',
      NOTFOUND: 'Fehler 404, bitte versuchen Sie es später noch einmal | Bonduelle',
      RECIPES_ALL: 'Alle unsere Rezepte',
      RECIPETYPE: 'Rezept: {{value}} von Gemüse',
      RECIPESEASON: 'Rezepte für den {{value}}',
      RECIPEPREPARATION: 'Rezepte: Vorbereitungszeit {{value}}',
      RECIPEDIFFICULTY: 'Einfaches Küchen- und Gemüserezept: Gemüse {{value}}',
      RECIPEVEGETABLE: 'Einfaches Rezept mit {{value}}',
      RECIPECOST: 'Rezept & Gemüse {{value}}',
      RECIPEDISH: 'Koch- und Gemüserezepte für {{value}}',
      RECIPEDESC: 'Rezept & Gemüse {{value}}',
      '2RECIPEFACETS': 'Rezept {{value}}',
      '4RECIPEFACETS': 'Rezept {{value}}',
      PRODUCTSEASON: 'Unser Gemüse der Saison: {{value}}',
      PRODUCTTECHNO: '{{value}}',
      PRODUCTVEGETABLE: 'Produkte mit {{value}}',
      PRODUCTRANGE: 'Produkte mit {{value}}',
      THEME_ALL: 'Verzehrfertiges Gemüse, frische Salate und mehr...',
      THEME_STARTER: 'Rezepte für die Vorspeise',
      THEME_SALAD: 'Rezepte für Salat',
      THEME_CAN: '',
      THEME_FROZEN: '',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: '',
      THEME_RECIPE: 'Einfache und schnelle Rezepte',
      THEME_PLATE: 'Rezept-Ideen für warme und kalte Hauptgänge',
      THEME_ACC: 'Beilagen-Rezepte',
      THEME_SNACK: 'Rezepte für leckere Snacks',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': '{{value}}'
    },
    DESCRIPTION: {
      MENU_ONE: '',
      MENU_TWO: '',
      MENU_THREE: '',
      MENU_FOUR: '',
      HOME: 'Entdecken Sie unser Gemüse, leckere Rezeptideen und tolle Inspirationen. Gemüse lecker leicht gemacht von Bonduelle',
      SEARCH: {
        MAIN: 'Entdecken Sie unser Gemüse und tolle Rezeptideen für {{value}}. Einfache und leckere Rezepte mit Bonduelle',
        RECIPES: 'Alle unsere {{value}} nach Rezept sortiert. Einfache und leckere Rezepte mit Gemüse.',
        VEGETABLES: 'Alle unsere {{value}} sortiert nach Gemüse. Einfache und leckere Rezepte. Gekochtes Gemüse.',
        ARTICLES: 'Alle unsere {{value}} sortiert nach Artikel. Einfache und leckere Rezepte. Gekochtes Gemüse.'
      },
      CONTACT: 'Haben Sie eine Frage oder benötigen Sie Informationen zu einem Produkt, einer Dienstleistung oder einem laufenden Betrieb? Sie können unseren Kundenservice kontaktieren. Antwort innerhalb von 48 Stunden garantiert.',
      RECIPES: 'Entdecken Sie tolle Rezepte mit Gemüse und machen Sie Ihren Liebsten eine Freude. Schnell, einfachen und lecker.',
      RECIPE_HOME: 'Entdecken Sie leckere Gemüse-Rezepte für die Feiertage. Verwöhnen Sie Ihre Liebsten zu einem besonderen Anlass.',
      RECIPE: '',
      PRODUCTS: 'Unsere große Auswahl an Gemüse ermöglicht es Ihnen schnell und einfache, leckere und ausgewogene Rezepte zuzubereiten.',
      NEWS: 'Entdecken Sie wie Sie Ihr Gemüse zubereiten und kochen sollten, um schmackhafte Gerichte zu kreieren. ',
      CORPORATE: {
        NEWS: 'Aktuelle Nachrichten aus der Welt der Landwirtschaft, Pflanzeninnovation, Lebensmittelverschwendung und wie man auf einfache Weise besser isst.',
        COMPANY_STORY: 'Möchten Sie mehr über unsere Welt des Gemüses und unserer Landwirtschafts- und Gemüseinnovationen erfahren? Entdecken Sie dies und vieles mehr auf unserem Blog',
        CSR_COMMITMENTS: 'Fragen Sie sich, wie unser Gemüse angebaut wird, woher es kommt, wie wir die Umwelt respektieren? Hier finden Sie Antworten.',
        VEGETAL_INNOVATIONS: 'Gemüse steht im Mittelpunkt unseres Interesses. Neue Wege & Innovationen, um umweltfreundlicher und weniger verschwenderisch zu werden.',
        VEGETABLE: '',
        FIELD_PLATE: 'Entdecken Sie unsere köstlichen Rezepte. Sie werden Ihren Gaumen erfreuen. Einfache und leckere Rezepte mit Gemüse.',
        ARTICLE: ''
      },
      PRODUCT: '{{value}}, um ein leckeres Rezept zuzubereiten und Ihren Lieben eine Freude zu machen.',
      MAGAZINE: {
        NEWS: 'Entdecke Gemüse4Me mit tollen Rezepten, Tipps und Wissenswertem Rund um das Thema Gemüse.',
        ARTICLE: '',
        COOK: 'Unsere leckeren Rezepte werden euren Gaumen verwöhnen. Entdeckt sie und weitere Tipps und Inspirationen auf Gemüse4Me',
        GROW: 'Möchtet Ihr eigenes Gemüse im Garten anbauen? Entdeckt unsere Tipps und Tricks für den Anbau und die Pflege des Gemüses.',
        EXPLORE: 'Entdecke mit einfachen und fantasievollen Rezepten neue Gemüsesorten für Deine Kinder.',
        RECIPE: ''
      },
      FAQ: 'Haben sie eine Frage? Wir haben alle Fragen zusammengefasst, die unsere Kunden am häufigsten gestellt haben.',
      PAGE: 'Seite ',
      RECIPETYPE: 'Entdecken Sie unsere Rezepte {{value}} und wie Sie am besten Ihr Gemüse kochen. Rezeptidee, schnell, originell, einfach.',
      RECIPESEASON: 'Entdecke tolle Rezept-Ideen für den {{value}}. Rezepte für die schnelle, trendige oder einfache Küche',
      RECIPEPREPARATION: 'Entdecken Sie unsere Rezepte und wie Sie Ihr Gemüse in {{value}} kochen. Rezeptidee, schnell, originell, einfach.',
      RECIPEDIFFICULTY: 'Entdecken Sie unsere Rezepte und wie Sie Ihr Gemüse zubereiten. Rezeptidee, schnell, originell, einfach.',
      RECIPEVEGETABLE: 'Entdecken Sie unsere {{value}} Rezepte und wie Sie Ihr Gemüse kochen. Rezeptidee, schnell, originell, einfach.',
      RECIPECOST: 'Entdecken Sie unsere Rezepte und wie Sie Ihr Gemüse für {{value}} kochen. Rezeptidee, schnell, originell, einfach.',
      RECIPEDISH: 'Entdecken Sie unsere Rezepte und wie Sie Ihr Gemüse für {{value}} Gäste zubereiten. Rezeptidee, schnell, originell, einfach.',
      RECIPEDESC: 'Entdecken Sie unsere Rezepte und wie Sie Ihr Gemüse zubereiten. Rezeptidee, schnell, originell, einfach.',
      '2RECIPEFACETS': 'Entdecken Sie unsere Rezepte und wie Sie Ihr Gemüse zubereiten. Rezeptidee, schnell, originell, einfach.',
      '4RECIPEFACETS': 'Entdecken Sie unsere Rezepte und erfahren Sie wie Sie Ihr Gemüse am besten zubereiten.',
      PRODUCTSEASON: 'Unsere große Auswahl an Gemüse ermöglicht es Ihnen, leckere, einfache und ausgewogene saisonale Rezepte zuzubereiten. Einfache und leckere Rezepte. Gekochtes Gemüse.',
      PRODUCTTECHNO: 'Unsere große Auswahl an Gemüse ermöglicht es Ihnen, leckere, einfache und ausgewogene Rezepte zuzubereiten. Einfache und leckere Rezepte. Gekochtes Gemüse.',
      PRODUCTVEGETABLE: 'Unsere breite Palette an Gemüse ermöglicht es Ihnen, leckere, einfache und ausgewogene Rezepte auf der Basis von {{value}} zuzubereiten. Einfache und leckere Rezepte. Gekochtes Gemüse.',
      PRODUCTRANGE: 'Unsere breite Palette an Gemüse ermöglicht es Ihnen, leckere, einfache und ausgewogene Rezepte auf der Basis von {{value}} zuzubereiten. Einfache und leckere Rezepte. Gekochtes Gemüse.',
      THEME_ALL: 'Unsere große Auswahl an Gemüse ermöglicht es Ihnen, leckere, einfache und ausgewogene Rezepte zuzubereiten',
      THEME_STARTER: 'Auf der Suche nach einer neuen Vorspeise? Bei uns findest Du eine große Auswahl an tollen Rezept-Ideen für Vorspeisen und vieles mehr.',
      THEME_SALAD: 'Auf der Suche nach einem neuen Salat-Rezept? Bei uns findest Du eine große Auswahl an tollen Rezept-Ideen für jeden Anlass.',
      THEME_CAN: '',
      THEME_FROZEN: '',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: '',
      THEME_RECIPE: 'Bei uns findest Du eine große Auswahl an tollen Rezept-Ideen für jeden Anlass.',
      THEME_PLATE: 'Auf der Suche nach einem neuen Rezept? Bei uns findest du Rezept-Ideen zu jedem Anlass, z.B. zu Hauptgängen',
      THEME_ACC: 'Schnell zubereitete und einfach Beilagen mit Gemüse. Bei uns findest du tolle Rezept-Ideen für jeden Anlass.',
      THEME_SNACK: 'Auf der Suche nach einer neuen Snack-Idee? Bei uns findest du tolle Rezept-Ideen zu jedem Anlass.',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': 'Unsere große Auswahl an verzehrfertigem Gemüse ermöglicht es Ihnen ganz einfach, leckere und ausgewogene Rezepte zuzubereiten.',
      NOTFOUND: 'Die angeforderte Seite konnte leider nicht gefunden werden. Bitte führen sie eine neue Suchanfrage durch oder versuchen Sie es zu einem späteren Zeitpunkt erneut.'
    },
    PIPE: { SOCIETY: ' | Bonduelle' },
    THEME_ALL: 'alle',
    THEME_STARTER: 'Vorspeise',
    THEME_SALAD: 'rezepte-fuer-salate',
    THEME_CAN: '',
    THEME_FROZEN: '',
    THEME_READY_MEALS: '',
    THEME_BIO: '',
    THEME_VEGETABLES: '',
    THEME_RECIPE: 'rezepte',
    THEME_PLATE: 'Rezepte für den Hauptgang',
    THEME_ACC: 'beilagen',
    THEME_SNACK: 'snacking'
  },
  VEGETABLE: {
    DISCOVER_VEGETABLE: 'Entdecke unser Gemüse',
    OURS_RECIPES: 'Unsere Rezepte',
    DISCOVER_ALSO: 'Entdecke auch ...',
    SEE_ALL: 'Entdecke unser Gemüse',
    ON: 'Gemüseart'
  },
  RECIPE: {
    'INGREDIENTS_LABEL ': 'Zutaten für {{value}} Personen',
    PEOPLES: '{{value}} Personen',
    DIFFICULTY: { EASY: 'einfach', MEDIUM: 'mittel', HARD: 'schwer' },
    COST: { CHEAP: 'günstig', EXPENSIVE: 'teuer' },
    TIMING: { MIN: 'min', HOURS: 'Std' },
    STEP: 'Schritt',
    DISCOVER_ALSO: 'Entdecke auch ...',
    HOME_SEE_MORE: 'Mehr Rezepte anzeigen',
    SHOWCASE: 'Top',
    HOME_BOOK: 'Unsere Rezepte',
    MIN_MORE: 'min. +',
    HOUR_MORE: 'Std. +',
    FILTER_BY_CRITERIA: 'filtern',
    SORT_BY: 'sortieren:',
    BEST_RATED: 'Beste Bewertung',
    MOST_CONSULTED: 'Meist gesehen',
    MEMBERS_RECIPES: 'Mitglieder Rezepte',
    CRITERIA: 'News',
    DISCOVER: { RECIPES: 'Entdekce unsere Rezepte' },
    DISCOVER_RECIPE: 'Zum rezept',
    INSTRUCTIONS: 'Anleitung',
    DEFAULT_TAG: 'Rezeptideen',
    MOST_RECENT: 'Neueste',
    RECIPE_VIDEOS: 'Rezeptvideos',
    PRODUCT_ASSOCIATE: 'Rezept mit',
    BUDGET_LOW: 'Günstig',
    BUDGET_HIGH: 'Teuer',
    BUDGET_MIDDLE: 'Mittel',
    PRINT_RECIPE: 'Rezept drucken',
    LET_YOURSELF_SURPRISED: 'Überrasche Dich selbst',
    COOK_TIP_TITLE: '',
    ENJOY_MEAL: '',
    FILTER: { LEGEND: 'Legend' }
  },
  PREDIGGO: { DIFFICULTY: { EASY: 'einfach', MEDIUM: 'mittel', HARD: 'schwer' } },
  COOKIES: {
    TEXT: 'Wenn Sie Ihren Besuch auf dieser Website fortsetzen, akzeptieren Sie die Verwendung von Cookies aus technischen Gründen, zu statistischen Zwecken und zum Management des Web-Verkehrs, zum Informationsaustausch mit Werbetreibenden auf anderen Websites, um Ihnen relevante Werbemitteilungen bereitzustellen, die Ihre Interessen widerspiegeln.',
    CLOSE: 'Schließen',
    MORE: 'Mehr erfahren',
    AGREE_YOUTUBE: 'Sie müssen Youtube-Cookies akzeptieren, um dieses Video zu sehen.',
    BUTTON_YOUTUBE: 'YouTube Cookies akzeptieren'
  },
  POPUP: { LAUNCH: 'POPUP-OUVERTURE-SITE' },
  FIELD_PLATE: { DISCOVER_VEGETABLE: 'Entdecke unser Gemüse' },
  SEARCH: {
    OUR_RECIPES: 'Unsere Rezepte',
    OUR_PRODUCTS: 'Gemüse',
    OUR_ARTICLES: 'Unsere Artikel',
    RECIPES: 'Unsere Rezepte',
    PRODUCTS: 'Gemüse',
    ARTICLES: 'Artikel',
    ALL: 'Alles anzeigen',
    SORT_BY: 'Sortieren nach',
    RESULTS: 'Ergebnisse',
    RESULT: 'Ergebnis',
    PRODUCT_OUT: 'Gemüse mit',
    APPLY: 'Anwenden',
    FILTER: 'Filter',
    RECIPE_OUT: 'Rezepte mit',
    NO_RESULT: 'Oops',
    SEE_MORE: 'Mehr entdecken',
    DISCOVER_RECIPES: 'Entdecke unsere Rezepte',
    SEE_MORE_OF: 'Mehr anzeigen',
    DELETE: 'Löschen'
  },
  RECIPE_LIST: {
    OUR_RECIPES: 'Rezepte',
    RESULTS: 'Ergebnis',
    SEE_MORE: 'Mehr Rezepte entdecken'
  },
  PRODUCT: {
    BUTTON: {
      ACTU: 'Produktneuheiten',
      FOUND: 'PRODUKT KAUFEN',
      DISCOVER: 'Entdecke das gesamte Sortiment'
    },
    NUTRITIONAL_VALUE: 'Nährwerte',
    CONDITIONING: 'Produktart',
    PORTIONS: 'Portionen',
    NET_WEIGHT: 'Nettogewicht',
    DRY_WEIGHT: 'Abtropfgewicht',
    INGREDIENTS: 'Zutaten',
    OUR_RECIPES: 'Entdecke unsere Rezepte',
    FOR: 'Für',
    PER_PORTION: 'Pro Portion',
    ENERGY: 'Brennwert',
    FAT: 'Fett',
    SATURATE: 'gesättigte Fettsäuren',
    GLUCIDES: 'Kohlenhydrate',
    FIBER: 'Ballaststoffe',
    PROTEINS: 'Eiweiß',
    SALT: 'Salz',
    DISCOVER_ALSO: 'Entdecke<br>auch ...',
    MORE_INFORMATION: 'mehr Informationen',
    TRACES: 'Spuren von',
    CONTAINS: 'Enthält',
    SLIDER: { SHEET: 'Produkt anzeigen', BUY: 'buy this product' },
    NUTRISCORE: 'information-nutriscore',
    TRIMAN: 'information-triman',
    AGRICONFIANCE: 'information-agriconfiance',
    BIO: 'information-bio',
    PESTICIDE_FREE: '',
    LEAF: '',
    LOCK: '',
    SEE_ALL: 'Zeige alle Gemüseprodukte',
    LEGISLATION: 'in Übereinstimmung mit den geltenden Rechtsvorschriften.',
    PORTION: { SINGULAR: 'portion', PLURAL: 'portionen' },
    DISCLAIMER: 'Rechtlicher Hinweis: Unsere Produkte können Rezepturänderungen unterliegen. Verbindlich sind die Angaben auf der Produktverpackung.',
    COOKING: '',
    CONSERVATION: ''
  },
  FAQ: {
    ON: 'Frage zu',
    ALL: 'Alles',
    SEE_MORE: 'Mehr Fragen anzeigen',
    TITLE_1: 'Alle Antworten auf Ihre Fragen',
    TITLE_2: 'Haben Sie eine Frage zu einem bestimmten Produkt?',
    LINK_LABEL: 'schreiben Sie uns'
  },
  FORM: {
    GO_TO_STEP: 'Gehe zu Schritt',
    STEP: 'Schritt',
    RECEIVE_EMAIL_COPY: 'Erhalte eine Kopie der E-Mail',
    SEND: 'Senden',
    GDPR: 'Weitere Informationen zur Verwaltung der persönlichen Daten',
    ERROR: 'Alle mit * gekennzeichneten Felder müssen korrekt ausgefüllt sein',
    SEND_ERROR: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.',
    NOTICE: 'Für die ordnungsgemäße Behandlung Ihrer Frage oder Ihres Kommentars, werden Sie von Bonduelle je nach Problem möglicherweise um Gesundheitsinformationen gebeten. Es ist nicht zwingend erforderlich, diese zu beantworten.',
    SENDING: 'Formular absenden',
    LOADING: 'Loading...',
    PRODUCT_INFORMATION_TITLE: 'Frage zu einem Produkt',
    PRODUCT_INFORMATION: {
      SEO_TITLE: 'Frage zu einem Produkt | Bonduelle',
      SEO_DESC: 'Wenden Sie sich an unseren Verbraucherservice, um Informationen zu einem Produkt aus unserem Sortiment zu erhalten.'
    },
    INFORMATIONS: 'Frage',
    MISTER: 'Herr',
    MISS: 'Frau',
    NAME: 'Name',
    OUR_NAME: 'Ihr Name',
    FIRSTNAME: 'Vorname',
    OUR_FIRSTNAME: 'Ihr Vorname',
    MAIL: 'E-Mail Adresse',
    MAIL_PLH: '',
    MAIL_CONFIRM: 'E-Mail Adresse bestätigen',
    MAIL_ERROR: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
    MAIL_ERROR_CONFIRM: 'Bitte bestätigen Sie Ihre E-Mail-Adresse.',
    ADDRESS: 'Straße und Hausnummer',
    STREET_PLH: 'Hausnummer',
    STREET: 'Straße & Hausnummer',
    ADDRESS_COMPLEMENT: 'Adresszusatz',
    ADDRESS_COMP_PLH: 'Zusätzliche Informationen',
    ZIPCODE: 'PLZ',
    CITY: 'Stadt',
    CITY_PLH: 'Ort',
    PHONE: 'Telefonnummer',
    PHONE_PLH: 'Ihre Telefonnummer',
    COUNTRY: 'Land',
    PRODUCT_INFORMATIONS: 'Frage zum Produkt',
    PRODUCT_INFORMATION_DESC: 'Bitte geben Sie die folgenden Informationen ein, damit unser Team Ihre Anfrage bearbeiten kann:',
    PRODUCT: {
      DETAIL: {
        DESC: 'Für die Anfrage können Sie die entsprechenden Felder ausfüllen und ein Foto als Beispiel hinzufügen. Die Fotos sollten den EAN-Code, die Chargennummer und das Verfallsdatum des Produkts enthalten.'
      },
      NOTE: {
        TITLE: 'Beschwerde zu einem Produkt',
        SEO_TITLE: 'Hinterlassen Sie hier eine Beschwerde zu einem Produkt | Bonduelle',
        SEO_DESC: 'Haben Sie einen Hinweis oder Kommentar zu einem Produkt aus unserem Sortiment? Unser Verbraucherservice hilft Ihnen gerne weiter.'
      }
    },
    PRODUCT_RANGE: 'Betroffene Produktgruppe',
    PRODUCT_NAME: 'Produktname',
    PRODUCT_NAME_PLH: 'Geben Sie den Namen des Bonduelle-Produkts an, auf das sich Ihre Frage bezieht',
    PRODUCT_INFORMATIONS_DETAIL: 'Detaillierte Informationen',
    PRODUCT_INFORMATIONS_LABEL: 'EAN-Code des Produkts',
    PRODUCT_INFORMATIONS_PLH: 'Der EAN-Code besteht aus 13 Ziffern auf der Verpackung des Produkts.',
    PRODUCT_LOT: 'Chargennummer des Produkts',
    PRODUCT_LOT_INFO: 'Die Chargennummer beginnt mit einem L \\n gefolgt von einer Reihe von Ziffern. \\n Sie finden sie auf der Vorderseite der Salat-Beutel \\n oder am Boden der Dose. \\n\\nZum Beispiel: L 72450492107: 13.',
    PHOTO: 'Ein Foto hinzufügen',
    EXPIRED_DATE: 'Verfallsdatum',
    EXPIRED_DATE_ERROR: 'Geben Sie das Datum wie folgt ein: TT / MM / JJJJ',
    EXPIRED_DATE_DESC: 'Das Verfallsdatum ist auf der Vorderseite der Verpackung oder auf dem Boden der Dose angegeben.',
    PHOTO_MORE: 'Fotos hochladen',
    MESSAGE: 'Ihre Nachricht *',
    OUR_MESSAGE: 'Schreiben Sie Ihre Nachricht',
    SALES_OPERATIONS: {
      TITLE: 'Informationen zu einem Handelsgeschäft',
      SEO_TITLE: 'Informationen zu einem Handelsgeschäft | Bonduelle',
      SEO_DESC: 'Wenden Sie sich an unseren Kundendienst, um Informationen zu einem aktuellen Geschäftsbetrieb zu erhalten.'
    },
    PRESS_SERVICE: 'Kontaktieren Sie den Presseservice',
    FOOD_SERVICE: 'Fragen oder Anmerkungen zum Bonduelle Foodservice?',
    BUSINESS_INFORMATION: '',
    MODAL_INFORMATION: '',
    JOB_OFFER: { TITLE: '', SEO_TITLE: '', SEO_DESC: '' },
    WEB_SUGGESTION: {
      TITLE: 'Verbesserungsvorschlag zur Website',
      SEO_TITLE: 'Verbesserungsvorschlag zur Website hinterlassen',
      SEO_DESC: 'Bitte wenden Sie sich an unseren Verbraucherservice, um einen Verbesserungsvorschlag zu hinterlassen.'
    },
    ANOTHER: {
      TITLE: 'Sonstige Anfragen',
      SEO_TITLE: 'Kontakt für sonstige Anfragen | Bonduelle',
      SEO_DESC: 'Kontaktieren Sie unseren Verbraucherservice für Informationen, Service oder Vorschläge.'
    },
    AMBASSADOR: 'Bonduelle-Botschafter werden',
    CATEGORY: { SALAD: '', FROZEN: '', CAN: '', TAKEAWAY: '', VEGETABLE: '' }
  },
  CONTACT: {
    TITLE: 'Zu Diensten',
    SPECIAL_INFO: '',
    TAKE_CARE: '',
    BY_MAIL: 'Per Post',
    BY_PHONE: 'Per Telefon',
    BY_SOURDLINE: '',
    MAIL_TEXT: 'BDG GmbH<br>\nAm Heilbrunnen 136/138<br>\n72766 Reutlingen',
    PHONE_TEXT: 'Montag bis Freitag von 9 bis 18 Uhr<br>Tel. +49 - 7121 - 1494 - 0<br>Fax.: +49 - 7121 - 59399 - 53<br>\n' +
      '<br>Verbraucherhotline<br>\n' +
      '\n' +
      '01805 - 97 28 20<br>\n' +
      '0,14€/Min. aus dem deutschen Festnetz, Mobilfunk max. 0,42€/Min.<br>\n' +
      'Unsere Hotline ist Montag bis Freitag von 8:00 bis 18:00 Uhr persönlich für Sie erreichbar.',
    SOURDLINE_TEXT: '',
    BY_DISTRIBUTOR: '',
    BY_DISTRIBUTOR_TEXT: '',
    SUBTITLE: 'Grund der Kontaktaufnahme:',
    AGREEMENT: '',
    READ_MORE: 'Darüber hinaus sind Sie jederzeit berechtigt, BONDUELLE aufzufordern, Ihre Rechte auf Zugang, Berichtigung, Löschung und Übertragbarkeit Ihrer personenbezogenen Daten sowie Ihre Rechte auf Einschränkung und Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten auszuüben. Um Ihre Rechte in Bezug auf Ihre personenbezogenen Daten auszuüben, schreiben Sie bitte an Dpo_france(at)bonduelle(dot)com oder per Post an die folgende Adresse: zu Händen des Datenschutzbeauftragten, Bonduelle, Rue Nicolas Appert, Postfach 30173, F- 59653 Villeneuve D’Ascq. Um mehr darüber zu erfahren, wie BONDUELLE Ihre personenbezogenen Daten erhebt und verwendet, klicken Sie hier',
    READ_MORE_LABEL: 'Lesen Sie auch',
    SATISFACTION_TEXT: 'Um mit Ihnen Kontakt aufzunehmen, Ihnen die zum Produkt\n' +
      'angeforderten Informationen zukommen zu lassen und Ihnen auf\n' +
      'Wunsch einen Zufriedenheitsfragebogen zuzusenden, verarbeiten wir die\n' +
      'bereitgestellten Informationen in unserer Eigenschaft als\n' +
      'Datenverantwortlicher. Rechtsgrundlage für die Bearbeitung Ihrer\n' +
      'Anfrage ist die Einwilligung aufgrund der ggf. von Ihnen im Rahmen Ihrer\n' +
      'Anfrage angegebenen gesundheitsbezogenen Daten. Diese\n' +
      'Informationen werden so lange aufbewahrt, wie es für die Bearbeitung\n' +
      'Ihrer Anfrage erforderlich ist, und bis zu 5 Jahre lang, um den Nachweis zu\n' +
      'erbringen, dass Ihre Anfrage bearbeitet wurde, und um unseren\n' +
      'gesetzlichen Verpflichtungen nachzukommen. Rechtsgrundlage für die\n' +
      'Zusendung des Zufriedenheitsfragebogens ist ebenfalls Ihre Einwilligung.\n' +
      'Diese Informationen werden so lange aufbewahrt, wie es zum Versenden\n' +
      'des Zufriedenheitsfragebogens erforderlich ist, maximal aber für ein Jahr.\n' +
      'Weitere Informationen zur Verarbeitung Ihrer personenbezogenen Daten\n' +
      'finden Sie in unserer <a href="https://www.bonduelle.de/datenschutz" target="_blank" class="underline">Datenschutzerklärung</a>.',
    SATISFACTION_CHECKBOX: 'Wenn Sie dieses Kästchen ankreuzen, erklären Sie sich damit\n' +
      'einverstanden, einen Zufriedenheitsfragebogen (bestehend aus einer\n' +
      'einzelnen Frage) zu erhalten, sobald Ihre Anfrage bearbeitet wurde. Das\n' +
      'ist optional.',
    CHECKBOX_TEXT: 'Indem Sie dieses Kästchen ankreuzen, erklären Sie sich damit\n' +
      'einverstanden, dass Bonduelle die persönlichen Gesundheitsdaten, die\n' +
      'Sie in Ihrer Nachricht angeben, ausschließlich zum Zweck der\n' +
      'Beantwortung Ihrer Anfrage verarbeiten darf. <strong>Dies ist für die Bearbeitung\n' +
      'Ihrer Anfrage zwingend erforderlich</strong>.',
    PHONE: '',
    CALL_US: 'Rufe uns an',
    TO_WRITE: 'Schreibe uns',
    PHONE_NUMBER: '0049712114940',
    SUCCESS_MESSAGE: 'Herzlichen Dank!<br>Ihre Nachricht wurde an unser Verbraucher-Service-Team gesendet.<br>Sie werden sich innerhalb von ca. 5 Arbeitstagen mit Ihnen in Verbindung setzen.<br><br>\n' +
      'Selbstverständlich stehen wir Ihnen unter 01805 - 97 28 20 <br>(0,14€/Min. aus dem deutschen Festnetz, Mobilfunk max. 0,42€/Min.) zur Verfügung.',
    PHOTOERROR: { MESSAGE: '' }
  },
  GLOBAL: { READ_MORE: '' },
  FLOWBOX: { GENERIC_KEY: '', PRODUCT_KEY: '', RECIPE_KEY: '', HOME_KEY: '' },
  TOKEN: {
    FACEBOOK: '',
    PAGE_FACEBOOK_ID: '',
    INSTAGRAM: '',
    INSTA_ID: '17841407530344296',
    YOUTUBE: '',
    TWITTER: '',
    MAGAZINE: '8280707075.56fadc4.53c44f24290d435994504821739c71c9'
  },
  COOKIE: {
    BANNER: {
      MANDATORY: 'Notwendige',
      ANALYTICS: 'Analyse',
      MARKETING: 'Marketing',
      SOCIAL_MEDIA: 'Social Media',
      ACCEPT: 'Alle akzeptieren',
      REJECT: 'Alle ablehnen',
      SET_UP: 'Konfigurieren',
      MANDATORY_DETAILS: 'Die notwendigen Cookies sind für den Betrieb der Website obligatorisch, um die grundlegenden Funktionen dieser Website zu ermöglichen. Die für die Benutzung der Website Bonduelle.de erforderlichen Cookies sind : \n' +
        '<ul>\n' +
        '<li>Sitzungs-Cookies: Das sind Cookies, die von PHP-basierten Anwendungen erzeugt werden. Hierbei handelt es sich um einen Allzweck-Bezeichner, der zur Verwaltung von Benutzersitzungsvariablen verwendet wird. Es handelt sich normalerweise um eine zufällig generierte Zahl, die Art und Weise, wie sie verwendet wird, kann standortspezifisch sein, aber ein gutes Beispiel ist die Beibehaltung eines Login-Status für einen Benutzer zwischen den Seiten.</li>\n' +
        '</ul>',
      ANALYTICS_DETAILS: 'Analyse Cookies helfen uns, die Website zu optimieren, z.B. um Statistiken zu sammeln, Ihre Präferenzen für soziale Interaktionen aufzuzeichnen oder die Leistung und Stabilität unserer Anwendungen zu messen.\n' +
        '<br>\n' +
        'Die von der Website Bonduelle.de verwendeten analytischen Cookies sind : \n' +
        '<ul>\n' +
        '<li>DoubleClick: Dieses Cookie wird von DoubleClick (Eigentum von Google) gesetzt, um ein Profil der Interessen des Website-Besuchers zu erstellen und relevante Werbung auf anderen Websites anzuzeigen. Das Hauptgeschäft des Unternehmens ist das Auspielen von Google-Werbeanzeigen.</li>\n' +
        '<li>Google Analytics: Diese Cookies ermöglichen es, die am meisten besuchten Seiten und den Verkehr der Website zu verfolgen.</li>\n' +
        '<li>UTMTracking: dieses Cookie ermöglicht die Verwaltung von UTM-Variablen für ein optimales Follow-up der verschiedenen Online-Werbekampagnen</li>.\n' +
        '</ul>',
      MARKETING_DETAILS: 'Marketing-Cookies ermöglichen es uns, Ihre Präferenzen für Rezepte und Produkte zu verfolgen, so dass wir Ihnen immer die Produkte und Informationen anbieten können, die Ihren Wünschen am besten entsprechen.\n' +
        '<br>\n' +
        'Die von der Website Bonduelle.de verwendeten Marketing-Cookies sind : \n' +
        '<ul>\n' +
        `<li>Soziale Netzwerke: Diese Cookies ermöglichen die Anzeige der Bonduelle 'SocialWall' auf der Startseite der Website. Diese "SocialWall" ermöglicht es Ihnen, auf einen Blick unsere neuesten Beiträge aus den wichtigsten sozialen Netzwerken zu sehen, die von unseren Social-Teams genutzt werden.</li>\n` +
        '</ul>',
      V2: 'ok'
    },
    MODAL: {
      TITLE: 'Cookie-Einstellungen',
      TEXT: 'Die Gruppe Bonduelle verpflichtet sich, die persönlichen Daten der Benutzer ihrer Websites und insbesondere der Website Bonduelle.de zu schützen. Damit Sie die von der Website angebotenen Dienstleistungen nutzen können, wie z.B. die Beratung über Produkte, die Entdeckung neuer Feinschmeckerrezepte, das Abonnement bestimmter Dienstleistungen wie z.B. unsere Newsletter und um die Nutzung und Personalisierung der Website entsprechend Ihrem Profil zu optimieren, verwendet die Website Cookies. Die Bonduelle-Gruppe verpflichtet sich, ein hohes Maß an Schutz in Bezug auf solche persönlichen Daten zu gewährleisten und bei der Verarbeitung persönlicher Daten im Zusammenhang mit der Nutzung ihrer Website alle geltenden Datenschutzbestimmungen einzuhalten.',
      CONFIG: 'Konfiguration',
      SAVE: 'Speichern'
    }
  },
  LINK: { JOB_OFFERS: '' },
  CLIC2BUY: { INFOS: '' }
};
