import {
  Directive, Input, ElementRef,
  Renderer2, OnInit, OnChanges,
  SimpleChanges, HostListener, HostBinding
} from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { SfLink } from '../models/link.interface';

const charsMapConvert = require('@app/core/charsMap');

@Directive({
    selector: '[sfLink]',
    standalone: false
})
export class SfLinkDirective implements OnInit, OnChanges {

  @Input() sfLink: SfLink;

  @HostBinding('attr.target') @Input() target: string;

  private aElement: HTMLElement;
  private get linkEl(): HTMLElement {
    if (!this.aElement) {
      this.aElement = this.renderer.createElement('a');
    }
    return this.aElement;
  }

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private router: Router
  ) { }

  ngOnInit() {
    const element: HTMLElement = this.el.nativeElement;
    const childNodes: NodeList = element.childNodes;

    // Transfer all child nodes from root element to newly created element
    while (childNodes.length > 0) {
      this.renderer.appendChild(this.linkEl, childNodes.item(0));
    }
    this.renderer.appendChild(element, this.linkEl);
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    for (const propName of Object.keys(simpleChanges)) {
      const newLink: SfLink = simpleChanges[propName].currentValue as SfLink;
      if (newLink && newLink.url) {
        newLink.url = newLink.url[0] && newLink.url[0]['value'] ? newLink.url[0]['value'] : newLink.url;
      }
      if (newLink && newLink.url) {
        newLink.url = decodeURI(newLink.url);
        newLink.url = charsMapConvert(newLink.url, {multiLevelUrl: true});

        // SEO: All links to lower case
        newLink.url = newLink.url.toLowerCase();

        this.renderer.setStyle(this.linkEl, 'cursor', 'pointer');
        if (newLink.is_external) {
          this.renderer.setAttribute(this.linkEl, 'href', this.checkUrl(newLink.url));
          this.renderer.setAttribute(this.aElement, 'target', '_blank');
          this.target = '_blank';
        } else {
          this.renderer.setAttribute(this.linkEl, 'href', newLink.url);
          this.renderer.setAttribute(this.aElement, 'target', '_self');
          this.target = '_self';
        }
      } else {
        this.renderer.removeAttribute(this.linkEl, 'href');
        this.renderer.removeAttribute(this.aElement, 'target');
        this.target = '_self';
      }
    }
  }

  /**
   * checks if url contains 'https://', if not, appends it => https://{url}
   * @memberof SfLinkDirective
   */
  private checkUrl(url: string) {
    if (url.includes('http://') || url.includes('https://') || url.startsWith('mailto:')) {
      return url;
    } else { return ('https://' + url); }
  }

  @HostListener('click', ['$event.button', '$event.ctrlKey', '$event.metaKey', '$event.shiftKey'])
  onclick(button: number, ctrlKey: boolean, metaKey: boolean, shiftKey: boolean): boolean {
    if (button !== 0 || ctrlKey || metaKey || shiftKey) {
      return true;
    }

    return this.navigate();
  }

  @HostListener('keydown', ['$event'])
  onkeydownHandler(event: KeyboardEvent): boolean {
    if ( event.keyCode !== 13 ) {
      return true;
    }

    return this.navigate();
  }

  private navigate(): boolean {
    if (typeof this.target === 'string' && this.target !== '_self') {
      return true;
    }

    if (!this.sfLink || !this.sfLink.url || typeof this.sfLink.url !== 'string') {
      return true;
    }
    const [url, queryParams, fragment] = this.extractParams(this.sfLink.url);
    const options: NavigationExtras = {};

    /** @fixme should not rely on the parameter (SfLink shouldn't even have a 'param' property) */
    if (this.sfLink.param) {
      options.queryParams = this.sfLink.param;
    }

    if (this.sfLink.extras) {
      options.state = this.sfLink.extras;
    }
    if (queryParams) {
      options.queryParams = {...options.queryParams, ...this.extractQueryParams(queryParams)};
    }
    if (fragment) {
      options.fragment = fragment;
    }

    /*console.log(
      `SfLink - navigate to ${url}
      ${options.queryParams ? 'queryParams : ' + JSON.stringify(options.queryParams) : ''}
      ${options.fragment ? 'fragment : ' + options.fragment : ''}`
    );*/

    this.router.navigate([url], options);
    return false;
  }

  /**
   * Decompose a url to its url, queryparams and fragment parts
   */
  private extractParams(url: string): [string, string, string] {
    if (!url) {
      return ['', null, null];
    }
    let queryParams: string;
    let fragment: string;
    const fragmentParts = url.split('#');
    const queryParts = fragmentParts[0].split('?');
    const newUrl = queryParts[0];
    if (fragmentParts[1]) {
      fragment = fragmentParts[1];
    }
    if (queryParts[1]) {
      queryParams = queryParts[1];
    }
    return [newUrl, queryParams, fragment];
  }

  /**
   * Decompose a queryParam string into a dictionnary of key/value
   */
  private extractQueryParams(queryString: string): Object {
    const r: Object = {};
    if (!queryString) {
      return r;
    }
    const queryParams: string[] = queryString.split('&');
    queryParams.forEach(param => {
      if (param) {
        const [key, value] = param.split('=');
        if (key !== '' && value !== '') {
          r[key] = value;
        }
      }
    });
    return r;
  }
}
