import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'levelsCost',
    standalone: false
})
export class LevelsCostPipe implements PipeTransform {
  constructor( public translate: TranslateService) {
  }
  transform(value: string): string {
    if (value === '1' ) {
      return this.translate.instant('RECIPE.BUDGET_LOW');
    } else if (value === '3') {
      return this.translate.instant('RECIPE.BUDGET_HIGH');
    } else {
      return this.translate.instant('RECIPE.BUDGET_MIDDLE');
    }
  }

}
