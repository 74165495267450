import { Component, Input, HostBinding } from '@angular/core';
import { SafeStyle } from '@angular/platform-browser';

/**
 * by default for the sf-lib, all the icons are main color with white inside.
 * To change the color, then apply a class with the proper color or put a color in color property
 * To change the stroke/fill inside, override the class sf-icon-fill and sf-icon-stroke
 */
@Component({
    selector: 'sf-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.sass'],
    standalone: false
})
export class SfIconComponent {

  @Input() icon: string;

  @Input() size: number;

  @Input() color: string;

  @HostBinding('attr.xlink:href') get href(): string {
    return `src/assets/icons/icon-set.svg#${this.icon}`;
  }

  @HostBinding('style') get style(): SafeStyle {
    let styleStr = '';
    if (this.color) {
      styleStr = styleStr + `color: ${this.color};`;
    } else {
      styleStr = styleStr + `color: currentColor;`;
    }
    if (this.size) {
      styleStr = styleStr + `height: ${this.size}px; width: ${this.size}px;`;
    }
    return styleStr;
  }

  constructor(
  ) { }

}
