import { SfModel } from '@app/sf-lib/public_api';
import { RequestPostSettings } from './request-post-settings.model';


export class RequestPostParams extends SfModel {

  /**
   * Settings of the request
   * @memberof RequestPostParams
   */
  public odSettings: RequestPostSettings;

  /**
   * Settings of the request
   * @memberof RequestPostParams
   */
  public odData: { [key: string]: string };

  constructor(data?: { odSettings: RequestPostSettings, odData: { [key: string]: string }}) {
    super();
    if (data) {
      this.odSettings = new RequestPostSettings(data.odSettings);
      this.odData = data.odData || {};
    } else {
      this.odSettings = new RequestPostSettings();
      this.odData = {};
    }
  }

  public static create(): RequestPostParams {
    const newEntity: RequestPostParams = new RequestPostParams();
    newEntity.odSettings = new RequestPostSettings();
    newEntity.odData = {};
    return newEntity;
  }
}
