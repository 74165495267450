<div class="jumbotron">
  <a [href]="section.url" [ngClass]="{'has-link': !!section.url}">
    <div *ngIf="section.image && section.image.uri" class="--horizontal">
      <img [src]="options.apiUrl + section.image.uri.original" alt="" loading="lazy"/>
    </div>
    <div *ngIf="section.smallimage && section.smallimage.uri" class="--vertical">
      <img [src]="options.apiUrl + section.smallimage.uri.original" alt="" loading="lazy"/>
    </div>
  </a>
</div>
