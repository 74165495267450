import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'nutriScore',
    standalone: false
})
export class NutriScorePipe implements PipeTransform {
    transform(value: string): string {
        if (value) {
            const result = 'nutri' + value[0].toUpperCase();
            return result;
        }
    }
}
