import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SfGalleryComponent } from './components/gallery.component';
import { SfImageModule } from '../image/image.module';

@NgModule({
  imports: [
    CommonModule,
    SfImageModule
  ],
  exports: [
    SfGalleryComponent
  ],
  declarations: [SfGalleryComponent]
})
export class SfGalleryModule {}
