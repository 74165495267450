import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

import { SITE_INFO, SfSiteInfo } from '@app/sf-lib/public_api';
import { environment } from '@env/environment';

export const eventID = {
  SOCIAL: 'social_share',
  ACCOUNT: 'account',
  CONTACT: {
    ITEM: 'contact_item',
  },
  GOOD_DEALS: 'bons_plans',
  NL_SUBSCRIPTION: 'nl_subsription',
  HOME_PAGE: {
    SLIDER: 'slider_hp',
    RECIPE: 'recipe_hp',
    VEGETABLES: 'vegetables_hp',
    PASSION: 'passion_hp',
    HEADLINES: 'headlines_hp'
  },
  OUR_PRODUCTS: {
    PRODUCT_CONTENT: 'list_product_content',
    PRODUCT_MORE: 'list_product_more',
    SORT: 'list_sort',
    FILTER: 'list_filter_',
    FIND: 'product_store',
    NEWS: 'product_news',
    ZOOM_PICTURES: 'product_zoom_pictures',
    DISCOVER_ALSO: 'product_cross_product',
    DISCOVER_GAMMES: 'view_recipe_video'
  },
  OUR_RECIPES: {
    RECIPE_CONTENT: 'list_product_content',
    RECIPE_MORE: 'list_recipe_more',
    SORT: 'list_sort',
    FILTER: 'list_filter_',
    PRINT: 'recipe_print',
    PRODUCT_RELATED: 'recipe_product_related',
    RECIPE_RELATED: 'recipe_related',
    OTHER_PRODUCT: 'recipe_other_product',
    SEARCH: 'search_recipe'
  },
  SEARCH: {
    ARTICLE: 'search_article',
    CATEGORY: 'search_category',
    RECIPE: 'search_recipe',
    PRODUCT: 'search_product',
    DISH: 'search_type_dish',
    TIME: 'search_time',
    LEVEL: 'search_level',
    PRICE: 'search_price',
    GUEST: 'search_guest_number',
    VEGETABLES: 'search_vegetables',
    SEASON: 'search_season'
  },
  FAQ: {
    CONTACT_US: 'faq_contact_us'
  }
};

@Injectable()
export class TagManagerService {
  /**
   * General code use for Google Tag Manager
   *
   * @memberof TagManagerService
   */
  GTMCode = environment.GtmCode;
  /**
   * List of events id
   *
   * @memberof TagManagerService
   */
  eventID = eventID;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(SITE_INFO) private siteInfo: SfSiteInfo,
    private translateService: TranslateService,
    private router: Router,
  ) {
  }

  /**
   * Generate script for Google Tag Manager
   *
   * @returns
   * @memberof TagManagerService
   */
  public getGTMScript() {
    const GTMscript = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${this.GTMCode}');`;
    return GTMscript;
  }

  public getGTMNoScript() {
    return `<iframe src="https://www.googletagmanager.com/ns.html?id=${this.GTMCode}"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>`
  }

  /**
   * Push user event's on Google Tag Manager
   *
   * @param {string} eventAttribut
   * @param {string} [name]
   * @memberof TagManagerService
   */
  public setEvent(eventAttribut: string, value?: string) {
    const event = {
      event: 'gtm.click',
      'attribut': eventAttribut,
      'name': value ? value : '',
      'type': 'click'
    };
    this.pushDataLayer(event);
  }

  /**
   * Push event of new tagManger
   *
   * @param {string} type
   * @param {string} name
   * @param {string} category
   */
  public setNewEvent(type?: string, name?: string, category?: string, myEvent?: string, social?: string) {
    let event;

    if(!social) {
      event = {
        event: !myEvent ? 'bondEventLab' : myEvent,
        'eventCat': type ? type : '',
        'eventAct': name ? name : '',
        'eventLab': category ? category : ''
      };
    } else {
      event = {
        event: 'socialInt',
        'socialNet': type ? type : 'RS',
        'socialAct': name ? name : '',
        'socialTarget': category ? category : ''
      };
    }

    if(!environment.production || (environment.production && event.eventCat && event.eventCat === 'GDPR banner')) {
      this.pushDataLayer(event);
    }
  }

  /**
   * Get environment object
   *
   * @memberof TagManagerService
   */
  public getEnvironment() {
    // Fetch site's base url to determine environment
      const env = {
        'env_work': environment.production ? 'PRD' : 'DEV',
        'env_name': 'Sombrero',
        'env_country': this.siteInfo.lang.country,
        'env_channel': '',
        'env_language': this.siteInfo.lang.code
      };
      return env;
  }

  /**
   * Set dom event
   *
   * @memberof TagManagerService
   */
  public setDom() {
    const event = {
      'event': 'myDom'
    };
    const env = this.getEnvironment();
    const dom = { ...event, ...env };
    this.pushDataLayer(dom);
  }

  /**
   * Set search prediggo event
   *
   * @param {*} total
   * @param {string} keywords
   * @memberof TagManagerService
   */
  public setSearchEvent(total, keywords: string) {
    const searchEvent = {
      'search_keywords': keywords,
      'search_results_number': total.product + total.recipe,
      'search_results_legumes': total.product,
      'search_results_recettes': total.recipe
    };
    this.pushDataLayer(searchEvent);
  }

  /**
   * Set content page
   *
   * @param {string} type
   * @param {string} id
   * @param {string} name
   * @param {*} options
   * @memberof TagManagerService
   */
  public setContentPage(type?: string, id?: any, name?: string, options?: object) {
    let routeArray = this.router.url.split('/');
    routeArray = routeArray.map(item => item.split('?')[0]);
    if (!routeArray) {
      return;
    }

    const isLegumiz = routeArray[1] === this.translateService.instant('ROUTES.magazine');
    const isProduct = routeArray[1] === this.translateService.instant('ROUTES.products');
    const isRecipe = routeArray[1] === this.translateService.instant('ROUTES.recipes');
    const isArticle = routeArray[1] === this.translateService.instant('ROUTES.since-1853');
    const isSearch = routeArray[1] === this.translateService.instant('ROUTES.search');

    const pageType =
      !routeArray[1] ? 'Homepage' :
        isProduct && !routeArray[3] ? 'ProductList' :
          isProduct && routeArray[3] ? 'Product' :
            isRecipe && !routeArray[3] ? 'RecipeList' :
              isRecipe && routeArray[3] ? 'Recipe' :
                isArticle && !routeArray[3] ? 'ArticleList' :
                  isArticle && routeArray[3] ? 'Article' :
                    isSearch ? 'Search' :
                      'Other';

    const menuCategory = isLegumiz && routeArray[2] ? routeArray[2] : routeArray[1] ? routeArray[1] : 'Home';

    const contentPage = {
      event: 'contentPage',
      content_type: type ? type : '',
      content_id: id ? id : null,
      content_name: name ? name : '',
      env: environment.production ? 'PRD' : 'DEV',
      country: this.siteInfo.lang.country.toUpperCase(),
      language: this.siteInfo.lang.code.toUpperCase(),
      site: isLegumiz ? 'Legumiz' : 'Bonduelle',
      pageType,
      menuCategory,
    };

    if (options && options['nb_results']) {
      contentPage['nb_results'] = options['nb_results'];
    }

    if (pageType === 'Product') {
      contentPage['productName'] = name;
      contentPage['brandContent'] = options ? options['brandContent'] : null;
    }

    if (pageType === 'Recipe') {
      contentPage['recipeName'] = name;
      contentPage['brandContent'] = options ? options['brandContent'] : null;
    }

    if (pageType === 'Article') {
      contentPage['articleCategory'] = options ? options['articleCategory'] : null;
      contentPage['articleName'] = name;
    }

    this.pushDataLayer(contentPage);
  }

  /**
   * Push datalayer on Google Tag Manager
   *
   * @param {*} data
   * @memberof TagManagerService
   */
  public pushDataLayer(data) {
    if (isPlatformBrowser(this.platformId)) {
      if ((<any>window).dataLayer) { (<any>window).dataLayer.push(data); }
    }
  }

}
