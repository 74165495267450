import { SomProductPage, SomProductProperty } from "@app/core/models/product-page.model";
import { PrediggoItem } from "../model/prediggo-item.model";
import { toUpperCaseFirstLetter } from "@app/shared/utils/utils";
import { SfSimpleMediaImage, SfSimpleMediaVideo } from "@app/sf-lib/public_api";
import { productLink } from "@assets/link/product-link";
import { replaceSpecialChars } from "@app/core/special-charts";

export class PrediggoProductAdapter {
    private item: PrediggoItem;
    private currentLang: string;
  
    
    constructor(item: PrediggoItem, currentLang: string) {
      this.item = item;
      this.currentLang = currentLang
    }
  
    public adapt(): SomProductPage[] {
      const prediggoItem = this.item;
      const productPages = [];
      const product = this._parseCommonAttributes();
  
      for (let i = 0; i < prediggoItem.groupingInfo.childrenSkus.length; i++) {
        const sku = prediggoItem.groupingInfo.childrenSkus[i];
        const childProduct: SomProductPage = this._parseChildAttributes(sku, product);
       
        productPages.push(childProduct);
      }
  
      return productPages;
    }

    private _extractAttributeValueFromPrediggo(attributeName: string): string {
      const attribute = this.item.attributeInfo.find(attr => attr.attributeName === attributeName);
  
      if (attribute) {
        return attribute.vals[0].value;
      }
    }

    private _extractProductOriginFromPrediggo(index: number, skuId: string): { vegetable: string, countries: string[]} | undefined {
      const vegetableAttr = this.item.attributeInfo.find(attr => attr.attributeName === `originVegetableComponent${index}`);
      const countriesAttr = this.item.attributeInfo.find(attr => attr.attributeName === `originRawComponent${index}`);

      if (vegetableAttr && countriesAttr) {
        const filteredByIdCountries = countriesAttr.vals.filter(v => v.properties.itemId.includes(skuId))
        const filteredByIdVegetables = vegetableAttr.vals.filter(v => v.properties.itemId.includes(skuId))

        const vegetableVal = filteredByIdVegetables[0] ? filteredByIdVegetables[0].value : undefined
        const countriesVal = filteredByIdCountries.map(value => value.label)

        if (countriesVal && vegetableVal) {
          const productOrigin = {
            vegetable: vegetableVal,
            countries: countriesVal
          }
          return productOrigin
        }
      }
    }
    
    
    private _extractContentBetweenParentheses(input: string): string {
      const match = input.match(/\((.*?)\)/);
      return match ? match[1] : '';
    }

    private _extractNutritionalValueFromPrediggo(attributeName: string, skuId: string): SomProductProperty {
        const attribute = this.item.attributeInfo.find(attr => attr.attributeName === attributeName);
        const portionAttribute = this._findAttributeValueBySku(`portion${toUpperCaseFirstLetter(attributeName)}`, skuId)
        
        if (attribute) {
          const unit = this._extractContentBetweenParentheses(attribute.attributeLabel);
          return { 
            label: attribute.attributeLabel, 
            baseValue: `${attribute.vals[0].value} ${unit}`,
            portionValue: portionAttribute ? `${portionAttribute} ${unit}`: undefined
          };
        }
    
        return undefined;
      }
    
      private _extractAllergensFromPrediggo() {
        const allergensList = [
          'Celery', 'Crustaceans', 'Egg', 'Fish', 'Gluten', 
          'Lupin', 'Milk', 'Molluscs', 'Mustard', 'Peanut', 
          'SesameSeeds', 'Soya', 'Sulphites', 'Treenuts'
        ]
        const allergens = {};
        const allergensTrace = {};
        
        for (let allergen of allergensList) {
          const attributeName = `allergen${allergen}`;
          const attribute = this.item.attributeInfo.find(attr => attr.attributeName === attributeName);
    
          if (attribute) {
            const allergenType = attribute.vals[0].value;
    
            switch (allergenType) {
              case 'T': 
                allergensTrace[attributeName] = attribute.attributeLabel;
                break;
              case 'P': 
                allergens[attributeName] = attribute.attributeLabel
              break;
            }
          }
        }
        return { allergens, allergensTrace };
      }
    
      private _extractClaimsFromPrediggo() {
        const claimList = [
          'claimEnergy', 
          'claimFatSaturate', 
          'claimFibre',
          'claimProteins',
          'claimSalt', 
          'claimNatrium',
          'claimVitProA', 
          'claimVitA', 
          'claimVitE', 
          'claimVitK1', 
          'claimVitC',
          'claimVitB2', 
          'claimVitB9', 
          'claimCalcium', 
          'claimPhosphorus', 
          'claimMagnesium',
          'claimIron', 
          'claimCopper', 
          'claimSelenium'
        ]
        return this._extractClaims(claimList);
      }
    
      private _extractProductClaimsFromPrediggo() {
        const claimList = [
          'claimNoPreserv', 
          'claimNoAddSugar',
          'claimNoAddSalt', 
          'claimNoPalmOil',
          'claimNoGmos', 
          'claimNoAdditiv', 
          'claimNoColour', 
          'claimNaturalSalt',
          'claimFat', 
          'claimSugar', 
        ]
        return this._extractClaims(claimList);
      }
    
      private _extractClaims(claimList: string[]): { [key: string]: string } {
        const claims = {};
      
        claimList.forEach(claim => {
          const attribute = this.item.attributeInfo.find(attr => attr.attributeName === claim);
          if (attribute) {
            claims[claim] = attribute.vals?.[0]?.label || '';
          }
        });
      
        return claims;
      }
    
      private _extractPictoUrlFromPrediggo(): string[] | undefined {
        const pictoUrl = this.item.attributeInfo.find(attr => attr.attributeName === 'pictoUrl');;
    
        if (pictoUrl) {
          return pictoUrl.vals.map(val => val.value)
        }
    
        return undefined
      }

      _parseCommonAttributes(): SomProductPage {
        const product = new SomProductPage();
        const { allergens, allergensTrace } = this._extractAllergensFromPrediggo();
        const prediggoItem = this.item;
    
        product.id = prediggoItem.sku;
        product.gtin = prediggoItem.sku;
        product.webLandingPageButtonText = this._extractAttributeValueFromPrediggo('webLandingPageButtonText');
        product.productIngredients = this._extractAttributeValueFromPrediggo('productIngredients');
        product.allergens = allergens;
        product.allergensTrace = allergensTrace;
        product.claims = this._extractClaimsFromPrediggo();
        product.claimsProduct = this._extractProductClaimsFromPrediggo();
        product.pictoUrl = this._extractPictoUrlFromPrediggo();
        product.productWarning = this._extractAttributeValueFromPrediggo('productWarning');
        product.productConservation = this._extractAttributeValueFromPrediggo('productConservation');
        product.nutriscore = this._extractAttributeValueFromPrediggo('nutriscore');
        // product.originInfoStatement = this._extractAttributeValueFromPrediggo('originInfoStatement');
        // for (let i = 1; i <= 5; i ++) {
        //   const origin = this._extractProductOriginFromPrediggo(i);
        //   if (origin) {
        //     product.originInfo.push(origin)
        //   }
        // }

        return product
      }

      private _extractH3Text(input: string = ''): { extracted: string | null; updatedString: string } {
        const regex = /<h3>(.*?)<\/h3>/;
        const match = input.match(regex);
        
        if (!match) {
            return { extracted: null, updatedString: input };
        }
        
        const extracted = match[1];
        const updatedString = input.replace(regex, '');
        
        return { extracted, updatedString };
    }

      private _parseChildAttributes(skuId: string, product: SomProductPage): SomProductPage {
        const childProduct = new SomProductPage(product);
        
        childProduct.id = skuId;
        childProduct.gtin = skuId;
        childProduct.gtinParent = this._findAttributeValueBySku('gtinParent', skuId);
        childProduct.name = this._findAttributeValueBySku('name', skuId) ;
        childProduct.imageUrl =  this._buildImage(this._findAttributeValueBySku('imageUrl', skuId), childProduct.productName);
        childProduct.netweight = parseInt(this._findAttributeValueBySku('netweight', skuId), 10);
        childProduct.dryweight = parseInt(this._findAttributeValueBySku('dryweight', skuId), 10);
        childProduct.portionWeight = parseInt(this._findAttributeValueBySku('portionWeight', skuId), 10);
        childProduct.portionNumber = parseInt(this._findAttributeValueBySku('portionNumber', skuId), 10);
        childProduct.productIngredients = this._findAttributeValueBySku('productIngredients', skuId);
        childProduct.productInstructions = this._findAttributeValueBySku('productInstructions', skuId);
        childProduct.productName =  this._findAttributeValueBySku('name', skuId) || this._findAttributeValueBySku('productName', skuId);
        childProduct.productMainPicture = this._buildImage(this._findAttributeValueBySku('imageUrl', skuId), childProduct.productName);
        childProduct.productZoomPicture = this._buildImage(this._findAttributeValueBySku('productZoomPicture', skuId), childProduct.productName);
        childProduct.productSeoDescription = this._findAttributeValueBySku('productSeoDescription', skuId);
        childProduct.productSeoTitle = this._findAttributeValueBySku('productSeoTitle', skuId);
        childProduct.webCatchphrase = this._findAttributeValueBySku('webCatchphrase', skuId);
        childProduct.webDescription = this._findAttributeValueBySku('webDescription', skuId);
        childProduct.webLandindPageUrl = this._findAttributeValueBySku('webLandindPageUrl', skuId);
        childProduct.webLandingPageButtonText = this._findAttributeValueBySku('webLandingPageButtonText', skuId);
        childProduct.webNewsPicture = this._buildImage(this._findAttributeValueBySku('webNewsPicture', skuId), childProduct.productName);
        childProduct.webNewsVideo = this._buildVideo(this._findAttributeValueBySku('webNewsVideo', skuId));

        //Origin Info
        const originInfoText = this._findAttributeValueBySku('originInfoStatement', skuId);
        const originInfoStatement = this._extractH3Text(originInfoText);
        childProduct.generalStatement = this._findAttributeValueBySku('generalStatement', skuId);
        childProduct.originInfoTitle = originInfoStatement.extracted 
        childProduct.originInfoStatement =  originInfoStatement.updatedString;
        childProduct.originInfo = [];
        for (let i = 1; i <= 5; i ++) {
          const origin = this._extractProductOriginFromPrediggo(i, skuId);
          if (origin) {
            childProduct.originInfo.push(origin)
          }
        }
        childProduct.url = this._buildUrl(skuId, childProduct.name)
        childProduct.urlParent = this._buildUrl(childProduct.gtinParent, childProduct.name)


        childProduct.nutritionalValues = {
            energyKj: this._extractNutritionalValueFromPrediggo("energyKj", skuId),
            energyKcal: this._extractNutritionalValueFromPrediggo("energyKcal", skuId),
            fatTotal: this._extractNutritionalValueFromPrediggo("fatTotal", skuId),
            fatSaturate: this._extractNutritionalValueFromPrediggo("fatSaturate", skuId),
            carbohydratesTotal: this._extractNutritionalValueFromPrediggo("carbohydratesTotal", skuId),
            carbohydratesSugar: this._extractNutritionalValueFromPrediggo("carbohydratesSugar", skuId),
            proteins: this._extractNutritionalValueFromPrediggo("proteins", skuId),
            fibre: this._extractNutritionalValueFromPrediggo("fibre", skuId),
            salt: this._extractNutritionalValueFromPrediggo("salt", skuId),
            calcium: this._extractNutritionalValueFromPrediggo("calcium", skuId),
            cholesterol: this._extractNutritionalValueFromPrediggo("cholesterol", skuId),
            copper: this._extractNutritionalValueFromPrediggo("copper", skuId),
            magnesium: this._extractNutritionalValueFromPrediggo("magnesium", skuId),
            manganese: this._extractNutritionalValueFromPrediggo("manganese", skuId),
            phosphorus: this._extractNutritionalValueFromPrediggo("phosphorus", skuId),
            potassium: this._extractNutritionalValueFromPrediggo("potassium", skuId),
            selenium: this._extractNutritionalValueFromPrediggo("selenium", skuId),
            zinc: this._extractNutritionalValueFromPrediggo("zinc", skuId),
            iron: this._extractNutritionalValueFromPrediggo("iron", skuId),
            vitE: this._extractNutritionalValueFromPrediggo("vitE", skuId),
            vitC: this._extractNutritionalValueFromPrediggo("vitC", skuId),
            vitB2: this._extractNutritionalValueFromPrediggo("vitB2", skuId),
            vitB9: this._extractNutritionalValueFromPrediggo("vitB9", skuId),
            vitA: this._extractNutritionalValueFromPrediggo("vitA", skuId),
            vitProA: this._extractNutritionalValueFromPrediggo("vitProA", skuId),
            vitK1: this._extractNutritionalValueFromPrediggo("vitK1", skuId),
        }

        return childProduct;
    }

    private _findAttributeValueBySku(attributeName: string, sku: string): string {
        const attribute = this.item.attributeInfo.find(attr => attr.attributeName === attributeName);

        if (attribute) {
            const value = attribute.vals.find(val => val.properties.itemId[0] === sku);
            return value ? value.value : attribute.vals[0].value
        }
        return undefined
    }

    private _buildImage(url: string, alt: string = '') {
        const img = new SfSimpleMediaImage();
        img.sfType = 'SfMediaImage';
        img.alt_image = alt;
        img.image.uri.original = url === 'N/A' ? '/assets/images/placeholder.png' : url;

        return img
    }

    private _buildVideo(src: string) {
        const video = new SfSimpleMediaVideo();
        video.sfType = 'SfMediaVideo';
        video.video = src;
        
        return video;
    }

    private _buildUrl(id: string, productName: string) {

      const tradLink = productLink.find(l => l.lang === this.currentLang);
      const lang = this.currentLang.split('_')[1];
      const alias = replaceSpecialChars(productName, lang).replaceAll(/ /g, '-');

      return `/${tradLink.link}/${alias}/${id}`.toLocaleLowerCase()
    }
    
  }
  