const replaceChars = function(chars) {
  return chars
    // Unicodes
    .replace(/\u200B/g, '') // Zero-width space
    .replace(/\u00AD/g, '') // Soft Hyphen
    .replace(/\u00A0/g, '-') // Non-breaking space
    // Special chars
    .replace(/ /g, '-')
    .replace(/---/g, '-')
    .replace(/--/g, '-')
    .replace(/[_'«»₣!"#()*,`´:;@^}{+|~‚„…†‡‹‘’“”•–—›¡%¦©ª¬®°µ¶º¿℅§¨―‣‼]/g, '')
    .replace(/&/g, '%26')
    .replace(/²/g, '2')
    .replace(/³/g, '3')
    // Money
    .replace(/[₵¢💲$₫֏₣ƒ₲₴₭£₺₦₧₱₩¥]/g, '')
    .replace(/₳/g, 'a')
    .replace(/[₡₢]/g, 'b')
    .replace(/[€₠]/g, 'e')
    .replace(/[₻₼]/g, 'k')
    .replace(/[₹₽]/g, 'm')
    .replace(/[៛₪]/g, 'h')
    .replace(/[₸₮]/g, 'o')
    // Alphabet
    .replace(/[àÂâáÀÃãäÁĄĀąĂăαάăъ]/g, 'a')
    .replace(/[ÅåÄ]/g, 'aa')
    .replace(/[Ææäá]/g, 'ae')
    .replace(/[βБбβ]/g, 'b')
    .replace(/[ÇçČčĆćγКкΓγ]/g, 'c')
    .replace(/[Чч]/g, 'ci')
    .replace(/[ĎďδДдΔδ]/g, 'd')
    .replace(/[Đđ]/g, 'dj')
    .replace(/[ÈèÉéÊêËëĚěęėĘĖēεέΗεέή]/g, 'e')
    .replace(/[φФфφ]/g, 'f')
    .replace(/[ģγГгγ]/g, 'g')
    .replace(/η/g, 'h')
    .replace(/[ÎîÏïÍíÌìįĮīĺιИиЙйιίϊΐî]/g, 'i')
    .replace(/[Яя]/g, 'ia')
    .replace(/[Юю]/g, 'iu')
    .replace(/[ξЖжξ]/g, 'j')
    .replace(/[ķĶκκΚ]/g, 'k')
    .replace(/χ/g, 'kh')
    .replace(/[ĽľļŁłĹλЛлΛλ]/g, 'l')
    .replace(/[μм]/g, 'm')
    .replace(/[ŇňņŃńννн]/g, 'n')
    .replace(/[ÔôÖöÓóŐőÒòõÕοόοόΩω]/g, 'o')
    .replace(/[ŒœØø]/g, 'oe')
    .replace(/[πПпΠπ]/g, 'p')
    .replace(/[Φφ]/g, 'ph')
    .replace(/[Ψψ]/g, 'ps')
    .replace(/[ŘřŔŕρρ]/g, 'r')
    .replace(/[ŠšŚśσȘșШшΣσς]/g, 's')
    .replace(/ß/g, 'ss')
    .replace(/[Щщ]/g, 'st')
    .replace(/[ŤťτȚțЦцΤτțт]/g, 't')
    .replace(/[Θθ]/g, 'th')
    .replace(/[ÙùÛûÜüÚúŰűŮůųūŪŲθυύϋϋ]/g, 'u')
    .replace(/[Üü]/g, 'ue')
    .replace(/[ωВвωώ]/g, 'v')
    .replace(/[χΞξχ]/g, 'x')
    .replace(/[Ýýυυ]/g, 'y')
    .replace(/[ŽžŹźŻżζЗзζ]/g, 'z')
    // Lower case
    .toLowerCase();
};

const charsMapConvert = function (chars, options) {

  const urlPattern = /^(https?:\/\/)(.*)$/;
  const match = chars.match(urlPattern);

  //Filter charts after procotol. Example :  https://(www.bonduelle.fr/legumes/p/puree-delice-carottes-potiron-creme-si-douce/03083681066852)
  if (match) {
    const protocol = match[1];
    let path = match[2];
  
    // Replace '/' to flatten URL
    if (!options || !options.multiLevelUrl) {
      path = path.replace(/\//g, '-');
    }

    path = replaceChars(path)
    return protocol + path;
  } else {
    if (!options || !options.multiLevelUrl) {
      chars = chars.replace(/\//g, '-');
    }

    return replaceChars(chars);
  }
}
module.exports = charsMapConvert;
