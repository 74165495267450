import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SfExpansionPanelComponent } from './components/expansion-panel.component';
import { AngularMaterialModule } from '@app/shared/angular-material/angular-material.module';

@NgModule({
  imports: [
    CommonModule,
    AngularMaterialModule,
  ],
  declarations: [SfExpansionPanelComponent],
  exports: [SfExpansionPanelComponent]
})
export class SfExpansionPanelModule { }
