<div class="sf-item-text sf-main-border-bottom sf-main-color" *ngIf="item" [sfLink]="link" (mouseenter)="hover = true" (mouseleave)="hover = false">

  <div class="sf-item-text-container" (click)="eventService.setEvent(item.title, item.tag?.name)">
    <div class="sf-item-text-image">
      <sf-image [sfRollover]="hover" type="ratio_245_196" class="sf-global-display-deckstop sf-item-text-image-background" [image]="item.cover"></sf-image>
      <sf-image *ngIf="item.smallcover" [sfRollover]="hover" type="ratio_245_196" class="sf-global-display-mobile sf-item-text-image-background" [image]="item.smallcover"></sf-image>
      <sf-image *ngIf="!item.smallcover" [sfRollover]="hover" type="ratio_245_196" class="sf-global-display-mobile sf-item-text-image-background" [image]="item.cover"></sf-image>
    </div>
    <div class="sf-item-text-content">

      <!-- Tag -->
      <ng-container *ngIf="item.tag?.name" [ngSwitch]="seoTag">
        <h2 *ngSwitchCase="'H1'" class="sf-item-text-content-tag sf-title">{{ (item.tag.name[0] && item.tag.name[0]['value'] ? item.tag.name[0]['value'] : item.tag?.name) | uppercase }}</h2>
        <h2 *ngSwitchCase="'H2'" class="sf-item-text-content-tag sf-title">{{ (item.tag.name[0] && item.tag.name[0]['value'] ? item.tag.name[0]['value'] : item.tag?.name) | uppercase }}</h2>
        <h3 *ngSwitchCase="'H3'" class="sf-item-text-content-tag sf-title">{{ (item.tag.name[0] && item.tag.name[0]['value'] ? item.tag.name[0]['value'] : item.tag?.name) | uppercase }}</h3>
        <div *ngSwitchDefault class="sf-item-text-content-tag sf-title">{{ (item.tag.name[0] && item.tag.name[0]['value'] ? item.tag.name[0]['value'] : item.tag?.name) | uppercase }}</div>
      </ng-container>

      <!-- Title -->
      <ng-container *ngIf="item.title" [ngSwitch]="seoTitle">
        <h1 *ngSwitchCase="'H1'" class="sf-item-text-content-title sf-title">{{ (item.title[0] && item.title[0]['value'] ? item.title[0]['value'] : item.title) | truncateString : 50 }}</h1>
        <h2 *ngSwitchCase="'H2'" class="sf-item-text-content-title sf-title">{{ (item.title[0] && item.title[0]['value'] ? item.title[0]['value'] : item.title) | truncateString : 50 }}</h2>
        <h3 *ngSwitchCase="'H3'" class="sf-item-text-content-title sf-title">{{ (item.title[0] && item.title[0]['value'] ? item.title[0]['value'] : item.title) | truncateString : 50}}</h3>
        <div *ngSwitchDefault class="sf-item-text-content-title sf-title">{{ (item.title[0] && item.title[0]['value'] ? item.title[0]['value'] : item.title) | truncateString : 50}}</div>
      </ng-container>
    </div>
  </div>
</div>
