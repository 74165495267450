/* tslint:disable */export const gr_el = {
  '404': {
    TITLE: 'Ουπς...',
    PAGENOTFOUND: 'Η σελίδα δεν βρέθηκε',
    BUTTON: 'Πίσω στην αρχική',
    RECIPES: 'Ανακαλύψτε τις συνταγές μας'
  },
  HOME: {
    SHOWCASE: 'Συνταγές μαγειρικής',
    CATCH_PHRASE: '',
    SHOWCASE_SEE_ALL: 'Δείτε όλες τις συνταγές',
    MAGAZINE: { TITLE: 'ΚΟΡΥΦΗ', BUTTON: 'Ανακαλύψτε τα όσπρια' },
    OUR_VEGETABLES: 'Τα λαχανικά μας',
    SOCIAL_WALL: 'Ας μοιραστούμε μαζί το πάθος μας για τα φυτά',
    FAQ_TITLE: 'Οι ερωτήσεις σας',
    FAQ_SEE_MORE: 'Δείτε περισσότερα',
    FLOWBOX: 'Μοιραστείτε τις γκουρμέ στιγμές σας με το #mybonduelle'
  },
  SELECT: { DEFAULT_VALUE: 'Η Bonduelle παρουσιάζει' },
  MENU: {
    CONTACT_US: 'Επικοινωνείστε μαζί μας',
    MY_ACCOUNT: 'Ο λογαριασμός μου',
    GOOD_DEALS: 'Προσφορές',
    NEWSLETTER: 'Τα νέα μας'
  },
  FOOTER: {
    LINK: {
      BONDUELLE: 'Bonduelle.com',
      CREDITS: 'Credits',
      LEGAL_NOTICE: 'Νομική ειδοποίηση',
      TERMS_OF_SALES: 'όροι πώλησης',
      COOKIES: 'Cookies',
      SITEMAP: 'Χάρτης ιστότοπου',
      PRIVACY_POLICY: 'Πολιτική απορρήτου',
      ALL_RIGHTS_RESERVED: '2018 ©Όλα τα δικαιώματα διατηρούνται'
    },
    HISTORY: {
      TITLE: 'Bonduelle, από το 1853',
      TEXT: 'από το 1853\n' +
        'Bonduelle είναι μια οικογενειακή εταιρεία που εργάζεται εδώ και 7 γενιές για την ανάπτυξη της αγροτικής παραγωγής που σέβεται τη γη και τους ανθρώπους. Υποστηρίζουμε μια αποτελεσματική, έξυπνη και ενοποιημένη αγροοικολογία, αποφασιστικά στραμμένη προς το μέλλον και καινοτομώντας καθημερινά από χωράφι σε πιάτο για να δημιουργήσουμε ένα καλύτερο μέλλον μέσω της φυτικής διατροφής.'
    },
    NEWSLETTER: {
      TITLE: 'Η κοινότητα της Bonduelle',
      TEXT: 'Δέχομαι να λαμβάνω νέα από το newsletter της Bonduelle "όλα για τα λαχανικά από την Bonduelle" (νέα, προωθητικές ενέργειες, διαγωνισμούς, άρθρα...)',
      PLACEHOLDER: 'Το email σας',
      GDPR_LINK: 'Περισσότερα για να διαχειρίζεστε τα δεδομένα σας'
    }
  },
  PRODUCTS: 'Προϊόντα',
  MAGAZINE: {
    HOME: 'Καλωσήρθατε στο περιοδικό',
    CATCH_PHRASE: '',
    MENU: { BONDUELLE_LINK: 'πηγαίνετε στο bonduelle.gr' },
    SHOWCASE: 'Παρουσίαση',
    PREVIOUS_ARTICLES: 'Προηγούμενα άρθρα',
    PREVIOUS_ARTICLES_SEE_ALL: 'Δείτε όλα μας τα άρθρα',
    PREVIOUS_EDITIONS: 'Οι προηγούμενες εκδόσεις μας',
    PREVIOUS_EDITIONS_SEE_ALL: 'Δείτε περισσότερες εκδόσεις',
    PREVIOUS_ARTICLES_BY_CATEGORY: 'Προηγούμενα άρθρα ανά κατηγορία',
    RUBRIC: {
      SEE_ALL: 'Δείτε όλες τις συνταγές',
      SEE_ALL_ARTICLES: 'Δείτε όλα τα άρθρα',
      ON: 'άρθρα για'
    },
    RUBRIC_COOK: { ON: 'συνταγές για' },
    ARTICLE: { JOIN_US: 'Ελάτε μαζί μας', READ_ALSO: 'Επίσης για να διαβάσετε' },
    RECIPE: {
      INGREDIENTS: 'Συστατικά',
      INGREDIENTS_LABEL_DESC: 'για {{value}} άτομα',
      INGREDIENTS_LABEL_ONE_DESC: 'για {{value}} άτομο',
      INGREDIENTS_LABEL: 'Συστατικά για {{value}} άτομα',
      INGREDIENTS_LABEL_ONE: 'Συστατικά για {{value}} άτομο',
      PEOPLES: '{{value}} άτομα',
      PEOPLE: '{{value}} άτομο',
      DIFFICULTY: { EASY: 'εύκολο', MEDIUM: 'μέτριο', HARD: 'δύσκολο' },
      COST: { CHEAP: 'φτηνό', EXPENSIVE: 'ακριβό' },
      TIMING: { MIN: 'λεπτά', HOURS: 'ώρες' },
      STEP: 'βήμα',
      DISCOVER_ALSO: 'Ανακαλύψτε επίσης'
    }
  },
  ARTICLE: { DISCOVER_ALSO: 'Ανακαλύψτε επίσης', INGREDIENTS: 'Συστατικά' },
  SF: {
    SLIDE: { DISCOVER: 'Ανακαλύψτε' },
    SEARCH: { PLACEHOLDER: 'Ψάξτε', ALL: 'όλα' },
    NEWSLETTER: {
      BTN_LABEL: 'Εγγράφομαι',
      PLACEHOLDER: 'Το email σας',
      ERROR_MESSAGE: 'Μη έγκυρο email',
      ALREADY_SUBSCRIBED: 'Είστε ήδη εγγεγραμμένος',
      GDPR: {
        TXT: 'Περισσότερες πληροφορίες για να διαχειρίζεστε τα δεδομένα σας',
        LINK: '/cookies#gdpr'
      }
    },
    MONTHLY_FOLDER: { TITLE: 'Τα νέα του μήνα' },
    MINI_NEWSLETTER: {
      PLACEHOLDER: 'Το email σας',
      GDPR: {
        TXT: 'Περισσότερες πληροφορίες για να διαχειρίζεστε τα δεδομένα σας',
        LINK: '/cookies#gdpr'
      }
    },
    SOCIALITEM: { SHARE: 'μοιραστείτε', COMMENT: 'σχόλιο', BONDUELLE: 'Bonduelle' },
    SEARCHRECIPE: { DISCOVER_ALL: 'ψάξτε σε όλες τις συνταγές' },
    FORM: {
      ERROR: 'μη έγκυρο πεδίο',
      PHOTO: {
        ADD: 'προσθέστε εικόνα',
        DELETE: 'διαγράψτε την εικόνα',
        SEE: 'κοιτάξτε'
      }
    }
  },
  CORPORATE: {
    COMPANY_STORY: 'Η ΙΣΤΟΡΙΑ ΜΑΣ',
    CSR_COMMITMENT: 'Η ΔΕΣΜΕΥΣΗ ΜΑΣ',
    VEGETABLE_INNOVATIONS: 'ΝΕΑ ΚΑΙ ΔΡΑΣΕΙΣ',
    NEWS: 'όλα τα νέα',
    SEE_MORE: 'Δείτε περισσύτερα άρθρα',
    ON: 'άρθρα για'
  },
  NEWSLETTER: {
    TITLE: 'Εγγραφείτε στο newsletter μας',
    SUBTITLE: 'Προσφορές, μαγειρικές εμπνεύσεις και λίγη κουλτούρα!'
  },
  ROUTES: {
    products: 'proionta',
    news: 'nea',
    'since-1853': 'apo-1853',
    'field-plate': 'pedio',
    'company-story': 'e-istoria-tes-bonduelle',
    'csr-commitments': 'desmefseis-csr',
    'vegetal-innovations': 'fytikes-kainotomies',
    magazine: 'periodiko',
    all: 'ola',
    article: 'arthro',
    cook: 'mageirepse',
    cultivate: 'kaliergise',
    explore: 'exerevnise',
    recipe: 'syntage/',
    recipes: 'syntages',
    'recipes-home': 'syntages-arhiki',
    r: 'r',
    credits: 'syntelestes',
    'legal-notice': 'nomiki-anakoinosi',
    'terms-of-sales': 'oroi-polisis',
    cookies: 'cookies-policy',
    sitemap: 'hartis-istoselidas',
    'privacy-policy': 'politiki-idiotikotitas-cookies',
    'all-rights-reserved': 'epifylaxi-pantos-dikaiomatos',
    search: 'apotelesmata-anazetese',
    'articles-search': 'arthro',
    vegetables: 'proionta',
    p: 'p',
    contact: 'epikoinonia',
    'info-product': 'plirofories-proiontos',
    'note-product': 'paratiriseis-proiontos',
    suggestion: 'protasi',
    'other-request': 'alo-aitima',
    faq: 'syhnes-erotiseis',
    'all-themes': 'ola-ta-themata',
    'products-search': 'anazitisi-proiontos',
    recipesearch: 'anazitisi-syntagis',
    'product-news': 'nea-proionta',
    'good-deals': 'kales-efkairies',
    'not-found': '404',
    'menu-page': 'selida-menu',
    'menu-one': 'zese-ygieina',
    'menu-two': 'mageirepse-nostima',
    'menu-three': 'morpho',
    'menu-four': 'gine-demioyrgike',
    'menu-external': 'menu-exoteriko',
    'theme-pasta-rice': 'thema-zymarika-ryzi',
    'theme-bowls': 'thema-mpol',
    'theme-soup': 'thema-soupa',
    'theme-snack': 'thema-snack',
    'theme-sauce': 'thema-saltsa',
    'theme-unique-plate': 'thema-monadiko-piato',
    'theme-over': 'thema-over',
    'theme-legumin': 'thema-ospria',
    'theme-ingredients-recipes': 'thema-ylika-syntages',
    'theme-cook-plate': 'thema-mageirepse-piato'
  },
  BREAD: {
    products: 'proionta',
    news: 'nea',
    'since-1853': 'apo-1853',
    'field-plate': 'pedio',
    'company-story': 'e-istoria-tes-bonduelle',
    'csr-commitments': 'desmefseis-csr',
    'vegetal-innovations': 'fytikes-kainotomies',
    magazine: 'periodiko',
    all: 'ola',
    article: 'arthro',
    cook: 'mageirepse',
    cultivate: 'kaliergise',
    explore: 'exerevnise',
    recipe: 'syntage/',
    recipes: 'syntages',
    'recipes-home': 'syntages-arhiki',
    'articles-search': 'arthro',
    search: 'apotelesmata-anazetese',
    vegetables: 'proionta',
    contact: 'epikoinonia',
    'info-product': 'plirofories-proiontos',
    'note-product': 'paratiriseis-proiontos',
    suggestion: 'protasi',
    'other-request': 'alo-aitima',
    faq: 'syhnes-erotiseis',
    'all-themes': 'ola-ta-themata',
    'products-search': 'anazitisi-proiontos',
    recipesearch: 'anazitisi-syntagis',
    'product-news': 'nea-proionta',
    'good-deals': 'kales-efkairies',
    'not-found': '404',
    'menu-page': 'selida-menu',
    'menu-one': 'zese-ygieina',
    'menu-two': 'mageirepse-nostima',
    'menu-three': 'morpho',
    'menu-four': 'menu-tessera\n'
  },
  MENU_PAGE: {
    TITLE1: 'Ζήσε Υγιεινά',
    TITLE2: 'Μαγείρεψε νόστιμα',
    TITLE3: 'Όμορφο',
    TITLE4: 'Γίνε Δημιουργική',
    EXTERNAL: ''
  },
  TECHNICAL: { MENU_PAGE: 'Θέσεις εργασίας' },
  SEO: {
    TITLE: {
      MENU_ONE: 'Μενού 1 Τίτλος',
      MENU_TWO: 'Μενού 2 Τίτλος',
      MENU_THREE: 'Μενού 3 Τίτλος',
      MENU_FOUR: 'Μενού 4 Τίτλος',
      HOME: 'Bonduelle',
      SEARCH: {
        MAIN: '{{value}}',
        RECIPES: '{{value}}',
        VEGETABLES: '{{value}}',
        ARTICLES: '{{value}} Διαχωρισμός ανά άρθρο | Bonduelle'
      },
      CONTACT: 'Bonduelle',
      RECIPES: 'Bonduelle',
      RECIPE_HOME: 'Bonduelle',
      RECIPE: 'Bonduelle',
      PRODUCTS: 'Bonduelle',
      NEWS: 'Bonduelle',
      CORPORATE: {
        NEWS: 'Bonduelle',
        COMPANY_STORY: 'Bonduelle',
        CSR_COMMITMENTS: 'Bonduelle',
        VEGETAL_INNOVATIONS: 'Bonduelle',
        FIELD_PLATE: 'Bonduelle',
        ARTICLE: '{{value}}',
        VEGETABLE: '{{value}}'
      },
      PRODUCT: '{{value}}',
      MAGAZINE: {
        NEWS: 'Bonduelle',
        ARTICLE: 'Bonduelle',
        COOK: 'Bonduelle',
        GROW: 'Bonduelle',
        EXPLORE: 'Bonduelle',
        RECIPE: 'Bonduelle'
      },
      FAQ: 'Bonduelle',
      NOTFOUND: 'Bonduelle',
      RECIPES_ALL: '',
      RECIPETYPE: '{{value}}',
      RECIPESEASON: '{{value}}',
      RECIPEPREPARATION: '{{value}}',
      RECIPEDIFFICULTY: '{{value}}',
      RECIPEVEGETABLE: '{{value}}',
      RECIPECOST: '{{value}}',
      RECIPEDISH: '{{value}}',
      RECIPEDESC: '{{value}}',
      '2RECIPEFACETS': '',
      '4RECIPEFACETS': '{{value}}',
      PRODUCTSEASON: '{{value}}',
      PRODUCTTECHNO: '{{value}}',
      PRODUCTVEGETABLE: '{{value}}',
      PRODUCTRANGE: 'Products from range {{value}}',
      THEME_ALL: '',
      THEME_STARTER: '',
      THEME_SALAD: '',
      THEME_CAN: '',
      THEME_FROZEN: '',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: '',
      THEME_RECIPE: '',
      THEME_PLATE: '',
      THEME_ACC: '',
      THEME_SNACK: '',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': '{{value}}'
    },
    DESCRIPTION: {
      MENU_ONE: 'Μενού 1 περιγραφή',
      MENU_TWO: 'Μενού 2 περιγραφή',
      MENU_THREE: 'Μενού 3 περιγραφή',
      MENU_FOUR: 'Μενού 4 περιγραφή',
      HOME: '',
      SEARCH: {
        MAIN: '{{value}}',
        RECIPES: '{{value}}',
        VEGETABLES: '{{value}}',
        ARTICLES: 'Όλα τα {{value}} διαχωρισμένα ανά άρθρο. Απλές και γευστικές συνταγές. Μαγειρεμένα λαχανικά.'
      },
      CONTACT: '',
      RECIPES: '',
      RECIPE_HOME: '',
      RECIPE: '',
      PRODUCTS: '',
      NEWS: '',
      CORPORATE: {
        NEWS: '',
        COMPANY_STORY: '',
        CSR_COMMITMENTS: '',
        VEGETAL_INNOVATIONS: '',
        VEGETABLE: '',
        FIELD_PLATE: '',
        ARTICLE: ''
      },
      PRODUCT: '{{value}}',
      MAGAZINE: {
        NEWS: '',
        ARTICLE: '',
        COOK: '',
        GROW: '',
        EXPLORE: '',
        RECIPE: ''
      },
      FAQ: '',
      PAGE: 'σελίδα ',
      RECIPETYPE: '{{value}}',
      RECIPESEASON: '{{value}}',
      RECIPEPREPARATION: '{{value}}',
      RECIPEDIFFICULTY: '{{value}}',
      RECIPEVEGETABLE: '',
      RECIPECOST: '{{value}}',
      RECIPEDISH: '{{value}}',
      RECIPEDESC: '{{value}}',
      '2RECIPEFACETS': '{{value}}',
      '4RECIPEFACETS': '{{value}}',
      PRODUCTSEASON: '{{value}}',
      PRODUCTTECHNO: '{{value}}',
      PRODUCTVEGETABLE: '{{value}}',
      PRODUCTRANGE: 'Discover all products from range {{value}}',
      THEME_ALL: '',
      THEME_STARTER: '',
      THEME_SALAD: '',
      THEME_CAN: '',
      THEME_FROZEN: '',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: '',
      THEME_RECIPE: '',
      THEME_PLATE: '',
      THEME_ACC: '',
      THEME_SNACK: '',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': '{{value}}',
      NOTFOUND: ''
    },
    PIPE: { SOCIETY: ' | Bonduelle' },
    THEME_ALL: '',
    THEME_STARTER: '',
    THEME_SALAD: '',
    THEME_CAN: '',
    THEME_FROZEN: '',
    THEME_READY_MEALS: '',
    THEME_BIO: '',
    THEME_VEGETABLES: '',
    THEME_RECIPE: '',
    THEME_PLATE: '',
    THEME_ACC: '',
    THEME_SNACK: ''
  },
  VEGETABLE: {
    DISCOVER_VEGETABLE: 'Ανακαλύψτε επίσης τα φύλλα λαχανικών μας',
    OURS_RECIPES: 'Οι συνταγές μας',
    DISCOVER_ALSO: 'Ανακαλύψτε επίσης',
    SEE_ALL: 'Δείτε όλα τα λαχανικά',
    ON: 'Λαχανικά για'
  },
  RECIPE: {
    'INGREDIENTS_LABEL ': 'Συστατικά για {{value}} ανθρώπους',
    PEOPLES: '{{value}} ανθρώπους',
    DIFFICULTY: { EASY: 'Εύκολο', MEDIUM: 'Μέτριο', HARD: 'Δύσκολο' },
    COST: { CHEAP: 'Φτηνό', EXPENSIVE: 'Ακριβό' },
    TIMING: { MIN: 'Λεπτά', HOURS: 'ώρες' },
    STEP: 'Βήμα',
    DISCOVER_ALSO: 'Ανακαλύψτε επίσης',
    HOME_SEE_MORE: 'Δείτε περισσότερες συνταγές',
    SHOWCASE: 'Κορυφή',
    HOME_BOOK: 'Οι συνταγές μας',
    MIN_MORE: 'm και +',
    HOUR_MORE: 'h και +',
    FILTER_BY_CRITERIA: 'Φιλτράρετε με κριτήρια',
    SORT_BY: 'Ταξινόμηση κατά :',
    BEST_RATED: 'Καλύτερα βαθμολογημένα',
    MOST_CONSULTED: 'Πιο συμβουλευτικά',
    MEMBERS_RECIPES: 'Συνταγές μελών',
    CRITERIA: 'Νέα',
    DISCOVER: { RECIPES: 'Ανακαλύψτε τις συνταγές μας' },
    DISCOVER_RECIPE: 'Ανακαλύψτε την συνταγή',
    INSTRUCTIONS: 'Οδηγίες',
    DEFAULT_TAG: 'Ιδέα για συνταγή',
    MOST_RECENT: 'Πιο πρόσφατα',
    RECIPE_VIDEOS: 'Βίντεο συνταγών',
    PRODUCT_ASSOCIATE: 'Συνταγή που φτιάχτηκε με',
    BUDGET_LOW: 'Φτηνό',
    BUDGET_HIGH: 'Υψηλό',
    BUDGET_MIDDLE: 'Μέτριο',
    PRINT_RECIPE: 'εκτυπώστε την συνταγή',
    LET_YOURSELF_SURPRISED: 'αφήστε τον εαυτό σας να μείνει έκπληκτος',
    COOK_TIP_TITLE: 'Συμουλή μαγειρικής τίτλος',
    ENJOY_MEAL: 'Απολαύστε το γεύμα σας!',
    FILTER: { LEGEND: 'Θρύλος' }
  },
  PREDIGGO: { DIFFICULTY: { EASY: 'Εύκολο', MEDIUM: 'μέτριο', HARD: 'Δύσκολο' } },
  COOKIES: {
    TEXT: 'Συνεχίζοντας την επίσκεψή σας στον ιστότοπο, αποδέχεστε την χρήση cookies για τεχνικούς λόγους, για την χρήση στατιστικών αναλύσεων και διαχείρισης κίνησης δικτύου, οι πληροφορίες μοιρ΄ζονται με άλλους διαφημιζόμενους σε άλλους ιστότοπους έτσι ώστε να σας παρέχουν προωθητικές ενέργειες που ανταποκρίνονται στα ενδιαφέροντά σας.',
    CLOSE: 'Κλείσιμο',
    MORE: 'Μάθετε περισσότερα',
    AGREE_YOUTUBE: 'Πρέπει να αποδεχτείτε cookies από το Youtube για να δείτε αυτό το βίντεο',
    BUTTON_YOUTUBE: 'Αποδέχομαι τα cookies'
  },
  POPUP: { LAUNCH: 'POPUP-OUVERTURE-SITE' },
  FIELD_PLATE: { DISCOVER_VEGETABLE: 'Ανακαλύψτε επίσης τα φύλλα λαχανικών μας' },
  SEARCH: {
    OUR_RECIPES: 'Συνταγές',
    OUR_PRODUCTS: 'Προϊόντα',
    OUR_ARTICLES: 'Τα άρθρα μας',
    RECIPES: 'Συνταγές',
    PRODUCTS: 'Προϊόντα',
    ARTICLES: 'Άρθρα',
    ALL: 'Όλα',
    SORT_BY: 'Ταξινόμηση κατά',
    RESULTS: 'αποτελέσματα',
    RESULT: 'αποτέλεσμα',
    PRODUCT_OUT: 'προϊόντα από',
    APPLY: 'Εφαρμόζω',
    FILTER: 'Φίλτρο',
    RECIPE_OUT: 'Συνταγές από',
    NO_RESULT: "Λευκό λάχανο γι' αυτή την εύρεση...Μας έχετε θέσει δύσκολο ερώτημα",
    SEE_MORE: 'Δείτε περισσότερα',
    DISCOVER_RECIPES: 'Ανακαλύψτε τις συνταγές μας',
    SEE_MORE_OF: 'δείτε περισσότερα από',
    DELETE: 'διαγραφή'
  },
  RECIPE_LIST: {
    OUR_RECIPES: 'Συνταγές',
    RESULTS: 'αποτελέσματα',
    SEE_MORE: 'Δείτε περισσότερες συνταγές'
  },
  PRODUCT: {
    BUTTON: {
      ACTU: 'νέα προϊόντων',
      FOUND: 'που να βρείτε αυτό το προϊόν',
      DISCOVER: 'ανακαλύψτε όλη την ποικιλία'
    },
    NUTRITIONAL_VALUE: 'διατροφικές αξίες',
    CONDITIONING: 'μάθηση',
    PORTIONS: 'μερίδες',
    NET_WEIGHT: 'καθαρό βάρος',
    DRY_WEIGHT: 'στραγγισμένο βάρος',
    INGREDIENTS: 'συστατικά',
    OUR_RECIPES: 'Ανακαλύψτε τις συνταγές μας',
    FOR: 'για',
    PER_PORTION: 'και σε μερίδα από',
    ENERGY: 'ενέργεια',
    FAT: 'λιπαρά',
    SATURATE: 'κορεσμένα λιπαρά',
    GLUCIDES: 'υδατάνθρακες',
    FIBER: 'φυτικές ίνες',
    PROTEINS: 'πρωτεΐνες',
    SALT: 'αλάτι',
    DISCOVER_ALSO: 'ανακαλύψτε επίσης',
    MORE_INFORMATION: 'περισσότερες πληροφορίες',
    TRACES: 'Ίχνη από',
    CONTAINS: 'Περιέχει',
    SLIDER: { SHEET: 'δείτε το χαρτί', BUY: 'αγοράστε το προϊόν' },
    NUTRISCORE: 'πληροφορίες - nutriscore',
    TRIMAN: 'πληροφορίες - triman',
    AGRICONFIANCE: 'πληροφορίες - agriconfiance',
    BIO: 'πληροφορίες - bio',
    PESTICIDE_FREE: '',
    LEAF: '',
    LOCK: '',
    SEE_ALL: 'Δείτε όλα τα προϊόντα',
    LEGISLATION: 'σύμφωνα με την κείμενη νομοθεσία.',
    PORTION: { SINGULAR: 'μερίδα', PLURAL: 'μερίδες' },
    DISCLAIMER: '',
    COOKING: 'μαγειρεύω',
    CONSERVATION: 'συντήρηση'
  },
  FAQ: {
    ON: 'ερωτήσεις σε',
    ALL: 'Όλα',
    SEE_MORE: 'δείτε περισσότερες ερωτήσεις',
    TITLE_1: 'Όλες οι απαντήσεις στα ερωτήματά σας',
    TITLE_2: 'Έχετε κάποια ερώτηση για κάποιο συγκεκριμένο προϊόν;',
    LINK_LABEL: 'Επικοινωνείστε μαζί μας'
  },
  FORM: {
    GO_TO_STEP: 'Πηγαίνετε στο βήμα',
    STEP: 'Βήμα',
    RECEIVE_EMAIL_COPY: 'Λάβετε ένα αντίγραφο του email',
    SEND: 'Αποστολή',
    GDPR: 'Περισσότερα για την διαχείριση των δεδομένων σας',
    ERROR: 'όλα τα πεδία με * πρέπει να συμπληρωθούν και να είναι έγκυρα τα στοιχεία που εισάγετε',
    SEND_ERROR: 'Κάποιο λάθος προέκυψε, παρακαλώ προπσαθείστε ξανά σε λιγάκι',
    NOTICE: 'Για την σωστή διαχείριση του φακέλου σας, η Bonduelle, ανάλογα με την περίπτωση μπορεί να ζητήσει στοιχεία υγείας. Δεν είναι απαραίτητα και μπορείτε να αρνηθείτε να τα αποκαλύψετε.',
    SENDING: 'Στέλνουμε την φόρμα',
    LOADING: 'Φόρτωση',
    PRODUCT_INFORMATION_TITLE: 'Πληροφορίες για το προϊόν',
    PRODUCT_INFORMATION: {
      SEO_TITLE: 'Πληροφορίες για το προϊόν | Bonduelle',
      SEO_DESC: 'Παρακαλώ επικοινωνείστε με την εξυπηρέτηση πελατών'
    },
    INFORMATIONS: 'οι πληροφορίες σας',
    MISTER: 'Κύριος',
    MISS: 'Κυρία',
    NAME: 'Όνομα',
    OUR_NAME: 'Το όνομά μας',
    FIRSTNAME: 'όνομα',
    OUR_FIRSTNAME: 'Το όνομά μας',
    MAIL: 'το mail σας',
    MAIL_PLH: 'mail@address.com',
    MAIL_CONFIRM: 'επιβεβαιώστε το mail σας',
    MAIL_ERROR: 'παρακαλώ γράψτε ένα έγκυρο email',
    MAIL_ERROR_CONFIRM: 'παρακαλώ επιβεβαιώστε το email σας',
    ADDRESS: 'η διεύθυνσή σας',
    STREET_PLH: '',
    STREET: 'Οδός και αριθμός',
    ADDRESS_COMPLEMENT: 'περισσότερες πληροφορίες για την διεύθυνσή σας',
    ADDRESS_COMP_PLH: 'περισσότερες πληροφορίες για την διεύθυνσή σας',
    ZIPCODE: 'Ταχυδρομικός κώδικας',
    CITY: 'πόλη',
    CITY_PLH: 'η πόλη σας',
    PHONE: 'τηλέφωνο',
    PHONE_PLH: 'αριθμός τηλεφώνου',
    COUNTRY: 'χώρα',
    PRODUCT_INFORMATIONS: 'πληροφορίες προϊόντος',
    PRODUCT_INFORMATION_DESC: 'Προκειμένου οι υπηρεσίες μας να μελετήσουν καλύτερα το<br>αίτημά σας, παρέχετε τις ακόλουθες πληροφορίες:',
    PRODUCT: {
      DETAIL: {
        DESC: 'Για κάθε κριτήριο που ζητάτε, μπορείτε να συμπληρώσετε το πεδίο φόρμας<br>και να προσθέσετε μια φωτογραφία εικονογράφησης. Πληροφορίες: η φωτογραφία σας πρέπει να επιτρέπει την καθαρή ανάγνωση<br>του γραμμωτού κώδικα, του αριθμού παρτίδας και του BBD του προϊόντος.'
      },
      NOTE: {
        TITLE: 'Σχολιάστε ένα προϊόν',
        SEO_TITLE: 'Σημείωση και σχόλιο για ένα προϊόν | Bonduelle',
        SEO_DESC: 'Μια παρατήρηση ή ένα σχόλιο για ένα προϊόν της σειράς μας; Επικοινωνήστε με την εξυπηρέτηση καταναλωτών για να σας βοηθήσουμε. Εξατομικευμένη απάντηση εντός 48 ωρών'
      }
    },
    PRODUCT_RANGE: 'Φάσμα των σχετικών προϊόντων',
    PRODUCT_NAME: 'Ονομασία προϊόντος',
    PRODUCT_NAME_PLH: 'Γράψτε το όνομα του προϊόντος Bonduelle που αφορά το αίτημά σας',
    PRODUCT_INFORMATIONS_DETAIL: 'Detailed informations ',
    PRODUCT_INFORMATIONS_LABEL: 'Barcode προϊόντος',
    PRODUCT_INFORMATIONS_PLH: 'Ο γραμμωτός κώδικας αποτελείται<br>από 13 ψηφία που βρίσκονται στη<br> συσκευασία του προϊόντος σας.',
    PRODUCT_LOT: 'Αριθμός παρτίδας προϊόντος',
    PRODUCT_LOT_INFO: 'Ο αριθμός παρτίδας<br>αρχίζει πάντα με το γράμμα L ακολουθούμενο<br> από πολλά ψηφία και ποικίλλει<br> ανάλογα με το προϊόν.<br><br>Για παράδειγμα L72450492107:13.',
    PHOTO: 'Προσθέστε μια εικόνα',
    EXPIRED_DATE: 'Ημερομηνία λήξης',
    EXPIRED_DATE_ERROR: 'Εισαγάγετε μια έγκυρη ημερομηνία σε μορφή ηη/μμ/εεεε',
    EXPIRED_DATE_DESC: 'Το BBD (Best Before Date)<br>και το BBD (Use By Date)<br>παρουσιάζονται ως ημερομηνίες.',
    PHOTO_MORE: 'Πρόσθετες εικόνες',
    MESSAGE: 'Το μήνυμά σου *',
    OUR_MESSAGE: 'γράψτε το μήνυμά σας',
    SALES_OPERATIONS: {
      TITLE: 'Πληροφορίες σχετικά με μια επιχειρηματική συναλλαγή',
      SEO_TITLE: 'Πληροφορίες για τις τρέχουσες λειτουργίες: διαγωνισμός… | Bonduelle',
      SEO_DESC: 'Επικοινωνήστε με την εξυπηρέτηση καταναλωτών για πληροφορίες σχετικά με μια εμπορική λειτουργία που βρίσκεται σε εξέλιξη. Εξατομικευμένη απάντηση εντός 48 ωρών'
    },
    PRESS_SERVICE: 'Επικοινωνήστε με το τμήμα Τύπου',
    FOOD_SERVICE: 'Επικοινωνήστε με την ομάδα του Bonduelle Food Service',
    BUSINESS_INFORMATION: 'Λάβετε δείγματα/κουπόνια/προωθητικά είδη',
    MODAL_INFORMATION: '',
    JOB_OFFER: { TITLE: '', SEO_TITLE: '', SEO_DESC: '' },
    WEB_SUGGESTION: {
      TITLE: 'Κάντε μια πρόταση για τον ιστότοπο',
      SEO_TITLE: 'Μήνυμα βελτίωσης, πρόταση ιστότοπου | Bonduelle',
      SEO_DESC: 'Επικοινωνήστε με την εξυπηρέτηση καταναλωτών για να μας βοηθήσετε να βελτιώσουμε τον ιστότοπο.'
    },
    ANOTHER: {
      TITLE: 'Κάντε άλλο αίτημα',
      SEO_TITLE: 'Επικοινωνήστε για οποιοδήποτε άλλο αίτημα, εξυπηρέτηση | Bonduelle',
      SEO_DESC: 'Επικοινωνήστε με την εξυπηρέτηση καταναλωτών για πληροφορίες, μια υπηρεσία ή μια πρόταση. Εξατομικευμένη απάντηση εντός 48 ωρών'
    },
    AMBASSADOR: 'Γίνετε πρεσβευτής της Bonduelle',
    CATEGORY: {
      SALAD: 'Σαλάτα',
      FROZEN: 'κατεψυγμένο',
      CAN: 'κονσέρβα',
      TAKEAWAY: 'Πάρε μακριά',
      VEGETABLE: ''
    }
  },
  CONTACT: {
    TITLE: 'Στην διάθεσή σας',
    SPECIAL_INFO: '',
    TAKE_CARE: '',
    BY_MAIL: '',
    BY_PHONE: 'Μέσω τηλεφώνου',
    BY_SOURDLINE: 'Από το Sourdline',
    MAIL_TEXT: '',
    PHONE_TEXT: 'Οι σύμβουλοί μας είναι στην διάθεσή σας στο τηλ +302114111780 (Εσ. 801) από Δευτέρα έως Παρασκευή 9πμ - 5μμ.',
    SOURDLINE_TEXT: 'Για τους καταναλωτές με προβλήματα ακοής μπορείτε να κάνετε ζωντανή συνομιλία με εκρόσωπο από Δευτέρα έως Παρασκευή 9πμ - 6μμ',
    BY_DISTRIBUTOR: 'διανομείς',
    BY_DISTRIBUTOR_TEXT: '<strong>ATLANTA AE</strong><br />\n' +
      'ΑΝΤΙΠΡΟΣΩΠΕΙΕΣ - ΔΙΑΝΟΜΕΣ<br />\n' +
      '3ο ΧΛΜ Λ. Μαρκοπούλου, 19002 ΠΑΙΑΝΙΑ<br />\n' +
      'Τηλ. 210 6675000 Email: info@atlanta.gr<br />\n' +
      'ΑΦΜ : 094077212 - ΔOY : ΦΑΕ<br />\n' +
      'ΑΘΗΝΩΝ<br />\n' +
      '<a href="https://www.atlanta.gr/el" target="_blank">https://www.atlanta.gr/el</a><br />',
    SUBTITLE: 'Θα θέλατε να επικοινωνήσετε μαζί μας για:',
    AGREEMENT: '',
    READ_MORE: "Επίσης, δικαιούστε οποιδήποτε στιγμή να ασκήσετε το δικαίωμά σας στην Bonduelle και να έχετε πρόσβαση, να διορθώσετε, να διαγράψετε ή να ζητήσετε την φορητότα των προσωπικών σας στοιχείων όπως και να εναντιωθείτε ή να απαγορέψετε την συλλογή τους. Για να ασκήσετε το δικαίωμά σας για αυτό που προαναφέραμε, παρακαλώ γράψτε στο Dpo_france(at)bonduelle(dot)com ή στην ακόλουθη διεύθυνση υπόψιν: στον Υπεύθυνο για την προστασία προσωπικών δεδομένων, Bonduelle, Rue Nicolas Appert, PO Box 30173, F-59653 Villeneuve D'Ascq. Για να μάθετε περισσότερα σχετικά με τον τρόπο που συλλέγει και χρησιμοποιεί η Bonduelle τα στοιχεία σας παρακαλώ πατήστε εδώ. CHECK THIS",
    READ_MORE_LABEL: 'Διαβάστε επίσης',
    SATISFACTION_TEXT: 'Προκειμένου να επικοινωνήσουμε μαζί σας για να σας δώσουμε τις πληροφορίες που ζητούνται για το προϊόν και να σας στείλουμε ένα ερωτηματολόγιο\n' +
      'ικανοποίησης εάν το επιθυμείτε, θα επεξεργαστούμε τις πληροφορίες που παρέχονται με την ιδιότητά μας ως υπεύθυνος επεξεργασίας δεδομένων. Η νομική\n' +
      'βάση για την επεξεργασία του αιτήματός σας είναι η συγκατάθεση λόγω των πιθανών δεδομένων που σχετίζονται με την υγεία που ενδέχεται να παρέχετε στο\n' +
      'αίτημά σας. Αυτές οι πληροφορίες θα διατηρηθούν για όσο διάστημα είναι\n' +
      'απαραίτητο για την επεξεργασία του αιτήματός σας και για έως και 5 χρόνια για την παροχή απόδειξης ότι το αίτημά σας έχει υποβληθεί σε επεξεργασία και για τη\n' +
      'συμμόρφωση με τις νομικές μας υποχρεώσεις. Η νομική βάση για την αποστολή\n' +
      'του ερωτηματολογίου ικανοποίησης είναι επίσης η συγκατάθεσή σας. Αυτές οι πληροφορίες θα διατηρηθούν για το χρόνο που απαιτείται για την αποστολή του\n' +
      "ερωτηματολογίου ικανοποίησης και έως 1 έτος κατ' ανώτατο όριο. Για να μάθετε περισσότερα σχετικά με την επεξεργασία των προσωπικών σας δεδομένων,\n" +
      'συμβουλευτείτε την <a href="https://www.bonduelle.gr/politiki-idiotikotitas-cookies" target="_blank" class="underline">Πολιτική Απορρήτου μας</a>.',
    SATISFACTION_CHECKBOX: 'Επιλέγοντας αυτό το πλαίσιο, συμφωνείτε να λάβετε ένα ερωτηματολόγιο\n' +
      'ικανοποίησης (αποτελούμενο από μία ερώτηση) μετά την επεξεργασία του\n' +
      'αιτήματός σας. Αυτό είναι προαιρετικό.',
    CHECKBOX_TEXT: 'Επιλέγοντας αυτό το πλαίσιο, συμφωνείτε ότι η Bonduelle μπορεί να\n' +
      'επεξεργάζεται τα προσωπικά δεδομένα υγείας που μπορείτε να παρέχετε στο\n' +
      'μήνυμά σας με μοναδικό σκοπό να ανταποκριθεί στο αίτημά σας. <strong>Αυτό είναι\n' +
      'υποχρεωτικό για την επεξεργασία του αιτήματός σας</strong>.',
    PHONE: 'Δευτέρα έως Παρασκευή από τις 9πμ έως τις 6μμ στο 00.33.09.70.25.22.22 (η κλήση δεν έχει προσαυξήσεις, εκτός αργιών και ΣΚ)',
    CALL_US: 'Καλέστε μας',
    TO_WRITE: 'Επικοινωνήστε μαζί μας (γραπτώς)',
    PHONE_NUMBER: '',
    SUCCESS_MESSAGE: 'Σας ευχαριστούμε! Το μήνυμά σας έχει αποσταλλεί στην ομάδα μας. Επεξεργαζόμαστε το αίτημά σας και θα επανέλθουμε σε 2-3 ημέρες εξαιρουμένων αργιών και ΣΚ. Παραμένουμε στην διάθεσή σας από την Δευτέρα 09:00 έως τις 18:00 στο +330970252222. Γιατην διασφάλιση ποιότητας των υπηρεσιώ μας όλες οι επικοινωνίες μας ενδέχεται να καταγραφούν.',
    PHOTOERROR: { MESSAGE: '' }
  },
  GLOBAL: { READ_MORE: 'Διαβάστε περισσότερα' },
  FLOWBOX: {
    GENERIC_KEY: 'ba_GnGC3TKedKEe0-gKomQ',
    PRODUCT_KEY: '1hRXF7TUSt-fhhlJASv-TQ',
    RECIPE_KEY: 'Ulp6NW8-QFOoSa61nBIlaA',
    HOME_KEY: 'g7--tXG3QgiwJjjpsaMmrg'
  },
  TOKEN: {
    FACEBOOK: '',
    PAGE_FACEBOOK_ID: '',
    INSTAGRAM: '',
    INSTA_ID: '17841407279489116',
    YOUTUBE: '',
    TWITTER: '',
    MAGAZINE: '6318061952.9bbab10.8842be1c95904c9d80ff59765a3a2ccc'
  },
  COOKIE: {
    BANNER: {
      MANDATORY: 'Required',
      ANALYTICS: 'ANALYTICS',
      MARKETING: 'MARKETING',
      SOCIAL_MEDIA: 'SOCIAL MEDIA',
      ACCEPT: 'Accept all',
      REJECT: 'Reject All',
      SET_UP: 'Config',
      MANDATORY_DETAILS: 'The required cookies are necessary for the operation of the site, for example to store your login information and provide you with a secure connection.\n' +
        'The cookies required to use the bonduelle.fr site are : \n' +
        '<ul>\n' +
        '<li>session cookies (SESSION_ID): Cookie generated by PHP-based applications. This is a general purpose identifier used to manage user session variables. It is a randomly generated number, the way it is used can be site specific, but a good example is maintaining a login status for a user between pages.</li>\n' +
        '</ul>',
      ANALYTICS_DETAILS: 'Functional cookies help us to optimize the site, for example, to collect statistics, to record your preferences for social interactions or to measure the performance and stability of our application.\n' +
        '<br>\n' +
        'The analytical cookies used by the bonduelle.com site are : \n' +
        '<ul>\n' +
        "<li>DoubleClick: This cookie is set by DoubleClick (owned by Google) to create a profile of the website visitor's interests and display relevant advertisements on other sites. The company's main activity is the exchange of real-time bidding ads from Google.</li>\n" +
        '<li>Google Analytics: these cookies allow tracking the most visited pages and site traffic.</li>\n' +
        '<li>UTMTracking: this cookie allows the management of UTM variables for an optimal follow-up of the different online advertising campaigns</li>.\n' +
        '</ul>',
      MARKETING_DETAILS: 'Marketing cookies allow us to track your preferences for recipes and products in order to always offer you the products that best match your desires.\n' +
        '<br>\n' +
        'The marketing cookies used by the bonduelle.fr site are : \n' +
        '<ul>\n' +
        '<li> Prediggo: this tool uses the SESSION_ID cookie to offer you the products and recipes that best suit your browsing and your searches. </li>\n' +
        '<li>ClickToBuy: this cookie enables us to remember your location so that we can offer you the nearest stores that carry the Bonduelle products you are looking for.</li>\n' +
        `<li>Social Networks: these cookies allow the Bonduelle 'SocialWall' to be displayed on the site's homepage.  This wall" allows you to see all the latest posts from the major social networks used by the Bonduelle teams.</li>\n` +
        '<li>FlowBox: this cookie enables the use of the services of the flowbox software, which allows us to offer our visitors the recipes and creations of other Internet users who use Bonduelle products.</li>\n' +
        '</ul> "',
      V2: 'ok'
    },
    MODAL: {
      TITLE: 'Cookie management',
      TEXT: 'The Bonduelle Group is committed to ensuring the protection of the Personal Data of users of its Web sites, and in particular the Bonduelle.fr Web site.  <br>To enable you to benefit from the services offered by the site, such as consulting products, discovering new gourmet recipes, subscribing to certain services such as our Newsletters, and also to optimize its use and personalization according to your profile, the site uses cookies. The Bonduelle Group is fully committed to ensuring a high level of protection with respect to such Personal Data and to complying with all applicable Data Protection rules when processing Personal Data in connection with the use of its Web site.\n' +
        'Thus, you can activate/deactivate cookies at any time.',
      CONFIG: 'Configuration',
      SAVE: 'Save'
    }
  },
  LINK: { JOB_OFFERS: 'https://jobs.bonduelle.com/?locale=en_US' },
  CLIC2BUY: { INFOS: '' }
};
