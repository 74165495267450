import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import * as langs from '@assets/i18n';

export class AppTranslateLoader implements TranslateLoader {

  constructor() {}

  /**
   * Gets the translations
   */
  public getTranslation(lang: string): Observable<Object> {
    let translation: Object;
    const langEntries = Object.entries(langs);
    for (let i = 0; i < langEntries.length; i++) {
      const [key, value]: [string, Object] = langEntries[i];
      if (key === lang) {
        translation = value;
        break;
      }
    }
    return of(translation);
  }
}
