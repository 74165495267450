import { Component, Input } from '@angular/core';

import { SeparatorSimpleModel } from './separator-simple.model';

@Component({
    selector: 'separator-simple',
    templateUrl: './separator-simple.component.html',
    styleUrls: ['./separator-simple.component.sass'],
    standalone: false
})
export class SeparatorSimpleComponent {

  @Input() section: SeparatorSimpleModel;

}
