import { Component, OnInit, Input } from '@angular/core';
import { SfMediaImage } from '../../image';

@Component({
    selector: 'sf-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.sass'],
    standalone: false
})
export class SfGalleryComponent implements OnInit {

  @Input() index: number;

  @Input() images: SfMediaImage[];

  public bigImage: SfMediaImage;

  constructor() { }

  ngOnInit() {
    if (this.index) {
      this.bigImage = this.images[this.index];
    } else {
      this.bigImage = this.images[0];
    }
  }

  public changeImage(index: number) {
    this.bigImage = this.images[index];
  }

}
