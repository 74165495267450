import { SfMediaImage } from '../../image';
import { SfItem, isSfItem } from './item.interface';

export interface SfItemRecipe extends SfItem {

  text: string;

  recipeEditorial: string;

  icon: SfMediaImage[];

  recipeDifficulty: string;

  recipeTimingPreparation: number;

}

export function isSfItemRecipe(object: Object): object is SfItemRecipe {
  return isSfItem(object) &&
  'text' in object &&
  'recipeEditorial' in object &&
  'icon' in object &&
  'recipeDifficulty' in object &&
  'recipeTimingPreparation' in object;
}
