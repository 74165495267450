import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoggerService } from '@app/core/logger/services/logger.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: []
})
export class LoggerModule {
  static forRoot(): ModuleWithProviders<LoggerModule> {
    return {
      ngModule: LoggerModule,
      providers: [
        LoggerService
      ],
    };
  }
}
