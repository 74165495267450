import { Injectable } from '@angular/core';

import * as moment from 'moment';

/**
 * Wrapper Service used for logging messages into console with various log levels
 */
@Injectable({providedIn: 'root'})
export class LoggerService {

  /**
   * TAG
   */
  private LOG_TAG = '🔧 LoggerService';

  /**
   * Constructor
   */
  constructor() {
  }

  /**
   * Private method for logging in console
   * @param {string} level
   * @param {string} log_tag
   * @param {string} message
   * @param {boolean} logOnServer
   * @param {Object} data Object data or null
   */
  private _log(level: string, log_tag: string, message: string, logOnServer: boolean, data: any = null) {

    let color1;
    switch (level) {
      case 'TRACE':
        color1 = 'blue';
        break;
      case 'DEBUG':
        color1 = 'teal';
        break;
      case 'INFO':
      case 'LOG':
        color1 = 'gray';
        break;
      case 'WARN':
        color1 = 'darkorange';
        break;
      case 'ERROR':
        color1 = 'red';
        break;
      case 'OFF':
      default:
        return;
    }

    if (data) {
      console.log(`%c${moment().format('HH:mm:ss')} [${level}] ${log_tag}: %c${message}`,
        `color:${color1}`,
        `color:black`,
        data);
    } else {
      console.log(`%c${moment().format('HH:mm:ss')} [${level}] ${log_tag}: %c${message}`,
        `color:${color1}`,
        `color:$black`);
    }
  }

  /**
   * Log in console with log level TRACE
   *
   * @param {string} log_tag A TAG identifying who is logging. Usually the name of the class
   * @param {string} message
   * @param {Object} data Additionnal data or null
   */
  trace(log_tag: string, message: string, data: any = null) {
    this._log('TRACE', log_tag, message, true, data);
  }

  /**
   * Log in console with log level DEBUG
   *
   * @param {string} log_tag A TAG identifying who is logging. Usually the name of the class
   * @param {string} message
   * @param {Object} data Additionnal data or null
   */
  debug(log_tag: string, message: string, data: Object = null) {
    this._log('DEBUG', log_tag, message, true, data);
  }

  /**
   * Log in console with log level INFO
   *
   * @param {string} log_tag A TAG identifying who is logging. Usually the name of the class
   * @param {string} message
   * @param {Object} data Additionnal data or null
   */
  info(log_tag: string, message: string, data: Object = null) {
    this._log('INFO', log_tag, message, true, data);
  }

  /**
   * Log in console with log level LOG
   *
   * @param {string} log_tag A TAG identifying who is logging. Usually the name of the class
   * @param {string} message
   * @param {Object} data Additionnal data or null
   */
  log(log_tag: string, message: string, data: Object = null) {
    this._log('LOG', log_tag, message, true, data);
  }

  /**
   * Log in console with log level WARN
   *
   * @param {string} log_tag A TAG identifying who is logging. Usually the name of the class
   * @param {string} message
   * @param {Object} data Additionnal data or null
   */
  warn(log_tag: string, message: string, data: Object = null) {
    this._log('WARN', log_tag, message, true, data);
  }

  /**
   * Log in console with log level ERROR
   *
   * @param {string} log_tag A TAG identifying who is logging. Usually the name of the class
   * @param {string} message
   * @param {Object} data Additionnal data or null
   */
  error(log_tag: string, message: string, data: Object = null) {
    this._log('ERROR', log_tag, message, true, data);
  }
}
