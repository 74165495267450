// @ts-nocheck
import { SomPage } from './page.model';
import {
  SfMediaImage,
  SfMediaVideo,
  SfSlider,
  SfLink,
  SfSimpleSlider,
  SfSimpleVideoSlide,
  SfSimpleSlide,
  SfDisplayable,
  SfItemRecipe,
  SfLocalizeService,
  SfItem,
  SfTag
} from '@app/sf-lib/public_api';
import { AppInjector } from '../app-injector';
import { TranslateService } from '@ngx-translate/core';
import { transliterate as slugify } from 'transliteration';
import { recipeLink } from '../../../assets/link/recipe-link'

const charsMapConvert = require('@app/core/charsMap');

export class SomRecipePage extends SomPage implements SfItem, SfItemRecipe, SfDisplayable {

  /** Recipe ID. Fetched from back */
  recipeid: string;

  /** SfItemRecipe properties */
  text: string;
  icon: SfMediaImage[];

  trackingCode: string;

  /** PIM recipe properties */
  id: string;
  recipeUrl: string;
  recipeCategory: string;
  recipeCookTipDescription: string;
  recipeCookTipTitle: string;
  recipeCookTipPicture: SfMediaImage;
  recipeCookTipVideo: SfMediaVideo;
  recipeDifficulty: string;
  recipeCost: string;
  recipeDish: string;
  recipeDishNumber: number;
  recipeEditorial: string;
  recipeIngredientsSummary: string;
  recipeIngredientPicture: SfMediaImage[] | string;
  recipeName: string;
  recipeUrl: string;
  name: string;
  recipeSeoDescription: string;
  recipeSeoTitle: string;
  recipeStep: string[];
  recipeTimingCook: number;
  recipeTimingPreparation: number;
  recipeYoutubeCode: SfMediaVideo;
  recipeMainPicture: SfMediaImage;
  imageUrl: SfMediaImage;
  recipeExtraPicture: SfMediaImage;
  recipeStep1?: string;
  recipeStep2?: string;
  recipeStep3?: string;
  recipeStep4?: string;
  recipeStep5?: string;
  recipeStep6?: string;
  recipeStep7?: string;
  recipeStep8?: string;
  thumbnailUrl: string;

  get recipeTitle(): string {
    return this.name ? this.name : this.recipeName;
  }

  /** SEO Title */
  get meta_title(): string { return this.recipeSeoTitle; }

  /** SEO description */
  get meta_description(): string { return this.recipeSeoDescription; }

  /** SfItem cover */
  get cover(): SfMediaImage {
    return this.imageUrl ? this.imageUrl : this.recipeMainPicture;
  }

  /** SfItem title */
  get title(): string {
    return this.name ? this.name :this.recipeName;
  }

  get tag(): SfTag {
    const newTag = new SfTag();
    newTag.sfType = 'SfTag';
    if (this.recipeDish) {
      newTag.name = this.recipeDish;
    } else {
      this.translate.instant('RECIPE.DEFAULT_TAG');
    }
    return newTag;
  }

  /** SfItem link */
  get link(): SfLink {
    let alias = `${this.id}`;
    const title = this.name ? this.name : this.recipeName;
    let slugRecipe;
    if (this.recipeName || this.name) {
      slugRecipe = this.localize.currentLang.code === 'he' ? slugify(title) : title;
      alias = encodeURI(charsMapConvert(slugRecipe));
    }

    this.id = this.id.toUpperCase();
    const tradLink = recipeLink.find(l => l.lang === this.translate.currentLang)
    if (this.trackingCode) {
      return {
        is_external: false,
        label: '',
        url: `/${tradLink.link}/${alias}/${this.id}`,
        extras: {id: this.trackingCode}
      };
    } else {
    return {
      is_external: false,
      label: '',
      url: `/${tradLink.link}/${alias}/${this.id}`
    };
  }
  }
  set link(value: SfLink) {
    return;
  }

  /** SfDisplayable display */
  display: boolean;

  /** Slider  */
  get slider(): SfSlider {
    const s: SfSlider = new SfSimpleSlider();
    s.slides = [];
    if (this.recipeYoutubeCode && this.recipeYoutubeCode.video) {
      const video: SfSimpleVideoSlide = new SfSimpleVideoSlide();
      video.sfType = 'SfVideoSlide';
      video.video = this.recipeYoutubeCode;
      (s.slides as any[]).push(video);
    }
    if (this.recipeMainPicture && this.recipeMainPicture.image.uri.original) {
      const main: SfSimpleSlide = new SfSimpleSlide();
      main.sfType = 'SfSlide';
      main.background = this.recipeMainPicture;
      s.slides.push(main);
    }
    if (this.recipeExtraPicture && this.recipeExtraPicture.image.uri.original) {
      const extra: SfSimpleSlide = new SfSimpleSlide();
      extra.sfType = 'SfSlide';
      extra.background = this.recipeExtraPicture;
      s.slides.push(extra);
    }
    return s;
  }

  private readonly baseUrl = '/recipes/r';

  protected localize: SfLocalizeService;
  protected translate: TranslateService;

  constructor() {
    super();
    this.recipeStep = [];
      try {
        const injector = AppInjector.getInjector();
        this.localize = injector.get<SfLocalizeService>(SfLocalizeService);
        this.translate = injector.get<TranslateService>(TranslateService);
      } catch (e) {
        console.error('Failed initializing model', e);
      }
  }

  public static flatten(recipe: SomRecipePage): any {
    const newRecipe = {...recipe};
    delete newRecipe['localize'];
    delete newRecipe['translate'];

    return newRecipe;
  }

  /**
   * Custom function used by JSON.stringify
   * This prevent JSON.stringify to throw an error on circular
   * references
   *
   * @param {string} nm Name of the property
   * @returns {string}
   * @memberof SomRecipePage
   */
  public toJSON(nm: string) {
    const newRecipe = {
      recipeid: this.recipeid,
      text: this.text,
      icon: this.icon,
      trackingCode: this.trackingCode,
      id: this.id,
      recipeCategory: this.recipeCategory,
      recipeCookTipDescription: this.recipeCookTipDescription,
      recipeCookTipTitle: this.recipeCookTipTitle,
      recipeCookTipPicture: this.recipeCookTipPicture,
      recipeCookTipVideo: this.recipeCookTipVideo,
      recipeCost: this.recipeCost,
      recipeDifficulty: this.recipeDifficulty,
      recipeDish: this.recipeDish,
      recipeDishNumber: this.recipeDishNumber,
      recipeEditorial: this.recipeEditorial,
      recipeIngredientsSummary: this.recipeIngredientsSummary,
      recipeName: this.name ? this.name : this.recipeName,
      recipeSeoDescription: this.recipeSeoDescription,
      recipeSeoTitle: this.recipeSeoTitle,
      recipeStep: this.recipeStep,
      recipeTimingCook: this.recipeTimingCook,
      recipeTimingPreparation: this.recipeTimingPreparation,
      recipeYoutubeCode: this.recipeYoutubeCode,
      recipeMainPicture: this.imageUrl ? this.imageUrl : this.recipeMainPicture,
      imageUrl: this.imageUrl,
      recipeExtraPicture: this.recipeExtraPicture,
      recipeIngredientPicture: this.recipeIngredientPicture,
      display: this.display,
      meta_title: this.meta_title,
      meta_description: this.meta_description,
      cover: this.cover,
      title: this.title,
      tag: this.tag,
      link: this.link,
      slider: this.slider,

      lang: this.lang,
      content: this.content,
      meta_keywords: this.meta_keywords,
      thumbnailUrl: this.thumbnailUrl
    };
    return newRecipe;
  }

}

export function isSomRecipePage(object: Object): object is SomRecipePage {
  return 'recipeid' in object;
}
