// @ts-nocheck
import {
  Component,
  OnInit,
  TemplateRef,
  Input,
  Inject,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  AfterViewInit
} from '@angular/core';
import { SliderGridService } from '../../services/slider-grid.service';
import { SliderGridAnimations } from './slider-grid.animations';
import { OPTIONS, SfOptions } from '../../../../core/interfaces/options.interface';
import { SfItem } from '../../../item';
import { SfSliderGrid } from '../../models/slider-grid.interface';
import { SfSimpleSliderGrid } from '../../models/slider-grid.model';
import { SfSimpleSlideGrid } from '../../models/slide-grid.model';

@Component({
    selector: 'sf-slider-grid',
    templateUrl: './slider-grid.component.html',
    styleUrls: ['./slider-grid.component.sass'],
    animations: [
        SliderGridAnimations
    ],
    standalone: false
})
export class SfSliderGridComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Input() template: TemplateRef<any>;

  @Input() items: SfItem[];

  @Input() sliderGrid: SfSliderGrid;

  @Input() pace = 25000;

  @Input() content: 'item' | 'item-text' = 'item-text';

  @Input() mode: 'objective' | 'subjective' = 'objective';

  public animationLockCounter = 0;

  public sliderGridArray: SfSliderGrid = this.sliderGrid;

  public elementWidth: number;

  constructor(
    public sliderGridService: SliderGridService,
    @Inject(OPTIONS) public options: SfOptions
  ) { }

  ngOnInit() {
    this.sliderGridService.init(this.sliderGrid.gridSlides, this.pace);
    this.sliderGridService.start();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName of Object.keys(changes)) {
      if (propName === 'items') {
        this.sliderGridService.stop();
        this.sliderGrid = new SfSimpleSliderGrid();
        this.sliderGrid.gridSlides = [];
        const items: SfItem[] = changes[propName].currentValue;
        if (Array.isArray(items)) {
          let i = 0;
          while (i < items.length) {
            const newSlide: SfSimpleSlideGrid = new SfSimpleSlideGrid();
            newSlide.items = items.slice(i, i + 4);
            this.sliderGrid.gridSlides.push(newSlide);
            i += 4;
          }
        }
        this.sliderGridService.init(this.sliderGrid.gridSlides, this.pace);
      }
    }
  }

  ngAfterViewInit() {
    this.sliderGridService.start();
  }

  ngOnDestroy() {
    this.sliderGridService.stop();
  }

  public animationStart() {
    this.animationLockCounter = this.animationLockCounter + 1;
  }

  public animationDone() {
    this.animationLockCounter = this.animationLockCounter - 1;
  }

  public next() {
    if (this.animationLockCounter > 0) { return; }
    this.sliderGridService.next();
  }

  public previous() {
    if (this.animationLockCounter > 0) { return; }
    this.sliderGridService.previous();
  }

}
