import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
    name: 'toHTML',
    standalone: false
})
export class StringToHTMLPipe implements PipeTransform {

  constructor() {}

  transform(value: string, search?: string): SafeHtml {
      if (search) {
        value = value.replace(new RegExp('(?![^&;]+;)(?!<[^<>]*)(' + search + ')(?![^<>]*>)(?![^&;]+;)', 'gi'),
        '<span class="highlight">$1</span>');
      }
      return value.split('\n').join('<br><br>');
  }

}
