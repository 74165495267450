import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { translateConfig } from './classes/translate-config';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forRoot(translateConfig)
  ],
  declarations: []
})
export class AppTranslateModule {
}
