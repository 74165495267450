import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SfItemComponent } from './components/item.component';
import { SfLinkModule } from '../link/link.module';
import { SfImageModule } from '../image/image.module';
import { SfRolloverModule } from '../rollover/rollover.module';
import { SfItemIconComponent } from './components/item-icon.component';
import { SfItemTextComponent } from './components/item-text.component';
import { SfItemTextHorizontalComponent } from './components/item-text-horizontal.component';
import { SfItemStatComponent } from './components/item-stat.component';
import { SfUtilsModule } from '../../core/utils/utils.module';
import { SfItemRecipeComponent } from './components/item-recipe.component';

import { TranslateModule } from '@ngx-translate/core';
import { AngularMaterialModule } from '@app/shared/angular-material/angular-material.module';
import { SfItemFlipComponent } from './components/item-flip.component';
import { SfItemIconBkgComponent } from './components/item-icon-bkg/item-icon-bkg.component';

@NgModule({
  imports: [
    CommonModule,
    SfLinkModule,
    SfImageModule,
    SfRolloverModule,
    SfUtilsModule,
    TranslateModule,
    AngularMaterialModule,
  ],
  declarations: [
    SfItemComponent,
    SfItemIconComponent,
    SfItemTextComponent,
    SfItemTextHorizontalComponent,
    SfItemStatComponent,
    SfItemRecipeComponent,
    SfItemFlipComponent,
    SfItemIconBkgComponent
  ],
  exports: [
    SfItemComponent,
    SfItemIconComponent,
    SfItemTextComponent,
    SfItemTextHorizontalComponent,
    SfItemStatComponent,
    SfItemRecipeComponent,
    SfItemFlipComponent,
    SfItemIconBkgComponent
  ]
})
export class SfItemModule { }
