<div class="sf-search-recipe">
  <div class="sf-search-recipe-bar">
    <div class="sf-search-recipe-bar-text">
      {{ 'SF.SEARCHRECIPE.DISCOVER_ALL' |translate }}
    </div>
    <div class="sf-search-recipe-bar-search">
      <input [formControl]="searchRecipe" class="sf-search-recipe-bar-search-bar" type="text" [placeholder]="'SF.SEARCH.PLACEHOLDER' | translate"
        tabindex="1" (keydown.enter)="onkeydown(searchRecipe.value)" (blur)="blurFunction()">
      <button class="sf-search-recipe-bar-search-bar-icon" id="searchInput" (click)="onkeydown(searchRecipe.value)">
        <mat-icon svgIcon="search" ></mat-icon>
      </button>
      <ul class="sf-search-recipe-bar-search-bar-list" *ngIf="keywordRecommandation">
        <li class="sf-search-recipe-bar-search-bar-list-content" *ngFor="let keyword of keywordRecommandation; let i = index" [attr.tabindex]="i + 2"
          (click)="onkeydown(keyword)">
          <div [innerHtml]="keyword | sfHighlight : searchRecipe.value"></div>
        </li>
      </ul>
    </div>
  </div>
  <div class="sf-search-recipe-icon">
    <mat-icon svgIcon="search-recipe"></mat-icon>
  </div>
</div>