import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable} from 'rxjs';
import { environment } from '@env/environment';
import { SfLocalizeService } from '@app/sf-lib/public_api';

/**
 * Interceptor responsible for passing headers/params in all requests
 * @export
 * @class PrediggoInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class PrediggoInterceptor implements HttpInterceptor {

  /** @ignore */
  constructor(
    private localize: SfLocalizeService
  ) { }

  /**
   * Append necessary headers and/or params to all requests to the API
   * @param {HttpRequest<any>} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   * @memberof AuthInterceptor
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.url.indexOf(environment.prediggoApiUrl) < 0) {
      return next.handle(request);
    }

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-type', 'application/json');
    headers = headers.append('x-api-key', environment.prediggoApiKey);

    request = request.clone({
      headers: headers
    });

    return next.handle(request);

  }
}
