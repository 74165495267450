import { Injectable, Inject } from '@angular/core';
import { ActivatedRoute, Router, Route, Routes } from '@angular/router';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { SITE_INFO, SfSiteInfo } from '../models/site-info.model';
import * as utils from '../classes/localize-utils';

import { SfLang } from '../../models/lang.model';

import * as moment from 'moment';

/**
 * Service responsible for providing current langague of the app
 */
@Injectable({providedIn: 'root'})
export class SfLocalizeService {

  public langSubject: BehaviorSubject<SfLang> = new BehaviorSubject(null);
  get lang(): Observable<SfLang> { return this.langSubject.asObservable(); }
  get currentLang(): SfLang { return this.langSubject.getValue(); }

  public lastLang: SfLang;

  constructor(
    @Inject(SITE_INFO) private siteInfo: SfSiteInfo,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) {
    this.langSubject.next(siteInfo.lang);
    this.lastLang = siteInfo.defaultLang;

    this.reconfigure(siteInfo.lang, siteInfo.path);

    if (!router.navigated) {
      router.initialNavigation();
    }
  }

  /**
   * Effectively changes the site's language.
   * Operates a change of URL, routes and translations.
   * Will do nothing if the language doesn't exists for the current country
   * (current country is determined with site's extension => .fr .com .it ...)
   * @memberof SfLocalizeService
   */
  use(lang: SfLang) {
    // Don't use a language that is not defined for this site
    if (!this.siteInfo.locales.find((s) => s.code === lang.code)) { return; }
    this.lastLang = this.currentLang;
    return  this.reconfigure(lang);
  }

  navigateHome() {
    if (this.currentLang && !this.currentLang.default) {
      this.router.navigate([this.currentLang.code]);
    } else {
      this.router.navigate(['']);
    }
  }

  /**
   * Returns the translated path. Same function used by the SfLocalizePipe
   * @memberof SfLocalizeService
   */
  instant(path: string) {
    let result: string = path;

    /** translate key and update values */
    const anonymizedQuery = utils.anonymize(path, this.siteInfo.defaultLang, this.siteInfo.translationObject);
    result = utils.unanonymize(anonymizedQuery, this.currentLang, this.siteInfo.translationObject);

    // If path should start with langcode add it
    if (!this.currentLang.default) {
      result = this.appendLangcode(result, this.currentLang);
    }

    return result;
  }

  private reconfigure(lang: SfLang, url?: string) {
    let queryParams = '';
    if (this.location.path().split('?').length > 1) {
      queryParams = this.location.path().split('?')[1];
    }

    url = url || this.router.url;

    // Change the language used by ngxTranslate and moment
    this.translate.use(utils.fullCode(lang));
    moment.locale(lang.code);

    // Translate router config
    this.translateRouteConfig(this.router.config, lang);

    // Translate location path
    let translatedUrl: string = this.translateLocation(url, lang);

    // Update service's language
    this.langSubject.next(lang);

    // Update current url
    if (queryParams) {
      translatedUrl = translatedUrl + '?' + queryParams;
    }

    return this.router.navigateByUrl(translatedUrl);
  }

  private translateRouteConfig(config: Routes, lang: SfLang) {
    let unwrappedRootRoutes: Routes = config;
    // Rebase root routes if they're contained in localized config
    if (config.length === 1 && this.isLocalized(config[0].path)) {
      unwrappedRootRoutes = config[0].children;
    }

    // Translate root routes config
    let newRootConfig: Routes = [];
    const newRoutes: Routes = [];
    unwrappedRootRoutes.forEach(
      (rootRoute: Route) => {
        if (rootRoute.path !== void 0) {
          newRoutes.push(
            this.translateRoute(rootRoute, lang)
          );
        }
      }
    );

    // Wrap root routes in localized route (ex. '/be/...')
    // if this is not the defaut route for this country
    if (!lang.default) {
      newRootConfig.push(
        {
          path: lang.code,
          children: newRoutes
        }
      );
    } else {
      newRootConfig = newRoutes;
    }
    // Reset root config
    this.router.resetConfig(newRootConfig);
  }

  private translateRoute(route: Route, lang: SfLang): Route {
    if (!route) { return route; }
    const newRoute: Route = {...route};

    if (newRoute.path) {
      if (!newRoute.data) { newRoute.data = {}; }
      newRoute.data['path'] = utils.anonymize(newRoute.path, this.lastLang, this.siteInfo.translationObject);
    }
    if (newRoute.redirectTo) {
      if (!newRoute.data) { newRoute.data = {}; }
      newRoute.data['redirectTo'] = utils.anonymize(newRoute.redirectTo.toString(), this.lastLang, this.siteInfo.translationObject);
    }

    if (newRoute.path && newRoute.data && newRoute.data.path) {
      newRoute.data['path'] = utils.anonymize(newRoute.path, this.lastLang, this.siteInfo.translationObject);
      newRoute.path = utils.unanonymize(newRoute.data.path, lang, this.siteInfo.translationObject);
    }
    if (newRoute.children && newRoute.children.length > 1) {
      const newChildren: Routes = [];
      newRoute.children.forEach(child => {
        newChildren.push(this.translateRoute(child, lang));
      });
      newRoute.children = newChildren;
    }
    if (newRoute.redirectTo && newRoute.data && newRoute.data.redirectTo) {
      newRoute.data['redirectTo'] = utils.anonymize(newRoute.redirectTo.toString(), this.lastLang, this.siteInfo.translationObject);
      newRoute.redirectTo = utils.unanonymize(newRoute.data.redirectTo, lang, this.siteInfo.translationObject);
    }
    if (route.loadChildren && (<any>route)._loadedConfig) {
      const oldLoadedConfigRoutes: Object[] = (<any>route)._loadedConfig.routes;
      (<any>route)._loadedConfig.routes = [];
      oldLoadedConfigRoutes.forEach(
        element => {
          (<any>route)._loadedConfig.routes.push(
            this.translateRoute(element, lang)
          );
        }
      );
    }
    return newRoute;
  }

  /**
   * Translate given url from url's language to new language
   * If necessary, add the langcode to the beginning of the path
   */
  private translateLocation(path: string, destLang: SfLang): string {

    if (destLang.code === this.currentLang.code) { return path; }

    // Split given path into langcode and remaining path.
    const splitPath = this.splitPath(path);

    // Translate remaining path to uuids
    const anonymizedPath = utils.anonymize(splitPath.path, this.currentLang, this.siteInfo.translationObject);

    // Translate uuid path to destination language path
    let translatedPath = utils.unanonymize(anonymizedPath, destLang, this.siteInfo.translationObject);

    // If path should start with langcode add it
    if (!destLang.default) {
      translatedPath = this.appendLangcode(translatedPath, destLang);
    }
    return translatedPath;
  }

  private appendLangcode(path: string, lang: SfLang): string {
    let appendedPath: string;
    if (path) {
      const langPath = path.startsWith('/') ? `/${lang.code}` : `${lang.code}/`;
      appendedPath = `${langPath}${path}`;
      if (appendedPath.endsWith('/')) {
        appendedPath = appendedPath.slice(0, -1);
      }
    } else {
      appendedPath = `/${lang.code}`;
    }
    return appendedPath;
  }

  private splitPath(path: string): {langcode: string, path: string} {
    const result: {langcode: string, path: string} = {
      langcode: '',
      path: ''
    };
    const segments = path.split('/');
    let find: SfLang;
    for (let i = 0; i < 2; i++) {
      find = this.siteInfo.locales.find(l => l.code === segments[i]);
      if (find !== undefined) {
        result.langcode = find.code;
        segments.splice(i, 1);
        result.path = segments.join('/');
        break;
      }
    }
    if (!find) {
      result.path = path;
    }
    return result;
  }

  private isLocalized(path: string): string | null {
    const queryParts = path.split('?');
    const pathSegments = queryParts[0].split('/');
    // Localized URLs are either 'be' or '/be/something'
    if (pathSegments.length === 1) {
      return utils.localesContains(pathSegments[0], this.siteInfo.locales) ? pathSegments[0] : null;
    } else if (pathSegments.length > 1) {
      return utils.localesContains(pathSegments[1], this.siteInfo.locales) ? pathSegments[1] : null;
    }
  }
}
