import { Injectable, Injector, TemplateRef, Type } from '@angular/core';
import { Overlay, OverlayConfig, PositionStrategy, ConnectionPositionPair } from '@angular/cdk/overlay';
import { PortalInjector, ComponentPortal } from '@angular/cdk/portal';
import { SfPopoverComponent } from '../components/hint/popover.component';
import { SfPopoverRef } from './popover-ref';

export type SfPopoverContent = TemplateRef<any> | Type<any> | string;

export interface SfPopoverParams<T> {
  origin: HTMLElement;
  content: SfPopoverContent;
  data?: T;
  // width?: string | number;
  // height: string | number;
}

@Injectable()
export class SfPopoverService {

  constructor(
    private overlay: Overlay,
    private injector: Injector
  ) { }

  open<T>({ origin, content, data }: SfPopoverParams<T>): SfPopoverRef<T> {
    const overlayRef = this.overlay.create(this.getOverlayConfig(origin));
    const popoverRef = new SfPopoverRef<T>(overlayRef, content, data);

    const injector = this.createInjector(popoverRef, this.injector);
    overlayRef.attach(new ComponentPortal(SfPopoverComponent, null, injector));

    return popoverRef;
  }

  createInjector(popoverRef: SfPopoverRef, injector: Injector) {
    const tokens = new WeakMap([[SfPopoverRef, popoverRef]]);
    return new PortalInjector(injector, tokens);
  }

  private getOverlayConfig(origin): OverlayConfig {
    return new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'sf-popover-backdrop',
      positionStrategy: this.getOverlayPosition(origin),
      scrollStrategy: this.overlay.scrollStrategies.reposition()
    });
  }

  private getOverlayPosition(origin: HTMLElement): PositionStrategy {
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo(origin)
      .withPositions(this.getPositions())
      .withPush(true);

    return positionStrategy;
  }

  private getPositions(): ConnectionPositionPair[] {
    return [
      {
        originX: 'end',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'top',
        offsetX: 0,
        offsetY: 20
      },
      {
        originX: 'end',
        originY: 'top',
        overlayX: 'end',
        overlayY: 'bottom',
        offsetX: 0,
        offsetY: -20
      },
    ];
  }

}
