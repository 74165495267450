import { SfMediaImage } from '../../image';
import { SfSectionImage } from './section-image.interface';
import { SfModel } from '../../../core/networking';
import { SfLink } from '../../link';

export class SfSimpleSectionImage extends SfModel implements SfSectionImage {

  /** Title */
  title: string;

  /** Text of the section. May contain HTML tags */
  text: string;

  /** Image to display in the section */
  image: SfMediaImage;

  /** Position of the image in the section ('left'|'right') */
  position: 'left' | 'right';

  /** link in section */
  link?: SfLink;

}
