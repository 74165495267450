import { SfMediaImage, SfItem, SfLink, SfLocalizeService, SfBanner, SfTag } from '@app/sf-lib/public_api';
import { SomPage } from './page.model';
import { AppInjector } from '../app-injector';
import * as moment from 'moment';

export class SomMagazineHomePage extends SomPage implements SfItem, SfBanner {

  /** Unique ID from server */
  nid: number;

  /** Duplicate ID. When a node is duplicated we should show this ID in url  */
  duplication_id: number;

  /** Type from server */
  type: string;

  /** Title */
  title: string;

  /** Path alias information */
  path: {
    alias: string;
  };

  /** Main image of the article */
  cover: SfMediaImage;

  /** Main image of the article for mobile */
  smallcover: SfMediaImage;

  /** Date of the magazine edition */
  date?: string;

  /** Magazine edition number */
  number: string;

  get link(): SfLink {
    let alias = `${this.nid}`;
    if (this.path && this.path.alias) {
      alias = this.path.alias.replace(/\//g, '');
    }
    return {
      is_external: false,
      label: '',
      url: `${this.localize.instant(this.baseUrl)}/${alias}/${this.nid}/${this.duplication_id}`
    };
  }
  set link(value: SfLink) {
    return;
  }

  get tag(): SfTag {
    const newTag = new SfTag();
    newTag.sfType = 'SfTag';
    let month: string;
    let year: string;
    if (this.localize && this.localize.currentLang && this.localize.currentLang.code) {
      month = moment(this.date, 'dd/MM/yyyy').locale(this.localize.currentLang.code).format('MMMM');
      year = moment(this.date, 'dd/mm/YYYY').format('YY');
    } else {
      month = moment(this.date, 'dd/MM/yyyy').format('MMMM');
      year = moment(this.date, 'dd/mm/YYYY').format('YY');
    }
    if (this.number) {
      newTag.name = `${month} ${year} — ${this.number}`;
    } else {
      newTag.name = `${month} ${year}`;
    }
    return newTag;
  }

  /** SfBanner uppertitle */
  get uppertitle(): string {
    let month: string;
    let year: string;
    if (!this.date || this.localize.currentLang.code === 'sv') {
      return ''
    }
    if (this.localize && this.localize.currentLang && this.localize.currentLang.code) {
      month = moment(this.date, 'dd/MM/yyyy').locale(this.localize.currentLang.code).format('MMMM');
      year = moment(this.date, 'dd/mm/YYYY').format('YY');
    } else {
      month = moment(this.date, 'dd/MM/yyyy').format('MMMM');
      year = moment(this.date, 'dd/mm/yyyy').format('YY');
    }
    if (this.number) {
      return `${month} ${year} — ${this.number}`;
    } else {
      return `${month} ${year}`;
    }
  }

  /** SfBanner background */
  get background(): SfMediaImage {
    return this.cover;
  }

  /** SfBanner smallbackground */
  get smallbackground(): SfMediaImage {
    return this.smallcover;
  }

  private readonly baseUrl = '/magazine/news';

  private localize: SfLocalizeService;

  constructor() {
    super();
    try {
      const injector = AppInjector.getInjector();
      this.localize = injector.get(SfLocalizeService);
    } catch (e) {
      console.error('Failed initializing model', e);
    }
  }

  /**
   * Custom function used by JSON.stringify
   * This prevent JSON.stringify to throw an error on circular
   * references
   *
   * @param {string} nm Name of the property
   * @returns {string}
   * @memberof SomRecipePage
   */
  public toJSON(nm: string) {
    const newArticle = {
      date: this.date,
      number: this.number,
      nid: this.nid,
      duplication_id: this.duplication_id,
      type: this.type,
      title: this.title,
      cover: this.cover,
      path: this.path,
      link: this.link,
      uppertitle: this.uppertitle,
      background: this.background,
      tag: this.tag,
      lang: this.lang,
      content: this.content,
      meta_title: this.meta_title,
      meta_description: this.meta_description,
      meta_keywords: this.meta_keywords,
    };
    return newArticle;
  }
}
