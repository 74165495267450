import { SfMediaImage, SfLink, SfItem, SfTag } from '@app/sf-lib/public_api';

export class SomArticleItem implements SfItem {
  // Product id from Prediggo
  id: string;
  category: 'corporate_article' | 'magazine_article' | string
  title: string;
  titleSeo: string;
  thumbnailUrl: string;
  isNew: boolean;
  shortDescription: string;
  description: string;
  descriptionSeo: string;
  publicationStartDate: Date;
  availability: 'none' | 'all'
  url: string

  /** Prediggo tracking code */
  trackingCode?: string;
  /** SfItem link */
  link: SfLink;

    

  constructor() {
  }
    cover: SfMediaImage;
    smallcover?: SfMediaImage;
    tag?: SfTag;
}
