import { SfModel } from '@app/sf-lib/public_api';
import { SomFaqArticlePage } from '@app/core/models/faq-article-page.model';
import { SomCorporateArticlePage } from '@app/core/models/corporate-article-page.model';
import { SomRecipePage } from '@app/core/models/recipe-page.model';
import { SomProductItem } from '@app/core/models/product-item.model';
import { SomGenericPage } from '@app/core/models/generic-page.model';
import { SomMagazineArticlePage } from '@app/core/models/magazine-article-page.model';

export class SomTile extends SfModel {

  color?: string
  text?: string
  /** Type */
  type: 'tile_faq' |
   'tile_corporate_article' |
   'tile_product' |
   'tile_recipe' |
   'tile_generic_page' |
   'tile_magazine_article' |
   'tile_menu_article';

  faq?:  SomFaqArticlePage;

  corporate_article?: SomCorporateArticlePage;

  magazine_article?: SomMagazineArticlePage;

  recipe?: string;

  recipePage?: SomRecipePage;

  product?: string;

  productPage?: SomProductItem;

  generic_page?: SomGenericPage;

  show_video_icon?: boolean;

  iconName?: string;

  /** A boolean to know the display for this tiles */
  status: boolean;
}
