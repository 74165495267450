import { SomPage } from './page.model';
import { SfMediaImage } from '@app/sf-lib/public_api';
import { SomTheme } from './theme.model';

export class SomRecipeListPage extends SomPage {

  /** Banner */
  public banner: {
    background: SfMediaImage,
    label: string
  };

  /** HTML description */
  public description: string;

  /** Theme */
  public theme: SomTheme;

  constructor() {
    super();
    this.banner = {
      background: undefined,
      label: undefined
    };
  }
}
