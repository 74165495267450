import { Pipe, PipeTransform } from '@angular/core';
import { PrediggoSlot } from '../model/prediggo-slot.model';
import { PrediggoService } from '../services/prediggo.service';
import { SomRecipePage } from '@app/core/models/recipe-page.model';
import { SomProductItem } from '@app/core/models/product-item.model';
import { PREDIGGO_ITEM_TYPE } from '../enum/prediggo-item-type.enum';
import { PredigoConverter } from '../services/prediggo-converter.service';
import { SomArticleItem } from '@app/core/models/article-item.model';

@Pipe({
    name: 'prediggoItems',
    standalone: false
})
export class PrediggoItemsPipe implements PipeTransform {

  constructor(
    private prediggo: PrediggoService,
    private prediggoConverter: PredigoConverter,
    ) {}

  transform(values: PrediggoSlot[]): (SomRecipePage | SomProductItem | SomArticleItem)[] {
    return values.map((slot: PrediggoSlot) => {
      switch (slot.item.itemType) {
        case PREDIGGO_ITEM_TYPE.PRODUCT:
          return this.prediggo.prediggoItemToProductItem(slot.item);
        case PREDIGGO_ITEM_TYPE.RECIPE:
          return this.prediggo.prediggoItemToRecipePage(slot.item);
        case PREDIGGO_ITEM_TYPE.ARTICLE:
          return this.prediggoConverter.convertPrediggoItemToArticleItem(slot.item);
        default:
          return this.prediggo.prediggoItemToRecipePage(slot.item);
      }
    });
    
  }

}
