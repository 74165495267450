<div class="sf-form-select" *ngIf="data && formControl">
  <label class="sf-form-select-label sf-title" [for]="controlName" *ngIf="data.label" [ngClass]="{'error-color': !formControl.pristine && formControl.invalid}">
    {{ data.label | translate }}
    <span *ngIf="data.required"> *</span>
  </label>


  <select class="sf-form-select-container sf-main-color" [formControl]="formControl" [id]="controlName">
    <option class="sf-form-select-option" disabled>
      <label class="sf-form-select-option-label sf-title">
        -- Select --
      </label>
    </option>
    <ng-container *ngFor="let sl of data.values;index as i">
      <option  *ngIf="sl.label | translate | labelEmpty" class="sf-form-select-option" [value]="sl.value">
        <label class="sf-form-select-option-label sf-title">
          {{ sl.label | translate }}
        </label>
      </option>
    </ng-container>

  </select>

  <div class="sf-form-select-error sf-error-color sf-title" *ngIf="!formControl.pristine && formControl.invalid">
    {{ data.error || 'SF.FORM.ERROR' | translate }}
  </div>
</div>
