import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SfOptions, OPTIONS } from './interfaces/options.interface';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { SfMessageModule } from './message/message.module';

@NgModule({ declarations: [], imports: [CommonModule,
        SfMessageModule.forRoot()], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class SfLib {
  static forRoot(options: SfOptions): ModuleWithProviders<SfLib> {
    return {
      ngModule: SfLib,
      providers: [
        { provide: OPTIONS, useValue: options }
      ]
    };
  }
}
