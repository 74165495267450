import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { HOSTNAME } from './../../express.tokens';
import { Meta, Title } from '@angular/platform-browser';


@Injectable({
  providedIn: 'root'
})
export class SeoService {

  private renderer2: Renderer2;
  private currentCanonical: string;

  constructor(
    @Inject(DOCUMENT) private documentElement: Document,
    @Inject(PLATFORM_ID) protected platformId: Object,
    @Optional() @Inject(HOSTNAME) private hostname,
    private titleService: Title,
    private meta: Meta,
    rendererFactory2: RendererFactory2,
  ) {
    this.renderer2 = rendererFactory2.createRenderer(null, null);
  }

  public setPageTitle(title: string) {
    this.titleService.setTitle(title);
  }

  public setPageDescription(description: string) {
    this.meta.updateTag({ name: 'description', content: description })
  }

  public setCanonical(canonicalPath: string): void {
    const hostName: string = isPlatformServer(this.platformId) ? this.hostname : window.location.host;
    const canonicalElement = this.documentElement.querySelector('link[rel=canonical]');
    
    if (canonicalPath === null && canonicalElement) {
      this.renderer2.removeChild(this.documentElement.head, canonicalElement);
      return;
    }

    if (canonicalPath !== null) {
      canonicalPath = canonicalPath[0] !== '/' ? `/${canonicalPath}` : canonicalPath
      const canonicalUrl = `https://${hostName}${canonicalPath}`;
  
      if (canonicalElement) {
        this.renderer2.setAttribute(canonicalElement, 'href', canonicalUrl);
        return;
      }
      
      const linkElement = this.renderer2.createElement('link');
      linkElement.rel = 'canonical';
      linkElement.href = canonicalUrl;
      this.renderer2.appendChild(this.documentElement.head, linkElement);
    }

  }

  public getCurrentCanonical() {
    return this.currentCanonical;
  }

  public addHrefLangsLinks(): void {
    const hrefLangs = [
      { lang: 'fr', url: 'https://www.bonduelle.fr' },
      { lang: 'de', url: 'https://www.bonduelle.de' },
      { lang: 'nl-BE', url: 'https://www.bonduelle.be' },
      { lang: 'nl', url: 'https://www.bonduelle.nl' },
      { lang: 'hu', url: 'https://www.bonduelle.hu' },
      { lang: 'it', url: 'https://www.bonduelle.it' },
      { lang: 'cs', url: 'https://www.bonduelle.cz' },
      { lang: 'sk', url: 'https://www.bonduelle.sk' },
      { lang: 'da', url: 'https://www.bonduelle.dk' },
      { lang: 'de-AT', url: 'https://www.bonduelle.at' },
      { lang: 'pt', url: 'https://www.bonduelle.pt' },
      { lang: 'es', url: 'https://www.bonduelle.es' },
      { lang: 'lt', url: 'https://www.bonduelle.lt' },
      { lang: 'lv', url: 'https://www.bonduelle.lv' },
      { lang: 'et', url: 'https://www.bonduelle.ee' },
      { lang: 'fi', url: 'https://www.bonduelle.fi' },
      { lang: 'no', url: 'https://www.bonduelle.no' },
      { lang: 'sv', url: 'https://www.bonduelle.se' },
      { lang: 'pl', url: 'https://www.bonduelle.pl' },
      { lang: 'sr', url: 'https://www.bonduelle.rs' },
      { lang: 'sq', url: 'https://www.bonduelle.al' },
      { lang: 'el', url: 'https://www.bonduelle.gr' },
      { lang: 'ro', url: 'https://www.bonduelle.ro' },
      { lang: 'hr', url: 'https://www.bonduelle.hr' },
      { lang: 'bg', url: 'https://www.bonduelle.bg' },
      { lang: 'bs', url: 'https://www.bonduelle.ba' },
      { lang: 'x-default', url: 'https://www.bonduelle.fr' }
    ];

    // Ajoute les nouvelles balises hreflang
    hrefLangs.forEach(({ lang, url }) => {
      const linkElement = this.renderer2.createElement('link');
      linkElement.setAttribute('rel', 'alternate');
      linkElement.setAttribute('hreflang', lang);
      linkElement.setAttribute('href', url);
      this.renderer2.appendChild(this.documentElement.head, linkElement);
    });
  }

  public removeHreflangs(): void {
    const links = this.documentElement.head.querySelectorAll('link[rel="alternate"]');
    links.forEach((link) => {
      this.renderer2.removeChild(this.documentElement.head, link);
    });
  }

}
