import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UtilsModule } from '@app/shared/utils/utils.module';
import { SfSectionVideoComponent } from './components/section-video.component';
import { SfVideoModule } from '../video/video.module';
import { SfUtilsModule } from '../../core/utils/utils.module';

@NgModule({
    imports: [
        CommonModule,
        SfVideoModule,
        SfUtilsModule,
        UtilsModule,
    ],
  declarations: [SfSectionVideoComponent],
  exports: [SfSectionVideoComponent]
})
export class SfSectionVideoModule { }
