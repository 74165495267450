import {
  SfMediaImage, SfSection, SfBanner
} from '@app/sf-lib/public_api';
import { SomPage } from './page.model';

export class SomFieldToPlatePage extends SomPage implements SfBanner {

  nid: number;

  /** Type from server */
  type: string;

  /** Title */
  title: string;

  /** Path alias information */
  path: {
    alias: string;
  };

  /** Main image of the article */
  cover: SfMediaImage;

  /** Main image of the article for mobile */
  smallcover: SfMediaImage;

  sections: SfSection[];

  /** SfBanner uppertitle */
  uppertitle: string;

  /** SfBanner background */
  get background(): SfMediaImage {
    return this.cover;
  }

  /** SfBanner smallbackground */
  get smallbackground(): SfMediaImage {
    return this.smallcover;
  }

  /**
   * Custom function used by JSON.stringify
   *
   * @param {string} nm Name of the property
   * @returns {string}
   * @memberof SomFieldToPlatePage
   */
  public toJSON(nm: string) {
    const newArticle = {
      nid: this.nid,
      type: this.type,
      title: this.title,
      path: this.path,
      cover: this.cover,
      sections: this.sections,
      uppertitle: this.uppertitle,
      background: this.background,

      lang: this.lang,
      content: this.content,
      meta_title: this.meta_title,
      meta_description: this.meta_description,
      meta_keywords: this.meta_keywords,
    };
    return newArticle;
  }
}
