import { SfModel } from '../../../core/networking/models/model';
import { SfLink } from './link.interface';

export class SfSimpleLink extends SfModel implements SfLink {

  /** page url may be external or internal */
  url: string;

  /** Whether url is external or not */
  is_external?: boolean;

  /** Label displayable on a button for exemple */
  label?: string;

  /**
   * Parameters of the link (i.e: '/:id')
   * @fixme Shouldn't exist. Params should be handled in the url directly
   */
  param?: any;
  extras?: any;
}
