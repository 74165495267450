import { SfFormField } from './form-field.interface';

export interface SfFormSection {

  /** Title */
  title: string;

  /** Description of this section */
  description: string;

  /** Fields of a form */
  formFields: SfFormField[];
}
