import { SfFile } from '../../../core/models/file.model';

export interface SfMediaImage {

  /** Image url */
  thumbnail: SfFile;

  /** alt attribute */
  alt_image?: string;

  /** Main image */
  image: SfFile;
}

export function isSfMediaImage(object: Object): object is SfMediaImage {
  return 'image' in object && 'thumbnail' in object;
}
