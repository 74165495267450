import { SfSimpleSectionVideo } from '@app/sf-lib/public_api';

export class SomSectionVideo extends SfSimpleSectionVideo {

  public position?: string;

  constructor() {
    super();
    this.position = 'center';
  }
}
