import { Injectable, Inject, PLATFORM_ID, makeStateKey, TransferState } from '@angular/core';
import * as shortid from 'shortid';

import {
  SfNetworkingService,
  SfLocalizeService,
  SfItem,
  SfSimpleMediaImage,
  SfSimpleMediaVideo,
  SfSimpleLink,
  SITE_INFO,
  SfSiteInfo,
  SfTag
} from '@app/sf-lib/public_api';
import { PrediggoAutoComplete } from '../model/prediggo-auto-complete.model';
import { PrediggoAutoCompleteResponse } from '../model/prediggo-auto-complete-response.model';
import { environment } from '@env/environment';
import { Observable, forkJoin, of } from 'rxjs';
import { PrediggoSimplePageContent } from '../model/prediggo-simple-page-content.model';
import { PrediggoSimplePageContentResponse } from '../model/prediggo-simple-page-content-response.model';
import { PrediggoItemsInfo } from '../model/prediggo-items-info.model';
import { PrediggoItemsInfoResponse } from '../model/prediggo-items-info-response.model';
import { PrediggoAdvanced } from '../model/prediggo-advanced.model';
import { PrediggoAttributeInfo } from '../model/prediggo-attribute-info.model';
import { PrediggoItem } from '../model/prediggo-item.model';
import { map, concatAll, toArray, catchError, tap } from 'rxjs/operators';
import { SomRecipePage, isSomRecipePage } from '@app/core/models/recipe-page.model';
import { SomProductPage } from '@app/core/models/product-page.model';
import { SomProductItem } from '@app/core/models/product-item.model';
import { isPlatformBrowser } from '@angular/common';
import { transliterate as slugify } from 'transliteration';
import { productLink } from '@assets/link/product-link';
import { PrediggoProductAdapter } from '../adapters/product-adapter';
import { TranslateService } from '@ngx-translate/core';

const charsMapConvert = require('@app/core/charsMap');

@Injectable({
  providedIn: 'root'
})
export class PrediggoService {

  private readonly PREDIGGO_AUTOCOMPLETE = '/autoComplete';
  private readonly PREDIGGO_SIMPLE_PAGE_CONTENT = '/simplePageContent';
  private readonly PREDIGGO_ITEMS_INFO = '/itemsInfo';

  get prediggoLangcode(): string {
    if (!this.siteInfo || !this.siteInfo.lang) {
      return 'fr';
    }
    
    if (!this.siteInfo.lang.default && this.localize.currentLang) {
      if (this.localize.currentLang.country === 'be') {
        return this.localize.currentLang.code === 'fr' ? 'fb' : 'nb';
      }
      return this.localize.currentLang.code.toLowerCase();
    } 

    return this.siteInfo.lang.country === 'com' ? 'en' : this.siteInfo.lang.code.toLowerCase();
  }

  get prediggoZone(): string {
    if (!this.siteInfo || !this.siteInfo.lang) {
      return 'fr_FR';
    } 
    if (!this.siteInfo.lang.default && this.localize.currentLang) {
      return `${this.siteInfo.lang.code.toLowerCase()}_${this.localize.currentLang.country.toUpperCase()}`;
    } 
    return this.siteInfo.lang.country === 'com' ? 'en_EN' : `${this.siteInfo.lang.code.toLowerCase()}_${this.siteInfo.lang.country.toUpperCase()}`;
    
  }

  constructor(
    private network: SfNetworkingService,
    private localize: SfLocalizeService,
    private translate: TranslateService,
    private transferState: TransferState,
    @Inject(SITE_INFO) private siteInfo: SfSiteInfo,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  public autoComplete(query: PrediggoAutoComplete | string): Observable<PrediggoAutoCompleteResponse> {
    if (typeof query === 'string') {
      const value = query;
      query = PrediggoAutoComplete.create();
      query.query = value;
    }

    query.sessionId = this._getSessionId()
    query.languageCode = this.prediggoLangcode; // this.localize.currentLang.code;
    query.advanced = query.advanced || new PrediggoAdvanced();
    query.region = `${this.siteInfo.lang.code.toLowerCase()}_${this.siteInfo.lang.country.toUpperCase()}`;
    query.moduleVersion = '3.0';

    if (isPlatformBrowser(this.platformId)) { query.advanced.referer = document.referrer; }

    return this.network.post(
      `${environment.prediggoApiUrl}${this.PREDIGGO_AUTOCOMPLETE}`,
      query
    ) as Observable<PrediggoAutoCompleteResponse>;
  }

  private _getSessionId(): string {
    const generateId = shortid.generate()
    if (isPlatformBrowser(this.platformId)) {   
      const id = localStorage.getItem('PREDIGGO_SESSION_ID');
      if (id) {
        return id
      }
      localStorage.setItem('PREDIGGO_SESSION_ID', generateId);
    }
    return generateId
  }

  public simplePageContent(query: PrediggoSimplePageContent | number): Observable<PrediggoSimplePageContentResponse> {
    if (typeof query === 'number') {
      const value = query;
      query = PrediggoSimplePageContent.create();
      query.pageId = value;
    }
    query.sessionId = this._getSessionId()
    query.languageCode = this.prediggoLangcode; // this.localize.currentLang.code;
    query.advanced = query.advanced || new PrediggoAdvanced();
    query.region = `${this.siteInfo.lang.code.toLowerCase()}_${this.siteInfo.lang.country.toUpperCase()}`;
    query.moduleVersion = '3.0';
    if (isPlatformBrowser(this.platformId)) { query.advanced.referer = document.referrer; }

    return this.network.post(
      `${environment.prediggoApiUrl}${this.PREDIGGO_SIMPLE_PAGE_CONTENT}`,
      query
    ) as Observable<PrediggoSimplePageContentResponse>;
  }

  public itemsInfo(query: PrediggoItemsInfo | string[]): Observable<PrediggoItemsInfoResponse> {
    if (Array.isArray(query)) {
      const values = [].concat(query);
      query = PrediggoItemsInfo.create();
      query.skus = values;
    }

    query.sessionId = this._getSessionId()
    query.languageCode = this.prediggoLangcode; // this.localize.currentLang.code;
    query.advanced = query.advanced || new PrediggoAdvanced();
    query.region = `${this.siteInfo.lang.code.toLowerCase()}_${this.siteInfo.lang.country.toUpperCase()}`;
    query.moduleVersion = '3.0';

    const stateKey = makeStateKey<PrediggoItemsInfoResponse>(`prediggo_items_${query.skus.join('_')}`);
    const cachedData = this.transferState.get(stateKey, null);

    if (cachedData) {
      return of(cachedData);
    }

    return this.network.post(`${environment.prediggoApiUrl}${this.PREDIGGO_ITEMS_INFO}`, query).pipe(
      tap((response: PrediggoItemsInfoResponse) => {
        this.transferState.set(stateKey, response); 
      })
    )
  }


  /**
   * Takes an array of incomplete SfItem (may be composed of articles and recipes)
   * and returns a fully formated array of SfItem
   * @memberof PrediggoService
   */
  public consolidateItems(items: SfItem[]): Observable<SfItem[]> {
    const itemsObservable: Observable<SfItem[]> = forkJoin(
      items.map(item => {
        if (isSomRecipePage(item)) {
          return this.itemsInfo([item.recipeid]).pipe(
            // If an error occurs, ignore it
            catchError(() => of(void 0)),
            map((res: PrediggoItemsInfoResponse) => {
              if (res && res.items && res.items[item.recipeid]) {
                this.prediggoItemToRecipePage(res.items[item.recipeid], item);
                return item;
              } else {
                return item;
              }
            })
          );
        } else {
          return of(item);
        }
      })
    ).pipe(concatAll(), toArray()) as Observable<SfItem[]>;

    return itemsObservable;
  }

  /**
   * Converts a PrediggoItem to a SfRecipePage
   * @memberof PrediggoService
   */
  public prediggoItemToRecipePage(item: PrediggoItem, previousItem?: SomRecipePage): SomRecipePage {
    if (!item) { return void 0; }
    const recipe: SomRecipePage = previousItem || new SomRecipePage();
    recipe.id = item.sku;
    recipe.recipeid = item.sku;
    recipe.trackingCode = item.trackingCode;
    if (Array.isArray(item.attributeInfo)) {
      item.attributeInfo.forEach(
        (attribute: PrediggoAttributeInfo) => {
          if (attribute.vals && attribute.vals.length > 1) {
            recipe[attribute.attributeName] = attribute.vals.map(val => val.label);
          } else if (attribute.vals && attribute.vals.length > 0) {
            recipe[attribute.attributeName] = attribute.vals[0].label;
          }
        }
      );
    }

    for (let i = 1; i < 9; i++) {
      if (recipe[`recipeStep${i}`]) {
        recipe.recipeStep.push(recipe[`recipeStep${i}`]);
      } else { break; }
    }
    this.inflate(recipe);
    return recipe;
  }


  /**
   * Converts a PrediggoItem to a SfProductItem
   * Use over prediggoItemToProductPage for performance
   * @memberof PrediggoService
   */
  public prediggoItemToProductItem(item: PrediggoItem): SomProductItem {
    if (!item || !item.attributeInfo) { return; }

    const productItem: SomProductItem = new SomProductItem();
    const techno = item.attributeInfo.find((attr) => attr.attributeName === 'technologie');
    productItem.id = item.sku;
    productItem.trackingCode = item.trackingCode;
    productItem.tag = new SfTag();
    if (techno) productItem.tag.name = techno.vals[0].label;

    let index = 0;

    if (item.groupingInfo && item.groupingInfo.childrenSkus && item.groupingInfo.referenceItemSku) {
      index = item.groupingInfo.childrenSkus.findIndex(i => i === item.groupingInfo.referenceItemSku);
      productItem.id = item.groupingInfo.referenceItemSku;
    }

    let productName = '';
    let productZoomPicture = '';

    const countriesForZoomPicture = [
      'al', 'ba', 'be', 'bg', 'de', 'it', 'rs', 'at', 'sk', 'dk', 'hr', 'fi', 'no', 
      'se', 'lv', 'lt', 'ee', 'cz', 'nl', 'pl', 'hu', 'es', 'pt', 'fr', 'gr', 'ro'
    ];

    const countriesForImageUrl = [
      'al', 'ba', 'be', 'bg', 'de', 'it', 'rs', 'sk', 'at', 'cz', 'dk', 'fi', 'hr', 
      'lv', 'lt', 'ee', 'no', 'se', 'nl', 'pl', 'hu', 'es', 'pt', 'fr', 'gr', 'ro'
    ];

    const countriesForMainPicture = [
      'be','ba','bg','de','it','sk','at','cz','dk','fi','hr','lv','lt','ee','no','se',
      'nl','pl','hu','es','pt','fr','gr','ro'
    ]

    item.attributeInfo.forEach(
      (attribute: PrediggoAttributeInfo) => {
        switch (attribute.attributeName) {
          case 'name':
          case 'productName':
            productName = this._getAttributeLabel(attribute, index)
            break;
          case 'productZoomPicture':
            if (this.siteInfo && !countriesForZoomPicture.includes(this.siteInfo.lang.country)) {
              productZoomPicture = this._getAttributeLabel(attribute, index);
            }
            break;
          case 'imageUrl':
            if (this.siteInfo && countriesForImageUrl.includes(this.siteInfo.lang.country)) {
                if (Array.isArray(attribute.vals)) {
                  const parentPicture = item.groupingInfo ?
                    attribute.vals.find(search => {
                      return search.properties &&
                        search.properties.itemId &&
                        search.properties.itemId[0] === item.groupingInfo.referenceItemSku;
                    }) :
                    null;
                  productZoomPicture = parentPicture ?
                    parentPicture.label :
                    attribute.vals[0].label;
                }
              }
              break;
          case 'productMainPicture':
            if (this.siteInfo && countriesForMainPicture.includes(this.siteInfo.lang.country)) {
              if (Array.isArray(attribute.vals)) {
                const mySkuPicture =  productItem.id + '_380';
                for (let i = 0; i < attribute.vals.length; i++) {
                  if (mySkuPicture === attribute.vals[i].value) {
                    index = i;
                  }
                }
              }
              productZoomPicture = attribute.vals[index] ? attribute.vals[index].label : attribute.vals[1] ? attribute.vals[1].label
              : attribute.vals[0] ? attribute.vals[0].label : '';
            }
            break;
          default:
            break;
        }
      }
    );
    

    // Title
    productItem.title = productName.replace(new RegExp(String.fromCharCode(160), 'g'), ' ');

    // Cover
    const Zpict = new SfSimpleMediaImage();
    Zpict.sfType = 'SfMediaImage';
    Zpict.alt_image = productName;
    if (typeof productZoomPicture === 'string') {
      Zpict.image.uri.original = productZoomPicture;
    }
    productItem.cover = Zpict;

    // Link
    const link = new SfSimpleLink();
    let alias = `${productItem.id}`;
    let slugProduct;
    if (productName) {
      slugProduct = this.localize.currentLang.code === 'he' ? slugify(productName) : productName;
      alias = encodeURI(charsMapConvert(slugProduct));
    }
    link.is_external = false;
    link.label = '';
    const tradLink = productLink.find(l => l.lang === `${this.siteInfo.lang.country}_${this.siteInfo.lang.code}`)
    if (productItem.trackingCode) {
      link.url = `${tradLink.link}/${alias}/${productItem.id}`;
      link.extras = { id: productItem.trackingCode };
    } else {
      link.url = `${tradLink.link}/${alias}/${productItem.id}`;
    }
    productItem.link = link;

    return productItem;
  }

  /**
   * Converts a PrediggoItem to a SfProductPage
   * @memberof PrediggoService
   */
  public prediggoItemToProductPage(item: PrediggoItem): SomProductPage[] {
    if (!item || !item.groupingInfo) {
      return undefined;
    }
    const currentLang = this.translate.currentLang;
    const adapter = new PrediggoProductAdapter(item, currentLang);
    const products: SomProductPage[] = adapter.adapt();

    return products;
    
  }


  private _getAttributeLabel(attribute, index) {
    return attribute.vals[index] ? attribute.vals[index].label
      : attribute.vals[0] ? attribute.vals[0].label : '';
  }

  /**
   * Generic method to inflate an object by generating wrapper objects when needed.
   * Inflates SfMediaImage and SfMediaVideo
   * @memberof PrediggoService
   */
  private inflate(src: SomRecipePage | SomProductPage) {
    if (!src) { return src; }

    if (isSomRecipePage(src)) {
      // Recipe

      // Cook tip picture
      const CTimg = new SfSimpleMediaImage();
      CTimg.sfType = 'SfMediaImage';
      CTimg.alt_image = src['recipeCookTipTitle'] || '';
      if (typeof src['recipeCookTipPicture'] === 'string') {
        CTimg.image.uri.original = src['recipeCookTipPicture'] as any;
      }
      src['recipeCookTipPicture'] = CTimg;

      // Main picture
      const Mimg = new SfSimpleMediaImage();
      Mimg.sfType = 'SfMediaImage';
      Mimg.alt_image = src['recipeTitle'] || '';
      const img = src['imageUrl'] ? src['imageUrl'] : src['recipeMainPicture'];
      if (typeof img === 'string') {
        Mimg.image.uri.original = img as any;
      }
      src['recipeMainPicture'] = Mimg;

      // Main picture
      const Uimg = new SfSimpleMediaImage();
      Uimg.sfType = 'SfMediaImage';
      Uimg.alt_image = src['recipeTitle'] || '';
      const newImg = src['imageUrl']
      if (typeof newImg === 'string') {
        Uimg.image.uri.original = img as any;
      }
      src['imageUrl'] = Uimg;

      // Extra picture
      const Eimg = new SfSimpleMediaImage();
      Eimg.sfType = 'SfMediaImage';
      Eimg.alt_image = src['recipeTitle'] || '';
      if (typeof src['recipeExtraPicture'] === 'string') {
        Eimg.image.uri.original = src['recipeExtraPicture'] as any;
      }
      src['recipeExtraPicture'] = Eimg;

      // Cook tip video
      const CTvideo = new SfSimpleMediaVideo();
      CTvideo.sfType = 'SfMediaVideo';
      if (typeof src['recipeCookTipVideo'] === 'string') {
        CTvideo.video = src['recipeCookTipVideo'] as any ;
      }
      src['recipeCookTipVideo'] = CTvideo;

      // Cook tip video
      const Yvideo = new SfSimpleMediaVideo();
      Yvideo.sfType = 'SfMediaVideo';
      if (typeof src['recipeYoutubeCode'] === 'string') {
        Yvideo.video = src['recipeYoutubeCode'] as any;
      }
      src['recipeYoutubeCode'] = Yvideo;

      const IRimage = new SfSimpleMediaImage();
      IRimage.sfType = 'SfMediaImage';
      IRimage.alt_image = src['recipeTitle'] || '';
      if (typeof src['recipeIngredientPicture'] === 'string') {
        IRimage.image.uri.original = src['recipeIngredientPicture'];
        src['recipeIngredientPicture'] = [IRimage] as any;
      } else if (src['recipeIngredientPicture']
      && typeof src['recipeIngredientPicture'] === 'object'
      && Array.isArray(['recipeIngredientPicture'])) {
        const tRecipeIngredientPicture: SfSimpleMediaImage[] = [];
        src['recipeIngredientPicture'].forEach(
          (picture) => {
            const ingredientImg = new SfSimpleMediaImage();
            ingredientImg.sfType = 'SfMediaImage';
            ingredientImg.alt_image = src['recipeTitle'] || '';
            ingredientImg.image.uri.original = picture as any;
            tRecipeIngredientPicture.push(ingredientImg);
          }
        );
        if (tRecipeIngredientPicture) {
          src['recipeIngredientPicture'] = tRecipeIngredientPicture;
        }
      }
    } else {
      // Product

      // Main picture
      const Mimg = new SfSimpleMediaImage();
      Mimg.sfType = 'SfMediaImage';
      Mimg.alt_image = src['name'] || '';
      if (typeof src['productMainPicture'] === 'string') {
        Mimg.image.uri.original = src['productMainPicture'] as any;
      }
      src['productMainPicture'] = Mimg;

      // News Picture
      if (src['webNewsPicture']) {
        const Zpict = new SfSimpleMediaImage();
        Zpict.sfType = 'SfMediaImage';
        Zpict.alt_image = src['name'] || '';
        if (typeof src['webNewsPicture'] === 'string') {
          Zpict.image.uri.original = src['webNewsPicture'] as any;
        }
        src['webNewsPicture'] = Zpict;
      }
      // News Video
      if (src['webNewsVideo']) {
        const Zvideo = new SfSimpleMediaVideo();
        Zvideo.sfType = 'SfMediaVideo';
        if (typeof src['webNewsVideo'] === 'string') {
          Zvideo.video = src['webNewsVideo'] as any;
        }
        src['webNewsVideo'] = Zvideo;
      }

      // Zoom picture
      const Zimg = new SfSimpleMediaImage();
      Zimg.sfType = 'SfMediaImage';
      Zimg.alt_image = src['productName'] || '';
      if (typeof src['productZoomPicture'] === 'string') {
        Zimg.image.uri.original = src['productZoomPicture'] as any;
      }
      src['productZoomPicture'] = Zimg;

    }
  }
}
