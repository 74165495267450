/* tslint:disable */export const bg_bg = {
  '404': {
    TITLE: 'Упс...',
    PAGENOTFOUND: 'Страницата не съществува',
    BUTTON: 'Обратно',
    RECIPES: 'Открийте рецептите ни'
  },
  HOME: {
    SHOWCASE: 'Готварски рецепти',
    CATCH_PHRASE: '',
    SHOWCASE_SEE_ALL: 'Виж всички рецепти',
    MAGAZINE: { TITLE: 'Нагоре', BUTTON: 'Открийте Légumiz' },
    OUR_VEGETABLES: 'Нашите зеленчуци',
    SOCIAL_WALL: 'Заедно да споделим страстта си към растенията',
    FAQ_TITLE: 'Вашите въпроси',
    FAQ_SEE_MORE: 'Виж повече',
    FLOWBOX: 'Споделете вашите гурме моменти с #mybonduelle'
  },
  SELECT: { DEFAULT_VALUE: 'Bonduelle представя' },
  MENU: {
    CONTACT_US: 'Свържете се с нас',
    MY_ACCOUNT: 'Моят потребителски профил',
    GOOD_DEALS: 'Оферти',
    NEWSLETTER: 'Бюлетин на Bonduelle'
  },
  FOOTER: {
    LINK: {
      BONDUELLE: 'Bonduelle.com',
      CREDITS: 'Благодарности',
      LEGAL_NOTICE: 'Правна информация',
      TERMS_OF_SALES: 'Условия за продажба',
      COOKIES: 'Бисквитки',
      SITEMAP: 'Карта на сайта',
      PRIVACY_POLICY: 'Политика за поверителност',
      ALL_RIGHTS_RESERVED: '2018 © Всички права запазени'
    },
    HISTORY: {
      TITLE: 'Bonduelle, от 1853 насам',
      TEXT: 'Bonduelle е семейна фирма, работеща в продължение на 7 поколения, за да развие земеделско производство, което се отнася с уважение към земята и хората. Ние подкрепяме ефективната, интелигентна и единна агроекология, постоянно гледаме напред и иновативно, от полето до чинията, за да създадем по-добро бъдеще чрез храни на растителна основа.'
    },
    NEWSLETTER: {
      TITLE: 'Общността Bonduelle',
      TEXT: 'Съгласявам се да получавам бюлетина « Всичко за зеленчуците от Bonduelle » (новини, промоции, конкурси, статии ...)',
      PLACEHOLDER: 'Вашият имейл',
      GDPR_LINK: 'Още за съхранението на данните Ви'
    }
  },
  PRODUCTS: 'Продукти',
  MAGAZINE: {
    HOME: 'Добре дошли в списанието',
    CATCH_PHRASE: 'научете новините за зеленчуците с Bonduelle!',
    MENU: { BONDUELLE_LINK: 'Заповядайте на bonduelle.bg' },
    SHOWCASE: 'Витрина',
    PREVIOUS_ARTICLES: 'Предишни статии',
    PREVIOUS_ARTICLES_SEE_ALL: 'Вижте всичките статии',
    PREVIOUS_EDITIONS: 'Нашите предишни издания',
    PREVIOUS_EDITIONS_SEE_ALL: 'Вижте повече издания',
    PREVIOUS_ARTICLES_BY_CATEGORY: 'Предишни издания по категория',
    RUBRIC: {
      SEE_ALL: 'Вижте всички рецепти',
      SEE_ALL_ARTICLES: 'Вижте всички статии',
      ON: 'статии за'
    },
    RUBRIC_COOK: { ON: 'рецепти за' },
    ARTICLE: { JOIN_US: 'Присъединете се', READ_ALSO: 'Прочетете също' },
    RECIPE: {
      INGREDIENTS: 'Съставки',
      INGREDIENTS_LABEL_DESC: 'за {{value}} човек/души',
      INGREDIENTS_LABEL_ONE_DESC: 'за {{value}} човек/души',
      INGREDIENTS_LABEL: 'Съставки за {{value}}човек/души',
      INGREDIENTS_LABEL_ONE: 'Съставки за {{value}} човек/души',
      PEOPLES: '{{value}} човек/души',
      PEOPLE: '{{value}} човек/души',
      DIFFICULTY: { EASY: 'Лесна', MEDIUM: 'Средна', HARD: 'Трудна' },
      COST: { CHEAP: 'Евтина', EXPENSIVE: 'Скъпа' },
      TIMING: { MIN: 'мин', HOURS: 'часове' },
      STEP: 'Стъпка',
      DISCOVER_ALSO: 'Открийте също...'
    }
  },
  ARTICLE: { DISCOVER_ALSO: 'Открийте също ...', INGREDIENTS: 'Съставки' },
  SF: {
    SLIDE: { DISCOVER: 'Открийте' },
    SEARCH: { PLACEHOLDER: 'Търсене', ALL: 'Всички' },
    NEWSLETTER: {
      BTN_LABEL: 'Регистрация',
      PLACEHOLDER: 'Вашият имейл',
      ERROR_MESSAGE: 'Невалиден имейл',
      ALREADY_SUBSCRIBED: 'Вече сте абонирани',
      GDPR: {
        TXT: 'Още информация за съхранението на данните Ви',
        LINK: '/cookies#gdpr'
      }
    },
    MONTHLY_FOLDER: { TITLE: 'Месечни новини' },
    MINI_NEWSLETTER: {
      PLACEHOLDER: 'Вашият имейл',
      GDPR: {
        TXT: 'Още информация за съхранението на данните Ви',
        LINK: '/cookies#gdpr'
      }
    },
    SOCIALITEM: {
      SHARE: 'споделете',
      COMMENT: 'коментирайте',
      BONDUELLE: 'Bonduelle'
    },
    SEARCHRECIPE: { DISCOVER_ALL: 'Търсете във всички рецепти!' },
    FORM: {
      ERROR: 'Невалидно поле',
      PHOTO: {
        ADD: 'Добавете снимка',
        DELETE: 'Изтрийте снимката',
        SEE: 'Вижте'
      }
    }
  },
  CORPORATE: {
    COMPANY_STORY: 'история на компанията',
    CSR_COMMITMENT: 'Корпоративна социална отговорност',
    VEGETABLE_INNOVATIONS: 'зеленчукови иновации',
    NEWS: 'всички новини',
    SEE_MORE: 'Вижте още статии',
    ON: 'статии за'
  },
  NEWSLETTER: {
    TITLE: 'Абонирайте се за бюлетина',
    SUBTITLE: 'Добри оферти, кулинарно вдъхновение и малко култура!'
  },
  ROUTES: {
    products: 'produkti',
    news: 'novini',
    'since-1853': 'ot-1853-nasam',
    'field-plate': 'ot-poleto-do-chiniiata',
    'company-story': 'istoriia-na-kompaniiata',
    'csr-commitments': 'korporativna-sotsialna-otgovornost',
    'vegetal-innovations': 'zelenchukovi-inovatsii',
    magazine: 'spisanie',
    all: 'vsichki',
    article: 'statiia',
    cook: 'gotvi',
    cultivate: 'otglezhdai',
    explore: 'otkrivai',
    recipe: 'izchakaite-retsepta',
    recipes: 'retsepti',
    'recipes-home': 'domashni-retsepti',
    r: 'r',
    credits: 'krediti-kom',
    'legal-notice': 'pravna-informatsiia',
    'terms-of-sales': 'pravna-informatsiia',
    cookies: 'biskvitki',
    sitemap: 'karta-na-saita',
    'privacy-policy': 'politika-na-poveritelnost',
    'all-rights-reserved': 'politika-na-poveritelnost',
    search: 'tiersene',
    'articles-search': 'statiia',
    vegetables: 'zelenchutsi',
    p: 'p',
    contact: 'kontakti',
    'info-product': 'informatsiia-za-produkta',
    'note-product': 'belezhka-za-produkta',
    suggestion: 'predlozhenie',
    'other-request': 'informatsiia-za-produkta',
    faq: 'chesto-zadavani-vieprosi',
    'all-themes': 'vsichki-temi',
    'products-search': 'tiersene-na-produkti',
    recipesearch: 'tiersene-na-retsepti',
    'product-news': 'novini-za-produkti',
    'good-deals': 'oferti',
    'not-found': 'ne-e-namereno',
    'menu-page': 'vdiekhnovenie',
    'menu-one': 'zhiveite-zdravi',
    'menu-two': 'gotvi-se-vkusno',
    'menu-three': 'gotvi-sies-stil',
    'menu-four': 'biedi-kreativen',
    'menu-external': 'vienshno-meniu',
    'theme-pasta-rice': '',
    'theme-bowls': '',
    'theme-soup': '',
    'theme-snack': '',
    'theme-sauce': '',
    'theme-unique-plate': '',
    'theme-over': 'tema',
    'theme-legumin': 'bobovi',
    'theme-ingredients-recipes': '',
    'theme-cook-plate': ''
  },
  BREAD: {
    products: 'produkti',
    news: 'novini',
    'since-1853': 'ot-1853-nasam',
    'field-plate': 'ot-poleto-do-chiniiata',
    'company-story': 'istoriia-na-kompaniiata',
    'csr-commitments': 'Korporativna-sotsialna-otgovornost',
    'vegetal-innovations': 'zelenchukovi-inovatsii',
    magazine: 'spisanie',
    all: 'vsichki',
    article: 'statiia',
    cook: 'gotvi',
    cultivate: 'otglezhdai',
    explore: 'otkrivai',
    recipe: 'izchakaite-retsepta',
    recipes: 'retsepti',
    'recipes-home': 'domashni-retsepti',
    'articles-search': 'statiia',
    search: 'tiersene',
    vegetables: 'zelenchutsi',
    contact: 'kontakti',
    'info-product': 'informatsiia-za-produkta',
    'note-product': 'belezhka-za-produkta',
    suggestion: 'predlozhenie',
    'other-request': 'informatsiia-za-produkta',
    faq: 'chesto-zadavani-vieprosi',
    'all-themes': 'vsichki-temi',
    'products-search': 'tiersene-na-produkti',
    recipesearch: 'tiersene-na-retsepti',
    'product-news': 'novini-za-produkti',
    'good-deals': 'oferti',
    'not-found': 'ne-e-namereno',
    'menu-page': 'Vdiekhnovenie',
    'menu-one': 'Zhiveite Zdravi',
    'menu-two': 'Gotvi Se Vkusno',
    'menu-three': 'Gotvi Sies Stil',
    'menu-four': 'Biedi Kreativen'
  },
  MENU_PAGE: {
    TITLE1: 'ЖИВЕЙТЕ ЗДРАВОСЛОВНО',
    TITLE2: 'ГОТВЕТЕ ВКУСНО',
    TITLE3: 'ПРИГОТВЯЙТЕ СЪС СТИЛ',
    TITLE4: 'БЪДЕТЕ КРЕАТИВНИ',
    EXTERNAL: ''
  },
  TECHNICAL: { MENU_PAGE: 'оферти за работа' },
  SEO: {
    TITLE: {
      MENU_ONE: 'ЖИВЕЙТЕ ЗДРАВОСЛОВНО',
      MENU_TWO: 'ГОТВЕТЕ ВКУСНО',
      MENU_THREE: 'ПРИГОТВЯЙТЕ СЪС СТИЛ',
      MENU_FOUR: 'БЪДЕТЕ КРЕАТИВНИ',
      HOME: 'Bonduelle',
      SEARCH: {
        MAIN: '{{value}}',
        RECIPES: '{{value}}',
        VEGETABLES: '{{value}}',
        ARTICLES: '{{value}} Резултати в “Статии” | Bonduelle'
      },
      CONTACT: 'Bonduelle',
      RECIPES: 'Bonduelle',
      RECIPE_HOME: 'Bonduelle',
      RECIPE: 'Bonduelle',
      PRODUCTS: 'Bonduelle',
      NEWS: 'Bonduelle',
      CORPORATE: {
        NEWS: 'Bonduelle',
        COMPANY_STORY: 'Bonduelle',
        CSR_COMMITMENTS: 'Bonduelle',
        VEGETAL_INNOVATIONS: 'Bonduelle',
        FIELD_PLATE: 'Bonduelle',
        ARTICLE: '{{value}}',
        VEGETABLE: '{{value}}'
      },
      PRODUCT: '{{value}}',
      MAGAZINE: {
        NEWS: 'Bonduelle',
        ARTICLE: 'Bonduelle',
        COOK: 'Bonduelle',
        GROW: 'Bonduelle',
        EXPLORE: 'Bonduelle',
        RECIPE: 'Bonduelle'
      },
      FAQ: 'Bonduelle',
      NOTFOUND: 'Bonduelle',
      RECIPES_ALL: '',
      RECIPETYPE: '{{value}}',
      RECIPESEASON: '{{value}}',
      RECIPEPREPARATION: '{{value}}',
      RECIPEDIFFICULTY: '{{value}}',
      RECIPEVEGETABLE: '{{value}}',
      RECIPECOST: '{{value}}',
      RECIPEDISH: '{{value}}',
      RECIPEDESC: '{{value}}',
      '2RECIPEFACETS': '',
      '4RECIPEFACETS': '{{value}}',
      PRODUCTSEASON: '{{value}}',
      PRODUCTTECHNO: '{{value}}',
      PRODUCTVEGETABLE: '{{value}}',
      PRODUCTRANGE: 'Продукти от гама {{value}}',
      THEME_ALL: '',
      THEME_STARTER: '',
      THEME_SALAD: '',
      THEME_CAN: '',
      THEME_FROZEN: '',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: '',
      THEME_RECIPE: '',
      THEME_PLATE: '',
      THEME_ACC: '',
      THEME_SNACK: '',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': '{{value}}'
    },
    DESCRIPTION: {
      MENU_ONE: 'Прочетете всичко за здравословния начин на живот, правилата за хранене, методите за приготвяне и консумирането на зеленчуци.',
      MENU_TWO: 'Открийте полезни кулинарни тайни за приготвяне на любимите Ви ястия и рецепти от Bonduelle.',
      MENU_THREE: 'Всичко за кулинарните тайни на световните кухни, разликите между традиционните ястия и стиловете на хранене.',
      MENU_FOUR: 'Прочетете съвети и статии за хранене от Bonduelle. Научете повече за ползите и приготвянето на здравословна храна.',
      HOME: '',
      SEARCH: {
        MAIN: '{{value}}',
        RECIPES: '{{value}}',
        VEGETABLES: '{{value}}',
        ARTICLES: 'Всички {{value}} в раздел “Статии”. Лесни и вкусни рецепти. Сготвени зеленчуци.'
      },
      CONTACT: '',
      RECIPES: '',
      RECIPE_HOME: '',
      RECIPE: '',
      PRODUCTS: '',
      NEWS: '',
      CORPORATE: {
        NEWS: '',
        COMPANY_STORY: '',
        CSR_COMMITMENTS: '',
        VEGETAL_INNOVATIONS: '',
        VEGETABLE: '',
        FIELD_PLATE: '',
        ARTICLE: ''
      },
      PRODUCT: '{{value}}',
      MAGAZINE: {
        NEWS: '',
        ARTICLE: '',
        COOK: '',
        GROW: '',
        EXPLORE: '',
        RECIPE: ''
      },
      FAQ: '',
      PAGE: 'Страницата',
      RECIPETYPE: '{{value}}',
      RECIPESEASON: '{{value}}',
      RECIPEPREPARATION: '{{value}}',
      RECIPEDIFFICULTY: '{{value}}',
      RECIPEVEGETABLE: '',
      RECIPECOST: '{{value}}',
      RECIPEDISH: '{{value}}',
      RECIPEDESC: '{{value}}',
      '2RECIPEFACETS': '{{value}}',
      '4RECIPEFACETS': '{{value}}',
      PRODUCTSEASON: '{{value}}',
      PRODUCTTECHNO: '{{value}}',
      PRODUCTVEGETABLE: '{{value}}',
      PRODUCTRANGE: 'Открийте всички продукти от гамата {{value}}',
      THEME_ALL: '',
      THEME_STARTER: '',
      THEME_SALAD: '',
      THEME_CAN: '',
      THEME_FROZEN: '',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: '',
      THEME_RECIPE: '',
      THEME_PLATE: '',
      THEME_ACC: '',
      THEME_SNACK: '',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': '{{value}}',
      NOTFOUND: ''
    },
    PIPE: { SOCIETY: '' },
    THEME_ALL: '',
    THEME_STARTER: '',
    THEME_SALAD: '',
    THEME_CAN: '',
    THEME_FROZEN: '',
    THEME_READY_MEALS: '',
    THEME_BIO: '',
    THEME_VEGETABLES: '',
    THEME_RECIPE: '',
    THEME_PLATE: '',
    THEME_ACC: '',
    THEME_SNACK: ''
  },
  VEGETABLE: {
    DISCOVER_VEGETABLE: 'Открийте списъците със зеленчуците ни',
    OURS_RECIPES: 'Нашите рецепти',
    DISCOVER_ALSO: 'Открийте също...',
    SEE_ALL: 'Вижте всички зеленчуци',
    ON: 'Зеленчуци на'
  },
  RECIPE: {
    'INGREDIENTS_LABEL ': 'Съставки за {{value}} човек/души',
    PEOPLES: '{{value}} души',
    DIFFICULTY: { EASY: 'Лесна', MEDIUM: 'Средна', HARD: 'Трудна' },
    COST: { CHEAP: 'Евтина', EXPENSIVE: 'Скъпа' },
    TIMING: { MIN: 'Мин', HOURS: 'часове' },
    STEP: 'Стъпка',
    DISCOVER_ALSO: 'Открийте също...',
    HOME_SEE_MORE: 'Вижте още рецепти',
    SHOWCASE: 'Нагоре',
    HOME_BOOK: 'Нашите рецепти',
    MIN_MORE: ' m и +',
    HOUR_MORE: 'h и +',
    FILTER_BY_CRITERIA: 'Филтрирайте по критерий',
    SORT_BY: 'Сортирайте по:',
    BEST_RATED: 'С най-висок рейтинг',
    MOST_CONSULTED: 'Най-много преглеждани',
    MEMBERS_RECIPES: 'Рецепти на членове',
    CRITERIA: 'Новини',
    DISCOVER: { RECIPES: 'Открийте рецептите ни' },
    DISCOVER_RECIPE: 'Открийте рецептата',
    INSTRUCTIONS: 'Инструкции',
    DEFAULT_TAG: 'Идея за рецепта',
    MOST_RECENT: 'Най-скорошни',
    RECIPE_VIDEOS: 'Видео рецепти',
    PRODUCT_ASSOCIATE: 'Рецепти с',
    BUDGET_LOW: 'Евтина',
    BUDGET_HIGH: 'Висок',
    BUDGET_MIDDLE: 'Средна',
    PRINT_RECIPE: 'принтирайте рецепта',
    LET_YOURSELF_SURPRISED: 'Изненадайте себе си',
    COOK_TIP_TITLE: 'Съвет за готвене, заглавие',
    ENJOY_MEAL: 'Да Ви е сладко!',
    FILTER: { LEGEND: 'Легенда' }
  },
  PREDIGGO: { DIFFICULTY: { EASY: 'Лесна', MEDIUM: 'средна', HARD: 'Трудна' } },
  COOKIES: {
    TEXT: 'Като влизате в този сайт, Вие се съгласявате с използването на бисквитки по технически причини, за целите на статистически анализи и управление на уеб трафика, споделяне на информация с рекламодатели на други уебсайтове, за да ви предоставим подходящи промоционални съобщения, които отразяват интересите Ви.',
    CLOSE: 'Затворете',
    MORE: 'Научете повече',
    AGREE_YOUTUBE: 'Трябва да приемете бисквитките на Youtube, за да видите този видеоклип',
    BUTTON_YOUTUBE: 'Приемете бисквитките'
  },
  POPUP: { LAUNCH: 'ОТВАРЯНЕ НА ИЗСКАЧАЩ ПРОЗОРЕЦ' },
  FIELD_PLATE: { DISCOVER_VEGETABLE: 'Открийте списъците със зеленчуците ни' },
  SEARCH: {
    OUR_RECIPES: 'Рецепти',
    OUR_PRODUCTS: 'Продукти',
    OUR_ARTICLES: 'Нашите статии',
    RECIPES: 'Рецепти',
    PRODUCTS: 'Продукти',
    ARTICLES: 'Статии',
    ALL: 'Всички',
    SORT_BY: 'Сортирай по',
    RESULTS: 'резултати',
    RESULT: 'резултат',
    PRODUCT_OUT: 'продукти от',
    APPLY: 'Приложи',
    FILTER: 'Филтър',
    RECIPE_OUT: 'рецепти от',
    NO_RESULT: 'Бяло зеле за това търсене ... Задавате ни подвеждащ въпрос',
    SEE_MORE: 'Виж повече',
    DISCOVER_RECIPES: 'Открийте рецептите ни',
    SEE_MORE_OF: 'вижте още от',
    DELETE: 'изтриване'
  },
  RECIPE_LIST: {
    OUR_RECIPES: 'Рецепти',
    RESULTS: 'Резултати',
    SEE_MORE: 'Вижте още рецепти'
  },
  PRODUCT: {
    BUTTON: {
      ACTU: 'новини за продукти',
      FOUND: 'Къде да намерите този продукт',
      DISCOVER: 'открийте цялата гама'
    },
    NUTRITIONAL_VALUE: 'хранителна стойност',
    CONDITIONING: 'здравословно',
    PORTIONS: 'порции',
    NET_WEIGHT: 'нето тегло',
    DRY_WEIGHT: 'сухо тегло',
    INGREDIENTS: 'съставки',
    OUR_RECIPES: 'Открийте рецептите ни',
    FOR: 'за',
    PER_PORTION: 'и по порция от',
    ENERGY: 'енергия',
    FAT: 'мазнини',
    SATURATE: 'наситени мазнини',
    GLUCIDES: 'въглехидрати',
    FIBER: 'фибри',
    PROTEINS: 'белтъчини',
    SALT: 'сол',
    DISCOVER_ALSO: 'открийте също ...',
    MORE_INFORMATION: 'още информация',
    TRACES: 'Следи от',
    CONTAINS: 'Съдържа',
    SLIDER: { SHEET: 'вижте списъка', BUY: 'купете този продукт' },
    NUTRISCORE: 'информация Nutri-Score',
    TRIMAN: 'информация Triman',
    AGRICONFIANCE: 'информация Agri Confiance',
    BIO: 'информация Bio',
    PESTICIDE_FREE: '',
    LEAF: '',
    LOCK: '',
    SEE_ALL: 'Вижте всички продукти',
    LEGISLATION: 'в съответствие с действащото законодателство.',
    PORTION: { SINGULAR: 'порция', PLURAL: 'порции' },
    DISCLAIMER: '',
    COOKING: 'готвене',
    CONSERVATION: 'консервиране'
  },
  FAQ: {
    ON: 'въпроси за',
    ALL: 'Всички',
    SEE_MORE: 'вижте още въпроси',
    TITLE_1: 'Всички отговори на Вашите въпроси',
    TITLE_2: 'Имате ли въпрос за конкретен продукт?',
    LINK_LABEL: 'Пишете ни'
  },
  FORM: {
    GO_TO_STEP: 'Отидете на стъпка',
    STEP: 'Стъпка',
    RECEIVE_EMAIL_COPY: 'Получаване на копие по имейл',
    SEND: 'Изпрати',
    GDPR: 'Още за съхранението на данните Ви',
    ERROR: 'Всички полета, отбелязани с *, трябва да бъдат попълнени и верни',
    SEND_ERROR: 'Възникна грешка, моля, опитайте отново след няколко минути',
    NOTICE: 'За правилното обработване на Вашите данни Bonduelle може, в зависимост от случая, да Ви поиска данни за здравето. Те не са задължителни и можете да не ги предоставяте.',
    SENDING: 'Изпращане на формуляра...',
    LOADING: 'Зареждане...',
    PRODUCT_INFORMATION_TITLE: 'Информация за продукта',
    PRODUCT_INFORMATION: {
      SEO_TITLE: 'Информация за продукта | Bonduelle',
      SEO_DESC: 'Свържете се с нашето обслужване на клиенти'
    },
    INFORMATIONS: 'Вашата информация',
    MISTER: 'Г-н',
    MISS: 'Г-жа',
    NAME: 'Име',
    OUR_NAME: 'Нашето име',
    FIRSTNAME: 'Име',
    OUR_FIRSTNAME: 'Нашето име',
    MAIL: 'Вашият имейл',
    MAIL_PLH: 'mail@address.com',
    MAIL_CONFIRM: 'потвърдете имейла си',
    MAIL_ERROR: 'моля въведете валиден имейл',
    MAIL_ERROR_CONFIRM: 'моля потвърдете имейла си',
    ADDRESS: 'Вашият адрес',
    STREET_PLH: '',
    STREET: 'Улица и номер',
    ADDRESS_COMPLEMENT: 'допълнителна информация за адреса Ви',
    ADDRESS_COMP_PLH: 'допълнителна информация за адреса Ви',
    ZIPCODE: 'пощенски код',
    CITY: 'град',
    CITY_PLH: 'Вашият град',
    PHONE: 'телефон',
    PHONE_PLH: 'телефонен номер',
    COUNTRY: 'държава',
    PRODUCT_INFORMATIONS: 'информация за продукта',
    PRODUCT_INFORMATION_DESC: 'За да могат нашите колеги да проучат най-добре заявката Ви, моля, предоставете следната информация:',
    PRODUCT: {
      DETAIL: {
        DESC: 'За всеки заявен критерий попълните полето на формуляра<br>и да добавете илюстративна снимка. Информация:<br>баркодът, номерът на партидата и срокът на годност на продукта трябва ясно да се разчитат на снимката.'
      },
      NOTE: {
        TITLE: 'Коментирайте продукта',
        SEO_TITLE: 'Коментари за продукта | Bonduelle',
        SEO_DESC: 'Искате ли да коментирате някой от нашите продукти? Свържете се с нашето обслужване на клиенти, за да ви помогнем. Персонализиран отговор в рамките на 48 часа'
      }
    },
    PRODUCT_RANGE: 'Продуктова гама',
    PRODUCT_NAME: 'Име на продукта',
    PRODUCT_NAME_PLH: 'Въведете името на продукта Bonduelle, за който се отнася заявката Ви.',
    PRODUCT_INFORMATIONS_DETAIL: 'Подробна информация',
    PRODUCT_INFORMATIONS_LABEL: 'Баркод на продукта',
    PRODUCT_INFORMATIONS_PLH: 'Баркодът е <br>13-цифрен номер, намиращ се върху<br> опаковката на продукта.',
    PRODUCT_LOT: 'Партиден номер на продукта',
    PRODUCT_LOT_INFO: 'Номерът на партидата се намира близо<br>до датата на годност<br>и се състои от няколко цифри и букви<br>на няколко реда/ Започва<br>винаги с буквата L, последвана<br>от няколко цифри и варира <br> в зависимост от продукта.<br><br>Например L72450492107:13.',
    PHOTO: 'Добавете снимка',
    EXPIRED_DATE: 'Дата на DLUO /DLC',
    EXPIRED_DATE_ERROR: 'Моля, въведете валидна дата във формат дд/мм/гггг',
    EXPIRED_DATE_DESC: 'Най-добър до (BBD) и Използвайте преди (UBD) са представени под формата на дати.',
    PHOTO_MORE: 'Допълнителни снимки',
    MESSAGE: 'Вашето съобщение*',
    OUR_MESSAGE: 'Напишете съобщение',
    SALES_OPERATIONS: {
      TITLE: 'Информация за търговската сделка',
      SEO_TITLE: 'Информация за текущи операции: конкурси... | Bonduelle',
      SEO_DESC: 'Свържете се с нашето обслужване на клиенти за информация относно текуща търговска операция. Персонализиран отговор в рамките на 48 часа'
    },
    PRESS_SERVICE: 'Свържете се с пресслужбата',
    FOOD_SERVICE: 'Свържете се с екипа на Bonduelle Food Service',
    BUSINESS_INFORMATION: 'Получаване на мостри/ваучери/промоционални артикули',
    MODAL_INFORMATION: '',
    JOB_OFFER: { TITLE: '', SEO_TITLE: '', SEO_DESC: '' },
    WEB_SUGGESTION: {
      TITLE: 'Направете предложение за уебсайта',
      SEO_TITLE: 'Съобщение за подобрение, предложение за сайта | Bonduelle',
      SEO_DESC: 'Моля, свържете се с нашето обслужване на клиенти, за да ни помогнете да подобрим сайта.'
    },
    ANOTHER: {
      TITLE: 'Направете друго запитване',
      SEO_TITLE: 'Контакт за всяка друга заявка, услуга | Bonduelle',
      SEO_DESC: 'Свържете се с нашия отдел за обслужване на клиенти за информация, услуга или предложение. Персонализиран отговор в рамките на 48 часа'
    },
    AMBASSADOR: 'Станете посланик на Bonduelle',
    CATEGORY: {
      SALAD: 'Салата',
      FROZEN: 'замразен',
      CAN: 'консерва',
      TAKEAWAY: 'за вкъщи',
      VEGETABLE: ''
    }
  },
  CONTACT: {
    TITLE: 'На Ваше разположение',
    SPECIAL_INFO: '',
    TAKE_CARE: '',
    BY_MAIL: 'дистрибутор',
    BY_PHONE: '',
    BY_SOURDLINE: '',
    MAIL_TEXT: 'ВК Комерсиал Европродукт ООД\r\n' +
      'бул. Самоковско шосе 2Л\r\n' +
      'ТЦ БОИЛА, 1138 София\r\n' +
      'Тел. 02/ 973 11 17\r\n' +
      'marketing@vk-bg.com\r\n' +
      'https://www.vk-bg.com/vk/',
    PHONE_TEXT: '',
    SOURDLINE_TEXT: '',
    BY_DISTRIBUTOR: '',
    BY_DISTRIBUTOR_TEXT: '',
    SUBTITLE: 'Искате да се свържете с нас за :',
    AGREEMENT: '',
    READ_MORE: "Освен това имате право по всяко време да поискате BONDUELLE да упражни правата Ви за достъп, коригиране, изтриване и преносимост на Вашите лични данни, както и правата Ви да ограничите и възразите срещу обработването на вашите лични данни. За да защитите правата си, свързани с Вашите лични данни, моля, пишете на Dpo_france(at)bonduelle(dot)com или по пощата на следния адрес: на вниманието на длъжностното лице по защита на данните, Bonduelle, Rue Nicolas Appert, PO Box 30173, F- 59653 Villeneuve D'Ascq. За да научите повече за начина, по който BONDUELLE събира и използва Вашите лични данни, кликнете тук",
    READ_MORE_LABEL: 'Прочетете също',
    SATISFACTION_TEXT: 'С цел да се свържем с вас след коментар относно нашите продукти, за да можем да обработим вашата жалба и да ви изпратим въпросник за удовлетвореност, ако желаете, ще обработим предоставената информация в качеството си на администратор на данни. Правното основание за обработка на вашата заявка е съгласието, поради потенциално здравословни данни, които може да предоставите в заявката си. Тази информация ще се съхранява за времето, необходимо за обработка на вашата заявка и до 5 години, за да се предостави доказателство, че вашата заявка е била обработена, и за да се спазят нашите законови задължения. Правното основание за изпращане на въпросника за удовлетвореност също е вашето съгласие. Тази информация ще се съхранява за времето, необходимо за изпращане на въпросника за удовлетвореност и максимум до 1 година. За повече информация относно обработката на вашите лични данни, моля, консултирайте се с нашата <a href="https://www.bonduelle.bg/politika-za-poveritelnost" target="_blank" class="underline">Политика за поверителност</a>.',
    SATISFACTION_CHECKBOX: 'С поставянето на отметка в това поле, вие се съгласявате да получавате въпросник за удовлетвореност (състоящ се от един единствен въпрос) след като вашата заявка бъде обработена. Това е по желание.',
    CHECKBOX_TEXT: 'С поставянето на отметка в това поле, вие се съгласявате, че Bonduelle може да обработва личните здравни данни, които може да предоставите в съобщението си, единствено с цел да отговори на вашата заявка. <strong>Това е задължително, за да се обработи вашата заявка</strong>.',
    PHONE: 'От понеделник до петък от 9:00 до 18:00 часа на 00.33.09.70.25.22.22 (обаждането не се таксува, с изключение на почивните дни и официалните празници)',
    CALL_US: 'Обадете ни се',
    TO_WRITE: 'Пишете ни',
    PHONE_NUMBER: '3.3097E+11',
    SUCCESS_MESSAGE: 'Благодарим! Съобщението Ви е изпратено към екипа ни. Ще бъде обработено средно\n' +
      ' между 2 и 3 дни, с изключение на уикенди и празници.<br><br>\n' +
      ' Естествено оставаме на Ваше разположение от понеделник до петък от 9 до 18 часа на 330970252222 (без допълнителни такси).<br><br>\n' +
      ' Като част от качествения контрол на нашите услуги, цялата ни кореспонденция може да бъде записвана.',
    PHOTOERROR: { MESSAGE: '' }
  },
  GLOBAL: { READ_MORE: 'Прочетете още' },
  FLOWBOX: {
    GENERIC_KEY: 'ba_GnGC3TKedKEe0-gKomQ',
    PRODUCT_KEY: '1hRXF7TUSt-fhhlJASv-TQ',
    RECIPE_KEY: 'Ulp6NW8-QFOoSa61nBIlaA',
    HOME_KEY: 'g7--tXG3QgiwJjjpsaMmrg'
  },
  TOKEN: {
    FACEBOOK: '',
    PAGE_FACEBOOK_ID: '',
    INSTAGRAM: '',
    INSTA_ID: '17841407279489116',
    YOUTUBE: '',
    TWITTER: '',
    MAGAZINE: '6318061952.9bbab10.8842be1c95904c9d80ff59765a3a2ccc'
  },
  COOKIE: {
    BANNER: {
      MANDATORY: 'Изискване',
      ANALYTICS: 'АНАЛИТИКА',
      MARKETING: 'МАРКЕТИНГ',
      SOCIAL_MEDIA: 'СОЦИАЛНИ МЕДИИ',
      ACCEPT: 'Приемете всички',
      REJECT: 'Откажете всички',
      SET_UP: 'Конфигуриране',
      MANDATORY_DETAILS: 'Бисквитките са необходими за работата на сайта, например за съхраняване на информацията Ви за вход и за осигуряване на защитена връзка.\n' +
        ' Бисквитките, необходими за използване на сайта bonduelle.fr са: \n' +
        ' <ul>\n' +
        ' <li>бисквитки за сесия (SESSION_ID): Бисквитка, генерирана от базирани на PHP приложения. Това е идентификатор с общо предназначение, използван за управление на променливите на потребителската сесия. Това е произволно генериран номер, а начинът, по който се използва, може да бъде специфичен за сайта, но подходящ пример е поддържането на потребителя в логнато състояние между страниците.</li>\n' +
        ' </ul>',
      ANALYTICS_DETAILS: 'Функционалните бисквитки ни помагат да оптимизираме сайта, например да събираме статистически данни, да записваме предпочитанията Ви за социално взаимодействие или да измерваме работата и стабилността на нашето приложение.\n' +
        ' <br>\n' +
        ' Аналитичните бисквитки, използвани от сайта bonduelle.com са: \n' +
        ' <ul>\n' +
        ' <li>DoubleClick: Тази бисквитка е зададена от DoubleClick (собственост на Google), за да създаде профил с интересите на посетителя на уебсайта и да показва подходящи реклами на други сайтове. Основната дейност на компанията е обмен на реклами за наддаване в реално време от Google.</li>\n' +
        ' <li>Google Analytics: тези бисквитки позволяват проследяване на най-посещаваните страници и трафик на сайта.</li>\n' +
        ' <li>UTMTracking: тази бисквитка позволява управление на UTM променливи за оптимално проследяване на различните онлайн рекламни кампании</li>.\n' +
        ' </ul>',
      MARKETING_DETAILS: 'Маркетинговите бисквитки ни позволяват да проследяваме предпочитанията Ви за рецепти и продукти, за да предлагаме винаги продуктите, които най-добре отговарят на желанията Ви.\n' +
        ' <br>\n' +
        ' Маркетинговите бисквитки, използвани от сайта bonduelle.fr са: \n' +
        ' <ul>\n' +
        ' <li> Prediggo: този инструмент използва бисквитката SESSION_ID, за да ви предложи продуктите и рецептите, които най-добре отговарят на Вашето браузване и търсения. </li>\n' +
        ' <li>ClickToBuy: тази бисквитка ни позволява да запомним местоположението Ви, така че да можем да ви предложим най-близките магазини, които предлагат продуктите Bonduelle, които търсите.</li>\n' +
        ' <li>Социални мрежи: тези бисквитки позволяват "Социалната стена" на Bonduelle да се показва на началната страница на сайта. Тази "стена" Ви позволява да видите всички най-нови публикации от основните социални мрежи, използвани от екипите на Bonduelle.</li>\n' +
        ' <li>FlowBox: тази бисквитка позволява използването на услугите на софтуера Flowbox, който предлага на нашите посетители рецепти и съдържание на други интернет потребители, които използват продуктите на Bonduelle.</li>\n' +
        ' </ul> "',
      V2: 'ok'
    },
    MODAL: {
      TITLE: 'Управление на бисквитките',
      TEXT: 'Bonduelle Group се ангажира да гарантира защитата на личните данни на потребителите на своите уеб сайтове, и по-специално на уеб сайта Bonduelle.fr. <br>За да можете да се възползвате от услугите, предлагани от сайта, като консултация относно продукти, откриване на нови гурме рецепти, абониране за определени услуги като нашите бюлетини, както и за оптимизиране на използването и персонализирането му според Вашия профил, сайтът използва бисквитки. Bonduelle Group изцяло се ангажира да осигури високо ниво на защита на личните данни и спазването на всички приложими правила за защита на личните данни при обработката им във връзка с използването на нейния уебсайт.\n' +
        ' Можете да активирате/деактивирате бисквитките по всяко време.',
      CONFIG: 'Конфигуриране',
      SAVE: 'Запази'
    }
  },
  LINK: { JOB_OFFERS: 'https://jobs.bonduelle.com/?locale=en_US' },
  CLIC2BUY: { INFOS: '' }
};
