import { SfModel } from '@app/sf-lib/public_api';
import { PrediggoAdvanced } from './prediggo-advanced.model';

export class PrediggoItemsInfo extends SfModel {

  /**
   * ID of the session used by the user
   * @memberof PrediggoItemsInfoResponse
   */
  public sessionId: string;

  /**
   * Language code for internationalization of content. 2 letters.
   * @memberof PrediggoItemsInfoResponse
   */
  public languageCode: string;

  /**
   * Language code for internationalization of content. 4 letters.
   * @memberof PrediggoSimplePageContent
   */
  public region?: string;

  /**
   * Language code for internationalization of content. 4 letters.
   * @memberof PrediggoSimplePageContent
   */
  public moduleVersion?: string;

  /**
   * The set of SKUs whose information to retrieve
   * @memberof PrediggoItemsInfoResponse
   */
  public skus: string[];

  /**
   * Optional. Advanced properties
   * @memberof PrediggoItemsInfoResponse
   */
  public advanced?: PrediggoAdvanced;

  constructor() {
    super();
    this.sessionId = '';
    this.languageCode = '';
    this.skus = [];
    this.advanced = new PrediggoAdvanced();
  }

  public static create(): PrediggoItemsInfo {
    const newEntity = new PrediggoItemsInfo();
    newEntity.advanced = void 0;
    return newEntity;
  }
}
