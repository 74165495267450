export interface SfLink {

  /** page url may be external or internal */
  url: string;

  /** Whether url is external or not */
  is_external?: boolean;

  /** Label displayable on a button for exemple */
  label?: string;

  /**
   * Parameters of the link (i.e: '/:id')
   * @fixme Shouldn't exist. Params should be handled in the url directly
   */
  param?: any;

  extras?: any;
}
