import {
  Component,
  Inject,
  PLATFORM_ID,
  OnInit,
  Renderer2,
  OnDestroy
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { isPlatformServer, isPlatformBrowser, DOCUMENT } from '@angular/common';
import { SfSiteInfo, SITE_INFO } from '@app/sf-lib/public_api';
import { Router, NavigationEnd, RouterOutlet } from '@angular/router';
import { filter, distinctUntilChanged } from 'rxjs/operators';
import { TagManagerService } from './core/tag-manager/services/tag-manager.service';
import { SeoService } from './core/seo.service';


@Component({
    selector: 'som-root',
    templateUrl: './app.component.html',
    imports: [RouterOutlet]
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Bonduelle';

  private noIndex: HTMLMetaElement;
  private pinterestHu: HTMLMetaElement;
  private pinterestDe: HTMLMetaElement;
  private websiteScriptElement: HTMLScriptElement;
  private organizationScriptElement: HTMLScriptElement;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private router: Router,
    private tagManager: TagManagerService,
    private renderer: Renderer2,
    private seoService: SeoService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(SITE_INFO) private siteInfo: SfSiteInfo,
    @Inject(DOCUMENT) private somDocument: Document
  ) {
    const svgUrl = '/assets/icons/icon-set.svg';
    const domain = isPlatformServer(this.platformId)
      ? `${(this.siteInfo as any).origin}`
      : '';

    this.matIconRegistry.addSvgIconSet(this.sanitizer.bypassSecurityTrustResourceUrl(domain + svgUrl));

    this.router.events
      .pipe(
        filter(event => {
          return event instanceof NavigationEnd;
        }),
        distinctUntilChanged()
      )
      .subscribe((event: NavigationEnd) => {

        if (
          event &&
          event.urlAfterRedirects &&
          event.urlAfterRedirects === '/' ||
          (!this.siteInfo.lang.default && event.urlAfterRedirects.length === 3)
        ) {
          // We're on home page. Add microformat
          if (!this.websiteScriptElement) {
            if (isPlatformServer(this.platformId)) {
              this.websiteScriptElement = this.renderer.createElement('script');
              this.websiteScriptElement.type = `application/ld+json`;
              this.websiteScriptElement.text = JSON.stringify(
                this.websiteFormat()
              );
              this.renderer.appendChild(
                this.somDocument.head,
                this.websiteScriptElement
              );
            }
          }
          if (!this.organizationScriptElement) {
            if (isPlatformServer(this.platformId)) {
              this.organizationScriptElement = this.renderer.createElement(
                'script'
              );
              this.organizationScriptElement.type = `application/ld+json`;
              this.organizationScriptElement.text = JSON.stringify(
                this.organizationFormat()
              );
              this.renderer.appendChild(
                this.somDocument.head,
                this.organizationScriptElement
              );
            }
          }
          this.seoService.addHrefLangsLinks();

        } else {
          this.seoService.removeHreflangs();
          // Remove microformat
          if (this.websiteScriptElement && isPlatformServer(this.platformId)) {
            this.renderer.removeChild(
              this.somDocument.head,
              this.websiteScriptElement
            );
            this.websiteScriptElement = null;
          }
          if (
            this.organizationScriptElement &&
            isPlatformServer(this.platformId)
          ) {
            this.renderer.removeChild(
              this.somDocument.head,
              this.organizationScriptElement
            );
            this.organizationScriptElement = null;
          }
        }

        if (this.somDocument) {
          if (isPlatformServer(this.platformId)) {
            this.somDocument.documentElement.lang = this.siteInfo.lang.code;
          }
        }
      });

    if (isPlatformBrowser(this.platformId)) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.innerHTML = this.tagManager.getGTMScript();
      document.head.appendChild(script);
      const noScript = document.createElement('noscript');
      noScript.innerHTML = this.tagManager.getGTMNoScript();
      document.body.appendChild(noScript);
    }

    if (
      !this.pinterestHu &&
      this.siteInfo &&
      this.siteInfo.lang &&
      this.siteInfo.lang.code === 'hu'
    ) {
      this.pinterestHu = this.renderer.createElement('meta');
      this.pinterestHu.name = 'p:domain_verify';
      this.pinterestHu.content = '36d961700f36de1ec3e01b816f0dd9ef';
      this.renderer.appendChild(this.somDocument.head, this.pinterestHu);
    }

    if (
      !this.pinterestDe &&
      this.siteInfo &&
      this.siteInfo.lang &&
      this.siteInfo.lang.code === 'de'
    ) {
      this.pinterestDe = this.renderer.createElement('meta');
      this.pinterestDe.name = 'p:domain_verify';
      this.pinterestDe.content = '5ce1f791464062836c64a51927b013b4';
      this.renderer.appendChild(this.somDocument.head, this.pinterestDe);
    }
  }

  private websiteFormat(): Object {
    const obj = {
      '@context': 'https://schema.org/',
      '@type': 'WebSite',
      name: 'BONDUELLE',
      url: this.siteInfo.origin
    };
    return obj;
  }

  private organizationFormat(): Object {
    const obj = {
      '@context': 'https://schema.org/',
      '@type': 'Organization',
      url: this.siteInfo.origin,
      logo: `${this.siteInfo.origin}/assets/images/logo.png`
    };
    return obj;
  }

  ngOnInit() {
    

  }

  ngOnDestroy() {
    // Destroy the scripts ... just in case
    if (this.websiteScriptElement) {
      this.renderer.removeChild(
        this.somDocument.head,
        this.websiteScriptElement
      );
    }
    if (this.organizationScriptElement) {
      this.renderer.removeChild(
        this.somDocument.head,
        this.organizationScriptElement
      );
    }
    if (this.noIndex) {
      this.renderer.removeChild(this.somDocument.head, this.noIndex);
    }

    if (this.pinterestHu) {
      this.renderer.removeChild(this.somDocument.head, this.pinterestHu);
    }

    if (this.pinterestDe) {
      this.renderer.removeChild(this.somDocument.head, this.pinterestDe);
    }
  }

}
