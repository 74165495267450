import { SomPage } from './page.model';
import { SfBanner, SfMediaImage } from '@app/sf-lib/public_api';

export class SomFaqPage extends SomPage implements SfBanner {

  title: string;

  background: SfMediaImage;

  smallbackground: SfMediaImage;

  banner: SfBanner;

}
