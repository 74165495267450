import { AfterViewInit, Component, Inject, Input, Renderer2, ViewChild } from '@angular/core';

import { OPTIONS, SfOptions } from '@app/sf-lib/lib/core/interfaces/options.interface';

@Component({
    selector: 'responsive-image',
    templateUrl: './responsive-image.component.html',
    styleUrls: ['./responsive-image.component.sass'],
    standalone: false
})
export class ResponsiveImageComponent implements AfterViewInit {

  @Input() section;

  @ViewChild('link', {static: false}) link;

  constructor(
    @Inject(OPTIONS) public options: SfOptions,
    private renderer2: Renderer2,
  ) {
  }

  ngAfterViewInit() {
    if (!this.section.link || !this.section.link.url) {
      this.renderer2.removeAttribute(this.link.nativeElement, 'href');
      this.renderer2.removeAttribute(this.link.nativeElement, 'target');
    }
  }

  get linkUrl() {
    return this.section && this.section.link && this.section.link.url ?
      this.section.link.url :
      '';
  }

  get isExternal() {
    return this.section && this.section.link && this.section.link.is_external ?
      this.section.link.is_external :
      false;
  }

}
