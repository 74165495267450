/* tslint:disable */export const il_he = {
  '404': {
    TITLE: 'אופס...',
    PAGENOTFOUND: 'הדף אינו קיים',
    BUTTON: 'חזרה לדף הבית',
    RECIPES: 'גלו את המתכונים שלנו'
  },
  HOME: {
    SHOWCASE: 'מתכונים',
    CATCH_PHRASE: '',
    SHOWCASE_SEE_ALL: 'כל המתכונים',
    MAGAZINE: { TITLE: 'למעלה', BUTTON: 'גלו את לגומיז' },
    OUR_VEGETABLES: 'הירקות שלנו',
    SOCIAL_WALL: 'יחד, נחלוק את התשוקה לירקות',
    FAQ_TITLE: 'שאלות ותשובות',
    FAQ_SEE_MORE: 'עוד',
    FLOWBOX: ''
  },
  SELECT: { DEFAULT_VALUE: 'בונדואל מציגים' },
  MENU: {
    CONTACT_US: 'צור קשר',
    MY_ACCOUNT: 'החשבון שלי',
    GOOD_DEALS: 'מבצעים מיוחדים',
    NEWSLETTER: 'הניוזלטר של בונדואל'
  },
  FOOTER: {
    LINK: {
      BONDUELLE: 'Bonduelle.com',
      CREDITS: 'קרדיטים',
      LEGAL_NOTICE: 'הודעות משפטיות',
      TERMS_OF_SALES: 'תנאי מכירה',
      COOKIES: 'עוגיות',
      SITEMAP: 'מפת אתר',
      PRIVACY_POLICY: 'מדיניות פרטיות',
      ALL_RIGHTS_RESERVED: '2018 ©כל הזכויות שמורות'
    },
    HISTORY: {
      TITLE: 'בונדואל, מ-1853',
      TEXT: 'בונדואל הוא עסק משפחתי הקיים כבר מעל ל-7 דורות ועובד על מנת לפתח מוצרים חקלאים שמכבדים את האדמה ובני האדם. אנו מייצגים חקלאות יעילה, חכמה ומאוחדת, שמסתכלת אל העתיד ומפתחת חידושים בכל התהליך, מהשדה לצלחת, על מנת ליצור עתיד טוב יותר דרך אוכל המבוסס על ירקות\n'
    },
    NEWSLETTER: {
      TITLE: 'הקהילה של בונדואל',
      TEXT: 'אני מאשר את קבלת הניוזלטר <<הכול על ירקות מאת בונדואל>> (חדשות, קידומי מכירות, תחרויות, כתבות...)',
      PLACEHOLDER: 'דוא"ל',
      GDPR_LINK: 'אודות ניהול נתונים'
    }
  },
  PRODUCTS: 'מוצרים',
  MAGAZINE: {
    HOME: 'ברוכים הבאים למגזין',
    CATCH_PHRASE: 'חדשות הירקות של בונדואל!',
    MENU: { BONDUELLE_LINK: 'בקרו בכתובת bonduelle.com' },
    SHOWCASE: 'תצוגה',
    PREVIOUS_ARTICLES: 'כתבות קודמות',
    PREVIOUS_ARTICLES_SEE_ALL: 'כל הכתבות',
    PREVIOUS_EDITIONS: 'מהדורות קודמות',
    PREVIOUS_EDITIONS_SEE_ALL: 'מהדורות נוספות',
    PREVIOUS_ARTICLES_BY_CATEGORY: 'כתבות קודמות על פי קטגוריה',
    RUBRIC: {
      SEE_ALL: 'כל המתכונים',
      SEE_ALL_ARTICLES: 'כל הכתבות',
      ON: 'כתבות בנושא'
    },
    RUBRIC_COOK: { ON: 'מתכוני' },
    ARTICLE: { JOIN_US: 'הצטרפו אלינו', READ_ALSO: 'לקריאה נוספת' },
    RECIPE: {
      INGREDIENTS: 'מרכיבים',
      INGREDIENTS_LABEL_DESC: 'עבור אנשים {{value}}',
      INGREDIENTS_LABEL_ONE_DESC: 'עבור אדם {{value}}',
      INGREDIENTS_LABEL: 'מרכיבים עבור {{value}} אנשים',
      INGREDIENTS_LABEL_ONE: 'מרכיבים עבור {{value}} אנשים',
      PEOPLES: 'אנשים {{value}}',
      PEOPLE: 'אנשים {{value}}',
      DIFFICULTY: { EASY: 'קל', MEDIUM: 'בינוני', HARD: 'קשה' },
      COST: { CHEAP: 'זול', EXPENSIVE: 'יקר' },
      TIMING: { MIN: 'דקות', HOURS: 'שעות' },
      STEP: 'שלב',
      DISCOVER_ALSO: 'גלו גם'
    }
  },
  ARTICLE: { DISCOVER_ALSO: 'גלו גם', INGREDIENTS: 'מרכיבים' },
  SF: {
    SLIDE: { DISCOVER: 'גלו' },
    SEARCH: { PLACEHOLDER: 'חיפוש', ALL: 'הכול' },
    NEWSLETTER: {
      BTN_LABEL: 'אני נרשם',
      PLACEHOLDER: 'דוא"ל',
      ERROR_MESSAGE: 'כתובת הדוא"ל אינה בתוקף',
      ALREADY_SUBSCRIBED: 'כבר נרשמת',
      GDPR: {
        TXT: 'מידע נוסף אודות ניהול הנתונים שלך',
        LINK: '/cookies#gdpr'
      }
    },
    MONTHLY_FOLDER: { TITLE: 'חדשות חודשיות' },
    MINI_NEWSLETTER: {
      PLACEHOLDER: 'דוא"ל',
      GDPR: {
        TXT: 'מידע נוסף אודות ניהול הנתונים שלך',
        LINK: '/cookies#gdpr'
      }
    },
    SOCIALITEM: { SHARE: 'שיתוף', COMMENT: 'הערה', BONDUELLE: 'Bonduelle' },
    SEARCHRECIPE: { DISCOVER_ALL: 'חיפוש בכל המתכונים' },
    FORM: {
      ERROR: 'שדה לא בתוקף',
      PHOTO: { ADD: 'הוספת תמונה', DELETE: 'מחק את התמונה', SEE: 'ראה' }
    }
  },
  CORPORATE: {
    COMPANY_STORY: 'הסיפור שלנו',
    CSR_COMMITMENT: 'מחויבות חברתית',
    VEGETABLE_INNOVATIONS: 'חדשנות',
    NEWS: 'כל החדשות',
    SEE_MORE: 'כתבות נוספות',
    ON: 'כתבות בנושא'
  },
  NEWSLETTER: {
    TITLE: 'הירשמו לניוזלטר',
    SUBTITLE: 'מבצעים, השראה קולינרית וקצת תרבות'
  },
  ROUTES: {
    products: 'products',
    news: 'news',
    'since-1853': 'since-1853',
    'field-plate': 'field-plate',
    'company-story': 'company-story',
    'csr-commitments': 'csr-commitments',
    'vegetal-innovations': 'vegetal-innovations',
    magazine: 'magazine',
    all: 'all',
    article: 'article',
    cook: 'cook',
    cultivate: 'cultivate',
    explore: 'explore',
    recipe: 'wait-recipe',
    recipes: 'recipes',
    'recipes-home': 'recipes-home',
    r: 'r',
    credits: 'credits-com',
    'legal-notice': 'legal-notice',
    'terms-of-sales': 'terms-of-sales',
    cookies: 'cookies',
    sitemap: 'sitemap',
    'privacy-policy': 'privacy-policy',
    'all-rights-reserved': 'all-rights-reserved',
    search: 'search',
    'articles-search': 'article',
    vegetables: 'vegetables',
    p: 'p',
    contact: 'שירות לקוחות',
    'info-product': 'renseignement-produit',
    'note-product': 'remarque-produit',
    suggestion: 'suggestion',
    'other-request': 'renseignement-produit',
    faq: 'faq',
    'all-themes': 'all-themes',
    'products-search': 'products-search',
    recipesearch: 'recipe-search',
    'product-news': 'product-news',
    'good-deals': 'good-deals',
    'not-found': 'not-found',
    'menu-page': 'menu-page',
    'menu-one': 'menu-one',
    'menu-two': 'menu-two',
    'menu-three': 'menu-three',
    'menu-four': 'menu-four',
    'menu-external': 'menu-external',
    'theme-pasta-rice': '',
    'theme-bowls': '',
    'theme-soup': '',
    'theme-snack': '',
    'theme-sauce': '',
    'theme-unique-plate': '',
    'theme-over': 'theme-over',
    'theme-legumin': 'legumin',
    'theme-ingredients-recipes': '',
    'theme-cook-plate': ''
  },
  BREAD: {
    products: 'מוצרים',
    news: 'חדשות',
    'since-1853': 'מאז 1853',
    'field-plate': 'field-plate',
    'company-story': 'הסיפור שלנו',
    'csr-commitments': 'מחויבות חברתית',
    'vegetal-innovations': 'חדשנות',
    magazine: 'מגזין',
    all: 'הכול',
    article: 'כתבה',
    cook: 'בישול',
    cultivate: 'פיתוח',
    explore: 'חקור',
    recipe: 'ממתין למתכון',
    recipes: 'מתכונים',
    'recipes-home': 'מתכונים- בית',
    'articles-search': 'כתבה',
    search: 'חיפוש',
    vegetables: 'ירקות',
    contact: 'שירות לקוחות',
    'info-product': '',
    'note-product': '',
    suggestion: 'הצעות',
    'other-request': '',
    faq: 'שאלות ותשובות',
    'all-themes': 'כל הנושאים',
    'products-search': 'מוצרים- חיפוש',
    recipesearch: 'מתכונים- חיפוש',
    'product-news': 'מוצרים- חדשות',
    'good-deals': 'מבצעים',
    'not-found': 'לא נמצא',
    'menu-page': 'תפריט- עמוד',
    'menu-one': 'תפריט- 1',
    'menu-two': 'תפריט- 2',
    'menu-three': 'תפריט- 3',
    'menu-four': 'תפריט- 4'
  },
  MENU_PAGE: {
    TITLE1: 'כותרת 1',
    TITLE2: 'כותרת 2',
    TITLE3: 'כותרת 3',
    TITLE4: 'כותרת 4',
    EXTERNAL: ''
  },
  TECHNICAL: { MENU_PAGE: 'דרושים' },
  SEO: {
    TITLE: {
      MENU_ONE: 'כותרת תפריט 1',
      MENU_TWO: 'כותרת תפריט 2',
      MENU_THREE: 'כותרת תפריט 3',
      MENU_FOUR: 'כותרת תפריט 4',
      HOME: 'Bonduelle',
      SEARCH: {
        MAIN: '{{value}}',
        RECIPES: '{{value}}',
        VEGETABLES: '{{value}}',
        ARTICLES: '{{value}}'
      },
      CONTACT: 'Bonduelle',
      RECIPES: 'Bonduelle',
      RECIPE_HOME: 'Bonduelle',
      RECIPE: 'Bonduelle',
      PRODUCTS: 'Bonduelle',
      NEWS: 'Bonduelle',
      CORPORATE: {
        NEWS: 'Bonduelle',
        COMPANY_STORY: 'Bonduelle',
        CSR_COMMITMENTS: 'Bonduelle',
        VEGETAL_INNOVATIONS: 'חדשנות',
        FIELD_PLATE: 'Bonduelle',
        ARTICLE: '{{value}}',
        VEGETABLE: '{{value}}'
      },
      PRODUCT: '{{value}}',
      MAGAZINE: {
        NEWS: 'Bonduelle',
        ARTICLE: 'Bonduelle',
        COOK: 'Bonduelle',
        GROW: 'Bonduelle',
        EXPLORE: 'Bonduelle',
        RECIPE: 'Bonduelle'
      },
      FAQ: 'שאלות ותשובות',
      NOTFOUND: 'Bonduelle',
      RECIPES_ALL: '',
      RECIPETYPE: '{{value}}',
      RECIPESEASON: '{{value}}',
      RECIPEPREPARATION: '{{value}}',
      RECIPEDIFFICULTY: '{{value}}',
      RECIPEVEGETABLE: '{{value}}',
      RECIPECOST: '{{value}}',
      RECIPEDISH: '{{value}}',
      RECIPEDESC: '{{value}}',
      '2RECIPEFACETS': '',
      '4RECIPEFACETS': '{{value}}',
      PRODUCTSEASON: '{{value}}',
      PRODUCTTECHNO: '{{value}}',
      PRODUCTVEGETABLE: '{{value}}',
      PRODUCTRANGE: 'מוצרים מטווח {{value}}',
      THEME_ALL: '',
      THEME_STARTER: '',
      THEME_SALAD: '',
      THEME_CAN: '',
      THEME_FROZEN: '',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: '',
      THEME_RECIPE: '',
      THEME_PLATE: '',
      THEME_ACC: '',
      THEME_SNACK: '',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': '{{value}}'
    },
    DESCRIPTION: {
      MENU_ONE: 'תיאור תפריט 1',
      MENU_TWO: 'תיאור תפריט 2',
      MENU_THREE: 'תיאור תפריט 3',
      MENU_FOUR: 'תיאור תפריט 4',
      HOME: '',
      SEARCH: {
        MAIN: '{{value}}',
        RECIPES: '{{value}}',
        VEGETABLES: '{{value}}',
        ARTICLES: '{{value}}'
      },
      CONTACT: '',
      RECIPES: '',
      RECIPE_HOME: '',
      RECIPE: '',
      PRODUCTS: '',
      NEWS: '',
      CORPORATE: {
        NEWS: '',
        COMPANY_STORY: '',
        CSR_COMMITMENTS: '',
        VEGETAL_INNOVATIONS: '',
        VEGETABLE: '',
        FIELD_PLATE: '',
        ARTICLE: ''
      },
      PRODUCT: '{{value}}',
      MAGAZINE: {
        NEWS: '',
        ARTICLE: '',
        COOK: '',
        GROW: '',
        EXPLORE: '',
        RECIPE: ''
      },
      FAQ: '',
      PAGE: 'page',
      RECIPETYPE: '{{value}}',
      RECIPESEASON: '{{value}}',
      RECIPEPREPARATION: '{{value}}',
      RECIPEDIFFICULTY: '{{value}}',
      RECIPEVEGETABLE: '',
      RECIPECOST: '{{value}}',
      RECIPEDISH: '{{value}}',
      RECIPEDESC: '{{value}}',
      '2RECIPEFACETS': '{{value}}',
      '4RECIPEFACETS': '{{value}}',
      PRODUCTSEASON: '{{value}}',
      PRODUCTTECHNO: '{{value}}',
      PRODUCTVEGETABLE: '{{value}}',
      PRODUCTRANGE: 'גלה את כל המוצרים מטווח {{value}}',
      THEME_ALL: '',
      THEME_STARTER: '',
      THEME_SALAD: '',
      THEME_CAN: '',
      THEME_FROZEN: '',
      THEME_READY_MEALS: '',
      THEME_BIO: '',
      THEME_VEGETABLES: '',
      THEME_RECIPE: '',
      THEME_PLATE: '',
      THEME_ACC: '',
      THEME_SNACK: '',
      RECIPE_THEME1: '',
      '2PRODUCTSFACETS': '{{value}}',
      NOTFOUND: ''
    },
    PIPE: { SOCIETY: ' | Bonduelle' },
    THEME_ALL: '',
    THEME_STARTER: '',
    THEME_SALAD: '',
    THEME_CAN: '',
    THEME_FROZEN: '',
    THEME_READY_MEALS: '',
    THEME_BIO: '',
    THEME_VEGETABLES: '',
    THEME_RECIPE: '',
    THEME_PLATE: '',
    THEME_ACC: '',
    THEME_SNACK: ''
  },
  VEGETABLE: {
    DISCOVER_VEGETABLE: 'גלו את גליונות הירקות שלנו',
    OURS_RECIPES: 'המתכונים שלנו',
    DISCOVER_ALSO: 'גלה גם...',
    SEE_ALL: 'כל הירקות',
    ON: 'ירקות בנושא'
  },
  RECIPE: {
    'INGREDIENTS_LABEL ': 'מרכיבים עבור {{value}}',
    PEOPLES: 'אנשים {{value}}',
    DIFFICULTY: { EASY: 'קל', MEDIUM: 'בינוני', HARD: 'קשה' },
    COST: { CHEAP: 'זול', EXPENSIVE: 'יקר' },
    TIMING: { MIN: 'דקות', HOURS: 'שעות' },
    STEP: 'שלב',
    DISCOVER_ALSO: 'גלה גם...',
    HOME_SEE_MORE: 'מתכונים נוספים',
    SHOWCASE: 'למעלה',
    HOME_BOOK: 'המתכונים שלנו',
    MIN_MORE: 'דקות ו- +',
    HOUR_MORE: 'שעות ו- +',
    FILTER_BY_CRITERIA: 'סנן לפי קריטריונים',
    SORT_BY: 'מיין לפי:',
    BEST_RATED: 'הפופולריים ביותר',
    MOST_CONSULTED: 'הנשאלים ביותר',
    MEMBERS_RECIPES: 'מתכונים של חברים',
    CRITERIA: 'חדשות',
    DISCOVER: { RECIPES: 'גלה את המתכונים שלנו' },
    DISCOVER_RECIPE: 'גלה את המתכון',
    INSTRUCTIONS: 'אופן ההכנה',
    DEFAULT_TAG: 'רעיון למתכון',
    MOST_RECENT: 'האחרונים',
    RECIPE_VIDEOS: 'סרטוני מתכונים',
    PRODUCT_ASSOCIATE: 'מתכון עם',
    BUDGET_LOW: 'זול',
    BUDGET_HIGH: 'גבוה',
    BUDGET_MIDDLE: 'בינוני',
    PRINT_RECIPE: 'הדפס מתכון',
    LET_YOURSELF_SURPRISED: 'תנו לנו להפתיע אתכם',
    COOK_TIP_TITLE: 'כותרת טיפ בישול',
    ENJOY_MEAL: 'בתאבון!',
    FILTER: { LEGEND: 'אגדה' }
  },
  PREDIGGO: { DIFFICULTY: { EASY: 'קל', MEDIUM: 'בינוני', HARD: 'קשה' } },
  COOKIES: {
    TEXT: 'בהמשך הביקור באתר, הינכם מאשרים את השימוש בעוגיות מסיבות טכניות, למטרות ניתוח סטטיסטי וניהול התעבורה באתר, שיתוף מידע עם מפרסמים באתרים אחרים וכדי לספק לכם קידומי מכירות רלוונטיים המתאימים לתחומי העניין שלכם.',
    CLOSE: 'סגור',
    MORE: 'גלו עוד',
    AGREE_YOUTUBE: 'עליך לאשר את העוגיות של Youtube כדי לצפות בסרטון',
    BUTTON_YOUTUBE: 'אשר עוגיות'
  },
  POPUP: { LAUNCH: 'אתר פופאפ' },
  FIELD_PLATE: { DISCOVER_VEGETABLE: 'גלו את גליונות הירקות שלנו' },
  SEARCH: {
    OUR_RECIPES: 'מתכונים',
    OUR_PRODUCTS: 'מוצרים',
    OUR_ARTICLES: 'מאמרים',
    RECIPES: 'מתכונים',
    PRODUCTS: 'מוצרים',
    ARTICLES: 'מאמרים',
    ALL: 'הכול',
    SORT_BY: 'מיין לפי',
    RESULTS: 'תוצאות',
    RESULT: 'תוצאה',
    PRODUCT_OUT: 'מוצרים ללא',
    APPLY: 'החל',
    FILTER: 'סנן',
    RECIPE_OUT: 'מתכונים ללא',
    NO_RESULT: 'כרוב לבן... התקלת אותנו',
    SEE_MORE: 'ראה עוד',
    DISCOVER_RECIPES: 'גלו את המתכונים שלנו',
    SEE_MORE_OF: 'ראה עוד',
    DELETE: 'מחק'
  },
  RECIPE_LIST: {
    OUR_RECIPES: 'מתכונים',
    RESULTS: 'תוצאות',
    SEE_MORE: 'מתכונים נוספים'
  },
  PRODUCT: {
    BUTTON: {
      ACTU: 'חדשות מוצרים',
      FOUND: 'היכן למצוא מוצר זה',
      DISCOVER: 'גלו את כל המבחר'
    },
    NUTRITIONAL_VALUE: 'ערך תזונתי',
    CONDITIONING: 'תנאים',
    PORTIONS: 'מנה',
    NET_WEIGHT: 'משקל נטו',
    DRY_WEIGHT: 'משקל יבש',
    INGREDIENTS: 'מרכיבים',
    OUR_RECIPES: 'גלו את המתכונים שלנו',
    FOR: 'עבור',
    PER_PORTION: 'ולפי מנה של',
    ENERGY: 'אנרגיה',
    FAT: 'שומן',
    SATURATE: 'שומן רווי',
    GLUCIDES: 'פחמימות',
    FIBER: 'סיבים',
    PROTEINS: 'חלבון',
    SALT: 'מלח',
    DISCOVER_ALSO: 'גלה גם...',
    MORE_INFORMATION: 'מידע נוסף',
    TRACES: 'עקבות של',
    CONTAINS: 'מכיל',
    SLIDER: { SHEET: 'ראה גיליון', BUY: 'קנה מוצר זה' },
    NUTRISCORE: '',
    TRIMAN: '',
    AGRICONFIANCE: '',
    BIO: '',
    PESTICIDE_FREE: '',
    LEAF: '',
    LOCK: '',
    SEE_ALL: 'קרה כל המוצרים',
    LEGISLATION: '',
    PORTION: { SINGULAR: 'מנה', PLURAL: 'מנות' },
    DISCLAIMER: '',
    COOKING: 'בישול',
    CONSERVATION: 'אחסון'
  },
  FAQ: {
    ON: 'שאלות',
    ALL: 'הכול',
    SEE_MORE: 'ראה שאלות נוספות',
    TITLE_1: 'שאלות ותשובות',
    TITLE_2: 'האם יש לך שאלה לגבי מוצר ספציפי?',
    LINK_LABEL: 'כתבו לנו'
  },
  FORM: {
    GO_TO_STEP: 'עבור לשלב',
    STEP: 'שלב',
    RECEIVE_EMAIL_COPY: 'קבל עותק בדוא"ל',
    SEND: 'נשלח',
    GDPR: 'עוד אודות ניהול הנתונים שלך',
    ERROR: 'כל השדות המסומנים ב-* הינם שדות חובה',
    SEND_ERROR: 'אירעה שגיאה, אנא נסו שנית בעוד כמה דקות',
    NOTICE: 'לטיפול הנכון בתיק שלך, על פי המקרה, בונדואל עשויה לבקש נתוני בריאות. הם אינם בגדר חובה ובאפשרותך לא לשלוח אותם.',
    SENDING: 'שולח את הטופס...',
    LOADING: 'מעלה...',
    PRODUCT_INFORMATION_TITLE: 'מידע אודות המוצר',
    PRODUCT_INFORMATION: {
      SEO_TITLE: 'מידע אודות המוצר | בונדואל',
      SEO_DESC: 'צור קשר עם שירות הלקוחות'
    },
    INFORMATIONS: 'הפרטים שלכם',
    MISTER: 'אדון',
    MISS: 'גברת',
    NAME: 'שם',
    OUR_NAME: 'השם שלנו',
    FIRSTNAME: 'שם פרטי',
    OUR_FIRSTNAME: 'השם הפרטי שלנו',
    MAIL: 'דוא"ל',
    MAIL_PLH: 'mail@address.com',
    MAIL_CONFIRM: 'אישור כתובת הדוא"ל',
    MAIL_ERROR: 'יש להזין כתובת דוא"ל תקפה',
    MAIL_ERROR_CONFIRM: 'יש לאשר את כתובת הדוא"ל',
    ADDRESS: 'כתובת',
    STREET_PLH: '',
    STREET: 'רחוב ומספר',
    ADDRESS_COMPLEMENT: 'הערות לגבי הכתובת',
    ADDRESS_COMP_PLH: 'הערות לגבי הכתובת',
    ZIPCODE: 'מיקוד',
    CITY: 'עיר',
    CITY_PLH: 'העיר שלך',
    PHONE: 'טלפון',
    PHONE_PLH: 'מספר טלפון',
    COUNTRY: 'ארץ',
    PRODUCT_INFORMATIONS: 'פרטי מוצר',
    PRODUCT_INFORMATION_DESC: '',
    PRODUCT: {
      DETAIL: { DESC: '' },
      NOTE: { TITLE: '', SEO_TITLE: '', SEO_DESC: '' }
    },
    PRODUCT_RANGE: '',
    PRODUCT_NAME: '',
    PRODUCT_NAME_PLH: '',
    PRODUCT_INFORMATIONS_DETAIL: '',
    PRODUCT_INFORMATIONS_LABEL: '',
    PRODUCT_INFORMATIONS_PLH: '',
    PRODUCT_LOT: '',
    PRODUCT_LOT_INFO: '',
    PHOTO: '',
    EXPIRED_DATE: '',
    EXPIRED_DATE_ERROR: '',
    EXPIRED_DATE_DESC: '',
    PHOTO_MORE: '',
    MESSAGE: '',
    OUR_MESSAGE: '',
    SALES_OPERATIONS: { TITLE: '', SEO_TITLE: '', SEO_DESC: '' },
    PRESS_SERVICE: '',
    FOOD_SERVICE: '',
    BUSINESS_INFORMATION: '',
    MODAL_INFORMATION: '',
    JOB_OFFER: { TITLE: '', SEO_TITLE: '', SEO_DESC: '' },
    WEB_SUGGESTION: { TITLE: '', SEO_TITLE: '', SEO_DESC: '' },
    ANOTHER: { TITLE: '', SEO_TITLE: '', SEO_DESC: '' },
    AMBASSADOR: '',
    CATEGORY: {
      SALAD: 'סלט',
      FROZEN: 'קפוא',
      CAN: 'קופסה',
      TAKEAWAY: 'טייק אוויי',
      VEGETABLE: ''
    }
  },
  CONTACT: {
    TITLE: 'לשירותך',
    SPECIAL_INFO: '',
    TAKE_CARE: '',
    BY_MAIL: 'בדוא"ל',
    BY_PHONE: 'בטלפון',
    BY_SOURDLINE: 'בהודעה',
    MAIL_TEXT: '',
    PHONE_TEXT: 'היועצים שלך לשירותך <a href="tel:+33970252222"><b>09 70 25 22 22</b></a> (שיחה ללא תשלום נוסף) משני עד שישי 9:00-18:00 ניתן לשלוח הודעה קולית 24 שעות ביממה.',
    SOURDLINE_TEXT: "לחירשים/ כבדי שמיעה, אנו לשירותכם באתר או בצ'ט <b> משני עד שישי בין השעות 9:00-18:00",
    BY_DISTRIBUTOR: '',
    BY_DISTRIBUTOR_TEXT: '',
    SUBTITLE: 'ברצונך ליצור קשר בנושא:',
    AGREEMENT: '',
    READ_MORE: "בנוסף, הינך רשאי בכל עת לבקש מבונדואל לממש את זכותך לגישה, תיקון, מחיקה והעברת הנתונים האישיים שלך, כמו גם את זכותך להגביל ולהתנגד לעיבוד הנתונים האישיים שלך. כדי לממש את זכויותיך הקשורות לנתונים האישיים שלך, יש לכתוב ל-Dpo_france@bonduelle.com או לדואר לכתובת הבאה: עבור אחראי הגנת נתונים, Bonduelle, Rue Nicolas Appert, PO Box 30173, F-59653 Villeneuve D'Ascq. כדי ללמוד עוד על האיסוף והשימוש של בונדואל בנתונים אישיים, לחץ כאן",
    READ_MORE_LABEL: 'קרא גם',
    SATISFACTION_TEXT: 'על מנת ליצור איתך קשר כדי לספק לך את המידע המבוקש על המוצר ולשלוח לך שאלון שביעות\n' +
      'רצון אם תרצה בכך, נעבד את המידע שנמסר בתפקידנו כבקר נתונים. הבסיס המשפטי לעיבוד\n' +
      'בקשתך הוא הסכמה עקב הנתונים הפוטנציאליים הקשורים לבריאות שאתה עשוי לספק בבקשתך.\n' +
      'מידע זה יישמר כל עוד דרוש לעיבוד בקשתך ועד 5 שנים כדי לספק הוכחה לכך שבקשתך טופלה\n' +
      'וכדי לעמוד בהתחייבויות החוקיות שלנו. הבסיס המשפטי לשליחת שאלון שביעות הרצון הוא גם\n' +
      'הסכמתך. מידע זה יישמר למשך הזמן הנדרש לשליחת שאלון שביעות הרצון ועד לשנה לכל היותר.\n' +
      '.למידע נוסף על עיבוד הנתונים האישיים שלך, אנא עיין במדיניות הפרטיות שלנו',
    SATISFACTION_CHECKBOX: 'על ידי סימון תיבה זו, אתה מסכים לקבל שאלון שביעות רצון (המורכב משאלה בודדת) לאחר עיבוד\n' +
      '.בקשתך. זה אופציונלי',
    CHECKBOX_TEXT: 'רשאית לעבד את נתוני הבריאות האישיים Bonduelle-על ידי סימון תיבה זו, אתה מסכים ש\r\n' +
      '.שאתה עשוי לספק בהודעתך רק למטרה להגיב לבקשתך. זה חובה על מנת לטפל בבקשתך',
    PHONE: 'שני עד שישי, מ- 9:00-18:00 בטלפון 00.33.09.70.25.22.22 (השיחה אינה בתשלום נוסף, לא כולל סופי שבוע וחגים)',
    CALL_US: 'התקשר אלינו',
    TO_WRITE: 'כתוב לנו',
    PHONE_NUMBER: '3,31E+11',
    SUCCESS_MESSAGE: 'תודה! הודעתך נשלך לצוות ותועבד בזמן ממוצע של 2-3 ימים, לא כולל סופי שבוע וחגים. <br><br> אנו עומדים לשירותך בימים שני עד שישי, בין השעות 9:00-18:00 בטלפון 00.33.09.70.25.22.22 (השיחה אינה בתשלום נוסף).<br><br> כחלק ממעקב אחר איכות השירות שלנו, השיחות עשויות להיות מוקלטות.',
    PHOTOERROR: { MESSAGE: '' }
  },
  GLOBAL: { READ_MORE: 'קרא עוד' },
  FLOWBOX: {
    GENERIC_KEY: 'ba_GnGC3TKedKEe0-gKomQ',
    PRODUCT_KEY: '1hRXF7TUSt-fhhlJASv-TQ',
    RECIPE_KEY: 'Ulp6NW8-QFOoSa61nBIlaA',
    HOME_KEY: 'g7--tXG3QgiwJjjpsaMmrg'
  },
  TOKEN: {
    FACEBOOK: '',
    PAGE_FACEBOOK_ID: '',
    INSTAGRAM: '',
    INSTA_ID: '17841407279489116',
    YOUTUBE: '',
    TWITTER: '',
    MAGAZINE: '6318061952.9bbab10.8842be1c95904c9d80ff59765a3a2ccc'
  },
  COOKIE: {
    BANNER: {
      MANDATORY: 'שדה חובה',
      ANALYTICS: 'אנליטיקה',
      MARKETING: 'שיווק',
      SOCIAL_MEDIA: 'מדיה חברתית',
      ACCEPT: 'קבל הכול',
      REJECT: 'דחה הכול',
      SET_UP: 'הגדר',
      MANDATORY_DETAILS: 'העוגיות נדרשות לתפעול האתר, לדוגמה, לאחסון פרטי ההתחברות ואספקת חיבור בטוח. העוגיות נדרשות לשימוש באתר bonduelle.fr הן: <ul>\n' +
        '<li>session cookies (SESSION_ID): עוגיות המופקות על ידי אפליקציות מבוססת PHP. זהו מזהה למטרה כללית המשמש לניהול משתני הסשן של המשתמש. מדובר במספר רנדומלי, שאופן השימוש שלו ספציפי לאתר, אולם דוגמה טובה היא שימור סטטוס ההתחברות של משתמש בין עמודים. </li>\n' +
        '</ul>',
      ANALYTICS_DETAILS: 'עוגיות פונקציונליות מסייעות לנו למטב את האתר, לדוגמה, לאסוף נתונים סטטיסטיים, לרשום את העדפותיך לגבי אינטראקציות חברתיות או למדוד את הביצועים והיציבות של האפליקציה שלנו. <br> העוגיות האנליטיות בהן נעשה שימוש על ידי אתר bonduelle.fr הן: <ul> <li>DoubleClick: עוגיה זו  מוגדרת על ידי DoubleClick (בבעלות גוגל) ליצור פרופיל לתחומי העניין של המבקר באתר ולהציג פרסומות רלוונטיות מאתרים אחרים. הפעילות העיקרית של החברה היא החלפת פרסומות בזמן אמת מגוגל. </li>\n' +
        '<li>Google Analytics: עוגיות אלו מאפשרות מערב אחר העמודים הכי מבוקרים והתעבורה באתר. </li>\n' +
        '<li>UTMTracking: עוגיות אלו מאפשרות ניהול משתני UTM למעקב אופטימלי אחר קמפיינים שונים באינטרנט </li>.\n' +
        '</ul>',
      MARKETING_DETAILS: 'עוגיות שיווק מאפשרות לנו לעקוב אחר העדפותיך למתכונים ומוצרים כדי להציע לך את המוצרים המתאימים ביותר לצרכיך. <br> עוגיות השיווק בהן נעשה שימוש על ידי אתר bonduelle.fr הן: <ul> <li> Prediggo: כלי זה משמש כמזהה סשן המציע לך את המוצרים והמתכונים המתאימים ביותר לחיפושים והניווטים שלך.  </li>\n' +
        '<li>ClickToBuy עוגיות אלו מאפשרות לנו לזכור את מיקומך כדי להציע לך את החנויות הקרובות ביותר המוכרות את מוצרי בונדואל שהינך מחפש. </li>\n' +
        '<li>Social Networks: עוגיות אלו מאפשרות להציג את "הקיר החברתי" של בונדואל בעמוד הבית של האתר. קיר זה מאפשר לך לראות את הפוסטים האחרונים מרשתות חברתיות גדולות בהן השתמשו צוותי בונדואל. </li>\n' +
        '<li>FlowBox: עוגיות אלו מאפשרות לשימוש בשירותי תוכנית flowbox, המאפשרת לנו להציע למבקרים מתכונים ויצירות של גולשי אינטרנט אחרים שהשתמשו במוצרי בונדואל.  </li>\n' +
        '</ul>',
      V2: 'אישור'
    },
    MODAL: {
      TITLE: 'ניהול עוגיות',
      TEXT: 'קבוצת בונדואל מחויבת להבטיח גנה על הנתונים האישיים של המשתמשים באתריה, ובפרט באתר Bonduelle.fr. <br> על מנת לאפשר לך ליהנות מהשירותים המוצעים באתר, כמו ייעוץ לגבי מוצרים, מתכוני גורמה חדשים, רישום לשירותים מסוימים כמו הניוזלטר ולמטב את השימוש וההתאמה האישית לפי הפרופיל שלך, האתר משתמש בעוגיות. קבוצת בנדואל מחויבת להבטיח רמת הגנה גבוהה לנתונים האישיים ולציית לכל חוקי הגנת הנתונים החלים בעת עיבוד הנתונים האישיים בהקשר לשימוש באתר החברה. לפיכך, ניתן להפעיל/ לנתק את השימוש בנתונים בכל עת.',
      CONFIG: 'הגדרות',
      SAVE: 'שמור'
    }
  },
  LINK: { JOB_OFFERS: 'https://jobs.bonduelle.com/?locale=en_US' },
  CLIC2BUY: { INFOS: '' }
};
