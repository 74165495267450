import { PrediggoAdvanced } from './prediggo-advanced.model';
import { PrediggoParameters } from './prediggo-parameters.model';
import { SfModel } from '@app/sf-lib/public_api';

export class PrediggoSimplePageContent extends SfModel {

  /**
   * ID of the session used by the user
   * @memberof PrediggoSimplePageContent
   */
  public sessionId: string;

  /**
   * Language code for internationalization of content. 2 letters.
   * @memberof PrediggoSimplePageContent
   */
  public languageCode: string;

  /**
   * Language code for internationalization of content. 4 letters.
   * @memberof PrediggoSimplePageContent
   */
  public region?: string;

  /**
   * Language code for internationalization of content. 4 letters.
   * @memberof PrediggoSimplePageContent
   */
  public moduleVersion?: string;

  /**
   * The identifier of the desired page
   * @memberof PrediggoSimplePageContent
   */
  public pageId: number;

  /**
   * A list of parameters
   * @memberof PrediggoSimplePageContent
   */
  public parameters?: PrediggoParameters;

  /**
   * Advanced properties
   * @memberof PrediggoSimplePageContent
   */
  public advanced?: PrediggoAdvanced;

  constructor() {
    super();
    this.sessionId = '';
    this.languageCode = '';
    this.pageId = 0;
    this.parameters = new PrediggoParameters();
    this.advanced = new PrediggoAdvanced();
  }

  public static create(): PrediggoSimplePageContent {
    const newEntity = new PrediggoSimplePageContent();
    newEntity.parameters = void 0;
    newEntity.advanced = void 0;
    return newEntity;
  }

}
