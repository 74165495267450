import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'displayNone',
    standalone: false
})
export class DisplayNonePipe implements PipeTransform {

  transform(country: string): boolean {
    if (country !== 'sk' && country !== 'cz') {
      return true
    }
    return false;
  }

}
