import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SfImageComponent } from './components/image.component';
import { SfLazyLoadImageModule } from './directives/lazy-load-image.module';

@NgModule({
  imports: [
    CommonModule,
    SfLazyLoadImageModule
  ],
  declarations: [
    SfImageComponent
  ],
  exports: [
    SfImageComponent
  ]
})
export class SfImageModule { }
