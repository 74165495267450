import { Component, Inject, Input, ViewEncapsulation } from '@angular/core';

import { OPTIONS, SfOptions } from '@app/sf-lib/lib/core/interfaces/options.interface';
import { JumbotronModel } from './jumbotron.model';

@Component({
    selector: 'jumbotron',
    templateUrl: './jumbotron.component.html',
    styleUrls: ['./jumbotron.component.sass'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class JumbotronComponent {

  @Input() section: JumbotronModel;

  constructor(
    @Inject(OPTIONS) public options: SfOptions,
  ) {
  }

}
