import { PrediggoAdvanced } from './prediggo-advanced.model';
import { SfModel } from '@app/sf-lib/public_api';

export class PrediggoAutoComplete extends SfModel {

  /**
   * ID of the session used by the user
   * @memberof PrediggoAutoComplete
   */
  public sessionId: string;

  /**
   * Language code for internationalization of content. 2 letters.
   * @memberof PrediggoAutoComplete
   */
  public languageCode: string;

  /**
   * Language code for internationalization of content. 4 letters.
   * @memberof PrediggoSimplePageContent
   */
  public region?: string;

  /**
   * Language code for internationalization of content. 4 letters.
   * @memberof PrediggoSimplePageContent
   */
  public moduleVersion?: string;

  /**
   * The actual search query
   * @memberof PrediggoAutoComplete
   */
  public query: string;

  /**
   * Optional. Map of filter properties
   * exemple:
   *  {
   *    'brand' : ['ibm', 'sony'],
   *    'category' : '121'
   *  }
   * @memberof PrediggoAutoComplete
   */
  public filters?: { [key: string]: string[]|string };

  /**
   * Optional. Advanced properties
   * @memberof PrediggoAutoComplete
   */
  public advanced?: PrediggoAdvanced;

  constructor() {
    super();
    this.sessionId = '';
    this.languageCode = '';
    this.query = '';
    this.filters = {};
    this.advanced = new PrediggoAdvanced();
  }

  public static create(): PrediggoAutoComplete {
    const newEntity = new PrediggoAutoComplete();
    newEntity.filters = void 0;
    newEntity.advanced = void 0;
    return newEntity;
  }
}
