import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SfLazyLoadDirective } from './lazy-load-images.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
      SfLazyLoadDirective
    ],
  exports: [
      SfLazyLoadDirective
    ]
})
export class SfLazyLoadImageModule { }
